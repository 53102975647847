import * as types from '../../lib/actionTypes.es6';

export function userLoginRequest(loginData) {
  return {
    type: types.LOGIN_REQUESTED,
    payload: loginData,
  };
}

export function userLoginSuccess(authData) {
  return {
    type: 'USER_LOGIN_SUCCESS',
    payload: authData,
  };
}

export function userLoginFail(response) {
  return {
    type: 'USER_LOGIN_FAIL',
    payload: response,
  };
}

export function userLogout() {
  return {
    type: 'USER_LOGOUT',
  };
}

export function sessionExpireAlert() {
  return {
    type: 'SESSION_EXPIRED_ALERT',
  };
}

export function sessionRefreshRequest() {
  return {
    type: 'SESSION_REFRESH_REQUEST',
  };
}

export function sessionRefreshSuccess(authData) {
  return {
    type: 'SESSION_REFRESH_SUCCESS',
    payload: authData,
  };
}

export function sessionRefreshFailed() {
  return {
    type: types.SESSION_REFRESH_FAIL,
  };
}

export function unauthorizedAction(message) {
  return {
    type: 'UNAUTHORISED_ACTION',
    payload: message,
  };
}

export function resetUnauthorizedAction() {
  return {
    type: 'RESET_UNAUTHORISED_ACTION',
  };
}

export function forgotPasswordRequest(username) {
  return {
    type: types.FORGOT_PASSWORD_REQUEST,
    payload: username,
  };
}

export function forgotPasswordSuccess() {
  return {
    type: types.FORGOT_PASSWORD_SUCCESS,
  };
}

export function forgotPasswordFailure(error) {
  return {
    type: types.FORGOT_PASSWORD_FAILURE,
    error,
  };
}

export function forgotPasswordReset() {
  return {
    type: types.FORGOT_PASSWORD_RESET,
  };
}



export function getStartedRequest(payload) {
  return {
    type: types.GET_STARTED_REQUEST,
    payload,
  };
}

export function getStartedSuccess(response) {
  return {
    type: types.GET_STARTED_SUCCESS,
    payload: response
  };
}

export function getStartedFailure(payload) {
  return {
    type: types.GET_STARTED_FAILURE,
    payload,
  };
}


export function resetgetStartedSuccess(error) {
  return {
    type: types.RESET_GET_STARTED_SUCCESS,
  };
}

export function resetGetStartedError(error) {
  return {
    type: types.RESET_GET_STARTED_ERROR,
  };
}