import * as types from '../../lib/actionTypes.es6';

export function reportSuspendedPoliciesRequest(reportType) {
  return {
    type: types.REPORT_SUSPENDED_POLICIES_REQUEST,
    payload: reportType,
  };
}

export function reportSuspendedPoliciesRequestSuccess(reportURL) {
  return {
    type: types.REPORT_SUSPENDED_POLICIES_SUCCESS,
    payload: reportURL,
  };
}

export function reportSuspendedPoliciesRequestError(error) {
  return {
    type: types.REPORT_SUSPENDED_POLICIES_FAILURE,
    payload: error,
  };
}

export function reportSuspendedPoliciesReset() {
  return {
    type: types.REPORT_SUSPENDED_POLICIES_RESET,
  };
}
