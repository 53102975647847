import React, { useMemo } from 'react';
import { hashHistory } from 'react-router';
import Grid from "@material-ui/core/Grid";
import TableInc from '../../../../../shared-ui/TableInc';
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import { formatDateLocale } from '../../../../../lib/utils.es6';
import { TablePagination } from '@mui/material';

export function DisplayClaims({ currencyCode, classes, setSelectedClaims, selectedClaims, setShowNothingSelectedError, pagingData, getClaims, getClaimsFiltered, getClaimsByPage, getClaimsBySize, filteredClaims }) {

  const claimsFormatted = useMemo(() => selectedClaims.map(claim => ({
    ...claim,
    grower_number: claim.claim_details.policy_number
  })), [selectedClaims]);

  const claimDateValue = (claim) => {
    switch (claim.status) {
      case "All_Docs_Pending":
      case "Docs_Partially_Submitted":
        return claim.created_at;
      case "Awaiting_Confirmation":
        return claim.documentation_received_at;
      case "Failed_Confirmation":
        return claim.updated_at;
      case "Decision_Pending":
        return claim.updated_at;
      case "Approved":
        return claim.decision_made_at;
      default:
        return claim.created_at;
    }
  };

  const claimDateLabel = () => {
    const claim = claimsFormatted[0];

    if (!claim) return "Created At";

    switch (claim.status) {
      case "All_Docs_Pending":
      case "Docs_Partially_Submitted":
        return "Created At";
      case "Awaiting_Confirmation":
        return "Doc submitted at";
      case "Failed_Confirmation":
        return "Failed at";
      case "Decision_Pending":
        return "Confirmed at";
      case "Approved":
        return "Approved At";
      default:
        return "Created At";
    }
  };

  const getTableData = () => {
    return new Promise((resolve) => {
      resolve({
        data: claimsFormatted,
        page: pagingData.page - 1,
        size: pagingData.size,
        totalCount: pagingData.total,
      });
    });
  };

  return <Grid container spacing={2}>
    <Grid item xs={12} md={12} sm={12}>
      <TableInc
        columns={[
          { title: 'Claim number', field: 'claim_number', render: rowData => rowData.claim_number || "N/A" },
          { title: 'status', field: 'status', render: rowData => rowData.status || "N/A" },
          { title: 'LOU number', field: 'preauthorization_number', render: rowData => rowData.preauthorization_number || "N/A" },
          { title: 'Grower  Number', field: 'grower_number'  },
          { title: 'Hospital', field: 'facility', render: rowData => rowData.facility || "N/A" },
          { title: 'Invoice Number', field: 'benefit_usages', render: rowData => rowData.benefit_usages ? rowData.benefit_usages.map((benefit) => benefit.invoice_number) : "N/A" },
          { title: 'Payable Amount', field: 'benefit_usages', render: rowData => `${currencyCode} ${(rowData.benefit_usages.reduce((psum, benefit) => psum + benefit.benefit_amount_in_cents, 0) / 100).toLocaleString()}` || "N/A" },
          { title: claimDateLabel(), render: rowData => formatDateLocale(claimDateValue(rowData)) || "N/A" },
          { title: 'Case Manager', field: 'web_agent_name' },
          { title: 'action', field: 'claimaction', render: rowData => {
              return <div className={classes.claimactionbt}>
                <ButtonInc
                  onClick={() => hashHistory.push(`admin/claim-processing-v2/${rowData.guid}`)}
                  variant="contained">View Claim</ButtonInc>
              </div>;
            }
          },
        ]}
        data={() => getTableData()}
        key={JSON.stringify(claimsFormatted)}
        options={{
          showTitle: false,
          pagination: false,
          selection: true,
          pageSize: pagingData.size,
          selectionProps: rowData => ({
            color: 'primary',
          }),
          rowStyle: rowData => ({
            backgroundColor: rowData.selected ? '#ffe2ec' : '#FFF'
          }),
          search: false,
          paging: false,
        }}
        components={{
          Pagination: () => null
        }}
        onSelectionChange={(rows) => {
          setSelectedClaims(claimsFormatted.map(item => rows.find(row => row.guid === item.guid) ? { ...item, selected: true } : { ...item, selected: false }));
          setShowNothingSelectedError(false)
        }}
        localization={{
          toolbar: {
            nRowsSelected: '{0} claim(s) selected'
          }
        }}
      />
      <div>
      <TablePagination
        component="div"
        count={pagingData.total}
        page={pagingData.page - 1}
        rowsPerPage={pagingData.size}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        onPageChange={(event, newPage) => {
          console.log('Custom pagination - page change:', newPage + 1);
          getClaimsFiltered({ 
            page: newPage + 1, 
            size: pagingData.size 
          });
        }}
        onRowsPerPageChange={(event) => {
          const newSize = parseInt(event.target.value, 10);
          console.log('Custom pagination - size change:', newSize);
          getClaimsFiltered({ 
            page: 1,
            size: newSize 
          });
        }}
      />
      </div>

    </Grid>
  </Grid>;
}