import React, { useEffect, useState } from 'react'
import { useStyle } from "./Style";
import ModalInc from '../../../../../shared-ui/ModalInc'
import ButtonInc from '../../../../../shared-ui/ButtonInc'
import moment from 'moment';
import find from 'lodash/find';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress, FormHelperText } from '@mui/material';
import { getBenefitUsageBalances } from '../../../../../lib/requests.es6';


const EditLOU = (props) => {
  if (!props.louToEdit) {
    return ""
  }

  console.log("Lous to edit", props.louToEdit)
  const classes = useStyle();

  const [policy, setPolicy] = useState('');
  const [member, setMember] = useState('');
  const [currentBenefit, setCurrentBenefit] = useState({});
  const [benefitType, setBenefitType] = useState();


  const [admissionDate, setAdmissionDate] = useState(props.louToEdit.hospital_details.hospital_admission_date);
  const [dischargeDate, setDischargeDate] = useState(props.louToEdit.hospital_details.hospital_discharch_date);
  const [condition, setCondition] = useState('')
  const [conditionError, setConditionError] = useState(false);
  const [numberOfdays, setNumberOfDays] = useState(props.louToEdit.hospital_details.days_in_hospital);
  const [surgicalAmount, setSurgicalAmount] = useState();
  const [rateperday, setRatePerday] = useState('');
  const [reasonForDowngrade, setReasonForDowngrade] = useState('')

  const [benefitUsageBalances, setBenefitUsageBalances] = useState(null)
  const [errorFetchingBenefitUsage, setErrorFetchingBenefitUsage] = useState(false)
  const [loadingBenefitBalances, setLoadingBenefitBalances] = useState(false)


  const fetchBenefitUsageBalance = async (selectedMember) => {
    setLoadingBenefitBalances(true);
    try {
      const response = await getBenefitUsageBalances({ dependant_guid: selectedMember.dependant.guid });
      if(isEmptyBenefitUsage(response.data)){
        setErrorFetchingBenefitUsage(true)
      }else{
        setBenefitUsageBalances(response.data);
        setCurrentBenefit({})
        setBenefitType('')
        setErrorFetchingBenefitUsage(false)
      }

    } catch (error) {
      console.error('Error fetching benefit balances:', error);
    }
    setLoadingBenefitBalances(false);
  };

  const isEmptyBenefitUsage = (response) => !response.hasOwnProperty("DAILY HOSPITAL BENEFIT") || !response.hasOwnProperty("ADDITIONAL SURGERY BENEFIT")

  useEffect(() => {
    const growerNumber = props.louToEdit.policy_number
    const policy = find(props.policies, { policy_number: growerNumber })
    setPolicy(policy)

    const memberGuid = props.louToEdit.dependant_guid
    const member = findMember(policy.quotation.policy_schedule_entries, memberGuid)
    setMember(member)

    fetchBenefitUsageBalance(member);

  
    setAdmissionDate(props.louToEdit.hospital_details.hospital_admission_date)
    setDischargeDate(props.louToEdit.hospital_details.hospital_discharch_date)
    setNumberOfDays(props.louToEdit.hospital_details.days_in_hospital)
    setCondition(props.louToEdit.hospital_details.diagnosis_type)
    updateNumberOfDays()

  }, [])
  useEffect(() => {

    if (benefitType) {

      const memberHospitalBenefit = find(member.benefits, {"type": "DAILY HOSPITAL BENEFIT"})
      console.log('memberHospitalBenefit', memberHospitalBenefit);
      const hospital = {
        ...benefitUsageBalances["DAILY HOSPITAL BENEFIT"],
        yearly_total: benefitUsageBalances["DAILY HOSPITAL BENEFIT"].remaining_amount_in_cents/100,
        remaining_amount_in_cents: (memberHospitalBenefit.cover_amount_in_cents/memberHospitalBenefit.cover_usage_count)/100,
        type: "DAILY HOSPITAL BENEFIT",
      }
      const surgery = {
        ...benefitUsageBalances["ADDITIONAL SURGERY BENEFIT"],
        remaining_amount_in_cents: benefitUsageBalances["ADDITIONAL SURGERY BENEFIT"].remaining_amount_in_cents / 100,
        type: "ADDITIONAL SURGERY BENEFIT",
      }

      if (benefitType === 'HOSPITAL + SURGERY') {
        setCurrentBenefit({ hospital, surgery })

      } else if (benefitType === 'HOSPITAL') {
        setCurrentBenefit({ hospital })

      } else if (benefitType === 'SURGERY') {
        setCurrentBenefit({ surgery })

    }
    }

  }, [benefitType])

  const findMember = (policyEntries, memberGuid) => {
    for (let member of policyEntries) {
      if (member.dependant.guid === memberGuid) return member
    }
    return ''
  }


  useEffect(() => {
    ValidatorForm.addValidationRule('hasAdmissionDateFirst', () => {
      return admissionDate !== ''
    });

    ValidatorForm.addValidationRule('numberOfDaysDoesNotExceedBalance', (value) => {
      return exceedsHospitalDays()
    });

    ValidatorForm.addValidationRule('dischargeDateIsAfterAdmissionDate', (value) => {
      if (!admissionDate || !dischargeDate) return true;
      return moment(dischargeDate).isAfter(moment(admissionDate));
    });

    updateNumberOfDays();

    return function cleanup() {
      ValidatorForm.removeValidationRule('hasAdmissionDateFirst');
      ValidatorForm.removeValidationRule('numberOfDaysDoesNotExceedBalance');
      ValidatorForm.removeValidationRule('dischargeDateIsAfterAdmissionDate');
    };
  }, [admissionDate, dischargeDate])

  useEffect(() => {
    const daily_hospital_rate = currentBenefit ? (currentBenefit.hospital ? currentBenefit.hospital.remaining_amount_in_cents : 0) : 0;
    ValidatorForm.addValidationRule('dailyRateNotMoreThanDailyHospitalRate', () => {
      return rateperday <= daily_hospital_rate;
    });
    return function cleanup() {
      ValidatorForm.removeValidationRule('dailyRateNotMoreThanDailyHospitalRate');
    };

  }, [rateperday])


  useEffect(() => {
    const surgeryAmnt = currentBenefit ? (currentBenefit.surgery ? currentBenefit.surgery.remaining_amount_in_cents : 0) : 0;
    ValidatorForm.addValidationRule('surgeryAmountNotMoreThanTotalSurgeryAmount', () => {
      return Number(surgicalAmount) <= surgeryAmnt
    });
    return function cleanup() {
      ValidatorForm.removeValidationRule('surgeryAmountNotMoreThanTotalSurgeryAmount');
    };
  }, [surgicalAmount])

  useEffect(() => {
    const surgeryAmnt = currentBenefit ? (currentBenefit.surgery ? currentBenefit.surgery.remaining_amount_in_cents : 0) : 0;
    const defaultDailyRate = currentBenefit ? (currentBenefit.hospital ? currentBenefit.hospital.remaining_amount_in_cents : 0) : 0;

    setSurgicalAmount(surgeryAmnt)
    setRatePerday(defaultDailyRate)
    updateNumberOfDays()
  }, [currentBenefit])


  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === "benefitType") {
      setBenefitType(value)
    }

    if (name === "policy") {
      setPolicy(value)
    }
    if (name === "member") {
      setMember(value)
      fetchBenefitUsageBalance(value);

    }
    if (name === "admissionDate") {
      setAdmissionDate(value)
    }
    if (name === "dischargeDate") {
      setDischargeDate(value)
    }

    if (name === "condition") {
      setCondition(value)
      setConditionError(false);
    }

    if (name === "surgicalAmount") {
      setSurgicalAmount(value)
    }
    if (name === "rateperday") {
      setRatePerday(value)
    }
    if (name === "reasonForDowngrade") {
      setReasonForDowngrade(value)
    }

  }

  const exceedsHospitalDays = () => {
    const balance_days = currentBenefit ? (currentBenefit.hospital ? currentBenefit.hospital.remaining_usage_count : 0) : 0;

    if (!admissionDate || !dischargeDate) return true;
    const days = daysInHospital(dischargeDate, admissionDate);
    return days <= balance_days;
  }


  const updateNumberOfDays = () => {
    const daysInHospitald = daysInHospital(dischargeDate, admissionDate);
    const balance_days = currentBenefit ? (currentBenefit.hospital ? currentBenefit.hospital.remaining_usage_count : 0) : 0;
    if (daysInHospitald > balance_days && currentBenefit.hospital) {
      setNumberOfDays(balance_days);
    } else {
      setNumberOfDays(daysInHospitald);
    }
  }

  // const reset = () => {
  //   setPolicy('');
  //   setMember('');
  //   setAdmissionDate('');
  //   setDischargeDate('');
  //   setCondition('');
  //   setNumberOfDays('');
  //   setSurgicalAmount('');
  //   setRatePerday('');
  //   setReasonForDowngrade('');
  // }



  const handleEditLou = () => {

    const previousHospitalBenefit = find(props.louToEdit.benefits, { "benefit_type": "DAILY HOSPITAL BENEFIT" })
    const previousSurgeryBenefit = find(props.louToEdit.benefits, { "benefit_type": "ADDITIONAL SURGERY BENEFIT" })
    console.log({ previousHospitalBenefit});

    const benefits = []

    if (currentBenefit.hospital) benefits.push({
      "benefit_type": currentBenefit.hospital.type,
      "authorized_amount_in_cents": getHospitalAmount() * 100,
      "authorized_usage_count": numberOfdays,
      "guid": previousHospitalBenefit.guid,
    })
    if (currentBenefit.surgery) benefits.push({
      "benefit_type": currentBenefit.surgery.type,
      "authorized_amount_in_cents": Number(surgicalAmount) * 100,
      "guid": previousSurgeryBenefit.guid,
    })

    const payload = {
      "guid": props.louToEdit.guid,
      "benefits": [
        ...benefits
      ],
      "hospital_details": {
        "hospital_admission_date": admissionDate,
        "hospital_discharch_date": dischargeDate,
        "days_in_hospital": numberOfdays,
        "hospital": props.louToEdit.hospital_details.hospital,
        "hospital_code": props.louToEdit.hospital_details.hospital_code,
        "hospital_address": props.louToEdit.hospital_details.hospital_address,
        "hospital_region": props.louToEdit.hospital_details.hospital_region,
        "hospital_telephone": props.louToEdit.hospital_details.hospital_telephone,
        "diagnosis_name": props.louToEdit.hospital_details.diagnosis_name,
        "diagnosis_code": props.louToEdit.hospital_details.diagnosis_code,
        "diagnosis_type": props.louToEdit.hospital_details.diagnosis_type,
      },
      "event_date": admissionDate,
      "notes": reasonForDowngrade,
      "customer_guid": policy.customer_guid,

    }
    console.log("payload", payload)



    props.editLou(payload);
  }

  const members = policy ? policy.quotation.policy_schedule_entries : []
  const getHospitalAmount = () => {
    const daysInHospitald = daysInHospital(dischargeDate, admissionDate);
    if (rateperday && daysInHospitald) return rateperday * daysInHospitald
    return 0
  }

  const handleEditCreatedSuccessfully = () => {
    props.resetEditLOUSuccessAlert()
  }
  const handleEditCreateError = () => {
    props.resetEditLOUFailureAlert()
  }

  const benefitsTypes = [
    'HOSPITAL',
    'SURGERY', 
    'HOSPITAL + SURGERY'
  ]

  return (
    <>
      <ModalInc
        modalTitle="Amend Letter of Undertaking  "
        subTitle="Fill the below fields to amend letter of undertaking"
        open={props.open}
        onClose={props.close}
        fullWidth
        maxWidth="md"
      >
        <ValidatorForm onSubmit={() => handleEditLou()} >

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
              <SelectValidator
                name='policy'
                labelId="select-grower-number"
                id="select-grower-number"
                value={policy}
                label="Select Grower Number"
                variant="outlined"
                validators={['required']}
                disabled
                errorMessages={['Please select a grower number']}
                style={{ width: "100%" }}
              >
                {
                  props.policies.map(policy => {
                    return <MenuItem
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }}
                      value={policy}
                    >
                      {policy.policy_number}
                    </MenuItem>
                  })
                }


              </SelectValidator>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <SelectValidator
                name='member'
                labelId="select-member"
                id="select-member"
                value={member}
                label="Select Member"
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select a member']}
                style={{ width: "100%" }}
                disabled
              >
                {
                  members.map(member => {
                    return <MenuItem
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }}
                      value={member}
                    >
                      {member.dependant.first_name}

                    </MenuItem>
                  })
                }

              </SelectValidator>
            </Grid>
            <Grid item xs={12} sm={4} md={4} className={classes.selectbenefit}>
              <SelectValidator
                name='benefitType'
                labelId="select-benefit"
                id="select-benefit"
                value={benefitType}
                label={loadingBenefitBalances ? '...Fetching benefits' : "Select Benefit"}
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select benefits']}
                style={{ width: "100%" }}
              >
                {
                  ((errorFetchingBenefitUsage ||  loadingBenefitBalances) ? []: (member ? benefitsTypes: [])).map(benefitType => {
                    return <MenuItem
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }}
                      value={benefitType}
                    >
                      {benefitType}

                    </MenuItem>
                  })
                }

              </SelectValidator>
              {errorFetchingBenefitUsage && <p style={{color: 'red'}}>Could not find benefits for this member</p>}
            </Grid>

            <Grid container spacing={2} className={classes.summaryWrapper}>
              <Grid item xs={12} sm={4} md={4} className={classes.beneficiaryname}>
                <h4>Beneficiary Name</h4>
                {member
                  ? <p><strong> {member.dependant.first_name}</strong></p>
                  : <p>N/A</p>}
              </Grid>
              <Grid item xs={12} sm={4} md={4} className={classes.benefitbalance}>
                <h4>Hospital Benefit Balance</h4>
                {currentBenefit.hospital
                  ?
                  <>
                  <p><strong>DHB {currentBenefit.hospital.remaining_usage_count} days left -  KSH {currentBenefit.hospital.remaining_amount_in_cents}</strong></p>
                  </>
                  : <p>N/A</p>}
              </Grid>
              <Grid item xs={12} sm={4} md={4} className={classes.surgicalbalance}>
                <h4>Surgical Balance</h4>
                {currentBenefit.surgery
                  ? <p><strong>KSH {currentBenefit.surgery.remaining_amount_in_cents}</strong></p>
                  : <p>N/A</p>}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <div className={classes.completeLouSectionTitle}>
                  <h4>Complete New LOU</h4>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4} className={classes.autocomplete}>
              <TextValidator
                  id="hospital"
                  label="hospital"
                  name='hospital'
                  value={props.louToEdit.hospital_details.hospital}
                  disabled
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
         
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  id="admissionDate"
                  label="Admission Date"
                  name='admissionDate'
                  value={admissionDate}
                  onChange={handleChange}
                  fullWidth
                  type='date'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  id="dischargeDate"
                  label="Discharge Date "
                  name='dischargeDate'
                  value={dischargeDate}
                  onChange={handleChange}
                  fullWidth
                  type='date'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  validators={
                    ['hasAdmissionDateFirst',
                      // 'numberOfDaysDoesNotExceedBalance', 
                      'dischargeDateIsAfterAdmissionDate']
                  }
                  errorMessages={[
                    `Please select admision date first`,
                    // `Total days in hospital exceeds the daily hospital days balance`,
                    `Discharge date can not be before admision date`,
                  ]}
                />
              </Grid>
              {/* {!exceedsHospitalDays() && <p>Total days in hospital exceeds the daily hospital days balance</p>} */}

              <Grid item xs={12} sm={4} md={4} className={classes.autocomplete}>

              <TextValidator
                  id="diagnosis"
                  label="diagnosis"
                  name='diagnosis'
                  value={props.louToEdit.hospital_details.diagnosis_name}
                  disabled
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />


                <div className={classes.checkcondition}>
                  <FormControl error={conditionError} component="fieldset">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="condition"
                      value={condition}
                      onChange={handleChange}
                      disabled
                    >
                      <FormControlLabel value="Acute" control={<Radio />} label="Acute" />
                      <FormControlLabel value="Chronic" control={<Radio />} label="Chronic" />
                    </RadioGroup>
                    {conditionError && <FormHelperText>Select a condition</FormHelperText>}
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  id="days"
                  fullWidth
                  label="Number of Days"
                  name='numberOfdays'
                  value={numberOfdays}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  id="SurgeryAmount"
                  fullWidth
                  label="Surgery amount"
                  name='surgicalAmount'
                  type='number'
                  value={surgicalAmount}
                  onChange={handleChange}
                  variant="outlined"
                  validators={['required', 'surgeryAmountNotMoreThanTotalSurgeryAmount']}
                  errorMessages={[
                    `Please enter surgery amount`,
                    `surgery amount  can not be more than the total surgery amount`,
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
              <TextValidator
                  id="rateperday"
                  fullWidth
                  label="Rate Per Day"
                  type='number'
                  name='rateperday'
                  value={rateperday}
                  onChange={handleChange}
                  variant="outlined"
                  validators={['required', 'dailyRateNotMoreThanDailyHospitalRate']}
                  errorMessages={[
                    `Please enter rate per day`,
                    `rate can not be more than the daily hospital rate`,
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <TextValidator
                  id="reasonForDowngrade"
                  fullWidth
                  label="Reason For change"
                  name='reasonForDowngrade'
                  value={reasonForDowngrade}
                  multiline
                  rows={2}
                  maxRows={4}
                  onChange={handleChange}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={[
                    `Please enter rate per day`,
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} className={classes.calculationswrapper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={4}>
                    <p><strong>Hospital: </strong>KSH {getHospitalAmount()}</p>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <p><strong>Surgery: </strong>KSH {surgicalAmount}</p>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <p><strong>Total Amount: </strong>KSH {Number(getHospitalAmount()) + Number(surgicalAmount)}</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    type='submit'
                    style={{ marginTop: "20px", float: "right" }}
                  >
                    Amend Document
                  </ButtonInc>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginTop: "20px" }}
                    onClick={props.close}
                  >
                    Cancel
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </ValidatorForm>

        <AlertDialog
          custom
          show={props.showEditLOUProgressAlert}
          size="sm"
          style={{ marginTop: '0', top: '30vh' }}
          confirmBtnText="ok"
          showCancel={false}
          showConfirm={false}
          cancelBtnText="cancel"
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2>Editting LOU record... </h2>
        </AlertDialog>

        <AlertDialog
          success
          show={props.showEditLOUSuccessAlert}
          size="sm"
          title="LOU record edited"
          style={{ marginTop: '0', top: '30vh' }}
          onConfirm={() => {
            handleEditCreatedSuccessfully();
          }}
          confirmBtnText="ok"
          showCancel={false}
          showConfirm
          cancelBtnText="cancel"
          confirmBtnCssClass
        ></AlertDialog>

        <AlertDialog
          show={!!props.showEditLOUErrorAlert}
          danger
          title="Error editting lou record"
          onConfirm={() => handleEditCreateError()}
          confirmBtnText="ok"
          confirmBtnCssClass
          showCancel={false}
          style={{ marginTop: '0', top: '30vh' }}
        >
          {props.error
            ? props.error.message
            : ''}
        </AlertDialog>

      </ModalInc>
    </>
  )
}

export default EditLOU

function daysInHospital(dischargeDate, admissionDate, defaultDays) {
  if (dischargeDate && admissionDate) {
    return moment(dischargeDate).diff(moment(admissionDate), 'days');
  }
  return defaultDays
}
