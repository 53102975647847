import { take, call, put } from 'redux-saga/effects';
import { getReportPayableClaimsBlob } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import * as types from '../../lib/actionTypes.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

export function* reportsPayableClaimRequestWatcher() {
  for (;;) {
    const reportAction = yield take(types.REPORT_PAYABLE_CLAIM_REQUEST);
    try {
      const response = yield call(
        getReportPayableClaimsBlob,
        reportAction.payload
      );
      console.log(response.data);
      yield put(actions.reportPayableClaimRequestSuccess(response.data));
    } catch (e) {
      yield put(
        actions.reportPayableClaimRequestError(
          'An error occurred, this could be due to invalid data'
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
