export const RolesData =  [
    {
        "name": "KTDA case manager",
        "code": "KTDA_CASE_MANAGER",
    },
    {
        "name": "KTDA approver",
        "code": "KTDA_APPROVER",
    },
    {
        "name": "KTDA assessor 1",
        "code": "KTDA_ASSESSOR_1",
    },
    {
        "name": "KTDA assessor 2",
        "code": "KTDA_ASSESSOR_2",
    },
    {
        "name": "KTDA underwrtier",
        "code": "KTDA_UNDERWRITER",
    },

    {
            "guid": "0edad4f1-75d1-11e7-af9a-0ab5968da949",
            "privileges": [
                {
                    "guid": "4d967dbd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "MANAGE_EXISTING_CUSTOMER"
                },
                {
                    "guid": "4e4cf95b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIM_CALLBACKS"
                },
                {
                    "guid": "4de6cddd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS"
                },
                {
                    "guid": "4e2f9756-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_DASHBOARD"
                },
                {
                    "guid": "ac7817ca-0d63-11eb-b099-02b2bf7e617e",
                    "name": "VIEW_CUSTOMER_SEARCH"
                },
                {
                    "guid": "d2963a68-72d2-11e7-af9a-0ab5968da949",
                    "name": "VIEW_EXISTING_CUSTOMER"
                },
                {
                    "guid": "8c91836e-094e-11e7-ac59-e94dc1e43147",
                    "name": "READ_POLICY"
                },
                {
                    "guid": "92d8910c-b316-11eb-8fd9-02b2bf7e617e",
                    "name": "VIEW_CASHBACK"
                },
                {
                    "guid": "8dc98b3c-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_CLAIM"
                },
                {
                    "guid": "0ee2a9df-75d1-11e7-af9a-0ab5968da949",
                    "name": "UPDATE_CLAIM_ASSESSORS_LEVEL_2"
                },
                {
                    "guid": "8dc9748a-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_CLAIM"
                },
                {
                    "guid": "4e6b49fd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_PAYABLE"
                },
                {
                    "guid": "4e77e954-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_CLOSED"
                },
                {
                    "guid": "4da7ff4b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CUSTOMER_SUMMARY"
                },
                {
                    "guid": "4df69d17-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_DEBIT_ORDER"
                },
                {
                    "guid": "4e5da15c-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_OPEN_CLAIMS"
                },
                {
                    "guid": "4db8b2ef-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_POLICIES"
                },
                {
                    "guid": "4e220f7b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CLAIM_PROCESSING"
                },
                {
                    "guid": "4e3da8f5-7b12-11e9-927f-02b2bf7e617e",
                    "name": "SEARCH_CLAIMS_DASHBOARD"
                },
                {
                    "guid": "8dc8ef74-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_POLICY"
                },
                {
                    "guid": "0ee03edb-75d1-11e7-af9a-0ab5968da949",
                    "name": "VIEW_CLAIM_CALLBACK"
                },
                {
                    "guid": "4dd7973e-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_PAYMENTS"
                },
                {
                    "guid": "8dc91c42-094e-11e7-ac59-e94dc1e43147",
                    "name": "CREATE_CLAIM"
                }
            ],
            "name": "Claim Assessors (Level 2)",
            "code": "ROLE_CAL2",
            "active": true,
            "createdAt": "2017-07-31T09:17:21.592+0000"
        },
        {
            "guid": "345c6b1c-f81a-11ea-b099-02b2bf7e617e",
            "privileges": [
                {
                    "guid": "4d967dbd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "MANAGE_EXISTING_CUSTOMER"
                },
                {
                    "guid": "4e4cf95b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIM_CALLBACKS"
                },
                {
                    "guid": "4de6cddd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS"
                },
                {
                    "guid": "4e2f9756-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_DASHBOARD"
                },
                {
                    "guid": "4d7ed661-7b12-11e9-927f-02b2bf7e617e",
                    "name": "REGISTER_NEW_CUSTOMER"
                },
                {
                    "guid": "4e05d4c4-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_BUY_NEW_POLICY"
                },
                {
                    "guid": "92d8910c-b316-11eb-8fd9-02b2bf7e617e",
                    "name": "VIEW_CASHBACK"
                },
                {
                    "guid": "4f5bfafb-ffa5-11e9-b8a4-02b2bf7e617e",
                    "name": "IGNORE_MAX_DAYS_ALLOWED_AFTER_DISCHARGE_OR_DEATH"
                },
                {
                    "guid": "4ea4a734-7b12-11e9-927f-02b2bf7e617e",
                    "name": "EDIT_CUSTOMER"
                },
                {
                    "guid": "4dbe176d-7b12-11e9-927f-02b2bf7e617e",
                    "name": "EDIT_POLICIES"
                },
                {
                    "guid": "4e09092b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CREATE_NEW_POLICY"
                },
                {
                    "guid": "0ee1e05b-75d1-11e7-af9a-0ab5968da949",
                    "name": "UPDATE_CLAIM_ASSESSORS_LEVEL_1"
                },
                {
                    "guid": "4e13bd75-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_MAKE_CLAIM"
                },
                {
                    "guid": "4ea68969-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_EDIT_CUSTOMER"
                },
                {
                    "guid": "4da7ff4b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CUSTOMER_SUMMARY"
                },
                {
                    "guid": "8dc8d890-094e-11e7-ac59-e94dc1e43147",
                    "name": "UPDATE_POLICY_CHANGE"
                },
                {
                    "guid": "4db8b2ef-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_POLICIES"
                },
                {
                    "guid": "4e220f7b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CLAIM_PROCESSING"
                },
                {
                    "guid": "4e3da8f5-7b12-11e9-927f-02b2bf7e617e",
                    "name": "SEARCH_CLAIMS_DASHBOARD"
                },
                {
                    "guid": "0ee03edb-75d1-11e7-af9a-0ab5968da949",
                    "name": "VIEW_CLAIM_CALLBACK"
                },
                {
                    "guid": "4dd7973e-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_PAYMENTS"
                },
                {
                    "guid": "8dc8ef74-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_POLICY"
                },
                {
                    "guid": "0ebc42ea-75d1-11e7-af9a-0ab5968da949",
                    "name": "GENERATE_REPORT"
                }
            ],
            "name": "Group Policy Insurance Broker",
            "code": "ROLE_BROKER",
            "active": true,
            "createdAt": "2020-09-16T12:43:21.000+0000"
        },
        {
            "guid": "347de705-d5e2-11ec-9324-02a6f7a2bce5",
            "privileges": [
                {
                    "guid": "d294708b-72d2-11e7-af9a-0ab5968da949",
                    "name": "REGISTER_CUSTOMER"
                },
                {
                    "guid": "8dc90608-094e-11e7-ac59-e94dc1e43147",
                    "name": "BUY_NEW_POLICY"
                },
                {
                    "guid": "e15d5473-72f0-11e7-af9a-0ab5968da949",
                    "name": "VIEW_CUSTOMER_STATUS"
                },
                {
                    "guid": "8710711a-8366-11e8-927f-02b2bf7e617e",
                    "name": "PAYMENT_PUSH"
                },
                {
                    "guid": "d2963a68-72d2-11e7-af9a-0ab5968da949",
                    "name": "VIEW_EXISTING_CUSTOMER"
                },
                {
                    "guid": "c465f6a6-72f0-11e7-af9a-0ab5968da949",
                    "name": "CREATE_CLAIM_CALLBACK"
                },
                {
                    "guid": "8dc8ef74-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_POLICY"
                },
                {
                    "guid": "d0a8df6b-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_ALL_PRODUCTS"
                },
                {
                    "guid": "d298a772-72d2-11e7-af9a-0ab5968da949",
                    "name": "UPDATE_EXISTING_CUSTOMER"
                },
                {
                    "guid": "8c91a312-094e-11e7-ac59-e94dc1e43147",
                    "name": "DELETE_POLICY"
                },
                {
                    "guid": "8dc91c42-094e-11e7-ac59-e94dc1e43147",
                    "name": "CREATE_CLAIM"
                },
                {
                    "guid": "8dc9748a-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_CLAIM"
                }
            ],
            "name": "API Client user",
            "code": "ROLE_API_CLIENT",
            "active": true,
            "createdAt": "2022-05-17T13:06:46.000+0000"
        },
        {
            "guid": "3cc77485-75d9-11e7-af9a-0ab5968da949",
            "privileges": [
                {
                    "guid": "4e4cf95b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIM_CALLBACKS"
                },
                {
                    "guid": "4de6cddd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS"
                },
                {
                    "guid": "ac7817ca-0d63-11eb-b099-02b2bf7e617e",
                    "name": "VIEW_CUSTOMER_SEARCH"
                },
                {
                    "guid": "4e05d4c4-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_BUY_NEW_POLICY"
                },
                {
                    "guid": "8dc98b3c-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_CLAIM"
                },
                {
                    "guid": "4e6b49fd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_PAYABLE"
                },
                {
                    "guid": "4e77e954-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_CLOSED"
                },
                {
                    "guid": "4ea68969-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_EDIT_CUSTOMER"
                },
                {
                    "guid": "4da7ff4b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CUSTOMER_SUMMARY"
                },
                {
                    "guid": "4df69d17-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_DEBIT_ORDER"
                },
                {
                    "guid": "4e5da15c-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_OPEN_CLAIMS"
                },
                {
                    "guid": "0ee03edb-75d1-11e7-af9a-0ab5968da949",
                    "name": "VIEW_CLAIM_CALLBACK"
                },
                {
                    "guid": "4dd7973e-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_PAYMENTS"
                },
                {
                    "guid": "0ebc42ea-75d1-11e7-af9a-0ab5968da949",
                    "name": "GENERATE_REPORT"
                },
                {
                    "guid": "48588cbb-762b-11e7-af9a-0ab5968da949",
                    "name": "UPDATE_INSURECO_FINANCE"
                },
                {
                    "guid": "4d967dbd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "MANAGE_EXISTING_CUSTOMER"
                },
                {
                    "guid": "8dea8ed5-46bb-11ec-b098-02b2bf7e617e",
                    "name": "DELETE_GROUP"
                },
                {
                    "guid": "4e2f9756-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_DASHBOARD"
                },
                {
                    "guid": "92e0c023-b316-11eb-8fd9-02b2bf7e617e",
                    "name": "UPDATE_CASHBACK_PAYMENT_STATUS"
                },
                {
                    "guid": "34e81e02-0e31-11eb-b099-02b2bf7e617e",
                    "name": "VIEW_ALL_GROUPS"
                },
                {
                    "guid": "d2963a68-72d2-11e7-af9a-0ab5968da949",
                    "name": "VIEW_EXISTING_CUSTOMER"
                },
                {
                    "guid": "7daf191a-8674-11e8-927f-02b2bf7e617e",
                    "name": "CLAIM_RETRY_TRANSACTION_PAYMENT"
                },
                {
                    "guid": "8c91836e-094e-11e7-ac59-e94dc1e43147",
                    "name": "READ_POLICY"
                },
                {
                    "guid": "92d8910c-b316-11eb-8fd9-02b2bf7e617e",
                    "name": "VIEW_CASHBACK"
                },
                {
                    "guid": "8dc9748a-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_CLAIM"
                },
                {
                    "guid": "bef58280-d846-11e8-927f-02b2bf7e617e",
                    "name": "CLAIM_PAYMENT_UPDATE_OUTCOME"
                },
                {
                    "guid": "4db8b2ef-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_POLICIES"
                },
                {
                    "guid": "4e220f7b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CLAIM_PROCESSING"
                },
                {
                    "guid": "4e3da8f5-7b12-11e9-927f-02b2bf7e617e",
                    "name": "SEARCH_CLAIMS_DASHBOARD"
                },
                {
                    "guid": "8dc8ef74-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_POLICY"
                }
            ],
            "name": "InsureCo Finance",
            "code": "ROLE_ICF",
            "active": true,
            "createdAt": "2017-07-31T10:15:54.606+0000"
        },
        {
            "guid": "3fb3ee58-cfea-11e7-9a0f-0a45811b6168",
            "privileges": [
                {
                    "guid": "3fbd10da-cfea-11e7-9a0f-0a45811b6168",
                    "name": "UPDATE_CLAIM_UPLOADER"
                },
                {
                    "guid": "92d8910c-b316-11eb-8fd9-02b2bf7e617e",
                    "name": "VIEW_CASHBACK"
                }
            ],
            "name": "Claim Uploaders",
            "code": "ROLE_CU",
            "active": true,
            "createdAt": "2017-11-23T01:04:25.000+0000"
        },
        {
            "guid": "4840803c-762b-11e7-af9a-0ab5968da949",
            "privileges": [
                {
                    "guid": "4d967dbd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "MANAGE_EXISTING_CUSTOMER"
                },
                {
                    "guid": "4e4cf95b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIM_CALLBACKS"
                },
                {
                    "guid": "4de6cddd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS"
                },
                {
                    "guid": "4e2f9756-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_DASHBOARD"
                },
                {
                    "guid": "d2963a68-72d2-11e7-af9a-0ab5968da949",
                    "name": "VIEW_EXISTING_CUSTOMER"
                },
                {
                    "guid": "4e05d4c4-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_BUY_NEW_POLICY"
                },
                {
                    "guid": "8c91836e-094e-11e7-ac59-e94dc1e43147",
                    "name": "READ_POLICY"
                },
                {
                    "guid": "8dc98b3c-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_CLAIM"
                },
                {
                    "guid": "8dc9748a-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_CLAIM"
                },
                {
                    "guid": "4e6b49fd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_PAYABLE"
                },
                {
                    "guid": "4e77e954-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_CLOSED"
                },
                {
                    "guid": "4ea68969-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_EDIT_CUSTOMER"
                },
                {
                    "guid": "4da7ff4b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CUSTOMER_SUMMARY"
                },
                {
                    "guid": "4df69d17-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_DEBIT_ORDER"
                },
                {
                    "guid": "4e5da15c-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_OPEN_CLAIMS"
                },
                {
                    "guid": "4db8b2ef-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_POLICIES"
                },
                {
                    "guid": "4e220f7b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CLAIM_PROCESSING"
                },
                {
                    "guid": "4e3da8f5-7b12-11e9-927f-02b2bf7e617e",
                    "name": "SEARCH_CLAIMS_DASHBOARD"
                },
                {
                    "guid": "8dc8ef74-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_POLICY"
                },
                {
                    "guid": "0ee03edb-75d1-11e7-af9a-0ab5968da949",
                    "name": "VIEW_CLAIM_CALLBACK"
                },
                {
                    "guid": "4dd7973e-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_PAYMENTS"
                },
                {
                    "guid": "0ebc42ea-75d1-11e7-af9a-0ab5968da949",
                    "name": "GENERATE_REPORT"
                }
            ],
            "name": "InsureCo Underwriting",
            "code": "ROLE_ICU",
            "active": true,
            "createdAt": "2017-07-31T20:03:12.559+0000"
        },
        {
            "guid": "8c915aa6-094e-11e7-ac59-e94dc1e43147",
            "privileges": [
                {
                    "guid": "d0aa7bf6-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "READ_PRODUCT"
                },
                {
                    "guid": "8dc90608-094e-11e7-ac59-e94dc1e43147",
                    "name": "BUY_NEW_POLICY"
                },
                {
                    "guid": "4e4cf95b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIM_CALLBACKS"
                },
                {
                    "guid": "8dc95f36-094e-11e7-ac59-e94dc1e43147",
                    "name": "DELETE_CLAIM"
                },
                {
                    "guid": "e15d5473-72f0-11e7-af9a-0ab5968da949",
                    "name": "VIEW_CUSTOMER_STATUS"
                },
                {
                    "guid": "4de6cddd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS"
                },
                {
                    "guid": "4d7ed661-7b12-11e9-927f-02b2bf7e617e",
                    "name": "REGISTER_NEW_CUSTOMER"
                },
                {
                    "guid": "4e05d4c4-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_BUY_NEW_POLICY"
                },
                {
                    "guid": "8dc98b3c-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_CLAIM"
                },
                {
                    "guid": "d298a772-72d2-11e7-af9a-0ab5968da949",
                    "name": "UPDATE_EXISTING_CUSTOMER"
                },
                {
                    "guid": "273a0746-8be9-11e7-af9a-0ab5968da949",
                    "name": "UPDATE_USER"
                },
                {
                    "guid": "9e64b3f9-beb4-11e9-927f-02b2bf7e617e",
                    "name": "DELETE_COUNTRY"
                },
                {
                    "guid": "8c91a312-094e-11e7-ac59-e94dc1e43147",
                    "name": "DELETE_POLICY"
                },
                {
                    "guid": "0ee1e05b-75d1-11e7-af9a-0ab5968da949",
                    "name": "UPDATE_CLAIM_ASSESSORS_LEVEL_1"
                },
                {
                    "guid": "d294708b-72d2-11e7-af9a-0ab5968da949",
                    "name": "REGISTER_CUSTOMER"
                },
                {
                    "guid": "4e77e954-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_CLOSED"
                },
                {
                    "guid": "4e13bd75-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_MAKE_CLAIM"
                },
                {
                    "guid": "4e5da15c-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_OPEN_CLAIMS"
                },
                {
                    "guid": "c465f6a6-72f0-11e7-af9a-0ab5968da949",
                    "name": "CREATE_CLAIM_CALLBACK"
                },
                {
                    "guid": "b4c5b962-f305-11e7-88cc-023b8a6e1e1a",
                    "name": "CREATE_DEBIT"
                },
                {
                    "guid": "d299d2db-72d2-11e7-af9a-0ab5968da949",
                    "name": "UPDATE_CLAIM_CALLBACK"
                },
                {
                    "guid": "0ee03edb-75d1-11e7-af9a-0ab5968da949",
                    "name": "VIEW_CLAIM_CALLBACK"
                },
                {
                    "guid": "4dd7973e-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_PAYMENTS"
                },
                {
                    "guid": "48588cbb-762b-11e7-af9a-0ab5968da949",
                    "name": "UPDATE_INSURECO_FINANCE"
                },
                {
                    "guid": "2738ab36-8be9-11e7-af9a-0ab5968da949",
                    "name": "CREATE_USER"
                },
                {
                    "guid": "8dc9c930-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ACCOUNTING_REPORT"
                },
                {
                    "guid": "d0dbf51d-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "UPDATE_PRODUCT_PREMIUM"
                },
                {
                    "guid": "8dea8ed5-46bb-11ec-b098-02b2bf7e617e",
                    "name": "DELETE_GROUP"
                },
                {
                    "guid": "4e2f9756-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_DASHBOARD"
                },
                {
                    "guid": "34e81e02-0e31-11eb-b099-02b2bf7e617e",
                    "name": "VIEW_ALL_GROUPS"
                },
                {
                    "guid": "8dc9b4f4-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_BUSINESS_REPORT"
                },
                {
                    "guid": "8c91836e-094e-11e7-ac59-e94dc1e43147",
                    "name": "READ_POLICY"
                },
                {
                    "guid": "8dc9de2a-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_SMS_REPORT"
                },
                {
                    "guid": "0ee2a9df-75d1-11e7-af9a-0ab5968da949",
                    "name": "UPDATE_CLAIM_ASSESSORS_LEVEL_2"
                },
                {
                    "guid": "d0dedaef-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_ALL_PRODUCT_PREMIUMS"
                },
                {
                    "guid": "4f5bfafb-ffa5-11e9-b8a4-02b2bf7e617e",
                    "name": "IGNORE_MAX_DAYS_ALLOWED_AFTER_DISCHARGE_OR_DEATH"
                },
                {
                    "guid": "6674b853-222a-11ea-b8a4-02b2bf7e617e",
                    "name": "UPDATE_SMS_TEMPLATE"
                },
                {
                    "guid": "8dc9a018-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_REPORT_DASHBOARD"
                },
                {
                    "guid": "4e09092b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CREATE_NEW_POLICY"
                },
                {
                    "guid": "d0a8df6b-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_ALL_PRODUCTS"
                },
                {
                    "guid": "8dc9748a-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_CLAIM"
                },
                {
                    "guid": "d0ac1f3a-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "DELETE_PRODUCT"
                },
                {
                    "guid": "3fbd10da-cfea-11e7-9a0f-0a45811b6168",
                    "name": "UPDATE_CLAIM_UPLOADER"
                },
                {
                    "guid": "667785cd-222a-11ea-b8a4-02b2bf7e617e",
                    "name": "VIEW_ALL_SMS_TEMPLATES"
                },
                {
                    "guid": "1e56fc5c-b398-11e9-927f-02b2bf7e617e",
                    "name": "REVERT_EXPIRED_CLAIM"
                },
                {
                    "guid": "4db8b2ef-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_POLICIES"
                },
                {
                    "guid": "9e45a97e-beb4-11e9-927f-02b2bf7e617e",
                    "name": "READ_PARTNER"
                },
                {
                    "guid": "4e51b3a3-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CREATE_CLAIM_CALLBACKS"
                },
                {
                    "guid": "9e472f59-beb4-11e9-927f-02b2bf7e617e",
                    "name": "DELETE_PARTNER"
                },
                {
                    "guid": "667337de-222a-11ea-b8a4-02b2bf7e617e",
                    "name": "DELETE_SMS_TEMPLATE"
                },
                {
                    "guid": "48681090-762b-11e7-af9a-0ab5968da949",
                    "name": "UPDATE_CLAIM"
                },
                {
                    "guid": "666cf959-222a-11ea-b8a4-02b2bf7e617e",
                    "name": "CREATE_SMS_TEMPLATE"
                },
                {
                    "guid": "4e9a8d9f-7b12-11e9-927f-02b2bf7e617e",
                    "name": "USER_CREDENTIALS"
                },
                {
                    "guid": "a7c90c84-bf67-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_ALL_LANGUAGES"
                },
                {
                    "guid": "b4b8d2ac-f305-11e7-88cc-023b8a6e1e1a",
                    "name": "PREMIUM_REVERSAL"
                },
                {
                    "guid": "ac7817ca-0d63-11eb-b099-02b2bf7e617e",
                    "name": "VIEW_CUSTOMER_SEARCH"
                },
                {
                    "guid": "4e90eeb1-7b12-11e9-927f-02b2bf7e617e",
                    "name": "LOYALTY_ELIGIBILITY"
                },
                {
                    "guid": "4eb21abe-7b12-11e9-927f-02b2bf7e617e",
                    "name": "EDIT_CLAIM"
                },
                {
                    "guid": "9e652278-beb4-11e9-927f-02b2bf7e617e",
                    "name": "UPDATE_COUNTRY"
                },
                {
                    "guid": "d0dd7d51-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "READ_PRODUCT_PREMIUM"
                },
                {
                    "guid": "4e6b49fd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_PAYABLE"
                },
                {
                    "guid": "ac7fa916-85ac-11e7-af9a-0ab5968da949",
                    "name": "LOYALTY_RENEWAL"
                },
                {
                    "guid": "4ea68969-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_EDIT_CUSTOMER"
                },
                {
                    "guid": "4da7ff4b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CUSTOMER_SUMMARY"
                },
                {
                    "guid": "4df69d17-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_DEBIT_ORDER"
                },
                {
                    "guid": "66761b1e-222a-11ea-b8a4-02b2bf7e617e",
                    "name": "READ_SMS_TEMPLATE"
                },
                {
                    "guid": "27327d51-8be9-11e7-af9a-0ab5968da949",
                    "name": "VIEW_ALL_USER"
                },
                {
                    "guid": "0ebc42ea-75d1-11e7-af9a-0ab5968da949",
                    "name": "GENERATE_REPORT"
                },
                {
                    "guid": "fdf3cbc6-72f0-11e7-af9a-0ab5968da949",
                    "name": "CREATE_POLICY_CHANGE_CALLBACK"
                },
                {
                    "guid": "4d967dbd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "MANAGE_EXISTING_CUSTOMER"
                },
                {
                    "guid": "9e63d03a-beb4-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_COUNTRIES"
                },
                {
                    "guid": "9e64469b-beb4-11e9-927f-02b2bf7e617e",
                    "name": "CREATE_COUNTRY"
                },
                {
                    "guid": "8710711a-8366-11e8-927f-02b2bf7e617e",
                    "name": "PAYMENT_PUSH"
                },
                {
                    "guid": "92e0c023-b316-11eb-8fd9-02b2bf7e617e",
                    "name": "UPDATE_CASHBACK_PAYMENT_STATUS"
                },
                {
                    "guid": "b4c73129-f305-11e7-88cc-023b8a6e1e1a",
                    "name": "DELETE_DEBIT"
                },
                {
                    "guid": "d2963a68-72d2-11e7-af9a-0ab5968da949",
                    "name": "VIEW_EXISTING_CUSTOMER"
                },
                {
                    "guid": "7daf191a-8674-11e8-927f-02b2bf7e617e",
                    "name": "CLAIM_RETRY_TRANSACTION_PAYMENT"
                },
                {
                    "guid": "9e4a2c89-beb4-11e9-927f-02b2bf7e617e",
                    "name": "UPDATE_PARTNER"
                },
                {
                    "guid": "2739a805-8be9-11e7-af9a-0ab5968da949",
                    "name": "DELETE_USER"
                },
                {
                    "guid": "92d8910c-b316-11eb-8fd9-02b2bf7e617e",
                    "name": "VIEW_CASHBACK"
                },
                {
                    "guid": "d0af02cd-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "UPDATE_PRODUCT"
                },
                {
                    "guid": "4ea4a734-7b12-11e9-927f-02b2bf7e617e",
                    "name": "EDIT_CUSTOMER"
                },
                {
                    "guid": "9e488e36-beb4-11e9-927f-02b2bf7e617e",
                    "name": "CREATE_PARTNER"
                },
                {
                    "guid": "d0ad9a6c-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "CREATE_PRODUCT"
                },
                {
                    "guid": "aa25e972-dd37-11ea-b099-02b2bf7e617e",
                    "name": "SEND_SMS_RECOMMENDING_RECURRING_PAYMENT"
                },
                {
                    "guid": "9e4427b1-beb4-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_ALL_PARTNERS"
                },
                {
                    "guid": "8dc8d890-094e-11e7-ac59-e94dc1e43147",
                    "name": "UPDATE_POLICY_CHANGE"
                },
                {
                    "guid": "bef58280-d846-11e8-927f-02b2bf7e617e",
                    "name": "CLAIM_PAYMENT_UPDATE_OUTCOME"
                },
                {
                    "guid": "ac27ee3a-4166-11e8-927f-02b2bf7e617e",
                    "name": "PREMIUM_UPDATE"
                },
                {
                    "guid": "d0da714b-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "DELETE_PRODUCT_PREMIUM"
                },
                {
                    "guid": "4e220f7b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CLAIM_PROCESSING"
                },
                {
                    "guid": "4e3da8f5-7b12-11e9-927f-02b2bf7e617e",
                    "name": "SEARCH_CLAIMS_DASHBOARD"
                },
                {
                    "guid": "8dc8ef74-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_POLICY"
                },
                {
                    "guid": "d0d8da75-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "CREATE_PRODUCT_PREMIUM"
                },
                {
                    "guid": "8dc91c42-094e-11e7-ac59-e94dc1e43147",
                    "name": "CREATE_CLAIM"
                },
                {
                    "guid": "8c91953e-094e-11e7-ac59-e94dc1e43147",
                    "name": "UPDATE_POLICY"
                }
            ],
            "name": "ADMINISTRATOR",
            "code": "ROLE_ADMIN",
            "active": true,
            "createdAt": "2017-03-15T09:11:02.000+0000"
        },
        {
            "guid": "8c916d66-094e-11e7-ac59-e94dc1e43147",
            "privileges": [
                {
                    "guid": "ac7817ca-0d63-11eb-b099-02b2bf7e617e",
                    "name": "VIEW_CUSTOMER_SEARCH"
                },
                {
                    "guid": "8c91836e-094e-11e7-ac59-e94dc1e43147",
                    "name": "READ_POLICY"
                },
                {
                    "guid": "92d8910c-b316-11eb-8fd9-02b2bf7e617e",
                    "name": "VIEW_CASHBACK"
                }
            ],
            "name": "User Account",
            "code": "ROLE_USER",
            "active": true,
            "createdAt": "2017-03-15T09:11:02.000+0000"
        },
        {
            "guid": "8dc851f4-094e-11e7-ac59-e94dc1e43147",
            "privileges": [
                {
                    "guid": "8dc90608-094e-11e7-ac59-e94dc1e43147",
                    "name": "BUY_NEW_POLICY"
                },
                {
                    "guid": "4e4cf95b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIM_CALLBACKS"
                },
                {
                    "guid": "4de6cddd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS"
                },
                {
                    "guid": "ac7817ca-0d63-11eb-b099-02b2bf7e617e",
                    "name": "VIEW_CUSTOMER_SEARCH"
                },
                {
                    "guid": "4d7ed661-7b12-11e9-927f-02b2bf7e617e",
                    "name": "REGISTER_NEW_CUSTOMER"
                },
                {
                    "guid": "4e90eeb1-7b12-11e9-927f-02b2bf7e617e",
                    "name": "LOYALTY_ELIGIBILITY"
                },
                {
                    "guid": "4e05d4c4-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_BUY_NEW_POLICY"
                },
                {
                    "guid": "4eb21abe-7b12-11e9-927f-02b2bf7e617e",
                    "name": "EDIT_CLAIM"
                },
                {
                    "guid": "8dc98b3c-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_CLAIM"
                },
                {
                    "guid": "d298a772-72d2-11e7-af9a-0ab5968da949",
                    "name": "UPDATE_EXISTING_CUSTOMER"
                },
                {
                    "guid": "8c91a312-094e-11e7-ac59-e94dc1e43147",
                    "name": "DELETE_POLICY"
                },
                {
                    "guid": "4e6b49fd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_PAYABLE"
                },
                {
                    "guid": "d294708b-72d2-11e7-af9a-0ab5968da949",
                    "name": "REGISTER_CUSTOMER"
                },
                {
                    "guid": "4e77e954-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_CLOSED"
                },
                {
                    "guid": "4e13bd75-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_MAKE_CLAIM"
                },
                {
                    "guid": "4ea68969-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_EDIT_CUSTOMER"
                },
                {
                    "guid": "4da7ff4b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CUSTOMER_SUMMARY"
                },
                {
                    "guid": "4df69d17-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_DEBIT_ORDER"
                },
                {
                    "guid": "4e5da15c-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_OPEN_CLAIMS"
                },
                {
                    "guid": "d299d2db-72d2-11e7-af9a-0ab5968da949",
                    "name": "UPDATE_CLAIM_CALLBACK"
                },
                {
                    "guid": "0ee03edb-75d1-11e7-af9a-0ab5968da949",
                    "name": "VIEW_CLAIM_CALLBACK"
                },
                {
                    "guid": "4dd7973e-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_PAYMENTS"
                },
                {
                    "guid": "4d967dbd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "MANAGE_EXISTING_CUSTOMER"
                },
                {
                    "guid": "4e2f9756-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_DASHBOARD"
                },
                {
                    "guid": "d2963a68-72d2-11e7-af9a-0ab5968da949",
                    "name": "VIEW_EXISTING_CUSTOMER"
                },
                {
                    "guid": "8c91836e-094e-11e7-ac59-e94dc1e43147",
                    "name": "READ_POLICY"
                },
                {
                    "guid": "92d8910c-b316-11eb-8fd9-02b2bf7e617e",
                    "name": "VIEW_CASHBACK"
                },
                {
                    "guid": "4ea4a734-7b12-11e9-927f-02b2bf7e617e",
                    "name": "EDIT_CUSTOMER"
                },
                {
                    "guid": "4dbe176d-7b12-11e9-927f-02b2bf7e617e",
                    "name": "EDIT_POLICIES"
                },
                {
                    "guid": "4e09092b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CREATE_NEW_POLICY"
                },
                {
                    "guid": "d0a8df6b-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_ALL_PRODUCTS"
                },
                {
                    "guid": "aa25e972-dd37-11ea-b099-02b2bf7e617e",
                    "name": "SEND_SMS_RECOMMENDING_RECURRING_PAYMENT"
                },
                {
                    "guid": "8dc9748a-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_CLAIM"
                },
                {
                    "guid": "8dc8d890-094e-11e7-ac59-e94dc1e43147",
                    "name": "UPDATE_POLICY_CHANGE"
                },
                {
                    "guid": "4db8b2ef-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_POLICIES"
                },
                {
                    "guid": "d296f79a-72d2-11e7-af9a-0ab5968da949",
                    "name": "VIEW_PAYMENT"
                },
                {
                    "guid": "4e220f7b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CLAIM_PROCESSING"
                },
                {
                    "guid": "4e3da8f5-7b12-11e9-927f-02b2bf7e617e",
                    "name": "SEARCH_CLAIMS_DASHBOARD"
                },
                {
                    "guid": "4e51b3a3-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CREATE_CLAIM_CALLBACKS"
                },
                {
                    "guid": "8dc8ef74-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_POLICY"
                },
                {
                    "guid": "8dc91c42-094e-11e7-ac59-e94dc1e43147",
                    "name": "CREATE_CLAIM"
                },
                {
                    "guid": "8c91953e-094e-11e7-ac59-e94dc1e43147",
                    "name": "UPDATE_POLICY"
                },
                {
                    "guid": "4e9a8d9f-7b12-11e9-927f-02b2bf7e617e",
                    "name": "USER_CREDENTIALS"
                }
            ],
            "name": "Call Centre Agent",
            "code": "ROLE_CCA",
            "active": true,
            "createdAt": "2017-03-15T09:11:04.890+0000"
        },
        {
            "guid": "8dc86cd4-094e-11e7-ac59-e94dc1e43147",
            "privileges": [
                {
                    "guid": "8dc90608-094e-11e7-ac59-e94dc1e43147",
                    "name": "BUY_NEW_POLICY"
                },
                {
                    "guid": "4e4cf95b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIM_CALLBACKS"
                },
                {
                    "guid": "4de6cddd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS"
                },
                {
                    "guid": "ac7817ca-0d63-11eb-b099-02b2bf7e617e",
                    "name": "VIEW_CUSTOMER_SEARCH"
                },
                {
                    "guid": "4d7ed661-7b12-11e9-927f-02b2bf7e617e",
                    "name": "REGISTER_NEW_CUSTOMER"
                },
                {
                    "guid": "4e05d4c4-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_BUY_NEW_POLICY"
                },
                {
                    "guid": "4eb21abe-7b12-11e9-927f-02b2bf7e617e",
                    "name": "EDIT_CLAIM"
                },
                {
                    "guid": "8dc98b3c-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_CLAIM"
                },
                {
                    "guid": "d298a772-72d2-11e7-af9a-0ab5968da949",
                    "name": "UPDATE_EXISTING_CUSTOMER"
                },
                {
                    "guid": "8c91a312-094e-11e7-ac59-e94dc1e43147",
                    "name": "DELETE_POLICY"
                },
                {
                    "guid": "4e6b49fd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_PAYABLE"
                },
                {
                    "guid": "d294708b-72d2-11e7-af9a-0ab5968da949",
                    "name": "REGISTER_CUSTOMER"
                },
                {
                    "guid": "4e77e954-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_CLOSED"
                },
                {
                    "guid": "4e13bd75-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_MAKE_CLAIM"
                },
                {
                    "guid": "4ea68969-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_EDIT_CUSTOMER"
                },
                {
                    "guid": "4da7ff4b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CUSTOMER_SUMMARY"
                },
                {
                    "guid": "4df69d17-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_DEBIT_ORDER"
                },
                {
                    "guid": "4e5da15c-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_OPEN_CLAIMS"
                },
                {
                    "guid": "d299d2db-72d2-11e7-af9a-0ab5968da949",
                    "name": "UPDATE_CLAIM_CALLBACK"
                },
                {
                    "guid": "0ee03edb-75d1-11e7-af9a-0ab5968da949",
                    "name": "VIEW_CLAIM_CALLBACK"
                },
                {
                    "guid": "4dd7973e-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_PAYMENTS"
                },
                {
                    "guid": "0ebc42ea-75d1-11e7-af9a-0ab5968da949",
                    "name": "GENERATE_REPORT"
                },
                {
                    "guid": "4d967dbd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "MANAGE_EXISTING_CUSTOMER"
                },
                {
                    "guid": "4e2f9756-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_DASHBOARD"
                },
                {
                    "guid": "d2963a68-72d2-11e7-af9a-0ab5968da949",
                    "name": "VIEW_EXISTING_CUSTOMER"
                },
                {
                    "guid": "8dc9b4f4-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_BUSINESS_REPORT"
                },
                {
                    "guid": "8c91836e-094e-11e7-ac59-e94dc1e43147",
                    "name": "READ_POLICY"
                },
                {
                    "guid": "92d8910c-b316-11eb-8fd9-02b2bf7e617e",
                    "name": "VIEW_CASHBACK"
                },
                {
                    "guid": "8dc9de2a-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_SMS_REPORT"
                },
                {
                    "guid": "4ea4a734-7b12-11e9-927f-02b2bf7e617e",
                    "name": "EDIT_CUSTOMER"
                },
                {
                    "guid": "8dc9a018-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_REPORT_DASHBOARD"
                },
                {
                    "guid": "4dbe176d-7b12-11e9-927f-02b2bf7e617e",
                    "name": "EDIT_POLICIES"
                },
                {
                    "guid": "4e09092b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CREATE_NEW_POLICY"
                },
                {
                    "guid": "aa25e972-dd37-11ea-b099-02b2bf7e617e",
                    "name": "SEND_SMS_RECOMMENDING_RECURRING_PAYMENT"
                },
                {
                    "guid": "8dc9748a-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_CLAIM"
                },
                {
                    "guid": "8dc8d890-094e-11e7-ac59-e94dc1e43147",
                    "name": "UPDATE_POLICY_CHANGE"
                },
                {
                    "guid": "bef58280-d846-11e8-927f-02b2bf7e617e",
                    "name": "CLAIM_PAYMENT_UPDATE_OUTCOME"
                },
                {
                    "guid": "4db8b2ef-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_POLICIES"
                },
                {
                    "guid": "4e220f7b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CLAIM_PROCESSING"
                },
                {
                    "guid": "4e3da8f5-7b12-11e9-927f-02b2bf7e617e",
                    "name": "SEARCH_CLAIMS_DASHBOARD"
                },
                {
                    "guid": "4e51b3a3-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CREATE_CLAIM_CALLBACKS"
                },
                {
                    "guid": "8dc8ef74-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_POLICY"
                },
                {
                    "guid": "8dc91c42-094e-11e7-ac59-e94dc1e43147",
                    "name": "CREATE_CLAIM"
                },
                {
                    "guid": "8c91953e-094e-11e7-ac59-e94dc1e43147",
                    "name": "UPDATE_POLICY"
                }
            ],
            "name": "Call Centre Manager",
            "code": "ROLE_CCM",
            "active": true,
            "createdAt": "2017-03-15T09:11:04.891+0000"
        },
        {
            "guid": "8dc88476-094e-11e7-ac59-e94dc1e43147",
            "privileges": [
                {
                    "guid": "4d967dbd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "MANAGE_EXISTING_CUSTOMER"
                },
                {
                    "guid": "4e4cf95b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIM_CALLBACKS"
                },
                {
                    "guid": "4de6cddd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS"
                },
                {
                    "guid": "4e2f9756-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_DASHBOARD"
                },
                {
                    "guid": "ac7817ca-0d63-11eb-b099-02b2bf7e617e",
                    "name": "VIEW_CUSTOMER_SEARCH"
                },
                {
                    "guid": "d2963a68-72d2-11e7-af9a-0ab5968da949",
                    "name": "VIEW_EXISTING_CUSTOMER"
                },
                {
                    "guid": "8c91836e-094e-11e7-ac59-e94dc1e43147",
                    "name": "READ_POLICY"
                },
                {
                    "guid": "92d8910c-b316-11eb-8fd9-02b2bf7e617e",
                    "name": "VIEW_CASHBACK"
                },
                {
                    "guid": "8dc98b3c-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_CLAIM"
                },
                {
                    "guid": "8dc9748a-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_CLAIM"
                },
                {
                    "guid": "0ee1e05b-75d1-11e7-af9a-0ab5968da949",
                    "name": "UPDATE_CLAIM_ASSESSORS_LEVEL_1"
                },
                {
                    "guid": "4e6b49fd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_PAYABLE"
                },
                {
                    "guid": "4e77e954-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_CLOSED"
                },
                {
                    "guid": "4da7ff4b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CUSTOMER_SUMMARY"
                },
                {
                    "guid": "4df69d17-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_DEBIT_ORDER"
                },
                {
                    "guid": "4e5da15c-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_OPEN_CLAIMS"
                },
                {
                    "guid": "4db8b2ef-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_POLICIES"
                },
                {
                    "guid": "4e220f7b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CLAIM_PROCESSING"
                },
                {
                    "guid": "4e3da8f5-7b12-11e9-927f-02b2bf7e617e",
                    "name": "SEARCH_CLAIMS_DASHBOARD"
                },
                {
                    "guid": "8dc8ef74-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_POLICY"
                },
                {
                    "guid": "0ee03edb-75d1-11e7-af9a-0ab5968da949",
                    "name": "VIEW_CLAIM_CALLBACK"
                },
                {
                    "guid": "4dd7973e-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_PAYMENTS"
                },
                {
                    "guid": "8dc91c42-094e-11e7-ac59-e94dc1e43147",
                    "name": "CREATE_CLAIM"
                }
            ],
            "name": "Claim Assessors (Level 1)",
            "code": "ROLE_CAL1",
            "active": true,
            "createdAt": "2017-03-15T09:11:04.891+0000"
        },
        {
            "guid": "8dc898e4-094e-11e7-ac59-e94dc1e43147",
            "privileges": [
                {
                    "guid": "4e4cf95b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIM_CALLBACKS"
                },
                {
                    "guid": "4de6cddd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS"
                },
                {
                    "guid": "ac7817ca-0d63-11eb-b099-02b2bf7e617e",
                    "name": "VIEW_CUSTOMER_SEARCH"
                },
                {
                    "guid": "4e05d4c4-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_BUY_NEW_POLICY"
                },
                {
                    "guid": "8dc98b3c-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_CLAIM"
                },
                {
                    "guid": "4e6b49fd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_PAYABLE"
                },
                {
                    "guid": "4e77e954-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_CLOSED"
                },
                {
                    "guid": "4e13bd75-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_MAKE_CLAIM"
                },
                {
                    "guid": "4ea68969-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_EDIT_CUSTOMER"
                },
                {
                    "guid": "4da7ff4b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CUSTOMER_SUMMARY"
                },
                {
                    "guid": "4df69d17-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_DEBIT_ORDER"
                },
                {
                    "guid": "4e5da15c-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_OPEN_CLAIMS"
                },
                {
                    "guid": "0ee03edb-75d1-11e7-af9a-0ab5968da949",
                    "name": "VIEW_CLAIM_CALLBACK"
                },
                {
                    "guid": "4dd7973e-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_PAYMENTS"
                },
                {
                    "guid": "0ebc42ea-75d1-11e7-af9a-0ab5968da949",
                    "name": "GENERATE_REPORT"
                },
                {
                    "guid": "8dc9c930-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ACCOUNTING_REPORT"
                },
                {
                    "guid": "4d967dbd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "MANAGE_EXISTING_CUSTOMER"
                },
                {
                    "guid": "8dea8ed5-46bb-11ec-b098-02b2bf7e617e",
                    "name": "DELETE_GROUP"
                },
                {
                    "guid": "4e2f9756-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_DASHBOARD"
                },
                {
                    "guid": "34e81e02-0e31-11eb-b099-02b2bf7e617e",
                    "name": "VIEW_ALL_GROUPS"
                },
                {
                    "guid": "d2963a68-72d2-11e7-af9a-0ab5968da949",
                    "name": "VIEW_EXISTING_CUSTOMER"
                },
                {
                    "guid": "8dc9b4f4-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_BUSINESS_REPORT"
                },
                {
                    "guid": "8c91836e-094e-11e7-ac59-e94dc1e43147",
                    "name": "READ_POLICY"
                },
                {
                    "guid": "92d8910c-b316-11eb-8fd9-02b2bf7e617e",
                    "name": "VIEW_CASHBACK"
                },
                {
                    "guid": "8dc9de2a-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_SMS_REPORT"
                },
                {
                    "guid": "8dc9a018-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_REPORT_DASHBOARD"
                },
                {
                    "guid": "8dc9748a-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_CLAIM"
                },
                {
                    "guid": "bef58280-d846-11e8-927f-02b2bf7e617e",
                    "name": "CLAIM_PAYMENT_UPDATE_OUTCOME"
                },
                {
                    "guid": "4db8b2ef-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_POLICIES"
                },
                {
                    "guid": "4e220f7b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CLAIM_PROCESSING"
                },
                {
                    "guid": "4e3da8f5-7b12-11e9-927f-02b2bf7e617e",
                    "name": "SEARCH_CLAIMS_DASHBOARD"
                },
                {
                    "guid": "8dc8ef74-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_POLICY"
                },
                {
                    "guid": "48681090-762b-11e7-af9a-0ab5968da949",
                    "name": "UPDATE_CLAIM"
                }
            ],
            "name": "InsureCo Mgmt",
            "code": "ROLE_ICM",
            "active": true,
            "createdAt": "2017-03-15T09:11:04.892+0000"
        },
        {
            "guid": "8dc8adc0-094e-11e7-ac59-e94dc1e43147",
            "privileges": [
                {
                    "guid": "4e4cf95b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIM_CALLBACKS"
                },
                {
                    "guid": "4de6cddd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS"
                },
                {
                    "guid": "ac7817ca-0d63-11eb-b099-02b2bf7e617e",
                    "name": "VIEW_CUSTOMER_SEARCH"
                },
                {
                    "guid": "8dc98b3c-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_CLAIM"
                },
                {
                    "guid": "4e6b49fd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_PAYABLE"
                },
                {
                    "guid": "4e77e954-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_CLOSED"
                },
                {
                    "guid": "4da7ff4b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CUSTOMER_SUMMARY"
                },
                {
                    "guid": "4df69d17-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_DEBIT_ORDER"
                },
                {
                    "guid": "4e5da15c-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_OPEN_CLAIMS"
                },
                {
                    "guid": "0ee03edb-75d1-11e7-af9a-0ab5968da949",
                    "name": "VIEW_CLAIM_CALLBACK"
                },
                {
                    "guid": "4dd7973e-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_PAYMENTS"
                },
                {
                    "guid": "0ebc42ea-75d1-11e7-af9a-0ab5968da949",
                    "name": "GENERATE_REPORT"
                },
                {
                    "guid": "8dc9c930-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ACCOUNTING_REPORT"
                },
                {
                    "guid": "4d967dbd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "MANAGE_EXISTING_CUSTOMER"
                },
                {
                    "guid": "4e2f9756-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_CLAIMS_DASHBOARD"
                },
                {
                    "guid": "d2963a68-72d2-11e7-af9a-0ab5968da949",
                    "name": "VIEW_EXISTING_CUSTOMER"
                },
                {
                    "guid": "8dc9b4f4-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_BUSINESS_REPORT"
                },
                {
                    "guid": "8c91836e-094e-11e7-ac59-e94dc1e43147",
                    "name": "READ_POLICY"
                },
                {
                    "guid": "92d8910c-b316-11eb-8fd9-02b2bf7e617e",
                    "name": "VIEW_CASHBACK"
                },
                {
                    "guid": "8dc9de2a-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_SMS_REPORT"
                },
                {
                    "guid": "8dc9a018-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_REPORT_DASHBOARD"
                },
                {
                    "guid": "8dc9748a-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_CLAIM"
                },
                {
                    "guid": "4db8b2ef-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_POLICIES"
                },
                {
                    "guid": "4e220f7b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CLAIM_PROCESSING"
                },
                {
                    "guid": "4e3da8f5-7b12-11e9-927f-02b2bf7e617e",
                    "name": "SEARCH_CLAIMS_DASHBOARD"
                },
                {
                    "guid": "8dc8ef74-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_POLICY"
                }
            ],
            "name": "MNO Mgmt",
            "code": "ROLE_MM",
            "active": true,
            "createdAt": "2017-03-15T09:11:04.892+0000"
        },
        {
            "guid": "8dc8c4ae-094e-11e7-ac59-e94dc1e43147",
            "privileges": [
                {
                    "guid": "d0aa7bf6-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "READ_PRODUCT"
                },
                {
                    "guid": "8dc90608-094e-11e7-ac59-e94dc1e43147",
                    "name": "BUY_NEW_POLICY"
                },
                {
                    "guid": "8dc98b3c-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_CLAIM"
                },
                {
                    "guid": "d298a772-72d2-11e7-af9a-0ab5968da949",
                    "name": "UPDATE_EXISTING_CUSTOMER"
                },
                {
                    "guid": "9e64b3f9-beb4-11e9-927f-02b2bf7e617e",
                    "name": "DELETE_COUNTRY"
                },
                {
                    "guid": "8c91a312-094e-11e7-ac59-e94dc1e43147",
                    "name": "DELETE_POLICY"
                },
                {
                    "guid": "d294708b-72d2-11e7-af9a-0ab5968da949",
                    "name": "REGISTER_CUSTOMER"
                },
                {
                    "guid": "b4c5b962-f305-11e7-88cc-023b8a6e1e1a",
                    "name": "CREATE_DEBIT"
                },
                {
                    "guid": "d299d2db-72d2-11e7-af9a-0ab5968da949",
                    "name": "UPDATE_CLAIM_CALLBACK"
                },
                {
                    "guid": "0ee03edb-75d1-11e7-af9a-0ab5968da949",
                    "name": "VIEW_CLAIM_CALLBACK"
                },
                {
                    "guid": "8dc9c930-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ACCOUNTING_REPORT"
                },
                {
                    "guid": "d0dbf51d-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "UPDATE_PRODUCT_PREMIUM"
                },
                {
                    "guid": "8dea8ed5-46bb-11ec-b098-02b2bf7e617e",
                    "name": "DELETE_GROUP"
                },
                {
                    "guid": "34e81e02-0e31-11eb-b099-02b2bf7e617e",
                    "name": "VIEW_ALL_GROUPS"
                },
                {
                    "guid": "8dc9b4f4-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_BUSINESS_REPORT"
                },
                {
                    "guid": "8c91836e-094e-11e7-ac59-e94dc1e43147",
                    "name": "READ_POLICY"
                },
                {
                    "guid": "8dc9de2a-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_SMS_REPORT"
                },
                {
                    "guid": "d0dedaef-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_ALL_PRODUCT_PREMIUMS"
                },
                {
                    "guid": "6674b853-222a-11ea-b8a4-02b2bf7e617e",
                    "name": "UPDATE_SMS_TEMPLATE"
                },
                {
                    "guid": "8dc9a018-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_REPORT_DASHBOARD"
                },
                {
                    "guid": "d0a8df6b-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_ALL_PRODUCTS"
                },
                {
                    "guid": "8dc9748a-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_CLAIM"
                },
                {
                    "guid": "d0ac1f3a-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "DELETE_PRODUCT"
                },
                {
                    "guid": "667785cd-222a-11ea-b8a4-02b2bf7e617e",
                    "name": "VIEW_ALL_SMS_TEMPLATES"
                },
                {
                    "guid": "1e56fc5c-b398-11e9-927f-02b2bf7e617e",
                    "name": "REVERT_EXPIRED_CLAIM"
                },
                {
                    "guid": "9e45a97e-beb4-11e9-927f-02b2bf7e617e",
                    "name": "READ_PARTNER"
                },
                {
                    "guid": "9e472f59-beb4-11e9-927f-02b2bf7e617e",
                    "name": "DELETE_PARTNER"
                },
                {
                    "guid": "667337de-222a-11ea-b8a4-02b2bf7e617e",
                    "name": "DELETE_SMS_TEMPLATE"
                },
                {
                    "guid": "48681090-762b-11e7-af9a-0ab5968da949",
                    "name": "UPDATE_CLAIM"
                },
                {
                    "guid": "666cf959-222a-11ea-b8a4-02b2bf7e617e",
                    "name": "CREATE_SMS_TEMPLATE"
                },
                {
                    "guid": "a7c90c84-bf67-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_ALL_LANGUAGES"
                },
                {
                    "guid": "b4b8d2ac-f305-11e7-88cc-023b8a6e1e1a",
                    "name": "PREMIUM_REVERSAL"
                },
                {
                    "guid": "ac7817ca-0d63-11eb-b099-02b2bf7e617e",
                    "name": "VIEW_CUSTOMER_SEARCH"
                },
                {
                    "guid": "7c0b06a5-ba75-11e8-927f-02b2bf7e617e",
                    "name": "INTERNAL_DASHBOARD"
                },
                {
                    "guid": "9e652278-beb4-11e9-927f-02b2bf7e617e",
                    "name": "UPDATE_COUNTRY"
                },
                {
                    "guid": "d0dd7d51-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "READ_PRODUCT_PREMIUM"
                },
                {
                    "guid": "66761b1e-222a-11ea-b8a4-02b2bf7e617e",
                    "name": "READ_SMS_TEMPLATE"
                },
                {
                    "guid": "e6011a14-a525-11e8-927f-02b2bf7e617e",
                    "name": "BULK_SMS_BLAST"
                },
                {
                    "guid": "0ebc42ea-75d1-11e7-af9a-0ab5968da949",
                    "name": "GENERATE_REPORT"
                },
                {
                    "guid": "9e63d03a-beb4-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_COUNTRIES"
                },
                {
                    "guid": "4d967dbd-7b12-11e9-927f-02b2bf7e617e",
                    "name": "MANAGE_EXISTING_CUSTOMER"
                },
                {
                    "guid": "9e64469b-beb4-11e9-927f-02b2bf7e617e",
                    "name": "CREATE_COUNTRY"
                },
                {
                    "guid": "b4c73129-f305-11e7-88cc-023b8a6e1e1a",
                    "name": "DELETE_DEBIT"
                },
                {
                    "guid": "d2963a68-72d2-11e7-af9a-0ab5968da949",
                    "name": "VIEW_EXISTING_CUSTOMER"
                },
                {
                    "guid": "9e4a2c89-beb4-11e9-927f-02b2bf7e617e",
                    "name": "UPDATE_PARTNER"
                },
                {
                    "guid": "92d8910c-b316-11eb-8fd9-02b2bf7e617e",
                    "name": "VIEW_CASHBACK"
                },
                {
                    "guid": "d0af02cd-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "UPDATE_PRODUCT"
                },
                {
                    "guid": "9e488e36-beb4-11e9-927f-02b2bf7e617e",
                    "name": "CREATE_PARTNER"
                },
                {
                    "guid": "d0ad9a6c-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "CREATE_PRODUCT"
                },
                {
                    "guid": "9e4427b1-beb4-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_ALL_PARTNERS"
                },
                {
                    "guid": "8dc8d890-094e-11e7-ac59-e94dc1e43147",
                    "name": "UPDATE_POLICY_CHANGE"
                },
                {
                    "guid": "d0da714b-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "DELETE_PRODUCT_PREMIUM"
                },
                {
                    "guid": "8dc8ef74-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_ALL_POLICY"
                },
                {
                    "guid": "d0d8da75-c9d2-11e9-927f-02b2bf7e617e",
                    "name": "CREATE_PRODUCT_PREMIUM"
                },
                {
                    "guid": "8dc91c42-094e-11e7-ac59-e94dc1e43147",
                    "name": "CREATE_CLAIM"
                },
                {
                    "guid": "8c91953e-094e-11e7-ac59-e94dc1e43147",
                    "name": "UPDATE_POLICY"
                }
            ],
            "name": "Inclusivity Mgmt",
            "code": "ROLE_IM",
            "active": true,
            "createdAt": "2017-03-15T09:11:04.893+0000"
        },
        {
            "guid": "ac60eb0b-0d63-11eb-b099-02b2bf7e617e",
            "privileges": [
                {
                    "guid": "4e13bd75-7b12-11e9-927f-02b2bf7e617e",
                    "name": "VIEW_MAKE_CLAIM"
                },
                {
                    "guid": "d2963a68-72d2-11e7-af9a-0ab5968da949",
                    "name": "VIEW_EXISTING_CUSTOMER"
                },
                {
                    "guid": "92d8910c-b316-11eb-8fd9-02b2bf7e617e",
                    "name": "VIEW_CASHBACK"
                },
                {
                    "guid": "4e220f7b-7b12-11e9-927f-02b2bf7e617e",
                    "name": "CLAIM_PROCESSING"
                },
                {
                    "guid": "8dc98b3c-094e-11e7-ac59-e94dc1e43147",
                    "name": "VIEW_CLAIM"
                },
                {
                    "guid": "4f5bfafb-ffa5-11e9-b8a4-02b2bf7e617e",
                    "name": "IGNORE_MAX_DAYS_ALLOWED_AFTER_DISCHARGE_OR_DEATH"
                },
                {
                    "guid": "8dc91c42-094e-11e7-ac59-e94dc1e43147",
                    "name": "CREATE_CLAIM"
                }
            ],
            "name": "Group Agent",
            "code": "ROLE_GROUP_AGENT",
            "active": true,
            "createdAt": "2020-10-13T14:52:10.000+0000"
        }
    ]
