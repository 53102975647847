import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProductsHeader from "../../../NewProductList/components/ProductsHeader/ProductsHeader";

export default function ViewPartner(props) {
  if (props.isLoading) {
    return "";
  }

  const [expandedPartner, setExpandedPartner] = React.useState(true)

  if (!props.partner || (props.partnerNotFoundError && !props.isLoading)) {
    return (
      <Alert severity="error">
        Could not find partner, Please check your partner guid!
      </Alert>
    );
  }

  const partner = props.partner;

  const getDefaultLanguageName = (partner) => {
    const defaultLanguageCode = partner.default_language;

    for (let language of partner.languages) {
      if (defaultLanguageCode === language.code) return language.name;
    }
    return defaultLanguageCode;
  };

  return (
    <div style={{ marginTop: "3%" }}>

      <>
        {/* Partner */}
        <Accordion expanded={expandedPartner}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon onClick={()=>{setExpandedPartner(!expandedPartner)}}/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <ProductsHeader
              title={"Partner details: " + partner.name}
              showEditButton={true}
              onEditButtonClick={props.openUpdatePartnerDialog}
            />
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper} style={{ marginTop: "2%" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table-cell-header">
                      Property
                    </TableCell>
                    <TableCell align="left" className="table-cell-header">
                      Value
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Name</strong>
                    </TableCell>
                    <TableCell align="left">{partner.name}</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Partner Guid</strong>
                    </TableCell>
                    <TableCell align="left">{partner.guid}</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Partner Code</strong>
                    </TableCell>
                    <TableCell align="left">{partner.code}</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Country</strong>
                    </TableCell>
                    <TableCell align="left">{partner.country.name}</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>default language</strong>
                    </TableCell>
                    <TableCell align="left">
                      {getDefaultLanguageName(partner)}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>call centre number</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partner.call_centre_number}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>whatsapp number</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partner.whats_app_number}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>ussd shortcode</strong>
                    </TableCell>
                    <TableCell align="left">{partner.ussd_shortcode}</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>service_name</strong>
                    </TableCell>
                    <TableCell align="left">{partner.service_name}</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>insurer</strong>
                    </TableCell>
                    <TableCell align="left">{partner.insurer}</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Active</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partner.active ? "true" : "false"}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Creaated At</strong>
                    </TableCell>
                    <TableCell align="left">{partner.created_at}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
        <br />
        <br />
      </>

    </div>
  );
}
