import React, { useState, useEffect } from 'react'
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@material-ui/core/Grid";
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';


import { useStyle } from "./Style";
import { Button, CircularProgress } from '@mui/material';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { getLocalNumberLength, getPartner } from '../../../../../lib/access.es6';
import { reloadPage } from '../../../../../lib/utils.es6';



const EditUser = (props) => {
  const classes = useStyle();


  const [firstname, setFirstname] = useState(props.customerData.first_name);
  const [middlename, setmiddlename] = useState('');
  const [surname, setsurname] = useState(props.customerData.surname);
  const [dateofbirth, setdateofbirth] = useState(props.customerData.date_of_birth);
  const [phonenumber, setphonenumber] = useState(props.customerData.msisdn);
  const [nationalid, setnationalid] = useState(props.customerData.national_id);
  const [gender, setGender] = useState(props.customerData.gender);
  const [emailAddress, setEmailAddress] = useState(props.customerData.email);
  const [physicalAddress, setPhysicalAddress] = useState(props.customerData.address);
  const [beneficiaryName, setBeneficiaryName] =  useState(props.customerData.beneficiary_name)
  const [beneficiaryNumber, setBeneficiaryNumber] = useState(props.customerData.beneficiary_msisdn)

  //validations
  useEffect(() => {

    ValidatorForm.addValidationRule('isValidPhoneNumber', value => {
      return value.length === getLocalNumberLength();
    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidPhoneNumber');
    };
  }, [])

  
  //country calling code

  const internationalCallingCode = getPartner().country.international_calling_code

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'firstname') {
      setFirstname(value)
    }
    if (name === 'middlename') {
      setmiddlename(value)
    }
    if (name === 'surname') {
      setsurname(value)
    }
    if (name === 'dateofbirth') {
      setdateofbirth(value)
    }
    if (name === 'phonenumber') {
      setphonenumber(value)
    }
    if (name === 'nationalid') {
      setnationalid(value)
    }
    if (name === 'gender') {
      setGender(value)
    }

    if (name === 'emailAddress') {
      setEmailAddress(value)
    }
    if (name === 'physicalAddress') {
      setPhysicalAddress(value)
    }
    if(name === 'beneficiaryName'){
      setBeneficiaryName(value)
    }
    if(name === 'beneficiaryNumber'){
      setBeneficiaryNumber(value)
    }


  };
  const reset = () => {
    setFirstname('');
    setmiddlename('');
    setsurname('');
    setdateofbirth('');
    setphonenumber('');
    setnationalid('');
    setGender('');
    setEmailAddress('');
    setPhysicalAddress('');
    setBeneficiaryName('')
  }
  const registerCustomer = () => {
    const payload = {
      "guid": props.customerData.guid,
      "msisdn": phonenumber,
      "first_name": firstname,
      "middlename": middlename,
      "surname": surname,
      "national_id": nationalid,
      "date_of_birth": dateofbirth,
      "gender": gender,
      "email": emailAddress,
      "address": physicalAddress,
      "beneficiary_name": beneficiaryName,
      "beneficiary_msisdn": beneficiaryNumber,
    }
    //console.log("payload", payload)
    props.editCustomer(payload);
    reset()
  }
  const resetAfterSucesfulyEdit = () => {
    reloadPage()
  }
  const resetAfterFailedEdit = () => {
    reloadPage()
  }
  return (
    <>
      <Dialog open={props.openEditCustomerDialog} onClose={props.toggleEditCustomerDialog} fullWidth
        maxWidth="md">
        <ValidatorForm onSubmit={() => registerCustomer()} >
          <DialogTitle className={classes.registermodaltitle}>
            <div >
              <h3> {props.labels.editCustomerDetailsHeading}</h3>
            </div>
          </DialogTitle>
          <DialogContent className={classes.registerinputwrapper}>
            <DialogContentText>
              {props.labels.editCustomerInformation}
            </DialogContentText>
            <Grid container spacing={2} >
              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  name='firstname'
                  id="First-Name"
                  label="First Name"
                  value={firstname}
                  onChange={handleChange}
                  fullWidth
                  className={classes.registerinputs}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please enter first name']}
                />

              </Grid>
              <Grid item xs={12} sm={4} md={4}>

                <TextValidator
                  name='middlename'
                  id="Middle-Name"
                  label="Middle Name"
                  value={middlename}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  name='surname'
                  id="Surname"
                  label="Surname"
                  value={surname}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please enter surname name']}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  name='nationalid'
                  id="National-iD"
                  label="National ID"
                  value={nationalid}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please enter a valid national id']}

                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  name='dateofbirth'
                  id="Date-of-birth"
                  label="Date of birth"
                  value={dateofbirth}
                  onChange={handleChange}
                  fullWidth
                  type='date'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please enter date of birth']}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <SelectValidator
                  name='gender'
                  labelId="select-gender"
                  id="select-gender"
                  value={gender}
                  label="Gender"
                  onChange={handleChange}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please select gender']}
                  style={{ width: "100%" }}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>

                </SelectValidator>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  name='phonenumber'
                  id="Phone"
                  label="Customer number"
                  type="text"
                  value={phonenumber}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"

                />
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  name='emailAddress'
                  id="emailAddress"
                  label="Email Address"
                  type='email'
                  value={emailAddress}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"

                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  name='physicalAddress'
                  id="physicalAddress"
                  label="Physical Address"
                  value={physicalAddress}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"

                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextValidator
                  name='beneficiaryName'
                  id="beneficiaryName"
                  label="Beneficiary Name"
                  value={beneficiaryName}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"

                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextValidator
                  name='beneficiaryNumber'
                  id="beneficiaryNumber"
                  label="Beneficiary Number"
                  value={beneficiaryNumber}
                  onChange={handleChange}
                  fullWidth
                  type='number'
                  InputProps={{
                    startAdornment: <InputAdornment
                      sx={{
                        paddingTop: "32px",
                      }}
                      position="start"
                    >{internationalCallingCode ? internationalCallingCode : ''}</InputAdornment>,
                    style: {
                      background: "#F7F7F7"
                    }
                  }}

                  variant="outlined"
                  validators={['isValidPhoneNumber']}
                  errorMessages={[`Please enter a valid ${getLocalNumberLength()} digit phone number`]}

                />
              </Grid>

            </Grid>

          </DialogContent>
          <DialogActions className={classes.registerbtns} >
            <Button variant="contained" type='submit'>{props.labels.updateCustomer}</Button>
            <Button variant="outlined" onClick={props.toggleEditCustomerDialog}>{props.labels.cancel}</Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>

      <AlertDialog
        custom
        show={props.loader}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>updating customer</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={!!props.customerEditedSuccessfully}
        size="sm"
        title={'Customer details updated'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { resetAfterSucesfulyEdit() }}
        confirmBtnText={'ok'}
        showCancel={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>


      <AlertDialog
        show={!!props.customerEditedFailed}
        danger
        title={'Error updating customer details'}
        onConfirm={() => { resetAfterFailedEdit() }}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.errors ? props.errors.message : ''}
      </AlertDialog>
    </>
  )
}

export default EditUser