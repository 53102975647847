import * as types from '../../../../lib/actionTypes.es6'

export const initialState = {

  lous: [],
  getLousLoader: false,
  getLousError: null,

  errors: null,
  loader: false,

  createdLou: null,
  createLouSuccess: false,
  createLouLoader: false,
  createLouError: null,


  editLouErrors: null,
  editLouInfo: null,
  editLouSuccess: false,
  louToEdit: null,

  createdHospital: null,
  createHospitalSuccess: false,
  createHospitalLoader: false,
  createHospitalError: null,

  deleteHospitalSuccess: false,
  deleteHospitalLoader: false,
  deleteHospitalError: null,
  hardDeleteHospitalSuccess: false,
  hardDeleteHospitalLoader: false,
  HospitalToDelete: null,

  hospitals: [],
  getHospitalsLoader: false,
  getHospitalsError: null,

  searchedHospitals: [],
  searchedHospitalsLoader: false,
  searchedHospitalsError: null,


  createdDiagnosis: null,
  createDiagnosisSuccess: false,
  createDiagnosisLoader: false,
  createDiagnosisError: null,


  deleteDiagnosisSuccess: false,
  deleteDiagnosisLoader: false,
  deleteDiagnosisError: null,
  hardDeleteDiagnosisSuccess: false,
  hardDeleteDiagnosisLoader: false,
  DiagnosisToDelete: null,


  diagnosis: [],
  getDiagnosisLoader: false,
  getDiagnosisError: null,

  searchedDiagnosis: [],
  searchedDiagnosisLoader: false,
  searchedDiagnosisError: null,


  deleteLouSuccess: false,
  deleteLouLoader: false,
  deleteLouError: null,
  louToDelete: null,


};

export default function LouReducer(state = initialState, action) {
  switch (action.type) {
    case types.DELETE_LOU_REQUEST:
      return {
        ...state,
        deleteLouError: null,
        deleteLouLoader: true,
        deleteLouSuccess: false,
        louToDelete: action.payload
      };

    case types.DELETE_LOU_SUCCESS:
      return {
        ...state,
        deleteLouSuccess: true,
        deleteLouLoader: false,
        deleteLouError: null,
      };

    case types.DELETE_LOU_FAILURE:
      return { ...state, deleteLouLoader: false, deleteLouError: action.payload };

    case types.RESET_DELETE_LOU_SUCCESS:
      return { ...state, deleteLouSuccess: false };

    case types.RESET_DELETE_LOU_ERROR:
      return { ...state, deleteLouError: null };

    case types.SET_LOU_TO_DELETE:
      return { ...state, louToDelete: action.payload };

    case types.RESET_LOU_TO_DELETE:
      return { ...state, louToDelete: null };

    case types.GET_LOUS_REQUEST:
      return {
        ...state,
        getLousError: null,
        getLousLoader: true,
      };

    case types.GET_LOUS_SUCCESS:
      return {
        ...state,
        lous: action.payload,
        getLousLoader: false,
        getLousError: null,
      };

    case types.GET_LOUS_FAILURE:
      return { ...state, getLousLoader: false, getLousError: action.payload };



    case types.CREATE_LOU_REQUEST:
      return {
        ...state,
        createLouError: null,
        createLouLoader: true,
        createLouSuccess: false,
        createdLou: null
      };

    case types.CREATE_LOU_SUCCESS:
      return {
        ...state,
        createdLou: action.payload,
        createLouSuccess: true,
        createLouLoader: false,
        createLouError: null,
      };

    case types.CREATE_LOU_FAILURE:
      return { ...state, createLouLoader: false, createLouError: action.payload };


    case types.RESET_CREATE_LOU_SUCCESS:
      return { ...state, createLouSuccess: false };
    case types.RESET_CREATE_LOU_FAILURE:
      return { ...state, createLouError: null };

    case types.EDIT_LOU_REQUEST:
      return {
        ...state,
        loader: true,
        editLouErrors: null,
        editLouInfo: null,
        editLouSuccess: false,
        louToEdit: action.payload
      };

    case types.EDIT_LOU_SUCCESS:
      return {
        ...state,
        editLouInfo: action.payload,
        loader: false,
        editLouSuccess: true,
      };

    case types.EDIT_LOU_FAILURE:
      return {
        ...state,
        loader: false,
        editLouErrors: action.payload,
      };

    case types.RESET_EDIT_LOU:
      return { ...state, louToEdit: null };

    case types.RESET_EDIT_LOU_ERROR:
      return { ...state, louToEdit: null };

    case types.RESET_EDIT_LOU_SUCCESS:
      return { ...state, editLouSuccess: false, louToEdit: null };

    case types.SET_LOU_TO_EDIT:
      return { ...state, louToEdit: action.payload };

    case types.CREATE_HOSPITAL_REQUEST:
      return {
        ...state,
        createHospitalError: null,
        createHospitalLoader: true,
        createHospitalSuccess: false,
        createdHospital: null
      };

    case types.CREATE_HOSPITAL_SUCCESS:
      return {
        ...state,
        createdHospital: action.payload,
        createHospitalSuccess: true,
        createHospitalLoader: false,
        createHospitalError: null,
      };

    case types.CREATE_HOSPITAL_FAILURE:
      return { ...state, createHospitalLoader: false, createHospitalError: action.payload };

    case types.RESET_CREATE_HOSPITAL_SUCCESS:
      return { ...state, createHospitalSuccess: false };

    case types.RESET_CREATE_HOSPITAL_ERROR:
      return { ...state, createHospitalError: null };

    case types.DELETE_HOSPITAL_REQUEST:
      return {
        ...state,
        deleteHospitalError: null,
        deleteHospitalLoader: true,
        deleteHospitalSuccess: false,
        hardDeleteHospitalLoader: true,
        HospitalToDelete: action.payload
      };

    case types.DELETE_HOSPITAL_SUCCESS:
      return {
        ...state,
        deleteHospitalSuccess: true,
        deleteHospitalLoader: false,
        deleteHospitalError: null,
        hardDeleteHospitalSuccess: true,
      };

    case types.DELETE_HOSPITAL_FAILURE:
      return { ...state, deleteHospitalLoader: false, deleteHospitalError: action.payload, hardDeleteHospitalLoader: false };

    case types.RESET_DELETE_HOSPITAL_SUCCESS:
      return { ...state, deleteHospitalSuccess: false, hardDeleteHospitalSuccess: false, hardDeleteHospitalLoader: false };

    case types.RESET_DELETE_HOSPITAL_ERROR:
      return { ...state, deleteHospitalError: null };

    case types.SET_HOSPITAL_TO_DELETE:
      return { ...state, HospitalToDelete: action.payload };

    case types.RESET_HOSPITAL_TO_DELETE:
      return { ...state, HospitalToDelete: null };


    case types.GET_HOSPITALS_REQUEST:
      return {
        ...state,
        getHospitalsError: null,
        getHospitalsLoader: true,
      };

    case types.GET_HOSPITALS_SUCCESS:
      return {
        ...state,
        hospitals: action.payload,
        getHospitalsLoader: false,
        getHospitalsError: null,
      };

    case types.GET_HOSPITALS_FAILURE:
      return { ...state, getHospitalsLoader: false, getHospitalsError: action.payload };


    case types.SEARCH_HOSPITALS_REQUEST:
      return {
        ...state,
        searchedHospitalsError: null,
        searchedHospitalsLoader: true,
      };

    case types.SEARCH_HOSPITALS_SUCCESS:
      return {
        ...state,
        searchedHospitals: action.payload,
        searchedHospitalsLoader: false,
        searchedHospitalsError: null,
      };

    case types.SEARCH_HOSPITALS_FAILURE:
      return { ...state, searchedHospitalsLoader: false, searchedHospitalsError: action.payload };


    case types.CREATE_DIAGNOSIS_REQUEST:
      return {
        ...state,
        createDiagnosisError: null,
        createDiagnosisLoader: true,
        createDiagnosisSuccess: false,
        createdDiagnosis: null
      };

    case types.CREATE_DIAGNOSIS_SUCCESS:
      return {
        ...state,
        createdDiagnosis: action.payload,
        createDiagnosisSuccess: true,
        createDiagnosisLoader: false,
        createDiagnosisError: null,
      };

    case types.CREATE_DIAGNOSIS_FAILURE:
      return { ...state, createDiagnosisLoader: false, createDiagnosisError: action.payload };

    case types.RESET_CREATE_DIAGNOSIS_SUCCESS:
      return { ...state, createDiagnosisSuccess: false };

    case types.RESET_CREATE_DIAGNOSIS_ERROR:
      return { ...state, createDiagnosisError: null };


    case types.DELETE_DIAGNOSIS_REQUEST:
      return {
        ...state,
        deleteDiagnosisError: null,
        deleteDiagnosisLoader: true,
        deleteDiagnosisSuccess: false,
        DiagnosisToDelete: action.payload,
        hardDeleteDiagnosisLoader: true,
      };

    case types.DELETE_DIAGNOSIS_SUCCESS:
      return {
        ...state,
        deleteDiagnosisSuccess: true,
        deleteDiagnosisLoader: false,
        deleteDiagnosisError: null,
        hardDeleteDiagnosisSuccess: true,
      };

    case types.DELETE_DIAGNOSIS_FAILURE:
      return { ...state, deleteDiagnosisLoader: false, deleteDiagnosisError: action.payload, hardDeleteDiagnosisLoader: false };

    case types.RESET_DELETE_DIAGNOSIS_SUCCESS:
      return { ...state, deleteDiagnosisSuccess: false, hardDeleteDiagnosisSuccess: false, hardDeleteDiagnosisLoader: false };

    case types.RESET_DELETE_DIAGNOSIS_ERROR:
      return { ...state, deleteDiagnosisError: null };

    case types.SET_DIAGNOSIS_TO_DELETE:
      return { ...state, DiagnosisToDelete: action.payload };

    case types.RESET_DIAGNOSIS_TO_DELETE:
      return { ...state, DiagnosisToDelete: null };

    case types.GET_DIAGNOSIS_REQUEST:
      return {
        ...state,
        getDiagnosisError: null,
        getDiagnosisLoader: true,
      };

    case types.GET_DIAGNOSIS_SUCCESS:
      return {
        ...state,
        diagnosis: action.payload,
        getDiagnosisLoader: false,
        getDiagnosisError: null,
      };

    case types.GET_DIAGNOSIS_FAILURE:
      return { ...state, getDiagnosisLoader: false, getDiagnosisError: action.payload };

    case types.SEARCH_DIAGNOSIS_REQUEST:
      return {
        ...state,
        searchedDiagnosisError: null,
        searchedDiagnosisLoader: true,
      };

    case types.SEARCH_DIAGNOSIS_SUCCESS:
      return {
        ...state,
        searchedDiagnosis: action.payload,
        searchedDiagnosisLoader: false,
        searchedDiagnosisError: null,
      };

    case types.SEARCH_DIAGNOSIS_FAILURE:
      return { ...state, searchedDiagnosisLoader: false, searchedDiagnosisError: action.payload };

    case types.SET_LOU_TO_VIEW:
      return { ...state, louToView: action.payload };

    case types.RESET_LOU_TO_VIEW:
      return { ...state, louToView: null };


    default:
      return state;
  }
}
