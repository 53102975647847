import React from 'react'
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Grid } from '@mui/material';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useStyle } from "./Style";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Button from '@mui/material/Button';
import SkeletonWrapper from '../../../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';

const ITEM_HEIGHT = 48;

const DisplayDocuments = (props) => {
  if (props.loader) {
    return <SkeletonWrapper loading={props.loader} />
  }

  const classes = useStyle();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedDocAction, setSelectedDocAction] = React.useState(null)

  const open = Boolean(anchorEl);

  const [expanded, setExpanded] = React.useState(false)

  const dataForDisplay = expanded ? props.documents : props.documents.slice(0, 2)


  const handleClick = (event, document) => {
    setAnchorEl(event.currentTarget);
    setSelectedDocAction(document);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (dataForDisplay.length > 0) {
    return (
      <>
        {
          dataForDisplay.map((document, index) => (
            <Grid container className={classes.policyWrapper} key={index}>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container className={classes.policyHeader}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Grid container>
                      <Grid item xs={12} md={2} sm={2} className={classes.policyIconWrapper}   >
                        <Box className={classes.policyIcons}>
                          <InsertDriveFileOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={10} sm={10} className={classes.policyHeaderContent}>
                        <h6>{document.type || "N/A"}</h6>
                        <p> {props.labels.FileName}</p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} >

                    <Grid container>
                      <Grid item xs={12} md={6} sm={6}>
                        <div >

                        </div>
                      </Grid>
                      <Grid item xs={12} md={6} sm={6} className={classes.policyActiveStatusWrapper}>
                        <Box display="flex" justifyContent="flex-end">
                          <ul>
                            <li>
                              <Tooltip title={props.labels.viewDocument}>
                                <span style={{ cursor: "pointer" }} ><a href={document.url} target='_blank' rel="noopener noreferrer" ><VisibilityIcon sx={{ color: " #4B7E34" }} /></a> </span>
                              </Tooltip>
                            </li>
                            <li className={classes.actionbtnpolicy}>
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? 'long-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={(event) => handleClick(event, document)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              {
                                selectedDocAction && selectedDocAction.guid === document.guid && <Menu
                                  id="long-menu"
                                  MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                  }}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  PaperProps={{
                                    style: {
                                      maxHeight: ITEM_HEIGHT * 4.5,
                                      width: '20ch',
                                    },
                                  }}
                                >
                                  <MenuItem
                                    onClick={() => { setAnchorEl(null); props.setDocumentToDelete(document) }}
                                    style={{
                                      fontFamily: "Open Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      lineHeight: "15px",
                                      color: "#000000",
                                    }}>
                                    {props.labels.DeleteDocument}
                                  </MenuItem>


                                </Menu>
                              }

                            </li>
                          </ul>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))
        }

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Button style={{
              background: "#FBA92D",
              borderRadius: "8px",
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "15px",
              boxShadow: "none",
              padding: "10px",
              marginTop: "20px",
              textTransform: "capitalize",
            }} variant="contained" size="large" endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} onClick={() => setExpanded(!expanded)}>
              {expanded ? `${props.labels.showMoreDocuments}` : `${props.labels.showLessDocuments}`}
            </Button>

          </Grid>
        </Grid>

      </>
    )
  } else {
    return (<>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <h1 style={{
            color: "#000000",
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            textAlign: "center",
            marginTop: "100px",
          }}> {props.labels.noDocsFound}</h1>

        </Grid>
      </Grid>

    </>)
  }
}

export default DisplayDocuments