import React from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { localisedText } from '../../lib/localisation.es6';
import find from 'lodash/find';
import {Autocomplete, Button, CircularProgress, FormControl, InputAdornment, InputLabel, Select} from '@mui/material';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import Grid from "@mui/material/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {DatePicker} from "@material-ui/pickers";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { formatDate } from '../../lib/utils.es6';
import {
  getInternationalCallingCode,
  getLocalNumberLength,
  getPartner,
  isSouthAfricanPartner,
  isVAlidSouthAfricanNationalId
} from '../../lib/access.es6';
import DependantsTable from './DependantsTable';
import ProductsHeader from '../../containers/NewProductList/components/ProductsHeader/ProductsHeader';
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {PRIMARY_THEME_COLOR_SHADE_1} from "../../lib/constants";
import AlertDialog from '../AlertDialog/index.es6';
const relationships = [
  { value: 'Principal', gender: 'N/A' },
  { value: 'Spouse', gender: 'N/A' },
  { value: 'Spouse', gender: 'Female' },
  { value: 'Child', gender: 'N/A' },
  { value: 'Parent', gender: 'N/A' },
  { value: 'Parent_In_Law', gender: 'N/A' },
  { value: 'Beneficiary_Adult', gender: 'N/A' },
  { value: 'Husband', gender: 'Male' },
  { value: 'Wife', gender: 'Female' },
  { value: 'Daughter', gender: 'Female' },
  { value: 'Son', gender: 'Male' },
  { value: 'Mother', gender: 'Female' },
  { value: 'Father', gender: 'Male' },
  { value: 'Mother_In_Law', gender: 'Female' },
  { value: 'Father_In_Law', gender: 'Male' },
  { value: 'Uncle', gender: 'Male' },
  { value: 'Aunt', gender: 'Female' },
  { value: 'Sister', gender: 'Female' },
  { value: 'Granddaughter', gender: 'Female' },
  { value: 'Grandchild', gender: 'N/A' },
  { value: 'Sister_In_Law', gender: 'Female' },
  { value: 'Other', gender: 'N/A' },
  { value: 'Grand_Child', gender: 'N/A' },
  { value: 'Daughter_In_Law', gender: 'Female' },
  { value: 'Fiance', gender: 'N/A' },
  { value: 'Step_Father', gender: 'Male' },
  { value: 'Step_Child', gender: 'N/A' },
  { value: 'Step_Mother', gender: 'Female' },
  { value: 'Son_In_Law', gender: 'Male' },
  { value: 'Secondary_Spouse', gender: 'Female' },
  { value: 'Next_Of_Kin', gender: 'N/A' },
  { value: 'Wider_Child', gender: 'N/A' },
];

export class Dependants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
      dependantGuid: null,
      firstName: null,
      lastName: null,
      dateOfBirth: null,
      nationalId: null,
      gender: '',
      relationship: '',
      openAddModal: false,
      openEditModal: false,
      anchorElAction: null,
      openConfirmDelete: false,
      msisdn: null,
      selectedDependant: null,
      SAIdCheckError: false,
    };

    this.onFirstNameChange = this.onFirstNameChange.bind(this);
    this.onLastNameChange = this.onLastNameChange.bind(this);
    this.onNationalIdChange = this.onNationalIdChange.bind(this);
    this.onGenderChange = this.onGenderChange.bind(this);
    this.onRelationshipChange = this.onRelationshipChange.bind(this);
    this.onDateOfBirthChange = this.onDateOfBirthChange.bind(this);
    this.saveDependant = this.saveDependant.bind(this);
    this.updateDependant = this.updateDependant.bind(this);
    this.deleteDependant = this.deleteDependant.bind(this);
    this.toggleAddDependantDialog = this.toggleAddDependantDialog.bind(this);
    this.toggleEditDependantDialog = this.toggleEditDependantDialog.bind(this);
    this.handleCloseActionMenu = this.handleCloseActionMenu.bind(this);
    this.handleOpenConfirmDeleteDialog = this.handleOpenConfirmDeleteDialog.bind(this);
    this.onMsisdnChange = this.onMsisdnChange.bind(this);
    this.toggleOpenConfirmDeleteDialog = this.toggleOpenConfirmDeleteDialog.bind(this);
  }

  componentWillMount() {
    this.updateLanguage();
    this.props.resetAddDependantSuccessAlert()
  }

  componentDidUpdate() {

    this.updateLanguage();
  }

  componentDidMount() {
    const partner = getPartner();
    const nationalIdRegex = partner ? partner.country.national_id_regex : '';
    const pattern = new RegExp(nationalIdRegex);
    ValidatorForm.addValidationRule('isValidNationalId', value => {
      if (!isSouthAfricanPartner()) {
        return pattern.test(value);
      }
      const result = isVAlidSouthAfricanNationalId(value);
      if (result.isValid) {
        this.setState({
          dateOfBirth: formatDate(new Date(result.dob)),
          gender: result.gender,
          SAIdCheckError: result.error,
        });
        return true;
      }
      this.setState({SAIdCheckError: result.error});
      return false;
    });
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'Dependants',
      this.state.labels
    );
  }

  resetFormFields(){
    this.setState({
      firstName: null,
      lastName: null,
      dateOfBirth: null,
      nationalId:null,
      gender:'',
      relationship:'',
      msisdn: '',
    })
  }

  getRelationships(){
    const { gender } = this.state
    if(!gender) return relationships


    if(gender === 'Male'){
      return relationships.filter(relationship => relationship.gender !== 'Female')
    }

    if(gender === 'Female'){
      return relationships.filter(relationship => relationship.gender !== 'Male')
    }

    return relationships
  }

  saveDependant(){
    console.log('saving dep');
    if(!this.state.firstName || !this.state.lastName || !this.state.gender || !this.state.relationship){

      console.log('terminating');
      return;
    }
    const payload ={
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      date_of_birth: this.state.dateOfBirth,
      national_id: this.state.nationalId,
      gender: this.state.gender,
      relationship: this.state.relationship.value,
      customer_guid: this.props.customerGuid,
      msisdn: this.state.msisdn,
    }

    this.props.onSave(payload);
    // this.toggleAddDependantDialog();
  }

  updateDependant(){
    if(!this.state.firstName || !this.state.lastName || !this.state.gender || !this.state.relationship){
      return;
    }

    const payload ={
      guid: this.state.dependantGuid,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      date_of_birth: this.state.dateOfBirth,
      national_id: this.state.nationalId,
      gender: this.state.gender,
      relationship: this.state.relationship.value,
      customer_guid: this.props.customerGuid,
      msisdn:this.state.msisdn,
    }

    this.props.onUpdate(payload);
    this.toggleEditDependantDialog();
  }

  deleteDependant(){
    this.props.onDelete(this.state.dependantGuid);
    this.setState({ openConfirmDelete: false });
  }

  toggleAddDependantDialog() {
    this.setState({ openAddModal: !this.state.openAddModal });
  }

  toggleEditDependantDialog() {
    this.setState({ openEditModal: !this.state.openEditModal });
  }

  closeEditDependantDialog() {
    this.setState({ openEditModal: false });
    this.resetFormFields();
  }

  onFirstNameChange(event){
    this.setState({ firstName: event.target.value });
  }

  onLastNameChange(event){
    this.setState({ lastName: event.target.value });
  }

  onNationalIdChange(event){
    const value = event.target.value;
    this.setState({ nationalId: value });

    const result = isVAlidSouthAfricanNationalId(value)
    if(result.isValid){
      this.setState({
        dateOfBirth: formatDate(new Date(result.dob)),
        gender: result.gender,
        SAIdCheckError: result.error
      })
      console.log( formatDate(new Date(result.dob)),);
      return true
    }else {
      this.setState({ SAIdCheckError: true})
      return false
    }
  }




  onGenderChange(event){
    this.setState({ gender: event.target.value });
  }

  onRelationshipChange(relationship){
    this.setState({ relationship: relationship });
  }


  onDateOfBirthChange(date){
    this.setState({ dateOfBirth: date ? date._d : date });
  }

  onMsisdnChange(event){
    const { value } = event.target;
    this.setState({ msisdn: value });
  }

  setEditDependant(dependant){
    const selectedRelationship = find(relationships, {value: dependant.relationship})
    this.setState({
      dependantGuid: dependant.guid,
      firstName: dependant.first_name,
      lastName: dependant.last_name,
      nationalId: dependant.national_id,
      gender: dependant.gender,
      relationship: selectedRelationship,
      dateOfBirth: dependant.date_of_birth?new Date(dependant.date_of_birth): null,
      msisdn: dependant.msisdn,
    });

    this.toggleEditDependantDialog();
    this.handleCloseActionMenu();
  }


  handleDependantCreatedSuccessfully(){
    this.props.resetAddDependantSuccessAlert()
    this.toggleAddDependantDialog()
    this.resetFormFields()
  }

  handleDependantCreateError(){
    this.props.resetAddDependantErrorAlert()
  }

  handleDependantEditedSuccessfully(){
    this.props.resetEditDependantSuccessAlert()
    this.toggleEditDependantDialog()
    this.resetFormFields()
  }

  handleDependantEditError(){
    this.props.resetEditDependantErrorAlert()
  }

  addDependantDialog() {
    const labels = this.state.labels;
    const open = this.state.openAddModal;

    const confirmActions = [
      <Button type="submit" variant="contained" style={{marginRight: '2%'}}>
        {labels.save}
      </Button>,
      <Button
        onClick={this.toggleAddDependantDialog}
        variant="contained"
        type="button"
        color="secondary"
        style={{marginRight: '2%'}}
      >
        {labels.cancel}
      </Button>,
    ];
    return (
      <Dialog
        open={open}
        onClose={this.toggleAddDependantDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
        fullScreen
      >
        <AppBar sx={{position: 'relative'}}>
          <Toolbar style={{background: PRIMARY_THEME_COLOR_SHADE_1}}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.toggleAddDependantDialog}
              aria-label="close"
            >
              <CloseIcon/>
            </IconButton>
            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
              {labels.addDependant}
            </Typography>
          </Toolbar>
        </AppBar>
        <ValidatorForm onSubmit={this.saveDependant}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item lg={3} md={6} xs={12}>
                <TextValidator
                  variant="outlined"
                  onChange={this.onFirstNameChange}
                  value={this.state.firstName}
                  placeholder={labels.firstName}
                  label={labels.firstName}
                  fullWidth
                  validators={['required']}
                  errorMessages={['Please enter first name']}
                />
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <TextValidator
                  onChange={this.onLastNameChange}
                  value={this.state.lastName}
                  label={labels.lastName}
                  name={labels.lastName}
                  fullWidth
                  variant="outlined"
                  placeholder={labels.lastName}
                  validators={['required']}
                  errorMessages={['Please enter last name']}
                />
              </Grid>
              <Grid lg={3} xs={12} md={6} item>
                <TextField
                  fullWidth
                  placeholder={labels.telephone}
                  label={labels.telephone}
                  variant="outlined"
                  onChange={this.onMsisdnChange}
                  value={this.state.msisdn}
                  onInput={e => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, getLocalNumberLength());
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {getInternationalCallingCode()}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid lg={3} xs={12} md={6} item>
                <DatePicker
                  fullWidth
                  variant="outlined"
                  clearable
                  label={labels.dateOfBirth}
                  inputVariant="outlined"
                  value={this.state.dateOfBirth}
                  onChange={this.onDateOfBirthChange}
                  format="YYYY-MM-DD"
                />
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                {isSouthAfricanPartner() ? (
                  <TextValidator
                    onChange={this.onNationalIdChange}
                    value={this.state.nationalId}
                    label={labels.nationalId}
                    name="national_id"
                    fullWidth
                    variant="outlined"
                    placeholder={labels.nationalId}
                    validators={['required', 'isValidNationalId']}
                    errorMessages={[
                      'Please enter national id',
                      this.state.SAIdCheckError || 'Invalid national Id',
                    ]}
                  />
                ) : (
                  <TextField
                    fullWidth
                    placeholder={labels.nationalId}
                    label={labels.nationalId}
                    name="national_id"
                    variant="outlined"
                    onChange={this.onNationalIdChange}
                    value={this.state.nationalId}
                  />
                )}
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <Autocomplete
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={labels.relationship}
                      placeholder={labels.relationship}
                      required
                    />
                  )}
                  fullWidth
                  value={this.state.relationship}
                  getOptionLabel={a => labels[a.value] || ''}
                  onChange={(event, newValue) => {
                    this.onRelationshipChange(newValue);
                  }}
                  id="controllable-states-demo"
                  options={this.getRelationships().sort((a, b) =>
                    labels[a.value].localeCompare(labels[b.value])
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label"> {labels.gender}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.gender}
                    label={labels.gender}
                    onChange={this.onGenderChange.bind(this)}
                  >
                    <MenuItem key="-1" value="">{labels.gender}</MenuItem>
                    {['Male', 'Female'].map((gender, index) => (
                      <MenuItem key={index} value={gender}>
                        {gender}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {confirmActions.map((item, index) => (
              <div key={index}> {item}</div>
            ))}
          </DialogActions>
        </ValidatorForm>
        <AlertDialog
              custom
              show={this.props.showAddDependantProgressAlert}
              size="sm"
              style={{marginTop: '0', top: '30vh'}}
              confirmBtnText="ok"
              showCancel={false}
              showConfirm={false}
              cancelBtnText="cancel"
              showTitle={false}
              confirmBtnCssClass
            >
              <CircularProgress />
              <h2>Adding Dependant </h2>
            </AlertDialog>

            <AlertDialog
              success
              show={!!this.props.showAddDependantSuccessAlert}
              size="sm"
              title="Dependant added"
              style={{marginTop: '0', top: '30vh'}}
              onConfirm={() => {
                this.handleDependantCreatedSuccessfully();
              }}
              confirmBtnText="ok"
              showCancel={false}
              showConfirm
              cancelBtnText="cancel"
              confirmBtnCssClass
            ></AlertDialog>

            <AlertDialog
              show={!!this.props.showAddDependantErrorAlert}
              danger
              title="Error adding Dependant"
              onConfirm={() => this.handleDependantCreateError()}
              confirmBtnText="ok"
              confirmBtnCssClass
              showCancel={false}
              style={{marginTop: '0', top: '30vh'}}
            >
              {this.props.createDependantError
                ? this.props.createDependantError.message
                : ''}
            </AlertDialog>
      </Dialog>
    );
  }

  editDependantDialog() {
    const labels = this.state.labels;
    const open = this.state.openEditModal;

    const confirmActions = [
      <Button variant="contained" type="submit" style={{marginRight: '2%'}}>
        {labels.save}
      </Button>,
      <Button
        onClick={this.toggleEditDependantDialog}
        variant="contained"
        type="button"
        color="secondary"
        style={{marginRight: '2%'}}
      >
        {labels.cancel}
      </Button>,
    ];
    return (
      <Dialog
        open={open}
        onClose={this.closeEditDependantDialog.bind(this)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
        fullScreen
      >
        <AppBar sx={{position: 'relative'}}>
          <Toolbar style={{background: PRIMARY_THEME_COLOR_SHADE_1}}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.closeEditDependantDialog.bind(this)}
              aria-label="close"
            >
              <CloseIcon/>
            </IconButton>
            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
              {labels.editDependant}
            </Typography>
          </Toolbar>
        </AppBar>
        <ValidatorForm onSubmit={this.updateDependant}>
          <DialogContent>
          <AlertDialog
              custom
              show={this.props.showEditDependantProgressAlert}
              size="sm"
              style={{marginTop: '0', top: '30vh'}}
              confirmBtnText="ok"
              showCancel={false}
              showConfirm={false}
              cancelBtnText="cancel"
              showTitle={false}
              confirmBtnCssClass
            >
              <CircularProgress />
              <h2>Editing Dependant </h2>
            </AlertDialog>

            <AlertDialog
              success
              show={!!this.props.showEditDependantSuccessAlert}
              size="sm"
              title="Dependant Edited"
              style={{marginTop: '0', top: '30vh'}}
              onConfirm={() => {
                this.handleDependantEditedSuccessfully();
              }}
              confirmBtnText="ok"
              showCancel={false}
              showConfirm
              cancelBtnText="cancel"
              confirmBtnCssClass
            ></AlertDialog>

            <AlertDialog
              show={!!this.props.showEditDependantErrorAlert}
              danger
              title="Error Editing Dependant"
              onConfirm={() => this.handleDependantEditError()}
              confirmBtnText="ok"
              confirmBtnCssClass
              showCancel={false}
              style={{marginTop: '0', top: '30vh'}}
            >
              {this.props.createDependantError
                ? this.props.createDependantError.message
                : ''}
            </AlertDialog>
            <Grid container spacing={1}>
              <Grid xs={12} md={6} lg={3} item>
                <TextValidator
                  onChange={this.onFirstNameChange}
                  value={this.state.firstName}
                  label={labels.firstName}
                  name={labels.firstName}
                  fullWidth
                  variant="outlined"
                  placeholder={labels.firstName}
                  required
                  validators={['required']}
                  errorMessages={['Please enter first name']}
                />
              </Grid>
              <Grid xs={12} md={6} lg={3} item>
                <TextValidator
                  onChange={this.onLastNameChange}
                  value={this.state.lastName}
                  label={labels.lastName}
                  name={labels.lastName}
                  fullWidth
                  variant="outlined"
                  placeholder={labels.lastName}
                  required
                  validators={['required']}
                  errorMessages={['Please enter last name']}
                />
              </Grid>
              <Grid xs={12} md={6} lg={3} item>
                <TextValidator
                  onChange={this.onNationalIdChange}
                  value={this.state.nationalId}
                  label={labels.nationalId}
                  name={labels.nationalId}
                  fullWidth
                  variant="outlined"
                  placeholder={labels.nationalId}
                  validators={['required', 'isValidNationalId']}
                  errorMessages={[
                    'Please enter national id',
                    this.state.SAIdCheckError
                      ? this.state.SAIdCheckError
                      : 'Invalid national Id',
                  ]}
                />
              </Grid>
              <Grid xs={12} md={6} lg={3} item>
                <TextField
                  fullWidth
                  placeholder={labels.telephone}
                  label={labels.telephone}
                  variant="outlined"
                  onChange={this.onMsisdnChange}
                  value={this.state.msisdn}
                  onInput={e => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, getLocalNumberLength());
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {getInternationalCallingCode()}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid xs={12} md={6} lg={3} item>
                <DatePicker
                  fullWidth
                  variant="outlined"
                  clearable
                  label={labels.dateOfBirth}
                  inputVariant="outlined"
                  value={this.state.dateOfBirth}
                  onChange={this.onDateOfBirthChange}
                  format='YYYY-MM-DD'
                />
              </Grid>
              <Grid xs={12} md={6} lg={3} item>
                <Autocomplete
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={labels.relationship}
                      placeholder={labels.relationship}
                      required
                    />
                  )}
                  fullWidth
                  value={this.state.relationship}
                  getOptionLabel={a => labels[a.value] || ''}
                  onChange={(event, newValue) => {
                    this.onRelationshipChange(newValue);
                    this.setState({gender: newValue.gender});
                  }}
                  id="controllable-states-demo"
                  options={this.getRelationships().sort((a, b) =>
                    labels[a.value].localeCompare(labels[b.value])
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label"> {labels.gender}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.gender}
                    label={labels.gender}
                    onChange={this.onGenderChange.bind(this)}
                  >
                    <MenuItem key="-1" value="">{labels.gender}</MenuItem>
                    {['Male', 'Female'].map((gender, index) => (
                      <MenuItem key={index} value={gender}>
                        {gender}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {confirmActions.map((item, index) => (
              <div key={index}> {item}</div>
            ))}
          </DialogActions>
        </ValidatorForm>

      </Dialog>
    );
  }

  handleActionMenuButtons(dependant, event) {
    this.setState({ anchorElAction: event.currentTarget, selectedDependant: dependant });
  }

  handleCloseActionMenu() {
    this.setState({ anchorElAction: null });
  }

  setDeleteDialog(dependant) {
    this.setState({ openConfirmDelete: true, dependantGuid: dependant.guid });
    this.handleCloseActionMenu();
  }

  handleOpenConfirmDeleteDialog(dependant) {
    this.setState({ openConfirmDelete: true, dependantGuid:dependant.guid });
  }

  toggleOpenConfirmDeleteDialog() {
    this.setState({ openConfirmDelete: !this.state.openConfirmDelete});
  }

  deleteDependantDialog(){
    const { labels, } = this.state;
    const btnStyle = {
      margin: '2%',
      green: {
        backgroundColor: 'orangered',
        color: 'white',
        margin: '2px',
        height: '50px',
        padding: '0 30px',
      },
      danger: {
        backgroundColor: 'green',
        color: 'white',
        margin: '2px',
        height: '50px',
        padding: '0 30px',
      },
    };

    const confirmDeleteActions = [
      <Button style={btnStyle.danger} onClick={this.toggleOpenConfirmDeleteDialog}>
        {labels.no}
      </Button>,
      <Button style={btnStyle.green} onClick={this.deleteDependant}>
        {labels.yes}
      </Button>,
    ];

    return (
      <Dialog
        open={this.state.openConfirmDelete}
        onClose={this.handleOpenConfirmDeleteDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {labels.confirmDeleteDependant}
        </DialogTitle>
        <DialogContent>{labels.confirmDeleteDependantText}</DialogContent>
        <DialogActions>
          {confirmDeleteActions.map((item, index) => (
            <div key={index}> {item}</div>
          ))}
        </DialogActions>
      </Dialog>
    )
  }

  render() {
    const { labels, } = this.state;
    let dependants = this.props.dependants || [];
    let nuclearFamilyDependants = dependants.filter( d => (this.props.extendedProductConfigRelationships.includes(d.relationship)) )
    let extendedFamilyDependents = dependants.filter( d => !this.props.extendedProductConfigRelationships.includes(d.relationship) )
    let anchorElAction = this.state.anchorElAction
    if (this.props.extendedProductConfigRelationships.length > 0) {
      return (
        <div style={{marginTop: "60px"}}>
          <ProductsHeader
            title="Dependants"
            onAddButtonClick={this.toggleAddDependantDialog}
            showAddButton={true}
          />

          {(nuclearFamilyDependants.length > 0) &&
            <DependantsTable
              dependants={nuclearFamilyDependants}
              setEditDependant={this.setEditDependant.bind(this)}
              setDeleteDialog={this.setDeleteDialog.bind(this)}
              displayEditButton={true}
              displayDeleteButton={true}
              labels={labels}
              title={"Nuclear Family Dependents"}
            />}

          {(extendedFamilyDependents.length > 0) && <DependantsTable
            dependants={extendedFamilyDependents}
            setEditDependant={this.setEditDependant.bind(this)}
            setDeleteDialog={this.setDeleteDialog.bind(this)}
            displayEditButton={true}
            displayDeleteButton={true}
            labels={labels}
            title={"Extended Family Dependents"}
          />}
          {this.props.children}
          {this.DisplayDependents(labels, dependants, anchorElAction)}
        </div>
      );
    } else {
      return (
        <div style={{marginTop: "60px"}}>
          <ProductsHeader
            title="Dependants"
            onAddButtonClick={this.toggleAddDependantDialog}
            showAddButton={true}
          />
          <DependantsTable
            dependants={dependants}
            setEditDependant={this.setEditDependant.bind(this)}
            setDeleteDialog={this.setDeleteDialog.bind(this)}
            displayEditButton={true}
            displayDeleteButton={true}
            labels={labels}
          />
          {this.props.children}
          <Grid container spacing={2}>
      {/* {this.addDependantDialog()}
      {this.editDependantDialog()}
      {this.deleteDependantDialog()} */}
    </Grid>
          {this.DisplayDependents(labels, dependants, anchorElAction)}

        </div>
      );
    }

  }

  DisplayDependents(labels, dependants, anchorElAction) {
    return <Grid container spacing={2}>
      {this.addDependantDialog()}
      {this.editDependantDialog()}
      {this.deleteDependantDialog()}
    </Grid>;
  }
}

export default connect(state => ({
  globalData: state.global,
  policyData: state.policyList,
}))(Dependants);
