import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";

const HeaderRibbonInc = (props) => {
  const classes = useStyle();


  return (
    <section className={classes.claimsheader}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10} md={10}>
          <div className={classes.claimstitle}>
            <ul>
              <li>{props.title}</li>
            </ul>
          </div>
        </Grid>

      </Grid>


    </section>
  )
}

export default HeaderRibbonInc