import React from 'react'
import { Box, Grid, Button } from '@mui/material';
import { useStyle } from "./Style";
import SkeletonWrapper from '../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import { formatDateLocale } from '../../../../../lib/utils.es6';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ViewDocument from '../../../../../shared-ui/ViewDocument/ViewDocument';
import { hasAuthourity } from '../../../../../lib/access.es6';

const Documents = (props) => {

  const classes = useStyle();


  const [expanded, setExpanded] = React.useState(false)

  const [viewDocumentUrl, setViewDocumentUrl] = React.useState(null);
  const [numPages, setNumPages] = React.useState(null);

  const dataForDisplay = expanded ? props.documents : props.documents.slice(0, 2)

  if (props.documents.length === 0) {
    return <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
        <Grid container spacing={2} className={classes.claimdetailswrapper}>
          <Grid item xs={12} sm={12} md={12} className={classes.ClaimDetailscontent}>
            <p style={{ textAlign: 'center', marginTop: "30px" }}>No details found</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }

  if (props.loader) {
    return <SkeletonWrapper loading={props.loader} />
  }
  return (
    <>
      {
        dataForDisplay.map((document, index) => (
          <Grid container className={classes.policyWrapper} key={index}>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container className={classes.policyHeader}>
                <Grid item xs={12} sm={6} md={6}>
                  <Grid container>
                    <Grid item xs={12} md={2} sm={2} className={classes.policyIconWrapper}   >
                      <Box className={classes.policyIcons}>
                        <InsertDriveFileOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={10} sm={10} className={classes.policyHeaderContent}>
                      <h6>{document.description || "N/A"}</h6>
                      <p> {"Document Name"}</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6} >

                  <Grid container>
                    <Grid item xs={12} md={6} sm={6}>
                      <div className={classes.policyHeaderContent} >
                        <h6>{formatDateLocale(document.created_at) || "N/A"}</h6>
                        <p> {"Created At"}</p>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} className={classes.policyActiveStatusWrapper}>
                      <Box display="flex" justifyContent="flex-end">
                        <ul>
                          <li>
                            <Tooltip title={"View Document"}>
                              <span style={{ cursor: "pointer" }} onClick={() => setViewDocumentUrl(document.url)}  ><VisibilityIcon sx={{ color: " #4B7E34" }} /> </span>
                            </Tooltip>
                          </li>
                          <li>
                            <Tooltip title="Download Document">
                              <span style={{ cursor: "pointer", marginLeft: "10px" }} ><a href={document.url} download target='_blank' rel="noopener noreferrer" ><FileDownloadIcon sx={{ color: " #000" }} /></a> </span>
                            </Tooltip>
                          </li>
                          <li style={{ marginLeft: "10px", display: hasAuthourity('CAPTURE_POLICY') ? 'block' : 'none' }} >
                            <Tooltip title={" Delete Document"}>
                              <span style={{ cursor: "pointer" }} onClick={() => props.setDocumentToDelete(document)} ><DeleteOutlineOutlinedIcon sx={{ color: " #B3251E" }} /> </span>
                            </Tooltip>
                          </li>

                        </ul>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        ))
      }
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          {
            dataForDisplay.length > 1 && (
              <Button style={{
                background: "#FBA92D",
                borderRadius: "8px",
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "15px",
                boxShadow: "none",
                padding: "10px",
                marginTop: "20px",
                textTransform: "capitalize",
              }} variant="contained" size="large" endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} onClick={() => setExpanded(!expanded)}>
                {expanded ? `Show Less` : `Show More`}
              </Button>
            )
          }


        </Grid>
      </Grid>
      <ViewDocument
        title="Policy Document"
        open={viewDocumentUrl}
        close={() => setViewDocumentUrl(null)}
        viewDocumentUrl={viewDocumentUrl}
        numPages={numPages}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      />
    </>
  )
}

export default Documents