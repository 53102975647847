import * as React from "react";
import Grid from "@mui/material/Grid";
import BenefitsTable from "../BenefitsTable/BenefitsTable";
import CashBackIndemnityForm from "./CashBackIndemnityForm";
import FixedIndemnityForm from "./FixedIndemnityForm";
import VariableIndemnityForm from "./VariableIndemnityForm";
import { Button, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import AlertDialog from "../../../../components/AlertDialog/index.es6";

export default class AddBenefits extends React.Component {

  constructor(props) {
    super(props)
  
    this.state = {
       type: 'cashback'
    }
  }


  handleBenefitCreatedSuccessfully = () => {
    this.props.resetAddBenefitSuccessAlert()
    this.props.goToNextStep()
  }

  addAnotherBenefit = () => {
    this.props.resetAddBenefitSuccessAlert()
  }

  handleBenefitCreateError = () => {
    this.props.resetAddBenefitErrorAlert()
  }

  handleBenefitDeletedSuccessfully = () => {
    this.props.resetHardDeleteBenefitSuccessAlert()
  }

  handleBenefitDeleteError = () => {
    this.props.resetHardDeleteBenefitErrorAlert()
  }

  render(){
    return (
      <React.Fragment>
        <Grid container >
          <Grid item x={12}>
            <AlertDialog
              custom
              show={this.props.showAddBenefitProgressAlert}
              size="sm"
              style={{ marginTop: '0', top: '30vh' }}
              confirmBtnText={'ok'}
              showCancel={false}
              showConfirm={false}
              cancelBtnText={'cancel'}
              showTitle={false}
              confirmBtnCssClass
            >
              <CircularProgress />
              <h2>Adding benefit</h2>
            </AlertDialog>
  
            <AlertDialog
              success
              show={this.props.showAddBenefitSuccessAlert}
              size="sm"
              title={'Benefit added'}
              style={{ marginTop: '0', top: '30vh' }}
              onConfirm={() => { this.handleBenefitCreatedSuccessfully() }}
              confirmBtnText={'ok'}
              showCancel={false}
              showConfirm={false}
              cancelBtnText={'cancel'}
              confirmBtnCssClass
            >
              <div >
                <Button variant="outlined" onClick={() => { this.addAnotherBenefit() }} style={{ marginRight: '3%', borderColor: 'green', color: 'green', fontWeight: 'bold' }}>Add another benefit</Button>
                <Button variant="contained" onClick={() => { this.handleBenefitCreatedSuccessfully() }} style={{ background: 'green', }}>Finish</Button>
              </div>
            </AlertDialog>
  
            <AlertDialog
              show={this.props.showAddBenefitErrorAlert}
              danger
              title={'Error adding benefit'}
              onConfirm={() => this.handleBenefitCreateError()}
              confirmBtnText={'Try again'}
              confirmBtnCssClass
              showCancel={false}
              style={{ marginTop: '0', top: '30vh' }}
            >
              {this.props.createBenefitError ? this.props.createBenefitError.message : ''}
            </AlertDialog>
  
  
  
  
            {/* hard delete premium alers */}
            <AlertDialog
              custom
              show={this.props.showHardDeleteBenefitProgressAlert}
              size="sm"
              style={{ marginTop: '0', top: '30vh' }}
              confirmBtnText={'ok'}
              showCancel={false}
              showConfirm={false}
              cancelBtnText={'cancel'}
              showTitle={false}
              confirmBtnCssClass
            >
              <CircularProgress />
              <h2>deleting benefit</h2>
            </AlertDialog>
  
            <AlertDialog
              success
              show={this.props.showHardDeleteBenefitSuccessAlert}
              size="sm"
              title={' benefit deleted'}
              style={{ marginTop: '0', top: '30vh' }}
              onConfirm={() => { this.handleBenefitDeletedSuccessfully() }}
              confirmBtnText={'ok'}
              showCancel={false}
              cancelBtnText={'cancel'}
              confirmBtnCssClass
            >
            </AlertDialog>
  
            <AlertDialog
              show={this.props.showHardDeleteBenefitErrorAlert}
              danger
              title={'Error deleting benefit'}
              onConfirm={() => this.handleBenefitDeleteError()}
              confirmBtnText={'Try again'}
              confirmBtnCssClass
              showCancel={false}
              style={{ marginTop: '0', top: '30vh' }}
            >
              {this.props.hardDeleteBenefitError ? this.props.hardDeleteBenefitError.message : ''}
            </AlertDialog>
          </Grid>
          <Grid item md={4} xs={12} style={{ textAlign: 'center' }}>
            <label style={{ margin: '3% 0%' }}>Select benefit type:</label>
          </Grid>
          <Grid item md={8} xs={12} style={{ textAlign: 'left' }}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(e) => { this.setState({type: e.target.value }) }}
                value={this.state.type}
              >
                <FormControlLabel value="cashback" name="cashback" control={<Radio />} label="Cashback Indemnity" />
                <FormControlLabel value="fixed" name="fixex" control={<Radio />} label="Fixed Indemnity" />
                <FormControlLabel value="variable" name="variable" control={<Radio />} label="Variable Indemnity" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <hr />
        </Grid>
  
        {
          (this.state.type === 'cashback') &&
          <CashBackIndemnityForm
            hardDeleteBenefit={this.props.hardDeleteBenefit}
            addBenefit={this.props.addBenefit}
          />
        }
        {
          (this.state.type === 'variable') &&
          <VariableIndemnityForm
            hardDeleteBenefit={this.props.hardDeleteBenefit}
            addBenefit={this.props.addBenefit}
            currencyCode ={this.props.currencyCode}
          />
        }
        {
          (this.state.type === 'fixed') &&
          <FixedIndemnityForm
            hardDeleteBenefit={this.props.hardDeleteBenefit}
            addBenefit={this.props.addBenefit}
            currencyCode ={this.props.currencyCode}
          />
        }
  
        {
          (this.props.createdBenefits && this.props.createdBenefits.length > 0) &&
          <BenefitsTable
            benefits={this.props.createdBenefits}
            hardDeleteBenefit={this.props.hardDeleteBenefit}
            displayEditButton={false}
            displayDeleteButton={true}
          />
        }
      </React.Fragment>
    );
  }

}
