import * as types from '../../../../lib/actionTypes.es6'


export const initialState = {
  errors: null,
  loader: false,


  addBulkPolicyOnboardError: null,
  addBulkPolicyOnboardLoader: false,
  addedBulkPolicyOnboarding: null,

  saveBulkPolicyOnboardingLoader: false,
  saveBulkPolicyOnboardingError: null,
  addedTemplateDocument: null,

  BulkPoliciesBatches: [],
  getBulkPoliciesLoader: false,
  getBulkPoliciesError: null,

  failedPoliciesBatches: [],
  failedPoliciesBatchesLoader: false,
  failedPoliciesBatchesError: null

};

export default function bulkPolicyOnboardingReducer(state = initialState, action) {
  switch (action.type) {


    case types.ADD_BULK_POLICY_ONBOARDING_REQUEST:
      return {
        ...state,
        addBulkPolicyOnboardLoader: true,
        addBulkPolicyOnboardError: null,
        addedBulkPolicyOnboarding: null,
      };

    case types.ADD_BULK_POLICY_ONBOARDING_SUCCESS:
      return {
        ...state,
        addedBulkPolicyOnboarding: action.payload,
        addBulkPolicyOnboardLoader: false,
      };

    case types.ADD_BULK_POLICY_ONBOARDING_FAILURE:
      return {
        ...state,
        addBulkPolicyOnboardLoader: false,
        addBulkPolicyOnboardError: action.payload,
      };



    case types.SAVE_BULK_POLICY_ONBOARDING_REQUEST:
      return {
        ...state,
        saveBulkPolicyOnboardingLoader: true,
        saveBulkPolicyOnboardingError: null,
        addedTemplateDocument: null,
      };

    case types.SAVE_BULK_POLICY_ONBOARDING_SUCCESS:
      return {
        ...state,
        addedTemplateDocument: action.payload,
        saveBulkPolicyOnboardingLoader: false,
      };

    case types.SAVE_BULK_POLICY_ONBOARDING_FAILURE:
      return {
        ...state,
        saveBulkPolicyOnboardingLoader: false,
        saveBulkPolicyOnboardingError: action.payload,
      };

    case types.GET_BULK_POLICY_ONBOARDING_BATCHES_REQUEST:
      return {
        ...state,
        getBulkPoliciesError: null,
        getBulkPoliciesLoader: true,
      };

    case types.GET_BULK_POLICY_ONBOARDING_BATCHES_SUCCESS:
      return {
        ...state,
        BulkPoliciesBatches: action.payload,
        getBulkPoliciesLoader: false,
        getBulkPoliciesError: null,
      };

    case types.GET_BULK_POLICY_ONBOARDING_BATCHES_FAILURE:
      return { ...state, getBulkPoliciesLoader: false, getBulkPoliciesError: action.payload };


      case types.GET_FAILED_BATCHES_REQUEST:
        return {
          ...state,
          failedPoliciesBatchesError: null,
          failedPoliciesBatchesLoader: true,
        };
  
      case types.GET_FAILED_BATCHES_SUCCESS:
        return {
          ...state,
          failedPoliciesBatches: action.payload,
          failedPoliciesBatchesLoader: false,
          failedPoliciesBatchesError: null,
        };
  
      case types.GET_BATCHES_FAILURE:
        return { ...state, failedPoliciesBatchesLoader: false, failedPoliciesBatchesError: action.payload };


    default:
      return state;
  }
}
