import React, { useState, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';
import { formatDate, formatStatus } from '../../../../../lib/utils.es6';
import { InputAdornment, Switch } from '@mui/material';
import find from 'lodash/find';
import { getLocalNumberLength, getPartner, isVAlidSouthAfricanNationalId } from '../../../../../lib/access.es6';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import MultipleSelectChip from '../../../../../containers/PartnerStatus/components/Edit/MultipleSelectChip';
import moment from 'moment';


const Adddependant = (props) => {
  const classes = useStyle();
  const [firstname, setFirstname] = useState('');
  const [middlename, setMiddleName] = useState('');
  const [lastname, setLastname] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [phonenumber, setPhoneNumber] = useState('');
  const [nationalid, setNationalId] = useState('');
  const [gender, setGender] = useState('');
  const [town, setTown] = useState('');
  const [workaddress, setWorkAddress] = useState('');
  const [relationship, setRelationship] = useState('');
  const [southAfricanIdCheckError, setSouthAfricanIdCheckError] = useState(null)

  const [ageRanges, setAgeRanges] = useState(null)
  const [addOns, setAddOns] = useState([])
  const [extendedMemberPremiumNotFound, setExtendedMemberPremiumNotFound] = useState(false)

  const [isCitizen, setIsCitizen] = useState(true)
  const [passport, setPassport] = useState('')

  const formRef = useRef(null);

  useEffect(() => {

    ValidatorForm.addValidationRule('isValidPhoneNumber', value => {
      return value.length === getLocalNumberLength();
    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidPhoneNumber');
    };
  }, [])

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidPassport', (value) => {
      const regex = /^(?=.*[0-9])(?=.*[a-zA-Z]).{5,}$/;
      return regex.test(value);
    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidPassport');
    };
  }, []);

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidNationalId', (value) => {
      if (relationship === 'Child' && value === '') {
        return true;
      }

      const result = isVAlidSouthAfricanNationalId(value)
      if (result.isValid) {
        setGender(result.gender)
        setDateOfBirth(formatDate(new Date(result.dob)))
        setSouthAfricanIdCheckError(result.error)
        return true
      } else {
        setSouthAfricanIdCheckError(result.error)
        return false
      }

    });

    ValidatorForm.addValidationRule('isNationalIdRequired', (value) => {
      if (relationship === 'Child' && value === '') {
        return true;
      }
      return false;
    });




    ValidatorForm.addValidationRule('isValidDateOfBirth', (dobValue) => {
      if (!dateOfBirth) return true;
      const memberConfig = props.getMemberConfig(relationship)
      if (!memberConfig) return true

      const maximum_age = memberConfig.age_range.max
      const minimum_age = memberConfig.age_range.min

      const selectedDateObj = new Date(dobValue);
      const currentDate = new Date();

      const ageDifference = currentDate.getFullYear() - selectedDateObj.getFullYear();

      setAgeRanges({ minimum_age, maximum_age })
      return ageDifference >= minimum_age && ageDifference <= maximum_age

    });

    ValidatorForm.addValidationRule('hasMaximumMemberCount', (relationship) => {
      const memberConfig = props.getMemberConfig(relationship)
      if (!memberConfig) return true

      const maximumMembersAllowed = memberConfig.max_count
      const totalMemberTypeAdded = props.countRelationships(relationship)

      if (totalMemberTypeAdded === maximumMembersAllowed) return false
      return true
    });


    ValidatorForm.addValidationRule('shouldAddPrincipalFirst', (value) => {
      const principalNotAdded = !find(props.dependants, { relationship: "Principal" })
      if (value !== "Principal" && principalNotAdded) {
        return false
      } else {
        return true
      }

    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidDateOfBirth');
      ValidatorForm.removeValidationRule('isValidNationalId');
      ValidatorForm.removeValidationRule('isNationalIdRequired');
      ValidatorForm.removeValidationRule('shouldAddPrincipalFirst');
      ValidatorForm.removeValidationRule('hasMaximumMemberCount');
    };

  }, [relationship, dateOfBirth])


  useEffect(() => {

    const startingIndex = internationalCallingCode.length
    const endingIndex = props.principal.msisdn.length
    const phone = props.principal.msisdn.substring(startingIndex, endingIndex)

    if (props.autoFillPrincipal) {
      setFirstname(props.principal.first_name);
      setMiddleName('');
      setLastname(props.principal.surname);
      setDateOfBirth(props.principal.date_of_birth);
      setPhoneNumber(phone);
      setNationalId(props.principal.national_id);
      setGender(props.principal.gender);
      setTown('');
      setWorkAddress(props.principal.address || '');
      setRelationship('Principal');
      props.toggleAutoFillPrincipal()
    }
  }, [props.autoFillPrincipal]);



  const triggerFieldValidation = (fieldName) => {
    if (formRef.current) {
      const field = getFormField(formRef.current.childs, fieldName)
      if (field) field.validate();
    }
  };

  const getFormField = (fields, name) => {
    for (let field of fields) {
      if (field.props.name === name) return field
    }
    return null
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'firstname') {
      setFirstname(value)
    }
    if (name === 'middlename') {
      setMiddleName(value)
    }
    if (name === 'lastname') {
      setLastname(value)
    }
    if (name === 'dateOfBirth') {
      setDateOfBirth(value)
      setExtendedMemberPremiumNotFound(false)
    }
    if (name === 'phonenumber') {
      setPhoneNumber(value)
    }
    if (name === 'nationalid') {
      setNationalId(value)
    }
    if (name === 'passport') {
      setPassport(value)
    }
    if (name === 'gender') {
      setGender(value)
    }
    if (name === 'town') {
      setTown(value)
    }
    if (name === 'workaddress') {
      setWorkAddress(value)
    }
    if (name === 'relationships') {
      setRelationship(value)
      triggerFieldValidation('nationalid');
      setDateOfBirth('')
      setNationalId('')

    }

  };
  const reset = () => {
    setFirstname('');
    setMiddleName('');
    setLastname('');
    setDateOfBirth('');
    setPhoneNumber('');
    setNationalId('');
    setGender('');
    setTown('');
    setWorkAddress('');
    setRelationship('');
    setAddOns([])
  }

  const getAge = (dateOfBirth) => {
    if (!dateOfBirth) return ""
    const age = moment().diff(dateOfBirth, 'years');
    return age;
  }

  const addDependant = () => {

    const internationalCallingCode = getPartner().country.international_calling_code
    const memberType = props.getMemberType(relationship);
    const extendedConfig = memberType === 'Extended' ? props.getExtendedMemberConfig(dateOfBirth) : null
    const extendedPremium = extendedConfig ? extendedConfig.premium_rate : 0
    const addOnPremiums = addOns.reduce((accumulator, addOn) => {
      return accumulator + addOn.premium_amount_in_cents;
    }, 0);

    if (memberType === 'Extended' && extendedPremium === 0) {
      setExtendedMemberPremiumNotFound(true)
      return
    } else {
      setExtendedMemberPremiumNotFound(false)
    }

    const totalPremium = memberType === 'Extended'
      ? extendedPremium + addOnPremiums
      : (
        relationship === 'Principal'
          ? addOnPremiums + props.selectedProduct.premiums[0].amount_in_cents
          : addOnPremiums)



    const extendedMemberBenefit = memberType === 'Extended' ? [
      {
        type: "DEATH BENEFIT",
        cover_amount_in_cents: extendedConfig.benefit_amount,
        waiting_period_in_days: props.selectedProduct.waiting_period_days || 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      }] : []

    const nuclerMemberBenefit = [{
      type: "DEATH BENEFIT",
      cover_amount_in_cents: props.selectedProduct.fixed_indemnities[0].amount_benefit * 100,
      waiting_period_in_days: props.selectedProduct.waiting_period_days || 30,
      cover_usage_count: 0,
      benefit_period_cardinality: 1,
      benefit_period_granuality: "year"
    }]

    const additionalRiderBenefit = addOns.map(addOn => {
      return {
        name: addOn.name,
        type: `ADD-ON BENEFIT ${(addOn.label).toUpperCase()}`,
        cover_amount_in_cents: addOn.benefit_amount_in_cents,
        waiting_period_in_days: props.selectedProduct.waiting_period_days || 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      }
    })

    let Allbenefitst = []
    if (memberType === 'Extended') {
      Allbenefitst = [
        ...extendedMemberBenefit,
        ...additionalRiderBenefit
      ]
    } else {
      Allbenefitst = [
        ...nuclerMemberBenefit,
        ...additionalRiderBenefit
      ]
    }



    const payload = {
      "id" : uuidv4(), 
      "first_name": firstname,
      "middle_name": middlename,
      "last_name": lastname,
      "msisdn": internationalCallingCode + phonenumber,
      "date_of_birth": dateOfBirth,
      "gender": gender,
      "national_id": isCitizen ? nationalid : passport,
      "town": town,
      "relationship": relationship,
      "work_address": workaddress,
      "type": memberType,
      "premium_amount_in_cents": totalPremium,
      "extended_premium": extendedPremium,
      "add_on_premium": addOnPremiums,
      "benefits": [
        ...Allbenefitst
      ]
    }
    console.log('after payload: ', payload);

    props.createDependant(payload);
    const tempDependants = props.dependants;
    tempDependants.push(payload);
    props.setDependants(tempDependants);
    reset();
  }


  const handleDependantCreatedSuccessfully = () => {
    props.resetAddDependantSuccessAlert();
  }
  const handleDependantCreateError = () => {
  }


  //country calling code
  const internationalCallingCode = getPartner().country.international_calling_code

  const minimum_age = ageRanges ? ageRanges.minimum_age : ''
  const maximum_age = ageRanges ? ageRanges.maximum_age : ''
  const dobErrorMessage = `Age ranges for the selected relationship are:  ${minimum_age} to ${maximum_age}, current age is ${getAge(dateOfBirth)} years`


  const memberType = props.getMemberType(relationship);
  const extendedConfig = memberType === 'Extended' ? props.getExtendedMemberConfig(dateOfBirth) : null
  const extendedPremium = extendedConfig ? extendedConfig.premium_rate : 0
  const addOnPremiums = addOns.reduce((accumulator, addOn) => {
    return accumulator + addOn.premium_amount_in_cents;
  }, 0);

  const totalPremium = memberType === 'Extended'
    ? extendedPremium + addOnPremiums
    : addOnPremiums


  const nuclear_members = props.dependants.filter(member => member.type === 'Nuclear')
  const extended_members = props.dependants.filter(member => member.type === 'Extended')

  console.log("nuclear_members", nuclear_members);
  console.log("extended_members", extended_members)

  const selectedProductAddons = props.selectedProduct.membership_configs.content.add_ons.map(addOn => {
    return {
      ...addOn,
      label: addOn.name,
      name: addOn.name.toUpperCase() + " :  (     premium: " + addOn.premium_amount_in_cents / 100 + ",   benefit: " + addOn.benefit_amount_in_cents / 100 + ")"
    }
  })

  const nuclearFamPremium = Number(props.selectedProduct.premiums[0].amount_in_cents / 100)

  const totalRidersPremium = Number(nuclear_members.reduce((accumulator, member) => {
    return accumulator + member.add_on_premium;
  }, 0))

  const totalPremiumNuclear = Number(nuclearFamPremium) + Number(totalRidersPremium / 100)

  return (
    <>

      <AlertDialog
        success
        show={props.showAddDependantSuccessAlert}
        size="sm"
        title={'Dependant created'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleDependantCreatedSuccessfully() }}
        onCancel={() => { handleDependantCreatedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={true}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>
      <AlertDialog
        info
        show={props.depAlert}
        size="sm"
        title={'Dependant  removed'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { props.setDepAlert(false) }}
        confirmBtnText={'ok'}
        showCancel={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>

      <AlertDialog
        show={props.showAddDependantErrorAlert}
        danger
        title={'Error creating Dependant'}
        onConfirm={() => handleDependantCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {'error'}
      </AlertDialog>
      <Grid container spacing={2} >
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.detailstitle}>
            <p>Add a member</p>
          </div>
        </Grid>
      </Grid>
      <ValidatorForm onSubmit={() => addDependant()} ref={formRef} >
        <section className={classes.quoteinputwrapper}>
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <SelectValidator
                labelId="select-relationship"
                id="select-relationship"
                value={relationship}
                label="Relationship"
                name='relationships'
                onChange={handleChange}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
                variant="outlined"
                validators={['required', 'hasMaximumMemberCount', 'shouldAddPrincipalFirst']}
                errorMessages={['Please select reletionship', 'You have already added maximum number of members allowed for this relationship', 'You should add principal first']}
              >
                {
                  props.relationships.map((relationship, index) => {
                    if (relationship === 'Nuclear' || relationship === 'Extended') {
                      return <MenuItem
                        disabled
                        style={{
                          fontWeight: '600',
                          marginTop: '1%',
                        }}
                      > {relationship}</MenuItem>
                    }
                    return <MenuItem key={index} sx={{
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: 300,
                      fontSize: "12px",
                      lineHeight: "16px",
                      letterSpacing: "0.005em",
                      color: "#000000",
                    }} value={relationship}>{formatStatus(relationship)}</MenuItem>
                  })
                }
              </SelectValidator>

            </Grid>
          </Grid>



          <Grid container spacing={2} >
          <Grid item xs={12}>
                    <label style={{ color: "#000", fontSize: "16px", fontWeight: "300" }}>Is Citizen</label>
                    <Switch
                      checked={isCitizen}
                      onChange={() => setIsCitizen(!isCitizen)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="First-Name"
                fullWidth
                label="First Name"
                name='firstname'
                value={firstname}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter first name']}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Middle-Name"
                fullWidth
                label="Middle Name"
                name='middlename'
                value={middlename}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Last-Name"
                fullWidth
                label="Last Name"
                name='lastname'
                value={lastname}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter Last name']}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              {
                isCitizen ?
                  <TextValidator
                    id="National Id"
                    fullWidth
                    type='number'
                    label="National ID"
                    name='nationalid'
                    value={nationalid}
                    onChange={handleChange}
                    className={classes.quoteinputs}
                    variant="outlined"
                    validators={relationship === 'Child' ? ['isValidNationalId'] : ['required', 'isValidNationalId']}
                    errorMessages={['Please enter a valid national id', southAfricanIdCheckError ? southAfricanIdCheckError : 'Invalid national Id']}

                  />
                  : <TextValidator
                    name='passport'
                    id="passport"
                    label="Passport Number"
                    value={passport}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    validators={['required', 'isValidPassport']}
                    errorMessages={['passport is required if not citizen', 'Please enter a valid passport number, should be alphanumeric and length greater than 4']}

                  />
              }

              
            </Grid>

            <Grid item xs={12} sm={4} md={4}>

              <SelectValidator
                labelId="select-gender"
                id="select-gender"
                value={gender}
                name='gender'
                type="text"
                label="Gender"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select gender']}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >
                <MenuItem sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }} value="Male">Male</MenuItem>
                <MenuItem sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }} value="Female">Female</MenuItem>

              </SelectValidator>
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Date-of-birth"
                label="Date of birth"
                name='dateOfBirth'
                value={dateOfBirth}
                onChange={handleChange}
                fullWidth
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                validators={["required", "isValidDateOfBirth"]}
                errorMessages={["Date of birth is required", dobErrorMessage]}

              />


            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Phone"
                fullWidth
                label="Phone Number"
                name='phonenumber'
                value={phonenumber}
                onChange={handleChange}
                type="number"
                className={classes.quoteinputs}
                InputProps={{
                  startAdornment: <InputAdornment
                    sx={{
                      paddingTop: "32px",
                    }}
                    position="start"
                  >{internationalCallingCode ? internationalCallingCode : ''}</InputAdornment>,
                  style: {
                    background: "#F7F7F7"
                  }
                }}
                variant="outlined"
                validators={phonenumber ? ["isValidPhoneNumber"] : []}
                errorMessages={[`Please enter a valid ${getLocalNumberLength()} digit phone number`]}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Town"
                fullWidth
                label="Town"
                name='town'
                value={town}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Work-address"
                fullWidth
                label="physical Address"
                name='workaddress'
                value={workaddress}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </section>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={6} md={6}>
            <div className={classes.benefitstitle}>
              <p>Benefits</p>
            </div>
          </Grid>

        </Grid>
        <section className={classes.benefitswrapper}>
          <Grid container spacing={2}>

            <Grid item xs={12} >
              <MultipleSelectChip
                constantFields={selectedProductAddons}
                fieldLabel="add ons"
                fields={addOns}
                setFields={setAddOns}
                multiple={true}
                displayLabel={false}
                style={{ width: '100%' }}

              />
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a style={{ marginLeft: '2%' }} onClick={() => setAddOns([])}>reset rider selection</a>
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextField
                id="Premium-in-Cents"
                fullWidth
                label="extra rider premium"
                name='premiumsincents'
                disabled
                value={addOnPremiums / 100}
                onChange={handleChange}
                defaultValue="55.00"
                InputProps={{
                  startAdornment: (<InputAdornment position="start">
                    {props.currencyCode}
                  </InputAdornment>),

                  'aria-label': props.currencyCode,
                }}
              />
            </Grid>

            {memberType === 'Extended' && <Grid item xs={12} sm={4} md={4}>
              <TextField
                id="Premium-in-Cents"
                fullWidth
                label="extended member premium"
                name='premiumsincents'
                disabled
                value={extendedPremium / 100}
                onChange={handleChange}
                defaultValue="55.00"
                InputProps={{
                  startAdornment: (<InputAdornment position="start">
                    {props.currencyCode}
                  </InputAdornment>),

                  'aria-label': props.currencyCode,
                }}
              />
              {extendedMemberPremiumNotFound && <p style={{ color: 'red' }}>
                Could not find a premium for this extended member!
                Please update your product to include premium for this age range
              </p>}
            </Grid>}

            <Grid item xs={12} sm={4} md={4}>
              <TextField
                id="Premium-in-Cents"
                fullWidth
                label="Total extra premium"
                name='premiumsincents'
                disabled
                value={totalPremium / 100}
                onChange={handleChange}
                defaultValue="55.00"
                InputProps={{
                  startAdornment: (<InputAdornment position="start">
                    {props.currencyCode}
                  </InputAdornment>),

                  'aria-label': props.currencyCode,
                }}
              />
            </Grid>

          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.quotesbtns}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type='submit'
                //onClick={addDependant}
                >
                  Add  Dependant
                </Button>

              </div>
            </Grid>
          </Grid>
        </section>
      </ValidatorForm >
      {
        props.dependants.length > 0 && <h3>Added members</h3>
      }
      {
        nuclear_members.length > 0 && <section className={classes.dependantdetailstable}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <h6>Nuclear members</h6>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>

              <div className={classes.dependantWrapper}>

                <table>
                  <tr>
                    <th>Dependant Name</th>
                    <th>Reletionship</th>
                    <th>Benefit amount</th>
                    <th> Rider Premium </th>
                    <th>Action</th>
                  </tr>
                  {nuclear_members.map((d, id) => {
                    return <tr >
                      <td>{d.first_name + " " + d.last_name}</td>
                      <td>{d.relationship}</td>
                      <td>
                        <ol>
                          {d.benefits.map(b => {
                            return <li>{b.type + " "} {<strong>{props.currencyCode + " " + (b.cover_amount_in_cents / 100).toLocaleString()}</strong>}</li>
                          })}
                        </ol>
                      </td>
                      <td>{props.currencyCode + " " + d.add_on_premium / 100}</td>

                      <th><DeleteIcon style={{ cursor: "pointer" }} onClick={() => { props.removeDependant(d.id) }} /></th>
                    </tr>
                  })}
                </table>


              </div>
            </Grid>
          </Grid>
          {<p style={{ textAlign: 'right' }}>nuclear premum total:  ZAR {totalPremiumNuclear}</p>}
        </section>
      }

      {
        extended_members.length > 0 && <section className={classes.dependantdetailstable}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <h6>Extended members</h6>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>

              <div className={classes.dependantWrapper}>

                <table>
                  <tr>
                    <th>Dependant Name</th>
                    <th>Reletionship</th>
                    <th>Benefit amount</th>
                    <th> Extended Premium </th>
                    <th> Rider Premium </th>
                    <th> Premium </th>
                    <th>Action</th>
                  </tr>
                  {extended_members.map((d) => {
                    return <tr >
                      <td>{d.first_name + " " + d.last_name}</td>
                      <td>{d.relationship}</td>
                      <td>
                        <ol>
                          {d.benefits.map(b => {
                            return <li>{b.type + " "} {<strong>{props.currencyCode + " " + (b.cover_amount_in_cents / 100).toLocaleString()}</strong>}</li>
                          })}
                        </ol>
                      </td>
                      <td>{props.currencyCode + " " + d.extended_premium / 100}</td>
                      <td>{props.currencyCode + " " + d.add_on_premium / 100}</td>
                      <td>{props.currencyCode + " " + d.premium_amount_in_cents / 100}</td>
                      <th><DeleteIcon style={{ cursor: "pointer" }} onClick={() => { props.removeDependant(d.id) }} /></th>
                    </tr>
                  })}
                </table>


              </div>
            </Grid>
          </Grid>
          {<p style={{ textAlign: 'right' }} >extended premum total:  ZAR {Number(extended_members.reduce((accumulator, member) => {
            return accumulator + member.premium_amount_in_cents;
          }, 0) / 100)}</p>}
        </section>
      }
    </>
  )
}

export default Adddependant