
import * as types from '../../../../../../lib/actionTypes.es6'

export const getAllAcessBankCustomerPremiumsRequest = (payload) =>{
  return{
    type: types.GET_ALL_ACCESSBANK_CUSTOMER_PREMIUMS_REQUEST,
    payload,
  }
}

export const getAllAccessBankPremiumsSuccess = (payload) =>{
  return{
    type: types.GET_ALL_ACCESSBANK_CUSTOMER_PREMIUMS_SUCCESS,
    payload: payload,
  }
}
export const getAllAccessBankPremiumsFailure = (error) =>{
  return{
    type: types.GET_ALL_ACCESSBANK_CUSTOMER_PREMIUMS_FAILURE,
    payload: error,
  }
}