import React, { useState } from 'react'
import { useStyle } from "./Style";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { gettestCredentials, createCustomerprofile, queryavailableproducts, buyapolicy, makeapayment, makeaclaim } from './CodeSnippets'
import BuiltForDevelopersMobile from './BuiltForDevelopersMobile';
import Button from '@mui/material/Button';


import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//media query and breakpoints and MU theme imports
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const BuiltForDevelopers = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const classes = useStyle();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <section className={classes.developers} id="developers">
            <Container>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <h2>Built With Developers in Mind</h2>
                        <div className={classes.devtext}>
                            <p>Looking to embed digital insurance capabilities into your existing application or build a fully-fledged insurance application? Look no further than ASPin! Our API-first approach means we provide a well-documented, easy-to-use RESTful API that any software developer can integrate with ease. Say goodbye to reinventing the wheel and hello to focusing on the business side of things as we handle the technology infrastructure for you. Get started with ASPin today and see the difference API-first can make!</p>
                            <Button>
                                <a href='https://apidocs.inclusivitysolutions.com/' target='_blank' rel='noopener noreferrer'> View Documentation</a>
                            </Button>
                        </div>

                    </Grid>
                </Grid>
            </Container>
            <Container>
                <Grid container>
                    <Grid item xs={12} md={12} sm={12}>
                        <div className={classes.tabswrapper}>
                            <Box sx={{ width: '100%' }}>
                                {
                                    isMobile ? (<BuiltForDevelopersMobile />) : (<>
                                        <Box>
                                            <Tabs centered value={value} onChange={handleChange} aria-label="basic tabs example"
                                                TabIndicatorProps={{
                                                    sx: { backgroundColor: "#FBA92D" }
                                                }}
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                sx={{

                                                    "& button": {
                                                        height: "50px",
                                                        marginLeft: "20px",
                                                        background: "#D9D9D9",
                                                        fontFamily: "Lato",
                                                        fontStyle: "normal",
                                                        fontSize: "12px",
                                                        lineHeight: "20px",
                                                        textAlign: "center",
                                                        letterSpacing: "0.001em",
                                                    },
                                                    "& button:active": {
                                                        background: '#FBA92D',
                                                    },
                                                    "& button.Mui-selected": {
                                                        background: '#FBA92D',
                                                        color: "#fff"
                                                    }
                                                }}>
                                                <Tab label="Step 1: Get test credentials" {...a11yProps(0)} />
                                                <Tab label="Step 2: create a customer profile" {...a11yProps(1)} />
                                                <Tab label="Step 3: Query available products" {...a11yProps(2)} />
                                                <Tab label="Step 4: Buy a policy cover" {...a11yProps(3)} />
                                                <Tab label="Step 5: Make Payment" {...a11yProps(4)} />
                                                <Tab label="Step 6: Make a Claim" {...a11yProps(5)} />
                                            </Tabs>
                                        </Box>
                                        <TabPanel value={value} index={0}>
                                            <div className={classes.codeformat}>
                                                <SyntaxHighlighter language="javascript" style={dark} showLineNumbers>
                                                {gettestCredentials}
                                                </SyntaxHighlighter>
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                            <div className={classes.codeformat}>
                                                <SyntaxHighlighter language="javascript" style={dark} showLineNumbers>
                                                    {createCustomerprofile}
                                                </SyntaxHighlighter>
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={value} index={2}>
                                            <div className={classes.codeformat}>
                                                <SyntaxHighlighter language="javascript" style={dark} showLineNumbers>
                                                    {queryavailableproducts}
                                                </SyntaxHighlighter>
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={value} index={3}>
                                            <div className={classes.codeformat}>
                                                <SyntaxHighlighter language="javascript" style={dark} showLineNumbers>
                                                    {buyapolicy}
                                                </SyntaxHighlighter>
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={value} index={4}>
                                            <div className={classes.codeformat}>
                                                <SyntaxHighlighter language="javascript" style={dark} showLineNumbers>
                                                    {makeapayment}
                                                </SyntaxHighlighter>
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={value} index={5}>
                                            <div className={classes.codeformat}>
                                                <SyntaxHighlighter language="javascript" style={dark} showLineNumbers>
                                                    {makeaclaim}
                                                </SyntaxHighlighter>
                                            </div>
                                        </TabPanel>
                                    </>)
                                }
                            </Box>
                        </div>

                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default BuiltForDevelopers