import { take, call, put } from 'redux-saga/effects';
import { uploadTigoLoyaltyEligibility } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import * as types from '../../lib/actionTypes.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';

/**
 * this saga continually watches upload tigo loyalty request actions
 */
export function* tigoUploadLoyaltyRequestWatcher() {
  for (;;) {
    // pause and wait for the TIGO_UPLOAD_LOYALTY_REQUEST action to be dispatched
    const uploadData = yield take(types.TIGO_UPLOAD_LOYALTY_REQUEST);
    try {
      const response = yield call(
        uploadTigoLoyaltyEligibility,
        uploadData.payload
      );
      yield put(actions.tigoLoyaltyUploadSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.tigoLoyaltyUploadSuccess(errorMessage));
      unAuthorizedNotification(e);
    }
  }
}
