import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import 'react-table/react-table.css';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

import LocalizedStrings from 'react-localization';
import Grid from '@mui/material/Grid';
import { localisedText } from '../../lib/localisation.es6';
import { getPartner } from '../../lib/access.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import * as actions from './actions.es6';
import SkeletonWrapper from '../../components/SkeletonWrapper/SkeletonWrapper';

import ViewPartnerDocuments from './components/ViewPartnerDocuments';
import { AddPartnerDocument } from './components/AddPartnerDocument';
import ProductsHeader from '../NewProductList/components/ProductsHeader/ProductsHeader';
import { getEnvironmentInformation } from '../../lib/utils.es6';

export const documentTypes = [
  { value: 'Customer', label: 'CustomerTemplate' },
  { value: 'Claim', label: 'ClaimTemplate' },
  { value: 'Policy', label: 'PolicyTemplate' },
  { value: 'LOU', label: 'LOUTemplate' },
];

export function DocumentGeneration(props) {
  const { dispatch } = props;

  const { language } = props.globalData;

  const {
    templateDocuments,
    getTemplateDocumentloader,
    addTemplateDocumentLoader,
    deleteTemplateDocumentLoader,
  } = props.documentsData;

  const [labels, setLabels] = useState(null);
  const [partner, setPartner] = useState(getPartner());
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [s3Url, setS3Url] = useState(null);

  useEffect(() => {
    setLabels(new LocalizedStrings(localisedText));
    setPartner(getPartner());
    return function cleanup() {};
  }, []);

  useEffect(() => {
    if (!partner || !labels || !language) {
      return;
    }
    updateCurrentLanguage(language, 'PartnerDocuments', labels);

    dispatch(actions.getTemplateDocumentsRequest());

    const environment = getEnvironmentInformation();
    if (environment && environment.servicesURLs.enviromentType === 'prod') {
      setS3Url('https://policy-document.s3.amazonaws.com');
    } else {
      setS3Url('https://policy-document.s3.amazonaws.com');
    }

    return function cleanup() {};
  }, [dispatch, language, labels, partner]);

  const onSave = payload => {
    setShowAddDialog(false);
    dispatch(actions.addTemplateDocumentRequest(payload));
  };

  const onEdit = payload => {
    dispatch(actions.editTemplateDocumentRequest(payload));
  };

  const onDelete = payload => {
    dispatch(actions.deleteTemplateDocumentRequest(payload));
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" style={{ marginTop: '2%' }}>
        <Grid container spacing={2}>
          {!labels ||
          getTemplateDocumentloader ||
          addTemplateDocumentLoader ||
          deleteTemplateDocumentLoader ? (
            <Grid item xs={12}>
              <SkeletonWrapper
                loading={
                  getTemplateDocumentloader ||
                  addTemplateDocumentLoader ||
                  deleteTemplateDocumentLoader
                }
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <ProductsHeader
                  title={labels.Templates}
                  onAddButtonClick={() => setShowAddDialog(true)}
                  showAddButton
                />
              </Grid>
              <Grid item xs={12}>
                <ViewPartnerDocuments
                  documents={templateDocuments}
                  onDelete={onDelete}
                  labels={labels}
                  s3Url={s3Url}
                  onEdit={onEdit}
                />
              </Grid>
            </>
          )}
          {labels && showAddDialog && (
            <AddPartnerDocument
              show={showAddDialog}
              labels={labels}
              onSave={onSave}
              s3Url={s3Url}
            />
          )}
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default connect(state => ({
  documentsData: state.documentGeneration,
  globalData: state.global,
}))(DocumentGeneration);
