import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import 'react-table/react-table.css';
import CssBaseline from '@mui/material/CssBaseline';


import TableInc from '../../shared-ui/TableInc';
import BoxInc from '../../shared-ui/BoxInc/Index';
import { hasAuthourity } from '../../lib/access.es6';
import { Chip } from '@mui/material';
import { addFeatureFlag, deleteFeatureFlag, updateFeatureFlag } from '../../lib/requests.es6';
import SkeletonWrapper from '../NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import { getFeatureFlagsRequest } from './actions.es6';


export function FeatureFlags(props) {


  useEffect(() => {
    fetFeatureFlags()
  }, [])

  const fetFeatureFlags =() => {
    props.dispatch(getFeatureFlagsRequest())
  }

  const featureFlags = props.featureFlagsData.featureFlags
  const featureFlagsLoading = props.featureFlagsData.getFeatureFlagsloader

  return (
    <React.Fragment>
      <CssBaseline />
      <BoxInc
        expanded
        title="Feature Flags"
        hasPermision={hasAuthourity('BULK_ONBOARD_CUSTOMERS')}
        actionButtons={[
        ]
        }

      >
        {
          featureFlagsLoading
            ? <SkeletonWrapper loading={featureFlagsLoading} />
            : <TableInc
              title="Extended members relationship"
              columns={[
                { title: 'Feature Name', field: 'name', editable: 'onAdd' },
                {
                  title: 'Is Enabled', field: 'is_enabled', lookup: { true: 'true', false: 'false' }, render: (rowData) => rowData.is_enabled

                    ? <Chip sx={{
                      color: "#fff",
                      bgcolor: "#75A85E"
                    }} label={'True'} />

                    : <Chip sx={{
                      color: "#fff",
                      bgcolor: "red"
                    }} label={'False'} />
                },
              ]}
              data={
                featureFlags
              }

              options={{
                pageSize: featureFlags.length,
                showTitle: false,
                actionsColumnIndex: -1,
                addRowPosition: 'first'

              }}

              editable={{
                onRowAdd: newData =>
                  new Promise((resolve, reject) => {
                    const formattedData = {
                      "name": newData.name,
                      "is_enabled": newData.is_enabled === 'true' ? true : false,
                    };
                    addFeatureFlag(formattedData).then(
                      (response) => {
                        resolve();
                        fetFeatureFlags()
                      }
                    ).catch(
                      (error) => {
                        console.log("did not work", error)
                        reject();
                      }
                    )

                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    const formattedData = {
                      "name": newData.name,
                      "is_enabled": newData.is_enabled === 'true' ? true : false,
                    };
                    updateFeatureFlag(formattedData).then(
                      (response) => {
                        resolve();
                        fetFeatureFlags()
                      }
                    ).catch(
                      (error) => {
                        console.log("did not work", error)
                        reject();
                      }
                    )

                  }),
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    const formattedData = {
                      "name": oldData.name,
                      "is_enabled": oldData.is_enabled === 'true' ? true : false,
                    };
                    deleteFeatureFlag(formattedData).then(
                      (response) => {
                        resolve();
                        fetFeatureFlags()
                      }
                    ).catch(
                      (error) => {
                        console.log("did not work", error)
                        reject();
                      }
                    )

                  }),
              }}
            />
        }



      </BoxInc>
    </React.Fragment>
  );
}

export default connect(state => ({
  featureFlagsData: state.featureFlags,
  globalData: state.global,
}))(FeatureFlags);
