import * as types from '../../../lib/actionTypes.es6';

export function getCurrentGroupMembersRequest(payload) {
  return {
    type: types.GET_CURRENT_GROUP_MEMBERS_REQUEST,
    payload,
  };
}

export function getCUrrentGroupMembersSuccess(response) {
  return {
    type: types.GET_CURRENT_GROUP_MEMBERS_SUCCESS,
    payload: response,
  };
}

export function getCUrrentGroupMembersFailed(error) {
  return {
    type: types.GET_CURRENT_GROUP_MEMBERS_FAILURE,
    payload: error,
  };
}

export function getCurrentGroupMembersForAdminsRequest(payload) {
  return {
    type: types.GET_CURRENT_GROUP_MEMBERS_FOR_ADMINS_REQUEST,
    payload,
  };
}

export function getCUrrentGroupMembersForAdminsSuccess(response) {
  return {
    type: types.GET_CURRENT_GROUP_MEMBERS_FOR_ADMINS_SUCCESS,
    payload: response,
  };
}

export function getCUrrentGroupMembersForAdminsFailed(error) {
  return {
    type: types.GET_CURRENT_GROUP_MEMBERS_FOR_ADMINS_FAILURE,
    payload: error,
  };
}

export function resetGetCUrrentGroupMembersForAdmins() {
  return {
    type: types.RESET_GET_CURRENT_GROUP_MEMBERS_FOR_ADMINS,
  };
}

export function getCurrentGroupMembersForCSVRequest(payload) {
  return {
    type: types.GET_CURRENT_GROUP_MEMBERS_FOR_CSV_REQUEST,
    payload,
  };
}

export function getCUrrentGroupMembersForCSVSuccess(response) {
  return {
    type: types.GET_CURRENT_GROUP_MEMBERS_FOR_CSV_SUCCESS,
    payload: response,
  };
}

export function getCUrrentGroupMembersForCSVFailed(error) {
  return {
    type: types.GET_CURRENT_GROUP_MEMBERS_FOR_CSV_FAILURE,
    payload: error,
  };
}

export function resetGetCUrrentGroupMembersForCSV() {
  return {
    type: types.RESET_GET_CURRENT_GROUP_MEMBERS_FOR_CSV,
  };
}


export function getCurrentGroupPoliciesRequest(payload) {
  return {
    type: types.GET_CURRENT_GROUP_POLICIES_REQUEST,
    payload,
  };
}

export function getCurrentGroupPoliciesSuccess(response) {
  return {
    type: types.GET_CURRENT_GROUP_POLICIES_SUCCESS,
    payload: response,
  };
}

export function getCurrentGroupPoliciesFailed(error) {
  return {
    type: types.GET_CURRENT_GROUP_POLICIES_FAILURE,
    payload: error,
  };
}


export function getCurrentGroupPremiumsRequest(payload) {
  return {
    type: types.GET_CURRENT_GROUP_PREMIUMS_REQUEST,
    payload,
  };
}

export function getCurrentGroupPremiumsSuccess(response) {
  return {
    type: types.GET_CURRENT_GROUP_PREMIUMS_SUCCESS,
    payload: response,
  };
}

export function getCurrentGroupPremiumsFailed(error) {
  return {
    type: types.GET_CURRENT_GROUP_PREMIUMS_FAILURE,
    payload: error,
  };
}




export function getCurrentGroupPaymentsRequest(payload) {
  return {
    type: types.GET_CURRENT_GROUP_PAYMENTS_REQUEST,
    payload,
  };
}

export function getCurrentGroupPaymentsSuccess(response) {
  return {
    type: types.GET_CURRENT_GROUP_PAYMENTS_SUCCESS,
    payload: response,
  };
}

export function getCurrentGroupPaymentsFailed(error) {
  return {
    type: types.GET_CURRENT_GROUP_PAYMENTS_FAILURE,
    payload: error,
  };
}



export function getCurrentGroupClaimsRequest(payload) {
  return {
    type: types.GET_CURRENT_GROUP_CLAIMS_REQUEST,
    payload,
  };
}

export function getCurrentGroupClaimsSuccess(response) {
  return {
    type: types.GET_CURRENT_GROUP_CLAIMS_SUCCESS,
    payload: response,
  };
}

export function getCurrentGroupClaimsFailed(error) {
  return {
    type: types.GET_CURRENT_GROUP_CLAIMS_FAILURE,
    payload: error,
  };
}

export function getCurrentGroupClaimCallbacksRequest(payload) {
  return {
    type: types.GET_CURRENT_GROUP_CLAIM_CALLBACKS_REQUEST,
    payload,
  };
}

export function getCurrentGroupClaimCallbacksSuccess(response) {
  return {
    type: types.GET_CURRENT_GROUP_CLAIM_CALLBACKS_SUCCESS,
    payload: response,
  };
}

export function getCurrentGroupClaimCallbacksFailed(error) {
  return {
    type: types.GET_CURRENT_GROUP_CLAIM_CALLBACKS_FAILURE,
    payload: error,
  };
}