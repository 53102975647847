import { Button } from '@mui/material'
import { useStyle } from "./Style";
import React from 'react'
import loader from './loader.svg'

export default function ButtonInc(props) {
  const classes = useStyle();
  const { btnType, ...otherProps } = props;

  if(props.hasPermision === false){
    return null
  }

  const buttonClass = btnType === 'secondary' ? classes.SecondaryButton : classes.ButtonInc;

  if (props.loading) {
    return <div className={buttonClass}>
      <Button
        {...otherProps}
      >
        <img src={loader} alt="loader" />
      </Button>
    </div>
  }
  return (
    <div className={buttonClass}>
      <Button
        {...otherProps}
      >{props.children}</Button>
    </div>
  )
}
