import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import Button from '@mui/material/Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { hasAuthourity } from '../../../../../lib/access.es6';
import HelpDrawerInc from '../../../../../shared-ui/HelpDrawerInc';


const PolicyHeader = (props) => {
	const classes = useStyle();
	return (


		<section className={classes.policyheader}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={5} md={5}>
					<div className={classes.policytitle}>
						<h1>Customer Status</h1>
					</div>
				</Grid>

				<Grid item xs={12} sm={7} md={7} >
					<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center',  }}>
					<div className={classes.registerbtn}>
							<Button style={{
								display: hasAuthourity('ACCESSBANK_REGISTER_CUSTOMER') ? 'inline-flex' : 'none',
							
							}} variant="contained" endIcon={<AddOutlinedIcon />} onClick={props.open}>
								Register Customer
							</Button>
						</div>

						<HelpDrawerInc section="customer" marginTop="5px" marginRight="25px" />
					
					</div>

				</Grid>
			</Grid>
		</section>
	)
}

export default PolicyHeader