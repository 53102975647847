import { take, call, put } from 'redux-saga/effects';
import { purchasePolicy, listPaidProducts } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import * as types from '../../lib/actionTypes.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';
import { getAllPoliciesRequest } from '../CustomerScreen/actions.es6';

export function* purchasePolicyRequestWatcher() {
  for (;;) {
    const requestAction = yield take(types.PURCHASE_POLICY_REQUEST);
    let response = {};
    try {
      response = yield call(purchasePolicy, requestAction.payload);
      yield put(actions.purchasePolicySuccess(response.data));
      yield put(getAllPoliciesRequest({msisdn: requestAction.payload.msisdn}));

    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.purchasePolicyFail(errorPayload));
    }
  }
}

export function* productListWatcher() {
  for (;;) {
    // NOTE: Do we gain anything ny moving this in the while clause??
    yield take(types.PAID_PRODUCT_LIST_REQUEST);
    let response = {};
    try {
      response = yield call(listPaidProducts);
      yield put(actions.listPaidProductsSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.listPaidProductsFail(errorPayload));
    }
  }
}
