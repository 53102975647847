import * as types from '../../lib/actionTypes.es6';

export function searchCustomerByMsisdnRequest(customerData) {
  return {
    type: types.SEARCH_CUSTOMER_BY_MSISDN_REQUEST,
    payload: customerData,
  };
}

export function searchCustomerByMsisdnSuccess(customerInfo) {
  return {
    type: types.SEARCH_CUSTOMER_BY_MSISDN_SUCCESS,
    payload: customerInfo,
  };
}

export function searchCustomerByMsisdnFail(response) {
  return {
    type: 'SEARCH_CUSTOMER_BY_MSISDN_FAIL',
    payload: response,
  };
}

export function resetSearchFlag() {
  return {
    type: 'RESET_SEARCH_FLAG',
  };
}

export function updatePageSize(size) {
  return {
    type: 'UPDATE_PAGE_SIZE',
    payload: size,
  };
}

export function resetSearchResults() {
  return {
    type: 'RESET_SEARCH_RESULTS',
  };
}

export function setCurrentCustomer(guid) {
  return {
    type: 'SET_CURRENT_CUSTOMER',
    payload: guid,
  };
}
