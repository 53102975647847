import React from 'react'
import TableInc from '../../../../../shared-ui/TableInc';
import { addCustomerBeneficiaryBankDetails } from '../../../../../lib/requests.es6';
import { SOUTH_AFRICA_BANKS_NAME, SOUTH_AFRICA_BANKS_CODES } from '../../../../../containers/NewProductList/PoductConstants';


const BeneficiaryBankDetails = (props) => {
 if(props.banksDetails){

  const bankdetails = [props.banksDetails]
  return (
    <>
       <TableInc
          columns={[
            { title: 'Account Name', field: 'account_name' },
            { title: 'Bank Name', field: 'bank_name' , lookup: SOUTH_AFRICA_BANKS_NAME },
            { title: 'Account Number', field: 'account_number' },
            { title: 'Account Type', field: 'account_type' },
            { title: 'Branch Code', field: 'branch_code',lookup: SOUTH_AFRICA_BANKS_CODES  },
          ]}
          data={
            bankdetails
          }
          options={{
            pageSize: bankdetails.length + 1,
            showTitle: false,
            actionsColumnIndex: -1
  
          }}
  
          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
  
                const bankDetails = {
                  "bank_name": newData.bank_name,
                  "account_name": newData.account_name,
                  "account_number": newData.account_number,
                  "account_type": newData.account_type,
                  "branch_code": newData.branch_code,
                  "customer_guid": props.customerGuid,
                  "beneficiary_guid": props.beneficiaryGuid,
                }
                console.log("bankDetails", bankDetails)
                addCustomerBeneficiaryBankDetails(bankDetails).then(
                  (response) => {
                    console.log("here is response", response);
                    resolve();
                    window.location.reload();
                  }
                ).catch(
                  (error) => {
                    console.log("did not work", error)
                    reject();
                  }
                )
  
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                console.log('you are deleting this: ', oldData)
                // this.deletePrincipal(oldData);
                resolve();
              }),
          }}
          />
    </>
  )
 }

}

export default BeneficiaryBankDetails