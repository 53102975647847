import * as types from '../../../../../../lib/actionTypes.es6';
import GAlogger, { monitoringEventCategories } from '../../../../../../lib/monitoring.es6';

export function resetAccessBankRegistration() {
  return {
    type: types.RESET_ACCESS_BANK_REGISTRATION,
  };
}

export function registerAcessBankCustomerRequest(customerData) {
  return {
    type: types.REGISTER_ACCESS_BANK_CUSTOMER_REQUEST,
    payload: customerData,
  };
}

export function registerAcessBankCustomerSuccess(customerInfo) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.registerCustomer);
  return {
    type: types.REGISTER_ACCESS_BANK_CUSTOMER_SUCCESS,
    payload: customerInfo,
  };
}

export function registerAccessBankCustomerFail(response) {
  return {
    type: types.REGISTER_ACCESS_BANK_CUSTOMER_FAIL,
    payload: response,
  };
}

export function displayAcessBankError(message) {
  return {
    type: types.SET_ACCESS_BANK_REGISTRATION_ERROR,
    payload: message,
  };
}



export function updateAccessBankCustomerRequest(customerData) {
  return {
    type: types.UPDATE_ACCESS_BANK_CUSTOMER_REQUEST,
    payload: customerData,
  };
}

export function updateAccessBankCustomerSuccess(customerInfo) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.updateCustomer);
  return {
    type: types.UPDATE_ACCESS_BANK_CUSTOMER_SUCCESS,
    payload: customerInfo,
  };
}

export function updateAccessBankCustomerFail(response) {
  return {
    type: types.UPDATE_ACCESS_BANK_CUSTOMER_FAIL,
    payload: response,
  };
}

export function resetAccessBankUpdateCustomer() {
  return {
    type: types.UPDATE_CUSTOMER_ACCESS_BANK_RESET,
  };
}

export function displayEditAccessBankError(error) {
  return {
    type: types.EDIT_ACCESS_BANK_CUSTOMER_DISPLAY_ERROR,
    payload: error,
  };
}