const getBenefits = (member, newPlan) => {

  const benefits = [];
  const memberType = getMemberType(member.dependant.relationship, newPlan)

  if (memberType === "Nuclear") {
    const nuclerMemberBenefit = {
      type: "DEATH BENEFIT",
      cover_amount_in_cents: newPlan.fixed_indemnities[0].amount_benefit * 100,
      waiting_period_in_days: 30,
      cover_usage_count: 0,
      benefit_period_cardinality: 1,
      benefit_period_granuality: "year"
    }
    // console.log('Nuclear benfit: ', nuclerMemberBenefit);
    benefits.push(nuclerMemberBenefit);
  }
  if (memberType === "Extended") {
    const premium = getExtendedMemberConfig(member.dependant.date_of_birth, newPlan)
    const extendedMemberBenefit = {
      type: "DEATH BENEFIT",
      cover_amount_in_cents: premium.benefit_amount,
      waiting_period_in_days: 30,
      cover_usage_count: 0,
      benefit_period_cardinality: 1,
      benefit_period_granuality: "year"
    }
    // console.log('Extended benfit: ', extendedMemberBenefit);
    benefits.push(extendedMemberBenefit);
  }
  const addOnsBenefit = getAddOnBenefits(member, newPlan);


  return [...benefits, ...addOnsBenefit]
}

const getAddOnBenefits = (member, newPlan) => {
  const benefits = []
  const currentAddOnBenefits = member.benefits.filter(benefit => benefit.type.includes("ADD-ON"))


  if (currentAddOnBenefits.length < 1) return []

  const planAddOns = newPlan.membership_configs.content.add_ons

  for (let benefit of currentAddOnBenefits) {
    const addOnBenefit = getAddOnBenefit(benefit.type, planAddOns)



    if (addOnBenefit) {
      const formatted = {
        type: "ADD-ON BENEFIT " + addOnBenefit.name.toUpperCase(),
        cover_amount_in_cents: addOnBenefit.benefit_amount_in_cents,
        premium_amount_in_cents: addOnBenefit.premium_amount_in_cents,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      }
      // console.log({
      //   planAddOns,
      //   addOnBenefit,
      //   formatted
      // });
      benefits.push(formatted)
    }
  }

  return benefits
}

const getAddOnBenefit = (type, addOns) => {
  const name = type.split("ADD-ON BENEFIT ")[1].toLowerCase()

  const add_on = addOns.find((addOn) => {
    return addOn.name.toLowerCase() === name;
  });

  return add_on
}

const getMemberType = (relationship, newPlan) => {
  const nuclear = newPlan ? newPlan.membership_configs.content.nuclear.map(item => item.relationship) : [];


  const extended = newPlan ? newPlan.membership_configs.content.extended.map(item => item.relationship) : [];

  if (nuclear.includes(relationship)) return 'Nuclear'
  if (extended.includes(relationship)) return 'Extended'
  return 'N/A'
}

const getExtendedMemberConfig = (dateOfBirth, newPlan) => {
  const age = getAge(dateOfBirth);

  const extendedPremiums = newPlan.membership_configs
    ? (newPlan.membership_configs.content.premiums ? newPlan.membership_configs.content.premiums : [])
    : []

  const premium = extendedPremiums.find((premium) => {
    return age >= premium.entry_age && age <= premium.exit_age;
  });

  if (premium) {
    return premium;
  } else {
    return 0;
  }
};

function getAge(dateOfBirth) {
  const birthDate = new Date(dateOfBirth);
  const currentDate = new Date();

  const age = currentDate.getFullYear() - birthDate.getFullYear();
  // const age2 = moment().diff(dob, 'years');
  console.log({
    // age, age2
  });
  return age;

}

 export const generateNewQoutePayload = (policy, newPlan) => {

  // console.log('generateNewQoutePayload: ', { policy, newPlan });
  if (!policy && !newPlan) return null;

  const dependantsWithCurrentPlan = policy.quotation.policy_schedule_entries
  const dependantsWithNewPlan = dependantsWithCurrentPlan.map(entry => {
    const benefits = getBenefits(entry, newPlan)
    return {
      ...entry.dependant,
      benefits: benefits,
      premium_amount_in_cents: getPremiumInCents(entry, newPlan, benefits)
    }
  })

  // console.log('newPlan: ', newPlan);
  // console.log('dependantsWithCurrentPlan: ', dependantsWithCurrentPlan);
  // console.log('dependantsWithNewPlan: ', dependantsWithNewPlan);

  const newQoutePayload = {
    "product_guid": newPlan.guid,
    "product_name": newPlan.name,
    "product_code": newPlan.code,
    "customer_guid": policy.customer_guid,
    "partner": policy.partner,
    "lapse_period": 3,
    "premium_granularity": "month",
    "premium_cardinality": 1,
    "policy_schedule_entries": [
      ...dependantsWithNewPlan
    ],
    total_premmius: (dependantsWithNewPlan.reduce((psum, dependant) => psum + dependant.premium_amount_in_cents, 0))
  }
  return newQoutePayload
}

const getPremiumInCents = (entry, newPlan, benefits) => {
  // console.log('>>>>>>>>=<<<<<<<<<<<');
  // console.log('>>>>>>>>=<<<<<<<<<<<');
  // console.log('>>>>>>>>=<<<<<<<<<<<');
  // console.log('getting premium');
  // console.log('entry: ', entry);

  const nuclearPremium = getNuclearPremium(entry, newPlan)
  const extendedPremium = getExtendedPremium(entry, newPlan)
  const addOnsPremium = getAddOnPremium(entry, newPlan, benefits)


  let premium_amount_in_cents = 0;
  if (nuclearPremium) {
    premium_amount_in_cents = premium_amount_in_cents + nuclearPremium
  }

  if (extendedPremium) {
    premium_amount_in_cents = premium_amount_in_cents + extendedPremium
  }

  if (addOnsPremium) {
    premium_amount_in_cents = premium_amount_in_cents + addOnsPremium
  }

  // console.log('nuclearPremium: ', nuclearPremium);
  // console.log('extendedPremium: ', extendedPremium);
  // console.log('addOnsPremium: ', addOnsPremium);
  // console.log('premium_amount_in_cents: ', premium_amount_in_cents);
  // console.log('           ');
  // console.log('end premiums');
  // console.log('                ');
  // console.log('                ');

  return premium_amount_in_cents
}

const getNuclearPremium = (entry, newPlan) => {
  if (entry.dependant.relationship === 'Principal') {
    return newPlan.premiums[0].amount_in_cents
  }
}

const getExtendedPremium = (member, newPlan) => {
  const memberType = getMemberType(member.dependant.relationship, newPlan)

  if (memberType === 'Extended') {
  const premium = getExtendedMemberConfig(member.dependant.date_of_birth, newPlan)

  if (premium) {
    return premium.premium_rate
  }

  return 0
}

}
const getAddOnPremium = (member, newPlan, benefits) => {
  let total = 0;
  for (let benefit of benefits) {
    if (benefit.type.includes('ADD-ON')) {
      total = total + benefit.premium_amount_in_cents
      // console.log('add ben: ', benefit);
    }
  }
  return total
}




 export const generateChangeQoutePayload = (newQuote, existingPolicy, effected_At) => {
  return {
    "policy_guid": existingPolicy.guid,
    "quotation_guid": newQuote.guid,
    "customer_guid": newQuote.customer_guid,
    "start_date": effected_At,
  }
}

// generateNewQoutePayload(tempPolicyPayload, tempPayload)
// console.log(generateNewQoutePayload(tempPolicyPayload, tempPayload))


