import React from "react";
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import ButtonInc from "../../../../shared-ui/ButtonInc";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

const Header = ({
  claim,
  labels,
  showAssessmentButton,
  toggleAssesmentDialog,
}) => {
  const classes = useStyle();

  const isApprovedStatus = [
    "Approved_Pending",
    "Approved_Paid",
    "Approved",
  ].includes(claim.status);

  return (
    <section className={classes.claimsheader}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <div className={classes.claimstitle}>
            <ul>
              <div className={classes.leftItems}>
                <li>{claim.guid || "N/A"}</li>
                <li> - {claim.type.replaceAll("_", " ") || "N/A"}</li>
                <li>
                  <span
                    className={
                      isApprovedStatus
                        ? classes.claimStatussuccess
                        : classes.claimStatusdanger
                    }
                  >
                    {claim.status.replaceAll("_", " ") || "N/A"}
                  </span>
                </li>
              </div>
              <div className={classes.rightItems}>
                {showAssessmentButton && (
                  <li>
                    <ButtonInc
                      className={classes.aibtn}
                      onClick={toggleAssesmentDialog}
                      variant="contained"
                      color="primary"
                    >
                      <span style={{ color: "#fff" }}>View AspinAI assesment</span>
                      <AutoFixHighIcon
                        sx={{
                          color: "white",
                          fontSize: "16px",
                        }}
                      />
                    </ButtonInc>
                  </li>
                )}
              </div>
            </ul>
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default Header;
