import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  languages: [],
  loader: false,
};

export default function languagesReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_LANGUAGES_REQUEST:
      return { ...state, loader: true, errors: null, languages: [] };

    case types.GET_LANGUAGES_SUCCESS:
      return { ...state, languages: action.payload, loader: false };

    case types.GET_LANGUAGES_FAILED:
      return { ...state, loader: false, errors: action.payload };

    default:
      return state;
  }
}
