import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import { ClaimStatusEnum } from '../../../lib/enum';

const ClaimHeader = (props) => {
  const classes = useStyle();
  return (
    <section className={classes.claimsheader}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10} md={10}>
          <div className={classes.claimstitle}>
            <ul>
              <li>{props.claimData.claim_number || "N/A"}</li>
              {
                props.claimData.benefits.map((benfit, index) => {
                 return <li key={index} >- {benfit.type || "N/A"} </li>
                })
              }
              <li><span className={[ClaimStatusEnum.Approved_Paid, ClaimStatusEnum.Approved].includes(props.claimData.status) ? classes.claimStatussuccess : classes.claimStatusdanger}>{props.claimData.status || "N/A"}</span></li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <Grid container spacing={2} className={classes.claimactionbtn} style={{display: props.claimData.is_waiting_period === true ? "block" : "none"}}>
            <Grid item xs={12} sm={12} md={12}>
            <li><span className={classes.waitingPeridod}>{props.claimData.is_waiting_period === true ? "Waiting Period" :  "Not in waiting"}</span></li>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </section>
  )
}

export default ClaimHeader