import React, { useState } from 'react'
import ModalInc from '../../../../../shared-ui/ModalInc'
import ButtonInc from '../../../../../shared-ui/ButtonInc'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress } from '@material-ui/core';

const AddClaimFacilityNotes = (props) => {

  const [facility, setFacility] = useState('');
  const [facilitycode, setFacilityCode] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === "facility") {
      setFacility(value)
    }
    if (name === "facilitycode") {
      setFacilityCode(value)
    }


  }
  const reset = () => {
    setFacility('');
    setFacilityCode('');
  }
  const handleClaimFacilityNotes = () => {
    const payload = {
      "facility": facility,
      "facility_code": facilitycode,
      "claim_guid": props.claimguid
    }
    console.log("notes payload", payload);
    props.handleClaimFacilityNotes(payload)
    reset()
  }
  const handleNoteCreatedSuccessfully = () => {
    props.resetCreateFacilityNotesSuccessAlert();
    props.toggleClaimFacilityNotesDialog();
    window.location.reload();
  }
  const handleNoteCreateError = () => {
    props.resetCreateFacilityNotesErrorAlert();
  }
  return (
    <>
      <AlertDialog
        custom
        show={props.showCreateFacilityNoteProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2> Creating Claim facilty details...</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showCreateFacilityNotesSuccessAlert}
        size="sm"
        title={'Claim facilty details created'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleNoteCreatedSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={true}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >

      </AlertDialog>

      <AlertDialog
        show={props.showCreateFacilityNotesErrorAlert}
        danger
        title={'Error creating facility details'}
        onConfirm={() => handleNoteCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >

        {props.CreateFacilityNotesError ? props.CreateFacilityNotesError.message : ''}
      </AlertDialog>
      <ModalInc
        modalTitle="Add Facility Details"
        subTitle="Fill the below fields to add  facility details"
        open={props.openClaimFacilityNotesDialog}
        onClose={props.toggleClaimFacilityNotesDialog}
        fullWidth
      >
        <ValidatorForm onSubmit={() => handleClaimFacilityNotes()} >

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <TextValidator
                name='facility'
                id="facility"
                label="Facility"
                value={facility}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter Facility']}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextValidator
                name='facilitycode'
                id="facilitycode"
                label="Facility Code"
                value={facilitycode}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter Facility Code']}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} md={6}>

            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    type='submit'
                    style={{ marginTop: "20px", float: "right" }}
                  >
                    Add Facility detail  
                  </ButtonInc>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginTop: "20px" }}
                    onClick={props.toggleClaimFacilityNotesDialog}
                  >
                    Cancel
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </ValidatorForm>

      </ModalInc>
    </>
  )
}

export default AddClaimFacilityNotes