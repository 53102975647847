import React from 'react';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';

const WarningDialog = ({ 
  show, 
  onConfirm, 
  onCancel, 
  title, 
  message,
  confirmText,
  cancelText 
}) => {
  return (
    <AlertDialog
      warning
      show={show}
      title={title}
      onConfirm={onCancel}
      onCancel={onConfirm}
      confirmBtnText={cancelText}
      cancelBtnText={confirmText}
      showCancel={true}
      showConfirm={true}
      style={{ marginTop: '0', top: '30vh' }}
    >
      {message}
    </AlertDialog>
  );
};

export default WarningDialog; 