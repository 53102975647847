import React, { useState } from 'react';
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { hasAuthourity } from '../../../../../lib/access.es6';
import { ClaimActionEnum } from '../../../lib/enum';

export const AllDocsPending = (props) => {
  const classes = useStyle();

  const [docs_finalisation_date, setDocs_finalisation_date] = useState('');
  const [missingDocsError, setMissingDocsError] = useState(false)
  const handleDocsFinalisation = () => {
    if (props.missingDocs) {
      setMissingDocsError(true)
      return
    }
    props.submitClaimAction({
      claim_action: ClaimActionEnum.complete_docs, 
      claim_guid: props.claim.guid ,
      body: { docs_completion_date_time: docs_finalisation_date }
    })
  }

  return <section className={classes.ClaimActionButtons}>

    <ValidatorForm onSubmit={() => handleDocsFinalisation()} >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <TextValidator
            name='docs_finalisation_date'
            id="docs_finalisation_date"
            label="Docs Finalisation Date"
            value={docs_finalisation_date}
            onChange={(event) => { setDocs_finalisation_date(event.target.value) }}
            fullWidth
            type='date'
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            validators={['required']}
            errorMessages={['Please enter date of birth']}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <ButtonInc
            type="submit"
            variant="contained"
            style={{ width: "221px", height: "55px", background: "#FBA92D" }}
            onClick={handleDocsFinalisation}
            loading={props.loading}
            hasPermision={hasAuthourity('KTDA_FINALIZE_DOCUMENTATION')}
          >Finalise Docomentation</ButtonInc>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <p style={{ color: 'red' }}>{props.error ? props.error.message : ''}</p>
          <p style={{ color: 'red' }}>{missingDocsError ? "Missing required docs please upload before finilizing" : ''}</p>
        </Grid>
      </Grid>
    </ValidatorForm>
  </section>;
};
