export const initialState = {
  isLoaded: true,
};

export default function mainLoaderReducer(state = initialState, action) {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isLoaded: false };

    case 'FINISHED_LOADING':
      return { ...state, isLoaded: true };
    default:
      return state;
  }
}
