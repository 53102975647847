import * as types from '../../lib/actionTypes.es6';


export function getFeatureFlagsRequest(type) {
  return {
    type: types.GET_FEATURE_FLAGS_REQUEST,
    payload: type,
  };
}

export function getFeatureFlagsSuccess(response) {
  return {
    type: types.GET_FEATURE_FLAGS_SUCCESS,
    payload: response,
  };
}

export function getFeatureFlagsFailed(error) {
  return {
    type: types.GET_FEATURE_FLAGS_FAILURE,
    payload: error,
  };
}