import React, {useState} from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import { getPartner } from '../../../../lib/access.es6';
import TableInc from '../../../../shared-ui/TableInc';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Tooltip } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import CloseIcon    from '@mui/icons-material/Close';

import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';

import QuotesDependants from '../QuotesDependants/QuotesDependants';
import { formatDateLocale } from '../../../../lib/utils.es6';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    width: "100%",
    maxWidth: "1000px"
  }
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#fff',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Quotes = (props) => {
  const classes = useStyle();

  const [open, setOpen] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState(null)




  const handleClickOpen = (policy) => {
    setSelectedPolicy(policy)
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const customerQuotes = props.quotes || [];

  const currencyCode = getPartner().country.currency_code

  const tempArr = customerQuotes.map(quotes => {
    return {
      ...quotes,
      quotation_number:  quotes.quotation_number || "N/A",
      start_date:  formatDateLocale(quotes.start_date) || "N/A" ,
      policy_number: quotes.policy_number || "N/A",
      lapse_period:    quotes.lapse_period * quotes.premium_cardinality + " " + quotes.premium_granularity || "N/A",
      total_premium_amount_in_cents:   `${currencyCode} ${quotes.total_premium_amount_in_cents / 100} per ${quotes.premium_cardinality} ${quotes.premium_granularity}` || "0.00"
      }
      
  })
  return (
    <>
      {
        customerQuotes.length > 0 ? (<>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} >
              <TableInc
                columns={[
                  {
                    title: 'Quotes', field: 'quotation_number'
                  },
                  {
                    title: 'Start Date', field: 'start_date'
                  },
                  {
                    title: 'End Date', field: 'end_date', render: rowData => <>
                      {
                        formatDateLocale(rowData.end_date) || "N/A"
                      }
                    </>
                  },
                  {
                    title: 'Lapse period', field: 'lapse_period'
                  },
                  {
                    title: 'Premiums', field: 'total_premium_amount_in_cents',
                  },
                  {
                    title: 'Status', field: 'status', render: rowData => {
                      return <>
                        <span className={(rowData.status === "Taken_Up") ? classes.active : (rowData.status === "Raised") ? classes.active : classes.cancelled}>{(rowData.status === "Taken_Up") ? "Taken Up" : (rowData.status === "Raised") ? "Raised" : (rowData.status === "Not_Taken_Up") ? "Not_Taken_Up" : (rowData.status === "Not_Taken_Up") ? "Not_Taken_Up" : (rowData.status === "Decommissioned") ? "Decommissioned" : (rowData.status === "Replaced") ? "Replaced" : ("N/A")}</span>
                      </>

                    }
                  },
                  {
                    title: 'action', field: 'policyaction', render: rowData => {
                      return <div className={classes.actionbtn}>
                        <Tooltip title="View Dependents">
                          <span style={{ cursor: "pointer" }} onClick={() => handleClickOpen(rowData)}> <VisibilityIcon /></span>
                        </Tooltip>

                      </div>
                    }
                  },
                ]}

                data={
                  tempArr
                }
                title="Quotes"
                options={{
                  pageSize: customerQuotes.length < 5
                    ? customerQuotes.length
                    : 5,
                  exportButton: true,
                  showTitle: false,
                }}
              />

            </Grid>
          </Grid>
          {/*dependants dialog box */}
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="dependants-dialog-title"
            open={open}
          >
            <BootstrapDialogTitle className={classes.dependantstitle} id="dependants-dialog-title" onClose={handleClose}>
              Dependants
            </BootstrapDialogTitle>
            <BootstrapDialogTitle id="dependants-dialog-title" onClose={handleClose}>
            </BootstrapDialogTitle>
            <DialogContent >
              <QuotesDependants policy={selectedPolicy} />
            </DialogContent>
          </BootstrapDialog>
        </>) : (<>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} >
              <div className={classes.policieswrapper}>
                <div className={classes.nodatafound}>
                  <p>No quotes found generate a quote  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </>)
      }


    </>
  )
}

export default Quotes