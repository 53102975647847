import * as React from 'react';
import Button from '@mui/material/Button';
import { CircularProgress, Grid } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import ListItemIcon from '@mui/material/ListItemIcon';
import ArticleIcon from '@mui/icons-material/Article';
import AlertDialog from '../AlertDialog/index.es6';
import DialogContent from "@mui/material/DialogContent";
import ButtonInc from '../../shared-ui/ButtonInc';

export default function DownloadDocument(props) {

  if (!props.entity) {
    return null; // Render nothing if conditions are not met
  }
  const { templates, isEmptyTemplatesResponse, downloadDocument, labels } = props;
  const [showWarning, setShowWarning] = useState(true);
  const [showTemplatesList, setShowTemplatesList] = useState(false);
  const [showNoTemplatesAlert, setShowNoTemplatesAlert] = useState(false);

  React.useEffect(() => {
    props.resetGetTemplateDocuments()
  }, [])

  React.useEffect(() => {
    if (isEmptyTemplatesResponse) {
      setShowNoTemplatesAlert(true)
    }
    if (templates.length > 0) getDocument()
  }, [templates, isEmptyTemplatesResponse])


  const getDocument = () => {
    setShowWarning(false);

    if (templates.length === 1) {
      downloadDocument(templates[0].guid);
    } else {
      setShowTemplatesList(true);
    }
  };

  const cancel = () => {
    props.resetDownloadDocument();
    props.resetEntity();
    props.resetGetTemplateDocuments();
  };

  const handleDownloadDocumentSuccessfully = () => {
    props.resetDownloadDocumentSuccessAlert();
    props.resetDownloadDocument();
    props.resetEntity();
    props.resetGetTemplateDocuments();
  };

  const handleDownloadDocumentError = () => {
    props.resetDownloadDocumentErrorAlert();
    props.resetEntity();
    props.resetGetTemplateDocuments();
  };

  const download = () => {
    const fileURL = window.URL.createObjectURL(props.entityDocument);
    const alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = `${props.entity.guid}.pdf`;
    alink.click();
  };

  return (
    <React.Fragment>
      <AlertDialog
        custom
        show={showNoTemplatesAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText="ok"
        showCancel={false}
        showConfirm={false}
        cancelBtnText="cancel"
        showTitle={false}
        confirmBtnCssClass
      >
        <h2>No templates found</h2>
        <p>Please create and upload a template for {props.identifier}</p>
        <Grid container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{ padding: '3%' }}
        >
          <Grid xs={6} style={{ textAlign: 'right' }}>
            <ButtonInc onClick={
              () => {
                props.resetEntity()
                props.goToTemplatesPage()
              }
            }>Upload template now</ButtonInc>
          </Grid>
          <Grid xs={6}>
            <ButtonInc
              variant="outlined"
              style={{ textAlign: 'left' }}
              onClick={
                () => {
                  props.resetEntity()
                }
              }>Cancel</ButtonInc>
          </Grid>
        </Grid>


      </AlertDialog>

      <AlertDialog
        custom
        show={props.getTemplatesLoader}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText="ok"
        showCancel={false}
        showConfirm={false}
        cancelBtnText="cancel"
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Getting available templates</h2>
      </AlertDialog>


      <AlertDialog
        custom
        show={props.showDownloadDocumentProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText="ok"
        showCancel={false}
        showConfirm={false}
        cancelBtnText="cancel"
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Downloading Document</h2>
      </AlertDialog>
      <AlertDialog
        warning
        show={showWarning && props.entity}
        size="sm"
        title={labels.requestedDownload}
        reverseConfirmAndCancelButtons
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={cancel}
        onCancel={() => {
          setShowWarning(false);
          props.getTemplates()
        }}
        confirmBtnText={labels.cancel}
        showCancel
        showConfirm
        cancelBtnText={labels.download}
        confirmBtnCssClass
      ></AlertDialog>

      <AlertDialog
        success
        show={props.showDownloadDocumentSuccessAlert}
        size="sm"
        title={labels.downloadDocument}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => {
          handleDownloadDocumentSuccessfully();
        }}
        confirmBtnText="Ok"
        showCancel={false}
        showConfirm={false}
        cancelBtnText={labels.download}
        confirmBtnCssClass
      >
        <div>
          <Button
            variant="contained"
            onClick={download}
            style={{ background: 'green', marginRight: '2%' }}
            startIcon={<DownloadIcon />}
          >
            {labels.downloadDocument}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              handleDownloadDocumentSuccessfully();
            }}
            style={{ border: '1px green solid', color: 'green' }}
          >
            {labels.imDone}
          </Button>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showDownloadDocumentErrorAlert}
        danger
        title={labels.errorDownloadingDocument}
        onConfirm={() => handleDownloadDocumentError()}
        confirmBtnText={labels.tryAgain}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.downloadDocumentError ? props.downloadDocumentError.message : ''}
      </AlertDialog>

      <Dialog
        onClose={() => setShowTemplatesList(false)}
        open={showTemplatesList && !props.getTemplatesLoader}
      >
        <DialogTitle>{labels.selectTemplateToDownload}</DialogTitle>
        <DialogContent>
          <List dense>
            {templates.map((template, index) => (
              <ListItem key={index} disableGutters>
                <ListItemButton
                  onClick={() => {
                    downloadDocument(template.guid);
                    setShowTemplatesList(false);
                  }}
                >
                  <ListItemIcon>
                    <ArticleIcon />
                  </ListItemIcon>
                  <ListItemText primary={template.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
