import React from 'react';
import { connect } from 'react-redux';

import {
  deletePremiumRequest,
  addMissingPaymentRequest,
  addMissingPaymentReset,
  sendRecurringPaymentRecommendationSmsRequest,
  sendRecurringPaymentRecommendationSmsReset
} from '../../../CustomerStatus/actions.es6';
import { getPartnerGuid, hasAuthourity } from '../../../../lib/access.es6';
import { buildFailureMessage } from '../../../../lib/utils.es6';
import withLanguage from '../../../../components/Language/index.es6';
import { PARTNERS_THAT_CAN_SEND_RECURRING_PAYMENT_SMS } from '../../../../lib/constants';
import PaymentTable from './PaymentTable';
import AlertDialog from '../../../../components/AlertDialog/index.es6';

export class Payments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: props.labels,
      alerts: null
    };
  }

  componentWillUnmount() {
    this.resetAddMissingPayment();
  }

  componentWillMount(){
    this.props.getPayments()
  }

  resetAddMissingPayment() {
    this.props.dispatch(addMissingPaymentReset());
  }


  deletePremium(premium) {
    const payload = {
      premiumGuid: premium.guid,
      customerGuid: this.props.customerData.currentCustomer.guid,
    };
    this.props.dispatch(deletePremiumRequest(payload));
  }

  addMissingPayment(payment) {
    const payload = {
      paymentData: payment,
      customerGuid: this.props.customerData.currentCustomer.guid,
    };
    this.props.dispatch(addMissingPaymentRequest(payload));
  }

  sendRecurringPaymentRecommendationSms(customerGuid) {
    const payload = { customerGuid }
    this.props.dispatch(sendRecurringPaymentRecommendationSmsRequest(payload));
    this.setState({ alert: null })
  }


  promptRecurringPaymentSmsDialog(msisdn, partner) {
    const { labels } = this.state;
    this.setState({
      alert: <AlertDialog
        warning
        id="promptSendRecurringPaymentRecommendationSms"
        title={labels.sendRecurringPaymentRecommendationSms}
        reverseConfirmAndCancelButtons={false}
        onConfirm={() => this.sendRecurringPaymentRecommendationSms(msisdn, partner)}
        onCancel={() => this.setState({ alert: null })}
        showCancel
        showConfirm
        confirmBtnText={labels.sendSms}
        cancelBtnText={labels.cancel}
      >
      </AlertDialog>
    });
  }
  recurringPaymentSmsSuccessDialog() {
    const { labels } = this.state;
    return <AlertDialog
      id="recurringPaymentSmsSuccessDialog"
      success
      title={labels.smsSent}
      onConfirm={() => this.props.dispatch(sendRecurringPaymentRecommendationSmsReset())}
      showCancel={false}
      showConfirm
      confirmBtnText={labels.ok}
    >
    </AlertDialog>
  }

  recurringPaymentSmsErrorDialog() {
    const { labels } = this.state;
    return <AlertDialog
      id="recurringPaymentSmsErrorDialog"
      danger
      title={labels.couldNotSendSms}
      onCancel={() => this.props.dispatch(sendRecurringPaymentRecommendationSmsReset())}
      showConfirm={false}
      showCancel
      confirmBtnText={labels.ok}
    >
    </AlertDialog>
  }
  canSendRecurringSMS() {
    const currentPartner = getPartnerGuid()
    return PARTNERS_THAT_CAN_SEND_RECURRING_PAYMENT_SMS.includes(currentPartner) && hasAuthourity('SEND_SMS_RECOMMENDING_RECURRING_PAYMENT')
  }

  displayCustomerList() {
    const labels = this.state.labels;
    return (
      <div>
            {this.state.alert ? this.state.alert : ''}
            {this.props.statusData.recurringPaymentRecommedationSmsSent ? this.recurringPaymentSmsSuccessDialog() : ''}
            {this.props.statusData.recurringPaymentRecommedationSmsError ? this.recurringPaymentSmsErrorDialog() : ''}
          
              
              <PaymentTable
                columnWidth="col-md-12"
                showMore
                errors={this.props.statusData.payment_error}
                success_message={this.props.statusData.success_message}
                currentCustomer={this.props.customerData}
                payments={this.props.payments}
                onReset={this.resetAddMissingPayment.bind(this)}
                sendRecurringPaymentRecommendationSms={this.sendRecurringPaymentRecommendationSms.bind(this)}
                promptRecurringPaymentSmsDialog={this.promptRecurringPaymentSmsDialog.bind(this)}
                canSendRecurringSMS={this.canSendRecurringSMS.bind(this)}
                labels={labels}
              />
      </div>
    );
  }

  successMessage() {
    const successMessage = this.props.statusData.success_message;
    return successMessage;
  }

  failureMessage() {
    const defaultErrorMessage = 'Something went wrong!';
    const errorMessage = buildFailureMessage(
      defaultErrorMessage,
      this.props.statusData.errors
    );
    return errorMessage;
  }

  displayUserFeedback() {
    const styleErrorMessage = {
      display: this.props.statusData.errors ? 'block' : 'none',
    };

    const styleSuccessMessage = {
      display: this.props.statusData.success_message ? 'block' : 'none',
    };

    // if (this.props.statusData.loader) {
    //   return (
    //     <center>
    //       <CircularProgress />
    //     </center>
    //   );
    // }
    return (
      <div>
        <center style={styleErrorMessage}>
          {' '}
          <p className="error">{this.failureMessage()} </p>{' '}
        </center>
        <center style={styleSuccessMessage}>
          {' '}
          <p className="success">{this.successMessage()}</p>{' '}
        </center>
      </div>
    );
  }

  render() {
    return (
      <div>
        {/* {this.displayUserFeedback()} */}
        {this.displayCustomerList()}
      </div>
    );
  }
}

export default withLanguage(
  connect(state => ({
    customerData: state.currentCustomer,
    statusData: state.customerStatus,
    customerScreenData: state.customerScreen,
    globalData: state.global,
  }))(Payments)
);
