import React, { useState } from 'react'
import { useStyle } from "./Style";
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import Button from '@mui/material/Button';
import Grid from "@material-ui/core/Grid";


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { CircularProgress } from '@mui/material';


const MakePayment = (props) => {
  const [paymentAmount, setPaymentAmount] = useState('');
  const [paymentrefrence, setPaymentRefrence] = useState('');
  const [effectedat, setEffectedAt] = useState('');
  const [policy, setPolicy] = useState('');
  const [policyguid, setPolicyguid] = useState('');


  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === "paymentamount") {
      setPaymentAmount(value);
    }
    if (name === "paymentrefrence") {
      setPaymentRefrence(value);
    }
    if (name === "effectedat") {
      setEffectedAt(value);
    }
    if (name === "policy") {
      setPolicy(value);
      setPolicyguid(value.guid)
    }

  }

  const reset = () =>{
    setPaymentAmount('');
    setPaymentRefrence('');
    setEffectedAt('');
    setPolicy('');
  }

  const handleMakePayment = () => {
    const payload = {
      "policy_guid": policyguid,
      "amount_in_cents": Number(paymentAmount) * 100,
      "mno_reference": paymentrefrence,
      "status": "Succeeded",
      "channel": "WebClient",
      "effected_at": effectedat,
    }
    props.addPayment(payload);
  }


  const handleBuyPolicySuccessfully = () => {
    props.handleCloseAddPaymentDialog();
    reset();
    props.resetPayment();
    
  }
  const handleBuyPolicyError = () => { 
    props.resetPayment();
  }
  const classes = useStyle();
  return (
    <>

      <section className={classes.makepaymentdialog}>

      <AlertDialog
          custom
          show={!!props.paymentProgressAlert}
          size="sm"
          style={{ marginTop: '0', top: '30vh' }}
          confirmBtnText="ok"
          showCancel={false}
          showConfirm={false}
          cancelBtnText="cancel"
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2>Adding payment </h2>
        </AlertDialog>

        <AlertDialog
          success
          show={props.paymentAlert === "Payment Added Successfully" ? true : false}
          size="sm"
          title={'Purchase  Successfuly Made'}
          style={{ marginTop: '0', top: '30vh' }}
          onConfirm={() => { handleBuyPolicySuccessfully() }}
          confirmBtnText={'ok'}
          showCancel={false}
          cancelBtnText={'cancel'}
          confirmBtnCssClass
        >

        </AlertDialog>

        <AlertDialog
          show={props.paymentError === null ? false : true}
          danger
          title={'Error occured while making payment'}
          onConfirm={() => handleBuyPolicyError()}
          confirmBtnText={'Try again'}
          confirmBtnCssClass
          showCancel={false}
          style={{ marginTop: '0', top: '30vh' }}
        >
          {"error"}
        </AlertDialog>
        <Dialog open={props.openAddPaymentDialog} onClose={props.handleCloseAddPaymentDialog}>
          <ValidatorForm onSubmit={() => handleMakePayment()} >
            <DialogTitle className={classes.paymentmodaltitle}>
              <div >
                <h3>Add  payment</h3>
              </div>
            </DialogTitle>
            <DialogContent className={classes.paymentinputwrapper}>
              <DialogContentText>
                Fill the below details to add  a payment
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextValidator
                    name='paymentamount'
                    id="payment-amount"
                    label="Payment Amount"
                    value={paymentAmount}
                    onChange={handleChange}
                    fullWidth
                    className={classes.paymentinputs}
                    variant="outlined"
                    validators={['minNumber:1']}
                    errorMessages={['Please enter a valid payment amount']}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextValidator
                    name='paymentrefrence'
                    id="payment-reference"
                    label="Payment Reference"
                    value={paymentrefrence}
                    onChange={handleChange}
                    fullWidth
                    className={classes.paymentinputs}
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Please enter payment reference']}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextValidator
                    name='effectedat'
                    id="effected-date"
                    label="Effected At"
                    value={effectedat}
                    onChange={handleChange}
                    fullWidth
                    type='date'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Please enter effected date']}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>

                  <SelectValidator
                    name='policy'
                    labelId="select-policy"
                    id="select-policy"
                    value={policy}
                    label="Policy"
                    onChange={handleChange}
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Please select a policy']}
                    style={{ width: "100%" }}
                  >
                    {
                      props.policies.map((policy, index) => {
                        return <MenuItem
                          sx={{
                            fontFamily: "Open Sans",
                            fontStyle: "normal",
                            fontWeight: 300,
                            fontSize: "12px",
                            lineHeight: "16px",
                            letterSpacing: "0.005em",
                            color: "#000000",
                          }}
                          key={index}
                          value={policy}>
                          {policy.policy_number}
                        </MenuItem>
                      })
                    }

                  </SelectValidator>

                </Grid>
              </Grid>

            </DialogContent>
            <DialogActions className={classes.paymentbtns}>
              <Button variant="contained" type='submit'>Make Payment</Button>
              <Button variant="outlined" onClick={props.handleCloseAddPaymentDialog}>Cancel</Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </section>
    </>
  )
}

export default MakePayment