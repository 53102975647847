import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';
import { formatStatus } from '../../../../lib/utils.es6';
import benefitOptions from '../BenefitsOptionsData/Benefits'
import AlertDialog from '../../../../components/AlertDialog/index.es6';
import { InputAdornment } from '@mui/material';
import find from 'lodash/find';
import { getLocalNumberLength, getPartner } from '../../../../lib/access.es6';

const relationshipdata = [
  'Principal', 'Child', 'Spouse'
]
const Adddependant = (props) => {
  const classes = useStyle();
  const [firstname, setFirstname] = useState('');
  const [middlename, setmiddlename] = useState('');
  const [lastname, setlastname] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [phonenumber, setphonenumber] = useState('');
  const [nationalid, setnationalid] = useState('');
  const [gender, setgender] = useState('');
  const [town, settown] = useState('');
  const [workaddress, setworkaddress] = useState('');
  const [relationship, setrelationship] = useState('');
  const [benefits, setBenefits] = useState(null);
  const [premiumsincents, setpremiumsincents] = useState('');
  const [conditions, setConditions] = useState(true)
  const [childStillInSchool, setChildStillInSchool] = useState(false)
  const [MAX_CHILD_AGE, setMAX_CHILD_AGE] = useState(18);

  const formRef = useRef(null);

  useEffect(() => {
    setDateOfBirth(dateOfBirth);
  }, [childStillInSchool])

  useEffect(() => {

    ValidatorForm.addValidationRule('isValidPhoneNumber', value => {
      return value.length === getLocalNumberLength();
    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidPhoneNumber');
    };
  }, [])

  useEffect(() => {
    setMAX_CHILD_AGE(childStillInSchool ? 25 : 18)



    ValidatorForm.addValidationRule('isValidNationalId', (value) => {
      if (relationship === 'Principal' && relationship === 'Spouse') {
        const nationalIdRegex = '^(\\d{6}|\\d{8})$';
        const pattern = new RegExp(nationalIdRegex);
        return pattern.test(value);
      } else {
        return false
      }

    });

    ValidatorForm.addValidationRule('isNationalIdRequired', (value) => {
      // Check if the relationship is 'Child' and the National ID is empty
      if (relationship === 'Child' && value === '') {
        return true; // Validation passes if the conditions are met
      }
      return false; // Validation fails in other cases
    });

    ValidatorForm.addValidationRule('isValidChildDateOfBirth', (value) => {
      if (relationship === 'Child') {
        const selectedDate = moment(value);
        const maxDate = moment().subtract(MAX_CHILD_AGE + 1, 'years');
        return selectedDate.isAfter(maxDate, 'day');
      }
      return true;
    });




    ValidatorForm.addValidationRule('isValidPrincipalAge', (value) => {
      if (relationship === 'Principal') {
        const selectedDateObj = new Date(value);
        const currentDate = new Date();

        const ageDifference = currentDate.getFullYear() - selectedDateObj.getFullYear();

        return ageDifference >= MAX_CHILD_AGE && ageDifference <= 75;
      }
      return true
    });

    ValidatorForm.addValidationRule('isValidSpouseAge', (value) => {
      if (relationship === 'Spouse') {
        const selectedDateObj = new Date(value);
        const currentDate = new Date();

        const ageDifference = currentDate.getFullYear() - selectedDateObj.getFullYear();

        return ageDifference >= MAX_CHILD_AGE && ageDifference <= 75;
      }
      return true
    });

    ValidatorForm.addValidationRule('hasDuplicatePrincipal', (value) => {
      const principalAdded = !!find(props.dependants, { relationship: "Principal" })
      if (value === "Principal" && principalAdded) {
        return false
      } else {
        return true
      }

    });


    ValidatorForm.addValidationRule('shouldAddPrincipalFirst', (value) => {
      const principalNotAdded = !find(props.dependants, { relationship: "Principal" })
      if (value !== "Principal" && principalNotAdded) {
        return false
      } else {
        return true
      }

    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidDateOfBirth');
      ValidatorForm.removeValidationRule('isValidNationalId');
      ValidatorForm.removeValidationRule('isNationalIdRequired');
      ValidatorForm.removeValidationRule('isValidPrincipalAge');
      ValidatorForm.removeValidationRule('isValidChildDateOfBirth');
      ValidatorForm.removeValidationRule('isValidSpouseAge');
      ValidatorForm.removeValidationRule('hasDuplicatePrincipal');
      ValidatorForm.removeValidationRule('shouldAddPrincipalFirst');
    };

  }, [relationship, dateOfBirth, childStillInSchool, MAX_CHILD_AGE])


  useEffect(() => {

    const startingIndex = internationalCallingCode.length
    const endingIndex = props.principal.msisdn.length
    const phone = props.principal.msisdn.substring(startingIndex, endingIndex)

    if (props.autoFillPrincipal) {
      setFirstname(props.principal.first_name);
      setmiddlename('');
      setlastname(props.principal.surname);
      setDateOfBirth(props.principal.date_of_birth);
      setphonenumber(phone);
      setnationalid(props.principal.national_id);
      setgender(props.principal.gender);
      settown('');
      setworkaddress(props.principal.address || '');
      setrelationship('Principal');
      props.toggleAutoFillPrincipal()
    }
  }, [props.autoFillPrincipal]);


  const calculateAge = (dateOfBirth) => {
    const currentDate = moment();
    const birthDate = moment(dateOfBirth);

    // Calculate the age in years and days
    const years = currentDate.diff(birthDate, 'years');
    birthDate.add(years, 'years'); // Add years to birth date
    const days = currentDate.diff(birthDate, 'days');

    return (`${years} years and ${days} days`);

  };

  const isAdult = () => {
    const yearOfBirth = parseInt(dateOfBirth.substring(0, 4))
    const currentYear = new Date().getFullYear()
    const age = currentYear - yearOfBirth
    return age >= MAX_CHILD_AGE
  }

  const triggerFieldValidation = (fieldName) => {
    if (formRef.current) {
      const field = getFormField(formRef.current.childs, fieldName)
      if (field) field.validate();
    }
  };

  const getFormField = (fields, name) => {
    for (let field of fields) {
      if (field.props.name === name) return field
    }
    return null
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'firstname') {
      setFirstname(value)
    }
    if (name === 'middlename') {
      setmiddlename(value)
    }
    if (name === 'lastname') {
      setlastname(value)
    }
    if (name === 'dateOfBirth') {
      setDateOfBirth(value)
      setBenefits(null)
      setpremiumsincents('')
    }
    if (name === 'phonenumber') {
      setphonenumber(value)
    }
    if (name === 'nationalid') {
      setnationalid(value)
    }
    if (name === 'gender') {
      setgender(value)
    }
    if (name === 'town') {
      settown(value)
    }
    if (name === 'workaddress') {
      setworkaddress(value)
    }
    if (name === 'relationships') {
      setrelationship(value)
      setBenefits(null);
      setpremiumsincents('');
      triggerFieldValidation('nationalid');
      triggerFieldValidation('dateOfBirth');
    }

  };
  const reset = () => {
    setFirstname('');
    setmiddlename('');
    setlastname('');
    setDateOfBirth('');
    setphonenumber('');
    setnationalid('');
    setgender('');
    settown('');
    setworkaddress('');
    setrelationship('');
    setpremiumsincents('');
    setBenefits('');
  }
  const onSelectBenefit = (event) => {
    const benefit = event.target.value;
    setBenefits(benefit);

    if (dateOfBirth) {
      const premium = isAdult() ? benefit.adult_premim : benefit.child_premium
      setpremiumsincents(premium);
    }
  }
  const handleConditions = () => {
    setConditions(!conditions)
  }
  const addDependant = () => {

    const showChildAgeErrorMessage = (relationship === 'Child') && moment(dateOfBirth).isBefore(moment().subtract(MAX_CHILD_AGE, 'years') + 1, 'day')
    const showAdultAgeErrorMessage = (relationship === 'Principal' || relationship === 'Spouse') && !isValidAdultAge()


    if (showChildAgeErrorMessage || showAdultAgeErrorMessage) return

    const Allbenefitst = benefits.benefits;
    const internationalCallingCode = getPartner().country.international_calling_code
    const payload = {
      "first_name": firstname,
      "middle_name": middlename,
      "last_name": lastname,
      "msisdn": internationalCallingCode + phonenumber,
      "date_of_birth": dateOfBirth,
      "gender": gender,
      "national_id": nationalid,
      "town": town,
      "relationship": relationship,
      "work_address": workaddress,
      "premium_amount_in_cents": premiumsincents * 100,
      "benefits": [
        ...Allbenefitst
      ]
    }
    props.createDependant(payload);
    const tempDependants = props.dependants;
    tempDependants.push(payload);
    props.setDependants(tempDependants);
    if (relationship === "Principal") {
      // setPrincipalProducts([benefits])
      props.setSelectedProduct(benefits)
    }
    reset();
  }


  const handleDependantCreatedSuccessfully = () => {
    props.resetAddDependantSuccessAlert();
  }
  const handleDependantCreateError = () => {
  }


  //country calling code
  const internationalCallingCode = getPartner().country.international_calling_code
  // const principalAdded = !!find(props.dependants, { relationship: "Principal" })



  const isValidAdultAge = () => {
    if (relationship === 'Spouse' || relationship === 'Principal') {
      const selectedDateObj = moment(dateOfBirth);
      const currentDate = moment();

      const ageDifference = currentDate.diff(selectedDateObj, 'years');


      const result = ageDifference >= 18 && ageDifference <= 74;

      return result
    }


    return true;
  };


  const childAgeErrorMessage = `Child must be ${MAX_CHILD_AGE} years or younger. current age is \n\n ${calculateAge(dateOfBirth)} years old`;
  const showChildAgeErrorMessage = (relationship === 'Child') && moment(dateOfBirth).isBefore(moment().subtract(MAX_CHILD_AGE, 'years') + 1, 'day')


  const adultAgeErrorMessage = `principal and Spouse must be at least 18 years and not more than 75 years`;
  const showAdultAgeErrorMessage = (relationship === 'Principal' || relationship === 'Spouse') && !isValidAdultAge()


  return (
    <>

      <AlertDialog
        success
        show={props.showAddDependantSuccessAlert}
        size="sm"
        title={'Dependant created'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleDependantCreatedSuccessfully() }}
        onCancel={() => { handleDependantCreatedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={true}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>
      <AlertDialog
        info
        show={props.depAlert}
        size="sm"
        title={'Dependant  removed'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { props.setDepAlert(false) }}
        confirmBtnText={'ok'}
        showCancel={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>

      <AlertDialog
        show={props.showAddDependantErrorAlert}
        danger
        title={'Error creating Dependant'}
        onConfirm={() => handleDependantCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {'error'}
      </AlertDialog>
      <Grid container spacing={2} >
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.detailstitle}>
            <p>Add Dependant</p>
          </div>
        </Grid>
      </Grid>
      <ValidatorForm onSubmit={() => addDependant()} ref={formRef} >
        <section className={classes.quoteinputwrapper}>
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <SelectValidator
                labelId="select-relationship"
                id="select-relationship"
                value={relationship}
                label="Relationship"
                name='relationships'
                onChange={handleChange}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
                variant="outlined"
                validators={['required', 'hasDuplicatePrincipal', 'shouldAddPrincipalFirst']}
                errorMessages={['Please select reletionship', 'Cannot have more than one principal', 'You should add principal first']}
              >
                {
                  relationshipdata.map((relationship, index) => {
                    return <MenuItem
                      key={index}
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }}
                      //disabled={principalAdded}
                      value={relationship}>
                      {formatStatus(relationship)}</MenuItem>
                  })
                }
              </SelectValidator>

            </Grid>
          </Grid>

          <Grid container spacing={2} >

            {
              relationship === 'Child' &&
              <Grid item xs={12} sm={4} md={4}>
                <div className={classes.child_still_in_chool}>
                  <input type="checkbox" onClick={() => {
                    setChildStillInSchool(!childStillInSchool)
                    triggerFieldValidation('dateOfBirth')
                  }
                  } id="preexistingconditioncheck" name="preexistingcondition" value={childStillInSchool} />
                  <label for="child_still_in_chool">Child is still in school?</label>
                </div>
              </Grid>
            }

            <Grid item xs={12} sm={4} md={4}>
              <div className={classes.child_still_in_chool}>
                <input type="checkbox" onClick={handleConditions} id="preexistingconditioncheck" name="preexistingcondition" value="Do you have a pre existing condition" />
                <label for="child_still_in_chool">Any pre existing condition?</label>
              </div>
            </Grid>

          </Grid>



          <Grid container spacing={2} >
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="First-Name"
                fullWidth
                label="First Name"
                name='firstname'
                value={firstname}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter first name']}
                style={{ width: "100%" }}

              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Middle-Name"
                fullWidth
                label="Middle Name"
                name='middlename'
                value={middlename}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                style={{ width: "100%" }}

              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Last-Name"
                fullWidth
                label="Last Name"
                name='lastname'
                value={lastname}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter Last name']}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>

              <SelectValidator
                labelId="select-gender"
                id="select-gender"
                value={gender}
                name='gender'
                type="text"
                label="Gender"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select gender']}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >
                <MenuItem sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }} value="Male">Male</MenuItem>
                <MenuItem sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }} value="Female">Female</MenuItem>

              </SelectValidator>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Phone"
                fullWidth
                label="Phone Number"
                name='phonenumber'
                value={phonenumber}
                onChange={handleChange}
                type="number"
                className={classes.quoteinputs}
                InputProps={{
                  startAdornment: <InputAdornment
                    sx={{
                      paddingTop: "32px",
                    }}
                    position="start"
                  >{internationalCallingCode ? internationalCallingCode : ''}</InputAdornment>,
                  style: {
                    background: "#F7F7F7"
                  }
                }}
                variant="outlined"
                validators={phonenumber ? ["isValidPhoneNumber"] : []}
                errorMessages={[`Please enter a valid ${getLocalNumberLength()} digit phone number`]}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Date-of-birth"
                label="Date of birth"
                name='dateOfBirth'
                value={dateOfBirth}
                onChange={handleChange}
                fullWidth
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                style={{ width: "100%" }}
              />
              {showChildAgeErrorMessage ? <p style={{ color: 'red' }}>{childAgeErrorMessage}</p> : ''}
              {showAdultAgeErrorMessage ? <p style={{ color: 'red' }}>{adultAgeErrorMessage}</p> : ''}


            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="National Id"
                fullWidth
                type='number'
                label="National ID"
                name='nationalid'
                value={nationalid}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                validators={relationship === 'Child' ? [] : ['required', 'matchRegexp:^[0-9]{6,8}$']}
                errorMessages={['National ID is required', 'National ID must be between 6 and 8 digits']}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Town"
                fullWidth
                label="Town"
                name='town'
                value={town}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Work-address"
                fullWidth
                label="Work Address"
                name='workaddress'
                value={workaddress}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                style={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        </section>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={6} md={6}>
            <div className={classes.benefitstitle}>
              <p>Benefits</p>
            </div>
          </Grid>

        </Grid>
        <section className={classes.benefitswrapper}>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={6} md={6}>
              <SelectValidator
                labelId="select-benefits"
                id="select-benefits"
                value={benefits}
                label="Benefits"
                name='benefits'
                onChange={onSelectBenefit}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select benefits']}
              >
                {
                  (props.selectedProduct ? [props.selectedProduct] : (

                    conditions ? benefitOptions : benefitOptions.slice(0, 7)
                  )
                  ).map((benefit, index) => {
                    return (
                      <MenuItem
                        key={index}
                        sx={{
                          fontFamily: "Open Sans",
                          fontStyle: "normal",
                          fontWeight: 300,
                          fontSize: "12px",
                          lineHeight: "16px",
                          letterSpacing: "0.005em",
                          color: "#000000",
                        }}
                        value={benefit}>
                        {benefit.option}
                      </MenuItem>
                    )
                  })
                }
              </SelectValidator>

            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                id="Premium-in-Cents"
                fullWidth
                label="Premium"
                name='premiumsincents'
                disabled
                value={premiumsincents}
                onChange={handleChange}
                defaultValue="55.00"
                InputProps={{
                  startAdornment: (<InputAdornment position="start">
                    {props.currencyCode}
                  </InputAdornment>),

                  'aria-label': props.currencyCode,
                }}
                style={{ width: "100%" }}

              />
            </Grid>

          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.quotesbtns}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type='submit'
                //onClick={addDependant}
                >
                  Add  Dependant
                </Button>

              </div>
            </Grid>
          </Grid>
        </section>
      </ValidatorForm>
      {props.dependants.length > 0 && <section className={classes.dependantdetailstable}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <h3>Added dependants</h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>

            <div className={classes.dependantWrapper}>

              <table>
                <tr>
                  <th>Dependant Name</th>
                  <th>Reletionship</th>
                  <th>Benefit Type</th>
                  <th>Benefit</th>
                  <th> Premium </th>
                  <th>Action</th>
                </tr>
                {props.dependants.map((d, index) => {
                  return <tr >
                    <td>{d.first_name + " " + d.last_name}</td>
                    <td>{d.relationship}</td>
                    <td>{props.selectedProduct ? props.selectedProduct.option : ''}</td>
                    <td>
                      <ol>
                        {d.benefits.map(b => {
                          return <li>{b.type + " "} {<strong>{props.currencyCode + " " + (b.cover_amount_in_cents / 100).toLocaleString()}</strong>}</li>
                        })}
                      </ol>
                    </td>
                    <td>{props.currencyCode + " " + d.premium_amount_in_cents / 100}</td>
                    <th><DeleteIcon style={{ cursor: "pointer" }} onClick={() => { props.removeDependant(index) }} /></th>
                  </tr>
                })}
              </table>


            </div>
          </Grid>
        </Grid>
      </section>
      }
    </>
  )
}

export default Adddependant