import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  currentCustomer: null,
  success_message: null,
  premiums: [],
  claims: [],
  loader: false,
  edit_loader: false,
  editModalState: false,
  customer_edited_succesfully: false,
  customer_edited_failed: false,
  customer_refresh_failed: false
};

function getPremiums(currentCustomer) {
  let premiums = [];
  (currentCustomer.policies || []).map(policy => {
    premiums = premiums.concat(policy.premiums || []);
    return null;
  });
  return premiums;
}

function getPayments(currentCustomer) {
  let payments = [];
  (currentCustomer.policies || []).map(policy => {
    payments = payments.concat(policy.payments || []);
    return null;
  });
  return payments;
}

export default function currentCustomerReducer(state = initialState, action) {
  let payload = {};
  switch (action.type) {
    case types.CHANGE_CURRENT_CUSTOMER:
    case types.MAKE_CURRENT_CUSTOMER:
      payload = action.payload;
      return {
        ...state,
        currentCustomer: payload,
        success_message: action.message,
        premiums: getPremiums(payload),
        payments: getPayments(payload),
        claims: payload.claims,
        loader: false,
        customer_refresh_failed: false
      };

    case types.REFRESH_CUSTOMER_REQUEST:
      return { ...state, loader: true , customer_refresh_failed: false};

    case types.REFRESH_CUSTOMER_FAILED:
      console.log("failedrefresh", action.payload)
      return { ...state, loader: false, customer_refresh_failed: true };

    case types.UPDATE_CUSTOMER_REQUEST:
      return { ...state, edit_loader: true, errors: null, customer_edited_succesfully: false, customer_edited_failed: false };

    case types.PULL_CUSTOMER_KYC_REQUEST:
      return { ...state, errors: null, editModalState: false };

    case types.PULL_CUSTOMER_KYC_REQUEST_FAILURE:
      return { ...state, errors: true };

    case types.PULL_CUSTOMER_KYC_REQUEST_SUCCESS:
      return { ...state, errors: false, success_message: true };

    case types.UPDATE_CUSTOMER_SUCCESS:
      payload = action.payload;
      return {
        ...state,
        edit_loader: false,
        premiums: getPremiums(payload),
        payments: getPayments(payload),
        claims: payload.claims,
        editModalState: false,
        customer_edited_succesfully: true,
        customer_edited_failed: false
      };

    case types.DELETE_PREMIUM_FAILURE:
    case types.CHANGE_LOYALTY_REGISTRATION_FAILURE:
    case types.UPDATE_CUSTOMER_FAIL:
      return {
        ...state,
        loader: false,
        edit_loader: false,
        errors: action.payload,
        customer_edited_succesfully: false,
        customer_edited_failed: true
      };
    case types.UPDATE_CUSTOMER_RESET:
      return {
        ...state,
        loader: false,
        edit_loader: false,
        editModalState: false,
        customer_edited_succesfully: false,
        customer_edited_failed: false
      };

    case types.EDIT_CUSTOMER_DISPLAY_ERROR:
      return { ...state, errors: action.payload };

    case types.OPEN_EDIT_CUSTOMER_MODAL:
      return { ...state, editModalState: true };

    case types.CLOSE_EDIT_CUSTOMER_MODAL:
      return { ...state, editModalState: false };

    case types.USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
}
