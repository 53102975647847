import React,{useState} from 'react'
import AlertDialog from '../../../../../components/AlertDialog/index.es6'
import { CircularProgress } from '@mui/material'
import ButtonInc from '../../../../../shared-ui/ButtonInc'

const DeleteClaimExpense = (props) => {

  if (!props.expenseToDelete) {
    return ""
  }
  const [showWarning, setShowWarning] = useState(false);

  const deleteExpense = () =>{
    setShowWarning(true)
    props.deleteClaimExpense(props.expenseToDelete);
  }
  const cancel = () =>{
    props.resetExpenseToDelete()
  }
  const handleExpenseDeletedSuccessfully = () =>{
    props.resetDeleteExpenseSuccessAlert()
    props.resetExpenseToDelete();
    window.location.reload();
  }
  const handleExpenseDeleteError = () =>{
    props.resetExpenseToDelete()

  }
  return (
    <>
      <AlertDialog
        custom
        show={props.showDeleteExpenseProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Deleting Expense</h2>
      </AlertDialog>

      <AlertDialog
        warning
        show={!showWarning && props.expenseToDelete}
        size="sm"
        title={'Are you sure you want to delete this claim expense '}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { cancel() }}
        onCancel={() => { deleteExpense() }}
        confirmBtnText={'Cancel'}
        showCancel={true}
        showConfirm={true}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
      </AlertDialog>
      <AlertDialog
        success
        show={props.showDeleteExpenseSuccessAlert} 
        size="sm"
        title={'Expense deleted'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleExpenseDeletedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
        <div >
          <ButtonInc  onClick={() => { handleExpenseDeletedSuccessfully() }} style={{ background: 'green', }}>OK</ButtonInc>
        </div>
      </AlertDialog>
      <AlertDialog
        show={props.showDeleteExpenseErrorAlert}
        danger
        title={'Error deleting expense'}
        onConfirm={() => handleExpenseDeleteError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {'Could not delete claim expense'}
      </AlertDialog>
    </>
  )
}

export default DeleteClaimExpense