
import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles((LandingPageTheme) => ({


    stats: {
        background: "#FFFFFF",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    statsbox: {
        textAlign: "center",
        padding: "20px 0 20px 0",
        [LandingPageTheme.breakpoints.down("md")]: {
            padding: "10px 0 10px 0",
        },
        "& span": {
            fontWeight: "700",
            color: "#000000",
            fontSize: "16px",
            [LandingPageTheme.breakpoints.down("md")]: {
                fontSize: "14px"
            }
        },
        "& p": {
            fontFamily: 'Lato',
            fontStyle: "normal",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.0015em",
            color: "#000"
        },
    },

  


}));