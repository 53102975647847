import React from 'react';
import { connect } from 'react-redux';
import Claims from '../../components/Claims/index.es6';
import NoRecord from '../../components/NoRecord/index.es6';
import * as globalActions from '../Main/actions.es6';

export class ClaimList extends React.Component {
  componentWillMount() {
    this.props.dispatch(globalActions.showCustomerBar());
  }

  displayClaims() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (!currentCustomer) {
      return <NoRecord />;
    }
    const claims = this.props.customerScreenData.claims;

    return (
      <Claims
        extended
        claims={claims}
        columnWidth="col-md-12"
        showMore={false}
      />
    );
  }

  render() {
    return <div>{this.displayClaims()}</div>;
  }
}

export default connect(state => ({ 
  customerData: state.currentCustomer,
  customerScreenData: state.customerScreen,
}))(
  ClaimList
);
