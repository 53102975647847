import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({

  nodatafound: {
    textAlign: "center",
    margin: "40px 0 40px 0",
    "& p": {
      fontFamily: "open sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "15px",
      color: "#000000",
    },
    "& button": {
      background: "#FBA92D",
      borderRadius: "8px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      boxShadow: "none",
      padding: "10px",
      textTransform: "capitalize",
      marginTop: "15px",
      "&:hover": {
        background: "#173E4B",
        boxShadow: "none",
      }
    }
  },

}));