import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  response: null,
  loader: false,
  summary: null,
  fileBlob: null,
  loaderForDownload: null,
  blastStopped: null,
  loaderForStoppingBlast: false,
  batchProgress:null
};

export default function bulkSmsReducer(state = initialState, action) {
  switch (action.type) {
    case types.UPLOAD_BULK_SMS_REQUEST:
      return { ...state, loader: true, errors: null, response: null };

    case types.UPLOAD_BULK_SMS_SUCCESS:
      return { ...state, response: action.payload, loader: false };

    case types.UPLOAD_BULK_SMS_FAILED:
      return { ...state, loader: false, errors: action.payload };

    case types.SUMMARY_BULK_SMS_REQUEST:
      return { ...state, loader: true, errors: null, summary: null };

    case types.SUMMARY_BULK_SMS_SUCCESS:
      return {
        ...state,
        summary: action.payload,
        loader: false,
        fileBlob: null,
      };

    case types.SUMMARY_BULK_SMS_FAILED:
      return {
        ...state,
        loader: false,
        errors: action.payload,
        summary: null,
        fileBlob: null,
      };

    case types.DOWNLOAD_BULK_SMS_REQUEST:
      return {
        ...state,
        loaderForDownload: true,
        errors: null,
        response: null,
        summary: null,
        fileBlob: null,
      };

    case types.DOWNLOAD_BULK_SMS_SUCCESS:
      return {
        ...state,
        fileBlob: action.payload,
        loaderForDownload: false,
        summary: null,
      };

    case types.DOWNLOAD_BULK_SMS_FAILED:
      return {
        ...state,
        loaderForDownload: false,
        errors: action.payload,
        summary: null,
        fileBlob: null,
      };
    case types.GET_BATCH_PROGRESS_REQUEST:
      return {
        ...state,
        loader: true,
        loaderForStoppingBlast: false,
      };

    case types.GET_BATCH_PROGRESS_FAILED:
      return {
        ...state,
        loader: false,
        error: action.payload,
      };
    case types.GET_BATCH_PROGRESS_SUCCESS:
      return {
        ...state,
        loader: false,
        batchProgress: action.payload,
      };

    case types.DOWNLOAD_BULK_SMS_RESET:
    case types.SUMMARY_BULK_SMS_RESET:
    case types.UPLOAD_BULK_SMS_RESET:
    case types.STOP_BLAST_RESET:
    case types.USER_LOGOUT:
      return initialState;
    case types.STOP_BLAST_REQUEST:
      return {
        ...state,
        loaderForStoppingBlast: true,
        errors: null,
        blastStopped: null,
      };
    case types.STOP_BLAST_SUCCESS:
      return {
        ...state,
        loaderForStoppingBlast: false,
        blastStopped: action.payload,
    };

    case types.STOP_BLAST_FAILED:
      return {
        ...state,
        loaderForStoppingBlast: false,
        error: action.payload,
    };

    default:
      return state;
  }
}
