import React from 'react';
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";

export const Rejected = (props) => {
  const classes = useStyle();

  return <section className={classes.ClaimActionButtons}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <p style={{textAlign: "center"}}>Claim is {props.claim.status}, no action is needed</p>
      </Grid>
    </Grid>
  </section>;
};
