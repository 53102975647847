import React from 'react';
import { Paper, Grid, 
  // TextField, Button 
} from '@mui/material';
import { useFormLogic } from './formUtils';

const PropertyForm = ({ claim }) => {
  const {
    // formData,
    // isSubmitting,
    // submitMessage,
    // handleInputChange,
    handleSubmit,
  } = useFormLogic(claim);

  return (
    <Paper elevation={0} sx={{ p: 3, mb: 4 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Form fields */}
        </Grid>
      </form>
    </Paper>
  );
};

export default PropertyForm;
