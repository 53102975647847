import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";


const Alerts = () => {
  const classes = useStyle();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={7} sm={8} md={8} className={classes.alertswrappersuccess}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3}>
              <div className={classes.iconwrapper}>
                <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 34.0333L4.64999 23.6833L9.36666 18.9667L15 24.6167L31.4667 8.13333L36.1833 12.85L15 34.0333Z" fill="white" />
                </svg>
              </div>
            </Grid>
            <Grid item xs={12} sm={7} md={7}>
              <div className={classes.contentwrapper}>
                <h4>Congratulations</h4>
                <p>Your quote has been created successfully</p>
                <ul>
                  <Button variant='contained' size='large'>Generate Another Quote</Button>
                  <Button variant='contained' size='large'>View Quote</Button>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <div className={classes.closeiconwrapper}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.17 14.83L14.83 9.17M14.83 14.83L9.17 9.17M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLineJoin="round" />
                </svg>

              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={7} sm={8} md={8} className={classes.alertswrappererror}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3}>
              <div className={classes.iconwrappererror}>
                <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.4333 20L31.6667 29.2333V31.6667H29.2333L20 22.4333L10.7667 31.6667H8.33333V29.2333L17.5667 20L8.33333 10.7667V8.33333H10.7667L20 17.5667L29.2333 8.33333H31.6667V10.7667L22.4333 20Z" fill="white" />
                </svg>

              </div>
            </Grid>
            <Grid item xs={12} sm={7} md={7}>
              <div className={classes.contentwrapper}>
                <h4>Something went wrong!</h4>
                <p>Please try again.</p>

              </div>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <div className={classes.closeiconwrapper}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.17 14.83L14.83 9.17M14.83 14.83L9.17 9.17M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLineJoin="round" />
                </svg>

              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={7} sm={8} md={8} className={classes.alertswrapperwarning}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3}>
              <div className={classes.iconwrapperwarning}>
                <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 12.9167V21.6667M35.1333 14.3V25.7C35.1333 27.5667 34.1333 29.3 32.5167 30.25L22.6167 35.9667C21 36.9 19 36.9 17.3667 35.9667L7.46666 30.25C6.66962 29.7879 6.00822 29.1241 5.54891 28.3255C5.0896 27.5268 4.84856 26.6213 4.85 25.7V14.3C4.85 12.4333 5.85 10.7 7.46666 9.74999L17.3667 4.03333C18.9833 3.09999 20.9833 3.09999 22.6167 4.03333L32.5167 9.74999C34.1333 10.7 35.1333 12.4167 35.1333 14.3Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLineJoin="round" />
                  <path d="M20 27V27.1667" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLineJoin="round" />
                </svg>


              </div>
            </Grid>
            <Grid item xs={12} sm={7} md={7}>
              <div className={classes.contentwrapper}>
                <h4>Warning</h4>
                <p>Your password strength is low.</p>

              </div>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <div className={classes.closeiconwrapper}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.17 14.83L14.83 9.17M14.83 14.83L9.17 9.17M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLineJoin="round" />
                </svg>

              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={7} sm={8} md={8} className={classes.alertswrapperinfo}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3}>
              <div className={classes.iconwrapperinfo}>
                <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.7833 5L5 13.7833V26.2167L13.7833 35H26.2167C29.1667 32.0667 35 26.2167 35 26.2167V13.7833L26.2167 5M15.1667 8.33333H24.8333L31.6667 15.1667V24.8333L24.8333 31.6667H15.1667L8.33333 24.8333V15.1667M18.3333 25H21.6667V28.3333H18.3333V25ZM18.3333 11.6667H21.6667V21.6667H18.3333V11.6667Z" fill="white" />
                </svg>
              </div>
            </Grid>
            <Grid item xs={12} sm={7} md={7}>
              <div className={classes.contentwrapper}>
                <h4> Did you Know? </h4>
                <p>Your can be an awesome person .</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <div className={classes.closeiconwrapper}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.17 14.83L14.83 9.17M14.83 14.83L9.17 9.17M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLineJoin="round" />
                </svg>

              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </>
  )
}

export default Alerts