import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../../lib/actionTypes.es6';
import {
  addGuide,
  getGuide,
  getGuides,
  editGuide,
  deleteGuide
} from '../../../../lib/requests.es6';
import * as actions from './actions';
import { unAuthorizedNotification } from '../../../../lib/access.es6';
import { GAloggerException } from '../../../../lib/monitoring.es6';


// template docs

export function* getGuidesRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.GET_GUIDES_REQUEST);
      const response = yield call(getGuides, payload);
      yield put(actions.getGuidesSuccess(response.data));
    } catch (e) {
      let errorPayload = {
        message: 'An error occurred, this could be due to invalid data',
      };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.getGuidesFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* getGuideRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.GET_GUIDE_REQUEST);
      const response = yield call(getGuide, payload);
      yield put(actions.getGuideSuccess(response.data));
    } catch (e) {
      let errorPayload = {
        message: 'An error occurred, this could be due to invalid data',
      };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.getGuideFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* addGuideRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.ADD_GUIDE_REQUEST);

      const response = yield call(addGuide, payload);
      yield put(actions.addGuideSuccess(response.data));
    } catch (e) {
      let errorPayload = {
        message: 'An error occurred, this could be due to invalid data',
      };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.addGuideFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* editGuideRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.EDIT_GUIDE_REQUEST);

      const response = yield call(editGuide, payload);
      yield put(actions.editGuideSuccess(response.data));
      //yield put(actions.getTemplateDocumentsRequest());
    } catch (e) {
      let errorPayload = {
        message: 'An error occurred, this could be due to invalid data',
      };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.editGuideFailed(errorPayload));
      GAloggerException(e);
    }
  }
}


export function* deleteGuideRequestWatcher() {
  for (;;) {
    const request = yield take(types.DELETE_GUIDE_REQUEST);
    try {
      const response = yield call(deleteGuide, request.payload);
      yield put(actions.deleteGuideSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.deleteGuideFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
