import React from 'react'
import MenuItem from '@mui/material/MenuItem';

export default function MenuItemInc(props) {

  if(props.hasPermision === false){
    return null
  }
  return (
    
      <MenuItem
        {...props}
      >{props.children}</MenuItem>


  )
}
