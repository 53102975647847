import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({

  dependantstitle: {
    height: "60px",
    background: "#173E4B",
    color: "#fff",
    fontFamily: "Lato !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.0015em !important",
  },
  loudetailbox: {
    borderRight: "1px solid #A7A7A7",
    textAlign: "center",
    "&:nth-child(4)":{
      border: "none",
    },
    "& h4": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
    }
  },
  border:{
    borderBottom: " 1px solid #A7A7A7",
    width: "100%",
    marginTop: "20px",
    marginBottom: "30px",
  }
}));