import React, { useEffect, useState } from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import SkeletonWrapper from '../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';


import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { getPartner, getPartnerGuid, hasAuthourity } from '../../../../../lib/access.es6';
import { DisplayClaims } from './DisplayClaims';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress } from '@mui/material';
import debounce from 'debounce-promise'
import { searchHospitals } from '../../../../../lib/requests.es6';


const FilterClaims = (props) => {
  const classes = useStyle();

  const [selectedHospital, setSelectedHospital] = useState(null);

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [claims, setClaims] = useState(props.claimsData)

  const [selectedClaims, setSelectedClaims] = useState(props.claimsData.map(claim => { return {...claim, selected: false} } ))
  const [nothingSelected, setShowNothingSelectedError] = useState(false)



  const [openHospitals, setOpenHospitals] = useState(false);
  const [loadingHospitals, setHospitalLoading] = useState(false);
  const [inputHospitalValue, setInputHospitalValue] = useState('');

  const [hospitals, setHospitals] = useState([]);
  const [hospitalError, setHospitalError] = useState(false);

  const debounceHospitalSearch = debounce(searchHospitals, 3000)

  const fetchHospitals = async (query) => {
    setHospitalLoading(true);
    try {
      const response = await debounceHospitalSearch({ query });
      setHospitals(response.data);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
    setHospitalLoading(false);
  };

  const handleHospitalInputChange = (event, newValue) => {
    setInputHospitalValue(newValue);
    fetchHospitals(newValue);
 
  };


  useEffect(() => {
    setClaims(props.claimsData)
    setSelectedClaims(props.claimsData.map(claim => { return {...claim, selected: false} } ))
  }, [props.claimsData])

  // useEffect(() => {
  //   let claimsFilterd = [...props.claimsData];

  //   if (startDate && endDate && selectedHospital) {
  //     claimsFilterd = claimsFilterd.filter(claim => {
  //       const claimDate = new Date(claim.created_at);
  //       const startDateObj = new Date(startDate);
  //       const endDateObj = new Date(endDate);


  //       const results =  claimDate >= startDateObj && claimDate <= endDateObj.setDate(endDateObj.getDate() + 1) && claim.facility === selectedHospital.label;
  //       console.log("results", results)
  //       return results

  //     });
  //   }else if (selectedHospital) {
  //     console.log("selectedHospital", selectedHospital)
  //     claimsFilterd = claimsFilterd.filter(claim => claim.facility === selectedHospital.label)
  //     console.log("claimsFilterd", claimsFilterd)
     
  //   }else if (startDate && endDate) {
  //     claimsFilterd = claimsFilterd.filter(claim => {
  //       const claimDate = new Date(claim.created_at);
  //       const startDateObj = new Date(startDate);
  //       const endDateObj = new Date(endDate);

  //       return claimDate >= startDateObj && claimDate <= endDateObj.setDate(endDateObj.getDate() + 1);
  //     });
  //   }else {
  //     claimsFilterd = [...props.claimsData];
  //   }

  //   setClaims(claimsFilterd)
  //   setSelectedClaims(claimsFilterd)
  // }, [selectedHospital, startDate, endDate])

  if (props.loading) {
    return <SkeletonWrapper loading={props.loading} />
  }


  const handleClearFilter = () => {
    setSelectedHospital(null)
    setStartDate(null);
    setEndDate(null)
    setClaims(props.claimsData)
    setSelectedClaims(props.claimsData.map(claim => { return {...claim, selected: false} } ))
  };



  const handleClaimBulkApprove = () => {

    const claim_guids =  selectedClaims.filter(item => item.selected).map(claim =>  {return  claim.guid })
    console.log("claim_guids", claim_guids)
    if(claim_guids.length < 1){
      setShowNothingSelectedError(true)
      return
    }

    const payload = {
      "start_date": startDate,
      "end_date": endDate,
      "partner": getPartnerGuid(),
      "facility": selectedHospital ? selectedHospital.label: 'N/A',
      "status": props.status,
      "claim_guids": claim_guids
    }

    console.log({payload});

    props.handleBulkApproval(payload)
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === "startDate") {
      setStartDate(value)
      props.getClaimsByStartDate(value)
    }
    if (name === "endDate") {
      setEndDate(value)
      props.getClaimsByEndDate(value)
    }

  }
  const currencyCode = getPartner().country.currency_code

  const payableAmountInCents = 0;

  const payableAmount = payableAmountInCents ? payableAmountInCents / 100 : 0



  const handleBulkApproveSuccessfully = () => {
    props.resetBulkApproveSuccessAlert()
    handleClearFilter()
  }

  const handleBulkApproveError = () => {
    props.resetBulkApproveErrorAlert()
  }

  const handleFilterClaims = () => {
    console.log("newValue", inputHospitalValue)
    props.getClaimsFiltered()
  }

    
  return (
    <>
      <section className={classes.claimTable}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sm={12} className={classes.filterTitle}>
            <h2>Filter By:</h2>
          </Grid>
        </Grid>
        <ValidatorForm onSubmit={() => handleClaimBulkApprove()} >
          <Grid container spacing={2} className={classes.filterWrapper}>
            {
              props.type === "hospital" && <Grid item xs={12} md={4} sm={4} className={classes.autocomplete} >

                <div>
                <Autocomplete
                  open={openHospitals}
                  onOpen={() => {
                    setOpenHospitals(true);
                  }}
                  onClose={() => {
                    setOpenHospitals(false);
                  }}
                  onChange={(event, selectedHospital)=>{
                   // setHospital(selectedHospital)
                    setHospitalError(false)
                    props.getClaimByHosipital(selectedHospital.name)

                  }}
                  ListboxProps={{
                    sx: {
                      "& li": {
                        fontFamily: 'Open Sans',
                        fontStyle: "normal",
                        fontSize: "12px",
                        lineHeight: "25px",
                        color: "#000000",
                      },
                    }
                  }}
                  id="hospital"
                  name='hospital'
                  options={hospitals.sort((a, b) => (b.region ? -b.region.localeCompare(a.region) : b))}
                  groupBy={(option) => option.region}
                  getOptionLabel={(option) => option.name.toLowerCase()}
                  loading={loadingHospitals}
                  inputValue={inputHospitalValue}
                  onInputChange={handleHospitalInputChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Hospital"
                      variant="outlined"
                      placeholder="Start typing your hospital name"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loadingHospitals ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}

                />
                {hospitalError && <p style={{color: 'red'}}>Select a hospital</p>}

                  {/* <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={availableHospitals}
                    sx={{ width: 300 }}
                    value={selectedHospital}
                    onChange={(event, newValue) => {
                      setSelectedHospital(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Hospital"
                      required={false}
                      errorMessages={"start date is required"}
                    />}
                  /> */}
                </div>

              </Grid>
            }

            <Grid item xs={12} md={4} sm={4} >
              <TextValidator
                id="startDate"
                label="Start Date"
                name='startDate'
                value={startDate || ''}
                onChange={handleChange}
                fullWidth
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required={false}
                errorMessages={"start date is required"}
              />
              {props.hasBulkApprove && <Grid container spacing={2} className={classes.filterBtns}>
                <Grid item xs={12} md={6} sm={6}>
                  <p>Total  : KSH {payableAmount}</p>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  {
                    props.status === 'decision_pending'
                      ? <ButtonInc
                        hasPermision={hasAuthourity('BULK_APPROVE_CLAIMS')}
                        variant="contained" type='submit'>Bulk Approve</ButtonInc>
                      : <ButtonInc
                        hasPermision={hasAuthourity('BULK_PAY_APPROVE_CLAIMS')}
                        variant="contained" type='submit'>Bulk Pay Approve</ButtonInc>
                  }
                  
                </Grid>
              </Grid>}
            </Grid>
            <Grid item xs={12} md={4} sm={4} >

              <TextValidator
                id="endDate"
                label="End Date"
                name='endDate'
                value={endDate || ''}
                onChange={handleChange}
                fullWidth
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required={false}
                errorMessages={"start date is required"}
              />
            </Grid>
            <Grid container xs={12} md={6} sm={6} className={classes.filterBtns}>
        
              {/* <ButtonInc
                onClick={handleFilterClaims}
                variant="contained"
              >
                 Filter Claims
              </ButtonInc>
            </Grid>
            <Grid item xs={12} md={6} sm={6} className={classes.filterBtns}>
              <ButtonInc
                onClick={handleClearFilter}
                variant="contained"
              >
                Clear Filter
              </ButtonInc> */}
            </Grid>

          </Grid>
        </ValidatorForm>
        <Grid container spacing={2} style={{padding: "30px"}}>
          <Grid xs={12} sm={6} md={6}>
            <Grid container spacing={2}>
            <Grid  itemxs={12} sm={3} md={3}>
            <ButtonInc
                onClick={handleFilterClaims}
                variant="contained"
              >
                 Filter Claims
              </ButtonInc>
            </Grid>
            <Grid  itemxs={12} sm={6} md={6}>
            <ButtonInc
                onClick={handleClearFilter}
                variant="contained"
              >
                Clear Filter
              </ButtonInc>
            </Grid>
            </Grid>
          </Grid>
          
        </Grid>
     
        {nothingSelected && <p style={{color: 'red'}}> please select claims to approve </p>}
        <DisplayClaims pagingData={props.pagingData} getClaims={(payload)=> props.getClaims(payload)} currencyCode={currencyCode}  classes={classes} claims={claims} setSelectedClaims={setSelectedClaims} selectedClaims={selectedClaims} setShowNothingSelectedError={setShowNothingSelectedError} filteredClaims={props.filteredClaims}
          getClaimsFiltered={props.getClaimsFiltered}
          getClaimsByPage={props.getClaimsByPage}
          getClaimsBySize={props.getClaimsBySize}
          />

        {
          props.hasAlerts &&
          <>
            <AlertDialog
              custom
              show={props.showBulkApproveProgressAlert}
              size="sm"
              style={{ marginTop: '0', top: '30vh' }}
              confirmBtnText={'ok'}
              showCancel={false}
              showConfirm={false}
              cancelBtnText={'cancel'}
              showTitle={false}
              confirmBtnCssClass
            >
              <CircularProgress />
              <h2>submitting bulk approval request</h2>
            </AlertDialog>

            <AlertDialog
              success
              show={props.showBulkApproveSuccessAlert}
              size="sm"
              title={'submitted bulk approval request'}
              style={{ marginTop: '0', top: '30vh' }}
              onConfirm={() => { handleBulkApproveSuccessfully() }}
              confirmBtnText={'ok'}
              showCancel={false}
              showConfirm={false}
              cancelBtnText={'cancel'}
              confirmBtnCssClass
            >
              <div >
                <ButtonInc variant="contained" onClick={() => { handleBulkApproveSuccessfully() }} style={{ background: 'green', }}>Ok</ButtonInc>
              </div>
            </AlertDialog>

            <AlertDialog
              show={!!props.showBulkApproveErrorAlert}
              danger
              title={'Error submitting bulk approval request'}
              onConfirm={() => handleBulkApproveError()}
              confirmBtnText={'Try again'}
              confirmBtnCssClass
              showCancel={false}
              style={{ marginTop: '0', top: '30vh' }}
            >
              {props.bulkApproveError ? props.bulkApproveError.message : ''}
            </AlertDialog>
          </>
        }

      </section>

    </>
  )
}

export default FilterClaims