import * as types from '../../lib/actionTypes.es6';

export function uploadBulkSmsRequest(data) {
  return {
    type: types.UPLOAD_BULK_SMS_REQUEST,
    payload: data,
  };
}

export function uploadBulkSmsSuccess(response) {
  return {
    type: types.UPLOAD_BULK_SMS_SUCCESS,
    payload: response,
  };
}

export function uploadBulkSmsFailure(error) {
  return {
    type: types.UPLOAD_BULK_SMS_FAILED,
    payload: error,
  };
}

export function resetUploadBulkSms() {
  return {
    type: types.UPLOAD_BULK_SMS_RESET,
  };
}

export function summaryBulkSmsRequest(data) {
  return {
    type: types.SUMMARY_BULK_SMS_REQUEST,
    payload: data,
  };
}

export function summaryBulkSmsSuccess(response) {
  return {
    type: types.SUMMARY_BULK_SMS_SUCCESS,
    payload: response,
  };
}

export function summaryBulkSmsFailure(error) {
  return {
    type: types.SUMMARY_BULK_SMS_FAILED,
    payload: error,
  };
}

export function dowloadBulkSmsRequest(data) {
  return {
    type: types.DOWNLOAD_BULK_SMS_REQUEST,
    payload: data,
  };
}

export function dowloadBulkSmsSuccess(response) {
  return {
    type: types.DOWNLOAD_BULK_SMS_SUCCESS,
    payload: response,
  };
}

export function dowloadBulkSmsFailure(error) {
  return {
    type: types.DOWNLOAD_BULK_SMS_FAILED,
    payload: error,
  };
}

export function stopBlastRequest(batchId) {
  return {
    type: types.STOP_BLAST_REQUEST,
    payload: batchId,
  };
}

export function stopBlastSuccess(response) {
  return {
    type: types.STOP_BLAST_SUCCESS,
    payload: response,
  };
}

export function stopBlastFailure(error) {
  return {
    type: types.STOP_BLAST_FAILED,
    payload: error,
  };
}

export function resetStopBlast() {
  return {
    type: types.STOP_BLAST_RESET,
  };
}

export function getBatchProgressRequest(batchId) {
  return {
    type: types.GET_BATCH_PROGRESS_REQUEST,
    payload: batchId,
  };
}

export function getBatchProgressSuccess(response) {
  return {
    type: types.GET_BATCH_PROGRESS_SUCCESS,
    payload: response,
  };
}

export function getBatchProgressFailed(error) {
  return {
    type: types.GET_BATCH_PROGRESS_FAILED,
    payload: error,
  };
}