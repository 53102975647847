import React, { useState } from 'react'
import { useStyle } from "./Style";
import { ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import DropzoneS3Uploader from "react-dropzone-s3-uploader";
import ModalInc from '../../../../../shared-ui/ModalInc';
import { getS3BucketSignedUrlForBulkPayments } from '../../../../../lib/requests.es6';
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import ProgressState from '../ProgressState/ProgressState';
import { getPartnerGuid, getUserFullname, getUserGuid } from '../../../../../lib/access.es6';
import { Switch } from '@mui/material';
import DragableColumns from './dnd/DragableColumns';

const Upload = (props) => {
  const classes = useStyle();
  const [s3Url] = useState('inclusivity-bulk-payments.s3.amazonaws.com');
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null)
  const [isBundled, setIsBundled] = useState(false)
  const [confirmColumnOrder, setConfirmColumnOrder] = useState(false)
  const [columnMappings, setColumnMappings] = useState([])
  const [error, setError] = useState('')

  const handleFinishedUpload = (info) => {
    const payload = {
      "partner_guid": getPartnerGuid(),
      "bucketKey": info.bucketKey,
      filename: info.bucketKey,
      mappings: columnMappings,
      "is_bundled": isBundled,
      "web_agent_guid": getUserGuid(),
      "web_agent_name": getUserFullname()


    }
    props.saveBulkPayments(payload);
  }

  const getS3Ur = (file, callback) => getS3BucketSignedUrlForBulkPayments(file, callback)

  const uploadOptions = {
    getSignedUrl: getS3Ur,
    s3Url: s3Url,
    uploadRequestHeaders: {},
    onProgress: (progress) => setProgress(progress),
    onError: (errorMessage) => setErrorMessage(errorMessage),

  };


  const reset = () => {
    setProgress(0)
    props.close();
    window.location.reload();
  }

  return (
    <>

      <ModalInc
        modalTitle="Upload payment file"
        subTitle="Drag the columns right or left to match you excell columns"
        open={props.open}
        onClose={props.close}
        fullWidth
        maxWidth="md"
      >
        <ValidatorForm onSubmit={() => { }} >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>

              <Grid container style={{ textAlign: 'left', margin: '1%' }}>
                <DragableColumns
                  setColumnMappings={setColumnMappings}
                />

                <Grid item xs={12}>

                  <Grid container style={{ margin: '0 4%' }}>
                    <Grid item xs={6}>
                      <label style={{ color: "#000", fontSize: "16px", fontWeight: "300" }}>My excell file looks like above column order </label>
                      <Switch
                        checked={confirmColumnOrder}
                        onChange={() => {
                          setConfirmColumnOrder((confirmColumnOrder) => !confirmColumnOrder)
                          setError("")
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    
                    {error && <p style={{color: 'red'}}>{error}</p>}

                    </Grid>

                    <Grid item xs={6}>
                      <label style={{ color: "#000", fontSize: "16px", fontWeight: "300" }}>Is bundled payments</label>
                      <Switch
                        checked={isBundled}
                        onChange={() => setIsBundled((isBundled) => !isBundled)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </Grid>

                  </Grid>


                </Grid>
              </Grid>

              <div className={classes.uploadsection}>
                <DropzoneS3Uploader
                  onFinish={handleFinishedUpload}
                  upload={uploadOptions}
                  s3Url={s3Url}
                  passChildrenProps={false}
                  maxSize={1024 * 1024 * 500}
                  className={classes.uploadzone}
                  {...(!confirmColumnOrder && { onDrop: () => setError('please confirm your excell file matches the above sample table, drag columns around if needed.') })}
                  style={{
                    width: "100%",
                    height: "250px",
                    background: "#FFFFFF",
                    border: "1px dashed #B8B8B8",
                    marginTop: "40px",
                    padding: " 70px 0"
                  }}
                >
                  <div >
                    <ProgressState progress={progress} errorMessage={errorMessage} />

                  </div>
                </DropzoneS3Uploader>
              </div>
            </Grid>
            {props.errror && <p style={{ color: 'red' }}>{props.errror.message}</p>}
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'center' }}>
                  <ButtonInc
                    size="large"
                    onClick={reset}
                    type="submit"
                    variant="contained"
                    style={{ width: "221px", height: "55px", background: "#FBA92D", marginTop: '3%' }}
                  > I'm  Done</ButtonInc>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={props.close}
                    style={{ width: "221px", height: "55px", background: "#FBA92D", marginTop: '3%' }}
                  >
                    Cancel
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </ModalInc>
    </>
  )
}

export default Upload