import React,{useState} from 'react'
import ModalInc from '../../../../../shared-ui/ModalInc'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import { getPartnerGuid } from '../../../../../lib/access.es6';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress } from '@material-ui/core';


const CreateHospital = (props) => {

  const [hospitalname,setHospitalName] = useState('')
  const [hospitalcode, setHospitalCode] = useState('');
  const [serviceOffered,setServiceOffered] = useState('')
  const [physicalAddress,setPhysicalAddress] = useState('')
  const [telephone,setTelephone] = useState('')
  const [region,setRegion] = useState('')





  const handleChange = (event) =>{
    const {name,value} = event.target;
    if(name === "hospitalname"){
      setHospitalName(value);
    }
    if(name === "hospitalcode"){
      setHospitalCode(value);
    }
    if(name === "service_offered"){
      setServiceOffered(value)
    }
    if(name === "physical_address"){
      setPhysicalAddress(value)
    }
    if(name === "telephone"){
      setTelephone(value)
    }
    if(name === "region"){
      setRegion(value)
    }
  }
  const reset = () =>{
    setHospitalName('');
    setServiceOffered('');
    setPhysicalAddress('');
    setTelephone('');
    setRegion('');
  }

  const handleCreateHospital = () => {
    const payload = {
      "partner": getPartnerGuid(),
      "name": hospitalname,
      "code": hospitalcode,
      "service_offered": serviceOffered,
      "physical_address": physicalAddress,
      "telephone": telephone,
      "region": region
    }
    console.log("create hospital payload",payload )
    props.createHospital(payload)
    reset()

  }
  const handleHospitalCreatedSuccessfully = () =>{
    props.resetCreateHospitalSuccessAlert();
    props.close()
  }
  const handleHospitalCreateError = () =>{
    props.resetCreateHospitalErrorAlert();
    props.close()
  }
  return (
    <>
         <AlertDialog
        custom
        show={props.showCreateHospitalProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2> Creating Hospital...</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showCreateHospitalSuccessAlert}
        size="sm"
        title={'Hospital  created'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleHospitalCreatedSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={true}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      
      </AlertDialog>

      <AlertDialog
        show={props.showCreateHospitalErrorAlert}
        danger
        title={'Error creating Hospital'}
        onConfirm={() => handleHospitalCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
      
         {props.createHospitalError ? props.createHospitalError.message : ''} 
      </AlertDialog>
      <ModalInc
        modalTitle="Create Hospital "
        subTitle="Fill the below fields to create hospital"
        open={props.open}
        onClose={props.close}
        fullWidth
        maxWidth="md"
      >
        <ValidatorForm onSubmit={() => handleCreateHospital()} >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <TextValidator
                id="hospitalname"
                label="Hospital Name"
                name='hospitalname'
                value={hospitalname}
                onChange={handleChange}
                fullWidth
                type='text'
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter hospital name']}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextValidator
                id="hospitalcode"
                label="Hospital Code"
                name='hospitalcode'
                value={hospitalcode}
                onChange={handleChange}
                fullWidth
                type='text'
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter hospital code']}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextValidator
                id="service_offered"
                label="Service Offered"
                name='service_offered'
                value={serviceOffered}
                onChange={handleChange}
                fullWidth
                type='text'
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextValidator
                id="physical_address"
                label="Physical Address"
                name='physical_address'
                value={physicalAddress}
                onChange={handleChange}
                fullWidth
                type='text'
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextValidator
                id="telephone"
                label="Telephone"
                name='telephone'
                value={telephone}
                onChange={handleChange}
                fullWidth
                type='text'
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextValidator
                id="region"
                label="Region"
                name='region'
                value={region}
                onChange={handleChange}
                fullWidth
                type='text'
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} md={6}>

            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    type='submit'
                    style={{ marginTop: "20px", float: "right" }}
                  >
                    Create Hospital
                  </ButtonInc>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginTop: "20px" }}
                    onClick={props.close}
                  >
                    Cancel
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </ModalInc>
    </>
  )
}

export default CreateHospital