import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import Tooltip from '@mui/material/Tooltip';
import SkeletonWrapper from '../../NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import { formatDateLocale } from '../../../lib/utils.es6';
import TableInc from '../../../shared-ui/TableInc';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';

export default function ViewReportTemplates(props) {
  const classes = useStyle();

  const templates = props.templates || [];


  if (props.loading) return <SkeletonWrapper type="table" loading={true} />


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}  >
          <Grid container spacing={2} className={classes.claimdocswrapper}>
            <Grid item xs={12} sm={12} md={12}>

              <TableInc
                columns={[
                  { title: 'Name', field: 'title', render: rowData => rowData.title || "N/A" },
                  { title: 'Created At', field: 'created_at', render: rowData => formatDateLocale(rowData.created_at) || "N/A" },
                  { title: 'Updated At', field: 'updated_at', render: rowData => formatDateLocale(rowData.created_at) || "N/A" },
                  { title: 'Created By', field: 'created_by', render: rowData => rowData.created_by || "N/A" },
                  { title: 'Updated By', field: 'updated_by', render: rowData => rowData.updated_by || "N/A" },
                  {
                    title: 'Actions', field: 'created_At', render: rowData => {
                        return <div className={classes.actionbtn}>
                             <Tooltip title={" Edit Document"} >
                              <span onClick={() => props.setTemplateReportToEdit(rowData)} style={{ cursor: "pointer" }} ><EditIcon sx={{ color: "green" }} /> </span>
                            </Tooltip>
                            <Tooltip onClick={() => props.setReportTemplateToDelete(rowData)}  title={" Delete Document"} > 
                              <span style={{ cursor: "pointer" }} ><DeleteOutlineOutlinedIcon sx={{ color: " #B3251E" }} /> </span>
                            </Tooltip>


                        </div>
                 
                    }
                  },
                ]}
                data={templates}
                options={{
                  pageSize: templates.length < 5
                    ? templates.length
                    : 10,
                  showTitle: false,
                }}

              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
