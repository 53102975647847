import * as types from '../../lib/actionTypes.es6';

export function reportPreAuthorizationRequest(reportType) {
  return {
    type: types.REPORT_PRE_AUTHORIZATION_REQUEST,
    payload: reportType,
  };
}

export function reportPreAuthorizationRequestSuccess(reportURL) {
  return {
    type: types.REPORT_PRE_AUTHORIZATION_SUCCESS,
    payload: reportURL,
  };
}

export function reportPreAuthorizationRequestError(error) {
  return {
    type: types.REPORT_PRE_AUTHORIZATION_FAILURE,
    payload: error,
  };
}

export function reportPreAuthorizationReset() {
  return {
    type: types.REPORT_PRE_AUTHORIZATION_RESET,
  };
}
