import * as types from '../../../../../../lib/actionTypes.es6';
import GAlogger, { monitoringEventCategories } from '../../../../../../lib/monitoring.es6';

export function getAllOrangePaymentsRequest(customerGuid) {
  return {
    type: types.GET_ALL_ORANGE_PAYMENTS_REQUEST,
    payload: customerGuid,
  };
}

export function getAllOrangePaymentsSuccess(files) {
  return {
    type: types.GET_ALL_ORANGE_PAYMENTS_SUCCESS,
    payload: files,
  };
}

export function getAllOrangePaymentsFail(response) {
  return {
    type: types.GET_ALL_ORANGE_PAYMENTS_FAILURE,
    payload: response,
  };
}


export function getAllOrangeProductsRequest() {
  return {
    type: types.GET_ALL_ORANGE_PRODUCTS_REQUEST,
  };
}

export function getAllOrangeProductsSuccess(products) {
  return {
    type: types.GET_ALL_ORANGE_PRODUCTS_SUCCESS,
    payload: products,
  };
}

export function getAllOrangeProductsFail(response) {
  return {
    type: types.GET_ALL_ORANGE_PRODUCTS_FAILURE,
    payload: response,
  };
}

export function addOrangeMissingPaymentRequest(payment) {
  return {
    type: types.ADD_ORANGE_MISSING_PAYMENT_REQUEST,
    payload: payment,
  };
}

export function addOrangeMissingPaymentSuccess(payment) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.addPayment);
  return {
    type: types.ADD_ORANGE_MISSING_PAYMENT_SUCCESS,
    payload: payment,
  };
}

export function addOrangeMissingPaymentFail(response) {
  return {
    type: types.ADD_ORANGE_MISSING_PAYMENT_FAIL,
    payload: response,
  };
}

export function addOrangeMissingPaymentReset() {
  return {
    type: types.ADD_ORANGE_MISSING_PAYMENT_RESET,
  };
}