import React from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import CircularProgress from '@material-ui/core/CircularProgress';
import {  DatePicker } from '@material-ui/pickers';
import * as actions from './actions.es6';
import { localisedText } from '../../lib/localisation.es6';
import * as globalActions from '../Main/actions.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { availableFormat } from '../../lib/reportUtils.es6';
import {
  formatDate,
  formatDateTime,
  downloadBlobReports,
} from '../../lib/utils.es6';

export class ReportClaimFinalisation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      start_date: formatDate(new Date()),
      end_date: formatDate(new Date()),
      labels: new LocalizedStrings(localisedText),
    };
  }

  componentWillMount() {
    this.props.dispatch(globalActions.hideCustomerBar());
    this.updateLanguage();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'ReportClaimFinalisation',
      this.state.labels
    );
  }

  getReport() {
    const type = document.getElementById('type').value;
    const payload = {
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      type,
    };
    this.props.dispatch(actions.claimFinalisationReportRequest(payload));
  }

  resetReport() {
    this.props.dispatch(actions.claimFinalisationReportReset());
  }

  selectPartner(partner) {
    this.props.dispatch(globalActions.setCurrentPartner(partner));
  }

  dateHandler(type, moment) {
    if (type === 'start_date') {
      this.setState({ start_date: formatDate(new Date(moment)) });
    } else if (type === 'end_date') {
      this.setState({ end_date: formatDate(new Date(moment)) });
    }
    this.resetReport();
  }

  render() {
    const labels = this.state.labels;
    const fileName = `claim-finalisation-report-${formatDateTime(new Date())}.${
      document.getElementById('type')
        ? document.getElementById('type').value
        : ''
    }`;
    return (
      <div>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_title">
                <h2>{labels.claimFinalisationReport}</h2>
                <div className="clearfix" />
              </div>
              <div className="x_content">
                <br />
                <div className="row form-group">
                  <label
                    className="control-label col-md-4 col-sm-4 col-xs-12"
                    htmlFor="start_date"
                  >
                    {labels.startDate}
                    <span>*</span>
                  </label>
                  <div className="col-md-2 col-sm-2 col-xs-12">
                    <DatePicker
                      value={this.state.start_date}
                      onChange={this.dateHandler.bind(this, 'start_date')}
                      format="YYYY-MM-DD"
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <label
                    className="control-label col-md-4 col-sm-4 col-xs-12"
                    htmlFor="end_date"
                  >
                    {labels.endDate}
                    <span>*</span>
                  </label>
                  <div className="col-md-2 col-sm-2 col-xs-12">
                    <DatePicker
                      value={this.state.end_date}
                      onChange={this.dateHandler.bind(this, 'end_date')}
                      format="YYYY-MM-DD"
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <label
                    className="control-label col-md-4 col-sm-4 col-xs-12"
                    htmlFor="type"
                  >
                    {labels.reportType}
                    <span>*</span>
                  </label>
                  <div className="col-md-2 col-sm-2 col-xs-12">
                    <select
                      id="type"
                      className="form-control"
                      onChange={this.resetReport.bind(this)}
                    >
                      {availableFormat().map((format, index) => (
                        <option key={index} value={format}>
                          {format.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-sm-offset-4 col-sm-5">
                  <button
                    className="btn btn-primary"
                    onClick={this.getReport.bind(this)}
                  >
                    {labels.generateReport}
                  </button>
                </div>
                <br />
                {this.props.reportsData.loader ? (
                  <div className="col-sm-offset-4 col-sm-1">
                    <center>
                      <CircularProgress />
                    </center>
                  </div>
                ) : (
                  <div />
                )}
                {this.props.reportsData.reportBlob ? (
                  <div className="report-url">
                    <br />
                    <br />
                    <div className="col-sm-offset-4 col-sm-5">
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          downloadBlobReports(
                            this.props.reportsData.reportBlob,
                            fileName
                          )
                        }
                      >
                        {labels.downloadReport}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  reportsData: state.claimFinalisationReport,
  globalData: state.global,
}))(ReportClaimFinalisation);
