import React from 'react';
import { Button } from '@material-ui/core';
import { partnerCanPayClaim, hasAuthourity } from '../../lib/access.es6';
import './index.css';

export default class ClaimProcessingButtons extends React.Component {
  clickHandle(status) {
    this.props.onClickHandle(status);
  }

  updateClaim() {
    this.props.onUpdateHandle();
  }

  // TODO: We should enable buttons instead i.e. have them disabled by default
  approveClaimBtnProps() {
    const approveBtnProps = {};
    const claimData = this.props.claim;
    if (
      [
        'All_Docs_Pending',
        'Docs_Partially_Submitted',
        'Not_Valid',
        'Approved_Paid',
        'ExGratia_Paid',
        'Payment_In_Review',
        'Expired',
      ].includes(claimData.status)
    ) {
      approveBtnProps.disabled = 'disabled';
      approveBtnProps.className = 'buttonDefault';
    }else{
      approveBtnProps.className = 'buttonGreen';
    }
    return approveBtnProps;
  }

  approveClaimText() {
    const labels = this.props.labels;
    const claimData = this.props.claim;
    let text = labels.approveClaimBtn;
    const payableStatus = this.props.payable_status;
    if (payableStatus.includes(claimData.status)) {
      text = partnerCanPayClaim() ? labels.payClaimBtn : labels.markAsPaidBtn;
    } else if (claimData.status === 'Rejected_Pending') {
      text = labels.revertRejection;
    } else if (claimData.status === 'Rejected') {
      text = labels.revertApproval;
    }
    return text;
  }

  approveBtnStatus() {
    const claimData = this.props.claim;
    let status = 'Approved_Pending';
    if (claimData.status === 'Approved_Pending') {
      status = 'Approved';
    } else if (claimData.status === 'Rejected_Pending') {
      status = 'Decision_Pending';
    } else if (claimData.status === 'Approved') {
      status = 'Approved_Paid';
    } else if (claimData.status === 'Rejected') {
      status = 'ExGratia_Pending';
    } else if (claimData.status === 'ExGratia_Pending') {
      status = 'ExGratia';
    } else if (claimData.status === 'ExGratia') {
      // NOTE Not reviewed yet
      status = 'ExGratia_Paid';
    }
    return status;
  }

  // TODO: We should enable buttons instead i.e. have them disabled by default
  rejectClaimBtnProps() {
    const rejectBtnProps = {};
    const claimData = this.props.claim;
    if (
      [
        'All_Docs_Pending',
        'Docs_Partially_Submitted',
        'Not_Valid',
        'Approved',
        'Approved_Paid',
        'Rejected',
        'ExGratia',
        'ExGratia_Paid',
        'ExGratia_Pending',
        'Payment_In_Review',
        'Expired',
      ].includes(claimData.status)
    ) {
      rejectBtnProps.disabled = 'disabled';
      rejectBtnProps.className = 'buttonDefault';
    }else{
      rejectBtnProps.className = 'buttonDanger';
    }
    return rejectBtnProps;
  }

  rejectClaimText() {
    const labels = this.props.labels;
    const claimData = this.props.claim;
    let text = labels.rejectClaimBtn;
    if (claimData.status === 'Rejected_Pending') {
      text = labels.confirmRejectionBtn;
    } else if (claimData.status === 'Approved_Pending') {
      text = labels.reverseClaimBtn;
    }
    return text;
  }

  rejectBtnStatus() {
    const claimData = this.props.claim;
    let status = 'Rejected_Pending';
    if (claimData.status === 'Approved_Pending') {
      status = 'Decision_Pending';
    } else if (claimData.status === 'ExGratia_Pending') {
      status = 'Rejected';
    } else if (claimData.status === 'Rejected_Pending') {
      status = 'Rejected';
    }
    return status;
  }

  revertExpiredClaim() {
    return this.props.labels.revertExpiredClaim;
  }

  render() {
    const labels = this.props.labels;
    const claimData = this.props.claim;
    return (
      <div className="row" style={{ marginLeft: '5%', marginRight: '5%' }}>
        <div
          className="col-sm-2 "
          style={{ border: '2px solid gray', borderRadius: '5px' }}
        >
          <br />
          <p>
            <b>
              {labels.claimAmount} {this.props.total}
            </b>
          </p>
          <br />
        </div>
        <div
          className="col-sm-2 "
          style={{ border: '2px solid gray', borderRadius: '5px' }}
        >
          <br />
          <p>
            <b>
              {labels.claimEditor}{' '}
              {claimData.editor_full_name ? claimData.editor_full_name : 'N/A'}
            </b>
          </p>
          <br />
        </div>
        <div className="col-sm-7 col-sm-offset-1 pull-right">
          <Button
            style={{marginRight: '1%', color: 'white'}}
            {...this.approveClaimBtnProps()}
            onClick={this.clickHandle.bind(this, this.approveBtnStatus())}
            variant="contained"
          >
            {this.approveClaimText()}
          </Button>
          <Button
            style={{color: 'white'}}
            {...this.rejectClaimBtnProps()}
            onClick={this.clickHandle.bind(this, this.rejectBtnStatus())}
            variant="contained"
          >
            {this.rejectClaimText()}
          </Button>
          {hasAuthourity('REVERT_EXPIRED_CLAIM') &&
          this.props.claim.status === 'Expired' ? (
            <Button
              style={{color: 'white'}}
              className='buttonGreen'
              onClick={this.props.reverseClaim.bind(this)}
              variant="contained"
            >
              {this.revertExpiredClaim()}
            </Button>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}
