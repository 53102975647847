import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../lib/actionTypes.es6';

import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../../lib/access.es6';
import { GAloggerException } from '../../../lib/monitoring.es6';
import { addDependant, addPrincipal, deleteDependant, deletePrincipal, editDependant, editPrincipal, getTempMembers, uploadExcel } from '../../../lib/requests.es6';


export function* getTempMembersRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.GET_ALL_TEMP_MEMBERS_REQUEST);
      const response = yield call(getTempMembers, payload);
      yield put(actions.getAllTemporaryMemberSuccess(response.data));
    } catch (e) {
      yield put(
        actions.getAllTemporaryMemberFailed(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* uploadExcelMembersRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.UPLOAD_EXCEL_MEMBERS_REQUEST);
      const response = yield call(uploadExcel, payload);
      yield put(actions.uploadExcelMembersSuccess(response.data));
    } catch (e) {
      yield put(
        actions.uploadExcelMembersFailed(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


// ADD
export function* addPrincipalRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.ADD_PRINCIPAL_REQUEST);
      const response = yield call(addPrincipal, payload);
      yield put(actions.addPrincipalSuccess(response.data));
      yield put(actions.getAllTemporaryMemberRequest({ page: 0, size: 1000, groupId: payload.group_id }))
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
      }
      yield put(
        actions.addPrincipalFailed(errorPayload)
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* addDependantRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.ADD_DEPENDANT_REQUEST);
      const response = yield call(addDependant, payload);
      yield put(actions.addDependantSuccess(response.data));
      yield put(actions.getAllTemporaryMemberRequest({ page: 0, size: 1000, groupId: payload.group_id }))
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
      }
      yield put(
        actions.addDependantFailed(errorPayload)
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

// EDIT
export function* editPrincipalRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.EDIT_PRINCIPAL_REQUEST);
      const response = yield call(editPrincipal, payload);
      yield put(actions.editPrincipalSuccess(response.data));
      yield put(actions.getAllTemporaryMemberRequest({ page: 0, size: 1000, groupId: payload.group_id }))
    } catch (e) {
      yield put(
        actions.editPrincipalFailed(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* editDependantRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.EDIT_DEPENDANT_REQUEST);
      const response = yield call(editDependant, payload);
      yield put(actions.editDependantSuccess(response.data));
      yield put(actions.getAllTemporaryMemberRequest({ page: 0, size: 1000, groupId: payload.group_id }))
    } catch (e) {
      yield put(
        actions.editDependantFailed(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


// DELETE

export function* deletePrincipalRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.DELETE_PRINCIPAL_REQUEST);
      const response = yield call(deletePrincipal, payload.principal_id);
      yield put(actions.deletePrincipalSuccess(response.data));
      yield put(actions.getAllTemporaryMemberRequest({ page: 0, size: 1000, groupId: payload.group_id }))
    } catch (e) {
      yield put(
        actions.deletePrincipalFailed(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* deleteDependantRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.DELETE_DEPENDANT_REQUEST);
      const response = yield call(deleteDependant, payload.dependant_id);
      yield put(actions.deleteDependantSuccess(response.data));
      yield put(actions.getAllTemporaryMemberRequest({ page: 0, size: 1000, groupId: payload.group_id }))
    } catch (e) {
      yield put(
        actions.deleteDependantFailed(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}