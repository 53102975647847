import React, { useState } from 'react'
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, CircularProgress, Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useStyle } from "./Style";
import AlertDialog from '../../../../../../../components/AlertDialog/index.es6';
import { getPartner } from '../../../../../../../lib/access.es6';

const BuyPolicy = (props) => {
  if(!props.allProducts){
    return
  }
  const classes = useStyle();
  const [selectProduct, setSelectProduct] = useState(props.allProducts);
  const [premiumData, setPremiumData] = useState([])
  const [spouseNid, setSpouseNid] = useState('')
  const [showSpouseNid, setShowSpouseNid] = useState(false)
  const [coverType, setCoverType] = useState('');
  const [selectedPremium, setSelectedPremium] = useState('');
  const [selectedProductCode, setSelectedProductCode] = useState('')
  const handleChange = (event) => {
    const { name, value } = event.target
    if (name === 'selectproduct') {
      setSelectProduct(value)
      if (selectProduct) {
        setCoverType(value.cover_type)
        setShowSpouseNid(true);
        setPremiumData(value.premiums)
        setSelectedProductCode(value.code)
      }
    }
    if (name === 'spouseNid') {
      setSpouseNid(value)
    }
    if (name === 'selectpremium') {
      setSelectedPremium(value)
      console.log("selectedPremium", selectedPremium)
    }
  }

  const buySimplePolicy = () => {
    let payload
    if (coverType === 'Family') {
      payload = {
        product_code: selectedProductCode,
        msisdn: props.customerMsisdn,
        amount_in_cents: selectedPremium,
        spouse_id: spouseNid
      }
    } else {
      payload = {
        product_code: selectedProductCode,
        msisdn: props.customerMsisdn,
        amount_in_cents: selectedPremium,
      }
    }
    props.BuyPolicy(payload)
    console.log(payload)
  }
  const resetAfterSucesfullPurchase = () =>{
    props.resetAfterSucesfullPurchase();
    props.toggleBuyPolicyDialog()
  }
  const resetAfterFailedPurchase = ()=>{
    props.resetAfterFailedPurchase();
    props.toggleBuyPolicyDialog()
  }
  const currencyCode = getPartner().country.currency_code

  return (
    <>
      <Dialog open={props.openBuyPolicyDialog} onClose={props.toggleBuyPolicyDialog}
        fullWidth
        maxWidth="md">
        <ValidatorForm onSubmit={() => buySimplePolicy()} >
          <DialogTitle className={classes.registermodaltitle}>
            <div >
              <h3>{props.labels.titleBuyPolicy}</h3> 
            </div>
          </DialogTitle>
          <DialogContent className={classes.registerinputwrapper}>
            <DialogContentText>
              {props.labels.subtitleBuyPolicy}
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sm={12}>

                <SelectValidator
                  name='selectproduct'
                  labelId="select-product"
                  id="select-product"
                  value={selectProduct}
                  label={props.labels.selectProduct}
                  onChange={handleChange}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={[`${props.labels.selectProductError}}`]}
                  style={{ width: "100%" }}
                >
                  {
                    props.allProducts.map((product, index) => (
                      <MenuItem
                        key={index}
                        style={{
                          fontFamily: "Open Sans",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "15px",
                          color: "#000000",
                        }} value={product}>{
                          product.name
                        }</MenuItem>
                    )


                    )
                  }
                </SelectValidator>


              </Grid>
              {
                coverType === 'Family' && showSpouseNid && <Grid item xs={12} md={12} sm={12}>
                  <TextValidator
                    name='spouseNid'
                    id="spouseNid"
                    label={props.labels.spouseId}
                    type="number"
                    value={spouseNid}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    validators={['required']}
                    errorMessages={[`${props.labels.spouseIdError}`]} 
                  />
                </Grid>

              }



              <Grid item xs={12} md={12} sm={12}>

                <SelectValidator
                  name='selectpremium'
                  labelId="select-premium"
                  id="select-premium"
                  value={selectedPremium}
                  label={props.labels.SelectAcover}  
                  onChange={handleChange}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={[`${props.labels.selectCoverError}`]}
                  style={{ width: "100%" }}
                >
                  {
                    premiumData.map((premium, index) => (
                      <MenuItem
                        key={index}
                        style={{
                          fontFamily: "Open Sans",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "15px",
                          color: "#000000",
                        }} value={premium.amount_in_cents}>{
                          premium.cardinality + " " + premium.granularity + " -" + currencyCode +  " " + premium.amount_in_cents / 100
                        }</MenuItem>
                    )


                    )
                  }
                </SelectValidator>


              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.registerbtns} >
            <Button variant="contained" type='submit'>{props.labels.titleBuyPolicy}</Button>
            <Button variant="outlined" onClick={props.toggleBuyPolicyDialog}>{props.labels.cancel}</Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>

      <AlertDialog
        custom
        show={props.loader}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>{props.labels.purchasingPolicy}</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={!!props.successfullPolicyPurchase}
        size="sm"
        title={props.policyPurchaseInfo ? props.policyPurchaseInfo.message : `${props.labels.PolicyPurchaseRequestSubmitted}`}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { resetAfterSucesfullPurchase() }}
        confirmBtnText={'ok'}
        showCancel={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>


      <AlertDialog
        show={!!props.policyPurchaseFailure}
        danger
        title={`${props.labels.ErrorPurchasingPolicy}`}
        onConfirm={() => { resetAfterFailedPurchase() }}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.policyPurchaseDataError ? props.policyPurchaseDataError.message : ''}
      </AlertDialog>

    </>
  )
}

export default BuyPolicy