import React from 'react';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { hashHistory } from 'react-router';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LocalizedStrings from 'react-localization';
import { localisedText } from '../../../lib/localisation.es6';
import { updateCurrentLanguage } from '../../../lib/languageUtils.es6';
import * as actions from '../GroupMembers/actions.es6';
import { getCustomer } from '../../Customer/actions.es6';
import GroupCustomers from '../../../components/GroupResourceComponents/groupCustomers.es6';
import GroupClaims from '../../../components/GroupResourceComponents/groupClaims.es6';
import GroupPremiums from '../../../components/GroupResourceComponents/groupPremiums.es6';
import GroupPolicies from '../../../components/GroupResourceComponents/groupPolicies.es6';
import GroupPayments from '../../../components/GroupResourceComponents/groupPayments.es6';
import { selectClaim, refreshClaimRequest } from '../../ClaimsProcessing/actions.es6';
import { Callback } from '../../../components/Callback/index.es6';
import { claimCallbackDeleteRequest, resetClaimCallbackDelete } from '../../ClaimCallback/actions.es6';
import AlertDialog from '../../../components/AlertDialog/index.es6';
import { Backdrop, CircularProgress, Grid } from '@material-ui/core';
import { hasAuthourity } from '../../../lib/access.es6';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../lib/constants';


export class GroupStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
      value: 0,
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      policiesPage: DEFAULT_PAGE,
      policiesSize: DEFAULT_SIZE,
    }
  }

  componentWillMount() {
    this.updateLanguage();
    const groupId = this.props.params.groupId

    this.getGroupMembers()
    this.props.dispatch(actions.getCurrentGroupClaimsRequest(groupId));
    this.props.dispatch(actions.getCurrentGroupPaymentsRequest(groupId));
    this.props.dispatch(actions.getCurrentGroupPremiumsRequest(groupId));
    this.getPolicies()
    this.props.dispatch(actions.getCurrentGroupClaimCallbacksRequest(groupId));

  }

  getGroupMembers = () =>{
    const { page, size } = this.state
    const groupId = this.props.params.groupId
    if(hasAuthourity('VIEW_ALL_GROUPS')){
      this.props.dispatch(actions.getCurrentGroupMembersForAdminsRequest({groupId, page, size }));
    }else{
      this.props.dispatch(actions.getCurrentGroupMembersRequest({groupId, page, size }));
    }
  }

  getPolicies = () => {
    const { policiesPage: page, policiesSize: size } = this.state
    const groupId = this.props.params.groupId
    this.props.dispatch(actions.getCurrentGroupPoliciesRequest({groupId, page, size }));
  }

  viewClaim(claim) {
    this.props.dispatch(selectClaim(claim));
    this.props.dispatch(refreshClaimRequest(claim.guid))
    hashHistory.push(`admin/claims_processing/${claim.guid}`);
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'GroupStatus',
      this.state.labels
    );
  }

  redirectToCustomerStatusPage(customerGuid) {
    this.props.dispatch(getCustomer(customerGuid));
    hashHistory.push('admin/home');
  }

  redirectToCustomerMakeClaimPage(customerGuid) {
    this.props.dispatch(getCustomer(customerGuid));
    hashHistory.push('admin/make_claim');
  }

  deleteCallback(guid) {
    this.props.dispatch(claimCallbackDeleteRequest(guid));
  }

  resetDeleteCallback(){
    this.props.dispatch(resetClaimCallbackDelete());
    let groupId = this.props.params.groupId
    if(groupId){
      this.props.dispatch(actions.getCurrentGroupClaimCallbacksRequest(groupId));
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChangePageSize(event) {
    event.preventDefault();
    const size = parseInt(event.target.value);
    console.log('size: ', size);
    this.setState({ size }, function () {
      this.getGroupMembers()
    });
  }

  handlePageClick(data) {
    const page = data.selected;
    console.log('page: ', page);
    this.setState({ page }, function () {
      this.getGroupMembers()
    });
  }

    handleChangePoliciesPageSize(event) {
    event.preventDefault();
    const policiesSize = parseInt(event.target.value);
    console.log('policiesSize: ', policiesSize);
    this.setState({ policiesSize }, function () {
      this.getPolicies()
    });
  }

  handlePoliciesPageClick(data) {
    const policiesPage = data.selected;
    console.log('policiesPage: ', policiesPage);
    this.setState({ policiesPage }, function () {
      this.getPolicies()
    });
  }


  getPages() {
    return Math.ceil(this.props.currentGroupData.count / this.state.size);
  }

  getPoliciesPages() {
    return Math.ceil(this.props.currentGroupData.policiesCount / this.state.policiesSize);
  }

  pageSizeSelector() {
    const { size } = this.state;
    const display = {
      display: this.props.currentGroupData.count > size ? '' : 'none',
    };
    return (
      <select
        value={size}
        onChange={this.handleChangePageSize.bind(this)}
        style={display}
      >
        <option>5</option>
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    );
  }

  paginateGroups() {
    const pages = this.getPages();
    const display = {
      display: pages > 1 ? '' : 'none',
    };
    const labels = this.state.labels;
    return (
      <div style={display}>
        <ReactPaginate
          previousLabel={labels.paginationPreviousText}
          nextLabel={labels.paginationNextText}
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick.bind(this)}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
          forcePage={this.state.page}
        />
      </div>
    );
  }


  policiesPageSizeSelector() {
    const { policiesSize } = this.state;
    const display = {
      display: this.props.currentGroupData.count > policiesSize ? '' : 'none',
    };
    return (
      <select
        value={policiesSize}
        onChange={this.handleChangePoliciesPageSize.bind(this)}
        style={display}
      >
        <option>5</option>
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    );
  }

  policesPaginateGroups() {
    const pages = this.getPoliciesPages();
    const display = {
      display: pages > 1 ? '' : 'none',
    };
    const labels = this.state.labels;
    return (
      <div style={display}>
        <ReactPaginate
          previousLabel={labels.paginationPreviousText}
          nextLabel={labels.paginationNextText}
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePoliciesPageClick.bind(this)}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
          forcePage={this.state.page}
        />
      </div>
    );
  }

  renderGroupMembersPagination(){
    return <Grid container style={{alignItems: "right", backgroundColor: 'white'}}>
        <Grid item xs={11} style={{ textAlign: "right", backgroundColor: 'white'}}> {this.paginateGroups()}</Grid>
        <Grid item xs={1} style={{textAlign: "left", backgroundColor: 'white', paddingLeft: '1%', margin: "auto" }}> {this.pageSizeSelector()} </Grid>
    </Grid>
  }

  renderPoliciesPagination(){
    return <Grid container style={{alignItems: "right", backgroundColor: 'white'}}>
        <Grid item xs={11} style={{ textAlign: "right", backgroundColor: 'white'}}> {this.policesPaginateGroups()}</Grid>
        <Grid item xs={1} style={{textAlign: "left", backgroundColor: 'white', paddingLeft: '1%', margin: "auto" }}> {this.policiesPageSizeSelector()} </Grid>
    </Grid>
  }

  displayTabContent(currentTabValue) {
    const { members, policies, premiums, claims, payments, claimCallbacks } = this.props.currentGroupData;
    switch (currentTabValue) {
      case 0:
        return <GroupCustomers
          paginatation={this.renderGroupMembersPagination()}
          members={members}
          redirectToCustomerStatusPage={this.redirectToCustomerStatusPage.bind(this)}
          redirectToCustomerMakeClaimPage={this.redirectToCustomerMakeClaimPage.bind(this)}
        />;
      case 1:
        return <GroupPremiums
          premiums={premiums}
        />;
      case 2:
        return <GroupPayments
          payments={payments}
        />;
      case 3:
        return <GroupPolicies
          paginatation={this.renderPoliciesPagination()}
          policies={policies}
        />;
      case 4:
        return <GroupClaims
          viewClaim={this.viewClaim.bind(this)}
          claims={claims}
        />;
      case 5:
        return <Callback
        deleteCallback={this.deleteCallback.bind(this)}
        callback={claimCallbacks}
        viewCustomer={this.redirectToCustomerMakeClaimPage.bind(this)}
        columnWidth="col-md-12"
        globalData={this.props.globalData}
      />
      default:
        return <h2>Something wrong</h2>;

    }

  }

  render() {
    const { deleteCallbackSuccess } = this.props.callbackData
    return (
      <div style={{width: '98%', margin: "auto" }}>
         <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={this.props.currentGroupData.loaderMembers}>
            <CircularProgress size={80} color="inherit" />
          </Backdrop>
        <Paper style={{ flexGrow: 1 }}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            centered
          >
            <Tab style={{ width: '100%' }} label="Members" />
            <Tab style={{ width: '100%' }} label="Premiums" />
            <Tab style={{ width: '100%' }} label="Payments" />
            <Tab style={{ width: '100%' }} label="Policies" />
            <Tab style={{ width: '100%' }} label="Claims" />
            <Tab style={{ width: '100%' }} label="Claim Callbacks" />
          </Tabs>

        </Paper>
        <div style={{ margin: '2%' }}>
          {
            this.displayTabContent(this.state.value)
          }
        </div>
        <AlertDialog
          success
          show={!!deleteCallbackSuccess}
          title={'Call back request deleted'}
          confirmBtnText={'OK'}
          onConfirm={() => this.resetDeleteCallback()}
          showCancel={false}
          confirmBtnCssClass
        >
        </AlertDialog>;
    </div>

    );
  }
}

export default connect(state => ({
  groupRegistrationData: state.groupRegistration,
  globalData: state.global,
  currentGroupData: state.currentGroup,
  callbackData: state.claimsCallBack,
}))(GroupStatus);