import * as types from '../../../lib/actionTypes.es6';

export const initialState = {
  excelFileUploaded: null,
  certificateFileUploaded: null,
  errors: null,


  preRegisterGroupLoader: false,
  groupPreRegistrationError: null,
  groupPreRegisteration: null,
  groupPreRegistrationSuccess: null,

  registerGroupLoader: false,
  groupRegistrationError: null,
  groupRegisteration: null,
  groupRegistrationSuccess: null,

  actualGroupQuote: null,
  actualGroupQuoteSuccess: null,
  actualGroupQuoteError: null,
  actualGroupQuoteLoader: false,

  updateGroup: null,
  updateGroupSuccess: null,
  updateGroupError: null,
  updateGroupLoader: false,

  getGroup: null,
  getGroupSuccess: null,
  getGroupError: null,
  getGroupLoader: false,
};

export default function groupRegistrationReducer(state = initialState, action) {
  switch (action.type) {
    case types.GROUP_CERTIFICATE_UPLOAD_CALLBACK:
      return { ...state, certificateFileUploaded: action.payload };

    case types.GROUP_EXCEL_UPLOAD_CALLBACK:
      return { ...state, excelFileUploaded: action.payload };

    case types.REGISTER_GROUP_REQUEST:
      return { ...state, registerGroupLoader: true, groupRegistrationError: null, groupRegistrationSuccess: null };

    case types.REGISTER_GROUP_SUCCESS:
      return { ...state, registerGroupLoader: false, groupRegisteration: action.payload, groupRegistrationSuccess: true };

    case types.REGISTER_GROUP_FAILURE:
      return { ...state, registerGroupLoader: false, groupRegistrationError: action.payload };


    case types.PRE_REGISTER_GROUP_REQUEST:
      return { ...state, preRegisterGroupLoader: true, groupPreRegistrationError: null, groupPreRegistrationSuccess: null };

    case types.PRE_REGISTER_GROUP_SUCCESS:
      return { ...state, preRegisterGroupLoader: false, groupPreRegisteration: action.payload, groupPreRegistrationSuccess: true };

    case types.PRE_REGISTER_GROUP_FAILURE:
      return { ...state, preRegisterGroupLoader: false, groupPreRegistrationError: action.payload };


    case types.GET_ACTUAL_GROUP_QUOTE_REQUEST:
      return { ...state, actualGroupQuoteLoader: true, actualGroupQuoteError: null, actualGroupQuote: null };

    case types.GET_ACTUAL_GROUP_QUOTE_SUCCESS:
      return { ...state, actualGroupQuoteLoader: false, actualGroupQuote: action.payload, actualGroupQuoteSuccess: true };

    case types.GET_ACTUAL_GROUP_QUOTE_FAILURE:
      return { ...state, actualGroupQuoteLoader: false, actualGroupQuoteError: action.payload };

    case types.UPDATE_GROUP_REQUEST:
      return { ...state, updateGroupLoader: true, updateGroupError: null, updateGroup: null };

    case types.UPDATE_GROUP_SUCCESS:
      return { ...state, updateGroupLoader: false, updateGroup: action.payload, updateGroupSuccess: true };

    case types.UPDATE_GROUP_FAILURE:
      return { ...state, updateGroupLoader: false, updateGroupError: action.payload };

    case types.GET_GROUP_REQUEST:
      return { ...state, getGroupLoader: true, getGroupError: null, getGroup: null };

    case types.GET_GROUP_SUCCESS:
      return { ...state, getGroupLoader: false, getGroup: action.payload, getGroupSuccess: true };

    case types.GET_GROUP_FAILURE:
      return { ...state, getGroupLoader: false, getGroupError: action.payload };

    case types.RESET_GROUP_REGISTRAION:
      return { ...state, groupRegistrationError: null, groupRegistrationSuccess: null, groupRegisteration: null };

    case types.RESET_GROUP_PRE_REGISTRAION:
      return { ...state, groupPreRegistrationError: null, groupPreRegistrationSuccess: null, groupPreRegisteration: null };

    case types.RESET_ACTUAL_QUOTE:
      return { ...state, actualGroupQuoteSuccess: null, actualGroupQuoteError: null, updateGroupSuccess: null };
    default:
      return state;
  }
}
