// material-ui
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';

// project import
import { drawerWidth } from '../../../store/constant';

const openedMixin = (theme) => ({
    width: drawerWidth,
    borderRight: 'none',
    zIndex: 1099,
    background: theme.palette.background.default,
    overflowX: 'hidden',
    boxShadow: theme.palette.mode === 'dark' ? theme.customShadows.z1 : 'none',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    })
});

const closedMixin = (theme) => ({
    borderRight: 'none',
    zIndex: 1099,
    background: theme.palette.background.default,
    overflowX: 'hidden',
    width: 72,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    })
});

// ==============================|| DRAWER - MINI STYLED ||============================== //

const MiniDrawerStyled = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    borderRight: '0px',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    }),
    "& ul": {
        "& span": {
            margin: "10px 0px 0.35em",
            fontFamily: "Lato",
            lineheight: "1.66",
            display: "block",
            color: "rgb(18, 25, 38)",
            fontSize: "0.875rem",
            fontWeight: 500,
            padding: "6px",
            textTransform: "capitalize",
        },
        "& p": {
            margin: "0px",
            fontSize: " 0.875rem",
            fontWeight: 400,
            lineHeight: "1.334em",
            fontFamily: "Open Sans",
            color: "rgb(54, 65, 82)"
        },
        "& svg": {
            width: "20px"
        }
    }
}));

export default MiniDrawerStyled;
