import React from 'react'
import Grid from "@material-ui/core/Grid";
import TableInc from '../../../../../shared-ui/TableInc';
import { useStyle } from "./Style";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';


const ITEM_HEIGHT = 48;

const Documents = (props) => {
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const documents = props.documents || [];


  return (
    <>
      {
        documents.length > 0 ? (<>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12}>
              <TableInc
                columns={[
                  {
                    title: 'Document Name', field: 'filename'
                  },
                  {
                    title: 'View Document', field: 'url', render: rowData => {
                      return <>
                        <Tooltip title="View Document">
                          <span style={{ cursor: "pointer" }} ><a href={rowData.url} target='_blank' rel="noopener noreferrer" ><VisibilityIcon sx={{ color: " #4B7E34" }} /></a> </span>
                        </Tooltip>
                      </>
                    }
                  },
                  {
                    title: 'action', field: 'claimaction', render: rowData => {
                      return <div className={classes.actionbtn}>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? 'long-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={handleClick}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          className={classes.tableactionbtns}
                          id="long-menu"
                          MenuListProps={{
                            'aria-labelledby': 'long-button',
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: '20ch',
                            },
                          }}
                        >

                          <MenuItem onClick={handleClose}>
                            <MenuItem style={{ cursor: "pointer" }} onClick={() => props.setDocumentToDelete(rowData)} >Delete Document</MenuItem>
                          </MenuItem>

                        </Menu>
                      </div>
                    }
                  },
                ]}
                title="Claims"
                data={
                  documents
                }
                options={{
                  pageSize: documents.length < 5
                    ? documents.length
                    : 5,
                  exportButton: true,
                  showTitle: false,
                }}
              />
            </Grid>
          </Grid>

        </>) : (<>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} >
              <div className={classes.policieswrapper}>
                <div className={classes.nodatafound}>
                  <p>No documents found  </p>
                </div>
              </div>
            </Grid>
          </Grid>

        </>)
      }
    </>
  )
}

export default Documents