import * as types from '../../lib/actionTypes.es6';

export function getCountriesRequest() {
  return {
    type: types.GET_COUNTRIES_REQUEST,
  };
}

export function getCountriesSuccess(response) {
  return {
    type: types.GET_COUNTRIES_SUCCESS,
    payload: response,
  };
}

export function getCountriesFail(response) {
  return {
    type: types.GET_COUNTRIES_FAILED,
    payload: response,
  };
}

// export function createCountryRequest(payload) {
//   return {
//     type: types.CREATE_COUNTRY_REQUEST,
//     payload,
//   };
// }

// export function createCountrySuccess(response) {
//   return {
//     type: types.CREATE_COUNTRY_SUCCESS,
//     payload: response,
//   };
// }

// export function createCountryFail(response) {
//   return {
//     type: types.CREATE_COUNTRY_FAILED,
//     payload: response,
//   };
// }

export function deleteCountryRequest(guid) {
  return {
    type: types.DELETE_COUNTRY_REQUEST,
    payload: guid,
  };
}

export function deleteCountrySuccess(response) {
  return {
    type: types.DELETE_COUNTRY_SUCCESS,
    payload: response,
  };
}

export function deleteCountryFail(response) {
  return {
    type: types.DELETE_COUNTRY_FAILED,
    payload: response,
  };
}

export function updateCountryRequest(payload) {
  return {
    type: types.UPDATE_COUNTRY_REQUEST,
    payload,
  };
}

export function updateCountrySuccess(response) {
  return {
    type: types.UPDATE_COUNTRY_SUCCESS,
    payload: response,
  };
}

export function updateCountryFail(response) {
  return {
    type: types.UPDATE_COUNTRY_FAILED,
    payload: response,
  };
}


// new
export function setCountryToEdit(response) {
  return {
    type: types.SET_COUNTRY_TO_UPDATE,
    payload: response,
  };
}

export function setCountryToDelete(response) {
  return {
    type: types.SET_COUNTRY_TO_DELETE,
    payload: response,
  };
}

export function resetCountryToEdit(response) {
  return {
    type: types.RESET_COUNTRY_TO_UPDATE,
  };
}

export function resetCountryToDelete(response) {
  return {
    type: types.RESET_COUNTRY_TO_DELETE,
  };
}

export function createCountryRequest(payload) {
  return {
    type: types.CREATE_COUNTRY_REQUEST,
    payload,
  };
}

export function createCountryFail(error) {
  return {
    type: types.CREATE_COUNTRY_FAILED,
    payload: error,
  };
}

export function createCountrySuccess(response) {
  return {
    type: types.CREATE_COUNTRY_SUCCESS,
    payload: response,
  };
}

export function resetCreateCountrySuccess(error) {
  return {
    type: types.RESET_CREATE_COUNTRY_SUCCESS,
  };
}

export function resetCreateCountryError(error) {
  return {
    type: types.RESET_CREATE_COUNTRY_ERROR,
  };
}

export function hardDeleteCountryRequest(payload) {
  return {
    type: types.DELETE_COUNTRY_REQUEST,
    payload,
  };
}

export function hardDeleteCountryFailure(error) {
  return {
    type: types.DELETE_COUNTRY_FAILED,
    payload: error,
  };
}

export function hardDeleteCountrySuccess(response) {
  return {
    type: types.DELETE_COUNTRY_SUCCESS,
    payload: response,
  };
}

export function resetHardDeleteCountrySuccess(error) {
  return {
    type: types.RESET_DELETE_COUNTRY_SUCCESS,
  };
}

export function resetHardDeleteCountryError(error) {
  return {
    type: types.RESET_DELETE_COUNTRY_ERROR,
  };
}

export function editCountryRequest(payload) {
  return {
    type: types.UPDATE_COUNTRY_REQUEST,
    payload,
  };
}

export function editCountryFailure(error) {
  return {
    type: types.UPDATE_COUNTRY_FAILED,
    payload: error,
  };
}

export function editCountrySuccess(response) {
  return {
    type: types.UPDATE_COUNTRY_SUCCESS,
    payload: response,
  };
}

export function resetEditCountrySuccess(error) {
  return {
    type: types.RESET_UPDATE_COUNTRY_SUCCESS,
  };
}

export function resetEditCountryError(error) {
  return {
    type: types.RESET_UPDATE_COUNTRY_ERROR,
  };
}