import React, { useState } from 'react';
import TablePagination from '@mui/material/TablePagination';
import Grid from "@material-ui/core/Grid";
import TableInc from '../../../../../shared-ui/TableInc';
import { useStyle } from "./Style";
import { PRIMARY_THEME_COLOR_SHADE_2 } from '../../../../../lib/constants';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { extractMessageFromSmsPayload, formatDateLocale } from '../../../../../lib/utils.es6';

const SmsActivity = (props) => {
  const classes = useStyle();
  const smsesActivities = props.smses || [];
  const smses = smsesActivities.map(sms => {
    return {
      ...sms,
      message: extractMessageFromSmsPayload(sms)
    }
  });


  if (props.simple) {
    return <Simple smses={smses} title={'Sms activities'} />
  }
  return (
    <>
      {
        smses.length > 0 ? (<>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12}>
              <TableInc
                columns={[
                  {
                    title: 'Created  At', field: 'created_at', render: rowData => <>
                      {
                        formatDateLocale(rowData.created_at) || "N/A"
                      }
                    </>
                  },
                  { title: 'Message', field: 'message' },
                  {
                    title: 'Status', field: 'status'
                  },
                 /* {
                    title: 'MNO Status', field: 'mno_status'
                  }, **/
                  {
                    title: 'Request Type', field: 'request_type'
                  },

                ]}
                title="SMS Activity"
                data={
                  smses
                }
                options={{
                  pageSize: smses.length < 5
                    ? smses.length
                    : 5,
                  exportButton: true,
                  showTitle: false,
                }}
              />
            </Grid>
          </Grid>

        </>) : (<>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} >
              <div className={classes.policieswrapper}>
                <div className={classes.nodatafound}>
                  <p>No sms found!</p>
                </div>
              </div>
            </Grid>
          </Grid>

        </>)
      }
    </>
  )
}

export default SmsActivity

const Simple = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return <div style={{ marginTop: "0%" }}>
    {props.title && <div className="page-title">
      <div className="title_left" style={{ color: PRIMARY_THEME_COLOR_SHADE_2 }}>
        <h4>{props.title}</h4>
      </div>
    </div>}
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead style={{ color: 'red' }}>
          <TableRow >
            <TableCell className="table-cell-header-dep">Created at	</TableCell>
            <TableCell className="table-cell-header-dep">Message	</TableCell>
            <TableCell className="table-cell-header-dep">Status	</TableCell>
            {/* <TableCell className="table-cell-header-dep">MNO Status	</TableCell> **/}
            <TableCell className="table-cell-header-dep">Request  Type	</TableCell>
          </TableRow>
        </TableHead>
            <TableBody>
              {props.smses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sms) => {
                return (
                  <TableRow key={sms.id}>
                <TableCell>{formatDateLocale(sms.created_at) || "N/A"}</TableCell>
                <TableCell>{ extractMessageFromSmsPayload(sms) }
                </TableCell>
                <TableCell>{sms.status}</TableCell>
                { /* <TableCell>{sms.mno_status}</TableCell> **/}
                <TableCell>{sms.request_type}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
          rowsPerPageOptions={[3, 5]}
          component="div"
          count={props.smses.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
  </div>
}