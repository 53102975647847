import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";




const PolicyHeader = (props) => {
	const classes = useStyle();
	return (
		<section className={classes.policyheader}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6} md={6}>
					<div className={classes.policytitle}>
						<h1>Customer Status</h1>
					</div>
				</Grid>

			</Grid>
		</section>
	)
}

export default PolicyHeader