import React from 'react';
import TableInc from '../../../../../../shared-ui/TableInc';
import { updateExtendedConfig } from '../../../../../../lib/requests.es6';


const ProductTypesTable = (props) => {
  let dataContent = [];

  if (props.membership_configs && props.membership_configs.content) {
    dataContent = props.membership_configs.content.product_types.map((type => {
      return {
        type: type
      }
    }));
  }

  const data = dataContent || [];


  const convertArrayOfString = (newData) => {
    const results =  newData.map((type => {
      return type.type
    }) )
    return results
  }

  const updateMembershipConfigs = async (newData) => {
    const payload = {
      "product_guid": props.productGuid,
      ...props.membership_configs,
      "content": {
        ...props.membership_configs.content,
        product_types: newData
      }
    }
    await updateExtendedConfig(payload)
     window.location.reload(true);
  };

  return (
    <TableInc
      title="Product Types"
      columns={[
        { title: 'types', field: 'type', },
   
      ]}
      data={data}
      options={{
        addRowPosition: 'first',
        showTitle: true,
        actionsColumnIndex: -1,
        pageSize: data.length,
      }}
      actionsColumnIndex={-1}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {

            const newDataArray = [...data, newData];
            const newArray = convertArrayOfString(newDataArray)           
            //setData(newDataArray);
            updateMembershipConfigs(newArray);
            resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            //setData(dataUpdate);
            const newArray = convertArrayOfString(dataUpdate)  
            updateMembershipConfigs(newArray);
            resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            const dataDelete = [...data];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);
           // setData(dataDelete);
           const newArray = convertArrayOfString(dataDelete) 
            updateMembershipConfigs(newArray);
            resolve();
          }),
      }}
    />
  );
};

export default ProductTypesTable;
