import React from "react";
import { connect } from "react-redux";
import SmsActivity from "./Components/DisplaySmsActivity/SmsActivity";
import { getOrangeSmsActivityRequest, } from "./Redux/actions";

class OrangeSmsActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentWillMount() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      const msisdn = currentCustomer.msisdn
      this.props.dispatch(getOrangeSmsActivityRequest(msisdn));
    }

  }



  render() {
    return (
      <>
        <SmsActivity 
        labels={this.props.labels}
        loader={this.props.orangeSmsActivityData.get_sms_activity_loader}
        smses={this.props.orangeSmsActivityData.smsActivities}
        />
      </>
    )
  }
}
export default connect((state) => ({
  customerData: state.currentCustomer,
  orangeSmsActivityData: state.orangeSmsActivityData,
}))(OrangeSmsActivity);