import React, { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  Typography
} from "@mui/material";
import ButtonInc from "../../../../shared-ui/ButtonInc";
import MultipleSelectChip from "../../../PartnerStatus/components/Edit/MultipleSelectChip";
import { updateClaim } from "../../lib/api";
import { isReadOnlyStatus, updateStatus } from "../../lib/helpers";

const HospitalForm = ({
  claim,
  metaData,
  conditions,
  hospitals,
  rejectionReason,
}) => {
  const [formData, setFormData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");

  useEffect(() => {
    setFormData({
      high_risk: claim.high_risk || false,
      doc_channel: claim.doc_channel || [],
      accidental:
        claim.accidental !== undefined ? claim.accidental.toString() : "",
      birth_certificate_valid: claim.birth_certificate_valid || false,
      verify_by: claim.verify_by,
      documentation_received_time:
        claim.documentation_received_time !== undefined
          ? claim.documentation_received_time.toString()
          : "",
      comments: claim.comments || "",
      received_files: claim.received_files,
      status: claim.status,
      hospital_admission_date: claim.hospital_admission_date,
      hospital_discharge_date: claim.hospital_discharge_date,
      hospitalization_reason: claim.hospitalization_reason,
      hospital_name: claim.hospital_name,
      hospital_guid: claim.hospital_guid,
      condition: claim.condition,
      type: claim.type,
      hospital: null,
    });
  }, [claim]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    console.log({ name, value, type });

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleVerificationChange = (newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      verify_by: newValue.join(","),
    }));
  };

  const handleDocChannelChange = (event) => {
    const { value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      doc_channel: [value],
    }));
  };

  const handleHospitalChange = (event, value) => {
    const hospital = hospitals.find(hospital => hospital.name === value) || null
    setFormData({
      ...formData,
      hospital: hospital,
      hospital_name: hospital ? hospital.name : '',
      hospital_guid: hospital ? hospital.guid : '',

    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    console.log("resformData", formData);

    const updatedFormData = updateStatus(formData);

    const payload = {
      ...claim,
      ...updatedFormData,
      verify_by: updatedFormData.verify_by,
      documentation_received_time: updatedFormData.documentation_received_time,
    };

    const res = await updateClaim(payload);
    console.log("res", res);

    setIsSubmitting(false);
    console.log("formData: ", formData);

    setSubmitMessage("Form submitted successfully!");
  };

  const formatDatetimeLocal = (datetime) => {
    const date = new Date(datetime);
    const isoString = date.toISOString();
    return isoString.slice(0, 16); // Ensures format YYYY-MM-DDTHH:MM
  };

  const verification = [
    "Hospital_Invoice",
    "Doctor_Letter",
    "Hospital_Records",
    "Conversation_with_doctor",
    "Other_Verification",
  ];
  // const verification = metaData ? metaData.verify_by['Personal_Accident'] : ["Hospital_Invoice", "Doctor_Letter", "Hospital_Records", "Conversation_with_doctor", "Other_Verification"];
  const isReadOnly = isReadOnlyStatus(formData.status);
  // console.log("formdata: ", formData);
  const verify_by = formData.verify_by ? formData.verify_by.split(",") : [];

  return (
    <Paper elevation={0} sx={{ p: 3, mb: 4 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Hospital Admission Date"
              type="date"
              name="hospital_admission_date"
              value={formData.hospital_admission_date || ""}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Hospital Discharge Date"
              type="date"
              name="hospital_discharge_date"
              value={formData.hospital_discharge_date || ""}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Hospitalization Reason"
              name="hospitalization_reason"
              value={formData.hospitalization_reason || ""}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item xs={12}>
      <Autocomplete
        options={hospitals.map(hospital => hospital.name)}
        value={formData.hospital ? formData.hospital.name : ''}
        onChange={handleHospitalChange}
        renderInput={(params) => (
          <TextField 
            {...params} 
            fullWidth 
            label="Hospital Name" 
            name="hospital_name" 
            required 
          />
        )}
      />
    </Grid>

          <Grid item xs={12} sm={12}>
            <FormControl fullWidth required>
              <InputLabel>Condition</InputLabel>
              <Select
                name="condition"
                value={
                  formData.condition 
                }
                onChange={handleChange}
                disabled={isReadOnly}
              >
                {conditions.map((condition) => {
                  return (
                    <MenuItem value={condition.value}>
                      {condition.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Document Received Via</InputLabel>
              <Select
                name="doc_channel"
                value={
                  formData.doc_channel && formData.doc_channel.length > 0
                    ? formData.doc_channel[0]
                    : ""
                }
                onChange={handleDocChannelChange}
                disabled={isReadOnly}
              >
                <MenuItem value="">Please select</MenuItem>
                <MenuItem value="whatsapp">WhatsApp</MenuItem>
                <MenuItem value="email">Email</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Accidental</InputLabel>
              <Select
                name="accidental"
                value={
                  formData.accidental === "false"
                    ? "false"
                    : formData.accidental === "true"
                    ? "true"
                    : ""
                }
                onChange={handleChange}
                disabled={isReadOnly}
              >
                <MenuItem value="">Please select</MenuItem>
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <MultipleSelectChip
              fieldLabel="Verification Received"
              fields={[...verify_by]}
              setFields={handleVerificationChange}
              constantFields={verification}
              multiple={true}
              displayLabel={false}
              style={{ width: "100%", marginLeft: "0px" }}
              disabled={isReadOnly}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.birth_certificate_valid || false}
                  onChange={handleChange}
                  name="birth_certificate_valid"
                />
              }
              label="Birth Certificate Valid"
              disabled={isReadOnly}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.high_risk || false}
                  onChange={handleChange}
                  name="high_risk"
                  disabled={isReadOnly}
                />
              }
              label="High Risk"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="All Documentation Complete Date"
              type="datetime-local"
              name="documentation_received_time"
              value={
                formData.documentation_received_time
                  ? formatDatetimeLocal(formData.documentation_received_time)
                  : ""
              }
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Comment"
              name="comments"
              value={formData.comments || ""}
              onChange={handleChange}
              multiline
              rows={4}
              disabled={isReadOnly}
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <ButtonInc
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting || isReadOnly}
            >
              {isSubmitting ? "Updating..." : "Update Claim details"}
            </ButtonInc>
          </Grid>
        </Grid>
      </form>
      {submitMessage && (
        <Typography color="primary">{submitMessage}</Typography>
      )}
    </Paper>
  );
};

export default HospitalForm;
