import { take, call, put } from 'redux-saga/effects';
import { getReportRegistrationBlob } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import * as types from '../../lib/actionTypes.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

export function* reportsRegistrationRequestWatcher() {
  for (;;) {
    const reportAction = yield take(types.REPORT_REGISTRATION_REQUEST);
    try {
      const response = yield call(
        getReportRegistrationBlob,
        reportAction.payload
      );
      yield put(actions.reportRegistrationRequestSuccess(response.data));
    } catch (e) {
      yield put(
        actions.reportRegistrationRequestError(
          'An error occurred, this could be due to invalid data'
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
