import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography, Box } from '@material-ui/core';
import AlertDialog from '../../../../../../components/AlertDialog/index.es6';
import { getExtendedRelationships, getNuclearRelationships, getNuclearRelationships2 } from '../../../helpers';


export default function SelectRelationship(props) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showWarning, setShowWarning] = useState(false)

  const parentPlan = props.parentsPlan ? props.parentsPlan : props.selectedPlan
  const parentsRelationships = getExtendedRelationships(parentPlan)
  const nuclearRelationships = getNuclearRelationships(props.selectedPlan)

  const relations = () => {
    if(props.memberRelationshipType === 'nuclear_members') return nuclearRelationships
    if(props.memberRelationshipType === 'parent_members') return parentsRelationships
    if(props.memberRelationshipType === 'extended_members') return getNuclearRelationships2()
    return []
  } 
  const relationships = relations()

  const countRelationship = (relationship) => {
    
    
    let count = 0;
    props.dependants.forEach(dependent => {
      if (dependent.relationship === relationship) {
        count++;
      }
    });
    return count;
  }


  useEffect(() => {

    if (props.dependants.length === 0) {
      props.setCurrentRelationShip('Principal')
      props.setCurrentComponent(props.COMPONENT_TYPES.ADD_DEPENDANT)
    }

    if (props.hasParentsPlan === true && props.lastSelectedExtendedRelationship) {
      props.setCurrentRelationShip(props.lastSelectedExtendedRelationship)
      props.setCurrentComponent(props.COMPONENT_TYPES.ADD_DEPENDANT)
    }

  }, [])
  const handleSelect = (option) => {
      setSelectedOption(option);
  };

  const add = () => {
    let xxx = parentsRelationships.map(relationshipObj => Object.keys(relationshipObj)[0]);
    if (xxx.includes(selectedOption) && props.hasParentsPlan === null){
      setShowWarning(true)
    } else {
      props.setCurrentRelationShip(selectedOption)
      props.setCurrentComponent(props.COMPONENT_TYPES.ADD_DEPENDANT)
    }

  }

  const continueWithCurrentPlan = () => {
    props.setCurrentRelationShip(selectedOption)
    props.setCurrentComponent(props.COMPONENT_TYPES.ADD_DEPENDANT)
    props.setHasParentsPlan(false)

  }

  const changlePlan = () => {
    props.setHasParentsPlan(true)
    props.setCurrentRelationShip(selectedOption)
    props.setCurrentComponent(props.COMPONENT_TYPES.SELECT_PLAN)
  }

  const cancel = () => {
    props.viewMembers()
    setSelectedOption(null)
  }

  return (
    <div style={{ display: props.currenRelationShip ? 'none' : 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Who would you like to add next?
      </Typography>
      <div style={{ maxHeight: '360px', overflowY: 'scroll', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {relationships.map((relationshipObj) => {
          const option = Object.keys(relationshipObj)[0]; // Get the key from the object
          const maximumCount = ()=> {
            if(props.memberRelationshipType === 'extended_members') {
              const extendedDeps = props.dependants.filter(d => d.type === "Extended_2")
              return 12 - extendedDeps.length
            }else{
              return relationshipObj[option]
            }
          }
          const maxCount = maximumCount(); // Get the value associated with the key
          const count = countRelationship(option);

          return (
            <Paper
              key={option}
              onClick={count < maxCount ? () => handleSelect(option) : null}

              style={{
                margin: '10px',
                padding: '20px',
                cursor: count < maxCount ? 'pointer' : 'not-allowed',
                backgroundColor: selectedOption === option ? '#3f51b5' : '#fff',
                color: selectedOption === option ? '#fff' : '#000',
                width: '300px', // This line sets a fixed width
                opacity: count < maxCount ? '1' : '0.6',
              }}
            >
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6">{option}</Typography>
                <Typography variant="h6">{`${count}/${maxCount}`}</Typography>
              </Box>
            </Paper>
          );
        })}

      </div>
      <Button variant="contained" color="primary" disabled={!selectedOption} style={{ marginTop: '1%' }} onClick={add}>
        Add
      </Button>

      <Button variant="contained" color="primary" style={{ marginTop: '1%' }} onClick={cancel}>
        cancel
      </Button>

      <AlertDialog
        warning
        show={showWarning}
        size="sm"
        title={'Would you like to select new plan for parents'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => continueWithCurrentPlan()}
        onCancel={() => changlePlan()}
        confirmBtnText={'Continue with plan'}
        showCancel={true}
        showConfirm={true}
        cancelBtnText={'Change Plan'}
        confirmBtnCssClass
      >
      </AlertDialog>
    </div>
  );
}
