import React from 'react'
import { ClaimStatusEnum } from '../../../lib/enum';
import { AllDocsPending } from './AllDocsPending';
import { Rejected } from './Rejected';
import DecisionPending from './DecisionPending';
import ApprovedPaid from './ApprovedPaid';
import Approved from './Approved';
import DocsPartiallySubmitted from './DocsPartiallySubmitted';
import AwaitingConfirmation from './AwaitingConfirmation';
import FailedConfirmation from './FailedConfirmation';
import RejectedPending from './RejectedPending';

const ClaimActionButtons = (props) => {

  switch (props.claim.status) {

    case ClaimStatusEnum.Rejected:
    case ClaimStatusEnum.Discarded:
      return <Rejected {...props} />

    case ClaimStatusEnum.Rejected_Pending:
      return <RejectedPending {...props} />

    case ClaimStatusEnum.All_Docs_Pending:
      return <AllDocsPending {...props} />

    case ClaimStatusEnum.Docs_Partially_Submitted:
      return <DocsPartiallySubmitted  {...props} />

    case ClaimStatusEnum.Awaiting_Confirmation:
      return <AwaitingConfirmation {...props} />

    case ClaimStatusEnum.Failed_Confirmation:
      return <FailedConfirmation {...props} />

    case ClaimStatusEnum.Decision_Pending:
      return <DecisionPending {...props} />

    case ClaimStatusEnum.Approved:
      return <Approved {...props} />

    case ClaimStatusEnum.Approved_Paid:
      return <ApprovedPaid {...props} />

    default:
      return ""
  }
}

export default ClaimActionButtons