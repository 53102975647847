import React, { useEffect, useState } from 'react'
import { useStyle } from "./Style";
import Adddependant from '../AddDependant/Adddependant';
import { getPartner, getPartnerGuid } from '../../../../../lib/access.es6';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress } from '@material-ui/core';
import { Button } from '@mui/material';
import SelectProduct from '../SelectProduct';
import { find } from 'lodash';

const Createquote = (props) => {
  const classes = useStyle();

  const [togglequoteanddependant, settogglequoteanddependant] = useState(true)
  const [dependants, setDependants] = useState([]);
  const [depAlert, setDepAlert] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);


  useEffect(() => {
    if (props.quoteToReCreate) {
      const policyEnteries = props.quoteToReCreate.quotation.policy_schedule_entries

      const tempDep = policyEnteries.map(entry => {
        return {
          ...entry.dependant,
          benefits: entry.benefits,
          premium_amount_in_cents: entry.premium_amount_in_cents
        }
      })
      setDependants(tempDep)
    }
  }, [props.quoteToReCreate])



  const handleAdddependant = () => {
    settogglequoteanddependant(!togglequoteanddependant)
  }


  const removeDependant = (id) => {
    const currentDependents = dependants

    console.log("currentDependents",{
      id
    } )

    const updatedDeps = currentDependents.filter(curD => curD.id !== id)

    //currentDependents.splice(index, 1)
    setDependants(updatedDeps)
    setDepAlert(true)
  }
  const handleCreateQuote = () => {
    const payload = {
      "product_guid":selectedProduct ? selectedProduct.guid : 'n/a',
      "product_name": selectedProduct ? selectedProduct.name : 'n/a',
      "product_code": selectedProduct ? selectedProduct.code : 'n/a',
      "customer_guid": props.customer_guid,
      "partner": getPartnerGuid(),
      "lapse_period": 3,
      "premium_granularity": "month",
      "premium_cardinality": 1,
      "policy_schedule_entries": [
        ...dependants
      ]
    }
    props.createQuote(payload)
  }
  const handleQuoteCreatedSuccessfully = () => {
    props.resetAddQuoteSuccessAlert();
  }

  const handleQuoteCreateError = () => {
    props.resetAddQuoteErrorAlert();
  }

  const handlenextStep = () => {
    props.handleNext();
  }

  const currencyCode = getPartner().country.currency_code



  const nuclear = selectedProduct ? selectedProduct.membership_configs.content.nuclear.map(item => item.relationship) : [];


  const extended = selectedProduct ? selectedProduct.membership_configs.content.extended.map(item => item.relationship) : [];
  const relationships = [
    'Nuclear', ...nuclear,
    'Extended', ...extended
  ]

  const getMemberConfig = (relationship) => {

    if (nuclear.includes(relationship)) {
      const res = find(selectedProduct.membership_configs.content.nuclear, { "relationship": relationship })
      res['type'] = nuclear
      return res
    }

    if (extended.includes(relationship)) {
      const res = find(selectedProduct.membership_configs.content.extended, { "relationship": relationship })
      res['type'] = extended
      return res
    }

    return null
  }

  const countRelationships = (relationship) => {
    const count = dependants.reduce((total, member) => {
      if (member.relationship === relationship) {
        return total + 1;
      }
      return total;
    }, 0);

    return count;
  }

  const getMemberType = (relationship) => {
    if (nuclear.includes(relationship)) return 'Nuclear'
    if (extended.includes(relationship)) return 'Extended'
    return 'N/A'
  }

  const getExtendedMemberConfig = (dateOfBirth) => {
    const age = getAge(dateOfBirth);

    const extendedPremiums = selectedProduct.membership_configs 
                             ? (selectedProduct.membership_configs.content.premiums ? selectedProduct.membership_configs.content.premiums : [])
                             : []
  
    const config = extendedPremiums.find((premium) => {
      return age >= premium.entry_age && age <= premium.exit_age;
    });

    if (config) {
      return config;
    } else {
      return 0;
    }
  };
  

  return (
    <>

      <AlertDialog
        custom
        show={props.showAddQuoteProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2> Creating Quote...</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showAddQuoteSuccessAlert}
        size="sm"
        title={'Quote  created'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleQuoteCreatedSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => { handlenextStep() }} style={{ background: 'green', }}>View Quote Details</Button>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showAddQuoteErrorAlert}
        danger
        title={'Error creating Quote'}
        onConfirm={() => handleQuoteCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {
          "error"
        }
        {/* {props.createQuoteError ? props.createQuoteError.message : ''} */}
      </AlertDialog>

      <SelectProduct
        products={props.products}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
      />

      <hr />

      {
        selectedProduct &&
        <>
          <Adddependant
            dependant={props.dependant}
            handleCreateQuote={handleCreateQuote}
            handleAdddependant={handleAdddependant}
            createQuote={props.createQuote}
            setDependants={setDependants}
            dependants={dependants}
            createDependant={props.createDependant}

            showAddDependantProgressAlert={props.showAddDependantProgressAlert}
            showAddDependantSuccessAlert={props.showAddDependantSuccessAlert}
            showAddDependantErrorAlert={props.showAddDependantErrorAlert}
            resetAddDependantSuccessAlert={props.resetAddDependantSuccessAlert}
            currencyCode={currencyCode}
            removeDependant={removeDependant}
            depAlert={depAlert}
            setDepAlert={setDepAlert}
            principal={props.principal}
            autoFillPrincipal={props.autoFillPrincipal}
            toggleAutoFillPrincipal={props.toggleAutoFillPrincipal}
            selectedProduct={selectedProduct}
            relationships={relationships}
            getMemberConfig={getMemberConfig}
            countRelationships={countRelationships}
            getMemberType={getMemberType}
            getExtendedMemberConfig={getExtendedMemberConfig}
          />
          <div style={{ textAlign: 'right', marginBottom: "40px" }}>
            {
              dependants.length > 0 &&
              <>
                Total: {currencyCode + " " + (
                  (dependants.reduce((psum, dependant) => psum + dependant.premium_amount_in_cents, 0)))
                  / 100}
              </>
            }

          </div>

          {dependants.length > 0 && <div className={classes.createquote}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleCreateQuote}

            >
              Create Quote
            </Button>
          </div>
          }

        </>

      }

    </>
  )
}

export default Createquote

function getAge(dateOfBirth) {
  const birthDate = new Date(dateOfBirth);
  const currentDate = new Date();

  const age = currentDate.getFullYear() - birthDate.getFullYear();
  // const age2 = moment().diff(dob, 'years');
  console.log({
    // age, age2
  });
  return age;
  
}
