import React from 'react';
import './style.css';
import LocalizedStrings from 'react-localization';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import * as actions from './actions.es6';
import { downloadBlobReports, formatDateTime } from '../../lib/utils.es6';
import { localisedText } from '../../lib/localisation.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import Grid from "@material-ui/core/Grid";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertDialog from '../../components/AlertDialog/index.es6';
import LinearProgress from '@material-ui/core/LinearProgress';
import { DatePicker, DateTimePicker } from "@material-ui/pickers";
import Loader from "react-loader";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { PRIMARY_THEME_COLOR_SHADE_2, SECONDARY_THEME_COLOR_SHADE_1, SECONDARY_THEME_COLOR_SHADE_2 } from '../../lib/constants';
import { Button } from '@mui/material';
import {getS3BucketSignedUrlForBulksms} from "../../lib/requests.es6";
import DropzoneS3Uploader from "react-dropzone-s3-uploader";

const btnStyle = {
  color: 'white',
  margin: '2px',
  height: '50px',
  padding: '0 30px',
  secondary: {
    backgroundColor: SECONDARY_THEME_COLOR_SHADE_1,
  },
  danger: {
    backgroundColor: SECONDARY_THEME_COLOR_SHADE_2,
  },
};

const recipientSources = {
  FromFile: "fromFile",
  AllRegistered: "allRegisteredCustomer",
  ActiveLoyalty: "activeCustomersWithLoyalty",
  ActiveUpSell: "activeCustomersWithUpSell",
  NotTakenUp: "customersWithNotTakenUp",
  Suspended: "suspendedCustomers",
  UnregisteredLoyalty: 'currentUnregisteredLoyalty',
};


export class BulkSms extends React.Component {
  constructor(props) {
    super(props);
    const localizedStrings = new LocalizedStrings(localisedText);

    this.state = {
      batchId: 0,
      file: null,
      isSubmitDisabled: false,
      errors: [],
      useDefaultText: false,
      msisdnColumn: 1,
      text: {
        index: 2,
        default: null,
      },
      skip_first_row: true,
      delimiter: ',',
      labels: localizedStrings,
      batchInfoType: null,
      submitted: false,
      tab: 'importSms',
      alert: null,
      can_reply_to: false,
      dispatchAt: null,
      recipientSource: 'FromFile',
      importing: false,
      start_date: null,
      end_date: null,
      s3Url: 'https://bulkssms.s3.amazonaws.com',
      completed: 0,
      message: null,
    };

    this.updateBatchId = this.updateBatchId.bind(this);
    this.onFileSelect = this.onFileSelect.bind(this);
    this.onRowSkipChange = this.onRowSkipChange.bind(this);
    this.onDelimiterChange = this.onDelimiterChange.bind(this);
    this.onMsisdnChange = this.onMsisdnChange.bind(this);
    this.onUseDefaultChange = this.onUseDefaultChange.bind(this);
    this.onDefaultTextChange = this.onDefaultTextChange.bind(this);
    this.updateIndex = this.updateIndex.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.download = this.download.bind(this);
    this.getSummary = this.getSummary.bind(this);
    this.downloadCSVTemplate = this.downloadCSVTemplate.bind(this);
    this.promptStopBlast = this.promptStopBlast.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.getBatchProgress = this.getBatchProgress.bind(this);
    this.getProgress = this.getProgress.bind(this);
    this.onCanReplyToChange = this.onCanReplyToChange.bind(this);
    this.onRecipientSourceChange = this.onRecipientSourceChange.bind(this);
    this.onImportingChange = this.onImportingChange.bind(this);
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.onEndDateChange = this.onEndDateChange.bind(this);
    this.sendBlast = this.sendBlast.bind(this);
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('isColumnMatch', (value) => {
      return value > 0;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule('isColumnMatch');
  }

  componentWillMount() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'BulkSms',
      this.state.labels
    );
  }

  updateBatchId(event) {
    this.setState({ batchId: event.target.value });
  }

  download() {
    if (this.state.batchId) {
      this.props.dispatch(actions.dowloadBulkSmsRequest(this.state.batchId));
    }
  }

  getSummary() {
    if (this.state.batchId) {
      this.props.dispatch(actions.summaryBulkSmsRequest(this.state.batchId));
      this.props.dispatch(actions.getBatchProgressRequest(this.state.batchId));
    }
  }

  stopBlast() {
    this.props.dispatch(actions.stopBlastRequest(this.state.batchId));
    this.setState({ alert: null })
  }

  promptStopBlast() {
    const { labels } = this.state;
    const { batchProgress } = this.props.bulkSms;
    const summary = !batchProgress || !batchProgress.processed
      ? null
      : labels.onGoingBlastSummary.replace('{alreadySent}', batchProgress.processed).replace('{notSent}', batchProgress.total - batchProgress.processed);

    this.setState({
      alert: <AlertDialog
        warning
        id="promptStopBlast"
        title={labels.stopBlast + ": " + this.state.batchId}
        reverseConfirmAndCancelButtons={true}
        onConfirm={() => this.stopBlast()}
        onCancel={() => this.setState({ alert: null })}
        showCancel
        showConfirm
        confirmBtnText={labels.stopBlast}
        cancelBtnText={labels.cancel}
      >
        <p>{labels.areYouSureYouWantThisBlast}<br />{summary}</p>
      </AlertDialog>
    });
  }

  blastStoppedSuccessDialog() {
    const { labels } = this.state;
    return <AlertDialog
      id="blastStoppedSuccessDialog"
      success
      title={labels.blastSuccessfullyStopped}
      onConfirm={() => this.props.dispatch(actions.resetStopBlast())}
      showCancel={false}
      showConfirm
      confirmBtnText={labels.ok}
    >
    </AlertDialog>
  }

  blastStopLoaderDialog() {
    const { labels } = this.state;
    return <AlertDialog
      id="blastStopLoaderDialog"
      info
      onConfirm={() => this.props.dispatch(actions.resetStopBlast())}
      showCancel={false}
      showConfirm={false}
    >
      <LinearProgress color="secondary" />

      <br />
      <p style={{ color: 'rgb(70, 184, 218)' }}>{labels.hangTightWhileBlastStopped}</p>

    </AlertDialog>
  }

  componentDidUpdate(prevProps) {
    this.updateLanguage();

    if (
      this.props.bulkSms.fileBlob &&
      prevProps.bulkSms.fileBlob !== this.props.bulkSms.fileBlob
    ) {
      downloadBlobReports(
        this.props.bulkSms.fileBlob,
        `bulk-report-${formatDateTime(new Date())}.csv`
      );
    }
  }

  onFileSelect(file) {
    this.setState({
      file: file.bucketKey,
      url: file.signedUrl,
      isSubmitDisabled: false,
    });
  }

  onMsisdnChange(event) {
    this.setState({ msisdnColumn: event.target.value });
  }

  onCanReplyToChange(event) {
    this.setState({ can_reply_to: event.target.checked });
  }

  onImportingChange() {
    this.setState({ importing: !this.state.importing });
  }

  onUseDefaultChange(event) {
    const { text } = this.state;
    const newText = {
      ...text,
      default: null,
      index: !event.target.checked ? 2 : null,
    };
    this.setState({ text: newText, useDefaultText: event.target.checked });
  }

  updateIndex(event) {
    const { text } = this.state;
    const newText = {
      ...text,
      default: null,
      index: event.target.value,
    };
    this.setState({ text: newText });
  }

  onDefaultTextChange(event) {
    const { text } = this.state;
    const newText = {
      ...text,
      default: event.target.value,
      index: null,
    };
    this.setState({ text: newText });
  }

  onDelimiterChange(event) {
    this.setState({ delimiter: event.target.value });
  }

  onRowSkipChange(event) {
    this.setState({ skip_first_row: event.target.checked });
  }

  onRecipientSourceChange(event) {
    const { value } = event.target;

    this.setState({
      recipientSource: value,
      useDefaultText: value !== 'FromFile',
    });
  }

  handleDateChange(date) {
    this.setState({ dispatchAt: date ? date._d : date });
  }

  onStartDateChange(date) {
    this.setState({ start_date: date ? date._d : date });
  }

  onEndDateChange(date) {
    this.setState({ end_date: date ? date._d : date });
  }

  handleUpload() {
    this.setState({ submitted: true });

    if (this.state.recipientSource === 'FromFile' && !this.state.file) {
      return;
    }

    this.onImportingChange();
  }

  sendBlast() {
    let dispatch_at = this.state.dispatchAt ? (this.state.dispatchAt.getFullYear() + "-" + (this.state.dispatchAt.getMonth() + 1) +
      "-" + this.state.dispatchAt.getDate() + " " + this.state.dispatchAt.getHours() + ":" +
      this.state.dispatchAt.getMinutes() + ":" + this.state.dispatchAt.getSeconds()) : null;


    const data = {
      columns: {
        msisdn: {
          index: this.state.msisdnColumn - 1,
        },
        text: {
          index: this.state.useDefaultText ? null : this.state.text.index - 1,
          default: this.state.useDefaultText ? this.state.text.default : null,
        },
      },
      skip_first_row: this.state.skip_first_row,
      delimiter: this.state.delimiter,
      can_reply_to: this.state.can_reply_to,
      dispatch_at: dispatch_at,
      recipient_source: this.state.recipientSource,
      start_date: ['FromFile', 'UnregisteredLoyalty'].indexOf(this.state.recipientSource) < 0 && this.state.start_date
        ? this.state.start_date.getFullYear() + "-" + (this.state.start_date.getMonth() + 1) + "-" + this.state.start_date.getDate()
        : null,
      end_date: ['FromFile', 'UnregisteredLoyalty'].indexOf(this.state.recipientSource) < 0 && this.state.end_date
        ? this.state.end_date.getFullYear() + "-" + (this.state.end_date.getMonth() + 1) + "-" + this.state.end_date.getDate()
        : null,
    };

    if (this.state.recipientSource === 'FromFile') {
      data.filename = this.state.file;
    }

    this.props.dispatch(actions.uploadBulkSmsRequest(data));
    this.onImportingChange();
  }

  getCharacterCounterColor() {
    if (this.state.text.default == null) {
      return "grey"
    }
    if (this.state.text.default.length > 254) {
      return "red"
    }
    return "green"
  }

  successMessage() {
    if (this.props.bulkSms.response) {
      return (
        this.state.labels.smsBatchBeingProcessed +
        this.props.bulkSms.response.batchId
      );
    }

    return null;
  }

  errorMessage() {
    if (this.props.bulkSms.errors) {
      return `${this.state.labels.defaultErrorMessage}: ${this.props.bulkSms.errors}`;
    }

    return null;
  }

  msisdnColumnHelp() {
    return this.state.labels.msisdnColumnHelp;
  }

  textValuesHelp() {
    return this.state.labels.textColumnHelp;
  }

  summaryMessage() {
    return this.state.labels.cellContentHelp;
  }

  handleTabChange(event, tab) {
    this.setState({ tab });
  }

  downloadCSVTemplate() {
    const rows = this.state.useDefaultText
      ? [["msisdn"], ["00254700000000"], ["00254715877345"],]
      : [["msisdn", "Message"], ["00254700000000", 'SMS Message'], ["00254715877345", 'SMS Message'],];

    let csvContent = "data:text/csv;charset=utf-8,"
      + rows.map(e => e.join(",")).join("\n");

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';

    const url = encodeURI(csvContent);
    a.href = url;
    a.download = 'sample.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  getBatchProgress(batchProgress) {
    return Math.floor((batchProgress.processed / batchProgress.total) * 100)
  }

  getProgress() {
    if (this.state.batchId) {
      this.props.dispatch(actions.getBatchProgressRequest(this.state.batchId));
    }
  }

  renderPreviousBatchView() {
    const { labels } = this.state;
    const { summary, batchProgress } = this.props.bulkSms;

    return <ValidatorForm style={{ marginTop: "20px" }}>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <TextValidator label={labels.batchNumber}
            fullWidth
            variant="outlined"
            type="number"
            onChange={this.updateBatchId}
            value={this.state.batchId}
            name="batchId"
            aria-describedby="emailHelp"
            placeholder={labels.batchNumber}
            validators={['required']}
            errorMessages={[labels.requiredInput]}
            InputLabelProps={{ shrink: true, }} />
        </Grid>
        <Grid item md={6} xs={12}>
          {!summary ? (
            ''
          ) : (
            <p className="text-left">
              <strong>{labels.batchNumber}:</strong>&nbsp;
              {summary.batchId}
              <br />
              <strong>{labels.totalSms}:</strong>&nbsp;
              {summary.size}
              <br />
              <strong>{labels.invalidSms}:</strong>&nbsp;
              {summary.invalidCount}
              <br />
              {summary.completedAt ? (
                <span>
                  <strong>{labels.completeAt}:</strong>&nbsp;
                  {summary.completedAt}
                </span>
              ) : (
                ''
              )}
              <br />
              {summary.remarks ? (
                <span>
                  <strong>
                    {labels.defaultErrorMessage}:
                  </strong>
                  &nbsp;
                  {summary.remarks}
                </span>
              ) : (
                ''
              )}
              <br />
              <br />
              {!batchProgress ? "" :
                <Box display="flex" alignItems="center">
                  <Box minWidth={5}>
                    <Typography variant="body2"
                      color="textSecondary">{`${this.getBatchProgress(batchProgress)}%`}</Typography>
                  </Box>
                  <Box width="80%" mr={1}>
                    <LinearProgress variant="determinate" value={this.getBatchProgress(batchProgress)} />
                  </Box>
                  <Box minWidth={35}>
                    <Typography variant="body2"
                      color="textSecondary">{`${batchProgress.processed} ${labels.sent}`}</Typography>
                  </Box>
                </Box>
              }
              <br />
              <Button disabled={batchProgress && batchProgress.processed === batchProgress.total}
                color="secondary"
                variant="contained"
                onClick={this.promptStopBlast} style={{ display: 'none' }}>{labels.stopThisBlast}</Button>
              <Button
                variant="contained"
                style={btnStyle.danger}
                type="button"
                onClick={this.getProgress}>
                {labels.getProgress}
              </Button>
            </p>
          )}
        </Grid>
        <Grid item md={7} xs={12}>
          <Button
            variant="contained"
            style={btnStyle.secondary}
            type="button"
            onClick={this.download}>
            {labels.downloadReport}
          </Button>
          <Button
            variant="contained"
            style={btnStyle.danger}
            type="button"
            onClick={this.getSummary}>
            {labels.getBatchSumary}
          </Button>
        </Grid>
      </Grid>
    </ValidatorForm>
  }

  displayCompleteMessage() {
    const style = {
      margin: '5%',
    };
    const labels = this.state.labels;
    if (this.state.completed === 0) {
      return (
        <div style={style}>
          <h4>{labels.dragAndDrop}</h4>
          <h4>{labels.Or}</h4>
          <h4>{labels.clickUpload}</h4>
        </div>
      );
    }
    if (this.state.completed > 0 && this.state.completed < 100) {
      return (
        <div style={style}>
          <h4>{labels.uploadProgress}</h4>
        </div>
      );
    }
    if (this.state.completed >= 100 && !this.state.message) {
      return (
        <div style={style}>
          <h4 style={{ color: 'green' }}>{labels.uploadSuccess}</h4>
          <h4>{labels.uploadMore}</h4>
        </div>
      );
    }
    if (this.state.completed >= 100 && this.state.message) {
      return (
        <div style={style}>
          <h4>{labels.uploadFailure}</h4>
        </div>
      );
    }
    return '';
  }

  progress(completed) {
    if (completed > 100) {
      this.setState({ completed: 100 });
    } else {
      this.setState({ completed });
    }
  }

  renderUploader() {
    const uploadOptions = {
      signingUrlMethod: 'GET',
      getSignedUrl: (file, callback)=> getS3BucketSignedUrlForBulksms(file, callback),
      s3Url: this.state.s3Url,
      uploadRequestHeaders: {},
      onProgress: this.progress.bind(this),
    };

    const style = {
      loader: {
        display: this.state.completed > 0 ? '' : 'none',
        width: '52%',
      },
      s3Uploader: {
        display: this.state.completed > 0 ? 'none' : '',
      },
      loaderColor: this.state.message ? 'red' : 'green',
    };

    return (
      <div className="form-group">
        <div>
          <center>
            <DropzoneS3Uploader
              onFinish={this.onFileSelect}
              maxSize={1024 * 1024 * 500}
              upload={uploadOptions}
              s3Url={this.state.s3Url}
              passChildrenProps={false}
            >
              <div>
                <br />
                <br />
                {this.displayCompleteMessage()}
              </div>
            </DropzoneS3Uploader>
            <br />
            <p>{this.state.file}</p>
            <br />
            <LinearProgress
              color={style.loaderColor}
              style={style.loader}
              mode="determinate"
              value={this.state.completed}
            />
          </center>
        </div>
      </div>
    );
  }

  renderUploadForm() {
    const { labels, recipientSource } = this.state;
    const { response } = this.props.bulkSms;

    return <ValidatorForm onSubmit={this.handleUpload} id="uploadBulkForm">
      <AlertDialog
        title={""}
        info
        show={this.state.importing}
        confirmBtnText={labels.importSmsBlast}
        onConfirm={this.sendBlast}
        onCancel={this.onImportingChange}
        showCloseButton={true}
        showCancel={true}
        closeOnClickOutside={true}
      >
        <h5>{labels.confirmImportSmsBlast}</h5>
      </AlertDialog>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <br />
          {response ? (
            <p className="success">{this.successMessage()}</p>
          ) : (
            ''
          )}
          <p className="warning">{this.summaryMessage()}</p>
        </Grid>
        <Grid item xs={12}>
          <div className="form-group">
            <div>
              <TextField
                fullWidth
                variant="outlined"
                label={labels.selectBlastSource}
                select
                className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl"
                onChange={this.onRecipientSourceChange}
                value={recipientSource}
                InputLabelProps={{ shrink: true, }}
              >
                {Object.keys(recipientSources).map((key) => (
                  <MenuItem key={key} value={key}>{labels[recipientSources[key]]}</MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </Grid>
        {recipientSource !== 'FromFile'
          ? <Grid container spacing={3} item xs={12}>
            {recipientSource !== 'UnregisteredLoyalty'
              ?
              <>
                <Grid item md={6} xs={12}>
                  <DatePicker
                    fullWidth
                    variant="outlined"
                    clearable
                    label={labels.startDate}
                    inputVariant="outlined"
                    value={this.state.start_date}
                    onChange={this.onStartDateChange}
                    format='YYYY-MM-DD'
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <DatePicker
                    fullWidth
                    variant="outlined"
                    clearable
                    label={labels.endDate}
                    inputVariant="outlined"
                    value={this.state.end_date}
                    onChange={this.onEndDateChange}
                    format='YYYY-MM-DD'
                  />
                </Grid>
              </>
              : ""}
          </Grid>
          : <>
            <Grid item xs={12}>
              {this.renderUploader()}
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={this.downloadCSVTemplate}
                style={{ color: SECONDARY_THEME_COLOR_SHADE_2 }}
                variant="text">
                {labels.downloadCSVTemplate}
              </Button>
            </Grid>
            <Grid item md={6} xs={12}>
              <label className="control-label">
                {labels.skipFirstRow}&emsp;
                <Checkbox
                  onChange={this.onRowSkipChange}
                  value="skip_first_row"
                  checked={this.state.skip_first_row}
                  style={{ color: SECONDARY_THEME_COLOR_SHADE_1 }}
                />
              </label>
            </Grid>
            <Grid item md={6} xs={12}>
              <label className="control-label">
                {labels.canReplyTo}&emsp;
                <Checkbox
                  style={{ color: SECONDARY_THEME_COLOR_SHADE_1 }}
                  onChange={this.onCanReplyToChange}
                  value="can_reply_to"
                  checked={this.state.can_reply_to} />
              </label>
            </Grid>
            {recipientSource !== 'FromFile'
              ? ""
              : <Grid item md={6} xs={12}>
                <label className="control-label">
                  {labels.useDefaultText}&emsp;<Checkbox
                    onChange={this.onUseDefaultChange}
                    value="useDefault"
                    style={{ color: SECONDARY_THEME_COLOR_SHADE_1 }}
                    checked={this.state.useDefaultText} />
                </label>
              </Grid>
            }
            <Grid item md={6} xs={12} />
          </>
        }
      </Grid>
      <br />
      <br />
      {recipientSource !== 'FromFile'
        ? ""
        : <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextValidator label={labels.delimiter}
              fullWidth
              variant="outlined"
              type="text"
              value={this.state.delimiter}
              onChange={this.onDelimiterChange}
              name="delimiter"
              aria-describedby="emailHelp"
              placeholder={labels.delimiter}
              validators={['required']}
              errorMessages={[labels.requiredInput]} />

          </Grid>
          <Grid item md={6} xs={12}>
            <Tooltip title={this.msisdnColumnHelp()} placement="right">
              <TextValidator label={labels.msisdn}
                fullWidth
                variant="outlined"
                type="number"
                value={this.state.msisdnColumn}
                onChange={this.onMsisdnChange}
                name="msisdnColumn"
                aria-describedby="emailHelp"
                placeholder={labels.msisdn}
                validators={['required', 'isColumnMatch']}
                errorMessages={[labels.requiredInput, labels.requiredInput]} />
            </Tooltip>
          </Grid>
        </Grid>
      }
      <Grid container spacing={3}>
        {!this.state.useDefaultText ?
          <Grid item md={6} xs={12}>
            <Tooltip title={this.textValuesHelp()} placement="right">
              <TextValidator label={labels.textColumn}
                fullWidth
                variant="outlined"
                type="number"
                value={this.state.text.index}
                onChange={this.updateIndex}
                name="textColumn"
                aria-describedby="emailHelp"
                validators={['required', 'isColumnMatch']}
                errorMessages={[labels.requiredInput, labels.requiredInput]} />
            </Tooltip>
          </Grid>
          : <Grid item md={6} xs={12}>
            <p style={{ color: this.getCharacterCounterColor() }}
            >{labels.totalCharacters}: {this.state.text.default ? this.state.text.default.length : 0}/254 {(this.getCharacterCounterColor() === "red") ? labels.maximumCharactersOfSmsExceeded : ""}</p>
            <TextValidator
              multiline={true}
              rows={5}
              placeholder={labels.textContent}
              label={labels.textContent}
              fullWidth
              variant="outlined"
              value={this.state.text.default}
              onChange={this.onDefaultTextChange}
              name="textContent"
              aria-describedby="emailHelp"
              validators={['required']}
              errorMessages={[labels.requiredInput]}
            />
          </Grid>}
        <Grid item md={6} xs={12}>
          <DateTimePicker
            fullWidth
            variant="outlined"
            clearable
            label={labels.dispatchAt}
            inputVariant="outlined"
            value={this.state.dispatchAt}
            onChange={this.handleDateChange}
            format='YYYY-MM-DD HH:mm'
          />
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Button
            variant="contained"
            style={btnStyle.secondary}
            type="submit">
            {labels.import}
          </Button>
        </Grid>
      </Grid>
    </ValidatorForm>
  }

  render() {
    const { labels } = this.state;

    return (
      <Loader loaded={!this.props.bulkSms.loader} color="#ff834f">
        <div className="x_panel">
          <div className="x_content">
            <div style={{ margin: "auto", borderColor: "black" }}>
              <center>
                {this.state.alert ? this.state.alert : ''}
                {this.props.bulkSms.blastStopped ? this.blastStoppedSuccessDialog() : ''}
                {this.props.bulkSms.loaderForStoppingBlast ? this.blastStopLoaderDialog() : ''}
                {this.props.bulkSms.loaderForDownload ?
                  <div>
                    <CircularProgress />
                    <p style={{ fontSize: '22px' }}>{labels.preparingYourReportFile}</p>
                  </div>
                  : ''}
              </center>
              {this.props.bulkSms.errors || this.state.errors.length > 0 ? (
                <div>
                  <p className="error">{this.errorMessage()}</p>
                  {this.state.errors.map((value, index) => (
                    <p className="error" key={index}>
                      {value}
                    </p>
                  ))}
                </div>
              ) : (
                ''
              )}
              <div className="form-group " style={{ margin: "1px", padding: "20px", width: "90%" }}>
                <AppBar position="static" style={{ backgroundColor: PRIMARY_THEME_COLOR_SHADE_2 }}>
                  <Tabs variant="fullWidth"
                    value={this.state.tab}
                    onChange={this.handleTabChange}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: SECONDARY_THEME_COLOR_SHADE_2
                      }
                    }}
                  >
                    <Tab value='importSms' id="importSms" label={labels.importSms} />
                    <Tab value='previousBatches' id="previousBatches" label={labels.previousBatches} />
                  </Tabs>
                </AppBar>
                {this.state.tab === 'importSms' ? this.renderUploadForm() : this.renderPreviousBatchView()}
              </div>
            </div>
          </div>
        </div>
      </Loader>);
  }
}

export default connect(state => ({
  bulkSms: state.bulkSms,
  globalData: state.global,
}))(BulkSms);
