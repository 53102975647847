import React from 'react'
import { useStyle } from "./Style";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Grid } from '@mui/material';
import ButtonInc from '../../../../shared-ui/ButtonInc';

const SidebarFilter = (props) => {
  const classes = useStyle();

  //const [value, setValue] = React.useState('female');

  const handleChange = (event) => {
    props.filterByDocType(event.target.value);
    console.log("event.target.value", event.target.value)
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} sm={12}>
          <div className={classes.filterWrapper}>
            <div className={classes.filterHeader}>
              <span>Filter search results by :</span>
            </div>
            <div className={classes.filterOption}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={props.document_type}
                  onChange={handleChange}
                >
                  <FormControlLabel value="Customer" control={<Radio />} label="Customer" />
                  <FormControlLabel value="Policy" control={<Radio />} label="Policy" />
                  <FormControlLabel value="Claim" control={<Radio />} label="Claim" />
                </RadioGroup>
              </FormControl>

            </div>
            <ButtonInc style={{
              marginLeft: "40px",
              marginTop: "10px"
            }}
            onClick={() => props.clearFilter()}
            >Clear Filter</ButtonInc>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default SidebarFilter