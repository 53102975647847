import React from 'react';
import MaterialTable from 'material-table';
import * as utils from '../../lib/utils.es6';


export default class GroupPayments extends React.Component {
  productNameDisplay(policy) {
    return `${policy.product_name} (${policy.product_cover_type})`;
  }
  render() {
    const payments = this.props.payments || [];
    return (
      <MaterialTable
        title="Group Payments"
        columns={[
          { title: 'Product Name', field: 'product_name' },
          { title: 'Reference', field: 'reference' },
          { title: 'Effected At', field: 'effected_at' },
          { title: "Status", field: 'status', render: payment => utils.formatStatus(payment.status)},
          { title: 'Amount', field: 'amount_in_cents', render: payment =>  payment.amount_in_cents / 100},
        ]}
        data={payments}
     
        options={{
					search: true,
					sorting: true,
					exportButton: true,
					actionsColumnIndex: -1,
					pageSize: 10,
					headerStyle: {
						backgroundColor: '#003643',
						color: '#FFF'
					}
				}}
      />
    );
  }
}