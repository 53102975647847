import React from "react";
import { BENEFIT_NAMES } from "../../PoductConstants";
import CashBackIndemnityTable from "./CashBackIndemnityTable";
import FixedIndemnityTable from "./FixedIndemnityTable";
import VariableIndemnityTable from "./VariableIndemnityTable";

export default function BenefitsTable(props) {

  const [count, setCount] = React.useState(0)

  React.useEffect(
    ()=>{
      setCount(count+1)
    }, [props.benefits]
  )

  let cashBackBenefits = props.benefits.filter(function (benefit) {
    return BENEFIT_NAMES.CASHBACK.includes(benefit.name);
  });

  let variableBenefits = props.benefits.filter(function (benefit) {
    return BENEFIT_NAMES.VARIABLE.includes(benefit.name);
  });

  let fixedBenefits = props.benefits.filter(function (benefit) {
    return BENEFIT_NAMES.FIXED.includes(benefit.name);
  });


  cashBackBenefits.forEach(benefit => {
    benefit['rowCollapsed'] = false
  });

  variableBenefits.forEach(benefit => {
    benefit['rowCollapsed'] = false
  });

  fixedBenefits.forEach(benefit => {
    benefit['rowCollapsed'] = false
  });

  if (props.benefits && props.benefits.length > 0) {
    return (
      <>
        <CashBackIndemnityTable
          cashBackBenefits={cashBackBenefits}
          hardDeleteBenefit={props.hardDeleteBenefit}
          displayEditButton={props.displayEditButton}
          displayDeleteButton={props.displayDeleteButton}
          setBenefitToEdit={props.setBenefitToEdit}
        />

        <FixedIndemnityTable
          fixedBenefits={fixedBenefits}
          hardDeleteBenefit={props.hardDeleteBenefit}
          displayEditButton={props.displayEditButton}
          displayDeleteButton={props.displayDeleteButton}
          setBenefitToEdit={props.setBenefitToEdit}
        />

        <VariableIndemnityTable
          variableBenefits={variableBenefits}
          hardDeleteBenefit={props.hardDeleteBenefit}
          displayEditButton={props.displayEditButton}
          displayDeleteButton={props.displayDeleteButton}
          setBenefitToEdit={props.setBenefitToEdit}
        />
      </>
    );
  }
  return "No benefits found, click on the add button to add new benefit."

}
