import { deepOrange } from '@mui/material/colors';
import {PARTNERS_GUID_ENUM} from "../routes/PartnersEnum";

export const PARTNERS_THAT_CAN_SEND_RECURRING_PAYMENT_SMS = ['orangeguid'];
export const PARTNERS_THAT_CAN_SEE_GROUP_PRODUCT_MENU = ['britamguid'];
export const DEFAULT_PAGE = 0;
export const DEFAULT_SIZE = 10;
export const PRIMARY_THEME_COLOR_SHADE_1 = '#032a37';
export const PRIMARY_THEME_COLOR_SHADE_2 = '#003643';
export const SECONDARY_THEME_COLOR_SHADE_1 = '#008A93';
export const SECONDARY_THEME_COLOR_SHADE_2 = deepOrange[400];

export const funeralPopUpPaymentPartnerList = [
  PARTNERS_GUID_ENUM.ATLEHANG,
  'MMI_Ghana',
];

export const POLICY_V2_PARTNERS = [
  PARTNERS_GUID_ENUM.KTDA,
  PARTNERS_GUID_ENUM.ATLEHANG,
  PARTNERS_GUID_ENUM.KONYANA,
  PARTNERS_GUID_ENUM.ACCESS_BANK,
  PARTNERS_GUID_ENUM.BBOX_KENYA,
  PARTNERS_GUID_ENUM.MANANKIFIN,
  PARTNERS_GUID_ENUM.FAITH_FUNERALS,
];

export const emailTypesEntity = [
  { value: 'Customer', label: 'Customer' },
  { value: 'Policy', label: 'Policy' },
  { value: 'Claim', label: 'Claim' },
  { value: 'LOU', label: 'LOU' },
];

export const emailTypes = [
  { value: 'OnDemand', label: 'OnDemand' },
  { value: 'Event', label: 'Event' },
  { value: 'Scheduled', label: 'Scheduled' },
];

export const PAYMENT_METHODS = Object.freeze({
  STRIKE_MONEY: 'strike_money',
  BANK_CARD: 'card_payment',
  RECURRING_DEBIT_ORDER: 'recurring_debit_creation',
});
