import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({

  menuItemsWrapper: {
   "&:hover":{
    "& .MuiSvgIcon-root":{
      fill: "white"
    },
    "& .MuiTypography-root ":{
      color: "white"
    },
    "& .MuiButtonBase-root .MuiTypography-root ":{
      color: "white"
    },
   }
  },
}));