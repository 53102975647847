import * as types from '../../../../lib/actionTypes.es6'

export const initialState = {
  errors: null,
  loader: false,

  get_policies_dcp_errors: null,
  get_policies_dcp_loader: false,
  get_policies_dcp_success_message: '',
  policies_dcp: [],



};

export default function policiesDashboardReducer(state = initialState, action) {
  switch (action.type) {

    case types.GET_POLICIES_DCP_REQUEST:
      return { ...state, get_policies_dcp_loader: true, get_policies_dcp_errors: null,  policies_dcp: [] };
    case types.GET_POLICIES_DCP_SUCCESS:
      return { ...state, get_policies_dcp_loader: false, get_policies_dcp_errors: null, policies_dcp: action.payload };
    case types.GET_POLICIES_DCP_FAILURE:
      return { ...state, get_policies_dcp_loader: false, get_policies_dcp_errors: action.payload };


    default:
      return state;
  }
}
