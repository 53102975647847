
import * as types from '../../../../../../lib/actionTypes.es6'

export const getAccessBankSmsActivityRequest = (msisdn) =>{
  return{
    type: types.GET_ACCESSBANK_SMS_ACTIVITY_REQUEST,
    payload: msisdn,
  }
}

export const getAccessBankSmsActivitySuccess = (payload) =>{
  return{
    type: types.GET_ACCESSBANK_SMS_ACTIVITY_SUCCESS,
    payload: payload,
  }
}

export const getAccessBankSmsActivityFailure = (error) =>{
  return{
    type: types.GET_ACCESSBANK_SMS_ACTIVITY_FAILURE,
    payload: error,
  }
}