import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../../lib/actionTypes.es6'
import * as actions from './actions';

import { getCustomerQuotes,getCustomerPolicies,getComplexCustomerPremiums,cancelV2Policy,policyNotes ,getSmsActivities, initiatePolicyMobileMoneyPayment} from '../../../../lib/requests.es6';
import { GAloggerException } from '../../../../lib/monitoring.es6';
import { unAuthorizedNotification } from '../../../../lib/access.es6';



export function* getCustomerPoliciesRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_ALL_CUSTOMER_POLICIES_REQUEST);
    try {
      const response = yield call(getCustomerPolicies, request.payload);
      yield put(actions.getAllPoliciesSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getAllPoliciesFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* getCustomerQuotesRequestWatcher() {
  for (;;) {
    const request = yield take(types.VIEW_CUSTOMER_QUOTES_REQUEST);
    try {
      const response = yield call(getCustomerQuotes, request.payload);
      yield put(actions.getCustomerQuotesSuccess(response.data));
        yield put(
        actions.getCustomerQuotesRequest({
          args: request.payload,
         
        })
       
      );
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getCustomerQuotesFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* getCustomerPremiumsComplexRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_ALL_CUSTOMER_PREMIUMS_REQUEST);
    try {
      const response = yield call(getComplexCustomerPremiums, request.payload);
      yield put(actions.getAllPremiumsSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getAllPremiumsFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* cancelPolicyRequestWatcher() {
  for (;;) {
    const request = yield take(types.CANCEL_V2_POLICY_REQUEST);
    try {
      const response = yield call(cancelV2Policy, request.payload.policy.guid);
      yield put(actions.policyNotesRequest(request.payload.note));
      yield put(actions.cancelV2PolicySuccess(response.data));
      yield put(actions.getAllPoliciesRequest({guid: request.payload.policy.customer_guid}));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.cancelV2PolicyFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* PolicyNotesRequestWatcher() {
  for (;;) {
    const request = yield take(types.POLICY_NOTES_REQUEST);
    try {
      const response = yield call(policyNotes, request.payload);
      yield put(actions.policyNotesSuccess(response.data));
    } catch (e) {

      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.policyNotesFailure(errorMessage));
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* PolicyNotesAddRequestWatcher() {
  for (;;) {
    const request = yield take(types.POLICY_NOTES_ADD_REQUEST);
    try {
      const response = yield call(policyNotes, request.payload);
      yield put(actions.policyNotesAddSuccess(response.data));
    } catch (e) {

      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.policyNotesAddFailure(errorMessage));
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* getSmsActivitiesRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_SMS_ACTIVITY_REQUEST);
    try {
      const response = yield call(getSmsActivities, request.payload);
      console.log('saga: ', request.payload);
      yield put(actions.getSmsActivitySuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getSmsActivityFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* initiatePaymentWatcher() {
  for (;;) {
    try {
      const { payload } = yield take(
        types.INITIATE_PAYMENT_REQUEST
      );
      const response = yield call(initiatePolicyMobileMoneyPayment, payload);
      let responseData = response.data
      if (responseData === "") responseData = "success";
      yield put(actions.initiatePaymentSuccess(responseData));
    } catch (e) {
      let errorPayload = { message: 'Ooops something went wrong' };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.initiatePaymentFailure(errorPayload));
    }
  }
}