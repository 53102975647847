
import React from 'react';
import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ClickAwayListener,
    Grid,
    List,
    ListItemButton,
    ListItemText,
    Paper,
    Popper,
    Typography,
    useMediaQuery
} from '@mui/material';

// project imports
import Transitions from '../../ui-component/extended/Transitions';

// assets
import TranslateTwoToneIcon from '@mui/icons-material/TranslateTwoTone';
import useConfig from '../../../../hooks/useConfig';
import { availableLanguages } from '../../../../lib/languageUtils.es6';
import { reloadPage } from '../../../../lib/utils.es6';

// ==============================|| LOCALIZATION ||============================== //

const LocalizationSection = (props) => {
    const { borderRadius, locale, onChangeLocale } = useConfig();

    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [language, setLanguage] = useState(locale);

    const handleListItemClick = (event, lng) => {
        props.onSelectLanguage(lng)
        setLanguage(lng);
        onChangeLocale(lng);
        setOpen(false);
        reloadPage()
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        setLanguage(locale);
    }, [locale]);

    const languages = availableLanguages()

    return (
        <>
            <Box
                sx={{
                    marginLeft: "50px",
                    [theme.breakpoints.down('md')]: {
                        ml: 1
                    }
                }}
            >
                <Avatar
                    variant="rounded"
                    sx={{
                        borderRadius: "8px",
                        marginTop: "10px",
                        width: "34px",
                        height: "34px",
                        fontSize: " 1.2rem",
                        background: "#fba92d",
                        color: "#fff",
                        transition: 'all .2s ease-in-out',
                        cursor: "pointer",
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            borderColor: "#032a37",
                            background: "#032a37",
                            color: "#fff"
                        }
                    }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="inherit"
                >
                    {language !== 'en' && (
                        <Typography sx={{
                            textTransform: 'uppercase', fontFamily: "Lato",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.001em",
                        }} color="inherit">
                            {language}
                        </Typography>
                    )}
                    {language === 'en' && <TranslateTwoToneIcon sx={{ fontSize: '1rem' }} />}
                </Avatar>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom-start' : 'bottom'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [matchesXs ? 0 : 0, 20]
                        }
                    }
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top-left' : 'top'} in={open} {...TransitionProps}>
                            <Paper elevation={16}>
                                {open && (
                                    <List
                                        component="nav"
                                        sx={{
                                            width: '100%',
                                            minWidth: 200,
                                            maxWidth: 280,
                                            bgcolor: theme.palette.background.paper,
                                            borderRadius: `${borderRadius}px`,
                                            [theme.breakpoints.down('md')]: {
                                                maxWidth: 250
                                            }
                                        }}
                                    >
                                        {

                                            languages.map(language => {
                                                return <ListItemButton selected={props.selectedLanguage === language.value} onClick={(event) => handleListItemClick(event, language.value)}
                                                    sx={{
                                                        '&.Mui-selected': {
                                                            backgroundColor: '#032a37',
                                                            color: '#fff',
                                                            fontFamily: "Lato",
                                                            fontStyle: "normal",
                                                            fontWeight: "500",
                                                            fontSize: "14px",
                                                            lineHeight: "20px",
                                                            letterSpacing: "0.001em",
                                                        },
                                                        '&:hover': {
                                                            backgroundColor: '#032a37 !important',
                                                            color: '#fff',
                                                        },
                                                    }}

                                                >
                                                    <ListItemText

                                                        primary={
                                                            <Grid container>
                                                                <Typography sx={{
                                                                    fontFamily: "Lato",
                                                                    fontStyle: "normal",
                                                                    fontWeight: "500",
                                                                    fontSize: "14px",
                                                                    lineHeight: "20px",
                                                                    letterSpacing: "0.001em",
                                                                }} >{language.display}</Typography>
                                                                <Typography variant="caption" sx={{
                                                                    ml: '8px',
                                                                    fontFamily: "Lato",
                                                                    fontStyle: "normal",
                                                                    fontWeight: "500",
                                                                    fontSize: "14px",
                                                                    lineHeight: "20px",
                                                                    letterSpacing: "0.001em",
                                                                }}>
                                                                    ({language.country})
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                    />
                                                </ListItemButton>
                                            })

                                        }

                                    </List>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default LocalizationSection;
