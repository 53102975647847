
export const TestimonialsData = [
  {
    title: " Chanda Chewe - ESVIC SOLUTIONS LIMITED", 
    body: "Aspin API's are written in a simple, easier and friendly manner to understand even to a newbie programmer. They are very easy to implement in any software development package you are working with as they strive to cover all programming languages leaving no software system behind."
  },
  {
    title: "Richard Obiye - Safaricom PLC", 
    body: "I liked how the ASPN API documentation is well structured. I could be able to figure out everything on my own without engaging the owners. That gave me and my team a good experience enabling quick integrations and delivery."
  },
  {
    title: "Elias Baya Karisa - MPOST", 
    body: "It's hard to point to a single, cool feature of the ASPIN Engine API documentation. In truth, there aren’t any shiny bells and whistles — and that's probably what makes the ASPIN Engine API reference so good. It has all the information you need to get started, presented cleanly and sensibly."
  },
  {
    title: "Winter Msukwa - Instafinance Limited", 
    body: "Interfacing with the API was straight forward, and the services are well documented which made it much easier to go through the integration with minimal support required."
  },
]
