import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({

  registermodaltitle: {
    background: "#FBA92D",
    textAlign: "center",
    padding: "10px !important",
    "& h3": {
        color: "#fff",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "24px",
        lineHeight: "24px",
    }
},
registerinputwrapper: {
    marginTop: "30px",
    "& p": {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "15px",
        color: "#000000",
        textAlign: "center",
        marginBottom: "30px",
    },
    "& input": {
        background: "#F7F7F7",
        borderRadius: "8px",
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.005em",
        color: "#000000",
    },
    "& label": {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: "12px",
        letterSpacing: "0.005em",
        color: "#000000",

    }
},
registerinputs:{
    "& .MuiOutlinedInput-root":{
            borderColor: "#000fff"
    },
    "&.Mui-focused fieldset": {
        borderColor: "#C52328",
        borderWidth: "2px"
    }
},
registerbtns: {
    marginBottom: "20px",

    "& button": {
        background: "#FBA92D",
        borderRadius: "8px",
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "15px",
        boxShadow: "none",
        padding: "10px",
        marginRight: "20px",
        textTransform: "capitalize",
        "&:hover": {
            background: "#173E4B",
            boxShadow: "none",
        }
    }
},
  policyWrapper: {
    background: "#FFFFFF",
    border: "1px solid #D9D9D9",
    boxShadow: "0px 8px 24px rgba(149, 157, 165, 0.2)",
    borderRadius: "4px",
    marginTop: "30px !important"
  },
  policyHeaderBorder: {
    borderBottom: "1px solid #D9D9D9",
    width: "100%"
  },
  policyIconWrapper: {
    padding: "10px",
  },
  policyIcons: {
    width: "30px",
    height: "30px",
    background: "#032a37",
    borderRadius: "100%",
    textAlign: "center",
    paddingTop: "5px",
    marginLeft: "auto"
  },
  policyHeaderContent: {
    paddingTop: "10px",
    "& h6": {
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "0.001em",
      color: "#000000",
      margin: "0 !important"
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#A9A9A9"
    }
  },
  activePill: {
    width: "63px",
    height: "21px",
    background: "#CCFFDD",
    borderRadius: "10px",
  },
  inactivePill:{
    width: "63px",
    height: "21px",
    background: "#DAE3E7",
    borderRadius: "10px",
    "& span":{
      color: "#8D8D8C !important"
    }
  },
  policyActiveStatusWrapper: {
    padding: "14px 30px",
    "& ul": {
      listStyle: "none",
      display: "contents",
      marginLeft: "20px",
      marginTop: "10px",
      "& li": {
        textAlign: "center",
        "& span": {
          display: "block",
          marginTop: 0,
          fontFamily: 'Open Sans',
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "10px",
          lineHeight: "20px",
          letterSpacing: "0.001em",
          color: "#2D8D4D",
        }
      }
    }
  },
  actionbtnpolicy:{
    marginTop: "-10px"
  },
  policyContentBorder:{
    borderBottom: "1px solid #D9D9D9",
    width: "100%",
    marginTop: "20px"
  },
  policyContentWrapper:{
    paddingTop: "20px",
    "& h6": {
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "0.001em",
      color: "#000000",
      margin: "0 !important"
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#A9A9A9"
    }
  },
  policyFooterWrapper:{
    paddingTop: "20px",
    paddingBottom: "20px",
    "& h6": {
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "0.001em",
      color: "#000000",
      margin: "0 !important"
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#A9A9A9"
    }
  },

  policyBalance:{
    textAlign: "end",
    marginRight: "40px",
    "& h6":{
      color: "#2D8D4D",
    }
  }
}));