import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import './PartnersTable.css'
import { SECONDARY_THEME_COLOR_SHADE_1 } from "../../../../lib/constants";

export default function PartnersTable(props) {

  if(props.partners && props.partners.length > 0){
  return (
    <>
      <div style={{ marginTop: "2%" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{color: 'red'}}>
              <TableRow >
                <TableCell className="table-cell-header">Name</TableCell>
                <TableCell className="table-cell-header">Country</TableCell>
                <TableCell className="table-cell-header">Insurer</TableCell>
                <TableCell className="table-cell-header" align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.partners.map((partner) => {
                return (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {partner.name}
                    </TableCell>
                    <TableCell align="left"> {partner.country.name}</TableCell>
                    <TableCell align="left"> {partner.insurer}</TableCell>
                    <TableCell align="right">
                      <Button onClick={()=>props.viewPartner(partner)}variant="contained" style={{ background: SECONDARY_THEME_COLOR_SHADE_1, color: 'white', fontWeight: 'bolder', marginRight: "2%" }}>
                        View Parnter
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
  }else if(props.partners && props.partners.length === 0){
  return "No parnters were found, click on the add button to add new partner."
  }
}
 