export const hasSameProduct = (policy1, policy2) => {
  if(policy1.product_name === policy2.product_name){
    console.log('same product name', policy1.product_name, policy2.product_name);
    
    return true
  }
  const policy1Product = getProductName(policy1)
  const policy2Product = getProductName(policy2)
  console.log('product names', policy1Product, policy2Product);
  
  return policy1Product === policy2Product
}

export const getProductName = (policy) => {
  if(policy.product_name.includes('Motshelo')){
    return 'Motshelo'
  }
  if(policy.product_name.includes('Clan')){
    return 'Clan'
  }

  if(policy.product_name.includes('Monamodi')){
    return 'Monamodi'
  }
  return 'Monamodi'
}