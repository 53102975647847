import * as types from '../../../lib/actionTypes.es6'

export function getClaimsDcpRequest(status){
  return{
    type: types.GET_CLAIMS_DCP_REQUEST,
    payload: status
  }
}


export const getClaimsDcpSuccess = (payload) =>{
  return{
    type: types.GET_CLAIMS_DCP_SUCCESS,
    payload: payload,
  }
}

export const getClaimsDcpFailure = (error) =>{
  return{
    type: types.GET_CLAIMS_DCP_FAILURE,
    payload: error,
  }
}