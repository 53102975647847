import React from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import {  DatePicker } from '@material-ui/pickers';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as actions from './actions.es6';
import { localisedText } from '../../lib/localisation.es6';
import { formatDate, downloadBlobReports } from '../../lib/utils.es6';
import * as globalActions from '../Main/actions.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { availableFormat } from '../../lib/reportUtils.es6';

export class ReportPremiumRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: formatDate(new Date()),
      endDate: formatDate(new Date()),
      labels: new LocalizedStrings(localisedText),
    };
  }

  componentWillMount() {
    this.props.dispatch(globalActions.hideCustomerBar());
    this.updateLanguage();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'ReportPremiumRegister',
      this.state.labels
    );
  }

  getReport() {
    const type = document.getElementById('type').value;
    const payload = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      type,
    };
    this.props.dispatch(actions.reportPremiumRegisterRequest(payload));
  }

  selectPartner(partner) {
    this.props.dispatch(globalActions.setCurrentPartner(partner));
  }

  dateHandler(type, moment) {
    if (type === 'startDate') {
      this.setState({ startDate: formatDate(new Date(moment)) });
    } else if (type === 'endDate') {
      this.setState({ endDate: formatDate(new Date(moment)) });
    }
    this.resetReport();
  }

  resetReport() {
    this.props.dispatch(actions.reportPremiumRegisterReset());
  }

  render() {
    const labels = this.state.labels;
    const fileName = `premium-register-report-${this.state.startDate}-to-${
      this.state.endDate
    }.${
      document.getElementById('type')
        ? document.getElementById('type').value
        : ''
    }`;
    return (
      <div>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_title">
                <h2>{labels.premiums_register_report}</h2>
                <div className="clearfix" />
              </div>
              <div className="x_content">
                <br />
                <div className="row form-group">
                  <label
                    className="control-label col-md-4 col-sm-4 col-xs-12"
                    htmlFor="startDate"
                  >
                    {labels.startDate}
                    <span>*</span>
                  </label>
                  <div className="col-md-2 col-sm-2 col-xs-12">
                    <DatePicker
                      value={this.state.startDate}
                      onChange={this.dateHandler.bind(this, 'startDate')}
                      format="YYYY-MM-DD"
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <label
                    className="control-label col-md-4 col-sm-4 col-xs-12"
                    htmlFor="endDate"
                  >
                    {labels.endDate}
                    <span>*</span>
                  </label>
                  <div className="col-md-2 col-sm-2 col-xs-12">
                    <DatePicker
                      value={this.state.endDate}
                      onChange={this.dateHandler.bind(this, 'endDate')}
                      format="YYYY-MM-DD"
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <label
                    className="control-label col-md-4 col-sm-4 col-xs-12"
                    htmlFor="type"
                  >
                    {labels.reportType}
                    <span>*</span>
                  </label>
                  <div className="col-md-2 col-sm-2 col-xs-12">
                    <select
                      id="type"
                      className="form-control"
                      onChange={this.resetReport.bind(this)}
                    >
                      {availableFormat().map((format, index) => (
                        <option key={index} value={format}>
                          {format.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-sm-offset-4 col-sm-5">
                  <button
                    className="btn btn-primary"
                    onClick={this.getReport.bind(this)}
                  >
                    {labels.generateReport}
                  </button>
                </div>
                <br />
                {this.props.reportsData.loader ? (
                  <div className="col-sm-offset-4 col-sm-1">
                    <center>
                      <CircularProgress />
                    </center>
                  </div>
                ) : (
                  <div />
                )}
                {this.props.reportsData.reportBlob ? (
                  <div className="report-url">
                    <br />
                    <br />
                    <div className="col-sm-offset-4 col-sm-5">
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          downloadBlobReports(
                            this.props.reportsData.reportBlob,
                            fileName
                          )
                        }
                      >
                        {labels.downloadReport}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  reportsData: state.reportPremiumRegister,
  globalData: state.global,
}))(ReportPremiumRegister);
