import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  searchappbar: {
    backgroundColor: '#173E4B !important',
    height: "74px"
  },
  appbarresultsnumber: {
    paddingTop: "15px",
    "& h2": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: " 0.001em",
      color: "#FFFFFF"
    }
  },
  seearchcloseicons:{
    textAlign: "right",
    paddingTop: "25px",
    "& svg":{
      cursor: "pointer"
    }
  },
  searchresultstable: {
    width: "100%",
    "& table": {
        background: "#FFFFFF",
        border: "1px solid #A7A7A7",
        margin: "30px 0",
        width: "100%",
        "& tr": {
            borderTop: "1px solid #A7A7A7",
        },
        "& th": {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
            padding: "10px",
            borderRight: "1px solid #A7A7A7",
        },
        "& td": {
            fontFamily: "open sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
            padding: "10px",
            borderRight: "1px solid #A7A7A7",
            "& button":{
              background: "#fba92c",
              color: "#fff !important",
              borderRadius: "8px",
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "15px",
              boxShadow: "none",
              padding: "10px",
              textTransform: "capitalize",
              "&:hover": {
                  background: "#fba92c",
                  boxShadow: "none",
              }
            }
        }
    }
},
isagent:{
  accentColor: "#5F9248"
},
paginationsearch:{
  textAlign: "right",
  "& select":{
    background: "#FFFFFF",
    border: "1px solid #B8B8B8",
    borderRadius: "2px",
    height: "25px",
    width: "65px",
  }
}

}));