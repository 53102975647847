import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  policyPurchaseInfo: null,
  loader: false,
  products: [],
  policy_purchase_succesful: false,
  policy_purchase_failed: false,
};

export default function policyPurchaseReducer(state = initialState, action) {
  switch (action.type) {
    case types.PURCHASE_POLICY_REQUEST:
      return { ...state, loader: true, errors: null, policyPurchaseInfo: null, policy_purchase_succesful: false,
        policy_purchase_failed: false };

    case types.PURCHASE_POLICY_SUCCESS:
      return { ...state, policyPurchaseInfo: action.payload, loader: false, policy_purchase_succesful: true,
        policy_purchase_failed: false, };

    case types.PURCHASE_POLICY_FAILURE:
      return { ...state, loader: false, errors: action.payload, policy_purchase_succesful: false,
        policy_purchase_failed: true, };

    case types.PAID_PRODUCT_LIST_SUCCESS:
      return { ...state, products: parseTags(action.payload) };

    case types.PURCHASE_POLICY_RESET:
      return {
        ...state, policy_purchase_succesful: false,
        policy_purchase_failed: false,
      };

    case types.MAKE_CURRENT_CUSTOMER:
    case types.USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
}

function parseTags(products){
  let parsedTagProducts = products.map(p => {
    if(p.tag && p.tag.includes("mainProduct")){
      return {...p, tag: JSON.parse(p.tag)}
    }
    return p
  })
  return parsedTagProducts
}
