import * as types from '../../lib/actionTypes.es6';

export function reportRegistrationRequest(reportType) {
  return {
    type: types.REPORT_REGISTRATION_REQUEST,
    payload: reportType,
  };
}

export function reportRegistrationRequestSuccess(reportURL) {
  return {
    type: types.REPORT_REGISTRATION_SUCCESS,
    payload: reportURL,
  };
}

export function reportRegistrationRequestError(error) {
  return {
    type: types.REPORT_REGISTRATION_FAILURE,
    payload: error,
  };
}

export function reportRegistrationReset() {
  return {
    type: types.REPORT_REGISTRATION_RESET,
  };
}
