import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { CircularProgress, InputAdornment } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AlertDialog from "../../../../components/AlertDialog/index.es6";
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../../lib/constants";
import { formatDate } from "../../../../lib/utils.es6";
import { DatePicker } from '@material-ui/pickers';
import { formatNumberWithCommaIncents } from "../../../../lib/access.es6";
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';

let initialState = {
  guid: '',
  granularity: '',
  amount_in_cents: '',
  at: '',
  bank_account_number: '',
  bank_name: '',
  branch_code: '',
  start_date: '',
  policy_guid: '',
  msisdn: '',
  reference: '',
}

export default function EditDebitOrder(props) {

  if (!props.debitOrder) {
    return ""
  }

  let debitOrder = cloneDeep(props.debitOrder)
  debitOrder.amount_in_cents = debitOrder.amount_in_cents / 100
  initialState = {...debitOrder}

  const [
    {
      guid,
      granularity,
      amount_in_cents,
      at,
      bank_account_number,
      bank_name,
      branch_code,
      start_date,
      policy_guid,
      msisdn,
      reference
    }, setState
  ] = React.useState(initialState)

  React.useEffect(()=>{
    setUniversalBranchCode()
  },[bank_name])


  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };


  const reset = () => {
    setState(initialState);
  }

  const datePickerHandler = (type, moment) => {
    const rawDate = formatDate(new Date(moment));
    if (type === 'start_date') {
      setState(prevState => ({ ...prevState, start_date: rawDate }));
    }
  }

  const setUniversalBranchCode = () => {
    const selectedBank = find(props.banks, {name: bank_name})

    if(selectedBank){
      setState(prevState => ({ ...prevState, branch_code: selectedBank.universal_branch_code }));
    }
  }


  const submit = () => {
    const payload = {
      guid,
      granularity,
      amount_in_cents: formatNumberWithCommaIncents(amount_in_cents),
      day: Number(at),
      bank_account_number,
      bank_name,
      branch_code,
      start_date, 
      policy_guid,
      msisdn,
      mno_reference: reference
    }
    props.editDebitOrder(payload)
  }

  const handleDebitOrderEditedSuccessfully = () => {
    props.resetEditDebitOrderSuccessAlert()
    props.resetDebitOrderToEdit()
    reset()
  }

  const handleDebitOrderCreateError = () => {
    props.resetEditDebitOrderErrorAlert()
  }

  return (
    <React.Fragment>
      <AlertDialog
        custom
        show={props.showEditDebitOrderProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Updating debitOrder</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showEditDebitOrderSuccessAlert}
        size="sm"
        title={'DebitOrder updated'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleDebitOrderEditedSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => { handleDebitOrderEditedSuccessfully() }} style={{ background: 'green', }}>Ok</Button>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showEditDebitOrderErrorAlert}
        danger
        title={'Error updating debitOrder'}
        onConfirm={() => handleDebitOrderCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.editDebitOrderError ? props.editDebitOrderError.message : ''}
      </AlertDialog>





      <Dialog
        open={props.debitOrder} fullWidth
        maxWidth='lg'
        onClose={props.resetDebitOrderToEdit}
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.resetDebitOrderToEdit}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit DebitOrder
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div style={{ marginTop: "2%" }}>
            <Box sx={{ width: "100%" }}>
              <ValidatorForm onSubmit={() => submit()} >
                <Grid container spacing={2}>
                  <Grid item lg={3} md={6} xs={12}>

                    <TextValidator
                      onChange={onChange}
                      value={amount_in_cents}
                      label='amount'
                      name='amount_in_cents'
                      fullWidth
                      variant="outlined"
                      type="number"
                      placeholder='amount'
                      validators={['required']}
                      errorMessages={['Please enter amount']}
                      InputProps={{
                        startAdornment: (<InputAdornment position="start">
                          {props.currencyCode}
                        </InputAdornment>),

                        'aria-label': 'R',
                      }}
                    />
                  </Grid>




                  <Grid item lg={3} md={6} xs={12}>
                    <SelectValidator
                      value={granularity}
                      label='granularity'
                      id="granularity"
                      name='granularity'
                      onChange={onChange}
                      className="form-control"
                      type="text"
                      variant="outlined"
                      validators={['required']}
                      errorMessages={['Please select granularity']}
                    >
                      <MenuItem value="">{'Please select granularity'}</MenuItem>
                      {
                        ['Day', 'Week', 'Month', 'Year'].map((type) => {
                          return <MenuItem value={type}>{type}</MenuItem>
                        })
                      }
                    </SelectValidator>
                  </Grid>


                  <Grid item lg={3} md={6} xs={12}>

                    <TextValidator
                      onChange={onChange}
                      value={bank_account_number}
                      label='bank_account_number'
                      name='bank_account_number'
                      fullWidth
                      variant="outlined"
                      type="number"
                      placeholder='bank account number'
                      validators={['required']}
                      errorMessages={['Please enter bank_account_number']}
                    />
                  </Grid>


                  <Grid item lg={3} md={6} xs={12}>
                    <SelectValidator
                      value={bank_name}
                      label='bank'
                      id="bank"
                      name='bank_name'
                      onChange={onChange}
                      className="form-control"
                      type="text"
                      variant="outlined"
                      validators={['required']}
                      errorMessages={['Please select bank']}
                    >
                      <MenuItem value="" disabled>{'Please select bank'}</MenuItem>
                      {(props.banks || []).map(b=>(<MenuItem value={b.name}>{b.name}</MenuItem>))}
                    </SelectValidator>
                  </Grid>


                  <Grid item lg={3} md={6} xs={12}>

                    <TextValidator
                      onChange={onChange}
                      value={branch_code}
                      label='branch_code'
                      name='branch_code'
                      fullWidth
                      variant="outlined"
                      type="number"
                      placeholder='branch code'
                      validators={['required']}
                      errorMessages={['Please enter branch_code']}
                    />
                  </Grid>


                  <Grid item lg={3} md={6} xs={12}>

                    <TextValidator
                      onChange={onChange}
                      value={at}
                      label='day'
                      name='at'
                      fullWidth
                      variant="outlined"
                      type="number"
                      placeholder='at'
                      validators={['required']}
                      errorMessages={['Please enter at']}
                    />
                  </Grid>


                  <Grid item xs={12} md={3} sm={6} >
                    <DatePicker
                      label="Debit order start Date"
                      value={start_date || null}
                      onChange={datePickerHandler.bind(this, 'start_date')}
                      format="YYYY-MM-DD"
                      placeholder='Debit order start date'
                      variant='outlined'
                      name="start_date"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant="contained" type="submit" style={{ marginRight: '2%' }}> Update </Button>
                    <Button variant="contained" color="secondary" style={{ marginRight: '2%' }} onClick={() => props.resetDebitOrderToEdit()}> Cancel</Button>
                  </Grid>

                </Grid>
              </ValidatorForm>
            </Box>
          </div>

        </DialogContent>
      </Dialog>
    </React.Fragment>

  );



}
