import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";

const PolicyHeader = () => {
  const classes = useStyle();
  return (
    <section className={classes.claimsheader}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10} md={10}>
          <div className={classes.claimstitle}>
            <ul>
              <li>Policy Processing  </li>
            </ul>
          </div>
        </Grid>

      </Grid>




    </section>
  )
}

export default PolicyHeader