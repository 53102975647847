import ReactGA from 'react-ga';
import { buildFailureMessage } from './utils.es6';

export const monitoringEventCategories = {
  userActions: {
    name: 'userAction',
    actions: {
      registerCustomer: 'Register a customer',
      purchasePolicy: 'Purchase policy for customer',
      fetchclaimCallBacks: 'Fetch claim call backs',
      fetchClaimsClosed: 'Fetch claims closed',
      getClaimSummary: 'Get claim summary',
      makeSearchClaims: 'Search Claims',
      fetchOpenedClaims: 'Fetch Opened Claims',
      fetchPayableClaims: 'Fetch payable claims',
      processClaim: 'Process a claim',
      claimSubmission: 'Claim submission',
      updateCustomer: 'Update customer details',
      deletePremium: 'Delete premium',
      addPayment: 'addPayment',
      addRemittance: 'addRemittance',
    },
  },
};

export function GAloggerExceptionBackup(e, alternate = null) {
  const errorMessage = buildFailureMessage(
    JSON.stringify(e.response.data),
    e.response.data
  );
  const errorStatus = e.response.status || 0;
  ReactGA.exception({
    description: alternate || errorMessage,
    fatal: Math.floor(errorStatus / 100) === 5,
  });
}

export function GAloggerException(e, alternate = null) {
  if (!e.response) {
    return;
  }
  const errorMessage = buildFailureMessage(
    JSON.stringify(e.response.data),
    e.response.data
  );

  const errorStatus = e.response.status || 0;
  ReactGA.event({
    category: 'Exceptions',
    action: alternate || errorMessage,
    label: Math.floor(errorStatus / 100) === 5 ? 'fatalError' : 'minorError',
  });
}

export default function GAlogger(category, action) {
  ReactGA.event({
    category,
    action,
  });
}


export function getAnanlyticsId(){
  const INCLUSIVITY_ASPIN_DOMAIN_GOOGLE_ANALYTICS_ID = 'UA-104191579-1'
  const INCLUSIVITY_ASPIN_DOMAIN = 'inclusivity-aspin.com'

  const GROUP_LAST_EXPENCE_DOMAIN_GOOGLE_ANALYTICS_ID = 'G-14DG5T8FRB';
  const GROUP_LAST_EXPENCE_DOMAIN = 'grouplastexpense.com';
  
  const currentUrl = window.location.href;
  
  if(currentUrl.includes(GROUP_LAST_EXPENCE_DOMAIN)){
    return GROUP_LAST_EXPENCE_DOMAIN_GOOGLE_ANALYTICS_ID
  }

  if(currentUrl.includes(INCLUSIVITY_ASPIN_DOMAIN)){
    return INCLUSIVITY_ASPIN_DOMAIN_GOOGLE_ANALYTICS_ID
  }

  return INCLUSIVITY_ASPIN_DOMAIN_GOOGLE_ANALYTICS_ID;

}