import React from "react";
import { connect } from "react-redux";

class PreAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openViewLOUDialog: false,
      openEditLOUDialog: false,
    };
  }

  render() {
    return (
      <>
      {this.props.children}
      </>
    )
  }

}
export default connect((state) => ({
  customerData: state.currentCustomer,
  claimDashboardData: state.claimDashboardData,
  globalData: state.global,
}))(PreAuth);