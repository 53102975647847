import React, { useState } from 'react'
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { useStyle } from "./Style";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-container': {
        backgroundColor: 'rgba(3, 42, 55, 0.94)'
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Banner = (props) => {
    const classes = useStyle();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <section className={classes.bannerImg}>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <div className={classes.bannerwrapper}>
                            <div className={classes.bannertext}>
                                <h1> Embedded Insurance Solutions<br /> for an Emerging Africa​</h1>
                                <p>Unleash the power of embedded insurance with ASPin - the ultimate plug-and-play platform for your digital insurance products launch!</p>
                            </div>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <div className={classes.watchvideo} >
                                        <Button  variant="outlined" size="large" onClick={handleClickOpen}>Watch Video</Button>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <div className={classes.scheduledemo}>
                                    <Button variant="contained" href='https://inclusivitysolutions.com/contact/' target='_blank' rel="noopener noreferrer">Schedule Demo</Button>
                                    </div>
                                </Grid>
                            </Grid>

                        </div>
                    </Grid>
                </Grid>
            </Container>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                </BootstrapDialogTitle>
                <DialogContent >
                    <div className={classes.videowrapper}>
                        <video width="100%" height="100%" controls >
                            <source src='https://puplic-assets.s3.eu-west-1.amazonaws.com/Inclusivity_Solutions_Video.mp4' type="video/mp4" />
                        </video>
                    </div>
                </DialogContent>

            </BootstrapDialog>
        </section>
    )
}

export default Banner