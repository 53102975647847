import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../lib/constants";
import CloseIcon from '@mui/icons-material/Close';
import './OpenClaimCallBacksTable.css'
import ContactsIcon from '@mui/icons-material/Contacts';
import { formatDateLocale } from '../../../lib/utils.es6';
import TableInc from '../../../shared-ui/TableInc';

export default function OpenClaimCallBacksTable(props) {
	const callbacks = props.callbacks || { data: [], page: 1, page_size: 10, total: 0};

	React.useEffect(() => {	
		props.onPageChange(1, 10, 'open');
	}, []);

	if (props.loading) return <p>loading</p>;

	return (
		<>
			<TableInc
				title="Open claim call back List"
				columns={[
					{ title: 'No', field: 'count', render: rowData => rowData.tableData.id + 1 },
					{ title: 'Created At', field: `created_at`, render: rowData =>formatDateLocale(rowData.created_at) },
					{ title: 'Modified At', field: `modifiedAt`, render: rowData => rowData.modifiedAt ? rowData.modifiedAt.substring(0, 10) : 'N/A' },
					{ title: 'Count', field: 'count' },
					{ title: 'MSISDN', field: 'customer_msisdn' },
					{ title: 'Full Name', field: 'customer_full_name' },
					{
						title: 'action', field: 'claimaction', render: rowData => {
							return <div>
								<Stack direction="row" spacing={1} justifyContent="flex-end" >
									<Tooltip title="View Customer">
										<IconButton onClick={() => { props.viewCustomer(rowData.customer_guid) }} >
											<ContactsIcon
												style={{ color: PRIMARY_THEME_COLOR_SHADE_1, fontWeight: 'bolder' }}
											/>
										</IconButton>
									</Tooltip>

									<Tooltip title="Close claim callback">
										<IconButton onClick={() => { props.deleteCallback(rowData.guid) }} >
											<CloseIcon
												style={{ color: 'red', fontWeight: 'bolder', marginRight: "2%", }}
											/>
										</IconButton>
									</Tooltip>
								</Stack>
							</div>
						}
					},
				]}
				data={(query) =>
					new Promise((resolve, reject) => {
						resolve({
							data: [...callbacks.data],
							page: callbacks.page - 1,
							totalCount: callbacks.total,
						});
					})
				}
				options={{
					showTitle: false,
					pageSizeOptions: [1, 5, 10, 20, 50, 100],
					pageSize: callbacks.page_size,
				}}
				onChangeRowsPerPage={(pageSize) => {
					console.log(pageSize, "pageSize");
					props.onPageChange(callbacks.page, pageSize, 'open');
				}}
				onChangePage={(page) => {
					console.log(page, "page");
					props.onPageChange(page + 1, callbacks.page_size, 'open');
				}}

			/>

		</>
	);
}
