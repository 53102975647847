import React from "react";
import { Grid, Box } from "@mui/material";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { formatDateLocale } from "../../../../lib/utils.es6";
import { useStyle } from "./Style";

export const Policy = ({ policy, handleViewPolicy }) => {
  const classes = useStyle();

  return (
    <Grid item xs={12} md={12} sm={12}>
      <div className={classes.policyCardWrapper}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={2}
            sm={2}
            className={classes.policyIconWrapper}
          >
            <Box className={classes.policyIcons}>
              <FolderOpenIcon
                fontSize="14px"
                sx={{ color: "#ffffff" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <span>Policy Number</span>
            <p>{policy.policy_number || "N/A"}</p>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <span>Status</span>
            <p>{policy.status || "N/A"}</p>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <span>Start Date</span>
            <p>{formatDateLocale(policy.start_date) || "N/A"}</p>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <span>Full Name</span>
            <p>{policy.full_name || "N/A"}</p>
          </Grid>

          <Grid item xs={12} md={2} sm={2} className={classes.viewWrapper}>
            <Box display="flex" justifyContent="flex-start">
              <ul style={{ cursor: "pointer" }} onClick={() => handleViewPolicy(policy)}>
                <li >View Policy </li>
                <li><ChevronRightOutlinedIcon /></li>
              </ul>
            </Box>
          </Grid>

        </Grid>
      </div>
    </Grid>
  );
};
