import React from 'react'
import { useStyle } from "./Style";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
const ModalInc = (props) => {
  const classes = useStyle();
  return (
    <>
      <Dialog open={props.open} onClose={props.close} fullWidth={props.fullWidth}
        maxWidth={props.maxWidth} >
        <DialogTitle className={classes.modaltitle}>
          <div >
            <h3>{props.modalTitle}</h3>
          </div>
        </DialogTitle>
        <DialogContent className={classes.modalinputwrapper}>
          <DialogContentText>
            {
              props.subTitle
            }
          </DialogContentText>
          {props.children}
        </DialogContent>
        <DialogActions className={classes.modalbtns} >
          {
            props.modalButtons && props.modalButtons.map((button, index) => button)
          }
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ModalInc