import * as types from '../../lib/actionTypes.es6';

export function createDebitOrderRequest(debitOrder) {
  return {
    type: types.CREATE_DEBIT_ORDER_REQUEST,
    payload: debitOrder,
  };
}

export function createDebitOrderSuccess(debitOrder) {
  return {
    type: types.CREATE_DEBIT_ORDER_SUCCESS,
    payload: debitOrder,
  };
}

export function createDebitOrderFailure(response) {
  return {
    type: types.CREATE_DEBIT_ORDER_FAILURE,
    payload: response,
  };
}

export function resetDebitOrderForm() {
  return {
    type: types.RESET_CREATE_DEBIT_ORDER,
  };
}

export function getAllBanksRequest() {
  return {
    type: types.GET_ALL_BANKS_REQUEST,
  };
}

export function getAllBanksSuccess(banks) {
  return {
    type: types.GET_ALL_BANKS_SUCCESS,
    payload: banks,
  };
}

export function getAllBanksFailure(response) {
  return {
    type: types.GET_ALL_BANKS_FAILURE,
    payload: response,
  };
}

