import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../../lib/actionTypes.es6'
import * as actions from './actions';

import {
  getLous, createLou, editLou, createHospital, deleteHospital,
  getHospitals, searchHospitals, createDiagnosis, deleteDiagnosis,
  getDiagnosis, searchDiagnosis, deleteLou
} from '../../../../lib/requests.es6'
import { GAloggerException } from '../../../../lib/monitoring.es6';
import { unAuthorizedNotification } from '../../../../lib/access.es6';


export function* getLOUSRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_LOUS_REQUEST);
    try {
      const response = yield call(getLous, request.payload);
      yield put(actions.getLOUSSuccess(response.data));

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getLOUSFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}



export function* createLOURequestWatcher() {
  for (;;) {
    const request = yield take(types.CREATE_LOU_REQUEST);
    try {
      const response = yield call(createLou, request.payload);
      yield put(actions.createLOUSuccess(response.data));
      yield put(actions.getLOUSRequest({customer_guid: request.payload.customer_guid }))

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.createLOUFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* editLOURequestWatcher() {
  for (;;) {
    const request = yield take(types.EDIT_LOU_REQUEST);
    try {
      const response = yield call(editLou, request.payload);
      yield put(actions.EditLOUSuccess(response.data));
      yield put(actions.getLOUSRequest({customer_guid: request.payload.customer_guid }))

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.EditLOUFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* deleteLouRequestWatcher() {
  for (;;) {
    const request = yield take(types.DELETE_LOU_REQUEST);
    try {
      const response = yield call(deleteLou, request.payload);
      yield put(actions.deleteLouSuccess(response.data));
      yield put(
        yield put(actions.getLOUSRequest({customer_guid: request.payload.customer_guid }))
      );
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.deleteLouFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* createHospitalRequestWatcher() {
  for (;;) {
    const request = yield take(types.CREATE_HOSPITAL_REQUEST);
    try {
      const response = yield call(createHospital, request.payload);
      yield put(actions.createHospitalSuccess(response.data));
      yield put(actions.getHospitalsRequest({partner: request.payload.partner}));

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.createHospitalFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* deleteHospitalRequestWatcher() {
  for (;;) {
    const request = yield take(types.DELETE_HOSPITAL_REQUEST);
    try {
      const response = yield call(deleteHospital, request.payload.id);
      yield put(actions.deleteHospitalSuccess(response.data));
      yield put(actions.getHospitalsRequest({partner: request.payload.partner}));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.deleteHospitalFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* getHospitalsRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_HOSPITALS_REQUEST);
    try {
      const response = yield call(getHospitals, request.payload);
      yield put(actions.getHospitalSuccess(response.data));

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getHospitalsFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* searchHospitalsRequestWatcher() {
  for (;;) {
    const request = yield take(types.SEARCH_HOSPITALS_REQUEST);
    try {
      const response = yield call(searchHospitals, request.payload);
      yield put(actions.searchHospitalSuccess(response.data));

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.searchHospitalsFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* createDiagnosisRequestWatcher() {
  for (;;) {
    const request = yield take(types.CREATE_DIAGNOSIS_REQUEST);
    try {
      const response = yield call(createDiagnosis, request.payload);
      yield put(actions.createDiagnosisSuccess(response.data));
      yield put(actions.getDiagnosisRequest({partner: request.payload.partner}));

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.createDiagnosisFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* deleteDiagnosisRequestWatcher() {
  for (;;) {
    const request = yield take(types.DELETE_DIAGNOSIS_REQUEST);
    try {
      const response = yield call(deleteDiagnosis, request.payload.id);
      yield put(actions.deleteDiagnosisSuccess(response.data));
      yield put(actions.getDiagnosisRequest({partner: request.payload.partner}));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.deleteDiagnosisFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* getDiagnosisRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_DIAGNOSIS_REQUEST);
    try {
      const response = yield call(getDiagnosis, request.payload);
      yield put(actions.getDiagnosisSuccess(response.data));

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getDiagnosisFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* searchDiagnosisRequestWatcher() {
  for (;;) {
    const request = yield take(types.SEARCH_DIAGNOSIS_REQUEST);
    try {
      const response = yield call(searchDiagnosis, request.payload);
      yield put(actions.searchDiagnosisSuccess(response.data));

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.searchDiagnosisFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}