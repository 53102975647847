import React from "react";
import LocalizedStrings from 'react-localization';
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import NavigationWrapper from "./Containers/NavigationWrapper/NavigationWrapper";
import { getCustomer } from "../../../containers/Customer/actions.es6";
import RegisterCustomerOrange from './Containers/RegisterCustomer/index'
import { localisedText } from "../../../lib/localisation.es6";
import OrangeEditUser from './Containers/EditUser/index'

class OrangeCustomerStatus extends React.Component {
  
  constructor(props) {
    const localizedStrings = new LocalizedStrings(localisedText);
    super(props);
    this.state = {
      labels: localizedStrings,
      openEditCustomerDialog: false,
      openBuyPolicyDialog: false,
      openAddPaymentDialog: false,
      openUploadDocument: false,
    }
  }

  componentWillMount() {
    this.updateLanguage();
    let customer_guid = this.props.params.customer_guid
		if (customer_guid) {
      this.setState({customer_guid: customer_guid})
			this.refreshCustomerStatus(customer_guid);
		} 
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    const language = this.props.globalData.language;
    let resetLanguage = false;
    if (!language) {
      resetLanguage = true;
    }
    const labels = this.state.labels;
    if (resetLanguage || labels.getLanguage() !== language) {
      labels.setLanguage(language);
      this.setState({});
    }
  }

  refreshCustomerStatus(customer_guid) {
		this.props.dispatch(getCustomer(customer_guid));

	}
  toggleEditCustomerDialog() {
    this.setState({ openEditCustomerDialog: !this.state.openEditCustomerDialog })
  }
  toggleBuyPolicyDialog() {
    this.setState({ openBuyPolicyDialog: !this.state.openBuyPolicyDialog })
  }
  
  toggleAddPaymentDialog(){
    this.setState({ openAddPaymentDialog: !this.state.openAddPaymentDialog })

  }
  toggleOpenUploadDocumentDialog(){
    this.setState({ openUploadDocument: !this.state.openUploadDocument })

  }
  render() {
    if (this.props.customerData.currentCustomer) {

      return (
        <>
          <RegisterCustomerOrange 
              />
          <OrangeEditUser
             openEditCustomerDialog={this.state.openEditCustomerDialog}
             toggleEditCustomerDialog={this.toggleEditCustomerDialog.bind(this)}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <section style={{
                background: "#FFFFFF",
                borderRadius: "8px",
                height: "auto",
                marginTop: "30px",
                padding: "30px"
              }}>

                <NavigationWrapper
                  customerData={this.props.customerData.currentCustomer}
                  loading={this.props.customerData.loader}
                  labels={this.state.labels}
                  toggleEditCustomerDialog={this.toggleEditCustomerDialog.bind(this)}

                  openBuyPolicyDialog={this.state.openBuyPolicyDialog}
                  toggleBuyPolicyDialog={this.toggleBuyPolicyDialog.bind(this)}

                  openAddPaymentDialog={this.state.openAddPaymentDialog}
                  toggleAddPaymentDialog={this.toggleAddPaymentDialog.bind(this)}

                  toggleOpenUploadDocumentDialog={this.toggleOpenUploadDocumentDialog.bind(this)}
                  openUploadDocument={this.state.openUploadDocument}
                />

              </section>
            </Grid>
          </Grid>
        </>
      )
    } else {
      return ""
    }

  }

}

export default connect((state) => ({
  customerData: state.currentCustomer,
  globalData: state.global,
}))(OrangeCustomerStatus);