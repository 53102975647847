import * as types from '../../lib/actionTypes.es6';
import { take, call, put } from 'redux-saga/effects';
import { addSingleBundledPayment, addMultipleBundledPayment, addSingleNonBundledPayment, addMultipleNonBundledPayment } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';



export function* addSinglePaymentRequestWatcher() {
  while (true) {
    const requestAction = yield take(types.ADD_SINGLE_PAYMENT_REQUEST);
    let response = {};
    try {
      if(requestAction.payload.isBundled) {
        response = yield call(
          addSingleBundledPayment,
          requestAction.payload
        );
      } else {
        response = yield call(
          addSingleNonBundledPayment,
          requestAction.payload
        );
      }
      
      yield put(actions.addSinglePaymentSuccess(response.message));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.addSinglePaymentFail(errorPayload));
    }
  }
}

export function* addMultiplePaymentRequestWatcher() {
  while (true) {
    const requestAction = yield take(types.ADD_MULTIPLE_PAYMENT_REQUEST);
    let response = {};
    
    try {
      if(requestAction.payload.isBundled) {
        response = yield call(
          addMultipleBundledPayment,
          requestAction.payload
        );
      } else {
        response = yield call(
          addMultipleNonBundledPayment,
          requestAction.payload
        );
      }
      
      yield put(actions.addMultiplePaymentSuccess(response));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.addMultiplePaymentFail(errorPayload));
    }
  }
}
