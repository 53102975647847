import * as types from '../../lib/actionTypes.es6';

export function getLanguagesRequest() {
  return {
    type: types.GET_LANGUAGES_REQUEST,
  };
}

export function getLanguagesSuccess(response) {
  return {
    type: types.GET_LANGUAGES_SUCCESS,
    payload: response,
  };
}

export function getLanguagesFailure(response) {
  return {
    type: types.GET_LANGUAGES_FAILED,
    payload: response,
  };
}
