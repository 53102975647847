import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import SkeletonWrapper from '../../../NewProductList/components/SkeletonWrapper/SkeletonWrapper';

import { getPartner } from '../../../../lib/access.es6';
import TableInc from '../../../../shared-ui/TableInc';
import { formatDateLocale } from '../../../../lib/utils.es6';
const Payments = (props) => {
  const classes = useStyle();


  const currencyCode = getPartner().country.currency_code

  const customerPayments = props.payments || [];


  if (props.loading) {
    return <SkeletonWrapper loading={props.loading} />
  }
  const tempArr = customerPayments.map(payments => {
    return {
      ...payments,
      product_name: payments.product_name || "N/A",
      amount_in_cents:  currencyCode + " " + payments.amount_in_cents / 100 || "N/A" ,
      payment_failure_reason: payments.payment_failure_reason || "N/A"
      }
      
  })

 

  return (


    <>

      {
        props.payments.length > 0 ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} >
                <TableInc

                  columns={[
                    {
                      title: 'Product Name', field: 'product_name', render: rowData => <>
                        {
                          rowData.product_name || "N/A"
                        }
                      </>
                    },
                    {
                      title: 'Payment reference', field: 'reference', render: rowData => <>
                        {
                          rowData.reference || "N/A"
                        }
                      </>
                    },
                    {
                      title: 'Created at', field: 'created_at', render: rowData => <>
                        {
                          formatDateLocale(rowData.created_at) || "N/A"
                        }
                      </>
                    },
                    {
                      title: 'Effected at', field: 'effected_at', render: rowData => <>
                        {
                          formatDateLocale(rowData.effected_at) || "N/A"
                        }
                      </>
                    },
                    {
                      title: 'Status', field: 'status', render: rowData => <>
                        {
                          rowData.status || "N/A"
                        }
                      </>
                    },
                    {
                      title: 'Amount', field: 'amount_in_cents'
                    },
                    {
                      title: 'Payment Failure Reason', field: 'payment_failure_reason', render: rowData => <>
                        {
                          rowData.payment_failure_reason || "N/A"
                        }
                      </>
                    },

                  ]}
                  title="Payments"
                  data={
                    tempArr
                  }
                  options={{
                    pageSize: customerPayments.length < 5
                    ? customerPayments.length
                    : 5,
                    exportButton: true,
                    showTitle: false,
                  }}

                />

              </Grid>
            </Grid>

          </>
        ) :
          (<>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} >
                <div className={classes.policieswrapper}>
                  <div className={classes.nodatafound}>
                    <p>No payments found please add payments </p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </>
          )
      }

    </>
  )
}

export default Payments