import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
const Stats = () => {
    const classes = useStyle();
  return (
   
    <section className={classes.stats}>
    <Container>
      <Grid container>
        <Grid item xs={12} sm={4} md={4}>
          <div className={classes.statsbox}>
            <span>3M+</span>
            <p>Registered Customers</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <div className={classes.statsbox}>
            <span>500k+</span>
            <p>Monthly Active Policies</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <div className={classes.statsbox}>
            <span>9</span>
            <p>countries</p>
          </div>
        </Grid>
      </Grid>
    </Container>
  </section>
  )
}

export default Stats