import React from 'react'
import SkeletonWrapper from '../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import CustomerProfileInc from '../../../../../shared-ui/CustomerProfileInc';

const UserProfile = (props) => {

  if (props.loading) {
    return <SkeletonWrapper loading={props.loading} />
  }


  return (
    <>
    <CustomerProfileInc
    labels={props.labels}
    data={props.customerData}
    />
    </>
  )
}

export default UserProfile