import React from "react";
import LocalizedStrings from 'react-localization';
import { connect } from "react-redux";
import { localisedText } from "../../../../../lib/localisation.es6";
import Policys from "./Components/DisplayPolicies/Policys";
import BuyPolicy from "./Components/BuyPolicy/BuyPolicy";
import CancelPolicy from "./Components/CancelPolicy/CancelPolicy";
import { reloadPage } from "../../../../../lib/utils.es6";
import { hashHistory } from 'react-router';
import { deleteOrangePolicyRequest, getAllOrangePoliciesRequest, listOrangePaidProductsRequest, purchaseOrangePolicyRequest, resetOrangePolicyPurchase, resetOrangePolicyToEdit, setOrangePolicyToCancel, setOrangePolicyToEdit, updateOrangePolicyRequest, resetOrangePolicyEdit } from "./Redux/actions";
import DownloadDocument from "../../../../../components/DownloadDocument/DownloadDocument";
import * as documentActions from '../../../../../containers/DocumentGeneration/actions.es6';
import { getPartner } from "../../../../../lib/access.es6";
import EditPolicy from "./Components/EditPolicy/EditPolicy";
class OrangePolicies extends React.Component {
  constructor(props) {
    const localizedStrings = new LocalizedStrings(localisedText);
    super(props);
    this.state = {
      openPolicyCancelDialog: false,
      openEditPolicyDialog: false,
      labels: localizedStrings,
    }
  }

  componentWillMount() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      this.props.dispatch(getAllOrangePoliciesRequest({ msisdn: currentCustomer.msisdn, guid: currentCustomer.guid }))
    }

    if (this.props.orangePolicyData.products.length < 1) {
      this.props.dispatch(listOrangePaidProductsRequest());
    }

  }
  BuyPolicy(payload) {
    this.props.dispatch(purchaseOrangePolicyRequest(payload));
  }

  resetAfterSucesfullPurchase() {
    this.props.dispatch(resetOrangePolicyPurchase());
    const guid = this.props.customerData.currentCustomer ? this.props.customerData.currentCustomer.guid : ""
    if (guid) {
      hashHistory.push('admin/customer_status/' + guid);
      reloadPage();
    }
  }
  resetAfterFailedPurchase() {
    this.props.dispatch(resetOrangePolicyPurchase());
  }
  cancelPolicy(policy) {
    this.props.dispatch(deleteOrangePolicyRequest(policy));
  }
  toggleCancelPolicyDialog() {
    this.setState({ openPolicyCancelDialog: !this.state.openPolicyCancelDialog })
  }
  setPolicyToCancel(policy) {
    this.props.dispatch(setOrangePolicyToCancel(policy))
  }

  toggleEditPolicyDialog() {
    this.setState({ openEditPolicyDialog: !this.state.openEditPolicyDialog })
  }

  setPolicyToEdit(policy) {
    this.props.dispatch(setOrangePolicyToEdit(policy))
  }
  closeEditDialog() {
    this.props.dispatch(resetOrangePolicyToEdit())
  }
  editPolicy(payload) {
    this.props.dispatch(updateOrangePolicyRequest(payload));
  }
  resetAfterSucesfullEdit() {
    this.props.dispatch(resetOrangePolicyEdit());
    reloadPage();
  }

  productNameDisplay(policy) {
    return `${policy.product_name} (${policy.product_cover_type})`;
  }

  getBenefitLabel(benefit, labels) {
    switch (benefit.type.trim()) {
      case 'Hospital':
        return labels.nightlyBenefit;
      case 'Death':
        return labels.fixedBenefit;
      case 'Cashback':
        return labels.cashback;
      case 'Property':
        return labels.propertyAssistanceBenefit;
      case 'Income_Loss':
        return labels.incomeLossBenefit;
      case 'Personal_Accident':
        return labels.personalAccident;
      case 'Medical_Expense':
        return labels.medicalExpense;
      case 'Inpatient':
        return labels.inpatient;
      case 'Maternity':
        return labels.maternity;
      case 'Outpatient':
        return labels.outpatient;
      default:
        return benefit.type;
    }
  }

  getBenefits(policy) {
    const labels = this.state.labels;
    const currencyCode = getPartner().country.currency_code

    return (
      policy.benefits && (
        <div>
          {policy.benefits.map((benefit, index) => (
            <>
              <strong key={index} >{this.getBenefitLabel(benefit, labels)}:</strong>&nbsp;
              {currencyCode} {benefit.amount.toLocaleString()}
              <br />
            </>
          ))}
        </div>
      )
    );
  }

  render() {
    const templateDocuments = this.props.documentGenerationData.templateDocuments;

    return (
      <>
        <BuyPolicy
          labels={this.props.labels}
          openBuyPolicyDialog={this.props.openBuyPolicyDialog}
          toggleBuyPolicyDialog={this.props.toggleBuyPolicyDialog}
          customerMsisdn={this.props.customerData.currentCustomer.msisdn}
          BuyPolicy={this.BuyPolicy.bind(this)}

          loader={this.props.orangePolicyData.loader}
          successfullPolicyPurchase={this.props.orangePolicyData.policy_purchase_succesful}
          policyPurchaseInfo={this.props.orangePolicyData.policyPurchaseInfo}
          policyPurchaseFailure={this.props.orangePolicyData.policy_purchase_failed}
          resetAfterSucesfullPurchase={this.resetAfterSucesfullPurchase.bind(this)}
          resetAfterFailedPurchase={this.resetAfterSucesfullPurchase.bind(this)}
          policyPurchaseDataError={this.props.orangePolicyData.errors}
          allProducts={this.props.orangePolicyData.products}
        />

        <Policys
          labels={this.props.labels}
          productNameDisplay={this.productNameDisplay.bind(this)}
          getBenefits={this.getBenefits.bind(this)}
          policies={this.props.orangePolicyData.policies}
          customer_guid={this.props.customer_guid}
          loader={this.props.orangePolicyData.get_policies_loader}
          toggleCancelPolicyDialog={this.toggleCancelPolicyDialog.bind(this)}
          setPolicyToCancel={this.setPolicyToCancel.bind(this)}

          setPolicyToEdit={this.setPolicyToEdit.bind(this)}
          openEditPolicyDialog={this.state.openEditPolicyDialog}


          templateDocuments={this.props.documentGenerationData.templateDocuments}
          setPolicyToDownloadDocumentFor={(policy) => this.setState({ policyToDownload: policy })}
        />

        <EditPolicy
          labels={this.props.labels}
          policy={this.props.orangePolicyData.policyToEdit}
          editPolicy={this.editPolicy.bind(this)}
          closeEditDialog={this.closeEditDialog.bind(this)}


          Editloader={this.props.orangePolicyData.editPolicyLoader}
          successfullPolicyEdit={this.props.orangePolicyData.editPolicySuccesful}
          policyEditInfo={this.props.orangePolicyData.editedPolicy}
          policyEditFailure={this.props.orangePolicyData.editPolicyfailed}
          resetAfterSucesfullEdit={this.resetAfterSucesfullEdit.bind(this)}
          resetAfterFailedEdit={this.resetAfterSucesfullEdit.bind(this)}
          policyEditDataError={this.props.orangePolicyData.editPolicyErrors}

        />

        <CancelPolicy
         labels={this.props.labels}
          openPolicyCancelDialog={this.state.openPolicyCancelDialog}
          toggleCancelPolicyDialog={this.toggleCancelPolicyDialog.bind(this)}
          cancelPolicy={this.cancelPolicy.bind(this)}
          policyToCancel={this.props.orangePolicyData.policyToCancel}

          cancelloader={this.props.orangePolicyData.cancelPolicyLoader}
          successfullPolicyCancel={this.props.orangePolicyData.cancelPolicySuccesful}
          policyCancelFailure={this.props.orangePolicyData.cancelPolicyfailed}
          policyCancelDataError={this.props.orangePolicyData.cancelPolicyErrors}
        />
        <DownloadDocument
          labels={this.state.labels}
          identifier="Policies"
          entityDocument={
            this.props.documentGenerationData.entityDocument
          }
          entity={this.state.policyToDownload}
          resetEntity={() => this.setState({ policyToDownload: null })}
          templates={templateDocuments}
          getTemplates={() => this.props.dispatch(documentActions.getTemplateDocumentsRequest('Policy'))}
          getTemplatesLoader={this.props.documentGenerationData.getTemplateDocumentloader}
          isEmptyTemplatesResponse={this.props.documentGenerationData.isEmptyTemplates}
          downloadDocument={guid => {
            this.props.dispatch(
              documentActions.downloadDocumentRequest({
                entity_guid: this.state.policyToDownload.guid,
                template_guid: guid,
              })
            );
          }}
          resetGetTemplateDocuments={() => {
            this.props.dispatch(documentActions.resetGetTemplateDocuments());
          }}
          resetDownloadDocument={() => {
            this.props.dispatch(documentActions.resetDocumentEntity());
          }}
          showDownloadDocumentProgressAlert={
            this.props.documentGenerationData.downloadDocumentLoader
          }
          showDownloadDocumentSuccessAlert={
            this.props.documentGenerationData.downloadDocumentSuccess
          }
          showDownloadDocumentErrorAlert={
            !!this.props.documentGenerationData.downloadDocumentError
          }
          downloadDocumentError={
            this.props.documentGenerationData.downloadDocumentError
          }
          resetDownloadDocumentSuccessAlert={() => {
            this.props.dispatch(
              documentActions.resetDownloadDocumentSuccess()
            );
          }}
          resetDownloadDocumentErrorAlert={() => {
            this.props.dispatch(
              documentActions.resetDownloadDocumentError()
            );
          }}

          goToTemplatesPage={() => {
            hashHistory.push('admin/view_doc_templates');
          }}
        />

      </>
    )


  }
}

export default connect((state) => ({
  customerData: state.currentCustomer,
  registrationData: state.customerRegistration,
  orangePolicyData: state.orangePoliciesData,
  documentGenerationData: state.documentGeneration,
}))(OrangePolicies);
