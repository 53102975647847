import * as types from '../../lib/actionTypes.es6';

export function dailyReportRequest(reportType) {
  return {
    type: types.DAILY_REPORT_REQUEST,
    payload: reportType,
  };
}

export function dailyReportRequestSuccess(reportURL) {
  return {
    type: types.DAILY_REPORT_REQUEST_SUCCESS,
    payload: reportURL,
  };
}

export function dailyReportRequestError(error) {
  return {
    type: types.DAILY_REPORT_REQUEST_ERROR,
    payload: error,
  };
}

export function dailyReportRequestReset() {
  return {
    type: types.DAILY_REPORT_REQUEST_RESET,
  };
}
