import React, { useState } from 'react'
import { useStyle } from "./Style";
import { hashHistory } from 'react-router';
import { Box, Grid, Button } from '@mui/material';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { formatDateLocale } from '../../../../../../../lib/utils.es6';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SkeletonWrapper from '../../../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import { getPartner, hasAuthourity } from '../../../../../../../lib/access.es6';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';


const Claims = (props) => {
  const classes = useStyle();


  const [expanded, setExpanded] = useState(false);


  const paymemtDisplay = expanded ? props.claims : props.claims.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).slice(0, 2)

  const currencyCode = getPartner().country.currency_code

  const viewClaim = (claim_guid) =>{
    hashHistory.push(`admin/claim-processing-v2/${claim_guid}`);

  }

  if (props.loader) {
    return <SkeletonWrapper loading={props.loader} />

  }


  if (props.claims && props.claims.length > 0) {
    return (
      <>
        {
          paymemtDisplay.map((claim, index) => (
            <>
              <Grid container className={classes.policyWrapper} key={index} >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container className={classes.policyHeader}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Grid container>
                        <Grid item xs={12} md={2} sm={2} className={classes.policyIconWrapper}   >
                          <Box className={classes.policyIcons}>
                            <NoteAddOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={10} sm={10} className={classes.policyHeaderContent}>
                          <h6>{claim.claim_number || "N/A"}</h6>
                          <p> Claim Number</p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} >

                      <Grid container>
                        <Grid item xs={12} md={4} sm={4}>
                          <div >

                          </div>
                        </Grid>
                        <Grid item xs={12} md={8} sm={8} className={classes.policyActiveStatusWrapper}>
                          <Box display="flex" justifyContent="flex-end">
                            <ul>
                              <li className={claim.active === true ? classes.activePill : classes.inactivePill}> <span>{claim.active === true ? `Active` : `Inactive`}</span></li>
                              <li  style={{display: hasAuthourity('VIEW_CLAIMS') ? "inline-flex" : "none"}} onClick={() => viewClaim(claim.guid)}>View Details <span><ChevronRightOutlinedIcon/></span></li>
                            </ul>

                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <div className={classes.policyHeaderBorder}></div>
                  </Grid>

                  <Grid container spacing={2} className={classes.policyContentWrapper}>
                    <Grid item xs={12} sm={3} md={3} >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3} md={3}></Grid>
                        <Grid item xs={12} sm={9} md={9}>
                          <p>Initiator Fullname </p>
                          <h6> {claim.initiator_fullname || "N/A"}</h6>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p> Created At </p>
                      <h6>{formatDateLocale(claim.created_at) || "N/A"}
                      </h6>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p>Initiator Phone Number </p>
                      <h6>{claim.initiator_msisdn || "N/A"}</h6>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p>status </p>
                      <h6>
                        {claim.status || "N/A"}
                      </h6>
                    </Grid>

                  </Grid>
                  <div className={classes.policyContentBorder}></div>
                  <Grid container spacing={2} className={classes.policyFooterWrapper}>
                    <Grid item xs={12} md={6} sm={6}>
                      <Grid container spacing={2} >


                        <Grid item xs={12} md={6} sm={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={3} md={3}></Grid>
                            <Grid item xs={12} sm={9} md={9}>
                              <p>Event Date</p>
                              <h6>{claim.event_date || "N/A"}</h6>

                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                          <p> Policy Number</p>
                          <h6>{claim.claim_details.policy_number || "N/A"}</h6>
                        </Grid>

                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <div className={classes.policyBalance}>
                        <p> Benefits</p>
                        {
                          <ol style={{listStyle: "none"}}>
                            {
                              claim.benefits.map(benefit => {
                                return <li>
                                  {benefit.type} - {<strong> {currencyCode + " " + (benefit.max_benefit_amount_in_cents / 100).toLocaleString()}</strong>}
                                </li>
                              })
                            }
                          </ol>
                        }
                        {/* <p>Amount</p>
                        <h6>{`${currencyCode} ${claim.amount_in_cents / 100}` || "0.00"}   </h6> */}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ))
        }

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Button style={{
              background: "#FBA92D",
              borderRadius: "8px",
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "15px",
              boxShadow: "none",
              padding: "10px",
              marginTop: "20px",
              textTransform: "capitalize",
            }} variant="contained" size="large" endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} onClick={() => setExpanded(!expanded)}>
              {expanded ? `Show Less` : `Show More`}
            </Button>

          </Grid>
        </Grid>
      </>
    )
  } else {
    return <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
        <Grid container spacing={2} className={classes.claimdetailswrapper}>
          <Grid item xs={12} sm={12} md={12} className={classes.ClaimDetailscontent}>
            <p style={{ textAlign: 'center', marginTop: "30px" }}>No details found</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }

}

export default Claims