import { take, call, put } from 'redux-saga/effects';
import { getReportClaimDeathBlob } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import * as types from '../../lib/actionTypes.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

export function* reportClaimDeathRequestWatcher() {
  for (;;) {
    const reportAction = yield take(types.REPORT_CLAIM_DEATH_REQUEST);
    try {
      const response = yield call(
        getReportClaimDeathBlob,
        reportAction.payload
      );
      console.log(response.data);
      yield put(actions.reportClaimDeathRequestSuccess(response.data));
    } catch (e) {
      yield put(
        actions.reportClaimDeathRequestError(
          'An error occurred, this could be due to invalid data'
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
