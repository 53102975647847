import * as types from '../../lib/actionTypes.es6';
import { take, call, put } from 'redux-saga/effects';
import { mapColumnsToFields, getPreviousLoyaltyStatus, getPreviousLoyaltiesStatus } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';



export function* uploadLoyaltyDataRequestWatcher() {
  while (true) {
    const requestAction = yield take(types.MAP_COLUMN_NUMBER_TO_FIELDS_SUCCESS);
    try {
      yield put(actions.uploadLoyaltyDataSuccess(requestAction.payload));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.uploadLoyaltyDataFail(errorPayload));
    }
  }
}

export function* mapColumnsToFieldsRequestWatcher() {
  while (true) {
    const requestAction = yield take(types.MAP_COLUMN_NUMBER_TO_FIELDS_REQUEST);
    let response = {};
    try {
        response = yield call(
          mapColumnsToFields,
          requestAction.payload
        );
      yield put(actions.mapColumnsToFieldsSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.mapColumnsToFieldsFail(errorPayload));
    }
  }
}

export function* getPreviousLoyaltyStatusRequestWatcher() {
  while (true) {
    const requestAction = yield take(types.GET_PREVIOUS_LOYALTY_STATUS_REQUEST);
    let response = {};
    try {
        response = yield call(
          getPreviousLoyaltyStatus,
          requestAction.payload
        );
      yield put(actions.getPreviousLoyaltyStatusSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.getPreviousLoyaltyStatusFail(errorPayload));
    }
  }
}

export function* getPreviousLoyaltiesStatusRequestWatcher() {
  while (true) {
    const requestAction = yield take(types.GET_PREVIOUS_LOYALTIES_STATUS_REQUEST);
    let response = {};
    try {
        response = yield call(
          getPreviousLoyaltiesStatus,
          requestAction.payload
        );
      yield put(actions.getPreviousLoyaltiesStatusSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.getPreviousLoyaltiesStatusFail(errorPayload));
    }
  }
}