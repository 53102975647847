import React, {useState} from 'react'
import AlertDialog from '../../../../../components/AlertDialog/index.es6'
import { CircularProgress, Grid } from '@mui/material'
import ButtonInc from '../../../../../shared-ui/ButtonInc'
import { reloadPage } from '../../../../../lib/utils.es6'
import ModalInc from '../../../../../shared-ui/ModalInc'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

const RejectPayment = (props) => {

  const [reasonsForRejectingPayment, setReasonForRejectingPayment] = useState('');

  const handleChange = (event) =>{
    const {name, value} = event.target

    if(name === "reasonsForRejectingPayment"){
      setReasonForRejectingPayment(value)
    }
  }


  const rejectPayment = () =>{
    props.rejectPayment(reasonsForRejectingPayment);
  }
  const handlePaymentApprovedSuccessfully = () => {
    reloadPage()
  }

  const handlePaymentApprovalError = () => {
    reloadPage()

  }

  return (
    <>

      <AlertDialog
        custom
        show={props.showRejectPaymentProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Rejecting payment...</h2>
      </AlertDialog>


      <AlertDialog
        success
        show={props.showRejectPaymentSuccessAlert}
        size="sm"
        title={`Payment rejected`}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handlePaymentApprovedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'Cancel'}
        confirmBtnCssClass
      >
        <div >
          <ButtonInc variant="contained" onClick={() => { handlePaymentApprovedSuccessfully() }} style={{ background: 'green', }}>Ok</ButtonInc>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showRejectPaymentErrorAlert}
        danger
        title={`Error rejecting payment`}
        onConfirm={() => handlePaymentApprovalError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.rejectPaymentError ? props.rejectPaymentError.message : ''}
      </AlertDialog>


      <ModalInc
        modalTitle="Add reasons for rejecting the payment"
        subTitle="Fill the below field to give reasons for rejecting the payment"
        open={props.openRejectPayment}
        onClose={props.toggleOpenRejectPayment}
        fullWidth
      >
        <ValidatorForm onSubmit={() => rejectPayment()} >

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <TextValidator
                name='reasonsForRejectingPayment'
                id="reasonsForRejectingPayment"
                label="Enter reason for rejecting the payment "
                value={reasonsForRejectingPayment}
                onChange={handleChange}
                multiline
                rows={5}
                maxRows={4}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter reason for rejecting the payment']}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} md={6}>

            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    type='submit'
                    style={{ marginTop: "20px", float: "right" }}
                  >
                    Reject Payment
                  </ButtonInc>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginTop: "20px" }}
                    onClick={props.toggleOpenRejectPayment}
                  >
                    Go back
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </ValidatorForm>

      </ModalInc>
    </>
  )
}

export default RejectPayment