import React, { useState } from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
//import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PoliciesDependants from '../PoliciesDependants/PoliciesDependants';
import { getPartner, hasAuthourity } from '../../../../lib/access.es6'
import SkeletonWrapper from '../../../NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import TableInc from '../../../../shared-ui/TableInc';
import { formatDateLocale, trimTimestampFromDate } from '../../../../lib/utils.es6';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItemInc from '../../../../shared-ui/MenuItemInc';

const ITEM_HEIGHT = 48;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    width: "100%",
    maxWidth: "1000px"
  }
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#fff',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Policies = (props) => {
  const classes = useStyle();

  const [open, setOpen] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState(null)
  const [selectedPolicyAction, setSelectedPolicyAction] = useState(null)

  const [anchorEl, setAnchorEl] = useState(null);


  const openPolicyCancelReason = Boolean(anchorEl);
  const handleOpenMenu = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedPolicyAction(rowData);
  };





  const handleClickOpen = (policy) => {
    setSelectedPolicy(policy)
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const currencyCode = getPartner().country.currency_code

  const policiesData = props.policies || [];
  if (props.loading) {
    return <SkeletonWrapper loading={props.loading} />
  }

  const tempArr = policiesData.map(policy => {
    return {
      ...policy,
      start_date: formatDateLocale(policy.start_date) || "N/A",
      due_date: formatDateLocale(policy.due_date) || "N/A",
      premium_granularity: (policy.quotation && policy.quotation.total_premium_amount_in_cents)
        ? `${currencyCode}  ${policy.quotation.total_premium_amount_in_cents / 100} per ${policy.quotation.premium_cardinality} ${policy.quotation.premium_granularity}` || "0.00"

        : "N/A",
      total_paid_amount_in_cents: `${currencyCode}  ${policy.total_paid_amount_in_cents / 100}` || "0.00",
      balance_amount_in_cents: `${currencyCode}  ${policy.balance_amount_in_cents / 100}` || "0.00",
    }

  })

  return (
    <>
      {
        policiesData.length > 0 ? (<>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} >
              <div className={classes.policieswrapper}>
                <TableInc
                  columns={[
                    {
                      title: 'Action', field: 'policyaction', render: rowData => {
                        return <div className={classes.actionbtn}>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={openPolicyCancelReason ? 'long-menu' : undefined}
                            aria-expanded={openPolicyCancelReason ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={(event) => handleOpenMenu(event, rowData)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          {
                            selectedPolicyAction && selectedPolicyAction.guid === rowData.guid && <Menu
                              className={classes.policyactionbtns}
                              id="long-menu"
                              MenuListProps={{
                                'aria-labelledby': 'long-button',
                              }}
                              anchorEl={anchorEl}
                              open={openPolicyCancelReason}
                              onClose={() => setAnchorEl(null)}
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                  width: '20ch',
                                },
                              }}
                            >
                              <MenuItemInc style={{ cursor: "pointer" }} onClick={() => { setAnchorEl(null); handleClickOpen(rowData) }}>View Dependants</MenuItemInc>
                              <MenuItemInc style={{ cursor: "pointer" }} onClick={() => {
                                setAnchorEl(null);
                                props.setPolicyToUpgrade(rowData)
                                props.togglePolicyUpgradeDialog(rowData)
                              }}
                                hasPermision={hasAuthourity('UPGRADE_DOWNGRADE_POLICY')}

                              >Upgrade / Downgrade</MenuItemInc>
                              <MenuItemInc style={{ cursor: "pointer" }} onClick={() => {
                                setAnchorEl(null);
                                props.setPolicyToAmend(rowData)
                                props.togglePolicyAmendDialog(rowData)
                              }}
                                hasPermision={hasAuthourity('UPGRADE_DOWNGRADE_POLICY')}

                              >Amend Policy</MenuItemInc>

                              {!["Non_Payment", "Cancelled"].includes(rowData.status)
                                && <MenuItemInc style={{ cursor: "pointer" }} hasPermision={hasAuthourity('DELETE_POLICY')} onClick={() => { setAnchorEl(null); props.setPolicyToCancel(rowData) }} >Cancel Policy</MenuItemInc>}
                              <MenuItemInc style={{ cursor: "pointer" }} hasPermision={hasAuthourity('DOWNLOAD_POLICY_DOCUMENT')} onClick={() => { setAnchorEl(null); props.setPolicyToDownloadDocumentFor(rowData) }} >Download Policy Document</MenuItemInc>
                              <MenuItemInc style={{ cursor: "pointer" }} hasPermision={hasAuthourity('ADD_POLICY_NOTES')} onClick={() => { setAnchorEl(null); props.setPolicyNoteToAdd(rowData) }} >Add Policy Notes</MenuItemInc>

                              <MenuItemInc style={{ cursor: "pointer" }} onClick={() => { setAnchorEl(null); props.setPolicyToView(rowData) }} >View Policy Notes</MenuItemInc>


                            </Menu>
                          }

                        </div>
                      }
                    }, {
                      title: 'Grower Number', field: 'policy_number', render: rowData => <>
                        {
                          rowData.policy_number || "N/A"
                        }
                      </>
                    },
                    { title: 'Plan', field: 'start_date', render: rowData => rowData.quotation.product_code },
                    { title: 'Start date', field: 'start_date' },
                    {
                      title: 'Status', field: 'status', render: rowData => <span className={rowData.status === 'Full_Coverage' ? classes.active : classes.cancelled}>{rowData.status}</span>
                    },
                    { title: 'Due Date', field: 'due_date', render: rowData => trimTimestampFromDate(rowData.due_date) || "N/A" },
                    {
                      title: 'Premiums', field: 'premium_granularity'
                    },
                    {
                      title: 'Total Premiums paid', field: 'total_paid_amount_in_cents'
                    },
                    {
                      title: 'Balance Due', field: 'balance_amount_in_cents'
                    },


                  ]}
                  title="Policies"
                  data={
                    tempArr
                  }
                  style={{ width: "90%" }}
                  options={{
                    pageSize: tempArr.length < 5
                      ? tempArr.length
                      : 5,
                    showTitle: false,
                    exportButton: true
                  }}
                />

              </div>
            </Grid>
          </Grid>
          {/*dependants dialog box */}
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="dependants-dialog-title"
            open={open}
          >
            <BootstrapDialogTitle className={classes.dependantstitle} id="dependants-dialog-title" onClose={handleClose}>
              Dependants
            </BootstrapDialogTitle>
            <BootstrapDialogTitle id="dependants-dialog-title" onClose={handleClose}>
            </BootstrapDialogTitle>
            <DialogContent >
              <PoliciesDependants policy={selectedPolicy} />
            </DialogContent>
          </BootstrapDialog>

        </>) : (<>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} >
              <div className={classes.policieswrapper}>
                <div className={classes.nodatafound}>
                  <p>No policies found generate a quote</p>
                </div>
              </div>
            </Grid>
          </Grid>
        </>)
      }
    </>
  )


}

export default Policies