import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../lib/actionTypes.es6'
import * as actions from './actions';

import { selectPolicies, initiateClaimdcp,getCustomerClaimsDcp } from '../../../lib/requests.es6';
import { GAloggerException } from '../../../lib/monitoring.es6';
import { unAuthorizedNotification } from '../../../lib/access.es6';


export function* selectPolicyRequestWatcher() {
  for (; ;) {
    const request = yield take(types.GET_EVENT_QUOTE_REQUEST);
    try {
      const response = yield call(selectPolicies, request.payload);
      yield put(actions.getEventQuoteSuccess(response.data));

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getEventQuoteFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* initiateClaimDcpRequestWatcher() {
  for (; ;) {
    const request = yield take(types.INITIATE_CLAIM_REQUEST);
    try {
      const response = yield call(initiateClaimdcp, request.payload);
      yield put(actions.initiateClaimSuccess(response.data));

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.initiateClaimFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* getCustomerClaimsDcpRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_CUSTOMER_CLAIMS_DCP_REQUEST);
    try {
      const response = yield call(getCustomerClaimsDcp, request.payload);
      yield put(actions.getCustomerClaimsDcpSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getCustomerClaimsDcpFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
