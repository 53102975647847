import React from 'react';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import { formatStatus } from '../../lib/utils.es6';

export default class ClaimBenefits extends React.Component {
  rowSelection(rows) {
    this.props.rowSelection(rows);
  }

  isBenefitsReadOnly() {
    const status = this.props.claim.status;

    return [
      'Not_Valid',
      'Approved',
      'Approved_Paid',
      'Approved_Pending',
      'Payment_In_Review',
      'Rejected_Pending',
      'Rejected',
      'ExGratia',
      'ExGratia_Paid',
    ].includes(status);
  }

  render() {
    const labels = this.props.labels;
    const claimData = this.props.claim;
    const benefits = claimData ? claimData.benefits : [];
    return (
      <div className="grouping">
        <h3 className="header">{labels.claimValidPolicies}</h3>
        <br />
        <div className="x_panel" style={{ marginBottom: '0', padding: '0' }}>
          <div className="x_content">
            <form className="form-horizontal form-label-left" noValidate>
              <Table
                onRowSelection={this.rowSelection.bind(this)}
                multiSelectable
                className="table table-striped table-hover"
                style={{ marginBottom: '0' }}
              >
                <TableHeader displaySelectAll={false}>
                  <TableRow>
                    <TableHeaderColumn>{labels.productName}</TableHeaderColumn>
                    <TableHeaderColumn>{labels.benefit}</TableHeaderColumn>
                    <TableHeaderColumn>{labels.nights}</TableHeaderColumn>
                    <TableHeaderColumn>
                      {labels.nightlyBenefit}
                    </TableHeaderColumn>
                    <TableHeaderColumn>{labels.total}</TableHeaderColumn>
                  </TableRow>
                </TableHeader>
                <TableBody deselectOnClickaway={false}>
                  {benefits.map((benefit, index) => (
                    <TableRow
                      key={index}
                      selected={this.props.selectedRows.indexOf(index) !== -1}
                      selectable={!this.isBenefitsReadOnly()}
                    >
                      <TableRowColumn>{benefit.product_name}</TableRowColumn>
                      <TableRowColumn>
                        {formatStatus(benefit.type)}
                      </TableRowColumn>
                      <TableRowColumn>
                        {benefit.night ? benefit.night : 'N/A'}
                      </TableRowColumn>
                      <TableRowColumn>{benefit.amount_benefit}</TableRowColumn>
                      <TableRowColumn>
                        {this.props.totalBenefitAmount(benefit)}
                      </TableRowColumn>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
