import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  generatequotewrapper: {
    background: "#FFFFFF", 
    borderRadius: "8px", 
    height: "auto",
    marginTop: "75px",
    marginBottom: "30px"
  },
  stepperlabelwrapper:{
    padding: "30px",
    "& span":{
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
    }
  },
  quotewrapper:{
    paddingLeft: "40px", 
    paddingRight: "40px" 
  },
  quotenavigationbtn:{
    textAlign: "right",
    marginTop: "30px",
    marginRight: "25px",
    "& button":{
      background: "#FBA92D",
      borderRadius: "8px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      boxShadow: "none",
      padding: "10px",
      marginRight: "20px",
      textTransform: "capitalize",
      marginBottom: "30px",
      "&:hover": {
          background: "#FBA92D",
          boxShadow: "none",
      }
    }
  },
  completebtn:{
    textAlign: 'center', 
    margin: '3%',
    "& p": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
      marginBottom: "30px"
  },
    "& button":{
      background: "green",
      color: "#fff !important",
      borderRadius: "8px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      boxShadow: "none",
      padding: "10px",
      textTransform: "capitalize",
      marginBottom: "30px",
      marginRight: '-2%',
      "&:hover": {
          background: "green",
          boxShadow: "none",
      }
    }
  },
  viewquote:{
    marginLeft: "40px !important",
    background: "#032a37 !important",
  }
}));