import React from "react";
import { connect } from "react-redux";
import DisplayDocuments from "./Components/DisplayDocuments/DisplayDocuments";
import { addOrangeCustomerDocumentRequest, deleteOrangeCustomerDocumentRequest, getOrangeCustomerDocumentsRequest, resetHardDeleteOrangeCustomerDocumentError, resetHardDeleteOrangeCustomerDocumentSuccess, resetOrangeDocumentToDelete, setOrangeCustomerDocumentsToDelete } from "./Redux/actions";
import UploadDocuments from "./Components/UploadDocuments/UploadDocuments";
import DeleteDocument from "./Components/DeleteDocument/DeleteDocument";
class OrangeDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openRegisterCustomerDialog: false,
    }

  }

  componentWillMount() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      this.props.dispatch(getOrangeCustomerDocumentsRequest(currentCustomer.guid));

    }

  }
  DocumentUpload(doc) {
    const customerGuid = this.props.customerData.currentCustomer.guid
    this.props.dispatch(addOrangeCustomerDocumentRequest({ ...doc, customerGuid }))
  }

	setDocumentToDelete(document) {
		this.props.dispatch(setOrangeCustomerDocumentsToDelete(document));
	}
  deleteDocument() {
    this.props.dispatch(deleteOrangeCustomerDocumentRequest(
      this.props.orangeDocumentsData.documentsToDelete.guid,
    ));

  }
  resetDocumentToDelete(document) {
    this.props.dispatch(resetOrangeDocumentToDelete(document))
  }
  render() {
    return (
      <>
        <DisplayDocuments
          labels={this.props.labels}
          loader={this.props.orangeDocumentsData.loader}
          documents={this.props.orangeDocumentsData.documents}

          setDocumentToDelete={this.setDocumentToDelete.bind(this)}

        />
        <UploadDocuments
          labels={this.props.labels}
          toggleOpenUploadDocumentDialog={this.props.toggleOpenUploadDocumentDialog}
          openUploadDocument={this.props.openUploadDocument}

          DocumentUpload={this.DocumentUpload.bind(this)}
          customer_guid={this.props.customerData.currentCustomer.guid}

        />
        <DeleteDocument
          labels={this.props.labels}
          DocumentToDelete={this.props.orangeDocumentsData.documentsToDelete}
          deleteDocument={this.deleteDocument.bind(this)}

          resetDocumentToDelete={this.resetDocumentToDelete.bind(this)}
          showDeleteDocumentProgressAlert={this.props.orangeDocumentsData.hardDeleteExpenseLoader}
          showDeleteDocumentSuccessAlert={this.props.orangeDocumentsData.hardDeleteDocumentSuccess}
          showDeleteDocumentErrorAlert={!!this.props.orangeDocumentsData.deleteDocumentError}
          deleteDocumentError={this.props.orangeDocumentsData.deleteClaimDocumentError}

          resetDeleteDocumentSuccessAlert={() => {
            this.props.dispatch(resetHardDeleteOrangeCustomerDocumentSuccess())
          }}
          resetDeleteDocumentErrorAlert={() => { this.props.dispatch(resetHardDeleteOrangeCustomerDocumentError()) }}
        />
      </>

    )
  }
}
export default connect((state) => ({
  customerData: state.currentCustomer,
  orangeDocumentsData: state.orangeDocumentsData
}))(OrangeDocuments);