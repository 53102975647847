import * as React from "react";
import ButtonInc from '../../../../shared-ui/ButtonInc'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@mui/material";
import AlertDialog from "../../../../components/AlertDialog/index.es6";
import ModalInc from "../../../../shared-ui/ModalInc";
import { getUserFullname, getUserGuid } from "../../../../lib/access.es6";

export default function CancelPolicy(props) {

  if (!props.policy) {
    return ""
  }

  const [reasonforcancellation, setReasonForCancellation] = React.useState('');

  const handleChange = (event) => {
    const { name, value } = event.target
    if (name === "reasonforcancellation") {
      setReasonForCancellation(value)
    }
  }
  const web_agent_guid = getUserGuid();
  const web_agent_name = getUserFullname();
  const cancelPolicy = () => {
    const note = {
      "note": reasonforcancellation,
      "policy_guid": props.policy.guid,
      "web_user": web_agent_name,
      "web_user_guid": web_agent_guid
    }
    const payload = {
      policy: props.policy,
      note: note
    }
    props.cancelPolicy(payload)

  }



  const handlePolicyCanceldSuccessfully = () => {

    props.resetCancelPolicySuccessAlert()
    props.resetPolicyToCancel()
    props.getAllPolicies()
  
  }

  const handlePolicyCancelError = () => {
    props.resetCancelPolicyErrorAlert()
  }

  return (
    <React.Fragment>
      <AlertDialog
        custom
        show={props.showCancelPolicyProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Cancelling policy...</h2>
      </AlertDialog>


      <AlertDialog
        success
        show={props.showCancelPolicySuccessAlert}
        size="sm"
        title={'Policy canceled'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handlePolicyCanceldSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'Cancel'}
        confirmBtnCssClass
      >
        <div >
          <ButtonInc variant="contained" onClick={() => { handlePolicyCanceldSuccessfully() }} style={{ background: 'green', }}>Ok</ButtonInc>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showCancelPolicyErrorAlert}
        danger
        title={'Error Cancelling policy'}
        onConfirm={() => handlePolicyCancelError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.cancelPolicyError ? props.cancelPolicyError.message : ''}
      </AlertDialog>

      <ModalInc
        modalTitle="Add reasons for cancellation"
        subTitle="Fill the below field to give reasons for cancellation"
        open={props.openPolicyCancelDialog}
        onClose={props.toggleCancelPolicyDialog}
        fullWidth
      >
        <ValidatorForm onSubmit={() => cancelPolicy()} >

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <TextValidator
                name='reasonforcancellation'
                id="reasonforcancellation"
                label="Reason for cancellation "
                value={reasonforcancellation}
                onChange={handleChange}
                multiline
                rows={5}
                maxRows={4}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter reason for cancellation']}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} md={6}>

            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    type='submit'
                    style={{ marginTop: "20px", float: "right" }}
                  >
                    Cancel Policy
                  </ButtonInc>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginTop: "20px" }}
                    onClick={props.closeCancelPolicyDialog}
                  >
                    Go back
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </ValidatorForm>

      </ModalInc>

    </React.Fragment>

  );



}
