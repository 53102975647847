import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import Tooltip from '@mui/material/Tooltip';
import SkeletonWrapper from '../../NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import { formatDateLocale } from '../../../lib/utils.es6';
import TableInc from '../../../shared-ui/TableInc';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';


export default function ViewEmailTemplates(props) {
  const classes = useStyle();

  const templates = props.templates || [];


  if (props.loading) return <SkeletonWrapper type="table" loading={true} />


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}  >
          <Grid container spacing={2} className={classes.claimdocswrapper}>
            <Grid item xs={12} sm={12} md={12}>

              <TableInc
                columns={[
                  { title: 'Name', field: 'name', render: rowData => rowData.name || "N/A" },
                  { title: 'Active	', field: 'active', render: rowData => <>
                  {
                    rowData.active === true ? "Yes" : "No"
                  }
                  </>},
                  { title: 'Template Type	', field: 'email_entity_type', render: rowData => rowData.email_entity_type },
                  { title: 'Email Type	', field: 'email_type', render: rowData => rowData.email_type },
                  { title: 'Expect A Reply	', field: 'expect_a_reply', render: rowData => <>
                  {
                    rowData.expect_a_reply === true ? "Yes" : "No"
                  }
                  </>},
                  { title: 'Recipients	', field: 'recipients', render: rowData => <>
                  
                  {
                     rowData.recipients.map((mail,index) =>  {
                      return <li key={index} style={{display: "inline-block", marginLeft: "5px", marginBottom: "5px"}}>{mail}</li>
                     }) 
                  }
                  </> },
                  { title: 'Created At', field: 'created_at', render: rowData => formatDateLocale(rowData.created_at) || "N/A" },
                  {
                    title: 'Actions', field: 'created_At', render: rowData => {
                        return <div className={classes.actionbtn}>
                            <Tooltip title={" Delete Document"} >
                              <span style={{ cursor: "pointer" }} ><DeleteOutlineOutlinedIcon sx={{ color: " #B3251E" }} /> </span>
                            </Tooltip>

                        </div>
                 
                    }
                  },
                ]}
                data={templates}
                options={{
                  pageSize: templates.length < 5
                    ? templates.length
                    : 10,
                  showTitle: false,
                }}

              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
