import * as types from '../../../../../../lib/actionTypes.es6'

export const initialState = {


  get_premiums_errors: null,
  get_premiums_loader: false,
  get_premiums_success_message: '',
  premiums: [],


};

export default function accessBankPremiumsReducer(state = initialState, action) {
  switch (action.type) {

    case types.GET_ALL_ACCESSBANK_CUSTOMER_PREMIUMS_REQUEST:
      return { ...state, get_premiums_loader: true, get_premiums_errors: null };
    case types.GET_ALL_ACCESSBANK_CUSTOMER_PREMIUMS_SUCCESS:
      return { ...state, get_premiums_loader: false, get_premiums_errors: null, premiums: action.payload };
    case types.GET_ALL_ACCESSBANK_CUSTOMER_PREMIUMS_FAILURE:
      return { ...state, get_premiums_loader: false, get_premiums_errors: action.payload };


    default:
      return state;
  }
}