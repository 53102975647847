import * as React from "react";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";

import AlertDialog from "../../../../../components/AlertDialog/index.es6";

export default function DeleteLOU(props) {

  if (!props.lou) {
    return ""
  }

  const [showWarning, setShowWarning ] = React.useState(false);


  const deleteLou = ()=>{
    setShowWarning(true) 
    props.deleteLou(props.lou)
  }

  const cancel = ()=>{
    props.resetLouToDelete()
  }


  const handleLouDeletedSuccessfully = () => {
    props.resetDeleteLouSuccessAlert()
    props.resetLouToDelete()
  }

  const handleLouDeleteError = () => {
    props.resetDeleteLouErrorAlert()
  }

  return (
    <React.Fragment>
      <AlertDialog
        custom
        show={props.showDeleteLouProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Deleting lou</h2>
      </AlertDialog>


      <AlertDialog
        warning
        show={!showWarning && props.lou}
        size="sm"
        title={'Are you sure you want to delete'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => {cancel() }}
        onCancel={() => {deleteLou()}}
        confirmBtnText={'Cancel'}
        showCancel={true}
        showConfirm={true}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
      </AlertDialog>

      <AlertDialog
        success
        show={props.showDeleteLouSuccessAlert}
        size="sm"
        title={'Lou deleted'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleLouDeletedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => { handleLouDeletedSuccessfully() }} style={{ background: 'green', }}>Ok</Button>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showDeleteLouErrorAlert}
        danger
        title={'Error deleting lou'}
        onConfirm={() => handleLouDeleteError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.editLouError ? props.editLouError.message : ''}
      </AlertDialog>






    </React.Fragment>

  );



}
