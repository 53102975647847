import * as types from '../../lib/actionTypes.es6';

export function reportClaimFinalizationReimbursementRequest(reportType) {
  return {
    type: types.REPORT_CLAIM_FINALIZATION_REIMBURSEMENT_REQUEST,
    payload: reportType,
  };
}

export function reportClaimFinalizationReimbursementRequestSuccess(reportURL) {
  return {
    type: types.REPORT_CLAIM_FINALIZATION_REIMBURSEMENT_SUCCESS,
    payload: reportURL,
  };
}

export function reportClaimFinalizationReimbursementRequestError(error) {
  return {
    type: types.REPORT_CLAIM_FINALIZATION_REIMBURSEMENT_FAILURE,
    payload: error,
  };
}

export function reportClaimFinalizationReimbursementReset() {
  return {
    type: types.REPORT_CLAIM_FINALIZATION_REIMBURSEMENT_RESET,
  };
}
