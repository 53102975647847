import * as types from '../../lib/actionTypes.es6';


export function getAllCashbacksRequest() {
    return {
      type: types.GET_ALL_CASHBACKS_REQUEST
    };
  }

  export function getAllCashbacksRequestSuccess(payload) {
    return {
      type: types.GET_ALL_CASHBACKS_SUCCESS,
      payload
    };
  }

  export function getAllCashbacksRequestFailure(payload) {
    return {
      type: types.GET_ALL_CASHBACKS_FAILURE,
      payload
    };
  }

  export function markCashbackAsPaidRequest(payload) {
    return {
      type: types.MARK_CASHBACK_AS_PAID_REQUEST,
      payload
    };
  }

  export function markCashbackAsPaidRequestSuccess(payload) {
    return {
      type: types.MARK_CASHBACK_AS_PAID_SUCCESS,
      payload
    };
  }

  export function markCashbackAsPaidRequestFailure(payload) {
    return {
      type: types.MARK_CASHBACK_AS_PAID_FAILURE,
      payload
    };
  }

  export function markCashbackAsUnpaidRequest(payload) {
    return {
      type: types.MARK_CASHBACK_AS_UNPAID_REQUEST,
      payload
    };
  }

  export function markCashbackAsUnpaidRequestSuccess(payload) {
    return {
      type: types.MARK_CASHBACK_AS_UNPAID_SUCCESS,
      payload
    };
  }

  export function markCashbackAsUnpaidRequestFailure(payload) {
    return {
      type: types.MARK_CASHBACK_AS_UNPAID_FAILURE,
      payload
    };
  }