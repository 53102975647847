import * as types from '../../../../lib/actionTypes.es6'



export function CreateDependantRequest(payload) {
  return {
    type: types.CREATE_DEPENDANT_REQUEST,
    payload,
  };
}

export function CreateDependantSuccess(payload) {
  return {
    type: types.CREATE_DEPENDANT_SUCCESS,
    payload,
  };
}

export function CreateDependantFailure(payload) {
  return {
    type: types.CREATE_DEPENDANT_FAILURE,
    payload,
  };
}

export function resetCreateDependantSuccess(error) {
  return {
    type: types.RESET_CREATE_DEPENDANT_SUCCESS,
  };
}

export function createQuoteRequest(payload) {
  return {
    type: types.CREATE_QUOTE_REQUEST,
    payload,
  };
}

export function createQuoteFailure(error) {
  return {
    type: types.CREATE_QUOTE_FAILURE,
    payload: error,
  };
}

export function createQuoteSuccess(response) {
  return {
    type: types.CREATE_QUOTE_SUCCESS,
    payload: response,
  };
}

export function resetQuoteSuccess(error) {
  return {
    type: types.RESET_CREATE_QUOTE_SUCCESS,
  };
}

export function resetQuoteError(error) {
  return {
    type: types.RESET_CREATE_QUOTE_ERROR,
  };
}



export function buyPolicyRequest(payload) {
  return {
    type: types.BUY_POLICY_REQUEST,
    payload,
  };
}


export function buyPolicyFailure(error) {
  return {
    type: types.BUY_POLICY_FAILURE,
    payload: error,
  };
}
export function buyPolicySuccess(response) {
  return {
    type: types.BUY_POLICY_SUCCESS,
    payload: response,
  };
}

export function resetBuypolicySuccess(error) {
  return {
    type: types.RESET_BUY_POLICY_SUCCESS,
  };
}

export function resetBuypolicyError(error) {
  return {
    type: types.RESET_BUY_POLICY_ERROR,
  };
}



export function changeQuoteRequest(payload) {
  return {
    type: types.CHANGE_QUOTE_REQUEST,
    payload,
  };
}


export function changeQuoteFailure(error) {
  return {
    type: types.CHANGE_QUOTE_FAILURE,
    payload: error,
  };
}
export function changeQuoteSuccess(response) {
  return {
    type: types.CHANGE_QUOTE_SUCCESS,
    payload: response,
  };
}

export function resetChangeQuoteSuccess(error) {
  return {
    type: types.RESET_CHANGE_QUOTE_SUCCESS,
  };
}

export function resetChangeQuoteError(error) {
  return {
    type: types.RESET_CHANGE_QUOTE_ERROR,
  };
}


export function setQuotationToReCreate(quote) {
  return {
    type: types.SET_QUOTATION_TO_RECREATE,
    payload: quote
  };
}