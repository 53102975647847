import * as types from '../../lib/actionTypes.es6';

export function claimsStatusRequest(status) {
  return {
    type: types.CLAIM_STATUS_REQUEST,
    payload: status,
  };
}

export function claimsStatusSuccess(payload) {
  return {
    type: types.CLAIM_STATUS_SUCCESS,
    payload,
  };
}

export function claimsStatusFail(payload) {
  return {
    type: types.CLAIM_STATUS_FAILURE,
    payload,
  };
}
