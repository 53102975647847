import * as React from "react";
import ButtonInc from '../../../../../../../shared-ui/ButtonInc'
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import { CircularProgress, MenuItem } from "@mui/material";
import AlertDialog from "../../../../../../../components/AlertDialog/index.es6";
import ModalInc from "../../../../../../../shared-ui/ModalInc";
import { getUserFullname, getUserGuid } from "../../../../../../../lib/access.es6";
import { reloadPage } from "../../../../../../../lib/utils.es6";

const PolicyNotes = (props) => {
  const [note, setNote] = React.useState('');
  const [type, setType] = React.useState('');

  const handleChange = (event) => {
    const { name, value } = event.target
    if (name === "note") {
      setNote(value)
    }
    if (name === "type") {
      setType(value)
    }
  }
  const web_agent_guid = getUserGuid();
  const web_agent_name = getUserFullname();
  const addPolicyNote = () => {
    const payload = {
      "note": note,
      "type": type,
      "policy_guid": props.policy_guid,
      "web_user": web_agent_name,
      "web_user_guid": web_agent_guid
    }
    props.handleAddPolicyNotes(payload)

  }



  const handlePolicyNotesSuccessfully = () => {
    reloadPage()
  }

  const handlePolicyNotesError = () => {
    reloadPage()
  }

  return (
    <React.Fragment>
      <AlertDialog
        custom
        show={props.showPolicyNoteProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>adding policy notes...</h2>
      </AlertDialog>


      <AlertDialog
        success
        show={props.showPolicyNoteSuccessAlert}
        size="sm"
        title={'Policy note added'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handlePolicyNotesSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'Cancel'}
        confirmBtnCssClass
      >
        <div >
          <ButtonInc variant="contained" onClick={() => { handlePolicyNotesSuccessfully() }} style={{ background: 'green', }}>Ok</ButtonInc>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showPolicyNoteErrorAlert}
        danger
        title={'Error adding  policy note'}
        onConfirm={() => handlePolicyNotesError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.PolicyNoteError ? props.PolicyNoteError.message : ''}
      </AlertDialog>


      <ModalInc
        modalTitle="Add policy note "
        subTitle="Fill the below field to add policy note"
        open={props.openPolicyNoteDialog}
        onClose={props.toggleOpenPolicyNoteDialog}
        fullWidth
      >
        <ValidatorForm onSubmit={() => addPolicyNote()} >

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <SelectValidator
                value={type}
                label='type'
                id="type"
                name='type'
                onChange={handleChange}
                className="form-control"
                type="text"
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select type']}
              >
                <MenuItem value="">{'Please select type'}</MenuItem>
                {
                  ["Genaral", "Policy Changes"].map((type) => {
                    return <MenuItem value={type}>{type}</MenuItem>
                  })
                }
              </SelectValidator>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextValidator
                name='note'
                id="note"
                label="Add Policy Note "
                value={note}
                onChange={handleChange}
                multiline
                rows={5}
                maxRows={4}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter policy note']}
                style={{marginTop: "30px"}}
              />
            </Grid>

          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} md={6}>

            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    type='submit'
                    style={{ marginTop: "20px", float: "right" }}
                  >
                    Add Note
                  </ButtonInc>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginTop: "20px" }}
                    onClick={props.toggleOpenPolicyNoteDialog}
                  >
                    Go back
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </ValidatorForm>

      </ModalInc>

    </React.Fragment>
  )
}

export default PolicyNotes