import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {CircularProgress, FormControl, InputAdornment, InputLabel, Select} from "@mui/material";
import {
  getInternationalCallingCode,
  getLocalNumberLength,
  getPartner, isSouthAfricanPartner,
  isVAlidSouthAfricanNationalId
} from "../../lib/access.es6";
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../lib/constants";
import AlertDialog from "../AlertDialog/index.es6";
import { formatDate } from "../../lib/utils.es6";
import {DatePicker} from "@material-ui/pickers";
import TextField from "@mui/material/TextField";


const initialState = {
  first_name: ' ',
  last_name: '',
  msisdn: '',
  date_of_birth: '',
  national_id: '',
  address: '',
  gender: '',
  email: ''
}
export default function AddBenefiary(props) {
  const [
    {
      first_name,
      last_name,
      msisdn,
      address,
      email,
    }, setState
  ] = React.useState(initialState);


  const [SAIdCheckError, setSAIdCheckError] = React.useState(null);
  const [dateOfBirth, setDateOfBirth] = React.useState('');
  const [gender, setGender] = React.useState('');
  const [nationalId, setNationalId] = React.useState('');

  React.useEffect(() => {
    const partner = getPartner();
    const nationalIdRegex = partner ? partner.country.national_id_regex : '';
    const pattern = new RegExp(nationalIdRegex);

    ValidatorForm.addValidationRule('isValidNationalId', value => {
      if (!isSouthAfricanPartner()) {
        return pattern.test(value);
      }

      const result = isVAlidSouthAfricanNationalId(value);
      if (result.isValid) {
        setDateOfBirth(formatDate(new Date(result.dob)));
        setGender(result.gender);
        setSAIdCheckError(result.error);

        return true;
      }

      setSAIdCheckError(result.error);
      return false;
    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidNationalId');
    };
  }, []);

  const onChange = e => {
    const {name, value} = e.target;
    setState(prevState => ({...prevState, [name]: value}));
  };

  const reset = () => {

  }

  const addBeneficiary = () => {
    const payload = {
      full_name: first_name + " " + last_name,
      first_name,
      last_name,
      msisdn,
      date_of_birth: dateOfBirth,
      national_id: nationalId,
      customer_guid: props.customerGuid,
      address,
      gender,
      email
    }
    props.addBenefiary(payload)
  }


  const handleBeneficiaryCreatedSuccessfully = () => {
    props.resetAddBeneficiaryDetailsSuccessAlert()
    props.closeAddBeneficiaryDialog()
  }

  const handleBeneficiaryCreateError = () => {
    props.resetAddBenefeciaryDetailsErrorAlert()
  }


  const onNationalIdChange = (event) => {
    const value = event.target.value;
    setNationalId(value)
    const result = isVAlidSouthAfricanNationalId(value)
    if (result.isValid) {
      setDateOfBirth(formatDate(new Date(result.dob)))
      setGender(result.gender)
      setSAIdCheckError(result.error);
      return true
    } else {
      setSAIdCheckError(true)
      return false
    }
  }


  return (
    <React.Fragment>
      <Dialog
        open={props.openAddBeneficiaryDialog}
        fullWidth
        maxWidth="lg"
        onClose={props.closeAddBeneficiaryDialog}
        fullScreen
      >
        <AppBar sx={{position: 'relative'}}>
          <Toolbar style={{background: PRIMARY_THEME_COLOR_SHADE_1}}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.closeAddBeneficiaryDialog}
              aria-label="close"
            >
              <CloseIcon/>
            </IconButton>
            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
              Add Beneficiary
            </Typography>
          </Toolbar>
        </AppBar>
        <ValidatorForm onSubmit={() => addBeneficiary()}>
          <DialogContent>
            <AlertDialog
              custom
              show={!!props.showAddBeneficiaryProgressAlert}
              size="sm"
              style={{marginTop: '0', top: '30vh'}}
              confirmBtnText="ok"
              showCancel={false}
              showConfirm={false}
              cancelBtnText="cancel"
              showTitle={false}
              confirmBtnCssClass
            >
              <CircularProgress/>
              <h2>Adding Benefiary </h2>
            </AlertDialog>

            <AlertDialog
              success
              show={!!props.showAddBeneficiarySuccessAlert}
              size="sm"
              title="Benefiary added"
              style={{marginTop: '0', top: '30vh'}}
              onConfirm={() => {
                handleBeneficiaryCreatedSuccessfully();
              }}
              confirmBtnText="ok"
              showCancel={false}
              showConfirm
              cancelBtnText="cancel"
              confirmBtnCssClass
            ></AlertDialog>

            <AlertDialog
              show={!!props.showAddBeneficiaryErrorAlert}
              danger
              title="Error adding beneficiary. You can only add one dependany "
              onConfirm={() => handleBeneficiaryCreateError()}
              confirmBtnText="ok"
              confirmBtnCssClass
              showCancel={false}
              style={{marginTop: '0', top: '30vh'}}
            >
              {props.createBeneficiaryError
                ? props.createBeneficiaryError.message
                : ''}
            </AlertDialog>

            <Grid container spacing={2}>
              <Grid item lg={3} md={6} xs={12}>
                <TextField
                  fullWidth
                  placeholder={props.labels.firstName}
                  label={props.labels.firstName}
                  name="first_name"
                  variant="outlined"
                  onChange={onChange}
                  value={first_name}
                />
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <TextValidator
                  onChange={onChange}
                  value={last_name}
                  label={props.labels.lastName}
                  name="last_name"
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder={props.labels.lastName}
                  validators={['required']}
                  errorMessages={['Please enter last name']}
                />
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <TextValidator
                  fullWidth
                  placeholder={props.labels.msisdn}
                  label={props.labels.msisdn}
                  name="msisdn"
                  variant="outlined"
                  onChange={onChange}
                  value={msisdn}
                  maxLength={getLocalNumberLength()}
                  onInput={e => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, getLocalNumberLength());
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {getInternationalCallingCode()}
                      </InputAdornment>
                    ),
                  }}
                  type="number"
                  validators={[isSouthAfricanPartner() ? 'required' : '']}
                  errorMessages={['Please enter telephone']}
                />
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <TextField
                  fullWidth
                  placeholder={props.labels.email}
                  label={props.labels.email}
                  name="email"
                  variant="outlined"
                  onChange={onChange}
                  value={email}
                  type="email"
                />
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <TextField
                  fullWidth
                  placeholder={props.labels.address}
                  label={props.labels.address}
                  name="address"
                  variant="outlined"
                  onChange={onChange}
                  value={address}
                  multiline={true}
                  rows={5}
                />
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                {isSouthAfricanPartner() ? (
                  <TextValidator
                    onChange={onNationalIdChange}
                    value={nationalId}
                    label={props.labels.nationalId}
                    name="national_id"
                    fullWidth
                    variant="outlined"
                    placeholder={props.labels.nationalId}
                    validators={['required', 'isValidNationalId']}
                    errorMessages={[
                      'Please enter national id',
                      SAIdCheckError || 'Invalid national Id',
                    ]}
                  />
                ) : (
                  <TextField
                    fullWidth
                    placeholder={props.labels.nationalId}
                    label={props.labels.nationalId}
                    name="national_id"
                    variant="outlined"
                    onChange={onNationalIdChange}
                    value={nationalId}
                  />
                )}
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <DatePicker
                  fullWidth
                  variant="outlined"
                  clearable
                  label={props.labels.dateOfBirth}
                  inputVariant="outlined"
                  value={dateOfBirth}
                  onChange={e => {
                    setDateOfBirth(e.target.value);
                  }}
                  format="YYYY-MM-DD"
                />
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>{props.labels.gender}</InputLabel>
                  <Select
                    value={gender}
                    label={props.labels.gender}
                    onChange={e => {
                      setGender(e.target.value);
                    }}
                  >
                    <MenuItem key="-1" value="">
                      {props.labels.gender}
                    </MenuItem>
                    {['Male', 'Female'].map((gender, index) => (
                      <MenuItem key={index} value={gender}>
                        {gender}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  style={{marginRight: '2%'}}
                >
                  {props.labels.save}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{marginRight: '2%'}}
                  onClick={() => reset()}
                >
                  {props.labels.reset}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </ValidatorForm>
      </Dialog>
    </React.Fragment>
  );
}
