import React from 'react';

const ScribeGuide = ({ guide }) => {

  const embedUrl = `${guide.url}?as=scrollable&skipIntro=true`

  return (
    <div style={{ margin: '0 auto', textAlign: 'center' }}>
      <iframe
        src={embedUrl}
        width="1200"
        height="1650"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen>
      </iframe>
      <p>description: {guide ? guide.description : "No description"}</p>
      <p>Author: {guide ? guide.created_by : "No author"}</p>
      <p>Updated at: {guide ? guide.updated_at : "No date"}</p>
      <p>Status: {guide ? guide.status : "No status"}</p>
    </div>
  );
};
export default ScribeGuide;
