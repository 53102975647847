import React from "react";
import { connect } from "react-redux";
import * as actions from "../../Redux/actions.js"
import { ClaimStatusEnum } from "../../enum";
import find from 'lodash/find';
import { getPartnerGuid, hasAuthourity } from "../../../../../lib/access.es6";
import ClaimsHeader from "../../../../../containers/ClaimDashboardv2/Components/ClaimsHeader/ClaimsHeader";
import ClaimsWrapper from "../ClaimsWrapper/ClaimsWrapper.js"
import { partnerHasClaimTags } from "../../../../../lib/utils.es6.js";
import { getInitialStatus } from "../../helpers.js";


class ClaimDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      size: 10,
      customer_guid: '',
      facility: '',
      facility_code: '',
      type: '',
      start_date: '',
      end_date: '',
      tag: 'reimbursement',
      status: ''
    };
  }


  componentDidMount() {
    const isFinanceUser = hasAuthourity('BULK_PAY_APPROVE_CLAIMS');
    const initialStatus =getInitialStatus(this.props.location && this.props.location.query && this.props.location.query.status);

    this.setState({
      status: isFinanceUser ? ClaimStatusEnum.Approved : initialStatus
    }, this.getClaimsFiltered); 
  }

  getClaimsByStartDate(start_date) {
    this.setState({ start_date });
  }
  getClaimsByEndDate(end_date) {
    this.setState({ end_date });
  }
  getClaimsByPage(page, callback){
    this.setState({ page }, callback);
  }
  getClaimsBySize(size, callback){
    this.setState({ size }, callback);
  }

  getClaimsByStatus(status, callback) {
    this.setState({ status }, callback);
  }

  getClaims(payload) {
    if(partnerHasClaimTags()){
      payload['tag'] ='reimbursement'
      payload['page'] = payload['page'] || 1
      payload['size'] = payload['size'] || 10
    }
    
    this.props.dispatch(actions.getClaimsDcpRequest(payload
    ))
  }

  getClaimsFiltered() {
    if (partnerHasClaimTags()) {
      this.props.dispatch(actions.getClaimsDcpFilteredRequest({
        "partner": getPartnerGuid(),
        tag: this.state.tag,
        page: this.state.page,
        size: this.state.size,
        customer_guid: this.state.customer_guid,
        facility: this.state.facility,
        facility_code: this.state.facility_code,
        type: this.state.type,
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        status: this.state.status
      }))


    }

  }

  handleBulkApproval(payload) {
    this.props.dispatch(actions.bulkApproveRequest(payload))
  }

  filterHospitalClaim(claims) {
    return claims.filter(claim => claim.preauthorization_number !== null)
  }

  isDeathClaim(claim) {
    const hasDeathBenefit = !!find(claim.benefits, { type: "DEATH BENEFIT" })
    const hasNoLou = claim.preauthorization_number === null;

    return hasDeathBenefit && hasNoLou
  }

  isReimbusermentClaim(claim) {
    const hasDeathBenefit = !!find(claim.benefits, { type: "DEATH BENEFIT" })
    const hasNoLou = claim.preauthorization_number === null;

    return !hasDeathBenefit && hasNoLou
  }

  filterDeathClaim(claims) {
    return claims.filter(claim => this.isDeathClaim(claim))
  }
  filterReimbursement(claims){
    return claims.filter(claim => this.isReimbusermentClaim(claim))
  }
  render() {
    //const claims = this.props.bulkApprovalData.claims_dcp || { data: [], page: 1, size: 6, total: 0}
    const claims = this.props.bulkApprovalData.claims_dcp_filtered || { data: [], page: 1, size: 6, total: 0 }


    if (!hasAuthourity('VIEW_CLAIM')) {
      return <p>You are unauthorized to view claims.</p>
    }

    return (
      <>
        <ClaimsHeader
          title="Reimbursement Claims Dashboard"
        />

        <ClaimsWrapper
          isFinanceUser={hasAuthourity('BULK_PAY_APPROVE_CLAIMS')}
          claimsData={claims.data}
          type="reimbursement"
          hasAlerts={true}
          hasBulkApprove={true}
          getClaims={this.getClaims.bind(this)}
          loading={this.props.bulkApprovalData.get_claims_dcp_filtered_loader}
          handleBulkApproval={this.handleBulkApproval.bind(this)}

          showBulkApproveProgressAlert={this.props.bulkApprovalData.bulkApprovalClaimLoader}
          showBulkApproveSuccessAlert={this.props.bulkApprovalData.bulkApprovalClaimSuccess}
          showBulkApproveErrorAlert={!!this.props.bulkApprovalData.bulkApprovalClaimError}
          bulkApproveError={this.props.bulkApprovalData.bulkApprovalClaimError}

          resetBulkApproveSuccessAlert={() => { this.props.dispatch(actions.resetbulkApprovalSuccess()) }}
          resetBulkApproveErrorAlert={() => { this.props.dispatch(actions.resetbulkApprovalFailure()) }}
          pagingData={{  data: claims.data, page: claims.page, size: claims.size, total: claims.total }}
          queryParamsStatus={this.props.location.query.status}

          getClaimsByStartDate={this.getClaimsByStartDate.bind(this)}
          getClaimsByEndDate={this.getClaimsByEndDate.bind(this)}
          getClaimsFiltered={this.getClaimsFiltered.bind(this)}
          getClaimsByStatus={this.getClaimsByStatus.bind(this)}
          getClaimsByPage={this.getClaimsByPage.bind(this)}
          getClaimsBySize={this.getClaimsBySize.bind(this)}
          filteredClaims={this.props.bulkApprovalData.claims_dcp_filtered}
        />
      </>
    )

  }

}
export default connect((state) => ({
  customerData: state.currentCustomer,
  claimDashboardData: state.claimDashboardData,
  bulkApprovalData: state.bulkApprovalData,
  globalData: state.global,
}))(ClaimDashboard);