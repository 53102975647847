import * as types from "../../lib/actionTypes.es6";


//get customer beneficiary details
export function getCustomerBeneficiaryBankDetailsRequest(payload) {
  return {
    type: types.GET_CUSTOMER_BENEFICIARY_BANK_DETAILS_REQUEST,
    payload,
  };
}


export function getCustomerBeneficiaryBankDetailsSuccess(response) {
  return {
    type: types.GET_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS,
    payload: response,
  };
}

export function getCustomerBeneficiaryBankDetailsFailed(error) {
  return {
    type: types.GET_CUSTOMER_BENEFICIARY_BANK_DETAILS_FAILURE,
    payload: error,
  };
}


//add customer beneficiary bank details

export function addCustomerBeneficiaryBankDetailsRequest(payload) {
  return {
    type: types.ADD_CUSTOMER_BENEFICIARY_BANK_DETAILS_REQUEST,
    payload,
  };
}

export function addCustomerBeneficiaryBankDetailsSuccess(response) {
  return {
    type: types.ADD_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS,
    payload: response,
  };
}

export function addCustomerBeneficiaryBankDetailsFailed(error) {
  return {
    type: types.ADD_CUSTOMER_BENEFICIARY_BANK_DETAILS_FAILURE,
    payload: error,
  };
}



//reset add customer detail

export function resetAddCustomerBeneficiaryBankDetailsSuccess() {
  return {
    type: types.RESET_ADD_BENEFICIARY_BANK_DETAILS_SUCCESS,
  };
}

export function resetAddCustomerBeneficiaryBankDetailsFailed() {
  return {
    type: types.RESET_ADD_BENEFICIARY_BANK_DETAILS_ERROR,
  };
}


export function updateCustomerBeneficiaryBankDetailsRequest(payload) {
  return {
    type: types.UPDATE_CUSTOMER_BENEFICIARY_BANK_DETAILS_REQUEST,
    payload,
  };
}

export function updateCustomerBeneficiaryBankDetailsSuccess(response) {
  return {
    type: types.UPDATE_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS,
    payload: response,
  };
}

export function updateCustomerBeneficiaryBankDetailsFailed(error) {
  return {
    type: types.UPDATE_CUSTOMER_BENEFICIARY_BANK_DETAILS_FAILURE,
    payload: error,
  };
}

export function deleteCustomerBeneficiaryBankDetailsRequest(payload) {
  return {
    type: types.DELETE_CUSTOMER_BENEFICIARY_BANK_DETAILS_REQUEST,
    payload,
  };
}

export function deleteCustomerBeneficiaryBankDetailsSuccess(response) {
  return {
    type: types.DELETE_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS,
    payload: response,
  };
}

export function deleteCustomerBeneficiaryBankDetailsFailed(error) {
  return {
    type: types.DELETE_CUSTOMER_BENEFICIARY_BANK_DETAILS_FAILURE,
    payload: error,
  };
}




// reset error and success on deleting customer bank details

export function resetDeleteCustomerBeneficiaryBankDetailsSuccess() {
  return {
    type: types.RESET_DELETE_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS,
  };
}

export function resetDeleteCustomerBeneficiaryBankDetailsFailed() {
  return {
    type: types.RESET_DELETE_CUSTOMER_BENEFICIARY_BANK_DETAILS_ERROR,
  };
}

// export function resetEditCustomerBeneficiaryBankDetailsSuccess() {
//   return {
//     type: types.RESET_EDIT_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS,
//   };
// }

// export function resetEditCustomerBeneficiaryBankDEtailsFailed() {
//   return {
//     type: types.RESET_EDIT_CUSTOMER_BENEFICIARY_BANK_DETAILS_ERROR,
//   };
// }

export function resetEditCustomerBankDetailsSuccess() {
  return {
    type: types.RESET_EDIT_CUSTOMER_BANK_DETAILS_SUCCESS,
  };
}

export function resetEditCustomerBankDEtailsFailed() {
  return {
    type: types.RESET_EDIT_CUSTOMER_BANK_DETAILS_ERROR,
  };
}

export function setCustomerBeneficiaryBankDetailsToEditSuccess(bankdetails) {
  return {
    type: types.SET_CUSTOMER_BENEFICIARY_BANK_DETAILS_TO_EDIT,
    payload: bankdetails,
  };
}

export function setCustomerBeneficiaryBankDetailsToEdit(bankdetails) {
  return {
    type: types.SET_CUSTOMER_BENEFICIARY_BANK_DETAILS_TO_EDIT,
    payload: bankdetails,
  };
}


export function resetCustomerBeneficiaryBankDetailsToEdit() {
  return {
    type: types.RESET_CUSTOMER_BENEFICIARY_BANK_DETAILS_TO_EDIT,
  };
}


//setting details to delete
// set customer bank details to delete

export function setCustomerBeneficiaryBankDetailsToDelete(bankdetails) {
  return {
    type: types.SET_CUSTOMER_BENEFICIARY_BANK_DETAILS_TO_DELETE,
    payload: bankdetails,
  };
}

export function resetCustomerBeneficiaryBankDetailsToDelete() {
  return {
    type: types.RESET_CUSTOMER_BENEFICIARY_BANK_DETAILS_TO_DELETE,
  };
}


export function setCustomerBeneficiaryBankDetailsToDeleteFailed(dependant) {
  return {
    type: types.SET_CUSTOMER_BENEFICIARY_BANK_DETAILS_TO_DELETE,
  };
}




