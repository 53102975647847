import React from 'react'
import PoliciesCardInc from "../../../../../../../shared-ui/PoliciesCardInc"
const Policies = (props) => {
  return (
    <>

      <PoliciesCardInc
        data={props.policies}
        loader={props.loader}
      />
    </>
  )



}

export default Policies