import * as types from '../../../../../../lib/actionTypes.es6'


export function getAccessBankCustomerClaimsRequest(customerGuid){
  return{
    type: types.GET_ACCESSBANK_CUSTOMER_CLAIMS_REQUEST,
    payload: customerGuid
  }
}


export const getAccessBankCustomerClaimsSuccess = (payload) =>{
  return{
    type: types.GET_ACCESSBANK_CUSTOMER_CLAIMS_SUCCESS,
    payload: payload,
  }
}

export const getAccessBankCustomerClaimsFailure = (error) =>{
  return{
    type: types.GET_ACCESSBANK_CUSTOMER_CLAIMS_FAILURE,
    payload: error,
  }
}