import React from 'react';
import { CircularProgress, Box } from '@mui/material';

const LoadingComponent = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress size={60} />
    </Box>
  );
};

export default LoadingComponent;
