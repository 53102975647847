import React from 'react';
import { hashHistory } from 'react-router';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { deepOrange } from '@material-ui/core/colors';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';

import * as utils from '../../lib/utils.es6';
import MainPageTitle from '../MainPageTitle/index.es6';
import { store } from '../../store.es6';
import { selectClaim } from '../../containers/ClaimsProcessing/actions.es6';
import { claimsStatusRequest } from '../../containers/ClaimsStatus/actions.es6';
import { dateTimeFormat } from '../../lib/access.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { localisedText } from '../../lib/localisation.es6';
import { Button } from '@mui/material';
import { SECONDARY_THEME_COLOR_SHADE_1 } from '../../lib/constants';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  tablecell: {
    padding: '0px',
  },
});

export class CustomersClaims extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
      order: 'asc',
      orderBy: 'created_at',
    };
  }

  componentWillMount() {
    this.updateLanguage();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'CustomersClaims',
      this.state.labels
    );
  }

  viewClaim(claim) {
    store.dispatch(selectClaim(claim));
    hashHistory.push(`admin/claims_processing/${claim.guid}`);
  }

  viewClaimStatus(status) {
    store.dispatch(claimsStatusRequest(status));
    hashHistory.push('admin/claims_status');
  }

  undefinedTimeString() {
    return 'n/a';
  }

  thresholdHours() {
    // TODO: Read this from the API
    return 48;
  }

  formatTime(hours) {
    const labels = this.state.labels;
    if (!hours) {
      return this.undefinedTimeString();
    }
    const remainingDays = Math.floor(hours / 24);
    const remainingHours = hours % 24;

    if (remainingDays <= 0) {
      return `${remainingHours} ${labels.hours}`;
    }
    if (remainingHours === 0) {
      return `${remainingDays} ${labels.days} `;
    }

    return `${remainingDays} ${labels.days} ${remainingHours} ${labels.hours}`;
  }

  creationTimeHeader() {
    const labels = this.state.labels;
    const creationTimeStyle = {
      display: this.creationTimeVisible() ? '' : 'none',
      color: 'white !important'
    };
    return (
      <TableCell style={creationTimeStyle}>
        {labels.timeSinceCreation}
      </TableCell>
    );
  }

  creationTimeRow(claim) {
    // TODO: Use a css class instead and/or classNames
    const creationTimeStyle = {
      display: this.creationTimeVisible() ? '' : 'none',
      backgroundColor: this.warningThreshold(claim, 'created_at')
        ? '#ff9999'
        : '',
    };
    return (
      <TableCell style={creationTimeStyle}>
        {this.timeSince(claim, 'created_at')}
      </TableCell>
    );
  }

  creationTimeVisible() {
    return ['All_Docs_Pending', 'Docs_Partially_Submitted'].includes(
      this.props.status
    );
  }

  docReceivedTimeHeader() {
    const labels = this.state.labels;
    const docReceivedTimeStyle = {
      display: this.docReceivedTimeVisible() ? '' : 'none',
    };
    return (
      <TableCell className="table-cell-header" style={docReceivedTimeStyle}>
        {labels.timeSinceDocReceived}
      </TableCell>
    );
  }

  docReceivedTimeRow(claim) {
    // TODO: Use a css class instead and/or classNames
    const docReceivedTimeStyle = {
      display: this.docReceivedTimeVisible() ? '' : 'none',
      backgroundColor: this.warningThreshold(
        claim,
        'documentation_received_time'
      )
        ? '#ff9999'
        : '',
    };
    return (
      <TableCell style={docReceivedTimeStyle}>
        {this.timeSince(claim, 'documentation_received_time')}
      </TableCell>
    );
  }

  docReceivedTimeVisible() {
    return [
      'Decision_Pending',
      'Rejected_Pending',
      'Approved_Pending',
      'Approved',
    ].includes(this.props.status);
  }

  timeSince(claim, field) {
    const DATE_FORMAT = dateTimeFormat();
    const timeFrom = claim[field];
    if (!timeFrom) {
      return this.undefinedTimeString();
    }
    const docReceivedOn = moment(timeFrom, DATE_FORMAT, true);
    const now = moment();
    const differenceHours = now.diff(docReceivedOn, 'hours');
    return this.formatTime(differenceHours);
  }

  // TODO: Reduce duplication with previous method
  timeSinceHours(claim, field) {
    const DATE_FORMAT = dateTimeFormat();
    const timeFrom = claim[field];
    if (!timeFrom) {
      return 0; // NOTE: Assuming the threshold is never 0 or negative
    }
    const docReceivedOn = moment(timeFrom, DATE_FORMAT, true);
    const now = moment.utc();
    return now.diff(docReceivedOn, 'hours');
  }

  warningThreshold(claim, field) {
    const timeSinceHours = this.timeSinceHours(claim, field);
    return timeSinceHours > this.thresholdHours();
  }

  getStripedStyle(index) {
    return { background: index % 2 ? 'white' : '#f2f2f2' };
  }

  handleRequestSort(property) {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  }

  getSorting(order, orderBy) {
    return order === 'desc'
      ? function (a, b) {
        if (a[orderBy] > b[orderBy]) {
          return 1;
        }
        if (a[orderBy] === b[orderBy]) {
          return 0;
        }
        return -1;
      }
      : function (a, b) {
        if (a[orderBy] > b[orderBy]) {
          return -1;
        }
        if (a[orderBy] === b[orderBy]) {
          return 0;
        }
        return 1;
      };
  }

  render() {
    const claims = this.props.claims ? this.props.claims : [];
    const { order, orderBy } = this.state;
    const labels = this.state.labels;

    const statusColumn = {
      display: this.props.showMore ? 'none' : '',
    };

    const creationTimeStyle = {
      display: this.creationTimeVisible() ? '' : 'none'
    };

    const docReceivedTimeStyle = {
      display: this.docReceivedTimeVisible() ? '' : 'none',
    };

    const columnData = [
      {
        id: 'customer_full_name',
        align: 'left',
        disablePadding: false,
        label: labels.customer,
      },
      {
        id: 'created_at_time',
        align: 'left',
        disablePadding: false,
        label: labels.createdAt,
        style: statusColumn,
      },
      {
        id: 'customer_msisdn',
        align: 'left',
        disablePadding: false,
        label: labels.msisdn,
      },
      {
        id: 'guid',
        align: 'left',
        disablePadding: false,
        label: `${labels.claim} #`,
      },
      {
        id: 'status',
        align: 'left',
        disablePadding: false,
        label: labels.status,
        style: statusColumn,
      },
      {
        id: 'time_since_creation',
        align: 'left',
        disablePadding: false,
        label: labels.timeSinceCreation,
        style: creationTimeStyle,
      },
      {
        id: 'documentation_received_time',
        align: 'left',
        disablePadding: false,
        label: labels.timeSinceDocReceived,
        style: docReceivedTimeStyle,
      },
    ];

    const claimsData = claims
      .sort(this.getSorting(order, orderBy))
      .map((claim, index) => (
        <TableRow
          key={claim.guid}
        >
          <TableCell>
            {claim.customer_full_name}
          </TableCell>
          <TableCell style={statusColumn}>
            {claim.created_at}
          </TableCell>
          <TableCell>
            {claim.customer_msisdn}
          </TableCell>
          <TableCell>{claim.guid}</TableCell>
          <TableCell style={statusColumn}>
            {utils.formatStatus(claim.status)}
          </TableCell>
          {this.creationTimeRow(claim)}
          {this.docReceivedTimeRow(claim)}
          <TableCell>
            <Button
              variant='contained'
              style={{ backgroundColor: deepOrange[400], color: '#FFF', margin: '3%' }}
              onClick={this.viewClaim.bind(this, claim)}
            >
              {labels.view}
            </Button>
          </TableCell>
        </TableRow>
      ));

    return (
      <div className={`${this.props.columnWidth} col-sm-6 col-xs-12`}>
        {(this.props.title ? <MainPageTitle pageTitle={utils.formatStatus(this.props.title)} /> : '')}
        <div style={{ minHeight: '355px' }}>
          <div>
            <h2>{this.props.subtitle}</h2>
            <div className="clearfix" />
          </div>
          {claims.length === 0 ? (
            <center>{labels.noClaimText}</center>
          ) : (
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {columnData.map((column, index) => (
                        <TableCell
                          key={index}
                          align={column.align}
                          padding={column.disablePadding ? 'none' : 'default'}
                          sortDirection={orderBy === column.id ? order : false}
                          style={column.style}
                          className="table-cell-header"
                        >
                          <Tooltip
                            title="Sort"
                            placement={
                              (column.align === 'right') ? 'bottom-end' : 'bottom-start'
                            }
                            enterDelay={300}
                          >
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={order}
                              onClick={this.handleRequestSort.bind(
                                this,
                                column.id
                              )}
                            >
                              {column.label}
                            </TableSortLabel>
                          </Tooltip>
                        </TableCell>
                      ))}
                      <TableCell className="table-cell-header">
                        {labels.action}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.showMore ? claimsData.slice(0, 3) : claimsData}
                  </TableBody>
                </Table>
              </TableContainer>
          )}
          <br />
          <Button
            style={{
              display: utils.displayValue(
                this.props.showMore && claims.length !== 0
              ),
              backgroundColor: SECONDARY_THEME_COLOR_SHADE_1
            }}
            onClick={this.viewClaimStatus.bind(this, this.props.status)}
            variant="contained"
            size="small"
          >
            {labels.moreSameClaims}{' '}
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(state => ({ globalData: state.global }))(
  withStyles(styles)(CustomersClaims)
);
