import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  claimCallbackList: [],
  loader: false,

  closedClaimCallbacksErrors: null,
  closedClaimCallbacks: [],
  closedClaimCallbacksLoader: false,

  deleteCallbackLoader: false,
  deleteCallbackError: null,
  deleteCallbackSuccess: null,
};

export default function claimCallbackReducer(state = initialState, action) {
  switch (action.type) {
    case types.CLAIM_CALLBACK_REQUEST:
      return { ...state, loader: true };

    case types.CLAIM_CALLBACK_SUCCESS:
      return { ...state, claimCallbackList: action.payload, loader: false };

    case types.CLAIM_CALLBACK_FAILURE:
      return {
        ...state,
        loader: false,
        errors: action.payload,
        claimCallbackList: null,
      };

    case types.GET_CLAIM_CALLBACKS_CLOSED_REQUEST:
      return { ...state, closedClaimCallbacksLoader: true };

    case types.GET_CLAIM_CALLBACKS_CLOSED_SUCCESS:
      const parsedReason = parseReason(action.payload.data);
      const data = { ...action.payload, data: parsedReason};
      return { ...state, closedClaimCallbacks: data, closedClaimCallbacksLoader: false };

    case types.GET_CLAIM_CALLBACKS_CLOSED_FAILURE:
      return {
        ...state,
        closedClaimCallbacksLoader: false,
        closedClaimCallbacksErrors: action.payload,
        closedClaimCallbacks: null,
      };

    case types.CLAIM_CALLBACK_DELETE_REQUEST:
      return { ...state, deleteCallbackLoader: true };

    case types.CLAIM_CALLBACK_DELETE_SUCCESS:
      return { ...state, deleteCallbackSuccess: action.payload, deleteCallbackLoader: false };

    case types.CLAIM_CALLBACK_DELETE_FAILURE:
      return {
        ...state,
        deleteCallbackLoader: false,
        deleteCallbackError: action.payload,
        deleteCallbackSuccess: null,
      };

    case types.RESET_CLAIM_CALLBACK_DELETE:
      return {
        ...state,
        deleteCallbackLoader: false,
        deleteCallbackError: null,
        deleteCallbackSuccess: null,
      };
  
    case types.USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
}



function parseReason(claims) {
  return claims.map(c => {
    try {
      const parsedReason = JSON.parse(c.reason);
      return { ...c, reason: parsedReason };
    } catch (e) {
      const defaultReason = { closed_by: 'N/A', reason: 'N/A' };
      return { ...c, reason: defaultReason };
    }
  });
}

