import React from 'react'
import { Box, Grid } from '@mui/material';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { useStyle } from "./Style";
import SkeletonWrapper from '../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper'
import { getPartner } from '../../../../../lib/access.es6';
import { formatDateLocale } from '../../../../../lib/utils.es6';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ViewDocument from '../../../../../shared-ui/ViewDocument/ViewDocument';

const PaymentDetails = (props) => {

  const [viewDocumentUrl, setViewDocumentUrl] = React.useState(null);
  const [numPages, setNumPages] = React.useState(null);

  const classes = useStyle();


  const currencyCode = getPartner().country.currency_code


  if (props.loader) {
    return <SkeletonWrapper loading={props.loader} />

  }

  return (
    <>
      <Grid container className={classes.policyWrapper}  >
        <Grid item xs={12} sm={12} md={12}>
          <Grid container className={classes.policyHeader}>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container>
                <Grid item xs={12} md={2} sm={2} className={classes.policyIconWrapper}   >
                  <Box className={classes.policyIcons}>
                    <NoteAddOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={10} sm={10} className={classes.policyHeaderContent}>

                  <p>Amount</p>
                  <h6>{`${currencyCode} ${props.payment.amount_in_cents / 100}` || "0.00"}   </h6>

                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} >

              <Grid container>
                <Grid item xs={12} md={6} sm={6}>
                  <div >

                  </div>
                </Grid>
                <Grid item xs={12} md={6} sm={6} className={classes.policyActiveStatusWrapper}>
                  <Box display="grid" justifyContent="flex-end">
                    <p> Processing Status</p>
                    <h6>{props.payment.processing_status || "N/A"}</h6>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.policyHeaderBorder}></div>
          </Grid>

          <Grid container spacing={2} className={classes.policyContentWrapper}>
            <Grid item xs={12} sm={3} md={3} >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3}></Grid>
                <Grid item xs={12} sm={9} md={9}>
                  <p>Payment Reference </p>
                  <h6> {props.payment.mno_reference || "N/A"}</h6>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <p> Created At </p>
              <h6>{formatDateLocale(props.payment.created_at) || "N/A"}
              </h6>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <p>Effected At </p>
              <h6>{formatDateLocale(props.payment.effected_at) || "N/A"}</h6>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <p>Proof Of Payment </p>
              <h6>
                {
                  props.payment.proof_of_payment_url ? (<>
                    <Tooltip title={"View Document"}>
                      <span style={{ cursor: "pointer" }} onClick={() => setViewDocumentUrl(props.payment.proof_of_payment_url)}><VisibilityIcon sx={{ color: " #4B7E34" }} /></span>
                    </Tooltip>
                    <Tooltip title="Download Document">
                      <span style={{ cursor: "pointer", marginLeft: "10px" }} ><a href={props.payment.proof_of_payment_url} download target='_blank' rel="noopener noreferrer" ><FileDownloadIcon sx={{ color: " #000" }} /></a> </span>
                    </Tooltip>
                  </>) : "N/A"
                }</h6>
            </Grid>

          </Grid>
          <div className={classes.policyContentBorder}></div>
          <Grid container spacing={2} className={classes.policyFooterWrapper}>
            <Grid item xs={12} md={6} sm={6}>
              <Grid container spacing={2} >


                <Grid item xs={12} md={6} sm={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} md={3}></Grid>
                    <Grid item xs={12} sm={9} md={9}>
                      <p>Payment Failure Reason</p>
                      <h6>{props.payment.payment_failure_reason || "N/A"}</h6>

                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <p> Message</p>
                  <h6>{props.payment.message || "N/A"}</h6>
                </Grid>

              </Grid>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <div className={classes.policyBalance}>

                <h6>{props.payment.channel || "N/A"}</h6>
                <p> channel</p>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ViewDocument
        title="Proof Of Payment Document"
        open={viewDocumentUrl}
        close={() => setViewDocumentUrl(null)}
        viewDocumentUrl={viewDocumentUrl}
        numPages={numPages}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      />
    </>
  )


}

export default PaymentDetails