import React, { useState } from 'react'
import { useStyle } from "./Style";
import ModalInc from '../../../../../../../shared-ui/ModalInc'
import ButtonInc from '../../../../../../../shared-ui/ButtonInc'
import {  SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import DropzoneS3Uploader from "react-dropzone-s3-uploader";
import { getS3BucketSignedUrlForCustomerDocuments } from '../../../../../../../lib/requests.es6';
import ProgressState from './ProgressState';
import MenuItem from '@mui/material/MenuItem';
const fileTypeEnums = [
  'BirthCertificate',
  'NationalIdentity',
  "Other",
]
const UploadDocuments = (props) => {
  const classes = useStyle();

  const [docType, setDocType] = useState('');
  const [docTypeError, setDocTypeError] = useState(false)
  const [s3Url] = useState('https://customers-document-received.s3.amazonaws.com');
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null)

  const handleFinishedUpload = (info) => {

    const payload = {
      customer_guid: props.customer_guid,
      type: docType,
      filename: docType,
      url: info.fileUrl.replace('undefined', info.bucketKey),
    }
    props.DocumentUpload(payload);
  }

  const getS3Ur = (file, callback) => getS3BucketSignedUrlForCustomerDocuments(props.customer_guid, file, callback)

  const uploadOptions = {
    getSignedUrl: getS3Ur,
    s3Url: s3Url,
    uploadRequestHeaders: {},
    onProgress: (progress) => setProgress(progress),
    onError: (errorMessage) => setErrorMessage(errorMessage),

  };

  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === "docsdescription") {
      setDocType(value);
    }

  }
  const reset = () => {
    if(docType === ""){
      setDocTypeError(true)
    }else{
      setDocType('');
      setProgress(0)
      window.location.reload();
    }

  }
  return (
    <>
        <>
      <ModalInc
        modalTitle={props.labels.UploadDocuments}
        subTitle={props.labels.uploadDocumentSubheading}
        open={props.openUploadDocument}
        onClose={props.toggleOpenUploadDocumentDialog}
        fullWidth
      >
        <ValidatorForm onSubmit={() => { }} >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              {/* <TextValidator
                name='docsdescription'
                id="docsdescription"
                label="Document Description "
                value={docDescription}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter Document Description']}
              /> */}

              <SelectValidator
                labelId="docType"
                id="docType"
                value={docType}
                name='docsdescription'
                type="text"
                label={props.labels.DocumentType}
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select gender']}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >
                {
                  fileTypeEnums.map((type, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }} value={type}>{type}</MenuItem>))
                }

              </SelectValidator>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.uploadsection}>
                {docTypeError && <p style={{ color: 'red' }}>{props.labels.docTypeError}</p>}
                <DropzoneS3Uploader
                  onFinish={handleFinishedUpload}
                  upload={uploadOptions}
                  s3Url={s3Url}
                  passChildrenProps={false}
                  maxSize={1024 * 1024 * 5}
                  className={classes.uploadzone}
                  {...(!setDocType && { onDrop: () => setDocTypeError('please add document type before uploading') })}
                  style={{
                    width: "100%",
                    height: "250px",
                    background: "#FFFFFF",
                    border: "1px dashed #B8B8B8",
                    marginTop: "40px",
                    padding: " 70px 0"
                  }}
                >
                  <div >
                    <ProgressState labels={props.labels} progress={progress} errorMessage={errorMessage} />

                  </div>
                </DropzoneS3Uploader>
              </div>
            </Grid>

          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'center' }}>
                  <ButtonInc
                    size="large"
                    onClick={reset}
                    variant="contained"
                    style={{ width: "221px", height: "55px", background: "#FBA92D", marginTop: '3%' }}
                  > {props.labels.ImDone}</ButtonInc>
                </Grid>
                <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'center' }}>
                  <ButtonInc
                    size="large"
                    onClick={props.toggleOpenUploadDocumentDialog}
                    type="submit"
                    variant="contained"
                    style={{ width: "221px", height: "55px", background: "#FBA92D", marginTop: '3%' }}
                  > {props.labels.cancel}</ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </ModalInc>
    </>
    
    </>
  )
}

export default UploadDocuments