import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
    policyheader: {
        background: "#FFFFFF",
        borderRadius: "8px",
    },
    policytitle: {
        marginLeft: "20px",
        "& h1": {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.0015em",
            color: "#000000"
        }
    },
    registerbtn: {
        textAlign: "right",
        marginRight: "30px",
        marginTop: "4px",
        "& button": {
            background: "#FBA92D",
            borderRadius: "8px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            boxShadow: "none",
            padding: "10px",
            textTransform: "capitalize",
            "&:hover": {
                background: "#173E4B",
                boxShadow: "none",
            }
        }
    },
    registermodaltitle: {
        background: "#FBA92D",
        textAlign: "center",
        padding: "10px !important",
        "& h3": {
            color: "#fff",
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "24px",
        }
    },
    registerinputwrapper: {
        marginTop: "30px",
        "& p": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
            textAlign: "center",
            marginBottom: "30px"
        },
        "& input": {
            background: "#F7F7F7",
            borderRadius: "8px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.005em",
            color: "#000000",
        },
        "& label": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "12px",
            letterSpacing: "0.005em",
            color: "#000000",

        }
    },
    registerinputs:{
        "& .MuiOutlinedInput-root":{
                borderColor: "#000fff"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#C52328",
            borderWidth: "2px"
        }
    },
    registerbtns: {
        marginBottom: "20px",

        "& button": {
            background: "#FBA92D",
            borderRadius: "8px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            boxShadow: "none",
            padding: "10px",
            marginRight: "20px",
            textTransform: "capitalize",
            "&:hover": {
                background: "#173E4B",
                boxShadow: "none",
            }
        }
    }
}));