import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  debitOrder: null,
  loader: false,

  banksErrors: null,
  banks: [],
  banksLoader: false,
};

export default function createDebitReducer(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_DEBIT_ORDER_REQUEST:
      return { ...state, loader: true, errors: null, debitOrder: null };

    case types.CREATE_DEBIT_ORDER_SUCCESS:
      return { ...state, debitOrder: action.payload, loader: false };

    case types.CREATE_DEBIT_ORDER_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    case types.RESET_CREATE_DEBIT_ORDER:
      return initialState;


    case types.GET_ALL_BANKS_REQUEST:
      return { ...state, banksLoader: true, banksErrors: null, debitOrder: null };

    case types.GET_ALL_BANKS_SUCCESS:
      return { ...state, banks: action.payload, banksLoader: false };

    case types.GET_ALL_BANKS_FAILURE:
      return { ...state, banksLoader: false, banksErrors: action.payload };
    default:
      return state;
  }
}
