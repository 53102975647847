import { take, call, put } from 'redux-saga/effects';
import { renewLoyalty } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import * as types from '../../lib/actionTypes.es6';
import { GAloggerException } from '../../lib/monitoring.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';

export function* loyaltyRenewalRequestWatcher() {
  for (;;) {
    // TODO: There should be no need of an infinite loop here. We can listen for a specific action
    yield take(types.LOYALTY_RENEWAL_REQUEST);
    try {
      const response = yield call(renewLoyalty);
      yield put(actions.loyaltyRenewalSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.loyaltyRenewalFailure(errorPayload));
    }
  }
}
