import React, { useState, useEffect } from 'react'
import moment from 'moment';
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

const HospitalClaimDetails = (props) => {
  const classes = useStyle();

  const [admissionDate, setAdmissionDate] = useState('');
  const [dischargeDate, setDischargeDate] = useState('');
  const [numberOfDaysInHospital, setNumberOfDaysInHospital] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "admissionDate") {
      setAdmissionDate(value)
      props.setAdditionalState("admission_date", value)
    }
    if (name === "dischargeDate") {
      setDischargeDate(value)
      props.setAdditionalState("discharge_date", value)
      props.setAdditionalState("installment_amount", props.eventData.additional_details.installment_amount)
    }

  }

  useEffect(() => {
    if (dischargeDate && admissionDate) {
      const days = daysInHospital(dischargeDate, admissionDate);
      setNumberOfDaysInHospital(days);
      props.setAdditionalState("number_of_days_in_hospital", days)
    }
  }, [dischargeDate, admissionDate]);

  const daysInHospital = (dischargeDate, admissionDate) =>{

    return moment(dischargeDate).diff(moment(admissionDate), 'days');

  }




  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.headingclaim}>
            <h4>Admission details</h4>
          </div>
        </Grid>
      </Grid>
      <ValidatorForm onSubmit={() => { }}>
        <Grid container spacing={2} className={classes.selectpolicyWrapper}>

          <Grid item xs={12} md={3} sm={3}>
            <TextValidator
              id="admissionDate"
              label="Admission Date"
              name='admissionDate'
              value={admissionDate}
              onChange={handleChange}
              fullWidth
              type='date'
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              validators={['required']}
              errorMessages={['Please enter admission date']}
              style={{ width: "100%" }}
            />
          </Grid>



          <Grid item xs={12} md={3} sm={3}>
            <TextValidator
              id="dischargeDate"
              label="Discharge Date "
              name='dischargeDate'
              value={dischargeDate}
              onChange={handleChange}
              fullWidth
              type='date'
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              style={{ width: "100%" }}
            />
          </Grid>

          <Grid item xs={12} md={3} sm={3}>
            <TextValidator
              id="numberOfDaysInHospital"
              style={{ width: "100%" }}
              label="Number Of Days In Hospital"
              name='numberOfDaysInHospital'
              value={numberOfDaysInHospital}
              onChange={handleChange}
              variant="outlined"
              validators={['required', 'minNumber:3', 'maxNumber:28']}
              errorMessages={['This field is required', 'number of days in hospital must be 3 to 28', 'number of days in hospital must be 3 to 28']}
            />
          </Grid>

          <Grid item xs={12} sm={3} md={3}>
            <TextValidator
              id="installment_amount"
              style={{ width: "100%" }}
              label="installment_amount"
              name='installment_amount'
              disabled
              fullWidth
              value={props.eventData.additional_details.installment_amount}
              variant="outlined"
            />
          </Grid>


        </Grid>

      </ValidatorForm>
    </>
  )
}

export default HospitalClaimDetails
