import React from 'react';
import ApprovedPending from './components/ApprovedPending';
import Approved from './components/Approved';
import ApprovedPaid from './components/ApprovedPaid';
import DecisionPending from './components/DecisionPending';
import ExGratiaPending from './components/ExGratiaPending';
import Rejected from './components/Rejected';
import RejectedPending from './components/RejectedPending';
import PaymentInReview from './components/PaymentInReview';
import Expired from './components/Expired';
import NotValid from './components/NotValid';

const ActionButtons = ({ claim, onStatusChange, labels, rejectionReasons }) => {
  const renderActionButtons = () => {
      switch (claim.status) {
      
      case 'Decision_Pending':
        return <DecisionPending claim={claim} onStatusChange={onStatusChange} labels={labels} rejectionReasons={rejectionReasons} />;
      
      case 'Approved_Pending':
        return <ApprovedPending claim={claim} onStatusChange={onStatusChange} labels={labels} />;
      
      case 'Approved':
        return <Approved claim={claim} onStatusChange={onStatusChange} labels={labels} />;
      
      case 'Approved_Paid':
        return <ApprovedPaid claim={claim} onStatusChange={onStatusChange} labels={labels} />;
      
      case 'Rejected_Pending':
        return <RejectedPending claim={claim} onStatusChange={onStatusChange} labels={labels} />;
      
      case 'Rejected':
        return <Rejected claim={claim} onStatusChange={onStatusChange} labels={labels} />;
      
      case 'ExGratia_Pending':
        return <ExGratiaPending claim={claim} onStatusChange={onStatusChange} labels={labels} />;
      
      case 'Payment_In_Review':
        return <PaymentInReview claim={claim} labels={labels} />;
      
      case 'Expired':
        return <Expired claim={claim} onStatusChange={onStatusChange} labels={labels} />;
      
      case 'Not_Valid':
        return <NotValid claim={claim} labels={labels} />;
      
      default:
        return null;
    }
  };

  return (
    <div className="claim-action-buttons">
      {renderActionButtons()}
    </div>
  );
};

export default ActionButtons; 