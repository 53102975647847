import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  loader: false,
  get_sms_templates_error: null,
  sms_templates: null,
  count: 0,

  supported_parameters: null,
  supported_parameters_errors: null,

  successfully_created_sms_template: null,
  failed_to_create_sms_template: null,

  successfully_updated_sms_template: null,
  failed_to_update_sms_template: null,

  successfully_deleted_sms_template: null,
  failed_to_delete_sms_template: null,

};

export default function smsTemplatesReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_SMS_TEMPLATES_REQUEST:
      return { ...state, loader: true };
    case types.GET_SMS_TEMPLATES_SUCCESS:
      return {
        ...state,
        loader: false,
        sms_templates: action.payload,
        count: action.payload.count,
        get_sms_templates_error: null
      };
    case types.GET_SMS_TEMPLATES_FAIL:
      return { 
        ...state, 
        loader: false, 
        get_sms_templates_error: action.payload };

    case types.GET_SMS_TEMPLATES_RESET:
      return { initialState };

    case types.GET_SUPPORTED_PARAMETERS_REQUEST:
      return { ...state, loader: true };
    case types.GET_SUPPORTED_PARAMETERS_SUCCESS:
      return {
        ...state,
        loader: false,
        supported_parameters: action.payload,
        supported_parameters_errors: null
      };
    case types.GET_SUPPORTED_PARAMETERS_FAIL:
      return { 
        ...state, 
        loader: false, 
        supported_parameters_errors: action.payload };

    case types.CREATE_SMS_TEMPLATES_REQUEST:
      return {
        ...state,
        loader: true,
        successfully_created_sms_template: null,
        failed_to_create_sms_template: null,
      };
    case types.CREATE_SMS_TEMPLATES_SUCCESS:
      return {
        ...state,
        loader: false,
        successfully_created_sms_template: action.payload,
        failed_to_create_sms_template: null,
      };
    case types.CREATE_SMS_TEMPLATES_FAIL:
      return { 
        ...state, 
        loader: false, 
        failed_to_create_sms_template: action.payload
      };
     

    case types.UPDATE_SMS_TEMPLATES_REQUEST:
      return { 
        ...state, 
        loader: true,
        successfully_updated_sms_template: null,
        failed_to_update_sms_template: null,
      };
    case types.UPDATE_SMS_TEMPLATES_SUCCESS:
      return {
        ...state, 
        loader: false,
        successfully_updated_sms_template: action.payload,
        failed_to_update_sms_template: null,
      };
    case types.UPDATE_SMS_TEMPLATES_FAIL:
      return { 
        ...state, 
        loader: false,
        successfully_updated_sms_template: null,
        failed_to_update_sms_template: action.payload,
      };


    case types.DELETE_SMS_TEMPLATES_REQUEST:
      return {
        ...state, 
        loader: true,
        successfully_deleted_sms_template: null,
        failed_to_delete_sms_template: null,
      };
    case types.DELETE_SMS_TEMPLATES_SUCCESS:
      return {
        ...state, 
        loader: false,
        successfully_deleted_sms_template: action.payload,
        failed_to_delete_sms_template: null,
      };
    case types.DELETE_SMS_TEMPLATES_FAIL:
      return { 
        ...state, 
        loader: false,
        successfully_deleted_sms_template: null,
        failed_to_delete_sms_template: action.payload,
       };

    case types.CREATE_SMS_TEMPLATES_RESET:
      return { 
        ...state,
        successfully_created_sms_template: null,
        failed_to_create_sms_template: null,
      };

    case types.UPDATE_SMS_TEMPLATES_RESET:
      return { 
        ...state,
        successfully_updated_sms_template: null,
        failed_to_update_sms_template: null,
      };

    case types.DELETE_SMS_TEMPLATES_RESET:
      return { 
        ...state,
        successfully_deleted_sms_template: null,
        failed_to_delete_sms_template: null,
      };
    default:
      return state;
  }
}
