import * as types from '../../lib/actionTypes.es6';
import GAlogger, { monitoringEventCategories } from '../../lib/monitoring.es6';

export function claimCallbackRequest(payload) {
  return {
    type: types.CLAIM_CALLBACK_REQUEST,
    payload,
  };
}

export function claimCallbackSuccess(payload) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.fetchclaimCallBacks);
  return {
    type: types.CLAIM_CALLBACK_SUCCESS,
    payload,
  };
}

export function claimCallbackFailure(payload) {
  return {
    type: types.CLAIM_CALLBACK_FAILURE,
    payload,
  };
}

export function getClosedClaimCallbackRequest(payload) {
  return {
    type: types.GET_CLAIM_CALLBACKS_CLOSED_REQUEST,
    payload
  };
}

export function getClosedClaimCallbackSuccess(payload) {
  return {
    type: types.GET_CLAIM_CALLBACKS_CLOSED_SUCCESS,
    payload,
  };
}

export function getClosedClaimCallbackFailure(payload) {
  return {
    type: types.GET_CLAIM_CALLBACKS_CLOSED_FAILURE,
    payload,
  };
}

export function claimCallbackDeleteRequest(guid) {
  return {
    type: types.CLAIM_CALLBACK_DELETE_REQUEST,
    payload: guid,
  };
}

export function claimCallbackDeleteSuccess(payload) {
  return {
    type: types.CLAIM_CALLBACK_DELETE_SUCCESS,
    payload,
  };
}

export function claimCallbackDeleteFailure(payload) {
  return {
    type: types.CLAIM_CALLBACK_DELETE_FAILURE,
    payload,
  };
}

export function resetClaimCallbackDelete() {
  return {
    type: types.RESET_CLAIM_CALLBACK_DELETE,
  };
}
