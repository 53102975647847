import { take, call, put } from 'redux-saga/effects';
import * as types from '../../lib/actionTypes.es6';
import { searchClaim } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

export function* claimSearchRequestWatcher() {
  while (true) {
    const actionResponse = yield take(types.CLAIM_SEARCH_REQUEST);
    try {
      const response = yield call(searchClaim, actionResponse.payload);
      yield put(actions.claimSearchSuccess(response.data));
    } catch (e) {
      // TODO: Update error handling
      yield put(
        actions.claimSearchFailure(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
