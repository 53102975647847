import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import Tooltip from '@mui/material/Tooltip';
import { formatDateLocale } from '../../../../lib/utils.es6';
import TableInc from '../../../../shared-ui/TableInc';
import SkeletonWrapper from '../../../../components/SkeletonWrapper/SkeletonWrapper';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import { hasAuthourity } from '../../../../lib/access.es6';
import ViewDocument from '../../../../shared-ui/ViewDocument/ViewDocument';

export default function KnowledgeBases(props) {
  const classes = useStyle();


  const [viewDocumentUrl, setViewDocumentUrl] = React.useState(null);
  const [numPages, setNumPages] = React.useState(null);

  const templates = props.guides;


  if (props.loading) return <SkeletonWrapper type="table" loading={true} />


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}  >
          <Grid container spacing={2} className={classes.claimdocswrapper}>
            <Grid item xs={12} sm={12} md={12}>

              <TableInc
                columns={[

                  {
                    title: 'Title',
                    field: 'title',
                    render: rowData => <>
                      <h2>{rowData.title}</h2>
                      <p>{rowData.description}</p>
                    </>,
                    cellStyle: {
                      width: '50%',
                      maxWidth: '50%',
                      fontSize: '200% !important',
                      fontWeight: 'bold'
                    },
                    headerStyle: {
                      width: '50%',
                      maxWidth: '50%',
                      // fontSize: '200%',
                      fontWeight: 'bold'
                    }
                  },

                  {
                    title: 'Type',
                    field: 'type',
                    render: rowData => rowData.type || "N/A",
                    cellStyle: {
                      width: '10%',
                      maxWidth: '10%'
                    },
                    headerStyle: {
                      width: '10%',
                      maxWidth: '10%'
                    }
                  },
                  {
                    title: 'Author',
                    field: 'updated_by',
                    render: rowData => rowData.updated_by || "N/A",
                    cellStyle: {
                      width: '10%',
                      maxWidth: '10%'
                    },
                    headerStyle: {
                      width: '10%',
                      maxWidth: '10%'
                    }
                  },
                  {
                    title: 'Updated At',
                    field: 'updated_at',
                    render: rowData => formatDateLocale(rowData.updated_at) || "N/A",
                    cellStyle: {
                      width: '20%',
                      maxWidth: '20%'
                    },
                    headerStyle: {
                      width: '20%',
                      maxWidth: '20%'
                    }
                  },
                  {
                    title: 'Actions',
                    field: 'created_At',
                    render: rowData => {
                      return <div className={classes.actionbtn}>
                        <Tooltip onClick={() => props.setGuideToDelete(rowData)} title={" Delete Guide"} >
                          <span style={{ cursor: "pointer" }} ><DeleteOutlineOutlinedIcon sx={{ color: " #B3251E" }} /> </span>
                        </Tooltip>
                      </div>
                    },
                    
                    cellStyle: {
                      width: '10%',
                      maxWidth: '10%',
                      display: hasAuthourity('DELETE_GUIDE'),
                    },

                    headerStyle: {
                      width: '10%',
                      maxWidth: '10%',
                      display: hasAuthourity('DELETE_GUIDE'),
                    }
                  }
                ]}

                // actions={[
                //   {
                //     icon: () => <SlideshowIcon sx={{ color: "green" }} />,
                //     tooltip: 'View guide',
                //     onClick: (event, rowData) => props.viewGuide(rowData)
                //   },
                // ]}
                actions={[
                  {
                    icon: () => <SlideshowIcon sx={{ color: "green" }} />,
                    tooltip: 'View guide',
                    onClick: (event, rowData) => {
                      if (rowData.type === "PDF") {
                        setViewDocumentUrl(rowData.url);
                        console.log("fileurl", rowData.url)
                      } else {
                        props.viewGuide(rowData);
                      }
                    },
                  },
                ]}
                

                localization={{
                  header: {
                    actions: 'View'
                  }
                }}

                data={templates}
                options={{
                  pageSize: templates.length < 5
                    ? templates.length
                    : 10,
                  showTitle: false,
                }}

              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>



      <ViewDocument
        title="Guide"
        open={viewDocumentUrl}
        close={() => setViewDocumentUrl(null)}
        viewDocumentUrl={viewDocumentUrl}
        numPages={numPages}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      />
    </>
  );
}