import React from 'react'
import { hashHistory} from 'react-router';
import MenuItem from '@mui/material/MenuItem';
import { SelectValidator, ValidatorForm } from 'react-material-ui-form-validator'
import Grid from "@material-ui/core/Grid";
import ButtonInc from '../../../../../shared-ui/ButtonInc';

export default function SelectProduct(props) {

  const [tempSelectedProduct, setTempSelectedProduct] = React.useState(null)

  const mainProducts = props.products.filter((product) => product.active === true )

  console.log("mainProducts", mainProducts)

  const hasNuclearPremium = (product) =>  product && product.premiums && product.premiums.length > 0
  const hasNuclearBenefit = (product) => product && product.fixed_indemnities && product.fixed_indemnities.length > 0


  const hasPremiumAndBenefit = (product) => {
    if(!product) return false;
    return hasNuclearPremium(product) && hasNuclearBenefit(product)
  }


  const inCompleteProduct = !hasPremiumAndBenefit(tempSelectedProduct)
  const hasNoNuclearPremium = !hasNuclearPremium(tempSelectedProduct)
  const hasNoNuclearBenefit = !hasNuclearBenefit(tempSelectedProduct)

  return (
    <div>
      <ValidatorForm onSubmit={() => null} >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectValidator
              labelId="select-product"
              id="select-product"
              value={props.selectedProduct}
              name='product'
              type="text"
              label="Select plan"
              onChange={(e) => {
                const product = e.target.value
                setTempSelectedProduct(product)
                if(hasPremiumAndBenefit(product)){
                  props.setSelectedProduct(product)
                }else{
                  props.setSelectedProduct(null)
                }
                console.log(product);
              }
              }
              variant="outlined"
              validators={['required']}
              errorMessages={['Please select plan']}
              style={{ width: "100%" }}
              sx={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.005em",
                color: "#000000",
              }}
            >
              {
                mainProducts.map(product => {
                  return <MenuItem sx={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.005em",
                    color: "#000000",
                  }} value={product}>{product.name}</MenuItem>
                })
              }

            </SelectValidator>
            {props.selectedProduct && <p>Nuclear family premium: ZAR {props.selectedProduct.premiums[0].amount_in_cents / 100}</p>}
            {
              tempSelectedProduct && inCompleteProduct && 
              <>
                <p>Incomplete product: {tempSelectedProduct.name}</p>
                {hasNoNuclearPremium && <p style={{ color: 'orange' }}>Missing nuclear family  <strong>premium</strong>!</p>}
                {hasNoNuclearBenefit && <p style={{ color: 'orange' }}>Missing nuclear family <strong>benefit</strong>!</p>}
                <ButtonInc onClick={()=> hashHistory.push(`admin/products/view/product/${tempSelectedProduct.guid}`)}>update product</ButtonInc>
              </>

            }


          </Grid>
        </Grid>

      </ValidatorForm>
    </div>
  )
}
