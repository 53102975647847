import { take, call, put } from 'redux-saga/effects';
import * as types from '../../lib/actionTypes.es6';
import { deletePremium, addMissingPayment, sendRecurringPaymentRecommendationSms } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { getCustomer } from '../Customer/actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';
import { getAllPaymentsRequest } from '../CustomerScreen/actions.es6';
import { getAllPoliciesRequest } from '../PolicyV2CustomerStatus/Redux/actions';

export function* deletePremiumWatcher() {
  while (true) {
    const requestAction = yield take(types.DELETE_PREMIUM_REQUEST);
    let response = {};
    try {
      const customerGuid = requestAction.payload.customerGuid;
      response = yield call(deletePremium, requestAction.payload.premiumGuid);
      yield put(getCustomer(customerGuid));
      yield put(actions.deletePremiumSuccess(response.message));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.deletePremiumFail(errorPayload));
    }
  }
}

export function* AddMissingPaymentRequestWatcher() {
  while (true) {
    const requestAction = yield take(types.ADD_MISSING_PAYMENT_REQUEST);
    const customerGuid = requestAction.payload.customerGuid;
    let response = {};
    try {
      response = yield call(
        addMissingPayment,
        requestAction.payload.paymentData
      );
      yield put(getCustomer(customerGuid));
      yield put(actions.addMissingPaymentSuccess(response.message));
      yield put(getAllPaymentsRequest({msisdn: requestAction.payload.msisdn, guid: customerGuid }));
      yield put(getAllPoliciesRequest({guid: customerGuid}));

    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.addMissingPaymentFail(errorPayload));
    }
  }
}

export function* sendRecurringPaymentRecommendationSmsRequestWatcher() {
  while (true) {
    const requestAction = yield take(types.SEND_RECURRING_PAYMENT_RECOMMENDATION_SMS_REQUEST);
    const payload = requestAction.payload
    let response = {};
    try {
      response = yield call(sendRecurringPaymentRecommendationSms, payload);
      yield put(actions.sendRecurringPaymentRecommendationSmsSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.sendRecurringPaymentRecommendationSmsFail(errorPayload));
    }
  }
}
