import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  policywrap: {
    background: "#FFFFFF",
    border: "1px solid #A7A7A7",
    borderRadius: "8px",
    padding: "0 !important",
    width: "100%",
    "& tr": {
      borderBottom: "1px solid #A7A7A7"
    },
    "& td": {
      borderRight: "1px solid #A7A7A7",
      padding: "5px 12px 15px 5px",
      "& ul":{
        padding: 0,
        listStyle: "none"
      },
      "&:nth-child(1)": {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "15px",
        color: "#000000",
      },
      "&:nth-child(2)": {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "15px",
        color: "#000000",
      }
    }
  },
  policyhead: {
    background: "#2B525F",
    borderRadius: "8px 8px 0px 0px",
    textAlign: "center",
    height: "49px",
    paddingTop: "10px",
    marginTop: "-1px",
    "& th": {
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.0015em",
      color: "#FFFFFF",
      textAlign: "center"
    }
  },

}));