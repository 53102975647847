import React from 'react';
import { getInternalDashboard } from '../../lib/utils.es6';

export default class InternalDashboards extends React.Component {

  render() {
    return (
      <div>
        <iframe
          style={{
            overflow: 'hidden',
            height: '100%',
            border: 'none',
            width: '100%',
            position: 'absolute',
          }}
          title="Stats from Power BI"
          src={getInternalDashboard()}
          frameBorder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    );
  }
}
