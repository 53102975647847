import React from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Customer from '../Customer/index.es6';
import Payments from '../../components/Payments/index.es6';
import Policies from '../../components/Policies/index.es6';
import Premiums from '../../components/Premiums/index.es6';
import DebitOrders from '../../components/DebitOrders/index.es6';
import Claims from '../../components/Claims/index.es6';
import NoRecord from '../../components/NoRecord/index.es6';
import AlertDialog from '../../components/AlertDialog/index.es6';
import { deletePolicyRequest } from '../PolicyList/actions.es6';
import {
  deletePremiumRequest,
  addMissingPaymentRequest,
  addMissingPaymentReset,
  sendRecurringPaymentRecommendationSmsRequest,
  sendRecurringPaymentRecommendationSmsReset
} from './actions.es6';
import * as globalActions from '../Main/actions.es6';
import { partnerHasDebitOrder, getPartnerGuid, hasAuthourity } from '../../lib/access.es6';
import { buildFailureMessage } from '../../lib/utils.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import withLanguage from '../../components/Language/index.es6';
import { PARTNERS_THAT_CAN_SEND_RECURRING_PAYMENT_SMS } from '../../lib/constants';
import { Dependants } from '../../components/Dependants/index.es6';
import { getCustomer } from '../Customer/actions.es6';


export class CustomerStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: props.labels,
    };
  }

  componentDidUpdate() {
    this.updateLanguage();
        
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'CustomerStatus',
      this.state.labels
    );
  }

  componentWillMount() {
    this.props.dispatch(globalActions.showCustomerBar());
    this.updateLanguage();
  }

  componentDidMount() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if(currentCustomer) {
      this.props.dispatch(getCustomer(currentCustomer.guid))
    }
  }

  componentWillUnmount() {
    this.resetAddMissingPayment();
  }

  resetAddMissingPayment() {
    this.props.dispatch(addMissingPaymentReset());
  }

  deletePolicy(policy) {
    this.props.dispatch(deletePolicyRequest(policy));
  }

  deletePremium(premium) {
    const payload = {
      premiumGuid: premium.guid,
      customerGuid: this.props.customerData.currentCustomer.guid,
    };
    this.props.dispatch(deletePremiumRequest(payload));
  }

  addMissingPayment(payment) {
    const payload = {
      paymentData: payment,
      customerGuid: this.props.customerData.currentCustomer.guid,
    };
    this.props.dispatch(addMissingPaymentRequest(payload));
  }

  sendRecurringPaymentRecommendationSms(customerGuid) {
    const payload = { customerGuid }
    this.props.dispatch(sendRecurringPaymentRecommendationSmsRequest(payload));
    this.setState({ alert: null })
  }


  promptRecurringPaymentSmsDialog(msisdn, partner) {
    const { labels } = this.state;
    this.setState({
      alert: <AlertDialog
        warning
        id="promptSendRecurringPaymentRecommendationSms"
        title={labels.sendRecurringPaymentRecommendationSms}
        reverseConfirmAndCancelButtons={false}
        onConfirm={() => this.sendRecurringPaymentRecommendationSms(msisdn, partner)}
        onCancel={() => this.setState({ alert: null })}
        showCancel
        showConfirm
        confirmBtnText={labels.sendSms}
        cancelBtnText={labels.cancel}
      >
      </AlertDialog>
    });
  }
  recurringPaymentSmsSuccessDialog() {
    const { labels } = this.state;
    return <AlertDialog
      id="recurringPaymentSmsSuccessDialog"
      success
      title={labels.smsSent}
      onConfirm={() => this.props.dispatch(sendRecurringPaymentRecommendationSmsReset())}
      showCancel={false}
      showConfirm
      confirmBtnText={labels.ok}
    >
    </AlertDialog>
  }

  recurringPaymentSmsErrorDialog() {
    const { labels } = this.state;
    return <AlertDialog
      id="recurringPaymentSmsErrorDialog"
      danger
      title={labels.couldNotSendSms}
      onCancel={() => this.props.dispatch(sendRecurringPaymentRecommendationSmsReset())}
      showConfirm={false}
      showCancel
      confirmBtnText={labels.ok}
    >
    </AlertDialog>
  }
  canSendRecurringSMS() {
    const currentPartner = getPartnerGuid()
    return PARTNERS_THAT_CAN_SEND_RECURRING_PAYMENT_SMS.includes(currentPartner) && hasAuthourity('SEND_SMS_RECOMMENDING_RECURRING_PAYMENT')
  }

  displayCustomerList() {
    const currentCustomer = this.props.customerData.currentCustomer;
    const labels = this.state.labels;
    if (!currentCustomer) {
      return (
        <NoRecord
          noTextLabel={labels.titleNoCustomer}
          searchLabel={labels.searchCustomer}
          redirectTo="admin/search"
        />
      );
    }
    return (
      <div>
        <Loader loaded={!this.props.customerData.loader} color="#ff834f">
          <div>
            {this.state.alert ? this.state.alert : ''}
            {this.props.statusData.recurringPaymentRecommedationSmsSent ? this.recurringPaymentSmsSuccessDialog() : ''}
            {this.props.statusData.recurringPaymentRecommedationSmsError ? this.recurringPaymentSmsErrorDialog() : ''}
            <div className="row">
              <Customer />
            </div>
            <div className="row" style={{ marginTop: '2%' }}>
              <Dependants
                dependants={currentCustomer.dependantDto}
                showMore
                customerGuid={currentCustomer.guid}
              />
              <Policies
                policies={currentCustomer.policies}
                showMore
                deletePolicyHandler={this.deletePolicy.bind(this)}
                customerGuid={currentCustomer.guid}
              />
              {partnerHasDebitOrder() ? (
                <DebitOrders
                  debitOrders={currentCustomer.debit_orders}
                  deleteDebitOrderHandler={() => { }}
                  columnWidth="col-md-12"
                  showMore
                />
              ) : (
                <div />
              )}
            </div>

            <div className="row" style={{ marginTop: '2%', marginBottom: '2%' }}>
              <Premiums
                columnWidth="col-md-12"
                showMore
                deletePremiumHandler={this.deletePremium.bind(this)}
                premiums={this.props.customerData.premiums}
              />
            </div>

            <div className="row" style={{ marginBottom: '3%' }}>
              <Payments
                columnWidth="col-md-6"
                showMore
                errors={this.props.statusData.payment_error}
                success_message={this.props.statusData.success_message}
                currentCustomer={this.props.customerData}
                payments={this.props.customerData.payments}
                onReset={this.resetAddMissingPayment.bind(this)}
                sendRecurringPaymentRecommendationSms={this.sendRecurringPaymentRecommendationSms.bind(this)}
                promptRecurringPaymentSmsDialog={this.promptRecurringPaymentSmsDialog.bind(this)}
                canSendRecurringSMS={this.canSendRecurringSMS.bind(this)}
              />
              <Claims
                columnWidth="col-md-6"
                showMore
                claims={this.props.customerData.claims}
              />
            </div>
          </div>
        </Loader>
      </div>
    );
  }

  successMessage() {
    // const labels = this.state.labels;
    // let successMessage = labels.successMessage;
    const successMessage = this.props.statusData.success_message;
    // const customerInfo = this.props.customerData.policyPurchaseInfo;
    // if (customerInfo && customerInfo.message) {
    //   successMessage = this.props.customerData.policyPurchaseInfo.message;
    // }
    return successMessage;
  }

  failureMessage() {
    // const validationErrors = this.state.validationErrors;
    // if (validationErrors.length !== 0) {
    //   return validationErrors[0]; // NOTE: Assuming there is always only 1 error
    // }
    // const labels = this.state.labels;
    // const defaultErrorMessage = labels.defaultErrorMessage;
    const defaultErrorMessage = 'Something went wrong!';
    const errorMessage = buildFailureMessage(
      defaultErrorMessage,
      this.props.statusData.errors
    );
    return errorMessage;
  }

  // TODO: Move this in a helper & reduce duplication
  displayUserFeedback() {
    const styleErrorMessage = {
      display: this.props.statusData.errors ? 'block' : 'none',
    };

    const styleSuccessMessage = {
      display: this.props.statusData.success_message ? 'block' : 'none',
    };

    if (this.props.statusData.loader) {
      return (
        <center>
          <CircularProgress />
        </center>
      );
    }
    return (
      <div>
        <center style={styleErrorMessage}>
          {' '}
          <p className="error">{this.failureMessage()} </p>{' '}
        </center>
        <center style={styleSuccessMessage}>
          {' '}
          <p className="success">{this.successMessage()}</p>{' '}
        </center>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.displayUserFeedback()}
        {this.displayCustomerList()}
      </div>
    );
  }
}

export default withLanguage(
  connect(state => ({
    customerData: state.currentCustomer,
    statusData: state.customerStatus,
    globalData: state.global,
  }))(CustomerStatus)
);
