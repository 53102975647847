import { makeStyles } from "@material-ui/styles";

export const useStyle = makeStyles(() => ({
  filterWrapper: {
    width: "100%",
    height: "auto",
    background: "#FFFFFF",
    borderRadius: "8px",
    marginTop: "20px",
    paddingBottom: "30px"

  },
  filterHeader: {
    width: "100%",
    height: "43px",
    background: "#032A37",
    borderRadius: "8px 8px 0px 0px",
   // textAlign: "center",
    paddingTop: "10px",
    "& span": {
      marginLeft: "40px",
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.0015em",
      color: "#FFFFFF",
    }
  },
  filterOption:{
    marginLeft: "40px",
    marginTop: "10px",
    "& ul":{
      marginTop: "15px",
    },
    "& span": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      color: "#000000",
    }, 
  }

}));