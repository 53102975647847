
import React from 'react';

import logo from '../../../Assets/logoblack.png'

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {

    return (
       
          <img src={logo} alt="Aspin" width="130px" />
    );
};

export default Logo;
