import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  statuscard: {
    background: "#FFFFFF",
    borderRadius: "8px",
    height: "auto",
    marginBottom: "20px",
  },
  statusheader: {
    background: "#173E4B",
    borderRadius: "8px 8px 0px 0px",
    height: "65px",
    "& h1": {
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.0015em",
      color: "#FFFFFF",
      paddingLeft: "30px"
    }
  },
  policieswrapper: {
    width: "100%",
    "& table": {
        background: "#FFFFFF",
        border: "1px solid #A7A7A7",
        margin: "30px",
        width: "90%",
        "& tr": {
            borderTop: "1px solid #A7A7A7",
        },
        "& th": {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
            padding: "10px",
            borderRight: "1px solid #A7A7A7",
        },
        "& td": {
            fontFamily: "open sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
            padding: "10px",
            borderRight: "1px solid #A7A7A7",

        }
    }
},
active:{
  color: "#75A85E",
  fontSize: "10px",
  fontWeight: "bold",
  display: "inline-block",
  textAlign: "center",
  lineHeight: "15px",
},
cancelled:{
  color: "#B3251E",
  fontSize: "10px",
  fontWeight: "bold",
  display: "inline-block",
  textAlign: "center",
  lineHeight: "15px",
},
showmoreshowless: {
  marginLeft: "30px",
  "& ul": {
      display: "flex",
      padding: "0",
      cursor: "pointer",
      "& li": {
          listStyle: "none",
          fontFamily: "open sans",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "15px",
          color: "#000000",
          "&:nth-child(2)": {
              marginTop: "-3px"
          }
      }
  }
},
addquote:{
  marginTop: "5px",
  textAlign: "right",
  marginRight: "50px",
  "& button":{
    background: "#fba92c",
    color: "#fff !important",
    borderRadius: "8px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "15px",
    boxShadow: "none",
    padding: "10px",
    textTransform: "capitalize",
    marginBottom: "30px",
    marginLeft: "30px",
    "&:hover": {
        background: "#fba92c",
        boxShadow: "none",
    }
  }
},
nodatafound: {
  textAlign: "center",
  margin: "40px 0 40px 0",
  "& p":{
    fontFamily: "open sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "15px",
    color: "#000000",
  },
  "& button": {
    background: "#FBA92D",
    borderRadius: "8px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "15px",
    boxShadow: "none",
    padding: "10px",
    textTransform: "capitalize",
    marginTop: "15px",
    "&:hover":{
        background: "#173E4B",
        boxShadow: "none",
    }
}
},
dependantstitle: {
  height: "60px",
  background: "#173E4B",
  color: "#fff",
  fontFamily: "Lato !important",
  fontStyle: "normal",
  fontWeight: "500 !important",
  fontSize: "16px !important",
  lineHeight: "24px !important",
  letterSpacing: "0.0015em !important",
}
}));