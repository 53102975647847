import React from 'react'
import { Box, Grid } from '@mui/material';
import { useStyle } from "./Style";
import SkeletonWrapper from '../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import { formatDateLocale } from '../../../../../lib/utils.es6';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
const Notes = (props) => {

  const classes = useStyle();

  if (props.loader) {
    return <SkeletonWrapper loading={props.loader} />
  }


  if (props.notes.length === 0) {
    return <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
        <Grid container spacing={2} className={classes.claimdetailswrapper}>
          <Grid item xs={12} sm={12} md={12} className={classes.ClaimDetailscontent}>
            <p style={{ textAlign: 'center', marginTop: "30px" }}>No details found</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }else{
    return (
      <>
      {
            props.notes.map((note, index) => (
              <Grid container className={classes.policyWrapper} key={index}>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container className={classes.policyHeader}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Grid container>
                        <Grid item xs={12} md={2} sm={2} className={classes.policyIconWrapper}   >
                          <Box className={classes.policyIcons}>
                            <InsertDriveFileOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={10} sm={10} className={classes.policyHeaderContent}>
                          <h6>{note.note || "N/A"}</h6>
                          <p> {"Note Details"}</p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} >
    
                      <Grid container>
                        <Grid item xs={12} md={6} sm={6}>
                          <div className={classes.policyHeaderContent} >
                          <h6>{formatDateLocale(note.created_at) || "N/A"}</h6>
                          <p> {"Created At"}</p>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} >
                        <div className={classes.policyHeaderContent} >
                          <h6>{note.web_user || "N/A"}</h6>
                          <p> {"Created By"}</p>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
    
            ))
      }
      </>
    )
  }


  
 
}

export default Notes