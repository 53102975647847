import { CircularProgress, Grid } from '@mui/material'
import React from 'react'
import ButtonInc from '../../../../shared-ui/ButtonInc'

const ChangePassword = (props) => {

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>

          <ButtonInc
            onClick={props.changePassword}
            style={{
              marginTop: "50px"
            }}>{props.loading ? <CircularProgress style={{ color: '#fff' }} /> : `${props.labels.clickToChangePassword}`}</ButtonInc>
        </Grid>
      </Grid>
      {
        props.showChangePasswordMessage === true ? (<Grid container spacing={2}>
          <Grid item xs={12} md={12} sm={12}>
            <p style={{
              marginTop: "30px", fontFamily: 'open sans',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "15px"
            }}>{props.labels.passwordResetLink}  {props.ProfileDetails.email}</p>
          </Grid>
        </Grid>) : ""
      }

    </>
  )
}

export default ChangePassword