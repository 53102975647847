import * as types from "../../lib/actionTypes.es6";

export const initialState = {
  error: null,
  loader: false,
  document: null,
  documents: [],

  deleteDocumentError: null,
  deleteDocumentLoader: false,
  deleteDocument: null,
  hardDeleteDocumentsSuccess: false,
  hardDeleteDocumentLoader: false,
  hardDeleteDocumentSuccess: false,

  documentsToDelete: null,
};

export default function customerDocumentsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CUSTOMER_DOCUMENT_REQUEST:
      return { ...state, loader: true, error: null, document: null };
    case types.GET_CUSTOMER_DOCUMENT_SUCCESS:
      return { ...state, document: action.payload, loader: false };
    case types.GET_CUSTOMER_DOCUMENT_FAILURE:
      return { ...state, loader: false, error: action.payload };
    case types.GET_CUSTOMER_DOCUMENTS_REQUEST:
      return { ...state, loader: true, error: null, documents: null };
    case types.GET_CUSTOMER_DOCUMENTS_SUCCESS:
      return { ...state, documents: action.payload, loader: false };
    case types.GET_CUSTOMER_DOCUMENTS_FAILURE:
      return { ...state, loader: false, error: action.payload };
    case types.ADD_CUSTOMER_DOCUMENT_REQUEST:
      return { ...state, loader: true, error: null, document: null };
    case types.ADD_CUSTOMER_DOCUMENT_SUCCESS:
      return { ...state, document: action.payload, loader: false };
    case types.ADD_CUSTOMER_DOCUMENT_FAILURE:
      return { ...state, loader: false, error: action.payload };
    case types.UPDATE_CUSTOMER_DOCUMENT_REQUEST:
      return { ...state, loader: true, error: null, document: null };
    case types.UPDATE_CUSTOMER_DOCUMENT_SUCCESS:
      return { ...state, document: action.payload, loader: false };
    case types.UPDATE_CUSTOMER_DOCUMENT_FAILURE:
      return { ...state, loader: false, error: action.payload };

    case types.DELETE_CUSTOMER_DOCUMENT_REQUEST:
      return {
        ...state, loader: true, error: null, document: null, deleteClaimDocumentLoader: true,
        deleteDocumentError: null,
        deleteDocument: null,
        hardDeleteDocumentLoader: true,
      };
    case types.DELETE_CUSTOMER_DOCUMENT_SUCCESS:
      return {
        ...state, loader: false, deleteDocumentLoader: false,
        deleteDocument: true,
        hardDeleteExpenseLoader: false,
        hardDeleteDocumentSuccess: true
      };


    case types.DELETE_CUSTOMER_DOCUMENT_FAILURE:
      return {
        ...state, loader: false, error: action.payload, deleteDocumentLoader: false,
        deleteDocumentError: action.payload,
        hardDeleteExpenseLoader: false,
      };

    case types.RESET_DELETE_CUSTOMER_DOCUMENT_SUCCESS:
      return { ...state, hardDeleteDocumentsSuccess: false, hardDeleteDocumentSuccess: false };

    case types.SET_CUSTOMER_DOCUMENT_TO_DELETE:
      return { ...state, documentsToDelete: action.payload };

    case types.RESET_CUSTOMER_DOCUMENT_TO_DELETE:
      return { ...state, documentsToDelete: null };

    default:
      return state;
  }
}
