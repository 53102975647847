import React from 'react';
import { connect } from 'react-redux';
import { updateGlobalLanguage } from './actions.es6';
import { sesssionMonitor } from '../../lib/access.es6';

export class App extends React.Component {
  componentWillMount() {
    this.props.dispatch(
      updateGlobalLanguage(
        localStorage.getItem('language')
          ? localStorage.getItem('language')
          : 'en'
      )
    );
    sesssionMonitor();
  }

  render() {
    document.getElementById('main').className = 'login';
    return <div>{this.props.children}</div>;
  }
}

export default connect(state => ({ globalData: state.global }))(App);
