import * as types from '../../lib/actionTypes.es6';
import GAlogger, { monitoringEventCategories } from '../../lib/monitoring.es6';

export function claimSearchRequest(claimSearchData) {
  return {
    type: types.CLAIM_SEARCH_REQUEST,
    payload: claimSearchData,
  };
}

export function claimSearchSuccess(claimInfo) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.makeSearchClaims);
  return {
    type: types.CLAIM_SEARCH_SUCCESS,
    payload: claimInfo,
  };
}

export function claimSearchFailure(response) {
  return {
    type: types.CLAIM_SEARCH_FAILURE,
    payload: response,
  };
}

export function claimTypesSuccess(claimTypes) {
  return {
    type: types.CLAIM_TYPES_SUCCESS,
    payload: claimTypes,
  };
}

export function claimStatusSuccess(statuses) {
  return {
    type: types.CLAIM_STATUSES_SUCCESS,
    payload: statuses,
  };
}

export function claimSearchReset() {
  return {
    type: types.CLAIM_SEARCH_RESET,
  };
}
