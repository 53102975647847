import { take, call, put } from 'redux-saga/effects';
import * as types from '../../lib/actionTypes.es6';
import {
  getUsers,
  createUser,
  getPartners,
  getRoles,
  changeUserPassword,
  updateUserRolesAndPartners,
  recoverPassword, 
  getUser,
  deleteUser,
  getCurrentRoles,
  getCurrentUserPartners
} from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

/**
 * this saga continually watches register request actions
 */
export function* getUsersRequestWatcher() {
  while (yield take('GET_USERS_REQUEST')) {
    try {
      const response = yield call(getUsers);
      yield put(actions.getUsersSuccess(response.data));
    } catch (e) {
      yield put(
        actions.getUsersFail(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* getUserRequestWatcher() {
  for (;;) {
    try {
      const action = yield take('GET_USER_REQUEST');
      const response = yield call(getUser, action.payload);
      yield put(actions.getUserSuccess(response.data));
    } catch (e) {
      yield put(
        actions.getUserFail(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* createUserRequestWatcher() {
  for (;;) {
    try {
      const action = yield take('CREATE_USERS_REQUEST');
      const response = yield call(createUser, action.payload);
      yield put(actions.createUsersSuccess(response));
      yield put(actions.getUsersRequest());
    } catch (e) {
      yield put(actions.createUsersFail(e.response.data));
      unAuthorizedNotification(e);
    }
  }
}

export function* getPartnersRequestWatcher() {
  while (yield take('GET_PARTNERS_REQUEST')) {
    try {
      const response = yield call(getPartners);
      yield put(actions.getPartnersSuccess(response.data));
    } catch (e) {
      yield put(
        actions.getPartnersFail(
          'An error occurred, this could be due to invalid data '
        )
      );
      unAuthorizedNotification(e);
    }
  }
}

export function* getRolesRequestWatcher() {
  while (yield take('GET_ROLES_REQUEST')) {
    try {
      const response = yield call(getRoles);
      yield put(actions.getRolesSuccess(response.data));
    } catch (e) {
      yield put(
        actions.getRolesFail(
          'An error occurred, this could be due to invalid data '
        )
      );
      unAuthorizedNotification(e);
    }
  }
}

export function* getCurrentRolesRequestWatcher() {
  for(;;){
   
    try {
      const action = yield take(types.GET_CURRENT_ROLES_REQUEST);
      const payload = {
        id: action.payload,
      }
      const response = yield call(getCurrentRoles, payload);
      yield put(actions.getCurrentRolesSuccess(response.data));
    } catch (e) {
      yield put(
        actions.getCurrentRolesFail(
          'An error occurred, this could be due to invalid data '
        )
      );
      unAuthorizedNotification(e);
    }
  }
}

export function* getCurrentPartnersRequestWatcher() {
  for(;;){
    try {
      const action = yield take(types.GET_CURRENT_PARTNER_REQUEST);
      const payload = {
        id: action.payload,
      }
      const response = yield call(getCurrentUserPartners, payload);
      yield put(actions.getCurrentPartnerSuccess(response.data.attributes.partners));
    } catch (e) {
      yield put(
        actions.getCurrentPartnerFail(
          'An error occurred, this could be due to invalid data '
        )
      );
      unAuthorizedNotification(e);
    }
  }
 
}

export function* deleteUserRequestWatcher() {
  for (;;) {
    try {
      const action = yield take(types.DELETE_USER_REQUEST);
      const payload = {
        id: action.payload,
        enabled: false
      }
      yield call(deleteUser, payload);
      yield put(actions.getUsersRequest());
      yield put(actions.deleteUsersSuccess('User Deleted successfully'))
    } catch (e) {
      yield put(
        actions.deleteUsersFail(
          'An error occurred, this could be due to invalid data '
        )
      );
      unAuthorizedNotification(e);
    }
  }
}

export function* changeUserPasswordRequestWatcher() {
  for (;;) {
    try {
      const action = yield take('CHANGE_USER_PASSWORD_REQUEST');
      yield call(changeUserPassword, action.payload);
    } catch (e) {
      unAuthorizedNotification(e);
    }
  }
}

export function* updateRolesAndPartnersRequestWatcher() {
  for (;;) {
    const request = yield take(types.UPDATE_ROLES_AND_PARTNERS_REQUEST);
    try {
      const response = yield call(updateUserRolesAndPartners, request.payload);
      yield put(actions.updateRolesAndPartnersdSuccess(response.data));
      yield put(actions.getUsersRequest());
    } catch (e) {
      yield put(actions.updateRolesAndPartnersdFail('Something went wrong'));
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* resetPasswordRequestWatcher() {
  while (true) {
    const response = yield take(types.RESET_USER_PASSWORD_REQUEST);
    try {
      yield call(recoverPassword, response.payload);
      yield put(actions.resetPasswordSuccess('password reset success'));
    } catch (e) {
      GAloggerException(e);
      yield put(actions.resetPasswordFailure('Something went wrong'));
    }
  }
}
