import React from 'react';
import { connect } from 'react-redux';
import { hashHistory } from 'react-router';
import {  DatePicker } from '@material-ui/pickers';
import Loader from 'react-loader';
import LocalizedStrings from 'react-localization';
import Checkbox from '@material-ui/core/Checkbox';
import MainPageTitle from '../../components/MainPageTitle/index.es6';
import * as actions from './actions.es6';
import { formatDate } from '../../lib/utils.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { localisedText } from '../../lib/localisation.es6';
import {
  getInternationalCallingCode,
  addInternationalCallingCode,
  getLocalNumberLength,
  partnerRegistrationFieldsContains,
  partnerLanguages,
  customerLanguagesVisible,
  partnerPullKyc,
  partnerWebDisplayFieldsContains, getUserGuid,
} from '../../lib/access.es6';

export class RegisterCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      birthday: null,
      labels: new LocalizedStrings(localisedText),
      language: '',
      hasWallet: true,
      nationalId: '',
      firstName: '',
      surname: '',
      fullName: '',
      msisdn: '',
    };
  }

  componentWillMount() {
    this.updateLanguage();
  }

  componentDidUpdate() {
    if (this.props.registrationData.customerInfo) {
      this.props.dispatch(actions.resetRegistration());
      hashHistory.push('admin/home');
    }
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'RegisterCustomer',
      this.state.labels
    );
  }

  errorMissingPhoneText() {
    return this.state.labels.phoneNumberDigitsMustBe + getLocalNumberLength()
  }

  validPhone() {
    if (this.state.msisdn.length !== getLocalNumberLength()) {
      this.props.dispatch(actions.displayError(this.errorMissingPhoneText()));
      return false;
    }
    return true;
  }

  submitRegistrationHandler(e) {
    const labels = this.state.labels;
    e.preventDefault();
    if (!this.validPhone()) {
      return;
    }

    const birthday = this.state.birthday;
    if (!birthday && partnerPullKyc() === false) {
      this.props.dispatch(actions.displayError(labels.errorMissingDateOfBirth));
      return;
    }

    const customerData = {
      date_of_birth: birthday || null,
      msisdn: getInternationalCallingCode() + this.state.msisdn,
    };

    if (this.fullNameValid() === false) {
      this.props.dispatch(actions.displayError(labels.errorMissingFullName));
      return;
    }
    this.updateFullNameInfo(customerData);

    if (this.beneficiaryValid() === false) {
      // TODO: Translate this
      this.props.dispatch(
        actions.displayError(
          `Beneficiary phone number must be ${getLocalNumberLength()} digits`
        )
      );
      return;
    }
    this.updateBeneficiaryInfo(customerData);

    if (this.nationalIdValid() === false) {
      this.props.dispatch(actions.displayError(labels.errorIncorrectID));
      return;
    }
    this.updateNationalIdInfo(customerData);

    if (this.firstNameValid() === false) {
      this.props.dispatch(actions.displayError(labels.errorMissingFirstName));
      return;
    }
    this.updateFirstNameInfo(customerData);

    if (this.surnameValid() === false) {
      this.props.dispatch(actions.displayError(labels.errorMissingSurname));
      return;
    }
    this.updateSurnameInfo(customerData);
    this.updateHasWalletInfo(customerData);

    this.updateLanguageInfo(customerData);
    if (!this.languageValid()) {
      delete customerData.language;
    }

    customerData.web_agent_guid =  getUserGuid();

    this.props.dispatch(actions.registerCustomerRequest(customerData));
  }

  updateHasWalletInfo(customerData) {
    if (this.hasWalletVisible()) {
      customerData.has_wallet = this.state.hasWallet;
    }
  }

  updateBeneficiaryInfo(customerData) {
    const regForm = new FormData(document.getElementById('registrationForm'));
    if (this.beneficiaryVisible()) {
      const beneficiaryName = regForm.get('beneficiary');
      if (beneficiaryName) {
        customerData.beneficiary_name = beneficiaryName;
      }

      const beneficiaryMsisdn = regForm.get('beneficiary_msisdn');
      if (beneficiaryMsisdn) {
        customerData.beneficiary_msisdn =
          getInternationalCallingCode() + beneficiaryMsisdn;
      }
    }
  }

  beneficiaryValid() {
    const regForm = new FormData(document.getElementById('registrationForm'));
    if (this.beneficiaryVisible()) {
      const beneficiaryMsisdn = regForm.get('beneficiary_msisdn');
      if (beneficiaryMsisdn) {
        if (beneficiaryMsisdn.length !== getLocalNumberLength()) {
          return false;
        }
      }
    }
    return true;
  }

  updateNationalIdInfo(customerData) {
    if (this.nationalIdVisible()) {
      const nationalId = this.state.nationalId;
      if (nationalId) {
        customerData.national_id = nationalId;
      }
    }
  }

  nationalIdValid() {
    if (this.nationalIdVisible()) {
      const nationalId = this.state.nationalId;
      if (nationalId) {
        // TODO: Validate the national id provided
      }
    }
    return true;
  }

  updateFirstNameInfo(customerData) {
    if (this.firstNameVisible()) {
      const firstName = this.state.firstName;
      if (firstName) {
        customerData.first_name = firstName;
      }
    }
  }

  firstNameValid() {
    if (this.firstNameVisible()) {
      const firstName = this.state.firstName;
      if (firstName) {
        return firstName.length !== 0;
      }
    }
    return true;
  }

  updateSurnameInfo(customerData) {
    if (this.surnameVisible()) {
      const surname = this.state.surname;
      if (surname) {
        customerData.surname = surname;
      }
    }
  }

  surnameValid() {
    if (this.surnameVisible()) {
      const surname = this.state.surname;
      if (surname) {
        return surname.length !== 0;
      }
    }
    return true;
  }

  updateFullNameInfo(customerData) {
    if (this.fullNameVisible()) {
      const fullName = this.state.fullName;
      if (fullName) {
        customerData.full_name = fullName;
      }
    }
  }

  fullNameValid() {
    if (this.fullNameVisible()) {
      const fullName = this.state.fullName;
      if (fullName) {
        return fullName.length !== 0;
      }
    }
    return true;
  }

  clearForm() {
    document.getElementById('registrationForm').reset();
    this.setState({ birthday: '' });
  }

  dateHandler(type, moment) {
    if (type === 'birthday') {
      this.setState({ birthday: formatDate(new Date(moment)) });
    }
  }

  beneficiaryVisible() {
    return (
      partnerRegistrationFieldsContains('beneficiaryName') ||
      partnerRegistrationFieldsContains('beneficiaryMsisdn') ||
      partnerWebDisplayFieldsContains('beneficiaryName') ||
      partnerWebDisplayFieldsContains('beneficiaryMsisdn')
    );
  }

  beneficiaryDisplay() {
    const labels = this.state.labels;
    const beneficiaryStyle = {
      display: this.beneficiaryVisible() ? 'block' : 'none',
    };

    return (
      <div style={beneficiaryStyle}>
        <div className="form-group">
          <label
            htmlFor="middle-name"
            className="control-label col-md-4 col-sm-4 col-xs-12"
          >
            {labels.beneficiaryFullname}
          </label>
          <div className="col-md-6 col-sm-6 col-xs-12">
            <input
              className="form-control col-md-7 col-xs-12"
              name="beneficiary"
              type="text"
              maxLength="75"
            />
          </div>
        </div>
        <div className="form-group">
          <label
            htmlFor="beneficiary_msisdn"
            className="control-label col-md-4 col-sm-4 col-xs-4"
          >
            {addInternationalCallingCode(labels.beneficiaryPhoneNumber)}
          </label>
          <div className="col-md-6 col-sm-4 col-xs-4">
            <input
              type="number"
              min="0"
              className="form-control"
              name="beneficiary_msisdn"
              maxLength={getLocalNumberLength()}
            />
          </div>
        </div>
      </div>
    );
  }

  changeNationalId(e) {
    this.setState({ nationalId: e.target.value });
  }

  nationalIdDisplay() {
    const labels = this.state.labels;
    const nationalIdStyle = {
      display: this.nationalIdVisible() ? 'block' : 'none',
    };

    return (
      <div className="form-group" style={nationalIdStyle}>
        <label
          htmlFor="national_id"
          className="control-label col-md-4 col-sm-4 col-xs-12"
        >
          {labels.nationalId}
          <span>*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <input
            className="form-control col-md-7 col-xs-12"
            name="national_id"
            id="national_id"
            value={this.state.nationalId}
            type="text"
            maxLength="75"
            disabled={this.pullKycVisible()}
            onChange={this.changeNationalId.bind(this)}
          />
        </div>
      </div>
    );
  }

  nationalIdVisible() {
    return (
      partnerRegistrationFieldsContains('nationalId') ||
      partnerWebDisplayFieldsContains('nationalId')
    );
  }

  changeFirstName(e) {
    this.setState({ firstName: e.target.value });
  }

  firstNameDisplay() {
    const labels = this.state.labels;
    const firstNameStyle = {
      display: this.firstNameVisible() ? 'block' : 'none',
    };

    return (
      <div className="form-group" style={firstNameStyle}>
        <label
          htmlFor="first_name"
          className="control-label col-md-4 col-sm-4 col-xs-12"
        >
          {labels.firstName}
          <span>*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <input
            className="form-control col-md-7 col-xs-12"
            name="first_name"
            id="first_name"
            value={this.state.firstName}
            type="text"
            maxLength="75"
            disabled={this.pullKycVisible()}
            onChange={this.changeFirstName.bind(this)}
          />
        </div>
      </div>
    );
  }

  firstNameVisible() {
    return (
      partnerRegistrationFieldsContains('firstName') ||
      partnerWebDisplayFieldsContains('firstName')
    );
  }

  changeSurname(e) {
    this.setState({ surname: e.target.value });
  }

  surnameDisplay() {
    const labels = this.state.labels;
    const surnameStyle = {
      display: this.surnameVisible() ? 'block' : 'none',
    };

    return (
      <div className="form-group" style={surnameStyle}>
        <label
          htmlFor="surname"
          className="control-label col-md-4 col-sm-4 col-xs-12"
        >
          {labels.surname}
          <span>*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <input
            className="form-control col-md-7 col-xs-12"
            name="surname"
            id="surname"
            value={this.state.surname}
            type="text"
            maxLength="75"
            disabled={this.pullKycVisible()}
            onChange={this.changeSurname.bind(this)}
          />
        </div>
      </div>
    );
  }

  surnameVisible() {
    return (
      partnerRegistrationFieldsContains('surname') ||
      partnerWebDisplayFieldsContains('surname')
    );
  }

  changeFullName(e) {
    this.setState({ fullName: e.target.value });
  }

  fullNameDisplay() {
    const labels = this.state.labels;
    const fullNameStyle = {
      display: this.fullNameVisible() ? 'block' : 'none',
    };

    return (
      <div className="form-group" style={fullNameStyle}>
        <label
          className="control-label col-md-4 col-sm-4 col-xs-12"
          htmlFor="full-name"
        >
          {labels.fullName}
          <span>*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <input
            id="full-name"
            name="full-name"
            required
            disabled={this.pullKycVisible()}
            value={this.state.fullName}
            className="form-control col-md-7 col-xs-12"
            type="text"
            maxLength="75"
            onChange={this.changeFullName.bind(this)}
          />
        </div>
      </div>
    );
  }

  dateOfBirthDisplay() {
    const labels = this.state.labels;
    const style = {
      display: this.pullKycVisible() ? 'none' : '',
    };
    return (
      <div className="form-group" style={style}>
        <label
          htmlFor="birthday"
          className="control-label col-md-4 col-sm-4 col-xs-12"
        >
          {labels.dateOfBirth}
          <span>*</span>
        </label>
        <div className="col-md-6 col-sm-3 col-xs-12">
          <DatePicker
            value={this.state.birthday}
            onChange={this.dateHandler.bind(this, 'birthday')}
            format="YYYY-MM-DD"
            placeholder="Pick Date Of Birth"
          />
        </div>
      </div>
    );
  }

  fullNameVisible() {
    return (
      partnerRegistrationFieldsContains('fullName') ||
      partnerWebDisplayFieldsContains('fullName')
    );
  }

  toggleHasWallet() {
    const hasWallet = this.state.hasWallet;
    this.setState({ hasWallet: !hasWallet });
  }

  hasWalletDisplay() {
    const hasWalletStyle = {
      display: this.hasWalletVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    return (
      <div className="form-group" style={hasWalletStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.hasWallet} <span className="required">*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12 padding5">
          <Checkbox
            required
            checked={this.state.hasWallet}
            onChange={this.toggleHasWallet.bind(this)}
            color="primary"
          />
        </div>
      </div>
    );
  }

  hasWalletVisible() {
    return (
      partnerRegistrationFieldsContains('hasWallet') ||
      partnerWebDisplayFieldsContains('hasWallet')
    );
  }

  selectLanguages(e) {
    this.setState({ language: e.target.value });
  }

  languageValid() {
    if (this.languageVisible()) {
      const language = this.state.language;
      if (language) {
        return language.length !== 0;
      }
    }
    return true;
  }

  updateLanguageInfo(customerData) {
    if (this.languageVisible()) {
      const language = this.state.language;
      if (language) {
        customerData.language = language;
      }
    }
  }

  languageDialogDisplay() {
    const labels = this.state.labels;
    const languages = partnerLanguages();
    const languageStyle = {
      display: this.languageVisible() ? 'block' : 'none',
    };
    return (
      <div className="form-group" style={languageStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.lang}
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="col-md-4 col-sm-4 col-xs-4">
            <select
              id="languages"
              onChange={this.selectLanguages.bind(this)}
              defaultValue={this.state.language}
            >
              <option key="-1" value="">
                {labels.selectLanguage}
              </option>
              {languages.map((lang, index) => (
                <option key={index} value={lang.code}>
                  {lang.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }

  languageVisible() {
    return customerLanguagesVisible();
  }

  pullKycVisible() {
    return partnerPullKyc();
  }

  changeMsisdn(e) {
    this.setState({ msisdn: e.target.value });
  }

  render() {
    const regError = {
      display: this.props.registrationData.errors ? 'block' : 'none',
    };
    const labels = this.state.labels;

    return (
      <div>
        <Loader loaded={!this.props.registrationData.loader} color="#ff834f">
          <MainPageTitle pageTitle={labels.titleRegister} />
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="x_panel">
                <div className="x_title">
                  <h2>{labels.subtitleRegister}</h2>
                  <div className="clearfix" />
                </div>
                <div className="x_content">
                  <br />
                  <form
                    id="registrationForm"
                    className="form-horizontal form-label-left"
                    noValidate
                    onSubmit={this.submitRegistrationHandler.bind(this)}
                  >
                    <center style={regError}>
                      <p className="registrationError" style={{ color: 'red' }}>
                        {this.props.registrationData.errors}
                      </p>
                    </center>
                    {this.nationalIdDisplay()}
                    {this.firstNameDisplay()}
                    {this.surnameDisplay()}
                    {this.hasWalletDisplay()}
                    {this.languageDialogDisplay()}
                    <div className="form-group">
                      <label
                        htmlFor="reg_phone_number"
                        className="control-label col-md-4 col-sm-4 col-xs-4"
                      >
                        {addInternationalCallingCode(labels.phoneNumber)}
                        <span>*</span>
                      </label>
                      <div className="col-md-6 col-sm-4 col-xs-4">
                        <input
                          id="reg_phone_number"
                          type="number"
                          min="0"
                          className="form-control"
                          name="phone"
                          maxLength={getLocalNumberLength()}
                          value={this.state.msisdn}
                          onChange={this.changeMsisdn.bind(this)}
                        />
                      </div>
                    </div>
                    {this.fullNameDisplay()}
                    {this.dateOfBirthDisplay()}
                    {this.beneficiaryDisplay()}
                    <div className="ln_solid" />
                    <div className="form-group">
                      <center>
                        <button
                          onClick={this.clearForm.bind(this)}
                          type="button"
                          className="btn btn-default"
                        >
                          {labels.cancel}
                        </button>
                        <button type="submit" className="btn btn-success">
                          {labels.submitRegister}
                        </button>
                      </center>
                    </div>
                  </form>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </Loader>
      </div>
    );
  }
}

// this subscribes the component to the branch of the store called registrationData
// a change in registrationData state branch will cause the component to re-render
export default connect(state => ({
  registrationData: state.customerRegistration,
  globalData: state.global,
}))(RegisterCustomer);
