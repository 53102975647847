import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../../../../lib/actionTypes.es6';
import {  getAllCustomerClaims } from '../../../../../../lib/requests.es6';
import * as actions from './actions.js';
import { unAuthorizedNotification } from '../../../../../../lib/access.es6';
import { GAloggerException } from '../../../../../../lib/monitoring.es6';


export function* getAllOrangeCustomerClaimsWatcher() {
  while (true) {
    const req = yield take(types.GET_ALL_ORANGE_CUSTOMER_CLAIMS_REQUEST);
    let response = {};
    try {
      response = yield call(getAllCustomerClaims, req.payload);
      yield put(actions.getAllOrangeCustomerClaimsSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.getAllOrangeCustomerClaimsFail(errorPayload));
    }
  }
}