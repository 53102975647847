import { take, call, put } from 'redux-saga/effects';
import * as types from '../../lib/actionTypes.es6';
import { getSummaryClaims } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

/**
 * this saga continually watches register request actions
 */
export function* summaryClaimRequestWatcher() {
  while (yield take(types.SUMMARY_CLAIM_REQUEST)) {
    try {
      const response = yield call(getSummaryClaims);
      yield put(actions.summaryClaimsSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.summaryClaimsFail(errorPayload));
    }
  }
}
