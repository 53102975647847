import React, { useState } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { hasAuthourity } from '../../../../../lib/access.es6';
import { BUTTON_STYLES } from '../../../lib/constants';

const Expired = ({ claim, onStatusChange, labels }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const processStatusChange = async (targetStatus) => {
    setIsSubmitting(true);
    try {
      await onStatusChange(targetStatus);
      setShowSuccess(true);
    } catch (error) {
      const message = error.response && error.response.data ? 
        error.response.data.message || error.response.data.error : 
        'Failed to update status';
      setErrorMessage(message);
      setShowError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <ButtonInc
            onClick={() => processStatusChange('Decision_Pending')}
            disabled={isSubmitting || !hasAuthourity('REVERT_EXPIRED_CLAIM')}
            variant="contained"
            style={BUTTON_STYLES.default}
          >
            {labels.revertExpiredClaim}
          </ButtonInc>
        </Grid>
      </Grid>

      <AlertDialog
        custom
        show={isSubmitting}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Updating Status...</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={showSuccess}
        onConfirm={() => setShowSuccess(false)}
        confirmBtnText="OK"
        title="Success"
      >
        Status updated successfully
      </AlertDialog>

      <AlertDialog
        error
        show={showError}
        onConfirm={() => setShowError(false)}
        confirmBtnText="OK"
        title="Error"
      >
        {errorMessage}
      </AlertDialog>
    </>
  );
};

export default Expired; 