import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles((LandingPageTheme) => ({

    mySwipertestimonial: {
        "& span": {
            background: "#fff",
            width: "12px",
            height: "12px",
            marginBottom: "-10px !important"
        }
    },
    testimonials: {
        background: "#FBA92D",
        borderRadius: "50px 50px 0px 0px",
        padding: "50px",
        [LandingPageTheme.breakpoints.down("md")]: {
            padding: "20px"
        },
        "& h2": {
            fontFamily: 'Lato',
            fontStyle: "normal",
            fontWeight: "900",
            fontSize: "22px",
            lineHeight: "28px",
            color: "#fff",
            [LandingPageTheme.breakpoints.down("md")]: {
                fontSize: "18px",
                lineHeight: "25px",
                letterSpacing: " 0.001em",
            }
        },
    },
    divider: {
        width: "28px",
        height: "7px",
        background: "#fff",
        transform: "matrix(1, 0, 0, -1, 0, 0)",
        float: "left",
        marginTop: "-10px",
        display: "inline",
        [LandingPageTheme.breakpoints.down("md")]: {
            marginBottom: "40px"
        },
    },
    testimonialouter: {
        float: "left",
        marginLeft: "30px",
        height: "320px",
        [LandingPageTheme.breakpoints.down("md")]: {
            marginLeft: "0",
            height: "360px"
        },
    },
    testimonialwrapper: {
        marginTop: "50px",
        background: "#FFFFFF",
        border: "1px solid #CCCCCC",
        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
        padding: "40px",
        textAlign: "center",
        [LandingPageTheme.breakpoints.down("md")]: {
            padding: "20px"
        },
        "& h4": {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "20px",
            textAlign: "center",
            letterSpacing: "0.001em",
            color: " #000000",
            marginTop: "40px",
            display: "inline-block"
        },
        "& p": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: "300",
            fontSize: "14px",
            lineHeight: "20px",
            textAlign: "center",
            letterSpacing: "0.001em",
            color: "#000000",
            marginTop: "20px"
        }
    },
    testimonialavatar: {
        width: "60px",
        height: "60px",
        background: "#032A37",
        borderRadius: "100%",
        paddingTop: "14px",
        margin: "-70px auto 0 auto"
    },

}));

