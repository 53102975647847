import React from 'react'
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import { getPartner } from '../../lib/access.es6';

const ShipRazorPackageDetails = (props) => {
  console.log('ship');
  
  const classes = useStyle();




  const currencyCode = getPartner().country.currency_code
  const packageDetails = (props.package.package.quotation.additional_details) ? props.package.package.quotation.additional_details : null

  if (packageDetails) {
    const orderDetails = packageDetails.orderDetails
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.policieswrapper}>
              <h2>Order Details</h2>
              <table>
                <tr>
                  <th>Order ID</th>
                  <td>{orderDetails.orderId}</td>
                </tr>
                <tr>
                  <th>Order Date</th>
                  <td>{new Date(orderDetails.orderDate).toLocaleString()}</td>
                </tr>
                <tr>
                  <th>Store Name</th>
                  <td>{orderDetails.storeName}</td>
                </tr>
                <tr>
                  <th>Total Price</th>
                  <td>{currencyCode + " " + orderDetails.totalPrice.toLocaleString()}</td>
                </tr>
                <tr>
                  <th>Package Name</th>
                  <td>{orderDetails.packageName}</td>
                </tr>
                <tr>
                  <th>Package Type</th>
                  <td>{orderDetails.packageType}</td>
                </tr>
                <tr>
                  <th>Payment Mode</th>
                  <td>{orderDetails.paymentMode}</td>
                </tr>
                <tr>
                  <th>Total Weight</th>
                  <td>{orderDetails.totalWeight} kg</td>
                </tr>
                <tr>
                  <th>Order Channel</th>
                  <td>{orderDetails.orderChannel}</td>
                </tr>
                <tr>
                  <th>Total Packages</th>
                  <td>{orderDetails.totalPackages}</td>
                </tr>
                <tr>
                  <th>Channel Order ID</th>
                  <td>{orderDetails.channelOrderId}</td>
                </tr>
                <tr>
                  <th>Product Category</th>
                  <td>{orderDetails.productCategory}</td>
                </tr>
              </table>
    
              <h2>Products</h2>
              <table>
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>Product Name</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Line Item ID</th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetails.products.map((entry, index) => (
                    <tr key={index}>
                      <td>{entry.sku}</td>
                      <td>{entry.productName}</td>
                      <td>{entry.quantity}</td>
                      <td>{currencyCode + " " + entry.unitPrice.toLocaleString()}</td>
                      <td>{entry.lineItemId}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
    
              <br />
            </div>
          </Grid>
        </Grid>
      </>
    );
    
  } else {
    return (
      <div className={classes.policieswrapper}>No Package Details Found</div>
    )
  }


}

export default ShipRazorPackageDetails