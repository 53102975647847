import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import AlertDialog from "../../../../components/AlertDialog/index.es6";
import { CircularProgress, Switch } from "@mui/material";
import { getPartnerGuid } from "../../../../lib/access.es6";
import { COVER_TYPES, PRODUCT_TYPES, PRODUCT_TYPES_FREE } from "../../PoductConstants";
import MultipleSelectChip from "../../../PartnerStatus/components/Edit/MultipleSelectChip";


const initialState = {
  name: '',
  type: '',
  code: '',
  cover_type: '',
  loyalty_premium_in_cents: '',
  tag: '',
  minimum_age: '',
  maximum_age: '',
  waiting_period_days: '',
  lapse_period_days: '',
  cool_off_period_hours: '',
  eligibility_threshold: '',
  debit_grace_days: '',
  notes:'',
}
export default function AddProduct(props) {
  const [
    { name,
      type,
      code,
      cover_type,
      loyalty_premium_in_cents,
      tag,
      minimum_age,
      maximum_age,
      waiting_period_days,
      lapse_period_days,
      cool_off_period_hours,
      eligibility_threshold,
      debit_grace_days,
      notes }, setState
  ] = useState(initialState)


  const [isAddOnProduct, setIsAddOnProduct] = useState(false)
  const [mainProduct, setMainProduct] = useState(null)
  const [addOnTags, setAddOnTags] = useState([])

  useEffect(() => {
    auoPopulateMainProduct()
  }, [mainProduct])

  useEffect(() => {

    if(addOnTags.length === 1){
      setState(prevState => ({
        ...prevState,
        name: name + "_ with " + addOnTags.join(", "),
        code: code + "_with_" + addOnTags.join("_")
       }));
    }

    if(addOnTags.length === 2){
      setState(prevState => ({
         ...prevState,
         name: name + ", " + addOnTags[1],
         code: code + "_" + addOnTags[1],
        }));
    }

    if(addOnTags.length === 3){
      setState(prevState => ({
        ...prevState,
        name: name + ", " + addOnTags[2],
        code: code + "_" + addOnTags[2],
      }));
    }

  }, [addOnTags])



  const auoPopulateMainProduct = () => {
    if (mainProduct) {
      setState(prevState => ({
        ...prevState,
        name: mainProduct.name,
        type: mainProduct.type,
        code: mainProduct.code,
        cover_type: mainProduct.cover_type,
        loyalty_premium_in_cents: mainProduct.loyalty_premium_in_cents,
        // tag: mainProduct.tag,
        minimum_age: mainProduct.minimum_age,
        maximum_age: mainProduct.maximum_age,
        waiting_period_days: mainProduct.waiting_period_days,
        lapse_period_days: mainProduct.lapse_period_days,
        cool_off_period_hours: mainProduct.cool_off_period_hours,
        eligibility_threshold: mainProduct.eligibility_threshold,
        debit_grace_days: mainProduct.debit_grace_days,
        notes: mainProduct.notes,
      }));
    }
  }


  const onChange = e => {
    const { name, value } = e.target;
    if (name === 'code') {
      setState(prevState => ({ ...prevState, [name]: replaceSpacesWithUnderscores(value) }));

    } else {
      setState(prevState => ({ ...prevState, [name]: value }));
    }

    if (name === 'type') {
      props.updateDialogProductType(value)
    }
  };

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidProductCode', (value) => {
      return !value.includes(" ")
    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidProductCode');
    }
  });

  const submit = () => {
    console.log('sumiting');
    const partner = getPartnerGuid();

    const payload = {
      name,
      type,
      code: code.length > 0 ? code : replaceSpacesWithUnderscores(name),
      cover_type,
      tag,
      minimum_age: Number(minimum_age),
      maximum_age: Number(maximum_age),
      waiting_period_days: Number(waiting_period_days),
      lapse_period_days: Number(lapse_period_days),
      cool_off_period_hours: Number(cool_off_period_hours),
      eligibility_threshold: Number(eligibility_threshold),
      debit_grace_days: Number(debit_grace_days),
      partnerGuid: partner,
      partner_guid: partner,
      active: true,
      notes,
    };

    if (isAddOnProduct) {
      payload.tag = JSON.stringify({
        mainProduct: { guid: mainProduct.guid },
        tags: addOnTags.join(','),
      });
    }

    if (PRODUCT_TYPES_FREE.includes(type)) {
      payload.loyalty_premium_in_cents = loyalty_premium_in_cents;
    }

    props.addProduct(payload);
  }

  const reset = () => {
    setState(initialState);
    setAddOnTags([])
    setMainProduct(null)
  }

  const handleProductCreatedSuccessfully = () => {
    props.resetAddProductSuccessAlert()
    props.goToNextStep()
    reset()
  }

  const handleProductCreateError = () => {
    props.resetAddProductErrorAlert()
  }

  const replaceSpacesWithUnderscores = (text) => text.replaceAll(" ", "_")


  return (
    <>
      <AlertDialog
        custom
        show={props.showAddProductProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Creating product</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showAddProductSuccessAlert}
        size="sm"
        title={'Product created'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleProductCreatedSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>

      <AlertDialog
        show={props.showAddProductErrorAlert}
        danger
        title={'Error creating product'}
        onConfirm={() => handleProductCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.addProductError ? props.addProductError.message : ''}
      </AlertDialog>

<ValidatorForm onSubmit={() => submit()} >

      <Grid container spacing={2}>

        <Grid item lg={12} md={12} xs={12} style={{ textAlign: 'left', marginLeft: '1%' }}>
          <label>is an add-on product </label>
          <Switch
            checked={isAddOnProduct}
            onChange={() => { setIsAddOnProduct(!isAddOnProduct) }}
            inputProps={{ 'aria-label': 'controlled' }}
            validators={['']}
          />
        </Grid>

       {
        isAddOnProduct &&
        <Grid item lg={6} md={6} xs={12} style={{ marginLeft: 'auto' }}>
          <SelectValidator label='main product'
            id="main product"
            value={mainProduct ? mainProduct : ''}
            name='type'
            onChange={(e) => { setMainProduct(e.target.value) }}
            className="form-control"
            type="text"
            variant="outlined"
            validators={['required']}
            errorMessages={['Please select main product']}
            fullWidth
            style={{ width: '100%' }}

          >
            <MenuItem value="">{'Please select main product'}</MenuItem>
            {
              props.mainProducts.map((product) => {
                return <MenuItem value={product}>{product.name}</MenuItem>
              })
            }
          </SelectValidator>
        </Grid>
        }


        <Grid item lg={6} md={6} xs={12} style={{ display: isAddOnProduct ? 'block' : 'none ' }}>
          <MultipleSelectChip
            constantFields={props.addOns}
            fieldLabel="add ons"
            fields={addOnTags}
            setFields={setAddOnTags}
            multiple={true}
            displayLabel={false}
            // style={{ width: '50%' }}
            style={{ width: '100%' }}

          />
        </Grid>



        <Grid item lg={6} md={6} xs={12}><TextValidator
          onChange={onChange}
          label={'product name '}
          value={name}
          name='name'
          fullWidth
          variant="outlined"
          type="text"
          placeholder='name'
          validators={['required']}
          errorMessages={['Please enter product name']}
          style={{ width: '100%' }}
        />
        </Grid>

        <Grid item lg={6} md={6} xs={12}><TextValidator
          onChange={onChange}
          label='product code'
          name='code'
          value={code || replaceSpacesWithUnderscores(name)}
          fullWidth
          variant="outlined"
          type="text"
          placeholder='code'
          validators={['required', 'isValidProductCode']}
          errorMessages={['Please enter aproduct code', 'should not contain spaces']}
          style={{ width: '100%' }}

        /> </Grid>

        <Grid item lg={3} md={6} xs={12} style={{ display: isAddOnProduct ? 'block' : 'none ' }}>
          <SelectValidator label='product type'
            id="product type"
            value={type}
            name='type'
            onChange={onChange}
            className="form-control"
            type="text"
            variant="outlined"
            validators={['required']}
            errorMessages={['Please select type']}
            style={{ width: '100%' }}
          >
            <MenuItem value="">{'Please select type'}</MenuItem>
            {
              PRODUCT_TYPES.map((type) => {
                return <MenuItem value={type}>{type}</MenuItem>
              })
            }
          </SelectValidator> </Grid>



        <Grid item lg={3} md={6} xs={12}> <SelectValidator label='product type'
          id="product type"
          value={type}
          name='type'
          onChange={onChange}
          className="form-control"
          type="text"
          variant="outlined"
          validators={['required']}
          errorMessages={['Please select type']}
          style={{ width: '100%' }}
        >
          <MenuItem value="">{'Please select type'}</MenuItem>
          {
            PRODUCT_TYPES.map((type) => {
              return <MenuItem value={type}>{type}</MenuItem>
            })
          }
        </SelectValidator> </Grid>
        <Grid item lg={3} md={6} xs={12}> <SelectValidator
          value={cover_type}
          label='cover type'
          id="cover type"
          name='cover_type'
          onChange={onChange}
          className="form-control"
          fullWidth
          type="text"
          variant="outlined"
          validators={['required']}
          errorMessages={['Please select cover type']}
          style={{ width: '100%' }}
        >
          <MenuItem value="">{'Please select cover type'}</MenuItem>
          {
            COVER_TYPES.map((type) => {
              return <MenuItem value={type}>{type}</MenuItem>
            })
          }
        </SelectValidator> </Grid>


        {
          PRODUCT_TYPES_FREE.includes(type) &&
          <Grid item lg={3} md={6} xs={12}><TextValidator
            onChange={onChange}
            value={loyalty_premium_in_cents}
            label='loyalty premium in cents'
            name='loyalty_premium_in_cents'
            fullWidth
            variant="outlined"
            type="text"
            placeholder='amount'
            validators={['required']}
            errorMessages={['Please enter loyalty premium in cents']}
          /> </Grid>
        }


        <Grid item lg={3} md={6} xs={12} style={{ display: isAddOnProduct ? 'none' : 'block ' }}>
          <TextValidator
          onChange={onChange}
          value={tag}
          label='product tag'
          name='tag'
          fullWidth
          variant="outlined"
          type="text"
          placeholder='amount'
          style={{ width: '100%' }}

        />
        </Grid>


        <Grid item lg={3} md={6} xs={12}><TextValidator
          onChange={onChange}
          value={minimum_age}
          label='minimum age'
          name='minimum_age'
          fullWidth
          variant="outlined"
          type="number"
          style={{ width: '100%' }}
          placeholder='minimum age'
          validators={['required']}
          errorMessages={['Please enter minimum age']}
        /></Grid>
        <Grid item lg={3} md={6} xs={12}><TextValidator
          onChange={onChange}
          value={maximum_age}
          label='maximum age'
          name='maximum_age'
          fullWidth
          variant="outlined"
          type="number"
          style={{ width: '100%' }}
          placeholder='maximum age'
          validators={['required']}
          errorMessages={['Please enter maximum age']}
        /></Grid>
        <Grid item lg={3} md={6} xs={12}><TextValidator
          onChange={onChange}
          value={waiting_period_days}
          label='waiting period'
          name='waiting_period_days'
          fullWidth
          variant="outlined"
          type="number"
          placeholder='waiting period'
          style={{ width: '100%' }}
          validators={['required']}
          errorMessages={['Please enter product waiting period']}
          aria-describedby="standard-weight-helper-text"
          InputProps={{
            endAdornment: (<InputAdornment position="end">
              days
            </InputAdornment>),

            'aria-label': 'weight',
          }}
        /></Grid>
        <Grid item lg={3} md={6} xs={12}><TextValidator
          onChange={onChange}
          value={lapse_period_days}
          label='lapse period'
          name='lapse_period_days'
          fullWidth
          variant="outlined"
          type="number"
          placeholder='lapse period in days'
          style={{ width: '100%' }}
          validators={['required']}
          errorMessages={['Please enter product lapse period']}
          aria-describedby="standard-weight-helper-text"
          InputProps={{
            endAdornment: (<InputAdornment position="end">
              days
            </InputAdornment>),

            'aria-label': 'weight',
          }}
        /></Grid>
        <Grid item lg={3} md={6} xs={12}><TextValidator
          onChange={onChange}
          value={cool_off_period_hours}
          label='cool off period'
          name='cool_off_period_hours'
          fullWidth
          variant="outlined"
          style={{ width: '100%' }}
          type="number"
          placeholder='cool off period in hours'
          InputProps={{
            endAdornment: (<InputAdornment position="end">
              hours
            </InputAdornment>),

            'aria-label': 'weight',
          }}
        /></Grid>



        {
          PRODUCT_TYPES_FREE.includes(type) &&
          <Grid item lg={3} md={6} xs={12}><TextValidator
            onChange={onChange}
            value={eligibility_threshold}
            label='eligibility threshold'
            name='eligibility_threshold'
            fullWidth
            variant="outlined"
            type="number"
            placeholder='eligibility threshold'
            validators={['required']}
            errorMessages={['Please enter eligibility threshold']}
            style={{ width: '100%' }}
          /></Grid>
        }

        <Grid item lg={3} md={6} xs={12}><TextValidator
          onChange={onChange}
          value={debit_grace_days}
          label='debit grace days'
          name='debit_grace_days'
          fullWidth
          variant="outlined"
          type="number"
          placeholder='debit grace days'
          validators={['required']}
          errorMessages={['Please enter product debit grace days']}
          style={{ width: '100%' }}
        /></Grid>

        <Grid item lg={6} md={6} xs={12}>
          <TextValidator
          onChange={onChange}
          value={notes}
          label='notes'
          name='notes'
          fullWidth
          variant="outlined"
          rows={5}
          multiline={true}
          placeholder='notes'
          style={{ width: '100%' }}
        /></Grid>

        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button variant="contained" type="submit" style={{ marginRight: '2%' }}> Save </Button>
          <Button variant="contained" color="secondary" style={{ marginRight: '2%' }}
            onClick={() => reset()}
          > Reset</Button>
        </Grid>

      </Grid>

    </ValidatorForm >
    </>
  );
}
