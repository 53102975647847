import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({

  selectpolicyWrapper: {
    "& input": {
      background: "#F7F7F7",
      borderRadius: "8px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#000000",
    },
    "& label": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      color: "#000000",
      transformOrigin: "bottom",
    }
  },
  claimBtn:{
    "& button":{
      background: "#032A37",
      color: "#fff !important",
      borderRadius: "8px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      boxShadow: "none",
      padding: "10px",
      textTransform: "capitalize",
      marginBottom: "30px",
      marginLeft: '10px',
      marginTop: "10px",
      "&:hover": {
          background: "#032A37",
          boxShadow: "none",
      }
    },
  },
  headingclaim:{
    "& h4":{
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#000000"
    }
  }

}));