import React from "react";
import { connect } from "react-redux";
import Policies from "./Components/Policies/Policies";
import { getAllPoliciesRequest } from "../../Redux/actions";


class NgsGroupPolicies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentWillMount() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      this.props.dispatch(getAllPoliciesRequest({ guid: currentCustomer.guid }))
    }
  }




  render() {

    if(this.props.NGSGroupcustomerStatusData.policies){
      return (
        <>
  
          <Policies
            loader={this.props.NGSGroupcustomerStatusData.get_policies_loader}
            policies={this.props.NGSGroupcustomerStatusData.policies}
          />
        </>
      )
    }

  


  }
}

export default connect((state) => ({
  customerData: state.currentCustomer,
  documentGenerationData: state.documentGeneration,
  NGSGroupcustomerStatusData: state.NGSGroupcustomerStatusData
}))(NgsGroupPolicies);