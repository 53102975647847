const uniqueCodes = [
    "ALREADY_REGISTERED",
    "APPROVED_CASHBACK",
    "APPROVED_CLAIM",
    "BUNDLE_ADDITION",
    "CANCEL_DEBIT_ORDER_POLICY",
    "CANCEL_POLICY_NO_COVERAGE",
    "CANCEL_POLICY_STILL_COVERAGE",
    "CASHBACK_QUALIFICATION",
    "CHANGE_POLICY_PAID",
    "CHANGE_POLICY_UNPAID",
    "CLAIM_CALLBACK",
    "CLAIM_CALLBACK_WITHOUT_POLICY",
    "CONFIRM_RECURRING_PAYMENTS",
    "CONFIRM_RECURRING_PAYMENTS_DATES",
    "DAY_OF_LOYALTY_END",
    "DAY_OF_SUSPENSION_NON_PAYMENT_FAMILY_12_PM",
    "DAY_OF_SUSPENSION_NON_PAYMENT_FAMILY_3_PM",
    "DAY_OF_SUSPENSION_NON_PAYMENT_FAMILY_9_AM",
    "DAY_OF_SUSPENSION_NON_PAYMENT_INDIVIDUAL_12_PM",
    "DAY_OF_SUSPENSION_NON_PAYMENT_INDIVIDUAL_3_PM",
    "DAY_OF_SUSPENSION_NON_PAYMENT_INDIVIDUAL_9_AM",
    "DEBIT_ORDER_CANCELLATION",
    "DEBIT_ORDER_CREATION",
    "DEBIT_ORDER_FAILED",
    "DEBIT_ORDER_SUCCESSFUL",
    "EDUCATIONAL_SMS_1ST_ORANGE_20180913",
    "EDUCATIONAL_SMS_2nd_ORANGE_20180918",
    "EDUCATIONAL_SMS_5TH_EQUITY_20180911",
    "EDUCATIONAL_SMS_6TH_EQUITY_20180918",
    "EDUCATIONAL_SMS_EQUITEL_20180925",
    "EDUCATIONAL_SMS_EQUITEL_20181008",
    "EDUCATIONAL_SMS_EQUITEL_20181115",
    "EDUCATIONAL_SMS_EQUITEL_20190604",
    "EDUCATIONAL_SMS_EQUITEL_20190611",
    "EDUCATIONAL_SMS_EQUITEL_20190618",
    "EDUCATIONAL_SMS_EQUITEL_20190624",
    "EDUCATIONAL_SMS_EQUITY_20190507",
    "EDUCATIONAL_SMS_EQUITY_20190701",
    "EDUCATIONAL_SMS_EQUITY_20190708",
    "EDUCATIONAL_SMS_EQUITY_20190715",
    "EDUCATIONAL_SMS_EQUITY_20190805",
    "EDUCATIONAL_SMS_ORANGE_20181009",
    "EDUCATIONAL_SMS_ORANGE_20181016",
    "EDUCATIONAL_SMS_ORANGE_20181023",
    "EDUCATIONAL_SMS_ORANGE_20181030",
    "EDUCATIONAL_SMS_ORANGE_20190327",
    "EDUCATIONAL_SMS_ORANGE_20190507",
    "EDUCATIONAL_SMS_ORANGE_20190521",
    "EDUCATIONAL_SMS_ORANGE_20190611",
    "EDUCATIONAL_SMS_ORANGE_20190625",
    "EDUCATIONAL_SMS_ORANGE_20190626",
    "EDUCATIONAL_SMS_ORANGE_20190709",
    "EDUCATIONAL_SMS_ORANGE_20190822_GROUP_A",
    "EDUCATIONAL_SMS_ORANGE_20190822_GROUP_B",
    "EDUCATIONAL_SMS_ORANGE_20190822_GROUP_C",
    "EDUCATIONAL_SMS_ORANGE_20190829",
    "EDUCATIONAL_SMS_ORANGE_20190902",
    "EDUCATIONAL_SMS_ORANGE_20190902_individual",
    "EDUCATIONAL_SMS_ORANGE_20200131",
    "EDUCATIONAL_SMS_ORANGE_20200204",
    "EDUCATIONAL_SMS_ORANGE_20200220",
    "EDUCATIONAL_SMS_ORANGE_20200224",
    "EDUCATIONAL_SMS_ORANGE_20200227",
    "EDUCATIONAL_SMS_ORANGE_20200303",
    "EDUCATIONAL_SMS_equity_20190514",
    "EDUCATIONAL_SMS_equity_20190527",
    "EDUCATIONAL_SMS_equity_20190528",
    "EDUCATIONAL_SMS_orange_20190514",
    "EDUCATIONAL_SMS_orange_20190521",
    "EDUCATIONAL_SMS_orange_20190604",
    "EDUCATIONAL_SMS_orange_20190618",
    "EIGHT_DAYS_TO_SUSPENSION_NON_PAYMENT_FAMILY",
    "EIGHT_DAYS_TO_SUSPENSION_NON_PAYMENT_INDIVIDUAL",
    "EQUITY_PAYMENT_DOWNTIME_AFTERMATH_SMS_20190812",
    "FAILED_PAYMENT",
    "FIRST_OF_THE_MONTH_ NOT_RENEWED",
    "FIRST_OF_THE_MONTH_ RENEWED",
    "FIRST_PREMIUM_PAID",
    "FOURTEEN_DAYS_TO_LOYALTY_END",
    "FOURTEEN_DAYS_TO_SUSPENSION_NON_PAYMENT",
    "FOURTEENDAYS_BEFORE_LOYALTY_EXPIRE",
    "INVALID_CLAIM",
    "INVITE_FRIEND",
    "KYC_UPDATE",
    "LAST_DAY_TO_SUSPENSION_NON_PAYMENT",
    "LAST_DAY_TO_TERMINATION_NON_FIRST_NON_PAYMENT",
    "LAST_DAY_UNPAID_FIRST_PREMIUM",
    "LAST_DAY_UNPAID_FIRST_PREMIUM_FAMILY",
    "LAST_DAY_UNPAID_FIRST_PREMIUM_INDIVIDUAL",
    "LOYALTY_NON_ELIGIBLE",
    "LOYALTY_NOT_RENEWED",
    "LOYALTY_OPT_OUT",
    "LOYALTY_REAL_TIME_NOTIFICATION_BASIC",
    "LOYALTY_REAL_TIME_NOTIFICATION_DOUBLE",
    "LOYALTY_REAL_TIME_NOTIFICATION_TRIPLE",
    "LOYALTY_RENEWAL_NEW_WALLET_MONEY",
    "LOYALTY_RENEWAL_NON_ELIGIBLE",
    "LOYALTY_RENEWED",
    "MARKETING_EQUITY_ACTIVE_UPSELL_FAMILY_20180607",
    "MARKETING_EQUITY_ACTIVE_UPSELL_INDIVIDUAL_20180607",
    "MARKETING_EQUITY_FOLLOW_UP_ONE_DAY_20180509",
    "MARKETING_EQUITY_FOLLOW_UP_ONE_DAY_20180510",
    "MARKETING_EQUITY_FOLLOW_UP_ONE_DAY_20180511",
    "MARKETING_EQUITY_FOLLOW_UP_ONE_DAY_20180512",
    "MARKETING_EQUITY_FOLLOW_UP_SIX_DAYS_20180509",
    "MARKETING_EQUITY_FOLLOW_UP_SIX_DAYS_20180510",
    "MARKETING_EQUITY_FOLLOW_UP_SIX_DAYS_20180511",
    "MARKETING_EQUITY_FOLLOW_UP_SIX_DAYS_20180512",
    "MARKETING_EQUITY_LOYALTY_A_20180424",
    "MARKETING_EQUITY_LOYALTY_A_20180503",
    "MARKETING_EQUITY_LOYALTY_B_20180424",
    "MARKETING_EQUITY_NON_QUALIFIER_A_20180424",
    "MARKETING_EQUITY_NON_QUALIFIER_B_20180424",
    "MARKETING_EQUITY_active_UPSELL_FAMILY_20180607",
    "MARKETING_EQUITY_active_UPSELL_INDIVIDUAL_20180607",
    "MARKETING_EQUITY_loyalty_b_20180503",
    "MARKETING_EQUITY_non_qualifier_a_20180503",
    "MARKETING_EQUITY_non_qualifier_b_20180503",
    "MEMBER_REQUEST_CLAIM",
    "NEW_LOYALTY_CONGRATS",
    "NEW_POLICY_BENEFITS",
    "NEW_POLICY_BENEFITS_FAMILY",
    "NEW_POLICY_BENEFITS_INDIVIDUAL",
    "NEW_POLICY_BENEFITS_START",
    "NEW_POLICY_BENEFITS_UNPAID_SEVEN_DAYS",
    "NEW_POLICY_BUNDLE",
    "NEW_POLICY_DETAILS",
    "NINE_DAYS_TO_SUSPENSION_NON_PAYMENT_FAMILY",
    "NINE_DAYS_TO_SUSPENSION_NON_PAYMENT_INDIVIDUAL",
    "NO_KYC",
    "ONE_DAY_AFTER_SUSPENSION_NON_PAYMENT_INDIVIDUAL",
    "ONE_DAY_TO_LOYALTY_END",
    "ONE_DAY_TO_SUSPENSION_NON_PAYMENT_FAMILY",
    "ONE_DAY_TO_SUSPENSION_NON_PAYMENT_INDIVIDUAL",
    "OPT_OUT_BLAST_SMS",
    "OPT_OUT_OF_SERVICE",
    "OPTED_IN",
    "OPTED_OUT_NO_COVERAGE",
    "OPTED_OUT_STILL_COVERAGE",
    "ORANGE_NEW_OM_20190221",
    "PAYMENT_ABOVE_ONE_PREMIUM",
    "PAYMENT_ABOVE_ONE_PREMIUM_WITH_IRREGULAR_PAYMENT",
    "PAYMENT_BELOW_ONE_MONTH",
    "PAYMENT_BELOW_ONE_MONTH_WITH_IRREGULAR_PAYMENT",
    "PAYMENT_ERROR_INSUFFICIENT",
    "PAYMENT_ERROR_PIN_TIMEOUT",
    "PAYMENT_FAILURE_NOTIFICATION",
    "PAYMENT_INSUFFICIENT_FUNDS",
    "PREMIUM_REFUND",
    "RECOMMEND_RECURRING_PAYMENT",
    "REFERRAL_GRATITUDE",
    "REFERRAL_ON_CLAIM_PAYOUT",
    "REFERRAL_ON_NEW_PAID_POLICY",
    "REJECTED_CLAIM",
    "REJECTED_CLAIM_GROUP_ADMIN",
    "REQUEST_BENEFICIARY_DETAILS",
    "REQUEST_SPOUSE_DETAILS",
    "SEVEN_DAYS_AFTER_SUBSCRIPTION_NO_PREMIUM",
    "SEVEN_DAYS_TO_LOYALTY_END",
    "SEVEN_DAYS_TO_SUSPENSION_NON_PAYMENT",
    "SEVEN_DAYS_TO_SUSPENSION_NON_PAYMENT_FAMILY",
    "SEVEN_DAYS_TO_SUSPENSION_NON_PAYMENT_FIRST_PARTIAL_PREMIUM",
    "SEVEN_DAYS_TO_SUSPENSION_NON_PAYMENT_FIRST_PREMIUM",
    "SEVEN_DAYS_TO_SUSPENSION_NON_PAYMENT_INDIVIDUAL",
    "SEVEN_DAYS_TO_SUSPENSION_NON_PAYMENT_PARTIAL",
    "SEVEN_DAYS_TO_TERMINATION_BUNDLE",
    "SEVEN_DAYS_TO_TERMINATION_NON_FIRST_NON_PAYMENT",
    "SevenDaysAfterSubscriptionNoPremiumReceived",
    "SIX_DAYS_TO_SUSPENSION_NON_PAYMENT_FAMILY",
    "SIX_DAYS_TO_SUSPENSION_NON_PAYMENT_INDIVIDUAL",
    "SMS_blast_equityguid_1535451058006",
    "SMS_blast_equityguid_1535460074595",
    "SMS_blast_equityguid_1535460496281",
    "SMS_blast_equityguid_1535461028871",
    "SMS_blast_equityguid_1536228120321",
    "SMS_blast_equityguid_1536228230179",
    "SMS_blast_equityguid_1536228327173",
    "SMS_blast_equityguid_1536228842000",
    "SUSPENSION_BUNDLE",
    "SUSPENSION_NON_PAYMENT",
    "SUSPENSION_NON_PAYMENT_WITH_BALANCE",
    "TERMINATION_BUNDLE",
    "TERMINATION_NON_FIRST_NON_PAYMENT",
    "TERMINATION_NON_FIRST_NON_PAYMENT_WITH_BALANCE",
    "TERMINATION_UNPAID_FIRST_PAYMENT",
    "TERMINATION_UNPAID_FIRST_PAYMENT_FAMILY",
    "TERMINATION_UNPAID_FIRST_PAYMENT_FAMILY_WITH_BALANCE",
    "TERMINATION_UNPAID_FIRST_PAYMENT_INDIVIDUAL",
    "TERMINATION_UNPAID_FIRST_PAYMENT_INDIVIDUAL_WITH_BALANCE",
    "TERMINATION_UNPAID_FIRST_PAYMENT_WITH_BALANCE",
    "TERMINATION_UNPAID_FIRST_PREMIUM",
    "TESTING",
    "THIRTY_DAYS_TO_SUSPENSION_NON_PAYMENT",
    "THREE_DAYS_AFTER_SUBSCRIPTION_NO_PREMIUM",
    "THREE_DAYS_AFTER_SUBSCRIPTION_NO_PREMIUM_FAMILY",
    "THREE_DAYS_AFTER_SUBSCRIPTION_NO_PREMIUM_INDIVIDUAL",
    "THREE_DAYS_TO_LOYALTY_END",
    "THREE_DAYS_TO_SUSPENSION_NON_PAYMENT",
    "THREE_DAYS_TO_SUSPENSION_NON_PAYMENT_FAMILY",
    "THREE_DAYS_TO_SUSPENSION_NON_PAYMENT_FIRST_PARTIAL_PREMIUM",
    "THREE_DAYS_TO_SUSPENSION_NON_PAYMENT_FIRST_PREMIUM",
    "THREE_DAYS_TO_SUSPENSION_NON_PAYMENT_INDIVIDUAL",
    "THREE_DAYS_TO_SUSPENSION_NON_PAYMENT_PARTIAL",
    "THREE_DAYS_TO_TERMINATION_BUNDLE",
    "THREE_DAYS_TO_TERMINATION_NON_FIRST_NON_PAYMENT",
    "THREE_DAYS_UNPAID_FIRST_PREMIUM",
    "TWENTY_DAYS_TO_LOYALTY_END",
    "TWENTYDAYS_BEFORE_LOYALTY_EXPIRE",
    "TWO_DAYS_AFTER_ALL_DOCS_PENDING_CLAIM",
    "TWO_DAYS_TO_LOYALTY_END",
    "TWO_DAYS_TO_SUSPENSION_NON_PAYMENT_FAMILY",
    "TWO_DAYS_TO_SUSPENSION_NON_PAYMENT_INDIVIDUAL",
    "TWO_DAY_BEFORE_DEBIT_ORDER_REMIND",
    "TEN_DAYS_TO_SUSPENSION_NON_PAYMENT_FAMILY",
    "TEN_DAYS_TO_SUSPENSION_NON_PAYMENT_INDIVIDUAL",
    "VALID_CLAIM",
    "VALID_PARTIAL_PREMIUM_PAYMENT",
    "VALID_PARTIAL_PREMIUM_PAYMENT_WITH_IRREGULAR_PAYMENT",
    "VALID_PREMIUM_PAYMENT",
    "VALID_PREMIUM_PAYMENT_SIXTY_DAYS",
    "WAITING_PERIOD_END",
    "15_DAYS_TO_SUSPENSION_NON_PAYMENT"
];

module.exports = uniqueCodes; 