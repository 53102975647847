export const generateNewQoutePayload = (policy, newPlan) => {

  console.log('generateNewQoutePayload: ', {policy, newPlan});
  if(!policy && !newPlan) return null;

  const dependantsWithCurrentPlan = policy.quotation.policy_schedule_entries
  const dependantsWithNewPlan = dependantsWithCurrentPlan.map(entry => {
    return {
      ...entry.dependant,
      benefits: newPlan.benefits,
      premium_amount_in_cents: getPremiumInCents(entry, newPlan)
    }
  })

  console.log('newPlan: ', newPlan );
  console.log('dependantsWithCurrentPlan: ', dependantsWithCurrentPlan );
  console.log('dependantsWithNewPlan: ', dependantsWithNewPlan );

  const newQoutePayload = {
    "product_guid": newPlan.product_guid,
    "product_name": newPlan.product_name,
    "product_code": newPlan.option,
    "customer_guid": policy.customer_guid,
    "partner": policy.partner,
    "lapse_period": 3,
    "premium_granularity": "month",
    "premium_cardinality": 1,
    "policy_schedule_entries": [
      ...dependantsWithNewPlan
    ],
    total_premmius: (dependantsWithNewPlan.reduce((psum, dependant) => psum + dependant.premium_amount_in_cents, 0))
  }
  return newQoutePayload
}

const getPremiumInCents = (entry, newPlan) => {
  const premium_amount_in_cents = entry.dependant.relationship === 'Child'? newPlan.child_premium * 100 : newPlan.adult_premim * 100
  return premium_amount_in_cents
}

export const generateChangeQoutePayload = (newQuote, existingPolicy, effected_At) => {
  return {
    "policy_guid": existingPolicy.guid,
    "quotation_guid": newQuote.guid,
    "customer_guid": newQuote.customer_guid,
    "start_date": effected_At,
  }
}
