import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProductsHeader from "../../../../NewProductList/components/ProductsHeader/ProductsHeader";
import { CircularProgress } from "@mui/material";

export default function ViewExtendedFamilyProductConfig(props) {
  const [expandedExtendedFamilyProductConfigs, setExpandedExtendedFamilyProductConfigs] = React.useState(true)

  if (props.isLoading) {
    return <div style={{ textAlign: 'center' }}>
      <CircularProgress />
    </div>
  }

  return (
    <div style={{ marginTop: "0%" }}>

      <>
        <Accordion expanded={expandedExtendedFamilyProductConfigs}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon onClick={() => { setExpandedExtendedFamilyProductConfigs(!expandedExtendedFamilyProductConfigs) }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <ProductsHeader
              title={"Extended Configs"}
              showEditButton={false}
              onEditButtonClick={props.openUpdateExtendedFamilyProductConfigsDialog}
            />
          </AccordionSummary>
          <AccordionDetails>
            {props.children}
          </AccordionDetails>
        </Accordion>
        <br />
        <br />
      </>

    </div>
  );
}
