import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({

  louactionbtns:{
    "& ul":{
      "& li":{
        fontFamily: 'Open Sans',
        fontStyle: "normal",
        fontSize: "12px",
        lineHeight: "25px",
        color: "#000000",
        textAlign: "inherit"
      }
    }
  }
}));