import * as types from '../../lib/actionTypes.es6';

// TODO: Move the session status (closed vs open) here
export const initialState = {
  language: null,
  partner: null,
  customerBarState: true,
  isSearchBar: false,
};

export default function updateLanguageReducer(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_LANGUAGE:
      localStorage.setItem('language', action.payload);
      return { ...state, language: action.payload };

    case 'SET_CURRENT_PARTNER':
      localStorage.setItem('partner', JSON.stringify(action.payload));
      return { ...state, partner: action.payload };

    case 'SHOW_CUSTOMER_BAR':
      return { ...state, customerBarState: true };

    case 'HIDE_CUSTOMER_BAR':
      return { ...state, customerBarState: false };

    case 'CUSTOMER_BAR_SHOW_USER':
      return { ...state, isSearchBar: false };

    case 'CUSTOMER_BAR_SHOW_SEARCH':
      return { ...state, isSearchBar: true };

    case types.USER_LOGOUT:
      // NOTE: Maintain current language during logout
      return state;

    default:
      return state;
  }
}
