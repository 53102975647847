import * as types from '../../lib/actionTypes.es6';

export function tigoLoyaltyUploadRequest(data) {
  return {
    type: types.TIGO_UPLOAD_LOYALTY_REQUEST,
    payload: data,
  };
}

export function tigoLoyaltyUploadSuccess(response) {
  return {
    type: types.TIGO_UPLOAD_LOYALTY_SUCCESS,
    payload: response,
  };
}

export function tigoLoyaltyUploadFailure(error) {
  return {
    type: types.TIGO_UPLOAD_LOYALTY_FAILURE,
    payload: error,
  };
}
