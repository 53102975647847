import React from 'react';
import { connect } from 'react-redux';
import { getPartnerConfigsRequest } from '../PartnerStatus/actions.es6';

class Dashboards extends React.Component {
  componentWillMount() {
    this.props.dispatch(getPartnerConfigsRequest())
  }

  render() {
    const partnerConfig = this.props.partnerStatusData.partnerConfig
    const loading = this.props.partnerStatusData.partnerConfigLoader
    const dashboardUrl = partnerConfig ? partnerConfig.power_bi_dashboard_url_1 : null;

    return (
      <div>
        {
          loading
            ? <h3>Loading dashboard</h3>
            : (
              !dashboardUrl 
              ? <h3>No dashboard found! Please contact system admin</h3>
              : <iframe
              style={{
                overflow: 'hidden',
                height: '100%',
                border: 'none',
                width: '100%',
                position: 'absolute',
              }}
              src={dashboardUrl}
              title="Stats from Power BI"
              frameBorder="0"
              allowFullScreen
            ></iframe>)

        }

      </div>
    );
  }
}

export default connect(state => ({
  partnerStatusData: state.partnerStatus,
}))(Dashboards);