import {call, put, take} from "redux-saga/effects";
import * as types from "../../lib/actionTypes.es6";
import {
  addCustomerDependant,
  deleteCustomerDependant, getCustomerDependant,
  getCustomerDependants,
  updateCustomerDependant
} from "../../lib/requests.es6";
import * as actions from "./actions.es6";
import {unAuthorizedNotification} from "../../lib/access.es6";
import {GAloggerException} from "../../lib/monitoring.es6";

export function* getCustomerDependantsRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.GET_CUSTOMER_DEPENDANTS_REQUEST);
      const response = yield call(getCustomerDependants, payload);
      yield put(actions.getCustomerDependantsSuccess(response.data));
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.getCustomerDependantsFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* getCustomerDependantRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.GET_CUSTOMER_DEPENDANT_REQUEST);

      const response = yield call(getCustomerDependant, payload);
      yield put(actions.getCustomerDependantSuccess(response.data));
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.getCustomerDependantFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* addCustomerDependantRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.ADD_CUSTOMER_DEPENDANT_REQUEST);

      const response = yield call(addCustomerDependant, payload);
      yield put(actions.addCustomerDependantSuccess(response.data));
      yield put(actions.getCustomerDependantsRequest(payload.customer_guid));
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.addCustomerDependantFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* updateCustomerDependantRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.UPDATE_CUSTOMER_DEPENDANT_REQUEST);

      const response = yield call(updateCustomerDependant, payload);
      yield put(actions.updateCustomerDependantSuccess(response.data));
      yield put(actions.getCustomerDependantsRequest(payload.customer_guid));
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.updateCustomerDependantFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* deleteCustomerDependantRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.DELETE_CUSTOMER_DEPENDANT_REQUEST);

      const response = yield call(deleteCustomerDependant, payload.guid);
      yield put(actions.deleteCustomerDependantSuccess(response.data));
      yield put(actions.getCustomerDependantsRequest(payload.customer_guid));
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.deleteCustomerDependantFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

