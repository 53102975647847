import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import Button from '@mui/material/Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';



const PolicyHeader = (props) => {
	const classes = useStyle();
	return (
		<section className={classes.policyheader}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6} md={6}>
					<div className={classes.policytitle}>
						<h1>Generate Quote</h1>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={6}>
					<div className={classes.registerbtn}>
						<Button variant="contained" endIcon={<AddOutlinedIcon />} onClick={props.openRegisterCustomerDialog}>
							Register Customer
						</Button>
					</div>
				</Grid>
			</Grid>
		</section>
	)
}

export default PolicyHeader