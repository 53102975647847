import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../lib/actionTypes.es6';
import {
  deleteGroup,
  getGroups
} from '../../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../../lib/access.es6';
import { GAloggerException } from '../../../lib/monitoring.es6';


export function* getGroupsRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.GET_GROUPS_REQUEST);
      const response = yield call(getGroups, payload);
      yield put(actions.getGroupsSuccess(response.data));
    } catch (e) {
      yield put(
        actions.getGroupsFailed(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* deleteMyGroupRequestWatcher() {

  while (true) {
    try {
      const { payload } = yield take(types.DELETE_MY_GROUP_REQUEST);
      const response = yield call(deleteGroup, payload.groupId);
      yield put(actions.deleteMyGroupSuccess(response.data));
      yield put(actions.getGroupsRequest(payload));
    } catch (e) {
      yield put(
        actions.deleteMyGroupFailed(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
