import React, { useState, useEffect } from 'react'
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';


import MenuItem from '@mui/material/MenuItem';


import { useStyle } from "./Style";
import { Button, CircularProgress } from '@mui/material';
import AlertDialog from '../../../../components/AlertDialog/index.es6';
import { getLocalNumberLength, getPartner } from '../../../../lib/access.es6';

const RegisterCustomer = (props) => {
  const classes = useStyle();

  const [firstname, setFirstname] = useState('');
  const [middlename, setmiddlename] = useState('');
  const [surname, setsurname] = useState('');
  const [dateofbirth, setdateofbirth] = useState('');
  const [phonenumber, setphonenumber] = useState('');
  const [nationalid, setnationalid] = useState('');
  const [gender, setgender] = useState('');
  const [growernumber, setgrowernumber] = useState('');

  //validations
  useEffect(() => {

    const nationalIdRegex = '^(\\d{6}|\\d{8})$';
    const pattern = new RegExp(nationalIdRegex);
    ValidatorForm.addValidationRule('isValidNationalId', value => {
      return pattern.test(value);
    });


    ValidatorForm.addValidationRule('isValidPhoneNumber', value => {
      return value.length === getLocalNumberLength();
    });

    ValidatorForm.addValidationRule('isValidGrowerNumber', (value) => {
      const alphabetsCount = value.replace(/[^a-zA-Z]/g, '').length;

      const isMaxLengthValid = value.length === 9;

      const isFormatValid = /^[A-Za-z]{2}[0-9]+$/.test(value);

      return alphabetsCount >= 2 && isMaxLengthValid && isFormatValid;
    });

    ValidatorForm.addValidationRule('isValidPrincipalAge', (value) => {
      if (value) {
        const selectedDateObj = new Date(value);
        const currentDate = new Date();

        const ageDifference = currentDate.getFullYear() - selectedDateObj.getFullYear();

        return ageDifference >= 18 && ageDifference <= 75;
      }
      return true; 
    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidNationalId');
      ValidatorForm.removeValidationRule('isValidPhoneNumber');
      ValidatorForm.removeValidationRule('isValidGrowerNumber');
      ValidatorForm.removeValidationRule('isValidPrincipalAge');
    };
  }, [])

  //country calling code

  const internationalCallingCode = getPartner().country.international_calling_code

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'firstname') {
      setFirstname(value)
    }
    if (name === 'middlename') {
      setmiddlename(value)
    }
    if (name === 'surname') {
      setsurname(value)
    }
    if (name === 'dateofbirth') {
      setdateofbirth(value)
    }
    if (name === 'phonenumber') {
      setphonenumber(value)
    }
    if (name === 'nationalid') {
      setnationalid(value)
    }
    if (name === 'growernumber') {
      setgrowernumber(value)
    }
    if (name === 'gender') {
      setgender(value)
    }
  };
  const reset = () => {
    setFirstname('');
    setmiddlename('');
    setsurname('');
    setdateofbirth('');
    setphonenumber('');
    setnationalid('');
    setgrowernumber('');
    setgender('');
  }
  const registerCustomer = () => {
    const payload = {
      "msisdn": phonenumber,
      "first_name": firstname,
      "middlename": middlename,
      "surname": surname,
      "national_id": nationalid,
      "date_of_birth": dateofbirth,
      "external_identifier": growernumber,
      "gender": gender
    }
    props.registerCustomer(payload);
    reset()
  }

  return (

    <>
      <section >
        <Dialog open={props.openRegisterCustomerDialog} onClose={props.toggleRegisterCustomerDialog} fullWidth
          maxWidth="md">
          <ValidatorForm onSubmit={() => registerCustomer()} >
            <DialogTitle className={classes.registermodaltitle}>
              <div >
                <h3>Register Customer</h3>
              </div>
            </DialogTitle>
            <DialogContent className={classes.registerinputwrapper}>
              <DialogContentText>
                Fill the below details to register a customer
              </DialogContentText>
              <Grid container spacing={2} >
                <Grid item xs={12} sm={6} md={6}>
                  <TextValidator
                    name='firstname'
                    id="First-Name"
                    label="First Name"
                    value={firstname}
                    onChange={handleChange}
                    fullWidth
                    className={classes.registerinputs}
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Please enter first name']}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextValidator
                    name='middlename'
                    id="Middle-Name"
                    label="Middle Name"
                    value={middlename}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextValidator
                    name='surname'
                    id="Surname"
                    label="Surname"
                    value={surname}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Please enter surname name']}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextValidator
                    name='dateofbirth'
                    id="Date-of-birth"
                    label="Date of birth"
                    value={dateofbirth}
                    onChange={handleChange}
                    fullWidth
                    type='date'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    validators={['required', 'isValidPrincipalAge']}
                    errorMessages={['Please enter date of birth', 'principal must be at least 18 years and not more than 75 years']}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextValidator
                    name='phonenumber'
                    id="Phone"
                    label="Phone"
                    type="number"
                    value={phonenumber}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment
                        sx={{
                          paddingTop: "32px",
                        }}
                        position="start"
                      >{internationalCallingCode ? internationalCallingCode : ''}</InputAdornment>,
                      style: {
                        background: "#F7F7F7"
                      }
                    }}

                    variant="outlined"
                    validators={['isValidPhoneNumber']}
                    errorMessages={[`Please enter a valid ${getLocalNumberLength()} digit phone number`]}

                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextValidator
                    name='nationalid'
                    id="National-iD"
                    label="National ID"
                    type='number'
                    value={nationalid}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    validators={['required', 'isValidNationalId']}
                    errorMessages={['Please enter a valid national ID ','National ID must be between 6 and 8 numbers']}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <SelectValidator
                    name='gender'
                    labelId="select-gender"
                    id="select-gender"
                    value={gender}
                    label="Gender"
                    onChange={handleChange}
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Please select gender']}
                    style={{ width: "100%" }}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>

                  </SelectValidator>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextValidator
                    name='growernumber'
                    id="Grower-number"
                    label="Grower Number"
                    value={growernumber}
                    onChange={handleChange}
                    fullWidth
                    type='text'
                    variant="outlined"
                    validators={['required', 'isValidGrowerNumber']}
                    errorMessages={['Please enter a grower number', 'Grower number must be 9 characters and must contain at least two alphabetic characters']}
                  />
                </Grid>
              </Grid>

            </DialogContent>
            <DialogActions className={classes.registerbtns} >
              <Button variant="contained" type='submit'>Register Customer</Button>
              <Button variant="outlined" onClick={props.toggleRegisterCustomerDialog}>Cancel</Button>

            </DialogActions>
          </ValidatorForm>
        </Dialog>

        <AlertDialog
          custom
          show={props.registrationData.loader}
          size="sm"
          style={{ marginTop: '0', top: '30vh' }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={'cancel'}
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2>registering customer</h2>
        </AlertDialog>

        <AlertDialog
          success
          show={!!props.registrationData.customerInfo}
          size="sm"
          title={'Customer registeration request submitted'}
          style={{ marginTop: '0', top: '30vh' }}
          onConfirm={() => { props.resetRegistrationSuccess() }}
          confirmBtnText={'ok'}
          showCancel={false}
          cancelBtnText={'cancel'}
          confirmBtnCssClass
        >
        </AlertDialog>

        <AlertDialog
          show={false}
          danger
          title={'Error registering customer'}
          onConfirm={() => { props.resetRegistrationError() }}
          confirmBtnText={'Try again'}
          confirmBtnCssClass
          showCancel={false}
          style={{ marginTop: '0', top: '30vh' }}
        >
          {'customer registration error '}
        </AlertDialog>
      </section>
    </>
  )
}

export default RegisterCustomer