import React, { useState, useEffect } from 'react'
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@material-ui/core/Grid";

import InputAdornment from '@mui/material/InputAdornment';


import MenuItem from '@mui/material/MenuItem';


import { useStyle } from "./Style";
import { Button, CircularProgress } from '@mui/material';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { getLocalNumberLength, getPartner } from '../../../../../lib/access.es6';
import { reloadPage } from '../../../../../lib/utils.es6';



const EditUser = (props) => {
  const classes = useStyle();
  const [fullname, setFullname] = useState(props.customerData.full_name);
  const [dateofbirth, setdateofbirth] = useState(props.customerData.date_of_birth);
  const [phonenumber, setphonenumber] = useState(props.customerData.msisdn.substring(5));
  const [language, setLanguage] = useState(props.customerData.language);




  //validations
  useEffect(() => {

    ValidatorForm.addValidationRule('isValidPhoneNumber', value => {
      return value.length === getLocalNumberLength();
    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidPhoneNumber');
    };
  }, [])

  //country calling code

  const internationalCallingCode = getPartner().country.international_calling_code

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'fullname') {
      setFullname(value)
    }

    if (name === 'dateofbirth') {
      setdateofbirth(value)
    }
    if (name === 'phonenumber') {
      setphonenumber(value)
    }
    if (name === 'language') {
      setLanguage(value)
    }



  };
  const reset = () => {
    setFullname('');
    setdateofbirth('');
    setphonenumber('');
    setLanguage('');
  }
  const registerCustomer = () => {
    const payload = {
      "guid": props.customerData.guid,
      "msisdn": phonenumber,
      "full_name": fullname,
      "date_of_birth": dateofbirth,
      "display_language": language
    }
    //console.log("payload", payload)
    props.editCustomer(payload);
    reset()
  }
  const resetAfterSucesfulyEdit = () => {
    props.resetAfterSucesfulyEdit();
    props.toggleEditCustomerDialog();
    reloadPage()
  }
  const resetAfterFailedEdit = () => {
    props.resetAfterFailedEdit();
    props.toggleEditCustomerDialog()
    reloadPage()
  }
  return (
    <>
      <Dialog open={props.openEditCustomerDialog} onClose={props.toggleEditCustomerDialog} fullWidth
        maxWidth="md">
        <ValidatorForm onSubmit={() => registerCustomer()} >
          <DialogTitle className={classes.registermodaltitle}>
            <div >
              <h3> {props.labels.editCustomerDetailsHeading}</h3>
            </div>
          </DialogTitle>
          <DialogContent className={classes.registerinputwrapper}>
            <DialogContentText>
              {props.labels.editCustomerInformation}
            </DialogContentText>
            <Grid container spacing={2} >

              <Grid item xs={12} sm={6} md={6}>

                <TextValidator
                  name='fullname'
                  id="full-Name"
                  label={props.labels.fullName}
                  value={fullname}
                  onChange={handleChange}
                  fullWidth
                  className={classes.registerinputs}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={[`${props.labels.fullNameErrorMessage}`]}
                />

              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextValidator
                  name='phonenumber'
                  id="Phone"
                  label={props.labels.phoneNumber}
                  type="number"
                  value={phonenumber}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment
                      sx={{
                        paddingTop: "32px",
                      }}
                      position="start"
                    >{internationalCallingCode ? internationalCallingCode : ''}</InputAdornment>,
                    style: {
                      background: "#F7F7F7"
                    }
                  }}

                  variant="outlined"
                  validators={phonenumber ? ["isValidPhoneNumber"] : []}
                  errorMessages={[` ${props.labels.pleaseEnterValid}  ${getLocalNumberLength()}  ${props.labels.pleaseEnterValidPhone} `]}

                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>

                <TextValidator
                  name='dateofbirth'
                  id="Date-of-birth"
                  label={props.labels.dateOfBirth}
                  value={dateofbirth}
                  onChange={handleChange}
                  fullWidth
                  type='date'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={[`${props.labels.dateOfBirthErrorMessage}`]}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <SelectValidator
                  name='language'
                  labelId="select-language"
                  id="select-language"
                  value={language}
                  label={props.labels.selectLanguage}
                  onChange={handleChange}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={[`${props.labels.languageSelectError}`]}
                  style={{ width: "100%" }}
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="fr">French</MenuItem>

                </SelectValidator>
              </Grid>

            </Grid>

          </DialogContent>
          <DialogActions className={classes.registerbtns} >
            <Button variant="contained" type='submit'>{props.labels.updateCustomer}</Button>
            <Button variant="outlined" onClick={props.toggleEditCustomerDialog}>{props.labels.cancel}</Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>

      <AlertDialog
        custom
        show={props.loader}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>updating customer</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={!!props.customerEditedSuccessfully}
        size="sm"
        title={'Customer details updated'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { resetAfterSucesfulyEdit() }}
        confirmBtnText={'ok'}
        showCancel={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>


      <AlertDialog
        show={!!props.customerEditedFailed}
        danger
        title={'Error updating customer details'}
        onConfirm={() => { resetAfterFailedEdit() }}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.errors ? props.errors.message : ''}
      </AlertDialog>
    </>
  )
}

export default EditUser