import * as types from '../../lib/actionTypes.es6';

export function reportClaimFinalizationDeathRequest(reportType) {
  return {
    type: types.REPORT_CLAIM_FINALIZATION_DEATH_REQUEST,
    payload: reportType,
  };
}

export function reportClaimFinalizationDeathRequestSuccess(reportURL) {
  return {
    type: types.REPORT_CLAIM_FINALIZATION_DEATH_SUCCESS,
    payload: reportURL,
  };
}

export function reportClaimFinalizationDeathRequestError(error) {
  return {
    type: types.REPORT_CLAIM_FINALIZATION_DEATH_FAILURE,
    payload: error,
  };
}

export function reportClaimFinalizationDeathReset() {
  return {
    type: types.REPORT_CLAIM_FINALIZATION_DEATH_RESET,
  };
}
