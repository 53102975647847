import React from 'react'
import { PRIMARY_THEME_COLOR_SHADE_1 } from '../../lib/constants'
import { Document, Page, pdfjs } from "react-pdf";
import { AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ViewDocument = (props) => {
  return (
    <>
      <Dialog
        fullScreen
        open={!!props.open}
        onClose={props.close}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.close}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {
                props.title
              }
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ height: '100%', textAlign: 'center', margin: 'auto' }}>
          {
            (props.viewDocumentUrl && props.viewDocumentUrl.includes('.pdf'))
              ? <Document
                file={props.viewDocumentUrl}
                onLoadSuccess={props.onLoadSuccess}
                style={{ width: '100%', height: '100%', overflow: 'auto', margin: 'auto' }}

              >
                {Array.from(new Array(props.numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
              : props.viewDocumentUrl ? <div style={{ height: '750px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={props.viewDocumentUrl} alt="Document" style={{ maxHeight: '100%', maxWidth: '100%' }} />
              </div> : null
          }

        </DialogContent>
      </Dialog>

    </>
  )
}

export default ViewDocument