import React from 'react'
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import { formatDateLocale } from '../../../../../lib/utils.es6';

const ClaimDetails = (props) => {
  console.log("claim details props",props.claimDetailsData)
  const classes = useStyle();
  if(props.initiateClaimLoader){
    return (
      <>
        <Grid container spacing={2} className={classes.headingclaim}>
          <Grid item xs={12} sm={12} md={12}>
            <h4>Claim Details</h4>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
              <p style={{textAlign:'center'}}>...Submittng claim</p>
          </Grid>
        </Grid>
      </>
    )
  }

  if(!props.claimDetailsData) return (
    <>
      <Grid container spacing={2} className={classes.headingclaim}>
        <Grid item xs={12} sm={12} md={12}>
          <h4>Claim Details</h4>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
            <p>nothing found yet</p>
        </Grid>
      </Grid>
    </>
  )

  return (
    <>
      <Grid container spacing={2} className={classes.headingclaim}>
        <Grid item xs={12} sm={12} md={12}>
          <h4>Claim Details</h4>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>

          <div className={classes.claimDetailsWrapper}>
            <table>
              <tr>
                <th>Claim Number</th>
                <th>Claim Status</th>
                <th>Event Date</th>
                <th>Initiator Fullname</th>
                <th>Created At</th>
              </tr>
              <tr >
                <td>{props.claimDetailsData.claim_number}</td>
                <td>{props.claimDetailsData.status}</td>
                <td>{formatDateLocale(props.claimDetailsData.event_date)}</td>
                <td>{props.claimDetailsData.initiator_fullname}</td>
                <td>{formatDateLocale(props.claimDetailsData.created_at)}</td>
              </tr>
            </table>


          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default ClaimDetails