import React from 'react'
import PremiumsNuclear from './AddOnsTable/PremiumsNuclear'
import BenefitsNuclear from './AddOnsTable/BenefitsNuclear'
import Nuclear from './NuclearTable/Nuclear'
import Extended from './NuclearTable/Extended'
import PremiumsConfigs from './PremiumsTable/PremiumsConfigs'
import PremiumWaivers from './AddOnsTable/PremiumWaiver'
import Annuities from './AddOnsTable/Annuities'
import GroupLifeAssurance from './AddOnsTable/GroupLifeAssurance'
import AddOns from './AddOnsTable/AddOns'
import PayOutPlans from './NuclearTable/PayOutPlans'

const DefaultComplexProductsConfigs = (props) => {
  return (
    <>
      <br />
      <br />

      <PremiumsNuclear
        currencyCode={props.currencyCode}
        membership_configs={props.membership_configs}
        productGuid={props.productGuid}
        refreshProduct={props.refreshProduct}
      />
      <br />
      <BenefitsNuclear
        currencyCode={props.currencyCode}
        membership_configs={props.membership_configs}
        productGuid={props.productGuid}

        refreshProduct={props.refreshProduct}
      />

      <Nuclear
        nuclear={props.nuclear}
        productGuid={props.productGuid}

        extendedConfig={props.extendedConfig}
        refreshProduct={props.refreshProduct}
      />
      <br />
      <br />

      <Extended
        extended={props.extended}
        productGuid={props.productGuid}

        extendedConfig={props.extendedConfig}
        refreshProduct={props.refreshProduct}
      />
      <br />
      <br />
      <PremiumsConfigs
        premiums={props.premiums}
        productGuid={props.productGuid}

        extendedConfig={props.extendedConfig}
        displayEditButton={props.displayEditButton}
        displayDeleteButton={props.displayDeleteButton}
        hardDeletePremium={props.hardDeletePremium}
        refreshProduct={props.refreshProduct}
      />

      <br />
      <br />





      <PremiumWaivers
        currencyCode={props.currencyCode}
        membership_configs={props.membership_configs}
        productGuid={props.productGuid}

        refreshProduct={props.refreshProduct}
      />
      <br />
      <br />
      <Annuities
        currencyCode={props.currencyCode}
        membership_configs={props.membership_configs}
        productGuid={props.productGuid}

        refreshProduct={props.refreshProduct}
      />
      <br />
      <br />
      <GroupLifeAssurance
        currencyCode={props.currencyCode}
        membership_configs={props.membership_configs}
        productGuid={props.productGuid}

        refreshProduct={props.refreshProduct}
      />
      <br />
      <br />


      <AddOns
        addOns={props.addOns}
        productGuid={props.productGuid}

        extendedConfig={props.extendedConfig}
        displayEditButton={props.displayEditButton}
        displayDeleteButton={props.displayDeleteButton}
        hardDeletePremium={props.hardDeletePremium}
        refreshProduct={props.refreshProduct}
      />
      <PayOutPlans
        members_payout_plan={props.members_payout_plan}
        extendedConfig={props.extendedConfig}
        productGuid={props.productGuid}

        refreshProduct={props.refreshProduct}
      />

    </>
  )
}

export default DefaultComplexProductsConfigs