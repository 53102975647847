import React from 'react';
import { connect } from 'react-redux';
import Premiums from '../../components/Premiums/index.es6';
import NoRecord from '../../components/NoRecord/index.es6';
import { deletePremiumRequest } from '../CustomerStatus/actions.es6';
import * as globalActions from '../Main/actions.es6';
import { getAllPremiumsRequest } from '../CustomerScreen/actions.es6';

export class PremiumList extends React.Component {
  componentWillMount() {
    this.props.dispatch(globalActions.showCustomerBar());
  }

  getPremiums(){
    const currentCustomer = this.props.customerData.currentCustomer;
    if(currentCustomer){
      this.props.dispatch(getAllPremiumsRequest({msisdn: currentCustomer.msisdn}))
    }
  }

  deletePremium(premium) {
    const payload = {
      premiumGuid: premium.guid,
      customerGuid: this.props.customerData.currentCustomer.guid,
    };
    this.props.dispatch(deletePremiumRequest(payload));
  }

  displayPremiums() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (!currentCustomer) {
      return <NoRecord />;
    }
    const premiums = this.props.customerScreenData.premiums;
    return (
      <div className="premium-list">
        <Premiums
          columnWidth="col-md-12"
          premiums={premiums}
          deletePremiumHandler={this.deletePremium.bind(this)}
          showMore={false}
          getPremiums={this.getPremiums.bind(this)}
        />
      </div>
    );
  }

  render() {
    return <div>{this.displayPremiums()}</div>;
  }
}

export default connect(state => ({ 
  customerData: state.currentCustomer,
  customerScreenData: state.customerScreen,
}))(
  PremiumList
);
