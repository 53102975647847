import React, { useState } from 'react'
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import { getPartner } from '../../../../lib/access.es6'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const PoliciesDependants = (props) => {
  const classes = useStyle();


  const [expanded, setExpanded] = useState(false)
  
  const currencyCode = getPartner().country.currency_code
  const dependants = (props.policy.quotation && props.policy.quotation.policy_schedule_entries) ? props.policy.quotation.policy_schedule_entries: null

  if (props.policy && dependants) {
    console.log({dependants, policy: props.policy});

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} >
            <div className={classes.policieswrapper}>
              <h2> <strong>Status</strong>: {props.policy.status}, <strong>Grower Number:</strong> {props.policy.policy_number}, <strong>Plan :</strong> {props.policy.quotation.product_code}</h2>
              <table>
                <tr>
                  <th> Name</th>
                  <th>Relationship</th>
                  <th>Dob</th>
                  <th>Benefits</th>
                  <th>Premium</th>
                </tr>

                {
                  dependants.map((entry, index) => (
                      (
                        <>
                          <tr key={index}>
                            <td>{entry.dependant.first_name + " " + entry.dependant.last_name}</td>
                            <td>{entry.dependant.relationship}</td>
                            <td>{entry.dependant.date_of_birth}</td>
                            <td>
                              <ol>
                                {
                                  entry.benefits.map(benefit => {
                                    return  <li>
                                    {benefit.type} - {<strong> {currencyCode + " " + (benefit.cover_amount_in_cents / 100).toLocaleString()}</strong>}
                                  </li>
                                  })
                                }
                              </ol>

                            </td>
                            <td>{currencyCode + " " + entry.premium_amount_in_cents  / 100}</td>
                          </tr>
                        </>
                      ) 
                  ))
                }
              </table>
              <br/>
              <p style={{textAlign: 'right'}}> total premium : {currencyCode + " " + props.policy.quotation.total_premium_amount_in_cents/100}</p>
              <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={classes.showmoreshowless} onClick={() => setExpanded(!expanded)}>
                <ul>
                  <li> {expanded ? 'Show Less' : 'Show More'}</li>
                  <li>{
                    expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
            </Grid>
          </Grid>
            </div>
          </Grid>
        </Grid>
      </>
    )
  } else {
    return "No Dependants Found"
  }


}

export default PoliciesDependants