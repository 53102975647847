import React from "react";
import LocalizedStrings from 'react-localization';
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { localisedText } from "../../lib/localisation.es6";
import { addReportTemplateRequest, deleteReportTemplateRequest, editReportTemplateRequest, getReportTemplateRequest, resetReportTemplateToDelete, resetReportTemplateToEdit, setReportTemplateToDelete, setReportTemplateToEdit } from "./Redux/actions";
import ButtonInc from "../../shared-ui/ButtonInc";
import BoxInc from "../../shared-ui/BoxInc/Index";
import AddReport from "./components/AddReport";
import ViewReportTemplates from "./components/ViewReportTemplates";
import EditReport from "./components/EditReport";
import DeleteReport from "./components/DeleteReport";
class ReportsConfigs extends React.Component {

  constructor(props) {
    const localizedStrings = new LocalizedStrings(localisedText);
    super(props);
    this.state = {
      labels: localizedStrings,
      openAddTemplateDialog: false,
      openEditTemplateDialog: false,
    }
  }

  componentWillMount() {
    this.updateLanguage();
    this.props.dispatch(getReportTemplateRequest());
    //this.props.dispatch(addReportTemplateRequest("payload"));
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    const language = this.props.globalData.language;
    let resetLanguage = false;
    if (!language) {
      resetLanguage = true;
    }
    const labels = this.state.labels;
    if (resetLanguage || labels.getLanguage() !== language) {
      labels.setLanguage(language);
      this.setState({});
    }
  }

  toggleOpenAddTemplate() {
    this.setState({ openAddTemplateDialog: !this.state.openAddTemplateDialog })
  }
  toggleOpenEditTemplateDialog(){
    this.setState({ openEditTemplateDialog: !this.state.openEditTemplateDialog })
  }

  saveReportTemplate(payload) {
    this.props.dispatch(addReportTemplateRequest(payload));
  }

  setTemplateReportToEdit (payload){
    this.props.dispatch(setReportTemplateToEdit(payload));
    console.log("templateToEdit", payload)
  }
  resetReportTemplateToEdit = (payload) => {
    this.props.dispatch(resetReportTemplateToEdit(payload))
  }
  editReportTemplate = (payload) => {
    this.props.dispatch(editReportTemplateRequest(payload))
  }

  setReportTemplateToDelete(report) {
    this.props.dispatch(setReportTemplateToDelete(report));
    console.log("reportTodelete", report)
  }
  deleteReportTemplate(report) {
    this.props.dispatch(deleteReportTemplateRequest({ id: report.id }));
    console.log("reportdelete", report)
  }

  resetReportTemplateToDelete(report) {
    this.props.dispatch(resetReportTemplateToDelete(report))
  }

  render() {

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <BoxInc
              expanded
              title="Reports"
              actionButtons={[
                <ButtonInc
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={(event) => {
                    event.stopPropagation()
                    this.toggleOpenAddTemplate()
                  }}
                >
                  Add Report
                </ButtonInc>

              ]}

            >
              <ViewReportTemplates
                loading={this.props.ReportsTemplateData.getReportTemplatesloader}
                templates={this.props.ReportsTemplateData.reportTemplates}
                toggleOpenEditTemplateDialog={this.toggleOpenEditTemplateDialog.bind(this)}
                setTemplateReportToEdit={this.setTemplateReportToEdit.bind(this)}
                setReportTemplateToDelete={this.setReportTemplateToDelete.bind(this)}
              />
              <AddReport
                open={this.state.openAddTemplateDialog}
                close={this.toggleOpenAddTemplate.bind(this)}
                saveReportTemplate={this.saveReportTemplate.bind(this)}


                showAddReportProgressAlert={
                  this.props.ReportsTemplateData.addReportTemplateLoader
                }
                showAddReportSuccessAlert={
                  this.props.ReportsTemplateData.addReportTemplateSuccess
                }
                showAddReportErrorAlert={
                  !!this.props.ReportsTemplateData.addReportTemplateError
                }
                addReportError={
                  this.props.ReportsTemplateData.addReportTemplateError
                }
              />
              <EditReport
              open={!!this.props.ReportsTemplateData.reportTemplateToEdit}
              close={this.resetReportTemplateToEdit.bind(this)}

              report={this.props.ReportsTemplateData.reportTemplateToEdit}
              editReportTemplate={this.editReportTemplate.bind(this)}

              showEditReportTemplateProgressAlert={this.props.ReportsTemplateData.editReportTemplateLoader}
              showEditReportTemplateSuccessAlert={this.props.ReportsTemplateData.editReportTemplateSuccess}
              showEditReportTemplateErrorAlert={!!this.props.ReportsTemplateData.editReportTemplateError}
              error={this.props.ReportsTemplateData.editReportTemplateError}
              />
              <DeleteReport
               deleteReportTemplate={this.deleteReportTemplate.bind(this)}
               reportTemplateToDelete={this.props.ReportsTemplateData.reportTemplateToDelete}

               resetReportTemplateToDelete={this.resetReportTemplateToDelete.bind(this)}
               showDeleteReportTemplateProgressAlert={this.props.ReportsTemplateData.hardDeleteReportTemplateLoader}
               showDeleteReportTemplateSuccessAlert={this.props.ReportsTemplateData.hardDeleteReportTemplateSuccess}
               showDeleteReportTemplateErrorAlert={!!this.props.ReportsTemplateData.deleteReportTemplateError} 
               deleteReportTemplateError={this.props.ReportsTemplateData.deleteReportTemplateError}
              
              />
            </BoxInc>
          </Grid>
        </Grid>
      </>
    )


  }

}

export default connect((state) => ({
  ReportsTemplateData: state.ReportsTemplateData,
  globalData: state.global,
}))(ReportsConfigs);