import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as actions from './actions.es6';

export class LoyaltyEligibilityTigo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
  }

  onFileSelect(event) {
    this.setState({ file: event.target.files[0] });
  }

  handleUpload() {
    if (this.state.file) {
      const data = new FormData();
      data.append('file', this.state.file);
      this.props.dispatch(actions.tigoLoyaltyUploadRequest(data));
    }
  }

  successMessage() {
    if (this.props.loyalty.response) {
      return `${this.props.loyalty.response.filename} has been successfully processed`;
    }

    return null;
  }

  unprocessedRows() {
    if (this.props.loyalty.response) {
      const unprocessedRows = this.props.loyalty.response.unprocessed_rows;
      return `Failed row numbers: ${
        unprocessedRows === '' ? 'none' : unprocessedRows
      }`;
    }

    return null;
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_title">
                <h2>Airtel Rwanda Loyalty Eligibility Uploads</h2>
                <div className="clearfix" />
              </div>
              <div className="x_content">
                <br />
                <div className="row form-group">
                  <label
                    className="control-label col-md-4 col-sm-4 col-xs-12"
                    htmlFor="full-name"
                  >
                    Upload csv *<span>*</span>
                  </label>
                  <div className="col-md-3 col-sm-3 col-xs-12">
                    <p style={{ color: 'green' }}>{this.successMessage()}</p>
                    <p style={{ color: 'green' }}>{this.unprocessedRows()}</p>
                    <br />
                    <input
                      name="uploader"
                      id="uploader"
                      className="form-control col-md-7 col-xs-12"
                      type="file"
                      accept=".csv"
                      onChange={this.onFileSelect.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-sm-offset-5">
                  {this.props.loyalty.loader ? (
                    <CircularProgress />
                  ) : (
                    <button
                      className="btn btn-primary"
                      onClick={this.handleUpload.bind(this)}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({ loyalty: state.tigoLoyalty }))(
  LoyaltyEligibilityTigo
);
