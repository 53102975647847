import React from 'react';
import { connect } from 'react-redux';
import DebitOrders from '../../components/DebitOrders/index.es6';
import NoRecord from '../../components/NoRecord/index.es6';

export class CustomerDebitOrders extends React.Component {
  displayCustomerDebitOrders(debitOrders) {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (!currentCustomer) {
      return <NoRecord />;
    }

    return (
      <DebitOrders
        debitOrders={debitOrders}
        deleteDebitOrderHandler={() => {}}
        columnWidth="col-md-12"
        showMore={false}
      />
    );
  }

  render() {
    const debitOrders = this.props.customerData.currentCustomer
      ? this.props.customerData.currentCustomer.debit_orders
      : [];
    return (
      <div className="container">
        {this.displayCustomerDebitOrders(debitOrders)}
      </div>
    );
  }
}

export default connect(state => ({ customerData: state.currentCustomer }))(
  CustomerDebitOrders
);
