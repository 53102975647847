import React, { useEffect, useState } from 'react'
import { useStyle } from "./Style";

import Adddependant from '../AddDependant/Adddependant';
import { getPartner, getPartnerGuid } from '../../../../lib/access.es6';
import AlertDialog from '../../../../components/AlertDialog/index.es6';
import { CircularProgress } from '@material-ui/core';
import { Button } from '@mui/material';
import benefitOptions from '../BenefitsOptionsData/Benefits'
import find from 'lodash/find';


const Createquote = (props) => {
  const classes = useStyle();

  const [togglequoteanddependant, settogglequoteanddependant] = useState(true)
  const [dependants, setDependants] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [depAlert, setDepAlert] = useState(false)


  useEffect(()=>{
    if(props.quoteToReCreate){
      const policyEnteries = props.quoteToReCreate.quotation.policy_schedule_entries
  
      const tempDep = policyEnteries.map(entry => {
        return {
          ...entry.dependant,
          benefits: entry.benefits,
          premium_amount_in_cents: entry.premium_amount_in_cents
        }
      })
      setDependants(tempDep)

      const quoteProduct = find(benefitOptions, { option: props.quoteToReCreate.quotation.product_code })
      console.log({
        code: props.quoteToReCreate.quotation.product_code,
        qoute: props.quoteToReCreate.quotation,
        quoteProduct,
      });
      if(quoteProduct){
        setSelectedProduct(quoteProduct)
      }
    }
  }, [props.quoteToReCreate])



  const handleAdddependant = () => {
    settogglequoteanddependant(!togglequoteanddependant)
  }


  const removeDependant = (index) => {
    const currentDependents = dependants
    const member = dependants[index]
    if(member.relationship === "Principal"){
      setDependants([])
    }else{
      currentDependents.splice(index, 1)
      setDepAlert(true)
    }

  }
  const handleCreateQuote = () => {
    const payload = {
      "product_guid":selectedProduct ? selectedProduct.product_guid : 'n/a',
      "product_name": selectedProduct ? selectedProduct.product_name : 'n/a',
      "product_code": selectedProduct ? selectedProduct.option : 'n/a',
      "customer_guid": props.customer_guid,
      "partner": getPartnerGuid(),
      "lapse_period": 3,
      "premium_granularity": "month",
      "premium_cardinality": 1,
      "policy_schedule_entries": [
        ...dependants
      ]
    }
    props.createQuote(payload)
  }
  const handleQuoteCreatedSuccessfully = () => {
    props.resetAddQuoteSuccessAlert();
  }

  const handleQuoteCreateError = () => {
    props.resetAddQuoteErrorAlert();
  }

  const handlenextStep = () => {
    props.handleNext();
  }

  const currencyCode = getPartner().country.currency_code

  return (
    <>

      <AlertDialog
        custom
        show={props.showAddQuoteProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2> Creating Quote...</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showAddQuoteSuccessAlert}
        size="sm"
        title={'Quote  created'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleQuoteCreatedSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => { handlenextStep() }} style={{ background: 'green', }}>View Quote Details</Button>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showAddQuoteErrorAlert}
        danger
        title={'Error creating Quote'}
        onConfirm={() => handleQuoteCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {
          "error"
        }
        {/* {props.createQuoteError ? props.createQuoteError.message : ''} */}
      </AlertDialog>

      <Adddependant
        dependant={props.dependant}
        handleCreateQuote={handleCreateQuote}
        handleAdddependant={handleAdddependant}
        createQuote={props.createQuote}
        setDependants={setDependants}
        dependants={dependants}
        createDependant={props.createDependant}

        showAddDependantProgressAlert={props.showAddDependantProgressAlert}
        showAddDependantSuccessAlert={props.showAddDependantSuccessAlert}
        showAddDependantErrorAlert={props.showAddDependantErrorAlert}
        resetAddDependantSuccessAlert={props.resetAddDependantSuccessAlert}
        currencyCode={currencyCode}
        removeDependant={removeDependant}
        depAlert={depAlert}
        setDepAlert={setDepAlert}
        principal={props.principal}
        autoFillPrincipal={props.autoFillPrincipal}
        toggleAutoFillPrincipal={props.toggleAutoFillPrincipal}

        setSelectedProduct={setSelectedProduct}
        selectedProduct={selectedProduct}
      />
      <div style={{ textAlign: 'right', marginBottom: "40px" }}>
        Total: {currencyCode + " " + (dependants.reduce((psum, dependant) => psum + dependant.premium_amount_in_cents, 0)) / 100}
      </div>

      {dependants.length > 0 && <div  className={classes.createquote}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleCreateQuote}
         
        >
          Create Quote
        </Button>
      </div>
      }
    </>
  )
}

export default Createquote