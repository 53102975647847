import * as types from '../../lib/actionTypes.es6';

export function getUserRequest(userId) {
  return {
    type: 'GET_USER_REQUEST',
    payload: userId,
  };
}

export function getUserSuccess(response) {
  return {
    type: 'GET_USER_SUCCESS',
    payload: response,
  };
}

export function getUserFail(response) {
  return {
    type: 'GET_USER_FAIL',
    payload: response,
  };
}

export function getUsersRequest() {
  return {
    type: 'GET_USERS_REQUEST',
  };
}

export function getUsersSuccess(response) {
  return {
    type: 'GET_USERS_SUCCESS',
    payload: response,
  };
}

export function getUsersFail(response) {
  return {
    type: 'GET_USERS_FAIL',
    payload: response,
  };
}

export function createtUsersRequest(payload) {
  return {
    type: 'CREATE_USERS_REQUEST',
    payload,
  };
}

export function createUsersSuccess(response) {
  return {
    type: 'CREATE_USERS_SUCCESS',
    payload: response,
  };
}

export function createUsersFail(response) {
  return {
    type: 'CREATE_USERS_FAIL',
    payload: response,
  };
}

export function resetCreateUserSuccess() {
  return {
    type: 'RESET_USERS_SUCCESS',
  };
}

export function getPartnersRequest() {
  return {
    type: 'GET_PARTNERS_REQUEST',
  };
}

export function getPartnersSuccess(response) {
  return {
    type: 'GET_PARTNERS_SUCCESS',
    payload: response,
  };
}

export function getPartnersFail(response) {
  return {
    type: 'GET_PARTNERS_FAIL',
    payload: response,
  };
}

export function getRolesRequest() {
  return {
    type: 'GET_ROLES_REQUEST',
  };
}

export function getRolesSuccess(response) {
  return {
    type: 'GET_ROLES_SUCCESS',
    payload: response,
  };
}

export function getRolesFail(response) {
  return {
    type: 'GET_ROLES_FAIL',
    payload: response,
  };
}

export function getCurrentRolesRequest(id) {
  return {
    type: types.GET_CURRENT_ROLES_REQUEST,
    payload: id,
  }
}

export function getCurrentRolesSuccess(response) {
  return {
    type: types.GET_CURRENT_ROLES_SUCCESS,
    payload: response
  }
}

export function getCurrentRolesFail(response) {
  return {
    type: types.GET_CURRENT_ROLES_FAIL,
    payload: response
  }
}

export function getCurrentPartnersRequest(id) {
  return {
    type: types.GET_CURRENT_PARTNER_REQUEST,
    payload: id
  }
}

export function getCurrentPartnerSuccess(response) {
  return {
    type: types.GET_CURRENT_PARTNER_SUCCESS,
    payload: response
  }
}

export function getCurrentPartnerFail(response) {
  return {
    type: types.GET_CURRENT_PARTNER_FAIL,
    payload: response
  }
}

export function deleteUsersRequest(guid) {
  return {
    type: types.DELETE_USER_REQUEST,
    payload: guid,
  };
}
export function setUserToDelete(response) {
  return {
    type: 'SET_USER_TO_DELETE',
    payload: response,
  };
}
export function deleteUsersSuccess(response) {
  return {
    type: types.DELETE_USER_SUCCESS,
    payload: response,
  };
}

export function resetHardDeleteUserSuccess() {
  return {
    type: 'RESET_DELETE_USER_SUCCESS',
  };
}


export function deleteUsersFail(response) {
  return {
    type: types.DELETE_USER_FAILED,
    payload: response,
  };
}
export function resetUsersToDelete() {
  return {
    type: 'RESET_USER_TO_DELETE',
  };
}
export function resetHardDeleteUserError() {
  return {
    type: 'RESET_DELETE_USER_ERROR',
  };
}

export function changeUserPasswordRequest(payload) {
  return {
    type: 'CHANGE_USER_PASSWORD_REQUEST',
    payload,
  };
}

export function changeUserPasswordSuccess(response) {
  return {
    type: 'CHANGE_USER_PASSWORD_SUCCESS',
    payload: response,
  };
}

export function changeUserPasswordFail(response) {
  return {
    type: 'CHANGE_USER_PASSWORD_FAIL',
    payload: response,
  };
}

export function setUserToEdit(response) {
  return {
    type: 'SET_USER_TO_UPDATE',
    payload: response,
  };
}

export function updateRolesAndPartnersdRequest(payload) {
  return {
    type: types.UPDATE_ROLES_AND_PARTNERS_REQUEST,
    payload,
  };
}

export function updateRolesAndPartnersdSuccess(response) {
  return {
    type: types.UPDATE_ROLES_AND_PARTNERS_SUCCESS,
    payload: response,
  };
}

export function updateRolesAndPartnersdFail(response) {
  return {
    type: types.UPDATE_ROLES_AND_PARTNERS_FAILED,
    payload: response,
  };
}

export function resetEditUserSuccess() {
  return {
    type: 'RESET_UPDATE_USER_SUCCESS',
  };
}
export function resetEditUserError() {
  return {
    type: 'RESET_UPDATE_USER_ERROR',
  };
}
export function resetUserToEdit() {
  return {
    type: 'RESET_USER_TO_UPDATE',
  };
}
export function resetPasswordRequest(payload) {
  return {
    type: types.RESET_USER_PASSWORD_REQUEST,
    payload,
  };
}

export function resetPasswordFailure(error) {
  return {
    type: types.RESET_USER_PASSWORD_FAILED,
    payload: error,
  };
}

export function resetPasswordSuccess(response) {
  return {
    type: types.RESET_USER_PASSWORD_SUCCESS,
    payload: response,
  };
}

export function resetUserContainer(response) {
  return {
    type: types.RESET_USER_CONTAINER,
    payload: response,
  };
}
