import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../../../lib/actionTypes.es6';

import {
  createDebitOrderz,
  deleteDebitOrderz,
  editDebitOrderz,
  getDebitOrderz, getAllBanks,
} from '../../../../../lib/requests.es6';
import * as actions from './actions';
import {unAuthorizedNotification} from "../../../../../lib/access.es6";


export function* createDebitOrderzRequestWatcher() {
  for (;;) {
    const request = yield take(types.CREATE_DEBIT_ORDERZ_REQUEST);
    try {
      const response = yield call(createDebitOrderz, request.payload);
      yield put(actions.createDebitOrderzSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.createDebitOrderzFailure(errorMessage));
    }
  }
}

export function* hardDeleteDebitOrderzRequestWatcher() {
  for (;;) {
    const request = yield take(types.HARD_DELETE_DEBIT_ORDERZ_REQUEST);
    try {
      const response = yield call(deleteDebitOrderz, request.payload);
      yield put(actions.hardDeleteDebitOrderzSuccess(response.data));
      yield put(
        actions.getDebitOrderzRequest({msisdn: request.payload.msisdn})
      );
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.hardDeleteDebitOrderzFailure(errorMessage));
    }
  }
}

export function* editDebitOrderzRequestWatcher() {
  for (;;) {
    const request = yield take(types.EDIT_DEBIT_ORDERZ_REQUEST);
    try {
      const response = yield call(editDebitOrderz, request.payload);
      yield put(actions.editDebitOrderzSuccess(response.data));
      yield put(
        actions.getDebitOrderzRequest({msisdn: request.payload.msisdn})
      );
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.editDebitOrderzFailure(errorMessage));
    }
  }
}



export function* getDebitOrderzRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_DEBIT_ORDERZ_REQUEST);
    try {
      const response = yield call(getDebitOrderz, request.payload);
      yield put(actions.getDebitOrderzSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getDebitOrderzFailure(errorMessage));
    }
  }
}

export function* getBanksWatcher() {
  while (true) {
    yield take(types.GET_ALL_BANKS_REQUEST);
    try {
      const response = yield call(getAllBanks);
      yield put(actions.getAllBanksSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.getAllBanksFailure(errorPayload));
    }
  }
}
