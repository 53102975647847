import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  updatePolicyWrapper:{
    padding: "20px",
  },
  tablewrapper: {
    width: "90%",
    padding: "2px",
    "& table": {
        background: "#FFFFFF",
        border: "1px solid #A7A7A7",
        margin: "auto",
        width: "100%",
        "& tr": {
            borderTop: "1px solid #A7A7A7",
        },
        "& th": {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
            padding: "10px",
            borderRight: "1px solid #A7A7A7",
        },
        "& td": {
            fontFamily: "open sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
            padding: "10px",
            borderRight: "1px solid #A7A7A7",
            "& ol":{
              "& li":{
                marginBottom: "10px",
              }
            }
        }
    }
},
active:{
  background: "#75A85E",
  borderRadius: "10px",
  color: "#fff",
  padding: "2px",
  fontSize: "10px",
  width: "47px",
  display: "inline-block",
  textAlign: "center",
  height: "20px",
  lineHeight: "15px",
},
cancelled:{
  background: "#B3251E",
  borderRadius: "10px",
  color: "#fff",
  padding: "2px",
  fontSize: "10px",
  width: "64px",
  display: "inline-block",
  textAlign: "center",
  height: "20px",
  lineHeight: "15px",
},
showmoreshowless: {
  marginTop: "20px",
  "& ul": {
      display: "flex",
      padding: "0",
      cursor: "pointer",
      "& li": {
          listStyle: "none",
          fontFamily: "open sans",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "15px",
          color: "#000000",
          "&:nth-child(2)": {
              marginTop: "-3px"
          }
      }
  }
},

}));