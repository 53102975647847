import * as types from '../../../../lib/actionTypes.es6'


export function getPaymentsDcpRequest(payload){
  return{
    type: types.GET_PAYMENTS_DCP_REQUEST,
    payload: payload
  }
}


export const getPaymentsDcpSuccess = (payload) =>{
  return{
    type: types.GET_PAYMENTS_DCP_SUCCESS,
    payload: payload,
  }
}

export const getPaymentsDcpFailure = (error) =>{
  return{
    type: types.GET_PAYMENTS_DCP_FAILURE,
    payload: error,
  }
}