import GAlogger, { monitoringEventCategories } from '../../lib/monitoring.es6';
import * as types from '../../lib/actionTypes.es6';

export function closedClaimsRequest() {
  return {
    type: types.CLOSED_CLAIM_REQUEST,
  };
}

export function closedClaimsSuccess(payload) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.fetchClaimsClosed);
  return {
    type: types.CLOSED_CLAIM_SUCCESS,
    payload,
  };
}

export function closedClaimsFail(payload) {
  return {
    type: types.CLOSED_CLAIM_FAIL,
    payload,
  };
}
