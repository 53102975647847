import * as types from '../../../../lib/actionTypes.es6';

export const initialState = {


  getGuideError: null,
  getGuideloader: false,
  guide: null,
  
  getGuidesError: null,
  getGuidesloader: false,
  guides: [],
  isEmptyTemplates: false,

  addGuideError: null,
  addGuideLoader: false,
  addGuideSuccess: false,
  addedGuide: null,

  //guideToDelete: null,
  editGuideSuccess: false,
  editGuideLoader: false,
  editGuideError: null,
  guideToEdit: null,
  editTemplateInfo: null,

 
  deletGuideSuccess: false,
  deleteGuideLoader: false,
  deleteGuideError: null,
  hardDeleteGuideSuccess: false,
  hardDeleteGuideLoader: false,
  guideToDelete: null,

};

export default function knowledgeBaseReducer(state = initialState, action) {
  switch (action.type) {


    case types.GET_GUIDE_REQUEST:
      return {
        ...state,
        getGuideloader: true,
        getGuideError: null,
        guide: [],
        isEmptyTemplates: false,
      };

    case types.GET_GUIDE_SUCCESS:
      return {
        ...state,
        guide: action.payload,
        getGuideloader: false,
        isEmptyTemplates: action.payload.length === 0
      };

    case types.GET_GUIDE_FAILURE:
      return {
        ...state,
        getGuideloader: false,
        getGuideError: action.payload,
        isEmptyTemplates: false,
      };
      
      case types.GET_GUIDES_REQUEST:
      return {
        ...state,
        getGuidesloader: true,
        getGuidesError: null,
        guides: [],
        isEmptyTemplates: false,
      };

    case types.GET_GUIDES_SUCCESS:
      localStorage.setItem('guides', JSON.stringify(action.payload));
      return {
        ...state,
        guides: action.payload,
        getGuidesloader: false,
        isEmptyTemplates: action.payload.length === 0
      };

    case types.GET_GUIDES_FAILURE:
      return {
        ...state,
        getGuidesloader: false,
        getGuidesError: action.payload,
        isEmptyTemplates: false,
      };

    case types.RESET_GET_GUIDES:
      return {
        ...state,
        getGuidesError: null,
        getGuidesloader: false,
        guides: [],
        isEmptyTemplates: false,
      };

    case types.ADD_GUIDE_REQUEST:
      return {
        ...state,
        addGuideLoader: true,
        addGuideSuccess: false,
        addGuideError: null,
        addedGuide: null,
      };

    case types.ADD_GUIDE_SUCCESS:
      return {
        ...state,
        addedGuide: action.payload,
        addGuideSuccess: true,
        addGuideLoader: false,
      };

    case types.ADD_GUIDE_FAILURE:
      return {
        ...state,
        addGuideLoader: false,
        addGuideSuccess: false,
        addGuideError: action.payload,
      };



    case types.EDIT_GUIDE_REQUEST:
      return {
        ...state,
        editGuideLoader: true,
        editGuideError: null,
        editTemplateInfo: null,
        editGuideSuccess: false,
        guideToEdit: action.payload
      };

    case types.EDIT_GUIDE_SUCCESS:
      return {
        ...state,
        editTemplateInfo: action.payload,
        editGuideLoader: false,
        editGuideSuccess: true,
      };

    case types.EDIT_GUIDE_FAILURE:
      return {
        ...state,
        editGuideLoader: false,
        editGuideError: action.payload,
      };

    case types.RESET_GUIDE_TO_EDIT:
      return { ...state, guideToEdit: null };

    case  types.RESET_EDIT_GUIDE_SUCCESS:
      return { ...state, editGuideSuccess: false, guideToEdit: null };

    case types.SET_GUIDE_TO_EDIT:
      return { ...state, guideToEdit: action.payload };



      case types.DELETE_GUIDE_REQUEST:
        return {
          ...state,
          deleteGuideError: null,
          deleteGuideLoader: true,
          deletGuideSuccess: false,
          guideToDelete: action.payload,
          hardDeleteGuideLoader: true,
        };
  
      case types.DELETE_GUIDE_SUCCESS:
        return {
          ...state,
          deletGuideSuccess: true,
          deleteGuideLoader: false,
          deleteGuideError: null,
          hardDeleteGuideSuccess: true,
        };
  
      case types.DELETE_GUIDE_FAILURE:
        return { ...state, deleteGuideLoader: false, deleteGuideError: action.payload, hardDeleteGuideLoader: false };
  
      case types.RESET_DELETE_GUIDE_SUCCESS:
        return { ...state, deletGuideSuccess: false, hardDeleteGuideSuccess: false, hardDeleteGuideLoader: false };
  
      case types.RESET_DELETE_GUIDE_ERROR:
        return { ...state, deleteGuideError: null };
  
      case types.SET_GUIDE_TO_DELETE:
        return { ...state, guideToDelete: action.payload };
  
      case types.RESET_GUIDE_TO_DELETE:
        return { ...state, guideToDelete: null };

    default:
      return state;
  }
}
