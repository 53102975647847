import React, { useState } from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FilterClaims from '../Claims/FilterClaims';
import { ClaimStatusEnum } from '../../enum';
import { getPartnerGuid } from '../../../../../lib/access.es6';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ClaimsWrapper = (props) => {
  const classes = useStyle();
  const [value, setValue] = useState(0);


  const getClaimStatus = (tabValue) => {
    switch (tabValue) {
      case 0:
        return ClaimStatusEnum.docs_pending
      case 1:
        return ClaimStatusEnum.verification
      case 2:
        return ClaimStatusEnum.Approved
      default:
    }
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
    const payload = {
      "partner": getPartnerGuid(),
      "statuses": getClaimStatus(newValue)
    }
    props.getClaims(payload)
  };

  return (
    <>
      <section className={classes.claimswrapper}>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="claims dashboards" variant="scrollable"
                scrollButtons="auto" className={classes.tabs}>
                <Tab label="Docs Pending" {...a11yProps(0)} />
                <Tab label="Decision Pending" {...a11yProps(1)} />
                <Tab label="Approved" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0} >
              <FilterClaims  type={props.type} claimsData={props.claimsData} loading={props.loading} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <FilterClaims  type={props.type} claimsData={props.claimsData} loading={props.loading} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <FilterClaims
                claimsData={props.claimsData}
                loading={props.loading}
                hasBulkApprove={props.hasBulkApprove}
                type={props.type}

                handleBulkApproval={props.handleBulkApproval}
                hasAlerts={props.hasAlerts}
                showBulkApproveProgressAlert={props.showBulkApproveProgressAlert}
                showBulkApproveSuccessAlert={props.showBulkApproveSuccessAlert}
                showBulkApproveErrorAlert={props.showBulkApproveErrorAlert}
                bulkApproveError={props.bulkApproveError}

                resetBulkApproveSuccessAlert={props.resetBulkApproveSuccessAlert}
                resetBulkApproveErrorAlert={props.resetBulkApproveErrorAlert}
              />
            </TabPanel>


          </Grid>
        </Grid>
      </section>
    </>
  )
}

export default ClaimsWrapper