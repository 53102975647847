import { take, call, put } from 'redux-saga/effects';
import { hashHistory } from 'react-router';
import * as types from '../../lib/actionTypes.es6';
import {
  loginUser,
  getStarted,
  refreshSession,
  recoverPassword,
  logoutUser,
  getUserPartners,
} from '../../lib/requests.es6';
import {
  userLoginSuccess,
  userLoginFail,
  sessionRefreshSuccess,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  sessionRefreshFailed,
  getStartedSuccess,
  getStartedFailure
} from './actions.es6';
import { setCurrentPartner } from '../Main/actions.es6';
import { GAloggerException } from '../../lib/monitoring.es6';
import { getPartnerGuid, sesssionMonitor } from '../../lib/access.es6';
import { reloadPage } from '../../lib/utils.es6';
import { getFeatureFlagsRequest } from '../FeatureFlags/actions.es6';

/**
 * this saga continually watches login request actions
 */
export function* loginRequestWatcher() {
  for (; ;) {
    // pause and wait for the USER_LOGIN_REQUEST action to be dispatched
    const regActionResponse = yield take(types.LOGIN_REQUESTED);

    try {
      const authResponse = yield call(loginUser, regActionResponse.payload);
      saveAuthData(authResponse.data);
      const userInfo = getUserInfo(authResponse.data.access_token);
      const userPartners = yield call(getUserPartners, userInfo.partners);

      const userProfileAuthData = aggregateUserprofileDataFromLogin(authResponse.data, userInfo, userPartners.data);

      yield put(userLoginSuccess(userProfileAuthData));
      yield put(setCurrentPartner(userProfileAuthData.user_partners[0]));
      yield put(getFeatureFlagsRequest())
    } catch (e) {
      console.log(e)
      GAloggerException(e);
      yield put(userLoginFail('Incorrect login details, please try again'));
    }
  }
}

export function* refreshSessionRequestWatcher() {
  while (yield take('SESSION_REFRESH_REQUEST')) {
    try {
      const response = yield call(refreshSession);
      const profileData = aggregateUserprofileDataFromRefresh(response.data)

      yield put(sessionRefreshSuccess(profileData));
      sesssionMonitor();
    } catch (e) {
      GAloggerException(e, 'Session refresh failed');
      yield put(userLoginFail('Refreshing the session fail. Please login'));
      yield put(sessionRefreshFailed());
    }
  }
}

export function* forgotPasswordRequestWatcher() {
  while (true) {
    const response = yield take(types.FORGOT_PASSWORD_REQUEST);
    try {
      yield call(recoverPassword, response.payload);
      yield put(forgotPasswordSuccess());
    } catch (e) {
      GAloggerException(e);
      yield put(forgotPasswordFailure());
    }
  }
}

export function* logoutRequestWatcher() {
  while (true) {
    yield take(types.USER_LOGOUT);
    try {
     const response =  yield call(logoutUser);
     console.log(response.data)
    } catch (e) {
      GAloggerException(e);
    } finally {
      logout();
    }
  }
}

export function* getStartedMessageRequestWatcher() {
  while (true) {
    const request = yield take(types.GET_STARTED_REQUEST);
    try {
      const response =  yield call(getStarted, request.payload);
      yield put(getStartedSuccess(response.data));
    } catch (e) {
      GAloggerException(e);
      console.log("get started error",e)
      yield put(getStartedFailure(e.response.data));
    }
  }
}


function logout() {
  const partnerGuid = getPartnerGuid();
  if (partnerGuid === 'britamguid') {
    hashHistory.push('britam');
  } else {
    hashHistory.push('login');
  }

  const maxId = setTimeout(() => { }, 0);
  for (let i = 0; i < maxId; i += 1) {
    clearTimeout(i);
  }
  
  localStorage.clear();
  reloadPage()
}

function saveAuthData(authInfo) {
  authInfo.timestamp = Date.now();
  authInfo.expires_in = authInfo.expires_in * 1000 + authInfo.timestamp;
  localStorage.setItem('auth', JSON.stringify(authInfo));
}

function aggregateUserprofileDataFromLogin(authInfo, userInfo, userPartners) {
  const profile = authInfo;
  profile.user_guid = userInfo.sub;
  profile.user_fullname = userInfo.name;
  profile.user_partners = userPartners;
  profile.branch = userInfo.branch;

  profile.user_principle = {
    authorities: transformUserRolesToAuthorities(userInfo.roles),
    username: userInfo.preferred_username,
    email: userInfo.email,
    email_verified: userInfo.email_verified
  };

  localStorage.setItem('auth', JSON.stringify(profile));
  localStorage.setItem(
    'partner',
    JSON.stringify(userPartners[0])
  );

  return profile;
}


function transformUserRolesToAuthorities(roles) {
  const authorities = []
  for (let i = 0; i < roles.length; i++) {
    authorities.push({ authority: roles[i] })
  }

  return authorities;
}

function aggregateUserprofileDataFromRefresh(authData) {
  authData.timestamp = Date.now();
  authData.expires_in =
    authData.expires_in * 1000 + authData.timestamp;

  const profileData = JSON.parse(localStorage.getItem('auth'));
  profileData.timestamp = authData.timestamp;
  profileData.expires_in = authData.expires_in;
  profileData.access_token = authData.access_token;
  profileData.refresh_expires_in = authData.refresh_expires_in;
  profileData.refresh_token = authData.refresh_token;


  localStorage.setItem('auth', JSON.stringify(profileData));

  return profileData;
}


function getUserInfo(accessToken) {
  return JSON.parse(atob(accessToken.split('.')[1]));
}