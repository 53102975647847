import React from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { deepOrange } from '@material-ui/core/colors'
import * as actions from './actions.es6';
import { buildFailureMessage, formatDate } from '../../lib/utils.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { localisedText } from '../../lib/localisation.es6';
import {
  getInternationalCallingCode,
  addInternationalCallingCode,
  getLocalNumberLength,
  partnerCustomerFieldsContains,
  hasInternationalCallingCode,
  partnerLanguages,
  partnerPullKyc,
  customerLanguagesVisible,
  languageLabel,
  getPartnerGuid,
} from '../../lib/access.es6';
import { Button, Grid } from '@mui/material';
// import { Cached, Edit } from '@mui/icons-material';
import { SECONDARY_THEME_COLOR_SHADE_1 } from '../../lib/constants';
import MainPageTitle from '../../components/MainPageTitle/index.es6';

export class Customer extends React.Component {
  constructor(props) {
    super(props);
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const hasWallet = currentCustomer ? currentCustomer.has_wallet : true;
    const localizedStrings = new LocalizedStrings(localisedText);
    this.state = {
      language: '',
      labels: localizedStrings,
      hasWallet,
      isPartnerPullKyc: partnerPullKyc(),
    };
  }

  componentWillMount() {
    this.updateLanguage();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'Customer',
      this.state.labels
    );
  }

  failureMessage() {
    const defaultErrorMessage =
      'An error occurred, this could be due to invalid data';
    const errorMessage = buildFailureMessage(
      defaultErrorMessage,
      this.props.currentCustomerData.errors
    );
    return errorMessage;
  }

  successMessage() {
    return this.state.labels.kyc_success_edit_message;
  }

  dialogDisplayUserErrorFeedback() {
    const styleErrorMessage = {
      display: this.props.currentCustomerData.errors ? 'block' : 'none',
    };
    return (
      <center style={styleErrorMessage}>
        {' '}
        <p style={{ color: 'red' }}>{this.failureMessage()} </p>{' '}
      </center>
    );
  }

  dialogDisplayUserSuccessFeedback() {
    const styleSuccessMessage = {
      display: this.props.currentCustomerData.success_message
        ? 'block'
        : 'none',
    };
    return (
      <center style={styleSuccessMessage}>
        {' '}
        <p style={{ color: 'green' }}>{this.successMessage()} </p>{' '}
      </center>
    );
  }

  // TODO: Move this in a helper method. It should use info on the country
  localNumber(msisdn) {
    return msisdn.substring(5);
  }

  pullCustomerKycRequest() {
    const customerData = {
      guid: this.props.currentCustomerData.currentCustomer.guid,
    };
    this.props.dispatch(actions.pullCustomerKycRequest(customerData));
  }

  submitUpdateCustomerRequest() {
    const labels = this.state.labels;
    const regForm = new FormData(document.getElementById('editDialogForm'));
    // TODO: Use class state instead of reducer to manage error
    // TODO: Move this validation in a helper method
    if (regForm.get('phone').length !== getLocalNumberLength()) {
      const errorObject = {
        message: `Phone number must be ${getLocalNumberLength()} digits`,
      };
      this.props.dispatch(actions.displayError(errorObject));
      return;
    }

    const customerData = {
      guid: this.props.currentCustomerData.currentCustomer.guid,
      date_of_birth: this.state.birthday,
      msisdn: getInternationalCallingCode() + regForm.get('phone'),
    };

    if (this.beneficiaryValid() === false) {
      // TODO: Display error message
      // return;
    }

    this.updateHasWalletInfo(customerData);

    // TODO: Use class state instead of reducer to manage error
    if (this.fullNameValid() === false) {
      const errorObject = {
        message: labels.errorMissingFullName,
      };
      this.props.dispatch(actions.displayError(errorObject));
      return;
    }
    this.updateFullNameInfo(customerData);

    // TODO: Use class state instead of reducer to manage error
    if (this.nationalIdValid() === false) {
      const errorObject = {
        message: labels.errorNationalIdMissing,
      };
      this.props.dispatch(actions.displayError(errorObject));
      return;
    }
    this.updateNationalIdInfo(customerData);

    // TODO: Use class state instead of reducer to manage error
    // TODO: Remove duplication
    if (this.firstNameValid() === false) {
      const errorObject = {
        message: labels.errorMissingFirstName,
      };
      this.props.dispatch(actions.displayError(errorObject));
      return;
    }
    this.updateFirstNameInfo(customerData);

    // TODO: Use class state instead of reducer to manage error
    // TODO: Remove duplication
    if (this.surnameValid() === false) {
      const errorObject = {
        message: labels.errorMissingSurname,
      };
      this.props.dispatch(actions.displayError(errorObject));
      return;
    }
    this.updateSurnameInfo(customerData);

    this.updateLanguageInfo(customerData);
    if (!this.languageValid()) {
      delete customerData.language;
    }

    this.updateBeneficiaryInfo(customerData);
    this.props.dispatch(actions.updateCustomerRequest(customerData));
  }

  updateBeneficiaryInfo(customerData) {
    const regForm = new FormData(document.getElementById('editDialogForm'));
    customerData.beneficiary_name = regForm.get('beneficiary');
    if (regForm.get('beneficiary_msisdn')) {
      customerData.beneficiary_msisdn = this.getInternationalNumber(
        regForm.get('beneficiary_msisdn')
      );
    }
  }

  getInternationalNumber(number) {
    if (hasInternationalCallingCode(number)) {
      return number;
    }
    return getInternationalCallingCode() + number;
  }

  dateHandler(type, moment) {
    const rawDate = formatDate(new Date(moment));
    if (type === 'birthday') {
      this.setState({ birthday: rawDate });
    }
  }

  handleOpen() {
    this.props.dispatch(actions.openEditCustomerModal());
  }

  handleClose() {
    this.props.dispatch(actions.closeEditCustomerModal());
  }

  cancelEdit() {
    this.clearDialogForm();
    this.handleClose();
  }

  refreshCustomer() {
    this.props.dispatch(
      actions.getCustomer(this.props.currentCustomerData.currentCustomer.guid)
    );
  }

  clearDialogForm() {
    document.getElementById('editDialogForm').reset();
    this.setState({ birthday: '' });
  }

  loyaltyRegistrationText() {
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const labels = this.state.labels;
    if (currentCustomer.accept_loyalty === true) {
      return labels.loyaltyOpOut;
    }
    return labels.loyaltyOpIn;
  }

  loyaltyRegistrationUpdate() {
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const payload = {
      msisdn: currentCustomer.msisdn,
      optin: !currentCustomer.accept_loyalty,
      partner_guid: getPartnerGuid(),
    };
    this.props.dispatch(actions.changeLoyaltyRegistrationRequest(payload));
  }

  beneficiaryValid() {
    const regForm = new FormData(document.getElementById('editDialogForm'));
    if (this.beneficiaryVisible()) {
      const beneficiaryMsisdn = regForm.get('beneficiary_msisdn');
      if (beneficiaryMsisdn) {
        if (beneficiaryMsisdn.length !== getLocalNumberLength()) {
          return false;
        }
      }
    }
    return true;
  }

  beneficiaryVisible() {
    return (
      partnerCustomerFieldsContains('beneficiary_name') ||
      partnerCustomerFieldsContains('beneficiary_msisdn')
    );
  }

  beneficiaryDisplay() {
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const labels = this.state.labels;
    const beneficiaryStyle = {
      display: this.beneficiaryVisible() ? 'block' : 'none',
    };
    return (
      <div style={beneficiaryStyle} >
        <div className="form-group">
          <label className="control-label col-md-4 col-sm-4 col-xs-12">
            {labels.beneficiaryFullname}
          </label>
          <div className="col-md-6 col-sm-6 col-xs-12 padding5">
            {currentCustomer.beneficiary_name}
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-md-4 col-sm-4 col-xs-12">
            {labels.beneficiaryPhoneNumber}
          </label>
          <div className="col-md-6 col-sm-6 col-xs-12 padding5">
            {currentCustomer.beneficiary_msisdn}
          </div>
        </div>
      </div>
    );
  }

  beneficiaryDialogDisplay() {
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const labels = this.state.labels;
    const beneficiaryStyle = {
      display: this.beneficiaryVisible() ? 'block' : 'none',
    };
    return (
      <div style={beneficiaryStyle}>
        <div className="form-group">
          <label className="control-label col-md-4 col-sm-4 col-xs-12">
            {labels.beneficiaryFullname}
          </label>
          <div className="col-md-6 col-sm-6 col-xs-12">
            <input
              className="form-control col-md-7 col-xs-12"
              name="beneficiary"
              type="text"
              defaultValue={currentCustomer.beneficiary_name}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-md-4 col-sm-4 col-xs-4">
            {addInternationalCallingCode(labels.beneficiaryPhoneNumber)}
          </label>
          <div className="col-md-6 col-sm-4 col-xs-4">
            <input
              type="text"
              className="form-control"
              name="beneficiary_msisdn"
              defaultValue={currentCustomer.beneficiary_msisdn}
            />
          </div>
        </div>
      </div>
    );
  }

  loyaltyRegistrationDisplay() {
    const loyaltyRegistrationStyle = {
      display: this.loyaltyRegistrationVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    return (
      <div className="form-group" style={loyaltyRegistrationStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.acceptsLoyalty} <span className="required">*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12 padding5">
          <Checkbox required checked={currentCustomer.accept_loyalty} />
        </div>
      </div>
    );
  }

  loyaltyRegistrationButtonDisplay() {
    const loyaltyRegistrationStyle = {
      display: this.loyaltyRegistrationVisible() ? 'block' : 'none',
      backgroundColor: SECONDARY_THEME_COLOR_SHADE_1,
      color: 'white'
    };
    return (
      <Button
        onClick={this.loyaltyRegistrationUpdate.bind(this)}
        style={loyaltyRegistrationStyle}
        variant='contained'
        size='small'
      >
        {this.loyaltyRegistrationText()}
      </Button>
    );
  }

  loyaltyRegistrationVisible() {
    return partnerCustomerFieldsContains('accept_loyalty');
  }

  hasWalletDisplay() {
    const hasWalletStyle = {
      display: this.hasWalletVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    return (
      <div className="form-group" style={hasWalletStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.hasWallet} <span className="required">*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12 padding5">
          <Checkbox required checked={currentCustomer.has_wallet} />
        </div>
      </div>
    );
  }

  hasWalletVisible() {
    return partnerCustomerFieldsContains('has_wallet');
  }

  updateHasWalletInfo(customerData) {
    if (this.hasWalletVisible()) {
      customerData.has_wallet = this.state.hasWallet;
    }
  }

  toggleHasWallet() {
    const hasWallet = this.state.hasWallet;
    this.setState({ hasWallet: !hasWallet });
  }

  hasWalletDialogDisplay() {
    const labels = this.state.labels;
    const hasWalletStyle = {
      display: this.hasWalletVisible() ? 'block' : 'none',
    };
    return (
      <div className="form-group" style={hasWalletStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.hasWallet} <span className="required">*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12 padding5">
          <Checkbox
            required
            checked={this.state.hasWallet}
            onChange={this.toggleHasWallet.bind(this)}
          />
        </div>
      </div>
    );
  }

  agentDisplay() {
    const agentStyle = {
      display: this.agentVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    return (
      <div className="form-group" style={agentStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.agent} <span className="required">*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12 padding5">
          <Checkbox required checked={currentCustomer.agent} />
        </div>
      </div>
    );
  }

  agentVisible() {
    return partnerCustomerFieldsContains('agent');
  }

  nationalIdValid() {
    const regForm = new FormData(document.getElementById('editDialogForm'));
    if (this.nationalIdVisible()) {
      const nationalId = regForm.get('national_id');
      if (nationalId) {
        return nationalId.length !== 0;
      }
    }
    return true;
  }

  updateNationalIdInfo(customerData) {
    const regForm = new FormData(document.getElementById('editDialogForm'));
    if (this.nationalIdVisible()) {
      const nationalId = regForm.get('national_id');
      if (nationalId) {
        customerData.national_id = nationalId;
      }
    }
  }

  nationalIdDialogDisplay() {
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const labels = this.state.labels;
    const nationalIdStyle = {
      display: this.nationalIdVisible() ? 'block' : 'none',
    };
    return (
      <div className="form-group" style={nationalIdStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.nationalId}
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <input
            className="form-control col-md-7 col-xs-12"
            name="national_id"
            id="national_id"
            type="text"
            defaultValue={currentCustomer.national_id}
            value={this.state.pulled_national_id}
            readOnly={this.state.isPartnerPullKyc}
          />
        </div>
      </div>
    );
  }

  nationalIdDisplay() {
    const nationalIdStyle = {
      display: this.nationalIdVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    return (
      <div className="form-group" style={nationalIdStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.nationalId} <span className="required">*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12 padding5">
          {currentCustomer.national_id}
        </div>
      </div>
    );
  }

  nationalIdVisible() {
    return partnerCustomerFieldsContains('national_id');
  }

  // TODO: Remove duplication
  firstNameValid() {
    const regForm = new FormData(document.getElementById('editDialogForm'));
    if (this.firstNameVisible()) {
      const firstName = regForm.get('first_name');
      if (firstName) {
        return firstName.length !== 0;
      }
    }
    return true;
  }

  updateFirstNameInfo(customerData) {
    const regForm = new FormData(document.getElementById('editDialogForm'));
    if (this.firstNameVisible()) {
      const firstName = regForm.get('first_name');
      if (firstName) {
        customerData.first_name = firstName;
      }
    }
  }

  firstNameDialogDisplay() {
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const labels = this.state.labels;
    const firstNameStyle = {
      display: this.firstNameVisible() ? 'block' : 'none',
    };
    return (
      <div className="form-group" style={firstNameStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.firstName}
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <input
            className="form-control col-md-7 col-xs-12"
            name="first_name"
            id="first_name"
            type="text"
            defaultValue={currentCustomer.first_name}
            value={this.state.pulled_first_name}
            readOnly={this.state.isPartnerPullKyc}
          />
        </div>
      </div>
    );
  }

  firstNameDisplay() {
    const firstNameStyle = {
      display: this.firstNameVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    return (
      <div className="form-group" style={firstNameStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.firstName} <span className="required">*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12 padding5">
          {currentCustomer.first_name}
        </div>
      </div>
    );
  }

  firstNameVisible() {
    return partnerCustomerFieldsContains('first_name');
  }

  // TODO: Remove duplication
  surnameValid() {
    const regForm = new FormData(document.getElementById('editDialogForm'));
    if (this.surnameVisible()) {
      const surname = regForm.get('surname');
      if (surname) {
        return surname.length !== 0;
      }
    }
    return true;
  }

  updateSurnameInfo(customerData) {
    const regForm = new FormData(document.getElementById('editDialogForm'));
    if (this.surnameVisible()) {
      const surname = regForm.get('surname');
      if (surname) {
        customerData.surname = surname;
      }
    }
  }

  surnameDialogDisplay() {
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const labels = this.state.labels;
    const surnameStyle = {
      display: this.surnameVisible() ? 'block' : 'none',
    };
    return (
      <div className="form-group" style={surnameStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.surname}
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <input
            className="form-control col-md-7 col-xs-12"
            name="surname"
            id="surname"
            type="text"
            defaultValue={currentCustomer.surname}
            value={this.state.pulled_lastname}
            readOnly={this.state.isPartnerPullKyc}
          />
        </div>
      </div>
    );
  }

  surnameDisplay() {
    const surnameStyle = {
      display: this.surnameVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    return (
      <div className="form-group" style={surnameStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.surname} <span className="required">*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12 padding5">
          {currentCustomer.surname}
        </div>
      </div>
    );
  }

  surnameVisible() {
    return partnerCustomerFieldsContains('surname');
  }

  fullNameValid() {
    const regForm = new FormData(document.getElementById('editDialogForm'));
    if (this.fullNameVisible()) {
      const fullName = regForm.get('full_name');
      if (fullName) {
        return fullName.length !== 0;
      }
    }
    return true;
  }

  updateFullNameInfo(customerData) {
    const regForm = new FormData(document.getElementById('editDialogForm'));
    if (this.fullNameVisible()) {
      const fullName = regForm.get('full_name');
      if (fullName) {
        customerData.full_name = fullName;
      }
    }
  }

  fullNameDialogDisplay() {
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const labels = this.state.labels;
    const fullNameStyle = {
      display: this.fullNameVisible() ? 'block' : 'none',
    };
    return (
      <div className="form-group" style={fullNameStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.fullName}
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <input
            className="form-control col-md-7 col-xs-12"
            name="full_name"
            id="full_name"
            type="text"
            defaultValue={currentCustomer.full_name}
            value={this.state.pulled_full_name}
            readOnly={this.state.isPartnerPullKyc}
          />
        </div>
      </div>
    );
  }

  fullNameDisplay() {
    const fullNameStyle = {
      display: this.fullNameVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    return (
      <div className="form-group" style={fullNameStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.fullName} <span className="required">*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12 padding5">
          {currentCustomer.full_name}
        </div>
      </div>
    );
  }

  fullNameVisible() {
    return partnerCustomerFieldsContains('full_name');
  }

  languageValid() {
    if (this.languageVisible()) {
      const language = this.state.language;
      if (language) {
        return language.length !== 0;
      }
    }
    return true;
  }

  updateLanguageInfo(customerData) {
    if (this.languageVisible()) {
      const language = this.state.language;
      if (language) {
        customerData.language = language;
      }
    }
  }

  languageLabel(languageIn) {
    return languageLabel(languageIn);
  }

  selectLanguages(e) {
    this.setState({ language: e.target.value });
  }

  languageDialogDisplay() {
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const labels = this.state.labels;
    const languages = partnerLanguages();
    const languageStyle = {
      display: this.languageVisible() ? 'block' : 'none',
    };
    return (
      <div className="form-group" style={languageStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.lang}
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="col-md-4 col-sm-4 col-xs-4">
            <select
              id="languages"
              onChange={this.selectLanguages.bind(this)}
              defaultValue={currentCustomer.language}
            >
              <option key="-1" value="">
                {labels.selectLanguage}
              </option>
              {languages.map((lang, index) => (
                <option key={index} value={lang}>
                  {lang}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }

  languageDisplay() {
    const languageStyle = {
      display: this.languageVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    return (
      <div className="form-group" style={languageStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.lang}
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12 padding5">
          {this.languageLabel(currentCustomer.language)}
        </div>
      </div>
    );
  }

  languageVisible() {
    return customerLanguagesVisible();
  }

  render() {
    const labels = this.state.labels;
    const styles = { backgroundColor: deepOrange[400], color: '#FFF' };
    const actionButtons = [
      <Button style={styles} onClick={this.cancelEdit.bind(this)}>
        {labels.cancel}
      </Button>,
      <Button
        onClick={this.pullCustomerKycRequest.bind(this)}
        style={{
          display: this.state.isPartnerPullKyc ? '' : 'none',
          backgroundColor: deepOrange[400],
          color: '#FFF',
        }}
      >
        {labels.getKyc}
      </Button>,
      <Button
        style={styles}
        keyboardFocused={this.state.isPartnerPullKyc === false}
        onClick={this.submitUpdateCustomerRequest.bind(this)}
      >
        {labels.edit}
      </Button>,
    ];
    if (
      !this.props.currentCustomerData ||
      !this.props.currentCustomerData.currentCustomer
    ) {
      return (
        <div className="col-md-12 col-sm-12 col-xs-12">
          {labels.nothingToDisplay}
        </div>
      );
    }
    const currentCustomer = this.props.currentCustomerData.currentCustomer;

    return (
      <React.Fragment>
        <div>
          <Grid container>
            <Grid item xs={4}>
              <div style={{ marginLeft: '1%' }}>
                <MainPageTitle pageTitle={labels.titleCustomerDetails} />
              </div>
            </Grid>
            <Grid item xs={5}>
            </Grid>


            {/* migrating this
            <Grid item xs={3}>
              <ButtonGroup style={{ marginLeft: '15%', marginTop: '3%' }} variant="text" aria-label="outlined primary button group">
                <IconButton
                  onClick={this.refreshCustomer.bind(this)}
                  style={{ color: SECONDARY_THEME_COLOR_SHADE_1 }}
                  size='small'
                >
                  <Cached />
                </IconButton>
                <IconButton
                  onClick={this.handleOpen.bind(this)}
                  style={{ color: SECONDARY_THEME_COLOR_SHADE_1 }}
                  size='small'
                >
                  <Edit />
                </IconButton>

                {this.loyaltyRegistrationButtonDisplay()}

              </ButtonGroup>


            </Grid>
             */}
          </Grid>

          <div className="clearfix">
            {this.dialogDisplayUserErrorFeedback()}
          </div>
          <div className="clearfix">
            {this.dialogDisplayUserSuccessFeedback()}
          </div>

        </div>
        <div className="x_content">
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow >
                  <TableCell className="table-cell-header">{labels.nationalId}</TableCell>
                  <TableCell className="table-cell-header">{labels.firstName}</TableCell>
                  <TableCell className="table-cell-header">{labels.surname}</TableCell>
                  <TableCell className="table-cell-header">{labels.phoneNumber}</TableCell>
                  <TableCell className="table-cell-header">{labels.dateOfBirth}</TableCell>
                  {currentCustomer.gender && <TableCell className="table-cell-header">{labels.gender}</TableCell>}
                  <TableCell className="table-cell-header">{labels.lang}</TableCell>
                  {currentCustomer.web_agent_name && <TableCell className="table-cell-header">{labels.registeredBy}</TableCell>}
                  <TableCell className="table-cell-header">{labels.agent}</TableCell>
                  <TableCell className="table-cell-header">{labels.acceptsLoyalty}</TableCell>
                  {/* <TableCell
                    className="table-cell-header"
                    style={{
                      display: this.beneficiaryVisible() ? '' : 'none',
                    }}
                  > {labels.beneficiaryFullname}
                  </TableCell>
                  <TableCell
                    className="table-cell-header"
                    style={{
                      display: this.beneficiaryVisible() ? '' : 'none',
                    }}
                  > {labels.beneficiaryPhoneNumber}
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">{currentCustomer.national_id}</TableCell>
                  <TableCell align="left">{currentCustomer.first_name}</TableCell>
                  <TableCell align="left">{currentCustomer.surname}</TableCell>
                  <TableCell align="left">{currentCustomer.msisdn}</TableCell>
                  <TableCell align="left">{currentCustomer.date_of_birth}</TableCell>
                  {currentCustomer.gender && <TableCell align="left">{currentCustomer.gender}</TableCell>}
                  <TableCell align="left">{currentCustomer.language}</TableCell>
                  {currentCustomer.web_agent_name && <TableCell align="left">{currentCustomer.web_agent_name}</TableCell>}
                  <TableCell align="left"><Checkbox required checked={currentCustomer.agent} style={{color: SECONDARY_THEME_COLOR_SHADE_1}} /></TableCell>
                  <TableCell align="left"> <Checkbox required checked={currentCustomer.accept_loyalty} style={{color: SECONDARY_THEME_COLOR_SHADE_1}} /></TableCell>
                  {/* <TableCell
                    align="left"
                    style={{
                      display: this.beneficiaryVisible() ? '' : 'none',
                    }}
                  >
                    {currentCustomer.beneficiary_name}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      display: this.beneficiaryVisible() ? '' : 'none',
                    }}
                  >
                    {currentCustomer.beneficiary_msisdn}
                  </TableCell> */}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <Dialog
          open={this.props.currentCustomerData.editModalState}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">
            {labels.dialogTitleCustomer}
          </DialogTitle>
          <DialogContent>
            <center>
              {' '}
              <CircularProgress
                style={{
                  display: this.props.currentCustomerData.edit_loader
                    ? ''
                    : 'none',
                }}
              />{' '}
            </center>
            <form
              id="editDialogForm"
              className="form-horizontal form-label-left"
              noValidate
            >
              {this.dialogDisplayUserErrorFeedback()}
              {this.nationalIdDialogDisplay()}
              {this.firstNameDialogDisplay()}
              {this.surnameDialogDisplay()}
              {this.hasWalletDialogDisplay()}
              {this.languageDialogDisplay()}
              {this.agentDisplay()}
              <div className="form-group">
                <label className="control-label col-md-4 col-sm-4 col-xs-4">
                  {addInternationalCallingCode(labels.phoneNumber)}
                  <span>*</span>
                </label>
                <div className="col-md-6 col-sm-4 col-xs-4">
                  <input
                    readOnly
                    type="text"
                    className="form-control"
                    name="phone"
                    required
                    defaultValue={this.localNumber(currentCustomer.msisdn)}
                  />
                </div>
              </div>
              {this.fullNameDialogDisplay()}
              <div className="form-group">
                <label className="control-label col-md-4 col-sm-4 col-xs-12">
                  {labels.dateOfBirth}
                  <span>*</span>
                </label>
                <div className="col-md-6 col-sm-3 col-xs-12">
                  {this.state.isPartnerPullKyc ? (
                    <input
                      name="birthday"
                      id="birthday"
                      defaultValue={currentCustomer.date_of_birth}
                      className="form-control col-md-7 col-xs-12"
                      required
                      readOnly
                      type="text"
                      value={this.state.pulled_birthday}
                    />
                  ) : (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        value={currentCustomer.date_of_birth}
                        onChange={this.dateHandler.bind(this, 'birthday')}
                        format="YYYY-MM-DD"
                        keyboard
                        clearable
                        placeholder="Date of birth"
                      />
                    </MuiPickersUtilsProvider>
                  )}
                </div>
              </div>
              {this.beneficiaryDialogDisplay()}
            </form>
          </DialogContent>
          <DialogActions>
            {actionButtons.map((item, index) => (
              <div key={index}> {item}</div>
            ))}
          </DialogActions>
        </Dialog>

        {/*  */}
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  currentCustomerData: state.currentCustomer,
  globalData: state.global,
}))(Customer);
