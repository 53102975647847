import React from 'react'
import { hashHistory } from 'react-router';
import Grid from "@material-ui/core/Grid";
import TableInc from '../../../../../shared-ui/TableInc';
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import { formatDateLocale } from '../../../../../lib/utils.es6';
import { getPartner } from '../../../../../lib/access.es6';
import SkeletonWrapper from '../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';
import ViewDocument from '../../../../../shared-ui/ViewDocument/ViewDocument';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const DisplayPayments = (props) => {

  const [viewDocumentUrl, setViewDocumentUrl] = React.useState(null);
  const [numPages, setNumPages] = React.useState(null);

  if (props.loader) {
    return <SkeletonWrapper loader={props.loader} />
  }
  const currencyCode = getPartner().country.currency_code

  const tempArr = props.payments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map(payment => {
    return {
      ...payment,
      policy_number: payment.additional_details ? payment.additional_details.policy_number : "N/A",
      processing_status: payment.processing_status,
      amount_in_cents: `${currencyCode} ${payment.amount_in_cents / 100} `,
      payment_failure_reason: payment.payment_failure_reason,
      proof_of_payment_url: payment.proof_of_payment_url,
      effected_at: formatDateLocale(payment.effected_at) || "N/A",
      created_at: formatDateLocale(payment.created_at) || "N/A",
      premium_granularity: (payment.quotation && payment.quotation.total_premium_amount_in_cents)
        ? `${currencyCode}  ${payment.quotation.total_premium_amount_in_cents / 100} per ${payment.quotation.premium_cardinality} ${payment.quotation.premium_granularity}` || "0.00"

        : "N/A",
      total_paid_amount_in_cents: `${currencyCode}  ${payment.total_paid_amount_in_cents / 100}` || "0.00",
      balance_amount_in_cents: `${currencyCode}  ${payment.balance_amount_in_cents / 100}` || "0.00",
    }

  })


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} sm={12}>
          <TableInc
            columns={[
              {
                title: 'Policy Number', field: 'policy_number', render: rowData => <>
                  {rowData.policy_number || "N/A"}
                </>
              },
              {
                title: 'Created At', field: 'created_at', render: rowData => <>
                  {rowData.created_at || "N/A"}
                </>
              },
              {
                title: 'Processing Status', field: 'processing_status', render: rowData => <>
                  {rowData.processing_status || "N/A"}
                </>
              },
              {
                title: 'Amount', field: 'amount_in_cents', render: rowData => <>
                  {rowData.amount_in_cents || "N/A"}
                </>
              },

              {
                title: 'Effected At', field: 'effected_at', render: rowData => <>
                  {rowData.effected_at || "N/A"}
                </>
              },
              {
                title: 'payment_failure_reason', field: 'payment_failure_reason', render: rowData => <>
                  {rowData.payment_failure_reason || "N/A"}
                </>
              },
              {
                title: 'Proof of Payment', field: 'proof_of_payment_url', render: rowData => <>
                  {
                    <>
                      <Tooltip title={"View Document"}>
                        <span style={{ cursor: "pointer" }} onClick={() => setViewDocumentUrl(rowData.proof_of_payment_url)}><VisibilityIcon sx={{ color: " #4B7E34" }} /></span></Tooltip>
                      <Tooltip title="Download Document">
                        <span style={{ cursor: "pointer", marginLeft: "10px" }} ><a href={rowData.proof_of_payment_url} download target='_blank' rel="noopener noreferrer" ><FileDownloadIcon sx={{ color: " #000" }} /></a> </span>
                      </Tooltip>

                    </>
                  }
                </>
              },

              {
                title: 'action', field: 'claimaction', render: rowData => {
                  return <div >
                    <ButtonInc
                      onClick={() => hashHistory.push(`admin/payment-processing/${rowData.guid}`)}

                      variant="contained">View Payment</ButtonInc>
                  </div>;
                }
              },
            ]}
            data={tempArr}
            options={{
              pageSize: 5,
              showTitle: false,
            }} />

        </Grid>
      </Grid>
      <ViewDocument
        title="Proof Of Payment Document"
        open={viewDocumentUrl}
        close={() => setViewDocumentUrl(null)}
        viewDocumentUrl={viewDocumentUrl}
        numPages={numPages}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      />
    </>
  )
}

export default DisplayPayments