import React from 'react';
import { Link } from 'react-router';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { localisedText } from '../../lib/localisation.es6';

export class SelectOption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
    };
  }

  componentWillMount() {
    this.updateLanguage();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'SelectOption',
      this.state.labels
    );
  }

  render() {
    const labels = this.state.labels;
    return (
      <div className="login_wrapper loginOption_wrapper">
        <div className="animate form login_form">
          <section className="login_content">
            {/* <img src="src/images/orange_logo.png" width="150"/> */}
            <form>
              <h1>{labels.titleSelect}</h1>
              <div className="whiteBox">
                <div className="col-md-6 ">
                  <Link to="admin">
                    <div className="loginOption">
                      <i
                        className="fa fa-user white fa-2x"
                        aria-hidden="true"
                      />
                      <hr />
                      {labels.customerManagement}
                    </div>
                  </Link>
                </div>
                <div className="col-md-6">
                  <Link to="admin/premium_report">
                    <div className="loginOption">
                      <i
                        className="fa fa-file-text-o fa-2x"
                        aria-hidden="true"
                      />
                      <hr />
                      {labels.reports}
                    </div>
                  </Link>
                </div>
                <div className="clearfix" />
              </div>
              <hr />
            </form>
          </section>
        </div>
      </div>
    );
  }
}

export default connect(state => ({ globalData: state.global }))(SelectOption);
