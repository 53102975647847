import { take, call, put } from 'redux-saga/effects';
import * as types from '../../lib/actionTypes.es6';
import {
  getFeatureFlags,
} from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';



export function* getFeatureFlagsRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.GET_FEATURE_FLAGS_REQUEST);
      const response = yield call(getFeatureFlags, payload);
      yield put(actions.getFeatureFlagsSuccess(response.data));
      localStorage.setItem('feature-flags', JSON.stringify(response.data));
    } catch (e) {
      let errorPayload = {
        message: 'An error occurred, this could be due to invalid data',
      };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.getFeatureFlagsFailed(errorPayload));
      GAloggerException(e);
    }
  }
}