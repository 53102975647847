import {BENEFIT_NAMES} from "../NewProductList/PoductConstants";

export const LOYALTY_FIELDS = Object.freeze([
    'msisdn', 'telephony', 'eazzy_app', 'stk_mbanking'
  ])


  export const REGISTRATION_FIELDS = Object.freeze([
    'guid',
    'external_identifier',
    'full_name',
    'first_name',
    'surname',
    'date_of_birth',
    'msisdn',
    'beneficiary_name',
    'beneficiary_msisdn',
    'national_id',
    'partner',
    'language',
    'active',
    'registration_channel',
    'experiment',
    'agent',
    'web_agent_guid',
    'created_at',
    'updated_at',
    'agent_guid',
    'gender',
    'birth_place',
    'date_of_activation',
    'web_agent_name',
    'address',
    'email',
  ])

  export const POLICY_FIELDS = Object.freeze([
    'start_date',
    'end_date',
    'spouse_dob',
    'created_at',
    'next_cash_back_disbursement_date',
    'cash_back_start_date',
    'updated_at',
    'guid',
    'policy_number',
    'customer_guid',
    'product_guid',
    'partner_guid',
    'is_policy_downgrading',
    'channel',
    'beneficiary_name',
    'beneficiary_msisdn',
    'spouse_name',
    'spouse_id',
    'spouse_msisdn',
    'dependent_start_date',
    'status',
    'active',
    'balance_in_cents',
    'group_premium_balance_in_cents',
    'waiting_period_days',
    'agent_guid',
    'web_agent_guid',
    'debit',
    'has_premium_in_waiting_period',
    'payment_method',
    'product_type',
    'cover_type',
  ])


  export const WEB_DISPLAY_FIELDS = Object.freeze([
   'dateOfBirth', 'firstName', 'surname', 'msisdn', 'nationalId'
  ])

  export const CUSTOMER_FIELDS = Object.freeze([
  'guid',
  'external_identifier',
  'full_name',
  'first_name',
  'surname',
  'date_of_birth',
  'msisdn',
  'beneficiary_name',
  'beneficiary_msisdn',
  'national_id',
  'partner',
  'language',
  'active',
  'registration_channel',
  'experiment',
  'agent',
  'web_agent_guid',
  'created_at',
  'updated_at',
  'agent_guid',
  'gender',
  'birth_place',
  'date_of_activation',
  'web_agent_name',
  'address',
  'email',
])


  export const CLAIM_TYPES = Object.freeze(BENEFIT_NAMES.VARIABLE.concat(BENEFIT_NAMES.FIXED))

  export const CLAIM_ASSESSMENT_MODES = Object.freeze(['None','Manual', 'SemiAutomatic', 'Automatic'])

  export const POLICY_SUBSCRIPTION_TYPES = Object.freeze([
    'SINGLE_POLICY_PER_PRODUCT_TYPE', 'MULTI_POLICY_DIFFERENT_BENEFIT_TYPES', 'MULTI_POLICY_DIFFERENT_PRODUCT_KIND'
  ])

  export const POLICY_SCHEME_TYPES = Object.freeze([
    "Complex", "Simple"
  ])
