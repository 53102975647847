import * as types from '../../../../../../lib/actionTypes.es6';

export const initialState = {

  get_premiums_errors: null,
  get_premiums_loader: false,
  get_premiums_success_message: '',
  premiums: [],


  get_products_errors: null,
  get_products_loader: false,
  get_products_success_message: '',
  products: [],

  
};

export default function orangePremiumsReducer(state = initialState, action) {
  switch (action.type) {



    case types.GET_ALL_ORANGE_PREMIUMS_REQUEST:
      return { ...state, get_premiums_loader: true, get_premiums_errors: null };
    case types.GET_ALL_ORANGE_PREMIUMS_SUCCESS:
      return { ...state, get_premiums_loader: false, get_premiums_errors: null, premiums: action.payload };
    case types.GET_ALL_ORANGE_PREMIUMS_FAILURE:
      return { ...state, get_premiums_loader: false, get_premiums_errors: action.payload };



      case types.GET_ALL_ORANGE_PRODUCTS_REQUEST:
        return { ...state, get_products_loader: true, get_products_errors: null };
      case types.GET_ALL_ORANGE_PRODUCTS_SUCCESS:
        return { ...state, get_products_loader: false, get_products_errors: null, products: action.payload };
      case types.GET_ALL_ORANGE_PRODUCTS_FAILURE:
        return { ...state, get_products_loader: false, get_products_errors: action.payload };

    default:
      return state;
  }
}


// function getBenefits(product) {
//   let benefits = [
//     ...product.variable_indemnities.map((benefit) => { return { amount: benefit.amount, type: benefit.name } }),
//     ...product.fixed_indemnities.map((benefit) => { return { amount: benefit.amount_benefit, type: benefit.name } }),
//   ]
//   return benefits
// }




// function getPremiumsWithProductDetails(premiums, products, polices) {
//   let npremiums = premiums.map((premium) => {
//     const policy = find(polices, { guid: premium.policy_guid })
//     const product = find(products, { guid: policy.product_guid })
//     return {
//       ...premium,
//       product_name: product.name || '',
//       product_cover_type: product.cover_type || '',
//       benefits: getBenefits(product)
//     }
//   })
//   return npremiums
// }

