import { take, call, put } from 'redux-saga/effects';
import * as types from '../../lib/actionTypes.es6';
import {
  downloadDocument,
  addTemplateDocument,
  deleteTemplateDocument,
  getTemplateDocuments,
  editTemplateDocument,
} from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

export function* downloadDocumentRequestWatcher() {
  for (;;) {
    const request = yield take(types.DOWNLOAD_DOCUMENT_REQUEST);
    try {
      const response = yield call(downloadDocument, request.payload);
      yield put(actions.downloadDocumentSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.downloadDocumentFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

// template docs

export function* getTemplateDocumentRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.GET_TEMPLATE_DOCUMENTS_REQUEST);
      const response = yield call(getTemplateDocuments, payload);
      yield put(actions.getTemplateDocumentsSuccess(response.data));
    } catch (e) {
      let errorPayload = {
        message: 'An error occurred, this could be due to invalid data',
      };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.getTemplateDocumentsFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* addTemplateDocumentRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.ADD_TEMPLATE_DOCUMENT_REQUEST);

      const response = yield call(addTemplateDocument, payload);
      yield put(actions.addTemplateDocumentSuccess(response.data));
      yield put(actions.getTemplateDocumentsRequest());
    } catch (e) {
      let errorPayload = {
        message: 'An error occurred, this could be due to invalid data',
      };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.addTemplateDocumentFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* editTemplateDocumentRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.EDIT_TEMPLATE_DOCUMENT_REQUEST);

      const response = yield call(editTemplateDocument, payload);
      yield put(actions.editTemplateDocumentSuccess(response.data));
      yield put(actions.getTemplateDocumentsRequest());
    } catch (e) {
      let errorPayload = {
        message: 'An error occurred, this could be due to invalid data',
      };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.editTemplateDocumentFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* deleteTemplateDocumentRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.DELETE_TEMPLATE_DOCUMENT_REQUEST);

      const response = yield call(deleteTemplateDocument, payload);
      yield put(actions.deleteTemplateDocumentSuccess(response.data));
      yield put(actions.getTemplateDocumentsRequest());
    } catch (e) {
      let errorPayload = {
        message: 'An error occurred, this could be due to invalid data',
      };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.deleteTemplateDocumentFailed(errorPayload));
      GAloggerException(e);
    }
  }
}
