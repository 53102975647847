import { take, call, put } from 'redux-saga/effects';
import { registerCustomer,updateCustomer } from '../../../../../../lib/requests.es6';
import * as actions from './actions.js';
import * as types from '../../../../../../lib/actionTypes.es6';
import { unAuthorizedNotification } from '../../../../../../lib/access.es6';
import { buildFailureMessage } from '../../../../../../lib/utils.es6';
import { GAloggerException } from '../../../../../../lib/monitoring.es6';
import { makeCurrentCustomer } from '../../../../../../containers/Customer/actions.es6';


/**
 * this saga continually watches register request actions
 */
export function* registerAccessBankRequestWatcher() {
  for (; ;) {
    // pause and wait for the REGISTER_CUSTOMER_REQUEST action to be dispatched
    const regActionResponse = yield take(types.REGISTER_ACCESS_BANK_CUSTOMER_REQUEST);
    try {
      const response = yield call(registerCustomer, regActionResponse.payload);
      yield put(actions.registerAcessBankCustomerSuccess(response.data));
      yield put(
        makeCurrentCustomer(
          response.data.customer,
          'Customer successfully registered'
        )
      );     
        } catch (e) {
      const errorMessage = buildFailureMessage(
        'An error occurred, this could be due to invalid data',
        e.response.data
      );
      yield put(actions.registerAccessBankCustomerFail(errorMessage));
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* updateAccessBankCustomerRequestWatcher() {
  for (;;) {
    const requestAction = yield take(types.UPDATE_ACCESS_BANK_CUSTOMER_REQUEST);
    let response = {};
    try {
      response = yield call(updateCustomer, requestAction.payload);
      yield put(actions.updateAccessBankCustomerSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.updateAccessBankCustomerFail(errorPayload));
    }
  }
}
