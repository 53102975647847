import * as types from '../../lib/actionTypes.es6';
import GAlogger, { monitoringEventCategories } from '../../lib/monitoring.es6';

export function openClaimsRequest() {
  return {
    type: types.OPEN_CLAIM_REQUEST,
  };
}

export function openClaimsSuccess(payload) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.fetchOpenedClaims);
  return {
    type: types.OPEN_CLAIM_SUCCESS,
    payload,
  };
}

export function openClaimsFail(payload) {
  return {
    type: types.OPEN_CLAIM_FAILURE,
    payload,
  };
}
