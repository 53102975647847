import * as React from "react";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";

import AlertDialog from "../../../../../components/AlertDialog/index.es6";

export default function DeleteBenefit(props) {

  if (!props.benefit) {
    return ""
  }

  const [showWarning, setShowWarning ] = React.useState(false);


  const deleteBenefit = ()=>{
    setShowWarning(true) 
    props.deleteBenefit(props.benefit)
  }

  const cancel = ()=>{
    props.resetBenefitToDelete()
  }


  const handleBenefitDeletedSuccessfully = () => {
    props.resetDeleteBenefitSuccessAlert()
    props.resetBenefitToDelete()
  }

  const handleBenefitDeleteError = () => {
    props.resetDeleteBenefitErrorAlert()
  }

  return (
    <React.Fragment>
      <AlertDialog
        custom
        show={props.showDeleteBenefitProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Deleting benefit</h2>
      </AlertDialog>


      <AlertDialog
        warning
        show={!showWarning && props.benefit}
        size="sm"
        title={'Are you sure you want to delete'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => {cancel() }}
        onCancel={() => {deleteBenefit()}}
        confirmBtnText={'Cancel'}
        showCancel={true}
        showConfirm={true}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
      </AlertDialog>

      <AlertDialog
        success
        show={props.showDeleteBenefitSuccessAlert}
        size="sm"
        title={'Benefit deleted'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleBenefitDeletedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => { handleBenefitDeletedSuccessfully() }} style={{ background: 'green', }}>Ok</Button>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showDeleteBenefitErrorAlert}
        danger
        title={'Error deleting benefit'}
        onConfirm={() => handleBenefitDeleteError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.editBenefitError ? props.editBenefitError.message : ''}
      </AlertDialog>






    </React.Fragment>

  );



}
