import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import './DebitOrdersTable.css'
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../../lib/constants";

export default function DisplayDebitOrders(props) {

  const inWholeNumbers = (amount_in_cents) => {
    return ((amount_in_cents / 100)+"").replace(".",",")
  }

  const frozen = Object.freeze(props.debitOrders)

  if (props.debitOrders && props.debitOrders.length > 0) {
    return (
      <>
        <div style={{ marginTop: "2%" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ color: 'red' }}>
                <TableRow >
                  <TableCell className="table-cell-header">granularity</TableCell>
                  <TableCell className="table-cell-header">bank_account_number</TableCell>
                  <TableCell className="table-cell-header">bank_name</TableCell>
                  <TableCell className="table-cell-header">branch_code</TableCell>

                  <TableCell className="table-cell-header">msisdn</TableCell>
                  <TableCell className="table-cell-header">amount_in_cents</TableCell>
                  <TableCell className="table-cell-header">Day</TableCell>
                  <TableCell className="table-cell-header">status</TableCell>

                  <TableCell className="table-cell-header" align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {frozen.map((debitOrder) => {
                  return (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left"> {debitOrder.granularity}</TableCell>
                      <TableCell align="left"> {debitOrder.bank_account_number}</TableCell>
                      <TableCell align="left"> {debitOrder.bank_name}</TableCell>
                      <TableCell align="left"> {debitOrder.branch_code}</TableCell>
                      <TableCell align="left"> {debitOrder.msisdn}</TableCell>
                      <TableCell align="left"> {props.currencyCode + inWholeNumbers(debitOrder.amount_in_cents)}</TableCell>
                      <TableCell align="left"> {debitOrder.at}</TableCell>
                      <TableCell align="left"> {debitOrder.status}</TableCell>





                      <TableCell align="right">
                        <Stack direction="row" spacing={1} justifyContent="flex-end" >
                          <IconButton onClick={() => { props.setDebitOrderToEdit(debitOrder) }} >
                            <EditIcon
                              style={{ color: PRIMARY_THEME_COLOR_SHADE_1, fontWeight: 'bolder', display: props.displayEditButton ? 'inline-block' : 'none' }}
                            />
                          </IconButton>
                          <IconButton onClick={() => { props.hardDeleteDebitOrder(debitOrder) }} >
                            <DeleteForeverIcon
                              style={{ color: 'red', fontWeight: 'bolder', marginRight: "2%", display: props.displayDeleteButton ? 'inline-block' : 'none' }}
                            />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
    );
  }
  return "No debitOrders found, click on the add button to add new debit order."
}
