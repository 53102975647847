import React from 'react'
import DeathClaimDetails from '../DeathClaimDetails/DeathClaimDetails'
import ReinbursementClaimDetails from '../ReinbursementClaimDetails/ReinbursementClaimDetails'

export default function AdditionalDetails(props) {

  if(props.hasDeathBenefit){
    return <DeathClaimDetails {...props} />
  }
  return (
    <ReinbursementClaimDetails {...props} />
  )
}
