import React from 'react';
import { connect } from 'react-redux';
import * as globalActions from '../Main/actions.es6';
import * as actions from "./actions"
import ProductsHeader from '../NewProductList/components/ProductsHeader/ProductsHeader';
import AddBenefiary from '../../components/Beneficiary/AddBenefiary';
import EditBeneficiaryDetails from '../../components/Beneficiary/EditBeneficiaryDetails';
import DeleteBeneficiaryDetails from '../../components/Beneficiary/DeleteBeneficiaryDetails';
import DisplayCustomerBeneficiaryDetails from '../../components/Beneficiary/DisplayCustomerBeneficiaryDetails';
import LocalizedStrings from "react-localization";
import {localisedText} from "../../lib/localisation.es6";
import {updateCurrentLanguage} from "../../lib/languageUtils.es6";
export class BeneficiaryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
      openAddBeneficiaryDialog: false,
      openEditBeneficiaryDialog: false,
      customer_guid: null
    }
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'Customer',
      this.state.labels
    );
  }

  componentDidMount() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      this.props.dispatch(actions.getCustomerBeneficiaryRequest(currentCustomer.guid));
    }
    this.props.dispatch(globalActions.showCustomerBar());
  }

  addBeneficiaryDetails = (payload) => {
    this.props.dispatch(actions.addCustomerBeneficiaryRequest(payload));
  }


  toggleEditBeneficiaryBankDetailsDialog() {
    this.setState({ openEditModal: !this.state.openEditModal });
  }
  closeEditBeneficiaryBankDetailsDialog() {
    this.setState({ openEditModal: false });
  }
  handleOpenConfirmDeleteDialog(beneficiary) {
    this.setState({ openConfirmDelete: false, customerGuid: beneficiary.guid });
  }
  setDeleteDialog(beneficiary) {
    this.setState({ openConfirmDelete: true, customer_guid: beneficiary.guid });
    this.handleCloseActionMenu();

  }
  deleteBeneficiaryBankDetails() {
    this.props.onDelete(this.state.customer_guid);
    this.setState({ openConfirmDelete: false });
  }

  toggleAddBeneficiaryDetailsDialog() {
    this.setState({ openAddBeneficiaryDialog: !this.state.openAddBeneficiaryDialog })
  }

  deleteBeneficiaryDetails(payload) {
    this.props.dispatch(actions.deleteCustomerBeneficiaryRequest(payload));
  }

  editBeneficiaryDetails(payload) {
    this.props.dispatch(actions.editCustomerBeneficiaryRequest(payload));
  }


  render() {
    const beneficiarydetails = this.props.customerBeneficiary.beneficiary
    return (
      <>

        <ProductsHeader
          title="Beneficiary Details"
          onAddButtonClick={this.toggleAddBeneficiaryDetailsDialog.bind(this)}
          showAddBeneficiaryDetailsDialog={this.state.openAddBeneficiaryDialog}
          showAddButton= {this.props.customerBeneficiary.beneficiary===null || this.props.customerBeneficiary.beneficiary===''}
        />


        <AddBenefiary
          openAddBeneficiaryDialog={this.state.openAddBeneficiaryDialog}
          closeAddBeneficiaryDialog={this.toggleAddBeneficiaryDetailsDialog.bind(this)}
          customerGuid={this.props.customerData.currentCustomer.guid}
          addBenefiary={this.addBeneficiaryDetails.bind(this)}
          labels={this.state.labels}
          showAddBeneficiaryDetailsProgressAlert={this.props.customerBeneficiary.createbeneficiaryLoader}
          showAddBeneficiaryDetailsSuccessAlert={this.props.customerBeneficiary.createbeneficiarySuccess}
          showAddBeneficiaryDetailsErrorAlert={!!this.props.customerBeneficiary.createbeneficiaryError}
          error={this.props.customerBeneficiary.createbeneficiaryError}
          resetAddBeneficiaryDetailsSuccessAlert={() => { this.props.dispatch(actions.resetAddCustomerBeneficiaryDetailsSuccess()) }}
          resetAddBenefeciaryDetailsErrorAlert={() => { this.props.dispatch(actions.resetAddCustomerBeneficiaryDetailsFailed()) }}

        />

        <DisplayCustomerBeneficiaryDetails
          beneficiary={beneficiarydetails}
          customerGuid={this.props.customerGuid}
          labels={this.state.labels}

          setBeneficiaryDetailsToDelete={(beneficiary) => this.props.dispatch(actions.setCustomerBeneficiaryDetailsToDelete(beneficiary))}
          setBeneficiaryDetailsToEdit={(beneficiary) => this.props.dispatch(actions.setCustomerBeneficiaryDetailsToEdit(beneficiary))}

          displayEditButton={true}
          displayDeleteButton={true}
          loading={this.props.customerBeneficiary.loader}
        />

        <DeleteBeneficiaryDetails
          beneficiaryDetailstodelete={this.props.customerBeneficiary.beneficiaryToDelete}
          deleteBeneficiaryDetails={this.deleteBeneficiaryDetails.bind(this)}
          resetBeneficiaryDetailsToDelete={() => this.props.dispatch(actions.resetCustomerBeneficiaryDetailsToDelete())}

          showDeleteBeneficiaryDetailsProgressAlert={this.props.customerBeneficiary.deletebeneficiaryLoader}
          showDeleteBeneficiaryDetailsSuccessAlert={this.props.customerBeneficiary.deletebeneficiarySuccess}
          showDeleteBeneficiaryDetailsErrorAlert={!!this.props.customerBeneficiary.deletebeneficiaryError}
          deleteBeneficiaryDetailsError={this.props.customerBeneficiary.deletebeneficiaryError}

          resetDeleteBeneficiarySuccessAlert={() => { this.props.dispatch(actions.resetDeleteCustomerBeneficiarySuccess()) }}
          resetDeleteBeneficiaryErrorAlert={() => { this.props.dispatch(actions.resetDeleteCustomerBeneficiaryFailed()) }}
        />

        <EditBeneficiaryDetails
          //openEditBeneficiaryDialog={this.state.openEditBeneficiaryDialog}
          beneficiaryDetailstoedit={this.props.customerBeneficiary.beneficiaryToEdit}
          editBeneficiaryDetails={this.editBeneficiaryDetails.bind(this)}
          resetBeneficiaryDetailsToEdit={() => { this.props.dispatch(actions.resetCustomerBeneficiaryDetailsToEdit()) }}
          labels={this.state.labels}
          showEditBeneficiaryDetailsProgressAlert={this.props.customerBeneficiary.editbeneficiaryLoader}
          showEditBeneficiaryDetailsSuccessAlert={this.props.customerBeneficiary.editbeneficiarySuccess}
          showEditBeneficiaryDetailsErrorAlert={!!this.props.customerBeneficiary.editbeneficiaryError}
          editBeneficiaryDetailsError={this.props.customerBeneficiary.editbeneficiaryError}

        resetEditBeneficiaryDetailsSuccessAlert={() => { this.props.dispatch(actions.resetEditCustomerBeneficiarySuccess()) }}
        resetEditBeneficiaryDetailsErrorAlert={() => { this.props.dispatch(actions.resetEditCustomerBeneficiarySuccess()) }}

        />

      </>
    );
  }
}

export default connect(state => ({
  customerData: state.currentCustomer,
  customerBeneficiary: state.customerBeneficiary,
  globalData: state.global,
}))(
  BeneficiaryList
);
