import React from 'react';
import MaterialTable from 'material-table';
import { Button, ButtonGroup } from '@material-ui/core';


export default class GroupCustomers extends React.Component {

  goToClaim(customerGuid) {
    this.props.redirectToCustomerMakeClaimPage(customerGuid)
  }

  goToCustomer(customerGuid) {
    this.props.redirectToCustomerStatusPage(customerGuid)
  }

  render() {
    const members = this.props.members || [];
    return (
      <> 
      {this.props.paginatation}
      <MaterialTable
        title="Group Members"
        columns={[
          { title: 'Name', field: 'full_name' },
          { title: 'National ID', field: 'national_id' },
          { title: 'Msisdn', field: 'msisdn' },
          { title: 'Date Of Birth', field: 'date_of_birth' },
          {
            title: 'Actions', field: 'kra_pin', render: rowData => {
              return (
                <ButtonGroup color="secondary" aria-label="outlined primary button group">
                  <Button onClick={this.goToCustomer.bind(this, rowData.guid)}>View</Button>
                  <Button onClick={this.goToClaim.bind(this, rowData.guid)}>Make a Claim</Button>
                </ButtonGroup>
              )
            }
          },
        ]}
        data={members}
        options={{
					search: true,
					sorting: true,
					exportButton: true,
					actionsColumnIndex: -1,
					pageSize: 20,
					headerStyle: {
						backgroundColor: '#003643',
						color: '#FFF'
					}
				}}

        detailPanel={rowData => {
          return (
            <div style={{ width: '96%', margin: "auto", zIndex: '999999' }}>
              {
                rowData.dependantDto.length > 0 ?
                  <MaterialTable
                    title="Dependants"
                    tableLayout='auto'
                    columns={[
                      { title: 'First Name', field: 'first_name' },
                      { title: 'Middle Name', field: 'middle_name' },
                      { title: 'Last Name', field: 'last_name' },
                      { title: 'DOB', field: 'dob' },
                      { title: 'Gender', field: 'gender' },
                      { title: 'National ID', field: 'national_id' },
                      { title: 'Relationship', field: 'relationship' },
                      { title: 'Msisdn', field: 'msisdn' },
                    ]}
                    data={rowData.dependantDto}
                    options={{
                      search: true,
                      sorting: true,
                      exportButton: true,
                      actionsColumnIndex: -1,
                      pageSize: 10,
                      headerStyle: {
                        backgroundColor: '#003643',
                        color: '#FFF'
                      }
                    }}
                  />
                  : <p style={{ textAlign: 'center' }}>No dependants found for this memeber</p>
              }
            </div>
          )
        }}
      />
      {this.props.paginatation}
      </>
    );
  }
}