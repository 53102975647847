import React from "react";
import { connect } from "react-redux";
import PoliciesHeader from "./Components/PoliciesHeader/PoliciesHeader";
import PoliciesWrapper from "./Components/PoliciesWrapper/PoliciesWrapper";
import { getPoliciesDcpRequest } from "./Redux/actions";
import { policiesStatusEnum } from "../lib/enum";
import { getPartnerGuid, getUserBranch } from "../../../lib/access.es6";

class PoliciesDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  componentWillMount() {
    const payload = {
      "status":  policiesStatusEnum.Un_Confirmed,
    }

    const partnerGuid = getPartnerGuid();
    if(partnerGuid === "accessbank"){
      payload['branch'] = getUserBranch();
    }

    this.props.dispatch(getPoliciesDcpRequest(payload))
  }

  getPolicies(payload) {
    this.props.dispatch(getPoliciesDcpRequest(payload
    ))
  }

  
  render() {

    return (
      <>
        <PoliciesHeader
          title="Policies Dashboard"
        />

        <PoliciesWrapper
          getPolicies={this.getPolicies.bind(this)}
          policies={this.props.PoliciesDashboardData.policies_dcp}
          loader={this.props.PoliciesDashboardData.get_policies_dcp_loader}
        />
      </>
    )

  }

}
export default connect((state) => ({
  profileData: state.profile,
  customerData: state.currentCustomer,
  PoliciesDashboardData: state.PoliciesDashboardData,
  globalData: state.global,
}))(PoliciesDashboard);