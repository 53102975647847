import { take, call, put } from 'redux-saga/effects';
import * as types from '../../lib/actionTypes.es6';
import {
  getProducts,
  createProduct,
  createPremium,
  hardDeletePremium,
  createFixedIndemnityBenefit,
  createVairableIndemnityBenefit, 
  createCashbackIndemnityBenefit,
  hardDeleteBenefit,
  getProduct,
  getProductEnums,
  editProduct,
  editPremium,
  editBenefit,

  getGroupProductConfigs,
  updateGroupProductConfigs,
  addGroupProductConfigs,

  getExtendedFamilyProductConfigs,
  updateExtendedFamilyProductConfigs,
  addExtendedFamilyProductConfigs,

  createPremiumConfig,
  getPremiumConfigs,
  createExtendedConfig
} from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

export function* getAllProductsRequestWatcher() {
  for (;;) {
    const request = yield take(types.VIEW_ALL_PRODUCTS_REQUEST);
    try {
      const response = yield call(getProducts, request.payload);
      yield put(actions.getAllProductsSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getAllProductsFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* createProductRequestWatcher() {
  for (;;) {
    const request = yield take(types.CREATE_PRODUCT_REQUEST);
    try {
      const response = yield call(createProduct, request.payload);
      yield put(actions.createProductSuccess(response.data));

      yield put(
        actions.getAllProductsRequest({
          partnerGuid: request.payload.partnerGuid,
        })
      );
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.createProductFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* createPremiumRequestWatcher() {
  for (;;) {
    const request = yield take(types.CREATE_PREMIUM_REQUEST);
    try {
      const response = yield call(createPremium, request.payload);
      yield put(actions.createPremiumSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.createPremiumFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* hardDeletePremiumRequestWatcher() {
  for (;;) {
    const request = yield take(types.HARD_DELETE_PREMIUM_REQUEST);
    try {
      const response = yield call(hardDeletePremium, request.payload);
      yield put(actions.hardDeletePremiumSuccess(response.data));
      yield put(
        actions.getProductRequest(request.payload.product_guid)
      );
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.hardDeletePremiumFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* createBenefitRequestWatcher() {
  for (;;) {
    const request = yield take(types.CREATE_BENEFIT_REQUEST);

    try {
      let requestFunc;
      if(request.payload.benefitType === 'variable'){
        requestFunc = createVairableIndemnityBenefit
      }
      if(request.payload.benefitType === 'fixed'){
        requestFunc = createFixedIndemnityBenefit
      }
      if(request.payload.benefitType === 'cashback'){
        requestFunc = createCashbackIndemnityBenefit
      }
      const response = yield call(requestFunc, request.payload);
      yield put(actions.createBenefitSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.createBenefitFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* hardDeleteBenefitRequestWatcher() {
  for (;;) {
    const request = yield take(types.HARD_DELETE_BENEFIT_REQUEST);
    try {
      const response = yield call(hardDeleteBenefit, request.payload);
      yield put(actions.hardDeleteBenefitSuccess(response.data));
      yield put(
        actions.getProductRequest(request.payload.benefit.product_guid)
      );
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.hardDeleteBenefitFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}




export function* getProductRequestWatcher() {
  for (;;) {
    const request = yield take(types.VIEW_PRODUCT_REQUEST);
    try {
      const response = yield call(getProduct, request.payload);
      yield put(actions.getProductSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getProductFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* getProductEnumRequestWatcher() {
  while (yield take(types.PRODUCT_ENUMS_REQUEST)) {
    try {
      const response = yield call(getProductEnums);
      yield put(actions.getProductEnumsSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getProductEnumsFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* editProductRequestWatcher() {
  for (;;) {
    const request = yield take(types.EDIT_PRODUCT_REQUEST);
    try {
      const response = yield call(editProduct, request.payload);
      yield put(actions.editProductSuccess(response.data));

      yield put(
        actions.getProductRequest(request.payload.product_guid)
      );
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.editProductFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* editPremiumRequestWatcher() {
  for (;;) {
    const request = yield take(types.EDIT_PREMIUM_REQUEST);
    try {
      const response = yield call(editPremium, request.payload);
      yield put(actions.editPremiumSuccess(response.data));

      yield put(
        actions.getProductRequest(request.payload.product_guid)
      );
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.editPremiumFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* editBenefitRequestWatcher() {
  for (;;) {
    const request = yield take(types.EDIT_BENEFIT_REQUEST);
    try {
      const response = yield call(editBenefit, request.payload);
      yield put(actions.editBenefitSuccess(response.data));

      yield put(
        actions.getProductRequest(request.payload.product_guid)
      );
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.editBenefitFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}



export function* getGroupProductConfigsRequestWatcher() {
  for (; ;) {
    const request = yield take(types.GET_GROUP_PRODUCT_CONFIGS_REQUEST);
    try {
      const response = yield call(getGroupProductConfigs, request.payload);
      yield put(actions.getGroupProductConfigsSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getGroupProductConfigsFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* updateGroupProductConfigsRequestWatcher() {
  for (; ;) {
    const request = yield take(types.UPDATE_GROUP_PRODUCT_CONFIGS_REQUEST);
    try {
      const response = yield call(updateGroupProductConfigs, request.payload);
      yield put(actions.updateGroupProductConfigsSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.updateGroupProductConfigsFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* addGroupProductConfigsRequestWatcher() {
  for (; ;) {
    const request = yield take(types.ADD_GROUP_PRODUCT_CONFIGS_REQUEST);
    try {
      const response = yield call(addGroupProductConfigs, request.payload);
      yield put(actions.addGroupProductConfigsSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.addGroupProductConfigsFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* getExtendedFamilyProductConfigsRequestWatcher() {
  for (; ;) {
    const request = yield take(types.GET_EXTENDED_FAMILY_PRODUCT_CONFIGS_REQUEST);
    try {
      const response = yield call(getExtendedFamilyProductConfigs, request.payload);
      yield put(actions.getExtendedFamilyProductConfigsSuccess(response.data));
      yield put(actions.getPremiumConfigsRequest(response.data.guid));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getExtendedFamilyProductConfigsFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* updateExtendedFamilyProductConfigsRequestWatcher() {
  for (; ;) {
    const request = yield take(types.UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_REQUEST);
    try {
      const response = yield call(updateExtendedFamilyProductConfigs, request.payload);
      yield put(actions.updateExtendedFamilyProductConfigsSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.updateExtendedFamilyProductConfigsFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* addExtendedFamilyProductConfigsRequestWatcher() {
  for (; ;) {
    const request = yield take(types.ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_REQUEST);
    try {
      const response = yield call(addExtendedFamilyProductConfigs, request.payload);
      yield put(actions.addExtendedFamilyProductConfigsSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.addExtendedFamilyProductConfigsFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* createPremiumConfigRequestWatcher() {
  for (;;) {
    const request = yield take(types.CREATE_PREMIUM_CONFIG_REQUEST);
    try {
      const response = yield call(createPremiumConfig, request.payload);
      yield put(actions.createPremiumConfigSuccess(response.data));
      yield put(actions.getPremiumConfigsRequest(request.payload.membership_guid));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.createPremiumConfigFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* createAddonConfigRequestWatcher() {
  for (;;) {
    const request = yield take(types.CREATE_ADDON_CONFIG_REQUEST);
    try {
      const membership_configs  = {
        "content": {
            "nuclear": [],
            "extended": [],
            "beneficiary": {},
            "additional_members_age_range": {},
            "premiums": [],
            "members_payout_plan": [],
            "add_ons": [
            ]
        },
        "product_guid": request.payload.guid
    }
      const response = yield call(createExtendedConfig, membership_configs);
      yield put(actions.createAddOnConfigSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.createAddOnConfigFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* getPremiumConfigsRequestWatcher() {
  for (; ;) {
    const request = yield take(types.GET_PREMIUM_CONFIGS_REQUEST);
    try {
      const response = yield call(getPremiumConfigs, request.payload);
      yield put(actions.getPremiumConfigsSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getPremiumConfigsFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}



