import React from "react";
import { connect } from "react-redux";
import { getAuditsRequest } from "./Redux/actions";
import { formatDateLocale } from "../../lib/utils.es6";
import TableInc from "../../shared-ui/TableInc";
import SkeletonWrapper from "../NewProductList/components/SkeletonWrapper/SkeletonWrapper";

class Audit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      size: this.props.size || 10,
    };
  }

  componentWillMount() {
    if (this.props.entity_guid) {
      const entity_guid = this.props.entity_guid;
      const payload = {
        entity_guid: entity_guid,
        page: this.state.page,
        size: this.state.size,
      };
      this.props.dispatch(getAuditsRequest(payload));
    }
  }

  getAudits = (pagingData) => {
    const payload = {
      entity_guid: this.props.entity_guid,
      page: pagingData.page || this.state.page,
      size: pagingData.size || this.state.size,
    };
    this.props.dispatch(getAuditsRequest(payload));
  };

  render() {
    const { auditData } = this.props;
    const audits = auditData.audits || {
      events: [],
      size: this.state.size,
      page: this.state.page,
      count: 0,
    };
    const events = audits.events;

    if (auditData.loading) {
      return <SkeletonWrapper loading={true} />;
    }

    if (auditData.error) {
      return <div>
        <p>error: {auditData.error ? auditData.error.message : ""}</p>
      </div> 
    }

    return (
      <TableInc
        columns={[
          { title: "Event Type", field: "event_type" },
          { title: "Description", field: "description" },
          { title: "Full Name", field: "full_name" },
          {
            title: "Created At",
            field: "created_at",
            render: (rowData) => formatDateLocale(rowData.created_at),
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            resolve({
              data: [...events],
              page: this.state.page,
              totalCount: audits.count,
            });
          })
        }
        options={{
          showTitle: false,
          pageSizeOptions: [1, 5, 10, 20, 50, 100],
          pageSize: this.state.size,
        }}
        onChangeRowsPerPage={(pageSize) => {
          this.setState({ size: pageSize });
          this.getAudits({ size: pageSize });
        }}
        onChangePage={(page) => {
          this.setState({ page: page });
          this.getAudits({ page: page + 1 });
        }}
      />
    );
  }
}

export default connect((state) => ({
  auditData: state.auditState,
}))(Audit);
