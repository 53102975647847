export const gettestCredentials = `
var axios = require('axios');

var config = {
  method: 'post',
maxBodyLength: Infinity,
  url: 'https://engine.staging.aspin-inclusivity.com/oauth/token?grant_type=password&scope=all&username=test_admin&password=Qwertyui1!',
  headers: { 
    'Authorization': 'Basic NUJOR3NjYkNWWjpYSzBpblhCajFLWjhFbzk4dWduVG11WlVmRE9lbkllVg=='
  }
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`

export const createCustomerprofile = `
const axios = require('axios');
const data = JSON.stringify({
  "msisdn": "00271603773357",
  "first_name": "Foo",
  "surname": "Bar",
  "partner_guid": "demo",
});
const config = {
  method: 'post',
  url: 'https://engine.staging.aspin-inclusivity.com//api/customers?partner=demo',
  headers: { 
    'Authorization': 'Bearer access_token', 
    'Content-Type': 'application/json'
  },
  data : data
};
axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
}); 
`

export const queryavailableproducts = `const axios = require('axios');

const config = {
  method: 'get',
  url: 'https://engine.staging.aspin-inclusivity.com/api/products?partner=demo',
  headers: { 
    'Authorization': 'Bearer access_token'
  }
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`

export const buyapolicy = `const axios = require('axios');
const data = JSON.stringify({
  "amount_in_cents": "60000",
  "channel": "ApiClient",
  "msisdn": "002250700000653",
  "product_code": "demo_individual",
  "debit": false
});

const config = {
  method: 'post',
  url: 'https://engine.staging.aspin-inclusivity.com/api/products/buy?partner=demo',
  headers: { 
    'Authorization': 'Bearer access_token', 
    'Content-Type': 'application/json'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`

export const makeapayment = `const axios = require('axios');
const data = JSON.stringify({
  "policy_guid": "5488c615ed6543e3a1f2edf160dd13f0",
  "amount_in_cents": 2000,
  "mno_reference": "trxn7363626535",
  "status": "Succeeded",
  "channel": "ApiClient",
  "effected_at": "2022-05-17"
});

const config = {
  method: 'post',
  url: 'https://engine.staging.aspin-inclusivity.com/api/payments?partner=demo',
  headers: { 
    'Authorization': 'Bearer access_token', 
    'Content-Type': 'application/json'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`

export const makeaclaim = `const axios = require('axios');
const data = JSON.stringify({
  "type": "Hospital",
  "customer_guid": "37f0617363e24a8580f19bbae93fe85f",
  "relation_to_main_member": "Self",
  "hospital_admission_date": "2017-05-16",
  "hospital_discharge_date": "2019-02-11"
});

const config = {
  method: 'post',
  url: 'https://engine.staging.aspin-inclusivity.com/api/claims?partner=demo',
  headers: { 
    'Authorization': 'Bearer access_token', 
    'Content-Type': 'application/json'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`