import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  claimsheader: {
        background: "#FFFFFF",
        borderRadius: "8px",
    },
    claimstitle: {
        marginLeft: "20px",
        "& h1": {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.0015em",
            color: "#000000"
        }
    },

}));