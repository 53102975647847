import * as types from '../../lib/actionTypes.es6';
import GAlogger, { monitoringEventCategories } from '../../lib/monitoring.es6';

export function makeCurrentCustomer(customer, message = null) {
  return {
    type: types.MAKE_CURRENT_CUSTOMER,
    payload: customer,
    message,
  };
}

export function changeCurrentCustomer(customer) {
  return {
    type: types.CHANGE_CURRENT_CUSTOMER,
    payload: customer,
  };
}

export function getCustomer(resource) {
  return {
    type: types.REFRESH_CUSTOMER_REQUEST,
    payload: resource,
  };
}

export function getCustomerFailed(resource) {
  return {
    type: types.REFRESH_CUSTOMER_FAILED,
    payload: resource,
  };
}

export function updateCustomerRequest(customerData) {
  return {
    type: types.UPDATE_CUSTOMER_REQUEST,
    payload: customerData,
  };
}

export function updateCustomerSuccess(customerInfo) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.updateCustomer);
  return {
    type: types.UPDATE_CUSTOMER_SUCCESS,
    payload: customerInfo,
  };
}

export function updateCustomerFail(response) {
  return {
    type: types.UPDATE_CUSTOMER_FAIL,
    payload: response,
  };
}

export function resetUpdateCustomerl() {
  return {
    type: types.UPDATE_CUSTOMER_RESET,
  };
}

export function displayError(error) {
  return {
    type: types.EDIT_CUSTOMER_DISPLAY_ERROR,
    payload: error,
  };
}

export function updatePolicyRequest(policyData) {
  return {
    type: types.UPDATE_POLICY_REQUEST,
    payload: policyData,
  };
}

export function updatePolicyFail(response) {
  return {
    type: types.UPDATE_POLICY_FAILURE,
    payload: response,
  };
}

export function changeLoyaltyRegistrationRequest(data) {
  return {
    type: types.CHANGE_LOYALTY_REGISTRATION_REQUEST,
    payload: data,
  };
}

export function changeLoyaltyRegistrationFail(data) {
  return {
    type: types.CHANGE_LOYALTY_REGISTRATION_FAILURE,
    payload: data,
  };
}

export function openEditCustomerModal() {
  return {
    type: types.OPEN_EDIT_CUSTOMER_MODAL,
  };
}

export function closeEditCustomerModal() {
  return {
    type: types.CLOSE_EDIT_CUSTOMER_MODAL,
  };
}

export function pullCustomerKycRequest(data) {
  return {
    type: types.PULL_CUSTOMER_KYC_REQUEST,
    payload: data,
  };
}

export function pullCustomerKycRequestFailure(data) {
  return {
    type: types.PULL_CUSTOMER_KYC_REQUEST_FAILURE,
    payload: data,
  };
}

export function pullCustomerKycRequestSuccess(data) {
  return {
    type: types.PULL_CUSTOMER_KYC_REQUEST_SUCCESS,
    payload: data,
  };
}
