import * as types from '../../lib/actionTypes.es6';
import GAlogger, { monitoringEventCategories } from '../../lib/monitoring.es6';

export function selectClaim(claim) {
  return {
    type: 'SELECT_CUSTOMER_CLAIM',
    payload: claim,
  };
}

export function getDocumentTypesRequest() {
  return {
    type: 'GET_DOCUMENT_TYPES_REQUEST',
  };
}

export function getDocumentTypesSuccess(response) {
  return {
    type: 'GET_DOCUMENT_TYPES_SUCCESS',
    payload: response,
  };
}

export function processClaimRequest(claimData) {
  return {
    type: 'PROCESS_CLAIM_REQUEST',
    payload: claimData,
  };
}

export function processClaimSuccess(claimData) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.processClaim);
  return {
    type: 'PROCESS_CLAIM_SUCCESS',
    payload: claimData,
  };
}

export function processClaimFail(error) {
  return {
    type: 'PROCESS_CLAIM_FAIL',
    payload: error,
  };
}

export function claimFieldsSuccess(claimData) {
  return {
    type: 'CLAIM_FIELDS_SUCCESS',
    payload: claimData,
  };
}

export function claimVerifyBySuccess(claimData) {
  return {
    type: 'CLAIM_VERIFY_BY_SUCCESS',
    payload: claimData,
  };
}

export function claimDocReceivedRequiredFieldsSuccess(claimData) {
  return {
    type: 'CLAIM_DOC_RECEIVED_REQUIRED_FIELDS_SUCCESS',
    payload: claimData,
  };
}

export function claimDocReceivedStatusesSuccess(claimData) {
  return {
    type: 'CLAIM_DOC_RECEIVED_STATUSES_SUCCESS',
    payload: claimData,
  };
}

export function claimHospitalsRequest() {
  return {
    type: 'CLAIM_HOSPITAL_REQUEST',
  };
}

export function claimHospitalsSuccess(response) {
  return {
    type: 'CLAIM_HOSPITAL_SUCCESS',
    payload: response,
  };
}

export function claimHospitalsFail(response) {
  return {
    type: 'CLAIM_HOSPITAL_FAIL',
    payload: response,
  };
}

export function claimRejectionReasonRequest(data) {
  return {
    type: types.CLAIM_REJECTION_REASON_REQUEST,
    payload: data,
  };
}

export function claimRejectionReasonSuccess(response) {
  return {
    type: types.CLAIM_REJECTION_REASON_SUCCESS,
    payload: response,
  };
}

export function claimRejectionReasonFail(response) {
  return {
    type: types.CLAIM_REJECTION_REASON_FAILURE,
    payload: response,
  };
}

export function setSelectedPolicies(polices) {
  return {
    type: types.SET_SELECTED_POLICIES,
    payload: polices,
  };
}

export function uploadClaimCallBackRequest(payload) {
  return {
    type: types.CLAIM_UPLOAD_CALLBACK_REQUEST,
    payload,
  };
}

export function createClaimFileRequest(payload) {
  return {
    type: types.CLAIM_FILE_CREATE_REQUEST,
    payload,
  };
}

export function createClaimFileFailure(payload) {
  return {
    type: types.CLAIM_FILE_CREATE_FAILURE,
    payload,
  };
}

export function createClaimFileSuccess(payload) {
  return {
    type: types.CLAIM_FILE_CREATE_SUCCESS,
    payload,
  };
}

export function deleteClaimFileRequest(payload) {
  return {
    type: types.CLAIM_FILE_DELETE_REQUEST,
    payload,
  };
}

export function deleteClaimFileRequestFailure(payload) {
  return {
    type: types.CLAIM_FILE_DELETE_REQUEST_FAILURE,
    payload,
  };
}

export function deleteClaimFileRequestSuccess() {
  return {
    type: types.CLAIM_FILE_DELETE_REQUEST_SUCCESS,
  };
}

export function refreshClaimRequest(payload) {
  return {
    type: types.REFRESH_CLAIM_REQUEST,
    payload,
  };
}

export function refreshClaimSuccess(payload) {
  return {
    type: types.REFRESH_CLAIM_SUCCESS,
    payload,
  };
}

export function refreshClaimFailure(payload) {
  return {
    type: types.REFRESH_CLAIM_FAILURE,
    payload,
  };
}

export function retryFailedClaimPaymentRequest(payload) {
  return {
    type: types.RETRY_FAILED_CLAIM_PAYMENT_REQUEST,
    payload,
  };
}

export function retryFailedClaimRequestPaymentSuccess(payload) {
  return {
    type: types.RETRY_FAILED_CLAIM_PAYMENT_REQUEST_SUCCESS,
    payload,
  };
}

export function retryFailedClaimRequestPaymentFailure(payload) {
  return {
    type: types.RETRY_FAILED_CLAIM_PAYMENT_REQUEST_FAILURE,
    payload,
  };
}

export function claimPaymentUpdateOutcomeRequest(payload) {
  return {
    type: types.CLAIM_PAYMENT_UPDATE_OUTCOME_REQUEST,
    payload,
  };
}

export function claimPaymentUpdateOutcomeSuccess(payload) {
  return {
    type: types.CLAIM_PAYMENT_UPDATE_OUTCOME_REQUEST_SUCCESS,
    payload,
  };
}

export function claimPaymentUpdateOutcomeFailure(payload) {
  return {
    type: types.CLAIM_PAYMENT_UPDATE_OUTCOME_REQUEST_FAILURE,
    payload,
  };
}

export function claimConditionsRequest() {
  return {
    type: types.CLAIM_CONDITION_REQUEST,
  };
}

export function claimConditionsSuccess(response) {
  return {
    type: types.CLAIM_CONDITION_SUCCESS,
    payload: response,
  };
}

export function claimConditionsFail(response) {
  return {
    type: types.CLAIM_CONDITION_FAILURE,
    payload: response,
  };
}

export function expiredClaimReversalRequest(data) {
  return {
    type: types.EXPIRED_CLAIM_REVERSAL_REQUEST,
    payload: data,
  };
}

export function expiredClaimReversalSuccess(response) {
  return {
    type: types.EXPIRED_CLAIM_REVERSAL_REQUEST_SUCCESS,
    payload: response,
  };
}

export function expiredClaimReversalFail(response) {
  return {
    type: types.EXPIRED_CLAIM_REVERSAL_REQUEST_FAILURE,
    payload: response,
  };
}
