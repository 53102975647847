import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  claimInfo: null,
  loader: false,
  types: ['Please select'],
  relationToMainMember: ['Please select'],
};

export default function claimSubmissionReducer(state = initialState, action) {
  switch (action.type) {
    case types.CLAIM_SUBMISSION_REQUEST:
      return { ...state, loader: true, errors: null, claimInfo: null };

    case types.CLAIM_SUBMISSION_SUCCESS:
      return { ...state, claimInfo: action.payload, loader: false };

    case types.CLAIM_SUBMISSION_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    case 'CLAIM_TYPES_SUCCESS':
      return { ...state, types: ['Please select'].concat(action.payload) };

    case 'CLAIM_RELATION_TO_MAIN_MEMBER_SUCCESS':
      return {
        ...state,
        relationToMainMember: ['Please select'].concat(action.payload),
      };

    case types.CLAIM_SUBMISSION_RESET:
      return { ...state, errors: null, claimInfo: null, loader: false };

    case types.MAKE_CURRENT_CUSTOMER:
    case types.USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
}
