import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({

  makepayment: {
    marginTop: "5px",
    textAlign: "right",
    marginRight: "30px",
    "& button": {
      background: "#fba92c",
      color: "#fff !important",
      borderRadius: "8px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      boxShadow: "none",
      padding: "10px",
      textTransform: "capitalize",
      marginBottom: "30px",
      marginLeft: "30px",
      "&:hover": {
        background: "#fba92c",
        boxShadow: "none",
      }
    }
  },
  paymentmodaltitle: {
    background: "#FBA92D",
    textAlign: "center",
    padding: "10px !important",
    "& h3": {
      color: "#fff",
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "24px",
      lineHeight: "24px",
    }
  },
  paymentinputwrapper: {
    marginTop: "30px",
    "& p": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
      textAlign: "center",
      marginBottom: "30px"
    },
    "& input": {
      background: "#F7F7F7",
      borderRadius: "8px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#000000",
    },
    "& label": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      letterSpacing: "0.005em",
      color: "#000000",

    }
  },
  paymentinputs: {
    "& .MuiOutlinedInput-root": {
      borderColor: "#000fff"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#C52328",
      borderWidth: "2px"
    }
  },
  paymentbtns: {
    marginBottom: "20px",
    "& button": {
      background: "#FBA92D",
      borderRadius: "8px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      boxShadow: "none",
      padding: "10px",
      marginRight: "20px",
      textTransform: "capitalize",
      "&:nth-child(2)":{
        background: "transparent",
        border: "1px solid #000",
        color: "#000",
        "&:hover": {
          background: "transparent",
          boxShadow: "none",
        }
      },
      "&:hover": {
        background: "#173E4B",
        boxShadow: "none",
      }
    }
  },
}));