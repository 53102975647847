import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import 'react-table/react-table.css';
import LocalizedStrings from 'react-localization';
import Chip from '@material-ui/core/Chip';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { localisedText } from '../../lib/localisation.es6';
import { formatStatus } from '../../lib/utils.es6';
import '../../containers/SmsTemplate/sms-template.css'
import { hasAuthourity } from '../../lib/access.es6';


export class SmsTemplateCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            labels: new LocalizedStrings(localisedText),
            anchorEl: null,
            smsTemplateToDelete: null,
            smsTemplateToUpdate: null,
        };
    }

    componentWillMount() {
        this.updateLanguage();
    }

    componentDidUpdate() {
        this.updateLanguage();
    }

    updateLanguage() {
        updateCurrentLanguage(
            this.props.globalData.language,
            'SmsTemplateCard',
            this.state.labels
        );
    }

    convertParametersToList(parameters) {
        if (parameters == null) return []
        let parameter = parameters.split(",").join("").split(" ");
        return parameter
    }

    openEditDeleteMenu(smsTemplate, event) {
        this.setState({ anchorEl: event.currentTarget, smsTemplateToDelete: smsTemplate, smsTemplateToUpdate: smsTemplate })
    }

    closeEditDeleteMenu() {
        this.setState({ anchorEl: null });
    }

    updateSmsTemplatePrompt(){
        this.closeEditDeleteMenu()
        this.props.updateSmsTemplatePrompt(this.state.smsTemplateToUpdate)
    }
    deleteSmsTemplatePrompt(){
        this.closeEditDeleteMenu()
        this.props.deleteSmsTemplatePrompt(this.state.smsTemplateToDelete)
    }


    renderCard(smsTemplate) {

        const parameters = this.convertParametersToList(smsTemplate.parameters);
        const labels = this.state.labels;
        return <Card>
            <CardHeader
                avatar={<div className="language-circle">{smsTemplate.language}</div>}
                action={
                    <div>
                        <IconButton aria-label="settings">
                            <ExpandMoreIcon onClick={this.openEditDeleteMenu.bind(this, smsTemplate)} />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.closeEditDeleteMenu.bind(this)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            {
                                hasAuthourity('UPDATE_SMS_TEMPLATE') ?
                                <MenuItem onClick={this.updateSmsTemplatePrompt.bind(this)}>
                                    <ListItemIcon>
                                        <EditIcon fontSize="small" style={{ color: "orange" }} />
                                    </ListItemIcon>
                                    <ListItemText primary={labels.editTemplate} />
                                 </MenuItem> : ''
                            }
                            {
                                hasAuthourity('DELETE_SMS_TEMPLATE') ?
                                <MenuItem onClick={this.deleteSmsTemplatePrompt.bind(this)}>
                                    <ListItemIcon>
                                        <DeleteIcon fontSize="small" style={{ color: "red" }} />
                                    </ListItemIcon>
                                    <ListItemText primary={labels.deleteTemplate} />
                                </MenuItem> : ''
                            }
                        </Menu></div>
                }
                title={formatStatus(smsTemplate.code)}
                subheader={smsTemplate.created_at}
            />

            <CardContent>
                <Typography className="speech-bubble" variant="body2" color="textSecondary" component="p">
                    {smsTemplate.template}
                </Typography>
                {parameters.length > 0 ? <hr /> : ''}
                {parameters.map(parameter => (
                    <Chip
                        size="small"
                        variant="outlined"
                        label={formatStatus(parameter)}
                        style={{
                            margin: '1px',
                            backgroundColor: "#FF8349",
                            color: "#fff"
                        }}
                    />
                ))}
                <hr />
                <div style={{ alignContent: "center" }}>
                    <FormControlLabel style={{ marginRight: "50px" }}
                        control={
                            <Checkbox
                                checked={smsTemplate.flash}
                                value="checkedB"
                                style={{ color: (smsTemplate.flash ? "orange" : '') }}
                            />
                        }
                        label={labels.flash}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={smsTemplate.system_generated}
                                value="checkedB"
                                style={{ color: (smsTemplate.system_generated ? "orange" : '') }}
                            />
                        }
                        label={labels.systemGenerated}
                    />
                    <FormControlLabel
                      control={
                          <Checkbox
                            checked={smsTemplate.reply_to}
                            value="checkedB"
                            style={{ color: (smsTemplate.reply_to ? "orange" : '') }}
                          />
                      }
                      label={labels.canReplyTo}
                    />
                    {smsTemplate.screen ? <Typography>{"Screen: " + smsTemplate.screen}</Typography> : ''}
                </div>

            </CardContent>
        </Card>
    }

    render() {
        let template = this.props.smsTemplate
        return (
            this.renderCard(template)
        );
    }
}
export default (SmsTemplateCard);
