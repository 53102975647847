import React from 'react'
import ProductTypesTable from './Components/ProductTypesTable/ProductTypesTable'
import PremiumRatesTable from './Components/PremiumRatesTable/PremiumRatesTable'
import LoanIntrestRateTable from './Components/LoanIntrestRateTable/LoanIntrestRateTable'
import CommissionRateTable from './Components/CommissionRateTable/CommissionRateTable'
const RealPeopleConfigs = (props) => {
  return (
    <>
      <ProductTypesTable
        membership_configs={props.membership_configs}
        productGuid={props.productGuid}
      />
      <br />
      <PremiumRatesTable 
        membership_configs={props.membership_configs}
        productGuid={props.productGuid}
      />
      <br />
      <LoanIntrestRateTable 
        membership_configs={props.membership_configs}
        productGuid={props.productGuid}
      />
      <br />
      <CommissionRateTable 
        membership_configs={props.membership_configs}
        productGuid={props.productGuid}
      />
    </>
  )
}

export default RealPeopleConfigs