import React, { useState } from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Claims from '../Claims/Claims';
import { ClaimStatusEnum } from '../../enum';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ClaimsWrapper = (props) => {
  const classes = useStyle();
  const [value, setValue] = useState(0);

  const getClaimStatus = (tabValue) => {
    switch (tabValue) {
      case 0:
        return ClaimStatusEnum.Pending
      case 1:
        return ClaimStatusEnum.All_Docs_Pending
      case 2:
        return ClaimStatusEnum.Docs_Partially_Submitted
      case 3:
        return ClaimStatusEnum.Decision_Pending
      case 4:
        return ClaimStatusEnum.Approved
      case 5:
        return ClaimStatusEnum.Approved_Paid
      case 6:
        return ClaimStatusEnum.Rejected
      default:
    }
  }
  const handleChange = (event, newValue) => {
    console.log('event, newValue', { event, newValue });
    setValue(newValue);
    const claimStatus = getClaimStatus(newValue)
    props.getClaims(claimStatus)
  };

  return (
    <>
      <section className={classes.claimswrapper}>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="claims dashboards" variant="scrollable"
                scrollButtons="auto" className={classes.tabs}>
                <Tab label="Pending" {...a11yProps(0)} />
                <Tab label="All Docs Pending" {...a11yProps(1)} />
                <Tab label="Docs Partially Submitted" {...a11yProps(2)} />
                <Tab label="Decision Pending" {...a11yProps(3)} />
                <Tab label="Approved" {...a11yProps(4)} />
                <Tab label="Approved Paid" {...a11yProps(5)} />
                <Tab label="Rejected" {...a11yProps(6)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0} >
              <Claims claimsData={props.claimsData} loading={props.loading} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Claims claimsData={props.claimsData} loading={props.loading} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Claims claimsData={props.claimsData} loading={props.loading} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Claims claimsData={props.claimsData} loading={props.loading} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Claims claimsData={props.claimsData} loading={props.loading} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Claims claimsData={props.claimsData} loading={props.loading} />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <Claims claimsData={props.claimsData} loading={props.loading} />
            </TabPanel>

          </Grid>
        </Grid>
      </section>
    </>
  )
}

export default ClaimsWrapper