import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import './BenefitsTable.css'
import { IconButton, Stack } from "@mui/material";
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../../lib/constants";

export default function CashBackIndemnityTable(props) {

  const setBenefitToEdit = (benefit) =>{
    const payload = benefit
    payload['benefitType'] = 'cashback';
    props.setBenefitToEdit(payload)
  }

  if (props.cashBackBenefits && props.cashBackBenefits.length > 0) {
    return (
      <>
        <div style={{ marginTop: "2%" }}>
          <h3 style={{textAlign: 'left'}}>Cash back benefits</h3>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ color: 'red' }}>
                <TableRow >
                  <TableCell className="table-cell-header">name</TableCell>
                  <TableCell className="table-cell-header">period</TableCell>
                  <TableCell className="table-cell-header">period_granularity</TableCell>
                  <TableCell className="table-cell-header">returned premiums count</TableCell>
                  <TableCell className="table-cell-header" align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.cashBackBenefits.map((benefit) => {
                  return (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left"> {benefit.name}</TableCell>
                      <TableCell align="left"> {benefit.period}</TableCell>
                      <TableCell align="left"> {benefit.period_granularity}</TableCell>
                      <TableCell align="left"> {benefit.returned_premiums_count}</TableCell>
                      <TableCell align="right">
                        <Stack direction="row" spacing={1} justifyContent="flex-end" >
                          <IconButton onClick={() => {setBenefitToEdit( benefit)}} >
                            <EditIcon
                              style={{ color: PRIMARY_THEME_COLOR_SHADE_1, fontWeight: 'bolder', display: props.displayEditButton ? 'inline-block' : 'none' }}
                            />
                          </IconButton>
                          <IconButton onClick={() => { props.hardDeleteBenefit({ benefit, benefitType: 'cashback_indemnity'}) }}>
                            <DeleteForeverIcon
                              style={{ color: 'red', fontWeight: 'bolder', marginRight: "2%", display: props.displayDeleteButton ? 'inline-block' : 'none' }}
                            />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
    );
  }
  return ""
}
