import * as types from '../../../lib/actionTypes.es6';

export const initialState = {


  getReportError: null,
  getReportloader: false,
  getReportSuccess: false,
  report: [],
  isEmpty: false,
  reportBlob: null,

};

export default function generateReportReducer(state = initialState, action) {

  switch (action.type) {

    case types.GENERATE_REPORT_REQUEST:
      return {
        ...state,
        getReportloader: true,
        getReportError: null,
        getReportSuccess: false,
        report: [],
        isEmpty: false,
        reportBlob: null
      };

    case types.GENERATE_REPORT_SUCCESS:
      return {
        ...state,
        report: action.payload,
        getReportloader: false,
        getReportSuccess: true,
        reportBlob: action.payload
      };

    case types.GENERATE_REPORT_FAILURE:
      return {
        ...state,
        getReportloader: false,
        getReportSuccess: false,
        getReportError: action.payload,
        isEmpty: false,
      };

    case types.RESET_GENERATE_REPORT:
      return {
        ...state,
        
        getReportError: null,
        getReportloader: false,
        report: [],
      };


    default:
      return state;
  }
}
