import React from 'react';
import { Grid, Typography } from '@mui/material';

const PaymentInReview = ({ claim, labels }) => {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography 
          align="center" 
          variant="body1" 
          style={{ 
            padding: '20px',
            color: '#FBA92D',
            fontWeight: 500
          }}
        >
          {/* {labels.claimPaymentInReview} */}
          Claim payment in review
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PaymentInReview; 