import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, InputAdornment } from "@mui/material";
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../../lib/constants";
import AlertDialog from "../../../../components/AlertDialog/index.es6";
import { formatDate } from "../../../../lib/utils.es6";
import { DatePicker } from '@material-ui/pickers';
import { formatNumberWithCommaIncents } from "../../../../lib/access.es6";
import find from 'lodash/find';


const initialState = {
  policy: '',
  granularity: 'Month',
  amount_in_cents: '',
  day: '',
  bank_account_number: '',
  bank: '',
  branch_code: '',
  start_date: '',
}
export default function AddDebitOrder(props) {

  const [
    {
      policy,
      granularity,
      amount_in_cents,
      day,
      bank_account_number,
      bank,
      branch_code,
      start_date,
    }, setState
  ] = React.useState(initialState)

  React.useEffect(()=>{
    setUniversalBranchCode()
  },[bank])

  React.useEffect(() => {
    if (!props.policies || !props.policies.length) {
      return;
    }

    if (props.policies.length === 1) {
      setState(prevState => ({
        ...prevState,
        policy: props.policies[0].guid,
      }));
    }
  }, [props.policies]);

  React.useEffect(() => {
    if (!props.totalPayablePremium) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      amount_in_cents:
        (props.totalPayablePremium.premium_amount_in_cents +
          props.totalPayablePremium.additional_premium_amount_in_cents) /
        100,
      granularity: props.totalPayablePremium.granularity,
      day: props.totalPayablePremium.cardinality,
    }));

  },[props.totalPayablePremium])

  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };


  const reset = () => {
    setState(initialState);
  }


  const submit = () => {
    const payload = {
      amount_in_cents: formatNumberWithCommaIncents(amount_in_cents),
      granularity,
      day: Number(day),
      bank_account_number,
      bank_name: bank,
      branch_code,
      start_date,
      policy_guid: policy,
      msisdn: props.msisdn,
      mno_reference: props.msisdn + bank_account_number
    }
    props.addDebitOrder(payload)
  }


  const handleDebitOrderCreatedSuccessfully = () => {
    props.resetAddDebitOrderSuccessAlert()
    reset()
    props.closeAddDebitOrderDialog()
    props.getAllDebitOrders();
  }

  const handleDebitOrderCreateError = () => {
    props.resetAddDebitOrderErrorAlert()
  }

  const datePickerHandler = (type, moment) => {
    const rawDate = formatDate(new Date(moment));
    if (type === 'start_date') {
      setState(prevState => ({ ...prevState, start_date: rawDate }));
    }
  }

  const setUniversalBranchCode = () => {
    const selectedBank = find(props.banks, {name: bank})

    if(selectedBank){
      setState(prevState => ({ ...prevState, branch_code: selectedBank.universal_branch_code }));
    }
  }

  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate());

  const getProductName = guid => {
    const product = props.products.find(p => p.guid === guid);

    return product ? product.name : '';
  };

  return (
    <React.Fragment>


      <Dialog
        open={props.openAddDebitOrderDialog} fullWidth
        maxWidth='lg'
        onClose={props.closeAddDebitOrderDialog}
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.closeAddDebitOrderDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add DebitOrder
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div style={{ marginTop: "2%" }}>
            <Box sx={{ width: "100%" }}>
              <ValidatorForm onSubmit={() => submit()} >
                <AlertDialog
                  custom
                  show={props.showAddDebitOrderProgressAlert}
                  size="sm"
                  style={{ marginTop: '0', top: '30vh' }}
                  confirmBtnText={'ok'}
                  showCancel={false}
                  showConfirm={false}
                  cancelBtnText={'cancel'}
                  showTitle={false}
                  confirmBtnCssClass
                >
                  <CircularProgress />
                  <h2>Adding debitOrder</h2>
                </AlertDialog>

                <AlertDialog
                  success
                  show={props.showAddDebitOrderSuccessAlert}
                  size="sm"
                  title={'DebitOrder added'}
                  style={{ marginTop: '0', top: '30vh' }}
                  onConfirm={() => { handleDebitOrderCreatedSuccessfully() }}
                  confirmBtnText={'ok'}
                  showCancel={false}
                  showConfirm={false}
                  cancelBtnText={'cancel'}
                  confirmBtnCssClass
                >
                  <div >
                    <Button variant="contained" onClick={() => { handleDebitOrderCreatedSuccessfully() }} style={{ background: 'green', }}>Ok</Button>
                  </div>
                </AlertDialog>

                <AlertDialog
                  show={props.showAddDebitOrderErrorAlert}
                  danger
                  title={'Error adding debitOrder'}
                  onConfirm={() => handleDebitOrderCreateError()}
                  confirmBtnText={'Try again'}
                  confirmBtnCssClass
                  showCancel={false}
                  style={{ marginTop: '0', top: '30vh' }}
                >
                  {props.createDebitOrderError ? props.createDebitOrderError.message : ''}
                </AlertDialog>

                <Grid container spacing={2}>
                  <Grid item lg={3} md={6} xs={12}>

                    <TextValidator
                      onChange={onChange}
                      value={amount_in_cents}
                      label='amount'
                      name='amount_in_cents'
                      fullWidth
                      variant="outlined"
                      type="number"
                      placeholder='amount'
                      validators={['required']}
                      errorMessages={['Please enter amount']}
                      InputProps={{
                        startAdornment: (<InputAdornment position="start">
                          {props.currencyCode}
                        </InputAdornment>),

                        'aria-label': 'R',
                      }}
                    />
                  </Grid>




                  <Grid item lg={3} md={6} xs={12}>
                    <SelectValidator
                      value={granularity}
                      label='granularity'
                      id="granularity"
                      name='granularity'
                      onChange={onChange}
                      className="form-control"
                      type="text"
                      variant="outlined"
                      validators={['required']}
                      errorMessages={['Please select granularity']}
                    >
                      <MenuItem value="">{'Please select granularity'}</MenuItem>
                      {
                        ['Day', 'Week', 'Month', 'Year'].map((type) => {
                          return <MenuItem value={type}>{type}</MenuItem>
                        })
                      }
                    </SelectValidator>
                  </Grid>

                  <Grid item lg={3} md={6} xs={12}>
                    <SelectValidator
                      value={policy}
                      label='policy'
                      id="policy"
                      name='policy'
                      onChange={event => {
                        onChange(event);
                        if (event.target.value) {
                          props.getTotalPayablePremium(event.target.value);
                        }
                      }}
                      className="form-control"
                      type="text"
                      variant="outlined"
                      validators={['required']}
                      errorMessages={['Please select policy']}
                      disabled={props.policies.length < 2}
                    >
                      <MenuItem value="">{'Please select policy'}</MenuItem>
                      {
                        (props.policies || []).map((policy) => {
                          return <MenuItem value={policy.guid}>{getProductName(policy.product_guid)}</MenuItem>
                        })
                      }
                    </SelectValidator>
                  </Grid>

                  <Grid item lg={3} md={6} xs={12}>

                    <TextValidator
                      onChange={onChange}
                      value={bank_account_number}
                      label='bank_account_number'
                      name='bank_account_number'
                      fullWidth
                      variant="outlined"
                      type="number"
                      placeholder='bank account number'
                      validators={['required']}
                      errorMessages={['Please enter bank_account_number']}
                    />
                  </Grid>


                  <Grid item lg={3} md={6} xs={12}>
                    <SelectValidator
                      value={bank}
                      label='bank'
                      id="bank"
                      name='bank'
                      onChange={onChange}
                      className="form-control"
                      type="text"
                      variant="outlined"
                      validators={['required']}
                      errorMessages={['Please select bank']}
                    >
                      <MenuItem value="" disabled>{'Please select bank'}</MenuItem>
                      {(props.banks || []).map(b=>(<MenuItem value={b.name}>{b.name}</MenuItem>))}
                    </SelectValidator>
                  </Grid>




                  <Grid item lg={3} md={6} xs={12}>

                    <TextValidator
                      onChange={onChange}
                      value={branch_code}
                      label='branch_code'
                      name='branch_code'
                      fullWidth
                      variant="outlined"
                      type="number"
                      placeholder='branch code'
                      validators={['required']}
                      errorMessages={['Please enter branch_code']}
                    />
                  </Grid>


                  <Grid item lg={3} md={6} xs={12}>

                    <TextValidator
                      onChange={onChange}
                      value={day}
                      label='day'
                      name='day'
                      fullWidth
                      variant="outlined"
                      type="number"
                      placeholder='day'
                      validators={['required']}
                      errorMessages={['Please enter day']}
                    />
                  </Grid>


                  <Grid item xs={12} md={3} sm={6} >
                    <DatePicker
                      label="Debit order start Date"
                      value={start_date || null}
                      onChange={datePickerHandler.bind(this, 'start_date')}
                      format="YYYY-MM-DD"
                      minDate={tomorrowDate}
                      placeholder='Debit order start date'
                      variant='outlined'
                      name="start_date"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant="contained" type="submit" style={{ marginRight: '2%' }}> Save </Button>
                    <Button variant="contained" color="secondary" style={{ marginRight: '2%' }} onClick={() => reset()}> Reset</Button>
                  </Grid>

                </Grid>
              </ValidatorForm>

            </Box>
          </div>

        </DialogContent>
      </Dialog>

    </React.Fragment>

  );
}
