import React, { useState } from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FaqsData } from './FaqsData';

const Faqs = () => {
    const classes = useStyle();
    const [expanded, setExpanded] = useState(false);
    const handleChangeFaqs = (panel) => (event, isExpanded) => {

        const faqtoggle = isExpanded ? panel : false;
        setExpanded(faqtoggle);
    };
    return (
        <section className={classes.faqs}>
            <Container>
                <Grid container>
                    <Grid item xs={12} md={12} sm={12}>
                        <h2>Frequently Asked Questions</h2>
                        <div className={classes.dividerdiff}></div>
                    </Grid>
                </Grid>
            </Container>
            <Container>
                <Grid container>
                    <Grid item xs={12} md={12} sm={12} className={classes.faqaccordion}>
                        <div>
                            {
                                FaqsData.map((faqdata) =>
                                    <Accordion key={faqdata.id} expanded={expanded === `panel${faqdata.id}`} onChange={handleChangeFaqs(`panel${faqdata.id}`)} sx={{
                                        backgroundColor: '#032A37',
                                        borderRadius: '10px',
                                        marginBottom: "20px",
                                        color: '#fff',

                                    }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"

                                        >

                                            <h4>{faqdata.title} </h4>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <p>
                                                {faqdata.body}
                                            </p>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            }

                        </div>
                    </Grid>
                </Grid>
            </Container>
            <div className={classes.faqbtn}>
                <a href='/#'>Visit Help Centre</a>
            </div>
        </section>
    )
}

export default Faqs