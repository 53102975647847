import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles((LandingPageTheme) => ({


    developers: {
        padding: "50px",
        [LandingPageTheme.breakpoints.down("md")]:{
            padding: "30px"
        },
        "& h2": {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "900",
            fontSize: "22px",
            lineHeight: "28px",
            textAlign: "center",
            color: "#000000",
            [LandingPageTheme.breakpoints.down("md")]:{
                fontSize: "18px"
            }
        },

    },
    devtext: {
        width: "70%",
        margin: "0 auto 40px",
        textAlign: "center",
        [LandingPageTheme.breakpoints.down("md")]:{
            width: "100%"
        },
        "& p": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "16px",
            textAlign: "center",
            letterSpacing: "0.005em",
            color: "#000000",
            marginTop: "20px"
        },
        "& button": {
            background: "#FBA92D",
            color: "#fff",
            borderRadius: "8px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            boxShadow: "none",
            padding: "15px 40px 15px 40px",
            textTransform: "capitalize",
            marginTop: "20px",
            "& a":{
                color: "#fff",
            },
            "&:hover":{
                background: "#173E4B",
                boxShadow: "none",
            },
        }
    },
    tabswrapper: {
        "& p": {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "24px",
            letterSpacing: "0.0015em",
            color: "#000000"
        }
    },
    codeformat:{
        "& pre":{
            border:" 0 !important",
            background:" #000 !important",
            fontSize: "14px !important",
            textShadow:" none !important"
        }
    }

  


}));

