import * as React from "react";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";

import AlertDialog from "../../../components/AlertDialog/index.es6";


export default function DeleteCountry(props) {

  if (!props.country) {
    return ""
  }

  const [showWarning, setShowWarning ] = React.useState(false);


  const deleteCountry = ()=>{
    setShowWarning(true) 
    props.deleteCountry(props.country)
  }

  const cancel = ()=>{
    props.resetCountryToDelete()
  }


  const handleCountryDeletedSuccessfully = () => {
    props.resetDeleteCountrySuccessAlert()
    props.resetCountryToDelete()
  }

  const handleCountryDeleteError = () => {
    props.resetDeleteCountryErrorAlert()
  }

  return (
    <React.Fragment>
      <AlertDialog
        custom
        show={props.showDeleteCountryProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Deleting country</h2>
      </AlertDialog>


      <AlertDialog
        warning
        show={!showWarning && props.country}
        size="sm"
        title={'Are you sure you want to delete'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => {cancel() }}
        onCancel={() => {deleteCountry()}}
        confirmBtnText={'Cancel'}
        showCancel={true}
        showConfirm={true}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
      </AlertDialog>

      <AlertDialog
        success
        show={props.showDeleteCountrySuccessAlert}
        size="sm"
        title={'Country deleted'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleCountryDeletedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => { handleCountryDeletedSuccessfully() }} style={{ background: 'green', }}>Ok</Button>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showDeleteCountryErrorAlert}
        danger
        title={'Error deleting country'}
        onConfirm={() => handleCountryDeleteError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.editCountryError ? props.editCountryError.message : ''}
      </AlertDialog>






    </React.Fragment>

  );



}
