import React, { useState } from 'react'
import MaterialTable from 'material-table';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const UsersTable = ({ setUserToEdit, displayEditButton, displayDeleteButton, users, loader, setUserToDelete, setUserToEditRoles }) => {
  const [selectedUser,setSelectedUser] = useState({});
  const [anchorEl, setAnchorEl] = useState(null)
  const usersdata = users || [];
 
  const handleMenuOpen= (rowData, event) => {
    setSelectedUser(rowData);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose= () => {
    setAnchorEl(null);
  };

  if ((users && users.length > 0) || loader) {
    return (
      <>
          <MaterialTable
            title="List of Users"
            columns={[
              { title: 'FirstName', field: 'firstName' },
              { title: 'Last Name', field: 'lastName' },
              { title: 'Username', field: 'username' },
              { title: 'Email', field: 'email' },
              {
                title: 'action', field: 'usersaction', render: rowData => {
                  return <div>

                    <MoreVertIcon
                      onClick={(event) => handleMenuOpen(rowData, event)}
                      style={{ float: 'left' }}
                    />
                     {  ( selectedUser.id === rowData.id) && <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                      name={rowData}>
                      <MenuItem  onClick={() => {  setAnchorEl(null); setUserToEdit(rowData) }} >Edit User/partner</MenuItem>
                      <MenuItem  onClick={() => {  setAnchorEl(null); setUserToEditRoles(rowData) }} >Edit User Roles</MenuItem>
                      
                      <MenuItem onClick={() => {  setAnchorEl(null); setUserToDelete(rowData) }}>Delete User</MenuItem>
                    </Menu>}
                  </div>
                }
              },
            ]}
            data={usersdata}
            options={{
              search: true,
              sorting: true,
              exportButton: true,
              actionsColumnIndex: -1,
              headerStyle: {
                backgroundColor: '#003643',
                color: '#FFF'
              }
            }}
            
          />  
      </>
    )
  } else {
    return "No users found, click on the add button to add a new user."
  }

}

export default UsersTable