import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  claimwrapper: {
    marginTop: "40px",
    marginBottom: "30px",
  },
  claimdetailswrapper: {
    margin: "30px",

  },
  ClaimDetailscontentlast:{
    "& h4": {
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
      marginLeft: "20px",
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#060606",
      marginLeft: "20px",
    }
  },
  ClaimDetailscontent: {
    borderRight: " 1px solid #A7A7A7",
    "&:last-child":{
      borderRight: "0"
    },
    "& h4": {
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
      marginLeft: "20px",
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#060606",
      marginLeft: "20px",
    }
  },
  borderbottom: {
    borderBottom: " 1px solid #A7A7A7",
    width: "90%",
    marginTop: "20px",
    marginBottom: "30px",
  },
  notesheader:{
    margin: "20px 0 20px 30px ",
    "& h4":{
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "15px",
      color: "#000000",
    },
    "& ul":{
      listStyle: "none",
      display: "flex",
      padding: "0",
      "& li":{
        display: "inline-block",
        "& svg":{
          marginTop: "6px",
          marginLeft: "6px",
        }
      }
    }
  },
  noteswrapper:{
    margin: "20px 0 20px 30px ",
  },
  notesbox:{
    background: "#FFFFFF",
    border: "1px solid #B8B8B8",
    width: "80%",
    marginBottom: "30px",
    padding: "15px",
    "& h4":{
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#060606;"
    },
 
  }

}));