import { makeStyles } from "@material-ui/styles";

export const useStyle = makeStyles(() => ({
  dialgHeaderWrapper:{
      background: "#FBA92D",
      textAlign: "center",
      padding: "10px !important",
      "& h3": {
          color: "#fff",
          fontFamily: "Lato",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "24px",
          lineHeight: "24px",
      }
  },
  claimDetailHeading:{
   // textAlign: "left",
    marginTop: "20px !important",
    marginLeft: "30px !important",
    "& span": {
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "0.001em",
      color: "#000000"
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#000",
      wordBreak: "break-word"
    }
  },

  resultsTable: {
    "& table": {
      "&:nth-child(1)": {
        background: "#FFFFFF",
        border: "1px solid #A7A7A7",
        margin: "30px",
        width: "95%",
      },
      "&:nth-child(2)": {
        border: "0 !important"
      },
      "& tr":{
        borderBottom: "1px solid #A7A7A7",
      },
      "& th": {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "15px",
        color: "#000000",
        padding: "10px",
        borderRight: "1px solid #A7A7A7",
      },
      "& td": {
        fontFamily: "open sans !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "12px !important",
        lineHeight: "26px !important",
        color: "#000000 !important",
        padding: "10px !important",
        borderRight: "1px solid #A7A7A7 !important",

      },
      "& tfoot": {
        "& tr": {
          "& td": {
            border: "0 !important"
          }
        }
      }
    },
    // "& .MuiPaper-root":{
    //   background: "transparent"
    // },
    "& .MuiPaper-elevation2": {
      boxShadow: "none",
    },
    "& .MuiToolbar-regular": {
      paddingRight: "40px",
    },
  },



}));