import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../lib/actionTypes.es6'
import * as actions from './actions';

import { createQuote,buyPolicy,uploadPolicyDocument,createDebitOrderz, updateQuote, changeQuote } from '../../../lib/requests.es6';
import { GAloggerException } from '../../../lib/monitoring.es6';
import { unAuthorizedNotification } from '../../../lib/access.es6';
import { getAllPoliciesRequest } from '../../PolicyV2CustomerStatus/Redux/actions';


export function* createQuoteRequestWatcher() {
  for (;;) {
    const request = yield take(types.CREATE_QUOTE_REQUEST);
    try {
      const response = yield call(createQuote, request.payload);
      yield put(actions.createQuoteSuccess(response.data));

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.createQuoteFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* BuyPolicyRequestWatcher() {
  for (;;) {
    const request = yield take(types.BUY_POLICY_REQUEST);
    try {
      const response = yield call(buyPolicy, request.payload);
      yield put(actions.buyPolicySuccess(response.data));
      yield put(getAllPoliciesRequest({guid: response.data.customer_guid}));

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.buyPolicyFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* changeQuoteRequestWatcher() {
  for (;;) {
    const request = yield take(types.CHANGE_QUOTE_REQUEST);
    try {
      const response = yield call(changeQuote, request.payload);
      yield put(actions.changeQuoteSuccess(response.data));
      yield put(actions.buyPolicySuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.changeQuoteFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* amendQuoteRequestWatcher() {
  for (;;) {
    const request = yield take(types.AMEND_QUOTE_REQUEST);
    try {
      const response = yield call(updateQuote, request.payload);
      yield put(actions.amendQuoteSuccess(response.data));
      yield put(actions.buyPolicySuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.amendQuoteFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* UploadPolicyDocumentRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.UPLOAD_POLICY_DCP_DOCUMENT_REQUEST);

      const response = yield call(uploadPolicyDocument, payload);
      yield put(actions.uploadPolicyDcpDocumentSuccess(response.data));
    } catch (e) {
      let errorPayload = 'An error occurred, this could be due to invalid data';
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.uploadPolicyDcpDocumentFailed(errorPayload));
      GAloggerException(e);
    }
  }
}


export function* createDebitOrdersDcpRequestWatcher() {
  for (;;) {
    const request = yield take(types.CREATE_DEBIT_ORDERS_DCP_REQUEST);
    try {
      const response = yield call(createDebitOrderz, request.payload);
      yield put(actions.createDebitOrdersDcpSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.createDebitOrdersDcpFailure(errorMessage));
    }
  }
}