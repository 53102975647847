import React, { useState } from 'react'
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress } from '@mui/material';

const ChangePolicy = (props) => {

  const classes = useStyle();
  const [selectedPolicy, setSelectedPolicy] = useState('');
  const [startdate, setStartdate] = useState('');
  const [policyguid, setPolicyguid] = useState('');
  const [customerguid, setCustomerguid] = useState('');


  const handleChange = (event) => {

    const { name, value } = event.target

    if (name === "selectedPolicy") {
      setSelectedPolicy(value)
      setPolicyguid(value.guid)
      setCustomerguid(value.customer_guid)
    }
    if (name === "startdate") {
      setStartdate(value)
    }

  }
  const handleChangeQuote = () => {
    const payload = {
      "policy_guid": policyguid,
      "quotation_guid": props.createdQuote.guid,
      "customer_guid": customerguid,
      "start_date": startdate,
    }
    props.changeQuote(payload);
  }

  const handleChangeQuoteSuccessfully = () =>{
    props.resetChangeQuoteSuccessAlert();
  }


  const handleChangeQuoteError = () =>{
    props.resetChangeQuoteErrorAlert()
  }

  return (
    <section className={classes.changequoteinputwrapper}>


      <AlertDialog
        custom
        show={props.changeQuoteProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}  
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2> Changing Quote... </h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.changeQuoteSuccessAlert}
        size="sm"
        title={'Quote changed  Successfuly '}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleChangeQuoteSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
       
      </AlertDialog>

      <AlertDialog
        show={props.changeQuoteErrorAlert}
        danger
        title={'Error Changing Quote'}
        onConfirm={() => handleChangeQuoteError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.changeQuoteError ? props.changeQuoteError.message : ''}
      </AlertDialog>

      <ValidatorForm onSubmit={() => handleChangeQuote()} >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sm={6}>
            <SelectValidator
              labelId="select-policies"
              id="select-policies"
              value={selectedPolicy}
              name='selectedPolicy'
              type="text"
              label="Selected Policy"
              onChange={handleChange}
              variant="outlined"
              validators={['required']}
              errorMessages={['Please select a policy']}
              style={{ width: "100%" }}
              sx={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.005em",
                color: "#000000",
              }}
            >
              {
                props.policies.map((policy, index) => {
                  return (
                    <MenuItem
                      key={index}
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }} value={policy}>{policy.policy_number}</MenuItem>
                  )

                })
              }

            </SelectValidator>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <TextValidator
              id="start-date"
              label="Start Date"
              name='startdate'
              value={startdate}
              onChange={handleChange}
              fullWidth
              type='date'
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              validators={['required']}
              errorMessages={['Please enter a start date']}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.changequotesbtns}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type='submit'
              >
                Change  Quote
              </Button>

            </div>
          </Grid>
        </Grid>
      </ValidatorForm>
    </section>
  )
}

export default ChangePolicy