import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// import MenuItem from '@mui/material/MenuItem';
// import InputAdornment from '@mui/material/InputAdornment';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import AlertDialog from "../../../../components/AlertDialog/index.es6";
import { CircularProgress, Switch } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../../lib/constants";
import { CLAIM_ASSESSMENT_MODES, CLAIM_TYPES } from "../../constants";
import MultipleSelectChip from "./MultipleSelectChip";

let initialState = {}


export default function UpdatePartnerConfigClaim(props) {
  if (!props.partnerConfigClaim) {
    return ""
  }
  initialState = props.partnerConfigClaim

  const [
    {
      guid,
      level_1_email_list,
      level_2_email_list,
      finance_email_list,
      closed_email_list,
      submission_email_list,

      claim_types,
      claim_assessment_mode,

      reinsurer_referral_email,


      reinsurer_referral_limit_loyalty,
      reinsurer_referral_limit_upsell,
      check_reinsurer_limit,
      claim_payout_cool_off_in_minutes,
      customer_high_risk_flagging_enabled,
      customer_high_risk_claim_paid_count,
      customer_high_risk_past_days_count,
      hospital_high_risk_flagging_enabled,
      hospital_high_risk_claim_paid_count,
      hospital_high_risk_past_days_count,
      customer_high_risk_flagging_from_hospital_days_enabled,
      customer_high_risk_previous_hospital_days_count,
      customer_high_risk_from_hospital_days_past_days_count,
      maximum_allowed_days_to_report_claim
    }, setState
  ] = React.useState(initialState)

  const [claimTypes, setClaimTypes] = React.useState([...props.formatAndConvertToListString(claim_types)]);
  const [claimAssessmentMode, setClaimAssessmentMode] = React.useState([claim_assessment_mode || 'None']);

  const [level1EmailList, setLevel1EmailList] = React.useState(props.removeSquareBrackets(level_1_email_list));
  const [level2EmailList, setLevel2EmailList] = React.useState(props.removeSquareBrackets(level_2_email_list));
  const [financeEmailList, setFinanceEmailList] = React.useState(props.removeSquareBrackets(finance_email_list));
  const [closedEmailList, setClosedEmailList] = React.useState(props.removeSquareBrackets(closed_email_list));
  const [submissionEmailList, setSubmissionEmailList] = React.useState(props.removeSquareBrackets(submission_email_list));

  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const onChangeSetLevel1EmailList = e => {
    setLevel1EmailList(e.target.value)
  }
  const onChangeSetLevel2EmailList = e => {
    setLevel2EmailList(e.target.value)
  }
  const onChangeSetFinanceEmailList = e => {
    setFinanceEmailList(e.target.value)
  }
  const onChangeSetClosedEmailList = e => {
    setClosedEmailList(e.target.value)
  }
  const onChangeSetSubmissionEmailList = e => {
    setSubmissionEmailList(e.target.value)
  }


  const toggleBooleanFields = e => {
    setState(prevState => ({ ...prevState, [e.target.name]: e.target.checked }));
  };



  const submit = () => {
    const payload = {
      guid,
      level_1_email_list: props.convertStringToList(level1EmailList),
      level_2_email_list: props.convertStringToList(level2EmailList),
      finance_email_list: props.convertStringToList(financeEmailList),
      closed_email_list: props.convertStringToList(closedEmailList),
      submission_email_list: props.convertStringToList(submissionEmailList),

      claim_types: props.convertListToString(claimTypes),
      claim_assessment_mode: claimAssessmentMode[0],
      reinsurer_referral_email,


      reinsurer_referral_limit_loyalty,
      reinsurer_referral_limit_upsell,
      check_reinsurer_limit,
      claim_payout_cool_off_in_minutes,
      customer_high_risk_flagging_enabled,
      customer_high_risk_claim_paid_count,
      customer_high_risk_past_days_count,
      hospital_high_risk_flagging_enabled,
      hospital_high_risk_claim_paid_count,
      hospital_high_risk_past_days_count,
      customer_high_risk_flagging_from_hospital_days_enabled,
      customer_high_risk_previous_hospital_days_count,
      customer_high_risk_from_hospital_days_past_days_count,
      maximum_allowed_days_to_report_claim
    }
    for (let k in payload) {
      if (payload[k] == null) delete payload[k]
    }
    console.log('submitting');
    console.log(payload);
    props.updatePartnerConfigClaim(payload)
  }



  const reset = () => {
    setState(initialState);
  }

  const handleUpdateParnterConfigdSuccessfully = () => {
    props.resetUpdatePartnerConfigClaimSuccessAlert()
    props.closeUpdatePartnerConfigClaimDialog()
    reset()
  }

  const handleUpdateParnterConfigError = () => {
    props.resetUpdatePartnerConfigClaimErrorAlert()
  }

  const booleanFields = [
    "hospital_high_risk_flagging_enabled",
    "customer_high_risk_flagging_enabled",
    "customer_high_risk_flagging_from_hospital_days_enabled",
  ]

  const textFields = [
    // "reinsurer_referral_email",
  ]

  const integerFields = [
    // "reinsurer_referral_limit_loyalty",
    // "reinsurer_referral_limit_upsell",
    "check_reinsurer_limit",
    "claim_payout_cool_off_in_minutes",
    "customer_high_risk_claim_paid_count",
    "customer_high_risk_past_days_count",
    "hospital_high_risk_claim_paid_count",
    "hospital_high_risk_past_days_count",
    "customer_high_risk_previous_hospital_days_count",
    "customer_high_risk_from_hospital_days_past_days_count",
    "maximum_allowed_days_to_report_claim"
  ]

  return (
    <>
      <AlertDialog
        custom
        show={props.showUpdatePartnerConfigClaimProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>updating partner config claim</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showUpdatePartnerConfigClaimSuccessAlert}
        size="sm"
        title={'Partner config claim updated'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleUpdateParnterConfigdSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>

      <AlertDialog
        show={props.showUpdatePartnerConfigClaimErrorAlert}
        danger
        title={'Error updating partner config claim'}
        onConfirm={() => handleUpdateParnterConfigError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.updatePartnerConfigClaimError ? props.updatePartnerConfigClaimError.message : ''}
      </AlertDialog>


      <Dialog open={props.showUpdatePartnerConfigClaimDialog} fullWidth maxWidth='lg' fullScreen onClose={props.closeUpdatePartnerConfigClaimDialog}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.closeUpdatePartnerConfigClaimDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Update Partner Config claim
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div style={{ marginTop: "2%" }}>
            <Box sx={{ width: "100%" }}>
              <ValidatorForm onSubmit={() => submit()} >
                <Grid container spacing={2}>

                {
                    booleanFields.map(field => {
                      return <Grid item lg={4} md={4} xs={12}>
                        <label>{field}</label>
                        <Switch
                          checked={eval(field)}  //eslint-disable-line
                          onChange={toggleBooleanFields}
                          name={field}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Grid>
                    })
                  }

                  <Grid item lg={4} md={4} xs={12}>
                    <MultipleSelectChip
                      constantFields={CLAIM_TYPES}
                      fieldLabel="Claim types"
                      fields={claimTypes}
                      setFields={setClaimTypes}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} xs={12}>
                    <MultipleSelectChip
                      constantFields={CLAIM_ASSESSMENT_MODES}
                      fieldLabel="Claim assessment modes"
                      fields={claimAssessmentMode}
                      setFields={setClaimAssessmentMode}
                      multiple={false}
                    />
                  </Grid>


                  <Grid item lg={4} md={4} xs={12}>
                    <TextValidator
                      onChange={onChangeSetLevel1EmailList}
                      value={level1EmailList} //eslint-disable-line
                      label={'level1EmailList'}
                      name={'level1EmailList'}
                      fullWidth
                      variant="outlined"
                      type="text"
                      placeholder={'level1EmailList'}
                    // validators={['required']}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} xs={12}>
                    <TextValidator
                      onChange={onChangeSetLevel2EmailList}
                      value={level2EmailList} //eslint-disable-line
                      label={'level2EmailList'}
                      name={'level2EmailList'}
                      fullWidth
                      variant="outlined"
                      type="text"
                      placeholder={'level2EmailList'}
                    // validators={['required']}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} xs={12}>
                    <TextValidator
                      onChange={onChangeSetFinanceEmailList}
                      value={financeEmailList} //eslint-disable-line
                      label={'financeEmailList'}
                      name={'financeEmailList'}
                      fullWidth
                      variant="outlined"
                      type="text"
                      placeholder={'financeEmailList'}
                    // validators={['required']}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} xs={12}>
                    <TextValidator
                      onChange={onChangeSetClosedEmailList}
                      value={closedEmailList} //eslint-disable-line
                      label={'closedEmailList'}
                      name={'closedEmailList'}
                      fullWidth
                      variant="outlined"
                      type="text"
                      placeholder={'closedEmailList'}
                    // validators={['required']}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} xs={12}>
                    <TextValidator
                      onChange={onChangeSetSubmissionEmailList}
                      value={submissionEmailList} //eslint-disable-line
                      label={'submissionEmailList'}
                      name={'submissionEmailList'}
                      fullWidth
                      variant="outlined"
                      type="text"
                      placeholder={'submissionEmailList'}
                    // validators={['required']}
                    />
                  </Grid>



                  {
                    textFields.map(field => {
                      return <Grid item lg={4} md={4} xs={12}>
                        <TextValidator
                          onChange={onChange}
                          value={eval(field)}  //eslint-disable-line
                          label={field}
                          name={field}
                          fullWidth
                          variant="outlined"
                          type="text"
                          placeholder={field}
                          // validators={['required']}
                          errorMessages={[`Please enter ${field}`]}
                        />
                      </Grid>
                    })
                  }

                  {
                    integerFields.map(field => {
                      return <Grid item lg={4} md={4} xs={12}>
                        <TextValidator
                          onChange={onChange}
                          value={eval(field)}  //eslint-disable-line
                          label={field}
                          name={field}
                          fullWidth
                          variant="outlined"
                          type="number"
                          placeholder={field}
                          // validators={['required']}
                          errorMessages={[`Please enter ${field}`]}
                        />
                      </Grid>
                    })
                  }
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant="contained" type="submit" style={{ marginRight: '2%' }}> Update </Button>
                    <Button variant="contained" color="secondary" style={{ marginRight: '2%' }}
                      onClick={() => reset()}
                    > Reset</Button>
                  </Grid>

                </Grid>
              </ValidatorForm>
            </Box>
          </div>

        </DialogContent>
      </Dialog>




    </>
  );
}
