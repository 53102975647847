import * as types from '../../../lib/actionTypes.es6';

// export function downloadDocumentRequest(payload) {
//   return {
//     type: types.DOWNLOAD_DOCUMENT_REQUEST,
//     payload,
//   };
// }

// export function downloadDocumentFailure(error) {
//   return {
//     type: types.DOWNLOAD_DOCUMENT_FAILURE,
//     payload: error,
//   };
// }

// export function downloadDocumentSuccess(response) {
//   return {
//     type: types.DOWNLOAD_DOCUMENT_SUCCESS,
//     payload: response,
//   };
// }

// export function resetDownloadDocumentSuccess(error) {
//   return {
//     type: types.RESET_DOWNLOAD_DOCUMENT_SUCCESS,
//   };
// }

// export function resetDownloadDocumentError(error) {
//   return {
//     type: types.RESET_DOWNLOAD_DOCUMENT_ERROR,
//   };
// }

// export function setDocumentEntity(response) {
//   return {
//     type: types.SET_ENTITY_TO_DOWNLOAD_FOR,
//     payload: response,
//   };
// }

// export function resetDocumentEntity(response) {
//   return {
//     type: types.RESET_ENTITY_TO_DOWNLOAD_FOR,
//   };
// }


// template document actions


export function getEmailTemplateRequest(type) {
  return {
    type: types.GET_EMAIL_TEMPLATES_REQUEST,
    payload: type,
  };
}

export function getEmailTemplatesSuccess(response) {
  return {
    type: types.GET_EMAIL_TEMPLATES_SUCCESS,
    payload: response,
  };
}

export function getEmailTemplatesFailed(error) {
  return {
    type: types.GET_EMAIL_TEMPLATES_FAILURE,
    payload: error,
  };
}

export function resetGetEmailTemplates(error) {
  return {
    type: types.RESET_GET_EMAIL_TEMPLATES,
    payload: error,
  };
}



export function addEmailTemplateRequest(payload) {
  return {
    type: types.ADD_EMAIL_TEMPLATE_REQUEST,
    payload,
  };
}

export function addEmailTemplateSuccess(response) {
  return {
    type: types.ADD_EMAIL_TEMPLATE_SUCCESS,
    payload: response,
  };
}

export function addEmailTemplateFailed(error) {
  return {
    type: types.ADD_EMAIL_TEMPLATE_FAILURE,
    payload: error,
  };
}

export function editTemplateDocumentRequest(payload) {
  return {
    type: types.EDIT_TEMPLATE_DOCUMENT_REQUEST,
    payload,
  };
}

export function editTemplateDocumentSuccess(response) {
  return {
    type: types.EDIT_TEMPLATE_DOCUMENT_SUCCESS,
    payload: response,
  };
}

export function editTemplateDocumentFailed(error) {
  return {
    type: types.EDIT_TEMPLATE_DOCUMENT_FAILURE,
    payload: error,
  };
}


export function deleteTemplateDocumentRequest(payload) {
  return {
    type: types.DELETE_TEMPLATE_DOCUMENT_REQUEST,
    payload,
  };
}

export function deleteTemplateDocumentSuccess(response) {
  return {
    type: types.DELETE_TEMPLATE_DOCUMENT_SUCCESS,
    payload: response,
  };
}

export function deleteTemplateDocumentFailed(error) {
  return {
    type: types.DELETE_TEMPLATE_DOCUMENT_FAILURE,
    payload: error,
  };
}
