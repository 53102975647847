import React from 'react';
import { connect } from 'react-redux';
import * as globalActions from '../Main/actions.es6';
import * as actions from "./actions"
import AddBankDetails from '../../components/BeneficiaryBankDetails/AddBankDetails';
import DeleteBankDetails from '../../components/BeneficiaryBankDetails/DeleteBankDetails';
import EditBankDetails from '../../components/BeneficiaryBankDetails/EditBankDetails';
import ProductsHeader from '../NewProductList/components/ProductsHeader/ProductsHeader';
import DisplayBeneficiaryBankDetails from '../../components/BeneficiaryBankDetails/DisplayBeneficiaryBankDetails';

export class BeneficiaryBankDetailsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddBankDetailsDialog: false,
      customer_guid: null
    }
  }
  componentDidMount() {
    const currentCustomer = this.props.customerData.currentCustomer;
    this.props.dispatch(actions.getCustomerBeneficiaryBankDetailsRequest(currentCustomer.guid));

    this.props.dispatch(globalActions.showCustomerBar());
  }
  addBankDetails = (payload) => {
    this.props.dispatch(actions.addCustomerBeneficiaryBankDetailsRequest(payload));
  }


  toggleEditBeneficiaryBankDetailsDialog() {
    this.setState({ openEditModal: !this.state.openEditModal });
  }
  closeEditBeneficiaryBankDetailsDialog() {
    this.setState({ openEditModal: false });
  }
  handleOpenConfirmDeleteDialog(beneficiary) {
    this.setState({ openConfirmDelete: false, customerGuid: beneficiary.guid });
  }
  setDeleteDialog(beneficiary) {
    this.setState({ openConfirmDelete: true, customer_guid: beneficiary.guid });
    this.handleCloseActionMenu();

  }
  deleteBeneficiaryBankDetails() {
    this.props.onDelete(this.state.customer_guid);
    this.setState({ openConfirmDelete: false });
  }

  toggleAddBankDetailsDialog() {
    this.setState({ openAddBankDetailsDialog: !this.state.openAddBankDetailsDialog })
  }

  deleteBankDetails(payload) {
    this.props.dispatch(actions.deleteCustomerBeneficiaryBankDetailsRequest(payload));
  }

  editBankDetails(payload) {
    this.props.dispatch(actions.updateCustomerBeneficiaryBankDetailsRequest(payload));
  }


  render() {
    const bankdetail = this.props.customerBeneficiarybankdetails.bankdetail
    const beneficiarydata = this.props.customerBeneficiary.beneficiary;
    return (
      <>

        <ProductsHeader
          title="Beneficiary Bank Details"
          onAddButtonClick={this.toggleAddBankDetailsDialog.bind(this)}
          showAddBankDetailsDialog={this.state.openAddBankDetailsDialog}
          showAddButton={this.props.customerBeneficiarybankdetails.bankdetail === null || this.props.customerBeneficiarybankdetails.bankdetail === ''}

        />

        <DisplayBeneficiaryBankDetails
          customerbankdetail={bankdetail}
          customerGuid={this.props.customerGuid}
          labels={this.state.labels}

         setBankDetailsToDelete={(bankdetails) => this.props.dispatch(actions.setCustomerBeneficiaryBankDetailsToDelete(bankdetails))}
          setBankDetailsToEdit={(bankdetails) => this.props.dispatch(actions.setCustomerBeneficiaryBankDetailsToEdit(bankdetails))}

          displayEditButton={true}
          displayDeleteButton={true}
          loading={this.props.customerBeneficiarybankdetails.loader}
        />
        {
          beneficiarydata &&  <AddBankDetails
          openAddBankDetailsDialog={this.state.openAddBankDetailsDialog}
          closeAddBeneficiaryBankDetailsDialog={this.toggleAddBankDetailsDialog.bind(this)}
          beneficiaryGuid={beneficiarydata.guid}
          customerGuid={this.props.customerData.currentCustomer.guid}
          addBankDetails={this.addBankDetails.bind(this)}


          showAddBankDetailsProgressAlert={this.props.customerBeneficiarybankdetails.createbankdetailLoader}
          showAddBankDetailsSuccessAlert={this.props.customerBeneficiarybankdetails.createbankdetailSuccess}
          showAddBankDetailsErrorAlert={!!this.props.customerBeneficiarybankdetails.createbankdetailError}
          error={this.props.customerBeneficiarybankdetails.createbankdetailError}

          resetAddBankDetailsSuccessAlert={() => { this.props.dispatch(actions.resetAddCustomerBeneficiaryBankDetailsSuccess()) }}
          resetAddBankDetailsErrorAlert={() => { this.props.dispatch(actions.resetAddCustomerBeneficiaryBankDetailsFailed()) }}
        />

        }

        <DeleteBankDetails
          bankDetailstodelete={this.props.customerBeneficiarybankdetails.bankDetailsToDelete}
          deleteBankDetails={this.deleteBankDetails.bind(this)}
          resetBankDetailsToDelete={() => this.props.dispatch(actions.resetCustomerBeneficiaryBankDetailsToDelete())}


          showDeleteBankDetailsProgressAlert={this.props.customerBeneficiarybankdetails.deletebankdetailLoader}
          showDeleteBankDetailsSuccessAlert={this.props.customerBeneficiarybankdetails.deletebankdetailSuccess}
          showDeleteBankDetailsErrorAlert={!!this.props.customerBeneficiarybankdetails.deletebankdetailError}
          deleteBankDetailsError={this.props.customerBeneficiarybankdetails.deleteBankDetailError}

          resetDeleteBankDetailsSuccessAlert={() => { this.props.dispatch(actions.resetDeleteCustomerBeneficiaryBankDetailsSuccess()) }}
          resetDeleteBankDetailsErrorAlert={() => { this.props.dispatch(actions.resetDeleteCustomerBeneficiaryBankDetailsFailed()) }}
        />

        <EditBankDetails
          bankDetails={this.props.customerBeneficiarybankdetails.bankDetailToEdit}
          editBankDetails={this.editBankDetails.bind(this)}
          //customerGuid={bankdetail.guid}
          customerGuid={this.props.customerData.currentCustomer.guid}
          resetBankDetailsToEdit={() => { this.props.dispatch(actions.resetCustomerBeneficiaryBankDetailsToEdit()) }}

          showEditBankDetailsProgressAlert={this.props.customerBeneficiarybankdetails.editbankdetailLoader}
          showEditBankDetailsSuccessAlert={this.props.customerBeneficiarybankdetails.editbankdetailSuccess}
          showEditBankDetailsErrorAlert={!!this.props.customerBeneficiarybankdetails.editbankdetailError}
          editBankDetailsError={this.props.customerBeneficiarybankdetails.editbankdetailError}

          //resetEditBankDetailsSuccessAlert={() => { this.props.dispatch(actions.resetEditCustomerBeneficiaryBankDetailsSuccess()) }}
          //resetEditBankDetailsErrorAlert={() => { this.props.dispatch(actions.resetEditCustomerBeneficiaryBankDEtailsFailed()) }}
          resetEditBankDetailsSuccessAlert={() => { this.props.dispatch(actions.resetEditCustomerBankDetailsSuccess()) }}
          resetEditBankDetailsErrorAlert={() => { this.props.dispatch(actions.resetEditCustomerBankDEtailsFailed()) }}
        />

      </>
    );
  }
}

export default connect(state => ({
  customerBeneficiary: state.customerBeneficiary,
  customerData: state.currentCustomer,
  customerBeneficiarybankdetails: state.customerBeneficiarybankdetails,

}))(
  BeneficiaryBankDetailsList
);
