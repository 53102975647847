import React from 'react'
import ModalInc from '../../../../../../../shared-ui/ModalInc'
import { CircularProgress, Grid } from '@mui/material'
import ButtonInc from '../../../../../../../shared-ui/ButtonInc'
import AlertDialog from '../../../../../../../components/AlertDialog/index.es6'
import { reloadPage } from '../../../../../../../lib/utils.es6'

const CancelPolicy = (props) => {
  const handleCancelPolicy = () =>{
    props.cancelPolicy(props.policyToCancel)
  }
  const resetAfterSucesfullCancel = () =>{
    reloadPage();
  }
  const resetAfterFailedCancel = () =>{
    reloadPage();
  }
  return (
    <>
      <ModalInc
        modalTitle={props.labels.Confirmcancellation} 
        open={props.openPolicyCancelDialog}
        onClose={props.toggleCancelPolicyDialog}
        fullWidth
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
           <p>{props.labels.Areyousureyouwanttocancelthispolicy}</p>
          </Grid>
        </Grid>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={6} md={6}>

          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8} md={8}>
                <ButtonInc
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ marginTop: "20px", float: "right" }}
                  onClick={handleCancelPolicy}
                >
                  {props.labels.cancelPolicy}
                </ButtonInc>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <ButtonInc
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ marginTop: "20px" }}
                  onClick={props.toggleCancelPolicyDialog}
                >
                  {props.labels.Goback}
                </ButtonInc>
              </Grid>
            </Grid>
          </Grid>
        </Grid>


      </ModalInc>
      <AlertDialog
        custom
        show={props.cancelloader}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>{props.labels.cancellingpolicy}</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={!!props.successfullPolicyCancel}
        size="sm"
        title={`${props.labels.Policycancelledsuccessfully}`}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { resetAfterSucesfullCancel() }}
        confirmBtnText={'ok'}
        showCancel={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>


      <AlertDialog
        show={!!props.policyCancelFailure}
        danger
        title={`${props.labels.Errorcancellingpolicy}` }
        onConfirm={() => { resetAfterFailedCancel() }}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.policyCancelDataError ? props.policyCancelDataError.message : ''}
      </AlertDialog>
    </>
  )
}

export default CancelPolicy