import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../../../../lib/actionTypes.es6';
import { getAllPolicies, listPaidProducts ,purchasePolicy,deletePolicy,updatePolicy} from '../../../../../../lib/requests.es6';
import * as actions from '../Redux/actions.js'
import { unAuthorizedNotification } from '../../../../../../lib/access.es6';
import { GAloggerException } from '../../../../../../lib/monitoring.es6';

export function* getOrangePoliciesWatcher() {
  while (true) {
    const request = yield take(types.GET_ALL_ORANGE_POLICIES_REQUEST);
    try {
     const response = yield call(getAllPolicies, request.payload);
      yield put(actions.getAllOrangePoliciesSuccess(response.data));

      const product_guid = getProductGuid(response.data)
      yield put(actions.getOrangeExtendedFamilyProductConfigsRequest(product_guid));

      const policy_guid = getPolicyGuid(response.data)
      yield put(actions.getTotalPayableOrangePremiumRequest(policy_guid));

    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.getAllOrangePoliciesFail(errorPayload));
    }
  }
}

export function* purchaseOrangePolicyRequestWatcher() {
  for (;;) {
    const requestAction = yield take(types.PURCHASE_ORANGE_POLICY_REQUEST);
    let response = {};
    try {
      response = yield call(purchasePolicy, requestAction.payload);
      yield put(actions.purchaseOrangePolicySuccess(response.data));

    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.purchaseOrangePolicyFail(errorPayload));
    }
  }
}

export function* OrangeProductListWatcher() {
  for (;;) {
    // NOTE: Do we gain anything ny moving this in the while clause??
    yield take(types.PAID_ORANGE_PRODUCT_LIST_REQUEST);
    let response = {};
    try {
      response = yield call(listPaidProducts);
      yield put(actions.listOrangePaidProductsSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.listOrangePaidProductsFail(errorPayload));
    }
  }
}

export function* deleteOrangePolicyWatcher() {
  for (;;) {
    // TODO: There should be no need of an infinite loop here. We can listen for a specific action
    const policyData = yield take(types.DELETE_ORANGE_POLICY_REQUEST);
    try {
      const response = yield call(deletePolicy, policyData.payload.guid);
      yield put(actions.deleteOrangePolicySuccess(response.data));
    } catch (e) {
      yield put(
        actions.deleteOrangePolicyFail('An error occurred, failed to delete entity')
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* updateOrangePolicyRequestWatcher() {
  while (true) {
    const requestAction = yield take(types.UPDATE_ORANGE_POLICY_REQUEST);
    let response = {};
    try {
      response = yield call(updatePolicy, requestAction.payload);
      yield put(actions.updateOrangePolicySuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.updatePolicyFail(errorPayload));
    }
  }
}

function getProductGuid(policies){
  for(let p of policies){
    if(p.active){
      return p.product_guid
    }
  }
  return null
}

function getPolicyGuid(policies){
  for(let p of policies){
    if(p.active){
      return p.guid
    }
  }
  return null
}