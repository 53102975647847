import {call, put, take} from "redux-saga/effects";
import * as types from "../../lib/actionTypes.es6";
import {
  addCustomerBankDetails,
  deleteCustomerBankDetails,
  getCustomerBankDetails,
  updateCustomerBankDetails
} from "../../lib/requests.es6";
import * as actions from "./actions.js";
import {unAuthorizedNotification} from "../../lib/access.es6";
import {GAloggerException} from "../../lib/monitoring.es6";

export function* getCustomerBankDetailsRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.GET_CUSTOMER_BANK_DETAILS_REQUEST);
      const response = yield call(getCustomerBankDetails, payload);
      yield put(actions.getCustomerBankDetailsSuccess(response.data));
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.getCustomerBankDetailsFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

// export function* getCustomerBeneficiaryRequestWatcher() {
//   while (true) {
//     try {
//       const {payload} = yield take(types.GET_CUSTOMER_BENEFICIARY_REQUEST);

//       const response = yield call(getCustomerDependant, payload);
//       yield put(actions.getCustomerDependantSuccess(response.data));
//     } catch (e) {
//       let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
//       if (e.response) {
//         errorPayload = e.response.data;
//         unAuthorizedNotification(e);
//       }
//       yield put(actions.getCustomerDependantFailed(errorPayload));
//       GAloggerException(e);
//     }
//   }
// }

export function* addCustomerBankDetailsRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.ADD_CUSTOMER_BANK_DETAILS_REQUEST);

      const response = yield call(addCustomerBankDetails, payload);
      yield put(actions.addCustomerBankDetailsSuccess(response.data));
      yield put(actions.getCustomerBankDetailsRequest(payload.customer_guid));
      
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.addCustomerBankDetailsFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* updateCustomerBankDetailsRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.EDIT_CUSTOMER_BANK_DETAILS_REQUEST);

      const response = yield call(updateCustomerBankDetails, payload);
      yield put(actions.editCustomerBankDetailsSuccess(response.data));
      yield put(actions.getCustomerBankDetailsRequest(response.data.customer_guid));
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.editCustomerBankDetailsFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* deleteCustomerBankDetailsRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.DELETE_CUSTOMER_BANK_DETAILS_REQUEST);

      const response = yield call(deleteCustomerBankDetails, payload);
      yield put(actions.deleteCustomerBankDetailsSuccess(response.data));
      yield put(actions.getCustomerBankDetailsRequest(payload.customer_guid));
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.deleteCustomerBankDetailsFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

