import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../../../../lib/actionTypes.es6';
import {  getAllPremiums, getAllProducts } from '../../../../../../lib/requests.es6';
import * as actions from './actions.js';
import { unAuthorizedNotification } from '../../../../../../lib/access.es6';
import { GAloggerException } from '../../../../../../lib/monitoring.es6';
import { getAllOrangeProductsFail, getAllOrangeProductsSuccess } from '../../Payments/Redux/actions';

export function* getAllOrangePremiumsWatcher() {
  while (true) {
    const requestAction = yield take(types.GET_ALL_ORANGE_PREMIUMS_REQUEST);
    let response = {};
    try {
      const msisdn = requestAction.payload;
      response = yield call(getAllPremiums, msisdn);
      yield put(actions.getAllOrangePremiumsSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.getAllOrangePremiumsFail(errorPayload));
    }
  }
}


export function* getAllProductsWatcher() {
  while (true) {
    yield take(types.GET_ALL_ORANGE_PRODUCTS_REQUEST);
    let response = {};
    try {
      response = yield call(getAllProducts);
      yield put(getAllOrangeProductsSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(getAllOrangeProductsFail(errorPayload));
    }
  }
}