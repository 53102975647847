import { take, call, put } from 'redux-saga/effects';
import { createDebitOrder, getAllBanks } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import * as types from '../../lib/actionTypes.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';

export function* createDebitOrderWatcher() {
  while (true) {
    const requestAction = yield take(types.CREATE_DEBIT_ORDER_REQUEST);
    try {
      const response = yield call(createDebitOrder, requestAction.payload);
      yield put(actions.createDebitOrderSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.createDebitOrderFailure(errorPayload));
    }
  }
}

export function* getAllBanksWatcher() {
  while (true) {
    yield take(types.GET_ALL_BANKS_REQUEST);
    try {
      const response = yield call(getAllBanks);
      yield put(actions.getAllBanksSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.getAllBanksFailure(errorPayload));
    }
  }
}
