import React, { useEffect, useState } from 'react'
import AlertDialog from '../../../../../components/AlertDialog/index.es6'
import { CircularProgress } from '@mui/material'
import ButtonInc from '../../../../../shared-ui/ButtonInc'
import { reloadPage } from '../../../../../lib/utils.es6'
import { getCustomerPolicies } from '../../../../../lib/requests.es6'
import { hasSameProduct } from '../../../lib/helpers'

const ApprovePolicy = (props) => {

  if (!props.policy) {
    return ""
  }

  const [showWarning, setShowWarning] = useState(false)
  const [showGettingActivePolicies, setShowGettingActivePolicies] = useState(false)
  const [showActivePolicyFoundErrorAlert, setShowActivePolicyFoundErrorAlert] = useState(false)

  const fetchActivePolicies = async () => {
    setShowGettingActivePolicies(true)
    const response = await getCustomerPolicies({guid: props.policy.customer_guid})
    const activePolicies = response.data.filter(policy => policy.active )
    if(activePolicies.length > 0 ){
      for(let currentPolicy of activePolicies){
        if(hasSameProduct(currentPolicy, props.policy)){
          setShowGettingActivePolicies(false)
          console.log('found duplicate active policy: ', currentPolicy.product_name, props.policy.product_name);
          
          return setShowActivePolicyFoundErrorAlert(true)
      }
    }
    }
    
    setShowGettingActivePolicies(false)
    props.approvePolicy() 

    return response
  }



  useEffect(()=>{
    if(props.updateAction){
      setShowWarning(true)
    }

  }, [props.updateAction])

  const handlePolicyApprovedSuccessfully = () => {
    reloadPage()
  }

  const handlePolicyApprovalError = () => {
    reloadPage()
  }


  const warningMessage = {
    Approve: `Are you sure you want to Approve policy  ${props.policyNumber}`,
    Discard: `Are you sure you want to Discard policy  ${props.policyNumber}`,
    ReConfirm: `Are you sure you want to send policy  ${props.policyNumber} for Re Confirmation`,
  }

  const progressMessage = {
    Approve: `Approving policy number ${props.policyNumber}...`,
    Discard: `Discarding policy number ${props.policyNumber}...`,
    ReConfirm: `Sending  policy number ${props.policyNumber} for Re Confirmation...`
  }

  const successMessage = {
    Approve: `Policy ${props.policyNumber} approved successully`,
    Discard: `Policy ${props.policyNumber} discarded successully`,
    ReConfirm: `Policy ${props.policyNumber} sent for reconfirmation`,
  }

  const errorMessage = {
    Approve: `Error approving policy ${props.policyNumber}.`,
    Discard: `Error discarding policy ${props.policyNumber}.`,
    ReConfirm: `Error sending  policy ${props.policyNumber} for Re Confirmation.`
  }

  return (
    <>

      <AlertDialog
        warning
        show={showWarning}
        size="sm"
        title={warningMessage[props.updateAction]}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { setShowWarning(false) }}
        onCancel={() => { 
          // props.approvePolicy() 
          setShowWarning(false)
          fetchActivePolicies()
        }}
        confirmBtnText={'Cancel'}
        showCancel={true}
        showConfirm={true}
        cancelBtnText={props.updateAction}
        confirmBtnCssClass
      >
      </AlertDialog>


      <AlertDialog
        show={showActivePolicyFoundErrorAlert}
        danger
        title={'Can not have two active Policies'}
        onConfirm={() => handlePolicyApprovalError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        <p>There is an activie policy for this same product,</p>
        <p>before approving this policy and making it active</p>
        <p>please cancel the previous active policy</p>
      </AlertDialog>

      <AlertDialog
        custom
        show={showGettingActivePolicies}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Checking for active policies of the same product type</h2>
      </AlertDialog>


      <AlertDialog
        custom
        show={props.showApprovePolicyProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>{progressMessage[props.updateAction]}</h2>
      </AlertDialog>


      <AlertDialog
        success
        show={props.showApprovePolicySuccessAlert}
        size="sm"
        title={successMessage[props.updateAction]}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handlePolicyApprovedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'Cancel'}
        confirmBtnCssClass
      >
        <div >
          <ButtonInc variant="contained" onClick={() => { handlePolicyApprovedSuccessfully() }} style={{ background: 'green', }}>Ok</ButtonInc>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showApprovePolicyErrorAlert}
        danger
        title={errorMessage[props.updateAction]}
        onConfirm={() => handlePolicyApprovalError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.approvePolicyError ? props.approvePolicyError.message : ''}
      </AlertDialog>

    </>
  )
}

export default ApprovePolicy