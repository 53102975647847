import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';

export class NoRecord extends React.Component {
  render() {
    return (
      <center>
        <h4>{this.props.noTextLabel}</h4>
        <Link to={this.props.redirectTo}>
          <button className="btn btn-info">{this.props.searchLabel}</button>
        </Link>
      </center>
    );
  }
}

export default connect(state => ({ globalData: state.global }))(NoRecord);
