import React, { useEffect } from 'react'
import ClaimsCardInc from '../../../../../../../shared-ui/ClaimsCardInc';


const Claims = (props) => {


  useEffect(() => {
    props.getClaims();
  }, [])
  
  useEffect(() => {
    props.getCustomerPolicies()
  }, [])


  const filteredClaims = props.claims.filter(claim => claim.policy_guid === props.policyGuid)



    return (
      <>
     <ClaimsCardInc
     
     data={filteredClaims}
     loader={props.claimsloader}
     
     />
    
      </>
    )


}

export default Claims