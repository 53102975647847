import * as types from '../../lib/actionTypes.es6';

export function reportRefundRequest(reportType) {
  return {
    type: types.REPORT_REFUND_REQUEST,
    payload: reportType,
  };
}

export function reportRefundSuccess(reportURL) {
  return {
    type: types.REPORT_REFUND_SUCCESS,
    payload: reportURL,
  };
}

export function reportRefundFailure(error) {
  return {
    type: types.REPORT_REFUND_FAILURE,
    payload: error,
  };
}

export function reportRefundReset() {
  return {
    type: types.REPORT_REFUND_RESET,
  };
}
