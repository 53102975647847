import * as React from "react";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import AlertDialog from "../AlertDialog/index.es6";


export default function DeleteBeneficiaryDetails(props) {

  if (!props.beneficiaryDetailstodelete) {
    return ""
  }

  const [showWarning, setShowWarning ] = React.useState(false);


  const deleteBeneficiaryDetails = ()=>{
    setShowWarning(true) 
    props.deleteBeneficiaryDetails(props.beneficiaryDetailstodelete)
  }

  const cancel = ()=>{
    props.resetBeneficiaryDetailsToDelete()
    
  }


  const handleBeneficiaryDetailsDeletedSuccessfully = () => {
    props.resetDeleteBeneficiarySuccessAlert()
    props.resetDeleteBeneficiaryErrorAlert()
  }

  const handleBeneficiaryDetailsDeleteError = () => {
    props.resetDeleteBeneficiaryErrorAlert()
  }

  return (
    <React.Fragment>
      <AlertDialog
        custom
        show={props.showDeleteBeneficiaryDetailsProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Deleting Beneficiary Details</h2>
      </AlertDialog>


      <AlertDialog
        warning
        show={!showWarning && props.beneficiaryDetails}
        size="sm"
        title={'Are you sure you want to delete'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { cancel()} }
        onCancel={() => { deleteBeneficiaryDetails()}}
        confirmBtnText={'Cancel'}
        showCancel={true}
        showConfirm={true}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
      </AlertDialog>

      <AlertDialog
        success
        show={props.showDeleteBeneficiaryDetailsSuccessAlert}
        size="sm"
        title={'Beneficiary Details deleted'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleBeneficiaryDetailsDeletedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => { handleBeneficiaryDetailsDeletedSuccessfully() }} style={{ background: 'green', }}>Ok</Button>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showDeleteBeneficiaryDetailsErrorAlert}
        danger
        title={'Error deleting beneficiary Details'}
        onConfirm={() => handleBeneficiaryDetailsDeleteError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.deleteBeneficiaryDetailsError ? props.deleteBeneficiaryDetailsError.message : ''}
      </AlertDialog>

    </React.Fragment>

  );



}
