import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import AlertDialog from "../../../../../components/AlertDialog/index.es6";
import { CircularProgress, Switch } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { COVER_TYPES, PRODUCT_TYPES, PRODUCT_TYPES_FREE } from "../../../PoductConstants";
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../../../lib/constants";

let initialState = {
  name: '',
  type: '',
  code: '',
  cover_type: '',
  loyalty_premium_in_cents: '',
  tag: '',
  minimum_age: '',
  maximum_age: '',
  waiting_period_days: '',
  lapse_period_days: '',
  cool_off_period_hours: '',
  eligibility_threshold: '',
  debit_grace_days: '',
  notes:'',
}


export default function EditProduct(props) {
  const {product} = props;

  const [
    { name,
      type,
      code,
      cover_type,
      loyalty_premium_in_cents,
      tag,
      minimum_age,
      maximum_age,
      waiting_period_days,
      lapse_period_days,
      cool_off_period_hours,
      eligibility_threshold,
      debit_grace_days,
      active,
      notes,
    }, setState
  ] = React.useState(initialState)

  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const toggleActive = e => {
    setState(prevState => ({ ...prevState, active: e.target.checked }));
  };

  React.useEffect(() => {
    if (!product) {
      return;
    }

    Object.keys(product).forEach(name => {
      const value = product[name];
      setState(prevState => ({ ...prevState, [name]: value }));
    });

    return function cleanup() {};
  }, [product]);


  React.useEffect(() => {
    ValidatorForm.addValidationRule('isValidProductCode', (value) => {
      return !value.includes(" ")
    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidProductCode');
    }
  });


  const submit = () => {
    const payload = {
      name,
      type,
      code,
      cover_type,
      tag,
      minimum_age: Number(minimum_age),
      maximum_age: Number(maximum_age),
      waiting_period_days: Number(waiting_period_days),
      lapse_period_days: Number(lapse_period_days),
      cool_off_period_hours: Number(cool_off_period_hours),
      eligibility_threshold: Number(eligibility_threshold),
      debit_grace_days: Number(debit_grace_days),
      partnerGuid: 'equityguid',
      active,
      product_guid: props.product.guid,
      notes,
    }
    if (PRODUCT_TYPES_FREE.includes(type)) {
      payload['loyalty_premium_in_cents'] = loyalty_premium_in_cents
    }
    props.editProduct(payload)
  }



  const reset = () => {
    setState(initialState);
  }

  const handleProductUpdatedSuccessfully = () => {
    props.resetEditProductSuccessAlert()
    props.closeEditProductDialog()
  }

  const handleProductUpdateError = () => {
    props.resetEditProductErrorAlert()
  }

  return (
    <>
      <AlertDialog
        custom
        show={props.showEditProductProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>updating product</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showEditProductSuccessAlert}
        size="sm"
        title={'Product updated'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleProductUpdatedSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>

      <AlertDialog
        show={props.showEditProductErrorAlert}
        danger
        title={'Error updating product'}
        onConfirm={() => handleProductUpdateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.editProductError ? props.editProductError.message : ''}
      </AlertDialog>


      <Dialog open={props.showEditProductDialog} fullWidth maxWidth='lg' fullScreen onClose={props.closeEditProductDialog}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.closeEditProductDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Product
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div style={{ marginTop: "2%" }}>
            <Box sx={{ width: "100%" }}>
              <ValidatorForm onSubmit={() => submit()} >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <label>Active</label>
                    <Switch
                      checked={active}
                      onChange={toggleActive}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}><TextValidator
                    onChange={onChange}
                    label={'product name'}
                    value={name}
                    name='name'
                    fullWidth
                    variant="outlined"
                    type="text"
                    placeholder='name'
                    validators={['required']}
                    errorMessages={['Please enter product name']}
                  />
                  </Grid>

                  <Grid item lg={3} md={6} xs={12}><TextValidator
                    onChange={onChange}
                    label='product code'
                    name='code'
                    value={code}
                    fullWidth
                    variant="outlined"
                    type="text"
                    placeholder='code'
                    validators={['required', 'isValidProductCode']}
                    errorMessages={['Please enter aproduct code', 'should not contain spaces']}
                  /> </Grid>

                  <Grid item lg={3} md={6} xs={12}>
                    <SelectValidator label='product type'
                    id="product type"
                                     fullWidth
                    value={type}
                    name='type'
                    onChange={onChange}
                    className="form-control"
                    type="text"
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Please select type']}
                  >
                    <MenuItem value="">{'Please select type'}</MenuItem>
                    {
                      PRODUCT_TYPES.map((type) => {
                        return <MenuItem value={type}>{type}</MenuItem>
                      })
                    }
                  </SelectValidator> </Grid>
                  <Grid item lg={3} md={6} xs={12}> <SelectValidator
                    value={cover_type}
                    label='cover type'
                    id="cover type"
                    name='cover_type'
                    onChange={onChange}
                    className="form-control"
                    type="text"
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Please select cover type']}
                    fullWidth
                  >
                    <MenuItem value="">{'Please select cover type'}</MenuItem>
                    {
                      COVER_TYPES.map((type) => {
                        return <MenuItem value={type}>{type}</MenuItem>
                      })
                    }
                  </SelectValidator> </Grid>


                  {
                    ['', 'Free', 'Free_Agent'].includes(type) &&
                    <Grid item lg={3} md={6} xs={12}><TextValidator
                      onChange={onChange}
                      value={loyalty_premium_in_cents}
                      label='loyalty premium in cents'
                      name='loyalty_premium_in_cents'
                      fullWidth
                      variant="outlined"
                      type="text"
                      placeholder='amount'
                      validators={['required']}
                      errorMessages={['Please enter loyalty premium in cents']}
                    /> </Grid>
                  }


                  <Grid item lg={3} md={6} xs={12}><TextValidator
                    onChange={onChange}
                    value={tag}
                    label='product tag'
                    name='tag'
                    fullWidth
                    variant="outlined"
                    type="text"
                    placeholder='amount'
                  />
                  </Grid>


                  <Grid item lg={3} md={6} xs={12}><TextValidator
                    onChange={onChange}
                    value={minimum_age}
                    label='minimum age'
                    name='minimum_age'
                    fullWidth
                    variant="outlined"
                    type="number"
                    placeholder='minimum age'
                    validators={['required']}
                    errorMessages={['Please enter minimum age']}
                  /></Grid>
                  <Grid item lg={3} md={6} xs={12}><TextValidator
                    onChange={onChange}
                    value={maximum_age}
                    label='maximum age'
                    name='maximum_age'
                    fullWidth
                    variant="outlined"
                    type="number"
                    placeholder='maximum age'
                    validators={['required']}
                    errorMessages={['Please enter maximum age']}
                  /></Grid>
                  <Grid item lg={3} md={6} xs={12}><TextValidator
                    onChange={onChange}
                    value={waiting_period_days}
                    label='waiting period'
                    name='waiting_period_days'
                    fullWidth
                    variant="outlined"
                    type="number"
                    placeholder='waiting period'
                    validators={['required']}
                    errorMessages={['Please enter product waiting period']}
                    aria-describedby="standard-weight-helper-text"
                    InputProps={{
                      endAdornment: (<InputAdornment position="end">
                        days
                      </InputAdornment>),

                      'aria-label': 'weight',
                    }}
                  /></Grid>
                  <Grid item lg={3} md={6} xs={12}><TextValidator
                    onChange={onChange}
                    value={lapse_period_days}
                    label='lapse period'
                    name='lapse_period_days'
                    fullWidth
                    variant="outlined"
                    type="number"
                    placeholder='lapse period in days'
                    validators={['required']}
                    errorMessages={['Please enter product lapse period']}
                    aria-describedby="standard-weight-helper-text"
                    InputProps={{
                      endAdornment: (<InputAdornment position="end">
                        days
                      </InputAdornment>),

                      'aria-label': 'weight',
                    }}
                  /></Grid>
                  <Grid item lg={3} md={6} xs={12}><TextValidator
                    onChange={onChange}
                    value={cool_off_period_hours}
                    label='cool off period'
                    name='cool_off_period_hours'
                    fullWidth
                    variant="outlined"
                    type="number"
                    placeholder='cool off period'
                  /></Grid>

                  {
                    PRODUCT_TYPES_FREE.includes(type) &&
                    <Grid item lg={3} md={6} xs={12}><TextValidator
                      onChange={onChange}
                      value={eligibility_threshold}
                      label='eligibility threshold'
                      name='eligibility_threshold'
                      fullWidth
                      variant="outlined"
                      type="number"
                      placeholder='eligibility threshold'
                      validators={['required']}
                      errorMessages={['Please enter eligibility threshold']}
                    /></Grid>
                  }

                  <Grid item lg={3} md={6} xs={12}><TextValidator
                    onChange={onChange}
                    value={debit_grace_days}
                    label='debit grace days'
                    name='debit_grace_days'
                    fullWidth
                    variant="outlined"
                    type="number"
                    placeholder='debit grace days'
                    validators={['required']}
                    errorMessages={['Please enter product debit grace days']}
                  /></Grid>
                  <Grid item lg={3} md={3} xs={12}>
                    <TextValidator
                      onChange={onChange}
                      value={notes}
                      label='notes'
                      name='notes'
                      fullWidth
                      variant="outlined"
                      rows={5}
                      multiline={true}
                      placeholder='notes'
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant="contained" type="submit" style={{ marginRight: '2%' }}> Update product </Button>
                    <Button variant="contained" color="secondary" style={{ marginRight: '2%' }}
                      onClick={() => reset()}
                    > Reset</Button>
                  </Grid>

                </Grid>
              </ValidatorForm>
            </Box>
          </div>

        </DialogContent>
      </Dialog>




    </>
  );
}
