import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../../../../lib/actionTypes.es6'
import * as actions from './actions';

import { getCustomerQuotes} from '../../../../../../lib/requests.es6';
import { GAloggerException } from '../../../../../../lib/monitoring.es6';
import { unAuthorizedNotification } from '../../../../../../lib/access.es6';




export function* NGSGroupCustomerQuotesRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_NGSGROUP_CUSTOMER_QUOTES_REQUEST);
    try {
      const response = yield call(getCustomerQuotes, request.payload);
      yield put(actions.getNGSGroupCustomerQuotesSuccess(response.data));
    
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getNGSGroupCustomerQuotesFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

