import * as types from '../../../../../../lib/actionTypes.es6';

export function getAllOrangeCustomerClaimsRequest(msisdn) {
  return {
    type: types.GET_ALL_ORANGE_CUSTOMER_CLAIMS_REQUEST,
    payload: msisdn
  };
}

export function getAllOrangeCustomerClaimsSuccess(claims) {
  return {
    type: types.GET_ALL_ORANGE_CUSTOMER_CLAIMS_SUCCESS,
    payload: claims,
  };
}

export function getAllOrangeCustomerClaimsFail(response) {
  return {
    type: types.GET_ALL_ORANGE_CUSTOMER_CLAIMS_FAILURE,
    payload: response,
  };
}