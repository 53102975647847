import React, { useState } from 'react'
import ModalInc from '../../../../../shared-ui/ModalInc'
import ButtonInc from '../../../../../shared-ui/ButtonInc'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress } from '@mui/material';
const EditClaimDetails = (props) => {

  if (!props.expenseToEdit) {
    return ""
  }

  const [description, setDescription] = useState(props.expenseToEdit.description);
  const [amountInCents, setAmountInCents] = useState(props.expenseToEdit.amount_in_cents);

  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === "description") {
      setDescription(value)
    }
    if (name === "amountInCents") {
      setAmountInCents(value)
    }


  }
  const reset = () => {
    setDescription('');
    setAmountInCents('');
  }
  const handleEditClaimExpense = () => {
    const payload = {
      "description": description,
      "amount_in_cents": Number(amountInCents) * 100,
      "expense_guid": props.expenseToEdit.guid,
      "claim_guid": props.claimguid
    }
    console.log("edit expense payload", payload);
    props.editClaimExpense(payload)
    reset()
  }
  const handleExpenseEditedSuccessfully = () => {
    props.resetEditExpenseSuccessAlert()
    props.resetExpenseToEdit();
    reset();
    window.location.reload();
  }
  const handleExpenseEditError = () => {
    props.resetEditExpenseErrorAlert()
  }
  return (
    <>
      <AlertDialog
        custom
        show={props.showEditExpenseProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Updating claim expense...</h2>
      </AlertDialog>
      <AlertDialog
        success
        show={props.showEditExpenseSuccessAlert}
        size="sm"
        title={'claim expense updated'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleExpenseEditedSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
        <div >
          <ButtonInc variant="contained" onClick={() => { handleExpenseEditedSuccessfully() }} style={{ background: 'green', }}>Ok</ButtonInc>
        </div>
      </AlertDialog>
      <AlertDialog
        show={props.showEditExpenseErrorAlert}
        danger
        title={'Error updating claim expense'}
        onConfirm={() => handleExpenseEditError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {"Error updating claim expense"}
      </AlertDialog>
      <ModalInc
        modalTitle="Update Claim Expense"
        subTitle="Fill the below fields to edit claim Expenses"
        open={props.openEditClaimExpenseDialog}
        onClose={props.toggleEditClaimExpenseDialog}
        fullWidth

      >
        <ValidatorForm onSubmit={() => handleEditClaimExpense()} >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <TextValidator
                name='description'
                id="description"
                label="Expense Description"
                value={description}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter description']}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextValidator
                name='amountInCents'
                id="amountInCents"
                label="Amount"
                type="number"
                value={amountInCents}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter Amount']}
              />
            </Grid>

          </Grid>
       
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} md={6}>

            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    type='submit'
                    style={{ marginTop: "20px", float: "right" }}
                  >
                    Update Claim Expense
                  </ButtonInc>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginTop: "20px" }}
                    onClick={props.toggleEditClaimExpenseDialog}
                  >
                    Cancel
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </ModalInc>
    </>
  )
}

export default EditClaimDetails