import React, { useState } from 'react'
import ModalInc from '../../../shared-ui/ModalInc'
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { Grid } from '@mui/material';
import { useStyle } from "./Style";
import ButtonInc from '../../../shared-ui/ButtonInc';
import DropzoneS3Uploader from "react-dropzone-s3-uploader";
import { getPartnerGuid } from '../../../lib/access.es6';
import ProgressState from './ProgressState/ProgressState';
import { getS3BucketSignedUrlForEmailTemplates } from '../../../lib/requests.es6';
import { emailTypesEntity,emailTypes } from '../../../lib/constants';
import MenuItem from '@mui/material/MenuItem';
const AddEmailTemplate = (props) => {
  const classes = useStyle();

  const [s3Url] = useState('https://s3.eu-west-1.amazonaws.com/partner-email-templates');
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null)
  const [done, setDone] = useState(false)
  const [error, setError] = useState('')

  const [templateName, setTemplateName] = useState('');
  const [templateCode, setTemplateCode] = useState('');
  const [subject, setSubject] = useState('');
  const [emailEntityType, setEntityEmailType] = useState('');
  const [recipients, setRecipients] = useState('');
  const [emailType, setEmailType] = useState('');
  const [subjectEntityCode, setSubjectEntityCode] = useState('')


  const handleChange = (event) => {
    const { name, value } = event.target
    if (name === "templateName") {
      setTemplateName(value)
    }
    if (name === "templateCode") {
      setTemplateCode(value)
    }
    if (name === "subject") {
      setSubject(value)
    }
    if (name === "emailEntityType") {
      setEntityEmailType(value)
    }
    if (name === "recipients") {
      setRecipients(value)
    }
    if (name === "emailType") {
      setEmailType(value)
    }
    if (name === "subjectEntityCode") {
      setSubjectEntityCode(value)
    }

  }

  const handleFinishedUpload = (info) => {
    console.log("info", info)

    const payload = {
      "partner_guid": getPartnerGuid(),
       "template_url": info.fileUrl.replace('undefined', info.bucketKey),
      "filename":  info.bucketKey,
      "name": templateName,
      "active": true,
      "code": templateCode,
      "subject": subject,
      "email_entity_type": emailEntityType,
      "system_generated": false,
      "expect_a_reply": false,
      "recipients": recipientsArray,
      "email_type": emailType,
      "subject_entity_code": subjectEntityCode
    
    }

    console.log("infoafterupload", payload)
    props.saveEmailTemplate(payload);
    setDone(true)
    
  }
  const recipientsArray = recipients.split(',').map(email => email.trim());

  const getS3Ur = ( file, callback) => getS3BucketSignedUrlForEmailTemplates(file, callback)

  const uploadOptions = {
    getSignedUrl: getS3Ur,
    s3Url: s3Url,
    uploadRequestHeaders: {},
    onProgress: (progress) => setProgress(progress),
    onError: (errorMessage) => setErrorMessage(errorMessage),

  };

  const reset = () => {
    setProgress(0)
    window.location.reload();
  }
  //const hasAllfieldsFilled = templateName && templateCode && subject && emailEntityType && systemGenerated && expectReply && recipientsArray && emailType && subjectEntityCode
    const hasAllfieldsFilled = true


  return (
    <>
      <ModalInc
        modalTitle="Upload  file"
        subTitle="Fill the below fields to upload files"
        open={props.open}
        onClose={props.close}
        fullWidth
        maxWidth="md"
      >


        <ValidatorForm onSubmit={() => {}} >
          <Grid container spacing={2}>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  name='templateName'
                  id="templateName"
                  label="Template Name"
                  value={templateName}
                  onChange={handleChange}
                  fullWidth
                  className={classes.paymentinputs}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please enter name']}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  name='templateCode'
                  id="templateCode"
                  label="Template Code"
                  value={templateCode}
                  onChange={handleChange}
                  fullWidth
                  className={classes.paymentinputs}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please enter template code']}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  name='subject'
                  id="subject"
                  label="Email subject"
                  value={subject}
                  onChange={handleChange}
                  fullWidth
                  className={classes.paymentinputs}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please enter subject']}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <SelectValidator
                  name='emailEntityType'
                  labelId="emailEntityType"
                  id="emailEntityType"
                  value={emailEntityType}
                  label="Email Entity Type"
                  onChange={handleChange}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please select email entity type']}
                  style={{ width: "100%" }}
                >
                  {
                    emailTypesEntity.map((type, index) => {
                      return <MenuItem
                        sx={{
                          fontFamily: "Open Sans",
                          fontStyle: "normal",
                          fontWeight: 300,
                          fontSize: "12px",
                          lineHeight: "16px",
                          letterSpacing: "0.005em",
                          color: "#000000",
                        }}
                        key={index}
                        value={type.value}>
                        {type.label}
                      </MenuItem>
                    })
                  }

                </SelectValidator>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <SelectValidator
                  name='emailType'
                  labelId="emailType"
                  id="emailType"
                  value={emailType}
                  label="Email Type"
                  onChange={handleChange}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please select email type']}
                  style={{ width: "100%" }}
                >
                  {
                    emailTypes.map((type, index) => {
                      return <MenuItem
                        sx={{
                          fontFamily: "Open Sans",
                          fontStyle: "normal",
                          fontWeight: 300,
                          fontSize: "12px",
                          lineHeight: "16px",
                          letterSpacing: "0.005em",
                          color: "#000000",
                        }}
                        key={index}
                        value={type.value}>
                        {type.label}
                      </MenuItem>
                    })
                  }

                </SelectValidator>
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  name='subjectEntityCode'
                  id="subjectEntityCode"
                  label="subject Entity Code"
                  value={subjectEntityCode}
                  onChange={handleChange}
                  fullWidth
                  className={classes.paymentinputs}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please enter subject entity code']}
                />
              </Grid>

 

              <Grid item xs={12} sm={12} md={12}>
                <TextValidator
                  name='recipients'
                  id="recipients"
                  label="Recipients Emails"
                  value={recipients}
                  onChange={handleChange}
                  fullWidth
                  className={classes.paymentinputs}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please enter recipients']}
                  helperText="use comma separated values e.g 'test@email.com, test2@email.com'"
                />
              </Grid>

     


            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.uploadsection}>
              {error && <p style={{ color: 'red' }}>{error}</p>}

                <DropzoneS3Uploader
                  onFinish={handleFinishedUpload}
                  upload={uploadOptions}
                  s3Url={s3Url}
                  passChildrenProps={false}
                  {...(!hasAllfieldsFilled && { onDrop: () => setError('please fill out all other details before  uploading ') })}
                  maxSize={1024 * 1024 * 500}
                  className={classes.uploadzone}
                  style={{
                    width: "100%",
                    height: "250px",
                    background: "#FFFFFF",
                    border: "1px dashed #B8B8B8",
                    marginTop: "40px",
                    padding: " 70px 0"
                  }}
                >
                  <div >
                    <ProgressState progress={progress} errorMessage={errorMessage} />

                  </div>
                </DropzoneS3Uploader>
              </div>
            </Grid>
            {props.errror && <p style={{ color: 'red' }}>{props.errror.message}</p>}
          </Grid>

          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'center' }}>
                  <ButtonInc
                    size="large"
                    onClick={reset}
                    disabled={!done}
                    variant="contained"
                    style={{ width: "221px", height: "55px", marginTop: '10%' }}
                  > I'm  Done</ButtonInc>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={props.close}
                    style={{ width: "221px", height: "55px", background: "#FBA92D", marginTop: '10%' }}
                  >
                    Cancel
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </ModalInc>

    </>
  )
}

export default AddEmailTemplate