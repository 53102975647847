import React from 'react';
import PropTypes from 'prop-types';
import { useStyle } from "./Style";
import { hashHistory } from 'react-router';

//import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, ButtonBase, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project imports
//import LAYOUT_CONST from 'constant';
import LAYOUT_CONST from '../../constant'
import useConfig from '../../../../hooks/useConfig';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { activeID, activeItem, openDrawer } from '../../../../containers/Drawer/redux/actions';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level, parentId, selectedItem, drawerOpen, dispatch, location }) => {
    const classes = useStyle();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const [isSelected, setIsSelected] = React.useState(item && selectedItem === item.id)


    const { layout, borderRadius } = useConfig();

    React.useEffect(() => {
        setIsSelected(item && selectedItem === item.id)
        // console.log('run navIem useffect for ' + item.id);
    }, [selectedItem])

    // active menu item on page load
    React.useEffect(() => {
        const currentIndex = location.pathname.substring(1)

        setIsSelected(currentIndex === item.id)
        // dispatch(activeItem(currentIndex[currentIndex.length-1]));
    }, []);

    // console.log('navitem >  selectedItem: ' + selectedItem + ", item: " + item.id  + ",  isSelected: " + isSelected);
    // const isSelected = () => item && selectedItem  ===  item.id

    const Icon = item && item.icon;
    const itemIcon = item && item.icon ? (
        <Icon
            stroke={1.5}
            size={drawerOpen ? '20px' : '24px'}
            style={{ color: isSelected ? "#fff" : theme.palette.text.primary }}
        />
    ) : (
        <FiberManualRecordIcon
            sx={{
                color: isSelected ? "#fff" : theme.palette.text.primary,
                width: item && selectedItem === item.id ? 8 : 6,
                height: item && selectedItem === item.id ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );


    const itemHandler = (item) => {
        // console.log('');
        // console.log('');
        // console.log('starting item handler');
        // console.log('currentSelectedItem: ' + selectedItem + ", item: " + item.id);
        dispatch(activeItem(item.id));

        if(parentId){
            dispatch(activeID(parentId));
        }else{
            dispatch(activeID(''));
        }
        
        if (matchesSM) dispatch(openDrawer(false));
        // dispatch(activeID(parentId));
        hashHistory.push(item.url)

        // console.log('end item handler');
        // console.log('currentSelectedItem: ' + selectedItem + ", item: " + item.id);

        // console.log('');
        // console.log('');
    };



    const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary';
    const iconSelectedColor = theme.palette.mode === 'dark' && drawerOpen ? 'text.primary' : 'secondary.main';

    return (
        <>
            <section className={classes.menuItemsWrapper}>
                {layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
                    <ListItemButton
                        // {...listItemProps}
                        disabled={item.disabled}
                        className='xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'
                        disableRipple={!drawerOpen}
                        sx={{
                            zIndex: 1201,
                            borderRadius: `${borderRadius}px`,
                            mb: 0.5,
                            pl: drawerOpen ? `${level * 24}px` : 1.25,
                            ...(drawerOpen &&
                                level === 1 &&
                                theme.palette.mode !== 'dark' && {
                                '&:hover': {
                                    background: "#032a37",
                                    color: "#fff"
                                },
                                '&.Mui-selected': {
                                    background: "#032a37",
                                    color: "#fff",
                                    '&:hover': {
                                        background: "#032a37",
                                        color: "#fff"
                                    }
                                }
                            }),
                            ...((!drawerOpen || level !== 1) && {
                                py: level === 1 ? 0 : 1,
                                '&:hover': {
                                    bgcolor: '#032a37'
                                },
                                '&.Mui-selected': {
                                    '&:hover': {
                                        bgcolor: '#032a37'
                                    },
                                    bgcolor: '#032a37'
                                }
                            }),
                            ...((isSelected) && {
                                "& .MuiSvgIcon-root":{
                                  fill: "white"
                                },
                                "& .MuiTypography-root ":{
                                  color: "white !important"
                                },
                                "& .MuiButtonBase-root .MuiTypography-root ":{
                                  color: "white"
                                },
                               }),
                        }}
                        selected={isSelected}
                        onClick={() => itemHandler(item)}
                    >
                        <ButtonBase sx={{ borderRadius: `${borderRadius}px` }} disableRipple={drawerOpen}>
                            <ListItemIcon
                                sx={{
                                    minWidth: level === 1 ? 36 : 18,
                                    color: isSelected ? iconSelectedColor : textColor,
                                    ...(!drawerOpen &&
                                        level === 1 && {
                                        borderRadius: `${borderRadius}px`,
                                        width: 46,
                                        height: 46,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        '&:hover': {
                                            background: "#032a37",
                                            color: "#fff"
                                        },
                                        ...(isSelected && {
                                            background: "#032a37",
                                            color: "#fff",
                                            '&:hover': {
                                                background: "#032a37",
                                                color: "#fff"
                                            }
                                        })
                                    }),

                                }}
                            >
                                {itemIcon}
                            </ListItemIcon>
                        </ButtonBase>

                        {(drawerOpen || (!drawerOpen && level !== 1)) && (
                            <ListItemText
                                primary={
                                    <Typography variant='body1' color="inherit">
                                        {item.title}
                                    </Typography>
                                }
                                secondary={
                                    item.caption && (
                                        <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                            {item.caption}
                                        </Typography>
                                    )
                                }
                            />
                        )}

                        {drawerOpen && item.chip && (
                            <Chip
                                color={item.chip.color}
                                variant={item.chip.variant}
                                size={item.chip.size}
                                label={item.chip.label}
                                avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                            />
                        )}
                    </ListItemButton>
                ) : null}
            </section>
        </>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number,
    parentId: PropTypes.string,
    selectedItem: PropTypes.string,
};

export default NavItem;
