import { makeStyles } from "@material-ui/styles";

export const useStyle = makeStyles(() => ({

  customerCardWrapper: {
    height: "auto",
    background: "#FFFFFF",
    border: "0px solid #A7A7A7",
    borderRadius: "8px",
   // marginTop: "20px",
    paddingTop: "20px",
    paddingBottom: "20px",
    "& span": {
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "0.001em",
      color: "#000000"
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "11px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#A9A9A9",
      wordBreak: "break-word"
    }
  },
  customerIconWrapper: {
    padding: "10px",
  },
  customerIcons: {
    width: "30px",
    height: "30px",
    background: "#298c83",
    borderRadius: "100%",
    textAlign: "center",
    paddingTop: "5px",
    marginLeft: "auto"
  },
  pill: {
    width: "79px",
    height: "26px",
    background: "#fff",
    borderRadius: "25px",
    textAlign: "center",
    border: "1px solid #ccc",
    "& span": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "10px",
      lineHeight: "20px",
      letterSpacing: "0.001em",
      color: "#000000",
    }
  },
  viewWrapper: {
    padding: "14px 30px",
    "& ul": {
      listStyle: "none",
      display: "contents",
      marginLeft: "20px",
      marginTop: "10px",
      "& li": {
        textAlign: "center",
        fontFamily: 'Open Sans',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "20px",
        letterSpacing: "0.001em",
        color: "#000000",
        marginTop: "16px",
        display: "block",

        "&:nth-child(2)": {
          display: "inline-flex",
          marginTop: "15px",
           marginLeft: "8px",
         // marginTop: "-2px",
          fontFamily: 'Open Sans',
          fontStyle: "normal",
          fontWeight: 100,
          fontSize: "13px",
          lineHeight: "20px",
          letterSpacing: "0.001em",
          color: "#000",
          //paddingTop: "2px",
          cursor: "pointer",
          "& span": {
            marginTop: "-1px",
            color: "#000"
          }
        }
      }
    },
    "& h6": {
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      letterSpacing: "0.001em",
      color: "#000000",
      margin: "0 !important"
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#A9A9A9"
    }
  },

  policyCardWrapper: {
    height: "auto",
    background: "#FFFFFF",
    border: "0px solid #A7A7A7",
    borderRadius: "8px",
    marginTop: "20px",
    //paddingTop: "20px",
    paddingBottom: "20px",
    "& span": {
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "0.001em",
      color: "#000000"
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "11px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#A9A9A9",
      wordBreak: "break-word"
    }
  },
  policyIconWrapper: {
    padding: "10px",
  },
  policyIcons: {
    width: "30px",
    height: "30px",
    background: "#FBA92D",
    borderRadius: "100%",
    textAlign: "center",
    paddingTop: "5px",
    marginLeft: "auto"
  },

  claimCardWrapper: {
    height: "auto",
    background: "#FFFFFF",
    border: "0px solid #A7A7A7",
    borderRadius: "8px",
    marginTop: "20px",
    //paddingTop: "20px",
    paddingBottom: "20px",
    "& span": {
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "0.001em",
      color: "#000000"
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "11px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#A9A9A9",
      wordBreak: "break-word"
    }
  },
  claimIconWrapper: {
    padding: "10px",
  },
  claimIcons: {
    width: "30px",
    height: "30px",
    background: "#D56239",
    borderRadius: "100%",
    textAlign: "center",
    paddingTop: "5px",
    marginLeft: "auto"
  },
  noResultsFound:{
    "& h1":{
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "20px",
      letterSpacing: "0.001em",
      color: "#000000",
      textAlign: "center",
      marginTop: "40px"
    }
  }
}));