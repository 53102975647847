import React from 'react';
import LocalizedStrings from 'react-localization';
import { hashHistory } from 'react-router';
import { connect } from "react-redux";
import Quotes from './Components/Quotes/Quotes';
import { localisedText } from '../../../../../lib/localisation.es6';
import { getAccessBankCustomerQuotesRequest } from './Redux/actions';

class BbboxxQuotes extends React.Component {

	constructor(props) {
		const localizedStrings = new LocalizedStrings(localisedText);
		super(props);
		this.state = {
			labels: localizedStrings,

		};
	}

	componentWillMount() {
		
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      this.props.dispatch(getAccessBankCustomerQuotesRequest({ guid: currentCustomer.guid }))
    }

	}

	componentDidUpdate() {
		this.updateLanguage();
	}

	updateLanguage() {
		const language = this.props.globalData.language;
		let resetLanguage = false;
		if (!language) {
			resetLanguage = true;
		}
		const labels = this.state.labels;
		if (resetLanguage || labels.getLanguage() !== language) {
			labels.setLanguage(language);
			this.setState({});
		}
	}



	createQuote(){
		hashHistory.push('admin/create-quote');
	}



	render() {

		if (this.props.customerData.currentCustomer) {
			return (
				<>
				<Quotes
        quotes={this.props.AccessBankQuoteData.quotes}
        loader={this.props.AccessBankQuoteData.get_quotes_loader}
        />

				</>
			)
		} else {
			return ""
		}

	}
}
export default connect((state) => ({
	customerData: state.currentCustomer,
	AccessBankQuoteData: state.AccessBankQuoteData,
	globalData: state.global,
}))(BbboxxQuotes);