import * as types from '../../../lib/actionTypes.es6';

export function addPrincipalRequest(payload) {
  return {
    type: types.ADD_PRINCIPAL_REQUEST,
    payload,
  };
}

export function addPrincipalSuccess(response) {
  return {
    type: types.ADD_PRINCIPAL_SUCCESS,
    payload: response,
  };
}

export function addPrincipalFailed(error) {
  return {
    type: types.ADD_PRINCIPAL_FAILURE,
    payload: error,
  };
}

export function addDependantRequest(payload) {
  return {
    type: types.ADD_DEPENDANT_REQUEST,
    payload,
  };
}

export function addDependantSuccess(response) {
  return {
    type: types.ADD_DEPENDANT_SUCCESS,
    payload: response,
  };
}

export function addDependantFailed(error) {
  return {
    type: types.ADD_DEPENDANT_FAILURE,
    payload: error,
  };
}



// EDIT

export function editPrincipalRequest(payload) {
  return {
    type: types.EDIT_PRINCIPAL_REQUEST,
    payload,
  };
}

export function editPrincipalSuccess(response) {
  return {
    type: types.EDIT_PRINCIPAL_SUCCESS,
    payload: response,
  };
}

export function editPrincipalFailed(error) {
  return {
    type: types.EDIT_PRINCIPAL_FAILURE,
    payload: error,
  };
}

export function editDependantRequest(payload) {
  return {
    type: types.EDIT_DEPENDANT_REQUEST,
    payload,
  };
}

export function editDependantSuccess(response) {
  return {
    type: types.EDIT_DEPENDANT_SUCCESS,
    payload: response,
  };
}

export function editDependantFailed(error) {
  return {
    type: types.EDIT_DEPENDANT_FAILURE,
    payload: error,
  };
}


// DELETE

export function deletePrincipalRequest(payload) {
  return {
    type: types.DELETE_PRINCIPAL_REQUEST,
    payload,
  };
}

export function deletePrincipalSuccess(response) {
  return {
    type: types.DELETE_PRINCIPAL_SUCCESS,
    payload: response,
  };
}

export function deletePrincipalFailed(error) {
  return {
    type: types.DELETE_PRINCIPAL_FAILURE,
    payload: error,
  };
}

export function deleteDependantRequest(payload) {
  return {
    type: types.DELETE_DEPENDANT_REQUEST,
    payload,
  };
}

export function deleteDependantSuccess(response) {
  return {
    type: types.DELETE_DEPENDANT_SUCCESS,
    payload: response,
  };
}

export function deleteDependantFailed(error) {
  return {
    type: types.DELETE_DEPENDANT_FAILURE,
    payload: error,
  };
}

export function getAllTemporaryMemberRequest(payload) {
  return {
    type: types.GET_ALL_TEMP_MEMBERS_REQUEST,
    payload,
  };
}

export function getAllTemporaryMemberSuccess(response) {
  return {
    type: types.GET_ALL_TEMP_MEMBERS_SUCCESS,
    payload: response,
  };
}

export function getAllTemporaryMemberFailed(error) {
  return {
    type: types.GET_ALL_TEMP_MEMBERS_FAILURE,
    payload: error,
  };
}

export function uploadExcelMembersRequest(payload) {
  return {
    type: types.UPLOAD_EXCEL_MEMBERS_REQUEST,
    payload,
  };
}

export function uploadExcelMembersSuccess(response) {
  return {
    type: types.UPLOAD_EXCEL_MEMBERS_SUCCESS,
    payload: response,
  };
}

export function uploadExcelMembersFailed(error) {
  return {
    type: types.UPLOAD_EXCEL_MEMBERS_FAILURE,
    payload: error,
  };
}


export function resetExcelFileUpload() {
  return {
    type: types.RESET_EXCEL_FILE_UPLOAD,
  };
}

export function resetAddEditMembers() {
  return {
    type: types.RESET_ADD_EDIT_MEMBERS,
  };
}

