import { take, call, put } from 'redux-saga/effects';
import { getReportPremiumDueBlob } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import * as types from '../../lib/actionTypes.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

export function* reportsPremiumDueRequestWatcher() {
  for (;;) {
    const reportAction = yield take(types.REPORT_PREMIUM_DUE_REQUEST);
    try {
      const response = yield call(
        getReportPremiumDueBlob,
        reportAction.payload
      );
      yield put(actions.reportPremiumDueRequestSuccess(response.data));
    } catch (e) {
      yield put(
        actions.reportPremiumDueRequestError(
          'An error occurred, this could be due to invalid data'
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
