import React from "react";
import LocalizedStrings from 'react-localization';
import { connect } from "react-redux";
import EditUser from "./EditUser";
import { localisedText } from "../../../../../lib/localisation.es6";
import { resetAccessBankUpdateCustomer, updateAccessBankCustomerRequest } from "../RegisterCustomer/redux/actions";
import { getUsersRequest } from "../../../../../containers/Users/actions.es6";

class AccessBankEditUser extends React.Component {
  
  constructor(props) {
    const localizedStrings = new LocalizedStrings(localisedText);
    super(props);
    this.state = {
      labels: localizedStrings,
    }
  }


  componentWillMount() {
    this.updateLanguage();
    this.props.dispatch(getUsersRequest());
  }
  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    const language = this.props.globalData.language;
    let resetLanguage = false;
    if (!language) {
      resetLanguage = true;
    }
    const labels = this.state.labels;
    if (resetLanguage || labels.getLanguage() !== language) {
      labels.setLanguage(language);
      this.setState({});
    }
  }

  editCustomer(payload) {
		this.props.dispatch(updateAccessBankCustomerRequest(payload))
	}
	resetAfterSucesfulyEdit() {
		this.props.dispatch(resetAccessBankUpdateCustomer());
	}

	resetAfterFailedEdit() {
		this.props.dispatch(resetAccessBankUpdateCustomer());
	}


  render() {

    let agentUsersData = this.props.userData.users.filter(user => user.attributes.isAgent && user.attributes.isAgent[0] === 'true') || [];

      return (
        <>
        <EditUser
        labels={this.state.labels}
        openEditCustomerDialog={this.props.openEditCustomerDialog}
        toggleEditCustomerDialog={this.props.toggleEditCustomerDialog}
        customerData={this.props.customerData.currentCustomer}
        agentUsersData={agentUsersData }

        editCustomer={this.editCustomer.bind(this)}
        loader={this.props.AccessBankRegistrationData.edit_loader}
        customerEditedSuccessfully={this.props.AccessBankRegistrationData.customer_edited_succesfully}
        customerEditedFailed={this.props.AccessBankRegistrationData.customer_edited_failed}
        resetAfterSucesfulyEdit={this.resetAfterSucesfulyEdit.bind(this)}
        resetAfterFailedEdit={this.resetAfterFailedEdit.bind(this)}
        />
        </>
      )
  

  }

}

export default connect((state) => ({
  customerData: state.currentCustomer,
  AccessBankRegistrationData: state.AccessBankRegistrationData,
  userData: state.userAdmin,
  globalData: state.global,
}))(AccessBankEditUser);