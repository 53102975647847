import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProductsHeader from "../../../NewProductList/components/ProductsHeader/ProductsHeader";
import { Chip, CircularProgress } from "@mui/material";
import { hasAuthourity } from "../../../../lib/access.es6";

export default function ViewPartnerConfig(props) {
  if (props.isLoading) {
    return <div style={{ textAlign: 'center' }}>
      <CircularProgress />
    </div>
  }

  if (!props.partnerConfig) {
    return "";
  }



  const [expandedPartnerConfigs, setExpandedPartnerConfigs] = React.useState(false)

  const partner = props.partner;


  const partnerConfigurations = props.partnerConfig

  return (
    <div style={{ marginTop: "3%" }}>

      <>

        {/* Partner Configurations*/}
        <Accordion expanded={expandedPartnerConfigs}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon onClick={() => { setExpandedPartnerConfigs(!expandedPartnerConfigs) }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <ProductsHeader
              title={"Partner Configurations"}
              showEditButton={true}
              onEditButtonClick={props.openUpdatePartnerConfigDialog}
            />
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper} style={{ marginTop: "2%" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table-cell-header">
                      Property
                    </TableCell>
                    <TableCell align="left" className="table-cell-header">
                      Value
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>sms active</strong>
                    </TableCell>
                    <TableCell align="left">{partnerConfigurations.sms_active ? 'true' : 'false'}</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>need eligibility in_status</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partnerConfigurations.need_eligibility_in_status ? "true" : "false"}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>policy subscriptions type</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partnerConfigurations.policy_subscription_type}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>policy scheme type</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partnerConfigurations.policy_scheme}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>callback active</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partnerConfigurations.callback_active ? "true" : "false"}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>can edit debit</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partnerConfigurations.can_edit_debit ? "true" : "false"}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>ussd active</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partnerConfigurations.ussd_active ? "true" : "false"}
                    </TableCell>
                  </TableRow>


                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>throw sms errors</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partnerConfigurations.throw_sms_errors ? "true" : "false"}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>can debit</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partnerConfigurations.can_debit ? "true" : "false"}
                    </TableCell>
                  </TableRow>


                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>mobile_payment_popup_active</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partnerConfigurations.mobile_payment_popup_active ? "true" : "false"}
                    </TableCell>
                  </TableRow>


                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>throw sms errors</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partnerConfigurations.throw_sms_errors ? "true" : "false"}
                    </TableCell>
                  </TableRow>


                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>pull kyc</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partnerConfigurations.pull_kyc ? "true" : "false"}
                    </TableCell>
                  </TableRow>


                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>trigger 1st premium on sale</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partnerConfigurations.trigger_1st_premium_on_sale ? "true" : "false"}
                    </TableCell>
                  </TableRow>


                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>can pay claim</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partnerConfigurations.can_pay_claim ? "true" : "false"}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>money transaction active</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partnerConfigurations.money_transaction_active ? "true" : "false"}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>irregular payment enabled</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partnerConfigurations.irregular_payment_enabled ? "true" : "false"}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>check loyalty during registration enabled</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partnerConfigurations.check_loyalty_during_registration_enabled ? "true" : "false"}
                    </TableCell>
                  </TableRow>


                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>partial premium payment enabled</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partnerConfigurations.partial_premium_payment_enabled ? "true" : "false"}
                    </TableCell>
                  </TableRow>


                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>display beneficiary enabled</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partnerConfigurations.display_beneficiary_enabled ? "true" : "false"}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>email active</strong>
                    </TableCell>
                    <TableCell align="left">{partnerConfigurations.email_active ? 'true' : 'false'}</TableCell>
                  </TableRow>



                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>sms limit active</strong>
                    </TableCell>
                    <TableCell align="left">
                      {partner.sms_limit_active ? "true" : "false"}
                    </TableCell>
                  </TableRow>



                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>sms_hourly_limit_count</strong>
                    </TableCell>
                    <TableCell align="left">{partnerConfigurations.sms_hourly_limit_count}</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>customer fields</strong>
                    </TableCell>
                    <TableCell align="left">{
                      props.formatAndConvertToListString(partnerConfigurations.customer_fields).map(i => <Chip label={i} style={{ marginRight: '1%' }} />)
                    }</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>policy fields</strong>
                    </TableCell>
                    <TableCell align="left">{
                      props.formatAndConvertToListString(partnerConfigurations.policy_fields).map(field => <Chip label={field} style={{ marginRight: '1%' }} />)
                    }</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>email whitelist</strong>
                    </TableCell>
                    <TableCell align="left">{
                      props.formatAndConvertToListString(partnerConfigurations.email_whitelist).map(field => <Chip label={field} style={{ marginRight: '1%' }} />)
                    }</TableCell>
                  </TableRow>


                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>registration fields</strong>
                    </TableCell>
                    <TableCell align="left">{
                      props.formatAndConvertToListString(partnerConfigurations.registration_fields).map(field => <Chip label={field} style={{ marginRight: '1%' }} />)
                    }</TableCell>
                  </TableRow>




                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>money transaction whitelist</strong>
                    </TableCell>
                    <TableCell align="left">{
                      props.formatAndConvertToListString(partnerConfigurations.money_transaction_whitelist).map(field => <Chip label={field} style={{ marginRight: '1%' }} />)
                    }</TableCell>
                  </TableRow>





                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>ussd_whitelist</strong>
                    </TableCell>
                    <TableCell align="left">{
                      props.formatAndConvertToListString(partnerConfigurations.ussd_whitelist).map(field => <Chip label={field} style={{ marginRight: '1%' }} />)
                    }</TableCell>
                  </TableRow>


                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>monitoring sms list</strong>
                    </TableCell>
                    <TableCell align="left">{
                      props.formatAndConvertToListString(partnerConfigurations.monitoring_sms_list).map(field => <Chip label={field} style={{ marginRight: '1%' }} />)
                    }</TableCell>
                  </TableRow>



                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>sms whitelist</strong>
                    </TableCell>
                    <TableCell align="left">{
                      props.formatAndConvertToListString(partnerConfigurations.sms_whitelist).map(field => <Chip label={field} style={{ marginRight: '1%' }} />)
                    }</TableCell>
                  </TableRow>


                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>web display fields</strong>
                    </TableCell>
                    <TableCell align="left">{
                      props.formatAndConvertToListString(partnerConfigurations.web_display_fields).map(field => <Chip label={field} style={{ marginRight: '1%' }} />)
                    }</TableCell>
                  </TableRow>




                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>debit policy cancellation email</strong>
                    </TableCell>
                    <TableCell align="left">{partnerConfigurations.debit_policy_cancellation_email}</TableCell>
                  </TableRow>



                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>loyalty fields</strong>
                    </TableCell>
                    <TableCell align="left">{
                      props.formatAndConvertToListString(partnerConfigurations.loyalty_fields).map(field => <Chip label={field} style={{ marginRight: '1%' }} />)
                    }</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>partial payment backdated</strong>
                    </TableCell>
                    <TableCell align="left">{
                      partnerConfigurations.partial_payment_backdated ? 'true' : 'false'
                    }</TableCell>
                  </TableRow>


                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>can purchase policy via ui</strong>
                    </TableCell>
                    <TableCell align="left">{
                      partnerConfigurations.can_purchase_policy_via_ui ? 'true' : 'false'
                    }</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>can purchase loyalty policy</strong>
                    </TableCell>
                    <TableCell align="left">{
                      partnerConfigurations.can_purchase_loyalty_policy ? 'true' : 'false'
                    }</TableCell>
                  </TableRow>


                  {
                    hasAuthourity('READ_POWER_BI_DASHBOARD_URLS')
                    &&
                    <>
                      <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <strong>Power BI dashboard URL 1</strong>
                        </TableCell>
                        <TableCell align="left">{
                          partnerConfigurations.power_bi_dashboard_url_1
                        }</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <strong>Power BI dashboard URL 2</strong>
                        </TableCell>
                        <TableCell align="left">{
                          partnerConfigurations.power_bi_dashboard_url_2
                        }</TableCell>
                      </TableRow>
                    </>
                  }





                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>Creaated At</strong>
                    </TableCell>
                    <TableCell align="left">{partnerConfigurations.created_at}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
        <br />
        <br />
      </>

    </div>
  );
}
