import React, { forwardRef } from "react";
import PersonalAccidentForm from "./PersonalAccidentForm";
import DeathForm from "./DeathForm";
import HospitalForm from "./HospitalForm";
import PropertyForm from "./PropertyForm";
import BoxInc from "../../../../shared-ui/BoxInc/Index";

const Form = forwardRef(({ 
  claim, 
  metaData,
  conditions,
  hospitals,
  rejectionReason, 
}, ref) => {
  const renderForm = () => {
    switch (claim.type) {
      case "Personal_Accident":
        return <PersonalAccidentForm 
                  claim={claim}
                  metaData={metaData}
                  rejectionReason={rejectionReason} 
                />;
      case "Property":
        return <PropertyForm claim={claim} />;
      case "Death":
        return <DeathForm claim={claim} />;
      case "Hospital":
        return <HospitalForm 
        claim={claim}
        metaData={metaData}
        conditions={conditions}
        hospitals={hospitals}
        rejectionReason={rejectionReason} 
         />;
      default:
        return <PersonalAccidentForm 
        claim={claim}
        metaData={metaData}
        conditions={conditions}
        hospitals={hospitals}
        rejectionReason={rejectionReason} 
      />;
    }
  };

  return (
    <BoxInc
      title={claim.type.replace('_', ' ') + " Form"}
      expanded
      style={{ marginTop: "40px" }}
      actionButtons={[]}
    >
      <div >{renderForm()}</div>
    </BoxInc>
  );
});

export default Form;
