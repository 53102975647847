import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import TableInc from '../../../../../shared-ui/TableInc';
import { trimTimestampFromDate } from '../../../../../lib/utils.es6';
import SkeletonWrapper from '../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import { Tooltip } from '@mui/material';
import ButtonInc from '../../../../../shared-ui/ButtonInc';


const FailedEnteriesTable = (props) => {
  const classes = useStyle();

  const failedEnteries = props.failedEnteries || [];
  if(props.loading) return <SkeletonWrapper type="table" loading={true} />

  const getPaymentPayload = (rowData) => {

    const payload = { }

    if(rowData.msisdn) payload['msisdn'] = rowData.msisdn
    if(rowData.reference) payload['reference'] = rowData.reference
    if(rowData.external_identifier) payload['external_identifier'] = rowData.external_identifier
    if(rowData.amount) payload['amount'] = rowData.amount
    if(rowData.effected_at) payload['effected_at'] = rowData.effected_at
    if(rowData.sub_channel) payload['sub_channel'] = rowData.sub_channel
    if(rowData.partner_guid) payload['partner_guid'] = rowData.partner_guid

    return JSON.stringify(payload)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}  >
          <Grid container spacing={2} className={classes.claimdocswrapper}>
            <Grid item xs={12} sm={12} md={12}>

              <TableInc
                columns={[
                  { title: 'Created At', field: 'created_at', render: rowData => trimTimestampFromDate(rowData.created_at) || "N/A" },
                  { title: 'status', field: 'status' },
                  { title: 'index', field: 'index', },
                  { title: 'payload', field: 'index',render: rowData => getPaymentPayload(rowData)},
                  { title: 'feedback', field: 'feedback' },
                  {
                    title: 'Action', field: 'bucket_key', render: rowData => {
                      return <div className={classes.actionbtn}>
                        <Tooltip title="View Document">
                          <ButtonInc style={{ cursor: "pointer" }} ><a href={rowData.bucket_key} target='_blank' rel="noopener noreferrer" style={{color: 'white'}}> download original file</a> </ButtonInc>
                        </Tooltip>
                      </div>
                    }
                  },
                ]}
                // data={ failedEnteries }
                data={query =>
                  new Promise((resolve, reject) => {
                      resolve({
                          data: [...failedEnteries.data],
                          page: failedEnteries.page - 1,
                          totalCount: failedEnteries.total,
                      });
                  })
              }

                options={{
                  showTitle: false,
                  pageSizeOptions: [1, 5, 10, 20, 50, 100],
                  pageSize: failedEnteries.page_size,

                }}

                onChangeRowsPerPage = {(pageSize) => {
                  console.log(pageSize, "pageSize")
                  props.onPageChange(failedEnteries.page, pageSize);
                }}

                onChangePage = {(page) => {
                  console.log(page, "page")
                  props.onPageChange(page + 1, failedEnteries.page_size);
                }
              }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default FailedEnteriesTable