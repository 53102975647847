import { makeStyles } from "@material-ui/styles";

export const useStyle = makeStyles((LandingPageTheme) => ({

    products: {
        background: "#FBA92D",
        borderRadius: "50px 50px 0px 0px",
        padding: "50px",
        [LandingPageTheme.breakpoints.down("md")]: {
            padding: "30px"
        },
        "& h2": {
            fontFamily: 'Lato',
            fontStyle: "normal",
            fontWeight: "900",
            fontSize: "22px",
            lineHeight: "28px",
            color: "#fff",
            [LandingPageTheme.breakpoints.down("md")]: {
                fontSize: "18px",
                lineHeight: "25px",
                letterSpacing: " 0.001em",
            }
        },
    },
    divider: {
        width: "28px",
        height: "7px",
        background: "#fff",
        transform: "matrix(1, 0, 0, -1, 0, 0)",
        float: "left",
        marginTop: "-10px",
        display: "inline",
    },
    productswrapper: {
        marginTop: "40px",
        maxHeight: "248px"
    },
    individualproduct: {
        textAlign: "center",
        float: "left",
        padding: "45px",
        [LandingPageTheme.breakpoints.down("md")]: {
            border: "0",
            marginTop: "30px",
            textAlign: "center",
            padding: "20px",
        },
        "&:nth-child(3)": {
            border: "none"
        },

        "& h4": {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "22px",
            lineHeight: "28px",
            color: "#FFFFFF"
        },
        "& p": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: " 0.005em",
            color: " #FFFFFF",
        }
    },

    mySwiperproducts: {
        "& span": {
            background: "#fff",
            width: "12px",
            height: "12px",
            marginBottom: "-10px !important"
        }
    },




}));

