import * as types from '../../../lib/actionTypes.es6'

export const initialState = {
  errors: null,
  loader: false,

  get_claims_dcp_errors: null,
  get_claims_dcp_loader: false,
  get_claims_dcp_success_message: '',
  claims_dcp: [],

};

export default function claimDashboardReducer(state = initialState, action) {
  switch (action.type) {

    case types.GET_CLAIMS_DCP_REQUEST:
      return { ...state, get_claims_dcp_loader: true, get_claims_dcp_errors: null };
    case types.GET_CLAIMS_DCP_SUCCESS:
      return { ...state, get_claims_dcp_loader: false, get_claims_dcp_errors: null, claims_dcp: action.payload };
    case types.GET_CLAIMS_DCP_FAILURE:
      return { ...state, get_claims_dcp_loader: false, get_claims_dcp_errors: action.payload };

    default:
      return state;
  }
}
