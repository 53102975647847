import * as types from "../../../../lib/actionTypes.es6";


export function deleteLouRequest(payload) {
  return {
    type: types.DELETE_LOU_REQUEST,
    payload,
  };
}

export function deleteLouFailure(error) {
  return {
    type: types.DELETE_LOU_FAILURE,
    payload: error,
  };
}

export function deleteLouSuccess(response) {
  return {
    type: types.DELETE_LOU_SUCCESS,
    payload: response,
  };
}

export function resetDeleteLouSuccess(error) {
  return {
    type: types.RESET_DELETE_LOU_SUCCESS,
  };
}

export function resetDeleteLouError(error) {
  return {
    type: types.RESET_DELETE_LOU_ERROR,
  };
}

export function resetLouToDelete(error) {
  return {
    type: types.RESET_LOU_TO_DELETE,
  };
}

export function setLouToDelete(lou) {
  return {
    type: types.SET_LOU_TO_DELETE,
    payload: lou
  };
}


export function getLOUSRequest(payload){
  return{
    type: types.GET_LOUS_REQUEST,
    payload: payload
  }

}

export function getLOUSSuccess(payload){
  return{
    type: types.GET_LOUS_SUCCESS,
    payload: payload
  }

}


export function getLOUSFailure(error){
  return{
    type: types.GET_LOUS_FAILURE,
  }
}


export function createLOURequest(payload){
  return{
    type: types.CREATE_LOU_REQUEST,
    payload: payload
  }

}

export function createLOUSuccess(payload){
  return{
    type: types.CREATE_LOU_SUCCESS,
    payload: payload
  }

}


export function createLOUFailure(error){
  return{
    type: types.CREATE_LOU_FAILURE,
  }
}

export function resetCreateLOUSuccess() {
  return {
    type: types.RESET_CREATE_LOU_SUCCESS,
  };
}
export function resetCreateLOUFailure() {
  return {
    type: types.RESET_CREATE_LOU_FAILURE,
  };
}


export function setLouToEdit(response) {
  return {
    type: types.SET_LOU_TO_EDIT,
    payload: response,
  };
}

export function EditLOURequest(payload) {
  return {
    type: types.EDIT_LOU_REQUEST,
    payload,
  };
}

export function EditLOUSuccess(response) {
  return {
    type: types.EDIT_LOU_SUCCESS,
    payload: response,
  };
}

export function EditLOUFailure(response) {
  return {
    type: types.EDIT_LOU_FAILURE,
    payload: response,
  };
}

export function resetLouEditSuccess() {
  return {
    type: types.RESET_EDIT_LOU_SUCCESS,
  };
}
export function resetLouEditError() {
  return {
    type: types.RESET_EDIT_LOU_ERROR,
  };
}
export function resetLOUEdit() {
  return {
    type: types.RESET_EDIT_LOU,
  };
}

export function createHospitalRequest(payload){
  return{
    type: types.CREATE_HOSPITAL_REQUEST,
    payload: payload
  }

}

export function createHospitalSuccess(payload){
  return{
    type: types.CREATE_HOSPITAL_SUCCESS,
    payload: payload
  }

}


export function createHospitalFailure(error){
  return{
    type: types.CREATE_HOSPITAL_FAILURE,
  }

}

export function resetCreateHospitalSuccess(error) {
  return {
    type: types.RESET_CREATE_HOSPITAL_SUCCESS,
  };
}

export function resetCreateHospitalError(error) {
  return {
    type: types.RESET_CREATE_HOSPITAL_ERROR,
  };
}


export const deleteHospitalRequest = (payload) =>{
  return{
    type: types.DELETE_HOSPITAL_REQUEST,
    payload
  }
}

export const deleteHospitalSuccess =  (response) =>{
  return{
    type: types.DELETE_HOSPITAL_SUCCESS,
    payload: response
  }
}

export const deleteHospitalFailure = (error) =>{
  return{
    type: types.DELETE_HOSPITAL_FAILURE,
    payload: error
  }
}

export const resetDeleteHospitalSuccess = () =>{
  return{
    type: types.RESET_DELETE_HOSPITAL_SUCCESS
  }
}

export const resetDeleteHospitalError = () =>{
  return{
    type: types.RESET_DELETE_HOSPITAL_ERROR
  }
}

export function setHospitalToDelete(response) {
  return {
    type: types.SET_HOSPITAL_TO_DELETE,
    payload: response,
  };
}

export function resetHospitalToDelete(response) {
  return {
    type: types.RESET_HOSPITAL_TO_DELETE,
  };
}

export function getHospitalsRequest(payload){
  return{
    type: types.GET_HOSPITALS_REQUEST,
    payload: payload
  }

}

export function getHospitalSuccess(payload){
  return{
    type: types.GET_HOSPITALS_SUCCESS,
    payload: payload
  }

}


export function getHospitalsFailure(error){
  return{
    type: types.GET_HOSPITALS_FAILURE,
  }
}


export function searchHospitalsRequest(payload){
  return{
    type: types.SEARCH_HOSPITALS_REQUEST,
    payload: payload
  }

}

export function searchHospitalSuccess(payload){
  return{
    type: types.SEARCH_HOSPITALS_SUCCESS,
    payload: payload
  }

}


export function searchHospitalsFailure(error){
  return{
    type: types.SEARCH_HOSPITALS_FAILURE,
  }
}


export function createDiagnosisRequest(payload){
  return{
    type: types.CREATE_DIAGNOSIS_REQUEST,
    payload: payload
  }

}

export function createDiagnosisSuccess(payload){
  return{
    type: types.CREATE_DIAGNOSIS_SUCCESS,
    payload: payload
  }

}

export function createDiagnosisFailure(error){
  return{
    type: types.CREATE_DIAGNOSIS_FAILURE,
  }
}

export const deleteDiagnosisRequest = (payload) =>{
  return{
    type: types.DELETE_DIAGNOSIS_REQUEST,
    payload
  }
}

export function resetCreateDiagnosisSuccess(error) {
  return {
    type: types.RESET_CREATE_DIAGNOSIS_SUCCESS,
  };
}

export function resetCreateDiagnosisError(error) {
  return {
    type: types.RESET_CREATE_DIAGNOSIS_ERROR,
  };
}

export const deleteDiagnosisSuccess =  (response) =>{
  return{
    type: types.DELETE_DIAGNOSIS_SUCCESS,
    payload: response
  }
}

export const deleteDiagnosisFailure = (error) =>{
  return{
    type: types.DELETE_DIAGNOSIS_FAILURE,
    payload: error
  }
}

export const resetDeleteDIagnosisSuccess = () =>{
  return{
    type: types.RESET_DELETE_DIAGNOSIS_SUCCESS
  }
}

export const resetDeleteDiagnosisError = () =>{
  return{
    type: types.RESET_DELETE_DIAGNOSIS_ERROR
  }
}

export function setDiagnosisToDelete(response) {
  return {
    type: types.SET_DIAGNOSIS_TO_DELETE,
    payload: response,
  };
}

export function resetDiagnosisToDelete(response) {
  return {
    type: types.RESET_DIAGNOSIS_TO_DELETE,
  };
}

export function getDiagnosisRequest(payload){
  return{
    type: types.GET_DIAGNOSIS_REQUEST,
    payload: payload
  }

}

export function getDiagnosisSuccess(payload){
  return{
    type: types.GET_DIAGNOSIS_SUCCESS,
    payload: payload
  }

}


export function getDiagnosisFailure(error){
  return{
    type: types.GET_DIAGNOSIS_FAILURE,
  }
}

export function searchDiagnosisRequest(payload){
  return{
    type: types.SEARCH_DIAGNOSIS_REQUEST,
    payload: payload
  }

}

export function searchDiagnosisSuccess(payload){
  return{
    type: types.SEARCH_DIAGNOSIS_SUCCESS,
    payload: payload
  }

}


export function searchDiagnosisFailure(error){
  return{
    type: types.SEARCH_DIAGNOSIS_FAILURE,
  }
}

export function setLouToView(response) {
  return {
    type: types.SET_LOU_TO_VIEW,
    payload: response,
  };
}

export function resetLouToView(response) {
  return {
    type: types.RESET_LOU_TO_VIEW,
  };
}