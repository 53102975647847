import MaterialTable from 'material-table';
import React from 'react';
import { hashHistory } from 'react-router';
import { connect } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as actions from './actions.es6';
import { TextField } from 'material-ui';

import { getActualGroupQuoteRequest, registerGroupRequest, resetActualQuote, resetGroupPreRegistraion, resetGroupRegistraion } from '../GroupRegistration/actions.es6';
import AlertDialog from '../../../components/AlertDialog/index.es6';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Grid } from '@material-ui/core';
import { getPartner } from '../../../lib/access.es6';
export class AddEditMembers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      incomplete: false,
      acceptTerms: false,
      searchDependantVal: '',
      searchType: '',
      defaultDate: '01',
      defaultMonth: '01',
    }
  }

  componentWillMount() {
    this.props.dispatch(actions.getAllTemporaryMemberRequest({ page: 0, size: 1000, groupId: this.props.groupId }))
  }


  addPrincipal(principal) {
    const payload = {
      "family_id": principal.nationalId,
      "group_id": this.props.groupId,
      "first_name": principal.firstName,
      "middle_name": principal.middleName,
      "last_name": principal.lastName,
      "dob": new Date(`${principal.dob}-${this.state.defaultMonth}-${this.state.defaultDate}`),
      "gender": principal.gender,
      "national_id": principal.nationalId,
      "msisdn": principal.msisdn,
      "town": principal.town,
      "relationship": principal.relationship,
      "principal_national_id": principal.nationalId,
      "registration_channel": "WEB_FORM"
    }
    console.log('addPrincipal: ', payload);
    this.props.dispatch(actions.addPrincipalRequest(payload));
  }

  addDependant(dependant, principal) {
    const payload =
    {
      "family_id": principal.familyId,
      "group_id": this.props.groupId,
      "first_name": dependant.first_name,
      "middle_name": dependant.middle_name,
      "last_name": dependant.last_name,
      "dob": new Date(`${dependant.dob}-${this.state.defaultMonth}-${this.state.defaultDate}`),
      "gender": dependant.gender,
      "msisdn": dependant.msisdn,
      "town": dependant.town,
      "relationship": dependant.relationship,
      "principal_national_id": principal.nationalId,
      "registration_channel": "WEB_FORM"
    }
    this.props.dispatch(actions.addDependantRequest(payload))
  }

  updatePrincipal(newData, oldData) {
    const payload = {
      "id": oldData.id,
      "family_id": newData.familyId,
      "group_id": this.props.groupId,
      "first_name": newData.firstName,
      "middle_name": newData.middleName,
      "last_name": newData.lastName,
      "dob": new Date(`${newData.dob}-${this.state.defaultMonth}-${this.state.defaultDate}`),
      "gender": newData.gender,
      "national_id": newData.nationalId,
      "msisdn": newData.msisdn,
      "town": newData.town,
      "relationship": newData.relationship,
      "principal_national_id": newData.nationalId,
      "registration_channel": "WEB_FORM"
    }
    this.props.dispatch(actions.editPrincipalRequest(payload))
  }

  updateDependant(newData, oldData) {
    const payload = {
      "id": oldData.id,
      "family_id": newData.family_id,
      "group_id": this.props.groupId,
      "first_name": newData.first_name,
      "middle_name": newData.middle_name,
      "last_name": newData.last_name,
      "dob": new Date(`${newData.dob}-${this.state.defaultMonth}-${this.state.defaultDate}`),
      "gender": newData.gender,
      "national_id": newData.national_id,
      "msisdn": newData.msisdn,
      "town": newData.town,
      "relationship": newData.relationship,
      "principal_national_id": newData.family_id,
      "registration_channel": "WEB_FORM"
    }

    this.props.dispatch(actions.editDependantRequest(payload))
  }

  deletePrincipal(principal) {
    const payload = {
      group_id: this.props.groupId,
      principal_id: principal.id
    }
    this.props.dispatch(actions.deletePrincipalRequest(payload))
  }

  deleteDependant(dependant) {
    const payload = {
      group_id: this.props.groupId,
      dependant_id: dependant.id
    }
    this.props.dispatch(actions.deleteDependantRequest(payload))
  }

  goToClaim(customerGuid) {
    this.props.redirectToCustomerMakeClaimPage(customerGuid)
  }

  goToCustomer(customerGuid) {
    this.props.redirectToCustomerStatusPage(customerGuid)
  }

  rowStylePrincipal(rawData) {
    return rawData.status === "Complete" ? { background: 'white' } : { background: '#d7da1147' };
  }

  rowStyleDependant(rawData) {
    return this.isCompleteDependant(rawData) ? { background: 'white' } : { background: '#d7da1147' };
  }

  isCompletePrincipal(rowData) {
    if (!rowData) return false

    if (!rowData.firstName || !rowData.lastName
      || !rowData.nationalId || !rowData.dob || !rowData.gender || !rowData.msisdn) {
      return false;
    }

    return true;

  }

  isCompleteDependant(rowData) {
    if (!rowData) return false
    if (!rowData.first_name || !rowData.last_name || !rowData.relationship
      || !rowData.dob || !rowData.gender) {
      return false;
    }

    return true;
  }

  isAllDependenciesComplete(members) {
    let dependents = members || [];

    for (let i = 0; i < dependents.length; i++) {
      if (!this.isCompleteDependant(dependents[i])) {
        return false
      }
    }

    return true;
  }
  isAllMembersCompelete(members) {
    let allMembers = members || [];

    for (let i = 0; i < allMembers.length; i++) {
      if (!this.isCompletePrincipal(allMembers[i])) {
        return false
      }
    }

    return true;
  }

  isGroupDetailsComplete() {
    const group = this.props.groupRegistrationData.getGroup;
    return group.kra_pin && group.address && group.certificate_url;
  }

  getPrincipalMemberStatus(member) {
    return (this.isCompletePrincipal(member) && this.isAllDependenciesComplete(member.dependants)) ? 'Complete' : 'In Progress'
  }
  getActualQuotation() {
    const groupDetailsIsComplete = this.isGroupDetailsComplete();
    if (!groupDetailsIsComplete) {
      this.setState({ showGroupDetailsCompleteMessage: true })
      return;
    }

    const group = this.props.groupRegistrationData.getGroup;
    this.props.dispatch(getActualGroupQuoteRequest(group.id));
  }
  toggleAcceptTerms() {
    const acceptTerms = this.state.acceptTerms;
    if (acceptTerms) {
      this.setState({ acceptTerms: !acceptTerms, acceptTermsError: false });
    } else {
      this.setState({ acceptTerms: !acceptTerms })
    }
  }

  goToGroupMembers() {
    const group = this.props.groupRegistrationData.getGroup;
    const groupId = group.id
    hashHistory.push(`admin/group-status/${groupId}`)
    this.props.dispatch(resetGroupPreRegistraion())
  }

  hasErrorList() {
    const errors = this.props.groupRegistrationData.actualGroupQuoteError ? this.props.groupRegistrationData.actualGroupQuoteError.csvErrors : [];
    return Array.isArray(errors) && errors.length > 0;
  }

  downloadCSVGroupScheduleErrors() {
    const errors = this.props.groupRegistrationData.actualGroupQuoteError ? this.props.groupRegistrationData.actualGroupQuoteError.csvErrors : [];

    if (!Array.isArray(errors)) {
      console.log('errors not an array');
      return
    }
    let csvContent = "data:text/csv;charset=utf-8, Error Message\n";

    for (let i = 0; i < errors.length; i++) {
      const row = errors[i];
      csvContent += row + "\n";
    }


    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';

    const url = encodeURI(csvContent);
    a.href = url;
    a.download = 'group_schedule_errors.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  submitGroupRegistration() {
    const group = this.props.groupRegistrationData.getGroup;

    this.props.dispatch(resetActualQuote());
    this.props.dispatch(registerGroupRequest(group.id))
  }

  handleChange(event) {
    if(event.target.name === 'searchPrincipalVal'){
      this.setState({ searchType: 'principal',   searchDependantVal: ''})
    }
    if(event.target.name === 'searchDependantVal'){
      this.setState({ searchType: 'dependant',  searchPrincipalVal: '' })
    }
    this.setState({[event.target.name]: event.target.value })
  }

  hasDependantValue(dependant, searchDependantVal) {
    let { first_name, middle_name, last_name, dob, national_id, msisdn } = dependant;
    let searchVal = searchDependantVal.toLowerCase();

    return (first_name && first_name.toLowerCase().includes(searchVal)) ||
      (middle_name && middle_name.toLowerCase().includes(searchVal)) ||
      (last_name && last_name.toLowerCase().includes(searchVal)) ||
      (dob && dob.toLowerCase().includes(searchVal)) ||
      (national_id && national_id.toLowerCase().includes(searchVal)) ||
      (msisdn && msisdn.toLowerCase().includes(searchVal))
  }

  hasPrincipalValue(principal, searchPrincipalVal) {
    let { firstName, middleName, lastName, dob, nationalId, msisdn } = principal;
    let searchVal = searchPrincipalVal.toLowerCase();

    return (firstName && firstName.toLowerCase().includes(searchVal)) ||
      (middleName && middleName.toLowerCase().includes(searchVal)) ||
      (lastName && lastName.toLowerCase().includes(searchVal)) ||
      (dob && dob.toLowerCase().includes(searchVal)) ||
      (nationalId && nationalId.toLowerCase().includes(searchVal)) ||
      (msisdn && msisdn.toLowerCase().includes(searchVal))
  }



  searchPrincipals(members) {
    const { searchPrincipalVal } = this.state;
    return members.filter(principal =>  this.hasPrincipalValue(principal, searchPrincipalVal))
  }

  searchDependants(members) {
    const { searchDependantVal } = this.state;
    return members.filter(principal => principal.dependants.some(dependant => this.hasDependantValue(dependant, searchDependantVal)))
  }

  getXYears(x=90){
    const current_year = (new Date()).getFullYear();
    const years = Array.from(new Array(x),( val, index) => Math.abs(index - current_year));
    years.reverse()
    let years_map = {}
    for (let year in years) {
      years_map[years[year]] = years[year]
    }
    return years_map
  }

  getDependants(dependants){
    let redacted_dependants = [];
    for (let index in dependants) {
      let dependant = dependants[index];
      dependant['dob'] = dependant.dob? dependant.dob.split("-")[0] : null;
      redacted_dependants.push(dependant)
    }
    return redacted_dependants;
  }

  getMembers(members){
    let redacted_members = []
    for (let member in members) {
      member = members[member]
      let year = member.dob? member.dob.split("-")[0] : null;
      member['dob'] = year
      redacted_members.push(member)
    }
    const { searchType, searchDependantVal, searchPrincipalVal } = this.state;

    if(searchType === 'principal' && searchPrincipalVal){
      return this.searchPrincipals(redacted_members)
    }

    if(searchType === 'dependant' && searchDependantVal){
      return this.searchDependants(redacted_members)
    }

    return redacted_members
  }
  render() {
    const currencyPartner =  getPartner();
    const currencyCode = currencyPartner ? currencyPartner.country.currency_code : "";
    const members = (this.props.addEditMembersData.members || []).map(member => Object.assign({ status: this.getPrincipalMemberStatus(member) }, member));
    const isLoading = this.props.addEditMembersData.loader || this.props.groupRegistrationData.getGroupLoader;
    const { actualGroupQuoteError, groupRegistrationError } = this.props.groupRegistrationData;
    const { addedPrincipalError, addedDependantError } = this.props.addEditMembersData
    const quote = this.props.groupRegistrationData.actualGroupQuote || {};
    const group = this.props.groupRegistrationData.getGroup;

    return (
      <>
        {this.props.paginatation}
        {
          (group && group.registration_status === 'Closed') ?
            <p style={{ color: 'red', textAlign: 'center' }}>This group's registration for new members is closed. Please contact Britam for more info.</p>
            : <>
              <Grid container>
                <Grid item xs={6}>
                <TextField label={'Group Size'}
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={this.state.searchPrincipalVal}
                  onChange={this.handleChange.bind(this)}
                  name="searchPrincipalVal"
                  placeholder={'Search principals'}
                />
                </Grid>

                <Grid item xs={6}>
                <TextField label={'Group Size'}
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={this.state.searchDependantVal}
                  onChange={this.handleChange.bind(this)}
                  name="searchDependantVal"
                  placeholder={'Search dependants'}
                />
                </Grid>

              </Grid>
              <MaterialTable
                title="Group Pre Registration Members"
                disabled={true}
                columns={[
                  { title: 'First Name', field: 'firstName' },
                  { title: 'Middle Name', field: 'middleName' },
                  { title: 'Last Name', field: 'lastName' },
                  {
                    title: 'Relationship', field: 'relationship',
                    lookup: {  Husband: 'Husband', Wife: 'Wife' },
                  },
                  { title: 'National ID', field: 'nationalId' },
                  { title: 'Msisdn', field: 'msisdn' },
                  { title: 'Date Of Birth', field: 'dob',
                    placeholder: 'select date', lookup: this.getXYears() },
                  {
                    title: 'Gender', field: 'gender',
                    lookup: { 'Male': 'Male', 'Female': 'Female' },
                  },
                  { title: 'Status', field: 'status', editable: 'never' },
                ]}

                editable={{
                  onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                      this.addPrincipal(newData)
                      resolve();
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      this.updatePrincipal(newData, oldData);
                      resolve();
                    }),
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      this.deletePrincipal(oldData);
                      resolve();
                    }),
                }}
                data={
                  this.getMembers(members)
                }
                onRowClick={(event, rowData, togglePanel) => togglePanel()}

                options={{
                  paging: false,
                  search: false,
                  pageSizeOptions: [5, 10, 20, 50, 100, 150],
                  actionsColumnIndex: -1,
                  addRowPosition: 'first',
                  rowStyle: rowData => (
                    this.rowStylePrincipal(rowData)
                  )
                }}

                detailPanel={rowData => {
                  return (
                    <div style={{ width: '96%', margin: "auto", zIndex: '999999' }}>
                      {
                        // rowData.dependants.length > 0 ?
                        <MaterialTable
                          title="Dependants"
                          tableLayout='auto'
                          columns={[
                            { title: 'First Name', field: 'first_name' },
                            { title: 'Middle Name', field: 'middle_name' },
                            { title: 'Last Name', field: 'last_name' },
                            { title: 'DOB', field: 'dob',
                              placeholder: 'select date', lookup: this.getXYears() },
                            {
                              title: 'Gender', field: 'gender',
                              lookup: { 'Male': 'Male', 'Female': 'Female' },
                            },
                            {
                              title: 'Relationship', field: 'relationship',
                              lookup: {  Husband: 'Husband', Wife: 'Wife', Daughter: 'Daughter', Son: 'Son', Mother: 'Mother', Father: 'Father', Mother_In_Law: 'Mother_In_Law', Father_In_Law: 'Father_In_Law' },
                            },
                            { title: 'Msisdn', field: 'msisdn' },
                            { title: 'Status', field: 'status', editable: 'never', render: (rawData) => this.isCompleteDependant(rawData) ? 'Complete' : 'In Progress' },
                          ]}

                          editable={{
                            onRowAdd: (newData) =>
                              new Promise((resolve, reject) => {
                                this.addDependant(newData, rowData);
                                resolve();
                              }),
                            onRowUpdate: (newData, oldData) =>
                              new Promise((resolve, reject) => {
                                this.updateDependant(newData, oldData);
                                resolve();
                              }),
                            onRowDelete: oldData =>
                              new Promise((resolve, reject) => {
                                this.deleteDependant(oldData);
                                resolve();
                              }),
                          }}
                          data={ this.getDependants(rowData.dependants)}
                          options={{
                            showTitle: true,
                            paging: false,
                            search: false,
                            header: true,
                            padding: 'dense',
                            tableLayout: 'fixed',
                            sorting: false,
                            actionsColumnIndex: -1,
                            addRowPosition: 'first',
                            rowStyle: rowData => (
                              this.rowStyleDependant(rowData)
                            )

                          }}
                        />
                      }
                    </div>
                  )
                }}
              />
              {this.props.paginatation}

            </>}


        <AlertDialog
          success
          show={!!this.props.groupRegistrationData.actualGroupQuoteSuccess}
          title={'Quote Ready'}
          onConfirm={() => this.submitGroupRegistration()}
          confirmBtnText={'Accept and Register Group'}
          showCancel={true}
          cancelBtnText={'Cancel'}
          onCancel={() => this.props.dispatch(resetActualQuote())}
          confirmBtnCssClass
        >
          <div style={{ textAlign: 'center' }}>
            <p>Total Families: {quote.group_family_count}</p>
            <p>Total Premium Before Tax: {currencyCode} {(quote.total_premium_amount_in_cents_before_tax / 100).toFixed(2)} per Year</p>
            <p>Total Premium After Tax: {currencyCode} {(quote.total_premium_amount_in_cents_with_tax / 100).toFixed(2)} per Year</p>

          </div>
        </AlertDialog>
        <SweetAlert
          success
          show={!!this.props.groupRegistrationData.groupRegistrationSuccess}
          title={'Group Registered 😀'}
          onConfirm={() => this.goToGroupMembers()}
          confirmBtnText={'View members'}
          showCancel={false}
          onCancel={() => this.handleResetForm()}
          confirmBtnCssClass
          style={{ display: "block", marginTop: "80px", fontSize: "200%" }}
          confirmBtnStyle={{
            backgroundColor: "#4CAF50", /* Green */
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "26px",
            fontWeight: 800
          }}
          cancelBtnStyle={{
            border: "1px solid grey",
            color: "grey",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "26px",
            fontWeight: 800
          }}
        >
          <h2> Group Registration is Successful</h2>
          <br />
        </SweetAlert>

        <AlertDialog
          show={this.props.showGroupDetailsCompleteMessage}
          danger
          title={'Please complete group details.'}
          onConfirm={this.props.setShowGroupDetailsCompleteMessage}
          confirmBtnText={'ok'}
          confirmBtnCssClass
          showCancel={false}
        >
          <p>Please complete group details. i.e kra pin, address, certificate url , then try again</p>
        </AlertDialog>

        <AlertDialog
          show={!!actualGroupQuoteError}
          danger
          title={this.hasErrorList() ? 'You have errors in your group members fields, download the log file to view the errors'
            : 'You have errors in your group members fields, Please check them and try again'
          }
          onConfirm={this.downloadCSVGroupScheduleErrors.bind(this)}
          confirmBtnText={'Download the Errors'}
          cancelBtnText={'try again'}
          confirmBtnCssClass
          showConfirm={this.hasErrorList()}
          showCancel={true}
          onCancel={() => this.props.dispatch(resetActualQuote())}
        >
          {actualGroupQuoteError ? actualGroupQuoteError.message : ''}
        </AlertDialog>
        <AlertDialog
          danger
          show={!!groupRegistrationError}
          title={'Could not complete group registration'}
          confirmBtnText={'Try again'}
          onConfirm={() => this.props.dispatch(resetGroupRegistraion())}
          cancelBtnText={'Try again'}
          showCancel={false}
          onCancel={() => this.props.dispatch(resetGroupRegistraion())}
          confirmBtnCssClass
        >
          {groupRegistrationError ? groupRegistrationError.message : ''}
        </AlertDialog>

        <AlertDialog
          show={!!addedPrincipalError}
          danger
          title={'could not add principal'}
          confirmBtnText={'try again'}
          confirmBtnCssClass
          showCancel={false}
          onConfirm={() => this.props.dispatch(actions.resetAddEditMembers())}
        >
          {addedPrincipalError ? addedPrincipalError.message : ''}
        </AlertDialog>

        <AlertDialog
          show={!!addedDependantError}
          danger
          title={'could not add dependant'}
          confirmBtnText={'try again'}
          confirmBtnCssClass
          showCancel={false}
          onConfirm={() => this.props.dispatch(actions.resetAddEditMembers())}
        >
          {addedDependantError ? addedDependantError.message : ''}
        </AlertDialog>


        <Backdrop style={{ zIndex: 3000, color: 'white' }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={!!this.props.groupRegistrationData.actualGroupQuoteLoader}>
          <CircularProgress size={80} color="inherit" />
          <h3 style={{ marginTop: '10%', marginLeft: '-15%' }}>.....Getting your actual quote....</h3>
        </Backdrop>
        <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={this.props.groupRegistrationData.registerGroupLoader}>
          <CircularProgress size={80} color="inherit" />
          <h3 style={{ marginTop: '15%', marginLeft: '-15%' }}>.....Registering your Group.....</h3>
        </Backdrop>
      </>
    );
  }
}
export default connect(state => ({
  groupRegistrationData: state.groupRegistration,
  globalData: state.global,
  addEditMembersData: state.addEditMembers,
}))(AddEditMembers);
