import React, { useState } from 'react'
import AlertDialog from '../../../../../components/AlertDialog/index.es6'
import { CircularProgress } from '@material-ui/core'
import ButtonInc from '../../../../../shared-ui/ButtonInc'

const DeleteDiagnosis = (props) => {

  if (!props.DiagnoisToDelete) {
    return ""
  }

  const [showWarning, setShowWarning] = useState(false);

  const cancel = () => {
    props.resetDiagnosisToDelete()
  }
  const deleteDiagnosis = () => {
    setShowWarning(true)
    props.deleteDiagnosis(props.DiagnoisToDelete);
  }
  const handleDiagnosisDeletedSuccessfully = () => {
    props.resetDiagnosisToDelete()

  }
  const handleDiagnosisDeletedError = () => {
    props.resetDiagnosisToDelete()

  }
  return (
    <>
      <AlertDialog
        custom
        show={props.showDeleteDiagnosisProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Deleting Diagnois...</h2>
      </AlertDialog>

      <AlertDialog
        warning
        show={!showWarning && props.DiagnoisToDelete}
        size="sm"
        title={'Are you sure you want to delete this diagnosis? '}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { cancel() }}
        onCancel={() => { deleteDiagnosis() }}
        confirmBtnText={'Cancel'}
        showCancel={true}
        showConfirm={true}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
      </AlertDialog>
      <AlertDialog
        success
        show={props.showDeleteDiagnosisSuccessAlert}
        size="sm"
        title={'Diagnosis deleted'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleDiagnosisDeletedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
        <div >
          <ButtonInc onClick={() => { handleDiagnosisDeletedSuccessfully() }} style={{ background: 'green', }}>OK</ButtonInc>
        </div>
      </AlertDialog>
      <AlertDialog
        show={props.showDeleteDiagnosisErrorAlert}
        danger
        title={'Error deleting diagnosis'}
        onConfirm={() => handleDiagnosisDeletedError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {'Could not delete diagnosis'}
      </AlertDialog>
    </>
  )
}

export default DeleteDiagnosis