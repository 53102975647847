import { READ_ONLY_CLAIM_STATUES, CLAIM_STATUSES, PAYABLE_STATUSES } from './constants';
import { partnerCanPayClaim } from '../../../lib/access.es6';

export const isReadOnlyStatus = (status) => {
  return READ_ONLY_CLAIM_STATUES.includes(status);
};

export const getNextStatus = (currentStatus) => {
  switch (currentStatus) {
    case CLAIM_STATUSES.DECISION_PENDING:
      return CLAIM_STATUSES.APPROVED_PENDING;
    case CLAIM_STATUSES.APPROVED_PENDING:
      return CLAIM_STATUSES.APPROVED;
    case CLAIM_STATUSES.REJECTED_PENDING:
      return CLAIM_STATUSES.REJECTED;
    case CLAIM_STATUSES.APPROVED:
      return CLAIM_STATUSES.APPROVED_PAID;
    case CLAIM_STATUSES.EXGRATIA_PENDING:
      return CLAIM_STATUSES.EXGRATIA;
    case CLAIM_STATUSES.EXGRATIA:
      return CLAIM_STATUSES.EXGRATIA_PAID;
    default:
      return currentStatus;
  }
};

export const getButtonText = (status, labels) => {
  if (PAYABLE_STATUSES.includes(status)) {
    return partnerCanPayClaim() ? labels.payClaimBtn : labels.markAsPaidBtn;
  }
  
  switch (status) {
    case CLAIM_STATUSES.REJECTED_PENDING:
      return labels.revertRejection;
    case CLAIM_STATUSES.REJECTED:
      return labels.revertApproval;
    default:
      return labels.approveClaimBtn;
  }
};

export const shouldShowWarning = (claim, targetStatus) => {
  return (
    (claim.customer_is_high_risk && isApprovalStatus(targetStatus)) ||
    (hasWaitingPeriodBenefit(claim) && isApprovalStatus(targetStatus))
  );
};

export const isApprovalStatus = (status) => {
  return [
    CLAIM_STATUSES.APPROVED,
    CLAIM_STATUSES.APPROVED_PENDING,
    CLAIM_STATUSES.EXGRATIA,
    CLAIM_STATUSES.EXGRATIA_PENDING
  ].includes(status);
};

export const hasWaitingPeriodBenefit = (claim) => {
  if (!claim || !claim.benefits) {
    return false;
  }
  
  return claim.benefits.some(function (benefit) {
    return benefit.type === 'Waiting_Period_Benefit' && benefit.approved;
  });
};

export const updateStatus = (formData) => {
  const files = formData ? formData.received_files : [];
  const filesCount = files.length;
  let newStatus = null;

  if (formData.status === 'All_Docs_Pending') {
    if (formData.documentation_received_time) {
      newStatus = 'Decision_Pending';
    } else if (filesCount !== 0) {
      newStatus = 'Docs_Partially_Submitted';
    }
  } else if (formData.status === 'Docs_Partially_Submitted') {
    if (formData.documentation_received_time) {
      newStatus = 'Decision_Pending';
    }
  }

  if (newStatus) {
    formData.status = newStatus;
  }

  return formData;
};
