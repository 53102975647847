import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../lib/actionTypes.es6'
import * as actions from './actions';

import { getPreauthDetails, createExpense,deleteExpense,editExpense ,addClaimDocument,deleteClaimDocument,claimActions, claimNotes,claimFacilityNotes, getClaimProcessingDcp, saveClaimDcpDocument} from '../../../lib/requests.es6';
import { GAloggerException } from '../../../lib/monitoring.es6';
import { unAuthorizedNotification } from '../../../lib/access.es6';



export function* createExpenseRequestWatcher() {
  for (;;) {
    const request = yield take(types.CREATE_EXPENSE_REQUEST);
    try {
      const response = yield call(createExpense, request.payload);
      yield put(actions.CreateExpenseSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.CreateExpenseFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* deleteExpenseRequestWatcher() {
  for (;;) {
    try {
      const action = yield take(types.DELETE_EXPENSE_REQUEST);
      const payload = {
        claim_guid: action.payload.claim_guid,
        expense_guid: action.payload.expense_guid,
      }
      yield call(deleteExpense, payload);
      //yield put(actions.getUsersRequest());
      yield put(actions.deleteExpenseSuccess('Expense Deleted successfully'))
    } catch (e) {
      yield put(
        actions.deleteExpenseFail(
          'An error occurred, this could be due to invalid data '
        )
      );
      unAuthorizedNotification(e);
    }
  }
}

export function* editExpenseRequestWatcher() {
  for (;;) {
    const request = yield take(types.EXPENSE_EDIT_REQUEST);
    try {
      const response = yield call(editExpense, request.payload);
      yield put(actions.expenseEditSuccess(response.data));
    } catch (e) {
      yield put(actions.expenseEditFail('Something went wrong'));
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* addClaimDocumentRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.ADD_CLAIM_DOCUMENTS_REQUEST);

      const response = yield call(addClaimDocument, payload);
      yield put(actions.addclaimDocumentsSuccess(response.data));
    } catch (e) {
      let errorPayload = {
        message: 'An error occurred, this could be due to invalid data',
      };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.addclaimDocumentsFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* deleteClaimDocumentRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.DELETE_CLAIM_DOCUMENTS_REQUEST);

      const response = yield call(deleteClaimDocument, payload);
      yield put(actions.deleteClaimDocumentsSuccess(response.data));
    } catch (e) {
      let errorPayload = {
        message: 'An error occurred, this could be due to invalid data',
      };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.deleteClaimDocumentsFailed(errorPayload));
      GAloggerException(e);
    }
  }
}


export function* claimActionsRequestWatcher() {
  for (;;) {
    const request = yield take(types.CLAIM_ACTION_REQUEST);
    try {
      const response = yield call(claimActions, request.payload);
      yield put(actions.claimActionSuccess(response.data));
      yield put(actions.getClaimProcessingDcpRequest({guid: response.data.guid}));

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.claimActionFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* claimNotesRequestWatcher() {
  for (;;) {
    const request = yield take(types.CLAIM_NOTES_REQUEST);
    try {
      const response = yield call(claimNotes, request.payload);
      yield put(actions.claimNotesSuccess(response.data));
    } catch (e) {

      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.claimNotesFailure(errorMessage));
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* claimFacilityNotesRequestWatcher() {
  for (;;) {
    const request = yield take(types.CLAIM_FACILTY_NOTES_REQUEST);
    try {
      const response = yield call(claimFacilityNotes, request.payload);
      yield put(actions.claimFacilityNotesSuccess(response.data));
    } catch (e) {

      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.claimFacilityNotesFailure(errorMessage));
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}



export function* getClaimProcessingDcpRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_CLAIM_PROCESSING_DCP_REQUEST);
    try {
      const response = yield call(getClaimProcessingDcp, request.payload);
      yield put(actions.getClaimProcessingDcpSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getClaimProcessingDcpFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* saveClaimDcpDocumentRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.SAVE_CLAIM_DCP_DOCUMENT_REQUEST);

      const response = yield call(saveClaimDcpDocument, payload);
      yield put(actions.saveClaimDcpDocumentSuccess(response.data));
      // yield put(actions.getTemplateDocumentsRequest());
    } catch (e) {
      let errorPayload = {
        message: 'An error occurred, this could be due to invalid data',
      };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.saveClaimDcpDocumentFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* getPreauthDetailsRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_PREAUTH_DETAILS_REQUEST);
    try {
      const response = yield call(getPreauthDetails, request.payload);
      yield put(actions.getPreauthDetailsSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getPreauthDetailsFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}