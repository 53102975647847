import React from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import Policies from '../../components/Policies/index.es6';
import * as actions from './actions.es6';
import NoRecord from '../../components/NoRecord/index.es6';
import * as globalActions from '../Main/actions.es6';
import { getAllPoliciesRequest } from '../CustomerScreen/actions.es6';

export class PolicyList extends React.Component {
  componentWillMount() {
    this.props.dispatch(globalActions.showCustomerBar());
    this.getPolicies();
  }

  getPolicies(){
    const currentCustomer = this.props.customerData.currentCustomer;
    if(currentCustomer){
      this.props.dispatch(getAllPoliciesRequest({msisdn: currentCustomer.msisdn, guid: currentCustomer.guid}))
    }
  }

  deletePolicy(policy) {
    this.props.dispatch(actions.deletePolicyRequest(policy));
  }

  displayPolicies() {
    const currentCustomer = this.props.customerData.currentCustomer;
    const policies = this.props.customerScreenData.policies;
    
    if (!currentCustomer) {
      return <NoRecord />;
    }
    return (
      <div className="render-polices">
        <Policies
          policies={policies}
          showMore={false}
          deletePolicyHandler={this.deletePolicy.bind(this)}
          customerGuid={currentCustomer.guid}
          getPolicies={this.getPolicies.bind(this)}
        />
      </div>
    );
  }

  render() {
    return (
      <Loader loaded={!this.props.customerData.loader} color="#ff834f">
        {this.displayPolicies()}
      </Loader>
    );
  }
}

export default connect(state => ({ 
  customerData: state.currentCustomer,
  customerScreenData: state.customerScreen,

}))(
  PolicyList
);
