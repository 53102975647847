import React from "react";
import { connect } from "react-redux";
import RegisterCustomer from "./Components/RegisterCustomer.js";
import { registerAcessBankCustomerRequest, resetAccessBankRegistration } from "./redux/actions.js";
import { reloadPage } from "../../../../../lib/utils.es6.js";
import { hashHistory } from 'react-router';
import PolicyHeader from '../PolicyHeader/PolicyHeader.js'
import { getUsersRequest } from "../../../../../containers/Users/actions.es6.js";

class AccessBankRegisterCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openRegisterCustomerDialog: false,
    
    }
  }


  componentWillMount() {
    this.props.dispatch(getUsersRequest());
   
  }

  registerCustomer(customerData) {
    this.props.dispatch(registerAcessBankCustomerRequest(customerData));
  }
  resetRegistrationSuccess() {
    this.props.dispatch(resetAccessBankRegistration());
    hashHistory.push('admin/customer_status/' + this.props.customerData.currentCustomer.guid);
    this.toggleRegisterCustomerDialog()
    reloadPage();
  }
  resetRegistrationError() {
    this.props.dispatch(resetAccessBankRegistration())

  }
  toggleRegisterCustomerDialog() {
    this.setState({ openRegisterCustomerDialog: !this.state.openRegisterCustomerDialog })
  }
  render() {
    let agentUsersData = this.props.userData.users.filter(user => user.attributes.isAgent && user.attributes.isAgent[0] === 'true') || [];

    return (
      <>
        < PolicyHeader
          labels={this.props.labels}
          open={this.toggleRegisterCustomerDialog.bind(this)}

        />
        <RegisterCustomer
          openRegisterCustomerDialog={this.state.openRegisterCustomerDialog}
          toggleRegisterCustomerDialog={this.toggleRegisterCustomerDialog.bind(this)}
          registerCustomer={this.registerCustomer.bind(this)}
          registrationData={this.props.AccessBankRegistrationData}
          resetRegistrationSuccess={this.resetRegistrationSuccess.bind(this)}
          resetRegistrationError={this.resetRegistrationError.bind(this)}
          labels={this.props.labels}
          agentUsersData={agentUsersData }
          error={this.props.AccessBankRegistrationData.errors}
          showRegisterCustomerErrorAlert={!!this.props.AccessBankRegistrationData.errors}

        />
      </>
    )


  }
}

export default connect((state) => ({
  customerData: state.currentCustomer,
  AccessBankRegistrationData: state.AccessBankRegistrationData,
  userData: state.userAdmin,
}))(AccessBankRegisterCustomer);