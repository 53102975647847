import React from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { hashHistory } from 'react-router';
import * as actions from './actions.es6';
import * as globalActions from '../Main/actions.es6';
import { localisedText } from '../../lib/localisation.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { getCustomer } from '../Customer/actions.es6';
import { PRIMARY_THEME_COLOR_SHADE_1, PRIMARY_THEME_COLOR_SHADE_2, SECONDARY_THEME_COLOR_SHADE_2 } from '../../lib/constants';
import { Grid, DialogContent, Button, Dialog, AppBar, Backdrop, Box, CircularProgress, MenuItem, Paper, Tab, Tabs, Typography } from '@mui/material';
import OpenClaimCallBacksTable from './components/OpenClaimCallBacksTable';
import ClosedClaimCallBacksTable from './components/ClosedClaimCallBacksTable';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import AlertDialog from '../../components/AlertDialog/index.es6';
import { getUserFullname } from '../../lib/access.es6'
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export class ClaimCallback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
      value: 0,
      openCloseClaimCallbackDialog: false,
      reason: '',
      otherReason: '',
      claimGuid: '',
    };
  }

  componentWillMount() {
    this.updateLanguage();
    this.props.dispatch(globalActions.hideCustomerBar());
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'ClaimsOpen',
      this.state.labels
    );
  }

  handlePageChange(page, pageSize, type){
    this.getCallBacks(page, pageSize, type)
  };
  
  getCallBacks(page, page_size, type){

    if(type === 'open'){
      this.props.dispatch(actions.claimCallbackRequest(
        {
          page: page || 1,
          page_size: page_size || 10
        }
      ));
    }else{
      this.props.dispatch(actions.getClosedClaimCallbackRequest(
        {
          page: page || 1,
          page_size: page_size || 10
        }
      ));
    }
  }

  viewCustomer(guid) {
    this.props.dispatch(getCustomer(guid));
    hashHistory.push('admin/home');
  }

  deleteCallback(guid) {
    this.setState({ openCloseClaimCallbackDialog: true, claimGuid: guid })
  }

  handleTabChange(event, value) {
    this.setState({ value });
  }

  handleClose(){
    this.setState({ openCloseClaimCallbackDialog: false })
  };

  refreshTabContent(value) {
    console.log({ value });
    if (value === 0) {
      this.props.dispatch(actions.claimCallbackRequest())
    }

    if (value === 1) {
      this.props.dispatch(actions.getClosedClaimCallbackRequest())
    }
  }


  closeClaimCallBack() {
    const payload = {
      claimGuid: this.state.claimGuid,
      reason: JSON.stringify(
        { 
        closed_by: getUserFullname(), 
        reason: this.state.otherReason ? this.state.otherReason : this.state.reason 
        }
      )
      
    }
    this.props.dispatch(actions.claimCallbackDeleteRequest(payload));
  }

  resetForm() {
    this.setState({
      reason: '',
      otherReason: '',
    })
  }

  resetCloseCallBack(){
    this.props.dispatch(actions.resetClaimCallbackDelete());
    this.setState({ openCloseClaimCallbackDialog: false })
    this.resetForm();
  }


  getTabStyle(tabValue) {
    const tabStyles = {
      default_tab: {
        color: 'whitesmoke',
      },
      active_tab: {
        color: "#fff",
        fontWeight: 600,
      }
    }

    const { value } = this.state;
    if (value === tabValue) {
      return tabStyles.active_tab
    } else {
      return tabStyles.default_tab
    }
  }


  render() {
    const { value } = this.state;
    return (
      <>

        <Paper style={{ width: '100%', minHeight: '700px', marginTop: '5%' }}>
          <AppBar position="static" style={{ backgroundColor: PRIMARY_THEME_COLOR_SHADE_2 }}>
            <Tabs
              value={value}
              onChange={this.handleTabChange.bind(this)}
              variant="fullWidth"
              TabIndicatorProps={{
                style: {
                  backgroundColor: SECONDARY_THEME_COLOR_SHADE_2
                }
              }}              >
              <Tab label={'open call backs'} {...a11yProps(0)} style={this.getTabStyle(0)} />
              <Tab label={'closed call backs'} {...a11yProps(3)} style={this.getTabStyle(1)} />
            </Tabs>
          </AppBar>



          <TabPanel value={value} index={0}>

            <OpenClaimCallBacksTable
              closedClaimCallbacks={this.props.callbackData.claimCallbackList}
              callbacks={this.props.callbackData.claimCallbackList}
              viewCustomer={this.viewCustomer.bind(this)}
              deleteCallback={this.deleteCallback.bind(this)}
              onPageChange={this.handlePageChange.bind(this)}
              loading={this.props.callbackData.loader}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>

            <ClosedClaimCallBacksTable
              callbacks={this.props.callbackData.closedClaimCallbacks}
              onPageChange={this.handlePageChange.bind(this)}
              loading={this.props.callbackData.closedClaimCallbacksLoader}
            />
          </TabPanel>

          <Dialog
            open={this.state.openCloseClaimCallbackDialog}
            onClose={() => this.setState({ openCloseClaimCallbackDialog: false })}
            fullWidth
            maxWidth="md"
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleClose.bind(this)}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Close Claim Call Back
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent style={{ margin: "auto", textAlign: "center" }}>
              <h3 style={{ color: "#003643" }}>close claim call back</h3>
              <p>Leave your reason for closing this claim call back</p>
              <ValidatorForm onSubmit={this.closeClaimCallBack.bind(this)} >
                <Grid container spacing={4} direction={'row'} rowSpacing={4} columnSpacing={4}>
                  <Grid item xs={12} sm={12} md={12}>
                    <SelectValidator
                      value={this.state.reason}
                      label='reason'
                      id="reason"
                      name='reason'
                      onChange={(e) => { this.setState({ reason: e.target.value }) }}
                      className="form-control"
                      type="text"
                      variant="outlined"
                      validators={['required']}
                      errorMessages={['Please select reason']}
                      fullWidth
                      style={{ width: '350px' }}
                    >
                      <MenuItem value="">{'Please select reason'}</MenuItem>
                      {
                        ['Request more information', 'Initiate claim', 'Customer Unavailable', 'Test the solution', 'Other'].map((type) => {
                          return <MenuItem value={type}>{type}</MenuItem>
                        })
                      }
                    </SelectValidator>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} style={{ disabled: this.state.reason !== 'Other' ? true : false, marginTop: '1%' }}>

                    <TextValidator label={'Other reason'}
                      fullWidth
                      variant="outlined"
                      type="text"
                      value={this.state.otherReason}
                      disabled={this.state.reason !== 'Other' ? true : false}
                      onChange={(e) => { this.setState({ otherReason: e.target.value }) }}
                      name="otherReason"
                      placeholder={'Other reason'}
                      validators={this.state.reason === 'Other' ? ['required'] : '' }
                      errorMessages={'Please type your reason'}
                      style={{ width: '350px', backgroundColor: this.state.reason !== 'Other' ? '#eee' : 'inherit' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Button
                      style={{ margin: "5px", backgroundColor: "#003643" }}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >Close Claim Call Back</Button>
                    <Button variant="outlined" onClick={this.handleClose.bind(this)} style={{ marginRight: '3%', borderColor: PRIMARY_THEME_COLOR_SHADE_1, color: PRIMARY_THEME_COLOR_SHADE_1, fontWeight: 'bold' }}>Cancel</Button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </DialogContent>
          </Dialog>

          <AlertDialog
            custom
            show={this.props.callbackData.deleteCallbackLoader}
            size="sm"
            style={{ marginTop: '0', top: '30vh' }}
            confirmBtnText={'ok'}
            showCancel={false}
            showConfirm={false}
            cancelBtnText={'cancel'}
            showTitle={false}
            confirmBtnCssClass
          >
            <CircularProgress />
            <h2>closing claim call back</h2>
          </AlertDialog>

          <AlertDialog
            success
            show={!!this.props.callbackData.deleteCallbackSuccess}
            size="sm"
            title={'claim call back closed'}
            style={{ marginTop: '0', top: '30vh' }}
            onConfirm={() => { this.resetCloseCallBack() }}
            confirmBtnText={'ok'}
            showCancel={false}
            cancelBtnText={'cancel'}
            confirmBtnCssClass
          >
          </AlertDialog>


          <AlertDialog
            show={!!this.props.callbackData.deleteCallbackError}
            danger
            title={'Error closing claim call back'}
            onConfirm={() => { this.resetCloseCallBack() }}
            confirmBtnText={'Try again'}
            confirmBtnCssClass
            showCancel={false}
            style={{ marginTop: '0', top: '30vh' }}
          >
            {this.props.callbackData.errors ? this.props.callbackData.errors.message : ''}
          </AlertDialog>

          <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={this.props.callbackData.loader || this.props.callbackData.closedClaimCallbacksLoader}>
            <CircularProgress size={80} color="inherit" />
          </Backdrop>

        </Paper>

      </>
    );
  }
}

export default connect(state => ({
  callbackData: state.claimsCallBack,
  globalData: state.global,
}))(ClaimCallback);
