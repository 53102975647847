import React, { useEffect, useState } from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import SkeletonWrapper from '../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';


import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { getPartner, getPartnerGuid, hasAuthourity } from '../../../../../lib/access.es6';
import { DisplayClaims } from './DisplayClaims';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress } from '@mui/material';

const FilterClaims = (props) => {
  const classes = useStyle();

  const [selectedHospital, setSelectedHospital] = useState(null);

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [claims, setClaims] = useState(props.claimsData)



  useEffect(() => {
    setClaims(props.claimsData)
  }, [props.claimsData])

  useEffect(() => {
    let claimsFilterd = [...claims];
    if (selectedHospital) {
      claimsFilterd = claimsFilterd.filter(claim => claim.facility === selectedHospital.label)
    }

    if (startDate && endDate) {
      claimsFilterd = claimsFilterd.filter(claim => {
        const claimDate = new Date(claim.created_at);
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);

        return claimDate >= startDateObj && claimDate <= endDateObj;
      });
    }


    setClaims(claimsFilterd)
  }, [selectedHospital, startDate, endDate])

  if (props.loading) {
    return <SkeletonWrapper loading={props.loading} />
  }


  const handleClearFilter = () => {
    setSelectedHospital(null)
    setStartDate(null);
    setEndDate(null)
    setClaims(props.claimsData)
  };

  const handleClaimBulkApprove = () => {

    const payload = {
      "start_date": startDate,
      "end_date": endDate,
      "partner": getPartnerGuid(),
      "facility": selectedHospital.label
    }
    console.log("bulk approve", payload)
    props.handleBulkApproval(payload)
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === "startDate") {
      setStartDate(value)
    }
    if (name === "endDate") {
      setEndDate(value)
    }

  }
  const currencyCode = getPartner().country.currency_code

  //const payableAmountInCents = claims.reduce((total, claim) => total + claim.total_payout_in_cents, 0);
  const payableAmountInCents = 0;

  const payableAmount = payableAmountInCents ? payableAmountInCents / 100 : 0


  const getAvailableHospital = (claims) => {
    const result = []
    for (let claim of claims) {
      if (claim.facility) {
        result.push({
          label: claim.facility,
          year: claim.facility,
        })
      }
    }

    return result
  }

  const availableHospitals = getAvailableHospital(claims);


  const handleBulkApproveSuccessfully = () => {
    props.resetBulkApproveSuccessAlert()
    handleClearFilter()
  }

  const handleBulkApproveError = () => {
    props.resetBulkApproveErrorAlert()
  }


  return (
    <>
      <section className={classes.claimTable}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sm={12} className={classes.filterTitle}>
            <h2>Filter By:</h2>
          </Grid>
        </Grid>
        <ValidatorForm onSubmit={() => handleClaimBulkApprove()} >
          <Grid container spacing={2} className={classes.filterWrapper}>
            {
              props.type === "hospital" && <Grid item xs={12} md={4} sm={4} className={classes.autocomplete} >

                <div>

                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={availableHospitals}
                    sx={{ width: 300 }}
                    value={selectedHospital}
                    onChange={(event, newValue) => {
                      setSelectedHospital(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Hospital"
                      required={props.hasBulkApprove}
                      errorMessages={"start date is required"}
                    />}
                  />
                </div>

              </Grid>
            }

            <Grid item xs={12} md={4} sm={4} >
              <TextValidator
                id="startDate"
                label="Start Date"
                name='startDate'
                value={startDate || ''}
                onChange={handleChange}
                fullWidth
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required={props.hasBulkApprove}
                errorMessages={"start date is required"}
              />
              {props.hasBulkApprove && <Grid container spacing={2} className={classes.filterBtns}>
                <Grid item xs={12} md={6} sm={6}>
                  <p>Total  : KSH {payableAmount}</p>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <ButtonInc
                    hasPermision={hasAuthourity('BULK_APPROVE_CLAIMS')}
                    variant="contained" type='submit'>Bulk Approve</ButtonInc>
                </Grid>
              </Grid>}
            </Grid>
            <Grid item xs={12} md={4} sm={4} >

              <TextValidator
                id="endDate"
                label="End Date"
                name='endDate'
                value={endDate || ''}
                onChange={handleChange}
                fullWidth
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required={props.hasBulkApprove}
                errorMessages={"start date is required"}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6} className={classes.filterBtns}>
              <ButtonInc
                onClick={handleClearFilter}
                variant="contained"
              >
                Clear Filter
              </ButtonInc>
            </Grid>

          </Grid>
        </ValidatorForm>
        {DisplayClaims(currencyCode, classes, claims)}
        {
          props.hasAlerts &&
          <>
            <AlertDialog
              custom
              show={props.showBulkApproveProgressAlert}
              size="sm"
              style={{ marginTop: '0', top: '30vh' }}
              confirmBtnText={'ok'}
              showCancel={false}
              showConfirm={false}
              cancelBtnText={'cancel'}
              showTitle={false}
              confirmBtnCssClass
            >
              <CircularProgress />
              <h2>submitting bulk approval request</h2>
            </AlertDialog>

            <AlertDialog
              success
              show={props.showBulkApproveSuccessAlert}
              size="sm"
              title={'submitted bulk approval request'}
              style={{ marginTop: '0', top: '30vh' }}
              onConfirm={() => { handleBulkApproveSuccessfully() }}
              confirmBtnText={'ok'}
              showCancel={false}
              showConfirm={false}
              cancelBtnText={'cancel'}
              confirmBtnCssClass
            >
              <div >
                <ButtonInc variant="contained" onClick={() => { handleBulkApproveSuccessfully() }} style={{ background: 'green', }}>Ok</ButtonInc>
              </div>
            </AlertDialog>

            <AlertDialog
              show={!!props.showBulkApproveErrorAlert}
              danger
              title={'Error submitting bulk approval request'}
              onConfirm={() => handleBulkApproveError()}
              confirmBtnText={'Try again'}
              confirmBtnCssClass
              showCancel={false}
              style={{ marginTop: '0', top: '30vh' }}
            >
              {props.BulkApproveError ? props.BulkApproveError.message : ''}
            </AlertDialog>
          </>
        }

      </section>

    </>
  )
}

export default FilterClaims