import * as types from '../../lib/actionTypes.es6';

export function reportPayableClaimRequest(reportType) {
  return {
    type: types.REPORT_PAYABLE_CLAIM_REQUEST,
    payload: reportType,
  };
}

export function reportPayableClaimRequestSuccess(reportURL) {
  return {
    type: types.REPORT_PAYABLE_CLAIM_SUCCESS,
    payload: reportURL,
  };
}

export function reportPayableClaimRequestError(error) {
  return {
    type: types.REPORT_PAYABLE_CLAIM_FAILURE,
    payload: error,
  };
}

export function reportPayableClaimReset() {
  return {
    type: types.REPORT_PAYABLE_CLAIM_RESET,
  };
}
