import React from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import Dialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import FontIcon from 'material-ui/FontIcon';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import MainPageTitle from '../MainPageTitle/index.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { localisedText } from '../../lib/localisation.es6';
import { Button } from '@material-ui/core';

export class DebitOrderTransactionsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
      openConfirmCancel: false,
      debitOrderTransaction: null,
      popoverOpen: false,
      selectedRows: [],
    };
  }

  componentWillMount() {
    this.updateLanguage();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'DebitOrders',
      this.state.labels
    );
  }

  handleCloseCancelDialog() {
    this.setState({ openConfirmCancel: false });
  }

  handleOpenCancelDialog(debitOrderTransaction) {
    this.setState({ openConfirmCancel: true, debitOrderTransaction });
  }

  cancelDebitOrder() {
    this.props.deleteDebitOrderHandler(this.state.debitOrderTransaction);
    this.setState({ openConfirmCancel: false, debitOrderTransaction: null });
  }

  handlePopOverOpen(event) {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      popoverOpen: true,
      anchorEl: event.currentTarget,
    });
  }

  handleRequestClosePopOver() {
    this.setState({
      popoverOpen: false,
    });
  }

  onTableSelectionHandler(rows) {
    const selectedRows = [];
    const length =
      rows === 'all' ? this.props.debitOrderTransactions.length : rows.length;
    for (let i = 0; i < length; i++) {
      selectedRows.push(
        this.props.debitOrderTransactions[rows === 'all' ? i : rows[i]].guid
      );
    }
    this.setState({ selectedRows });
  }

  updateTransactions(status) {
    const payload = {
      transactions: this.state.selectedRows,
      status,
    };
    this.setState({ popoverOpen: false });
    this.props.updateTransactions(payload);
  }

  generateTransactionCsv() {
    this.setState({ popoverOpen: false });
    const { labels } = this.state;
    const data = this.props.debitOrderTransactions.filter(
      x => this.state.selectedRows.indexOf(x.guid) > -1
    );

    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += `${labels.reference},${labels.createdAt},${labels.fullName},${labels.bankAccount}${labels.status}${labels.amount}${labels.retries}\n`;

    data.forEach(debitOrderTransaction => {
      const dataString = `${debitOrderTransaction.guid},${debitOrderTransaction.created_at},${debitOrderTransaction.full_name},${debitOrderTransaction.msisdn},${debitOrderTransaction.bank_account_number},${debitOrderTransaction.status},${debitOrderTransaction.amount_in_cents},${debitOrderTransaction.number_of_retries}\n`;
      csvContent += dataString;
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'debit_order_transactions.csv');
    document.body.appendChild(link);

    link.click();
  }

  render() {
    const labels = this.state.labels;
    const debitOrderTransactions = this.props.debitOrderTransactions
      ? this.props.debitOrderTransactions
      : [];
    const debitOrderData = debitOrderTransactions.map(debitOrderTransaction => (
      <TableRow
        key={debitOrderTransaction.guid}
        selected={
          this.state.selectedRows.indexOf(debitOrderTransaction.guid) > -1
        }
      >
        <TableRowColumn>{debitOrderTransaction.guid}</TableRowColumn>
        <TableRowColumn>{debitOrderTransaction.created_at}</TableRowColumn>
        <TableRowColumn>{debitOrderTransaction.full_name}</TableRowColumn>
        <TableRowColumn>{debitOrderTransaction.msisdn}</TableRowColumn>
        <TableRowColumn>
          {debitOrderTransaction.bank_account_number}
        </TableRowColumn>
        <TableRowColumn>{debitOrderTransaction.status}</TableRowColumn>
        <TableRowColumn>
          {debitOrderTransaction.amount_in_cents / 100}
        </TableRowColumn>
        <TableRowColumn>
          {debitOrderTransaction.number_of_retries}
        </TableRowColumn>
      </TableRow>
    ));
    const confirmDeleteActions = [
      <Button
        onClick={this.handleCloseCancelDialog.bind(this)}
        color="primary"
        variant="outlined"
        size="large"
        style={{ margin:"5px"}}
      >
        {labels.cancel}
      </Button>,
       <Button
          onClick={this.cancelDebitOrder.bind(this)}
          color="primary"
          variant="contained"
          size="large"
          style={{ margin:"15px"}}
       >
        {labels.removeDebitOrder}
       </Button>
    ];
    return (
      <div className="col-sm-12 col-xs-12">
        <MainPageTitle pageTitle={labels.dailyDebitOrder} />
        <IconButton iconClassName="fa fa-filter" />
        <Button
          onClick={this.handlePopOverOpen.bind(this)}
          style={{ marginBottom: '10px', float: 'right' }}
          disabled={this.state.selectedRows.length === 0}
          color="primary"
          size="large"
          variant="contained"
        >
            Action
        </Button>
        <br />
        <div className="x_panel">
          <div className="x_content">
            {debitOrderTransactions.length === 0 ? (
              <center>{labels.nothingFound}</center>
            ) : (
              <form className="form-horizontal form-label-left" noValidate>
                <Table
                  onRowSelection={this.onTableSelectionHandler.bind(this)}
                  multiSelectable
                  style={{ tableLayout: 'auto' }}
                  fixedHeader={false}
                >
                  <TableHeader>
                    <TableRow>
                      <TableHeaderColumn>{labels.reference}</TableHeaderColumn>
                      <TableHeaderColumn>{labels.createdAt}</TableHeaderColumn>
                      <TableHeaderColumn>{labels.fullName}</TableHeaderColumn>
                      <TableHeaderColumn>{labels.msisdn}</TableHeaderColumn>
                      <TableHeaderColumn>
                        {labels.bankAccount}
                      </TableHeaderColumn>
                      <TableHeaderColumn>{labels.status}</TableHeaderColumn>
                      <TableHeaderColumn>{labels.amount}</TableHeaderColumn>
                      <TableHeaderColumn>{labels.retries}</TableHeaderColumn>
                    </TableRow>
                  </TableHeader>
                  <TableBody className="debitOrderTransactions-body">
                    {debitOrderData}
                  </TableBody>
                </Table>
              </form>
            )}
          </div>
        </div>
        <Dialog
          title={labels.removeDebitOrderConfirmation}
          actions={confirmDeleteActions}
          modal={false}
          // open={this.state.openConfirmCancel}
          open={true}
          onRequestClose={this.handleCloseCancelDialog.bind(this)}
        >
          {labels.removeDebitOrderMessage}
        </Dialog>
        <Popover
          open={this.state.popoverOpen}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          onRequestClose={this.handleRequestClosePopOver.bind(this)}
        >
          <Menu>
            <MenuItem
              leftIcon={
                <FontIcon style={{ color: 'green' }} className="fa fa-check" />
              }
              primaryText={labels.markSuccess}
              onClick={this.updateTransactions.bind(this, 'Succeeded')}
            />
            <MenuItem
              leftIcon={
                <FontIcon style={{ color: 'red' }} className="fa fa-times" />
              }
              primaryText={labels.markFailed}
              onClick={this.updateTransactions.bind(this, 'Failed')}
            />
            <MenuItem
              leftIcon={
                <FontIcon
                  style={{ color: '#4080bf' }}
                  className="glyphicon glyphicon-download-alt"
                />
              }
              primaryText={labels.downloadCsv}
              onClick={this.generateTransactionCsv.bind(this)}
            />
          </Menu>
        </Popover>
      </div>
    );
  }
}

export default connect(state => ({ globalData: state.global }))(
  DebitOrderTransactionsComponent
);
