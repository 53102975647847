import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import AlertDialog from "../../../../components/AlertDialog/index.es6";
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, Switch } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../../lib/constants";
import MultipleSelectChip from "./MultipleSelectChip";
import { CUSTOMER_FIELDS, LOYALTY_FIELDS, POLICY_FIELDS, POLICY_SCHEME_TYPES, POLICY_SUBSCRIPTION_TYPES, REGISTRATION_FIELDS, WEB_DISPLAY_FIELDS } from "../../constants";
import { hasAuthourity } from "../../../../lib/access.es6";

let initialState = {}


export default function UpdatePartnerConfig(props) {
  if (!props.partnerConfig) {
    return ""
  }
  initialState = props.partnerConfig

  const [
    {
      guid,
      sms_active,
      sms_whitelist,
      email_active,
      email_whitelist,
      partner_guid,
      created_at,
      registration_fields,
      sms_limit_active,
      sms_hourly_limit_count,
      customer_fields,
      policy_fields,
      throw_sms_errors,
      can_debit,
      pull_kyc,
      trigger_1st_premium_on_sale,
      can_pay_claim,
      money_transaction_active,
      money_transaction_whitelist,
      can_edit_debit,
      ussd_active,
      ussd_whitelist,
      mobile_payment_popup_active,
      monitoring_sms_list,
      need_eligibility_in_status,
      callback_active,
      partial_premium_payment_enabled,
      display_beneficiary_enabled,
      web_display_fields,
      debit_policy_cancellation_email,
      irregular_payment_enabled,
      check_loyalty_during_registration_enabled,
      loyalty_fields,
      policy_subscription_type,
      policy_scheme,
      power_bi_dashboard_url_1,
      power_bi_dashboard_url_2,
      can_purchase_loyalty_policy,
      can_purchase_policy_via_ui
    }, setState
  ] = React.useState(initialState)

  const [customerFields, setCustomerFields] = React.useState([...props.formatAndConvertToListString(customer_fields)]);
  const [registrationFields, setRegistrationFields] = React.useState([...props.formatAndConvertToListString(registration_fields)]);
  const [loyaltyFields, setLoyaltyFields] = React.useState([...props.formatAndConvertToListString(loyalty_fields)]);
  const [policyFields, setPolicyFields] = React.useState([...props.formatAndConvertToListString(policy_fields)]);
  const [webDisplayFields, setWebDisplayFields] = React.useState([...props.formatAndConvertToListString(web_display_fields)]);
  const [policySubscriptionTypes, setPolicySubscriptionTypes] = React.useState([policy_subscription_type]);

  const [moneyTransactionWhitelist, setMoneyTransactionWhitelist] = React.useState(props.removeSquareBrackets(money_transaction_whitelist));
  const [ussdWhitelist, setUssdWhitelist] = React.useState(props.removeSquareBrackets(ussd_whitelist));
  const [monitoringSmsList, setMonitoringSmsList] = React.useState(props.removeSquareBrackets(monitoring_sms_list));
  const [emailWhitelist, setEmailWhitelist] = React.useState(props.removeSquareBrackets(email_whitelist));
  const [smsWhitelist, setSmsWhitelist] = React.useState(props.removeSquareBrackets(sms_whitelist));

  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const onChangeMoneyTransactionWhitelist = e => {
    setMoneyTransactionWhitelist(e.target.value)
  }

  const onChangeUssdWhitelist = e => {
    setUssdWhitelist(e.target.value)
  }

  const onChangeMonitoringSmsList = e => {
    setMonitoringSmsList(e.target.value)
  }

  const onChangeEmailWhitelist = e => {
    setEmailWhitelist(e.target.value)
  }

  const onChangeSmsWhitelist = e => {
    setSmsWhitelist(e.target.value)
  }

  const toggleBooleanFields = e => {
    setState(prevState => ({ ...prevState, [e.target.name]: e.target.checked }));
  };



  const submit = () => {
    const payload = {
      guid,
      sms_active,
      email_active,
      partner_guid,
      created_at,
      sms_limit_active,
      sms_hourly_limit_count,
      throw_sms_errors,
      can_debit,
      pull_kyc,
      trigger_1st_premium_on_sale,
      can_pay_claim,
      money_transaction_active,
      policy_scheme,
      can_edit_debit,
      ussd_active,
      mobile_payment_popup_active,
      need_eligibility_in_status,
      callback_active,
      partial_premium_payment_enabled,
      display_beneficiary_enabled,
      debit_policy_cancellation_email,
      irregular_payment_enabled,
      check_loyalty_during_registration_enabled,
      money_transaction_whitelist: props.convertStringToList(moneyTransactionWhitelist),
      email_whitelist: props.convertStringToList(emailWhitelist),
      monitoring_sms_list: props.convertStringToList(monitoringSmsList),
      sms_whitelist: props.convertStringToList(smsWhitelist),
      ussd_whitelist: props.convertStringToList(ussdWhitelist),

      web_display_fields: props.convertListToString(webDisplayFields),
      registration_fields: props.convertListToString(registrationFields),
      customer_fields: props.convertListToString(customerFields),
      policy_fields: props.convertListToString(policyFields),
      loyalty_fields: props.convertListToString(loyaltyFields),
      policy_subscription_type: props.convertListToString(policySubscriptionTypes)[0],

      power_bi_dashboard_url_1,
      power_bi_dashboard_url_2,
      can_purchase_loyalty_policy,
      can_purchase_policy_via_ui
    }
    for (let k in payload) {
      if (payload[k] == null) delete payload[k]
    }
    console.log('submitting');
    console.log(payload);
    props.updatePartnerConfig(payload)
  }



  const reset = () => {
    setState(initialState);
  }

  const handleUpdateParnterConfigdSuccessfully = () => {
    props.resetUpdatePartnerConfigSuccessAlert()
    props.closeUpdatePartnerConfigDialog()
    reset()
  }

  const handleUpdateParnterConfigError = () => {
    props.resetUpdatePartnerConfigErrorAlert()
  }

  const booleanFields = [
    'sms_active',
    'email_active',
    'sms_limit_active',
    'throw_sms_errors',
    'can_debit',
    'pull_kyc',
    'trigger_1st_premium_on_sale',
    'can_pay_claim',
    'money_transaction_active',
    'can_edit_debit',
    'ussd_active',
    'mobile_payment_popup_active',
    'need_eligibility_in_status',
    'callback_active',
    'partial_premium_payment_enabled',
    'display_beneficiary_enabled',
    'irregular_payment_enabled',
    'check_loyalty_during_registration_enabled',
    'can_purchase_loyalty_policy',
    'can_purchase_policy_via_ui',
  ]

  const textFields = [
    'debit_policy_cancellation_email',
  ]

  if (hasAuthourity('CREATE_POWER_BI_DASHBOARD_URLS')) {
    textFields.push('power_bi_dashboard_url_1', 'power_bi_dashboard_url_2');
  }

  const integerFields = [
    'sms_hourly_limit_count'
  ]

  return (
    <>
      <AlertDialog
        custom
        show={props.showUpdatePartnerConfigProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>updating partner config</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showUpdatePartnerConfigSuccessAlert}
        size="sm"
        title={'Partner config updated'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleUpdateParnterConfigdSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>

      <AlertDialog
        show={props.showUpdatePartnerConfigErrorAlert}
        danger
        title={'Error updating partner config'}
        onConfirm={() => handleUpdateParnterConfigError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.updatePartnerConfigError ? props.updatePartnerConfigError.message : ''}
      </AlertDialog>


      <Dialog open={props.showUpdatePartnerConfigDialog} fullWidth maxWidth='lg' fullScreen onClose={props.closeUpdatePartnerConfigDialog}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.closeUpdatePartnerConfigDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Update Partner Config
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div style={{ marginTop: "2%" }}>
            <Box sx={{ width: "100%" }}>
              <ValidatorForm onSubmit={() => submit()} >
                <Grid container spacing={2}>

                  {
                    booleanFields.map(field => {
                      return <Grid item lg={3} md={3} xs={12}>
                        <label>{field}</label>
                        <Switch
                          checked={eval(field)} //eslint-disable-line
                          onChange={toggleBooleanFields}
                          name={field}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Grid>
                    })
                  }

                  <Grid item lg={4} md={4} xs={12}>
                  </Grid>

                  <Grid item lg={4} md={4} xs={12}>
                    <MultipleSelectChip
                      constantFields={CUSTOMER_FIELDS}
                      fieldLabel="Customer Fields"
                      fields={customerFields}
                      setFields={setCustomerFields}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} xs={12}>
                    <MultipleSelectChip
                      constantFields={REGISTRATION_FIELDS}
                      fieldLabel="Registration Fields"
                      fields={registrationFields}
                      setFields={setRegistrationFields}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} xs={12}>
                    <MultipleSelectChip
                      constantFields={LOYALTY_FIELDS}
                      fieldLabel="Loyalty Fields"
                      fields={loyaltyFields}
                      setFields={setLoyaltyFields}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} xs={12}>
                    <MultipleSelectChip
                      constantFields={POLICY_FIELDS}
                      fieldLabel="Policy Fields"
                      fields={policyFields}
                      setFields={setPolicyFields}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} xs={12}>
                    <MultipleSelectChip
                      constantFields={WEB_DISPLAY_FIELDS}
                      fieldLabel="Web Display Fields"
                      fields={webDisplayFields}
                      setFields={setWebDisplayFields}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} xs={12}>
                    <MultipleSelectChip
                      constantFields={POLICY_SUBSCRIPTION_TYPES}
                      fieldLabel="Policy Subscription Type"
                      fields={policySubscriptionTypes}
                      setFields={setPolicySubscriptionTypes}
                      multiple={false}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="policy-scheme-label">Policy Scheme Type</InputLabel>

                      <Select
                        labelId="policy-scheme-label"
                        id="policy-scheme-id"
                        name={"policy_scheme"}
                        value={policy_scheme}
                        label="Policy Scheme Type"
                        placeholder="Policy Scheme Type"
                        onChange={onChange}
                      >
                        {
                          POLICY_SCHEME_TYPES.map((item, index) =>
                            <MenuItem key={index} value={item}>{item}</MenuItem>
                          )
                        }
                      </Select>
                    </FormControl>

                  </Grid>

                  {
                    integerFields.map(field => {
                      return <Grid item lg={4} md={4} xs={12}>
                        <TextValidator
                          onChange={onChange}
                          value={eval(field)} //eslint-disable-line
                          label={field}
                          name={field}
                          fullWidth
                          variant="outlined"
                          type="number"
                          placeholder={field}
                          // validators={['required']}
                          errorMessages={[`Please enter ${field}`]}
                        />
                      </Grid>
                    })
                  }

                  {
                    textFields.map(field => {
                      return <Grid item lg={4} md={4} xs={12}>
                        <TextValidator
                          onChange={onChange}
                          value={eval(field)} //eslint-disable-line
                          label={field}
                          name={field}
                          fullWidth
                          variant="outlined"
                          type="text"
                          placeholder={field}
                          // validators={['required']}
                          errorMessages={[`Please enter ${field}`]}
                        />
                      </Grid>
                    })
                  }

                  <Grid item lg={4} md={4} xs={12}>
                    <TextValidator
                      onChange={onChangeMoneyTransactionWhitelist}
                      value={moneyTransactionWhitelist} //eslint-disable-line
                      label={'moneyTransactionWhitelist'}
                      name={'moneyTransactionWhitelist'}
                      fullWidth
                      variant="outlined"
                      type="text"
                      placeholder={'moneyTransactionWhitelist'}
                    // validators={['required']}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} xs={12}>
                    <TextValidator
                      onChange={onChangeUssdWhitelist}
                      value={ussdWhitelist} //eslint-disable-line
                      label={'ussdWhitelist'}
                      name={'ussdWhitelist'}
                      fullWidth
                      variant="outlined"
                      type="text"
                      placeholder={'ussdWhitelist'}
                    // validators={['required']}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} xs={12}>
                    <TextValidator
                      onChange={onChangeMonitoringSmsList}
                      value={monitoringSmsList} //eslint-disable-line
                      label={'monitoring sms list'}
                      name={'monitoringSmsList'}
                      fullWidth
                      variant="outlined"
                      type="text"
                      placeholder={'monitoring sms list'}
                    // validators={['required']}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} xs={12}>
                    <TextValidator
                      onChange={onChangeEmailWhitelist}
                      value={emailWhitelist} //eslint-disable-line
                      label={'emailWhitelist'}
                      name={'emailWhitelist'}
                      fullWidth
                      variant="outlined"
                      type="text"
                      placeholder={'emailWhitelist'}
                    // validators={['required']}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} xs={12}>
                    <TextValidator
                      onChange={onChangeSmsWhitelist}
                      value={smsWhitelist} //eslint-disable-line
                      label={'smsWhitelist'}
                      name={'smsWhitelist'}
                      fullWidth
                      variant="outlined"
                      type="text"
                      placeholder={'smsWhitelist'}
                    // validators={['required']}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant="contained" type="submit" style={{ marginRight: '2%' }}> Update </Button>
                    <Button variant="contained" color="secondary" style={{ marginRight: '2%' }}
                      onClick={() => reset()}
                    > Reset</Button>
                  </Grid>

                </Grid>
              </ValidatorForm>
            </Box>
          </div>

        </DialogContent>
      </Dialog>




    </>
  );
}
