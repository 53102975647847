import React from 'react'
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import Container from "@material-ui/core/Container";
import highconfig from '../../../../../Assets/highly-customizable.png'
import intergration from '../../../../../Assets/intergarion.png'
import scalability from '../../../../../Assets/scalibility.png'
const Aspinprocess = () => {
    const classes = useStyle();
    return (
        <section className={classes.simpletodelier}>
            <Container>
                <Grid container>
                    <Grid item xs={12} md={12} sm={12}>
                        <h2>ASPin makes insurance simple to deliver​</h2>
                        <span></span>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4} md={4}>
                        <div className={classes.boxfeatures}>
                            <img src={highconfig} alt='highly configurable product' />
                            <h3>Easily Configurable Products </h3>
                            <p>With ASPin, you can streamline your insurance product launch process - our self-service platform lets you quickly create, configure, and launch insurance products on demand. With a no-code approach, you can get to market faster than ever before, without sacrificing quality or control.</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <div className={classes.boxfeatures}>
                            <img src={intergration} alt='Seemingly Integration' />
                            <h3>Seamless Integration </h3>
                            <p>Integrate your business with ease using ASPin - our API-first approach ensures seamless integration with our services from any environment. With our public APIs, you can create customized digital and embedded experiences that cater to your customers' unique needs. Empower your business with ASPin's flexible and agile API-driven architecture.</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <div className={classes.boxfeatures}>
                            <img src={scalability} alt='Maximum Scalability' />
                            <h3>Maximum Scalability </h3>
                            <p>Future-proof your business with ASPin - our cloud-native platform platform is designed to scale from the ground Up, enabling seamless handling of high transactional volumes. With ASPin, you can expand your business at a fraction of the cost and let our technological infrastructure take care of the rest. Experience unmatched scalability and efficiency with ASPin.</p>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default Aspinprocess