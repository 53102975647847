import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";



const AspinFeatureMobile = () => {
    const classes = useStyle();

    return (
        <>
            <Container>
                <Grid container className={classes.featureswrapper}>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className={classes.featurecustomer}>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className={classes.featurecontent}>
                            <h3>Customer Management</h3>
                            <p>Revolutionize your customer management with ASPin - say goodbye to hassles and hello to streamlined efficiency! Our platform provides a one-stop solution for managing your end customers with ease. Experience unmatched control and seamless management with ASPin.</p>
                            <ol>
                                <div className={classes.listwrapper}>
                                    <li>Streamline customer onboarding - choose your preferred channel and enjoy a seamless experience.</li>
                                </div>
                                <div className={classes.listwrapper}>

                                    <li>Simplify KYC management - effortlessly manage customer and dependent KYC details.</li>
                                </div>
                                <div className={classes.listwrapper}>

                                    <li>Effective customer communication - educate and connect with customers via our bulk SMS functionality.</li>
                                </div>
                                <div className={classes.listwrapper}>
                                    <li>Versatile API integration - engage customers on multiple sales channels, including USSD, chatbots, mobile apps, and web applications.</li>
                                </div>

                            </ol>
                        </div>
                    </Grid>
                </Grid>
                <Grid container className={classes.featureswrapper}>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className={classes.featureImgpolicy}>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className={classes.featurecontent}>
                            <h3>Policy Administration</h3>
                            <p>Experience the power of effortless growth - ASPIn's platform handles all your policy administration concerns, so you can focus on building your business.</p>
                            <ol>
                                <div className={classes.listwrapper}>
                                    <li>Automate policy issuance and administration for configured products, including lifecycle management. </li>
                                </div>
                                <div className={classes.listwrapper}>

                                    <li>Eliminate the need to manually track policy balances and lapses, saving you time and effort.</li>
                                </div>
                                <div className={classes.listwrapper}>
                                    <li>Enjoy payment solution flexibility with no vendor lock-in. Integrate with payment providers that align with your business needs.</li>
                                </div>
                                <div className={classes.listwrapper}>
                                    <li>Keep your end customers informed with transactional SMS updates, including payment reminders, receipts, and policy updates. Plus, maintain an audit trail of all communications.</li>
                                </div>

                            </ol>
                        </div>
                    </Grid>

                </Grid>
                <Grid container className={classes.featureswrapper}>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className={classes.featureclaim}>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className={classes.featurecontent}>
                            <h3>Claims Administration</h3>
                            <p>ASPin streamlines claims management by automating the entire process, from loss notification to payment. Our platform uses advanced analytics to detect fraud, assess risk, and expedite claims processing, reducing operational costs and improving customer satisfaction. With ASPin, insurers can focus on delivering exceptional customer experiences while we handle the heavy lifting of claims management.</p>
                            <ol>
                                <div className={classes.listwrapper}>
                                    <li>Enhanced claims validation functionalities: Aspin utilizes advanced algorithms and internal payment and policy records to quickly verify the validity of claims.</li>
                                </div>
                                <div className={classes.listwrapper}>

                                    <li>Simplified claim documentation collection: Aspin streamlines the process of collecting and organizing claim documentation, making it easier and faster for insurers to process claims.</li>
                                </div>
                                <div className={classes.listwrapper}>

                                    <li>Seamless customer communication: Aspin keeps customers informed via SMS throughout the claims process, enhancing customer satisfaction and loyalty.</li>
                                </div>
                                <div className={classes.listwrapper}>
                                    <li>Streamlined internal communication: Aspin's email integration makes it easy for claim assessors to collaborate and communicate internally, reducing claim processing time.</li>
                                </div>
                                <div className={classes.listwrapper}>
                                    <li>Automated claim payouts: Aspin enables insurers to quickly and securely disburse claim payments directly from the platform, improving efficiency and reducing operational costs.</li>
                                </div>
                            </ol>
                        </div>
                    </Grid>
                </Grid>
                <Grid container className={classes.featureswrapper}>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className={classes.featureImgreports}>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className={classes.featurecontent}>
                            <h3>Reporting and Data </h3>
                            <p>ASPin simplifies your reporting process by providing comprehensive reports to meet your business and compliance requirements. Stay on top of your regulatory obligations with ease, while gaining valuable insights into your business operations. With ASPin, you'll never have to worry about keeping up with the reporting burden again.</p>
                            <ol>
                                <div className={classes.listwrapper}>
                                    <li>Access a wide range of reports at your convenience through our self-service feature</li>
                                </div>
                                <div className={classes.listwrapper}>
                                    <li>Stay ahead of your competition with our comprehensive standard reports, specifically designed for the insurance industry.</li>
                                </div>
                                <div className={classes.listwrapper}>
                                    <li>Multiple formats supported, json, csv, excel and pdf.</li>
                                </div>
                                <div className={classes.listwrapper}>
                                    <li>Customize your reports to meet your unique business needs by leveraging our data APIs, which provide programmatic access to nonstandard and complex reporting features.</li>
                                </div>
                            </ol>
                        </div>
                    </Grid>


                </Grid>
            </Container>
        </>
    )
}

export default AspinFeatureMobile