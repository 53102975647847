import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { hasAuthourity } from '../../../../../lib/access.es6';

const PolicyHeader = (props) => {
  const classes = useStyle();
  const [showWarningReject, setShowWarningReject] = React.useState(false);

  const POLICY_STATUSES = {
    Un_Confirmed: 'Un_Confirmed',
    Rejected: 'Rejected',
    Discarded: 'Discarded',
  }


  const rejectPolicy = () => {
    setShowWarningReject(false)
    props.toggleOpenRejectPolicyDialog()
  }

  const status = props.policy ? props.policy.status : null;

  return (
    <section className={classes.claimsheader}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10} md={10}>
          <div className={classes.claimstitle}>
            <ul>
              <li>Policy Processing</li>
            </ul>
          </div>
        </Grid>
        {
          (status === POLICY_STATUSES.Un_Confirmed) &&

          <Grid item xs={12} sm={2} md={2} style={{display : hasAuthourity('APPROVE_POLICY') ? "block" : "none"}} >
            <Grid container spacing={2} className={classes.claimactionbtn}>
              <Grid item xs={12} sm={6} md={6}>
                <ButtonInc style={{ float: "right" }} onClick={() => {  props.setUpdatePolicyAction(props.updateActions.Approve)  }} >Approve</ButtonInc>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <ButtonInc style={{ float: "left", background: "#8F191C" }} onClick={() => setShowWarningReject(true)} >Reject</ButtonInc>
              </Grid>
            </Grid>
          </Grid>
        }

        {
          (status === POLICY_STATUSES.Rejected) &&

          <Grid item xs={12} sm={2} md={2}  style={{display : hasAuthourity('CAPTURE_POLICY') ? "block" : "none"}}>
            <Grid container spacing={2} className={classes.claimactionbtn}>
              <Grid item xs={12} sm={6} md={6}>
                <ButtonInc style={{ float: "right" }} onClick={() => {  props.setUpdatePolicyAction(props.updateActions.ReConfirm)  }}  >Re-Confirm</ButtonInc>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <ButtonInc style={{ float: "left", background: "#8F191C" }} onClick={() => {  props.setUpdatePolicyAction(props.updateActions.Discard)  }}  >Discard</ButtonInc>
              </Grid>
            </Grid>
          </Grid>
        }



      </Grid>

      <AlertDialog
        warning
        show={showWarningReject}
        size="sm"
        title={'Are you sure you want to reject'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { setShowWarningReject(false) }}
        onCancel={() => { rejectPolicy() }}
        confirmBtnText={'Cancel'}
        showCancel={true}
        showConfirm={true}
        cancelBtnText={'reject'}
        confirmBtnCssClass
      >
      </AlertDialog>


    </section>
  )
}

export default PolicyHeader