import React from 'react';
import { hashHistory } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { deepOrange } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import { Button } from '@mui/material';
import * as utils from '../../lib/utils.es6';
import MainPageTitle from '../MainPageTitle/index.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { localisedText } from '../../lib/localisation.es6';
import { updatePolicyRequest } from '../../containers/Customer/actions.es6';
import {
  getPolicyProductRequest,
  initiatePolicyMobileMoneyPaymentRequest,
  resetMobileMoneyInitiation,
} from '../../containers/PolicyList/actions.es6';
import {
  getPartnerGuid,
  isSouthAfricanPartner,
  partnerCanDebit,
  partnerCanEditDebit,
  partnerHasMobilePopupPayment,
  partnerHasPartialPayments,
  partnerPolicyFieldsContains,
} from '../../lib/access.es6';
import {
  funeralPopUpPaymentPartnerList, PAYMENT_METHODS,
  SECONDARY_THEME_COLOR_SHADE_1,
} from '../../lib/constants';
import { getExtendedFamilyProductConfigsRequest } from '../../containers/NewProductList/actions.es6';
import DownloadDocument from '../DownloadDocument/DownloadDocument';
import * as documentActions from '../../containers/DocumentGeneration/actions.es6';

export class Policies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
      openConfirmDelete: false,
      openEditPolicy: false,
      policy: null,
      partialAmount: 0,
      policyToDownload: null,
    };
  }

  componentWillMount() {
    this.updateLanguage();
    this.props.getPolicies();
    this.props.dispatch(documentActions.getTemplateDocumentsRequest('Policy'));
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'Policies',
      this.state.labels
    );
  }

  dateHandler(type, event) {
    event.preventDefault();
    const rawDate = utils.formatDate(new Date(event.target.value));
    if (type === 'spouse_birthday') {
      this.setState({ spouse_birthday: rawDate });
    }
  }

  handleCloseConfirmDialog() {
    this.setState({ openConfirmDelete: false });
  }

  handleOpenConfirmDialog() {
    this.setState({ openConfirmDelete: true });
  }

  handleCloseEditDialog() {
    this.setState({ openEditPolicy: false });
  }

  handleOpenEditDialog(policy) {
    this.setState({ openEditPolicy: true, policy });
    this.handleCloseActionMenu();
  }

  setPolicyToDownloadDocumentFor(policy) {
    this.setState({policyToDownload: policy})
    this.handleCloseActionMenu();
  }

  deletePolicy() {
    this.props.deletePolicyHandler(this.state.policy);
    this.setState({ openConfirmDelete: false, policy: null });
  }

  // TODO: Do we need to pass premium to an handler as when deleting?
  editPolicy() {
    const form = new FormData(document.getElementById('editDialogForm'));
    const payload = {
      ...this.state.policy,
      beneficiary_name: form.get('beneficiary_name'),
      spouse_name: form.get('spouse_name'),
      spouse_dob: this.state.spouse_birthday,
    };
    this.props.dispatch(updatePolicyRequest(payload));
    this.handleCloseEditDialog();
  }

  // TODO: Get these statuses from the API
  nonCancellableStatuses() {
    return ['Cancelled', 'Non_Payment', 'Not_Taken_Up'];
  }

  cancellable() {
    const status = this.state.policy.status;
    return !this.nonCancellableStatuses().includes(status);
  }

  beneficiaryVisible() {
    return partnerPolicyFieldsContains('beneficiary_name');
  }

  beneficiaryNameHeader() {
    const labels = this.state.labels;
    const beneficiaryStyle = {
      display: this.beneficiaryVisible() ? '' : 'none',
    };
    return (
      <TableCell className="table-cell-header" style={beneficiaryStyle}>
        {labels.beneficiaryFullname}
      </TableCell>
    );
  }

  beneficiaryNameRow(policy) {
    const beneficiaryStyle = {
      display: this.beneficiaryVisible() ? '' : 'none',
    };
    return (
      <TableCell className="col-xs-1" style={beneficiaryStyle}>
        {policy.beneficiary_name}
      </TableCell>
    );
  }

  beneficiaryNameDialog(policy) {
    const labels = this.state.labels;
    const beneficiaryStyle = {
      display: this.beneficiaryVisible() ? 'block' : 'none',
    };
    return (
      <div className="form-group" style={beneficiaryStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.beneficiaryFullname}
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <input
            id="beneficiary_name"
            name="beneficiary_name"
            className="form-control col-md-7 col-xs-12"
            type="text"
            defaultValue={policy.beneficiary_name}
          />
        </div>
      </div>
    );
  }

  spouseNameVisible() {
    return partnerPolicyFieldsContains('spouse_name');
  }

  spouseNameHeader() {
    const labels = this.state.labels;
    const spouseStyle = {
      display: this.spouseNameVisible() ? '' : 'none',
    };
    return (
      <TableCell className="table-cell-header" style={spouseStyle}>
        {labels.spouseName}
      </TableCell>
    );
  }

  spouseNameRow(policy) {
    const spouseStyle = {
      display: this.spouseNameVisible() ? '' : 'none',
    };
    return (
      <TableCell className="col-xs-1" style={spouseStyle}>
        {policy.spouse_name}
      </TableCell>
    );
  }

  spouseDobVisible() {
    return partnerPolicyFieldsContains('spouse_dob');
  }

  spouseDobHeader() {
    const labels = this.state.labels;
    const spouseStyle = {
      display: this.spouseDobVisible() ? '' : 'none',
    };
    return (
      <TableCell className="table-cell-header" style={spouseStyle}>
        {labels.spouseDateOfBirth}
      </TableCell>
    );
  }

  spouseDobRow(policy) {
    const spouseStyle = {
      display: this.spouseDobVisible() ? '' : 'none',
    };
    return (
      <TableCell className="col-xs-1" style={spouseStyle}>
        {policy.spouse_dob}
      </TableCell>
    );
  }

  spouseMsisdnVisible() {
    return partnerPolicyFieldsContains('spouse_msisdn');
  }

  spouseMsisdnHeader() {
    const labels = this.state.labels;
    const spouseStyle = {
      display: this.spouseMsisdnVisible() ? '' : 'none',
    };
    return (
      <TableCell className="table-cell-header" style={spouseStyle}>
        {labels.spouseMsisdn}
      </TableCell>
    );
  }

  spouseMsisdnRow(policy) {
    const spouseStyle = {
      display: this.spouseMsisdnVisible() ? '' : 'none',
    };
    return (
      <TableCell className="col-xs-1" style={spouseStyle}>
        {policy.spouse_msisdn}
      </TableCell>
    );
  }

  spouseIdVisible() {
    return partnerPolicyFieldsContains('spouse_id');
  }

  spouseIdHeader() {
    const labels = this.state.labels;
    const spouseStyle = {
      display: this.spouseIdVisible() ? '' : 'none',
    };
    return (
      <TableCell className="table-cell-header" style={spouseStyle}>
        {labels.spouseId}
      </TableCell>
    );
  }

  spouseIdRow(policy) {
    const spouseStyle = {
      display: this.spouseIdVisible() ? '' : 'none',
    };
    return (
      <TableCell className="col-xs-1" style={spouseStyle}>
        {policy.spouse_id}
      </TableCell>
    );
  }

  debitVisible() {
    return partnerCanDebit();
  }

  debitEditable() {
    return partnerCanEditDebit();
  }

  debitHeader() {
    const labels = this.state.labels;
    const debitStyle = {
      display: this.debitVisible() ? '' : 'none',
    };
    return (
      <TableCell className="table-cell-header" style={debitStyle}>
        {labels.debit}
      </TableCell>
    );
  }

  debitRow(policy) {
    const debitStyle = {
      display: this.debitVisible() ? '' : 'none',
    };
    return (
      <TableCell className="col-xs-1" style={debitStyle}>
        <Checkbox required checked={policy.debit} disabled />
      </TableCell>
    );
  }

  toggleDebit() {
    const debit = this.state.policy.debit;
    const updatedPolicy = { ...this.state.policy, debit: !debit };
    this.setState({ policy: updatedPolicy });
  }

  debitDialogDisPlay(policy) {
    const labels = this.state.labels;
    const debitStyle = {
      display: this.debitEditable() ? 'block' : 'none',
    };
    return (
      <div className="form-group" style={debitStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.debit}
          <span>*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <Checkbox
            id="debit"
            name="debit"
            required
            checked={policy.debit}
            onChange={this.toggleDebit.bind(this)}
          />
        </div>
      </div>
    );
  }

  productNameDisplay(policy) {
    return `${policy.product_name} (${policy.product_cover_type})`;
  }

  policyNumberDisplay(policy) {
    return `${policy.policy_number}`;
  }

  handleActionMenuButtons(policy, event) {
    this.setState({ anchorElAction: event.currentTarget, policy });
  }

  handleCloseActionMenu() {
    this.setState({ anchorElAction: null });
  }

  handleClosePaymentMenu() {
    this.setState({ anchorElPayment: null });
  }

  getPolicyProduct(policy, event) {
    const productGuid = policy.product_guid;
    this.props.dispatch(getPolicyProductRequest(productGuid));
    this.props.dispatch(getExtendedFamilyProductConfigsRequest(productGuid));
    this.setState({ anchorElPayment: event.currentTarget, policy });
  }

  initiatePayment(amountInCents) {
    const partnerGuid = getPartnerGuid();
    const payload = {
      policy_guid: this.state.policy.guid,
      amount_in_cents: amountInCents,
      partner: partnerGuid,
      customer_guid: this.state.policy.customer_guid,
      type: PAYMENT_METHODS.STRIKE_MONEY,
    };

    if (partnerGuid === 'atlehangguid') {
      payload.type = PAYMENT_METHODS.BANK_CARD;
    }

    if (partnerGuid === 'MMI_Ghana') {
      payload.type = PAYMENT_METHODS.RECURRING_DEBIT_ORDER;
    }

    this.props.dispatch(initiatePolicyMobileMoneyPaymentRequest(payload));
    this.handleClosePaymentMenu();
  }

  initiatePolicyMobilePayment(premium) {
    this.initiatePayment(premium.amount_in_cents);
  }

  handlePartialPaymentAmountInput(event) {
    this.setState({ partialAmount: event.target.value });
  }

  initiatePolicyPartialPayment() {
    this.initiatePayment(this.state.partialAmount * 100);
  }

  // NOTE: What is this for? There is no saga handling this action...
  closeMobileMoneyConfirmation() {
    this.props.dispatch(resetMobileMoneyInitiation());
  }

  comparePolicy(policyA, policyB) {
    if (policyA.active !== policyB.active) {
      if (policyA.active) {
        return -1;
      }
      return 1;
    }
    if (!policyA.start_date || !policyB.start_date) {
      if (policyA.guid > policyB.guid) {
        return -1;
      }
      return 1;
    }

    if (
      new Date(policyA.start_date).getTime() ===
      new Date(policyB.start_date).getTime()
    ) {
      return 0;
    }
    if (new Date(policyA.start_date) > new Date(policyB.start_date)) {
      return -1;
    }
    return 1;
  }

  getBenefitLabel(benefit, labels) {
    switch (benefit.type.trim()) {
      case 'Hospital':
        return labels.nightlyBenefit;
      case 'Death':
        return labels.fixedBenefit;
      case 'Cashback':
        return labels.cashback;
      case 'Property':
        return labels.propertyAssistanceBenefit;
      case 'Income_Loss':
        return labels.incomeLossBenefit;
      case 'Personal_Accident':
        return labels.personalAccident;
      case 'Medical_Expense':
        return labels.medicalExpense;
      case 'Inpatient':
        return labels.inpatient;
      case 'Maternity':
        return labels.maternity;
      case 'Outpatient':
        return labels.outpatient;
      default:
        return benefit.type;
    }
  }

  getInitiateMoneyDialogTitle() {
    const { labels } = this.state;

    const partnerGuid = getPartnerGuid();

    if (funeralPopUpPaymentPartnerList.includes(partnerGuid)) {
      return {
        title: labels.cardPaymentInitiatedTitle,
        message: labels.cardPaymentConfirmation,
      };
    }
    return {
      title: labels.strikeInitiatedTitle,
      message: labels.mobileMoneyConfirmation,
    };
  }

  getBenefits(policy) {
    const labels = this.state.labels;

    return (
      policy.benefits && (
        <div>
          {policy.benefits.map((benefit, index) => (
            <Typography variant="caption" component="span" key={index} noWrap>
              <strong>{this.getBenefitLabel(benefit, labels)}:</strong>&nbsp;
              {benefit.amount}
              <br />
            </Typography>
          ))}
        </div>
      )
    );
  }

  render() {
    const { labels, anchorElAction, anchorElPayment } = this.state;
    const policy = this.state.policy ? this.state.policy : {};
    const styles = { backgroundColor: deepOrange[400], color: '#FFF' };
    const confirmDeleteActions = [
      <Button style={styles} onClick={this.handleCloseConfirmDialog.bind(this)}>
        {labels.no}
      </Button>,
      <Button style={styles} onClick={this.deletePolicy.bind(this)}>
        {labels.yes}
      </Button>,
    ];
    const editPolicyActions = [
      <Button style={styles} onClick={this.handleCloseEditDialog.bind(this)}>
        {' '}
        {labels.cancel}
      </Button>,
      <Button style={styles} onClick={this.editPolicy.bind(this)}>
        {' '}
        {labels.edit}
      </Button>,
    ];
    const policies = this.props.policies ? this.props.policies : [];
    policies.sort(this.comparePolicy);
    const templateDocuments = this.props.documentGenerationData
      .templateDocuments;

    const policiesData = policies.map(aPolicy => (
      <TableRow key={aPolicy.guid}>
        <TableCell>{this.policyNumberDisplay(aPolicy)}</TableCell>
        <TableCell>{this.productNameDisplay(aPolicy)}</TableCell>
        {this.beneficiaryNameRow(aPolicy)}
        {this.spouseNameRow(aPolicy)}
        {this.spouseMsisdnRow(aPolicy)}
        {this.spouseDobRow(aPolicy)}
        {this.spouseIdRow(aPolicy)}
        {this.debitRow(aPolicy)}
        <TableCell>{utils.formatStatus(aPolicy.status)}</TableCell>
        <TableCell>{utils.pascalCase(aPolicy.active.toString())}</TableCell>
        <TableCell>{aPolicy.start_date}</TableCell>
        <TableCell>{aPolicy.end_date}</TableCell>
        <TableCell>{this.getBenefits(aPolicy)}</TableCell>
        <TableCell>{aPolicy.channel}</TableCell>
        {isSouthAfricanPartner() ? (
          <TableCell>{aPolicy.total_payable_premium / 100}</TableCell>
        ) : (
          <TableCell>{aPolicy.balance_in_cents / 100}</TableCell>
        )}
        <TableCell
          style={{ display: partnerHasMobilePopupPayment() ? '' : 'none' }}
        >
          <Button
            disabled={
              !aPolicy.active ||
              aPolicy.status === 'Cancelled' ||
              aPolicy.product_type === 'Free'
            }
            variant="contained"
            onClick={this.getPolicyProduct.bind(this, aPolicy)}
            style={{ backgroundColor: SECONDARY_THEME_COLOR_SHADE_1 }}
          >
            {labels.pay}
          </Button>
          <Menu
            anchorEl={anchorElPayment}
            open={Boolean(anchorElPayment)}
            onClose={this.handleClosePaymentMenu.bind(this)}
          >
            {this.props.policyData.popupLoader ? (
              <center>
                <CircularProgress />
              </center>
            ) : (
              <div style={{ minWidth: '100px' }}>
                {partnerHasPartialPayments() ? (
                  <MenuItem>
                    <form className="form-inline">
                      <div className="row">
                        <div className="col-sm-8">
                          <input
                            onChange={this.handlePartialPaymentAmountInput.bind(
                              this
                            )}
                            type="number"
                            min="1"
                            className=" form-control"
                            placeholder={labels.partialPaymentPlaceHolder}
                          />
                        </div>
                        <div className="col-sm-4">
                          <button
                            type="button"
                            onClick={this.initiatePolicyPartialPayment.bind(
                              this
                            )}
                            className="btn btn-success form-control"
                          >
                            {labels.strikeInitiatedButtonConfirm}
                          </button>
                        </div>
                      </div>
                    </form>
                  </MenuItem>
                ) : (
                  <div />
                )}
                {this.props.policyData.productPremium.premiums
                  .filter(x => x.payment_method === 'Mobile_Money')
                  .map(premium => (
                    <MenuItem
                      onClick={this.initiatePolicyMobilePayment.bind(
                        this,
                        premium
                      )}
                      key={premium.guid}
                    >
                      {' '}
                      {`${premium.cardinality} ${
                        premium.granularity
                      } ${premium.amount_in_cents / 100}`}{' '}
                    </MenuItem>
                  ))}
              </div>
            )}
          </Menu>
        </TableCell>
        <TableCell>
          <Button
            variant="flat"
            onClick={this.handleActionMenuButtons.bind(this, aPolicy)}
            style={{ backgroundColor: deepOrange[400], color: '#FFF' }}
            className="btn btn-info"
          >
            {labels.action}
          </Button>

          {this.state.policy && this.state.policy.guid === aPolicy.guid && (
            <Menu
              anchorEl={anchorElAction}
              open={Boolean(anchorElAction)}
              onClose={this.handleCloseActionMenu.bind(this)}
            >
              <MenuItem onClick={this.handleOpenEditDialog.bind(this, aPolicy)}>
                {' '}
                {labels.editPolicyDetails}{' '}
              </MenuItem>
                <MenuItem
                  onClick={this.setPolicyToDownloadDocumentFor.bind(
                    this,
                    aPolicy
                  )}
                >
                  {labels.downloadPolicyDocument}
                </MenuItem>
              {this.state.anchorElAction && this.cancellable() ? (
                <MenuItem onClick={this.handleOpenConfirmDialog.bind(this)}>
                  {' '}
                  {labels.cancelPolicy}{' '}
                </MenuItem>
              ) : (
                ''
              )}
            </Menu>
          )}
        </TableCell>
      </TableRow>
    ));

    return (
      <div className="col-md-12 col-sm-12 col-xs-12">
        <MainPageTitle pageTitle={labels.titlePolicies} />
        {policies.length === 0 ? (
          <center>{labels.nothingFound}</center>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table-cell-header">
                      {labels.policyNumber}
                    </TableCell>
                    <TableCell className="table-cell-header">
                      {labels.productName}
                    </TableCell>
                    {this.beneficiaryNameHeader()}
                    {this.spouseNameHeader()}
                    {this.spouseMsisdnHeader()}
                    {this.spouseDobHeader()}
                    {this.spouseIdHeader()}
                    {this.debitHeader()}
                    <TableCell className="table-cell-header">
                      {labels.status}
                    </TableCell>
                    <TableCell className="table-cell-header">
                      {labels.active}
                    </TableCell>
                    <TableCell className="table-cell-header">
                      {labels.startDate}
                    </TableCell>
                    <TableCell className="table-cell-header">
                      {labels.validUntil}
                    </TableCell>
                    <TableCell className="table-cell-header">
                      {labels.benefits}
                    </TableCell>
                    <TableCell className="table-cell-header">
                      {labels.channel}
                    </TableCell>
                    {isSouthAfricanPartner() ? (
                      <TableCell className="table-cell-header">
                        Total Premium
                      </TableCell>
                    ) : (
                      <TableCell className="table-cell-header">
                        {labels.balance}
                      </TableCell>
                    )}
                    <TableCell
                      className="table-cell-header"
                      style={{
                        display: partnerHasMobilePopupPayment() ? '' : 'none',
                      }}
                    >
                      {labels.initiatePayment}
                    </TableCell>
                    <TableCell className="table-cell-header">
                      {labels.action}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="policies-list">
                  {this.props.showMore
                    ? policiesData.slice(0, 3)
                    : policiesData}
                </TableBody>
              </Table>
            </TableContainer>

              <DownloadDocument
                labels={this.state.labels}
                identifier="Policies"
                entityDocument={
                  this.props.documentGenerationData.entityDocument
                }
                entity={this.state.policyToDownload}
                resetEntity={() => this.setState({ policyToDownload: null })}
                templates={templateDocuments}
                getTemplates={() => this.props.dispatch(documentActions.getTemplateDocumentsRequest('Policy'))}
                getTemplatesLoader={this.props.documentGenerationData.getTemplateDocumentloader}
                isEmptyTemplatesResponse={this.props.documentGenerationData.isEmptyTemplates}
                downloadDocument={guid => {
                  this.props.dispatch(
                    documentActions.downloadDocumentRequest({
                      entity_guid: this.state.policyToDownload.guid,
                      template_guid: guid,
                    })
                  );
                }}
                resetGetTemplateDocuments={() => {
                  this.props.dispatch(documentActions.resetGetTemplateDocuments());
                }}
                resetDownloadDocument={() => {
                  this.props.dispatch(documentActions.resetDocumentEntity());
                }}
                showDownloadDocumentProgressAlert={
                  this.props.documentGenerationData.downloadDocumentLoader
                }
                showDownloadDocumentSuccessAlert={
                  this.props.documentGenerationData.downloadDocumentSuccess
                }
                showDownloadDocumentErrorAlert={
                  !!this.props.documentGenerationData.downloadDocumentError
                }
                downloadDocumentError={
                  this.props.documentGenerationData.downloadDocumentError
                }
                resetDownloadDocumentSuccessAlert={() => {
                  this.props.dispatch(
                    documentActions.resetDownloadDocumentSuccess()
                  );
                }}
                resetDownloadDocumentErrorAlert={() => {
                  this.props.dispatch(
                    documentActions.resetDownloadDocumentError()
                  );
                }}

                goToTemplatesPage={() => {
                  hashHistory.push('admin/view_doc_templates');
                }}
              />
          </>
        )}
        <Button
          id="more_policies"
          href="#admin/policies"
          style={{
            display: utils.displayValue(
              this.props.showMore && policies.length !== 0
            ),
            backgroundColor: SECONDARY_THEME_COLOR_SHADE_1,
            marginTop: '1%',
          }}
          size="small"
          variant="contained"
        >
          {labels.morePolicies}
        </Button>
        <Dialog
          open={this.state.openConfirmDelete}
          onClose={this.handleCloseConfirmDialog.bind(this)}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">
            {labels.confirmDeletePolicy}
          </DialogTitle>
          <DialogContent>{labels.confirmDeletePolicyText}</DialogContent>
          <DialogActions>
            {confirmDeleteActions.map((item, index) => (
              <div key={index}> {item}</div>
            ))}
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openEditPolicy}
          onClose={this.handleCloseEditDialog.bind(this)}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">{labels.editPolicy}</DialogTitle>
          <DialogContent>
            <form
              id="editDialogForm"
              className="form-horizontal form-label-left"
              noValidate
            >
              <div className="form-group">
                <label className="control-label col-md-4 col-sm-4 col-xs-12">
                  {labels.productName}
                  <span>*</span>
                </label>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <input
                    id="product_name"
                    name="product_name"
                    required
                    readOnly
                    className="form-control col-md-7 col-xs-12"
                    type="text"
                    defaultValue={policy.product_name}
                  />
                </div>
              </div>
              {this.beneficiaryNameDialog(policy)}
              <div className="form-group">
                <label className="control-label col-md-4 col-sm-4 col-xs-12">
                  {labels.spouseName}
                </label>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <input
                    id="spouse_name"
                    name="spouse_name"
                    className="form-control col-md-7 col-xs-12"
                    type="text"
                    defaultValue={policy.spouse_name}
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  className="control-label col-md-4 col-sm-4 col-xs-12"
                  htmlFor="spouse_birthday"
                >
                  {labels.spouseDateOfBirth}
                  <span />
                </label>
                <div className="col-md-6 col-sm-3 col-xs-12">
                  <input
                    type="date"
                    className="form-control"
                    value={this.state.spouse_birthday || policy.spouse_dob}
                    onChange={this.dateHandler.bind(this, 'spouse_birthday')}
                    id="spouse_birthday"
                    aria-describedby="emailHelp"
                    placeholder={labels.spouseDateOfBirth}
                    required
                  />
                </div>
              </div>
              {this.debitDialogDisPlay(policy)}
              <div className="form-group">
                <label className="control-label col-md-4 col-sm-4 col-xs-12">
                  {labels.status}
                  <span>*</span>
                </label>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <input
                    id="status"
                    name="status"
                    required
                    readOnly
                    className="form-control col-md-7 col-xs-12"
                    type="text"
                    defaultValue={policy.status}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-md-4 col-sm-4 col-xs-12">
                  {labels.active}
                  <span>*</span>
                </label>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <Checkbox
                    id="active"
                    name="active"
                    required
                    checked={policy.active}
                    disabled
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-md-4 col-sm-4 col-xs-12">
                  {labels.startDate}
                  <span>*</span>
                </label>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <input
                    id="start_date"
                    name="start_date"
                    required
                    readOnly
                    className="form-control col-md-7 col-xs-12"
                    type="text"
                    defaultValue={policy.start_date}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-md-4 col-sm-4 col-xs-12">
                  {labels.validUntil}
                  <span>*</span>
                </label>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <input
                    id="end_date"
                    name="end_date"
                    required
                    readOnly
                    className="form-control col-md-7 col-xs-12"
                    type="text"
                    defaultValue={policy.end_date}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-md-4 col-sm-4 col-xs-12">
                  {labels.channel}
                  <span>*</span>
                </label>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <input
                    id="channel"
                    name="channel"
                    required
                    readOnly
                    className="form-control col-md-7 col-xs-12"
                    type="text"
                    defaultValue={policy.channel}
                  />
                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            {editPolicyActions.map((item, index) => (
              <div key={index}> {item}</div>
            ))}
          </DialogActions>
        </Dialog>

        <Dialog
          open={
            !!this.props.policyData.mobileMoneyTransaction ||
            !!this.props.policyData.errors
          }
          onClose={this.closeMobileMoneyConfirmation.bind(this)}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">
            {this.getInitiateMoneyDialogTitle().title}
          </DialogTitle>
          <DialogContent>
            {this.props.policyData.mobileMoneyTransaction
              ? this.getInitiateMoneyDialogTitle().message
              : this.props.policyData.errors
              ? this.props.policyData.errors.message
              : ''}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              style={{ backgroundColor: SECONDARY_THEME_COLOR_SHADE_1 }}
              onClick={this.closeMobileMoneyConfirmation.bind(this)}
            >
              {labels.strikeInitiatedButton}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect(state => ({
  globalData: state.global,
  policyData: state.policyList,
  documentGenerationData: state.documentGeneration,
}))(Policies);
