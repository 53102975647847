import React, { forwardRef, useState } from "react";
import ClaimDocuments from "./documents/ClaimDocuments";
import AddClaimDocuments from "./documents/AddClaimDocuments";
import DeleteClaimDocument from "./documents/DeleteClaimDocument";
import { createClaimFile, deleteClaimDocument, updateClaim } from "../lib/api";
import { updateStatus } from "../lib/helpers";

const Documents = forwardRef(({ claim, refetchClaim }, ref) => {
    const [description, setDescription] = useState('');
    const [documentToDelete, setDocumentToDelete] = useState(null);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [error, setError] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [deleteError, setDeleteError] = useState(null);


    const saveClaimUploadDetails = async (doc) => {
      try {
        await createClaimFile(doc);
        const formData = {...claim, received_files: [2]};
        const updatedFormData = updateStatus(formData);

        const payload = {
          ...claim,
          ...updatedFormData,
        };

      
        await updateClaim(payload);
        refetchClaim();
      } catch (error) {
        console.error('Could not upload:', error);
        setError('Failed to upload document');
      }
    };

    const handleDeleteDocument = async (document) => {
      setIsDeleting(true);
      try {
        await deleteClaimDocument({
          claim_guid: claim.guid,
          claim_doc_guid: document.guid
        });
        setDeleteSuccess(true);
        setIsDeleting(false);
        refetchClaim();
      } catch (error) {
        const message = error.response && error.response.data ? 
          (error.response.data.message || error.response.data.error) : 
          (error.message || "Failed to delete document");
        setDeleteError(message);
        setIsDeleting(false);
      }
    };

    const resetDeleteStates = () => {
      setDocumentToDelete(null);
      setDeleteSuccess(false);
      setDeleteError(null);
      setIsDeleting(false);
    };

    return (
      <>
        <ClaimDocuments
          setDescription={setDescription}
          claimDocuments={claim.received_files}
          setClaimDocumentToDelete={setDocumentToDelete}
          toggleMissingDocs={() => {}}
          openUploadDialog={() => setOpenUploadDialog(true)}
          type={claim.type}
        />

        <AddClaimDocuments
          description={description}
          toggleClaimDocumentsDialog={() => setOpenUploadDialog(!openUploadDialog)}
          openClaimDocumentsDialog={openUploadDialog}
          claim_guid={claim.guid}
          claimGuid={claim.guid}
          saveClaimDocument={saveClaimUploadDetails}
          error={error}
          setError={setError}
        />

        <DeleteClaimDocument
          DocumentToDelete={documentToDelete}
          deleteClaimDocument={handleDeleteDocument}
          resetDocumentToDelete={resetDeleteStates}
          showDeleteDocumentProgressAlert={isDeleting}
          showDeleteDocumentSuccessAlert={deleteSuccess}
          showDeleteDocumentErrorAlert={!!deleteError}
          deleteDocumentError={deleteError}
          resetDeleteDocumentSuccessAlert={resetDeleteStates}
          resetDeleteDocumentErrorAlert={resetDeleteStates}
        />
      </>
    );
});

export default Documents;
