import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  userInfo: {},
  loader: false,
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case types.CURRENT_PROFILE_REQUEST:
    case types.CURRENT_PROFILE_UPDATE_REQUEST:
      return { ...state, loader: true, errors: null, userInfo: {} };

    case types.CURRENT_PROFILE_SUCCESS:
    case types.CURRENT_PROFILE_UPDATE_SUCCESS:
      return { ...state, userInfo: action.payload, loader: false };

    // TODO: Update userInfo only if the request succeeded
    case types.CURRENT_PROFILE_FAIL:
    case types.CURRENT_PROFILE_UPDATE_FAIL:
      return { ...state, loader: false, errors: action.payload };

    case types.USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
}
