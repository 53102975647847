import React from 'react';
import Loader from 'react-loader';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import CustomersClaims from '../../components/CustomersClaims/index.es6';
import * as actions from './actions.es6';
import * as globalActions from '../Main/actions.es6';
import { localisedText } from '../../lib/localisation.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';

export class ClaimsOpen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
    };
  }

  componentWillMount() {
    this.props.dispatch(actions.openClaimsRequest());
    this.updateLanguage();
    this.props.dispatch(globalActions.hideCustomerBar());
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'ClaimsOpen',
      this.state.labels
    );
  }

  render() {
    const labels = this.state.labels;
    return (
      <Loader loaded={!this.props.claimOpenData.loader} color="#ff834f">
        <CustomersClaims
          claims={this.props.claimOpenData.openClaimList}
          title={labels.openClaims}
          columnWidth="col-md-12"
          showMore={false}
        />
      </Loader>
    );
  }
}

export default connect(state => ({
  claimOpenData: state.openClaimsList,
  globalData: state.global,
}))(ClaimsOpen);
