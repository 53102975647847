import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';
import TableInc from '../../../../../shared-ui/TableInc';
import { formatDateLocale } from '../../../../../lib/utils.es6';
import SkeletonWrapper from '../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import { LinearProgressWithLabel } from '../../../../../components/LinearProgressWithLabel';


const Batches = (props) => {
  const classes = useStyle();


  const batches = props.batches || [];
  console.log(batches, "batches")

  if (props.loading) return <SkeletonWrapper type="table" loading={true} />

  const progressPercentage = (batch) => {
    const done = batch.succeeded + batch.failed
    const percentageRemaining = done / batch.size * 100
    if (isNaN(percentageRemaining)) return 0
    return percentageRemaining > 100 ? 100 : percentageRemaining
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}  >
          <Grid container spacing={2} className={classes.claimdocswrapper}>
            <Grid item xs={12} sm={12} md={12}>

              <TableInc
                columns={[
                  { title: 'Batch Number', field: 'batch_number', render: rowData => rowData.id || "N/A" },
                  { title: 'Progress', field: 'size', render: rowData => <LinearProgressWithLabel value={progressPercentage(rowData)} /> },
                  { title: 'Total Size', field: 'size' },
                  { title: 'Succeeded', field: 'succeeded' },
                  { title: 'Failed', field: 'failed' },
                  { title: 'Created At', field: 'created_at', render: rowData => formatDateLocale(rowData.created_at) || "N/A" },
                  { title: 'Created By', field: 'web_agent_name', render: rowData => rowData.web_agent_name || "N/A" },

                  {
                    title: 'Data File', field: 'filename', render: rowData => {
                      return <Tooltip title={rowData.filename}>
                        <span style={{ cursor: "pointer" }}>
                          <a href={rowData.url} target='_blank' rel="noopener noreferrer" >
                            {rowData.filename}
                          </a>
                        </span>
                      </Tooltip>
                    }
                  },

                  {
                    title: 'Actions', field: 'created_At', render: rowData => {
                      if (rowData.failed) {
                        return <div className={classes.actionbtn}>
                          <Tooltip title="View Entries">
                            <span style={{ cursor: "pointer" }} ><VisibilityIcon
                              onClick={() => props.viewFailedEnteries(rowData.id)}
                              sx={{ color: " #4B7E34" }} /></span>
                          </Tooltip>
                        </div>
                      } return ''

                    }
                  },


                ]}

                // data = {
                //   batches.batches
                // }

                data={query =>
                  new Promise((resolve, reject) => {
                      resolve({
                          data: [...batches.data],
                          page: batches.page - 1,
                          totalCount: batches.total,
                      });
                  })
              }

                options={{
                  showTitle: false,
                  pageSizeOptions: [1, 5, 10, 20, 50, 100],
                  pageSize: batches.page_size,

                }}

                onChangeRowsPerPage = {(pageSize) => {
                  console.log(pageSize, "pageSize")
                  props.onPageChange(batches.page, pageSize);
                }}

                onChangePage = {(page) => {
                  console.log(page, "page")
                  props.onPageChange(page + 1, batches.page_size);
                }
              }

              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Batches
