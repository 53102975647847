import React, { useState } from 'react';
import { Drawer, List, ListItem, IconButton, Button, styled } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';
import posthog from 'posthog-js';
import { getPartnerGuid, getUserEmail, getUserFullname } from '../../lib/access.es6';



const CustomListItem = styled(ListItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    backgroundColor: 'transparent', // Remove hover effect
  },
  textDecoration: 'none',
}));



const HelpDrawerInc = (props) => {
  const [open, setOpen] = useState(false);

  // Dynamically set the current page URL as the link
  const currentURL = window.location.href;

  const helpGuides = {
    customer: [
      { title: 'How to register a customer', link: currentURL },
      { title: 'How to search for a customer', link: currentURL },
      { title: 'How to buy a policy for a customer', link: currentURL },
      { title: 'How to  edit a customer details', link: currentURL },
      { title: 'Resetting your password', link: currentURL },
    ],
    policy: [
      { title: 'Privacy Policy Overview', link: currentURL },
      { title: 'Terms and Conditions', link: currentURL },
    ],
    claim: [
      { title: 'How to File a Claim', link: currentURL },
      { title: 'Claim Process Explained', link: currentURL },
    ],
  };

  const toggleDrawer = () => {
    setOpen(!open);
    posthog.capture('clicked_help_guides_button', {
      email: `${getUserEmail()}`,
      name: `${getUserFullname()}`,
      partner: `${getPartnerGuid()}`,
      timestamp: new Date().toLocaleString(),
    });
  };

  const guides = helpGuides[props.section] || [];
  console.log("help articles")
  return (
    <>
      <Button
        onClick={toggleDrawer}
        variant="contained"
        endIcon={<HelpIcon />}
        style={{
          background: "rgb(3, 42, 55)",
          color: "#fff !important",
          borderRadius: "8px",
          fontFamily: "Open Sans",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "15px",
          boxShadow: "none",
          padding: "10px",
          textTransform: "capitalize",
          float: "right",
          marginTop: `${props.marginTop}`,
          marginLeft: `${props.marginLeft}`,
          marginRight: `${props.marginRight}`
        }}
      >
        Help Guides
      </Button>
      <Drawer anchor='right' open={open} onClose={toggleDrawer}>
        <div style={{ width: 300, }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: "rgb(3, 42, 55)", }} >
            <h6 style={{
              fontFamily: "Lato",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "24px",
              letterSpacing: "0.0015em",
              color: "#fff",
              marginLeft: "15px"
            }} >
              Help Articles
            </h6>
            <IconButton onClick={toggleDrawer}>
              <CloseIcon sx={{
                color: "#fff"
              }} />
            </IconButton>
          </div>
          <List>
            {guides.map((guide, index) => (
              <CustomListItem button key={index} component="a" href={guide.link}>
                <p style={{
                  fontFamily: 'Open Sans',
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                }}

                > {guide.title} </p>
              </CustomListItem>

            ))}
          </List>
        </div>
      </Drawer>
    </>
  );
};

export default HelpDrawerInc;
