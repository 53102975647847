import * as types from '../../../lib/actionTypes.es6';

export function getAllGroupsRequest(payload) {
  return {
    type: types.GET_ALL_GROUPS_REQUEST,
    payload,
  };
}

export function getAllGroupsSuccess(response) {
  return {
    type: types.GET_ALL_GROUPS_SUCCESS,
    payload: response,
  };
}

export function getAllGroupsFailed(error) {
  return {
    type: types.GET_ALL_GROUPS_FAILURE,
    payload: error,
  };
}

export function addGroupPaymentRequest(payload) {
  return {
    type: types.ADD_GROUP_PAYMENT_REQUEST,
    payload,
  };
}

export function addGroupPaymentSuccess(response) {
  return {
    type: types.ADD_GROUP_PAYMENT_SUCCESS,
    payload: response,
  };
}

export function addGroupPaymentFailed(error) {
  return {
    type: types.ADD_GROUP_PAYMENT_FAILURE,
    payload: error,
  };
}

export function resetAddGroupPayment() {
  return {
    type: types.RESET_ADD_GROUP_PAYMENT,
  };
}

export function deleteGroupRequest(payload) {
  return {
    type: types.DELETE_GROUP_REQUEST,
    payload,
  };
}

export function deleteGroupSuccess(response) {
  return {
    type: types.DELETE_GROUP_SUCCESS,
    payload: response,
  };
}

export function deleteGroupFailed(error) {
  return {
    type: types.DELETE_GROUP_FAILURE,
    payload: error,
  };
}
