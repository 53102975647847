import React, { useState } from 'react'
import ModalInc from '../../../../../../../shared-ui/ModalInc'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { CircularProgress, Grid } from '@mui/material'
import ButtonInc from '../../../../../../../shared-ui/ButtonInc'
import AlertDialog from '../../../../../../../components/AlertDialog/index.es6'

const EditPolicy = (props) => {
  if (!props.policy) {
    return null
  }

  const [beneficiaryFullName, setbeneficiaryFullName] = useState(props.policy.beneficiary_name)
  const [SpouseName, setSpouseName] = useState(props.policy.spouse_name || "")
  const [SpouseDateOfBirth, setSpouseDateOfBirth] = useState(props.policy.spouse_dob || "");

  const handleChange = (event) => {
    const { name, value } = event.target
    if (name === "beneficiaryFullName") {
      setbeneficiaryFullName(value)
    }
    if (name === "SpouseName") {
      setSpouseName(value)
    }
    if (name === "SpouseDateOfBirth") {
      setSpouseDateOfBirth(value);
    }

  }
  const editPolicyDetails = () => {
    const payload = {
      "guid": props.policy.guid,
      "partner_guid": props.policy.partner_guid,
      "spouse_name": SpouseName,
      "spouse_dob": SpouseDateOfBirth,
      "beneficiary_name": beneficiaryFullName,
    }
    props.editPolicy(payload)
  }
  const resetAfterSucesfullEdit = () => {
    props.resetAfterSucesfullEdit()
  }
  const resetAfterFailedEdit = () => {
    props.resetAfterSucesfullEdit()

  }
  return (
    <>
      <ModalInc
        modalTitle={props.labels.editPolicyDetails}
        subTitle={props.labels.editPolicyDetailsSubtitle}
        open={!!props.policy}
        onClose={props.closeEditDialog}
        fullWidth
      >
        <ValidatorForm onSubmit={() => editPolicyDetails()} >

          <Grid container spacing={2}>

            <Grid item xs={6} sm={12} md={12}>
              <TextValidator
                name='beneficiaryFullName'
                id="beneficiaryFullName"
                label={props.labels.beneficiaryFullname}
                value={beneficiaryFullName}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={[`${props.labels.beneficiaryFullnameError}`]}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextValidator
                name='SpouseName'
                id="SpouseName"
                label={props.labels.spouseName}
                value={SpouseName}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={[`${props.labels.spouseNameError}`]}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextValidator
                name='SpouseDateOfBirth'
                id="SpouseDateOfBirth"
                label={props.labels.spouseDateOfBirth}
                value={SpouseDateOfBirth}
                onChange={handleChange}
                fullWidth
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                validators={['required']}
                errorMessages={[`${props.labels.spouseDateOfBirthError}`]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} md={6}>

            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    type='submit'
                    style={{ marginTop: "20px", float: "right" }}
                  >
                    {props.labels.editPolicyDetails}
                  </ButtonInc>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginTop: "20px" }}
                    onClick={props.closeEditDialog}
                  >
                    {props.labels.Goback}
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </ValidatorForm>

      </ModalInc>
      <AlertDialog
        custom
        show={props.Editloader}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2> {props.labels.editingpolicy}</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={!!props.successfullPolicyEdit}
        size="sm"
        title={`${props.labels.Policyeditrequestsubmitted}`}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { resetAfterSucesfullEdit() }}
        confirmBtnText={'ok'}
        showCancel={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>


      <AlertDialog
        show={!!props.policyEditFailure}
        danger
        title={`${props.labels.Erroreditingpolicy}`}
        onConfirm={() => { resetAfterFailedEdit() }}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.policyEditDataError ? props.policyEditDataError.message : ''}
      </AlertDialog>

    </>
  )
}

export default EditPolicy