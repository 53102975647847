import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { BENEFIT_NAMES, GRANULARITY } from "../../../../PoductConstants";


let initialState = {
  name: '',
  period: '',
  period_granularity: '',
  returned_premiums_count: '',
  guid: '',
  product_guid: '',
}
export default function CashBackIndemnityForm(props) {
  if(!props.benefit){
    return ""
  }

  initialState = props.benefit


  const [
    { name,
      period,
      period_granularity,
      returned_premiums_count,
      guid,
      product_guid,
      enabled,
    }, setState
  ] = React.useState(initialState)

  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const submit = () => {
    const payload = {
      name,
      period: Number(period),
      period_granularity,
      returned_premiums_count: Number(returned_premiums_count),
      guid,
      product_guid,
      enabled,
    }
    payload['benefitType']='cashback'
    props.editBenefit(payload)
  }


  return (
    <React.Fragment>
      <ValidatorForm onSubmit={() => submit()} >
        <Grid container spacing={2}>
          <Grid item lg={3} md={6} xs={12}><SelectValidator
            value={name}
            label='name'
            id="name"
            name='name'
            onChange={onChange}
            className="form-control"
            type="text"
            variant="outlined"
            validators={['required']}
            errorMessages={['Please select name']}
            style={{ width: '225px' }}
          >
            <MenuItem value="">{'Please select name'}</MenuItem>
            {
              BENEFIT_NAMES.CASHBACK.map((type) => {
                return <MenuItem value={type}>{type}</MenuItem>
              })
            }
          </SelectValidator></Grid>

          <Grid item lg={3} md={6} xs={12}><TextValidator
            onChange={onChange}
            label='benifit period'
            name='period'
            value={period}
            fullWidth
            variant="outlined"
            type="number"
            placeholder='period'
            validators={['required']}
            errorMessages={['Please enter benifit period']}
          /></Grid>

          <Grid item lg={3} md={6} xs={12}><SelectValidator
            value={period_granularity}
            label='period granularity'
            id="period granularity"
            name='period_granularity'
            onChange={onChange}
            className="form-control"
            type="text"
            variant="outlined"
            validators={['required']}
            errorMessages={['Please select period granularity']}
          >
            <MenuItem value="">{'Please select period granularity'}</MenuItem>
            {
              GRANULARITY.map((type) => {
                return <MenuItem value={type}>{type}</MenuItem>
              })
            }
          </SelectValidator></Grid>


          <Grid item lg={3} md={6} xs={12}><TextValidator
            onChange={onChange}
            value={returned_premiums_count}
            label='returned premiums count'
            name='returned_premiums_count'
            fullWidth
            variant="outlined"
            type="number"
            placeholder='amount'
            validators={['required']}
            errorMessages={['Please enter returned premiums count']}
          /></Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button variant="contained" type="submit" style={{ marginRight: '2%' }}> Update </Button>
            <Button variant="contained" color="secondary" style={{ marginRight: '2%' }} onClick={() => props.resetBenefitToEdit()}> Cancel</Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </React.Fragment>
  );
}
