import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  downloadDocumentSuccess: false,
  downloadDocumentLoader: false,
  downloadDocumentError: null,
  entity: null,
  entityDocument: null,

  getTemplateDocumentError: null,
  getTemplateDocumentloader: false,
  templateDocuments: [],
  isEmptyTemplates: false,

  addTemplateDocumentError: null,
  addTemplateDocumentLoader: false,
  addedTemplateDocument: null,

  deleteTemplateDocumentError: null,
  deleteTemplateDocumentLoader: false,
  deleteTemplateDocument: null,
};

export default function documentGeneration(state = initialState, action) {
  switch (action.type) {
    case types.DOWNLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        downloadDocumentError: null,
        downloadDocumentLoader: true,
        downloadDocumentSuccess: false,
        entityDocument: null,
      };

    case types.DOWNLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        downloadDocumentSuccess: true,
        downloadDocumentLoader: false,
        downloadDocumentError: null,
        entityDocument: action.payload,
      };

    case types.DOWNLOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        downloadDocumentLoader: false,
        downloadDocumentError: action.payload,
        entityDocument: null,
      };

    case types.RESET_DOWNLOAD_DOCUMENT_SUCCESS:
      return { ...state, downloadDocumentSuccess: false };

    case types.RESET_DOWNLOAD_DOCUMENT_ERROR:
      return { ...state, downloadDocumentError: null };

    case types.RESET_ENTITY_TO_DOWNLOAD_FOR:
      return { ...state, entity: null };
    case types.SET_ENTITY_TO_DOWNLOAD_FOR:
      return { ...state, entity: action.payload };

    case types.GET_TEMPLATE_DOCUMENTS_REQUEST:
      return {
        ...state,
        getTemplateDocumentloader: true,
        getTemplateDocumentError: null,
        templateDocuments: [],
        isEmptyTemplates: false,
      };

    case types.GET_TEMPLATE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        templateDocuments: action.payload,
        getTemplateDocumentloader: false,
        isEmptyTemplates: action.payload.length === 0
      };

    case types.GET_TEMPLATE_DOCUMENTS_FAILURE:
      return {
        ...state,
        getTemplateDocumentloader: false,
        getTemplateDocumentError: action.payload,
        isEmptyTemplates: false,
      };

      case types.RESET_GET_TEMPLATE_DOCUMENTS:
        return {
          ...state,
          getTemplateDocumentError: null,
          getTemplateDocumentloader: false,
          templateDocuments: [],
          isEmptyTemplates: false,
        };

    case types.ADD_TEMPLATE_DOCUMENT_REQUEST:
      return {
        ...state,
        addTemplateDocumentLoader: true,
        addTemplateDocumentError: null,
        addedTemplateDocument: null,
      };

    case types.ADD_TEMPLATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        addedTemplateDocument: action.payload,
        addTemplateDocumentLoader: false,
      };

    case types.ADD_TEMPLATE_DOCUMENT_FAILURE:
      return {
        ...state,
        addTemplateDocumentLoader: false,
        addTemplateDocumentError: action.payload,
      };

    case types.DELETE_TEMPLATE_DOCUMENT_REQUEST:
      return {
        ...state,
        deleteTemplateDocumentLoader: true,
        deleteTemplateDocumentError: null,
        deleteTemplateDocument: null,
      };

    case types.DELETE_TEMPLATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleteTemplateDocumentLoader: false,
        deleteTemplateDocument: true,
      };

    case types.DELETE_TEMPLATE_DOCUMENT_FAILURE:
      return {
        ...state,
        deleteTemplateDocumentLoader: false,
        deleteTemplateDocumentError: action.payload,
      };

    default:
      return state;
  }
}
