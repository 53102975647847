import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import './PremiumsTable.css'
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../../lib/constants";

export default function PremiumsTable(props) {
  if (props.premiums && props.premiums.length > 0) {
    return (
      <>
        <div style={{ marginTop: "2%" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ color: 'red' }}>
                <TableRow >
                  <TableCell className="table-cell-header">granularity</TableCell>
                  <TableCell className="table-cell-header">payment method</TableCell>
                  <TableCell className="table-cell-header">cardinality</TableCell>
                  <TableCell className="table-cell-header">amount</TableCell>
                  <TableCell className="table-cell-header" align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.premiums.map((premium) => {
                  return (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left"> {premium.granularity}</TableCell>
                      <TableCell align="left"> {premium.payment_method}</TableCell>
                      <TableCell align="left"> {premium.cardinality}</TableCell>
                      <TableCell align="left"> {premium.amount_in_cents / 100}</TableCell>
                      <TableCell align="right">
                        <Stack direction="row" spacing={1} justifyContent="flex-end" >
                          <IconButton onClick={() => { props.setPremiumToEdit(premium) }} >
                            <EditIcon
                              style={{ color: PRIMARY_THEME_COLOR_SHADE_1, fontWeight: 'bolder', display: props.displayEditButton ? 'inline-block' : 'none' }}
                            />
                          </IconButton>
                          <IconButton onClick={() => { props.hardDeletePremium(premium) }} >
                            <DeleteForeverIcon
                              style={{ color: 'red', fontWeight: 'bolder', marginRight: "2%", display: props.displayDeleteButton ? 'inline-block' : 'none' }}
                            />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
    );
  }
  return "No premiums found, click on the add button to add new premums."
}
