import React from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { hashHistory } from 'react-router';

import LocalizedStrings from "react-localization";
import { localisedText } from "../../lib/localisation.es6";
import * as actions from "./actions.es6";
import { getPartnerGuid, hasAuthourity } from "../../lib/access.es6";
import { updateCurrentLanguage } from "../../lib/languageUtils.es6";
import LanguagesTable from "./components/LanguagesTable/LanguagesTable";
import ProductsHeader from "../NewProductList/components/ProductsHeader/ProductsHeader";
import SkeletonWrapper from "../NewProductList/components/SkeletonWrapper/SkeletonWrapper";

export class ViewLanguages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labels: new LocalizedStrings(localisedText),
      generalPartner: getPartnerGuid(),
      openViewDialog: false,
      openAddPartnerDialog: false,
      openViewPartnersDialog: false,
      noAccessToPartnerAlert: false,
    };
  }

  componentWillMount() {
    if (hasAuthourity('VIEW_ALL_LANGUAGES')) {
      this.props.dispatch(actions.getLanguagesRequest());
    }
    this.updateLanguage();
  }

  componentDidUpdate() {

    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'Languages',
      this.state.labels
    );
  }

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg" style={{ marginTop: "2%" }}>
          <SkeletonWrapper loading={this.props.languagesData.loader} />
          {
            !this.props.languagesData.loader &&
            <>
              <ProductsHeader
                title={"Languages"}
                showAddProductDialog={this.state.openAddPartnerDialog}
                showAddButton={false}
                onAddButtonClick={() => { this.setState({ openAddPartnerDialog: true }) }}
                showEditButton={false}
                onEditButtonClick={() => { }}
              />
              <LanguagesTable
                hashHistory={hashHistory}
                languages={this.props.languagesData.languages}
              />

              <br />
              <br />
            </>
          }

        </Container>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  languagesData: state.languages,
  globalData: state.global,
}))(ViewLanguages);
