import React from 'react'
import { Box, Grid } from '@mui/material';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { useStyle } from "./Style";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Button from '@mui/material/Button';
import { extractMessageFromSmsPayload, formatDateLocale } from '../../../../../../../lib/utils.es6';
import SkeletonWrapper from '../../../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';

const SmsActivity = (props) => {

  if (props.loader) {
    return <SkeletonWrapper loading={props.loader} />
  }
  const classes = useStyle();

  const [expanded, setExpanded] = React.useState(false)
  const smses = props.smses.map(sms => {
    return {
      ...sms,
      message: extractMessageFromSmsPayload(sms)
    }
  });
  const dataForDisplay = expanded ? smses : smses.slice(0, 2)
  if (dataForDisplay.length > 0) {
    return (
      <>
        {
          dataForDisplay.map((sms, index) => (
            <Grid container className={classes.policyWrapper} key={index}>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container className={classes.policyHeader}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Grid container>
                      <Grid item xs={12} md={2} sm={2} className={classes.policyIconWrapper}   >
                        <Box className={classes.policyIcons}>
                          <NoteAddOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={10} sm={10} className={classes.policyHeaderContent}>
                        <h6>{formatDateLocale(sms.created_at) || "N/A"}</h6>
                        <p>{props.labels.createdAt}</p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} >

                    <Grid container>
                      <Grid item xs={12} md={6} sm={6}>
                        <div >

                        </div>
                      </Grid>
                      <Grid item xs={12} md={6} sm={6} className={classes.policyActiveStatusWrapper}>
                        <Box display="flex" justifyContent="flex-end">
                          <ul>
                            <li className={sms.status === "sent" ? classes.activePill : classes.inactivePill}> <span>{sms.status === "sent" ? `Sent` : `Error`}</span></li>
                          </ul>

                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className={classes.policyHeaderBorder}></div>
                </Grid>

                <Grid container spacing={2} className={classes.policyContentWrapper}>
                  <Grid item xs={12} sm={3} md={3} >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3} md={3}></Grid>
                      <Grid item xs={12} sm={9} md={9}>
                        <p> {props.labels.RequestType}</p>
                        <h6> {sms.request_type || "N/A"}</h6>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={9} md={9}>
                    <p>  {props.labels.Message}</p>
                    <h6> {sms.message || "N/A"}</h6>
                  </Grid>
                </Grid>
                <div className={classes.policyContentBorder}></div>
                <Grid container spacing={2} className={classes.policyFooterWrapper}>
                  <Grid item xs={12} md={6} sm={6}>
                    <Grid container spacing={2} >


                      <Grid item xs={12} md={6} sm={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={3} md={3}></Grid>
                          <Grid item xs={12} sm={9} md={9}>
                            <p>{props.labels.MNOStatus}</p>
                            <h6>{sms.mno_status || "N/A"}</h6>

                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <div className={classes.policyBalance}>
                      <p> {props.labels.MNOMessage}</p>
                      <h6>{sms.mno_message || "N/A"}</h6>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          ))
        }

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Button style={{
              background: "#FBA92D",
              borderRadius: "8px",
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "15px",
              boxShadow: "none",
              padding: "10px",
              marginTop: "20px",
              textTransform: "capitalize",
            }} variant="contained" size="large" endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} onClick={() => setExpanded(!expanded)}>
              {expanded ? `${props.labels.ShowLessSMS}` : `${props.labels.ShowMoreSMS}`}
            </Button>

          </Grid>
        </Grid>

      </>
    )
  } else {
    return (<>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <h1 style={{
            color: "#000000",
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            textAlign: "center",
            marginTop: "100px",
          }}> {props.labels.Nosmsavailable}</h1>

        </Grid>
      </Grid>

    </>)
  }
}

export default SmsActivity