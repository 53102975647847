import { LinearProgress } from '@mui/material';
import React from 'react'
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
export default function ProgressState({ progress, errorMessage }) {


  if (progress === 0) {
    return <div>
      <h4>Drag and drop files here</h4>
      <h4>OR</h4>
      <h4>Click to upload</h4>
    </div>
  }

  if (progress > 0 && progress < 100) {
    return <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
      <h1>Uploading file</h1>
      <ProgressIndicator progress={progress} />
    </div>
  }

  if (progress >= 100 && !errorMessage) {
    return <div style={{ color: 'green' }}>
      <div><CheckCircleIcon /></div>
      Successfully uploaded file
      <ProgressIndicator progress={progress} />
    </div>
  }

  if (progress >= 100 && errorMessage) {
    return <div style={{ color: 'red' }}>
      <div><ReportProblemIcon /></div>
      errors with upload</div>
  }


}

function ProgressIndicator({ progress }) {
  return <><LinearProgress variant="determinate" value={progress} />
    <h1>{progress + "%"}</h1></>
}