import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
    buypolicywrapper:{
        width: "70%",
        margin: "auto",
        "& input": {
            background: "#F7F7F7",
            borderRadius: "8px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.005em",
            color: "#000000",
        },
        "& label": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "12px",
            color: "#000000",
            transformOrigin: "bottom",
        }
        },
        buypolicybtn:{
            marginTop: "20px",
            "& button":{
                background: "#fba92c",
                borderRadius: "8px",
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "15px",
                boxShadow: "none",
                padding: "10px",
                marginRight: "20px",
                textTransform: "capitalize",
                marginBottom: "30px",
                "&:hover": {
                  background: "#173E4B",
                  boxShadow: "none",
                }
            }
        },
        buypolicytabs:{
            "& span":{
                display: "none"
            }
        },
        changequoteinputwrapper:{
            margin: "30px auto",
            "& input": {
                background: "#F7F7F7",
                borderRadius: "8px",
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.005em",
                color: "#000000",
            },
            "& label": {
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "12px",
                color: "#000000",
                transformOrigin: "bottom",
            }
        },
        changequotesbtns:{
            marginTop: "20px",
            "& button":{
                background: "#fba92c",
                borderRadius: "8px",
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "15px",
                boxShadow: "none",
                padding: "10px",
                marginRight: "20px",
                textTransform: "capitalize",
                marginBottom: "30px",
                "&:hover": {
                  background: "#173E4B",
                  boxShadow: "none",
                }
            }
        }
}));