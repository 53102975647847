import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import BoxInc from "../../../shared-ui/BoxInc/Index";
import ButtonInc from "../../../shared-ui/ButtonInc";
import DisplayHospitals from "./Components/DisplayHospitals/DisplayHospitals";
import CreateHospital from "./Components/CreateHospital/CreateHospital";
import DeleteHospital from "./Components/DeleteHospital/DeleteHospital";
import { createHospitalRequest, deleteHospitalRequest, getHospitalsRequest, resetCreateHospitalError, resetCreateHospitalSuccess, resetHospitalToDelete, setHospitalToDelete } from "../PreAuth/Redux/actions";

class Hospitals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddHospitalDialog: false,
    };
  }
  componentWillMount() {
    this.props.dispatch(getHospitalsRequest());
  }

  toggleCreateHospitalDialog() {
    this.setState({ openAddHospitalDialog: !this.state.openAddHospitalDialog })
  }
  createHospital(payload) {
    this.props.dispatch(createHospitalRequest(payload));
  }
  setHospitalToDelete(hospital) {
    this.props.dispatch(setHospitalToDelete(hospital));
  }
  deleteHospital(hospital) {
    this.props.dispatch(deleteHospitalRequest({ id: hospital._id }));
  }
  resetHospitalToDelete(hospital){
    this.props.dispatch(resetHospitalToDelete(hospital))
  }

  render() {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <BoxInc
            expanded
              title=" Hospitals"
              actionButtons={[<ButtonInc
                variant="contained"
                color="primary"
                size="large"
                onClick={this.toggleCreateHospitalDialog.bind(this)}
              >
                Create Hospital
              </ButtonInc>]}

            >
              <DisplayHospitals
                hospitals={this.props.LouData.hospitals}
                loader={this.props.LouData.getHospitalsLoader}
                setHospitalToDelete={this.setHospitalToDelete.bind(this)}
              />
              <CreateHospital
                open={this.state.openAddHospitalDialog}
                close={this.toggleCreateHospitalDialog.bind(this)}
                createHospital={this.createHospital.bind(this)}

                createHospitalError={this.props.LouData.createHospitalError}
                showCreateHospitalProgressAlert={this.props.LouData.createHospitalLoader}
                showCreateHospitalSuccessAlert={this.props.LouData.createHospitalSuccess}
                showCreateHospitalErrorAlert={!!this.props.LouData.createHospitalError}
                resetCreateHospitalSuccessAlert={() => { this.props.dispatch(resetCreateHospitalSuccess()) }}
                resetCreateHospitalErrorAlert={() => { this.props.dispatch(resetCreateHospitalError()) }}
              />
              <DeleteHospital
                deleteHospital={this.deleteHospital.bind(this)}
                hospitalToDelete={this.props.LouData.HospitalToDelete}

                resetHospitalToDelete={this.resetHospitalToDelete.bind(this)}
                showDeleteHospitalProgressAlert={this.props.LouData.hardDeleteHospitalLoader}
                showDeleteHospitalSuccessAlert={this.props.LouData.hardDeleteHospitalSuccess}
                showDeleteHospitalErrorAlert={!!this.props.LouData.deleteHospitalError}
                deleteHospitalError={this.props.LouData.deleteHospitalError}
    
              />
            </BoxInc>
          </Grid>
        </Grid>
      </>
    )
  }

}
export default connect((state) => ({
  customerData: state.currentCustomer,
  LouData: state.LouData,
  globalData: state.global,
}))(Hospitals);