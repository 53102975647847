import * as types from '../../lib/actionTypes.es6';

export function uploadLoyaltyDataRequest(loyalty) {
  return {
    type: types.UPLOAD_LOYALTY_DATA_REQUEST,
    payload: loyalty,
  };
}

export function uploadLoyaltyDataSuccess(response) {
  return {
    type: types.UPLOAD_LOYALTY_DATA_SUCCESS,
    payload: response,
  };
}

export function uploadLoyaltyDataFail(error) {
  return {
    type: types.UPLOAD_LOYALTY_DATA_FAIL,
    payload: error,
  };
}

export function uploadLoyaltyDataReset() {
  return {
    type: types.UPLOAD_LOYALTY_DATA_RESET,
  };
}


export function mapColumnsToFieldsRequest(data) {
  return {
    type: types.MAP_COLUMN_NUMBER_TO_FIELDS_REQUEST,
    payload: data,
  };
}

export function mapColumnsToFieldsSuccess(response) {
  return {
    type: types.MAP_COLUMN_NUMBER_TO_FIELDS_SUCCESS,
    payload: response,
  };
}

export function mapColumnsToFieldsFail(error) {
  return {
    type: types.MAP_COLUMN_NUMBER_TO_FIELDS_FAIL,
    payload: error,
  };
}

export function getPreviousLoyaltyStatusRequest(data) {
  return {
    type: types.GET_PREVIOUS_LOYALTY_STATUS_REQUEST,
    payload: data,
  };
}

export function getPreviousLoyaltyStatusSuccess(response) {
  return {
    type: types.GET_PREVIOUS_LOYALTY_STATUS_SUCCESS,
    payload: response,
  };
}

export function getPreviousLoyaltyStatusFail(error) {
  return {
    type: types.GET_PREVIOUS_LOYALTY_STATUS_FAIL,
    payload: error,
  };
}


export function getPreviousLoyaltiesStatusRequest(data) {
  return {
    type: types.GET_PREVIOUS_LOYALTIES_STATUS_REQUEST,
    payload: data,
  };
}

export function getPreviousLoyaltiesStatusSuccess(response) {
  return {
    type: types.GET_PREVIOUS_LOYALTIES_STATUS_SUCCESS,
    payload: response,
  };
}

export function getPreviousLoyaltiesStatusFail(error) {
  return {
    type: types.GET_PREVIOUS_LOYALTIES_STATUS_FAIL,
    payload: error,
  };
}

export function setLoyaltiesStartDateRequest(data) {
  return {
    type: types.SET_LOYALTIES_START_DATE_REQUEST,
    payload: data,
  };
}

export function setLoyaltiesEndDateRequest(data) {
  return {
    type: types.SET_LOYALTIES_END_DATE_REQUEST,
    payload: data,
  };
}