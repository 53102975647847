import React from 'react'
import Chip from '@mui/material/Chip';
import { Grid } from '@mui/material';
const UserPartners = (props) => {
  return (
    <>
      {
        <Grid container spacing={2} style={{ marginTop: "40px" }}>
          {
            props.currentPartners.map((partner, index) =>
              <Chip sx={{ margin: "10px" }} key={index} label={partner} variant="outlined" />

            )
          }

        </Grid>

      }

    </>
  )
}

export default UserPartners