import * as types from '../../lib/actionTypes.es6';
import GAlogger, { monitoringEventCategories } from '../../lib/monitoring.es6';

export function deletePremiumRequest(premium) {
  return {
    type: types.DELETE_PREMIUM_REQUEST,
    payload: premium,
  };
}

export function deletePremiumSuccess(premiumInfo) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.deletePremium);
  return {
    type: types.DELETE_PREMIUM_SUCCESS,
    payload: premiumInfo,
  };
}

export function deletePremiumFail(response) {
  return {
    type: types.DELETE_PREMIUM_FAILURE,
    payload: response,
  };
}

export function addMissingPaymentRequest(payment) {
  return {
    type: types.ADD_MISSING_PAYMENT_REQUEST,
    payload: payment,
  };
}

export function addMissingPaymentSuccess(payment) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.addPayment);
  return {
    type: types.ADD_MISSING_PAYMENT_SUCCESS,
    payload: payment,
  };
}

export function addMissingPaymentFail(response) {
  return {
    type: types.ADD_MISSING_PAYMENT_FAIL,
    payload: response,
  };
}

export function addMissingPaymentReset() {
  return {
    type: types.ADD_MISSING_PAYMENT_RESET,
  };
}


export function sendRecurringPaymentRecommendationSmsRequest(payload) {
  return {
    type: types.SEND_RECURRING_PAYMENT_RECOMMENDATION_SMS_REQUEST,
    payload: payload,
  };
}

export function sendRecurringPaymentRecommendationSmsSuccess(response) {
  return {
    type: types.SEND_RECURRING_PAYMENT_RECOMMENDATION_SMS_SUCCESS,
    payload: response,
  };
}

export function sendRecurringPaymentRecommendationSmsFail(error) {
  return {
    type: types.SEND_RECURRING_PAYMENT_RECOMMENDATION_SMS_FAILED,
    payload: error,
  };
}

export function sendRecurringPaymentRecommendationSmsReset() {
  return {
    type: types.SEND_RECURRING_PAYMENT_RECOMMENDATION_SMS_RESET,
  };
}