import React, { useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import AlertDialog from '../../../components/AlertDialog/index.es6';
import ButtonInc from '../../../shared-ui/ButtonInc';
import { reloadPage } from '../../../lib/utils.es6';
const DeleteReport = (props) => {

  if (!props.reportTemplateToDelete) {
    return ""
  }

  const [showWarning, setShowWarning] = useState(false);

  const cancel = () => {
    props.resetReportTemplateToDelete()
  }
  const deleteReportTemplate = () => {
    setShowWarning(true)
    props.deleteReportTemplate(props.reportTemplateToDelete);
  }
  const handleReportTemplateDeletedSuccessfully = () => {
    props.resetReportTemplateToDelete()
    reloadPage()
  }
  const handleDeletedError = () => {
    props.resetReportTemplateToDelete()

  }
  return (
    <>
      <AlertDialog
        custom
        show={props.showDeleteReportTemplateProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Deleting report template...</h2>
      </AlertDialog>

      <AlertDialog
        warning
        show={!showWarning && props.reportTemplateToDelete}
        size="sm"
        title={'Are you sure you want to delete this report template? '}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { cancel() }}
        onCancel={() => { deleteReportTemplate() }}
        confirmBtnText={'Cancel'}
        showCancel={true}
        showConfirm={true}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
      </AlertDialog>
      <AlertDialog
        success
        show={props.showDeleteReportTemplateSuccessAlert}
        size="sm"
        title={'Report template deleted'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleReportTemplateDeletedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
        <div >
          <ButtonInc onClick={() => { handleReportTemplateDeletedSuccessfully() }} style={{ background: 'green', }}>OK</ButtonInc>
        </div>
      </AlertDialog>
      <AlertDialog
        show={props.showDeleteReportTemplateErrorAlert}
        danger
        title={'Error deleting report template'}
        onConfirm={() => handleDeletedError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >

        {props.deleteReportTemplateError
          ? props.deleteReportTemplateError.message
          : ''}

      </AlertDialog>
    </>
  )
}

export default DeleteReport