import React, { useState } from 'react'
import ModalInc from '../../../../../shared-ui/ModalInc'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import { getPartnerGuid } from '../../../../../lib/access.es6';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress } from '@material-ui/core';

const CreateDiagnosis = (props) => {

  const [diagnosisname, setDiagnosisName] = useState('');
  const [diagnosiscode, setDiagnosisCode] = useState('');
  const [diagnosistype, setDiagnosisType] = useState('');
  const [diagnosisdesc, setDiagnosisDesc] = useState('');


  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === "diagnosisname") {
      setDiagnosisName(value)
    }
    if (name === "diagnosiscode") {
      setDiagnosisCode(value)
    }
    if (name === "diagnosistype") {
      setDiagnosisType(value)
    }
    if (name === "diagnosisdesc") {
      setDiagnosisDesc(value)
    }

  }

  const handleCreateDiagnosis = () => {
    const payload = {
      "partner": getPartnerGuid(),
      "name": diagnosisname,
      "code": diagnosiscode,
      "type": diagnosistype,
      "description": diagnosisdesc
    }
    console.log("create diagnosis payload", payload);
    props.createDiagnosis(payload);
  }
  const handleDiagnosisCreatedSuccessfully = () =>{
    props.resetCreateDiagnosisSuccessAlert();
    props.close()
  }
  const handleDiagnosisCreateError = () =>{
    props.resetCreateDiagnosisErrorAlert();
    props.close()
  }
  return (
    <>
        <AlertDialog
        custom
        show={props.showCreateDiagnosisProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2> Creating Diagnosis...</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showCreateDiagnosisSuccessAlert}
        size="sm"
        title={'Diagnosis  created'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleDiagnosisCreatedSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={true}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      
      </AlertDialog>

      <AlertDialog
        show={props.showCreateDiagnosisErrorAlert}
        danger
        title={'Error creating Diagnosis'}
        onConfirm={() => handleDiagnosisCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
      
         {props.createDiagnosisError ? props.createDiagnosisError.message : ''} 
      </AlertDialog>
      <ModalInc
        modalTitle="Create Diagnosis "
        subTitle="Fill the below fields to create diagnosis"
        open={props.open}
        onClose={props.close}
        fullWidth
        maxWidth="md"
      >
        <ValidatorForm onSubmit={() => handleCreateDiagnosis()} >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <TextValidator
                id="diagnosisname"
                label="Diagnosis Name"
                name='diagnosisname'
                value={diagnosisname}
                onChange={handleChange}
                fullWidth
                type='text'
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter diagnosis name']}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextValidator
                id="diagnosiscode"
                label="Diagnosis Code"
                name='diagnosiscode'
                value={diagnosiscode}
                onChange={handleChange}
                fullWidth
                type='text'
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter hospital code']}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextValidator
                id="diagnosistype"
                label="Diagnosis Type"
                name='diagnosistype'
                value={diagnosistype}
                onChange={handleChange}
                fullWidth
                type='text'
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextValidator
                id="diagnosisdesc"
                label="Diagnosis Description"
                name='diagnosisdesc'
                value={diagnosisdesc}
                onChange={handleChange}
                fullWidth
                type='text'
                variant="outlined"
              />
            </Grid>

          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} md={6}>

            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    type='submit'
                    style={{ marginTop: "20px", float: "right" }}
                  >
                    Create Diagnosis
                  </ButtonInc>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginTop: "20px" }}
                    onClick={props.close}
                  >
                    Cancel
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </ModalInc>

    </>
  )
}

export default CreateDiagnosis