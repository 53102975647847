import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  reportURL: null,
  reportBlob: null,
  loader: false,
};

export default function reportClaimFinalizationDeathReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.REPORT_CLAIM_FINALIZATION_DEATH_REQUEST:
      return { ...state, loader: true, errors: null, reportBlob: null };

    case types.REPORT_CLAIM_FINALIZATION_DEATH_SUCCESS:
      return { ...state, reportBlob: action.payload, loader: false };

    case types.REPORT_CLAIM_FINALIZATION_DEATH_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    case types.REPORT_CLAIM_FINALIZATION_DEATH_RESET:
      return { ...state, reportBlob: null };

    default:
      return state;
  }
}
