import * as React from "react";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import AlertDialog from "../../../../../../../components/AlertDialog/index.es6";
import { reloadPage } from "../../../../../../../lib/utils.es6";



export default function DeleteDebitOrder(props) {

  if (!props.debitOrder) {
    return ""
  }

  const [showWarning, setShowWarning ] = React.useState(false);


  const deleteDebitOrder = ()=>{
    setShowWarning(true) 
    props.deleteDebitOrder(props.debitOrder)
  }

  const cancel = ()=>{
    props.resetDebitOrderToDelete()
  }


  const handleDebitOrderDeletedSuccessfully = () => {
    props.resetDeleteDebitOrderSuccessAlert()
    props.resetDebitOrderToDelete()
    reloadPage()
  }

  const handleDebitOrderDeleteError = () => {
    props.resetDeleteDebitOrderErrorAlert()
  }

  return (
    <React.Fragment>
      <AlertDialog
        custom
        show={props.showDeleteDebitOrderProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Deleting debitOrder</h2>
      </AlertDialog>


      <AlertDialog
        warning
        show={!showWarning && props.debitOrder}
        size="sm"
        title={'Are you sure you want to delete'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => {cancel() }}
        onCancel={() => {deleteDebitOrder()}}
        confirmBtnText={'Cancel'}
        showCancel={true}
        showConfirm={true}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
      </AlertDialog>

      <AlertDialog
        success
        show={props.showDeleteDebitOrderSuccessAlert}
        size="sm"
        title={'DebitOrder deleted'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleDebitOrderDeletedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => { handleDebitOrderDeletedSuccessfully() }} style={{ background: 'green', }}>Ok</Button>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showDeleteDebitOrderErrorAlert}
        danger
        title={'Error deleting debitOrder'}
        onConfirm={() => handleDebitOrderDeleteError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.editDebitOrderError ? props.editDebitOrderError.message : ''}
      </AlertDialog>






    </React.Fragment>

  );



}
