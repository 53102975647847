import { makeStyles } from "@material-ui/core/styles";

export const useStyle = makeStyles((theme) => ({
  claimsheader: {
    background: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
    width: "97.5%"
  },
  claimstitle: {
    "& ul": {
      margin: 0,
      padding: 0,
      listStyle: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
      gap: "15px",
      "& li": {
        fontSize: "16px",
        fontWeight: 500,
        color: "#333",
      },
    },
  },
  leftItems: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  rightItems: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    marginLeft: "auto",
  },
  viewCustomerBtn: {
    color: "#666",
    borderColor: "#ddd",
    "&:hover": {
      borderColor: "#999",
      background: "#f5f5f5",
    },
  },
  aibtn: {
    background: "#ff834f",
    padding: "8px 20px",
    fontWeight: "500",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    "&:hover": {
      background: "#ff6b1f",
      boxShadow: "0 4px 8px rgba(0,0,0,0.15)",
    },
  },
  claimStatussuccess: {
    background: "#4caf50",
    color: "#fff !important",
    padding: "5px 15px",
    borderRadius: "20px",
    fontSize: "14px !important",
  },
  claimStatusdanger: {
    background: "#f44336",
    color: "#fff !important",
    padding: "5px 15px",
    borderRadius: "20px",
    fontSize: "14px !important",
  },
})); 