import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  totalExpense: {
    width: "25%",
    textAlign: "center",
    float: "right",
    marginRight: "30px",
    marginBottom: "20px",
    height: "auto",
    padding: "15px 20px 5px 20px",
    background: "#F5F5F5",
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#060606"
    }
  },
 

}));