import { makeStyles } from "@material-ui/styles";



export const useStyle = makeStyles((LandingPageTheme) => ({
    mobiledrawer: {
        width: "100%"
    },
    closemobile: {
        cursor: "pointer",
        textAlign: "end",
        paddingRight: "10px",
        paddingTop: "10px",
        "& img": {
            width: "30px"
        }
    },
    navigation: {
        backgroundColor: '#032A37',
        height: "auto",
        paddingTop: "0",
    },
    logo: {
        "& img": {
            width: "80%",

        }
    },
    navigationLink: {
        "& ol": {
            marginTop: "30px",
            [LandingPageTheme.breakpoints.down("md")]: {
                padding: "0",
                textAlign: "center"
            },
            "& li": {
                display: "inline",
                marginLeft: "20px",
                [LandingPageTheme.breakpoints.down("md")]: {
                    display: "block",
                    marginLeft: "0",
                    marginBottom: "20px",
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "18px"
                },
                "& a": {
                    fontFamily: 'Lato',
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "0.0015em",
                    color: " #FFFFFF",
                    [LandingPageTheme.breakpoints.down("md")]: {
                        color: "#000",
                    },
                }
            }
        }
    },
    navigationCta: {
        marginTop: "30px",
        textAlign: "center",
        [LandingPageTheme.breakpoints.down("md")]: {
            marginTop: "0"
        },
        "& ol": {
            marginTop: "10px",
            display: "inline",
            listStyle: "none",
            padding: "0",
            [LandingPageTheme.breakpoints.down("md")]: {
                display: "block",
                marginTop: "0"
            },
            "& li": {
                float: "left",
                marginLeft: "20px",
                fontFamily: 'Lato',
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "24px",
                letterSpacing: "0.0015em",
                color: " #FFFFFF",
                [LandingPageTheme.breakpoints.down("md")]: {
                    float: "none",
                    color: "#000"
                },
                "& a": {
                    fontFamily: 'Lato',
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "0.0015em",
                    color: " #FFFFFF",
                    [LandingPageTheme.breakpoints.down("md")]: {
                        color: "#000",
                    },
                }
            }
        },
        "& button": {
            background: "#FBA92D",
            color: "#fff",
            borderRadius: "8px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            boxShadow: "none",
            padding: "15px 40px 15px 40px",
            textTransform: "capitalize",
            marginLeft: "20px",
            marginTop: "-12px;",
            "&:hover":{
                background: "#173E4B",
                color: "#fff",
                boxShadow: "none",
            },
            "& a": {
                color: "#fff",
            },
            [LandingPageTheme.breakpoints.down("md")]: {
                color: "#000",
                marginTop: "10px",
                marginBottom: "25px",
            },
        },
        "& a": {
            background: "#FBA92D",
            color: "#fff",
            borderRadius: "8px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            boxShadow: "none",
            padding: "15px 40px 15px 40px",
            textTransform: "capitalize",
            marginLeft: "20px",
            marginTop: "-12px;",
            "&:hover":{
                background: "#173E4B",
                color: "#fff",
                boxShadow: "none",
            },
            "& a": {
                color: "#fff",
            },
            [LandingPageTheme.breakpoints.down("md")]: {
                color: "#000",
                marginTop: "10px",
                marginBottom: "25px",
            },
        },
     
    },
    mobileiconcontainer: {
        marginLeft: "auto",
        borderRadius: "0"
    },
    mobileicon: {
        backgroundColor: "#fff",
        width: "40px",
        height: "40px"
    },

}));

