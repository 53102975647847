import * as types from '../../lib/actionTypes.es6';

export function getDebitOrderTransactionsRequest() {
  return {
    type: types.GET_DAILY_DEBIT_ORDER_REQUEST,
  };
}

export function getDebitOrderTransactionsSuccess(debitOrderTransactions) {
  return {
    type: types.GET_DAILY_DEBIT_ORDER_SUCCESS,
    payload: debitOrderTransactions,
  };
}

export function getDebitOrderTransactionsFailure(response) {
  return {
    type: types.GET_DAILY_DEBIT_ORDER_FAILURE,
    payload: response,
  };
}

export function updateDebitOrderTransactionsRequest(payload) {
  return {
    type: types.UPDATE_DAILY_DEBIT_ORDER_REQUEST,
    payload,
  };
}

export function updateDebitOrderTransactionsSuccess() {
  return {
    type: types.UPDATE_DAILY_DEBIT_ORDER_SUCCESS,
  };
}

export function updateDebitOrderTransactionsFailure(response) {
  return {
    type: types.UPDATE_DAILY_DEBIT_ORDER_FAILURE,
    payload: response,
  };
}
