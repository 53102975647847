export const BankData = [
  "Serowe",
  "Letlhakane",
  "Shakawe",
  "Mogoditshane",
  "MOCHUDI",
  "Mahalapye",
  "Lobatse",
  "Ghanzi",
  "Kasane",
  "Francistown",
  "Molepolole",
  "Gamecity",
  "Selebi Phikwe",
  "Palapye",
  "Maun",
  "Square",
  "Airport Junction",
  'Private Banking',
  "Kanye",
  "Fairgrounds",
]
