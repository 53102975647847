import { makeStyles } from "@material-ui/styles";

export const useStyle = makeStyles((LandingPageTheme) => ({
    simpletodelier: {
        padding: "50px",
        "& h2": {
            fontFamily: 'Lato',
            fontStyle: "normal",
            fontWeight: "900",
            fontSize: "22px",
            lineHeight: "28px",
            color: "#000",
            [LandingPageTheme.breakpoints.down("md")]: {
                fontSize: "18px",
                lineHeight: "25px",
                letterSpacing: " 0.001em",
                color: " #000000"
            }
        },
        "& span": {
            width: "28px",
            height: "7px",
            background: "#FBA92D",
            transform: "matrix(1, 0, 0, -1, 0, 0)",
            float: "left",
            marginTop: "-10px",
        },
    },
    boxfeatures: {
        minHeight: "230px",
        textAlign: "center",
        background: " #FFFFFF",
        border: "1px solid #CCCCCC",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
        marginTop: "60px",
        padding: "20px",
        [LandingPageTheme.breakpoints.down("md")]: {
            minHeight: "auto",
            marginTop: "20px",
        },
        "& img": {
            marginTop: "20px",
            width: "49px",
            height: "49px",
        },
        "& h3": {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.0015em",
            color: "#000000",
        },
        "& p": {
            fontFamily: 'Open Sans',
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000"
        }
    },




}));