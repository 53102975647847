import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  getFeatureFlagsError: null,
  getFeatureFlagsloader: false,
  featureFlags: [],
};

export default function featureFlags(state = initialState, action) {
  switch (action.type) {


    case types.GET_FEATURE_FLAGS_REQUEST:
      return {
        ...state,
        getFeatureFlagsloader: true,
        getFeatureFlagsError: null,
        featureFlags: [],
      };

    case types.GET_FEATURE_FLAGS_SUCCESS:
      return {
        ...state,
        getFeatureFlagsloader: false,
        featureFlags: action.payload,
      };

    case types.GET_FEATURE_FLAGS_FAILURE:
      return {
        ...state,
        getFeatureFlagsloader: false,
        getFeatureFlagsError: action.payload,
      };

    default:
      return state;
  }
}
