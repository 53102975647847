import React from "react";
import LocalizedStrings from 'react-localization';
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { localisedText } from "../../lib/localisation.es6";
import { addEmailTemplateRequest, getEmailTemplateRequest } from "./Redux/actions";
import ButtonInc from "../../shared-ui/ButtonInc";
import BoxInc from "../../shared-ui/BoxInc/Index";
import ViewEmailTemplates from "./components/ViewEmailTemplates";
import AddEmailTemplate from './components/AddEmailTemplate'
class EmailTemplates extends React.Component {

  constructor(props) {
    const localizedStrings = new LocalizedStrings(localisedText);
    super(props);
    this.state = {
      labels: localizedStrings,
      openUploadEmailTemplateDialog: false,
    }
  }

  componentWillMount() {
    this.updateLanguage();
    this.props.dispatch(getEmailTemplateRequest());
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    const language = this.props.globalData.language;
    let resetLanguage = false;
    if (!language) {
      resetLanguage = true;
    }
    const labels = this.state.labels;
    if (resetLanguage || labels.getLanguage() !== language) {
      labels.setLanguage(language);
      this.setState({});
    }
  }

  toggleOpenUploadEmailTemplateDialog(){
    this.setState({openUploadEmailTemplateDialog: !this.state.openUploadEmailTemplateDialog})
  }

  saveEmailTemplate(payload){
    this.props.dispatch(addEmailTemplateRequest(payload));
  }


  render() {

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <BoxInc
              expanded
              title="Email templates"
              actionButtons={[
                <ButtonInc
                  variant="contained"
                  color="primary"
                  size="large"
                //hasPermision={hasAuthourity('BULK_ONBOARD_CUSTOMERS')}
                onClick={(event) => {
                  event.stopPropagation()
                  this.toggleOpenUploadEmailTemplateDialog()
                }}
                >
                Upload  Email Template
                </ButtonInc>

              ]}

            >
              <ViewEmailTemplates
              loading={this.props.emailTemplates.getEmailTemplatesloader}
              templates={this.props.emailTemplates.emailTemplates}
              />
            <AddEmailTemplate
            open={this.state.openUploadEmailTemplateDialog}
            close={this.toggleOpenUploadEmailTemplateDialog.bind(this)}
            saveEmailTemplate={this.saveEmailTemplate.bind(this)}
            />
            </BoxInc>
          </Grid>
        </Grid>
      </>
    )


  }

}

export default connect((state) => ({
  emailTemplates: state.emailTemplates,
  globalData: state.global,
}))(EmailTemplates);