import * as types from '../../lib/actionTypes.es6';

export function reportClaimRegisterRequest(reportType) {
  return {
    type: types.REPORT_CLAIM_REGISTER_REQUEST,
    payload: reportType,
  };
}

export function reportClaimRegisterSuccess(reportURL) {
  return {
    type: types.REPORT_CLAIM_REGISTER_SUCCESS,
    payload: reportURL,
  };
}

export function reportClaimRegisterFailure(error) {
  return {
    type: types.REPORT_CLAIM_REGISTER_FAILURE,
    payload: error,
  };
}

export function reportClaimRegisterReset() {
  return {
    type: types.REPORT_CLAIM_REGISTER_RESET,
  };
}
