import * as React from "react";
import ButtonInc from '../../../../../shared-ui/ButtonInc'
import { ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@mui/material";
import AlertDialog from "../../../../../components/AlertDialog/index.es6";
import ModalInc from "../../../../../shared-ui/ModalInc";
import { getPartnerGuid } from "../../../../../lib/access.es6";

export default function InitiatePayment(props) {

  if (!props.policy) {
    return ""
  }

  const initiatePayment = () => {

    const paymentRequest = {
      policy_guid: props.policy.guid,
      amount_in_cents: props.policy.quotation.total_premium_amount_in_cents,
      partner: getPartnerGuid(),
      type: 'card_payment',
      customer_guid: props.policy.customer_guid,
      policy_type: 'complex'
    };
    props.initiatePaymentRequest(paymentRequest)

  }

  const handleInitiatePaymentSuccessfully = () => {
    props.resetInitiatePolicy();
  }

  const handleInitiatePaymentError = () => {
    props.resetInitiatePolicy();
  }

  
  return (
    <React.Fragment>
      <AlertDialog
        custom
        show={props.showInitiatePaymentProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Initiating Payment request...</h2>
      </AlertDialog>
      


      <AlertDialog
        success
        show={props.showInitiatePaymentSuccessAlert}
        size="sm"
        title={'Card Payment request Initiated'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleInitiatePaymentSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'Cancel'}
        confirmBtnCssClass
      >
        <div style={{textAlign: 'center'}} >
          <p> A card payment has been requested, Customer will receive an SMS with a link to enter their card payment details to process payment.</p>
          <ButtonInc variant="contained" onClick={() => { handleInitiatePaymentSuccessfully() }} style={{ background: 'green', }}>Ok</ButtonInc>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showInitiatePaymentErrorAlert}
        danger
        title={'Error initiating payment'}
        onConfirm={() => handleInitiatePaymentError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.initiatePaymentError ? props.initiatePaymentError.message : ''}
      </AlertDialog>

      <ModalInc
        modalTitle="initiating payment"
        open={!!props.policy}
        onClose={()=> props.resetInitiatePolicy()}
        fullWidth
      >
        <ValidatorForm onSubmit={() => initiatePayment()} >

          <Grid container spacing={2} style={{textAlign: 'center'}}>
            <p>You are about to initiate payment request </p>
            <br/>
            <p>Premium amount: ZAR  {props.policy.quotation.total_premium_amount_in_cents/100}</p>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} md={6}>

            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2} style={{textAlign: 'center'}}>
                <Grid item xs={12} sm={8} md={8}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    type='submit'
                    style={{ marginTop: "20px", float: "right" }}
                  >
                    Initiate Payment Request
                  </ButtonInc>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginTop: "20px" }}
                    onClick={()=> props.resetInitiatePolicy()}
                  >
                    Go back
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </ValidatorForm>

      </ModalInc>

    </React.Fragment>

  );



}
