import * as types from '../../lib/actionTypes.es6';
import { take, call, put } from 'redux-saga/effects';
import {
  downloadBulkSms,
  getBulkSmsSummary,
  uploadBulkSms,
  stopBlast, getBatchProgress
} from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';

export function* uploadBulkSmsRequestWatcher() {
  for (;;) {
    const uploadData = yield take(types.UPLOAD_BULK_SMS_REQUEST);
    try {
      const response = yield call(uploadBulkSms, uploadData.payload);
      yield put(actions.uploadBulkSmsSuccess(response.data));
      yield;
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.uploadBulkSmsFailure(errorMessage));
      unAuthorizedNotification(e);
    }
  }
}

export function* summariseBulkSmsRequestWatcher() {
  for (;;) {
    const data = yield take(types.SUMMARY_BULK_SMS_REQUEST);
    try {
      const response = yield call(getBulkSmsSummary, data.payload);
      yield put(actions.summaryBulkSmsSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.summaryBulkSmsFailure(errorMessage));
      unAuthorizedNotification(e);
    }
  }
}

export function* downloadBulkSmsRequestWatcher() {
  for (;;) {
    const data = yield take(types.DOWNLOAD_BULK_SMS_REQUEST);
    try {
      const response = yield call(downloadBulkSms, data.payload);
      yield put(actions.dowloadBulkSmsSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.dowloadBulkSmsFailure(errorMessage));
      unAuthorizedNotification(e);
    }
  }
}


export function* stopBlastRequestWatcher() {
  for (;;) {
    const data = yield take(types.STOP_BLAST_REQUEST);
    try {
      const response = yield call(stopBlast, data.payload);
      yield put(actions.stopBlastSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.stopBlastFailure(errorMessage));
      unAuthorizedNotification(e);
    }
  }
}

export function* getBatchProgressRequestWatcher() {
  for (;;) {
    const data = yield take(types.GET_BATCH_PROGRESS_REQUEST);
    try {
      const response = yield call(getBatchProgress, data.payload);
      yield put(actions.getBatchProgressSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getBatchProgressFailed(errorMessage));
      unAuthorizedNotification(e);
    }
  }
}
