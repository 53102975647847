import React, { useState } from 'react'
import ModalInc from '../../../../../shared-ui/ModalInc'
import ButtonInc from '../../../../../shared-ui/ButtonInc'
import { TextValidator,SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import MenuItem from '@mui/material/MenuItem';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress } from '@material-ui/core';

const AddBenefiary = (props) => {


  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [phone_number, setPhoneNumber] = useState('')
  const [address, setAddress] = useState('')
  const [email, setEmailAddress] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [nationalId, setNationalId] = useState('')
  const [gender, setGender] = useState('');
  const handleChange = (e) => {
    const {name, value} = e.target

    if(name === "first_name"){
      setFirstName(value);
    }
    if(name === "last_name"){
      setLastName(value);
    }
    if(name === "phone_number"){
      setPhoneNumber(value);
    }
    if(name === "address"){
      setAddress(value);
    }
    if(name === "email"){
      setEmailAddress(value);
    }
    if(name === "dateOfBirth"){
      setDateOfBirth(value);
    }
    if(name === "nationalId"){
      setNationalId(value);
    }
    if(name === "gender"){
      setGender(value);
    }
  }

  const handleBeneficiaryDetails = () => {
    const payload = {
      "first_name": first_name,
      "last_name": last_name,
      "msisdn": phone_number,
      "date_of_birth": dateOfBirth,
      "national_id": nationalId,
      "customer_guid": props.customerGuid,
      "address": address,
      "gender": gender,
      "email": email
    }
    props.addBenefiary(payload);
  }
  const handleBeneficiaryCreatedSuccessfully = () =>{
    props.resetAddBeneficiarySuccessAlert();
    props.close()
    
  }

  const handleBeneficiaryCreateError = () =>{
    props.resetAddBenefeciaryErrorAlert();
    props.close()
   
  }
  return (
    <>
           <AlertDialog
              custom
              show={!!props.showAddBeneficiaryProgressAlert}
              size="sm"
              style={{marginTop: '0', top: '30vh'}}
              confirmBtnText="ok"
              showCancel={false}
              showConfirm={false}
              cancelBtnText="cancel"
              showTitle={false}
              confirmBtnCssClass
            >
              <CircularProgress/>
              <h2>Adding Benefiary </h2>
            </AlertDialog>

            <AlertDialog
              success
              show={!!props.showAddBeneficiarySuccessAlert}
              size="sm"
              title="Benefiary added"
              style={{marginTop: '0', top: '30vh'}}
              onConfirm={() => {
                handleBeneficiaryCreatedSuccessfully();
              }}
              confirmBtnText="ok"
              showCancel={false}
              showConfirm
              cancelBtnText="cancel"
              confirmBtnCssClass
            ></AlertDialog>

            <AlertDialog
              show={!!props.showAddBeneficiaryErrorAlert}
              danger
              title="Error adding beneficiary. You can only add one dependany "
              onConfirm={() => handleBeneficiaryCreateError()}
              confirmBtnText="ok"
              confirmBtnCssClass
              showCancel={false}
              style={{marginTop: '0', top: '30vh'}}
            >
              {props.createBeneficiaryError
                ? props.createBeneficiaryError.message
                : ''}
            </AlertDialog>
      <ModalInc
        modalTitle="Add Beneficiary"
        subTitle="Fill the below fields to add  beneficiary details"
        open={props.open}
        onClose={props.close}
        fullWidth
      >
        <ValidatorForm onSubmit={() => handleBeneficiaryDetails()} >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <TextValidator
                name='first_name'
                id="first_name"
                label="First Name "
                value={first_name}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter first name']}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextValidator
                name='last_name'
                id="last_name"
                label="Last Name "
                value={last_name}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter last name']}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <TextValidator
                name='phone_number'
                id="phone_number"
                label="Phone Number "
                type='number'
                value={phone_number}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter phone number']}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextValidator
                name='address'
                id="address"
                label="Address "
                value={address}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter address']}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextValidator
                name='email'
                id="email"
                label="Email "
                value={email}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter email']}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextValidator
                name='dateOfBirth'
                id="dateOfBirth"
                label="Date of Birth "
                value={dateOfBirth}
                onChange={handleChange}
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter date of birth']}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextValidator
                name='nationalId'
                id="nationalId"
                label="National ID "
                value={nationalId}
                type='number'
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter nationalId']}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
            <SelectValidator
                labelId="select-gender"
                id="select-gender"
                value={gender}
                name='gender'
                type="text"
                label="Gender"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select gender']}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >
                <MenuItem sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }} value="Male">Male</MenuItem>
                <MenuItem sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }} value="Female">Female</MenuItem>

              </SelectValidator>
            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'center' }}>
                  <ButtonInc
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{ width: "221px", height: "55px", background: "#FBA92D", marginTop: '3%' }}
                  > Save</ButtonInc>
                </Grid>
                <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'center' }}>
                  <ButtonInc
                    size="large"
                    onClick={props.close}
                    variant="contained"
                    style={{ width: "221px", height: "55px", background: "#FBA92D", marginTop: '3%' }}
                  > Cancel</ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </ModalInc>
    </>
  )
}

export default AddBenefiary