import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, InputAdornment } from "@mui/material";
import AlertDialog from "../../../../../components/AlertDialog/index.es6";
import { PAYMENT_METHODS } from "../../../PoductConstants";
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../../../lib/constants";
const initialState = {
  granularity: '',
  payment_method: '',
  cardinality: '',
  amount_in_cents: '',
}
export default function AddPremium(props) {
  const [
    {
      granularity,
      payment_method,
      cardinality,
      amount_in_cents,
    }, setState
  ] = React.useState(initialState)

  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };


  const reset = () => {
    setState(initialState);
  }


  const submit = () => {
    const payload = {
      granularity,
      payment_method,
      cardinality: Number(cardinality),
      amount_in_cents: Number(amount_in_cents) * 100,
      product_guid: props.product.guid,
      active: true,
    }
    props.addPremium(payload)
  }


  const handlePremiumCreatedSuccessfully = () => {
    props.resetAddPremiumSuccessAlert()
    reset()
    props.closeAddPremiumDialog()
    props.refreshProduct();
  }

  const handlePremiumCreateError = () => {
    props.resetAddPremiumErrorAlert()
  }

  return (
    <React.Fragment>


      <Dialog
        open={props.openAddPremiumDialog} fullWidth
        maxWidth='lg'
        onClose={props.closeAddPremiumDialog}
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.closeAddPremiumDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Premium
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div style={{ marginTop: "2%" }}>
            <Box sx={{ width: "100%" }}>
            <ValidatorForm onSubmit={() => submit()} >
        <AlertDialog
          custom
          show={props.showAddPremiumProgressAlert}
          size="sm"
          style={{ marginTop: '0', top: '30vh' }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={'cancel'}
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2>Adding premium</h2>
        </AlertDialog>

        <AlertDialog
          success
          show={props.showAddPremiumSuccessAlert}
          size="sm"
          title={'Premium added'}
          style={{ marginTop: '0', top: '30vh' }}
          onConfirm={() => { handlePremiumCreatedSuccessfully() }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={'cancel'}
          confirmBtnCssClass
        >
          <div >
            <Button variant="contained" onClick={() => { handlePremiumCreatedSuccessfully() }} style={{ background: 'green', }}>Ok</Button>
          </div>
        </AlertDialog>

        <AlertDialog
          show={props.showAddPremiumErrorAlert}
          danger
          title={'Error adding premium'}
          onConfirm={() => handlePremiumCreateError()}
          confirmBtnText={'Try again'}
          confirmBtnCssClass
          showCancel={false}
          style={{ marginTop: '0', top: '30vh' }}
        >
          {props.createPremiumError ? props.createPremiumError.message : ''}
        </AlertDialog>

        <Grid container spacing={2}>
          <Grid item lg={3} md={6} xs={12}>

            <TextValidator
              onChange={onChange}
              value={amount_in_cents}
              label='amount'
              name='amount_in_cents'
              fullWidth
              variant="outlined"
              type="number"
              placeholder='amount'
              validators={['required']}
              errorMessages={['Please enter amount']}
              InputProps={{
                startAdornment: (<InputAdornment position="start">
                {props.currencyCode}
                </InputAdornment>),

                'aria-label': 'Ksh',
              }}
            />
          </Grid>


          <Grid item lg={3} md={6} xs={12}>
            <SelectValidator
              value={payment_method}
              label='payment method'
              id="payment method"
              name='payment_method'
              onChange={onChange}
              className="form-control"
              type="text"
              variant="outlined"
              validators={['required']}
              errorMessages={['Please select payment method']}
            >
              <MenuItem value="">{'Please select payment method'}</MenuItem>
              {
                PAYMENT_METHODS.map((type) => {
                  return <MenuItem value={type}>{type}</MenuItem>
                })
              }
            </SelectValidator>
          </Grid>

          <Grid item lg={3} md={6} xs={12}>
            <SelectValidator
              value={granularity}
              label='granularity'
              id="granularity"
              name='granularity'
              onChange={onChange}
              className="form-control"
              type="text"
              variant="outlined"
              validators={['required']}
              errorMessages={['Please select granularity']}
            >
              <MenuItem value="">{'Please select granularity'}</MenuItem>
              {
                ['Day', 'Week', 'Month', 'Year'].map((type) => {
                  return <MenuItem value={type}>{type}</MenuItem>
                })
              }
            </SelectValidator>
          </Grid>



          <Grid item lg={3} md={6} xs={12}>

            <TextValidator
              onChange={onChange}
              value={cardinality}
              label='cardinality'
              name='cardinality'
              fullWidth
              variant="outlined"
              type="number"
              placeholder='amount'
              validators={['required']}
              errorMessages={['Please enter cardinality']}
            />
          </Grid>

          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button variant="contained" type="submit" style={{ marginRight: '2%' }}> Save </Button>
            <Button variant="contained" color="secondary" style={{ marginRight: '2%' }} onClick={() => reset()}> Reset</Button>
          </Grid>

        </Grid>
      </ValidatorForm>

            </Box>
          </div>

        </DialogContent>
      </Dialog>

    </React.Fragment>

  );
}
