
import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SelectPlan.css'
import { white } from 'material-ui/styles/colors';
import { numberInCommaFormat } from '../../../../../../lib/access.es6';
import { getNuclearPremium, getNuclearBenefit } from '../../../helpers/index';

export default function SelectPlan({plans, hasParentsPlan, selectedPlan, setPlan, setParentsPlan, coverType}) {
  const [selected, setSelected] = useState(null);

  const handleSelect = (value) => {
    setSelected(value);
    handleSelect2(value)
  }

  const handleSelect2 = (plan) => {
    if(hasParentsPlan === true){
      setParentsPlan(plan);
    }else{
      setPlan(plan);
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2
  };


  const updatedPlans = plans.map((plan) => {
    const rank = parseInt(plan.name.split(" ")[1]);
    return { ...plan, rank };
  });

  const filteredPlans = hasParentsPlan === true ? updatedPlans.filter(plan => plan.rank < selectedPlan.rank) : updatedPlans


  return (
    <div style={{ background: 'light grey', padding: '2%' }}>
      <Slider {...settings}>
        {filteredPlans.map((plan, index) => (
          <div key={index}>
            <div
              onClick={() => handleSelect(plan)}
              style={{
                color: (selected && selected.name === plan.name) ? white : '#bdbdbd',
                fontSize: 20,
                borderRadius: '10%',
                transition: 'all 0.3s ease-in-out',
                cursor: 'pointer',
                background: (selected && selected.name === plan.name) ? 'orange' : '#032a37',
                margin: '3%',
                textAlign: 'center'
              }}
            >
              <div style={{ textTransform: 'uppercase' }}>{plan.name}</div>
              <hr style={{ margin: '0px' }} />
              <div>premium: {numberInCommaFormat(getNuclearPremium(plan, coverType) / 100)}</div>
              <div>benefit: {numberInCommaFormat(getNuclearBenefit(plan) / 100)}</div>
            </div>
            <hr />
          </div>
        ))}
      </Slider>
    </div>
  )
}
