import * as types from '../../../lib/actionTypes.es6';
import { getPaginatedGroups } from '../../../lib/utils.es6';

export const initialState = {
  errors: null,
  loader: false,
  groups: null,
};

export default function listAllGroupsReducer(state = initialState, action) {
  switch (action.type) {

    case types.GET_GROUPS_REQUEST:
      return { ...state, loader: true, errors: null, groups: null };

    case types.GET_GROUPS_SUCCESS:
      return { ...state, loader: false, ...getPaginatedGroups(action.payload) };

    case types.GET_GROUPS_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    case types.DELETE_MY_GROUP_REQUEST:
      return { ...state, loader: true, errors: null,};

    case types.DELETE_MY_GROUP_SUCCESS:
      return { ...state, loader: false, };

    case types.DELETE_MY_GROUP_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    default:
      return state;
  }
}
