import * as types from "../../../../lib/actionTypes.es6";



export function addBulkPaymentsRequest(payload) {
  return {
    type: types.ADD_BULK_PAYMENTS_REQUEST,
    payload,
  };
}

export function addBulkPaymentsSuccess(response) {
  return {
    type: types.ADD_BULK_PAYMENTS_SUCCESS,
    payload: response,
  };
}

export function addBulkPaymentsFailed(error) {
  return {
    type: types.ADD_BULK_PAYMENTS_FAILURE,
    payload: error,
  };
}



export function saveBulkPaymentsRequest(payload) {
  return {
    type: types.SAVE_BULK_PAYMENTS_REQUEST,
    payload,
  };
}

export function saveBulkPaymentsSuccess(response) {
  return {
    type: types.SAVE_BULK_PAYMENTS_SUCCESS,
    payload: response,
  };
}

export function saveBulkPaymentsFailed(error) {
  return {
    type: types.SAVE_BULK_PAYMENTS_FAILURE,
    payload: error,
  };
}



export function getBulkPaymentsBatchesRequest(payload){
  return{
    type: types.GET_BULK_PAYMENTS_BATCHES_REQUEST,
    payload: payload
  }

}

export function getBulkPaymentsBatchesSuccess(payload){
  return{
    type: types.GET_BULK_PAYMENTS_BATCHES_SUCCESS,
    payload: payload
  }

}


export function getBulkPaymentsBatchesFailure(error){
  return{
    type: types.GET_BULK_PAYMENTS_BATCHES_FAILURE,
  }
}

export function getFailedBatchesRequest(batch_guid){
  return{
    type: types.GET_FAILED_PAYMENTS_BATCHES_REQUEST,
    payload: batch_guid
  }

}

export function getFailedBatchesSuccess(payload){
  return{
    type: types.GET_FAILED_PAYMENTS_BATCHES_SUCCESS,
    payload: payload
  }

}


export function getFailedBatchesFailure(error){
  return{
    type: types.GET_BATCHES_PAYMENTS_FAILURE,
  }
}