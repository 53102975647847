
import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { hashHistory } from 'react-router';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, Card, Grid, InputAdornment, OutlinedInput, Popper } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

// project imports
import Transitions from '../../ui-component/extended/Transitions';

// assets
import { shouldForwardProp } from '@mui/system';

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
    zIndex: 1100,
    width: '99%',
    top: '-55px !important',
    padding: '0 12px',
    [theme.breakpoints.down('sm')]: {
        padding: '0 10px'
    }
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
    width: 434,
    marginLeft: "45px",
    paddingLeft: 16,
    paddingRight: 16,
    fontWeight: 400,
    lineHeight: "1.4375em",
    fontFamily: "Open Sans",
    '& input': {
        background: 'transparent !important',
        paddingLeft: '4px !important',
        fontFamily: "Open Sans",
    },
    '& input[type=search]': {
        boxSizing: "unset !important",
    },
    "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
        top: "0px",
        "& legend": {
            float: "unset",
            overflow: "hidden",
            padding: 0,
            lineHeight: "0px",
            width: "0"
        }
    },
    [theme.breakpoints.down('lg')]: {
        width: 250
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 4,
        //background: "#032A37"
    }
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: "#032A37",
    height: "34px",
    width: "34px",
    marginTop: "10px",
    color: "#fff",
    cursor: "pointer",
    marginLeft: "10px",
    '&:hover': {
        background: "#032A37",
        color: "#fff"
    }
}));

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, popupState, handleKeyPress, searchCriteria, searchForCustomer, handleSearchFilterClose }) => {


    const handleSearchMobile = (event) => {

        hashHistory.push(`/admin/search?q=${value}`);

    };

    return (
        <OutlineInputStyle
            id="input-search-header"
            value={value}
            type="search"
            onChange={(e) => setValue(e.target.value)}
            placeholder="Search here"
            onKeyPress={handleKeyPress}
            endAdornment={
                <InputAdornment position="end">
                    <HeaderAvatarStyle variant="rounded" onClick={handleSearchMobile}>
                        <SearchOutlinedIcon

                        />
                    </HeaderAvatarStyle>
                    <Box sx={{ ml: 2 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                background: "#032A37",
                                color: "#fff",
                                width: "34px",
                                height: "34px",
                                marginTop: "7px",
                                '&:hover': {
                                    background: "#fff",
                                    color: "#000"
                                }
                            }}
                            {...bindToggle(popupState)}
                        >
                            <CloseOutlinedIcon size="20px" />
                        </Avatar>
                    </Box>
                </InputAdornment>
            }
            aria-describedby="search-helper-text"
            inputProps={{ 'aria-label': 'weight' }}
        />
    );
};

MobileSearch.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    popupState: PopupState,
    searchCriteria: PropTypes.string,
    searchForCustomer: PropTypes.func,
    handleSearchFilterClose: PropTypes.func,
};

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = ({ searchForCustomer }) => {


    const theme = useTheme();
    const [value, setValue] = useState('');




    const handleSearchFilterClose = () => {
      //  setShowSearchFilter(false)
    }




    const handleKeyPressElasticSearch = (event) => {
        if (event.key === 'Enter') {
            const searchValue = event.target.value;
            hashHistory.push(`/admin/search?q=${searchValue}`)
    }
}




    const handleSearchDesktop = (event) => {
        hashHistory.push(`/admin/search?q=${value}`);
    };



    return (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <PopupState variant="popper" popupId="demo-popup-popper">
                    {(popupState) => (
                        <>
                            <Box sx={{ ml: 2 }}>
                                <HeaderAvatarStyle variant="rounded" {...bindToggle(popupState)}>
                                    <SearchOutlinedIcon stroke={1.5} size="19px" />
                                </HeaderAvatarStyle>
                            </Box>
                            <PopperStyle {...bindPopper(popupState)} transition>
                                {({ TransitionProps }) => (
                                    <>
                                        <Transitions type="zoom" {...TransitionProps} sx={{ transformOrigin: 'center left' }}>
                                            <Card
                                                sx={{
                                                    background: theme.palette.mode === 'dark' ? theme.palette.dark[900] : '#fff',
                                                    [theme.breakpoints.down('sm')]: {
                                                        border: 0,
                                                        boxShadow: 'none'
                                                    }
                                                }}
                                            >
                                                <Box sx={{ p: 2 }}>
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item xs>
                                                            <MobileSearch value={value} setValue={setValue} popupState={popupState} handleKeyPress={handleKeyPressElasticSearch}
                                                                searchForCustomer={handleKeyPressElasticSearch}
                                                                handleSearchFilterClose={handleSearchFilterClose}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Card>
                                        </Transitions>
                                    </>
                                )}
                            </PopperStyle>
                        </>
                    )}
                </PopupState>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <OutlineInputStyle
                    id="input-search-header"
                    value={value}
                    type="search"
                    onChange={(e) => setValue(e.target.value)}
                    onKeyPress={handleKeyPressElasticSearch}
                    placeholder={'Search here'}
                    endAdornment={
                        <InputAdornment position="end" >
                            <HeaderAvatarStyle variant="rounded" onClick={handleSearchDesktop}>
                                <SearchOutlinedIcon

                                />
                            </HeaderAvatarStyle>

                        </InputAdornment>
                    }
                    aria-describedby="search-helper-text"
                    inputProps={{ 'aria-label': 'weight' }}
                />
            </Box>
        </>
    );
};

export default SearchSection;
