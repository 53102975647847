import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { CircularProgress } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AlertDialog from "../AlertDialog/index.es6";
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../lib/constants";

let initialState = {
  account_name: "",
  account_number: "",
  account_type: "",
  branch_code: "",
  bank_name: "",
}
export default function EditBankDetails(props) {

  if(!props.bankDetails){
   return ""
  }

  initialState = props.bankDetails

  const [
    {
      account_name,
      bank_name,
      account_number,
      account_type,
      branch_code,
    }, setState
  ] = React.useState(initialState)



  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };


  const reset = () => {
    setState(initialState);
  }


  const submit = () => {
    const payload = {
      account_name,
      bank_name,
      account_number,
      account_type,
      branch_code,
      guid: props.bankDetails.guid,
      customer_guid: props.customerGuid,
      beneficiary_guid: props.bankDetails.beneficiary_guid
    }
    props.editBankDetails(payload)
    
  }

  const handleEditBankDetailsEditedSuccessfully = () => {
    props.resetEditBankDetailsSuccessAlert()
    props.resetBankDetailsToEdit()
    reset()
  }

  const handleEditBankDetailsCreateError = () => {
    props.resetEditBankDetailsErrorAlert()
  }

    return (
      <React.Fragment>
        <AlertDialog
          custom
          show={props.showEditBankDetailsProgressAlert}
          size="sm"
          style={{ marginTop: '0', top: '30vh' }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={'cancel'}
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2>Updating bankDetails</h2>
        </AlertDialog>
  
        <AlertDialog
          success
          show={props.showEditBankDetailsSuccessAlert}
          size="sm"
          title={'Bank Details updated'}
          style={{ marginTop: '0', top: '30vh' }}
          onConfirm={() => { handleEditBankDetailsEditedSuccessfully() }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={'cancel'}
          confirmBtnCssClass
        >
          <div >
            <Button variant="contained" onClick={() => { handleEditBankDetailsEditedSuccessfully() }} style={{ background: 'green', }}>Ok</Button>
          </div>
        </AlertDialog>
  
        <AlertDialog
          show={props.showEditBankDetailsErrorAlert}
          danger
          title={'Error updating bankDetails'}
          onConfirm={() => handleEditBankDetailsCreateError()}
          confirmBtnText={'Try again'}
          confirmBtnCssClass
          showCancel={false}
          style={{ marginTop: '0', top: '30vh' }}
        >
          {props.editBankDetailsError ? props.editBankDetailsError.message : ''}
        </AlertDialog>
  
  
  
  
  
        <Dialog
          open={!!props.bankDetails} fullWidth
          maxWidth='lg'
          onClose={props.resetBankDetailsToEdit}
          fullScreen
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={props.resetBankDetailsToEdit}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Edit BankDetails
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <div style={{ marginTop: "2%" }}>
              <Box sx={{ width: "100%" }}>
                <ValidatorForm onSubmit={() => submit()} >
                  <Grid container spacing={2}>
                  <Grid item lg={3} md={3} xs={12}>
                <TextValidator
                  onChange={onChange}
                  value={account_name}
                  label='Account Name'
                  name='account_name'
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder='Account Name'
                  validators={['required']}
                  errorMessages={['Please enter account name']}
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12}>
                <TextValidator
                  onChange={onChange}
                  value={bank_name}
                  label='Bank Name'
                  name='bank_name'
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder='Bank Name'
                  validators={['required']}
                  errorMessages={['Please enter bank name']}
                />
              </Grid>


              <Grid item lg={3} md={3} xs={12}>
                <TextValidator
                  onChange={onChange}
                  value={account_number}
                  label='Account Number'
                  name='account_number'
                  fullWidth
                  variant="outlined"
                  type="number"
                  placeholder='Account Number'
                  validators={['required']}
                  errorMessages={['Please enter account number']}
                />
              </Grid>


              <Grid item lg={3} md={6} xs={12}>
                <TextValidator
                  onChange={onChange}
                  value={account_type}
                  label='Account Type'
                  name='account_type'
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder='Account Type'
                  validators={['required']}
                  errorMessages={['Please enter account type']}
                />
              </Grid>


              <Grid item lg={3} md={6} xs={12}>
                <TextValidator
                  onChange={onChange}
                  value={branch_code}
                  label='Branch Code'
                  name='branch_code'
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder='Account Type'
                  validators={['required']}
                  errorMessages={['Please enter account type']}
                />
              </Grid>
  
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Button variant="contained" type="submit" style={{ marginRight: '2%' }}> Update </Button>
                      <Button variant="contained" color="secondary" style={{ marginRight: '2%' }} onClick={() => props.resetBankDetailsToEdit()}> Cancel</Button>
                    </Grid>
  
                  </Grid>
                </ValidatorForm>
              </Box>
            </div>
  
          </DialogContent>
        </Dialog>
      </React.Fragment>
  
    );
 

 
}
