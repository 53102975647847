import * as types from '../../../lib/actionTypes.es6';

export const initialState = {


  getEmailTemplatesError: null,
  getEmailTemplatesloader: false,
  emailTemplates: [],
  isEmptyTemplates: false,

  addEnailTemplateError: null,
  addEmailTemplateLoader: false,
  addedEmailTemplate: null,

  deleteEmailTemplateError: null,
  deleteEmailTemplateLoader: false,
  deleteEmailTemplate: null,
};

export default function emailTemplatesReducer(state = initialState, action) {
  switch (action.type) {


    case types.GET_EMAIL_TEMPLATES_REQUEST:
      return {
        ...state,
        getEmailTemplatesloader: true,
        getEmailTemplatesError: null,
        emailTemplates: [],
        isEmptyTemplates: false,
      };

    case types.GET_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        emailTemplates: action.payload,
        getEmailTemplatesloader: false,
        isEmptyTemplates: action.payload.length === 0
      };

    case types.GET_EMAIL_TEMPLATES_FAILURE:
      return {
        ...state,
        getEmailTemplatesloader: false,
        getEmailTemplatesError: action.payload,
        isEmptyTemplates: false,
      };

      case types.RESET_GET_EMAIL_TEMPLATES:
        return {
          ...state,
          getEmailTemplatesError: null,
          getEmailTemplatesloader: false,
          emailTemplates: [],
          isEmptyTemplates: false,
        };

    case types.ADD_TEMPLATE_DOCUMENT_REQUEST:
      return {
        ...state,
        addEmailTemplateLoader: true,
        addEnailTemplateError: null,
        addedEmailTemplate: null,
      };

    case types.ADD_TEMPLATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        addedEmailTemplate: action.payload,
        addEmailTemplateLoader: false,
      };

    case types.ADD_TEMPLATE_DOCUMENT_FAILURE:
      return {
        ...state,
        addEmailTemplateLoader: false,
        addEnailTemplateError: action.payload,
      };

    case types.DELETE_TEMPLATE_DOCUMENT_REQUEST:
      return {
        ...state,
        deleteEmailTemplateLoader: true,
        deleteEmailTemplateError: null,
        deleteEmailTemplate: null,
      };

    case types.DELETE_TEMPLATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleteEmailTemplateLoader: false,
        deleteEmailTemplate: true,
      };

    case types.DELETE_TEMPLATE_DOCUMENT_FAILURE:
      return {
        ...state,
        deleteEmailTemplateLoader: false,
        deleteEmailTemplateError: action.payload,
      };

    default:
      return state;
  }
}
