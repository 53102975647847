export const PartnersData = [
  {
    "guid": "accessbank",
    "code": "accessbank",
    "name": "Access Bank",
    "active": true,
    "country_code": "BWA",
    "default_language": "en",
    "created_at": "2023-10-20T13:02:28.909Z",
    "call_centre_number": "0",
    "ussd_shortcode": "*000#",
    "whats_app_number": "0",
    "service_name": "Access bank",
    "insurer": "Access bank",
    "languages": [],
    "registration_fields": [],
    "web_display_fields": [],
    "loyalty_fields": [],
    "customer_fields": [],
    "policy_fields": [],
    "throw_sms_errors": false,
    "check_age_range": true,
    "can_debit": false,
    "can_edit_debit": false,
    "pull_kyc": false,
    "maximum_age": null,
    "minimum_age": null,
    "has_cool_off": false,
    "can_pay_claim": false,
    "mobile_payment_popup_active": false,
    "partial_premium_payment_enabled": false,
    "passive_debit_order": false,
    "policy_scheme": "Simple",
    "partial_payment_backdated": false,
    "claim_types": []
  },
  {
    "guid": "kymbritam",
    "code": "ktda",
    "name": "KDTA-Britam",
    "country_code": "KE",
    "created_at": "2023-05-03 10:39:26.000000",
    "call_centre_number": "0",
    "whats_app_number": "0",
    "default_language": "en",
    "ussd_shortcode": "*000#",
    "service_name": "Kinga Ya Mkulima",
    "insurer": "Britam",
    "editor_id": "91",
    "active": 1
  },
  {
    "guid": "twendeguid",
    "code": "twende",
    "name": "Twende",
    "country_code": "ZM",
    "created_at": "2023-04-26 12:03:38.000000",
    "call_centre_number": "211 123 456",
    "whats_app_number": "211 123 456",
    "default_language": "en",
    "ussd_shortcode": "N/A",
    "service_name": "Twende",
    "insurer": "Professional Insurance",
    "editor_id": "43",
    "active": 1
  },
  {
    "guid": "airtelkeguid",
    "code": "airtelkenya",
    "name": "Airtel Kenya",
    "country_code": "KE",
    "created_at": "2022-06-10 10:39:26.000000",
    "call_centre_number": "0",
    "whats_app_number": "0",
    "default_language": "en",
    "ussd_shortcode": "*000#",
    "service_name": "Airtel Kenya",
    "insurer": "Britam",
    "editor_id": 91,
    "active": 0
  },
  {
    "guid": "airtelmalawiguid",
    "code": "airtelmalawiguid",
    "name": "Airtel Malawi",
    "country_code": "MLI",
    "created_at": "2022-08-24 06:48:23.730000",
    "call_centre_number": "0",
    "whats_app_number": "0",
    "default_language": "en",
    "ussd_shortcode": "*000#",
    "service_name": "xyz Insurance",
    "insurer": "Britam",
    "editor_id": 188,
    "active": 1
  },
  {
    "guid": "airtelzambia",
    "code": "airtelzambia",
    "name": "Airtel Zambia",
    "country_code": "ZM",
    "created_at": "2022-07-14 18:40:34.326000",
    "call_centre_number": "0000000000",
    "whats_app_number": "0000000000",
    "default_language": "en",
    "ussd_shortcode": "*123#",
    "service_name": "Airtel Zambia",
    "insurer": "Absa",
    "editor_id": 184,
    "active": 1
  },
  {
    "guid": "atlehang_everest",
    "code": "atlehang_everest",
    "name": "Atlehang  Everest",
    "country_code": "SA",
    "created_at": "2022-08-01 09:16:15.488000",
    "call_centre_number": "0027603885520",
    "whats_app_number": "0027603885520",
    "default_language": "en",
    "ussd_shortcode": "N/A",
    "service_name": "Atlehang  Everest Funeral Cover",
    "insurer": "Bright Rock",
    "editor_id": 91,
    "active": 1
  },
  {
    "guid": "atlehang_life",
    "code": "atlehang_life",
    "name": "Atlehang Life",
    "country_code": "SA",
    "created_at": "2022-07-29 07:22:01.543000",
    "call_centre_number": "27833579417",
    "whats_app_number": "27833579417",
    "default_language": "en",
    "ussd_shortcode": "*000",
    "service_name": "Atlehang Funeral",
    "insurer": "Atlehang Life",
    "editor_id": 197,
    "active": 1
  },
  {
    "guid": "baobabguid",
    "code": "baobab",
    "name": "Baobab",
    "country_code": "CI",
    "created_at": "2021-08-11 11:47:41.000000",
    "call_centre_number": "12345",
    "whats_app_number": "1234567",
    "default_language": "fr",
    "ussd_shortcode": "*123#",
    "service_name": "Baobab",
    "insurer": "Prudential Belife",
    "editor_id": 80,
    "active": 1
  },
  {
    "guid": "barclays",
    "code": "barclays",
    "name": "barclays",
    "country_code": "KE",
    "created_at": "2022-11-03 13:54:09.363000",
    "call_centre_number": "254703787073",
    "whats_app_number": "254703787073",
    "default_language": "en",
    "ussd_shortcode": "*000#",
    "service_name": "ORANGE",
    "insurer": "Britam",
    "editor_id": 43,
    "active": 1
  },
  {
    "guid": "britam_test_bed",
    "code": "britam_test_bed",
    "name": "BRITAM-TEST BED",
    "country_code": "KE",
    "created_at": "2022-08-19 13:13:25.573000",
    "call_centre_number": "00254721626041",
    "whats_app_number": "00254721626041",
    "default_language": "en",
    "ussd_shortcode": "5678",
    "service_name": "Test bed",
    "insurer": "Britam",
    "editor_id": 166,
    "active": 1
  },
  {
    "guid": "britamguid",
    "code": "britam",
    "name": "GLE",
    "country_code": "KE",
    "created_at": "2020-09-16 15:36:18.000000",
    "call_centre_number": "123455678",
    "whats_app_number": "234556783",
    "default_language": "en",
    "ussd_shortcode": "*123#",
    "service_name": "Group Last expense/funeral cover",
    "insurer": "Britam",
    "editor_id": 80,
    "active": 1
  },
  {
    "guid": "centrinoguid",
    "code": "centrinoguid",
    "name": "Centrino",
    "country_code": "KE",
    "created_at": "2022-08-22 09:23:31.680000",
    "call_centre_number": "0",
    "whats_app_number": "0",
    "default_language": "en",
    "ussd_shortcode": "*000#",
    "service_name": "CenSure",
    "insurer": "Britam",
    "editor_id": 188,
    "active": 1
  },
  {
    "guid": "dcf33785f2cf48f38c719d44180ee0f4",
    "code": "THUSA",
    "name": "Instafinance",
    "country_code": "ZM",
    "created_at": "2022-05-19 13:22:48.129000",
    "call_centre_number": "260 960 907 337",
    "whats_app_number": "260 960 907 337",
    "default_language": "en",
    "ussd_shortcode": "260 960 907 337",
    "service_name": "Thusa ",
    "insurer": "HOLLARD",
    "editor_id": 166,
    "active": 1
  },
  {
    "guid": "demo",
    "code": "Demo",
    "name": "Inclusivity Demo",
    "country_code": "SA",
    "created_at": "2021-06-09 08:31:49.000000",
    "call_centre_number": "07646244",
    "whats_app_number": "08696244",
    "default_language": "en",
    "ussd_shortcode": "*222#",
    "service_name": "Inclusivity Demo",
    "insurer": "insuretech",
    "editor_id": 184,
    "active": 1
  },
  {
    "guid": "equityguid",
    "code": "equity",
    "name": "Equity",
    "country_code": "KE",
    "created_at": "2017-05-09 23:58:32.000000",
    "call_centre_number": "0763063745",
    "whats_app_number": "0763123745",
    "default_language": "en",
    "ussd_shortcode": "*745#",
    "service_name": "Riziki Cover",
    "insurer": "Britam",
    "editor_id": 43,
    "active": 1
  },
  {
    "guid": "equityugandaguid",
    "code": "equityuganda",
    "name": "Equity Uganda",
    "country_code": "UG",
    "created_at": "2020-11-25 13:56:30.000000",
    "call_centre_number": "123455678",
    "whats_app_number": "234556783",
    "default_language": "en",
    "ussd_shortcode": "*123#",
    "service_name": "Equity Uganda",
    "insurer": "Prudential",
    "editor_id": 80,
    "active": 1
  },
  {
    "guid": "everestguid",
    "code": "everestguid",
    "name": "Everest Funerals",
    "country_code": "SA",
    "created_at": "2022-11-29 04:07:43.000000",
    "call_centre_number": "0",
    "whats_app_number": "0",
    "default_language": "en",
    "ussd_shortcode": "*000#",
    "service_name": "Everest Funerals",
    "insurer": "Atehang Life",
    "editor_id": 80,
    "active": 1
  },
  {
    "guid": "firstinsuretechguid",
    "code": "firstinsuretech",
    "name": "First Insure Tech",
    "country_code": "UAE",
    "created_at": "2019-07-29 09:46:11.000000",
    "call_centre_number": "0763063745",
    "whats_app_number": "0763123745",
    "default_language": "en",
    "ussd_shortcode": "*745#",
    "service_name": "Hollard Cover",
    "insurer": "Hospital Cover",
    "editor_id": 43,
    "active": 1
  },
  {
    "guid": "fnbzambiaguid",
    "code": "fnbzambia",
    "name": "FNB Zambia",
    "country_code": "ZM",
    "created_at": "2020-06-03 12:03:38.000000",
    "call_centre_number": "211 355 935",
    "whats_app_number": "211 255 681",
    "default_language": "en",
    "ussd_shortcode": "N/A",
    "service_name": "FNB Zambia Insurance",
    "insurer": "Hollard",
    "editor_id": 43,
    "active": 1
  },
  {
    "guid": "hollardzambiaguid",
    "code": "hollardzambia",
    "name": "Hollard Zambia",
    "country_code": "ZM",
    "created_at": "2019-07-08 15:15:32.000000",
    "call_centre_number": "0763063745",
    "whats_app_number": "0763123745",
    "default_language": "en",
    "ussd_shortcode": "*745#",
    "service_name": "Hollard Cover",
    "insurer": "Hollard Zambia Insuarer",
    "editor_id": 43,
    "active": 1
  },
  {
    "guid": "imarishaguid",
    "code": "imarishaguid",
    "name": "Imarisha Sacco",
    "country_code": "KE",
    "created_at": "2022-08-22 12:39:29.181000",
    "call_centre_number": "0",
    "whats_app_number": "0",
    "default_language": "en",
    "ussd_shortcode": "*000#",
    "service_name": "IM-Sure",
    "insurer": "Britam",
    "editor_id": 188,
    "active": 1
  },
  {
    "guid": "Inclusivity_Agency_Kenya",
    "code": "Inclusivity_Agency_Kenya",
    "name": "Inclusivity Agency Kenya",
    "country_code": "KE",
    "created_at": "2022-08-22 08:19:28.792000",
    "call_centre_number": "254759305955",
    "whats_app_number": "254759305955",
    "default_language": "en",
    "ussd_shortcode": "*000",
    "service_name": "Agency",
    "insurer": "N/A",
    "editor_id": 197,
    "active": 1
  },
  {
    "guid": "instafinanceguid",
    "code": "instafinance",
    "name": "Insta Finance",
    "country_code": "ZM",
    "created_at": "2022-05-18 09:06:42.000000",
    "call_centre_number": "260960907337",
    "whats_app_number": "260777749922",
    "default_language": "en",
    "ussd_shortcode": "*000#",
    "service_name": "Insta Finance",
    "insurer": "Hollard Zambia",
    "editor_id": 80,
    "active": 1
  },
  {
    "guid": "jdfuneralsguid",
    "code": "jdfuneralsguid",
    "name": "JD Funerals",
    "country_code": "SA",
    "created_at": "2022-11-29 04:07:43.000000",
    "call_centre_number": "0",
    "whats_app_number": "0",
    "default_language": "en",
    "ussd_shortcode": "*000#",
    "service_name": "JD Funerals",
    "insurer": "Atehang Life",
    "editor_id": 80,
    "active": 1
  },
  // {
  //   "guid": "Konyana_Fun",
  //   "code": "Konyana_Fun",
  //   "name": "Konyana Funeral Directors",
  //   "country_code": "SA",
  //   "created_at": "2022-08-19 09:32:18.515000",
  //   "call_centre_number": "0837835138",
  //   "whats_app_number": "0837835138",
  //   "default_language": "en",
  //   "ussd_shortcode": "000",
  //   "service_name": "Atlehang Funeral",
  //   "insurer": "Atlehang Life",
  //   "editor_id": 197,
  //   "active": 1
  // },
  {
    "guid": "atlehangguid",
    "code": "atlehangguid",
    "name": "Atlehang Life",
    "country_code": "SA",
    "created_at": "2022-11-29 04:07:42.000000",
    "call_centre_number": "0",
    "whats_app_number": "0",
    "default_language": "en",
    "ussd_shortcode": "*000#",
    "service_name": "Atlehang Life",
    "insurer": "Atehang Life",
    "editor_id": 80,
    "active": 1
  },
  {
    "guid": "lebowaguid",
    "code": "lebowaguid",
    "name": "Lebowa Funerals",
    "country_code": "SA",
    "created_at": "2022-11-29 04:07:43.000000",
    "call_centre_number": "0",
    "whats_app_number": "0",
    "default_language": "en",
    "ussd_shortcode": "*000#",
    "service_name": "Lebowa Funerals",
    "insurer": "Atehang Life",
    "editor_id": 80,
    "active": 1
  },
  {
    "guid": "limlakguid",
    "code": "limlakguid",
    "name": "Limlak Sacco",
    "country_code": "KE",
    "created_at": "2022-08-22 12:38:03.597000",
    "call_centre_number": "0",
    "whats_app_number": "0",
    "default_language": "en",
    "ussd_shortcode": "*000#",
    "service_name": "Lim Sure",
    "insurer": "Britam",
    "editor_id": 188,
    "active": 1
  },
  {
    "guid": "mfs",
    "code": "mfs",
    "name": "Mfs Remittances",
    "country_code": "RW",
    "created_at": "2020-03-13 10:02:22.081000",
    "call_centre_number": "123455678",
    "whats_app_number": "123455678",
    "default_language": "rw",
    "ussd_shortcode": "*123#",
    "service_name": "MFS Insurance",
    "insurer": "Radiant",
    "editor_id": 91,
    "active": null
  },
  {
    "guid": "mfsguid",
    "code": "mfs",
    "name": "Mfs Remittances (4 UAT)",
    "country_code": "RW",
    "created_at": "2020-04-07 06:04:30.000000",
    "call_centre_number": "123455678",
    "whats_app_number": "234556783",
    "default_language": "en",
    "ussd_shortcode": "*123#",
    "service_name": "MFS Insurance",
    "insurer": "Radiant",
    "editor_id": 43,
    "active": 1
  },
  {
    "guid": "MMI_Ghana",
    "code": "MMI_Ghana",
    "name": "MMI Ghana",
    "country_code": "GH",
    "created_at": "2022-08-11 09:31:21.384000",
    "call_centre_number": "233000000001",
    "whats_app_number": "233000000001",
    "default_language": "en",
    "ussd_shortcode": "*170#",
    "service_name": "Metropolitan Life",
    "insurer": "Metropolitan",
    "editor_id": 197,
    "active": 1
  },
  {
    "guid": "mozambique_telecom",
    "code": "mozambique_telecom",
    "name": "Mozambique Telecom",
    "country_code": "MZ",
    "created_at": "2022-07-13 15:57:35.970000",
    "call_centre_number": "0723456787",
    "whats_app_number": "0823456787",
    "default_language": "en",
    "ussd_shortcode": "*12345#",
    "service_name": "Moz Insurance",
    "insurer": "Moz Insurer",
    "editor_id": 95,
    "active": 1
  },
  {
    "guid": "orangeguid",
    "code": "orange",
    "name": "Orange",
    "country_code": "CI",
    "created_at": "2017-03-15 09:11:01.000000",
    "call_centre_number": "80080094",
    "whats_app_number": "57573838",
    "default_language": "fr",
    "ussd_shortcode": "#144*434#",
    "service_name": "Orange Insurance",
    "insurer": "Belife",
    "editor_id": 43,
    "active": 1
  },
  {
    "guid": "radiantyacuguid",
    "code": "radiant_yacu",
    "name": "Radiant Yacu",
    "country_code": "RW",
    "created_at": "2020-01-05 21:38:43.000000",
    "call_centre_number": "400",
    "whats_app_number": "0723407626",
    "default_language": "rw",
    "ussd_shortcode": "*300*9*4#",
    "service_name": "TURIKUMWE",
    "insurer": "Radiant",
    "editor_id": 43,
    "active": 1
  },
  {
    "guid": "safaricomkenya",
    "code": "safaricomkenya",
    "name": "Safaricom Kenya",
    "country_code": "KE",
    "created_at": "2022-07-12 11:16:15.566000",
    "call_centre_number": "00254705100100",
    "whats_app_number": "00254123456789",
    "default_language": "en",
    "ussd_shortcode": "*544#",
    "service_name": "Safaricom Cover",
    "insurer": "Britam",
    "editor_id": 197,
    "active": 1
  },
  {
    "guid": "shuatechguid",
    "code": "shuatech",
    "name": "Shua Tech",
    "country_code": "KE",
    "created_at": "2022-06-08 10:07:43.000000",
    "call_centre_number": "0",
    "whats_app_number": "0",
    "default_language": "en",
    "ussd_shortcode": "*000#",
    "service_name": "M-SHUA",
    "insurer": "Britam",
    "editor_id": 80,
    "active": 1
  },
  {
    "guid": "testpartner1234",
    "code": "testpartner1234",
    "name": "Test Partner",
    "country_code": "SA",
    "created_at": "2022-09-21 15:02:46.855000",
    "call_centre_number": "00254763073550",
    "whats_app_number": "00254763073550",
    "default_language": "en",
    "ussd_shortcode": "N/A",
    "service_name": "test service",
    "insurer": "Hollard",
    "editor_id": 91,
    "active": 1
  },
  {
    "guid": "tigorwandaguid",
    "code": "tigo_rwanda",
    "name": "Airtel Rwanda",
    "country_code": "RW",
    "created_at": "2017-03-15 09:00:00.000000",
    "call_centre_number": "100",
    "whats_app_number": "0727807326",
    "default_language": "rw",
    "ussd_shortcode": "*500*6*2#",
    "service_name": "Ingoboka Cash",
    "insurer": "Radiant",
    "editor_id": 43,
    "active": 1
  },
  {
    "guid": "tshipinotoguid",
    "code": "tshipinoto",
    "name": "Tshipi-Noto",
    "country_code": "SA",
    "created_at": "2022-11-29 04:07:43.000000",
    "call_centre_number": "0",
    "whats_app_number": "0",
    "default_language": "en",
    "ussd_shortcode": "*000#",
    "service_name": "Tshipi-Noto",
    "insurer": "Atehang Life",
    "editor_id": 80,
    "active": 1
  },
  {
    "guid": "ugandatelecomguid",
    "code": "uganda_telecom",
    "name": "Uganda Telecom",
    "country_code": "UG",
    "created_at": "2017-03-15 09:11:05.000000",
    "call_centre_number": "TO BE PROVIDED",
    "whats_app_number": "TO BE PROVIDED",
    "default_language": "en",
    "ussd_shortcode": "(USSD)",
    "service_name": "",
    "insurer": "",
    "editor_id": 43,
    "active": 1
  },
  {
    "guid": "bboxxkeguid",
    "code": "bboxxkeguid",
    "name": "Bboxx Kenya",
    "active": true,
  },
  {
    "guid": "bboxxrwguid",
    "code": "bboxxrwguid",
    "name": "Bboxx Rwanda",
    "active": true,
  },
  {
    "guid": "premcredzamguid",
    "code": "premcredzamguid",
    "name": "Premier Credit Zambia",
    "active": true,
  }
]