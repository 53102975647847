import * as types from '../../lib/actionTypes.es6';

export function getAllProductsRequest(payload) {
  return {
    type: types.VIEW_ALL_PRODUCTS_REQUEST,
    payload,
  };
}

export function getAllProductsFailure(error) {
  return {
    type: types.VIEW_ALL_PRODUCTS_FAILURE,
    payload: error,
  };
}

export function getAllProductsSuccess(response) {
  return {
    type: types.VIEW_ALL_PRODUCTS_SUCCESS,
    payload: response,
  };
}

export function createProductRequest(payload) {
  return {
    type: types.CREATE_PRODUCT_REQUEST,
    payload,
  };
}

export function createProductFailure(error) {
  return {
    type: types.CREATE_PRODUCT_FAILURE,
    payload: error,
  };
}

export function createProductSuccess(response) {
  return {
    type: types.CREATE_PRODUCT_SUCCESS,
    payload: response,
  };
}

export function getProductRequest(payload) {
  return {
    type: types.VIEW_PRODUCT_REQUEST,
    payload,
  };
}

export function getProductFailure(error) {
  return {
    type: types.VIEW_PRODUCT_FAILURE,
    payload: error,
  };
}

export function getProductSuccess(response) {
  return {
    type: types.VIEW_PRODUCT_SUCCESS,
    payload: response,
  };
}

export function getAllPartnersRequest() {
  return {
    type: types.VIEW_ALL_PARTNERS_REQUEST,
  };
}

export function getProductEnumsRequest(payload) {
  return {
    type: types.PRODUCT_ENUMS_REQUEST,
    payload,
  };
}

export function getProductEnumsFailure(error) {
  return {
    type: types.PRODUCT_ENUMS_FAILURE,
    payload: error,
  };
}

export function getProductEnumsSuccess(response) {
  return {
    type: types.PRODUCT_ENUMS_SUCCESS,
    payload: response,
  };
}

export function getProductPremiumEnumsRequest(payload) {
  return {
    type: types.PRODUCT_PREMIUM_ENUMS_REQUEST,
    payload,
  };
}

export function createProductPremiumRequest(payload) {
  return {
    type: types.CREATE_PRODUCT_PREMIUM_REQUEST,
    payload,
  };
}

export function getProductPremiumsRequest(payload) {
  return {
    type: types.VIEW_ALL_PRODUCT_PREMIUMS_REQUEST,
    payload,
  };
}
