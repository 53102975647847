import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  loader: false,
  message: null,
  data: null,
};

export default function loyaltyRenewalReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOYALTY_RENEWAL_REQUEST:
      return { ...state, loader: true, errors: null };

    case types.LOYALTY_RENEWAL_SUCCESS:
      return { ...state, data: action.payload, loader: false };

    case types.LOYALTY_RENEWAL_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    default:
      return state;
  }
}
