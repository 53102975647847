import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../containers/Users/actions.es6';
import { Container, CssBaseline } from '@material-ui/core'
import UsersTable from './UsersTable/UsersTable';
import ProductsHeader from '../NewProductList/components/ProductsHeader/ProductsHeader';
import SkeletonWrapper from '../NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import AddUser from './Component/AddUser';
import EditUser from './Component/EditUser';
import DeleteUser from './Component/DeleteUser';
import { PartnersData } from './Component/PartnersData';
import { RolesData } from './Component/RoleData';
import find from 'lodash/find';
import EditRoles from './Component/EditRoles';
import { getAllPartnersRequest } from '../Partners/actions.es6';

export class UsersNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddUserDialog: false,
      userToEditRoles: null,

    };
  }
  componentWillMount() {
    this.props.dispatch(getAllPartnersRequest());
    this.props.dispatch(actions.getUsersRequest());
    this.props.dispatch(actions.getRolesRequest());
  }

  closeAddUserDialog = () => {
    this.setState({ openAddUserDialog: false })
  }
  closeEditUserDialog = () => {
  }
  addUser = (user) => {
    this.props.dispatch(actions.createtUsersRequest(user));
    console.log(user)
  }
  refreshUsers = () => {
    this.props.dispatch(actions.getUsersRequest())
  }
  editUser = (user) => {
    this.props.dispatch(actions.updateRolesAndPartnersdRequest(user))
  }
  currentRoles = (user) => {

  }

  resetUserToEdit = (user) => {
    this.props.dispatch(actions.resetUserToEdit(user))
  }
  setUserToEdit = (user) => {
    this.props.dispatch(actions.setUserToEdit(user))
    this.props.dispatch(actions.getCurrentPartnersRequest(user.id));
  }
  setUserToDelete(user) {
    this.props.dispatch(actions.setUserToDelete(user));
  }
  deleteUser = (user) => {
    this.props.dispatch(actions.deleteUsersRequest(user.id));
  }
  resetUserToDelete = (user) => {
    this.props.dispatch(actions.resetUsersToDelete(user))

  }

  readableRoles(allRolesFromKeyCloak) {
    const readableRoles = allRolesFromKeyCloak.map(role => {
      const dummyRole = find(RolesData, { code: role.name })
      if(!dummyRole) return { ...role }
      return { ...role, name: dummyRole.name, code: role.name }
    })
    return readableRoles
  }

  readablePartners(allPartnersFromKeyCloak) {
    const readablePartners = allPartnersFromKeyCloak.map(partnerGuid => {
      const dummyPartner = find(PartnersData, { guid: partnerGuid })
      if(partnerGuid && dummyPartner){
        return { name: dummyPartner.name, guid: partnerGuid }
      }else{
        return null
      }
    })
    return readablePartners.filter(i=> i!==null)
  }

  cleanPartners() {
    return PartnersData.map(partner => {
      return { name: partner.name, guid: partner.guid }
    })
  }

  render() {
    let users = this.props.userData.users || [];
    const readableRoles = this.readableRoles(this.props.userData.roles)
    const reablePartners = this.cleanPartners()
    const currentReableRoles = this.readableRoles(this.props.userData.currentroles)
    const currentReablePartners = this.readablePartners(this.props.userData.currentPartners)
    
    return (
      <>
        <CssBaseline />
        <Container maxWidth="lg" style={{ marginTop: "3%" }}>
          <SkeletonWrapper loading={this.props.userData.loader} />

          {
            !this.props.userData.loader &&
            <>
              <ProductsHeader
                title={"Add Users"}
                showAddProductDialog={this.state.openAddUserDialog}
                showAddButton={true}
                onAddButtonClick={() => { this.setState({ openAddUserDialog: true }) }}
                showEditButton={false}
                onEditButtonClick={() => { }}
              />

              <UsersTable
                displayEditButton={true}
                displayDeleteButton={true}
                setUserToEdit={this.setUserToEdit.bind(this)}
                setUserToEditRoles={(user) => this.setState({ userToEditRoles: user })}
                setUserToDelete={this.setUserToDelete.bind(this)}
                users={users}
                loader={this.props.userData.loader}
              />
            </>

          }
          <AddUser
            openAddUserDialog={this.state.openAddUserDialog}
            closeAddUserDialog={this.closeAddUserDialog.bind(this)}
            addUser={this.addUser.bind(this)}
            showAddUserProgressAlert={this.props.userData.createUserLoader}
            showAddUserSuccessAlert={this.props.userData.createUserSuccess}
            resetAddUserSuccessAlert={() => {
              this.props.dispatch(actions.resetCreateUserSuccess())
              this.props.dispatch(actions.createtUsersRequest())
            }}
            refreshUsers={this.refreshUsers.bind(this)}
            allRoles={readableRoles}
            allPartners={PartnersData}
            partners={this.props.partnersData.partners}
            currentUserRoles={this.props.userData.currentroles}
            currentUserPartner={this.props.userData.currentpartners}
          />
          <EditUser
            user={this.props.userData.userToEdit}
            editUser={this.editUser.bind(this)}
            resetUserToEdit={this.resetUserToEdit.bind(this)}

            showEditUserProgressAlert={this.props.userData.loader}
            showEditUserSuccessAlert={this.props.userData.editUserSuccess}
            showEditUserErrorAlert={!!this.props.userData.updateRolesAndPartnersErrors}
            editUserError={this.props.userData.updateRolesAndPartnersErrors}
            resetEditUserSuccessAlert={() => {
              this.props.dispatch(actions.resetEditUserSuccess())
              this.props.dispatch(actions.getUsersRequest())
            }}
            resetEditUserErrorAlert={() => { this.props.dispatch(actions.resetEditUserError()) }}

            currentUserReableRoles={currentReableRoles.map(r => r.name)}
            currentUserPartners={this.props.userData.currentPartners}

            allRoless={readableRoles.map(r => r.name)}
            allPartners={this.props.partnersData.partners.map(p => p.guid)}

            allData={{ readableRoles, reablePartners }}

          />
          <EditRoles
            user={this.state.userToEditRoles}
            resetUserToEditRoles={() => this.setState({ userToEditRoles: null })}

            currentUserReableRoles={currentReableRoles.map(r => r.name)}
            currentUserPartners={currentReablePartners.map(p => p.name)}

            allRoless={readableRoles.map(r => r.name)}
            allPartners={reablePartners.map(p => p.name)}

            allData={{ readableRoles, reablePartners }}

          />
          
          <DeleteUser
            user={this.props.userData.userToDelete}
            deleteUser={this.deleteUser.bind(this)}
            resetUserToDelete={this.resetUserToDelete.bind(this)}
            showDeleteUserProgressAlert={this.props.userData.hardDeleteUserLoader}
            showDeleteUserSuccessAlert={this.props.userData.hardDeleteUserSuccess}
            showDeleteUserErrorAlert={!!this.props.userData.deleteUserErrors}
            deleteUserError={this.props.userData.deleteUserErrors}

            resetDeleteUserSuccessAlert={() => {
              this.props.dispatch(actions.resetHardDeleteUserSuccess())
              this.props.dispatch(actions.getUsersRequest())
            }}
            resetDeleteUserErrorAlert={() => { this.props.dispatch(actions.resetHardDeleteUserError()) }}
          />
        </Container>
      </>
    )
  }
}
export default connect(state => ({
  userData: state.userAdmin,
  globalData: state.global,
  partnersData: state.partners,
}))(UsersNew);