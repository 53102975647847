import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { hashHistory } from 'react-router';
import find from 'lodash/find';


import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { formatDateLocale } from '../../../../../lib/utils.es6';
import { getPartner, hasAuthourity } from '../../../../../lib/access.es6';
import ButtonInc from '../../../../../shared-ui/ButtonInc';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    width: "100%",
    maxWidth: "1000px"
  }
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#fff',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ViewLOU = (props) => {
  const classes = useStyle();

  const currencyCode = getPartner().country.currency_code
  const lou = props.lou




  if (lou) {
    const hospitalBenefit = lou.benefits ? find(lou.benefits, { "benefit_type": "DAILY HOSPITAL BENEFIT" }) : null
    const surgeryBenefit = lou.benefits ? find(lou.benefits, { "benefit_type": "ADDITIONAL SURGERY BENEFIT" }) : null

    const hospitalAmount = hospitalBenefit ? hospitalBenefit.authorized_amount_in_cents / 100 : 0
    const surgeryAmount = surgeryBenefit ? surgeryBenefit.authorized_amount_in_cents / 100 : 0

    const totalBenefit = hospitalAmount + surgeryAmount

    return (
      <>
        <BootstrapDialog
          onClose={props.close}
          aria-labelledby="dependants-dialog-title"
          open={props.open}
        >
          <BootstrapDialogTitle className={classes.dependantstitle} id="dependants-dialog-title" onClose={props.close}>
            Letter of Undertaking Details
          </BootstrapDialogTitle>
          <BootstrapDialogTitle id="dependants-dialog-title" onClose={props.close}>
          </BootstrapDialogTitle>
          <DialogContent >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3} className={classes.loudetailbox}>
                <h4>LOU  Number</h4>
                <p>{lou.preauth_number || "N/A"}</p>
              </Grid>
              <Grid item xs={12} sm={3} md={3} className={classes.loudetailbox}>
                <h4>Created At</h4>
                <p>{formatDateLocale(lou.created_at) || "N/A"}</p>
              </Grid>
              <Grid item xs={12} sm={3} md={3} className={classes.loudetailbox}>
                <h4>Created By</h4>
                <p>{lou.web_agent_name || "N/A"}</p>
              </Grid>
              <Grid item xs={12} sm={3} md={3} className={classes.loudetailbox}>
                <h4>Updated By</h4>
                <p>{lou.web_agent_name || "N/A"}</p>
              </Grid>
              <div className={classes.border}></div>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3} className={classes.loudetailbox}>
                <h4>Facility</h4>
                <p>{lou.hospital_details.hospital || "N/A"}</p>
              </Grid>
              <Grid item xs={12} sm={3} md={3} className={classes.loudetailbox}>
                <h4>Name of Patient</h4>
                <p>{lou.hospital_details.dependant_fullname || "N/A"}</p>
              </Grid>
              <Grid item xs={12} sm={3} md={3} className={classes.loudetailbox}>
                <h4>Membership Number</h4>
                <p>{lou.policy_number || "N/A"}</p>
              </Grid>
              <Grid item xs={12} sm={3} md={3} className={classes.loudetailbox}>
                <h4>Admission Date</h4>
                <p>{formatDateLocale(lou.hospital_details.hospital_admission_date) || "N/A"}</p>
              </Grid>
              <div className={classes.border}></div>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3} className={classes.loudetailbox}>
                <h4>Discharge Date</h4>
                <p>{formatDateLocale(lou.hospital_details.hospital_discharch_date) || "N/A"}</p>
              </Grid>
              <Grid item xs={12} sm={3} md={3} className={classes.loudetailbox}>
                <h4>Total Days</h4>
                <p>
                  {
                    daysInHospital(lou.hospital_details.hospital_discharch_date, lou.hospital_details.hospital_admission_date)
                  }
                </p>
              </Grid>
              <Grid item xs={12} sm={3} md={3} className={classes.loudetailbox}>
                <h4>Diagnosis</h4>
                <p>{lou.hospital_details.diagnosis_name}</p>
              </Grid>
              <Grid item xs={12} sm={3} md={3} className={classes.loudetailbox}>
                <h4>Scheme Name</h4>
                <p>Kinga ya MKulima</p>
              </Grid>
              <div className={classes.border}></div>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3} className={classes.loudetailbox}>
                <h4>Total Hospital Benefit</h4>
                <p>{`${currencyCode + " " + hospitalAmount}`}</p>
              </Grid>
              <Grid item xs={12} sm={3} md={3} className={classes.loudetailbox}>
                <h4>Surgery Benefit</h4>
                <p>{`${currencyCode + " " + surgeryAmount}`}</p>
              </Grid>
              <Grid item xs={12} sm={3} md={3} className={classes.loudetailbox}>
                <h4>Total Benefit</h4>
                <p>{currencyCode + " " + totalBenefit}</p>
              </Grid>
              <Grid item xs={12} sm={3} md={3} className={classes.loudetailbox}>
                <h4>Attached Claim Number</h4>
                <p>{lou.claim_number || "N/A"}</p>
              </Grid>
              <div className={classes.border}></div>
            </Grid>


            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3} className={classes.loudetailbox}>
                <h4>Previous Lou Number</h4>
                <p>N/A</p>
              </Grid>
              <Grid item xs={12} sm={3} md={3} className={classes.loudetailbox}>
                <h4>System Message</h4>
                {<p>{lou.system_message ? lou.system_message : 'N/A'}</p>}
              </Grid>

              <Grid item xs={12} sm={3} className={classes.loudetailbox}>
              <h4>Amended</h4>
                {<p>{lou.amended === true ? "True" : "False"}</p>}
              </Grid>

              <Grid item xs={12} sm={3} md={3} className={classes.loudetailbox}>
                <h4>Notes</h4>
                {<p>{lou.notes || 'N/A'}</p>}

              </Grid>

       
              <div className={classes.border}></div>
            </Grid>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3} className={classes.loudetailbox}>
                <h4>Claim</h4>
                {
                  lou.claim_guid ?
                    <ButtonInc
                      onClick={() => hashHistory.push(`admin/claim-processing-v2/${lou.claim_guid}`)}
                      variant="contained"
										  hasPermision={hasAuthourity('VIEW_CLAIM')}
                    >View Claim</ButtonInc>
                    : 'N/A'
                }
              </Grid>
              <div className={classes.border}></div>
            </Grid>


          </DialogContent>
        </BootstrapDialog>

      </>
    )
  }
  return ""


}
function daysInHospital(dischargeDate, admissionDate) {
  if (dischargeDate && admissionDate) {
    return moment(dischargeDate).diff(moment(admissionDate), 'days');
  }
  return 0
}

export default ViewLOU