import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  claimwrapper: {
    marginTop: "40px",
    marginBottom: "30px",
  },
  claimdetailswrapper: {
    margin: "30px",

  },
  ClaimDetailscontentlast:{
    "& h4": {
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
      marginLeft: "20px",
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#060606",
      marginLeft: "20px",
    }
  },
  ClaimDetailscontent: {
    borderRight: " 1px solid #A7A7A7",
    "&:last-child":{
      borderRight: "0"
    },
    "& h4": {
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
      marginLeft: "20px",
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#060606",
      marginLeft: "20px",
    }
  },
  borderbottom: {
    borderBottom: " 1px solid #A7A7A7",
    width: "90%",
    marginTop: "20px",
    marginBottom: "30px",
  },
  notesheader:{
    margin: "20px 0 20px 30px ",
    "& h4":{
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "15px",
      color: "#000000",
    },
    "& ul":{
      listStyle: "none",
      display: "flex",
      padding: "0",
      "& li":{
        display: "inline-block",
        "& svg":{
          marginTop: "6px",
          marginLeft: "6px",
        }
      }
    }
  },
  noteswrapper:{
    margin: "20px 0 20px 30px ",
  },
  notesbox:{
    background: "#FFFFFF",
    border: "1px solid #B8B8B8",
    width: "80%",
    marginBottom: "30px",
    padding: "15px",
    "& h4":{
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#060606;"
    },
  },
  policyWrapper: {
    background: "#FFFFFF",
    border: "1px solid #D9D9D9",
    boxShadow: "0px 8px 24px rgba(149, 157, 165, 0.2)",
    borderRadius: "4px",
    marginTop: "30px !important"
  },
  policyHeaderBorder: {
    borderBottom: "1px solid #D9D9D9",
    width: "100%"
  },
  policyIconWrapper: {
    padding: "10px",
  },
  policyIcons: {
    width: "30px",
    height: "30px",
    background: "#032a37",
    borderRadius: "100%",
    textAlign: "center",
    paddingTop: "5px",
    marginLeft: "auto"
  },
  policyHeaderContent: {
    paddingTop: "10px",
    "& h6": {
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "0.001em",
      color: "#000000",
      margin: "0 !important"
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#A9A9A9"
    }
  },
  activePill: {
    width: "63px",
    height: "21px",
    background: "#CCFFDD",
    borderRadius: "10px",
  },
  inactivePill:{
    width: "63px",
    height: "21px",
    background: "#DAE3E7",
    borderRadius: "10px",
    "& span":{
      color: "#8D8D8C !important"
    }
  },
  policyActiveStatusWrapper: {
    padding: "14px 30px",
    "& ul": {
      listStyle: "none",
      display: "contents",
      marginLeft: "20px",
      marginTop: "10px",
      "& li": {
        textAlign: "center",
        "& span": {
          display: "block",
          marginTop: 0,
          fontFamily: 'Open Sans',
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "10px",
          lineHeight: "20px",
          letterSpacing: "0.001em",
          color: "#2D8D4D",
        },
        "&:nth-child(2)": {
          display: "inline-flex",
          marginLeft: "15px",
          fontFamily: 'Open Sans',
          fontStyle: "normal",
          fontWeight: 100,
          fontSize: "13px",
          lineHeight: "20px",
          letterSpacing: "0.001em",
          color: "#000",
          paddingTop: "2px",
          cursor: "pointer",
          "& span":{
            marginTop: "-1px",
            color: "#000"
          }
        }
      }
    },
    "& h6": {
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      letterSpacing: "0.001em",
      color: "#000000",
      margin: "0 !important"
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#A9A9A9"
    }
  },
  actionbtnpolicy:{
    marginTop: "-10px"
  },
  policyContentBorder:{
    borderBottom: "1px solid #D9D9D9",
    width: "100%",
    marginTop: "20px"
  },
  policyContentWrapper:{
    paddingTop: "20px",
    "& h6": {
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "0.001em",
      color: "#000000",
      margin: "0 !important"
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#A9A9A9"
    }
  },
  policyFooterWrapper:{
    paddingTop: "20px",
    paddingBottom: "20px",
    "& h6": {
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "0.001em",
      color: "#000000",
      margin: "0 !important"
    },
    "& ol":{
      "& li":{
        fontFamily: 'Lato',
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "20px",
        letterSpacing: "0.001em",
        color: "#000000",
        margin: "0 !important"
      }
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#A9A9A9"
    }
  },

  policyBalance:{
    textAlign: "end",
    marginRight: "40px",
    marginTop: "15px",
    "& h6":{
      color: "#2D8D4D",
    }
  },


}));