import * as types from '../../lib/actionTypes.es6';

export function reportPremiumDueRequest(reportType) {
  return {
    type: types.REPORT_PREMIUM_DUE_REQUEST,
    payload: reportType,
  };
}

export function reportPremiumDueRequestSuccess(reportURL) {
  return {
    type: types.REPORT_PREMIUM_DUE_SUCCESS,
    payload: reportURL,
  };
}

export function reportPremiumDueRequestError(error) {
  return {
    type: types.REPORT_PREMIUM_DUE_FAILURE,
    payload: error,
  };
}

export function reportPremiumDueReset() {
  return {
    type: types.REPORT_PREMIUM_DUE_RESET,
  };
}
