import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import PremiumsTable from "../PremiumsTable/PremiumsTable";
import AlertDialog from "../../../../components/AlertDialog/index.es6";
import { CircularProgress, InputAdornment } from "@mui/material";
import { PAYMENT_METHODS } from "../../PoductConstants";
const initialState = {
  granularity: '',
  payment_method: '',
  cardinality: '',
  amount: '',
}
export default function AddPremium(props) {
  const [
    {
      granularity,
      payment_method,
      cardinality,
      amount,
    }, setState
  ] = React.useState(initialState)

  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };


  const reset = () => {
    setState(initialState);
  }


  const submit = () => {
    const payload = {
      granularity,
      payment_method,
      cardinality: Number(cardinality),
      amount_in_cents: Number(amount) * 100,
      product_guid: props.createdProduct.guid,
      active: true,
    }
    props.addPremium(payload)
  }


  const handlePremiumCreatedSuccessfully = () => {
    props.resetAddPremiumSuccessAlert()
    props.goToNextStep()
    reset()
  }

  const addAnotherPremium = () => {
    props.resetAddPremiumSuccessAlert()
    reset()
  }

  const handlePremiumCreateError = () => {
    props.resetAddPremiumErrorAlert()
  }

  const handlePremiumDeletedSuccessfully = () => {
    props.resetHardDeletePremiumSuccessAlert()
  }

  const handlePremiumDeleteError = () => {
    props.resetHardDeletePremiumErrorAlert()
  }

  return (
    <React.Fragment>
      <ValidatorForm onSubmit={() => submit()} >
        <AlertDialog
          custom
          show={props.showAddPremiumProgressAlert}
          size="sm"
          style={{ marginTop: '0', top: '30vh' }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={'cancel'}
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2>Adding premium</h2>
        </AlertDialog>

        <AlertDialog
          success
          show={props.showAddPremiumSuccessAlert}
          size="sm"
          title={'Premium added'}
          style={{ marginTop: '0', top: '30vh' }}
          onConfirm={() => { handlePremiumCreatedSuccessfully() }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={'cancel'}
          confirmBtnCssClass
        >
          <div >
            <Button variant="outlined" onClick={() => { addAnotherPremium() }} style={{ marginRight: '3%', borderColor: 'green', color: 'green', fontWeight: 'bold' }}>Add another premium</Button>
            <Button variant="contained" onClick={() => { handlePremiumCreatedSuccessfully() }} style={{ background: 'green', }}>Next: Add benefits</Button>
          </div>
        </AlertDialog>

        <AlertDialog
          show={props.showAddPremiumErrorAlert}
          danger
          title={'Error adding premium'}
          onConfirm={() => handlePremiumCreateError()}
          confirmBtnText={'Try again'}
          confirmBtnCssClass
          showCancel={false}
          style={{ marginTop: '0', top: '30vh' }}
        >
          {props.createPremiumError ? props.createPremiumError.message : ''}
        </AlertDialog>




        {/* hard delete premium alers */}
        <AlertDialog
          custom
          show={props.showHardDeletePremiumProgressAlert}
          size="sm"
          style={{ marginTop: '0', top: '30vh' }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={'cancel'}
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2>deleting premium</h2>
        </AlertDialog>

        <AlertDialog
          success
          show={props.showHardDeletePremiumSuccessAlert}
          size="sm"
          title={' premium deleted'}
          style={{ marginTop: '0', top: '30vh' }}
          onConfirm={() => { handlePremiumDeletedSuccessfully() }}
          confirmBtnText={'ok'}
          showCancel={false}
          cancelBtnText={'cancel'}
          confirmBtnCssClass
        >
        </AlertDialog>

        <AlertDialog
          show={props.showHardDeletePremiumErrorAlert}
          danger
          title={'Error deleting premium'}
          onConfirm={() => handlePremiumDeleteError()}
          confirmBtnText={'Try again'}
          confirmBtnCssClass
          showCancel={false}
          style={{ marginTop: '0', top: '30vh' }}
        >
          {props.hardDeletePremiumError ? props.hardDeletePremiumError.message : ''}
        </AlertDialog>


        <Grid container spacing={2}>
          <Grid item lg={3} md={6} xs={12}>
          <TextValidator
              onChange={onChange}
              value={amount}
              label='amount'
              name='amount'
              fullWidth
              variant="outlined"
              type="number"
              placeholder='amount'
              validators={['required']}
              errorMessages={['Please enter amount']}
              InputProps={{
                startAdornment: (<InputAdornment position="start">
                {props.currencyCode}
                </InputAdornment>),

                'aria-label': 'Ksh',
              }}
            />
          </Grid>


          <Grid item lg={3} md={6} xs={12}>
            <SelectValidator
              value={payment_method}
              label='payment method'
              id="payment method"
              name='payment_method'
              onChange={onChange}
              className="form-control"
              type="text"
              variant="outlined"
              validators={['required']}
              errorMessages={['Please select payment method']}
            >
              <MenuItem value="">{'Please select payment method'}</MenuItem>
              {
                PAYMENT_METHODS.map((type) => {
                  return <MenuItem value={type}>{type}</MenuItem>
                })
              }
            </SelectValidator>
          </Grid>

          <Grid item lg={3} md={6} xs={12}>
            <SelectValidator
              value={granularity}
              label='granularity'
              id="granularity"
              name='granularity'
              onChange={onChange}
              className="form-control"
              type="text"
              variant="outlined"
              validators={['required']}
              errorMessages={['Please select granularity']}
            >
              <MenuItem value="">{'Please select granularity'}</MenuItem>
              {
                ['Day', 'Week', 'Month', 'Year'].map((type) => {
                  return <MenuItem value={type}>{type}</MenuItem>
                })
              }
            </SelectValidator>
          </Grid>



          <Grid item lg={3} md={6} xs={12}>

            <TextValidator
              onChange={onChange}
              value={cardinality}
              label='cardinality'
              name='cardinality'
              fullWidth
              variant="outlined"
              type="number"
              placeholder='amount'
              validators={['required']}
              errorMessages={['Please enter cardinality']}
            />
          </Grid>

          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button variant="contained" type="submit" style={{ marginRight: '2%' }}> Save </Button>
            <Button variant="contained" color="secondary" style={{ marginRight: '2%' }} onClick={() => reset()}> Reset</Button>
          </Grid>

        </Grid>
      </ValidatorForm>
      {
        (props.createdPremiums && props.createdPremiums.length > 0) &&
        <PremiumsTable
          premiums={props.createdPremiums}
          hardDeletePremium={props.hardDeletePremium}
          displayEditButton={false}
          displayDeleteButton={true}
        />
      }

    </React.Fragment>

  );
}
