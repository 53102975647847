import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  policynotetitle: {
    "& h3": {
      fontFamily: "open sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "15px",
      color: "#000000",
    }
  },
  noteswrapper: {
    "& p": {
      fontFamily: 'Open Sans',
      marginBottom: "15px",
      marginTop: "10px",
      fontStyle: "normal",
      fontSize: "12px",
      lineHeight: "25px",
      color: "#000000",
      textAlign: "inherit"
    },
    "& span": {
      fontFamily: 'Open Sans',
      marginBottom: "10px",
      fontStyle: "normal",
      fontSize: "12px",
      lineHeight: "10px",
      color: "#000000",
      display: "block",
    }
  },
  border: {
    borderBottom: "1px solid #ccc",
    marginTop: "10px",
    marginBottom: "25px",
  },
  showmoreshowless: {
    marginTop: "20px",
    "& ul": {
      display: "flex",
      padding: "0",
      cursor: "pointer",
      "& li": {
        listStyle: "none",
        fontFamily: "open sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "15px",
        color: "#000000",
        "&:nth-child(2)": {
          marginTop: "-3px"
        }
      }
    }
  },



}));