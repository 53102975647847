import * as types from '../../../lib/actionTypes.es6'

export function getElasticSearchRequest(payload){
  return{
    type: types.GET_ELASTIC_SEARCH_REQUEST,
    payload: payload
  }
}


export const getElasticSearchSuccess = (payload) =>{
  return{
    type: types.GET_ELASTIC_SEARCH_SUCCESS,
    payload: payload,
  }
}

export const getElasticSearchFailure = (error) =>{
  return{
    type: types.GET_ELASTIC_SEARCH_FAILURE,
    payload: error,
  }
}