import React from 'react';
import { hashHistory } from 'react-router';
import Grid from "@material-ui/core/Grid";
import TableInc from '../../../../../shared-ui/TableInc';
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import { formatDateLocale } from '../../../../../lib/utils.es6';

export function DisplayClaims(currencyCode, classes, claims) {
  console.log("claims", claims)

  const claimsFormatted = claims.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map(claim => {
    return {
      ...claim,
      grower_number: claim.claim_details.policy_number
    }
  })

  return <Grid container spacing={2}>
    <Grid item xs={12} md={12} sm={12}>
      <TableInc
        columns={[
     
          {
            title: 'Claim number', field: 'claim_number', render: rowData => <>
              {rowData.claim_number || "N/A"}
            </>
          },
          {
            title: 'Policy  Number', field: 'grower_number'
          },
    
          {
            title: 'Payable Amount', field: 'benefit_usages', render: rowData => <>
              {`${currencyCode} ${(rowData.benefit_usages.reduce((psum, benefit) => psum + benefit.benefit_amount_in_cents, 0) / 100).toLocaleString()}` || "N/A"}
            </>
          },
          {
            title: 'Created At', field: 'created_at', render: rowData => <>
              {formatDateLocale(rowData.created_at) || "N/A"}
            </>
          },
          { title: 'Created By', field: 'web_agent_name' },

          { 
            title: 'action', field: 'claimaction', render: rowData => {
              return <div className={classes.claimactionbt}>
                <ButtonInc
                  onClick={() => hashHistory.push(`admin/claim-processing-v2/${rowData.guid}`)}
                  variant="contained">View Claim</ButtonInc>
              </div>;
            }
          },
        ]}
        data={claimsFormatted}
        options={{
          pageSize: 5,
          showTitle: false,
        }} />

    </Grid>
  </Grid>;
}
