import React, { useState } from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DisplayPolicies from '../Policies/DisplayPolicies';
import { policiesStatusEnum } from '../../../lib/enum';
import { getPartnerGuid, getUserBranch } from '../../../../../lib/access.es6';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PoliciesWrapper = (props) => {
  const classes = useStyle();
  const [value, setValue] = useState(0);


  const getPoliciesStatus = (tabValue) => {
    switch (tabValue) {
      case 0:
        return policiesStatusEnum.Un_Confirmed
      case 1:
        return policiesStatusEnum.Rejected
      default:
    }
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
    const payload = {
      "status": getPoliciesStatus(newValue),
    }

    const partnerGuid = getPartnerGuid();
    if(partnerGuid === "accessbank"){
      payload['branch'] = getUserBranch();
    }
    props.getPolicies(payload)
  };


  return (
    <>
      <section className={classes.claimswrapper}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="policies dashboards" variant="scrollable"
                scrollButtons="auto" className={classes.tabs}>
                <Tab label="Awaiting approval" {...a11yProps(0)} />
                <Tab label="Incomplete Applications" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0} >
              <DisplayPolicies policies={props.policies} loader={props.loader}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
            <DisplayPolicies policies={props.policies} loader={props.loader}/>
            </TabPanel>
         

          </Grid>
        </Grid>
      </section>
    </>
  )
}

export default PoliciesWrapper