import React from 'react'
import { Grid } from '@mui/material';
import { useStyle } from "./Style";
import SkeletonWrapper from '../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import { formatDateLocale } from '../../../../../lib/utils.es6';
import { getPartnerGuid } from '../../../../../lib/access.es6';

const UserProfile = (props) => {

  const partnerGuid = getPartnerGuid();


  if (props.loading) {
    return <SkeletonWrapper loading={props.loading} />
  }


  const classes = useStyle();


  return (
    <section style={{marginTop: "40px"}}>
      <Grid container spacing={2} className={classes.customerdatawrapper}>
        <Grid item xs={12} md={2} sm={2}>
          <h2>{props.labels.firstName} </h2>
          <p>{props.customerData.first_name || "N/A" }  </p>
        </Grid>
        <Grid item xs={12} md={2} sm={2}>
          <h2>{props.labels.fullName}   </h2>
          <p>{props.customerData.full_name || "N/A" }  </p>
        </Grid>
        <Grid item xs={12} md={2} sm={2}>
          <h2>{props.labels.surname} </h2>
          <p>{props.customerData.surname || "N/A" }  </p>
        </Grid>
        <Grid item xs={12} md={2} sm={2}>
          <h2>{props.labels.dateOfBirth}  </h2>
          <p>{formatDateLocale(props.customerData.date_of_birth) || "N/A" }  </p>
        </Grid>
        <Grid item xs={12} md={2} sm={2}>
          <h2>{props.labels.gender} </h2>
          <p>{props.customerData.gender || "N/A" }  </p>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.customerdatawrapper}>
        <Grid item xs={12} md={2} sm={2}>
          <h2>{props.labels.nationalId} </h2>
          <p>{props.customerData.national_id || "N/A" }  </p>
        </Grid>
        <Grid item xs={12} md={2} sm={2}>
          <h2>{ partnerGuid === "premcredzamguid" ? "NRC Number" : "Customer number" } </h2>
          <p>{props.customerData.msisdn || "N/A" }  </p>
        </Grid>
        <Grid item xs={12} md={2} sm={2}>
          <h2>{"Phone number"} </h2>
          <p>{props.customerData.contact_number || "N/A" }  </p>
        </Grid>
        <Grid item xs={12} md={2} sm={2}>
          <h2>{props.labels.email} </h2>
          <p>{props.customerData.email || "N/A" }  </p>
        </Grid>
        <Grid item xs={12} md={2} sm={2}>
          <h2>{props.labels.address} </h2>
          <p>{props.customerData.address || "N/A" }  </p>
        </Grid>
   
      </Grid>
      <Grid container spacing={2} className={classes.customerdatawrapper}>
    
        <Grid item xs={12} md={2} sm={2}>
          <h2>{props.labels.BeneficiaryNumber} </h2>
          <p>{props.customerData.beneficiary_msisdn || "N/A" }  </p>
        </Grid>
        <Grid item xs={12} md={2} sm={2}>
          <h2>{props.labels.BeneficiaryName} </h2>
          <p>{props.customerData.beneficiary_name || "N/A" }  </p>
        </Grid>
        <Grid item xs={12} md={2} sm={2}>
          <h2>{props.labels.createdAt} </h2>
          <p>{formatDateLocale(props.customerData.created_at) || "N/A" }  </p>
        </Grid>
        <Grid item xs={12} md={2} sm={2}>
          <h2>{props.labels.registrationChannel}</h2>
          <p>{props.customerData.registration_channel || "N/A" }  </p>
        </Grid>
        <Grid item xs={12} md={2} sm={2}>
          <h2> {props.labels.lang}  </h2>
          <p>{props.customerData.language || "N/A" }  </p>
        </Grid>
      </Grid>
    </section>
  )
}

export default UserProfile