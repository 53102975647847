import * as types from '../../lib/actionTypes.es6';

export function reportPaidClaimRequest(reportType) {
  return {
    type: types.REPORT_PAID_CLAIM_REQUEST,
    payload: reportType,
  };
}

export function reportPaidClaimSuccess(reportURL) {
  return {
    type: types.REPORT_PAID_CLAIM_SUCCESS,
    payload: reportURL,
  };
}

export function reportPaidClaimFailure(error) {
  return {
    type: types.REPORT_PAID_CLAIM_FAILURE,
    payload: error,
  };
}

export function reportPaidClaimReset() {
  return {
    type: types.REPORT_PAID_CLAIM_RESET,
  };
}
