import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import {  DatePicker } from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';
import LocalizedStrings from 'react-localization';
import { buildFailureMessage, formatDate } from '../../lib/utils.es6';
import MainPageTitle from '../../components/MainPageTitle/index.es6';
import * as actions from './actions.es6';
import NoRecord from '../../components/NoRecord/index.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { localisedText } from '../../lib/localisation.es6';
import * as globalActions from '../Main/actions.es6';
import {
  partnerPolicyFieldsContains,
  partnerCanDebit,
  partnerCanEditDebit,
} from '../../lib/access.es6';

export class BuyPolicy extends React.Component {
  constructor(props) {
    super(props);
    const localizedStrings = new LocalizedStrings(localisedText);
    this.state = {
      selectedProduct: localizedStrings.selectProduct,
      selectedProductCoverType: null,
      selectedPremium: localizedStrings.selectPremium,
      labels: localizedStrings,
      spouseBirthday: null,
      validationErrors: [],
      validationErrorFields: [],
      debit: false,
    };
  }

  componentWillMount() {
    // NOTE: We are assuming that the products don't change often
    if (this.props.policyPurchaseData.products.length < 1) {
      this.props.dispatch(actions.listPaidProductsRequest());
    }
    this.updateLanguage();
    this.props.dispatch(globalActions.showCustomerBar());
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'BuyPolicy',
      this.state.labels
    );
  }

  selectProduct(e) {
    const labels = this.state.labels;
    const validationErrorFields = this.removeItemFromArray(
      this.state.validationErrorFields,
      'products'
    );
    const validationErrors = this.removeItemFromArray(
      this.state.validationErrors,
      labels.selectProductError
    );
    this.setState({
      selectedProduct: document.getElementById('products').value,
      selectedProductCoverType: e.target[e.target.selectedIndex].getAttribute(
        'data-cover-type'
      ),
      selectedPremium: labels.selectPremium,
      validationErrorFields,
      validationErrors,
    });
  }

  removeItemFromArray(array, item) {
    return array.filter(elt => elt !== item);
  }

  dateHandler(type, moment) {
    if (type === 'spouseBirthday') {
      this.setState({ spouseBirthday: formatDate(new Date(moment)) });
    }
  }

  spouseNameValid() {
    const form = new FormData(document.getElementById('policyPurchaseForm'));
    if (this.spouseNameVisible()) {
      const spouseName = form.get('spouse_name');
      if (spouseName) {
        return spouseName.length !== 0;
      }
    }
    return true;
  }

  updateSpouseNameInfo(payload) {
    const form = new FormData(document.getElementById('policyPurchaseForm'));
    if (this.spouseNameVisible()) {
      const spouseName = form.get('spouse_name');
      if (spouseName) {
        payload.spouse_name = spouseName;
      }
    }
  }

  spouseNameDisplay() {
    const spouseNameStyle = {
      display: this.spouseNameVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    return (
      <div className="form-group" style={spouseNameStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.spouseName}
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <input
            className="form-control col-md-7 col-xs-12"
            name="spouse_name"
            type="text"
          />
        </div>
      </div>
    );
  }

  spouseNameVisible() {
    return partnerPolicyFieldsContains('spouse_name');
  }

  spouseDobValid() {
    if (this.spouseDobVisible()) {
      const spouseDob = this.state.spouseBirthday;
      if (spouseDob) {
        return spouseDob !== '';
      }
    }
    return true;
  }

  updateSpouseDobInfo(payload) {
    if (this.spouseDobVisible()) {
      const spouseDob = this.state.spouseBirthday;
      if (spouseDob) {
        payload.spouse_dob = spouseDob;
      }
    }
  }

  spouseDobDisplay() {
    const spouseDobStyle = {
      display: this.spouseDobVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    return (
      <div className="form-group" style={spouseDobStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.spouseDateOfBirth}
          <span />
        </label>
        <div className="col-md-6 col-sm-3 col-xs-12">
          <DatePicker
            value={this.state.spouseBirthday}
            onChange={this.dateHandler.bind(this, 'spouseBirthday')}
            format="YYYY-MM-DD"
            placeholder={labels.defaultSelection}
          />
        </div>
      </div>
    );
  }

  spouseDobVisible() {
    return partnerPolicyFieldsContains('spouse_dob');
  }

  spouseIdValid() {
    const form = new FormData(document.getElementById('policyPurchaseForm'));
    if (this.spouseIdVisible()) {
      const spouseId = form.get('spouse_id');
      if (spouseId) {
        return spouseId.length !== 0;
      }
    }
    return true;
  }

  updateSpouseIdInfo(payload) {
    const form = new FormData(document.getElementById('policyPurchaseForm'));
    if (this.spouseIdVisible()) {
      const spouseId = form.get('spouse_id');
      if (spouseId) {
        payload.spouse_id = spouseId;
      }
    }
  }

  spouseIdDisplay() {
    const spouseIdStyle = {
      display: this.spouseIdVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    return (
      <div className="form-group" style={spouseIdStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.spouseId}
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <input
            className="form-control col-md-7 col-xs-12"
            name="spouse_id"
            type="text"
          />
        </div>
      </div>
    );
  }

  spouseIdVisible() {
    return partnerPolicyFieldsContains('spouse_id');
  }

  displaySpouseInfo() {
    let result;
    if (this.state.selectedProductCoverType === 'Family') {
      result = (
        <div>
          {this.spouseIdDisplay()}
          {this.spouseNameDisplay()}
          {this.spouseDobDisplay()}
        </div>
      );
    }
    return result;
  }

  selectPremium() {
    const labels = this.state.labels;
    const validationErrorFields = this.removeItemFromArray(
      this.state.validationErrorFields,
      'premiums'
    );
    const validationErrors = this.removeItemFromArray(
      this.state.validationErrors,
      labels.selectPremiumError
    );
    this.setState({
      selectedProduct: document.getElementById('products').value,
      selectedPremium: document.getElementById('premiums').value,
      validationErrorFields,
      validationErrors,
    });
  }

  premiumsOptions() {
    const labels = this.state.labels;
    if (!this.state.selectedProduct) {
      return (
        <div className="col-md-4 col-sm-4 col-xs-4">
          <input
            type="text"
            className="form-control"
            name="noPremium"
            readOnly
            value={labels.selectProductTitle}
          />
        </div>
      );
    }
    const premiums = this.getPaidProductPremiums(this.state.selectedProduct);
    return (
      <div className="col-md-6 col-sm-6 col-xs-12">
        <div className="col-md-4 col-sm-4 col-xs-4">
          <select
            id="premiums"
            required
            onChange={this.selectPremium.bind(this)}
            defaultValue={this.state.selectedPremium}
          >
            <option key="-1" value={labels.selectPremium}>
              {labels.selectPremium}
            </option>
            {premiums.map((premium, index) =>
              premium.payment_method === 'Mobile_Money' ? (
                <option key={index} value={premium.amount_in_cents}>
                  {this.premiumLabel(premium)}
                </option>
              ) : null
            )}
          </select>
        </div>
      </div>
    );
  }

  clearForm() {
    document.getElementById('policyPurchaseForm').reset();
    const labels = this.state.labels;
    this.setState({
      selectedProduct: labels.selectProduct,
      selectedPremium: labels.selectPremium,
      spouseBirthday: '',
      validationErrorFields: [],
      validationErrors: [],
    });
  }

  premiumLabel(premium) {
    // TODO: Pluralise this
    return `${premium.cardinality} ${
      premium.granularity
    }(${premium.amount_in_cents / 100})`;
  }

  productLabel(product) {
    return `${product.name} (${product.cover_type})`;
  }

  getPaidProductPremiums(productCode) {
    const allProducts = this.props.policyPurchaseData.products;
    const matchingProducts = allProducts.filter(
      entry => entry.code === productCode
    );
    const currentProduct = matchingProducts[0]; // NOTE: We are very optimistic
    let premiums = [];
    if (currentProduct) {
      premiums = currentProduct.premiums;
    }
    return premiums;
  }

  validateData() {
    const labels = this.state.labels;
    const productCode = document.getElementById('products').value;
    if (productCode === labels.selectProduct) {
      this.setState({
        validationErrors: [labels.selectProductError],
        validationErrorFields: ['products'],
      });
      return false;
    }
    const premium = document.getElementById('premiums').value;
    if (premium === labels.selectPremium) {
      this.setState({
        validationErrors: [labels.selectPremiumError],
        validationErrorFields: ['premiums'],
      });
      return false;
    }
    return true;
  }

  resetErrors() {
    this.setState({ validationErrors: [], validationErrorFields: [] });
  }

  submitPolicyPurchaseHandler(e) {
    e.preventDefault();
    this.resetErrors();
    const dataIsValid = this.validateData();
    if (dataIsValid === false) {
      return false;
    }
    const productCode = document.getElementById('products').value;
    const premium = document.getElementById('premiums').value;

    const purchaseData = {
      product_code: productCode,
      msisdn: this.props.customerData.currentCustomer.msisdn,
      amount_in_cents: premium,
    };
    if (this.state.selectedProductCoverType === 'Family') {
      this.updateSpouseIdInfo(purchaseData);
      if (!this.spouseIdValid()) {
        delete purchaseData.spouse_id;
      }
      this.updateSpouseNameInfo(purchaseData);
      if (!this.spouseNameValid()) {
        delete purchaseData.spouse_name;
      }
      this.updateSpouseDobInfo(purchaseData);
      if (!this.spouseDobValid()) {
        delete purchaseData.spouse_dob;
      }
    }
    this.updateDebitInfo(purchaseData);
    this.props.dispatch(actions.purchasePolicyRequest(purchaseData));
    return false;
  }

  updateDebitInfo(purchaseData) {
    if (this.debitVisible()) {
      purchaseData.debit = this.state.debit;
    }
  }

  successMessage() {
    const labels = this.state.labels;
    let successMessage = labels.successMessage;
    const policyPurchaseInfo = this.props.policyPurchaseData.policyPurchaseInfo;
    if (policyPurchaseInfo && policyPurchaseInfo.message) {
      successMessage = this.props.policyPurchaseData.policyPurchaseInfo.message;
    }
    return successMessage;
  }

  failureMessage() {
    const validationErrors = this.state.validationErrors;
    if (validationErrors.length !== 0) {
      return validationErrors[0]; // NOTE: Assuming there is always only 1 error
    }
    const labels = this.state.labels;
    const defaultErrorMessage = labels.defaultErrorMessage;
    const errorMessage = buildFailureMessage(
      defaultErrorMessage,
      this.props.policyPurchaseData.errors
    );
    return errorMessage;
  }

  // TODO: Move this in a helper & reduce duplication
  displayUserFeedback() {
    const styleErrorMessage = {
      display:
        this.props.policyPurchaseData.errors ||
        this.state.validationErrors.length !== 0
          ? 'block'
          : 'none',
    };

    const styleSuccessMessage = {
      display: this.props.policyPurchaseData.policyPurchaseInfo
        ? 'block'
        : 'none',
    };

    if (this.props.policyPurchaseData.loader) {
      return (
        <center>
          <CircularProgress />
        </center>
      );
    }
    return (
      <div>
        <center style={styleErrorMessage}>
          {' '}
          <p className="error">{this.failureMessage()} </p>{' '}
        </center>
        <center style={styleSuccessMessage}>
          {' '}
          <p className="success">{this.successMessage()}</p>{' '}
        </center>
      </div>
    );
  }

  displayBuyPolicyForm() {
    const labels = this.state.labels;
    const currentCustomer = this.props.customerData.currentCustomer;
    // TODO: Move this logic in a helper method
    if (!currentCustomer) {
      return  (
        <NoRecord
          noTextLabel={labels.titleNoCustomer}
          searchLabel={labels.searchCustomer}
          redirectTo="admin/search"
        />
      );
    }
    const productDisplay = classNames({
      'form-group': true,
      error: this.state.validationErrorFields.includes('products'),
    });
    const premiumDisplay = classNames({
      'form-group': true,
      error: this.state.validationErrorFields.includes('premiums'),
    });
    return (
      <div>
        <MainPageTitle pageTitle={labels.titleBuyPolicy} />
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <br />
                <form
                  id="policyPurchaseForm"
                  className="form-horizontal form-label-left"
                  noValidate
                  onSubmit={this.submitPolicyPurchaseHandler.bind(this)}
                >
                  {this.displayUserFeedback()}
                  <div className={productDisplay}>
                    <label className="control-label col-md-4 col-sm-4 col-xs-12">
                      {labels.product}
                      <span className="required">*</span>
                    </label>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className="col-md-4 col-sm-4 col-xs-4">
                        <select
                          id="products"
                          onChange={this.selectProduct.bind(this)}
                          required
                          defaultValue={this.state.selectedProduct}
                        >
                          <option key="-1" value={labels.selectProduct}>
                            {labels.selectProduct}
                          </option>
                          {this.props.policyPurchaseData.products.map(
                            (product, index) => (
                              <option
                                key={index}
                                value={product.code}
                                data-cover-type={product.cover_type}
                              >
                                {this.productLabel(product)}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className={premiumDisplay}>
                    <label className="control-label col-md-4 col-sm-4 col-xs-12">
                      {labels.cover}
                      <span className="required">*</span>
                    </label>
                    {this.premiumsOptions()}
                  </div>
                  {this.displaySpouseInfo()}
                  {this.debitDisplay()}
                  <div className="ln_solid" />
                  <div className="form-group">
                    <center>
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={this.clearForm.bind(this)}
                      >
                        {labels.cancel}
                      </button>
                      <button type="submit" className="btn btn-success">
                        {labels.submitBuyPolicy}
                      </button>
                    </center>
                  </div>
                </form>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    );
  }

  toggleDebit() {
    const debit = this.state.debit;
    this.setState({ debit: !debit });
  }

  debitDisplay() {
    const debitStyle = {
      display: this.debitEditable() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    return (
      <div className="form-group" style={debitStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.debit}
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12 padding5">
          <Checkbox
            required
            checked={this.state.debit}
            onChange={this.toggleDebit.bind(this)}
            color="primary"
          />
        </div>
      </div>
    );
  }

  debitVisible() {
    return partnerCanDebit();
  }

  debitEditable() {
    return partnerCanEditDebit();
  }

  render() {
    return <div>{this.displayBuyPolicyForm()}</div>;
  }
}

export default connect(state => ({
  policyPurchaseData: state.policyPurchase,
  customerData: state.currentCustomer,
  globalData: state.global,
}))(BuyPolicy);
