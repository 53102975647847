import React from 'react';
import { Typography, Paper, Box } from '@mui/material';

const ErrorComponent = ({ error }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Paper elevation={3} sx={{ p: 4, maxWidth: 400 }}>
        <Typography variant="h5" color="error" gutterBottom>
          Error
        </Typography>
        <Typography variant="body1">
          {error.message ? error.message  : 'An unexpected error occurred.'}
        </Typography>
      </Paper>
    </Box>
  );
};

export default ErrorComponent;
