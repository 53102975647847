import * as types from '../../../../../../lib/actionTypes.es6'


export const initialState = {

   

  get_quotes_errors: null,
  get_quotes_loader: false,
  get_quotes_success_message: '',
  quotes: [],

};

export default function accessBankQuoteReducer(state = initialState, action) {
  switch (action.type) {



    case types.GET_ACCESSBANK_CUSTOMER_QUOTES_REQUEST:
      return { ...state, get_quotes_loader: true, get_quotes_errors: null };
    case types.GET_ACCESSBANK_CUSTOMER_QUOTES_SUCCESS:
      return { ...state, get_quotes_loader: false, get_quotes_errors: null, quotes: action.payload };
    case types.GET_ACCESSBANK_CUSTOMER_QUOTES_FAILURE:
      return { ...state, get_quotes_loader: false, get_quotes_errors: action.payload };

    default:
      return state;
  }
}