import { take, call, put } from 'redux-saga/effects';
import {
  updateClaim,
  getClaimsHospital,
  getClaimRejectionReason,
  claimUploadCallback,
  getClaimInfo,
  retryFailedClaimPayment,
  claimFileDelete,
  updateClaimPaymentOutcome,
  getClaimConditionList,
  revertExpiredClaim, createClaimFile,
} from '../../lib/requests.es6';
import * as types from '../../lib/actionTypes.es6';
import * as actions from './actions.es6';
import { closedClaimsRequest } from '../ClaimsClosed/actions.es6';
import { summaryClaimsRequest } from '../ClaimsDashboard/actions.es6';
import { openClaimsRequest } from '../ClaimsOpen/actions.es6';
import { payableClaimsRequest } from '../ClaimsPayable/actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

/**
 * this saga continually watches register request actions
 */
export function* claimProcessingWatcher() {
  for (;;) {
    // TODO: There should be no need of an infinite loop here. We can listen for a specific action
    const actionRequest = yield take('PROCESS_CLAIM_REQUEST');
    try {
      const response = yield call(updateClaim, actionRequest.payload);
      yield put(actions.processClaimSuccess(response.data));
      yield put(closedClaimsRequest());
      yield put(summaryClaimsRequest());
      yield put(openClaimsRequest());
      yield put(payableClaimsRequest());
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.processClaimFail(errorPayload));
    }
  }
}

export function* claimHospitalsWatcher() {
  while (yield take('CLAIM_HOSPITAL_REQUEST')) {
    try {
      const response = yield call(getClaimsHospital);
      yield put(actions.claimHospitalsSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.claimHospitalsFail(errorPayload));
    }
  }
}

export function* claimRejectionReasonWatcher() {
  while (true) {
    const requestData = yield take(types.CLAIM_REJECTION_REASON_REQUEST);
    try {
      const response = yield call(getClaimRejectionReason, requestData.payload);
      yield put(actions.claimRejectionReasonSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.claimRejectionReasonFail(errorPayload));
    }
  }
}

export function* claimUploadRequestWatcher() {
  for (;;) {
    const requestData = yield take(types.CLAIM_UPLOAD_CALLBACK_REQUEST);
    try {
      yield call(claimUploadCallback, requestData.payload);
      yield put(actions.refreshClaimRequest(requestData.payload.claim_guid));
    } catch (e) {
      if (e.response) {
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
    }
  }
}

export function* claimCreateRequestWatcher() {
  for (;;) {
    const requestData = yield take(types.CLAIM_FILE_CREATE_REQUEST);
    try {
      yield call(createClaimFile, requestData.payload);
      yield put(actions.refreshClaimRequest(requestData.payload.claim_guid));
    } catch (e) {
      if (e.response) {
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
    }
  }
}

export function* claimFileDeleteRequestWatcher() {
  for (;;) {
    const requestData = yield take(types.CLAIM_FILE_DELETE_REQUEST);
    try {
      yield call(claimFileDelete, requestData.payload);
      yield put(actions.deleteClaimFileRequestSuccess());
      yield put(actions.refreshClaimRequest(requestData.payload.claim_guid));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.deleteClaimFileRequestFailure(errorPayload));
    }
  }
}

export function* refreshClaimRequestWatcher() {
  for (;;) {
    const requestData = yield take(types.REFRESH_CLAIM_REQUEST);
    try {
      const response = yield call(getClaimInfo, requestData.payload);
      yield put(actions.refreshClaimSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.refreshClaimFailure(errorPayload));
    }
  }
}

export function* retryFailedClaimPaymentRequestWatcher() {
  for (;;) {
    const requestData = yield take(types.RETRY_FAILED_CLAIM_PAYMENT_REQUEST);
    try {
      const response = yield call(retryFailedClaimPayment, requestData.payload);
      yield put(actions.retryFailedClaimRequestPaymentSuccess(response.data));
      yield put(actions.refreshClaimRequest(response.data.claim_guid));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.retryFailedClaimRequestPaymentFailure(errorPayload));
    }
  }
}

export function* updateClaimPaymentOutcomeRequestWatcher() {
  for (;;) {
    const requestData = yield take(types.CLAIM_PAYMENT_UPDATE_OUTCOME_REQUEST);
    try {
      const response = yield call(
        updateClaimPaymentOutcome,
        requestData.payload
      );
      yield put(actions.claimPaymentUpdateOutcomeSuccess(response.data));
      yield put(actions.refreshClaimRequest(response.data.claim_guid));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.claimPaymentUpdateOutcomeFailure(errorPayload));
    }
  }
}

export function* claimConditionListWatcher() {
  for (;;) {
    yield take(types.CLAIM_CONDITION_REQUEST);
    try {
      const response = yield call(getClaimConditionList);
      yield put(actions.claimConditionsSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.claimConditionsFail(errorPayload));
    }
  }
}

export function* expiredClaimReversalWatcher() {
  for (;;) {
    const requestData = yield take(types.EXPIRED_CLAIM_REVERSAL_REQUEST);
    try {
      const response = yield call(revertExpiredClaim, requestData.payload);
      yield put(actions.expiredClaimReversalSuccess(response.data));
      yield put(actions.refreshClaimRequest(requestData.payload.guid));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.expiredClaimReversalFail(errorPayload));
    }
  }
}
