import * as types from '../../../lib/actionTypes.es6';

export const initialState = {


  getReportTemplatesError: null,
  getReportTemplatesloader: false,
  reportTemplates: [],
  isEmptyTemplates: false,

  addReportTemplateError: null,
  addReportTemplateLoader: false,
  addReportTemplateSuccess: false,
  addedReportTemplate: null,

  //reportTemplateToDelete: null,
  editReportTemplateSuccess: false,
  editReportTemplateLoader: false,
  editReportTemplateError: null,
  reportTemplateToEdit: null,
  editTemplateInfo: null,

 
  deletReportTemplateSuccess: false,
  deleteReportTemplateLoader: false,
  deleteReportTemplateError: null,
  hardDeleteReportTemplateSuccess: false,
  hardDeleteReportTemplateLoader: false,
  reportTemplateToDelete: null,

};

export default function reportsTemplatesReducer(state = initialState, action) {
  switch (action.type) {


    case types.GET_REPORT_TEMPLATES_REQUEST:
      return {
        ...state,
        getReportTemplatesloader: true,
        getReportTemplatesError: null,
        reportTemplates: [],
        isEmptyTemplates: false,
      };

    case types.GET_REPORT_TEMPLATES_SUCCESS:
      return {
        ...state,
        reportTemplates: action.payload,
        getReportTemplatesloader: false,
        isEmptyTemplates: action.payload.length === 0
      };

    case types.GET_REPORT_TEMPLATES_FAILURE:
      return {
        ...state,
        getReportTemplatesloader: false,
        getReportTemplatesError: action.payload,
        isEmptyTemplates: false,
      };

    case types.RESET_GET_REPORT_TEMPLATES:
      return {
        ...state,
        getReportTemplatesError: null,
        getReportTemplatesloader: false,
        reportTemplates: [],
        isEmptyTemplates: false,
      };

    case types.ADD_REPORT_TEMPLATE_REQUEST:
      return {
        ...state,
        addReportTemplateLoader: true,
        addReportTemplateSuccess: false,
        addReportTemplateError: null,
        addedReportTemplate: null,
      };

    case types.ADD_REPORT_TEMPLATE_SUCCESS:
      return {
        ...state,
        addedReportTemplate: action.payload,
        addReportTemplateSuccess: true,
        addReportTemplateLoader: false,
      };

    case types.ADD_REPORT_TEMPLATE_FAILURE:
      return {
        ...state,
        addReportTemplateLoader: false,
        addReportTemplateSuccess: false,
        addReportTemplateError: action.payload,
      };



    case types.EDIT_REPORT_TEMPLATE_REQUEST:
      return {
        ...state,
        editReportTemplateLoader: true,
        editReportTemplateError: null,
        editTemplateInfo: null,
        editReportTemplateSuccess: false,
        reportTemplateToEdit: action.payload
      };

    case types.EDIT_REPORT_TEMPLATE_SUCCESS:
      return {
        ...state,
        editTemplateInfo: action.payload,
        editReportTemplateLoader: false,
        editReportTemplateSuccess: true,
      };

    case types.EDIT_REPORT_TEMPLATE_FAILURE:
      return {
        ...state,
        editReportTemplateLoader: false,
        editReportTemplateError: action.payload,
      };

    case types.RESET_REPORT_TEMPLATE_TO_EDIT:
      return { ...state, reportTemplateToEdit: null };

    case  types.RESET_EDIT_REPORT_TEMPLATE_SUCCESS:
      return { ...state, editReportTemplateSuccess: false, reportTemplateToEdit: null };

    case types.SET_REPORT_TEMPLATE_TO_EDIT:
      return { ...state, reportTemplateToEdit: action.payload };



      case types.DELETE_REPORT_TEMPLATE_REQUEST:
        return {
          ...state,
          deleteReportTemplateError: null,
          deleteReportTemplateLoader: true,
          deletReportTemplateSuccess: false,
          reportTemplateToDelete: action.payload,
          hardDeleteReportTemplateLoader: true,
        };
  
      case types.DELETE_REPORT_TEMPLATE_SUCCESS:
        return {
          ...state,
          deletReportTemplateSuccess: true,
          deleteReportTemplateLoader: false,
          deleteReportTemplateError: null,
          hardDeleteReportTemplateSuccess: true,
        };
  
      case types.DELETE_REPORT_TEMPLATE_FAILURE:
        return { ...state, deleteReportTemplateLoader: false, deleteReportTemplateError: action.payload, hardDeleteReportTemplateLoader: false };
  
      case types.RESET_DELETE_REPORT_TEMPLATE_SUCCESS:
        return { ...state, deletReportTemplateSuccess: false, hardDeleteReportTemplateSuccess: false, hardDeleteReportTemplateLoader: false };
  
      case types.RESET_DELETE_REPORT_TEMPLATE_ERROR:
        return { ...state, deleteReportTemplateError: null };
  
      case types.SET_REPORT_TEMPLATE_TO_DELETE:
        return { ...state, reportTemplateToDelete: action.payload };
  
      case types.RESET_REPORT_TEMPLATE_TO_DELETE:
        return { ...state, reportTemplateToDelete: null };

    default:
      return state;
  }
}
