import React, { useState } from 'react'
import moment from 'moment';
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Switch } from '@mui/material';
import debounce from 'debounce-promise'
import { searchDiagnosis } from '../../../../../lib/requests.es6';
import { CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const ReinbursementClaimDetails = (props) => {
  const classes = useStyle();

  // date_of_admission, date_of_discharge, diagnosis, hospital, hospital_in_panel, 
  const [openDiagnosis, setOpenDiagnosis] = useState(false);
  const [loadingDiagnosis, setDiagnosisLoading] = useState(false);
  const [inputDiagnosisValue, setInputDiagnosisValue] = useState('');
  const [diagnosisSearchResult, setDiagnosisSearchResult] = useState([]);
  const [, setDiagnosis] = useState(null);
  const [admissionDate, setAdmissionDate] = useState('');
  const [dischargeDate, setDischargeDate] = useState('');
  const [hospitalInPanel, sethospitalInPanel] = useState(false)
  const [hospital, setHospital] = useState(null);


  const debounceDiagnosisSearch = debounce(searchDiagnosis, 3000)

  const fetchDiagnosis = async (query) => {
    setDiagnosisLoading(true);
    try {
      const response = await debounceDiagnosisSearch({ query });
      setDiagnosisSearchResult(response.data);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
    setDiagnosisLoading(false);
  };

  const handleDiagnosisInputChange = (event, newValue) => {
    setInputDiagnosisValue(newValue);
    fetchDiagnosis(newValue);
  };


  const getAge = () => {
    const dob = props.member.date_of_birth;
    const currentDate = moment();
    const birthDate = moment(dob);
    const currentAge = currentDate.diff(birthDate, 'years');
    return currentAge;
  };

  const getUserFullname = () => {
    if (!props.member) return ""

    let name = ""
    if (props.member.first_name) name = name + " " + props.member.first_name
    if (props.member.middle_name) name = name + " " + props.member.middle_name
    if (props.member.last_name) name = name + " " + props.member.last_name

    return name
  }


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.headingclaim}>
            <h4>Patient details</h4>
          </div>
        </Grid>
      </Grid>
      <ValidatorForm onSubmit={() => { }}>
        <Grid container spacing={2} className={classes.selectpolicyWrapper}>
          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="name"
              style={{ width: "100%" }}
              label="full name"
              name='full_name'
              value={getUserFullname()}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="relationship"
              style={{ width: "100%" }}
              label="relationship"
              name='relationship'
              value={props.member.relationship}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="gender"
              style={{ width: "100%" }}
              label="gender"
              name='gender'
              value={props.member.gender}
              variant="outlined"
            />
          </Grid>



          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="date_of_birth"
              style={{ width: "100%" }}
              label="date_of_birth"
              name='date_of_birth'
              value={props.member.date_of_birth}
              variant="outlined"
            />
          </Grid>



          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="age"
              style={{ width: "100%" }}
              label="age"
              name='age'
              value={getAge()}
              variant="outlined"
            />
          </Grid>
        </Grid>

        <hr />
        <Grid container spacing={2} className={classes.selectpolicyWrapper}>

          <Grid item xs={12} sm={4} md={4}>
            <TextValidator
              id="admissionDate"
              label="Admission Date"
              name='admissionDate'
              value={admissionDate}
              onChange={(e) => {
                setAdmissionDate(e.target.value)
                props.setAdditionalState("admission_date", e.target.value)
              }}
              fullWidth
              type='date'
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TextValidator
              id="dischargeDate"
              label="Discharge Date "
              name='dischargeDate'
              value={dischargeDate}
              onChange={(e) => {
                setDischargeDate(e.target.value)
                props.setAdditionalState("discharge_date", e.target.value)
              }}
              fullWidth
              type='date'
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />

          </Grid>


          <Grid item xs={12} sm={4} md={4}>

            <Autocomplete
              open={openDiagnosis}
              onOpen={() => {
                setOpenDiagnosis(true);
              }}
              onClose={() => {
                setOpenDiagnosis(false);
              }}
              ListboxProps={{
                sx: {
                  "& li": {
                    fontFamily: 'Open Sans',
                    fontStyle: "normal",
                    fontSize: "12px",
                    lineHeight: "25px",
                    color: "#000000",
                  },
                }
              }}
              id="diagnosis"
              name='diagnosis'
              options={diagnosisSearchResult.sort((a, b) => -b.code.localeCompare(a.code))}
              groupBy={(option) => option.code}
              getOptionLabel={(option) => option.name.toLowerCase()}
              loading={loadingDiagnosis}
              inputValue={inputDiagnosisValue}
              onInputChange={handleDiagnosisInputChange}
              onChange={(event, selectedDiagnosis) => {
                setDiagnosis(selectedDiagnosis)
                props.setAdditionalState("diagnosis_name", selectedDiagnosis.name)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Diagnosis (ICD-10)"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingDiagnosis ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}

            />
          </Grid>

          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="hospital"
              style={{ width: "100%" }}
              label="hospital"
              name='hospital'
              value={hospital}
              variant="outlined"
              onChange={(e) => {
                setHospital(e.target.value)
                props.setAdditionalState("hospital_name", e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} sm={4}>
            <label>hospital in panel: {hospitalInPanel ? 'Yes' : 'No'} </label>
            <Switch
              checked={hospitalInPanel}
              onChange={() => { 
                sethospitalInPanel(!hospitalInPanel) 
                props.setAdditionalState("hospital_in_panel", !hospitalInPanel)
              }}
              inputProps={{ 'aria-label': 'controlled' }}
              validators={['']}
            />
          </Grid>


        </Grid>

      </ValidatorForm>
    </>
  )
}

export default ReinbursementClaimDetails

