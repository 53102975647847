import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { getPartners, getPartner } from '../../../../lib/access.es6';
import { reloadPage } from '../../../../lib/utils.es6';
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";



const SelectPartner = (props) => {
    const classes = useStyle();
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

    };

    const handleClosePartners = () => {
        setAnchorEl(null);
    }

    const selectPartner = (event) => {
        setAnchorEl(null);
        const partnerGuid = event.target.dataset.value;
        const partners = getPartners();
        const selectedPartner = partners.find(x => x.guid === partnerGuid);
        reloadPage();
        props.selectPartner(selectedPartner);
    };


    const partners = getPartners() ? getPartners() : [];
    return (
        <>
            <Grid item container spacing={2} className={classes.greetings}>
                {
                    partners.length > 1 ? (<>
                        <Grid item xs={12} md={12} sm={12} className={classes.dropdownpartners}>
                            <span id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                style={{ cursor: "pointer" }}
                                className={classes.selectpartner}
                            >
                                <ul>
                                    <li> {getPartner().name}</li>
                                    <li><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 9H16L12 16" fill="black" />
                                    </svg></li>
                                </ul>
                            </span>
                        </Grid>
                    </>) : (null)
                }

            </Grid>

            <Menu
                id="partners"
                className={classes.partnerlist}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClosePartners}
                MenuListProps={{
                    'aria-labelledby': 'partners',
                }}
            >
                {
                    partners.map((partner, index) => {
                        return (
                            <MenuItem 
                            data-value={partner.guid} 
                            key={partner.guid} 
                            onClick={selectPartner}
                           // onTouchEnd={handleTouchSelect} // Handle touch events
                            >{partner.name}</MenuItem>
                        )
                    })
                }
            </Menu>
        </>
    )
}

export default SelectPartner