import React from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { deepOrange } from '@material-ui/core/colors'
import * as utils from '../../lib/utils.es6';
import MainPageTitle from '../MainPageTitle/index.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { localisedText } from '../../lib/localisation.es6';
import {
  partnerHasCoolOff,
  partnerSupportFixedBenefit,
} from '../../lib/access.es6';
import Typography from "@material-ui/core/Typography";
import { Button } from '@mui/material';
import { SECONDARY_THEME_COLOR_SHADE_1 } from '../../lib/constants';

export class Premiums extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
      openConfirmCancel: false,
      openConfirmCancelCoolOff: false,
      premium: null,
    };
  }

  componentWillMount() {
    this.updateLanguage();
    this.props.getPremiums()
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'Premiums',
      this.state.labels
    );
  }

  handleCloseCancelDialog() {
    this.setState({ openConfirmCancel: false, openConfirmCancelCoolOff: false });
  }

  handleOpenCancelDialog(premium) {
    this.setState({ openConfirmCancel: true, premium });
  }

  cancelPremium() {
    this.props.deletePremiumHandler(this.state.premium);
    this.setState({ openConfirmCancel: false, premium: null });
  }

  actionVisible() {
    return partnerHasCoolOff();
  }

  actionHeader() {
    const labels = this.state.labels;
    const actionStyle = {
      display: this.actionVisible() ? '' : 'none',
    };
    return <TableCell className="table-cell-header" style={actionStyle}>{labels.action}</TableCell>;
  }

  getElapsedHours(date) {
    return Math.abs(new Date() - new Date(date)) / (60 * 60 * 1000);
  }



  actionColumn(premium) {
    const labels = this.state.labels;
    const elapsedHours = this.getElapsedHours(premium.created_at);
    const actionStyle = {
      display: this.actionVisible() ? '' : 'none',
    };
    if (premium.state === 'Failed') {
      return <TableCell style={actionStyle}>N/A</TableCell>;
    }
    return premium.valid === false ||
      premium.active === false ||
      elapsedHours > premium.cool_off_period_hours ? (
      <TableCell style={actionStyle}>
        {
          premium.active === false ?
            <button
              id="premium-delete-disabled-btn"
              type="button"
              onClick={() => { }}
              disabled
              className="btn btn-danger disabled"
            >
              {labels.cancelled}
            </button>
            : <button
              id="premium-delete-disabled-btn"
              type="button"
              onClick={() => { }}
              disabled
              className="btn btn-danger disabled"
            >
              {labels.cancel}
            </button>
        }
      </TableCell>
    ) : (
      <TableCell style={actionStyle}>
        <button
          id="premium-delete-enabled-btn"
          type="button"
          onClick={
            this.handleOpenCancelDialog.bind(this, premium)
          }
          className="btn btn-danger"
        >
          {labels.cancel}
        </button>
      </TableCell>
    );
  }

  deathBenefitVisible() {
    return partnerSupportFixedBenefit();
  }

  comparePremiums(premiumA, premiumB) {
    if (premiumA.state !== premiumB.state) {
      if (premiumA.state === 'Succeeded' || !premiumA.state) {
        return -1;
      }
      return 1;
    }
    if (
      new Date(premiumA.created_at).getTime() ===
      new Date(premiumB.created_at).getTime()
    ) {
      return 0;
    }
    if (new Date(premiumA.created_at) > new Date(premiumB.created_at)) {
      return -1;
    }
    return 1;
  }


  getBenefitLabel(benefit, labels) {
    switch (benefit.type.trim()) {
      case 'Hospital':
        return labels.nightlyBenefit;
      case 'Death':
        return labels.fixedBenefit;
      case 'Cashback':
        return labels.cashback;
      case 'Property':
        return labels.propertyAssistanceBenefit;
      case 'Income_Loss':
        return labels.incomeLossBenefit;
      case 'Personal_Accident':
        return labels.personalAccident;
      case 'Medical_Expense':
        return labels.medicalExpense;
      case 'Inpatient':
        return labels.inpatient;
      case 'Maternity':
        return labels.maternity;
      case 'Outpatient':
        return labels.outpatient;
      default:
        return benefit.type;
    }
  }

  getBenefits(premium) {
    const labels = this.state.labels;

    return (
      premium.benefits &&
      <div>
        {premium.benefits.map((benefit, index) => {
          if (benefit.type === 'Death' && !this.deathBenefitVisible()) {
            return '';
          }

          return (
            <Typography variant="caption" component="span" key={index} noWrap={true}>
              <strong>{this.getBenefitLabel(benefit, labels)}:</strong>&nbsp;{benefit.amount}<br />
            </Typography>);
        }
        )}
      </div>
    );
  }


  render() {
    const labels = this.state.labels;
    const premiums = this.props.premiums ? this.props.premiums : [];
    premiums.sort(this.comparePremiums);

    const premiumsData = premiums.map(premium => (
      <TableRow key={premium.guid}>
        <TableCell>{premium.product_name}</TableCell>
        <TableCell>{premium.reference}</TableCell>
        <TableCell>{premium.created_at}</TableCell>
        <TableCell className="col-xs-1">
          <Checkbox required checked={premium.valid} disabled />
        </TableCell>
        <TableCell className="col-xs-1">
          <Checkbox required checked={premium.active} disabled />
        </TableCell>
        <TableCell>{premium.start_date}</TableCell>
        <TableCell>{premium.end_date}</TableCell>
        <TableCell>{premium.amount_in_cents / 100}</TableCell>
        <TableCell>{this.getBenefits(premium)}</TableCell>
        {this.actionColumn(premium)}
      </TableRow>
    ));
    const styles = { backgroundColor: deepOrange[400], color: '#FFF' };
    const confirmDeleteActions = [
      <Button
        id="cancelBtnId"
        style={styles}
        onClick={this.handleCloseCancelDialog.bind(this)}
      >
        {labels.cancel}{' '}
      </Button>,
      <Button
        id="deleteBtnId"
        style={styles}
        onClick={this.cancelPremium.bind(this)}
      >
        {' '}
        {labels.deletePremium}
      </Button>,
    ];
    return (
      <div className={`${this.props.columnWidth} col-sm-6 col-xs-12`}>
        <MainPageTitle pageTitle={labels.titlePremiums} />
        {premiums.length === 0 ? (
          <center>{labels.nothingFound}</center>
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow >
                  <TableCell className="table-cell-header">{labels.productName}</TableCell>
                  <TableCell className="table-cell-header">{labels.mnoReference}</TableCell>
                  <TableCell className="table-cell-header">{labels.createdAt}</TableCell>
                  <TableCell className="table-cell-header">{labels.valid}</TableCell>
                  <TableCell className="table-cell-header">{labels.active}</TableCell>
                  <TableCell className="table-cell-header">{labels.startDate}</TableCell>
                  <TableCell className="table-cell-header">{labels.endDate}</TableCell>
                  <TableCell className="table-cell-header">{labels.amount}</TableCell>
                  <TableCell className="table-cell-header">{labels.benefits}</TableCell>
                  {this.actionHeader()}
                </TableRow>
              </TableHead>
              <TableBody className="premiums-body">
                {this.props.showMore
                  ? premiumsData.slice(0, 3)
                  : premiumsData}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Button
          id='more_premiums'
          href="#admin/premiums"
          style={{
            display: utils.displayValue(
              this.props.showMore && premiums.length !== 0
            ),
            backgroundColor: SECONDARY_THEME_COLOR_SHADE_1,
            marginTop: '1%'
          }}
          size="small"
          variant='contained'
        >
          {labels.morePremiums}
        </Button>
        <Dialog
          open={this.state.openConfirmCancel}
          onClose={this.handleCloseCancelDialog.bind(this)}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">
            {labels.confirmDeletePremium}
          </DialogTitle>
          <DialogContent>{labels.confirmDeletePremiumText}</DialogContent>
          <DialogActions>
            {confirmDeleteActions.map((item, index) => (
              <div key={index}> {item}</div>
            ))}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect(state => ({ globalData: state.global }))(Premiums);
