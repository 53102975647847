import * as types from '../../lib/actionTypes.es6';

export function getAllPoliciesRequest(customerGuid) {
  return {
    type: types.GET_ALL_POLICIES_REQUEST,
    payload: customerGuid,
  };
}

export function getAllPoliciesSuccess(files) {
  return {
    type: types.GET_ALL_POLICIES_SUCCESS,
    payload: files,
  };
}

export function getAllPoliciesFail(response) {
  return {
    type: types.GET_ALL_POLICIES_FAILURE,
    payload: response,
  };
}

export function getAllPaymentsRequest(customerGuid) {
  return {
    type: types.GET_ALL_PAYMENTS_REQUEST,
    payload: customerGuid,
  };
}

export function getAllPaymentsSuccess(files) {
  return {
    type: types.GET_ALL_PAYMENTS_SUCCESS,
    payload: files,
  };
}

export function getAllPaymentsFail(response) {
  return {
    type: types.GET_ALL_PAYMENTS_FAILURE,
    payload: response,
  };
}

export function getAllPremiumsRequest(customerGuid) {
  return {
    type: types.GET_ALL_PREMIUMS_REQUEST,
    payload: customerGuid,
  };
}

export function getAllPremiumsSuccess(files) {
  return {
    type: types.GET_ALL_PREMIUMS_SUCCESS,
    payload: files,
  };
}

export function getAllPremiumsFail(response) {
  return {
    type: types.GET_ALL_PREMIUMS_FAILURE,
    payload: response,
  };
}


export function getAllProductsRequest() {
  return {
    type: types.GET_ALL_PRODUCTS_REQUEST,
  };
}

export function getAllProductsSuccess(products) {
  return {
    type: types.GET_ALL_PRODUCTS_SUCCESS,
    payload: products,
  };
}

export function getAllProductsFail(response) {
  return {
    type: types.GET_ALL_PRODUCTS_FAILURE,
    payload: response,
  };
}

export function getAllCustomerClaimsRequest(msisdn) {
  return {
    type: types.GET_ALL_CUSTOMER_CLAIMS_REQUEST,
    payload: msisdn
  };
}

export function getAllCustomerClaimsSuccess(claims) {
  return {
    type: types.GET_ALL_CUSTOMER_CLAIMS_SUCCESS,
    payload: claims,
  };
}

export function getAllCustomerClaimsFail(response) {
  return {
    type: types.GET_ALL_CUSTOMER_CLAIMS_FAILURE,
    payload: response,
  };
}


export function getTotalPayablePremiumRequest(policy_guid) {
  return {
    type: types.GET_TOTAL_PAYABLE_PREMIUM_REQUEST,
    payload: policy_guid
  };
}

export function getTotalPayablePremiumSuccess(rete) {
  return {
    type: types.GET_TOTAL_PAYABLE_PREMIUM_SUCCESS,
    payload: rete,
  };
}

export function getTotalPayablePremiumFail(error) {
  return {
    type: types.GET_TOTAL_PAYABLE_PREMIUM_FAILURE,
    payload: error,
  };
}


export function getAllMandatesRequest(msisdn) {
  return {
    type: types.GET_ALL_MANDATES_REQUEST,
    payload: msisdn,
  };
}

export function getAllMandatesSuccess(mandates) {
  return {
    type: types.GET_ALL_MANDATES_SUCCESS,
    payload: mandates,
  };
}

export function getAllMandatesFail(response) {
  return {
    type: types.GET_ALL_MANDATES_FAILURE,
    payload: response,
  };
}