
import * as types from '../../../../../../lib/actionTypes.es6';

export const getOrangeSmsActivityRequest = (msisdn) =>{
  return{
    type: types.GET_ORANGE_SMS_ACTIVITY_REQUEST,
    payload: msisdn,
  }
}

export const getOrangeSmsActivitySuccess = (payload) =>{
  return{
    type: types.GET_ORANGE_SMS_ACTIVITY_SUCCESS,
    payload: payload,
  }
}

export const getOrangeSmsActivityFailure = (error) =>{
  return{
    type: types.GET_ORANGE_SMS_ACTIVITY_FAILURE,
    payload: error,
  }
}