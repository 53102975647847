import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  debitOrderTransactions: null,
  loader: false,
};

export default function dailyDebitDebitTransactionReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.GET_DAILY_DEBIT_ORDER_REQUEST:
      return { ...state, loader: true, errors: null };

    case types.GET_DAILY_DEBIT_ORDER_SUCCESS:
      return {
        ...state,
        debitOrderTransactions: action.payload,
        loader: false,
      };

    case types.GET_DAILY_DEBIT_ORDER_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    case types.UPDATE_DAILY_DEBIT_ORDER_FAILURE:
      return { ...state, errors: action.payload };

    default:
      return state;
  }
}
