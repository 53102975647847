import React from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import LAYOUT_CONST from '../constant';
import { HORIZONTAL_MAX_ITEM } from '../config';
import useConfig from '../../../hooks/useConfig'
import { MenuSwitch } from '../../../routes/Menu';
import { getPartnerGuid } from '../../../lib/access.es6';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = (props) => {

    
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const { layout } = useConfig();

    // last menu-item to show in horizontal menu bar
    const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? HORIZONTAL_MAX_ITEM : null;

    const partnerGuid = getPartnerGuid()

    const menulonglist = MenuSwitch(props.labels, partnerGuid)

    const filterMenu = (items) => {
            return items.filter(item => {
                if (item.permisions && !item.permisions.includes(false)) {
                    if (item.children) {
                        item.children = filterMenu(item.children);
                    }
                    return true;
                }
                return false;
            });
        }
      const filteredMenu = filterMenu(menulonglist);
      
    const menu = filteredMenu || []
    const menuItems = { items: menu }
    let lastItemIndex = menuItems.items.length - 1;
    let remItems = [];
    let lastItemId;

    if (lastItem && lastItem < menuItems.items.length) {
        lastItemId = menuItems.items[lastItem - 1].id;
        lastItemIndex = lastItem - 1;
        remItems = menuItems.items.slice(lastItem - 1, menuItems.items.length).map((item) => ({
            title: item.title,
            elements: item.children
        }));
    }


    const navItems = menuItems.items.slice(0, lastItemIndex + 1).map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup
                    key={item.id}
                    item={item}
                    lastItem={lastItem}
                    remItems={remItems}
                    lastItemId={lastItemId}
                    selectedParentID={props.selectedParentID}
                    drawerOpen={props.drawerOpen}
                    dispatch={props.dispatch}
                    selectedItem={props.selectedItem}
                    location={props.location}
                />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error1
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;