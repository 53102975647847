import axios from 'axios';
import { countryCode, getAuthorizationToken, getPartnerGuid, getUserGuid } from '../../../lib/access.es6';
import { getEnvironmentInformation, ignoreMaxDaysAllowedAfterDischargeOrDeath } from '../../../lib/utils.es6';

const environmentalInformation = getEnvironmentInformation();

const crudApiInstance = axios.create({
  baseURL: environmentalInformation ? environmentalInformation.servicesURLs.api : '',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Content-Language': 'en',
  },
});

crudApiInstance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getAuthorizationToken()}`;
  return config;
});

export const getClaim = async ({ guid }) => {
  const response = await crudApiInstance.get(`api/claims/?guid=${guid}&partner=${getPartnerGuid()}`);
  return response.data;
};

export const processClaimRequest = async (claim) => {
  const response = await crudApiInstance.post('/api/claims/process', claim);
  return response.data;
};

export const uploadDocument = async (claimGuid, file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('claimGuid', claimGuid);
  await crudApiInstance.post('/api/documents/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getClaimRejectionReason = async (language) => {
  const response = await crudApiInstance.get(`api/claim_rejection_reasons/?language=${language}&partner=${getPartnerGuid()}`);
  return response.data;
};

export const getClaimHospitals = async () => {
  const code = countryCode();
  // const code = 'KE'
  const response = await crudApiInstance.get(`api/claim_hospitals?country=${code}&partner=${getPartnerGuid()}`);
  return response.data;
};

export const getClaimConditions = async () => {
  const response = await crudApiInstance.get(`api/claim_conditions?partner=${getPartnerGuid()}`);
  return response.data;
};

export const getClaimAssesment = async (claimGuid) => {
  const response = await crudApiInstance.get(`api/assessment?partner=${getPartnerGuid()}&claim_guid=${claimGuid}`);
  return response.data;
};

export const getCustomer = async (customerGuid) => {
  const response = await crudApiInstance.get(`api/customers/${customerGuid}?partner=${getPartnerGuid()}`);
  return response.data;
};

export const getClaimMetaData = async () => {
  const response = await crudApiInstance.get(`api/metadata/claim?partner=${getPartnerGuid()}`);
  return response.data;
};


export const updateClaim = async (args) => {
  let params = "";
  if (args.ignoreMaxDays) {
    params = `?${ignoreMaxDaysAllowedAfterDischargeOrDeath}=${args.ignoreMaxDays}&partner=${getPartnerGuid()}`;
  }else{
    params = `?partner=${getPartnerGuid()}`;

  }
  args.editor_guid = getUserGuid();
  console.log('args', args);
  
  const response = await crudApiInstance.put(`/api/claims/${args.guid}${params}`, args);
  console.log('update claim', response);
  
  return response.data;
}


export const  createClaimFile =  async (args) => {
  const response = await crudApiInstance.post(`/api/claims/${args.claim_guid}/files?partner=${getPartnerGuid()}`, args);
  return response.data
}

export const deleteClaimDocument =  async (args) => {
  const response = await crudApiInstance.delete(`api/claims/files/${args.claim_doc_guid}?partner=${getPartnerGuid()}`);
  return response.data
}