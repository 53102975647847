import React from 'react'
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import Container from "@material-ui/core/Container";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// core version + navigation, pagination modules:
import { Pagination } from 'swiper';
// import Swiper and modules styles

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'


import Britam from '../../../../../Assets/britam.jpg'
import Equitel from '../../../../../Assets/equitel-line.jpg'
import Hollard from '../../../../../Assets/hollard.jpg'
import apainsurance from '../../../../../Assets/apa.jpg'
import metropolitan from '../../../../../Assets/metro.jpg'
import absa from '../../../../../Assets/absa.jpg'
import AirtelLogo from '../../../../../Assets/equitel.jpg'
import mtn from '../../../../../Assets/mtn.jpg'
import orange from '../../../../../Assets/orange.jpg'
import mfsafrica from '../../../../../Assets/onafriq.jpg'
import fnbzambia from '../../../../../Assets/fnb.jpg'
import instafinance from '../../../../../Assets/insta.jpg'
import mpost from '../../../../../Assets/mpost.jpg'
import equitybank from '../../../../../Assets/equity.jpg'
import realpeople from '../../../../../Assets/realpeople.jpg'
import accessbank from '../../../../../Assets/access.jpg'
import rafode from '../../../../../Assets/rafode.jpg'
import bboxx from '../../../../../Assets/bboxx.jpg'
import attlehang from '../../../../../Assets/atle.jpg'
import twende from '../../../../../Assets/twende.jpg'
import prudential from '../../../../../Assets/prudential.jpg'
//media query and breakpoints and MU theme imports
import MobilePartners from './MobilePartners';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
const Partners = () => {
    const classes = useStyle();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <section className={classes.partners} id="partners">
            <Container>
                <Grid container>
                    <Grid item xs={12} md={12} sm={12}>
                        <h2>Our Partners</h2>
                        <div className={classes.divider}></div>
                    </Grid>
                </Grid>
            </Container>
            <Container>
                <Grid container>
                    {
                        isMobile ? (<MobilePartners />) : (<>
                            <Swiper
                                spaceBetween={30}
                                //  slidesPerView={6}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Pagination]}
                                className={classes.mySwiper}
                            >
                                <SwiperSlide>
                                    <div className={classes.partnerlogos}>
                                        <img src={Britam} alt='Britam' />
                                    </div>
                                    <div className={classes.partnerlogos}>
                                        <img src={Hollard} alt='Holard insurance' />
                                    </div>
                                    <div className={classes.partnerlogos}>
                                        <img src={apainsurance} alt='API insurance' />
                                    </div>
                                    <div className={classes.partnerlogos}>
                                        <img src={metropolitan} alt='Metropolitan Insurance' />
                                    </div>
                                    <div className={classes.partnerlogos}>
                                        <img src={absa} alt='Absa' />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={classes.partnerlogos}>
                                        <img src={mfsafrica} alt='MFS Africa' />
                                    </div>
                                    <div className={classes.partnerlogos}>
                                        <img src={AirtelLogo} alt='Airtel' />
                                    </div>
                                    <div className={classes.partnerlogos}>
                                        <img src={mtn} alt='MTN' />
                                    </div>
                                    <div className={classes.partnerlogos}>
                                        <img src={orange} alt='Orange' />
                                    </div>
                                    <div className={classes.partnerlogos}>
                                        <img src={Equitel} alt='equitel' />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={classes.partnerlogos}>
                                        <img src={realpeople} alt='Real People' />
                                    </div>
                                    <div className={classes.partnerlogos}>
                                        <img src={fnbzambia} alt='FNB zambia' />
                                    </div>
                                    <div className={classes.partnerlogos}>
                                        <img src={instafinance} alt='Insta Finance' />
                                    </div>
                                    <div className={classes.partnerlogos}>
                                        <img src={mpost} alt='mpost' />
                                    </div>
                                    <div className={classes.partnerlogos}>
                                        <img src={equitybank} alt='Equity Bank' />
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className={classes.partnerlogos}>
                                        <img src={accessbank} alt='accessbank' />
                                    </div>
                                    <div className={classes.partnerlogos}>
                                        <img src={rafode} alt='rafode' />
                                    </div>
                                    <div className={classes.partnerlogos}>
                                        <img src={bboxx} alt='bboxx' />
                                    </div>
                                    <div className={classes.partnerlogos}>
                                        <img src={attlehang} alt='attlehang' />
                                    </div>
                                    <div className={classes.partnerlogos}>
                                        <img src={twende} alt='twende' />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={classes.partnerlogos}>
                                        <img src={prudential} alt='prudential' />
                                    </div>
                                   
                                </SwiperSlide>
                            </Swiper>
                        </>)
                    }
                </Grid>
            </Container>
        </section>
    )
}

export default Partners