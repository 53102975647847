import React from 'react';
import { connect } from 'react-redux';
import Payments from '../../components/Payments/index.es6';
import NoRecord from '../../components/NoRecord/index.es6';
import * as globalActions from '../Main/actions.es6';
import { getAllPaymentsRequest } from '../CustomerScreen/actions.es6';

export class PaymentList extends React.Component {
  componentWillMount() {
    this.props.dispatch(globalActions.showCustomerBar());
  }

  getPayments(){
    const currentCustomer = this.props.customerData.currentCustomer;
    if(currentCustomer){
      this.props.dispatch(getAllPaymentsRequest({msisdn: currentCustomer.msisdn}))
    }
  }
  

  displayPayments() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (!currentCustomer) {
      return <NoRecord />;
    }
    const payments = this.props.customerScreenData.payments;
    return (
      <div className="premium-list">
        <Payments
          columnWidth="col-md-12"
          payments={payments}
          showMore={false}
          getPayments={this.getPayments.bind(this)}
        />
      </div>
    );
  }

  render() {
    return <div>{this.displayPayments()}</div>;
  }
}

export default connect(state => ({ 
  customerData: state.currentCustomer,
  customerScreenData: state.customerScreen,
 }))(
  PaymentList
);
