import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import { DialogContent } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../lib/constants";
import { useEffect } from "react";
import ManageRoles from "./ManageRoles";
import ButtonInc from "../../../shared-ui/ButtonInc";

const EditRoles = (props) => {
  if (!props.user) {
    return "";
  }

  const [currentUserReableRoles, setCurrentUserReableRoles] = useState(
    props.currentUserReableRoles
  );
  const [, setCurrentPartners] = useState(props.currentUserPartners);

  useEffect(() => {
    setCurrentUserReableRoles(props.currentUserReableRoles);
  }, [props.currentUserReableRoles]);

  useEffect(() => {
    setCurrentPartners(props.currentUserPartners);
  }, [props.currentUserPartners]);

  return (
    <>
      <Dialog
        open={props.user}
        fullWidth
        maxWidth="lg"
        onClose={props.resetUserToEditRoles}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => props.resetUserToEditRoles()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit User Roles
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container spacing={4} style={{ marginTop: "2%" }}>
            <Grid item xs={12}>
              <ManageRoles
                allGroups={props.allRoless}
                currentGroups={currentUserReableRoles}
                user={props.user}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <div style={{ textAlign: "center", marginBottom: "2%" }}><ButtonInc onClick={() => props.resetUserToEditRoles()} color="primary">Close</ButtonInc></div>
      </Dialog>
    </>
  );
};

export default EditRoles;
