import React from 'react';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import ManIcon from '@mui/icons-material/Man';
import Grid from "@material-ui/core/Grid";

const CoverTypes = {
  MEMBER_ONLY: 'Member Only',
  MEMBER_AND_FAMILY: 'Member & Family'
};

export default function SelectCoverType({setCoverType, coverType}) {

  const handleSelect = (value) => {
    setCoverType(value)
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12} style={{ textAlign: 'center' }}><h2>What is your clan cover type</h2></Grid>
      <Grid item xs={12} sm={3} style={{ textAlign: 'right', }}></Grid>
      <Grid item xs={6} sm={3} style={{ textAlign: 'right', }}>
        <div style={{ textAlign: 'center' }}>
          <ManIcon
            onClick={() => handleSelect(CoverTypes.MEMBER_ONLY)}
            sx={{
              color: coverType === CoverTypes.MEMBER_ONLY ? 'orange' : '#bdbdbd',
              fontSize: 200,
              border: coverType === CoverTypes.MEMBER_ONLY ? 'solid 1px #032a37' : 'none',
              borderRadius: '10%',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                color: 'orange',
                cursor: 'pointer'
              }
            }}
          />
          <div>{CoverTypes.MEMBER_ONLY}</div>
        </div>
      </Grid>
      <Grid item xs={6} sm={6}  style={{ textAlign: 'left' }}>
        <div style={{ textAlign: 'left' }}>
          <FamilyRestroomIcon
            onClick={() => handleSelect(CoverTypes.MEMBER_AND_FAMILY)}
            sx={{
              color: coverType === CoverTypes.MEMBER_AND_FAMILY ? 'orange' : '#bdbdbd',
              fontSize: 200,
              border: coverType === CoverTypes.MEMBER_AND_FAMILY ? 'solid 1px #032a37' : 'none',
              borderRadius: '10%',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                color: 'orange',
                cursor: 'pointer'
              }
            }}
          />
          <div style={{marginLeft: '5%'}}>{CoverTypes.MEMBER_AND_FAMILY}</div>
        </div>
      </Grid>
    </Grid>
  )
}
