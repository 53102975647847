import { take, call, put } from 'redux-saga/effects';
import {
  deletePolicy,
  getPolicyProduct,
  initiatePolicyMobileMoneyPayment,
} from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { getCustomer } from '../Customer/actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';
import * as types from '../../lib/actionTypes.es6';
import { getAllPoliciesRequest } from '../CustomerScreen/actions.es6';

/**
 * this saga continually watches delete entity request actions
 */
export function* deletePolicyWatcher() {
  for (;;) {
    // TODO: There should be no need of an infinite loop here. We can listen for a specific action
    const policyData = yield take(types.DELETE_POLICY_REQUEST);
    try {
      const response = yield call(deletePolicy, policyData.payload.guid);
      yield put(actions.deletePolicySuccess(response.data));
      yield put(getCustomer(policyData.payload.customer_guid));
      yield put(getAllPoliciesRequest({msisdn: policyData.payload.customer_msisdn, guid: policyData.payload.customer_guid}))
    } catch (e) {
      yield put(
        actions.deletePolicyFail('An error occurred, failed to delete entity')
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* getPolicyProductWatcher() {
  for (;;) {
    try {
      const { payload } = yield take(types.GET_POLICY_PRODUCT_REQUEST);
      const response = yield call(getPolicyProduct, payload);
      yield put(actions.getPolicyProductSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.getPolicyProductFailure(errorPayload));
    }
  }
}
//TODO: duplicated one for complex dcp
export function* initiatePolicyMobilePaymentWatcher() {
  for (;;) {
    try {
      const { payload } = yield take(
        types.INITIATE_POLICY_MOBILE_MONEY_PAYMENT_REQUEST
      );
      const response = yield call(initiatePolicyMobileMoneyPayment, payload);
      let responseData = response.data
      if (responseData === "") responseData = "success";
      yield put(actions.initiatePolicyMobileMoneyPaymentSuccess(responseData));
    } catch (e) {
      let errorPayload = { message: 'Ooops something went wrong' };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.initiatePolicyMobileMoneyPaymentFailure(errorPayload));
    }
  }
}
