import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// import InputAdornment from '@mui/material/InputAdornment';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import AlertDialog from "../../../../../components/AlertDialog/index.es6";
import { CircularProgress, Switch } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../../../lib/constants";

let initialState = {}


export default function UpdateGroupProductConfigs(props) {
  if (!props.partnerConfigGroup) {
    return ""
  }
  initialState = props.partnerConfigGroup

  const [
    {
      id,
      minimum_premium_in_cents,
      additional_child_premium_rate_in_cents,
      additional_adult_premium_rate_in_cents,
      benefit_in_cents,
      child_benefit_in_cents,
      over_age_parents_benefit_in_cents,
      spouse_entry_minimum_age,
      spouse_entry_maximum_age,
      spouse_exit_age,
      principal_entry_minimum_age,
      principal_entry_maximum_age,
      principal_exit_age,
      parents_entry_minimum_age,
      parents_entry_maximum_age,
      parents_exit_age,
      beneficiary_entry_minimum_age,
      beneficiary_exit_age,
      parents_upper_threshold_age,
      child_entry_minimum_age_in_days,
			child_exit_age,

      minimum_group_size,
      maximum_children,
      maximum_parents,
      maximum_parents_in_laws,
      maximum_spouse,
      policy_compensation_fund,
      training_levy,
      stamp_duty_in_cents,
    }, setState
  ] = React.useState(initialState)

  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const toggleBooleanFields = e => {
    setState(prevState => ({ ...prevState, [e.target.name]: e.target.checked }));
  };



  const submit = () => {
    const payload = {
      id,
      minimum_premium_in_cents,
      additional_child_premium_rate_in_cents,
      additional_adult_premium_rate_in_cents,
      benefit_in_cents,
      child_benefit_in_cents,
      over_age_parents_benefit_in_cents,
      spouse_entry_minimum_age,
      spouse_entry_maximum_age,
      spouse_exit_age,
      principal_entry_minimum_age,
      principal_entry_maximum_age,
      principal_exit_age,
      parents_entry_minimum_age,
      parents_entry_maximum_age,
      parents_exit_age,
      beneficiary_entry_minimum_age,
      beneficiary_exit_age,
      parents_upper_threshold_age,
      child_entry_minimum_age_in_days,
			child_exit_age,

      minimum_group_size,
      maximum_children,
      maximum_parents,
      maximum_parents_in_laws,
      maximum_spouse,
      policy_compensation_fund,
      training_levy,
      stamp_duty_in_cents,
    }
    for(let k in payload){
      if(payload[k] == null) delete payload[k]
    }
    console.log('submitting');
    console.log(payload);
    props.updateGroupProductConfigs(payload)
  }



  const reset = () => {
    setState(initialState);
  }

  const handleUpdateParnterConfigdSuccessfully = () => {
    props.resetUpdateGroupProductConfigsSuccessAlert()
    props.closeUpdateGroupProductConfigsDialog()
    reset()
  }

  const handleUpdateParnterConfigError = () => {
    props.resetUpdateGroupProductConfigsErrorAlert()
  }

  const booleanFields = [
  ]

  const textFields = [
  ]

  const integerFields = [
    "minimum_premium_in_cents",
    "additional_child_premium_rate_in_cents",
    "additional_adult_premium_rate_in_cents",
    "benefit_in_cents",
    "child_benefit_in_cents",
    "over_age_parents_benefit_in_cents",
    "spouse_entry_minimum_age",
    "spouse_entry_maximum_age",
    "spouse_exit_age",
    "principal_entry_minimum_age",
    "principal_entry_maximum_age",
    "principal_exit_age",
    "parents_entry_minimum_age",
    "parents_entry_maximum_age",
    "parents_exit_age",
    "beneficiary_entry_minimum_age",
    "beneficiary_exit_age",
    "parents_upper_threshold_age",
    "child_entry_minimum_age_in_days",
    "child_exit_age",
    
    "minimum_group_size",
    "maximum_children",
    "maximum_parents",
    "maximum_parents_in_laws",
    "maximum_spouse",
    "policy_compensation_fund",
    "training_levy",
    "stamp_duty_in_cents",
  ]

  return (
    <>
      <AlertDialog
        custom
        show={props.showUpdateGroupProductConfigsProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>updating partner config group</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showUpdateGroupProductConfigsSuccessAlert}
        size="sm"
        title={'Partner config group updated'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleUpdateParnterConfigdSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>

      <AlertDialog
        show={props.showUpdateGroupProductConfigsErrorAlert}
        danger
        title={'Error updating partner config group'}
        onConfirm={() => handleUpdateParnterConfigError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.updateGroupProductConfigsError ? props.updateGroupProductConfigsError.message : ''}
      </AlertDialog>


      <Dialog open={props.showUpdateGroupProductConfigsDialog} fullWidth maxWidth='lg' fullScreen onClose={props.closeUpdateGroupProductConfigsDialog}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.closeUpdateGroupProductConfigsDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Update Group Product Configs
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div style={{ marginTop: "2%" }}>
            <Box sx={{ width: "100%" }}>
              <ValidatorForm onSubmit={() => submit()} >
                <Grid container spacing={2}>

                  {
                    booleanFields.map(field => {
                      return <Grid item lg={3} md={3} xs={12}>
                        <label>{field}</label>
                        <Switch
                          checked={eval(field)}  //eslint-disable-line
                          onChange={toggleBooleanFields}
                          name={field}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Grid>
                    })
                  }

                  {
                    textFields.map(field => {
                      return <Grid item lg={3} md={3} xs={12}>
                        <TextValidator
                          onChange={onChange}
                          value={eval(field)}  //eslint-disable-line
                          label={field}
                          name={field}
                          fullWidth
                          variant="outlined"
                          type="text"
                          placeholder={field}
                          // validators={['required']}
                          errorMessages={[`Please enter ${ field }`]}
                        />
                      </Grid>
                    })
                  }

                  {
                    integerFields.map(field => {
                      return <Grid item lg={3} md={3} xs={12}>
                        <TextValidator
                          onChange={onChange}
                          value={eval(field)}  //eslint-disable-line
                          label={field}
                          name={field}
                          fullWidth
                          variant="outlined"
                          type="number"
                          placeholder={field}
                          // validators={['required']}
                          errorMessages={[`Please enter ${ field }`]}
                        />
                      </Grid>
                    })
                  }



                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant="contained" type="submit" style={{ marginRight: '2%' }}> Update</Button>
                    <Button variant="contained" color="secondary" style={{ marginRight: '2%' }}
                      onClick={() => reset()}
                    > Reset</Button>
                  </Grid>

                </Grid>
              </ValidatorForm>
            </Box>
          </div>

        </DialogContent>
      </Dialog>




    </>
  );
}
