import React, { useState, useEffect } from 'react'
import ModalInc from '../../../../../shared-ui/ModalInc'
import ButtonInc from '../../../../../shared-ui/ButtonInc'
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import MenuItem from '@mui/material/MenuItem';
import Grid from "@material-ui/core/Grid";
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress } from '@mui/material';
import { useStyle } from "./Style";
import find from 'lodash/find';
import { ClaimTypeEnum } from '../../../lib/enum';


const AddClaimExpense = (props) => {
  const classes = useStyle();

  const [expenseType, setExpenseType] = useState('')
  const [invoice_amount_in_cents, setInvoiceAmountInCents] = useState('');
  const [numberOfDays, setNumberOfDays] = useState('')
  const [amountInCents, setAmountInCents] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('')
  const [deathCertificateNumber, setDeathCertificateNumber] = useState('');

  useEffect(() => {
    const deathBenefit = find(props.claim.benefits, { type: "DEATH BENEFIT" });
    if (deathBenefit) {
      setAmountInCents(deathBenefit.max_benefit_amount_in_cents / 100);
      setExpenseType(deathBenefit.type)
    }
  }, [])


  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === "invoice_amount_in_cents") {
      setInvoiceAmountInCents(value)
    }
    if (name === "amountInCents") {
      setAmountInCents(value)
    }
    if (name === "selectexpensetype") {
      setExpenseType(value)
      console.log("expense type", value)
    }
    if (name === "numberOfDays") {
      setNumberOfDays(value)
    }
    if (name === "invoiceNumber") {
      setInvoiceNumber(value)
    }
    if(name === "deathCertificateNumber"){
      setDeathCertificateNumber(value)
    }

  }
  const reset = () => {
    setInvoiceAmountInCents('');
    setDeathCertificateNumber('')
    setAmountInCents('');
    setNumberOfDays('');
    setExpenseType('');
    setInvoiceNumber('');
  }
  const handleClaimExpense = () => {
    const payload = {
      "benefit_type": expenseType,
      "invoice_amount_in_cents": Number(invoice_amount_in_cents),
      "benefit_amount_in_cents": Number(amountInCents) * 100,
      "benefit_usage_count": expenseType === "DAILY HOSPITAL BENEFIT" ? Number(numberOfDays) : 0,
      "invoice_number": invoiceNumber,
      "external_unique_identifier":  props.type === ClaimTypeEnum.death ? deathCertificateNumber : null,
      "claim_guid": props.claim.guid

    }
   props.handleClaimExpense(payload)
   console.log("Death payload", payload)
    reset()
  }
  const handleExpenseCreatedSuccessfully = () => {
    props.resetCreateExpenseSuccessAlert();
    props.toggleClaimExpenseDialog();
    window.location.reload();
  }
  const handleExpenseCreateError = () => {
    props.resetCreateExpenseErrorAlert();
  }

  const amountLabel = props.type === ClaimTypeEnum.hospital ? "Amount Payable" : (
    props.type === ClaimTypeEnum.death ? "Benefit Amount Payable" : "Reimbursement Amounts"
  )
  const descriptionLabel = props.type === ClaimTypeEnum.hospital ? "Invoice Amount" : (
    props.type === ClaimTypeEnum.death ? "Death Certificate Number" : "Description (invoice/receipt Amount) "
  )
  return (
    <>
      <AlertDialog
        custom
        show={props.showCreateExpenseProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2> Creating Claim Expense...</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showCreateExpenseSuccessAlert}
        size="sm"
        title={'Claim expense created'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleExpenseCreatedSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={true}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >

      </AlertDialog>

      <AlertDialog
        show={props.showCreateExpenseErrorAlert}
        danger
        title={'Error creating claim expense'}
        onConfirm={() => handleExpenseCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >

        {props.CreateExpenseError ? props.CreateExpenseError.message : ''}
      </AlertDialog>
      <ModalInc
        modalTitle="Input  Details"
        subTitle="Fill the below fields to add details"
        open={props.openClaimExpenseDialog}
        onClose={props.toggleClaimExpenseDialog}
        fullWidth

      >
        <ValidatorForm onSubmit={() => handleClaimExpense()} >
          <Grid container spacing={2} className={classes.editClaimDialog}>
            <Grid item xs={12} sm={12} md={12}>
              <SelectValidator
                labelId="select-expense-type"
                id="select-expense-type"
                value={expenseType}
                name='selectexpensetype'
                type="text"
                label="Select  Type"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select type']}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >

                {
                  props.claim.benefits.map((benefit, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }}
                      value={benefit.type}>{benefit.type}</MenuItem>
                  ))
                }

              </SelectValidator>
            </Grid>

            {
              expenseType === "DAILY HOSPITAL BENEFIT" ? (<>
                <Grid item xs={12} sm={12} md={12}>
                  <TextValidator
                    name='numberOfDays'
                    id="numberOfDays"
                    label="Number Of Days"
                    type="number"
                    value={numberOfDays}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Please enter number of days']}
                  />
                </Grid>
              </>) : (<>
              </>)
            }
            <Grid item xs={12} sm={12} md={12}>
              {
                props.type === ClaimTypeEnum.death ? (<TextValidator
                  name='deathCertificateNumber'
                  id="deathCertificateNumber"
                  label={descriptionLabel}
                  type='number'
                  value={deathCertificateNumber}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please enter death certificate number']}
                />) : (<TextValidator
                  name='invoice_amount_in_cents'
                  id="invoice_amount_in_cents"
                  label={descriptionLabel}
                  type='number'
                  value={invoice_amount_in_cents}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please enter invoice amount']}
                />)
              }

            </Grid>


            <Grid item xs={12} sm={12} md={12}>
              <TextValidator
                name='amountInCents'
                id="amountInCents"
                label={amountLabel}
                type="number"
                value={amountInCents}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter Amount']}
              />
            </Grid>
            {
              props.type === ClaimTypeEnum.hospital && <Grid item xs={12} sm={12} md={12}>
                <TextValidator
                  name='invoiceNumber'
                  id="invoiceNumber"
                  label="Invoice Number"
                  type="text"
                  value={invoiceNumber}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please enter Number']}
                />
              </Grid>
            }


          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} md={6}>

            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    type='submit'
                    style={{ marginTop: "20px", float: "right" }}
                  >
                    Save details
                  </ButtonInc>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginTop: "20px" }}
                    onClick={props.toggleClaimExpenseDialog}
                  >
                    Cancel
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </ModalInc>

    </>
  )
}

export default AddClaimExpense