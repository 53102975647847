export const ClaimStatusEnum =  {
  Pending : 'Pending',
  All_Docs_Pending : 'All_Docs_Pending',
  Docs_Partially_Submitted : 'Docs_Partially_Submitted',
  Awaiting_Confirmation : 'Awaiting_Confirmation',
  Failed_Confirmation : 'Failed_Confirmation',
  Discarded : 'Discarded',
  Decision_Pending : 'Decision_Pending',
  Approved : 'Approved',
  Approved_Paid : 'Approved_Paid',
  Rejected : 'Rejected',
  Rejected_Pending : 'Rejected_Pending',
}

export const ClaimActionEnum = {
  complete_docs:  'complete_docs',
  confirm:  'confirm',
  re_confirm:  're_confirm',
  fail_confirm:  'fail_confirm',
  discard:  'discard',
  approve:  'approve',
  approve_paid:  'approve_paid',
  reject:  'reject',
}


export const ClaimTypeEnum = {
  hospital:  'hospital',
  death:  'death',
  reimbursement:  'reimbursement',
}
