import React, { useState } from 'react'
import ModalInc from '../../../../../shared-ui/ModalInc'
import ButtonInc from '../../../../../shared-ui/ButtonInc'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress } from '@material-ui/core';

const AddBeneficiaryBankDetails = (props) => {

  const [account_name, setAccountName] = useState('');
  const [bank_name, setBankName] = useState('');
  const [account_number, setAccountNumber] = useState('');
  const [account_type, setAccountType] = useState('');
  const [branch_code, setBranchCode] = useState('')

  const reset = () => {
    setAccountName('');
    setBankName('');
    setAccountNumber('');
    setAccountType('');
    setBranchCode('')
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === "account_name") {
      setAccountName(value)
    }
    if (name === "bank_name") {
      setBankName(value)
    }
    if (name === "account_number") {
      setAccountNumber(value)
    }
    if (name === "account_type") {
      setAccountType(value)
    }
    if (name === "branch_code") {
      setBranchCode(value)
    }
  }
  const handleBankDetails = () => {
    const payload = {
      "bank_name": bank_name,
      "account_name": account_name,
      "account_number": account_number,
      "account_type": account_type,
      "branch_code": branch_code,
      "beneficiary_guid": props.beneficiaryGuid,
      "customer_guid": props.customerGuid,
    }
    props.addBankBeneficiaryDetails(payload)
    reset()
    console.log("banks details", payload)

  }
  const handleCustomerBankDetailsCreatedSuccessfully = () => {
    props.resetAddBankDetailsSuccessAlert();
    props.close()
  }

  const handleCustomerBankDetailsCreateError = () => {
    props.resetAddBankDetailsErrorAlert();
    props.close()
  }
  return (
    <>
      <AlertDialog
        custom
        show={!!props.showAddBankDetailsProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Adding  bank details </h2>
      </AlertDialog>

      <AlertDialog
        success
        show={!!props.showAddBankDetailsSuccessAlert}
        size="sm"
        title={' bank details added'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleCustomerBankDetailsCreatedSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={true}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>

      <AlertDialog
        show={!!props.showAddBankDetailsErrorAlert}
        danger
        title={'Error adding bank details.'}
        onConfirm={() => handleCustomerBankDetailsCreateError()}
        confirmBtnText={'ok'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.error ? props.error.message : ''}
      </AlertDialog>
      <ModalInc
        modalTitle="Add Beneficiary Bank Details"
        subTitle="Fill the below fields to add  bank details"
        open={props.open}
        onClose={props.close}
        fullWidth
      >
        <ValidatorForm onSubmit={() => handleBankDetails()} >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <TextValidator
                name='account_name'
                id="account_name"
                label="Account Name "
                value={account_name}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter account name']}
              />

            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextValidator
                name='bank_name'
                id="bank_name"
                label="Bank Name "
                value={bank_name}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter bank name']}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextValidator
                name='account_number'
                id="account_number"
                label="Account Number "
                type="number"
                value={account_number}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter account number']}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextValidator
                name='account_type'
                id="account_type"
                label="Account Type "
                type="text"
                value={account_type}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter account type']}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextValidator
                name='branch_code'
                id="branch_code"
                label="Branch Code "
                type="text"
                value={branch_code}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter branch code']}
              />
            </Grid>

          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'center' }}>
                  <ButtonInc
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{ width: "221px", height: "55px", background: "#FBA92D", marginTop: '3%' }}
                  > Save</ButtonInc>
                </Grid>
                <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'center' }}>
                  <ButtonInc
                    size="large"
                    onClick={props.close}
                    variant="contained"
                    style={{ width: "221px", height: "55px", background: "#FBA92D", marginTop: '3%' }}
                  > Cancel</ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </ModalInc>
    </>
  )
}

export default AddBeneficiaryBankDetails