import React, { useState, useEffect } from 'react'
import ModalInc from '../../../../../shared-ui/ModalInc'
import ButtonInc from '../../../../../shared-ui/ButtonInc'
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import MenuItem from '@mui/material/MenuItem';
import Grid from "@material-ui/core/Grid";
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress } from '@mui/material';
import { useStyle } from "./Style";
import find from 'lodash/find';
import { ClaimTypeEnum } from '../../../lib/enum';

const AddHospitalClaimExpense = (props) => {
  const classes = useStyle();

  const [expenseType, setExpenseType] = useState('')
  const [invoice_amount_in_cents, setInvoiceAmountInCents] = useState('');
  const [numberOfDays, setNumberOfDays] = useState('')
  const [amount, setAmount] = useState('');
  const [deathCertificateNumber, setDeathCertificateNumber] = useState('');

  useEffect(() => {
    const deathBenefit = find(props.claim.benefits, { type: "Death" });
    if (deathBenefit) {
      setAmount(deathBenefit.max_benefit_amount_in_cents / 100);
      setExpenseType(deathBenefit.type)
    }
  }, [])


  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === "invoice_amount_in_cents") {
      setInvoiceAmountInCents(value)
    }
    if (name === "amount") {
      setAmount(value)
    }
    if (name === "selectexpensetype") {
      setExpenseType(value)
      const hospitalBenefit = find(props.claim.benefits, { type: "Hospital" });
      if (hospitalBenefit) {
        setNumberOfDays(props.claim.claim_details.additional_details.number_of_days_in_hospital)
      }
    }
    if (name === "numberOfDays") {
      setNumberOfDays(value)
    }
  }

  useEffect(() => {
    if (numberOfDays) {
      if (numberOfDays >= 3 && numberOfDays <= 14) {
        //const hospitalBenefit = find(props.claim.benefits, { type: "Hospital" });
        const getInstallemnt = props.claim.claim_details.additional_details.installment_amount ;
        setAmount(getInstallemnt)
      } else if (numberOfDays > 14) {
        //const hospitalBenefit = find(props.claim.benefits, { type: "Hospital" });
        const multiplyInstallement = (props.claim.claim_details.additional_details.installment_amount ) * 2;
        setAmount(multiplyInstallement)
      }
    }

  }, [numberOfDays, expenseType]);

  const reset = () => {
    setInvoiceAmountInCents('');
    setDeathCertificateNumber('')
    setAmount('');
    setNumberOfDays('');
    setExpenseType('');
  }
  const handleClaimExpense = () => {
    const payload = {
      "benefit_type": expenseType,
      "invoice_amount_in_cents": Number(invoice_amount_in_cents),
      "benefit_amount_in_cents": Math.floor(Number(amount) * 100),
      "benefit_usage_count": expenseType === "Hospital" ? Number(numberOfDays) : 0,
      // "invoice_number": invoiceNumber,
      "external_unique_identifier": props.type === ClaimTypeEnum.death ? deathCertificateNumber : null,
      "claim_guid": props.claim.guid

    }
    props.handleClaimExpense(payload)
    console.log("Death payload", payload)
    reset()
  }
  const handleExpenseCreatedSuccessfully = () => {
    props.resetCreateExpenseSuccessAlert();
    props.toggleClaimExpenseDialog();
    window.location.reload();
  }
  const handleExpenseCreateError = () => {
    props.resetCreateExpenseErrorAlert();
  }



  return (
    <>
      <AlertDialog
        custom
        show={props.showCreateExpenseProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2> Creating Claim Expense...</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showCreateExpenseSuccessAlert}
        size="sm"
        title={'Claim expense created'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleExpenseCreatedSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={true}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >

      </AlertDialog>

      <AlertDialog
        show={props.showCreateExpenseErrorAlert}
        danger
        title={'Error creating claim expense'}
        onConfirm={() => handleExpenseCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >

        {props.CreateExpenseError ? props.CreateExpenseError.message : ''}
      </AlertDialog>
      <ModalInc
        modalTitle="Input  Details"
        subTitle="Fill the below fields to add details"
        open={props.openClaimExpenseDialog}
        onClose={props.toggleClaimExpenseDialog}
        fullWidth

      >
        <ValidatorForm onSubmit={() => handleClaimExpense()} >
          <Grid container spacing={2} className={classes.editClaimDialog}>
            <Grid item xs={12} sm={12} md={12}>
              <SelectValidator
                labelId="select-expense-type"
                id="select-expense-type"
                value={expenseType}
                name='selectexpensetype'
                type="text"
                label="Select  Type"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select type']}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >

                {
                  props.claim.benefits.map((benefit, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }}
                      value={benefit.type}>{benefit.type}</MenuItem>
                  ))
                }

              </SelectValidator>
            </Grid>


            <Grid item xs={12} sm={12} md={12}>
              <TextValidator
                name='numberOfDays'
                id="numberOfDays"
                label="Number Of Days"
                type="number"
                disabled
                value={numberOfDays}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter number of days']}
              />
            </Grid>


            <Grid item xs={12} sm={12} md={12}>
              <TextValidator
                name='amount'
                id="amount"
                label="Amount Payable"
                disabled
                type="number"
                value={amount}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter Amount']}
              />
            </Grid>

          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} md={6}>

            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    type='submit'
                    style={{ marginTop: "20px", float: "right" }}
                  >
                    Save details
                  </ButtonInc>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginTop: "20px" }}
                    onClick={props.toggleClaimExpenseDialog}
                  >
                    Cancel
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </ModalInc>

    </>
  )
}

export default AddHospitalClaimExpense