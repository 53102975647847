import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Box, Paper, Typography, Grid } from '@material-ui/core';
import { numberInCommaFormat } from '../../../../../../lib/access.es6';
import ButtonInc from '../../../../../../shared-ui/ButtonInc';
import { calculateTotalPremiumInCents, getAdditionalBenefits, group_life_options, replicatePrincipalBenefits } from '../../../helpers';
import { find } from 'lodash';

export default function AddBenefits(props) {
  if (!props.openAddBenefitsDialog) return null
  const [availableBenefits, setAvailableBenefits] = useState([]);

  const { coverType, memberUUID, selectedPlan } = props.openAddBenefitsDialog

  function combineArrays(current, available) {
    const currentMap = current.reduce((map, item) => {
      map[item.uuid] = item.selected;
      return map;
    }, {});

    const combined = available.map(item => {
      if (item.uuid in currentMap) {
        return { ...item, selected: currentMap[item.uuid] };
      }
      return item;
    });

    return combined;
  }


  useEffect(() => {
    const member = find(props.dependants, { uuid: memberUUID })
    const currentBenefits = member ? member.benefits : []
    let benefits = getAdditionalBenefits(coverType, selectedPlan)
    if(props.openAddBenefitsDialog.relationship === 'Principal'){
      benefits = [...benefits, ...group_life_options]
    }
    if(props.openAddBenefitsDialog.relationship === 'Spouse'){
      benefits = [ ...group_life_options]
    }
    if (currentBenefits && currentBenefits.length > 0) {
      const combined = combineArrays(currentBenefits, benefits)
      setAvailableBenefits(combined)
    } else {
      setAvailableBenefits(benefits)
    }
  }, [])

  const handleClose = () => {
    props.setOpenAddBenefitsDialog(null);
  };

  const handleSelect = (option) => {
    const newData = availableBenefits.map(item => {
      if (item.uuid === option.uuid) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setAvailableBenefits(newData);
  }


  const addBenefits = () => {

    const member = find(props.dependants, { uuid: memberUUID })
    const currentBenefits = member.benefits.filter(benefit => benefit.is_initialized)
    const selectedBenefits = availableBenefits.filter(benefit => benefit.selected)
    const newBenefits = [...currentBenefits, ...selectedBenefits]

    member.benefits = newBenefits
    member.premium_amount_in_cents = calculateTotalPremiumInCents(newBenefits)
    
    const tempDependants = props.dependants
    
    for (let i = 0; i < tempDependants.length; i++) {
      if (tempDependants[i].uuid === member.uuid) {
        tempDependants[i] = member;
        break;
      }
    }
    const replicated = replicatePrincipalBenefits(tempDependants)
    props.setDependants(replicated)

  }

  return (
    <div>
      <Dialog
        open={!!props.openAddBenefitsDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={'lg'}
        fullWidth
      >
        <DialogContent>
        <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h4" gutterBottom>  What additional benefits would you like to add? </Typography>
                  <Typography variant="h6" gutterBottom> click to select or unselect? </Typography>
                  <br />
                </Grid>


                {availableBenefits.map((benefit) => (
                  <Grid item xs={12} sm={6} md={4} key={benefit.uuid}>
                    <Box
                      component={Paper}
                      p={2}
                      onClick={() => handleSelect(benefit)}
                      style={{
                        backgroundColor: benefit.selected ? '#032A37' : '#fff',
                        color: benefit.selected ? '#fff' : '#000',
                        cursor: 'pointer'
                      }}
                    >
                      <Typography variant="h6">{benefit.name}</Typography>
                      {benefit.premium_amount && <Typography variant="body1">Premium : {numberInCommaFormat(benefit.premium_amount / 100)}</Typography>}
                      {benefit.benefit_amount && <Typography variant="body1">Benefit : {numberInCommaFormat(benefit.benefit_amount / 100)}</Typography>}
                      {benefit.benefit_amount_in_cents && <Typography variant="body1">Benefit : {numberInCommaFormat(benefit.benefit_amount_in_cents / 100)}</Typography>}
                      {benefit.name.includes('Waiver') && <Typography variant="body1">Benefit : {benefit.name}</Typography>}

                    </Box>
                  </Grid>


                ))}
                <Grid item xs={12}></Grid>
              </Grid>
          <Grid style={{ textAlign: 'center', marginTop: '2%' }}> <ButtonInc
            onClick={() => {
              addBenefits()
              props.setOpenAddBenefitsDialog(null)
            }}
          >Save</ButtonInc></Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}
