import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  claimList: null,
  loader: false,
  types: [],
  statuses: [],
  count: 0,
  paginated: false,
};

export default function claimSearchReducer(state = initialState, action) {
  switch (action.type) {
    case types.CLAIM_SEARCH_REQUEST:
      return { ...state, loader: true, errors: null, claimList: null };

    case types.CLAIM_SEARCH_SUCCESS:
      return {
        ...state,
        loader: false,
        ...paginationPayload(action.payload),
      };

    case types.CLAIM_SEARCH_FAILURE:
      return { ...state, loader: false, errors: action.payload };
    case types.CLAIM_TYPES_SUCCESS:
      return { ...state, types: action.payload };
    case types.CLAIM_STATUSES_SUCCESS:
      return { ...state, statuses: action.payload };

    case types.CLAIM_SEARCH_RESET:
      return { ...state, claimList: null };

    case types.USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
}

function paginationPayload(claimInfo) {
  return {
    claimList: claimInfo.items || claimInfo,
    count: claimInfo.count,
    paginated: !!claimInfo.count,
  };
}
