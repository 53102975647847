import React from 'react';
import { Button } from '@material-ui/core';
import { deepOrange } from '@material-ui/core/colors'
import Refresh from 'material-ui/svg-icons/navigation/refresh';
import { formatStatus } from '../../lib/utils.es6';
import { Grid } from '@mui/material';
import ButtonInc from '../../shared-ui/ButtonInc';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

export default class ClaimOverview extends React.Component {
  refreshClaimHandler(claimGuid) {
    this.props.claimRefresh(claimGuid);
  }

  redirectToCustomerStatusPage(customerGuid) {
    this.props.redirectToCustomerStatusPageHandler(customerGuid);
  }

  aspinAiBar() {
      return (
        <Grid
          item
          xs={12}
          style={{
            textAlign: "end",
            marginRight: '5.35%',
            marginBottom: '-2.5%',
            display: this.props.showAssessmentButton ? "block" : "none",
          }}
        >
          <ButtonInc
            onClick={this.props.toggleAssesmentDialog}
            style={{
              border: "1px solid #808080",
              borderRadius: "8px",
              background: "#fff",
              padding: "5px 35px",
            }}
          >
            <span style={{ color: "#000000" }}>
              View ASPin AI assesment
            </span>
            <AutoFixHighIcon
              sx={{
                color: deepOrange[400],
                marginLeft: "10px",
              }}
            />
          </ButtonInc>
        </Grid>
      );
  }

  titleBar() {
    const labels = this.props.labels;
    const data = this.props.data;
    if (data) {
      return (
        <div className="row grouping pill">
          <div className="col-sm-8">
            <h3 className="pill-text">
              {`${labels.claim} # : ${data.guid}
                 ${labels.status}:  ${formatStatus(data.status)}`}
              {
                <Button
                  style={{ marginLeft: '30px', color: deepOrange[400] }}
                  onClick={this.redirectToCustomerStatusPage.bind(
                    this,
                    data.customer_guid
                  )}
                >{`${labels.customer} : ${data.customer_msisdn}`}</Button>
              }
            </h3>
          </div>
          <div className="col-sm-offset-2 col-sm-2">
            <Button
              onClick={this.refreshClaimHandler.bind(this, data.guid)}
              style={{ marginTop: '7%', color: deepOrange[400] }}
            >
              <Refresh style={{ color: deepOrange[400] }} /> {labels.refresh}
            </Button>
          </div>
        </div>
      );
    }
    return <div />;
  }

  render() {
    const labels = this.props.labels;
    const data = this.props.data;
    const type = this.props.data.type;
    const fields = this.props.fields;
    const claimProperties = data ? Object.keys(data) : [];
    const fieldsApplicable = (data && fields ? fields[type] : []) || [];
    return (
      <div>
        {this.aspinAiBar()}
        {this.titleBar()}
        <br />
        <div className="grouping">
          <h3 className="header">{labels.claimsOverview}</h3>
          <br />
          <div className="row">
            {claimProperties.map((key, index) => {
              if (
                fieldsApplicable.includes(key) &&
                !(data[key] && typeof data[key] === 'object')
              ) {
                return (
                  <div className="col-sm-6" key={index}>
                    <div className="col-sm-5">{formatStatus(key)}:</div>
                    <div className="col-sm-7">
                      {formatStatus(data[key]) ? data[key].toString() : 'N/A'}
                    </div>
                  </div>
                );
              }
              return <div key={index} />;
            })}
          </div>
        </div>
      </div>
    );
  }
}
