import React, { useState } from 'react';
import { useStyle } from "./Style";
import ModalInc from '../../../../shared-ui/ModalInc';
import ButtonInc from '../../../../shared-ui/ButtonInc';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import DropzoneS3Uploader from "react-dropzone-s3-uploader";
import { getS3BucketSignedUrlForClaims } from '../../../../lib/requests.es6';
import ProgressState from '../../../ClaimsProcessingV2/Components/ClaimDocuments/ProgressState';

const ACCEPTED_MIME_TYPES = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'application/pdf'
];

const MAX_FILE_SIZE = 1024 * 1024 * 5; // 5MB

const AddClaimDocuments = (props) => {
  const classes = useStyle();
  const [s3Url] = useState('https://claims-document-received.s3.amazonaws.com');
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleFinishedUpload = (info) => {
    info.status = 'Succeeded';
    info.claim_guid = props.claimGuid;
    info.filename = info.fileUrl.split('.s3.amazonaws.com/').at(1);
    info.url = info.signedUrl;

    props.saveClaimDocument(info);
  }

  const handleFileCheck = (file) => {
    if (!ACCEPTED_MIME_TYPES.includes(file.type)) {
      setErrorMessage('Invalid file type. Only PNG, JPEG, JPG, and PDF files are allowed.');
      return false;
    }

    if (file.size > MAX_FILE_SIZE) {
      setErrorMessage('File is too large. Maximum size is 5MB.');
      return false;
    }

    setErrorMessage(null);
    return true;
  };

  const getS3Ur = (file, callback) => {
    if (handleFileCheck(file)) {
      getS3BucketSignedUrlForClaims(file, callback, props.claimGuid);
    }
  }

  const uploadOptions = {
    getSignedUrl: getS3Ur,
    s3Url: s3Url,
    accept: ACCEPTED_MIME_TYPES,
    maxSize: MAX_FILE_SIZE,
    uploadRequestHeaders: {},
    onProgress: (progress) => setProgress(progress),
    onError: (error) => setErrorMessage(error),
  };

  const reset = () => {
    setProgress(0);
    setErrorMessage(null);
    props.toggleClaimDocumentsDialog();
    window.location.reload();
  }

  return (
    <ModalInc
      modalTitle="Upload Claim Documents"
      subTitle="Fill the below fields to add claim documents"
      open={props.openClaimDocumentsDialog}
      onClose={props.toggleClaimDocumentsDialog}
      fullWidth
    >
      <ValidatorForm onSubmit={() => {}} >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.uploadsection}>
              {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
              <p style={{ marginBottom: '10px' }}>
                Accepted file types: PNG, JPEG, JPG, PDF (Max size: 5MB)
              </p>
              <DropzoneS3Uploader
                onFinish={handleFinishedUpload}
                upload={uploadOptions}
                s3Url={s3Url}
                passChildrenProps={false}
                className={classes.uploadzone}
                style={{
                  width: "100%",
                  height: "250px",
                  background: "#FFFFFF",
                  border: "1px dashed #B8B8B8",
                  marginTop: "40px",
                  padding: " 70px 0"
                }}
              >
                <div>
                  <ProgressState progress={progress} errorMessage={errorMessage} />
                </div>
              </DropzoneS3Uploader>
            </div>
            {props.error && <p style={{color: 'red'}}>{props.error}</p>}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} style={{textAlign: 'center'}}>
                <ButtonInc
                  size="large"
                  onClick={reset}
                  type="submit"
                  variant="contained"
                  style={{ width: "221px", height: "55px", background: "#FBA92D", marginTop: '3%'}}
                >
                  I'm Done
                </ButtonInc>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ButtonInc
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={props.toggleClaimDocumentsDialog}
                  style={{ width: "221px", height: "55px", background: "#FBA92D", marginTop: '3%'}}
                >
                  Cancel
                </ButtonInc>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ValidatorForm>
    </ModalInc>
  );
};

export default AddClaimDocuments;