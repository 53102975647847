import * as types from '../../../../lib/actionTypes.es6'


export function getPoliciesDcpRequest(payload){
  return{
    type: types.GET_POLICIES_DCP_REQUEST,
    payload: payload
  }
}


export const getPoliciesDcpSuccess = (payload) =>{
  return{
    type: types.GET_POLICIES_DCP_SUCCESS,
    payload: payload,
  }
}

export const getPoliciesDcpFailure = (error) =>{
  return{
    type: types.GET_POLICIES_DCP_FAILURE,
    payload: error,
  }
}