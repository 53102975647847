import * as types from '../../../../lib/actionTypes.es6'


export const initialState = {
  errors: null,
  loader: false,


  addBulkPaymentsError: null,
  addBulkPaymentsLoader: false,
  addedBulkPayments: null,

  saveBulkPaymentsLoader: false,
  saveBulkPaymentsError: null,
  addedTemplateDocument: null,

  batches: [],
  getBatchesLoader: false,
  getBatchesError: null,

  failedPaymentsBatches: [],
  failedPaymentsBatchesLoader: false,
  failedPaymentsBatchesError: null

};

export default function bulkPaymentsReducer(state = initialState, action) {
  switch (action.type) {


    case types.ADD_BULK_PAYMENTS_REQUEST:
      return {
        ...state,
        addBulkPaymentsLoader: true,
        addBulkPaymentsError: null,
        addedBulkPayments: null,
      };

    case types.ADD_BULK_PAYMENTS_SUCCESS:
      return {
        ...state,
        addedBulkPayments: action.payload,
        addBulkPaymentsLoader: false,
      };

    case types.ADD_BULK_PAYMENTS_FAILURE:
      return {
        ...state,
        addBulkPaymentsLoader: false,
        addBulkPaymentsError: action.payload,
      };



    case types.SAVE_BULK_PAYMENTS_REQUEST:
      return {
        ...state,
        saveBulkPaymentsLoader: true,
        saveBulkPaymentsError: null,
        addedTemplateDocument: null,
      };

    case types.SAVE_BULK_PAYMENTS_SUCCESS:
      return {
        ...state,
        addedTemplateDocument: action.payload,
        saveBulkPaymentsLoader: false,
      };

    case types.SAVE_BULK_PAYMENTS_FAILURE:
      return {
        ...state,
        saveBulkPaymentsLoader: false,
        saveBulkPaymentsError: action.payload,
      };

    case types.GET_BULK_PAYMENTS_BATCHES_REQUEST:
      return {
        ...state,
        getBatchesError: null,
        getBatchesLoader: true,
      };

    case types.GET_BULK_PAYMENTS_BATCHES_SUCCESS:
      return {
        ...state,
        batches: action.payload,
        getBatchesLoader: false,
        getBatchesError: null,
      };

    case types.GET_BULK_PAYMENTS_BATCHES_FAILURE:
      return { ...state, getBatchesLoader: false, getBatchesError: action.payload };


      case types.GET_FAILED_PAYMENTS_BATCHES_REQUEST:
        return {
          ...state,
          failedPaymentsBatchesError: null,
          failedPaymentsBatchesLoader: true,
        };
  
      case types.GET_FAILED_PAYMENTS_BATCHES_SUCCESS:
        return {
          ...state,
          failedPaymentsBatches: action.payload,
          failedPaymentsBatchesLoader: false,
          failedPaymentsBatchesError: null,
        };
  
      case types.GET_BATCHES_PAYMENTS_FAILURE:
        return { ...state, failedPaymentsBatchesLoader: false, failedPaymentsBatchesError: action.payload };


    default:
      return state;
  }
}
