import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { BENEFIT_NAMES } from "../../../../PoductConstants";
import { InputAdornment } from "@mui/material";


let initialState = {
  name: 'Death',
  principal_max_age_limit: '60',
  spouse_max_age_limit: '60',
  spouse_min_age_limit: '18',
  child_max_age_limit: '18',
  amount_benefit: '123',
  guid: '',
  product_guid: '',
}
export default function FixedIndemnityForm(props) {
  if(!props.benefit){
    return ""
  }

  initialState = props.benefit

  const [
    { name,
      principal_max_age_limit,
      spouse_max_age_limit,
      spouse_min_age_limit,
      child_max_age_limit,
      amount_benefit,
      guid,
      product_guid,
    }, setState
  ] = React.useState(initialState)

  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const submit = () => {
    const payload = {
      name,
      principal_max_age_limit: Number(principal_max_age_limit),
      spouse_max_age_limit: Number(spouse_max_age_limit),
      spouse_min_age_limit: Number(spouse_min_age_limit),
      child_max_age_limit: Number(child_max_age_limit),
      amount_benefit: Number(amount_benefit),
      guid,
      product_guid,
    }
    payload['benefitType']='fixed'
    props.editBenefit(payload)
  }

  return (
    <React.Fragment>
      <ValidatorForm onSubmit={() => submit()} >
        <Grid container spacing={2}>
          <Grid item lg={3} md={6} xs={12}><SelectValidator
            value={name}
            label='name'
            id="name"
            name='name'
            onChange={onChange}
            className="form-control"
            type="text"
            variant="outlined"
            validators={['required']}
            errorMessages={['Please select name']}
            style={{ width: '225px' }}
          >
            <MenuItem value="">{'Please select name'}</MenuItem>
            {
              BENEFIT_NAMES.FIXED.map((type) => {
                return <MenuItem value={type}>{type}</MenuItem>
              })
            }
          </SelectValidator></Grid>

          <Grid item lg={3} md={6} xs={12}><TextValidator
            onChange={onChange}
            label='principal max age limit'
            name='principal_max_age_limit'
            value={principal_max_age_limit}
            fullWidth
            variant="outlined"
            type="number"
            placeholder='principal max age limit'
            validators={['required']}
            errorMessages={['Please enter principal max age limit']}
          /></Grid>

          <Grid item lg={3} md={6} xs={12}><TextValidator
            onChange={onChange}
            label='spouse max age limit'
            name='spouse_max_age_limit'
            value={spouse_max_age_limit}
            fullWidth
            variant="outlined"
            type="number"
            placeholder='spouse max age limit'
            validators={['required']}
            errorMessages={['Please enter spouse max age limit']}
          /></Grid>

          <Grid item lg={3} md={6} xs={12}><TextValidator
            onChange={onChange}
            label='spouse min age limit'
            name='spouse_min_age_limit'
            value={spouse_min_age_limit}
            fullWidth
            variant="outlined"
            type="number"
            placeholder='spouse min age limit'
            validators={['required']}
            errorMessages={['Please enter spouse min age limit']}
          /></Grid>


          <Grid item lg={3} md={6} xs={12}><TextValidator
            onChange={onChange}
            label='child max age limit'
            name='child_max_age_limit'
            value={child_max_age_limit}
            fullWidth
            variant="outlined"
            type="number"
            placeholder='child max age limit'
            validators={['required']}
            errorMessages={['Please enter child max age limit']}
          /></Grid>

          <Grid item lg={3} md={6} xs={12}><TextValidator
            onChange={onChange}
            value={amount_benefit}
            label='amount benefit'
            name='amount_benefit'
            fullWidth
            variant="outlined"
            type="number"
            placeholder='amount'
            validators={['required']}
            errorMessages={['Please enter amount benefit']}
            InputProps={{
              startAdornment: (<InputAdornment position="start">
              {props.currencyCode}
              </InputAdornment>),

              'aria-label': 'Ksh',
            }}
          /></Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button variant="contained" type="submit" style={{ marginRight: '2%' }}> SAve </Button>
            <Button variant="contained" color="secondary" style={{ marginRight: '2%' }} onClick={() => props.resetBenefitToEdit()}> Cancel</Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </React.Fragment>
  );
}
