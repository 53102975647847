import React from 'react'
import { useStyle } from "./Style";
import { hashHistory } from 'react-router';
import Grid from "@material-ui/core/Grid";
import Button from '@mui/material/Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import ButtonInc from '../../../../shared-ui/ButtonInc';
import { hasAuthourity } from '../../../../lib/access.es6';

const PolicyHeader = (props) => {
	const classes = useStyle();

	const handleBulkOnboard = () => {
		hashHistory.push('admin/bulk-policy-onboarding');

	}
	return (
		<section className={classes.policyheader}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6} md={6}>
					<div className={classes.policytitle}>
						<h1>Generate Quote</h1>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={6}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={9} md={9}>
							<div className={classes.registerbtn}>
								<Button variant="contained" endIcon={<AddOutlinedIcon />} onClick={props.openRegisterCustomerDialog}>
									Register Customer
								</Button>
							</div>
						</Grid>
						<Grid item xs={12} sm={3} md={3}>
							<div className={classes.registerbtn}>
								<ButtonInc variant="contained" endIcon={<UploadFileOutlinedIcon />} onClick={handleBulkOnboard} hasPermision={hasAuthourity('BULK_ONBOARD_CUSTOMERS')} >
									Bulk Onboard
								</ButtonInc>
							</div>
						</Grid>
					</Grid>

				</Grid>
			</Grid>
		</section>
	)
}

export default PolicyHeader