import * as types from '../../../../lib/actionTypes.es6'

export const initialState = {
  loader: false,

  get_policies_errors: null,
  get_policies_loader: false,
  get_policies_success_message: '',
  policies: [],

  get_quotes_errors: null,
  get_quotes_loader: false,
  get_quotes_success_message: '',
  quotes: [],

  get_premiums_errors: null,
  get_premiums_loader: false,
  get_premiums_success_message: '',
  premiums: [],

  cancelPolicyErrors: null,
  cancelPolicyLoader: false,
  cancelPolicySuccess: false,

  cancelV2PolicySuccess: false,
  cancelV2PolicyLoader: false,
  cancelV2PolicyError: null,
  policyToCancel: null,

  policyNotes: null,
  policyToView: null,
  policyNotesSuccess: false,
  policyNotesLoader: false,
  policyNotesError: null,

  NotesToAdd: null,
  policyNotesToAdd: null,
  policyNotesToAddSuccess: false,
  policyNotesToAddLoader: false,
  policyNotesToAddError: null,

  get_sms_activity_errors: null,
  get_sms_activity_loader: false,
  get_sms_activity_success_message: '',
  smsActivities: [],

  initiatePaymentSuccess: false,
  initiatePaymentLoader: false,
  initiatePaymentError: null,
};

export default function customerStatusKonyanaReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ALL_CUSTOMER_POLICIES_REQUEST:
      return { ...state, get_policies_loader: true, get_policies_errors: null };
    case types.GET_ALL_CUSTOMER_POLICIES_SUCCESS:
      return { ...state, get_policies_loader: false, get_policies_errors: null, policies: action.payload };
    case types.GET_ALL_CUSTOMER_POLICIES_FAILURE:
      return { ...state, get_policies_loader: false, get_policies_errors: action.payload };


    case types.VIEW_CUSTOMER_QUOTES_REQUEST:
      return { ...state, get_quotes_loader: true, get_quotes_errors: null };
    case types.VIEW_ALL_CUSTOMER_QUOTES_SUCCESS:
      return { ...state, get_quotes_loader: false, get_quotes_errors: null, quotes: action.payload };
    case types.VIEW_CUSTOMER_QUOTES_FAILURE:
      return { ...state, get_quotes_loader: false, get_quotes_errors: action.payload };


    case types.GET_ALL_CUSTOMER_PREMIUMS_REQUEST:
      return { ...state, get_premiums_loader: true, get_premiums_errors: null };
    case types.GET_ALL_CUSTOMER_PREMIUMS_SUCCESS:
      return { ...state, get_premiums_loader: false, get_premiums_errors: null, premiums: action.payload };
    case types.GET_ALL_CUSTOMER_PREMIUMS_FAILURE:
      return { ...state, get_premiums_loader: false, get_premiums_errors: action.payload };

    case types.CANCEL_POLICY_REQUEST:
      return {
        ...state,
        cancelPolicyErrors: null,
        cancelPolicyLoader: true,
        cancelPolicySuccess: false,
      };

    case types.CANCEL_POLICY_SUCCESS:
      return {
        ...state,
        cancelPolicySuccess: true,
        cancelPolicyLoader: false,
        cancelPolicyErrors: null,
      };

    case types.CANCEL_POLICY_FAILURE:
      return { ...state, cancelPolicyLoader: false, cancelPolicyErrors: action.payload };

    case types.RESET_CANCEL_POLICY_SUCCESS:
      return { ...state, cancelPolicySuccess: false };

    case types.RESET_CANCEL_POLICY_ERROR:
      return { ...state, cancelPolicyErrors: null };


    case types.CANCEL_V2_POLICY_REQUEST:
      return {
        ...state,
        cancelV2PolicyError: null,
        cancelV2PolicyLoader: true,
        cancelV2PolicySuccess: false,
        policyToCancel: action.payload
      };

    case types.CANCEL_V2_POLICY_SUCCESS:
      return {
        ...state,
        cancelV2PolicySuccess: true,
        cancelV2PolicyLoader: false,
        cancelV2PolicyError: null,
        //createdPremiums: deleteFromCreatedPremiums(state)
      };

    case types.CANCEL_V2_POLICY_FAILURE:
      return { ...state, cancelV2PolicyLoader: false, cancelV2PolicyError: action.payload };

    case types.RESET_CANCEL_V2_POLICY_SUCCESS:
      return { ...state, cancelV2PolicySuccess: false };

    case types.RESET_CANCEL_V2_POLICY_ERROR:
      return { ...state, cancelV2PolicyError: null };

    case types.SET_V2_POLICY_TO_CANCEL:
      return { ...state, policyToCancel: action.payload };

    case types.RESET_V2_POLICY_TO_CANCEL:
      return { ...state, policyToCancel: null };


    case types.POLICY_NOTES_REQUEST:
      return {
        ...state,
        policyNotesError: null,
        policyNotesLoader: true,
        policyNotesSuccess: false,
        policyNotes: null
      };

    case types.POLICY_NOTES_SUCCESS:
      return {
        ...state,
        policyNotes: action.payload,
        policyNotesSuccess: true,
        policyNotesLoader: false,
        policyNotesError: null,
      };

    case types.POLICY_NOTES_FAILURE:
      return { ...state, policyNotesLoader: false, policyNotesError: action.payload };

    case types.RESET_POLICY_NOTES_SUCCESS:
      return { ...state, policyNotesSuccess: false };

    case types.RESET_POLICY_NOTES_ERROR:
      return { ...state, policyNotesSuccess: null };

    case types.SET_POLICY_NOTE_TO_VIEW:
      return { ...state, policyToView: action.payload };

    case types.RESET_POLICY_NOTE_TO_VIEW:
      return { ...state, policyToView: null };


      case types.POLICY_NOTES_ADD_REQUEST:
        return {
          ...state,
          policyNotesToAddError: null,
          policyNotesToAddLoader: true,
          policyNotesToAddSuccess: false,
          NotesToAdd: null
        };
  
      case types.POLICY_NOTES_ADD_SUCCESS:
        return {
          ...state,
          NotesToAdd: action.payload,
          policyNotesToAddSuccess: true,
          policyNotesToAddLoader: false,
          policyNotesToAddError: null,
        };
  
      case types.POLICY_NOTES_ADD_FAILURE:
        return { ...state, policyNotesToAddLoader: false, policyNotesToAddError: action.payload };
  
      case types.RESET_POLICY_NOTES_ADD_SUCCESS:
        return { ...state, policyNotesToAddSuccess: false };
  
      case types.RESET_POLICY_NOTES_ADD_ERROR:
        return { ...state, policyNotesToAddSuccess: null };

    case types.SET_POLICY_TO_ADD:
      return { ...state, policyNotesToAdd: action.payload };

    case types.RESET_POLICY_NOTE_TO_ADD:
      return { ...state, policyNotesToAdd: null };

      case types.GET_SMS_ACTIVITY_REQUEST:
        return { ...state, get_sms_activity_loader: true, get_sms_activity_errors: null };
      case types.GET_SMS_ACTIVITY_SUCCESS:
        return { ...state, get_sms_activity_loader: false, get_sms_activity_errors: null, smsActivities: action.payload };
      case types.GET_SMS_ACTIVITY_FAILURE:
        return { ...state, get_sms_activity_loader: false, get_sms_activity_errors: action.payload };
  
      case types.INITIATE_PAYMENT_REQUEST:
        return { ...state, initiatePaymentSuccess: false, initiatePaymentLoader: true, initiatePaymentError: null, };
      case types.INITIATE_PAYMENT_SUCCESS:
        return { ...state, initiatePaymentSuccess: true, initiatePaymentLoader: false, initiatePaymentError: null, };
  
      case types.INITIATE_PAYMENT_FAILURE:
        return { ...state, initiatePaymentSuccess: false, initiatePaymentLoader: false, initiatePaymentError: action.payload, };


      case types.RESET_INITIATE_PAYMENT:
        return { ...state, initiatePaymentSuccess: false, initiatePaymentLoader: false, initiatePaymentError: null };

    default:
      return state;
  }
}

