import React from 'react';
import ButtonInc from '../../../../../../shared-ui/ButtonInc';
import { COMPONENT_TYPES } from '../../../helpers';



export default function NavButtons({setProductType, currentComponent, setCurrentComponent, selectedPlan, setPlan, parentsPlan,  setParentsPlan, hasParentsPlan, coverType }) {




  const handleBack = () => {
    if (currentComponent === COMPONENT_TYPES.SELECT_PLAN) {
      setProductType('')
    }
    if (currentComponent === COMPONENT_TYPES.SELECT_RELATIONSHIP) {
      setCurrentComponent(COMPONENT_TYPES.SELECT_PLAN);
    }
  };


  return (
    <div style={{ minHeight: '15vh', position: 'relative' }}>

      <div style={{ position: 'absolute', bottom: '10px', right: '10px', display: 'flex' }}>
        {(currentComponent === COMPONENT_TYPES.SELECT_PLAN) &&
          <ButtonInc variant="contained" color="primary" onClick={handleBack} style={{ marginRight: '10px' }}>Back</ButtonInc>
        }
       
      </div>
    </div>
  );
}
