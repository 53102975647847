import { makeStyles } from "@material-ui/styles";

export const useStyle = makeStyles((LandingPageTheme) => ({

    logincontent: {
        textAlign: "center",
        padding: "30px",
        "& img":{
            marginTop: "30px"
        },
        "& h3": {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "24px !important",
            lineHeight: "32px",
            color: "#000000",
            [LandingPageTheme.breakpoints.down("md")]: {
                fontSize: "18px !important",
            }
        },

        "& p": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px !important",
            lineHeight: "15px",
            textAlign: "center",
            color: "#000000"
        },

    },
    input: {
        background: "#FFFFFF",
        boxShadow: "0px 6px 5px rgba(0, 0, 0, 0.15)",
        borderRadius: "20px",
        width: " 100%",
        height: "60px",
        marginBottom: "25px",
        fontFamily: "Open Sans",
        fontSize: "14px !important",
        paddingLeft: "20px",
        [LandingPageTheme.breakpoints.down("md")]: {
            height: "50px"
        }
    },
    loginbtn: {
        textAlign: "center",
        "& button": {
            background: "#FBA92D",
            borderRadius: "20px",
            width: "371px",
            height: "46px",
            color: "#fff",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "14px !important",
            lineHeight: "20px",
            letterSpacing: "0.001em",
            [LandingPageTheme.breakpoints.down("md")]: {
                width: "100%"
            },
            "&:hover": {
                background: "#FBA92D",
                color: "#fff",
            }
        }
    },
    forgotpassword: {
        textAlign: "center",
        marginBottom: "30px",
        marginTop: "30px",
        "& li": {
            fontFamily: "Open Sans",
            listStyle: "none",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px !important",
            lineHeight: "20px",
            letterSpacing: "0.001em",
            color: "#000000",
            cursor: "pointer"
        }
    },
    rememberme: {
        marginTop: "30px",
        "& input": {
            color: "#000",
            width: "35px",
            border: "0",
            boxShadow: "none",
            height: "20px",
            float: "left"
        },
        "& label": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px !important",
            lineHeight: "20px",
            letterSpacing: "0.001em",
            color: "#000000",
            float: "left",
            marginTop: "4px"
        }
    },
    backdroplogin: {
        backgroundColor: "rgba(3, 42, 55, 0.94)"
    },
    closebtnmodal: {
        float: "right",
        marginTop: " -40px",
        marginRight: "-20px",
        cursor: "pointer",
    },
    logintrigger: {
        fontFamily: 'Lato',
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px !important",
        lineHeight: "24px",
        letterSpacing: "0.0015em",
        color: " #FFFFFF",
    }
}));

