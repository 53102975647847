import * as types from '../../lib/actionTypes.es6';

export function deletePolicyRequest(policy) {
  return {
    type: types.DELETE_POLICY_REQUEST,
    payload: policy,
  };
}

export function deletePolicySuccess() {
  return {
    type: types.DELETE_POLICY_SUCCESS,
  };
}

export function deletePolicyFail(error) {
  return {
    type: types.DELETE_POLICY_FAIL,
    payload: error,
  };
}

export function getPolicyProductRequest(productGuid) {
  return {
    type: types.GET_POLICY_PRODUCT_REQUEST,
    payload: productGuid,
  };
}

export function getPolicyProductSuccess(response) {
  return {
    type: types.GET_POLICY_PRODUCT_SUCCESS,
    payload: response,
  };
}

export function getPolicyProductFailure(response) {
  return {
    type: types.GET_POLICY_PRODUCT_FAILURE,
    payload: response,
  };
}

export function initiatePolicyMobileMoneyPaymentRequest(payload) {
  return {
    type: types.INITIATE_POLICY_MOBILE_MONEY_PAYMENT_REQUEST,
    payload,
  };
}

export function initiatePolicyMobileMoneyPaymentSuccess(payload) {
  return {
    type: types.INITIATE_POLICY_MOBILE_MONEY_PAYMENT_SUCCESS,
    payload,
  };
}

export function initiatePolicyMobileMoneyPaymentFailure(payload) {
  return {
    type: types.INITIATE_POLICY_MOBILE_MONEY_PAYMENT_FAILURE,
    payload,
  };
}

export function resetMobileMoneyInitiation() {
  return {
    type: types.RESET_MOBILE_MONEY_INITIATION,
  };
}
