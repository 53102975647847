import React from 'react'
import { Link } from 'react-scroll'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import logo from '../../../../../Assets/logo.png'
import MobileNavigation from './MobileNavigation';
import Button from '@mui/material/Button';


//media query and breakpoints and MU theme imports
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const Navigation = (props) => {
    const classes = useStyle();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <section className={classes.navigation} id="back-to-top-anchor">
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3} md={3}>
                        <div className={classes.logo}>
                            <img src={logo} alt='logo' />
                        </div>
                    </Grid>
                    {
                        isMobile ? (<MobileNavigation login={props.login} loading={props.loading} loginError={props.loginError} forgetpassword={props.forgetpassword} language={props.language} updateGlobalLanguage={props.updateGlobalLanguage} labels={props.labels} toggleLoginPopup={props.toggleLoginPopup} />) : (<>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className={classes.navigationLink}>
                                    <ol>
                                        <li>
                                            <Link style={{ cursor: "pointer" }} to="partners" spy={true} smooth={true} offset={50} duration={500} >
                                                Partners
                                            </Link>
                                        </li>
                                        <li>
                                            <Link style={{ cursor: "pointer" }} to="features" spy={true} smooth={true} offset={50} duration={500} >
                                                Features
                                            </Link>
                                        </li>

                                        <li>
                                            <Link style={{ cursor: "pointer" }} to="products" spy={true} smooth={true} offset={50} duration={500} >
                                                Products
                                            </Link>
                                        </li>
                                        <li>
                                            <Link style={{ cursor: "pointer" }} to="testimonial" spy={true} smooth={true} offset={50} duration={500} >
                                                Testimonials
                                            </Link>
                                        </li>
                                        <li>
                                            <Link style={{ cursor: "pointer" }} to="developers" spy={true} smooth={true} offset={50} duration={500} >
                                                Developers
                                            </Link>
                                        </li>
                                    </ol>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={3} md={3}>
                                <div className={classes.navigationCta}>
                                    <ol>
                                        <li onClick={props.toggleLoginPopup} style={{ cursor: "pointer" }} className={classes.logintrigger}>Sign In</li>
                                    </ol>
                                    {/* <Link style={{ cursor: "pointer" }} to="features" spy={true} smooth={true} offset={50} duration={500} > */}
                                        <Button href='https://inclusivitysolutions.com/contact/' target='_blank' rel="noopener noreferrer">Schedule Demo</Button>
                                    {/* </Link> */}
                                </div>
                            </Grid>
                        </>)
                    }

                </Grid>
            </Container>
        </section>
    )
}

export default Navigation