import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  users: [],
  loader: false,
  error: null,
  registrationError: null,

  userCreated: null,
  createUserLoader: false,
  createUserSuccess: false,

  partners: [],
  partnersErrors: null,

  roles: [],
  roles_errors: null,
  roles_loader: false,

  currentroles: [],
  current_roles_errors: null,
  current_roles_loader: false,

  currentPartners: [],
  current_partner_errors: null,
  current_partner_loader: false,

  updateRolesAndPartnersErrors: null,
  updateRolesAndPartnersInfo: null,

  resetPasswordSuccess: null,
  resetPasswordErrors: null,


  deleteUserErrors: null,
  deleteUserSuccess: null,
  user: null,
  hardDeleteUserSuccess: false,

  hardDeleteUserLoader: false,
  userToDelete: null,
  editUserSuccess: false,
  UserToEdit: null,


};

export default function userAdminReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_USERS_REQUEST':
      return { ...state, error: null, loader: true, users: [] };

    case 'GET_USERS_SUCCESS':
      return { ...state, users: action.payload, loader: false };

    case 'GET_USERS_FAIL':
      return { ...state, loader: false, error: action.payload };

    case 'CREATE_USERS_REQUEST':
      return { ...state, registrationError: null, createUserLoader: true, createUserSuccess: false };

    case 'CREATE_USERS_SUCCESS':
      return { ...state, createUserLoader: false, userCreated: action.payload, createUserSuccess: true };

    case 'CREATE_USERS_FAIL':
      return { ...state, registrationError: action.payload, createUserLoader: false, createUserSuccess: false };

    case 'RESET_USERS_SUCCESS':
      return { ...state, createUserSuccess: false };

    case 'SET_USER_TO_DELETE':
      return { ...state, userToDelete: action.payload };

    case 'RESET_USER_TO_DELETE':
      return { ...state, userToDelete: null };

    case 'GET_PARTNERS_REQUEST':
      return state;

    case 'GET_PARTNERS_SUCCESS':
      return { ...state, partners: action.payload };

    case 'GET_PARTNERS_FAIL':
      return { ...state, partnersErrors: action.payload };

    case 'GET_ROLES_REQUEST':
      return { ...state, roles_errors: null, roles_loader: true, roles: [] };

    case 'GET_ROLES_SUCCESS':
      return { ...state, roles: action.payload, roles_loader: false, roles_errors: null };

    case 'GET_ROLES_FAIL':
      return { ...state, roles_loader: false, roles_errors: action.payload };


    case types.GET_CURRENT_ROLES_REQUEST:
      return { ...state, current_roles_errors: null, current_roles_loader: true, currentroles: [] };

    case types.GET_CURRENT_ROLES_SUCCESS:
      return { ...state, currentroles: action.payload, current_roles_loader: false, current_roles_errors: null };

    case types.GET_CURRENT_ROLES_FAIL:
      return { ...state, current_roles_loader: false, current_roles_errors: action.payload };

    case types.GET_CURRENT_PARTNER_REQUEST:
      return { ...state, current_partner_errors: null, current_partner_loader: true, currentPartners: [] };

    case types.GET_CURRENT_PARTNER_SUCCESS:
      return { ...state, currentPartners: action.payload, current_partner_loader: false, current_partner_errors: null };


    case types.UPDATE_ROLES_AND_PARTNERS_REQUEST:
      return {
        ...state,
        loader: true,
        updateRolesAndPartnersErrors: null,
        updateRolesAndPartnersInfo: null,
        editUserSuccess: false,
        userToEdit: action.payload
      };

    case types.UPDATE_ROLES_AND_PARTNERS_SUCCESS:
      return {
        ...state,
        updateRolesAndPartnersInfo: action.payload,
        loader: false,
        editUserSuccess: true,
      };

    case types.UPDATE_ROLES_AND_PARTNERS_FAILED:
      return {
        ...state,
        loader: false,
        updateRolesAndPartnersErrors: action.payload,
      };

    case 'RESET_USER_TO_UPDATE':
      return { ...state, userToEdit: null };

    case 'RESET_UPDATE_USER_SUCCESS':
      return { ...state, editUserSuccess: false, UserToEdit: null };

    case 'SET_USER_TO_UPDATE':
      return { ...state, userToEdit: action.payload };

    case 'RESET_UPDATE_COUNTRY_ERROR':
      return { ...state, updateRolesAndPartnersErrors: null };

    case types.DELETE_USER_REQUEST:
      return { ...state, loader: true, deleteUserErrors: null, deleteUserSuccess: null, userToDelete: action.payload, hardDeleteUserLoader: true, hardDeleteUserSuccess: false, hardDeleteUserError: null }

    case types.DELETE_USER_FAILED:
      return { ...state, loader: false, deleteUserErrors: action.payload, deleteUserSuccess: null, hardDeleteUserLoader: false }

    case types.DELETE_USER_SUCCESS:
      return { ...state, loader: false, deleteUserErrors: null, deleteUserSuccess: action.payload, hardDeleteUserLoader: false, hardDeleteUserSuccess: true }

    case 'RESET_DELETE_USER_SUCCESS':
      return { ...state, hardDeleteUserSuccess: false };

    case 'GET_USER_REQUEST':
      return { ...state, loader: true, error: null, user: null }

    case 'GET_USER_FAILED':
      return { ...state, loader: false, error: action.payload, user: null }

    case 'GET_USER_SUCCESS':
      return { ...state, loader: false, error: null, user: action.payload }

    case types.RESET_USER_PASSWORD_REQUEST:
      return { ...state, loader: true, resetPasswordErrors: null, resetPasswordSuccess: null };

    case types.RESET_USER_PASSWORD_SUCCESS:
      return { ...state, loader: false, resetPasswordSuccess: action.payload };

    case types.RESET_USER_PASSWORD_FAILED:
      return { ...state, loader: false, resetPasswordErrors: action.payload };

    case types.USER_LOGOUT:
      return initialState;
    case types.RESET_USER_CONTAINER:
      return {
        ...state, loader: false, registrationError: null,
        roles_errors: null,
        error: null,
        updateRolesAndPartnersErrors: null,
        resetPasswordErrors: null,
        updateRolesAndPartnersInfo: null,
        userCreated: null,
        deleteUserErrors: null,
        deleteUserSuccess: null,
        resetPasswordSuccess: null,
      }

    default:
      return state;
  }
}
