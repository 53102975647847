import {call, put, take} from "redux-saga/effects";
import * as types from "../../lib/actionTypes.es6";
import {
  addCustomerDocument,
  deleteCustomerDocument, getCustomerDocument,
  getCustomerDocuments,
  updateCustomerDocument
} from "../../lib/requests.es6";
import * as actions from "./actions.es6";
import {unAuthorizedNotification} from "../../lib/access.es6";
import {GAloggerException} from "../../lib/monitoring.es6";

export function* getCustomerDocumentsRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.GET_CUSTOMER_DOCUMENTS_REQUEST);
      const response = yield call(getCustomerDocuments, payload);
      yield put(actions.getCustomerDocumentsSuccess(response.data));
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.getCustomerDocumentsFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* getCustomerDocumentRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.GET_CUSTOMER_DOCUMENT_REQUEST);

      const response = yield call(getCustomerDocument, payload);
      yield put(actions.getCustomerDocumentSuccess(response.data));
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.getCustomerDocumentFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* addCustomerDocumentRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.ADD_CUSTOMER_DOCUMENT_REQUEST);

      const response = yield call(addCustomerDocument, payload);
      yield put(actions.addCustomerDocumentSuccess(response.data));
      yield put(actions.getCustomerDocumentsRequest(payload.customer_guid));
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.addCustomerDocumentFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* updateCustomerDocumentRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.UPDATE_CUSTOMER_DOCUMENT_REQUEST);

      const response = yield call(updateCustomerDocument, payload);
      yield put(actions.updateCustomerDocumentSuccess(response.data));
      yield put(actions.getCustomerDocumentsRequest(payload.customer_guid));
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.updateCustomerDocumentFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* deleteCustomerDocumentRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.DELETE_CUSTOMER_DOCUMENT_REQUEST);

      const response = yield call(deleteCustomerDocument, payload);
      yield put(actions.deleteCustomerDocumentSuccess(response.data));
      yield put(actions.getCustomerDocumentsRequest(payload.customer_guid));
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.deleteCustomerDocumentFailed(errorPayload));
      GAloggerException(e);
    }
  }
}
