import React from 'react';

export default class Settings extends React.Component {
  render() {
    return (
      <div>
        <center>
          <h3> coming soon </h3>
        </center>
      </div>
    );
  }
}
