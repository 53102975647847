import React, { Component } from 'react';
import DisplayDebitOrders from './DisplayDebitOrders';
import ProductsHeader from '../../../NewProductList/components/ProductsHeader/ProductsHeader';
import AddDebitOrder from './AddDebitOrder';
import EditDebitOrder from './EditDebitOrder';
import DeleteDebitOrder from './DeleteDebitOrder';
import { getPartner } from '../../../../lib/access.es6';
import * as actions from './state/actions.js';
import * as statusActions from '../../actions.es6';
export default class DebitOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openAddDebitOrderDialog: false,
    };
  }

  componentWillMount() {
    this.getAllDebitOrders();
    this.props.dispatch(actions.getAllBanksRequest());
    this.props.dispatch(
      statusActions.getAllPoliciesRequest(
        this.props.currentCustomerData.currentCustomer.guid
      )
    );
  }

  getAllDebitOrders() {
    const year = new Date().getFullYear();
    const month = new Date().getUTCMonth() + 1;
    const day = new Date().getDate() + 1;
    const currentDate = `${year}-${month}-${day}`;
    const payload = {
      msisdn: this.props.currentCustomerData.currentCustomer.msisdn,
      status: 'Active',
      from: '2000-01-1',
      to: currentDate,
    };

    this.props.dispatch(actions.getDebitOrderzRequest(payload));
  }

  editDebitOrder(debitOrder) {
    this.props.dispatch(actions.editDebitOrderzRequest(debitOrder));
  }

  setDebitOrderToEdit(debitOrder) {
    this.props.dispatch(actions.setDebitOrderzToEdit(debitOrder));
  }

  setDebitOrderToDelete(debitOrder) {
    this.props.dispatch(actions.setDebitOrderzToDelete(debitOrder));
  }

  resetDebitOrderToEdit(debitOrder) {
    this.props.dispatch(actions.resetDebitOrderzToEdit(debitOrder));
  }

  resetDebitOrderToDelete(debitOrder) {
    this.props.dispatch(actions.resetDebitOrderzToDelete(debitOrder));
  }

  deleteDebitOrder(debitOrder) {
    this.props.dispatch(actions.hardDeleteDebitOrderzRequest(debitOrder));
  }

  openAddDebitOrderDialog() {
    this.setState({ openAddDebitOrderDialog: true });
  }

  closeAddDebitOrderDialog() {
    this.setState({ openAddDebitOrderDialog: false });
  }

  addDebitOrder(benefit) {
    this.props.dispatch(actions.createDebitOrderzRequest(benefit));
  }

  getTotalPayablePremium(policyGuid) {
    this.props.dispatch(
      statusActions.getTotalPayablePremiumRequest(policyGuid)
    );
  }

  // refreshProduct() {
  //   this.props.dispatch(getProductRequest(this.state.productGuid))
  // }

  render() {
    const currencyCode = getPartner().country.currency_code;

    return (
      <div>
        <div style={{ marginTop: '0%', marginBottom: '6%' }}>
          <ProductsHeader
            title="Debit orders"
            showAddButton
            onAddButtonClick={this.openAddDebitOrderDialog.bind(this)}
          />
          <DisplayDebitOrders
            debitOrders={this.props.newDebitOrderData.debitOrders}
            displayEditButton
            displayDeleteButton
            hardDeleteDebitOrder={this.setDebitOrderToDelete.bind(this)}
            setDebitOrderToEdit={this.setDebitOrderToEdit.bind(this)}
            currencyCode={currencyCode}
          />

          <AddDebitOrder
            openAddDebitOrderDialog={this.state.openAddDebitOrderDialog}
            closeAddDebitOrderDialog={this.closeAddDebitOrderDialog.bind(this)}
            addDebitOrder={this.addDebitOrder.bind(this)}
            showAddDebitOrderProgressAlert={
              this.props.newDebitOrderData.createDebitOrderLoader
            }
            showAddDebitOrderSuccessAlert={
              this.props.newDebitOrderData.createDebitOrderSuccess
            }
            showAddDebitOrderErrorAlert={
              !!this.props.newDebitOrderData.createDebitOrderError
            }
            createDebitOrderError={
              this.props.newDebitOrderData.createDebitOrderError
            }
            resetAddDebitOrderSuccessAlert={() => {
              this.props.dispatch(actions.resetCreateDebitOrderzSuccess());
            }}
            resetAddDebitOrderErrorAlert={() => {
              this.props.dispatch(actions.resetCreateDebitOrderzError());
            }}
            createdDebitOrders={this.props.newDebitOrderData.createdDebitOrders}
            product={this.props.newDebitOrderData.currentProduct}
            getAllDebitOrders={this.getAllDebitOrders.bind(this)}
            currencyCode={currencyCode}
            policies={this.props.policies}
            products={this.props.products}
            msisdn={this.props.currentCustomerData.currentCustomer.msisdn}
            banks={this.props.newDebitOrderData.banks}
            totalPayablePremium={this.props.totalPayablePremium}
            getTotalPayablePremium={this.getTotalPayablePremium.bind(this)}
          />

          <EditDebitOrder
            debitOrder={this.props.newDebitOrderData.debitOrderToEdit}
            editDebitOrder={this.editDebitOrder.bind(this)}
            resetDebitOrderToEdit={this.resetDebitOrderToEdit.bind(this)}
            showEditDebitOrderProgressAlert={
              this.props.newDebitOrderData.editDebitOrderLoader
            }
            showEditDebitOrderSuccessAlert={
              !!this.props.newDebitOrderData.editDebitOrderSuccess
            }
            showEditDebitOrderErrorAlert={
              !!this.props.newDebitOrderData.editDebitOrderError
            }
            editDebitOrderError={
              this.props.newDebitOrderData.editDebitOrderError
            }
            resetEditDebitOrderSuccessAlert={() => {
              this.props.dispatch(actions.resetEditDebitOrderzSuccess());
            }}
            resetEditDebitOrderErrorAlert={() => {
              this.props.dispatch(actions.resetEditDebitOrderzError());
            }}
            banks={this.props.newDebitOrderData.banks}
            currencyCode={currencyCode}
            policies={this.props.policies}
            products={this.props.products}
            totalPayablePremium={this.props.totalPayablePremium}
            getTotalPayablePremium={this.getTotalPayablePremium.bind(this)}
          />

          <DeleteDebitOrder
            debitOrder={this.props.newDebitOrderData.debitOrderToDelete}
            deleteDebitOrder={this.deleteDebitOrder.bind(this)}
            resetDebitOrderToDelete={this.resetDebitOrderToDelete.bind(this)}
            showDeleteDebitOrderProgressAlert={
              this.props.newDebitOrderData.hardDeleteDebitOrderLoader
            }
            showDeleteDebitOrderSuccessAlert={
              this.props.newDebitOrderData.hardDeleteDebitOrderSuccess
            }
            showDeleteDebitOrderErrorAlert={
              !!this.props.newDebitOrderData.hardDeleteDebitOrderError
            }
            deleteDebitOrderError={
              this.props.newDebitOrderData.hardDeleteDebitOrderError
            }
            resetDeleteDebitOrderSuccessAlert={() => {
              this.props.dispatch(actions.resetHardDeleteDebitOrderzSuccess());
            }}
            resetDeleteDebitOrderErrorAlert={() => {
              this.props.dispatch(actions.resetHardDeleteDebitOrderzError());
            }}
          />
        </div>
      </div>
    );
  }
}
