import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import './DependantsTable.css'
import { trimTimestampFromDate } from "../../lib/utils.es6";
import {PRIMARY_THEME_COLOR_SHADE_1, PRIMARY_THEME_COLOR_SHADE_2} from "../../lib/constants";

export default function DependantsTable(props) {
  if (props.dependants && props.dependants.length > 0) {
    return (
      <>
        <div style={{ marginTop: "0%" }}>
          {props.title && <div className="page-title">
            <div className="title_left" style={{ color: PRIMARY_THEME_COLOR_SHADE_2 }}>
              <h4>{props.title}</h4>
            </div>
          </div>}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ color: 'red' }}>
                <TableRow >
                  <TableCell className="table-cell-header-dep">{props.labels.createdAt}</TableCell>
                  <TableCell className="table-cell-header-dep">{props.labels.firstName}</TableCell>
                  <TableCell className="table-cell-header-dep">{props.labels.lastName}</TableCell>
                  <TableCell className="table-cell-header-dep">{props.labels.relationship}</TableCell>
                  <TableCell className="table-cell-header-dep">{props.labels.dateOfBirth}</TableCell>
                  <TableCell className="table-cell-header-dep">{props.labels.gender}</TableCell>
                  <TableCell className="table-cell-header-dep">{props.labels.telephone}</TableCell>
                  <TableCell className="table-cell-header-dep">{props.labels.action}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.dependants.map((dependant) => {
                  return (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{trimTimestampFromDate(dependant.created_at)}</TableCell>
                      <TableCell>{dependant.first_name}</TableCell>
                      <TableCell>{dependant.last_name}</TableCell>
                      <TableCell>{props.labels[dependant.relationship]}</TableCell>
                      <TableCell>{dependant.date_of_birth ? trimTimestampFromDate(dependant.date_of_birth) : ''}</TableCell>
                      <TableCell>{props.labels[dependant.gender]}</TableCell>
                      <TableCell>{dependant.msisdn}</TableCell>

                      <TableCell align="right">
                        <Stack direction="row" spacing={1} justifyContent="flex-end" >
                          <IconButton onClick={() => { props.setEditDependant(dependant) }} >
                            <EditIcon
                              style={{ color: PRIMARY_THEME_COLOR_SHADE_1, fontWeight: 'bolder', display: props.displayEditButton ? 'inline-block' : 'none' }}
                            />
                          </IconButton>
                          <IconButton onClick={() => { props.setDeleteDialog(dependant) }} >
                            <DeleteForeverIcon
                              style={{ color: 'red', fontWeight: 'bolder', marginRight: "2%", display: props.displayDeleteButton ? 'inline-block' : 'none' }}
                            />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
    );
  }
  return "No dependants found, click on the add button to add new dependant."
}
