import GAlogger, { monitoringEventCategories } from '../../lib/monitoring.es6';
import * as types from '../../lib/actionTypes.es6';

export function purchasePolicyRequest(purchasePolicyData) {
  return {
    type: types.PURCHASE_POLICY_REQUEST,
    payload: purchasePolicyData,
  };
}

export function purchasePolicySuccess(policyInfo) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.purchasePolicy);

  return {
    type: types.PURCHASE_POLICY_SUCCESS,
    payload: policyInfo,
  };
}

export function purchasePolicyFail(response) {
  return {
    type: types.PURCHASE_POLICY_FAILURE,
    payload: response,
  };
}

export function listPaidProductsRequest() {
  return {
    type: types.PAID_PRODUCT_LIST_REQUEST,
  };
}

export function listPaidProductsSuccess(response) {
  return {
    type: types.PAID_PRODUCT_LIST_SUCCESS,
    payload: response,
  };
}

export function listPaidProductsFail(response) {
  return {
    type: types.PAID_PRODUCT_LIST_FAIL,
    payload: response,
  };
}

export function resetPolicyPurchase() {
  return {
    type: types.PURCHASE_POLICY_RESET,
  };
}