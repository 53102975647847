import * as types from "../../../../../../lib/actionTypes.es6";


export function getOrangeCustomerDocumentsRequest(payload) {
  return {
    type: types.GET_ORANGE_CUSTOMER_DOCUMENTS_REQUEST,
    payload,
  };
}

export function getOrangeCustomerDocumentsSuccess(response) {
  return {
    type: types.GET_ORANGE_CUSTOMER_DOCUMENTS_SUCCESS,
    payload: response,
  };
}

export function getOrangeCustomerDocumentsFailed(error) {
  return {
    type: types.GET_ORANGE_CUSTOMER_DOCUMENTS_FAILURE,
    payload: error,
  };
}

export function addOrangeCustomerDocumentRequest(payload) {
  return {
    type: types.ADD_ORANGE_CUSTOMER_DOCUMENT_REQUEST,
    payload,
  };
}


export function addOrangeCustomerDocumentSuccess(response) {
  return {
    type: types.ADD_ORANGE_CUSTOMER_DOCUMENT_SUCCESS,
    payload: response,
  };
}

export function addOrangeCustomerDocumentFailed(error) {
  return {
    type: types.ADD_ORANGE_CUSTOMER_DOCUMENT_FAILURE,
    payload: error,
  };
}


export function deleteOrangeCustomerDocumentRequest(payload) {
  return {
    type: types.DELETE_ORANGE_CUSTOMER_DOCUMENT_REQUEST,
    payload,
  };
}

export function deleteOrangeCustomerDocumentSuccess(response) {
  return {
    type: types.DELETE_ORANGE_CUSTOMER_DOCUMENT_SUCCESS,
    payload: response,
  };
}

export function deleteOrangeCustomerDocumentFailed(error) {
  return {
    type: types.DELETE_ORANGE_CUSTOMER_DOCUMENT_FAILURE,
    payload: error,
  };
}

export function setOrangeCustomerDocumentsToDelete(response) {
  return {
    type: types.SET_ORANGE_CUSTOMER_DOCUMENT_TO_DELETE,
    payload: response,
  };
}

export function resetHardDeleteOrangeCustomerDocumentSuccess() {
  return {
    type: types.RESET_DELETE_ORANGE_CUSTOMER_DOCUMENT_SUCCESS,
  };
}

export function resetOrangeDocumentToDelete() {
  return {
    type: types.RESET_ORANGE_CUSTOMER_DOCUMENT_TO_DELETE,
  };
}
export function resetHardDeleteOrangeCustomerDocumentError() {
  return {
    type: types.RESET_ORANGE_CUSTOMER_DELETE_DOCUMENT_ERROR,
  };
}