import React from 'react'
import { Box, Grid } from '@mui/material';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { useStyle } from "./Style";
import SkeletonWrapper from '../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import { getPartner, hasAuthourity } from '../../../../../lib/access.es6';
import { formatDateLocale } from '../../../../../lib/utils.es6';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ITEM_HEIGHT = 48;

const PolicyDetails = (props) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedPolicyAction, setSelectedPolicyAction] = React.useState(null)

  const open = Boolean(anchorEl);

  const handleClick = (event, policy) => {
    setAnchorEl(event.currentTarget);
    setSelectedPolicyAction(policy);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyle();


  const currencyCode = getPartner().country.currency_code

  if (!props.policy) {
    return <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
        <Grid container spacing={2} className={classes.claimdetailswrapper}>
          <Grid item xs={12} sm={12} md={12} className={classes.ClaimDetailscontent}>
            <p style={{ textAlign: 'center' }}>No details found</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }

  if (props.loader) {
    return <SkeletonWrapper loading={props.loader} />
  }
  return (
    <>
      <Grid container className={classes.policyWrapper} >
        <Grid item xs={12} sm={12} md={12}>
          <Grid container className={classes.policyHeader}>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container>
                <Grid item xs={12} md={2} sm={2} className={classes.policyIconWrapper}   >
                  <Box className={classes.policyIcons}>
                    <NoteAddOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={10} sm={10} className={classes.policyHeaderContent}>
                  <h6>{props.policy.policy_number || "N/A"}</h6>
                  <p> Policy Number</p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} >

              <Grid container>
                <Grid item xs={12} md={6} sm={6}>
                  <div >

                  </div>
                </Grid>
                <Grid item xs={12} md={6} sm={6} className={classes.policyActiveStatusWrapper}>
                  <Box display="flex" justifyContent="flex-end">
                    <ul>
                      <li className={props.policy.active === true ? classes.activePill : classes.inactivePill}> <span>{props.policy.active === true ? `Active` : `Inactive`}</span></li>
                         <li className={classes.actionbtnpolicy} style={{display: "none"}}>
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? 'long-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={(event) => handleClick(event, props.policy)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              {
                                selectedPolicyAction && selectedPolicyAction.guid === props.policy.guid && <Menu
                                  id="long-menu"
                                  MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                  }}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  PaperProps={{
                                    style: {
                                      maxHeight: ITEM_HEIGHT * 4.5,
                                      width: '20ch',
                                    },
                                  }}
                                >


                                  {/* {
                                    !["Non_Payment", "Cancelled", "Rejected", "Un_Confirmed"].includes(props.policy.status) && <MenuItem style={{
                                      cursor: "pointer", fontFamily: "Open Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      lineHeight: "15px",
                                      color: "#000000",
                                       display: hasAuthourity('CAPTURE_POLICY') ? 'block' : 'none'
                                    
                                    }} onClick={() => {
                                      setAnchorEl(null);
                                    }} >Upgrade / Downgrade</MenuItem>
                                  } */}

                                  {!["Non_Payment", "Cancelled", "Rejected", "Un_Confirmed"].includes(props.policy.status)
                                    && <MenuItem style={{
                                      cursor: "pointer", fontFamily: "Open Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      lineHeight: "15px",
                                      color: "#000000",
                                      display: hasAuthourity('CAPTURE_POLICY') ? 'block' : 'none'
                                    }} onClick={() => { setAnchorEl(null); props.toggleOpenCancelPolicyDialog() }} >Cancel Policy</MenuItem>}
                                  <MenuItem style={{
                                    cursor: "pointer", fontFamily: "Open Sans",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    lineHeight: "15px",
                                    color: "#000000",
                                  }} onClick={() => { setAnchorEl(null); props.setPolicyToDownloadDocumentFor(props.policy) }} >Download Policy Document</MenuItem>
                             
                                </Menu>
                              }

                            </li> 
                    </ul>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.policyHeaderBorder}></div>
          </Grid>

          <Grid container spacing={2} className={classes.policyContentWrapper}>
            <Grid item xs={12} sm={3} md={3} >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3}></Grid>
                <Grid item xs={12} sm={9} md={9}>
                  <p>Plan </p>
                  <h6> {props.policy.product_name || "N/A"}</h6>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <p> Start Date</p>
              <h6>{formatDateLocale(props.policy.start_date) || "N/A"}
              </h6>

            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <p>Due Date</p>
              <h6>{formatDateLocale(props.policy.due_date) || "N/A"}</h6>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <p>Premiums</p>
              <h6>{
                (props.policy.quotation && props.policy.quotation.total_premium_amount_in_cents)
                  ? `${currencyCode}  ${props.policy.quotation.total_premium_amount_in_cents / 100} per ${props.policy.quotation.premium_cardinality} ${props.policy.quotation.premium_granularity}` || "0.00"

                  : "N/A"
              }</h6>
            </Grid>

          </Grid>
          <div className={classes.policyContentBorder}></div>
          <Grid container spacing={2} className={classes.policyFooterWrapper}>
            <Grid item xs={12} md={6} sm={6}>
              <Grid container spacing={2} >


                <Grid item xs={12} md={6} sm={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} md={3}></Grid>
                    <Grid item xs={12} sm={9} md={9}>
                      <p>status</p>
                      <h6>{props.policy.status || "N/A"}</h6>

                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <p> channel</p>
                  <h6>{props.policy.channel || "N/A"}</h6>
                </Grid>

              </Grid>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <div className={classes.policyBalance}>
                <p>Balance</p>
                <h6>{`${currencyCode}  ${props.policy.balance_amount_in_cents / 100}` || "0.00"}    </h6>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </>
  )
}

export default PolicyDetails