import { take, call, put } from 'redux-saga/effects';
import {
  getCurrentProfile,
  updateCurrentProfile,
} from '../../lib/requests.es6';
import * as actions from './actions.es6';
import * as types from '../../lib/actionTypes.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';
import { getUsersRequest } from '../Users/actions.es6';

export function* profileRequestWatcher() {
  for (;;) {
    // TODO: There should be no need of an infinite loop here. We can listen for a specific action
    const request = yield take(types.CURRENT_PROFILE_REQUEST);
    try {
      const response = yield call(getCurrentProfile, request.payload.guid);
      yield put(actions.currentProfileSuccess(response.data));
    } catch (e) {
      yield put(actions.currentProfileFail('Something went wrong'));
    }
  }
}

export function* profileUpdateRequestWatcher() {
  for (;;) {
    // TODO: There should be no need of an infinite loop here. We can listen for a specific action
    const request = yield take(types.CURRENT_PROFILE_UPDATE_REQUEST);
    try {
      const response = yield call(updateCurrentProfile, request.payload);
      yield put(actions.currentProfileUpdateSuccess(response.data));
      yield put(getUsersRequest());
    } catch (e) {
      // console.log("error message object", e.response.data);
      yield put(actions.currentProfileUpdateFail('Something went wrong'));
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
