import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import { hasAuthourity } from '../../../../../lib/access.es6';
import { ClaimActionEnum } from '../../../lib/enum';
const Approved = (props) => {
  const classes = useStyle();

  const handleApprovePaidClaim = () =>{
    props.submitClaimAction({
      claim_action: ClaimActionEnum.approve_paid,
      claim_guid: props.claim.guid,
    })
  }
  return (
    <section className={classes.ClaimActionButtons}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ButtonInc onClick={handleApprovePaidClaim}  hasPermision={hasAuthourity('APPROVE_PAID_CLAIM')} loading={props.loading} variant="contained" style={{ width: "221px", height: "55px", float: "left", marginRight: "20px" }}>Mark as Approved Paid</ButtonInc>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
          <p style={{color: 'red'}}>{props.error ? props.error.message : ''}</p>
        </Grid>
    </section>
  )
}

export default Approved