import * as types from '../../../../lib/actionTypes.es6'

export const initialState = {
  errors: null,
  loader: false,

  get_payments_dcp_errors: null,
  get_payments_dcp_loader: false,
  get_payments_dcp_success_message: '',
  payments_dcp: [],



};

export default function paymentsDashboardReducer(state = initialState, action) {
  switch (action.type) {

    case types.GET_PAYMENTS_DCP_REQUEST:
      return { ...state, get_payments_dcp_loader: true, get_payments_dcp_errors: null,  payments_dcp: [] };
    case types.GET_PAYMENTS_DCP_SUCCESS:
      return { ...state, get_payments_dcp_loader: false, get_payments_dcp_errors: null, payments_dcp: action.payload };
    case types.GET_PAYMENTS_DCP_FAILURE:
      return { ...state, get_payments_dcp_loader: false, get_payments_dcp_errors: action.payload };


    default:
      return state;
  }
}
