import * as types from '../../../lib/actionTypes.es6';

export const initialState = {
  selectedItem: 'dashboard',
  selectedParentID: '',
  drawerOpen: true
};

export default function drawerReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_SELECTED_DRAWER_ITEM:
      return { ...state, selectedItem: action.payload };

      case types.SET_SELECTED_DRAWER_ID:
      return { ...state, selectedParentID: action.payload };

      case types.SET_DRAWER_OPEN:
      return { ...state, drawerOpen: !state.drawerOpen };
    default:
      return state;
  }
}
