import * as types from '../../lib/actionTypes.es6';
import GAlogger, { monitoringEventCategories } from '../../lib/monitoring.es6';

export function resetRegistration() {
  return {
    type: 'RESET_REGISTRATION',
  };
}

export function registerCustomerRequest(customerData) {
  return {
    type: types.REGISTER_CUSTOMER_REQUEST,
    payload: customerData,
  };
}

export function registerCustomerSuccess(customerInfo) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.registerCustomer);
  return {
    type: types.REGISTER_CUSTOMER_SUCCESS,
    payload: customerInfo,
  };
}

export function registerCustomerFail(response) {
  return {
    type: types.REGISTER_CUSTOMER_FAIL,
    payload: response,
  };
}

export function displayError(message) {
  return {
    type: types.SET_REGISTRATION_ERROR,
    payload: message,
  };
}
