import { makeStyles } from "@material-ui/styles";
import banner from '../../../../../Assets/banner-img.png';
import bannerMobile from '../../../../../Assets/banner-mobile.png'

export const useStyle = makeStyles((LandingPageTheme) => ({
    bannerwrapper:{
        width: "70%",
        margin: "auto"
    },
    bannerImg: {
        backgroundImage: `url(${banner})`,
        height: "500px",
        paddingBottom: "50px",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        [LandingPageTheme.breakpoints.down("md")]:{
            backgroundImage: `url(${bannerMobile})`,
        }

    },
    getstartedwrapper: {
        background: "#fff",
        borderRadius: "15px",
        textAlign: "center",
        padding: "30px 0 30px 0",
        marginTop: "40px",
        boxShadow: "0px 0px 12px 2px rgb(0 0 0 / 7%)",
        "& p": {
            marginBottom: "30px"
        },
        "& form": {
            "& input": {
                border: "1px solid #000",
                width: "60%",
                marginBottom: "20px",
                padding: "10px",
                [LandingPageTheme.breakpoints.down("md")]:{
                    width: "80%",
                }
            },
            "& button": {
                backgroundColor: " #FBA92D",
                border: "0",
                color: "#fff",
                width: "40%",
                padding: "10px 0 10px 0",
                borderRadius: "20px"
            }
        }
    },
    bannertext: {
        marginTop: "85px",
        "& h1": {
            color: "#fff",
            fontFamily: 'Lato',
            fontStyle: "normal",
            fontWeight: "900",
            fontSize: "45px",
            lineHeight: "52px",
            textAlign: "center",
            [LandingPageTheme.breakpoints.down("md")]: {
                fontSize: "22px",
                lineHeight: "35px",
                textAlign: "center",
                fontWeight: "700",
            },
        },
        "& p": {
            fontFamily: "Open Sans",
            color: "#fff",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "30px",
            letterSpacing: "0.005em",
            textAlign: "center",
            marginTop: "30px",
            [LandingPageTheme.breakpoints.down("md")]: {
                fontSize: "12px",
                lineHeight: "24px",
                textAlign: "center",
                paddingRight: "0",
            }
        }
    },
    watchvideo: {
        marginTop: "20px",
        textAlign: "end",
        "& button": {
            border: "1px solid #FBA92D",
            color: "#fff",
            borderRadius: "8px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            boxShadow: "none",
            padding: "15px 40px 15px 40px",
            textTransform: "capitalize",
            marginTop: "10px",
            "&:hover":{
                background: "#173E4B",
                boxShadow: "none",
            },
        },
        [LandingPageTheme.breakpoints.down("md")]:{
            textAlign: "center"
        },
        "& span": {
            "&:nth-child(1)": {
                float: "left",
                marginTop: "-20px",
                cursor: "pointer"
            },
            "&:nth-child(2)": {
                fontFamily: 'Open Sans',
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                textAlign: "justify",
                letterSpacing: "0.005em",
                color: "#FFFFFF",
                cursor: "pointer",
                marginLeft: "20px"
            }
        },

    },
    scheduledemo:{
        marginTop: "20px",
        "& a": {
            background: "#FBA92D",
            borderRadius: "8px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            boxShadow: "none",
            padding: "15px 40px 15px 40px",
            textTransform: "capitalize",
            marginTop: "10px",
            "&:hover":{
                background: "#173E4B",
                color: "#fff",
                boxShadow: "none",
            },
            "& a":{
                fontFamily: 'Open Sans',
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                letterSpacing: "0.005em",
                color: "#FFFFFF",
            }
        },
        [LandingPageTheme.breakpoints.down("md")]:{
            textAlign: "center",
            marginTop: "0",
        },
    }

}));

