import { makeStyles } from "@material-ui/styles";
export const useStyle = makeStyles(() => ({


    dropdownpartners: {
        position: "relative"
    },
    selectpartner: {
        color: "#000",
        marginLeft: "20px",
        fontSize: "14px",
        display: "block",
        width: "auto",
        textAlign: "center",
        textTransform: "capitalize",
        marginTop: "25px",
        "& ul": {
            display: "flex",
            "& li": {
                display: "inline-block"
            },
        },

    },
    partnerlist: {
        "& ul": {
            "& li": {
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "0.001em",
                color: "#060606",
            }
        }
    }

}));

