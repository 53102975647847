import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";

const Viewpolicy = (props) => {
  const classes = useStyle();
  if (props.buyPolicyData) {
    return (
      <>
        <Grid container spacing={2} className={classes.viewpolicywrapper}>
          <Grid item xs={12} sm={12} md={12} >
            <table className={classes.policywrap}>
              <thead className={classes.policyhead}>
                <tr>
                  <th colspan="2" >Kinga Ya Mkulima</th>
                </tr>

              </thead>
              <tbody>
                <tr>
                  <td>Beneficiary Name</td>
                  <td>{props.buyPolicyData.beneficiary_name || "N/A"}</td>
                </tr>
                <tr>
                  <td>Grower Number</td>
                  <td>{props.buyPolicyData.policy_number || "N/A" }</td>
                </tr>
                <tr>
                  <td>Phone Number</td>
                  <td>{props.buyPolicyData.beneficiary_msisdn || "N/A"}</td>
                </tr>
                <tr>
                  <td>Start Date</td>
                  <td>{props.buyPolicyData.start_date || "N/A"}</td>
                </tr>
                <tr>
                  <td>End Date</td>
                  <td>{props.buyPolicyData.end_date || "N/A"}</td>
                </tr>
                <tr>
                  <td>Due Date</td>
                  <td>{props.buyPolicyData.due_date || "N/A"}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{props.buyPolicyData.status || "Unpaid"}</td>
                </tr>
                <tr>
                  <td>Active</td>
                  <td>{props.buyPolicyData.active === "true" ? "Active" : "Inactive"}</td>
                </tr>
                <tr>
                  <td>Total Amount</td>
                  <td>{props.buyPolicyData.total_paid_amount_in_cents || "N/A"}</td>
                </tr>
                <tr>
                  <td>Debit</td>
                  <td>{props.buyPolicyData.debit === "true" ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>Product Cover Type </td>
                  <td>
                    <ul>
                      <li>Daily Hospitalization</li>
                      <li>Medical Surgical</li>
                      <li>Death Benefit</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Created At</td>
                  <td>{props.buyPolicyData.updated_at}</td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
      </>
    )
  } else {
    return (
      <>
        <Grid container spacing={2} className={classes.viewpolicywrapper}>
          <Grid item xs={12} sm={6} md={6} >
            <p>No Policy found</p>
          </Grid>
        </Grid>
      </>
    )

  }

}

export default Viewpolicy