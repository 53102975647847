import React from 'react';
import {connect} from 'react-redux';
import {Button} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-table/react-table.css';
import LocalizedStrings from 'react-localization';
import Loader from 'react-loader';
import MainPageTitle from '../../components/MainPageTitle/index.es6';
import AlertDialog from '../../components/AlertDialog/index.es6';
import * as globalActions from '../Main/actions.es6';
import {updateCurrentLanguage} from '../../lib/languageUtils.es6';
import {localisedText} from '../../lib/localisation.es6';
import * as utils from '../../lib/utils.es6';
import {
  addRemittanceReset,
  addRemittancesRequest,
} from './actions.es6';
import {getPartnerGuid} from '../../lib/access.es6';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import { SECONDARY_THEME_COLOR_SHADE_1, SECONDARY_THEME_COLOR_SHADE_2 } from '../../lib/constants';

const btnStyle = {
  margin: '2%',
  secondary_2: {
    backgroundColor: SECONDARY_THEME_COLOR_SHADE_2,
    color: 'white',
    margin: '2px',
    height: '50px',
    padding: '0 30px',
  },
  secondary: {
    backgroundColor: SECONDARY_THEME_COLOR_SHADE_1,
    color: 'white',
    margin: '2px',
    height: '50px',
    padding: '0 30px',
  },
};

export class ManualRemittances extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
      file: null,
      errorList: null,
      fileInputKey: Date.now(),
      resetAfterSuccess: false,
      submitted:false,
      cancelled: false,
    };

    this.onFileSelect = this.onFileSelect.bind(this);
    this.downloadCSVTemplate = this.downloadCSVTemplate.bind(this);
    this.addRemittances = this.addRemittances.bind(this);
    this.dowloadFile = this.dowloadFile.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  componentWillMount() {
    this.updateLanguage();
    this.props.dispatch(globalActions.hideCustomerBar());
    this.props.dispatch(addRemittanceReset());
  }

  componentDidUpdate() {
    this.updateLanguage();
    if(this.props.remittances.success_single_payment){
      this.resetAfterSuccess()
    }
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'ManualRemittances',
      this.state.labels
    );
  }

  onFileSelect(event) {
    this.setState({
      file: event.target.files[0],
      isSubmitDisabled: false,
    });
  }

  cancel(){
    this.setState({
      cancelled: true,
    });
  }

  addRemittances() {
    this.setState({ submitted:true });

    if (!this.state.file) {
      return;
    }

    const payload = {
      file: new FormData(),
      partner: getPartnerGuid(),
    };

    payload.file.append('file', this.state.file);

    this.props.dispatch(addRemittancesRequest(payload));
    this.setState({ cancelled:false });
  }


  dowloadFile() {
    if (this.props.remittances.remittances_report_file) {
      utils.downloadBlobReports(
        this.props.remittances.remittances_report_file.data,
        `remittances-report-${utils.formatDateTime(new Date())}.csv`
      );
    }
  }

  downloadCSVTemplate() {
    const rows = [
      ["First Name", "Last Name", "ID Number", "msisdn", "Date of birth", "Account Number" , "Amount", 'Date of cash pick up'],
      ["abcdefghi1", "abcdefghi1", "abcdefghi1", "00254715877533", "1980/02/10", "abcdefghi1", "149" , "2000/11/19"],
      ["abcdefghi1", "abcdefghi1", "abcdefghi1", "00254715877533", "1980/02/10", "abcdefghi1", "149" , "2000/11/19"],
    ];

    let csvContent = "data:text/csv;charset=utf-8,"
      + rows.map(e => e.join(",")).join("\n");

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';

    const url = encodeURI(csvContent);
    a.href = url;
    a.download = 'sample.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  apiMultiplePaymentErrorMessage() {
    const errorMessage = this.props.remittances.error_remittances ? this.props.remittances.error_remittances.message : '';
    return errorMessage
  }

  renderRemittances(){
    const { labels, keyPayment } = this.state;
    return (
    <div>
      <br /> <br />
      <ValidatorForm onSubmit={this.addRemittances}
                     key={keyPayment}
                     onError={() => this.setState({ submitted:true })}>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <div className="form-group">
              <div>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label={labels.uploadRemittancesCsv}
                  type="file"
                  accept=".csv,.txt"
                  className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl"
                  key={this.state.fileInputKey}
                  onChange={this.onFileSelect}
                  InputLabelProps={{shrink: true,}}
                />
              </div>
              {this.state.submitted && !this.state.file
                ? <p style={{ color: "red" }} > {labels.pleaseSelectFileToUpload} </p>
                :""}
            </div>
          </Grid>
          <Grid item xs={10} >
            <Button variant="contained" onClick={this.downloadCSVTemplate} style={btnStyle.secondary_2}>{labels.downloadCSVTemplate}</Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              style={btnStyle.secondary}
              type="submit"
            >
              {labels.addRemittances}
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </div>);
  }

  render() {
    const { labels } = this.state;
    return (
      <Loader loaded={true} color="#ff834f">
        <MainPageTitle pageTitle={'Remittances'} />

        <div className="x_panel">
          <div className="x_content" >
            <div> <center>
              {this.props.remittances.loader ?
                <center>
                  <CircularProgress />
                </center> : ''}
              <center >
                {this.props.remittances.error_remittances ? this.apiMultiplePaymentErrorMessage(): ''}
              </center>
            </center>
            </div>
            <div style={{ margin: "auto", borderColor: "black" }}>
              <AlertDialog
                info
                show={!this.props.loader && !!this.props.remittances.remittances_report_file && !this.state.cancelled}
                confirmBtnText={labels.downloadPaymentUploadResults}
                onConfirm={() => this.dowloadFile()}
                onCancel={() =>this.cancel()}
                showCloseButton={true}
                closeOnClickOutside={true}
              >
                <h5>{labels.confirmDownloadResults}</h5>
              </AlertDialog>
              {this.renderRemittances()}
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default connect(state => ({
  remittances: state.remittances,
  globalData: state.global,
}))(ManualRemittances);
