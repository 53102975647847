import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import { ClaimStatusEnum } from '../../../lib/enum';
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

const ClaimHeader = (props) => {
  const classes = useStyle();
  return (
    <section className={classes.claimsheader}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10} md={10}>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={10} md={10}>
              <div className={classes.claimstitle}>
                <ul>
                  <li>{props.claimData.claim_number || "N/A"}</li>
                  {
                    props.claimData.benefits.map((benfit, index) => {
                      return <li key={index} >- {benfit.type || "N/A"} </li>
                    })
                  }
                  <li><span className={[ClaimStatusEnum.Approved_Paid, ClaimStatusEnum.Approved].includes(props.claimData.status) ? classes.claimStatussuccess : classes.claimStatusdanger}>{props.claimData.status || "N/A"}</span></li>
                </ul>
              </div>
            </Grid>
            {/* <Grid item xs={12} sm={2} md={2} className={classes.claimactionbtn} style={{ display: props.claimData.is_waiting_period === true ? "block" : "none" }}>
              <li><span className={classes.waitingPeridod}>{props.claimData.is_waiting_period === true ? "Waiting Period" : "Not in waiting"}</span></li>
            </Grid> */}
          </Grid>
        </Grid>
        {/* <Grid item xs={12} sm={2} md={2}>
          <Grid container spacing={2} className={classes.claimactionbtn} style={{ display: props.claimData.is_waiting_period === true ? "block" : "none" }}>
            <Grid item xs={12} sm={12} md={12}>
              <li><span className={classes.waitingPeridod}>{props.claimData.is_waiting_period === true ? "Waiting Period" : "Not in waiting"}</span></li>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item xs={12} sm={2} md={2}
          style={{
            display: props.showAssessmentButton ? "block" : "none",
          }}
        >
          <ButtonInc
          className={classes.aibtn}
            onClick={props.toggleAssesmentDialog}
            variant="contained"
            color="primary"
            size="large"
            style={{
              marginTop: "8px",
            }}
          >
            <span style={{ color: "#fff" }}>
             AI assesment
            </span>
            <AutoFixHighIcon
              sx={{
                color: 'white',
                marginLeft: "10px",
              }}
            />
          </ButtonInc>
        </Grid>

      </Grid>
    </section>
  )
}

export default ClaimHeader