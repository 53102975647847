import React, { useState, useEffect } from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress } from 'material-ui';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import PropTypes from 'prop-types';
import ChangePolicy from './ChangePolicy';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <section sx={{ p: 3 }}>
          <div>{children}</div>
        </section>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const Buypolicy = (props) => {

  const [beneficiaryName, setBeneficiaryName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [beneficiaryDateOfBirth,setBeneficiaryDateOfBirth] = useState('')

  const [tabvalue, setTabValue] = useState(0);

  const handleSetTabChange = (event, newValue) => {
    setTabValue(newValue);
  };




  const getBeneficiary = () => {
    const policyEntranteries = props.createdQuote.policy_schedule_entries;

    for (let item of policyEntranteries) {
      const dependant = item.dependant
      if (dependant.relationship === "Wife" || dependant.relationship === "Spouse") {
        setBeneficiaryName(dependant.first_name + " " + dependant.middle_name + " " + dependant.last_name);
        setPhoneNumber(dependant.msisdn);
        return dependant
      }
    }
    return null
  }

  useEffect(() => {
    getBeneficiary();
  }, [props.createdQuote]);


  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "beneficiaryName") {
      setBeneficiaryName(value)
    }
    if (name === "phonenumber") {
      setPhoneNumber(value)
    }
    if(name === "beneficiary_date_of_birth"){
      setBeneficiaryDateOfBirth(value)
    }

  }

  const classes = useStyle();

  const reset = () => {
    setBeneficiaryName('');
    setPhoneNumber('');
  }

  const handleBuyPolicy = () => {
    const payload = {
      "customer_guid": props.createdQuote.customer_guid,
      "quotation_guid": props.createdQuote.guid,
      "partner": props.createdQuote.partner,
      "channel": "USSD",
      "beneficiary_name": beneficiaryName,
      "beneficiary_msisdn": phoneNumber,
      "web_agent_guid": "string",
      "debit": true,
      "beneficiary_date_of_birth": beneficiaryDateOfBirth,
      "payment_method": "Mobile_Money"
    }
    props.buyPolicy(payload);
    reset();
  }

  const handleBuyPolicySuccessfully = () => {
    props.resetBuyPolicySuccessAlert();
  }

  const handlenextStep = () => {
    props.handleNext();
  }

  const handleBuyPolicyError = () => {
    props.resetBuyPolicyErrorAlert()
  }



  return (
    <>

      <AlertDialog
        custom
        show={props.showBuyPolicyProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2> Purchasing Policy.. </h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showBuyPolicySuccessAlert}
        size="sm"
        title={'Purchase  Successfuly Made'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleBuyPolicySuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => { handlenextStep() }} style={{ background: 'green', }}>View Policy Details</Button>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showBuyPolicyErrorAlert}
        danger
        title={'Error Purchasing Policy'}
        onConfirm={() => handleBuyPolicyError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.buyPolicyError ? props.buyPolicyError.message : ''}
      </AlertDialog>
      <section className={classes.buypolicywrapper}>
        <Tabs
          value={tabvalue}
          onChange={handleSetTabChange}
          aria-label="buy or change quote"
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          className={classes.buypolicytabs}
        >

          <Tab icon={<ShoppingCartOutlinedIcon />} iconPosition="start" label="Buy Policy" {...a11yProps(0)} style={{
            background: "#032A37",
            borderRadius: "8px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            boxShadow: "none",
            padding: "10px",
            marginRight: "20px",
            textTransform: "capitalize",
            color: "#fff",
            height: "0",
            minHeight: "45px"
          }} />
          <Tab icon={<PriceChangeOutlinedIcon />} iconPosition="start" label="Change Quote" {...a11yProps(1)} style={{
            background: "#032A37",
            borderRadius: "8px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            boxShadow: "none",
            padding: "10px",
            marginRight: "20px",
            textTransform: "capitalize",
            color: "#fff",
            height: "0",
            minHeight: "45px"
          }} />
        </Tabs>

        <TabPanel value={tabvalue} index={0}>
          <ValidatorForm onSubmit={() => handleBuyPolicy()} >
            <Grid container spacing={2} style={{ marginTop: "10px" }}>
              <Grid item xs={12} sm={6} md={6}>
                <TextValidator
                  id="Fullname"
                  onChange={handleChange}
                  name="beneficiaryName"
                  fullWidth
                  label="Beneficiary Name"
                  value={beneficiaryName}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please enter full name']}
                />


              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="Quotationid"
                  onChange={handleChange}
                  name="quotationID"
                  fullWidth
                  disabled
                  label="Quote ID"
                  defaultValue={props.createdQuote.quotation_number || "N/A"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  id="phonenumber"
                  onChange={handleChange}
                  name="phonenumber"
                  fullWidth
                  label="Phone Number"
                  value={phoneNumber}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please enter a valid phone number']}
                />
              </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="beneficiary_date_of_birth"
                    onChange={handleChange}
                    name="beneficiary_date_of_birth"
                    type='date'
                    fullWidth
                    label="Beneficiary Date Of Birth"
                    value={beneficiaryDateOfBirth}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <div className={classes.buypolicybtn}>
                  <Button
                    variant="contained"
                    size="large"
                    type='submit'
                  >
                    Buy Policy
                  </Button>
                </div>
              </Grid>
            </Grid>
          </ValidatorForm>
        </TabPanel>
        
        <TabPanel value={tabvalue} index={1} >
          <ChangePolicy
            changeQuote={props.changeQuote}
            createdQuote={props.createdQuote}
            policies={props.policies}
            changeQuoteError={props.changeQuoteError}
            changeQuoteProgressAlert={props.changeQuoteProgressAlert}
            changeQuoteSuccessAlert={props.changeQuoteSuccessAlert}
            changeQuoteErrorAlert={props.changeQuoteErrorAlert}
            resetChangeQuoteSuccessAlert={props.resetChangeQuoteSuccessAlert}
            resetChangeQuoteErrorAlert={props.resetChangeQuoteErrorAlert}
          />
        </TabPanel>


      </section>
    </>
  )
}

export default Buypolicy