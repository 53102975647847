import * as types from "../../lib/actionTypes.es6";

export const initialState = {
  error: null,
  loader: false,
  bankdetails: [],

  bankdetail: null,

  createdbankdetail: null,
  createbankdetailSuccess: false,
  createbankdetailLoader: false,
  createbankdetailError: null,


  bankDetailToEdit: null,

  editedBankDetail: null,
  editBankDetailSuccess: false,
  editBankDetailLoader: false,
  editBankDetailError: null,

  bankDetailsToDelete: null,

  deleteBankDetailSuccess: false,
  deleteBankDetailLoader: false,
  deleteBankDetailError: null,
  bankdetailToDeleteGuid: null,
};

export default function customerbankdetailsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CUSTOMER_BANK_DETAILS_REQUEST:
      return { ...state, loader: true, error: null, bankdetail: null };
    case types.GET_CUSTOMER_BANK_DETAILS_SUCCESS:
      return { ...state, bankdetail: action.payload, loader: false };
    case types.GET_CUSTOMER_BANK_DETAILS_FAILURE:
      return { ...state, loader: false, error: action.payload };

    // case types.GET_CUSTOMER_BANK_DETAILS_REQUEST:
    //   return { ...state, loader: true, error: null, bankdetails: null };
    // case types.GET_CUSTOMER_BANK_DETAILS_SUCCESS:
    //   return { ...state, bankdetails: action.payload, loader: false };
    // case types.GET_CUSTOMER_BANK_DETAILS_FAILURE:
    //   return { ...state, loader: false, error: action.payload };

    case types.ADD_CUSTOMER_BANK_DETAILS_REQUEST:
      return { ...state, createbankdetailLoader: true, createbankdetailError: null, createdbankdetail: null };
    case types.ADD_CUSTOMER_BANK_DETAILS_SUCCESS:
      return { ...state, bankdetail: action.payload, createbankdetailLoader: false, createbankdetailSuccess: true };
    case types.ADD_CUSTOMER_BANK_DETAILS_FAILURE:
      return { ...state, createbankdetailLoader: false, createbankdetailError: action.payload };

    case types.RESET_ADD_CUSTOMER_BANK_DETAILS_SUCCESS:
      return { ...state, createbankdetailSuccess: false };
    case types.RESET_ADD_CUSTOMER_BANK_DETAILS_ERROR:
      return { ...state, createbankdetailError: null };


    // EDIT CUSTOMER BANK DETIALS
    case types.EDIT_CUSTOMER_BANK_DETAILS_REQUEST:
      return { ...state, editBankDetailLoader: true, editBankDetailError: null, editedBankDetail: null };
    case types.EDIT_CUSTOMER_BANK_DETAILS_SUCCESS:
      return { ...state, editedBankDetail: action.payload, editBankDetailLoader: false, editBankDetailSuccess: true };
    case types.EDIT_CUSTOMER_BANK_DETAILS_FAILURE:
      return { ...state, editBankDetailLoader: false, editBankDetailError: action.payload, editedBankDetail: null };

    case types.SET_CUSTOMER_BANK_DETAILS_TO_EDIT:
      return { ...state, bankDetailToEdit: action.payload };
    case types.RESET_CUSTOMER_BANK_DETAILS_TO_EDIT:
      return { ...state, bankDetailToEdit: null };

    case types.RESET_EDIT_CUSTOMER_BANK_DETAILS_SUCCESS:
      return { ...state, editBankDetailSuccess: false };
    case types.RESET_EDIT_CUSTOMER_BANK_DETAILS_ERROR:
      return { ...state, editBankDetailError: null };


    case types.DELETE_CUSTOMER_BANK_DETAILS_REQUEST:
      return { ...state, deleteBankDetailLoader: true, deleteBankDetailError: null, bankdetail: null };
    case types.DELETE_CUSTOMER_BANK_DETAILS_SUCCESS:
      return { ...state, deleteBankDetailLoader: false, deleteBankDetailSuccess: true };
    case types.DELETE_CUSTOMER_BANK_DETAILS_FAILURE:
      return { ...state, deleteBankDetailLoader: false, deleteBankDetailError: action.payload };

    case types.SET_CUSTOMER_BANK_DETAILS_TO_DELETE: 
      return { ...state, bankDetailsToDelete: action.payload };

    case types.RESET_CUSTOMER_BANK_DETAILS_TO_DELETE:
      return { ...state, bankDetailsToDelete: null };


    case types.RESET_DELETE_CUSTOMER_BANK_DETAILS_SUCCESS:
      return { ...state, deleteBankDetailSuccess: false };

    case types.RESET_DELETE_CUSTOMER_BANK_DETAILS_ERROR:
      return { ...state, deleteBankDetailError: null };

    default:
      return state;
  }
}