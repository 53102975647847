import React from 'react'
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Twiter from '../../../../../Assets/twiter.png'
import Linkedin from '../../../../../Assets/linkedin.png'

function ScrollTop(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor',
        );

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
            });
        }
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                {children}
            </Box>
        </Fade>
    );
}

ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

const Footer = (props) => {

    const classes = useStyle();
    return (
        <section className={classes.footer}>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3} md={3}>
                        <div className={classes.footerwrapper}>
                            <h3>Resources</h3>
                            <ul>
                                {/* <li><a href='/#'>Help Center</a></li> */}
                                <li><a href='https://inclusivitysolutions.com/news'  target='_blank' rel="noopener noreferrer">Blog</a></li>
                                <li><a href='https://apidocs.inclusivitysolutions.com'  target='_blank' rel="noopener noreferrer">Developers</a></li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <div className={classes.footerwrapper}>
                            <h3>Contact Us</h3>
                            <ul>
                                <li><a href='mailto: info@inclusivitysolutions.com'>Email Us</a></li>
                                <li><a href='https://inclusivitysolutions.com/contact/' target='_blank' rel="noopener noreferrer">Schedule Demo</a></li>
                                {/* <li><a href='https://inclusivitysolutions.com/contact-us-2/'  target='_blank' rel="noopener noreferrer">Call</a></li> */}
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <div className={classes.footerwrapper}>
                            <h3>Social Media</h3>
                            <div className={classes.socialmedia}>
                                <li><a href='https://twitter.com/InclusivitySols' target='_blank' rel="noopener noreferrer"><img src={Twiter} alt="twitter" /></a></li>
                                <li><a href='https://www.linkedin.com/company/inclusivity-solutions' target='_blank' rel="noopener noreferrer"><img src={Linkedin} alt="linkedin" /></a></li>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <div className={classes.footerwrapper}>
                            <h3>Our Company</h3>
                            <ul>
                                <li><a href='https://inclusivitysolutions.com/about-us/' target='_blank' rel="noopener noreferrer">About Us</a></li>
                                <li><a href='https://inclusivitysolutions.com' target='_blank' rel="noopener noreferrer">Careers</a></li>
                                <li><a href='https://inclusivitysolutions.com/news' target='_blank' rel="noopener noreferrer">Press</a></li>
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <Container>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <div className={classes.copyrights}>
                            <p>Copyrights @ Inclusivity Solutions</p>
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <ScrollTop {...props}>
                <Fab size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
        </section>
    )
}

export default Footer