import React, { useState } from 'react'

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import createCustomer from '../../../../../Assets/create-customer-profile.png'
import queryProuct from '../../../../../Assets/query-available-products.png'
import buyPolicy from '../../../../../Assets/buypolicy.png'
import makepayment from '../../../../../Assets/Make-Payment.png'
import makeClaim from '../../../../../Assets/makeclaim.png'
import testcreds from '../../../../../Assets/testcreds.png'
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const BuiltForDevelopersMobile = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                    TabIndicatorProps={{
                        sx: { backgroundColor: "#FBA92D" }
                    }}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    sx={{

                        "& button": {
                            height: "20px",
                            marginLeft: "20px",
                            background: "#D9D9D9",
                            fontFamily: "Lato",
                            fontStyle: "normal",
                            fontSize: "14px",
                            lineHeight: "20px",
                            textAlign: "center",
                            letterSpacing: "0.001em",
                        },
                        "& button:active": {
                            background: '#FBA92D',
                        },
                        "& button.Mui-selected": {
                            background: '#FBA92D',
                            color: "#fff"
                        }
                    }}>
                    <Tab label="1" {...a11yProps(0)} />
                    <Tab label="2" {...a11yProps(1)} />
                    <Tab label="3" {...a11yProps(2)} />
                    <Tab label="4" {...a11yProps(3)} />
                    <Tab label="5" {...a11yProps(4)} />
                    <Tab label="6" {...a11yProps(5)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <p style={{ textAlign: "center" }}>Get test credentials</p>
                <img src={testcreds} alt="get test credentials" style={{ width: "100%" }} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <p style={{ textAlign: "center" }}>Create a customer profile</p>
                <img src={createCustomer} alt="Create a customer profile" style={{ width: "100%" }} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <p style={{ textAlign: "center" }}>Query available products</p>
                <img src={queryProuct} alt="Query available products" style={{ width: "100%" }} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <p style={{ textAlign: "center" }}>Buy a policy cover</p>
                <img src={buyPolicy} alt="Buy a policy cover" style={{ width: "100%" }} />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <p style={{ textAlign: "center" }}>Make Payment</p>
                <img src={makepayment} alt="Make Payment" style={{ width: "100%" }} />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <p style={{ textAlign: "center" }}>Make a Claim</p>
                <img src={makeClaim} alt="Make a Claim" style={{ width: "100%" }} />
            </TabPanel>
        </>
    )
}

export default BuiltForDevelopersMobile