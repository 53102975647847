import React from "react";
import './adonsTable.css'
import TableInc from "../../../../../shared-ui/TableInc";
import { updateExtendedConfig } from "../../../../../lib/requests.es6";
import { RELATIONSHIP_POOL } from "../../../PoductConstants";

export default function Nuclear(props) {

  const nuclearArr = props.nuclear.map(entry => { 

    return {
      ...entry,
      relationship: entry.relationship,
      min: entry.age_range.min,
      max: entry.age_range.max,
      max_count: entry.max_count
    }

  })

  if (props.nuclear) {
    return (
      <>
        <div style={{ marginTop: "2%" }}>

          <TableInc
            title="Nuclear members relationship"
            columns={[
              { title: 'Relationship', field: 'relationship', lookup: RELATIONSHIP_POOL },
              { title: 'Minimium Age', field: 'min', type: 'numeric' },
              { title: 'Maximum Age', field: 'max', type: 'numeric' },
              { title: 'Max Members Allowed', field: 'max_count', type: 'numeric' },
            ]}
            data={
              nuclearArr
            }
            options={{
              pageSize: nuclearArr.length,
              addRowPosition: 'first',
              showTitle: true,
              actionsColumnIndex: -1

            }}

            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {

                  const formattedData = {
                    "relationship": newData.relationship,
                    "age_range": {
                      "min": Number(newData.min),
                      "max": Number(newData.max)
                    },
                    "max_count": Number(newData.max_count)
                  };

                  const nuclearConfig = {
                    "product_guid": props.productGuid,
                    ...props.extendedConfig,
                    "content": {
                      ...props.extendedConfig.content,
                      nuclear: [...props.extendedConfig.content.nuclear, formattedData]
                    }
                  }
                  updateExtendedConfig(nuclearConfig).then(
                    (response) => {
                      resolve();
                      props.refreshProduct(props.productGuid);
                      window.location.reload(true);
                    }
                  ).catch(
                    (error) => {
                      console.log("did not work", error)
                      reject();
                    }
                  )

                }),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  console.log('you are deleting this: ', oldData)
                  const indexToDelete = oldData.tableData.id
                  const nuclear = props.extendedConfig.content.nuclear
                  nuclear.splice(indexToDelete, 1)
                  const nuclearConfig = {
                    "product_guid": props.productGuid,
                    ...props.extendedConfig,
                    "content": {
                      ...props.extendedConfig.content,
                      nuclear: nuclear
                    }
                  }
                  updateExtendedConfig(nuclearConfig).then(
                    (response) => {
                      resolve();
                      props.refreshProduct(props.productGuid);
                      window.location.reload(true);
                    }
                  ).catch(
                    (error) => {
                      console.log("did not work", error)
                      reject();
                    }
                  )
                  resolve();
                }),
            }}

          />
        </div>
      </>
    );
  }
  return "No nuclear found"
}
