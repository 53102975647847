import * as types from '../../lib/actionTypes.es6';

export function reportClaimDeathRequest(reportType) {
  return {
    type: types.REPORT_CLAIM_DEATH_REQUEST,
    payload: reportType,
  };
}

export function reportClaimDeathRequestSuccess(reportURL) {
  return {
    type: types.REPORT_CLAIM_DEATH_SUCCESS,
    payload: reportURL,
  };
}

export function reportClaimDeathRequestError(error) {
  return {
    type: types.REPORT_CLAIM_DEATH_FAILURE,
    payload: error,
  };
}

export function reportClaimDeathReset() {
  return {
    type: types.REPORT_CLAIM_DEATH_RESET,
  };
}
