import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  products: [],
  errors: null,
  loader: false,
  currentProduct: null,
  options: null,

  createdProduct: null,
  createProductSuccess: false,
  createProductLoader: false,
  createProductError: null,

  createdPremium: null,
  createPremiumSuccess: false,
  createPremiumLoader: false,
  createPremiumError: null,
  createdPremiums: [],

  hardDeletePremiumSuccess: false,
  hardDeletePremiumLoader: false,
  hardDeletePremiumError: null,
  premiumToHardDeleteGuid: null,

  createdBenefit: null,
  createBenefitSuccess: false,
  createBenefitLoader: false,
  createBenefitError: null,
  createdBenefits: [],

  hardDeleteBenefitSuccess: false,
  hardDeleteBenefitLoader: false,
  hardDeleteBenefitError: null,
  benefitToHardDeleteGuid: null,

  dialogProductType: '',

  editedProduct: null,
  editProductSuccess: false,
  editProductLoader: false,
  editProductError: null,

  premiumToEdit: null,
  benefitToEdit: null,
  benefitToDelete: null,

  editedPremium: null,
  editPremiumSuccess: false,
  editPremiumLoader: false,
  editPremiumError: null,

  editedBenefit: null,
  editBenefitSuccess: false,
  editBenefitLoader: false,
  editBenefitError: null,

  groupProductConfigs: null,
  groupProductConfigsErrors: null,
  groupProductConfigsLoader: false,

  updateGroupProductConfigs: null,
  updateGroupProductConfigsSuccess: false,
  updateGroupProductConfigsLoader: false,
  updateGroupProductConfigsError: null,

  addGroupProductConfigs: null,
  addGroupProductConfigsSuccess: false,
  addGroupProductConfigsLoader: false,
  addGroupProductConfigsError: null,


  extendedFamilyProductConfigs: null,
  // {
  //   created_at: '2023-02-21',
  //   maximum_children: 4,
  //   maximum_spouse: 4,
  //   nuclear_restricted_relationships: ['Son', 'Daughter', 'Father', 'Mother', 'Husband', 'Wife', 'Side Chick'].join(", ")
  // }
  // ,
  extendedFamilyProductConfigsErrors: null,
  extendedFamilyProductConfigsLoader: false,

  updateExtendedFamilyProductConfigs: null,
  updateExtendedFamilyProductConfigsSuccess: false,
  updateExtendedFamilyProductConfigsLoader: false,
  updateExtendedFamilyProductConfigsError: null,

  addExtendedFamilyProductConfigs: null,
  addExtendedFamilyProductConfigsSuccess: false,
  addExtendedFamilyProductConfigsLoader: false,
  addExtendedFamilyProductConfigsError: null,


  createdPremiumConfig: null,
  createPremiumConfigSuccess: false,
  createPremiumConfigLoader: false,
  createPremiumConfigError: null,
  createdPremiumConfigs: [],

  hardDeletePremiumConfigSuccess: false,
  hardDeletePremiumConfigLoader: false,
  hardDeletePremiumConfigError: null,
  premiumConfigToHardDeleteGuid: null,

  premiumConfigToEdit: null,
  editedPremiumConfig: null,
  editPremiumConfigSuccess: false,
  editPremiumConfigLoader: false,
  editPremiumConfigError: null,

  premiumConfigs: [],
  premiumConfigsLoader: false,
  premiumConfigsError: null,

  createdAddonConfig: null,
  createAddonConfigSuccess: false,
  createAddonConfigLoader: false,
  createAddonConfigError: null,
  createdAddonConfigs: [],
};

export default function newProductsReducer(state = initialState, action) {
  switch (action.type) {
    case types.VIEW_ALL_PRODUCTS_REQUEST:
      return { ...state, errors: null, loader: true, products: [] };

    case types.VIEW_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
        loader: false,
        errors: null,
      };

    case types.VIEW_ALL_PRODUCTS_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    case types.CREATE_PRODUCT_REQUEST:
      return {
        ...state,
        createProductError: null,
        createProductLoader: true,
        createProductSuccess: false,
        createdProduct: null
      };

    case types.CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        createdProduct: action.payload,
        createProductSuccess: true,
        createProductLoader: false,
        createProductError: null,
      };

    case types.CREATE_PRODUCT_FAILURE:
      return { ...state, createProductLoader: false, createProductError: action.payload };


    case types.RESET_CREATE_PRODUCT_SUCCESS:
      return { ...state, createProductSuccess: false };

    case types.RESET_CREATE_PRODUCT_ERROR:
      return { ...state, createProductError: null };

    case types.CREATE_PREMIUM_REQUEST:
      return {
        ...state,
        createPremiumError: null,
        createPremiumLoader: true,
        createPremiumSuccess: false,
        createdPremium: null
      };

    case types.CREATE_PREMIUM_SUCCESS:
      return {
        ...state,
        createdPremium: action.payload,
        createPremiumSuccess: true,
        createPremiumLoader: false,
        createPremiumError: null,
        createdPremiums: addToCreatedPremiums(action.payload, state)
      };

    case types.CREATE_PREMIUM_FAILURE:
      return { ...state, createPremiumLoader: false, createPremiumError: action.payload };

    case types.RESET_CREATE_PREMIUM_SUCCESS:
      return { ...state, createPremiumSuccess: false };

    case types.RESET_CREATE_PREMIUM_ERROR:
      return { ...state, createPremiumError: null };


    case types.HARD_DELETE_PREMIUM_REQUEST:
      return {
        ...state,
        hardDeletePremiumError: null,
        hardDeletePremiumLoader: true,
        hardDeletePremiumSuccess: false,
        premiumToHardDeleteGuid: action.payload
      };

    case types.HARD_DELETE_PREMIUM_SUCCESS:
      return {
        ...state,
        hardDeletePremiumSuccess: true,
        hardDeletePremiumLoader: false,
        hardDeletePremiumError: null,
        createdPremiums: deleteFromCreatedPremiums(state)
      };

    case types.HARD_DELETE_PREMIUM_FAILURE:
      return { ...state, hardDeletePremiumLoader: false, hardDeletePremiumError: action.payload };

    case types.RESET_HARD_DELETE_PREMIUM_SUCCESS:
      return { ...state, hardDeletePremiumSuccess: false };

    case types.RESET_HARD_DELETE_PREMIUM_ERROR:
      return { ...state, hardDeletePremiumError: null };

    // benefit

    case types.CREATE_BENEFIT_REQUEST:
      return {
        ...state,
        createBenefitError: null,
        createBenefitLoader: true,
        createBenefitSuccess: false,
        createdBenefit: null
      };

    case types.CREATE_BENEFIT_SUCCESS:
      return {
        ...state,
        createdBenefit: action.payload,
        createBenefitSuccess: true,
        createBenefitLoader: false,
        createBenefitError: null,
        createdBenefits: addToCreatedBenefits(action.payload, state)
      };

    case types.CREATE_BENEFIT_FAILURE:
      return { ...state, createBenefitLoader: false, createBenefitError: action.payload };

    case types.RESET_CREATE_BENEFIT_SUCCESS:
      return { ...state, createBenefitSuccess: false };

    case types.RESET_CREATE_BENEFIT_ERROR:
      return { ...state, createBenefitError: null };

    case 'RESET_CREATED_BENEFITS_AND_PREMIUMS_LISTS':
      return { ...state, createdPremiums: [], createdBenefits: [], };

    case types.HARD_DELETE_BENEFIT_REQUEST:
      return {
        ...state,
        hardDeleteBenefitError: null,
        hardDeleteBenefitLoader: true,
        hardDeleteBenefitSuccess: false,
        benefitToHardDeleteGuid: action.payload
      };

    case types.HARD_DELETE_BENEFIT_SUCCESS:
      return {
        ...state,
        hardDeleteBenefitSuccess: true,
        hardDeleteBenefitLoader: false,
        hardDeleteBenefitError: null,
        createdBenefits: deleteFromCreatedBenefits(state)
      };

    case types.HARD_DELETE_BENEFIT_FAILURE:
      return { ...state, hardDeleteBenefitLoader: false, hardDeleteBenefitError: action.payload };

    case types.RESET_HARD_DELETE_BENEFIT_SUCCESS:
      return { ...state, hardDeleteBenefitSuccess: false };

    case types.RESET_HARD_DELETE_BENEFIT_ERROR:
      return { ...state, hardDeleteBenefitError: null };


    // end of benefit




    case types.VIEW_PRODUCT_REQUEST:
      return { ...state, errors: null, loader: true };

    case types.VIEW_PRODUCT_SUCCESS:
      return {
        ...state,
        currentProduct: action.payload,
        loader: false,
        errors: null,
      };

    case types.VIEW_PRODUCT_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    case types.PRODUCT_ENUMS_REQUEST:
      return { ...state, errors: null, loader: true, options: null };

    case types.PRODUCT_ENUMS_SUCCESS:
      return {
        ...state,
        options: action.payload,
        loader: false,
        errors: null,
      };

    case types.PRODUCT_ENUMS_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    case types.USER_LOGOUT:
      return initialState;

    case 'UPDATE_PRODUCT_TYPE_IN_DIALOG':
      return { ...state, dialogProductType: action.payload };

    case types.EDIT_PRODUCT_REQUEST:
      return {
        ...state,
        editProductError: null,
        editProductLoader: true,
        editProductSuccess: false,
        editedProduct: null
      };

    case types.EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        editedProduct: action.payload,
        editProductSuccess: true,
        editProductLoader: false,
        editProductError: null,
      };

    case types.EDIT_PRODUCT_FAILURE:
      return { ...state, editProductLoader: false, editProductError: action.payload };


    case types.RESET_EDIT_PRODUCT_SUCCESS:
      return { ...state, editProductSuccess: false };

    case types.RESET_EDIT_PRODUCT_ERROR:
      return { ...state, editProductError: null };

    case types.EDIT_PREMIUM_REQUEST:
      return {
        ...state,
        editPremiumError: null,
        editPremiumLoader: true,
        editPremiumSuccess: false,
        editedPremium: null
      };

    case types.EDIT_PREMIUM_SUCCESS:
      return {
        ...state,
        editedPremium: action.payload,
        editPremiumSuccess: true,
        editPremiumLoader: false,
        editPremiumError: null,
      };

    case types.EDIT_PREMIUM_FAILURE:
      return { ...state, editPremiumLoader: false, editPremiumError: action.payload };


    case types.RESET_EDIT_PREMIUM_SUCCESS:
      return { ...state, editPremiumSuccess: false };

    case types.RESET_EDIT_PREMIUM_ERROR:
      return { ...state, editPremiumError: null };



    case types.EDIT_BENEFIT_REQUEST:
      return {
        ...state,
        editBenefitError: null,
        editBenefitLoader: true,
        editBenefitSuccess: false,
        editedBenefit: null
      };

    case types.EDIT_BENEFIT_SUCCESS:
      return {
        ...state,
        editedBenefit: action.payload,
        editBenefitSuccess: true,
        editBenefitLoader: false,
        editBenefitError: null,
      };

    case types.EDIT_BENEFIT_FAILURE:
      return { ...state, editBenefitLoader: false, editBenefitError: action.payload };


    case types.RESET_EDIT_BENEFIT_SUCCESS:
      return { ...state, editBenefitSuccess: false };

    case types.RESET_EDIT_BENEFIT_ERROR:
      return { ...state, editBenefitError: null };




    case types.SET_PREMIUM_TO_EDIT:
      return { ...state, premiumToEdit: action.payload };

    case types.SET_PREMIUM_TO_DELETE:
      return { ...state, premiumToDelete: action.payload };

    case types.SET_BENEFIT_TO_EDIT:
      return { ...state, benefitToEdit: action.payload };

    case types.SET_BENEFIT_TO_DELETE:
      return { ...state, benefitToDelete: action.payload };

    case types.RESET_PREMIUM_TO_EDIT:
      return { ...state, premiumToEdit: null };

    case types.RESET_PREMIUM_TO_DELETE:
      return { ...state, premiumToDelete: null };

    case types.RESET_BENEFIT_TO_EDIT:
      return { ...state, benefitToEdit: null };

    case types.RESET_BENEFIT_TO_DELETE:
      return { ...state, benefitToDelete: null };


    // get partner config group
    case types.GET_GROUP_PRODUCT_CONFIGS_REQUEST:
      return { ...state, groupProductConfigs: null, groupProductConfigsErrors: null, groupProductConfigsLoader: true };

    case types.GET_GROUP_PRODUCT_CONFIGS_SUCCESS:
      return { ...state, groupProductConfigs: action.payload, groupProductConfigsErrors: null, groupProductConfigsLoader: false };

    case types.GET_GROUP_PRODUCT_CONFIGS_FAILURE:
      return { ...state, groupProductConfigs: null, groupProductConfigsErrors: action.payload, groupProductConfigsLoader: false };


    // update partner config group 
    case types.UPDATE_GROUP_PRODUCT_CONFIGS_REQUEST:
      return { ...state, updateGroupProductConfigsError: null, updateGroupProductConfigsLoader: true, updateGroupProductConfigsSuccess: false, updateGroupProductConfigs: null };

    case types.UPDATE_GROUP_PRODUCT_CONFIGS_SUCCESS:
      return { ...state, updateGroupProductConfigs: action.payload, updateGroupProductConfigsSuccess: true, updateGroupProductConfigsLoader: false, updateGroupProductConfigsError: null };

    case types.UPDATE_GROUP_PRODUCT_CONFIGS_FAILURE:
      return { ...state, updateGroupProductConfigsLoader: false, updateGroupProductConfigsError: action.payload };

    case types.RESET_UPDATE_GROUP_PRODUCT_CONFIGS_SUCCESS:
      return { ...state, updateGroupProductConfigsSuccess: false };

    case types.RESET_UPDATE_GROUP_PRODUCT_CONFIGS_ERROR:
      return { ...state, updateGroupProductConfigsError: null };


    // add partner config group 
    case types.ADD_GROUP_PRODUCT_CONFIGS_REQUEST:
      return { ...state, addGroupProductConfigsError: null, addGroupProductConfigsLoader: true, addGroupProductConfigsSuccess: false, addGroupProductConfigs: null };

    case types.ADD_GROUP_PRODUCT_CONFIGS_SUCCESS:
      return { ...state, addGroupProductConfigs: action.payload, addGroupProductConfigsSuccess: true, addGroupProductConfigsLoader: false, addGroupProductConfigsError: null };

    case types.ADD_GROUP_PRODUCT_CONFIGS_FAILURE:
      return { ...state, addGroupProductConfigsLoader: false, addGroupProductConfigsError: action.payload };

    case types.RESET_ADD_GROUP_PRODUCT_CONFIGS_SUCCESS:
      return { ...state, addGroupProductConfigsSuccess: false };

    case types.RESET_ADD_GROUP_PRODUCT_CONFIGS_ERROR:
      return { ...state, addGroupProductConfigsError: null };






    // get extended family config 
    case types.GET_EXTENDED_FAMILY_PRODUCT_CONFIGS_REQUEST:
      return { ...state, extendedFamilyProductConfigs: null, extendedFamilyProductConfigsErrors: null, extendedFamilyProductConfigsLoader: true };

    case types.GET_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS:
      return { ...state, extendedFamilyProductConfigs: action.payload, extendedFamilyProductConfigsErrors: null, extendedFamilyProductConfigsLoader: false };

    case types.GET_EXTENDED_FAMILY_PRODUCT_CONFIGS_FAILURE:
      return { ...state, extendedFamilyProductConfigs: null, extendedFamilyProductConfigsErrors: action.payload, extendedFamilyProductConfigsLoader: false };


    // update extendedFamily 
    case types.UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_REQUEST:
      return { ...state, updateExtendedFamilyProductConfigsError: null, updateExtendedFamilyProductConfigsLoader: true, updateExtendedFamilyProductConfigsSuccess: false, updateExtendedFamilyProductConfigs: null };

    case types.UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS:
      return { ...state, updateExtendedFamilyProductConfigs: action.payload, updateExtendedFamilyProductConfigsSuccess: true, updateExtendedFamilyProductConfigsLoader: false, updateExtendedFamilyProductConfigsError: null };

    case types.UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_FAILURE:
      return { ...state, updateExtendedFamilyProductConfigsLoader: false, updateExtendedFamilyProductConfigsError: action.payload };

    case types.RESET_UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS:
      return { ...state, updateExtendedFamilyProductConfigsSuccess: false };

    case types.RESET_UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_ERROR:
      return { ...state, updateExtendedFamilyProductConfigsError: null };


    // add extendedFamily 
    case types.ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_REQUEST:
      return { ...state, addExtendedFamilyProductConfigsError: null, addExtendedFamilyProductConfigsLoader: true, addExtendedFamilyProductConfigsSuccess: false, addExtendedFamilyProductConfigs: null };

    case types.ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS:
      return { ...state, addExtendedFamilyProductConfigs: action.payload, extendedFamilyProductConfigs: action.payload, addExtendedFamilyProductConfigsSuccess: true, addExtendedFamilyProductConfigsLoader: false, addExtendedFamilyProductConfigsError: null };

    case types.ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_FAILURE:
      return { ...state, addExtendedFamilyProductConfigsLoader: false, addExtendedFamilyProductConfigsError: action.payload };

    case types.RESET_ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS:
      return { ...state, addExtendedFamilyProductConfigsSuccess: false };

    case types.RESET_ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_ERROR:
      return { ...state, addExtendedFamilyProductConfigsError: null };


    /// PREMIUM CONFIGS
    case types.CREATE_PREMIUM_CONFIG_REQUEST:
      return {
        ...state,
        createPremiumConfigError: null,
        createPremiumConfigLoader: true,
        createPremiumConfigSuccess: false,
        createdPremiumConfig: null
      };

    case types.CREATE_PREMIUM_CONFIG_SUCCESS:
      return {
        ...state,
        createdPremiumConfig: action.payload,
        createPremiumConfigSuccess: true,
        createPremiumConfigLoader: false,
        createPremiumConfigError: null,
        createdPremiumConfigs: addToCreatedPremiumConfigs(action.payload, state)
      };

    case types.CREATE_PREMIUM_CONFIG_FAILURE:
      return { ...state, createPremiumConfigLoader: false, createPremiumConfigError: action.payload };

    case types.RESET_CREATE_PREMIUM_CONFIG_SUCCESS:
      return { ...state, createPremiumConfigSuccess: false };

    case types.RESET_CREATE_PREMIUM_CONFIG_ERROR:
      return { ...state, createPremiumConfigError: null };

    ///addons configs

    case types.CREATE_ADDON_CONFIG_REQUEST:
      return {
        ...state,
        createAddonConfigError: null,
        createAddonConfigLoader: true,
        createAddonConfigSuccess: false,
        createdAddonConfig: null
      };

    case types.CREATE_ADDON_CONFIG_SUCCESS:
      return {
        ...state,
        createdAddonConfig: action.payload,
        createAddonConfigSuccess: true,
        createAddonConfigLoader: false,
        createAddonConfigError: null,
        createdAddonConfigs: addToCreatedAddonConfigs(action.payload, state)
      };

    case types.CREATE_ADDON_CONFIG_FAILURE:
      return { ...state, createAddonConfigLoader: false, createAddonConfigError: action.payload };

    case types.RESET_CREATE_ADDON_CONFIG_SUCCESS:
      return { ...state, createAddonConfigSuccess: false };

    case types.RESET_CREATE_ADDON_CONFIG_ERROR:
      return { ...state, createAddOnConfigError: null };

    //end of addon configs

    case types.HARD_DELETE_PREMIUM_CONFIG_REQUEST:
      return {
        ...state,
        hardDeletePremiumConfigError: null,
        hardDeletePremiumConfigLoader: true,
        hardDeletePremiumConfigSuccess: false,
        premiumConfigToHardDeleteGuid: action.payload
      };

    case types.HARD_DELETE_PREMIUM_CONFIG_SUCCESS:
      return {
        ...state,
        hardDeletePremiumConfigSuccess: true,
        hardDeletePremiumConfigLoader: false,
        hardDeletePremiumConfigError: null,
        createdPremiumConfigs: deleteFromCreatedPremiumConfigs(state)
      };

    case types.HARD_DELETE_PREMIUM_CONFIG_FAILURE:
      return { ...state, hardDeletePremiumConfigLoader: false, hardDeletePremiumConfigError: action.payload };

    case types.RESET_HARD_DELETE_PREMIUM_CONFIG_SUCCESS:
      return { ...state, hardDeletePremiumConfigSuccess: false };

    case types.RESET_HARD_DELETE_PREMIUM_CONFIG_ERROR:
      return { ...state, hardDeletePremiumConfigError: null };


    case types.EDIT_PREMIUM_CONFIG_REQUEST:
      return {
        ...state,
        editPremiumConfigError: null,
        editPremiumConfigLoader: true,
        editPremiumConfigSuccess: false,
        editedPremiumConfig: null
      };

    case types.EDIT_PREMIUM_CONFIG_SUCCESS:
      return {
        ...state,
        editedPremiumConfig: action.payload,
        editPremiumConfigSuccess: true,
        editPremiumConfigLoader: false,
        editPremiumConfigError: null,
      };

    case types.EDIT_PREMIUM_CONFIG_FAILURE:
      return { ...state, editPremiumConfigLoader: false, editPremiumConfigError: action.payload };


    case types.RESET_EDIT_PREMIUM_CONFIG_SUCCESS:
      return { ...state, editPremiumConfigSuccess: false };

    case types.RESET_EDIT_PREMIUM_CONFIG_ERROR:
      return { ...state, editPremiumConfigError: null };




    case types.SET_PREMIUM_CONFIG_TO_EDIT:
      return { ...state, premiumConfigToEdit: action.payload };

    case types.SET_PREMIUM_CONFIG_TO_DELETE:
      return { ...state, premiumConfigToDelete: action.payload };

    case types.RESET_PREMIUM_CONFIG_TO_EDIT:
      return { ...state, premiumConfigToEdit: null };

    case types.RESET_PREMIUM_CONFIG_TO_DELETE:
      return { ...state, premiumConfigToDelete: null };


    // get premium config group
    case types.GET_PREMIUM_CONFIGS_REQUEST:
      return { ...state, premiumConfigs: null, premiumConfigsErrors: null, premiumConfigsLoader: true };

    case types.GET_PREMIUM_CONFIGS_SUCCESS:
      return { ...state, premiumConfigs: action.payload, premiumConfigsErrors: null, premiumConfigsLoader: false };

    case types.GET_PREMIUM_CONFIGS_FAILURE:
      return { ...state, premiumConfigs: null, premiumConfigsErrors: action.payload, premiumConfigsLoader: false };

    default:
      return state;
  }
}


function addToCreatedPremiums(premium, state) {
  state.createdPremiums.push(premium)
  return state.createdPremiums
}

function deleteFromCreatedPremiums(state) {
  let { premiumToHardDeleteGuid, createdPremiums } = state
  let newCreatedPremiums = createdPremiums.filter(function (premium) {
    return premium.guid !== premiumToHardDeleteGuid;
  });
  return newCreatedPremiums
}


function addToCreatedBenefits(benefit, state) {
  state.createdBenefits.push(benefit)
  return state.createdBenefits
}

function deleteFromCreatedBenefits(state) {
  let { benefitToHardDeleteGuid, createdBenefits } = state
  let newCreatedBenefits = createdBenefits.filter(function (benefit) {
    return benefit.guid !== benefitToHardDeleteGuid.benefit.guid;
  });
  return newCreatedBenefits
}

function addToCreatedPremiumConfigs(premiumConfig, state) {
  state.createdPremiumConfigs.push(premiumConfig)
  return state.createdPremiumConfigs
}

function addToCreatedAddonConfigs(premiumConfig, state) {
  state.createdAddonConfigs.push(premiumConfig)
  return state.createdAddonConfigs
}

function deleteFromCreatedPremiumConfigs(state) {
  let { premiumConfigToHardDeleteGuid, createdPremiumConfigs } = state
  let newCreatedPremiumConfigs = createdPremiumConfigs.filter(function (premiumConfig) {
    return premiumConfig.guid !== premiumConfigToHardDeleteGuid;
  });
  return newCreatedPremiumConfigs
}