import React from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import NoRecord from '../../components/NoRecord/index.es6';
import * as globalActions from '../Main/actions.es6';
import { Dependants } from '../../components/Dependants/index.es6';

export class DependantsList extends React.Component {
  componentWillMount() {
    this.props.dispatch(globalActions.showCustomerBar());
  }

  displayDependants() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (!currentCustomer) {
      return <NoRecord />;
    }
    return (
      <div className="render-polices">
        <Dependants
          dependants={currentCustomer.dependantDto}
          showMore={false}
          customerGuid={currentCustomer.guid}
        />
      </div>
    );
  }

  render() {
    return (
      <Loader loaded={!this.props.customerData.loader} color="#ff834f">
        {this.displayDependants()}
      </Loader>
    );
  }
}

export default connect(state => ({ customerData: state.currentCustomer }))(
  DependantsList
);
