import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

export default function SkeletonWrapper(props) {
  return (
    <div>
      {
        props.loading && <Grid container spacing={2}>
          <Grid item xs={9}>
            <Skeleton variant="text" height={80} />
          </Grid>
          <Grid item xs={2} style={{ marginLeft: 'auto', marginTop: 'auto', marginBottom: 'auto' }}>
            <Skeleton variant="circular" width={60} height={60} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" width={"100%"} height={118} />
            <Skeleton variant="text" height={40} />
            <Skeleton variant="text" height={40} />
            <Skeleton variant="text" height={40} />
            <Skeleton variant="text" height={40} />
            <Skeleton variant="text" height={40} />
            <Skeleton variant="text" height={40} />
          </Grid>
        </Grid>
      }
    </div>
  );
}
