import React from 'react'
import { useStyle } from "./Style";
import MaterialTable from 'material-table';

const TableInc = (props) => {
  const classes = useStyle();
  return (
    <>
      <section className={classes.tableInc}>

            <MaterialTable
              title={props.title}
              columns={props.columns}
              data={props.data}
              {...props}
              options={{
                ...props.options
              }}
              {...props.otherProps}
            />

      </section>

    </>
  )
}

export default TableInc