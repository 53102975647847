import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../../lib/actionTypes.es6'
import * as actions from './actions';

import { getClaimsDcp,claimBulkApprove, getClaimsDcpFiltered } from '../../../../lib/requests.es6';
import { GAloggerException } from '../../../../lib/monitoring.es6';
import { unAuthorizedNotification } from '../../../../lib/access.es6';




export function* getClaimsDcpRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_CLAIMS_DCP_REQUEST);
    try {
      const response = yield call(getClaimsDcp, request.payload);
      yield put(actions.getClaimsDcpSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getClaimsDcpFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}



export function* getClaimsDcpFilteredRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_CLAIMS_DCP_FILTERED_REQUEST);
    try {
      const response = yield call(getClaimsDcpFiltered, request.payload);
      yield put(actions.getClaimsDcpFilteredSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getClaimsDcpFilteredFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* bulkApproveRequestWatcher() {
  for (;;) {
    const request = yield take(types.BULK_APPROVE_REQUEST);
    try {
      const response = yield call(claimBulkApprove, request.payload);
      yield put(actions.bulkApproveSuccess(response.data));

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.bulkApproveFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}