import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  uploadzone: {
    textAlign: "center",
    "& h4":{
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
    }
  }

}));