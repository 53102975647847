import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../lib/actionTypes.es6';
import {
  getCurrentGroupClaims, getCurrentGroupMembersForAdmins,
  getCurrentGroupMembers, getCurrentGroupPayments, getCurrentGroupPolicies, getCurrentGroupPremiums, getCurrentGroupClaimCallbacks,
  getGroupMembersForCSV
} from '../../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../../lib/access.es6';
import { GAloggerException } from '../../../lib/monitoring.es6';


export function* getCurrentGroupMembersRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.GET_CURRENT_GROUP_MEMBERS_REQUEST);
      const response = yield call(getCurrentGroupMembers, payload);
      yield put(actions.getCUrrentGroupMembersSuccess(response.data));
    } catch (e) {
      yield put(
        actions.getCUrrentGroupMembersFailed(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* getCurrentGroupMembersForAdminsRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.GET_CURRENT_GROUP_MEMBERS_FOR_ADMINS_REQUEST);
      const response = yield call(getCurrentGroupMembersForAdmins, payload);
      yield put(actions.getCUrrentGroupMembersForAdminsSuccess(response.data));
    } catch (e) {
      yield put(
        actions.getCUrrentGroupMembersForAdminsFailed(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* getCurrentGroupMembersForCsvRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.GET_CURRENT_GROUP_MEMBERS_FOR_CSV_REQUEST);
      const response = yield call(getGroupMembersForCSV, payload);
      yield put(actions.getCUrrentGroupMembersForCSVSuccess(response.data));
    } catch (e) {
      yield put(
        actions.getCUrrentGroupMembersForCSVFailed(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* getCurrentGroupPoliciesRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.GET_CURRENT_GROUP_POLICIES_REQUEST);
      const response = yield call(getCurrentGroupPolicies, payload);
      yield put(actions.getCurrentGroupPoliciesSuccess(response.data));
    } catch (e) {
      yield put(
        actions.getCurrentGroupPoliciesFailed(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* getCurrentGroupPremiumsRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.GET_CURRENT_GROUP_PREMIUMS_REQUEST);
      const response = yield call(getCurrentGroupPremiums, payload);
      yield put(actions.getCurrentGroupPremiumsSuccess(response.data));
    } catch (e) {
      yield put(
        actions.getCurrentGroupPremiumsFailed(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* getCurrentGroupPaymentsRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.GET_CURRENT_GROUP_PAYMENTS_REQUEST);
      const response = yield call(getCurrentGroupPayments, payload);
      yield put(actions.getCurrentGroupPaymentsSuccess(response.data));
    } catch (e) {
      yield put(
        actions.getCurrentGroupPaymentsFailed(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}



export function* getCurrentGroupClaimsRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.GET_CURRENT_GROUP_CLAIMS_REQUEST);
      const response = yield call(getCurrentGroupClaims, payload);
      yield put(actions.getCurrentGroupClaimsSuccess(response.data));
    } catch (e) {
      yield put(
        actions.getCurrentGroupClaimsFailed(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* getCurrentGroupClaimCallbacksRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.GET_CURRENT_GROUP_CLAIM_CALLBACKS_REQUEST);
      const response = yield call(getCurrentGroupClaimCallbacks, payload);
      yield put(actions.getCurrentGroupClaimCallbacksSuccess(response.data));
    } catch (e) {
      yield put(
        actions.getCurrentGroupClaimCallbacksFailed(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}