import * as types from "../../lib/actionTypes.es6";


export function getCustomerBankDetailstRequest(payload) {
  return {
    type: types.GET_CUSTOMER_BANK_DETAILS_REQUEST,
    payload,
  };
}


export function getCustomerBankDetailsSuccess(response) {
  return {
    type: types.GET_CUSTOMER_BANK_DETAILS_SUCCESS,
    payload: response,
  };
}

export function getCustomerBankDetailsFailed(error) {
  return {
    type: types.GET_CUSTOMER_BANK_DETAILS_FAILURE,
    payload: error,
  };
}
export function getCustomerBankDetailsRequest(payload) {
  return {
    type: types.GET_CUSTOMER_BANK_DETAILS_REQUEST,
    payload,
  };
}






export function addCustomerBankDetailsRequest(payload) {
  return {
    type: types.ADD_CUSTOMER_BANK_DETAILS_REQUEST,
    payload,
  };
}



export function addCustomerBankDetailsSuccess(response) {
  return {
    type: types.ADD_CUSTOMER_BANK_DETAILS_SUCCESS,
    payload: response,
  };
}

export function addCustomerBankDetailsFailed(error) {
  return {
    type: types.ADD_CUSTOMER_BANK_DETAILS_FAILURE,
    payload: error,
  };
}

export function resetAddCustomerCustomerBankDetailsSuccess() {
  return {
    type: types.RESET_ADD_CUSTOMER_BANK_DETAILS_SUCCESS,
  };
}

export function resetAddCustomerCustomerBankDetailsFailed() {
  return {
    type: types.RESET_ADD_CUSTOMER_BANK_DETAILS_ERROR,
  };
}





//
// delet customer bank details
//

// delete customer bank details actions

export function deleteCustomerBankDetailsRequest(payload) {
  return {
    type: types.DELETE_CUSTOMER_BANK_DETAILS_REQUEST,
    payload,
  };
}

export function deleteCustomerBankDetailsSuccess(response) {
  return {
    type: types.DELETE_CUSTOMER_BANK_DETAILS_SUCCESS,
    payload: response,
  };
}

export function deleteCustomerBankDetailsFailed(error) {
  return {
    type: types.DELETE_CUSTOMER_BANK_DETAILS_FAILURE,
    payload: error,
  };
}


// set customer bank details to delete
export function setCustomerBankDetailsToDelete(customerBankDetails) {
  return {
    type: types.SET_CUSTOMER_BANK_DETAILS_TO_DELETE,
    payload: customerBankDetails,
  };
}
export function resetCustomerBankDetailsToDelete() {
  return {
    type: types.SET_CUSTOMER_BANK_DETAILS_TO_DELETE,
  };
}

// reset error and success on deleting customer bank details
export function resetDeleteCustomerBankDetailsSuccess() {
  return {
    type: types.RESET_DELETE_CUSTOMER_BANK_DETAILS_SUCCESS,
  };
}

export function resetDeleteCustomerBankDetailsFailed() {
  return {
    type: types.RESET_DELETE_CUSTOMER_BANK_DETAILS_ERROR,
  };
}

export function resetAddCustomerBankDetailsSuccess() {
  return {
    type: types.RESET_CREATE_CUSTOMER_BANK_DETAILS_SUCCESS,
  };
}

export function resetAddCustomerBankDetailsFailed() {
  return {
    type: types.RESET_CREATE_CUSTOMER_BANK_DETAILS_ERROR,
  };
}


// end of  deleting customer bank details










//
// edit customer bank details
//



export function editCustomerBankDetailsRequest(payload) {
  return {
    type: types.EDIT_CUSTOMER_BANK_DETAILS_REQUEST,
    payload,
  };
}

export function editCustomerBankDetailsSuccess(response) {
  return {
    type: types.EDIT_CUSTOMER_BANK_DETAILS_SUCCESS,
    payload: response,
  };
}

export function editCustomerBankDetailsFailed(error) {
  return {
    type: types.EDIT_CUSTOMER_BANK_DETAILS_FAILURE,
    payload: error,
  };
}



export function resetEditCustomerBankDetailsSuccess() {
  return {
    type: types.RESET_EDIT_CUSTOMER_BANK_DETAILS_SUCCESS,
  };
}

export function resetEditCustomerBankDEtailsFailed() {
  return {
    type: types.RESET_EDIT_CUSTOMER_BANK_DETAILS_ERROR,
  };
}

export function setCustomerBankDetailsToEdit(bankDetails) {
  return {
    type: types.SET_CUSTOMER_BANK_DETAILS_TO_EDIT,
    payload: bankDetails,
  };
}

export function resetCustomerBankDetailsToEdit() {
  return {
    type: types.RESET_CUSTOMER_BANK_DETAILS_TO_EDIT,
  };
}

