import React from 'react'
import { hashHistory } from 'react-router';
import Grid from "@material-ui/core/Grid";
import TableInc from '../../../../../shared-ui/TableInc';
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import { formatDateLocale } from '../../../../../lib/utils.es6';
import { getPartner } from '../../../../../lib/access.es6';
import SkeletonWrapper from '../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
const DisplayPolicies = (props) => {


if (props.loader) {
  return <SkeletonWrapper loader={props.loader} />
}
const currencyCode = getPartner().country.currency_code

const tempArr = props.policies.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map(policy => {
  return {
    ...policy,
    active: policy.active,
    created_at: formatDateLocale(policy.created_at) || "N/A",
    premium_granularity: (policy.quotation && policy.quotation.total_premium_amount_in_cents)
      ? `${currencyCode}  ${policy.quotation.total_premium_amount_in_cents / 100} per ${policy.quotation.premium_cardinality} ${policy.quotation.premium_granularity}` || "0.00"

      : "N/A",
  }

})


  return (
    <>
    <Grid container spacing={2}>
    <Grid item xs={12} md={12} sm={12}>
      <TableInc
        columns={[
          { title: 'Policy Number', field: 'policy_number', render: rowData => rowData.policy_number || "N/A" },
          { title: 'Branch', field: 'additional_details', render: rowData => rowData.additional_details.branch || "N/A" },

          {
            title: 'Status', field: 'status'
          },
          {
            title: 'Active', field: 'active', render: rowData => <span style={{

              color: `${rowData.active === true ? "green" : "#B3251E"}`
            }}>{rowData.active === true ? "Yes" : "No"}</span>
            
          },
       
          {
            title: 'Premiums', field: 'premium_granularity'
          },
          {
            title: 'Created At', field: 'created_at'
          },
          {
            title: 'action', field: 'claimaction', render: rowData => {
              return <div >
                <ButtonInc
                  onClick={() => hashHistory.push(`admin/policy-processing/${rowData.policy_number}`)}

                  variant="contained">View Policy</ButtonInc>
              </div>;
            }
          },
        ]}
        data={tempArr}
        options={{
          pageSize: 5,
          showTitle: false,
        }} />

    </Grid>
  </Grid>
    </>
  )
}

export default DisplayPolicies