import React from 'react';
import LocalizedStrings from 'react-localization';
import { localisedText } from '../../lib/localisation.es6';

const withLanguage = WrappedComponent =>
  class extends React.Component {
    render() {
      return (
        <WrappedComponent
          {...this.props}
          labels={new LocalizedStrings(localisedText)}
        />
      );
    }
  };

export default withLanguage;
