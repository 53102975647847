import React from "react";
import { connect } from "react-redux";
import PoliciesHeader from "./Components/PaymentsHeader/PaymentsHeader";
import PoliciesWrapper from "./Components/PaymentsWrapper/PaymentsWrapper";
import { getPaymentsDcpRequest } from "./Redux/actions";
import { paymentStatusEnum } from "../lib/enum";

class PaymentsDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentWillMount() {
    const payload = {
      "status":  paymentStatusEnum.Un_Confirmed
    }
    this.props.dispatch(getPaymentsDcpRequest(payload))
  }

  getPolicies(payload) {
    this.props.dispatch(getPaymentsDcpRequest(payload
    ))
  }



  render() {

    return (
      <>
        <PoliciesHeader
          title="Payment Dashboard"
        />

        <PoliciesWrapper
          getPolicies={this.getPolicies.bind(this)}
          payments={this.props.PaymentsDashboardData.payments_dcp}
          loader={this.props.PaymentsDashboardData.get_payments_dcp_loader}
        />
      </>
    )

  }

}
export default connect((state) => ({
  customerData: state.currentCustomer,
  PaymentsDashboardData: state.PaymentsDashboardData,
  globalData: state.global,
}))(PaymentsDashboard);