import React, { useEffect } from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';



const AccordionStyled = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  boxShadow: "none",
  background: "transparent",
  "&:before": {
    display: 'none',
  }
}));
const AccordionDetailsStyled = styled(MuiAccordionDetails)(({ theme }) => ({
  background: "#FFFFFF",
  borderRadius: "0 0 8px 8px",
  marginTop: "-20px",
  height: "auto",
  marginBottom: "20px",
  width: "98%"
}));
const BoxInc = (props) => {

  if(props.hasPermision === false){
    return null
  }
  
  const [expanded, setExpanded] = React.useState(typeof props.expanded === 'boolean' ?  props.expanded : false )

  useEffect(()=>{
    setExpanded(props.expanded)
  },[props.expanded])

  const classes = useStyle();
  return (
    <>

      <AccordionStyled expanded={expanded} style={{ marginTop: '2%' }} className={classes.accordionWrapper} >

        <Grid container   {...props}>

          <section className={classes.statuscard}>
            <div className={classes.statusheader} onClick={() => {
               setExpanded(!expanded) 
               if(props.setExpanded){
                 props.setExpanded(!expanded)
               }
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8}>
                  <h1>{props.title}</h1>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={10} md={10}>
                      <div className={classes.actionbtns}>
                        {
                          props.actionButtons && props.actionButtons.map((button, index) => button)
                        }

                      </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>

                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.expandIconWrapper} onClick={() => { setExpanded(!expanded) }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      > </AccordionSummary>

                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

            </div>

          </section>
        </Grid>
        <AccordionDetailsStyled>
          {
            props.children
          }

        </AccordionDetailsStyled>
      </AccordionStyled>

    </>

  )
}

export default BoxInc