import React, { useEffect, useState } from 'react'
import { hashHistory } from 'react-router';
import { getCustomerRegistrationStatusByGuid } from '../../../../../lib/requests.es6';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import ButtonInc from '../../../../../shared-ui/ButtonInc';

const FailedCustomerRegistration = (props) => {
  const [failedRegistration, setFailedRegistration] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchFailedRegistration = async (query) => {
    setLoading(true)
    try {
      const response = await getCustomerRegistrationStatusByGuid(props.guid);
      setFailedRegistration(response.data);

    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
    setLoading(false)
  };

  useEffect(() => {
    fetchFailedRegistration()

  }, [props.guid])

  function extractPhoneNumber(message) {
    const start = message.indexOf("entry '") + 7;
    const end = message.indexOf("-accessbank");
    return message.substring(start, end);
  }

  const displayErrorMessage = () => {
    if (failedRegistration === null) {
      return
    } else {
      return failedRegistration.message.includes("Duplicate entry") ? `Failed: Another customer is already registered with this phone number: ${extractPhoneNumber(failedRegistration.message)}.  Please use a different phone number.` : failedRegistration.message
    }
  }
  const RegisterAgain = () => {
    hashHistory.push('admin/home');

  }

  // const errorMessage = failedRegistration.message.includes("Duplicate entry") ? `Access Bank Message` : failedRegistration.message

  return (
    <>
      {
        loading ? (<p>Checking registration status....</p>) : <Stack sx={{ width: '50vw' }} spacing={2}>
          <Alert
            severity="error"
            action={
              <ButtonInc onClick={RegisterAgain}>
                Register Again
              </ButtonInc>
            }
          >
            {displayErrorMessage()}

          </Alert>
        </Stack>
      }


    </>
  )
}

export default FailedCustomerRegistration