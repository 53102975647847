import Dialog from '@material-ui/core/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@mui/material/Grid';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import { getS3BucketSignedUrlForPartnerTemplateDocuments } from '../../../lib/requests.es6';
import { PRIMARY_THEME_COLOR_SHADE_1 } from '../../../lib/constants';
import { documentTypes } from '../index';

export function EditPartnerDocument(props) {
  const {
    showEditDialog,
    labels,
    onEdit,
    s3Url,
    document,
    setShowEditDialog,
  } = props;
  const [name, setName] = useState(null);
  const [type, setType] = useState(null);
  const [filename, setFilename] = useState(null);
  const [contentUrl, setContentUrl] = useState(null);

  const handleFinishedUpload = info => {
    setContentUrl(info.fileUrl.replace('undefined', info.bucketKey));
    setFilename(info.bucketKey);
  };

  const saveDocumentTemplate = () => {
    const payload = {
      ...document,
      name,
      type: type.value,
      filename,
      content_url: contentUrl,
    };

    onEdit(payload);
    setShowEditDialog(false);
  };

  const renderUploader = () => {
    const style = {
      margin: '5%',
    };

    const getS3Ur = (file, callback) =>
      getS3BucketSignedUrlForPartnerTemplateDocuments(file, callback);

    const uploadOptions = {
      getSignedUrl: getS3Ur,
      s3Url,
      uploadRequestHeaders: {},
    };

    return (
      <div className="form-group" style={{ marginTop: 10 }}>
        <div>
          <center>
            <DropzoneS3Uploader
              onFinish={handleFinishedUpload}
              maxSize={1024 * 1024 * 5}
              upload={uploadOptions}
              s3Url={s3Url}
              passChildrenProps={false}
            >
              <div style={style}>
                <h4>{labels.dragAndDrop}</h4>
                <h4>{labels.Or}</h4>
                <h4>{labels.clickUpload}</h4>
              </div>
            </DropzoneS3Uploader>
          </center>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!document) {
      return;
    }

    setContentUrl(document.content_url);
    setName(document.name);
    setFilename(document.filename);

    const found = documentTypes.find(x => x.value === document.type);
    setType(found);

    return function cleanup() {};
  }, [document]);

  return (
    <Dialog
      open={showEditDialog}
      onClose={() => setShowEditDialog(false)}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="lg"
      fullScreen
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setShowEditDialog(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {labels.addDocumentTemplate}
          </Typography>
        </Toolbar>
      </AppBar>
      <ValidatorForm onSubmit={saveDocumentTemplate}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid xs={12} md={6} lg={5} item>
              <TextValidator
                onChange={event => setName(event.target.value)}
                value={name}
                label={labels.name}
                name={labels.name}
                fullWidth
                variant="outlined"
                placeholder={labels.name}
                required
                validators={['required']}
                errorMessages={['Please enter template name']}
              />
            </Grid>
            <Grid xs={12} md={6} lg={5} item>
              <Autocomplete
                renderInput={params => (
                  <TextField
                    {...params}
                    label={labels.documentType}
                    placeholder={labels.documentType}
                    required
                    variant="outlined"
                  />
                )}
                fullWidth
                value={type}
                getOptionLabel={a => labels[a.label] || ''}
                onChange={(event, newValue) => {
                  setType(newValue);
                }}
                id="controllable-states-demo"
                options={documentTypes.sort((a, b) =>
                  labels[a.label].localeCompare(labels[b.label])
                )}
              />
            </Grid>
            <Grid xs={12} md={6} lg={5} item>
              <TextField
                value={filename}
                label={labels.filename}
                name={labels.filename}
                fullWidth
                variant="outlined"
                placeholder={labels.filename}
                disabled
              />
            </Grid>
            <Grid xs={12} item>
              {renderUploader()}
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                variant="contained"
                type="submit"
                style={{ marginRight: '2%' }}
              >
                {' '}
                Save{' '}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginRight: '2%' }}
                onClick={() => setShowEditDialog(false)}
              >
                {' '}
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </ValidatorForm>
    </Dialog>
  );
}
