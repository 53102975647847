import React, { Component } from 'react'
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import TimerRoundedIcon from '@material-ui/icons/TimerRounded';
import LocalHospitalRoundedIcon from '@material-ui/icons/LocalHospitalRounded';
import LocalAtmRoundedIcon from '@material-ui/icons/LocalAtmRounded';
import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import ChildCareRoundedIcon from '@material-ui/icons/ChildCareRounded';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import EmojiPeopleRoundedIcon from '@material-ui/icons/EmojiPeopleRounded';
import EventAvailableRoundedIcon from '@material-ui/icons/EventAvailableRounded';
import ContactPhoneRoundedIcon from '@material-ui/icons/ContactPhoneRounded';
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded';
import FingerprintRoundedIcon from '@material-ui/icons/FingerprintRounded';
import { Grid } from '@material-ui/core'
import BritamFaqs from '../BritamFaqs';


export default class BritamInfoSections extends Component {
  render() {
    return (
      <div>
        <Grid container style={{ textAlign: "center", margin: "auto", marginBottom: "0px" }}>

          <Grid container style={{ fontSize: "40px", width: "100%", backgroundColor: "#06c", color: "white", marginBottom: "70px" }}>
            <Grid container style={{ width: "80%", margin: "auto", paddingTop: "5%", paddingBottom: "5%", }}>
              <Grid item xs={12} sm={12} md={12}><h1 style={{ textAlign: "center", paddingBottom: "5%" }}>What are the Benefits?</h1></Grid>
              <Grid item xs={6} sm={3} md={3}>
                <p><MonetizationOnRoundedIcon style={{ fontSize: "40px" }} /></p>
                <h5> Cash payment for funeral expenses.</h5>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <p><LocalHospitalRoundedIcon style={{ fontSize: "40px" }} /></p>
                <h5> Covers natural or accidental death.</h5>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <p><LocalHospitalRoundedIcon style={{ fontSize: "40px" }} /></p>
                <h5> Cover extends to riots and HIV.</h5>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <p><LocalAtmRoundedIcon style={{ fontSize: "40px" }} /></p>
                <h5> 50% of the annual premium is refunded if there is no claim within 3 years.</h5>
              </Grid>
              <br />
              <br />
              <Grid item xs={6} sm={3} md={3}>
                <p><MonetizationOnRoundedIcon style={{ fontSize: "40px" }} /></p>
                <h5> Cash payment for funeral expenses.</h5>
              </Grid>

              <Grid item xs={6} sm={3} md={3}>
                <p><TimerRoundedIcon style={{ fontSize: "40px" }} /></p>
                <h5> No waiting period for accidental death.</h5>
              </Grid>

              <Grid item xs={6} sm={3} md={3}>
                <p><TimerRoundedIcon style={{ fontSize: "40px" }} /></p>
                <h5> 1 month waiting period for death due to natural causes for policy holder and dependants</h5>
              </Grid>

              <Grid item xs={6} sm={3} md={3}>
                <p><TimerRoundedIcon style={{ fontSize: "40px" }} /></p>
                <h5> 3 months waiting period for parents and parents-In-law for naturaldeath.</h5>
              </Grid>
            </Grid>
          </Grid>

          <Grid container style={{ fontSize: "40px", width: "100%", color: "#06c", marginBottom: "70px" }}>
            <Grid container style={{ width: "80%", margin: "auto", paddingTop: "5%", paddingBottom: "5%", }}>
              <Grid item xs={12} sm={12} md={12}><h1 style={{ textAlign: "center", paddingBottom: "5%" }}>Who Qualifies?</h1></Grid>
              <Grid item xs={6} sm={4} md={4}>
                <p><AccountBalanceRoundedIcon style={{ fontSize: "40px" }} /></p>
                <h5> Registered groups such as; SMEs, Microfinance Institutions, Sacco’s, and
Investment clubs</h5>
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <p><EmojiPeopleRoundedIcon style={{ fontSize: "40px" }} /></p>
                <h5> The policy owner must be an employee or member of the insuredgroup.</h5>
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <p><DateRangeRoundedIcon style={{ fontSize: "40px" }} /></p>
                <h5> Main member/ spouse- minimum entry age 18 years, maximum entry
age 65 years and maximum coverage age 70 years.</h5>
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <p><ChildCareRoundedIcon style={{ fontSize: "40px" }} /></p>
                <h5> Children- minimum entry age 1 month old, maximum entry age 18 years
and maximum coverage age 18 years – 25 years for fulltime students.</h5>
              </Grid>
              <br />
              <br />
              <Grid item xs={6} sm={4} md={4}>
                <p><PeopleRoundedIcon style={{ fontSize: "40px" }} /></p>
                <h5> Parents/ parents - in- law - minimum entry age 40 years, maximum
entry age 75 years.</h5>
              </Grid>

            </Grid>
          </Grid>

          <Grid container style={{ fontSize: "40px", width: "100%", backgroundColor: "#06c", color: "white", marginBottom: "70px" }}>
            <Grid container style={{ width: "70%", margin: "auto", paddingTop: "5%", paddingBottom: "5%", }}>
              <Grid item xs={12} sm={12} md={12}><h1 style={{ textAlign: "center", paddingBottom: "5%" }}>What are the features of the cover?</h1></Grid>
              <Grid item xs={6} sm={4} md={4}>
                <p><PeopleRoundedIcon style={{ fontSize: "40px" }} /></p>
                <h5> Covers the principal, spouse, children, parents and parents-in-law.</h5>
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <p><MonetizationOnRoundedIcon style={{ fontSize: "40px" }} /></p>
                <h5> Minimum premium is Kes. 150,000 per group payable per annum.</h5>
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <p><EventAvailableRoundedIcon style={{ fontSize: "40px" }} /></p>
                <h5>Term of cover: Annual.</h5>
              </Grid>

            </Grid>
          </Grid>

          <Grid container style={{ fontSize: "40px", width: "100%", color: "#06c", marginBottom: "70px" }}>
            <Grid container style={{ width: "80%", margin: "auto", paddingTop: "5%", paddingBottom: "5%", }}>
              <Grid item xs={12} sm={12} md={12}><h1 style={{ textAlign: "center", paddingBottom: "5%" }}>What do I require to get the cover?</h1></Grid>
              <Grid item xs={6} sm={4} md={4}>
                <p><FingerprintRoundedIcon style={{ fontSize: "40px" }} /></p>
                <h5> Copy of ID or Passport</h5>
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <p><ContactPhoneRoundedIcon style={{ fontSize: "40px" }} /></p>
                <h5>Mobile number</h5>
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <p><ListAltRoundedIcon style={{ fontSize: "40px" }} /></p>
                <h5>Complete proposal form / application form</h5>
              </Grid>

            </Grid>
          </Grid>
          <BritamFaqs />

          <Grid container style={{ fontSize: "40px", width: "100%", backgroundColor: "#06c", color: "white", marginBottom: "0px" }}>
            <Grid container style={{ width: "80%", margin: "auto", padding: "2%" }}>
              <Grid item xs={12} sm={12} md={12}><h1 style={{ textAlign: "center" }}>Contact Us</h1></Grid>
              <Grid item xs={12} sm={12} md={12} style={{ textAlign: "centre" }}>
                <h5> Tel: 0705 100 100</h5>
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{ textAlign: "centre" }}>
                <h5> email: info@britam.com</h5>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </div>
    )
  }
}
