import React, {useState,useEffect } from 'react'
import TableInc from '../../../../../../shared-ui/TableInc';
import { updateExtendedConfig } from '../../../../../../lib/requests.es6';

const LoanIntrestRateTable = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.membership_configs && props.membership_configs.content) {
      const {  loan_interest_rate } = props.membership_configs.content;
      const combinedData = [{
        loan_interest_rate: loan_interest_rate ,
      }];

      setData(combinedData);
    }
  }, [props.membership_configs]);


  const updateMembershipConfigs = async (newData) => {
    const adjustedInterestRate = newData.loan_interest_rate / 100;

    const payload = {
      "product_guid": props.productGuid,
      ...props.membership_configs,
      "content": {
        ...props.membership_configs.content,
        loan_interest_rate: adjustedInterestRate
      }
    }
    console.log("payload", payload)

    await updateExtendedConfig(payload)

    window.location.reload(true);
  };
  return (
    <TableInc
    title="Loan Interest Rate"
      columns={[
        { title: 'Loan Interest Rate (%)', field: 'loan_interest_rate' },
      ]}
      data={data}
      options={{
        addRowPosition: 'first',
        showTitle: true,
        actionsColumnIndex: -1,
        pageSize: 1, // Since we have a single row
      }}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            console.log("newData", newData)
            setData([newData]);  // Update single-row data
            updateMembershipConfigs(newData);  
            resolve();
          }),

        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setData([newData]); 
            updateMembershipConfigs(newData);  
            resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            setData([oldData]);  
            updateMembershipConfigs(oldData);  
            resolve();
          }),
      }}
    />
  )
}

export default LoanIntrestRateTable