import React, { useEffect, useState } from 'react'
import TableInc from '../../../../../shared-ui/TableInc';
import { createDebitOrderz, deleteDebitOrderz } from '../../../../../lib/requests.es6';
import {  DEBIT_ORDER_SOUTH_AFRICA_BANKS_NAME } from '../../../../../containers/NewProductList/PoductConstants';
import { formatNumberWithCommaIncents, getPartner, getPartnerGuid } from '../../../../../lib/access.es6';
import { formatDateLocale } from '../../../../../lib/utils.es6';
const DebitOrders = (props) => {
  const [policyGuid, setPolicyGuid] = useState('');

  useEffect(() => {
    if (!props.policy || !props.policy.length) {
      return;
    }
    if (props.policy.length === 1) {
      setPolicyGuid(props.policy[0].guid)
    }
  }, [props.policy]);

  const currencyCode = getPartner().country.currency_code


  const banksLookUp = ()=> {
    const lookups = {}
    if(DEBIT_ORDER_SOUTH_AFRICA_BANKS_NAME){
      for(let key in DEBIT_ORDER_SOUTH_AFRICA_BANKS_NAME){
        const bankName = DEBIT_ORDER_SOUTH_AFRICA_BANKS_NAME[key].name
        lookups[bankName] = bankName
      }
      return lookups
    }
   return {}
   
  }

  const banksLookup =  banksLookUp()
  return (
    <>
 
    
      <TableInc
        columns={[
          { title: 'Bank Account Number', field: 'bank_account_number' },
          { title: 'Bank Name', field: 'bank_name', lookup: banksLookup },
          { title: 'Branch Code', field: 'branch_code', editable: 'never' },
          { title: 'Phone Number', field: 'msisdn' },
          {
            title: 'Amount', field: 'amount_in_cents', render: rowData => <>
              {
                currencyCode + " " + (rowData.amount_in_cents / 100)
              }

            </>
          },
          {
            title: 'Day', field: 'at'
          },
          {
            title: 'Start Date', field: 'start_date', type: "date", render: rowData =>
              <>
                {
                  formatDateLocale(rowData.start_date)
                }
              </>
          },
          { title: 'Status', field: 'status', editable: 'never' },
        ]}
        data={
          props.debitOrders
        }
        options={{
          pageSize: props.debitOrders.length + 1,
          showTitle: false,
          actionsColumnIndex: -1

        }}

        editable={{

          onRowAdd: newData =>
            new Promise((resolve, reject) => {

              console.log('newData: ', newData);
              const debitOrdersDetails = {
                "start_date": newData.start_date,
                "msisdn": newData.msisdn,
                "bank_account_number": newData.bank_account_number,
                "bank_name": DEBIT_ORDER_SOUTH_AFRICA_BANKS_NAME[newData.bank_name].name,
                "branch_code": DEBIT_ORDER_SOUTH_AFRICA_BANKS_NAME[newData.bank_name].branch_code,
                "policy_guid": policyGuid,
                "partner": getPartnerGuid(),
                "granularity": 'Month',
                "day": Number(newData.at),
                "amount_in_cents": formatNumberWithCommaIncents(newData.amount_in_cents * 100),
                "mno_reference": props.msisdn + newData.bank_account_number
              }
              console.log("debitOrdersDetails", debitOrdersDetails)
              createDebitOrderz(debitOrdersDetails).then(
                (response) => {
                  console.log("here is response", response);
                  resolve();
                  window.location.reload();
                }
              ).catch(
                (error) => {
                  console.log("did not work", error)
                  reject();
                }
              )

            }),

            onRowDelete: oldData =>
            new Promise((resolve, reject) => {        
              deleteDebitOrderz(oldData).then(
                (response) => {
                  resolve();
                  window.location.reload();
                }
              ).catch(
                (error) => {
                  reject();
                }
              )
              resolve();
            }),
        }}
      />
    </>
  )
}

export default DebitOrders