import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  claimsheader: {
    background: "#FFFFFF",
    borderRadius: "8px",
  },
  claimstitle: {
    padding: "10px 0 10px 0",
    "& ul": {
      listStyle: "none",
      margin: "0",
      "& li": {
        display: "inline-block",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.0015em",
        color: "#000000",
        marginLeft: "10px",
      }
    },
  },

  claimStatussuccess: {
    color: "#000",
    display:" inline-block",
    height: "32px",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "32px",
    padding: "0 12px",
    borderRadius: "16px",
    backgroundColor: "rgba(95, 146, 72, 0.3)",
    marginBottom: "5px",
    marginRight: "5px",
  },
  claimStatusdanger:{
    color: "#000",
    display:" inline-block",
    height: "32px",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "32px",
    padding: "0 12px",
    borderRadius: "16px",
    backgroundColor: " rgba(243, 125, 128, 0.3)",
    marginBottom: "5px",
    marginRight: "5px",
  },
  claimactionbtn:{
    textAlign: "center",
    paddingTop: "10px",
    "& li":{
      listStyle: "none"
    }
  },
  waitingPeridod:{
    color: "#fff",
    display:" inline-block",
    height: "32px",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "32px",
    padding: "0 12px",
    borderRadius: "16px",
    backgroundColor: "#183e4b",
    marginBottom: "5px",
    marginRight: "5px",
  }

}));