import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../lib/actionTypes.es6';
import {
  registerGroup, getActualGroupQuote, updateGroup, getGroup, preRegisterGroup
} from '../../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../../lib/access.es6';
import { GAloggerException } from '../../../lib/monitoring.es6';


export function* preRegisterGroupRequestWatcher() {

  while (true) {
    try {
      const { payload } = yield take(types.PRE_REGISTER_GROUP_REQUEST);

      const response = yield call(preRegisterGroup, payload);
      yield put(actions.preRegisterGroupSuccess(response.data));
    } catch (e) {
      let errorPayload = { message: 'An error occurred, this could be due to invalid data' };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      } yield put(actions.preRegisterGroupFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* registerGroupRequestWatcher() {

  while (true) {
    try {
      const { payload } = yield take(types.REGISTER_GROUP_REQUEST);

      const response = yield call(registerGroup, payload);
      yield put(actions.registerGroupSuccess(response.data));
    } catch (e) {
      let errorPayload = { message: 'An error occurred, this could be due to invalid data' };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      } yield put(actions.registerGroupFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* getActualGroupQuoteRequestWatcher() {

  while (true) {
    try {
      const { payload } = yield take(types.GET_ACTUAL_GROUP_QUOTE_REQUEST);

      const response = yield call(getActualGroupQuote, payload);
      yield put(actions.getActualGroupQuoteSuccess(response.data));
    } catch (e) {
      let errorPayload = { message: 'An error occurred, this could be due to invalid data' };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      } yield put(actions.getActualGroupQuoteFailed(errorPayload));
      GAloggerException(e);
    }
  }
}


export function* updateGroupRequestWatcher() {

  while (true) {
    try {
      const { payload } = yield take(types.UPDATE_GROUP_REQUEST);

      const response = yield call(updateGroup, payload);
      yield put(actions.updateGroupSuccess(response.data));
      yield put(actions.getGroupRequest(payload.groupId));
    } catch (e) {
      let errorPayload = { message: 'An error occurred, this could be due to invalid data' };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      } yield put(actions.updateGroupFailed(errorPayload));
      GAloggerException(e);
    }
  }
}


export function* getGroupRequestWatcher() {

  while (true) {
    try {
      const { payload } = yield take(types.GET_GROUP_REQUEST);

      const response = yield call(getGroup, payload);
      yield put(actions.getGroupSuccess(response.data));
    } catch (e) {
      let errorPayload = { message: 'An error occurred, this could be due to invalid data' };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      } yield put(actions.getGroupFailed(errorPayload));
      GAloggerException(e);
    }
  }
}