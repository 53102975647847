import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  alertswrappersuccess: {
    background: "#F1F9F4",
    border: "1px solid #4B7E34",
    borderRadius: "20px",
    padding: "25px 0 25px 0 !important"
  },
  alertswrappererror: {
    background: "#FBEEE9",
    border: "1px solid #C73932",
    borderRadius: "20px",
    padding: "25px 0 25px 0 !important",
    marginTop: "40px"
  },
  alertswrapperwarning:{
    background: "#FEF7EA",
    border: "1px solid #FDC023",
    borderRadius: "20px",
    padding: "25px 0 25px 0 !important",
    marginTop: "40px"
  },
  alertswrapperinfo:{
    background: "#E7EEFA",
    border: "1px solid #3286E9",
    borderRadius: "20px",
    padding: "25px 0 25px 0 !important",
    marginTop: "40px"
  },
  iconwrapper: {
    background: "#75A85E",
    height: "86px",
    width: "86px",
    borderRadius: "100%",
    textAlign: "center",
    paddingTop: "28px",
    margin: "auto"
  },
  iconwrappererror:{
    background: "#B3251E",
    height: "86px",
    width: "86px",
    borderRadius: "100%",
    textAlign: "center",
    paddingTop: "28px",
    margin: "auto"
  },
  iconwrapperwarning:{
    background: "#FEBF21",
    height: "86px",
    width: "86px",
    borderRadius: "100%",
    textAlign: "center",
    paddingTop: "28px",
    margin: "auto"
  },
  iconwrapperinfo:{
    background: "#3286E9",
    height: "86px",
    width: "86px",
    borderRadius: "100%",
    textAlign: "center",
    paddingTop: "28px",
    margin: "auto"
  },
  contentwrapper: {
    "& h4": {
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "22px",
      lineHeight: "28px",
      color: "#000000"
    },
    "& p": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000"
    },
    "& ul": {
      padding: "0",
      display: "flex",
      "& button:nth-child(1)": {
        background: "#75A85E",
        borderRadius: "8px",
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "15px",
        color: "#fff",
      },
      "& button:nth-child(2)": {
        background: "#fff",
        borderRadius: "8px",
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "15px",
        color: "#75A85E",
        border: "1px solid #75A85E",
        marginLeft: "20px",
        boxShadow: "none"
      }
    }
  },

  closeiconwrapper:{
    textAlign: "right",
    "& svg":{
      marginRight:" 15px",
      cursor: "pointer"
    }
  }
}));