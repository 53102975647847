import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { CircularProgress, InputAdornment } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AlertDialog from "../../../../../components/AlertDialog/index.es6";
import { PAYMENT_METHODS } from "../../../PoductConstants";
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../../../lib/constants";

let initialState = {
  granularity: '',
  payment_method: '',
  cardinality: '',
  amount_in_cents: '',
  guid: ''
}
export default function EditPremium(props) {

  if(!props.premium){
   return ""
  }

  let premium = props.premium
  premium.amount_in_cents = props.premium.amount_in_cents / 100
  initialState = premium

  const [
    {
      granularity,
      payment_method,
      cardinality,
      amount_in_cents,
      guid,
    }, setState
  ] = React.useState(initialState)



  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };


  const reset = () => {
    setState(initialState);
  }


  const submit = () => {
    const payload = {
      ...props.premium,
      granularity,
      payment_method,
      cardinality: Number(cardinality),
      amount_in_cents: Number(amount_in_cents) * 100,
      guid,
    }
    props.editPremium(payload)
  }

  const handlePremiumEditedSuccessfully = () => {
    props.resetEditPremiumSuccessAlert()
    props.resetPremiumToEdit()
    reset()
  }

  const handlePremiumCreateError = () => {
    props.resetEditPremiumErrorAlert()
  }

    return (
      <React.Fragment>
        <AlertDialog
          custom
          show={props.showEditPremiumProgressAlert}
          size="sm"
          style={{ marginTop: '0', top: '30vh' }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={'cancel'}
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2>Updating premium</h2>
        </AlertDialog>

        <AlertDialog
          success
          show={props.showEditPremiumSuccessAlert}
          size="sm"
          title={'Premium updated'}
          style={{ marginTop: '0', top: '30vh' }}
          onConfirm={() => { handlePremiumEditedSuccessfully() }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={'cancel'}
          confirmBtnCssClass
        >
          <div >
            <Button variant="contained" onClick={() => { handlePremiumEditedSuccessfully() }} style={{ background: 'green', }}>Ok</Button>
          </div>
        </AlertDialog>

        <AlertDialog
          show={props.showEditPremiumErrorAlert}
          danger
          title={'Error updating premium'}
          onConfirm={() => handlePremiumCreateError()}
          confirmBtnText={'Try again'}
          confirmBtnCssClass
          showCancel={false}
          style={{ marginTop: '0', top: '30vh' }}
        >
          {props.editPremiumError ? props.editPremiumError.message : ''}
        </AlertDialog>





        <Dialog
          open={props.premium} fullWidth
          maxWidth='lg'
          onClose={props.resetPremiumToEdit}
          fullScreen
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={props.resetPremiumToEdit}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Edit Premium
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <div style={{ marginTop: "2%" }}>
              <Box sx={{ width: "100%" }}>
                <ValidatorForm onSubmit={() => submit()} >
                  <Grid container spacing={2}>
                    <Grid item lg={3} md={6} xs={12}>

                      <TextValidator
                        onChange={onChange}
                        value={amount_in_cents}
                        label='amount'
                        name='amount_in_cents'
                        fullWidth
                        variant="outlined"
                        type="number"
                        placeholder='amount'
                        validators={['required']}
                        errorMessages={['Please enter amount']}
                        InputProps={{
                          startAdornment: (<InputAdornment position="start">
                          {props.currencyCode}
                          </InputAdornment>),

                          'aria-label': 'Ksh',
                        }}
                      />
                    </Grid>


                    <Grid item lg={3} md={6} xs={12}>
                      <SelectValidator
                        value={payment_method}
                        label='payment method'
                        id="payment method"
                        name='payment_method'
                        onChange={onChange}
                        className="form-control"
                        type="text"
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Please select payment method']}
                      >
                        <MenuItem value="">{'Please select payment method'}</MenuItem>
                        {
                          PAYMENT_METHODS.map((type) => {
                            return <MenuItem value={type}>{type}</MenuItem>
                          })
                        }
                      </SelectValidator>
                    </Grid>

                    <Grid item lg={3} md={6} xs={12}>
                      <SelectValidator
                        value={granularity}
                        label='granularity'
                        id="granularity"
                        name='granularity'
                        onChange={onChange}
                        className="form-control"
                        type="text"
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Please select granularity']}
                      >
                        <MenuItem value="">{'Please select granularity'}</MenuItem>
                        {
                          ['Day', 'Week', 'Month', 'Year'].map((type) => {
                            return <MenuItem value={type}>{type}</MenuItem>
                          })
                        }
                      </SelectValidator>
                    </Grid>



                    <Grid item lg={3} md={6} xs={12}>

                      <TextValidator
                        onChange={onChange}
                        value={cardinality}
                        label='cardinality'
                        name='cardinality'
                        fullWidth
                        variant="outlined"
                        type="number"
                        placeholder='amount'
                        validators={['required']}
                        errorMessages={['Please enter cardinality']}
                      />
                    </Grid>

                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Button variant="contained" type="submit" style={{ marginRight: '2%' }}> Update </Button>
                      <Button variant="contained" color="secondary" style={{ marginRight: '2%' }} onClick={() => props.resetPremiumToEdit()}> Cancel</Button>
                    </Grid>

                  </Grid>
                </ValidatorForm>
              </Box>
            </div>

          </DialogContent>
        </Dialog>
      </React.Fragment>

    );



}
