import React, { useState } from 'react'
import { useStyle } from "./Style";
import { Box, Grid } from '@mui/material';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Button from '@mui/material/Button';
import { formatDateLocale } from '../../lib/utils.es6';
import { getPartner } from '../../lib/access.es6';

const DependantsCardInc = (props) => {
  const classes = useStyle();
  const currencyCode = getPartner().country.currency_code
  const dependants = (props.data.quotation && props.data.quotation.policy_schedule_entries) ? props.data.quotation.policy_schedule_entries : null

  const [expanded, setExpanded] = useState(false)

  const dataForDisplay = expanded ? dependants : dependants.slice(0, 2)

  if (props.data && dependants) {

    return (
      <>
        {
          <p style={{
            textAlign: 'right', fontFamily: "open sans",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
            marginTop: "20px"
          }}> Total Premium : {currencyCode + " " + props.data.quotation.total_premium_amount_in_cents / 100}</p>
        }
        {
          dataForDisplay.map((entry, index) => (
            <>
              <Grid container className={classes.policyWrapper} key={index} >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container className={classes.policyHeader}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Grid container>
                        <Grid item xs={12} md={2} sm={2} className={classes.policyIconWrapper}   >
                          <Box className={classes.policyIcons}>
                            <NoteAddOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={10} sm={10} className={classes.policyHeaderContent}>
                          <h6>{entry.dependant.first_name + " " + entry.dependant.last_name || "N/A"}</h6>
                          <p> Full Name</p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} >

                      <Grid container>
                        <Grid item xs={12} md={6} sm={6}>
                          <div >

                          </div>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} className={classes.policyActiveStatusWrapper}>
                          <Box display="flex" justifyContent="flex-end">
                            <ul>
                              <li className={entry.active === true ? classes.activePill : classes.inactivePill}> <span>{entry.active === true ? `Active` : `Inactive`}</span></li>
                            </ul>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <div className={classes.policyHeaderBorder}></div>
                  </Grid>

                  <Grid container spacing={2} className={classes.policyContentWrapper}>
                    <Grid item xs={12} sm={3} md={3} >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3} md={3}></Grid>
                        <Grid item xs={12} sm={9} md={9}>
                          <p>Date Of Birth </p>
                          <h6> {entry.dependant.date_of_birth || "N/A"}</h6>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p> Gender</p>
                      <h6>{entry.dependant.gender || "N/A"}
                      </h6>

                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p>National ID</p>
                      <h6>{entry.dependant.national_id || "N/A"}</h6>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p>Town</p>
                      <h6>{
                        entry.dependant.town || "N/A"
                      }</h6>
                    </Grid>


                  </Grid>
                  <div className={classes.policyContentBorder}></div>

                  <Grid container spacing={2} className={classes.policyContentWrapper}>
                    <Grid item xs={12} sm={3} md={3} >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3} md={3}></Grid>
                        <Grid item xs={12} sm={9} md={9}>
                          <p>Relationship </p>
                          <h6> {entry.dependant.relationship || "N/A"}</h6>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p> Work Address</p>
                      <h6>{entry.dependant.work_address || "N/A"}
                      </h6>

                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p>Postal Adress</p>
                      <h6>{entry.dependant.postal_address || "N/A"}</h6>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <p>Passport </p>
                      <h6>{
                        entry.dependant.passport || "N/A"
                      }</h6>
                    </Grid>

                  </Grid>
                  <div className={classes.policyContentBorder}></div>
                  <Grid container spacing={2} className={classes.policyFooterWrapper}>
                    <Grid item xs={12} md={6} sm={6}>
                      <Grid container spacing={2} >


                        <Grid item xs={12} md={6} sm={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={3} md={3}></Grid>
                            <Grid item xs={12} sm={9} md={9}>
                              <p>Created At</p>
                              <h6>{formatDateLocale(entry.dependant.created_at) || "N/A"}</h6>

                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                          <p> Benefits</p>
                          {
                            <ol>
                              {
                                entry.benefits.map(benefit => {
                                  return <li>
                                    {benefit.type} - {<strong> {currencyCode + " " + (benefit.cover_amount_in_cents / 100).toLocaleString()}</strong>}
                                  </li>
                                })
                              }
                            </ol>
                          }
                        </Grid>

                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <div className={classes.policyBalance}>
                        <p>Premiums</p>
                        <h6>{`${currencyCode}  ${entry.premium_amount_in_cents / 100}` || "0.00"}    </h6>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </>
          ))
        }
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            {
              dataForDisplay.length > 1 && (
                <Button style={{
                  background: "#FBA92D",
                  borderRadius: "8px",
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "15px",
                  boxShadow: "none",
                  padding: "10px",
                  marginTop: "20px",
                  textTransform: "capitalize",
                }} variant="contained" size="large" endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} onClick={() => setExpanded(!expanded)}>
                  {expanded ? `Show Less` : `Show More`}
                </Button>
              )
            }


          </Grid>
        </Grid>

      </>

    )
  } else {
    return "No Dependants Found"
  }
}

export default DependantsCardInc