import * as types from '../../../../../../lib/actionTypes.es6'

export const initialState = {

  get_sms_activity_errors: null,
  get_sms_activity_loader: false,
  get_sms_activity_success_message: '',
  smsActivities: [],

};

export default function orangeSmsActivityReducer(state = initialState, action) {
  switch (action.type) {

      case types.GET_ORANGE_SMS_ACTIVITY_REQUEST:
        return { ...state, get_sms_activity_loader: true, get_sms_activity_errors: null };
      case types.GET_ORANGE_SMS_ACTIVITY_SUCCESS:
        return { ...state, get_sms_activity_loader: false, get_sms_activity_errors: null, smsActivities: action.payload };
      case types.GET_ORANGE_SMS_ACTIVITY_FAILURE:
        return { ...state, get_sms_activity_loader: false, get_sms_activity_errors: action.payload };
  

    default:
      return state;
  }
}

