import { take, call, put } from 'redux-saga/effects';
import * as types from '../../lib/actionTypes.es6';
import { getLanguages } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

export function* getLanguagesWatcher() {
  while (yield take(types.GET_LANGUAGES_REQUEST)) {
    try {
      const response = yield call(getLanguages);
      yield put(actions.getLanguagesSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.getLanguagesFailure(errorPayload));
    }
  }
}
