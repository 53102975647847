import React from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box } from '@mui/material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';


// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import LocalizationSection from './LocalizationSection';
//import NotificationSection from './NotificationSection';
import SelectPartner from '../Header/SelectPartner/SelectPartner';
import DisplaySearchResults from '../../../containers/DisplaySearchResults/index.es6';



// assets

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = (props) => {
    const theme = useTheme();

    return (
        <>
            {/* logo & toggler button */}

            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>

                <Avatar
                    variant="rounded"
                    sx={{
                        borderRadius: "8px",
                        width: "34px",
                        height: "34px",
                        marginTop: "10px",
                        fontSize: "1.2rem",
                        overflow: 'hidden',
                        transition: 'all .2s ease-in-out',
                        background: "#032A37",
                        color: "#fff",
                        cursor: "pointer",
                        '&:hover': {
                            background: "#032A37",
                            color: "#fff"
                        }
                    }}
                    onClick={() => props.openDrawer()}
                    // onClick={() => this.props.dispatch(openDrawer())}
                    color="inherit"
                >
                    <MenuOutlinedIcon sx={{
                        width: "20px",
                        height: "20px"
                    }} />
                </Avatar>
            </Box>

            {/* header search */}


            <DisplaySearchResults />

            <SearchSection

                // pass existing search props
                searchForCustomer={props.searchForCustomer}
                customerList={props.customerList}
                resetSearch={props.resetSearch}
                selectCustomer={props.selectCustomer}
                searchData={props.searchData}
                dispatch={props.dispatch}

            />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* choose partner */}
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <SelectPartner selectPartner={props.selectPartner} username={props.username} />
                 
            </Box>
            {/*localization */}
            <Box sx={{ display: { xs: 'none', sm: 'block' }, mr: '0.5%' }}>
                <LocalizationSection
                    onSelectLanguage={props.onSelectLanguage}
                    selectedLanguage={props.selectedLanguage} 
                />
            </Box>
            {/* notification  */}
            {/* <NotificationSection /> */}
            {/*   profile */}
            <ProfileSection
                logOutUserHandler={props.logOutUserHandler}
            />

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection selectPartner={props.selectPartner} username={props.username} />
            </Box>

        </>
    );
};

export default Header;
