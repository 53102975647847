import React, { useState } from 'react'
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import { getPartner } from '../../../../../lib/access.es6'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const QuotesDependants = (props) => {
  const classes = useStyle();


  const [expanded, setExpanded] = useState(false)

  const currencyCode = getPartner().country.currency_code
  const dependants = (props.policy && props.policy.policy_schedule_entries) ? props.policy.policy_schedule_entries : null

  if (props.policy && dependants) {

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} >
            <div className={classes.policieswrapper}>
              <table>
                <tr>
                  <th> Name</th>
                  <th>Relationship</th>
                  <th>Benefits</th>
                  <th>Premium</th>
                </tr>

                {
                  dependants.map((entry, index) => (
                    (
                      <>
                        <tr key={index}>
                          <td>{entry.dependant.first_name + " " + entry.dependant.last_name}</td>
                          <td>{entry.dependant.relationship}</td>
                          <td>
                            <ol>
                              {
                                entry.benefits.map(benefit => {
                                  return <li>
                                    {benefit.type} - {<strong> {currencyCode + " " + benefit.cover_amount_in_cents.toLocaleString()}</strong>}
                                  </li>
                                })
                              }
                            </ol>

                          </td>
                          <td>{currencyCode + " " + entry.premium_amount_in_cents / 100}</td>
                        </tr>
                      </>
                    )
                  ))
                }
              </table>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <div className={classes.showmoreshowless} onClick={() => setExpanded(!expanded)}>
                    <ul>
                      <li> {expanded ? 'Show Less' : 'Show More'}</li>
                      <li>{
                        expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </li>
                    </ul>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </>
    )
  } else {
    return "No Dependants Found"
  }
}

export default QuotesDependants