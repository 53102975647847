import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
    principletitle: {
        marginTop: "40px",
        "& h2": {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.0015em",
            color: "#000000",
        }
    },
    noresultwrapper:{
        marginTop: "75px",
    },
    principalwrapper: {
        background: "#FFFFFF",
        borderRadius: "8px",
        height: "auto",
        padding: "25px 15px 25px 15px"
    },
    customeravatar: {
        background: "#D9D9D9",
        borderRadius: "5px",
        textAlign: "center",
        width: "66px",
        height: "57px",
        paddingTop: "15px",
    },
    principalname: {
        "& h3": {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.0015em",
            color: "#000000",
        }
    },
    principaldetails: {
        marginTop: "20px",
        "& h4": {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.0015em",
            color: "#000000",
        },
        "& p": {
            fontFamily: 'Open Sans',
            marginBottom: "10px",
            fontStyle: "normal",
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
            "& span":{
                background: "#75A85E",
                borderRadius: "10px",
                color: "#fff",
                padding: "2px",
                fontSize: "10px",
                width: "47px",
                display: "inline-block",
                textAlign: "center",
                height: "20px",
            }
        }
    },
    editprincipalbtn:{
      display: "flex",
        "& button": {
            background: "#FBA92D",
            borderRadius: "8px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            boxShadow: "none",
            padding: "10px",
            textTransform: "capitalize",
            marginTop: "10px",
            "&:hover":{
                background: "#173E4B",
                boxShadow: "none",
            },
            "&:nth-child(2)":{
              marginLeft: "10px",
            }
        }
    },
    paymentmodaltitle: {
        background: "#FBA92D",
        textAlign: "center",
        padding: "10px !important",
        "& h3": {
          color: "#fff",
          fontFamily: "Lato",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "24px",
          lineHeight: "24px",
        }
      },
      paymentinputwrapper: {
        marginTop: "30px",
        "& p": {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "15px",
          color: "#000000",
          textAlign: "center",
          marginBottom: "30px"
        },
        "& input": {
          background: "#F7F7F7",
          borderRadius: "8px",
          fontFamily: "Open Sans",
          fontStyle: "normal",
          fontWeight: 300,
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0.005em",
          color: "#000000",
        },
        "& label": {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          fontWeight: 300,
          fontSize: "12px",
          letterSpacing: "0.005em",
          color: "#000000",
    
        }
      },
      paymentinputs: {
        "& .MuiOutlinedInput-root": {
          borderColor: "#000fff"
        },
        "&.Mui-focused fieldset": {
          borderColor: "#C52328",
          borderWidth: "2px"
        }
      },
      paymentbtns: {
        marginBottom: "20px",
        "& button": {
          background: "#FBA92D",
          borderRadius: "8px",
          fontFamily: "Open Sans",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "15px",
          boxShadow: "none",
          padding: "10px",
          marginRight: "20px",
          textTransform: "capitalize",
          "&:nth-child(2)":{
            background: "transparent",
            border: "1px solid #000",
            color: "#000",
            "&:hover": {
              background: "transparent",
              boxShadow: "none",
            }
          },
          "&:hover": {
            background: "#173E4B",
            boxShadow: "none",
          }
        }
      },
}));