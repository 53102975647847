import { take, call, put } from 'redux-saga/effects';
import * as types from '../../lib/actionTypes.es6';
import { getOpenCallback, getClosedCallback, claimCallbackDelete } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';


export function* claimCallbackRequestWatcher() {
  for (;;) {
    const request = yield take(types.CLAIM_CALLBACK_REQUEST);
    try {
      const response = yield call(getOpenCallback, request.payload);
      yield put(actions.claimCallbackSuccess(response.data));

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.claimCallbackFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}



export function* getClosedClaimCallbackRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_CLAIM_CALLBACKS_CLOSED_REQUEST);
    try {
      const response = yield call(getClosedCallback, request.payload);
      yield put(actions.getClosedClaimCallbackSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getClosedClaimCallbackFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* claimCallbackDeleteRequestWatcher() {
  for (;;) {
    try {
      const { payload } = yield take(types.CLAIM_CALLBACK_DELETE_REQUEST);
      const response = yield call(claimCallbackDelete, payload);
      yield put(actions.claimCallbackDeleteSuccess(response.data));
      yield put(actions.claimCallbackRequest(
        {
          page: 1,
          page_size: 10
        }
      ));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.claimCallbackDeleteFailure(errorPayload));
    }
  }
}
