import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  claimsStatusList: null,
  status: null,
  loader: false,
};

export default function claimStatusReducer(state = initialState, action) {
  switch (action.type) {
    case types.CLAIM_STATUS_REQUEST:
      return { ...initialState, loader: true, status: action.payload };

    case types.CLAIM_STATUS_SUCCESS:
      return { ...state, ...handlePagination(action.payload), loader: false };

    case types.CLAIM_STATUS_FAILURE:
      return {
        ...state,
        loader: false,
        errors: action.payload,
        claimsStatusList: null,
      };

    case types.USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
}

function handlePagination(claimInfo) {
  return { claimsStatusList: claimInfo.items || claimInfo };
}
