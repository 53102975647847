import * as types from '../../lib/actionTypes.es6';
import { take, call, put } from 'redux-saga/effects';
import { getSmsTemplates, createSmsTemplate, updateSmsTemplate, deleteSmsTemplate, getSupportedParameters } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';



export function* getSmsTemplatesRequestWatcher() {
  while (true) {
    const requestAction = yield take(types.GET_SMS_TEMPLATES_REQUEST);
    let response = {};
    try {
      response = yield call(
        getSmsTemplates,
        requestAction.payload
      );
      yield put(actions.getSmsTemplatesSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.getSmsTemplatesFail(errorPayload));
    }
  }
}

export function* createSmsTemplatesRequestWatcher() {
  while (true) {
    const requestAction = yield take(types.CREATE_SMS_TEMPLATES_REQUEST);
    let response = {};
    try {
      response = yield call(
        createSmsTemplate,
        requestAction.payload
      );
      yield put(actions.createSmsTemplatesSuccess(response.data));
      yield put(actions.getSmsTemplatesRequest(requestAction.payload.partner_guid))

    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.createSmsTemplatesFail(errorPayload));
    }
  }
}

export function* updateSmsTemplatesRequestWatcher() {
  while (true) {
    const requestAction = yield take(types.UPDATE_SMS_TEMPLATES_REQUEST);
    let response = {};
    try {
      response = yield call(
        updateSmsTemplate,
        requestAction.payload
      );
      yield put(actions.updateSmsTemplatesSuccess(response.data));
      yield put(actions.getSmsTemplatesRequest(requestAction.payload.partner_guid))

    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.updateSmsTemplatesFail(errorPayload));
    }
  }
}

export function* deleteSmsTemplatesRequestWatcher() {
  while (true) {
    const requestAction = yield take(types.DELETE_SMS_TEMPLATES_REQUEST);
    let response = {};
    try {
      response = yield call(
        deleteSmsTemplate,
        requestAction.payload.guid
      );
      yield put(actions.deleteSmsTemplatesSuccess(response.data));
      yield put(actions.getSmsTemplatesRequest(requestAction.payload.partnerGuid))

    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.deleteSmsTemplatesFail(errorPayload));
    }
  }
}

export function* getSupportedParametersRequestWatcher() {
  while (true) {
    const requestAction = yield take(types.GET_SUPPORTED_PARAMETERS_REQUEST);
    let response = {};
    try {
      response = yield call(
        getSupportedParameters,
        requestAction.payload
      );
      yield put(actions.getSupportedParametersSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.getSupportedParametersFail(errorPayload));
    }
  }
}