import React, { useState } from 'react';
import { Paper, Grid, Chip, Tooltip } from "@mui/material";
import TableInc from '../../../../shared-ui/TableInc';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import find from 'lodash/find';
import BoxInc from "../../../../shared-ui/BoxInc/Index";
import ViewDocument from '../../../../shared-ui/ViewDocument/ViewDocument';

const ClaimDocuments = (props) => {
  const [viewDocumentUrl, setViewDocumentUrl] = useState(null);
  const [numPages, setNumPages] = useState(null);
  
  const claimDocs = props.claimDocuments || [];

  const staticHospitalDocs = [
    { description: 'Hospital_Invoice *' },
    { description: 'Discharge_Summary *' },
    { description: 'LOU_Document *' },
  ];

  const staticDeathDocs = [
    { description: 'Certified copy of death certificate/ Burial Permit *' },
    { description: 'Deceased ID copy *' },
    { description: 'Claimants ID copy *' },
    { description: 'Autopsy/Medical report *' },
    { description: 'Police Abstract *' },
    { description: 'Copy of Mobile money (M-pesa form) *' },
  ];

  const staticReimbursementDocs = [
    { description: 'Hospital_Invoice *' },
    { description: 'Discharge_Summary *' },
    { description: 'Payment Receipts *' },
    { description: 'Claimants ID copy *' },
    { description: 'Medical/Diagnostic Report *' },
  ];

  let staticDocs = [];
  if (props.type === 'Death') {
    staticDocs = [...staticDeathDocs];
  }
  if (props.type === 'Hospital') {
    staticDocs = [...staticHospitalDocs];
  }
  if (props.type === 'Reimbursement') {
    staticDocs = [...staticReimbursementDocs];
  }

  const missingStaticDocs = [];

  for (let doc of staticDocs) {
    if (!find(claimDocs, { description: doc.description })) {
      missingStaticDocs.push({ ...doc, status: 'missing' });
      if (!props.missingDocs) {
        props.toggleMissingDocs();
      }
    }
  }

  const result = [
    ...claimDocs,
    { description: '', status: 'any other doc' }
  ];

  return (
    <BoxInc
      title="Documents"
      expanded
      style={{ marginTop: "40px" }}
      actionButtons={[]}
    >
      <div id="documents" component-id="documents">
        <Paper elevation={0} sx={{ p: 3, mb: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableInc
                columns={[
                  {
                    title: 'Document Name',
                    field: 'filename'
                  },
                  {
                    title: 'Status',
                    field: 'created_At',
                    render: rowData => rowData.status === 'missing'
                      ? <Chip
                          size="small"
                          variant="outlined"
                          label="missing"
                          style={{
                            margin: '1px',
                            backgroundColor: "red",
                            color: "#fff"
                          }}
                        />
                      : (rowData.status === 'any other doc' ? 'upload any other doc'
                        : <Chip
                            size="small"
                            variant="outlined"
                            label="uploaded"
                            style={{
                              margin: '1px',
                              backgroundColor: "green",
                              color: "#fff"
                            }}
                          />)
                  },
                  {
                    title: 'Actions',
                    field: 'created_At',
                    render: rowData => {
                      return !(rowData.status === 'missing' || rowData.status === 'any other doc') ? (
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <Tooltip title="View Document">
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => setViewDocumentUrl(rowData.url)}
                            >
                              <VisibilityIcon sx={{ color: "#4B7E34" }} />
                            </span>
                          </Tooltip>
                          <Tooltip title="Download Document">
                            <span style={{ cursor: "pointer" }}>
                              <a href={rowData.url} download target="_blank" rel="noopener noreferrer">
                                <FileDownloadIcon sx={{ color: "#000" }} />
                              </a>
                            </span>
                          </Tooltip>
                          <Tooltip title="Delete Document">
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => props.setClaimDocumentToDelete(rowData)}
                            >
                              <DeleteIcon sx={{ color: "#8F191C" }} />
                            </span>
                          </Tooltip>
                        </div>
                      ) : (
                        <div>
                          <Tooltip title="Upload Document">
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                props.setDescription(rowData.description);
                                props.openUploadDialog();
                              }}
                            >
                              <FileUploadIcon sx={{ color: "#000" }} />
                            </span>
                          </Tooltip>
                        </div>
                      );
                    }
                  },
                ]}
                data={result}
                options={{
                  pageSize: result.length,
                  showTitle: false,
                  actionsColumnIndex: -1,
                  search: false,
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>

      <ViewDocument
        title="Claim Document"
        open={viewDocumentUrl}
        close={() => setViewDocumentUrl(null)}
        viewDocumentUrl={viewDocumentUrl}
        numPages={numPages}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      />
    </BoxInc>
  );
};

export default ClaimDocuments;