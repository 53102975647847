import React from 'react';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { hashHistory } from 'react-router';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LocalizedStrings from 'react-localization';
import { localisedText } from '../../../lib/localisation.es6';
import { updateCurrentLanguage } from '../../../lib/languageUtils.es6';
import * as actions from '../GroupMembers/actions.es6';
import { getCustomer } from '../../Customer/actions.es6';
import { selectClaim, refreshClaimRequest } from '../../ClaimsProcessing/actions.es6';
import { claimCallbackDeleteRequest, resetClaimCallbackDelete } from '../../ClaimCallback/actions.es6';
import AlertDialog from '../../../components/AlertDialog/index.es6';
import { Backdrop, CircularProgress, Grid } from '@material-ui/core';
import { hasAuthourity } from '../../../lib/access.es6';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../lib/constants';
import { AddEditMembers } from '../AddEditMembers';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import { getS3BucketSignedUrlForGroupUploadResources } from '../../../lib/requests.es6';
import LinearProgress from 'material-ui/LinearProgress';
import { SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Button } from '@material-ui/core';
import { getGroupRequest, groupExcelUploadCallbackRequest } from '../GroupRegistration/actions.es6';
import { resetExcelFileUpload, uploadExcelMembersRequest } from '../AddEditMembers/actions.es6';
import UpdateGroup from '../../../components/UpdateGroup/index.es6';
import { MenuItem } from 'material-ui/Menu';
import { getActualGroupQuoteRequest } from '../GroupRegistration/actions.es6';
import { buttonStyle } from '../../../components/AlertDialog/styles';

const btnStyle = {
  margin: '2%',
  green: {
    backgroundColor: 'green',
    color: 'white',
    margin: '2px',
    height: '50px',
    padding: '0 30px',
  },
  danger: {
    backgroundColor: 'orangered',
    color: 'white',
    margin: '2px',
    height: '50px',
    padding: '0 30px',
  },
  greyedOut: {
    backgroundColor: 'grey',
    color: 'white',
    margin: '2px',
    height: '50px',
    padding: '0 30px',
  }
};

export class AddGroupMembers extends React.Component {
  constructor(props) {
    super(props);

    const columnLetters = [
      'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
      'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X','Y', 'Z',
    ]
    const mappings = {};
    this.state = {
      labels: new LocalizedStrings(localisedText),
      value: 1,
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      policiesPage: DEFAULT_PAGE,
      policiesSize: DEFAULT_SIZE,
      membersFields: ['Family Id Column', 'Firstname Column', 'Middle Name', 'Last Name', 'Dob', 'Relationship', 'National Id', 'MSISDN'],
      s3Url: 'https://group-upload-resources.s3.amazonaws.com',
      excelFileUploadCompleted: 0,
      excelFileUploadMessage: null,
      groupScheduleError: null,
      mappings,
      showLoyaltyWarning: false,
      completed: 0,
      message: null,
      groupId: null,
      columnLetters,
      headerRow: '',
      showGroupDetailsCompleteMessage: false, 
      duplicateDownloaded: false,
    }
  }

  componentWillMount() {
    this.updateLanguage();
    const groupId = this.props.params.groupId
    this.setState({ groupId })
    this.props.dispatch(getGroupRequest(groupId))
  }




  getActualQuotation() {
    const groupDetailsIsComplete = this.isGroupDetailsComplete();
    if(!groupDetailsIsComplete){
      this.setState({ showGroupDetailsCompleteMessage: true })
      return;
    }

    const group = this.props.groupRegistrationData.getGroup;
    this.props.dispatch(getActualGroupQuoteRequest(group.id));
  }


  isGroupDetailsComplete(){
    const group = this.props.groupRegistrationData.getGroup;
    return group.kra_pin && group.address && group.certificate_url;
  }

  isCompletePrincipal(rowData) {
    if (!rowData) return false

    if (!rowData.firstName || !rowData.lastName
      || !rowData.nationalId || !rowData.dob || !rowData.gender || !rowData.msisdn) {
      return false;
    }

    return true;

  }

  isCompleteDependant(rowData) {
    if (!rowData) return false
    if (!rowData.first_name || !rowData.last_name || !rowData.relationship
      || !rowData.dob || !rowData.gender) {
      return false;
    }

    return true;
  }

  isAllDependenciesComplete(members) {
    let dependents = members || [];

    for (let i = 0; i < dependents.length; i++) {
      if (!this.isCompleteDependant(dependents[i])) {
        return false
      }
    }

    return true;
  }
  isAllMembersCompelete() {
    const allMembers = this.props.addEditMembersData.members || []

    for (let i = 0; i < allMembers.length; i++) {
      const member = allMembers[i];
      if (!this.isCompletePrincipal(member) || !this.isAllDependenciesComplete(member.dependants)) {
        return false
      }
    }

    return true;
  }


  getGroupMembers = () => {
    const { page, size } = this.state
    const groupId = this.props.params.groupId
    if (hasAuthourity('VIEW_ALL_GROUPS')) {
      this.props.dispatch(actions.getCurrentGroupMembersForAdminsRequest({ groupId, page, size }));
    } else {
      this.props.dispatch(actions.getCurrentGroupMembersRequest({ groupId, page, size }));
    }
  }

  getPolicies = () => {
    const { policiesPage: page, policiesSize: size } = this.state
    const groupId = this.props.params.groupId
    this.props.dispatch(actions.getCurrentGroupPoliciesRequest({ groupId, page, size }));
  }

  viewClaim(claim) {
    this.props.dispatch(selectClaim(claim));
    this.props.dispatch(refreshClaimRequest(claim.guid))
    hashHistory.push(`admin/claims_processing/${claim.guid}`);

  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'AddGroupMembers',
      this.state.labels
    );
  }

  redirectToCustomerStatusPage(customerGuid) {
    this.props.dispatch(getCustomer(customerGuid));
    hashHistory.push('admin/home');
  }

  redirectToCustomerMakeClaimPage(customerGuid) {
    this.props.dispatch(getCustomer(customerGuid));
    hashHistory.push('admin/make_claim');
  }

  deleteCallback(guid) {
    this.props.dispatch(claimCallbackDeleteRequest(guid));
  }

  resetDeleteCallback() {
    this.props.dispatch(resetClaimCallbackDelete());
    let groupId = this.props.params.groupId
    if (groupId) {
      this.props.dispatch(actions.getCurrentGroupClaimCallbacksRequest(groupId));
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChangePageSize(event) {
    event.preventDefault();
    const size = parseInt(event.target.value);
    console.log('size: ', size);
    this.setState({ size }, function () {
      this.getGroupMembers()
    });
  }

  handlePageClick(data) {
    const page = data.selected;
    console.log('page: ', page);
    this.setState({ page }, function () {
      this.getGroupMembers()
    });
  }

  handleChangePoliciesPageSize(event) {
    event.preventDefault();
    const policiesSize = parseInt(event.target.value);
    console.log('policiesSize: ', policiesSize);
    this.setState({ policiesSize }, function () {
      this.getPolicies()
    });
  }

  handlePoliciesPageClick(data) {
    const policiesPage = data.selected;
    console.log('policiesPage: ', policiesPage);
    this.setState({ policiesPage }, function () {
      this.getPolicies()
    });
  }


  getPages() {
    return Math.ceil(this.props.currentGroupData.count / this.state.size);
  }

  getPoliciesPages() {
    return Math.ceil(this.props.currentGroupData.policiesCount / this.state.policiesSize);
  }

  pageSizeSelector() {
    const { size } = this.state;
    const display = {
      display: this.props.currentGroupData.count > size ? '' : 'none',
    };
    return (
      <select
        value={size}
        onChange={this.handleChangePageSize.bind(this)}
        style={display}
      >
        <option>5</option>
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    );
  }

  paginateGroups() {
    const pages = this.getPages();
    const display = {
      display: pages > 1 ? '' : 'none',
    };
    const labels = this.state.labels;
    return (
      <div style={display}>
        <ReactPaginate
          previousLabel={labels.paginationPreviousText}
          nextLabel={labels.paginationNextText}
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick.bind(this)}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
          forcePage={this.state.page}
        />
      </div>
    );
  }


  policiesPageSizeSelector() {
    const { policiesSize } = this.state;
    const display = {
      display: this.props.currentGroupData.count > policiesSize ? '' : 'none',
    };
    return (
      <select
        value={policiesSize}
        onChange={this.handleChangePoliciesPageSize.bind(this)}
        style={display}
      >
        <option>5</option>
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    );
  }

  policesPaginateGroups() {
    const pages = this.getPoliciesPages();
    const display = {
      display: pages > 1 ? '' : 'none',
    };
    const labels = this.state.labels;
    return (
      <div style={display}>
        <ReactPaginate
          previousLabel={labels.paginationPreviousText}
          nextLabel={labels.paginationNextText}
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePoliciesPageClick.bind(this)}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
          forcePage={this.state.page}
        />
      </div>
    );
  }

  renderGroupMembersPagination() {
    return <Grid container style={{ alignItems: "right", backgroundColor: 'white' }}>
      <Grid item xs={11} style={{ textAlign: "right", backgroundColor: 'white' }}> {this.paginateGroups()}</Grid>
      <Grid item xs={1} style={{ textAlign: "left", backgroundColor: 'white', paddingLeft: '1%', margin: "auto" }}> {this.pageSizeSelector()} </Grid>
    </Grid>
  }

  renderPoliciesPagination() {
    return <Grid container style={{ alignItems: "right", backgroundColor: 'white' }}>
      <Grid item xs={11} style={{ textAlign: "right", backgroundColor: 'white' }}> {this.policesPaginateGroups()}</Grid>
      <Grid item xs={1} style={{ textAlign: "left", backgroundColor: 'white', paddingLeft: '1%', margin: "auto" }}> {this.policiesPageSizeSelector()} </Grid>
    </Grid>
  }

  displayTabContent(currentTabValue) {
    switch (currentTabValue) {
      case 0:
        return this.renderUploadTab();
      case 1:
        return <AddEditMembers
          paginatation={this.renderGroupMembersPagination()}
          redirectToCustomerStatusPage={this.redirectToCustomerStatusPage.bind(this)}
          redirectToCustomerMakeClaimPage={this.redirectToCustomerMakeClaimPage.bind(this)}
          dispatch={this.props.dispatch}
          addEditMembersData={this.props.addEditMembersData}
          groupId={this.state.groupId}
          groupRegistrationData={this.props.groupRegistrationData}
          showGroupDetailsCompleteMessage={this.state.showGroupDetailsCompleteMessage}
          setShowGroupDetailsCompleteMessage={()=>{this.setState({ showGroupDetailsCompleteMessage : false })}}
        />;

      case 2:
        return <UpdateGroup
          groupRegistrationData={this.props.groupRegistrationData}
          dispatch={this.props.dispatch}
        />;

      default:
        return <h2>Something wrong</h2>;

    }

  }
  progress(completed) {
    if (completed > 100) {
      this.setState({ completed: 100 });
    } else {
      this.setState({ completed });
    }
  }

  errorHandler(message) {
    this.setState({ message });
  }

  displayCompleteMessage() {
    // if (true) {
    if (this.state.completed === 0) {
      return (
        <div>
          <h4>Drag and drop files here, or click to upload.</h4>
        </div>
      );
    }
    if (this.state.completed > 0 && this.state.completed < 100) {
      return (
        <div>
          <h4>Upload in progress, do not interrupt</h4>
        </div>
      );
    }
    if (this.state.completed >= 100 && !this.state.message) {
      return (
        <div>
          <h4 style={{ color: 'green' }}>Upload succesfully completed</h4>
        </div>
      );
    }
    if (this.state.completed >= 100 && this.state.message) {
      return (
        <div>
          <h4>Upload completed, but unfortunately something went wrong :(</h4>
        </div>
      );
    }
    return '';
  }

  renderUploadTab() {
    const { labels } = this.state;
    const group  = this.props.groupRegistrationData.getGroup
    const registrationStatus = group ? group.registration_status : false;
    const registrationStatusClosed = registrationStatus === 'Closed';

    return <>
            <p style={{ color: 'red', textAlign: 'center', display: registrationStatusClosed ? 'block' : 'none'  }}>This group's regisgtration for new members is closed. Please contact Britam for more info.</p>
    <Grid container item xs={12} spacing={3} style={{ marginTop: '10px' }}>

      <Grid item md={4} xs={12}>
        <h3>Upload excel file here</h3>
        {this.renderGroupExcelFileUploader()}
        {
          this.state.groupScheduleError &&
          <p style={{ color: 'red' }}>Error: Please upload group Schedule Excel. </p>
        }
      </Grid>
      <Grid item md={8} xs={12}>
        <AlertDialog
          warning
          show={!!this.state.showLoyaltyWarning}
          title={labels.uploadLoyaltyData}
          confirmBtnText={labels.yes}
          onConfirm={this.submitFieldToColumns}
          cancelBtnText={labels.no}
          onCancel={this.cancelUploadLoyalties}
          showCancel={true}
          confirmBtnCssClass
        >
          {labels.loyaltyWarning}
        </AlertDialog>
        {this.renderForm()}
      </Grid>
    </Grid>
    </> 

  }

  progressExcelFile(completed) {
    if (completed > 100) {
      this.setState({ excelFileUploadCompleted: 100 });
    } else if (completed === 0) {
      this.setState({ excelFileUploadCompleted: 8 });
    } else {
      this.setState({ excelFileUploadCompleted: completed });
    }
  }

  handleFinishedUploadExcelFile(info) {
    this.setState({ membersFileSignedUrl: info.signedUrl, groupScheduleError: false })
    this.props.dispatch(groupExcelUploadCallbackRequest(info));
  }

  displayCompleteMessageForGroupExcelFile() {
    const style = {
      margin: '5%',
      textAlign: 'center'
    };
    const labels = this.state.labels;
    if (this.state.excelFileUploadCompleted === 0) {
      return (
        <div style={style}>
          <h4>{'Drag and Drop Group Schedule excel file here'}</h4>
          <h4>{labels.Or}</h4>
          <h4>{labels.clickUpload}</h4>
        </div>
      );
    }

    if (this.state.excelFileUploadCompleted > 0 && this.state.excelFileUploadCompleted < 100) {
      return (
        <div style={style}>
          <h4>{labels.uploadProgress}</h4>
        </div>
      );
    }
    if (this.state.excelFileUploadCompleted >= 100 && !this.state.excelFileUploadMessage) {
      return (
        <div style={style}>
          <h4 style={{ color: 'green' }}>{labels.uploadSuccess}</h4>
        </div>
      );
    }
    if (this.state.excelFileUploadCompleted >= 100 && this.state.excelFileUploadMessage) {
      return (
        <div style={style}>
          <h4>{labels.uploadFailure}</h4>
        </div>
      );
    }
    return '';
  }

  uploadGroupSchedule() {
    if (!this.props.groupRegistrationData.excelFileUploaded) {
      this.setState({ groupScheduleError: true });
      return;
    }

    const { groupId, mappings, columnLetters, headerRow } = this.state
    const payload = {
      "groupId": groupId,
      "groupScheduleS3Key": this.props.groupRegistrationData.excelFileUploaded.bucketKey,
      "headerRow": headerRow,
      "familyId": columnLetters.indexOf(mappings["Family Id Column"]),
      "firstName": columnLetters.indexOf(mappings["Firstname Column"]),
      "middleName": columnLetters.indexOf(mappings["Middle Name"]),
      "lastName": columnLetters.indexOf(mappings["Last Name"]),
      "dob": columnLetters.indexOf(mappings["Dob"]),
      "nationalId": columnLetters.indexOf(mappings["National Id"]),
      "msisdn": columnLetters.indexOf(mappings["MSISDN"]),
      "relationship": columnLetters.indexOf(mappings["Relationship"]),
    }

    this.props.dispatch(uploadExcelMembersRequest(payload))
  }

  renderGroupExcelFileUploader() {
    const uploadOptions = {
      getSignedUrl: getS3BucketSignedUrlForGroupUploadResources,
      uploadRequestHeaders: {},
      onProgress: this.progressExcelFile.bind(this),
      onError: this.errorHandler.bind(this),
    };

    const style = {
      loader: {
        display: this.state.excelFileUploadCompleted > 0 ? '' : 'none',
        width: '52%',
      },
      s3Uploader: {
        display: this.state.excelFileUploadCompleted > 0 ? 'none' : '',
      },
      loaderColor: this.state.message ? 'red' : 'green',
    };

    return (
      <div>
        <div>
          <DropzoneS3Uploader
            onFinish={this.handleFinishedUploadExcelFile.bind(this)}
            maxSize={1024 * 1024 * 5}
            upload={uploadOptions}
            s3Url={this.state.s3Url}
          >
            <div>
              <br />
              <br />
              {this.displayCompleteMessageForGroupExcelFile()}
            </div>
          </DropzoneS3Uploader>

          <br />
          <br />
          <LinearProgress
            color={style.loaderColor}
            style={style.loader}
            mode="determinate"
            value={this.state.excelFileUploadCompleted}
          />
        </div>
      </div>
    );
  }

  handleColumnNumberInput(field, event) {
    const { mappings } = this.state;
    mappings[field] = event.target.value;
    this.setState({ mappings })
  }
  resetUpload() {
    const mappings = {};
    this.setState({ mappings })
    this.props.dispatch(resetExcelFileUpload())
  }

  renderForm() {
    const { membersFields, labels, columnLetters } = this.state;
    const group  = this.props.groupRegistrationData.getGroup
    const registrationStatus = group ? group.registration_status : false;
    const registrationStatusClosed = registrationStatus === 'Closed';

    return <div style={{ display: (membersFields ? 'block' : 'none') }}>
      <center>
        <h3>{labels.mapColumnsToFields}</h3>
        <p> Tell us how your excel looks, which fields correspond to which column letter in your excel.</p>
        <ValidatorForm onSubmit={this.uploadGroupSchedule.bind(this)} key={this.state.formKey}>
          <Grid container spacing={3}>
            <Grid container item xs={12} spacing={3} style={{ margin: 'auto' }}>
            <Grid item xs={4}>
                  <SelectValidator label={'HEADER ROW NUMBER'}
                        id="row number"
                        name={'headerRow'}
                        className="form-control"
                        type="text"
                        value={this.state.headerRow}
                        onChange={(event) => this.setState({ headerRow: event.target.value})}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Please select row number']}
                        style={{ marginBottom: "20px" }}>
                        <MenuItem value="">{'Please select row number'}</MenuItem>
                        {
                          [1,2,3,4,5,6,7,8,9,10].map((rowNumber) => {
                            return <MenuItem value={rowNumber}>{rowNumber}</MenuItem>
                          })
                        }
                      </SelectValidator>
                </Grid>
              {membersFields.map(field => {
                const label = labels[field.toLowerCase()] || field;
                return <Grid item xs={4} key={field}>
                  <SelectValidator label={label.toUpperCase()}
                        id="column letter"
                        name={field + "ColumnNumber"}
                        className="form-control"
                        type="text"
                        value={this.state.mappings[field]}
                        onChange={(event) => this.handleColumnNumberInput(field, event)}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Please select column letter']}
                        style={{ marginBottom: "20px" }}>
                        <MenuItem value="">{'Please select column letter'}</MenuItem>
                        {
                          columnLetters.map((product) => {
                            return <MenuItem value={product}>{product}</MenuItem>
                          })
                        }
                      </SelectValidator>
                </Grid>
              })}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                style={registrationStatusClosed ? btnStyle.greyedOut : btnStyle.green}
                disabled={registrationStatusClosed}
                type="submit">
                Upload excel
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </center> </div>

  }

  getPrincipalCount() {
    return (this.props.addEditMembersData.members || []).length;
  }

  getTotalMembersCount() {
    let total = this.getPrincipalCount();

    const principals = this.props.addEditMembersData.members || [];

    for (let i = 0; i < principals.length; i++) {
      total += (principals[i].dependants || []).length;
    }

    return total;

  }

  getDuplicateContentString(duplicates) {
    const firstDuplicate = duplicates[0]
    delete firstDuplicate.group
    delete firstDuplicate.created_at
    const headersArray = Object.keys(firstDuplicate)
    const headersString = headersArray.join(",")
    const contentString = [
      headersString, 
      ...duplicates.map(duplicate =>
        {
          delete duplicate.group
          delete duplicate.created_at
          return this.getRowAsString(duplicate, headersArray)
        }
      )]
    return contentString
  }

  getRowAsString(duplicate, headersArray) {
    let rowAsString = ""
    for (let key of headersArray) {
      rowAsString += duplicate[key] + ","
    }
    return rowAsString
  }

  downloadDuplicates(){
    this.setState({ duplicateDownloaded: true })
    const duplicates = this.props.addEditMembersData.uploadExcel.duplicates
    const errors = this.getDuplicateContentString(duplicates)

    let csvContent = "data:text/csv;charset=utf-8,";

    for (let i = 0; i < errors.length; i++) {
      const row = errors[i];

      csvContent += row + "\n";
    }

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';

    const url = encodeURI(csvContent);
    a.href = url;
    a.download = 'duplicates_group_schedule.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  }


  render() {
    const allMembersAreComplete = this.isAllMembersCompelete();
    const membersTab = this.state.value === 1;

    const succesfullyUploadedWithDuplicates = this.props.addEditMembersData.uploadExcel && this.props.addEditMembersData.uploadExcel.duplicates
    const succesfullyUploadedWithNoDuplicates = this.props.addEditMembersData.uploadExcel && !succesfullyUploadedWithDuplicates
    return (
      <div style={{ width: '98%', margin: "auto" }}>
        <Paper style={{ flexGrow: 1, marginLeft: '2%' }}>
          <div>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              style={{ width: '40%', float: 'left' }}
            // variant="fullWidth"
            // centered
            >
              <Tab id="tab" style={{ width: '10%' }} label="Upload group" />
              <Tab style={{ width: '10%' }} label="Members" />
              <Tab style={{ width: '100%', float: 'right' }} label="Group details" />
            </Tabs>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
            >
              {
                this.getPrincipalCount() > 0 &&
                <>
                  <Tab id="tab" style={{ width: '100%' }} label={`${this.getPrincipalCount()} Principle Members`} />
                  <Tab style={{ width: '100%' }} label={`${this.getTotalMembersCount()} Group Members`} />
                  {
                  <div style={{ textAlign: 'center', margin: 'auto' }}>
                    { membersTab ? 
                      <Button
                      onClick={this.getActualQuotation.bind(this)}
                      style={{ background: allMembersAreComplete ? 'green' : 'grey', color: 'white', padding: '5% 5%' }}
                      variant="contained"
                      color="primary"
                      disabled={!allMembersAreComplete}>Get Actual Quotation</Button> : ''
                    }
                  </div>
              }
                </>
              }

            </Tabs>
          </div>

        </Paper>
        <div style={{ margin: '2%' }}>
          {
            this.displayTabContent(this.state.value)
          }
        </div>
        <AlertDialog
          success
          show={!!succesfullyUploadedWithDuplicates}
          title={'Successfully uploaded'}
          confirmBtnText={'OK'}
          onConfirm={() => this.resetUpload()}
          showCancel={false}
          showConfirm={false}
          confirmBtnCssClass
        >
          <div>
          <p>All unique members has been added. However duplicates were found, please download the duplicate, review and reupload only the resolved duplicates.</p>
          <Button 
            style={{...buttonStyle.danger, fontSize: '15px', marginRight: '1%'} }
            onClick={this.downloadDuplicates.bind(this)}
          >Download duplicates</Button>
          <Button style={{...buttonStyle.success, fontSize: '15px', display: this.state.duplicateDownloaded ? 'inline' : 'none'} }
            onClick={() => this.resetUpload()}
          >try again</Button>
          </div>
        </AlertDialog>

        <AlertDialog
          success
          show={!!succesfullyUploadedWithNoDuplicates}
          title={'Successfully uploaded'}
          confirmBtnText={'OK'}
          onConfirm={() => this.resetUpload()}
          showCancel={false}
          confirmBtnCssClass
        >
        </AlertDialog>
        <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={this.props.addEditMembersData.uploadExcelLoader}>
          <CircularProgress size={80} color="inherit" />
          <h3 style={{ marginTop: '15%', marginLeft: '-15%' }}>.....Uploading Excell.....</h3>
        </Backdrop>
      </div>

    );
  }
}

export default connect(state => ({
  groupRegistrationData: state.groupRegistration,
  globalData: state.global,
  currentGroupData: state.currentGroup,
  callbackData: state.claimsCallBack,
  addEditMembersData: state.addEditMembers,
}))(AddGroupMembers);