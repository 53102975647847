import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useStyle } from "./Style";
import { Grid } from '@mui/material';
import ProfileDetails from '../ProfileDetails/ProfileDetails';
import ChangePassword from '../ChangePassword/ChangePassword';
import UserPartners from '../UserPartners/UserPartners';
import UserRoles from '../UserRoles/UserRoles';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const AccountSettingsWrapper = (props) => {
  const classes = useStyle();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box
        sx={{ flexGrow: 1, display: 'flex', height: 500 }}
      >
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider', width: "15%" }}
          className={classes.tabs}
        >
          <Tab label={props.labels.profileDetails} {...a11yProps(0)} />
          <Tab label={props.labels.changePassword}   {...a11yProps(1)} />
          <Tab label={props.labels.editDetails}   {...a11yProps(2)} />
          <Tab label={props.labels.partners}   {...a11yProps(3)} />
          <Tab label={props.labels.roles}  {...a11yProps(4)} />
        </Tabs>
        <TabPanel value={value} index={0} className={classes.tabPanels}>
          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>{props.labels.profileDetails}</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >


            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <ProfileDetails
              ProfileDetails={props.profileData}
              labels={props.labels}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabPanels}>
          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>{props.labels.changePassword} 
              </h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >


            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <ChangePassword
              changePassword={props.changePassword}
              showChangePasswordMessage={props.showChangePasswordMessage}
              ProfileDetails={props.profileData}
              loading={props.loading}
              labels={props.labels}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2} className={classes.tabPanels}>
        <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>{props.labels.editDetails} 
              </h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >


            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={3} className={classes.tabPanels}>
        <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>{props.labels.partners} 
              </h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >


            </Grid>
          </Grid>
          <Grid container spacing={2}>
              <Grid item xs={12} md={12} sm={12}>
                <UserPartners
                currentPartners={props.currentPartners}
                />
              </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={4} className={classes.tabPanels}>
        <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>{props.labels.roles} 
              </h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >


            </Grid>
          </Grid>
          <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <UserRoles currentRoles={props.currentRoles}/>
              </Grid>
          </Grid>
        </TabPanel>
      
  
      </Box>
    </>
  )
}

export default AccountSettingsWrapper