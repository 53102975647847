import * as types from '../../../lib/actionTypes.es6';

export function getGroupsRequest(payload) {
  return {
    type: types.GET_GROUPS_REQUEST,
    payload,
  };
}

export function getGroupsSuccess(response) {
  return {
    type: types.GET_GROUPS_SUCCESS,
    payload: response,
  };
}

export function getGroupsFailed(error) {
  return {
    type: types.GET_GROUPS_FAILURE,
    payload: error,
  };
}

export function deleteMyGroupRequest(payload) {
  return {
    type: types.DELETE_MY_GROUP_REQUEST,
    payload,
  };
}

export function deleteMyGroupSuccess(response) {
  return {
    type: types.DELETE_MY_GROUP_SUCCESS,
    payload: response,
  };
}

export function deleteMyGroupFailed(error) {
  return {
    type: types.DELETE_MY_GROUP_FAILURE,
    payload: error,
  };
}
