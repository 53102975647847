import React from "react"
import HospitalExpenses from "./HospitalExpenses"
import DeathExpenses from "./DeathExpenses"
import FuneralExpenses from "./FuneralExpenses"
import DeviceDamageExpenses from "./DeviceDamageExpenses"
import DisabilityExpenses from './DisabilityExpenses'
import DeviceTheftExpenses from "./DeviceTheftExpenses"
import TemporaryDisabilityExpenses from "./TemporaryDisabilityExpenses"
export default function ClaimExpenses(props) {

  if(props.hasDeathBenefit){
    return <DeathExpenses {...props} />
  }
  if(props.hasFuneralBenefit){
    return <FuneralExpenses {...props} />
  }
  if(props.hasDeviceDamageBenefit){
    return <DeviceDamageExpenses {...props} />
  }
  if(props.hasDisabilityBenefit){
    return <DisabilityExpenses {...props} />
  }
  if(props.hasDeviceTheftBenefit){
    return <DeviceTheftExpenses {...props} />
  }

  if(props.hasTemporaryDisabilityBenefit){
    return <TemporaryDisabilityExpenses {...props} />
  }
  return (
    <HospitalExpenses {...props} />
  )
}