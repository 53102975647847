import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  filterWrapper: {
    padding: "0 20px",
    "& input": {
      background: "#F7F7F7",
      borderRadius: "8px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#000000",
    },
    "& label": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      letterSpacing: "0.005em",
      color: "#000000",

    }
  },
  autocomplete: {
    "& input": {
      background: "transparent",
      borderRadius: "8px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#000000",
    },
    "& label": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      color: "#000000",
      transformOrigin: "bottom",
    }
  },
  filterBtns:{
    marginTop: "10px",
    "& p":{
      marginTop: "10px",
    }
  },
  filterTitle: {
    "& h2": {
      padding: "20px",
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
    }

  },
  claimTable: {
    background: "#FFFFFF",
    "& table": {
      "&:nth-child(1)": {
        background: "#FFFFFF",
        border: "1px solid #A7A7A7",
        margin: "30px",
        width: "auto",
      },
      "&:nth-child(2)": {
        border: "0 !important"
      },
      "& th": {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "15px",
        color: "#000000",
        padding: "10px",
        borderRight: "1px solid #A7A7A7",
      },
      "& td": {
        fontFamily: "open sans !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "12px !important",
        lineHeight: "15px !important",
        color: "#000000 !important",
        padding: "10px !important",
        borderRight: "1px solid #A7A7A7 !important",

      },
      "& tfoot": {
        "& tr": {
          "& td": {
            border: "0 !important"
          }
        }
      }
    },
    "& .MuiPaper-elevation2": {
      boxShadow: "none",
    },
    "& .MuiToolbar-regular": {
      paddingRight: "40px",
    },

  },
  claimactionbt: {
    "& button": {
      background: "#fba92c",
      color: "#fff !important",
      borderRadius: "8px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      boxShadow: "none",
      padding: "10px",
      textTransform: "capitalize",
      marginLeft: "30px",
      "&:hover": {
        background: "#fba92c",
        boxShadow: "none",
      }
    }
  }

}));