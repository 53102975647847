import React from "react"
import HospitalExpenses from "./HospitalExpenses"
import DeathExpenses from "./DeathExpenses"

export default function ClaimExpenses(props) {

  if(props.hasDeathBenefit){
    return <DeathExpenses {...props} />
  }
  return (
    <HospitalExpenses {...props} />
  )
}