import React from 'react';
import LocalizedStrings from 'react-localization';
import { hashHistory } from 'react-router';
import { connect } from "react-redux";
import Premiums from './Components/Premiums/Premiums';
import { localisedText } from '../../../../../lib/localisation.es6';
import { getAllAcessBankCustomerPremiumsRequest } from './Redux/actions';

class AccessBankPremiums extends React.Component {

  constructor(props) {
    const localizedStrings = new LocalizedStrings(localisedText);
    super(props);
    this.state = {
      labels: localizedStrings,

    };
  }

  componentWillMount() {

    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      this.props.dispatch(getAllAcessBankCustomerPremiumsRequest({ guid: currentCustomer.guid }))
    }


  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    const language = this.props.globalData.language;
    let resetLanguage = false;
    if (!language) {
      resetLanguage = true;
    }
    const labels = this.state.labels;
    if (resetLanguage || labels.getLanguage() !== language) {
      labels.setLanguage(language);
      this.setState({});
    }
  }



  createQuote() {
    hashHistory.push('admin/create-quote');
  }



  render() {

    if (this.props.customerData.currentCustomer) {
      return (
        <>

          <Premiums
          premiums={this.props.AccessBankPremiumData.premiums}
          loader={this.props.AccessBankPremiumData.get_premiums_loader}
          />
        </>
      )
    } else {
      return ""
    }

  }
}
export default connect((state) => ({
  customerData: state.currentCustomer,
  AccessBankPremiumData: state.AccessBankPremiumData,
  globalData: state.global,
  profileData: state.profile,
}))(AccessBankPremiums);