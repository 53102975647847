import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProductsHeader from "../../../../NewProductList/components/ProductsHeader/ProductsHeader";
import { CircularProgress } from "@mui/material";
import { formatStatus } from "../../../../../lib/utils.es6";

export default function ViewGroupProductConfig(props) {
  const [expandedGroupProductConfigs, setExpandedGroupProductConfigs] = React.useState(false)

  if (props.isLoading) {
    return <div style={{ textAlign: 'center' }}>
      <CircularProgress />
    </div>
  }

  if (!props.partnerConfigGroup) {
    return <div style={{ marginBottom: '5%' }}>
      <Accordion expanded={expandedGroupProductConfigs}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={() => { setExpandedGroupProductConfigs(!expandedGroupProductConfigs) }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <ProductsHeader
            title={"Group Product Configs"}
            showEditButton={false}
            showAddButton={true}
            onEditButtonClick={props.openUpdateGroupProductConfigsDialog}
            onAddButtonClick={props.openAddGroupProductConfigsDialog}
          />
        </AccordionSummary>
        <AccordionDetails>
          <p>Could not find group product configs, click on the add button to add</p>
        </AccordionDetails>
      </Accordion>
    </div>
  }

  const groupProductConfigs = props.partnerConfigGroup
  // delete groupProductConfigs.id
  delete groupProductConfigs.product_guid

  return (
    <div style={{ marginTop: "0%" }}>

      <>
        <Accordion expanded={expandedGroupProductConfigs}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon onClick={() => { setExpandedGroupProductConfigs(!expandedGroupProductConfigs) }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <ProductsHeader
              title={"Group Product Configs"}
              showEditButton={true}
              onEditButtonClick={props.openUpdateGroupProductConfigsDialog}
            />
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper} style={{ marginTop: "2%" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table-cell-header">
                      Property
                    </TableCell>
                    <TableCell align="left" className="table-cell-header">
                      Value
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {
                    Object.keys(groupProductConfigs).map(configKey => {
                      return <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <strong>{formatStatus(configKey)}</strong>
                        </TableCell>
                        <TableCell align="left">{groupProductConfigs[configKey]}</TableCell>
                      </TableRow>
                    })
                  }

                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
        <br />
        <br />
      </>

    </div>
  );
}
