import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from "@mui/material";
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../lib/constants";
import AlertDialog from "../../../components/AlertDialog/index.es6";
const initialState = {
  granularity: '',
  payment_method: '',
  cardinality: '',
  amount_in_cents: '',

  active: true,
  code: '',
  created_at: '',
  currency_code: '',
  currency_name: '',
  date_format_pattern: '',
  date_format_pattern_month: '',
  date_time_format_pattern: '',
  editor_id: '',
  international_calling_code: '',
  local_number_length: '',
  msisdn_regex: '',
  name: '',
  partner_guids: '',
  time_zone: '',
  national_id_regex: '',
}
export default function AddCountry(props) {
  const [
    {
      active,
      code,
      currency_code,
      currency_name,
      date_format_pattern,
      date_format_pattern_month,
      date_time_format_pattern,
      international_calling_code,
      local_number_length,
      msisdn_regex,
      name,
      partner_guids,
      time_zone,
      national_id_regex,
    }, setState
  ] = React.useState(initialState)


  React.useEffect(() => {
    ValidatorForm.addValidationRule('isValidRegex', (regex) => {
      return isValidRegex(regex)
    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidRegex');
    }
  });

  const isValidRegex = (regex) => {
    var isValid = true;
    try {
      new RegExp(regex);
    } catch (e) {
      isValid = false;
    }
    return isValid
  }

  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };


  const reset = () => {
    setState(initialState);
  }


  const submit = () => {
    const payload = {
      active,
      code,
      currency_code,
      currency_name,
      date_format_pattern,
      date_format_pattern_month,
      date_time_format_pattern,
      international_calling_code,
      local_number_length,
      msisdn_regex,
      name,
      partner_guids: partner_guids? partner_guids.split(","): [],
      time_zone,
      national_id_regex,
    }
    props.addCountry(payload)
  }


  const handleCountryCreatedSuccessfully = () => {
    props.resetAddCountrySuccessAlert()
    reset()
    props.closeAddCountryDialog()
    props.refreshProduct();
  }

  const handleCountryCreateError = () => {
    props.resetAddCountryErrorAlert()
  }


  const textFields = [
    { name: 'code', placeholder: 'KE', label: 'Country code', },
    { name: 'name', placeholder: 'Kenya', label: 'Country name', },
    { name: 'currency_code', placeholder: 'Ksh', label: 'Currency code', },
    { name: 'currency_name', placeholder: 'Kenya Shilling', label: 'Currency name', },
    { name: 'time_zone', placeholder: 'EAT', label: 'Timezone', },
    { name: 'international_calling_code', placeholder: '00254', label: 'international calling code', },
    { name: 'date_format_pattern', placeholder: 'dd/MM/yyyy', label: 'Date format pattern', },
    { name: 'date_time_format_pattern', placeholder: 'dd/MM/yyyy HH/mm/ss', label: 'Date time format pattern', },
    { name: 'date_format_pattern_month', placeholder: 'MMM', label: 'Date format pattern month', },
    { name: 'local_number_length', placeholder: '9', label: 'Local number length', },
  ]

  const regexTextFields = [
    { name: 'msisdn_regex', placeholder: '^(00|\\+)254\\d{9}$', label: 'msisdn regex', },
    { name: 'national_id_regex', placeholder: '^(\\d{7}|\\d{8})$', label: 'National Id regex', },
  ]

  return (
    <React.Fragment>
      <Dialog
        open={props.openAddCountryDialog} fullWidth
        maxWidth='lg'
        onClose={props.closeAddCountryDialog}
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.closeAddCountryDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Country
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div style={{ marginTop: "2%" }}>
            <Box sx={{ width: "100%" }}>
              <ValidatorForm onSubmit={() => submit()} >
                <AlertDialog
                  custom
                  show={props.showAddCountryProgressAlert}
                  size="sm"
                  style={{ marginTop: '0', top: '30vh' }}
                  confirmBtnText={'ok'}
                  showCancel={false}
                  showConfirm={false}
                  cancelBtnText={'cancel'}
                  showTitle={false}
                  confirmBtnCssClass
                >
                  <CircularProgress />
                  <h2>Adding country</h2>
                </AlertDialog>

                <AlertDialog
                  success
                  show={props.showAddCountrySuccessAlert}
                  size="sm"
                  title={'Country added'}
                  style={{ marginTop: '0', top: '30vh' }}
                  onConfirm={() => { handleCountryCreatedSuccessfully() }}
                  confirmBtnText={'ok'}
                  showCancel={false}
                  showConfirm={false}
                  cancelBtnText={'cancel'}
                  confirmBtnCssClass
                >
                  <div >
                    <Button variant="contained" onClick={() => { handleCountryCreatedSuccessfully() }} style={{ background: 'green', }}>Ok</Button>
                  </div>
                </AlertDialog>

                <AlertDialog
                  show={props.showAddCountryErrorAlert}
                  danger
                  title={'Error adding country'}
                  onConfirm={() => handleCountryCreateError()}
                  confirmBtnText={'Try again'}
                  confirmBtnCssClass
                  showCancel={false}
                  style={{ marginTop: '0', top: '30vh' }}
                >
                  {props.createCountryError ? props.createCountryError.message : ''}
                </AlertDialog>

                <Grid container spacing={2}>


                  {
                    textFields.map(field => {
                      return <Grid item lg={3} md={6} xs={12}>
                        <TextValidator
                          onChange={onChange}
                          value={eval(field.name)} //eslint-disable-line
                          label={field.label}
                          name={field.name}
                          fullWidth
                          variant="outlined"
                          type="text"
                          placeholder={field.placeholder}
                          // validators={['required']}
                          errorMessages={[`Please enter ${field.label}`]}
                        />
                      </Grid>
                    })
                  }

                  {
                    regexTextFields.map(field => {
                      return <Grid item lg={3} md={6} xs={12}>
                        <TextValidator
                          onChange={onChange}
                          value={eval(field.name)} //eslint-disable-line
                          label={field.label}
                          name={field.name}
                          fullWidth
                          variant="outlined"
                          type="text"
                          placeholder={field.placeholder}
                          validators={['isValidRegex']}
                          errorMessages={['invalid regular expression']}
                        />
                      </Grid>
                    })
                  }

                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant="contained" type="submit" style={{ marginRight: '2%' }}> Save </Button>
                    <Button variant="contained" color="secondary" style={{ marginRight: '2%' }} onClick={() => reset()}> Reset</Button>
                  </Grid>

                </Grid>
              </ValidatorForm>

            </Box>
          </div>

        </DialogContent>
      </Dialog>

    </React.Fragment>

  );
}
