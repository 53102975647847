import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import { hasAuthourity } from '../../../../../lib/access.es6';
import { ClaimActionEnum } from '../../../lib/enum';

const FailedConfirmation = (props) => {
  const [toggleLoading, setToggleLoading] = React.useState('btn1')
  const classes = useStyle();

  const handleReConfirmClaim = () => {
    setToggleLoading('btn1')
    props.submitClaimAction({
      claim_action: ClaimActionEnum.re_confirm,
      claim_guid: props.claim.guid,
    })
  }
  const handleDiscardClaim = () => {
    setToggleLoading('btn2')
    props.submitClaimAction({
      claim_action: ClaimActionEnum.discard,
      claim_guid: props.claim.guid,
    })
  }
  return (
    <section className={classes.ClaimActionButtons}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ButtonInc onClick={handleReConfirmClaim} hasPermision={hasAuthourity('APPROVE_CLAIM')} loading={props.loading && toggleLoading ==='btn1'} variant="contained" style={{ width: "221px", height: "55px", float: "left", marginRight: "20px" }}>Request Reconfirmation</ButtonInc>
          <ButtonInc onClick={handleDiscardClaim} hasPermision={hasAuthourity('REJECT_CLAIM')} loading={props.loading && toggleLoading ==='btn2'} variant="contained" style={{ width: "221px", height: "55px", background: "#8F191C" }}>Discard Claim</ButtonInc>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <p style={{color: 'red'}}>{props.error ? props.error.message : ''}</p>
        </Grid>
      </Grid>
    </section>
  )
}

export default FailedConfirmation