import React from 'react'
import { useStyle } from "./Style";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// core version + navigation, pagination modules:
import { Pagination } from 'swiper';
// import Swiper and modules styles
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import Britam from '../../../../../Assets/britam.jpg'
import Equitel from '../../../../../Assets/equitel-line.jpg'
import Hollard from '../../../../../Assets/hollard.jpg'
import apainsurance from '../../../../../Assets/apa.jpg'
import metropolitan from '../../../../../Assets/metro.jpg'
import absa from '../../../../../Assets/absa.jpg'
import AirtelLogo from '../../../../../Assets/equitel.jpg'
import mtn from '../../../../../Assets/mtn.jpg'
import orange from '../../../../../Assets/orange.jpg'
import mfsafrica from '../../../../../Assets/onafriq.jpg'
import fnbzambia from '../../../../../Assets/fnb.jpg'
import instafinance from '../../../../../Assets/insta.jpg'
import mpost from '../../../../../Assets/mpost.jpg'
import equitybank from '../../../../../Assets/equity.jpg'
import realpeople from '../../../../../Assets/realpeople.jpg'
import accessbank from '../../../../../Assets/access.jpg'
import rafode from '../../../../../Assets/rafode.jpg'
import bboxx from '../../../../../Assets/bboxx.jpg'
import attlehang from '../../../../../Assets/atle.jpg'
import twende from '../../../../../Assets/twende.jpg'
import prudential from '../../../../../Assets/prudential.jpg'
const MobilePartners = () => {
    const classes = useStyle();
    return (
        <>
            <Swiper
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className={classes.mySwiper}
            >
                <SwiperSlide>
                    <div className={classes.partnerlogos}>
                        <img src={Britam} alt='Britam' />
                    </div>
                    <div className={classes.partnerlogos}>
                        <img src={Hollard} alt='Holard insurance' />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classes.partnerlogos}>
                        <img src={apainsurance} alt='API insurance' />
                    </div>
                    <div className={classes.partnerlogos}>
                        <img src={metropolitan} alt='Metropolitan Insurance' />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classes.partnerlogos}>
                        <img src={absa} alt='Absa' />
                    </div>
                    <div className={classes.partnerlogos}>
                        <img src={mfsafrica} alt='MFS Africa' />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classes.partnerlogos}>
                        <img src={AirtelLogo} alt='Airtel' />
                    </div>
                    <div className={classes.partnerlogos}>
                        <img src={mtn} alt='MTN' />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classes.partnerlogos}>
                        <img src={orange} alt='Orange' />
                    </div>
                    <div className={classes.partnerlogos}>
                        <img src={Equitel} alt='equitel' />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classes.partnerlogos}>
                        <img src={realpeople} alt='Real People' />
                    </div>
                    <div className={classes.partnerlogos}>
                        <img src={fnbzambia} alt='FNB zambia' />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classes.partnerlogos}>
                        <img src={instafinance} alt='Insta Finance' />
                    </div>
                    <div className={classes.partnerlogos}>
                        <img src={mpost} alt='mpost' />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classes.partnerlogos}>
                        <img src={equitybank} alt='Equity Bank' />
                    </div>
                    <div className={classes.partnerlogos}>
                        <img src={accessbank} alt='accessbank' />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classes.partnerlogos}>
                        <img src={rafode} alt='rafode' />
                    </div>
                    <div className={classes.partnerlogos}>
                        <img src={bboxx} alt='bboxx' />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classes.partnerlogos}>
                        <img src={attlehang} alt='attlehang' />
                    </div>
                    <div className={classes.partnerlogos}>
                        <img src={twende} alt='twende' />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classes.partnerlogos}>
                        <img src={prudential} alt='prudential' />
                    </div>
                 
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default MobilePartners