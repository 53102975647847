import React from 'react'
import TableInc from '../../../../../shared-ui/TableInc';
import SkeletonWrapper from '../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import { addCustomerBankDetails } from '../../../../../lib/requests.es6';
import { SOUTH_AFRICA_BANKS_CODES, SOUTH_AFRICA_BANKS_NAME } from '../../../../../containers/NewProductList/PoductConstants';

const BankDetails = (props) => {


  if (props.loading) {
    return <SkeletonWrapper loading={props.loading} />
  }

  if(props.banksDetails){
    const banks = [props.banksDetails]
    return (
      <>
        <TableInc
          columns={[
            { title: 'Account Name', field: 'account_name' },
            { title: 'Bank Name', field: 'bank_name', lookup: SOUTH_AFRICA_BANKS_NAME },
            { title: 'Account Number', field: 'account_number' },
            { title: 'Account Type', field: 'account_type' },
            { title: 'Branch Code', field: 'branch_code',lookup: SOUTH_AFRICA_BANKS_CODES },
          ]}
          data={
            banks
          }
          options={{
            pageSize: banks.length + 1,
            showTitle: false,
            actionsColumnIndex: -1
  
          }}
  
          editable={{
  
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
  
                const bankDetails = {
                  "bank_name": newData.bank_name,
                  "account_name": newData.account_name,
                  "account_number": newData.account_number,
                  "account_type": newData.account_type,
                  "branch_code": newData.branch_code,
                  "customer_guid": props.customerGuid,
                }
                console.log("bankDetails", bankDetails)
                addCustomerBankDetails(bankDetails).then(
                  (response) => {
                    console.log("here is response", response);
                    resolve();
                    window.location.reload();
                  }
                ).catch(
                  (error) => {
                    console.log("did not work", error)
                    reject();
                  }
                )
  
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                console.log('you are updating  this old_data: ', oldData, " with this new data: ", newData)
                resolve();
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                console.log('you are deleting this: ', oldData)
                resolve();
              }),
          }}
  
        /> 
      </>
    )
  }

}

export default BankDetails