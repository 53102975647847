import React from 'react';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import LocalizedStrings from 'react-localization';
import { buildFailureMessage, formatDate } from '../../../../lib/utils.es6';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Switch,
  Autocomplete
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import * as actions from '../../../BuyPolicy/actions.es6';
import NoRecord from '../../../../components/NoRecord/index.es6';
import { updateCurrentLanguage } from '../../../../lib/languageUtils.es6';
import { localisedText } from '../../../../lib/localisation.es6';
import * as globalActions from '../../../Main/actions.es6';

import {
  partnerPolicyFieldsContains,
  partnerCanDebit,
  partnerCanEditDebit,
  isSouthAfricanPartner,
} from '../../../../lib/access.es6';
import AlertDialog from '../../../../components/AlertDialog/index.es6';
import { PRIMARY_THEME_COLOR_SHADE_1 } from '../../../../lib/constants';
import { getCustomer } from '../../../Customer/actions.es6';

export class BuyPolicy extends React.Component {
  constructor(props) {
    super(props);
    const localizedStrings = new LocalizedStrings(localisedText);
    this.state = {
      selectedProduct: localizedStrings.selectProduct,
      selectedAddOnProduct: localizedStrings.selectProduct,
      selectedAddOnProductCoverType: null,
      selectedPremium: localizedStrings.selectPremium,
      labels: localizedStrings,
      spouseBirthday: null,
      validationErrors: [],
      validationErrorFields: [],
      debit: false,
      addProductAddons: false,
    };
  }

  componentWillMount() {
    // NOTE: We are assuming that the products don't change often
    if (this.props.policyPurchaseData.products.length < 1) {
      this.props.dispatch(actions.listPaidProductsRequest());
    }
    this.updateLanguage();
    this.props.dispatch(globalActions.showCustomerBar());
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'BuyPolicy',
      this.state.labels
    );
  }

  resetAfterSucesfullPurchase() {
    this.props.dispatch(actions.resetPolicyPurchase());
    const guid = this.props.customerData.currentCustomer ? this.props.customerData.currentCustomer.guid : ""
    if (guid) {
      this.props.dispatch(getCustomer(guid));
    }
    this.handleClose()
  }

  resetAfterFailedPurchase() {
    this.props.dispatch(actions.resetPolicyPurchase());
  }

  handleClose() {
    this.props.closeBuyPolicyDialog()
  }


  selectProduct(type, newValue) {
    const labels = this.state.labels;
    const validationErrorFields = this.removeItemFromArray(
      this.state.validationErrorFields,
      'products'
    );
    const validationErrors = this.removeItemFromArray(
      this.state.validationErrors,
      labels.selectProductError
    );

    if (type === 'mainProduct') {
      this.setState({
        selectedProduct: newValue,
        selectedProductCoverType: newValue.cover_type,
      });
    }
    else {
      this.setState({
        selectedAddOnProduct: newValue,
        selectedAddOnProductCoverType: newValue.cover_type,
      });
    }

    this.setState({
      selectedPremium: labels.selectPremium,
      validationErrorFields,
      validationErrors,
    });
  }

  removeItemFromArray(array, item) {
    return array.filter(elt => elt !== item);
  }

  dateHandler2(moment) {
    const rawDate = formatDate(new Date(moment.target.value));
    this.setState({ spouseBirthday: rawDate });
  }

  spouseNameValid() {
    const form = new FormData(document.getElementById('policyPurchaseForm'));
    if (this.spouseNameVisible()) {
      const spouseName = form.get('spouse_name');
      if (spouseName) {
        return spouseName.length !== 0;
      }
    }
    return true;
  }

  updateSpouseNameInfo(payload) {
    const form = new FormData(document.getElementById('policyPurchaseForm'));
    if (this.spouseNameVisible()) {
      const spouseName = form.get('spouse_name');
      if (spouseName) {
        payload.spouse_name = spouseName;
      }
    }
  }

  spouseNameDisplay() {
    const spouseNameStyle = {
      display: this.spouseNameVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    return (
      <div className="col-md-6 col-sm-6 col-xs-12"  style={{...spouseNameStyle, marginBottom: '10px'}}>
        <TextField
          fullWidth
          name="spouse_name"
          type="text"
          label={labels.spouseName} variant="outlined"
        />
      </div>
    );
  }

  spouseNameVisible() {
    return partnerPolicyFieldsContains('spouse_name');
  }

  spouseDobValid() {
    if (this.spouseDobVisible()) {
      const spouseDob = this.state.spouseBirthday;
      if (spouseDob) {
        return spouseDob !== '';
      }
    }
    return true;
  }

  updateSpouseDobInfo(payload) {
    if (this.spouseDobVisible()) {
      const spouseDob = this.state.spouseBirthday;
      if (spouseDob) {
        payload.spouse_dob = spouseDob;
      }
    }
  }

  spouseDobDisplay() {
    const spouseDobStyle = {
      display: this.spouseDobVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    return (
      <div className="control-label col-md-6 col-sm-6 col-xs-12" style={{...spouseDobStyle, marginBottom: '10px'}} >
        <TextField
          fullWidth
          name="date-of-birth"
          id="date-of-birth"
          type="date"
          format="YYYY-MM-DD"
          value={this.state.spouseBirthday}
          onChange={this.dateHandler2.bind(this)}
          label={labels.spouseDateOfBirth}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    );
  }

  spouseDobVisible() {
    return partnerPolicyFieldsContains('spouse_dob');
  }

  spouseIdValid() {
    const form = new FormData(document.getElementById('policyPurchaseForm'));
    if (this.spouseIdVisible()) {
      const spouseId = form.get('spouse_id');
      if (spouseId) {
        return spouseId.length !== 0;
      }
    }
    return true;
  }

  updateSpouseIdInfo(payload) {
    const form = new FormData(document.getElementById('policyPurchaseForm'));
    if (this.spouseIdVisible()) {
      const spouseId = form.get('spouse_id');
      if (spouseId) {
        payload.spouse_id = spouseId;
      }
    }
  }

  spouseIdDisplay() {
    const spouseIdStyle = {
      display: this.spouseIdVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    return (
        <div className={`col-md-6 col-sm-6 col-xs-12 ${spouseIdStyle}`} style={{marginBottom: '10px'}}>
          <TextField
            fullWidth
            name="spouse_id"
            type="text"
            label={labels.spouseId} variant="outlined"
          />
        </div>
    );
  }

  spouseIdVisible() {
    return partnerPolicyFieldsContains('spouse_id');
  }

  displaySpouseInfo() {
    let result;
    if (this.state.selectedProductCoverType === 'Family') {
      result = (
        <>
          {this.spouseNameDisplay()}
          {this.spouseIdDisplay()}
          {this.spouseDobDisplay()}
          <br/>
        </>
      );
    }
    return result;
  }

  selectPremium(e) {
    console.log('premium e target');
    console.log(e.target);
    const labels = this.state.labels;
    const validationErrorFields = this.removeItemFromArray(
      this.state.validationErrorFields,
      'premiums'
    );
    const validationErrors = this.removeItemFromArray(
      this.state.validationErrors,
      labels.selectPremiumError
    );
    this.setState({
      selectedPremium: e.target.value,
      validationErrorFields,
      validationErrors,
    });
  }

  premiumsOptions(premiumDisplay) {
    const labels = this.state.labels;
    if (!this.state.selectedProduct) {
      return (
        <div className={`col-md-6 col-sm-6 col-xs-12 ${premiumDisplay}`}>
          <TextField
            type="text"
            name="noPremium"
            readOnly
            value={labels.selectProductTitle}
            label={labels.selectProductTitle} variant="outlined"
          />
        </div>
      );
    }
    const productCode = this.state.addProductAddons ? this.state.selectedAddOnProduct.code : this.state.selectedProduct.code
    const premiums = this.getPaidProductPremiums(productCode);

    console.log('props: ', this.props);
    return (
      <div className={`col-md-6 col-sm-6 col-xs-12 ${premiumDisplay}`}>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label"> {labels.cover}</InputLabel>

          <Select
            labelId="demo-simple-select-label"
            id="premiums"
            required
            onChange={this.selectPremium.bind(this)}
            value={this.state.selectedPremium}
            label={labels.cover}
          >
            <MenuItem key="-1" value={labels.selectPremium}>
              {labels.selectPremium}
            </MenuItem>
            {premiums.map((premium, index) =>
              premium.payment_method === 'Mobile_Money' ? (
                <MenuItem key={index} value={premium.amount_in_cents}>
                  {this.premiumLabel(premium)}
                </MenuItem>
              ) : null
            )}
          </Select>
        </FormControl>
      </div>
    );
  }

  clearForm() {
    document.getElementById('policyPurchaseForm').reset();
    const labels = this.state.labels;
    this.setState({
      selectedProduct: labels.selectProduct,
      selectedPremium: labels.selectPremium,
      spouseBirthday: '',
      validationErrorFields: [],
      validationErrors: [],
    });
    this.handleClose()
  }

  toggleAddProductAddons() {
    this.setState({ addProductAddons: !this.state.addProductAddons })
  };

  premiumLabel(premium) {
    // TODO: Pluralise this
    return `${premium.cardinality} ${premium.granularity
      }(${premium.amount_in_cents / 100})`;
  }

  productLabel(product) {
    return `${product.name} (${product.cover_type})`;
  }

  getPaidProductPremiums(productCode) {
    const allProducts = this.props.policyPurchaseData.products;
    const matchingProducts = allProducts.filter(
      entry => entry.code === productCode
    );
    const currentProduct = matchingProducts[0]; // NOTE: We are very optimistic
    let premiums = [];
    if (currentProduct) {
      premiums = currentProduct.premiums;
    }
    return premiums;
  }

  validateData() {
    const labels = this.state.labels;
    const productCode = document.getElementById('products').value;
    if (productCode === labels.selectProduct) {
      this.setState({
        validationErrors: [labels.selectProductError],
        validationErrorFields: ['products'],
      });
      return false;
    }
    const premium = this.state.selectedPremium;
    if (premium === labels.selectPremium) {
      this.setState({
        validationErrors: [labels.selectPremiumError],
        validationErrorFields: ['premiums'],
      });
      return false;
    }
    return true;
  }

  resetErrors() {
    this.setState({ validationErrors: [], validationErrorFields: [] });
  }

  submitPolicyPurchaseHandler(e) {
    e.preventDefault();
    this.resetErrors();
    const dataIsValid = this.validateData();
    if (dataIsValid === false) {
      return false;
    }
    const productCode = this.state.addProductAddons ? this.state.selectedAddOnProduct.code : this.state.selectedProduct.code

    const premium = this.state.selectedPremium;

    const purchaseData = {
      product_code: productCode,
      msisdn: this.props.customerData.currentCustomer.msisdn,
      amount_in_cents: premium,
    };
    const productCoverType =  this.state.addProductAddons ? this.state.selectedAddOnProductCoverType : this.state.selectedProductCoverType
    if (productCoverType === 'Family') {
      this.updateSpouseIdInfo(purchaseData);
      if (!this.spouseIdValid()) {
        delete purchaseData.spouse_id;
      }
      this.updateSpouseNameInfo(purchaseData);
      if (!this.spouseNameValid()) {
        delete purchaseData.spouse_name;
      }
      this.updateSpouseDobInfo(purchaseData);
      if (!this.spouseDobValid()) {
        delete purchaseData.spouse_dob;
      }
    }
    this.updateDebitInfo(purchaseData);
    this.props.dispatch(actions.purchasePolicyRequest(purchaseData));
    return false;
  }

  updateDebitInfo(purchaseData) {
    if (this.debitVisible()) {
      purchaseData.debit = this.state.debit;
    }
  }

  successMessage() {
    const labels = this.state.labels;
    let successMessage = labels.successMessage;
    const policyPurchaseInfo = this.props.policyPurchaseData.policyPurchaseInfo;
    if (policyPurchaseInfo && policyPurchaseInfo.message) {
      successMessage = this.props.policyPurchaseData.policyPurchaseInfo.message;
    }
    return successMessage;
  }

  failureMessage() {
    const validationErrors = this.state.validationErrors;
    if (validationErrors.length !== 0) {
      return validationErrors[0]; // NOTE: Assuming there is always only 1 error
    }
    const labels = this.state.labels;
    const defaultErrorMessage = labels.defaultErrorMessage;
    const errorMessage = buildFailureMessage(
      defaultErrorMessage,
      this.props.policyPurchaseData.errors
    );
    return errorMessage;
  }

  filterProductAddons(){
    if(!this.state.selectedProduct.guid){
      return []
    }

    const addons = [];
    const allProds = this.props.policyPurchaseData.products;

    for(let prod of allProds){
      if(
          prod.tag
          &&  typeof prod.tag === "object"
          && prod.tag.mainProduct
          && prod.tag.mainProduct.guid === this.state.selectedProduct.guid
        ){
        addons.push(prod)
      }
    }
    return addons
  }

  displayBuyPolicyForm() {
    const labels = this.state.labels;
    const currentCustomer = this.props.customerData.currentCustomer;
    if (!currentCustomer) {
      return (
        <NoRecord
          noTextLabel={labels.titleNoCustomer}
          searchLabel={labels.searchCustomer}
          redirectTo="admin/search"
        />
      );
    }
    const productDisplay = classNames({
      'form-group': true,
      error: this.state.validationErrorFields.includes('products'),
    });
    const premiumDisplay = classNames({
      'form-group': true,
      error: this.state.validationErrorFields.includes('premiums'),
    });

    const productAddons = this.filterProductAddons()
    const mainProducts = this.props.policyPurchaseData.products.filter(p => !p.tag || (typeof p.tag !== "object"))
    console.log({productAddons, mainProducts, prods: this.props.policyPurchaseData.products });

    return (
      <div>

        <Dialog open={true} fullWidth maxWidth='lg' fullScreen onClose={this.clearForm.bind(this)}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.clearForm.bind(this)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {labels.titleBuyPolicy + 'abx'}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
                <form
                  id="policyPurchaseForm"
                  noValidate
                  onSubmit={this.submitPolicyPurchaseHandler.bind(this)}
                >
                  {
                    isSouthAfricanPartner() &&
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <label>Add product add ons</label>
                      <Switch
                        checked={this.state.addProductAddons}
                        onChange={this.toggleAddProductAddons.bind(this)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </div>
                  }


                    <div className={`col-md-6 col-sm-6 col-xs-12 ${productDisplay}`}>
                      <Autocomplete
                        id="products"
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={labels.selectProduct}
                            placeholder={labels.selectProduct}
                            required
                          />
                        )}
                        fullWidth
                        value={this.state.selectedProduct}
                        onChange={(event, newValue) => {
                          this.selectProduct("mainProduct", newValue);
                        }}
                        getOptionLabel={a => a.name || labels.selectProduct}
                        options={mainProducts.sort((a, b) => a.name.localeCompare(b.name))}
                      />
                  </div>

            {this.state.addProductAddons &&
                    <div className={`col-md-6 col-sm-6 col-xs-12 ${productDisplay}`}>
                      <Autocomplete
                        id="add-products"
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={labels.selectProduct}
                            placeholder={labels.selectProduct}
                            required
                          />
                        )}
                        fullWidth
                        value={this.state.selectedAddOnProduct}
                        onChange={(event, newValue) => {
                          this.selectProduct("addOnProduct", newValue);
                        }}
                        getOptionLabel={a => a.name || labels.selectProduct}
                        options={productAddons.sort((a, b) => a.name.localeCompare(b.name))}
                      />
                    </div>
                  }

                  {this.premiumsOptions(premiumDisplay)}
                  {this.displaySpouseInfo()}
                  {this.debitDisplay()}

              <div className="col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center', width: '100%', marginTop: '3%' }}>
                <Button variant="contained" type="submit" onClick={this.submitPolicyPurchaseHandler.bind(this)} style={{ marginRight: '1%', background: PRIMARY_THEME_COLOR_SHADE_1, }}> {labels.submitBuyPolicy}</Button>
                <Button variant="outlined" onClick={this.clearForm.bind(this)} style={{ marginRight: '3%', borderColor: PRIMARY_THEME_COLOR_SHADE_1, color: PRIMARY_THEME_COLOR_SHADE_1, fontWeight: 'bold' }}> {labels.cancel}</Button>
              </div>

                </form>
          </DialogContent>
        </Dialog>


        <AlertDialog
          custom
          show={this.props.policyPurchaseData.loader}
          size="sm"
          style={{ marginTop: '0', top: '30vh' }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={'cancel'}
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2>purchasing policy</h2>
        </AlertDialog>

        <AlertDialog
          success
          show={!!this.props.policyPurchaseData.policy_purchase_succesful}
          size="sm"
          title={this.props.policyPurchaseData.policyPurchaseInfo ? this.props.policyPurchaseData.policyPurchaseInfo.message : 'Policy purchase request submitted'}
          style={{ marginTop: '0', top: '30vh' }}
          onConfirm={() => { this.resetAfterSucesfullPurchase() }}
          confirmBtnText={'ok'}
          showCancel={false}
          cancelBtnText={'cancel'}
          confirmBtnCssClass
        >
        </AlertDialog>


        <AlertDialog
          show={!!this.props.policyPurchaseData.policy_purchase_failed}
          danger
          title={'Error purchasing policy'}
          onConfirm={() => { this.resetAfterFailedPurchase() }}
          confirmBtnText={'Try again'}
          confirmBtnCssClass
          showCancel={false}
          style={{ marginTop: '0', top: '30vh' }}
        >
          {this.props.policyPurchaseData.errors ? this.props.policyPurchaseData.errors.message : ''}
        </AlertDialog>


      </div>
    );
  }

  toggleDebit() {
    const debit = this.state.debit;
    this.setState({ debit: !debit });
  }

  debitDisplay() {
    const debitStyle = {
      display: this.debitEditable() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    return (
        <div className="col-md-6 col-sm-6 col-xs-12" style={debitStyle}>
        {labels.debit}

          <Checkbox
            required
            checked={this.state.debit}
            onChange={this.toggleDebit.bind(this)}
            color="primary"
          />
        </div>
    );
  }

  debitVisible() {
    return partnerCanDebit();
  }

  debitEditable() {
    return partnerCanEditDebit();
  }

  render() {
    return <div>{this.displayBuyPolicyForm()}</div>;
  }
}

export default BuyPolicy;
