import React from 'react';


import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { hashHistory } from 'react-router';

import AlertDialog from '../../components/AlertDialog/index.es6';
import Snackbar from 'material-ui/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactGA from 'react-ga';

// import MainPageContent from '../../components/MainPageContent/index.es6';
import * as searchActions from '../Search/actions.es6';

import {
  resetSearchFlag,
  searchCustomerByMsisdnRequest,
} from '../Search/actions.es6';
import * as langUtils from '../../lib/languageUtils.es6';
import {
  resetUnauthorizedAction,
  userLogout,
  sessionRefreshRequest,
} from '../Login/actions.es6';
import {
  setCurrentPartner,
  updateGlobalLanguage,
  customerBarShowSearch,
  customerBarShowUser,
} from '../Main/actions.es6';
import { localisedText } from '../../lib/localisation.es6';
import {
  getLocalNumberLength,
  getUserFullname,
  hasAuthourity,
  getPartnerGuid,
} from '../../lib/access.es6';
import { getCurrentUserInfo } from '../../lib/utils.es6';

import './style.css';
import { PRIMARY_THEME_COLOR_SHADE_1, SECONDARY_THEME_COLOR_SHADE_1, SECONDARY_THEME_COLOR_SHADE_2 } from '../../lib/constants';
import { Button, IconButton } from '@mui/material';
import { ArrowBack, Search } from '@mui/icons-material';
import DrawerNew from '../../components/Drawer/DrawerNew';
import { makeCurrentCustomer } from '../Customer/actions.es6';

export class Admin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      labels: new LocalizedStrings(localisedText),
      msisdn: '',
      submenus: {},
      partnerGuid: getPartnerGuid(),
    };
  }


  componentWillMount() {
    this.updateLanguage();
    const currentUser = getCurrentUserInfo();
    if (currentUser) {
      ReactGA.set({ userId: currentUser.user_guid });
    }
    // TODO: Needs more research
    // this.warnUserOnReload();
    // console.log('params: ', this.props.location);

  }

  componentDidUpdate() {
    if (this.props.searchData.searchFinishedFlag) {
      this.props.dispatch(resetSearchFlag());
    }
    this.updateLanguage();
  }

  handleRequestClose() {
    this.props.dispatch(resetUnauthorizedAction());
  }

  warnUserOnReload() {
    window.addEventListener('beforeunload', function (e) {
      if (!!window.localStorage.getItem('reload_event')) {
        window.localStorage.removeItem('reload_event');
        return;
      }
      const FQDN = window.location.origin;
      if (FQDN === 'http://localhost') {
        return;
      }

      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = '';
    });
  }

  updateLanguage() {
    const language = this.props.globalData.language;
    let resetLanguage = false;
    if (!language) {
      resetLanguage = true;
    }
    const labels = this.state.labels;
    if (resetLanguage || labels.getLanguage() !== language) {
      labels.setLanguage(language);
      this.setState({});
    }
  }

  selectPartner(partner) {
    this.props.dispatch(setCurrentPartner(partner));
  }

  handleToggle() {
    this.setState({ open: !this.state.open });
  }

  selectDrawerMenu(url) {
    this.setState({ open: !this.state.open });
    hashHistory.push(url);
  }

  toggleSearchBar() {
    const currentState = this.props.globalData.isSearchBar;
    if (currentState) {
      this.props.dispatch(customerBarShowUser());
    } else {
      this.props.dispatch(customerBarShowSearch());
    }
  }

  msisdnFieldHandler(event) {
    this.setState({ msisdn: event.target.value });
  }

  submitSearchCustomerHandler(e) {
    e.preventDefault();
    const searchCriteria = this.state.msisdn;
    if (!searchCriteria) {
      return;
    }
    const customerData = {
      msisdn: searchCriteria,
      page: 0,
      size: this.props.searchData.size,
    };
    this.props.dispatch(searchCustomerByMsisdnRequest(customerData));
    hashHistory.push('admin/search');
  }

  logout() {
    this.props.dispatch(userLogout());
  }

  refreshToken() {
    this.props.dispatch(sessionRefreshRequest());
  }

  refreshDialogDisplay() {
    const labels = this.state.labels;

    if (this.props.loginData.loader) {
      return (
        <center>
          <CircularProgress />
        </center>
      );
    }
    if (this.props.loginData.refreshSessionExpired) {
      return <p style={{ color: 'red' }}>{labels.oldSession} </p>;
    }
    return labels.sessionDialogText;
  }

  username() {
    const fullName = getUserFullname();
    const labels = this.state.labels;
    if (fullName) {
      return fullName;
    }
    return labels.anonymous;
  }

  updateGlobalLanguage(language) {
    this.props.dispatch(updateGlobalLanguage(language));
  }

  renderCustomerName() {
    // TODO: These should be css classes
    const searchBar = {
      display: this.props.globalData.isSearchBar ? 'block' : 'none',
    };

    const pill = {
      display: this.props.globalData.isSearchBar ? 'none' : 'block',
    };

    const switchCustomer = {
      margin: '10px',
      display: 'inline-block',
      backgroundColor: SECONDARY_THEME_COLOR_SHADE_2
    };

    const customerBarStyle = {
      minHeight: '100%',
      display: this.props.globalData.customerBarState ? 'block' : 'none',
      marginTop: '5%',
      backgroundColor: PRIMARY_THEME_COLOR_SHADE_1,
    };

    const currentCustomer = this.props.currentCustomerData.currentCustomer;

    const labels = this.state.labels;
    if (currentCustomer) {
      return (
        <div style={customerBarStyle}>
          <div style={pill}>
            <h3 className="customer-details pill-text" style={{ color: 'white' }}>
              {currentCustomer.msisdn} {currentCustomer.full_name}
            </h3>
            {
              hasAuthourity('VIEW_CUSTOMER_SEARCH') &&
              <Button
                onClick={this.toggleSearchBar.bind(this)}
                className="pull-right switch-customer"
                style={switchCustomer}
                variant='contained'
              >
                {labels.switchCustomer}
              </Button>
            }
          </div>

          <div style={searchBar}>
            <form
              id="findCustomerByMsisdn"
              className="form-horizontal form-label-left"
              noValidate
              onSubmit={this.submitSearchCustomerHandler.bind(this)}
            >
              <div style={{ paddingTop: '10px' }} className="form-group">
                <IconButton
                  onClick={this.toggleSearchBar.bind(this)}
                  className="col-md-3 col-sm-3 col-xs-3"
                  style={{
                    paddingTop: '8px',
                    fontSize: '25px',
                    color: 'white'
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <div className="col-md-4 col-sm-4 col-xs-4">
                  <input
                    id="msisdn"
                    type="number"
                    min="0"
                    className="form-control"
                    name="msisdn"
                    placeholder={`Full Phone Number are ${getLocalNumberLength()} digits`}
                    onChange={this.msisdnFieldHandler.bind(this)}
                    value={this.state.msisdn}
                  />
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1">
                  <Button type="submit" variant='contained' size='small' startIcon={<Search />} style={{ backgroundColor: SECONDARY_THEME_COLOR_SHADE_1 }}>
                    {labels.find}
                  </Button>
                </div>
                <div className="col-md-2 col-sm-2 col-xs-2">
                  <Button size='small' variant='contained' href='#admin/search' style={{ backgroundColor: SECONDARY_THEME_COLOR_SHADE_2 }}>
                    {labels.advancedSearch}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      );
    }
    return <div className="no-current-customer" />;
  }

  handleSubmenuClick(submenu) {
    this.setState({
      submenus: {
        ...this.state.submenus,
        [submenu]: !this.state.submenus[submenu],
      },
    });
    if (submenu === 'summary') {
      hashHistory.push('admin/home');
    }
  }


  searchForCustomer(customerData) {
    this.props.dispatch(
      searchActions.searchCustomerByMsisdnRequest(customerData)
    );
  }

  handleSearchClose() {
    this.props.dispatch(searchActions.resetSearchResults());
  }

  selectCustomer(customer) {
    this.props.dispatch(makeCurrentCustomer(customer));
  }

  render() {
    const mainBody = document.getElementById('main');
    const labels = this.state.labels;
    if (mainBody) {
      mainBody.className = 'nav-md';
    }


    return (

      <div className="container body">
        <div className="main_container">
            <DrawerNew
              drawerData={this.props.drawerData}
              selectedItem={this.props.drawerData.selectedItem}

              // global search props
              searchForCustomer={this.searchForCustomer.bind(this)}
              customerList={this.props.searchData.customerList}
              resetSearch={this.handleSearchClose.bind(this)}
              selectCustomer={this.selectCustomer.bind(this)}

              searchData={this.props.searchData}
              globalData={this.props.globalData}
              dispatch={this.props.dispatch}


              // navigation props
              availableLanguages={langUtils.availableLanguages()}
              logOutUserHandler={this.logout.bind(this)}
              username={this.username()}
              selectPartner={this.selectPartner.bind(this)}
              onSelectLanguage={this.updateGlobalLanguage.bind(this)}
              selectedLanguage={this.props.globalData.language}

              location={this.props.location}

              labels={labels}
            >
              {/* <MainPageContent> */}
                <div style={{ margin: '3% 1%' }}>
                  {this.props.children}
                 </div>
              {/* </MainPageContent> */}
            </DrawerNew>

          {!this.props.loginData.sessionOpen ?
            <AlertDialog
              warning
              title={labels.sessionDialogTitle}
              onCancel={() => this.logout()}
              onConfirm={() => this.refreshToken()}
              showCancel
              showConfirm={!this.props.loginData.refreshSessionExpired}
              cancelBtnText={labels.endSession}
              confirmBtnText={labels.refresh_session}
            >
              {this.refreshDialogDisplay()}
            </AlertDialog> : ''
          }
          <Snackbar
            open={this.props.loginData.message !== null}
            message={
              this.props.loginData.message ? this.props.loginData.message : ''
            }
            autoHideDuration={4000}
            onRequestClose={this.handleRequestClose.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  currentCustomerData: state.currentCustomer,
  searchData: state.search,
  loginData: state.login,
  globalData: state.global,
  drawerData: state.drawerData
}))(Admin);
