const benefitOptions = [

  {
    product_guid: "Kinga_Ya_Mkulima",
    product_code: "III (182)",
    product_name: "Kinga_Ya_Mkulima III (182)",
    option: "III (182)",
    benefits: [
      {
        type: "DAILY HOSPITAL BENEFIT",
        cover_amount_in_cents: 1000 * 100 * 30,
        waiting_period_in_days: 30,
        cover_usage_count: 30,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      },
      {
        type: "DEATH BENEFIT",
        cover_amount_in_cents: 10000 * 100,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      },
      {
        type: "ADDITIONAL SURGERY BENEFIT",
        cover_amount_in_cents: 10000 * 100,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      }
    ],
    adult_premim: 55,
    child_premium: 40
  },
  {
    product_guid: "Kinga_Ya_Mkulima",
    product_code: "IV (183)",
    product_name: "Kinga_Ya_Mkulima IV (183)",
    option: "IV (183)",
    benefits: [
      {
        type: "DAILY HOSPITAL BENEFIT",
        cover_amount_in_cents: 1500 * 100 * 30,
        waiting_period_in_days: 30,
        cover_usage_count: 30,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      },
      {
        type: "DEATH BENEFIT",
        cover_amount_in_cents: 15000 * 100,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      },
      {
        type: "ADDITIONAL SURGERY BENEFIT",
        cover_amount_in_cents: 15000 * 100,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      }
    ],
    adult_premim: 75,
    child_premium: 50
  },
  {
    product_guid: "Kinga_Ya_Mkulima",
    product_code: "VI (184)",
    product_name: "Kinga_Ya_Mkulima VI (184)",
    option: "VI (184)",
    benefits: [
      {
        type: "DAILY HOSPITAL BENEFIT",
        cover_amount_in_cents: 2000 * 100 * 30,
        waiting_period_in_days: 30,
        cover_usage_count: 30,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      },
      {
        type: "DEATH BENEFIT",
        cover_amount_in_cents: 20000 * 100,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      },
      {
        type: "ADDITIONAL SURGERY BENEFIT",
        cover_amount_in_cents: 20000 * 100,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      }
    ],
    adult_premim: 95,
    child_premium: 65
  },
  {
    product_guid: "Kinga_Ya_Mkulima",
    product_code: "VII (185)",
    product_name: "Kinga_Ya_Mkulima VII (185)",
    option: "VII (185)",
    benefits: [
      {
        type: "DAILY HOSPITAL BENEFIT",
        cover_amount_in_cents: 2500 * 100 * 30,
        waiting_period_in_days: 30,
        cover_usage_count: 30,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      },
      {
        type: "DEATH BENEFIT",
        cover_amount_in_cents: 25000 * 100,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      },
      {
        type: "ADDITIONAL SURGERY BENEFIT",
        cover_amount_in_cents: 25000 * 100,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      }
    ],
    adult_premim: 115,
    child_premium: 80
  },
  {
    product_guid: "Kinga_Ya_Mkulima",
    product_code: "VIII (186)",
    product_name: "Kinga_Ya_Mkulima VIII (186)",
    option: "VIII (186)",
    benefits: [
      {
        type: "DAILY HOSPITAL BENEFIT",
        cover_amount_in_cents: 3000 * 100 * 30,
        waiting_period_in_days: 30,
        cover_usage_count: 30,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      },
      {
        type: "DEATH BENEFIT",
        cover_amount_in_cents: 30000 * 100,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      },
      {
        type: "ADDITIONAL SURGERY BENEFIT",
        cover_amount_in_cents: 30000 * 100,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      }
    ],
    adult_premim: 130,
    child_premium: 90
  },
  {
    product_guid: "Kinga_Ya_Mkulima",
    product_code: "VIII (187)",
    product_name: "Kinga_Ya_Mkulima VIII (187)",
    option: "VIII (187)",
    benefits: [
      {
        type: "DAILY HOSPITAL BENEFIT",
        cover_amount_in_cents: 4000 * 100 * 30,
        waiting_period_in_days: 30,
        cover_usage_count: 30,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      },
      {
        type: "DEATH BENEFIT",
        cover_amount_in_cents: 40000 * 100,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      },
      {
        type: "ADDITIONAL SURGERY BENEFIT",
        cover_amount_in_cents: 40000 * 100,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      }
    ],
    adult_premim: 150,
    child_premium: 105
  },
  {
    product_guid: "Kinga_Ya_Mkulima",
    product_code: "IX(188)",
    product_name: "Kinga_Ya_Mkulima IX(188)",
    option: "IX(188)",
    benefits: [
      {
        type: "DAILY HOSPITAL BENEFIT",
        cover_amount_in_cents: 5000 * 100 * 30,
        waiting_period_in_days: 30,
        cover_usage_count: 30,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      },
      {
        type: "DEATH BENEFIT",
        cover_amount_in_cents: 50000 * 100,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      },
      {
        type: "ADDITIONAL SURGERY BENEFIT",
        cover_amount_in_cents: 50000 * 100,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      }
    ],
    adult_premim: 180,
    child_premium: 130
  },
  {
    product_guid: "Kinga_Ya_Mkulima",
    product_code: "X(189)",
    product_name: "Kinga_Ya_Mkulima X(189)",
    option: "X(189)",
    benefits: [
      {
        type: "DAILY HOSPITAL BENEFIT",
        cover_amount_in_cents: 7500 * 100 * 30,
        waiting_period_in_days: 30,
        cover_usage_count: 30,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      },
      {
        type: "DEATH BENEFIT",
        cover_amount_in_cents: 75000 * 100,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      },
      {
        type: "ADDITIONAL SURGERY BENEFIT",
        cover_amount_in_cents: 75000 * 100,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      }
    ],
    adult_premim: 270,
    child_premium: 195
  },
  {
    product_guid: "Kinga_Ya_Mkulima",
    product_code: "XI(190)",
    product_name: "Kinga_Ya_Mkulima XI(190)",
    option: "XI(190)",
    benefits: [
      {
        type: "DAILY HOSPITAL BENEFIT",
        cover_amount_in_cents: 10000 * 100 * 30,
        waiting_period_in_days: 30,
        cover_usage_count: 30,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      },
      {
        type: "DEATH BENEFIT",
        cover_amount_in_cents: 100000 * 100,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      },
      {
        type: "ADDITIONAL SURGERY BENEFIT",
        cover_amount_in_cents: 100000 * 100,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      }
    ],
    adult_premim: 360,
    child_premium: 260
  }
]
export default benefitOptions


export const konyanaOptions = [

  {
    product_guid: "Demo_Plan_1",
    product_code: "Demo_Plan_1",
    product_name: "Demo_Plan_1",
    option: "Demo_Plan_1",
    benefits: [
      {
        "guid": "11f6664c-df02-4a69-8a56-a75439d9f7fa",
        "type": "DEATH BENEFIT",
        "cover_amount_in_cents": 70000000,
        "cover_usage_count": 0,
        "waiting_period_in_days": 30,
        "benefit_period_cardinality": 1,
        "benefit_period_granuality": "year",
        "created_at": "2023-10-26T05:41:49.600Z",
        "updated_at": "2023-10-26T05:41:49.600Z"
    },
    {
      "guid": "b70dc6f3-a17a-452c-b55a-ad1ae71f37bf",
      "type": "ADD-ON BENEFIT TOMBSTONE",
      "cover_amount_in_cents": 10000000,
      "cover_usage_count": 0,
      "waiting_period_in_days": 30,
      "benefit_period_cardinality": 1,
      "benefit_period_granuality": "year",
      "created_at": "2023-10-26T05:41:49.655Z",
      "updated_at": "2023-10-26T05:41:49.655Z"
  }
    ],
    adult_premim: 55,
    child_premium: 40
  },
  {
    product_guid: "Demo_Plan_2",
    product_code: "Demo_Plan_2",
    product_name: "Demo_Plan_2",
    option: "Demo_Plan_2",
    benefits: [
      {
        "guid": "11f6664c-df02-4a69-8a56-a75439d9f7fa",
        "type": "DEATH BENEFIT",
        "cover_amount_in_cents": 70000000,
        "cover_usage_count": 0,
        "waiting_period_in_days": 30,
        "benefit_period_cardinality": 1,
        "benefit_period_granuality": "year",
        "created_at": "2023-10-26T05:41:49.600Z",
        "updated_at": "2023-10-26T05:41:49.600Z"
    },
    {
      "guid": "b70dc6f3-a17a-452c-b55a-ad1ae71f37bf",
      "type": "ADD-ON BENEFIT TOMBSTONE",
      "cover_amount_in_cents": 10000000,
      "cover_usage_count": 0,
      "waiting_period_in_days": 30,
      "benefit_period_cardinality": 1,
      "benefit_period_granuality": "year",
      "created_at": "2023-10-26T05:41:49.655Z",
      "updated_at": "2023-10-26T05:41:49.655Z"
  }
    ],
    adult_premim: 75,
    child_premium: 50
  },
]