import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  statuscard: {
    background: "#FFFFFF",
    borderRadius: "8px",
    height: "auto",
    marginBottom: "20px",
  },
  statusheader: {
    background: "#173E4B",
    borderRadius: "8px 8px 0px 0px",
    height: "65px",
    "& h1": {
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.0015em",
      color: "#FFFFFF",
      paddingLeft: "30px"    }
  },
  policieswrapper: {
    width: "100%",
    "& table": {
      background: "#FFFFFF",
      border: "1px solid #A7A7A7",
      margin: "30px",
      width: "90%",
      "& tr": {
        borderTop: "1px solid #A7A7A7",
      },
      "& th": {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "15px",
        color: "#000000",
        padding: "10px",
        borderRight: "1px solid #A7A7A7",
      },
      "& td": {
        fontFamily: "open sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "15px",
        color: "#000000",
        padding: "10px",
        borderRight: "1px solid #A7A7A7",

      }
    }
  },
  nodatafound: {
    textAlign: "center",
    margin: "40px 0 40px 0",
    "& p":{
      fontFamily: "open sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "15px",
      color: "#000000",
    },
    "& button": {
      background: "#FBA92D",
      borderRadius: "8px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      boxShadow: "none",
      padding: "10px",
      textTransform: "capitalize",
      marginTop: "15px",
      "&:hover":{
          background: "#173E4B",
          boxShadow: "none",
      }
  }
  },
  active: {
    background: "#75A85E",
    borderRadius: "10px",
    color: "#fff",
    padding: "2px",
    fontSize: "10px",
    width: "47px",
    display: "inline-block",
    textAlign: "center",
    height: "20px",
    lineHeight: "15px",
  },
  cancelled: {
    background: "#B3251E",
    borderRadius: "10px",
    color: "#fff",
    padding: "2px",
    fontSize: "10px",
    width: "64px",
    display: "inline-block",
    textAlign: "center",
    height: "20px",
    lineHeight: "15px",
  },
  showmoreshowless: {
    marginLeft: "30px",
    "& ul": {
      display: "flex",
      padding: "0",
      cursor: "pointer",
      "& li": {
        listStyle: "none",
        fontFamily: "open sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "15px",
        color: "#000000",
        "&:nth-child(2)": {
          marginTop: "-3px"
        }
      }
    }
  },
  dependantstitle: {
    height: "60px",
    background: "#173E4B",
    color: "#fff",
    fontFamily: "Lato !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.0015em !important",
  },
  makepayment: {
    marginTop: "5px",
    textAlign: "right",
    marginRight: "30px",
    "& button": {
      background: "#fba92c",
      color: "#fff !important",
      borderRadius: "8px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      boxShadow: "none",
      padding: "10px",
      textTransform: "capitalize",
      marginBottom: "30px",
      marginLeft: "30px",
      "&:hover": {
        background: "#fba92c",
        boxShadow: "none",
      }
    }
  },
  paymentmodaltitle: {
    background: "#FBA92D",
    textAlign: "center",
    padding: "10px !important",
    "& h3": {
      color: "#fff",
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "24px",
      lineHeight: "24px",
    }
  },
  paymentinputwrapper: {
    marginTop: "30px",
    "& p": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
      textAlign: "center",
      marginBottom: "30px"
    },
    "& input": {
      background: "#F7F7F7",
      borderRadius: "8px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#000000",
    },
    "& label": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      letterSpacing: "0.005em",
      color: "#000000",

    }
  },
  paymentinputs: {
    "& .MuiOutlinedInput-root": {
      borderColor: "#000fff"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#C52328",
      borderWidth: "2px"
    }
  },
  paymentbtns: {
    marginBottom: "20px",
    "& button": {
      background: "#FBA92D",
      borderRadius: "8px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      boxShadow: "none",
      padding: "10px",
      marginRight: "20px",
      textTransform: "capitalize",
      "&:nth-child(2)":{
        background: "transparent",
        border: "1px solid #000",
        color: "#000",
        "&:hover": {
          background: "transparent",
          boxShadow: "none",
        }
      },
      "&:hover": {
        background: "#173E4B",
        boxShadow: "none",
      }
    }
  },
  uploadzone: {
    textAlign: "center",
    "& h4":{
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
    }
  }
}));