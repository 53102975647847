import React, { useState } from 'react'
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import TextField from '@mui/material/TextField';
import Aspinblacklogo from '../../../../../Assets/logoblack.png'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { CircularProgress, Dialog, DialogContent, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as langUtils from '../../../../../lib/languageUtils.es6';
import { getEnviroment } from '../../../../../lib/utils.es6';



const LoginPopup = (props) => {
    const classes = useStyle();
    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('')
    const [showUsernameError, setShowUsernameError] = useState(false)
    const [showPasswordError, setShowPasswordError] = useState(false)


    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handlePassword = (e) => {
        setPassword(e.target.value);
        setShowPasswordError(false)
    }

    const handleUsername = (e) => {
        setUsername(e.target.value)
        setShowUsernameError(false)
    }

    const login = () => {
        if (!username) {
            setShowUsernameError(true)
            return
        }

        if (!password) {
            setShowPasswordError(true)
            return
        }
        props.login({ username, password })
    }
    const onSelectLanguage = (event) => {
        props.updateGlobalLanguage(event.target.value);
    }


    const enviroment = getEnviroment();
    const isOnTestEnvironment = ['dev', 'staging'].includes(enviroment);

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={classes.backdroplogin}
                disableEnforceFocus
                maxWidth="lg"
            >
                <DialogContent>
                    <div className={classes.logincontent}>
                        <span onClick={props.close} className={classes.closebtnmodal}><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.6654 25.3346L25.3346 15.6654M25.3346 25.3346L15.6654 15.6654M15.375 37.5833H25.625C34.1667 37.5833 37.5834 34.1667 37.5834 25.625V15.375C37.5834 6.83334 34.1667 3.41667 25.625 3.41667H15.375C6.83335 3.41667 3.41669 6.83334 3.41669 15.375V25.625C3.41669 34.1667 6.83335 37.5833 15.375 37.5833Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        </span>
                        <img src={Aspinblacklogo} alt="aspin logo" />

                      {
                        isOnTestEnvironment
                          ? <>
                            <h3 style={isOnTestEnvironment ? { background: 'red', color: '#fff' } : {}}>WELCOME TO THE TEST ENVIRONMENT</h3>
                          </>
                          : <>
                            <h3>{props.labels.welcomeToAspin}</h3>
                          </>
                      }
                        
                    </div>
                    <div className={classes.loginform}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                                {showUsernameError && <p style={{ color: 'red' }}>please enter username</p>}
                                <TextField
                                    required
                                    id="username"
                                    label={props.labels.username}
                                    type="text"
                                    variant="outlined"
                                    value={username}
                                    onChange={handleUsername}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                    className={classes.loginforms}
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                {showPasswordError && <p style={{ color: 'red' }}>please enter password</p>}
                                <TextField
                                    required
                                    id="password"
                                    label={props.labels.password}
                                    type={showPassword ? 'text' : 'password'}
                                    variant="outlined"
                                    value={password}
                                    onChange={handlePassword}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className={classes.loginforms}
                                    InputProps={{
                                        className: classes.input,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth >
                                    <InputLabel sx={{ fontFamily: "Open Sans", fontSize: "14px" }} id="demo-simple-select-label">{props.labels.selectLanguage}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={props.language}
                                        label="Select Language"
                                        onChange={onSelectLanguage}
                                    >
                                        {langUtils.availableLanguages().map((language) => (
                                            <MenuItem key={language.value} sx={{ fontFamily: "Open Sans", fontSize: "14px" }} value={language.value} >{language.display}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className={classes.forgotpassword}>
                                    <li onClick={props.forgetpassword} > {props.labels.forgotPassword}</li>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.loginbtn}>
                        {props.loginError && <p style={{ color: 'red' }}>{props.loginError}</p>}
                        <Button onClick={login} type="submit">{props.loading ? <CircularProgress style={{ color: '#fff' }} /> : `${props.labels.submitLogin}`}</Button>
                    </div>

                </DialogContent>

            </Dialog>
        </>
    )
}

export default LoginPopup