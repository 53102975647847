
export const FaqsData = [
    {
        id: 1,
        title: "Can you support multiple currencies? ",
        body: "Yes we have multiple currencies on the platform"
    },
    {
        id: 2,
        title: "Is there any risk of our data being shared with a competitor insurer who is on your platform",
        body: " No we have a multi tenanted platform which ensures data from each partner is kept confidential from each other"
    },
    {
        id: 3,
        title: "Do you support APIs?",
        body: "Yes we support a range of APIs based on the required clients customers journey eg Communication: SMS/USSD/ Whatsapp Payments: multiple payment gateways ref bank, mobile money, airtime etc KYC API Claims API"
    },
    {
        id: 4,
        title: "Can your system integrate with our current system?",
        body: "  we have easy APIs that support integration with most systems so if you have APIs this should not be a problem.   However, what we have found is that insurers typically prefer to use our platform end to end which includes live dashboards, comprehensive reporting and ability to support data dumps as required. "
    },
    {
        id: 5,
        title: "Can you digitize only our claims process if we wanted?",
        body: "  Yes we can."
    },
    {
        id: 6,
        title: "Which Channels do you support? ",
        body: "  We are a full omni channel platform supporting from the most basic to the most sophisicated, including USSD, SMS, Whatsapp, mobi sites,  call centre agents and agents and brokers "
    },
]
