import * as types from '../../../lib/actionTypes.es6'




export function createExpenseRequest(payload) {
  return {
    type: types.CREATE_EXPENSE_REQUEST,
    payload,
  };
}

export function CreateExpenseSuccess(response) {
  return {
    type: types.CREATE_EXPENSE_SUCCESS,
    payload: response,
  };
}

export function CreateExpenseFailure(error) {
  return {
    type: types.CREATE_EXPENSE_FAILURE,
    payload: error,
  };
}


export function resetCreateExpenseSuccess(error) {
  return {
    type: types.RESET_CREATE_EXPENSE_SUCCESS,
  };
}

export function resetCreateExpenseError(error) {
  return {
    type: types.RESET_CREATE_EXPENSE_ERROR,
  };
}


export function deleteExpenseRequest(expense_guid) {
  return {
    type: types.DELETE_EXPENSE_REQUEST,
    payload: expense_guid,
  };
}
export function setExpenseToDelete(response) {
  return {
    type: types.SET_EXPENSE_TO_DELETE,
    payload: response,
  };
}
export function deleteExpenseSuccess(response) {
  return {
    type: types.DELETE_EXPENSE_SUCCESS,
    payload: response,
  };
}

export function resetHardDeleteExpenseSuccess() {
  return {
    type: types.RESET_DELETE_EXPENSE_SUCCESS,
  };
}


export function deleteExpenseFail(response) {
  return {
    type: types.DELETE_EXPENSE_FAILED,
    payload: response,
  };
}
export function resetExpenseToDelete() {
  return {
    type: types.RESET_EXPENSE_TO_DELETE,
  };
}
export function resetHardDeleteExpenseError() {
  return {
    type: types.RESET_DELETE_EXPENSE_ERROR,
  };
}


export function setExpenseToEdit(response) {
  return {
    type: types.SET_EXPENSE_TO_EDIT,
    payload: response,
  };
}

export function expenseEditRequest(payload) {
  return {
    type: types.EXPENSE_EDIT_REQUEST,
    payload,
  };
}

export function expenseEditSuccess(response) {
  return {
    type: types.EXPENSE_EDIT_SUCCESS,
    payload: response,
  };
}

export function expenseEditFail(response) {
  return {
    type: types.EXPENSE_EDIT_FAILED,
    payload: response,
  };
}

export function resetExpenseEditSuccess() {
  return {
    type: types.RESET_EXPENSE_EDIT_SUCCESS,
  };
}
export function resetExpenseEditError() {
  return {
    type: types.RESET_EXPENSE_EDIT_ERROR,
  };
}
export function resetExpenseEdit() {
  return {
    type: types.RESET_EXPENSE_EDIT,
  };
}


export function addclaimDocumentsRequest(payload) {
  return {
    type: types.ADD_CLAIM_DOCUMENTS_REQUEST,
    payload,
  };
}

export function addclaimDocumentsSuccess(response) {
  return {
    type: types.ADD_CLAIM_DOCUMENTS_SUCCESS,
    payload: response,
  };
}

export function addclaimDocumentsFailed(error) {
  return {
    type: types.ADD_CLAIM_DOCUMENTS_FAILURE,
    payload: error,
  };
}


export function setDocumentsToDelete(response) {
  return {
    type: types.SET_DOCUMENT_TO_DELETE,
    payload: response,
  };
}

export function deleteclaimDocumentsRequest(payload) {
  return {
    type: types.DELETE_CLAIM_DOCUMENTS_REQUEST,
    payload,
  };
}

export function deleteClaimDocumentsSuccess(response) {
  return {
    type: types.DELETE_CLAIM_DOCUMENTS_SUCCESS,
    payload: response,
  };
}

export function deleteClaimDocumentsFailed(error) {
  return {
    type: types.DELETE_CLAIM_DOCUMENTS_FAILURE,
    payload: error,
  };
}



export function resetHardDeleteDocumentSuccess() {
  return {
    type: types.RESET_DELETE_DOCUMENT_SUCCESS,
  };
}

export function resetDocumentToDelete() {
  return {
    type: types.RESET_DOCUMENT_TO_DELETE,
  };
}
export function resetHardDeleteDocumentError() {
  return {
    type: types.RESET_DELETE_DOCUMENT_ERROR,
  };
}



export function claimActionRequest(payload) {
  return {
    type: types.CLAIM_ACTION_REQUEST,
    payload,
  };
}

export function claimActionSuccess(response) {
  return {
    type: types.CLAIM_ACTION_SUCCESS,
    payload: response,
  };
}

export function claimActionFailure(error) {
  return {
    type: types.CLAIM_ACTION_FAILURE,
    payload: error,
  };
}


export function resetClaimActionSuccess(error) {
  return {
    type: types.RESET_CLAIM_ACTION_SUCCESS,
  };
}

export function resetClaimActionError(error) {
  return {
    type: types.RESET_CLAIM_ACTION_ERROR,
  };

}

export function claimNotesRequest(payload) {
  return {
    type: types.CLAIM_NOTES_REQUEST,
    payload,
  };
}

export function claimNotesSuccess(response) {
  return {
    type: types.CLAIM_NOTES_SUCCESS,
    payload: response,
  };
}

export function claimNotesFailure(error) {
  return {
    type: types.CLAIM_NOTES_FAILURE,
    payload: error,
  };
}


export function resetClaimNotesSuccess(error) {
  return {
    type: types.RESET_CLAIM_NOTES_SUCCESS,
  };
}

export function resetClaimNotesError(error) {
  return {
    type: types.RESET_CLAIM_NOTES_ERROR,
  };
}

export const getClaimProcessingDcpRequest = (guid) =>{
  return{
    type: types.GET_CLAIM_PROCESSING_DCP_REQUEST,
    payload: guid,
  }
}

export const getClaimProcessingDcpSuccess = (payload) =>{
  return{
    type: types.GET_CLAIM_PROCESSING_DCP_SUCCESS,
    payload: payload,
  }
}

export const getClaimProcessingDcpFailure = (error) =>{
  return{
    type: types.GET_CLAIM_PROCESSING_DCP_FAILURE,
    payload: error,
  }
}

export function saveClaimDcpDocumentRequest(payload) {
  return {
    type: types.SAVE_CLAIM_DCP_DOCUMENT_REQUEST,
    payload,
  };
}

export function saveClaimDcpDocumentSuccess(response) {
  return {
    type: types.SAVE_CLAIM_DCP_DOCUMENT_SUCCESS,
    payload: response,
  };
}

export function saveClaimDcpDocumentFailed(error) {
  return {
    type: types.SAVE_CLAIM_DCP_DOCUMENT_FAILURE,
    payload: error,
  };
}

export function claimFacilityNotesRequest(payload) {
  return {
    type: types.CLAIM_FACILTY_NOTES_REQUEST,
    payload,
  };
}

export function claimFacilityNotesSuccess(response) {
  return {
    type: types.CLAIM_FACILITY_NOTES_SUCCESS,
    payload: response,
  };
}

export function claimFacilityNotesFailure(error) {
  return {
    type: types.CLAIM_FACILITY_NOTES_FAILURE,
    payload: error,
  };
}


export function resetFacilityClaimNotesSuccess(error) {
  return {
    type: types.RESET_FACILITY_CLAIM_NOTES_SUCCESS,
  };
}

export function resetFacilityClaimNotesError(error) {
  return {
    type: types.RESET_FACILITY_CLAIM_NOTES_ERROR,
  };
}


export const getPreauthDetailsRequest = (guid) =>{
  return{
    type: types.GET_PREAUTH_DETAILS_REQUEST,
    payload: guid,
  }
}

export const getPreauthDetailsSuccess = (payload) =>{
  return{
    type: types.GET_PREAUTH_DETAILS_SUCCESS,
    payload: payload,
  }
}

export const getPreauthDetailsFailure = (error) =>{
  return{
    type: types.GET_PREAUTH_DETAILS_FAILURE,
    payload: error,
  }
}