import React from 'react';
import { connect } from 'react-redux';
import { hashHistory } from 'react-router';
import { DatePicker } from '@material-ui/pickers';
import LocalizedStrings from 'react-localization';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import DialogContentText from '@mui/material/DialogContentText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import CloseIcon from '@mui/icons-material/Close';
import * as actions from './actions.es6';
import { formatDate } from '../../../../lib/utils.es6';
import { localisedText } from '../../../../lib/localisation.es6';
import {
  getInternationalCallingCode,
  addInternationalCallingCode,
  getLocalNumberLength,
  partnerRegistrationFieldsContains,
  partnerLanguages,
  customerLanguagesVisible,
  partnerPullKyc,
  partnerWebDisplayFieldsContains,
  getPartner, getUserGuid, getUserFullname,
  isSouthAfricanPartner,
  isVAlidSouthAfricanNationalId
} from '../../../../lib/access.es6';
import AlertDialog from '../../../../components/AlertDialog/index.es6';
import { CircularProgress, AppBar, Toolbar, Typography, IconButton, DialogActions, Button, TextField, Grid, InputAdornment } from '@mui/material';
//import { resetRegistration } from '../../../RegisterCustomer/actions.es6';
import { PRIMARY_THEME_COLOR_SHADE_1 } from '../../../../lib/constants';
import { updateCurrentLanguage } from '../../../../lib/languageUtils.es6';


export class RegisterCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      birthday: null,
      labels: new LocalizedStrings(localisedText),
      language: '',
      hasWallet: true,
      nationalId: '',
      firstName: '',
      surname: '',
      fullName: '',
      msisdn: '',
      gender: '',
    };
  }
  handleClearForm(){
    this.setState({
      birthday: null,
      language: '',
      hasWallet: true,
      nationalId: '',
      firstName: '',
      surname: '',
      fullName: '',
      msisdn: '',
      SAIdCheckError: null,
    })
  }
  componentWillMount() {
    this.updateLanguage();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  componentDidMount() {
    const partner = getPartner();
    const nationalIdRegex = partner ? partner.country.national_id_regex : "";
    var pattern = new RegExp(nationalIdRegex);
    ValidatorForm.addValidationRule('isValidNationalId', (value) => {
      if(!isSouthAfricanPartner()){
        return pattern.test(value)
      }
      const result = isVAlidSouthAfricanNationalId(value)
      if(result.isValid){
        this.setState({
          birthday: formatDate(new Date(result.dob)),
          gender: result.gender,
          SAIdCheckError: result.error
        })
        return true
      }else {
        this.setState({ SAIdCheckError: result.error})
        return false
      }
    });

  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule('isValidNationalId');
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }


  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'RegisterCustomer',
      this.state.labels
    );
  }


  errorMissingPhoneText() {
    return this.state.labels.phoneNumberDigitsMustBe + getLocalNumberLength()
  }

  validPhone() {
    if (this.state.msisdn.length !== getLocalNumberLength()) {
      this.props.dispatch(actions.displayError(this.errorMissingPhoneText()));
      return false;
    }
    return true;
  }

  verifyDobWithNationalId(nationalId, dateOfBirth) {
    const dateOfBirthNew = dateOfBirth.replaceAll("-", "").substring(2);
    const nationalIdnew = nationalId.substring(0, 6);
    return (dateOfBirthNew === nationalIdnew)
  }


  handleClose = () => { }
  submitRegistrationHandler(e) {
    const labels = this.state.labels;
    e.preventDefault();
    if (!this.validPhone()) {
      return;
    }

    const birthday = this.state.birthday;
    if (!birthday && partnerPullKyc() === false) {
      this.props.dispatch(actions.displayError(labels.errorMissingDateOfBirth));
      return;
    }

    const customerData = {
      date_of_birth: birthday || null,
      msisdn: getInternationalCallingCode() + this.state.msisdn,
    };

    if (this.fullNameValid() === false) {
      this.props.dispatch(actions.displayError(labels.errorMissingFullName));
      return;
    }
    this.updateFullNameInfo(customerData);

    if (this.beneficiaryValid() === false) {
      // TODO: Translate this
      this.props.dispatch(
        actions.displayError(
          `Beneficiary phone number must be ${getLocalNumberLength()} digits`
        )
      );
      return;
    }
    this.updateBeneficiaryInfo(customerData);

    if (this.nationalIdValid() === false) {
      this.props.dispatch(actions.displayError(labels.errorIncorrectID));
      return;
    }
    this.updateNationalIdInfo(customerData);

    if (isSouthAfricanPartner() && this.verifyDobWithNationalId(this.state.nationalId, birthday) === false) {
      this.props.dispatch(actions.displayError("Date of birth and national ID do not much"));
      return;
    }

    if (this.firstNameValid() === false) {
      this.props.dispatch(actions.displayError(labels.errorMissingFirstName));
      return;
    }
    this.updateFirstNameInfo(customerData);

    if (this.surnameValid() === false) {
      this.props.dispatch(actions.displayError(labels.errorMissingSurname));
      return;
    }
    this.updateSurnameInfo(customerData);
    this.updateHasWalletInfo(customerData);

    this.updateLanguageInfo(customerData);
    if (!this.languageValid()) {
      delete customerData.language;
    }

    customerData.web_agent_guid =  getUserGuid();
    customerData.web_agent_name =  getUserFullname();

    const genderIsEnabled = this.genderVisable();
    if(genderIsEnabled){
      customerData.gender = this.state.gender
    }

    this.props.dispatch(actions.registerCustomerRequest(customerData));
      this.handleClearForm();
  }

  updateHasWalletInfo(customerData) {
    if (this.hasWalletVisible()) {
      customerData.has_wallet = this.state.hasWallet;
    }
  }

  updateBeneficiaryInfo(customerData) {
    const regForm = new FormData(document.getElementById('registrationForm'));
    if (this.beneficiaryVisible()) {
      const beneficiaryName = regForm.get('beneficiary');
      if (beneficiaryName) {
        customerData.beneficiary_name = beneficiaryName;
      }

      const beneficiaryMsisdn = regForm.get('beneficiary_msisdn');
      if (beneficiaryMsisdn) {
        customerData.beneficiary_msisdn =
          getInternationalCallingCode() + beneficiaryMsisdn;
      }
    }
  }

  beneficiaryValid() {
    const regForm = new FormData(document.getElementById('registrationForm'));
    if (this.beneficiaryVisible()) {
      const beneficiaryMsisdn = regForm.get('beneficiary_msisdn');
      if (beneficiaryMsisdn) {
        if (beneficiaryMsisdn.length !== getLocalNumberLength()) {
          return false;
        }
      }
    }
    return true;
  }

  updateNationalIdInfo(customerData) {
    if (this.nationalIdVisible()) {
      const nationalId = this.state.nationalId;
      if (nationalId) {
        customerData.national_id = nationalId;
      }
    }
  }

  nationalIdValid() {
    if (this.nationalIdVisible()) {
      const nationalId = this.state.nationalId;
      if (nationalId) {
        // TODO: Validate the national id provided
      }
    }
    return true;
  }

  updateFirstNameInfo(customerData) {
    if (this.firstNameVisible()) {
      const firstName = this.state.firstName;
      if (firstName) {
        customerData.first_name = firstName;
      }
    }
  }

  firstNameValid() {
    if (this.firstNameVisible()) {
      const firstName = this.state.firstName;
      if (firstName) {
        return firstName.length !== 0;
      }
    }
    return true;
  }

  updateSurnameInfo(customerData) {
    if (this.surnameVisible()) {
      const surname = this.state.surname;
      if (surname) {
        customerData.surname = surname;
      }
    }
  }

  surnameValid() {
    if (this.surnameVisible()) {
      const surname = this.state.surname;
      if (surname) {
        return surname.length !== 0;
      }
    }
    return true;
  }

  updateFullNameInfo(customerData) {
    if (this.fullNameVisible()) {
      const fullName = this.state.fullName;
      if (fullName) {
        customerData.full_name = fullName;
      }
    }
  }

  fullNameValid() {
    if (this.fullNameVisible()) {
      const fullName = this.state.fullName;
      if (fullName) {
        return fullName.length !== 0;
      }
    }
    return true;
  }

  clearForm() {
    document.getElementById('registrationForm').reset();
    this.setState({ birthday: '' });
    this.props.toggleRegisterForm()
  }

  dateHandler(type, moment) {
    if (type === 'birthday') {
      this.setState({ birthday: formatDate(new Date(moment)) });
    }
  }
  dateHandler2(moment) {
    const rawDate = formatDate(new Date(moment.target.value));
    this.setState({ birthday: rawDate });
  }

  beneficiaryVisible() {
    return (
      partnerRegistrationFieldsContains('beneficiaryName') ||
      partnerRegistrationFieldsContains('beneficiaryMsisdn') ||
      partnerWebDisplayFieldsContains('beneficiaryName') ||
      partnerWebDisplayFieldsContains('beneficiaryMsisdn')
    );
  }

  genderVisable(){
    return partnerRegistrationFieldsContains('gender')
  }

  beneficiaryDisplay() {
    const labels = this.state.labels;
    const beneficiaryStyle = {
      display: this.beneficiaryVisible() ? 'block' : 'none',
    };

    return (
      <>
        <div className="col-md-6 col-sm-6 col-xs-12" style={beneficiaryStyle}>
          <br />
          <TextField
            fullWidth
            name="beneficiary"
            type="text"
            maxLength="75"
            label={labels.beneficiaryFullname} variant="outlined"
          />
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12" style={beneficiaryStyle}>
          <br />
          <TextField
            fullWidth
            type="number"
            min="0"
            className="form-control"
            name="beneficiary_msisdn"
            maxLength={getLocalNumberLength()}
            label={addInternationalCallingCode(labels.beneficiaryPhoneNumber)} variant="outlined"
          />
        </div>
      </>
    );
  }

  changeNationalId(e) {
    const { value } = e.target;

    this.setState({ nationalId: value });

    if (!isSouthAfricanPartner()) {
      return;
    }
    const result = isVAlidSouthAfricanNationalId(value);
    if (result.isValid) {
      this.setState({
        birthday: formatDate(new Date(result.dob)),
        gender: result.gender,
        SAIdCheckError: result.error,
      });
    } else {
      this.setState({ SAIdCheckError: result.error });
      return false;
    }
  }

  nationalIdDisplay() {
    const labels = this.state.labels;
    const nationalIdStyle = {
      display: this.nationalIdVisible() ? 'block' : 'none',
    };

    return (
      <div className="col-md-6 col-sm-6 col-xs-12" style={nationalIdStyle}>
        <TextField
          fullWidth
          name="national_id"
          id="national_id"
          value={this.state.nationalId}
          type="text"
          maxLength="75"
          disabled={this.pullKycVisible()}
          onChange={this.changeNationalId.bind(this)}
          label={labels.nationalId} variant="outlined"
        />
      </div>
    );
  }

  nationalIdVisible() {
    return (
      partnerRegistrationFieldsContains('nationalId') ||
      partnerWebDisplayFieldsContains('nationalId')
    );
  }

  changeFirstName(e) {
    this.setState({ firstName: e.target.value });
  }

  firstNameDisplay() {
    const labels = this.state.labels;
    const firstNameStyle = {
      display: this.firstNameVisible() ? 'block' : 'none',
    };

    return (
      <div className="col-md-6 col-sm-6 col-xs-12" style={firstNameStyle}>
        <br />
        <TextField
          fullWidth
          name="first_name"
          id="first_name"
          value={this.state.firstName}
          type="text"
          maxLength="75"
          disabled={this.pullKycVisible()}
          onChange={this.changeFirstName.bind(this)}
          label={labels.firstName} variant="outlined"
        />
      </div>
    );
  }

  firstNameVisible() {
    return (
      partnerRegistrationFieldsContains('firstName') ||
      partnerWebDisplayFieldsContains('firstName')
    );
  }

  changeSurname(e) {
    this.setState({ surname: e.target.value });
  }

  surnameDisplay() {
    const labels = this.state.labels;
    const surnameStyle = {
      display: this.surnameVisible() ? 'block' : 'none',
    };

    return (
      <div className="col-md-6 col-sm-6 col-xs-12" style={surnameStyle}>
        <br />
        <TextField
          fullWidth
          name="surname"
          id="surname"
          value={this.state.surname}
          type="text"
          maxLength="75"
          disabled={this.pullKycVisible()}
          onChange={this.changeSurname.bind(this)}
          label={labels.surname} variant="outlined"
        />
      </div>
    );
  }

  surnameVisible() {
    return (
      partnerRegistrationFieldsContains('surname') ||
      partnerWebDisplayFieldsContains('surname')
    );
  }

  changeFullName(e) {
    this.setState({ fullName: e.target.value });
  }

  fullNameDisplay() {
    const labels = this.state.labels;
    const fullNameStyle = {
      display: this.fullNameVisible() ? 'block' : 'none',
    };

    return (
      <div className="col-md-6 col-sm-6 col-xs-12" style={fullNameStyle}>
        <br />
        <TextField
          fullWidth
          id="full-name"
          name="full-name"
          required
          disabled={this.pullKycVisible()}
          value={this.state.fullName}
          type="text"
          maxLength="75"
          onChange={this.changeFullName.bind(this)}
          label={labels.fullName} variant="outlined"
        />
      </div>
    );
  }

  dateOfBirthDisplay() {
    const labels = this.state.labels;
    const style = {
      display: this.pullKycVisible() ? 'none' : '',
    };
    return (
      <div className="form-group" style={style}>
        <label
          htmlFor="birthday"
          className="control-label col-md-4 col-sm-4 col-xs-12"
        >
          {labels.dateOfBirth}
          <span>*</span>
        </label>
        <div className="col-md-6 col-sm-3 col-xs-12">
          <DatePicker
            value={this.state.birthday}
            onChange={this.dateHandler.bind(this, 'birthday')}
            format="YYYY-MM-DD"
            placeholder="Pick Date Of Birth"
          />
        </div>
      </div>
    );
  }

  fullNameVisible() {
    return (
      partnerRegistrationFieldsContains('fullName') ||
      partnerWebDisplayFieldsContains('fullName')
    );
  }

  toggleHasWallet() {
    const hasWallet = this.state.hasWallet;
    this.setState({ hasWallet: !hasWallet });
  }

  hasWalletDisplay() {
    const hasWalletStyle = {
      display: this.hasWalletVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    return (
      <div className="form-group" style={hasWalletStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.hasWallet} <span className="required">*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12 padding5">
          <Checkbox
            required
            checked={this.state.hasWallet}
            onChange={this.toggleHasWallet.bind(this)}
            color="primary"
          />
        </div>
      </div>
    );
  }

  hasWalletVisible() {
    return (
      partnerRegistrationFieldsContains('hasWallet') ||
      partnerWebDisplayFieldsContains('hasWallet')
    );
  }

  selectLanguages(e) {
    this.setState({ language: e.target.value });
  }

  languageValid() {
    if (this.languageVisible()) {
      const language = this.state.language;
      if (language) {
        return language.length !== 0;
      }
    }
    return true;
  }

  updateLanguageInfo(customerData) {
    if (this.languageVisible()) {
      const language = this.state.language;
      if (language) {
        customerData.language = language;
      }
    }
  }

  languageDialogDisplay() {
    const labels = this.state.labels;
    const languages = partnerLanguages();
    const languageStyle = {
      display: this.languageVisible() ? 'block' : 'none',
    };
    return (
      <div className="col-md-6 col-sm-6 col-xs-12" style={languageStyle}>
        <br />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label"> {labels.lang}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.language}
            label={labels.lang}
            onChange={this.selectLanguages.bind(this)}
          >
            <MenuItem key="-1" value="">{labels.selectLanguage}</MenuItem>
            {languages.map((lang, index) => (
              <MenuItem key={index} value={lang.code}>
                {lang.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }

  languageVisible() {
    return customerLanguagesVisible();
  }

  pullKycVisible() {
    return partnerPullKyc();
  }

  changeMsisdn(e) {
    this.setState({ msisdn: e.target.value });
  }

  resetRegistrationSuccess() {
    this.props.dispatch(actions.resetRegistration());
    hashHistory.push('admin/customer_status/'+this.props.customerData.currentCustomer.guid);
    this.props.toggleRegisterForm();
  }

  resetRegistrationError() {
    this.props.dispatch(actions.resetRegistration())
  }

  render() {
    const regError = {
      display: this.props.registrationData.errors ? 'block' : 'none',
    };
    const labels = this.state.labels;
    const languages = partnerLanguages();
    const localNumberLength = getLocalNumberLength();

    return (
      <div>
        <Dialog open={this.props.showRegisterForm} fullWidth maxWidth='lg' onClose={this.clearForm.bind(this)}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.clearForm.bind(this)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {labels.titleRegister}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <DialogContentText>
              {labels.subtitleRegister}
              <br />
              <br />
            </DialogContentText>

          </DialogContent>
          <form
            id="registrationForm"
            className="form-horizontal form-label-left"
            noValidate
            onSubmit={this.submitRegistrationHandler.bind(this)}
          >
            <center style={regError}>
              <p className="registrationError" style={{ color: 'red' }}>
                {this.props.registrationData.errors}
              </p>
            </center>
            <ValidatorForm onSubmit={() => { console.log('submiting form'); }} >

              <Grid container style={{ padding: '2%' }} spacing={2}>


                <Grid item xs={12} md={6} style={{ display: this.languageVisible() ? 'block' : 'none' }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"> {labels.lang}</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.language}
                      label={labels.lang}
                      onChange={this.selectLanguages.bind(this)}
                    >
                      <MenuItem key="-1" value="">{labels.selectLanguage}</MenuItem>
                      {languages.map((lang, index) => (
                        <MenuItem key={index} value={lang}>
                          {lang}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} style={{ display: this.nationalIdVisible() ? 'block' : 'none' }}>
                  <TextValidator
                    fullWidth
                    name="national_id"
                    id="national_id"
                    value={this.state.nationalId}
                    type="text"
                    maxLength="75"
                    disabled={this.pullKycVisible()}
                    onChange={this.changeNationalId.bind(this)}
                    label={labels.nationalId} variant="outlined"
                    validators={['required', 'isValidNationalId']}
                    errorMessages={['Please enter a valid national id', this.state.SAIdCheckError ? this.state.SAIdCheckError : 'Invalid national Id']}
                  />

                </Grid>

                <Grid item xs={12} md={6} style={{ display: this.firstNameVisible() ? 'block' : 'none' }}>
                  <TextField
                    fullWidth
                    name="first_name"
                    id="first_name"
                    value={this.state.firstName}
                    type="text"
                    maxLength="75"
                    disabled={this.pullKycVisible()}
                    onChange={this.changeFirstName.bind(this)}
                    label={labels.firstName} variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} md={6} style={{ display: this.surnameVisible() ? 'block' : 'none' }}>
                  <TextField
                    fullWidth
                    name="surname"
                    id="surname"
                    value={this.state.surname}
                    type="text"
                    maxLength="75"
                    disabled={this.pullKycVisible()}
                    onChange={this.changeSurname.bind(this)}
                    label={labels.surname} variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} md={6} style={{ display: this.hasWalletVisible() ? 'block' : 'none' }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          required
                          checked={this.state.hasWallet}
                          onChange={this.toggleHasWallet.bind(this)}
                          color="primary"
                        />
                      }
                      label={labels.hasWallet} />
                  </FormGroup>
                </Grid>


                <Grid item xs={12} md={6}>
                  <TextField
                    id="reg_phone_number"
                    type="number"
                    min="0"
                    name="phone"
                    maxLength={getLocalNumberLength()}
                    value={this.state.msisdn}
                    onChange={this.changeMsisdn.bind(this)}
                    label={labels.phoneNumber} variant="outlined"
                    fullWidth
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, localNumberLength)
                    }}
                    InputProps={{
                      startAdornment: (<InputAdornment position="start">
                        {getInternationalCallingCode()}
                      </InputAdornment>),
                    }}
                  />
                </Grid>


                <Grid item xs={12} md={6} style={{ display: this.fullNameVisible() ? 'block' : 'none' }}>
                  <TextField
                    fullWidth
                    id="full-name"
                    name="full-name"
                    required
                    disabled={this.pullKycVisible()}
                    value={this.state.fullName}
                    type="text"
                    maxLength="75"
                    onChange={this.changeFullName.bind(this)}
                    label={labels.fullName} variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} md={6} style={{ display: this.pullKycVisible() ? 'none' : 'block' }}>
                  <TextField
                    fullWidth
                    name="date-of-birth"
                    id="date-of-birth"
                    type="date"
                    format="YYYY-MM-DD"
                    value={this.state.birthday}
                    onChange={this.dateHandler2.bind(this)}
                    label={'date of birth'} variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{ display: this.genderVisable() ? 'block' : 'none' }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"> {'Select gender'}</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.gender}
                      label={'Select gender'}
                      onChange={(e)=> {this.setState({ gender: e.target.value })}}
                    >
                      <MenuItem key="-1" value="">{'Select gender'}</MenuItem>
                      {['Male', 'Female'].map((gender, index) => (
                        <MenuItem key={index} value={gender}>
                          {gender}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} style={{ display: this.beneficiaryVisible() ? 'block' : 'none' }}>
                  <TextField
                    fullWidth
                    name="beneficiary"
                    type="text"
                    maxLength="75"
                    label={labels.beneficiaryFullname} variant="outlined"
                  />
                </Grid>


                <Grid item xs={12} md={6} style={{ display: this.beneficiaryVisible() ? 'block' : 'none' }}>
                  <TextField
                    fullWidth
                    type="number"
                    min="0"
                    className="form-control"
                    name="beneficiary_msisdn"
                    maxLength={getLocalNumberLength()}
                    label={addInternationalCallingCode(labels.beneficiaryPhoneNumber)} variant="outlined"
                  />
                </Grid>


              </Grid>


            </ValidatorForm>

          </form>

          <DialogActions>
            <Button variant="contained" type="submit" onClick={this.submitRegistrationHandler.bind(this)} style={{ background: PRIMARY_THEME_COLOR_SHADE_1, }}> {labels.submitRegister}</Button>
            <Button variant="outlined" onClick={this.clearForm.bind(this)} style={{ marginRight: '3%', borderColor: PRIMARY_THEME_COLOR_SHADE_1, color: PRIMARY_THEME_COLOR_SHADE_1, fontWeight: 'bold' }}> {labels.cancel}</Button>
          </DialogActions>

        </Dialog>

        <AlertDialog
          custom
          show={this.props.registrationData.loader}
          size="sm"
          style={{ marginTop: '0', top: '30vh' }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={'cancel'}
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2>registering customer</h2>
        </AlertDialog>

        <AlertDialog
          success
          show={!!this.props.registrationData.customerInfo}
          size="sm"
          title={'Customer registeration request submitted'}
          style={{ marginTop: '0', top: '30vh' }}
          onConfirm={() => { this.resetRegistrationSuccess() }}
          confirmBtnText={'ok'}
          showCancel={false}
          cancelBtnText={'cancel'}
          confirmBtnCssClass
        >
        </AlertDialog>

        <AlertDialog
          show={false}
          danger
          title={'Error registering customer'}
          onConfirm={() => { this.resetRegistrationError() }}
          confirmBtnText={'Try again'}
          confirmBtnCssClass
          showCancel={false}
          style={{ marginTop: '0', top: '30vh' }}
        >
          {'customer registration error '}
        </AlertDialog>

      </div>
    );
  }
}

// this subscribes the component to the branch of the store called registrationData
// a change in registrationData state branch will cause the component to re-render
export default connect(state => ({
  customerData: state.currentCustomer,
  registrationData: state.customerRegistration,
  globalData: state.global,
  userAdminData: state.userAdmin,
}))(RegisterCustomer);
