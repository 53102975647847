import React from "react";
import { hashHistory } from 'react-router';
import LocalizedStrings from 'react-localization';
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import {
  addGuideRequest, deleteGuideRequest, editGuideRequest, getGuidesRequest,
  resetGuideToDelete, resetGuideToEdit, setGuideToDelete, setGuideToEdit
} from "./Redux/actions";
import KnowledgeBases from "./components/KnowledgeBases";
import { localisedText } from "../../../lib/localisation.es6";
import ButtonInc from "../../../shared-ui/ButtonInc";
import BoxInc from "../../../shared-ui/BoxInc/Index";
import AddGuide from "./components/AddGuide";
import { getPartnerGuid, hasAuthourity } from "../../../lib/access.es6";
import DeleteGuide from "./components/DeleteGuide";
import { uploadPolicyDcpDocumentRequest } from "../../../containers/Policyv2/Redux/actions";


class KnowledgeBase extends React.Component {

  constructor(props) {
    const localizedStrings = new LocalizedStrings(localisedText);
    super(props);
    this.state = {
      labels: localizedStrings,
      openAddTemplateDialog: false,
      openEditTemplateDialog: false,
    }
  }

  componentWillMount() {
    this.updateLanguage();
    this.props.dispatch(getGuidesRequest());
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    const language = this.props.globalData.language;
    let resetLanguage = false;
    if (!language) {
      resetLanguage = true;
    }
    const labels = this.state.labels;
    if (resetLanguage || labels.getLanguage() !== language) {
      labels.setLanguage(language);
      this.setState({});
    }
  }

  toggleOpenAddTemplate() {
    this.setState({ openAddTemplateDialog: !this.state.openAddTemplateDialog })
  }
  toggleOpenEditTemplateDialog() {
    this.setState({ openEditTemplateDialog: !this.state.openEditTemplateDialog })
  }

  saveGuide(payload) {
    this.props.dispatch(addGuideRequest(payload));
  }

  setGuideToEdit(payload) {
    this.props.dispatch(setGuideToEdit(payload));
    console.log("templateToEdit", payload)
  }

  resetGuideToEdit = (payload) => {
    this.props.dispatch(resetGuideToEdit(payload))
  }

  editGuide = (payload) => {
    this.props.dispatch(editGuideRequest(payload))
  }

  setGuideToDelete(guide) {
    this.props.dispatch(setGuideToDelete(guide));
    console.log("guideTodelete", guide)
  }

  deleteGuide(guide) {
    console.log("guidedelete", guide)
    this.props.dispatch(deleteGuideRequest(guide.guid));
  }

  resetGuideToDelete(guide) {
    this.props.dispatch(resetGuideToDelete(guide))
  }

  DocumentUpload(doc) {
		this.props.dispatch(uploadPolicyDcpDocumentRequest({ ...doc, }))

	}

  render() {

    const partnerGuid = getPartnerGuid();
    const guides = this.props.knowledgeBaseData.guides.filter(guide => guide.partner === partnerGuid);

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <BoxInc
              expanded
              title="Knowledge Base"
              actionButtons={[
                <ButtonInc
                  variant="contained"
                  color="primary"
                  size="large"
                  hasPermision={hasAuthourity('CREATE_GUIDE')}
                  onClick={(event) => {
                    event.stopPropagation()
                    this.toggleOpenAddTemplate()
                  }}
                >
                  Add a guide
                </ButtonInc>

              ]}

            >
              <KnowledgeBases
                loading={this.props.knowledgeBaseData.getGuidesloader}
                guides={guides}
                toggleOpenEditTemplateDialog={this.toggleOpenEditTemplateDialog.bind(this)}
                setGuideToEdit={this.setGuideToEdit.bind(this)}
                setGuideToDelete={this.setGuideToDelete.bind(this)}
                viewGuide={(guide) => hashHistory.push('/admin/knowledge_base/' + guide.guid)}
              />

              <AddGuide
                open={this.state.openAddTemplateDialog}
                close={this.toggleOpenAddTemplate.bind(this)}
                saveGuide={this.saveGuide.bind(this)}
                showAddGuideProgressAlert={
                  this.props.knowledgeBaseData.addGuideLoader
                }
                showAddGuideSuccessAlert={
                  this.props.knowledgeBaseData.addGuideSuccess
                }
                showAddGuideErrorAlert={
                  !!this.props.knowledgeBaseData.addGuideError
                }
                addGuideError={
                  this.props.knowledgeBaseData.addGuideError
                }
               // DocumentUpload={this.DocumentUpload.bind(this)}
              />

              <DeleteGuide
                deleteGuide={this.deleteGuide.bind(this)}
                guideToDelete={this.props.knowledgeBaseData.guideToDelete}

                resetGuideToDelete={this.resetGuideToDelete.bind(this)}
                showDeleteGuideProgressAlert={this.props.knowledgeBaseData.hardDeleteGuideLoader}
                showDeleteGuideSuccessAlert={this.props.knowledgeBaseData.hardDeleteGuideSuccess}
                showDeleteGuideErrorAlert={!!this.props.knowledgeBaseData.deleteGuideError}
                deleteGuideError={this.props.knowledgeBaseData.deleteGuideError}

              />


            </BoxInc>
          </Grid>
        </Grid>
      </>
    )


  }

}

export default connect((state) => ({
  knowledgeBaseData: state.knowledgeBaseState,
  globalData: state.global,
}))(KnowledgeBase);