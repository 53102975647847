import React from "react";
import { connect } from "react-redux";
import HomePageCta from "./HomePageCta";
import LocalizedStrings from "react-localization";
import { localisedText } from "../../../../lib/localisation.es6";
import RegisterCustomer from '../RegisterCustomer/index.es6';

class SimpleHomePage extends React.Component {
  constructor(props) {
    const localizedStrings = new LocalizedStrings(localisedText);
    super(props);
    this.state = {
      openRegistrationDialog: false,
      labels: localizedStrings,
    };
  }



  toggleRegisterCustomerDialog() {
    this.setState({
      openRegistrationDialog: !this.state.openRegistrationDialog,
    });
  }

  render() {
    return (
      <>
        <HomePageCta
          open={this.toggleRegisterCustomerDialog.bind(this)}
          labels={this.state.labels}
        />

        <RegisterCustomer
          globalData={this.props.globalData}
          dispatch={this.props.dispatch}
          registrationData={this.props.registrationData}
          openRegistrationDialog={this.state.openRegistrationDialog}
          closeRegistrationDialog={() => {
            this.setState({ openRegistrationDialog: false });
          }}
        />
      </>
    );
  }
}

export default connect((state) => ({
  customerData: state.currentCustomer,
  registrationData: state.customerRegistration,
  userData: state.userAdmin,
}))(SimpleHomePage);
