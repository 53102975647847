import { makeStyles } from "@material-ui/styles";
import circlecheck from '../../../../../Assets/tickcircle.png';
import policyadmin from '../../../../../Assets/policyadmin.png'
import CustomerAcquisition from '../../../../../Assets/Customer-Acquisition.png'
import Claims from '../../../../../Assets/claims.png'
import Reports from '../../../../../Assets/reports.png'



export const useStyle = makeStyles((LandingPageTheme) => ({
    aspinFeatures: {
        padding: "50px",
        [LandingPageTheme.breakpoints.down("md")]: {
            padding: "20px",
        },
        "& h2": {
            fontFamily: 'Lato',
            fontStyle: "normal",
            fontWeight: "900",
            fontSize: "22px",
            lineHeight: "28px",
            color: "#000"
        },

    },
    dividerdiff: {
        width: "28px",
        height: "7px",
        background: "#FBA92D",
        transform: "matrix(1, 0, 0, -1, 0, 0)",
        float: "left",
        marginTop: "-10px",
        display: "inline",
    },
    featureswrapper: {
        marginTop: "30px",
    },
    featureImg: {
        "& img": {
            width: "100%"
        }
    },
    featurecontent: {
        marginLeft: "50px",
        [LandingPageTheme.breakpoints.down("md")]: {
            marginLeft: "0",
        },
        "& h3": {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "32px",
            textAlign: "justify",
            color: "#000000"
        },
        "& p": {

            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "15px",
            textAlign: "justify",
            color: "#000000",
        },
        "& ol": {
            marginTop: "40px",
            float: "left",
            padding: "0",
            "& li": {
                float: "left",
                listStyle: "none",
                marginTop: "3px",
                fontFamily: 'Open Sans',
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "15px",
                textAlign: "justify",
                color: " #000000",
                [LandingPageTheme.breakpoints.down("md")]: {
                    textAlign: "start",
                },
                "&:before":{
                    content: " '' ",
                    backgroundImage: `url(${circlecheck})`,
                    height: "24px",
                    width: "24px",
                    float: "left",
                    marginRight: "20px",
                    marginTop: "-4px",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }
            }
        }
    },
    listwrapper:{
        width: "90%",
        float: "left",
        marginBottom: "30px",
    },
    featurecustomer:{
        backgroundImage: `url(${CustomerAcquisition})`,
        height: "500px",
        marginTop:" -40px",
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        [LandingPageTheme.breakpoints.down("md")]: {
            height: "280px",
            marginTop: "0",
        },
    },
    featureImgpolicy:{
        backgroundImage: `url(${policyadmin})`,
        height: "500px",
        marginLeft: "40px",
        marginTop:" -40px",
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        [LandingPageTheme.breakpoints.down("md")]: {
            height: "280px",
            marginLeft: "0",
            marginTop: "0",
        },
    },
    featureclaim:{
        backgroundImage: `url(${Claims})`,
        height: "500px",
        marginLeft: "40px",
        marginTop:" -40px",
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        [LandingPageTheme.breakpoints.down("md")]: {
            height: "280px",
            marginLeft: "0",
            marginTop: "0",
        },
    },
    featureImgreports:{
        backgroundImage: `url(${Reports})`,
        height: "500px",
        marginLeft: "40px",
        marginTop:" -65px",
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        [LandingPageTheme.breakpoints.down("md")]: {
            height: "280px",
            marginLeft: "0",
            marginTop: "0",
        },
    }

}));

