import * as types from '../../lib/actionTypes.es6';

export function createProductPremiumRequest(payload) {
  return {
    type: types.CREATE_PRODUCT_PREMIUM_REQUEST,
    payload,
  };
}

export function createProductPremiumFailure(error) {
  return {
    type: types.CREATE_PRODUCT_PREMIUM_FAILURE,
    payload: error,
  };
}

export function createProductPremiumSuccess(response) {
  return {
    type: types.CREATE_PRODUCT_PREMIUM_SUCCESS,
    payload: response,
  };
}

export function getProductPremiumEnumsRequest(payload) {
  return {
    type: types.PRODUCT_PREMIUM_ENUMS_REQUEST,
    payload,
  };
}

export function getProductPremiumEnumsFailure(error) {
  return {
    type: types.PRODUCT_PREMIUM_ENUMS_FAILURE,
    payload: error,
  };
}

export function getProductPremiumEnumsSuccess(response) {
  return {
    type: types.PRODUCT_PREMIUM_ENUMS_SUCCESS,
    payload: response,
  };
}

export function getAllProductPremiumsRequest(payload) {
  return {
    type: types.VIEW_ALL_PRODUCT_PREMIUMS_REQUEST,
    payload,
  };
}

export function getAllProductPremiumsFailure(error) {
  return {
    type: types.VIEW_ALL_PRODUCT_PREMIUMS_FAILURE,
    payload: error,
  };
}

export function getAllProductPremiumsSuccess(response) {
  return {
    type: types.VIEW_ALL_PRODUCT_PREMIUMS_SUCCESS,
    payload: response,
  };
}
