import * as types from '../../../lib/actionTypes.es6';
import { getPaginatedGroups } from '../../../lib/utils.es6';

export const initialState = {
  errors: null,
  loader: false,
  groups: null,
  groupPaymentLoader: false,
  groupPaymentSuccess: null,
  groupPaymentError: null,
  count: 0,
  size: 20,
};

export default function listAllGroupsReducer(state = initialState, action) {
  switch (action.type) {

    case types.GET_ALL_GROUPS_REQUEST:
      return { ...state, loader: true, errors: null, groups: null };

    case types.GET_ALL_GROUPS_SUCCESS:
      return { ...state, loader: false,  ...getPaginatedGroups(action.payload) };

    case types.GET_ALL_GROUPS_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    case types.ADD_GROUP_PAYMENT_REQUEST:
      return { ...state, groupPaymentLoader: true, groupPaymentError: null, groupPaymentSuccess: null };

    case types.ADD_GROUP_PAYMENT_SUCCESS:
      return { ...state, groupPaymentLoader: false, groupPaymentSuccess: 'Payment Added Successfully' };

    case types.ADD_GROUP_PAYMENT_FAILURE:
      return { ...state, groupPaymentLoader: false, groupPaymentError: action.payload };

    case types.DELETE_GROUP_REQUEST:
      return { ...state, loader: true, errors: null,};

    case types.DELETE_GROUP_SUCCESS:
      return { ...state, loader: false, };

    case types.DELETE_GROUP_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    case types.RESET_ADD_GROUP_PAYMENT:
      return { ...state, groupPaymentLoader: false, groupPaymentSuccess: null, groupPaymentError: null, };

    default:
      return state;
  }
}
