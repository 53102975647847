import React, { useEffect } from 'react';
import TableInc from '../../../../shared-ui/TableInc';
import { trimTimestampFromDate } from '../../../../lib/utils.es6';
import { getPartner } from '../../../../lib/access.es6';
import SkeletonWrapper from '../../../NewProductList/components/SkeletonWrapper/SkeletonWrapper';

const Mandates = (props) => {
  if(!props.mandates){
    return
  }


  const currencyCode = getPartner().country.currency_code

  useEffect(() =>{
    props.getMandates(props.msisdn)
  }, [] )

  if (props.mandatesLoader) {
    return <SkeletonWrapper loading={props.mandatesLoader} />
  }

  return (
    <div>
      <h2>Mandates for {props.msisdn} </h2>

      <TableInc
        columns={[
          {
            title: 'Start Date', field: 'startDate', render: rowData => <>
              {
             trimTimestampFromDate(rowData.startDate) || "N/A"
              }
            </>
          },
          {
            title: 'End Date', field: 'endDate', render: rowData => <>
              {
                trimTimestampFromDate(rowData.endDate) || "N/A"
              }
            </>
          },
          {
            title: 'Client Phone', field: 'clientPhone', render: rowData => <>
              {
                rowData.clientPhone || "N/A"
              }
            </>
          },
          {
            title: 'Amount', field: 'amount', render: rowData => <>
              {
               `${currencyCode}  ${rowData.amount}`  || "N/A"
              }
            </>
          },
          {
            title: 'Mandate ID', field: 'mandateId', render: rowData => <>
              {
                rowData.mandateId || "N/A"
              }
            </>
          },
          {
            title: 'Message', field: 'responseMessage', render: rowData => <>
              {
                rowData.responseMessage || "N/A"
              }
            </>
          },

          {
            title: 'Status', field: 'status', render: rowData => <>
              {
                rowData.status || "N/A"
              }
            </>
          },
        ]}
        data={props.mandates}
      options={{
        pageSize: props.mandates.length < 5
          ? props.mandates.length
          : 5,
        showTitle: false,
        exportButton: true
      }}

      />
   
    </div>
  );
};

export default Mandates;
