import React, { useEffect, useState, useCallback } from "react";
import { useQuery, useQueryClient } from "react-query";
import { connect } from 'react-redux';
import { Box, Container } from "@mui/material";
import LocalizedStrings from 'react-localization';

import LoadingComponent from "./components/LoadingComponent";
import ErrorComponent from "./components/ErrorComponent";
import NoClaimComponent from "./components/NoClaimComponent";
import {
  getClaim,
  getClaimAssesment,
  getClaimConditions,
  getClaimHospitals,
  getClaimMetaData,
  getClaimRejectionReason,
  getCustomer,
  updateClaim,
} from "./lib/api";
import { localisedText } from "../../lib/localisation.es6";
import Header from "./components/Header/index";
import Overview from "./components/Overview/index";
import Form from "./components/Forms/Form";
import Documents from "./components/Documents";
import Benefits from "./components/Benefits";
import Audit from "./components/Audit";
import ActionButtons from "./components/ActionButtons/index";
import Assessment from "./components/Assessment/Assessment";
const FIVE_MINUTES = 1000 * 60 * 5;

const ClaimsProcessingRefactored = (props) => {
  const queryClient = useQueryClient();
  const [labels] = useState(new LocalizedStrings(localisedText));
  const [openAssessmentDialog, setOpenAssessmentDialog] = useState(false);
  
  const claim_guid =
    props.routeParams && props.routeParams.claim_guid
      ? props.routeParams.claim_guid
      : "";

  const refetchClaim = useCallback(() => {
    queryClient.invalidateQueries(["claim", claim_guid]);
  }, [queryClient, claim_guid]);

  // Update language when global language changes
  useEffect(() => {
    const language = props.globalData && props.globalData.language;
    if (language && labels.getLanguage() !== language) {
      labels.setLanguage(language);
    }
  }, [props.globalData, labels]);

  const { data: claimData, isLoading, error } = useQuery(
    ["claim", claim_guid],
    async () => {
      if (!claim_guid) {
        throw new Error("No claim GUID provided");
      }
      const claim = await getClaim({ guid: claim_guid });
      return claim;
    },
    {
      enabled: claim_guid !== "",
      retry: 2,
      staleTime: FIVE_MINUTES,
      cacheTime: FIVE_MINUTES,
      refetchOnWindowFocus: false,
      onError: (error) => {
        console.error("Error fetching claim:", error);
      },
    }
  );

  const {
    data: claimRejectionReasons,
    // isLoading: isLoadingReasons,
    // error: reasonsError,
  } = useQuery("claimRejectionReasons", () => getClaimRejectionReason("en"), {
    staleTime: FIVE_MINUTES,
    cacheTime: FIVE_MINUTES,
    refetchOnWindowFocus: false,
    onError: (error) => {
      console.error("Error fetching claim rejection reasons:", error);
    },
  });

  const {
    data: claimHospitals,
    // isLoading: isLoadingClaimHospital,
    // error: hospitalsError,
  } = useQuery("claimHospital", () => getClaimHospitals(), {
    staleTime: FIVE_MINUTES,
    cacheTime: FIVE_MINUTES,
    refetchOnWindowFocus: false,
  });

  const {
    data: claimConditions,
    // isLoading: isLoadingClaimConditions,
    // error: errorClaimConditions,
  } = useQuery("claimConditons", getClaimConditions, {
    staleTime: FIVE_MINUTES,
    cacheTime: FIVE_MINUTES,
    refetchOnWindowFocus: false,
  });

  const {
    data: assesments,
    // isLoading: isLoadingAsssesment,
    // error: errorAssessment,
  } = useQuery(
    ["claim_assessment", claim_guid],
    () => getClaimAssesment(claim_guid),
    {
      enabled: Boolean(claim_guid),
      staleTime: FIVE_MINUTES,
      cacheTime: FIVE_MINUTES,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: customerData,
    // isLoading: isLoadingCustomer,
    // error: errorFetchCustomer,
  } = useQuery(
    ["customer", claimData && claimData[0] && claimData[0].customer_guid],
    () => getCustomer(claimData[0].customer_guid),
    {
      enabled: Boolean(claimData && claimData[0] && claimData[0].customer_guid),
      staleTime: FIVE_MINUTES,
      cacheTime: FIVE_MINUTES,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: claimMetaData,
    // isLoading: isLoadingClaimMetaData,
    // error: errorFetchingClaimMetaData,
  } = useQuery("claimMetaData", getClaimMetaData, {
    staleTime: FIVE_MINUTES,
    cacheTime: FIVE_MINUTES,
    refetchOnWindowFocus: false,
  });

  const handleStatusChange = async (newStatus, updatedClaim = {}) => {
    try {
      const payload = {
        ...claim,
        ...updatedClaim,
        status: newStatus,
      };
      
      await updateClaim(payload);
      refetchClaim();
    } catch (error) {
      console.error('Error updating claim status:', error);
      throw error; // Re-throw to be handled by the action button components
    }
  };

  if (isLoading) return <LoadingComponent />;
  if (error) return <ErrorComponent error={error} />;
  if (!claimData || !Array.isArray(claimData) || claimData.length === 0)
    return <NoClaimComponent labels={labels} />;

  const claim = claimData[0];

  return (
    <div className="claim-processing">
      <Box sx={{ display: "flex" }}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 1,
            width: { sm: `calc(100% - 60px)` },
            ml: { sm: `10px` },
          }}
        >
          <Container maxWidth="lg">
            <Header 
              claim={claim} 
              labels={labels}
              showAssessmentButton={Boolean(assesments && assesments.length)}
              toggleAssesmentDialog={() => setOpenAssessmentDialog(!openAssessmentDialog)}
              customerData={customerData}
            />
            <Overview 
              claim={claim} 
              labels={labels}
            />
            <Assessment 
              assessments={assesments || []}
              open={openAssessmentDialog}
              onClose={() => setOpenAssessmentDialog(false)}
            />
            <Form
              claim={claim}
              metaData={claimMetaData}
              conditions={claimConditions}
              hospitals={claimHospitals}
              rejectionReason={claimRejectionReasons}
              refetchClaim={refetchClaim}
              labels={labels}
            />
            <Documents claim={claim} refetchClaim={refetchClaim} labels={labels} />
            <Benefits claim={claim} refetchClaim={refetchClaim} labels={labels} />
            <Audit claim={claim} labels={labels} />
            <ActionButtons 
              claim={claim} 
              onStatusChange={handleStatusChange} 
              labels={labels}
              rejectionReasons={claimRejectionReasons}
            />
          </Container>
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  globalData: state.global
});

export default connect(mapStateToProps)(ClaimsProcessingRefactored);
