import React from "react";
import DisplayPremiums from "./Components/DisplayPremiums/DisplayPremiums";
import { connect } from "react-redux";
import AddPayment from "../Payments/Components/AddPayment/AddPayment";
import { addOrangeMissingPaymentRequest, addOrangeMissingPaymentReset } from "../Payments/Redux/actions";
import { getAllOrangePremiumsRequest } from "./Redux/actions";
class OrangePremiums extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentWillMount(){
    const currentCustomer = this.props.customerData.currentCustomer;
    this.props.dispatch(getAllOrangePremiumsRequest({ msisdn: currentCustomer.msisdn, guid: currentCustomer.guid }))
  }



  addPayment(payment) {
		const payload = {
			paymentData: payment,
			customerGuid: this.props.customerData.currentCustomer.guid,
		};
		this.props.dispatch(addOrangeMissingPaymentRequest(payload));
	}
	resetAddPayment() {
		this.props.dispatch(addOrangeMissingPaymentReset());
	}

  render() {
    return (
      <>
        <DisplayPremiums
        labels={this.props.labels}
        loader={this.props.orangePremiumsData.get_premiums_loader}
        premiums={this.props.orangePremiumsData.premiums}
        />
        <AddPayment
          labels={this.props.labels}
          openAddPaymentDialog={this.props.openAddPaymentDialog}
          toggleAddPaymentDialog={this.props.toggleAddPaymentDialog}

          addPayment={this.addPayment.bind(this)}


          policies={this.props.orangePolicyData.policies}
          paymentAlert={this.props.orangePaymentData.success_message}
          paymentProgressAlert={this.props.orangePaymentData.add_payment_loader}
          paymentError={this.props.orangePaymentData.payment_error}
          resetPayment={this.resetAddPayment.bind(this)}
        />
      </>
    )
  }

}


export default connect((state) => ({
  customerData: state.currentCustomer,
  orangePremiumsData: state.orangePremiumsData,
  orangePaymentData: state.orangePaymentData,
  orangePolicyData: state.orangePoliciesData,
}))(OrangePremiums);