import * as types from '../../../../../lib/actionTypes.es6';

export const initialState = {

  debitOrders: [],
  getDebitOrdersSuccess: false,
  getDebitOrdersError: false,
  getDebitOrderLoader: false,


  createdDebitOrder: null,
  createDebitOrderSuccess: false,
  createDebitOrderLoader: false,
  createDebitOrderError: null,
  createdDebitOrders: [],

  hardDeleteDebitOrderSuccess: false,
  hardDeleteDebitOrderLoader: false,
  hardDeleteDebitOrderError: null,
  debitOrderToHardDeleteGuid: null,


  debitOrderToEdit: null,

  editedDebitOrder: null,
  editDebitOrderSuccess: false,
  editDebitOrderFailed: false,
  editDebitOrderLoader: false,
  editDebitOrderError: null,

  banksErrors: null,
  banks: [],
  banksLoader: false,
};

export default function newDebitOrderReducer(state = initialState, action) {
  switch (action.type) {

    case types.GET_DEBIT_ORDERZ_REQUEST:
      return {
        ...state,
        getDebitOrdersError: null,
        getDebitOrderLoader: true,
        getDebitOrdersSuccess: null,
      };

    case types.GET_DEBIT_ORDERZ_SUCCESS:
      return {
        ...state,
        getDebitOrdersSuccess: true,
        getDebitOrderLoader: false,
        getDebitOrdersError: null,
        debitOrders: action.payload, state
      };

    case types.GET_DEBIT_ORDERZ_FAILURE:
      return { ...state, getDebitOrderLoader: false, getDebitOrdersError: action.payload };


    case types.CREATE_DEBIT_ORDERZ_REQUEST:
      return {
        ...state,
        createDebitOrderError: null,
        createDebitOrderLoader: true,
        createDebitOrderSuccess: false,
        createdDebitOrder: null
      };

    case types.CREATE_DEBIT_ORDERZ_SUCCESS:
      return {
        ...state,
        createdDebitOrder: action.payload,
        createDebitOrderSuccess: true,
        createDebitOrderLoader: false,
        createDebitOrderError: null,
        createdDebitOrders: addToCreatedDebitOrders(action.payload, state)
      };

    case types.CREATE_DEBIT_ORDERZ_FAILURE:
      return { ...state, createDebitOrderLoader: false, createDebitOrderError: action.payload };

    case types.RESET_CREATE_DEBIT_ORDERZ_SUCCESS:
      return { ...state, createDebitOrderSuccess: false };

    case types.RESET_CREATE_DEBIT_ORDERZ_ERROR:
      return { ...state, createDebitOrderError: null };


    case types.HARD_DELETE_DEBIT_ORDERZ_REQUEST:
      return {
        ...state,
        hardDeleteDebitOrderError: null,
        hardDeleteDebitOrderLoader: true,
        hardDeleteDebitOrderSuccess: false,
        debitOrderToHardDeleteGuid: action.payload
      };

    case types.HARD_DELETE_DEBIT_ORDERZ_SUCCESS:
      return {
        ...state,
        hardDeleteDebitOrderSuccess: true,
        hardDeleteDebitOrderLoader: false,
        hardDeleteDebitOrderError: null,
        createdDebitOrders: deleteFromCreatedDebitOrders(state)
      };

    case types.HARD_DELETE_DEBIT_ORDERZ_FAILURE:
      return { ...state, hardDeleteDebitOrderLoader: false, hardDeleteDebitOrderError: action.payload };

    case types.RESET_HARD_DELETE_DEBIT_ORDERZ_SUCCESS:
      return { ...state, hardDeleteDebitOrderSuccess: false };

    case types.RESET_HARD_DELETE_DEBIT_ORDERZ_ERROR:
      return { ...state, hardDeleteDebitOrderError: null };






    case types.EDIT_DEBIT_ORDERZ_REQUEST:
      return {
        ...state,
        editDebitOrderError: null,
        editDebitOrderLoader: true,
        editDebitOrderSuccess: false,
        editedDebitOrder: null,
        editDebitOrderFailed: false,
      };

    case types.EDIT_DEBIT_ORDERZ_SUCCESS:
      return {
        ...state,
        editedDebitOrder: action.payload,
        editDebitOrderSuccess: true,
        editDebitOrderLoader: false,
        editDebitOrderError: null,
        editDebitOrderFailed: false,
      };

    case types.EDIT_DEBIT_ORDERZ_FAILURE:
      return { ...state, editDebitOrderLoader: false, editDebitOrderError: action.payload,  editDebitOrderFailed: true };


    case types.RESET_EDIT_DEBIT_ORDERZ_SUCCESS:
      return { ...state, editDebitOrderSuccess: false, debitOrders: state.debitOrders };

    case types.RESET_EDIT_DEBIT_ORDERZ_ERROR:
      return { ...state, editDebitOrderError: null };







    case types.SET_DEBIT_ORDERZ_TO_EDIT:
      return { ...state, debitOrderToEdit: action.payload };

    case types.SET_DEBIT_ORDERZ_TO_DELETE:
      return { ...state, debitOrderToDelete: action.payload };

    case types.RESET_DEBIT_ORDERZ_TO_EDIT:
      return { ...state, debitOrderToEdit: null };

    case types.RESET_DEBIT_ORDERZ_TO_DELETE:
      return { ...state, debitOrderToDelete: null };

    case types.GET_ALL_BANKS_REQUEST:
      return { ...state, banksLoader: true, banksErrors: null, debitOrder: null };

    case types.GET_ALL_BANKS_SUCCESS:
      return { ...state, banks: action.payload, banksLoader: false };

    case types.GET_ALL_BANKS_FAILURE:
      return { ...state, banksLoader: false, banksErrors: action.payload };

    default:
      return state;
  }
}


function addToCreatedDebitOrders(debitOrder, state) {
  state.createdDebitOrders.push(debitOrder)
  return state.createdDebitOrders
}

function deleteFromCreatedDebitOrders(state) {
  let { debitOrderToHardDeleteGuid, createdDebitOrders } = state
  let newCreatedDebitOrders = createdDebitOrders.filter(function (debitOrder) {
    return debitOrder.guid !== debitOrderToHardDeleteGuid;
  });
  return newCreatedDebitOrders
}
