import React from 'react'
import ModalInc from '../../../../shared-ui/ModalInc'
import Grid from "@material-ui/core/Grid";

import ButtonInc from '../../../../shared-ui/ButtonInc';
import { PolicyNote } from './PolicyNote';

const PolicyNotes = (props) => {

  if (props.policy) {
    const myNotes = props.policy.notes || []
    const sortedNotes = [...myNotes ].sort((a, b) =>
      new Date(b.created_at) - new Date(a.created_at)
    );
    return (
      <>
        <ModalInc
          modalTitle="Policy Notes"
          open={props.open}
          onClose={props.close}
          fullWidth
          maxWidth="md"
        >

          <Grid container spacing={2}>
            {
              sortedNotes.length > 0 ? (<>
                <Grid item xs={12} sm={12} md={12}>

                  {sortedNotes.map((data, index) => <PolicyNote data={data} policy_number={props.policy.policy_number} key={index} />
                  )}
                </Grid>
              </>) : (<>
                <Grid item xs={12} sm={12} md={12}>
                  <p>No policy note found</p>
                </Grid>
              </>)
            }

          </Grid>


          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} md={6}>

            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8}>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginTop: "20px" }}
                    onClick={props.close}
                  >
                    Close
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ModalInc>
      </>
    )
  }
  return ""


}

export default PolicyNotes


