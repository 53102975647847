import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// import MenuItem from '@mui/material/MenuItem';
// import InputAdornment from '@mui/material/InputAdornment';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import AlertDialog from "../../../../components/AlertDialog/index.es6";
import { CircularProgress, Switch } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../../lib/constants";

let initialState = {}


export default function UpdatePartner(props) {
  if (!props.partner) {
    return ""
  }
  initialState = props.partner

  const [
    {
			guid,
			code,
			name,
			country_code,
			created_at,
			call_centre_number,
			whats_app_number,
			default_language,
			ussd_shortcode,
			service_name,
			insurer,
			editor_id,
			active,
    }, setState
  ] = React.useState(initialState)

  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const toggleBooleanFields = e => {
    setState(prevState => ({ ...prevState, [e.target.name]: e.target.checked }));
  };



  const submit = () => {
    const payload = {
			guid,
			code,
			name,
			country_code,
			created_at,
			call_centre_number,
			whats_app_number,
			default_language,
			ussd_shortcode,
			service_name,
			insurer,
			editor_id,
			active,
    }
    for(let k in payload){
      if(payload[k] == null) delete payload[k]
    }
    console.log('submitting');
    console.log(payload);
    props.updatePartner(payload)
  }



  const reset = () => {
    setState(initialState);
  }

  const handleUpdateParnterSuccessfully = () => {
    props.resetUpdatePartnerSuccessAlert()
    props.closeUpdatePartnerDialog()
    reset()
  }

  const handleUpdateParnterError = () => {
    props.resetUpdatePartnerErrorAlert()
  }

  const booleanFields = [
    'active'
  ]

  const textFields = [
    "name",
    "call_centre_number",
    "whats_app_number",
    "ussd_shortcode",
    "service_name",
    "insurer",
  ]

  // const selectFields = [
  //       "country_code",
  //       "default_language"
  // ]

  const integerFields = [
  ]

  return (
    <>
      <AlertDialog
        custom
        show={props.showUpdatePartnerProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>updating partner</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showUpdatePartnerSuccessAlert}
        size="sm"
        title={'Partner updated'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleUpdateParnterSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>

      <AlertDialog
        show={props.showUpdatePartnerErrorAlert}
        danger
        title={'Error updating partner'}
        onConfirm={() => handleUpdateParnterError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.updatePartnerError ? props.updatePartnerError.message : ''}
      </AlertDialog>


      <Dialog open={props.showUpdatePartnerDialog} fullWidth maxWidth='lg' fullScreen onClose={props.closeUpdatePartnerDialog}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.closeUpdatePartnerDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Update Partner
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div style={{ marginTop: "2%" }}>
            <Box sx={{ width: "100%" }}>
              <ValidatorForm onSubmit={() => submit()} >
                <Grid container spacing={2}>


                  {
                    textFields.map(field => {
                      return <Grid item lg={3} md={6} xs={12}>
                        <TextValidator
                          onChange={onChange}
                          value={eval(field)} //eslint-disable-line
                          label={field}
                          name={field}
                          fullWidth
                          variant="outlined"
                          type="text"
                          placeholder={field}
                          // validators={['required']}
                          errorMessages={[`Please enter ${ field }`]}
                        />
                      </Grid>
                    })
                  }

                  {
                    integerFields.map(field => {
                      return <Grid item lg={3} md={6} xs={12}>
                        <TextValidator
                          onChange={onChange}
                          value={eval(field)} //eslint-disable-line
                          label={field}
                          name={field}
                          fullWidth
                          variant="outlined"
                          type="number"
                          placeholder={field}
                          // validators={['required']}
                          errorMessages={[`Please enter ${ field }`]}
                        />
                      </Grid>
                    })
                  }

                {
                    booleanFields.map(field => {
                      return <Grid item lg={3} md={6} xs={12}>
                        <label>{field}</label>
                        <Switch
                          checked={eval(field)} //eslint-disable-line
                          onChange={toggleBooleanFields}
                          name={field}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Grid>
                    })
                  }



                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant="contained" type="submit" style={{ marginRight: '2%' }}> Update partner </Button>
                    <Button variant="contained" color="secondary" style={{ marginRight: '2%' }}
                      onClick={() => reset()}
                    > Reset</Button>
                  </Grid>

                </Grid>
              </ValidatorForm>
            </Box>
          </div>

        </DialogContent>
      </Dialog>




    </>
  );
}
