import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../lib/actionTypes.es6';
import {
  addReportTemplate,
  getReportsTemplates,
  editReportTemplate,
  deleteReportTemplate
} from '../../../lib/requests.es6';
import * as actions from './actions';
import { unAuthorizedNotification } from '../../../lib/access.es6';
import { GAloggerException } from '../../../lib/monitoring.es6';


// template docs

export function* getReportsTemplatesRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.GET_REPORT_TEMPLATES_REQUEST);
      const response = yield call(getReportsTemplates, payload);
      yield put(actions.getReportTemplatesSuccess(response.data));
    } catch (e) {
      let errorPayload = {
        message: 'An error occurred, this could be due to invalid data',
      };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.getReportTemplatesFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* addReportTemplateRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.ADD_REPORT_TEMPLATE_REQUEST);

      const response = yield call(addReportTemplate, payload);
      yield put(actions.addReportTemplateSuccess(response.data));
    } catch (e) {
      let errorPayload = {
        message: 'An error occurred, this could be due to invalid data',
      };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.addReportTemplateFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* editReportTemplateRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.EDIT_REPORT_TEMPLATE_REQUEST);

      const response = yield call(editReportTemplate, payload);
      yield put(actions.editReportTemplateSuccess(response.data));
      //yield put(actions.getTemplateDocumentsRequest());
    } catch (e) {
      let errorPayload = {
        message: 'An error occurred, this could be due to invalid data',
      };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.editReportTemplateFailed(errorPayload));
      GAloggerException(e);
    }
  }
}


export function* deleteReportTemplateRequestWatcher() {
  for (;;) {
    const request = yield take(types.DELETE_REPORT_TEMPLATE_REQUEST);
    try {
      const response = yield call(deleteReportTemplate, request.payload.id);
      yield put(actions.deleteReportTemplateSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.deleteReportTemplateFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
