import React from 'react';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import 'react-table/react-table.css';
import LocalizedStrings from 'react-localization';
import Loader from 'react-loader';
import MainPageTitle from '../../components/MainPageTitle/index.es6';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button } from '@material-ui/core';
import { getSmsTemplatesRequest, createSmsTemplatesRequest, createSmsTemplatesReset, deleteSmsTemplatesRequest, deleteSmsTemplatesReset, updateSmsTemplatesRequest, updateSmsTemplatesReset, getSupportedParametersRequest } from './actions.es6';
import { getPartnerGuid, getPartner } from '../../lib/access.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { localisedText } from '../../lib/localisation.es6';
import './sms-template.css'
import { SmsTemplateCard } from '../../components/smsTemplateCard/index.es6';
import { SmsTemplateCreateUpdate } from '../../components/smsTemplateCreateUpdate/index.es6';

const DEFAULT_PAGE = 0;
const DEFAULT_SIZE = 20;

export class SmsTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
      openCreateSmsTemplateDialog: false,
      openDeleteSmsTemplateDialog: false,
      smsTemplateToDelete: null,
      openUpdateSmsTemplateDialog: false,
      smsTemplateToUpdate: null,
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
    };
  }

  componentWillMount() {
    const partnerGuid = getPartnerGuid()
    this.updateLanguage();
    this.props.dispatch(getSupportedParametersRequest(partnerGuid))
    this.props.dispatch(getSmsTemplatesRequest(partnerGuid))
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'SmsTemplate',
      this.state.labels
    );
  }
  getAllTemplates(size, page) {
    const arg = {
      page, size
    }
    this.props.dispatch(getSmsTemplatesRequest(arg))
  }
  
  createSmsTemplate(smsTemplatePayload) {
    this.props.dispatch(createSmsTemplatesRequest(smsTemplatePayload))
  }

  resetCreateSmsTemplate() {
    this.setState({
      openCreateSmsTemplateDialog: false,
    })
    this.props.dispatch(createSmsTemplatesReset())
  }


  updateSmsTemplatePrompt(smsTemplateToUpdate) {
    this.setState({ smsTemplateToUpdate, openUpdateSmsTemplateDialog: true, })
  }

  updateSmsTemplate(smsTemplatePayload) {
    this.props.dispatch(updateSmsTemplatesRequest(smsTemplatePayload))
  }

  resetUpdateSmsTemplate() {
    this.setState({
      openUpdateSmsTemplateDialog: false,
    })
    this.props.dispatch(updateSmsTemplatesReset())
  }


  deleteSmsTemplatePrompt(smsTemplateToDelete) {
    this.setState({ smsTemplateToDelete, openDeleteSmsTemplateDialog: true, })
  }

  deleteSmsTemplate(guid) {
    const payload = { guid, partnerGuid: getPartnerGuid() }
    this.props.dispatch(deleteSmsTemplatesRequest(payload))
  }

  cancelDeleteSmsTemplate() {
    this.setState({ openDeleteSmsTemplateDialog: false, smsTemplateToDelete: null, })
  }

  resetDeleteSmsTemplate() {
    this.cancelDeleteSmsTemplate();
    this.props.dispatch(deleteSmsTemplatesReset());
  }


  successfullyCreatedTemplateMessageDialog(smsTemplate) {
    const { labels } = this.state
    return <Dialog
      open={true}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogContent style={{ textAlign: "center", backgroundColor: "#F9F9F9" }}>
        <DialogContentText id="alert-dialog-slide-description" style={{ color: "green", fontSize: "200%" }}>
          {labels.successfullyCreatedSmsTemplate}
          <br />
          <br />
          <div style={{ width: "70%", margin: "auto" }}>
            <SmsTemplateCard
              smsTemplate={smsTemplate}
              updateSmsTemplatePrompt={this.updateSmsTemplatePrompt.bind(this)}
              deleteSmsTemplatePrompt={this.deleteSmsTemplatePrompt.bind(this)}
              globalData={this.props.globalData}
            />
          </div>
        </DialogContentText>
        <br />
        <br />
        <Button onClick={this.resetCreateSmsTemplate.bind(this)} style={{ backgroundColor: "green", color: "white", marginRight: "12px", textTransform: "none" }} variant="contained">
          {labels.ok}
        </Button>
      </DialogContent>
    </Dialog>
  }

  successfullyUpdatedSmsTemplateMessageDialog(smsTemplate) {
    const { labels } = this.state;
    return <Dialog
      open={true}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogContent style={{ textAlign: "center", backgroundColor: "#F9F9F9" }}>
        <DialogContentText id="alert-dialog-slide-description" style={{ color: "green", fontSize: "200%" }}>
          {labels.successfullyUpdatedSmsTemplate}
          <br />
          <br />
          <div style={{ width: "70%", margin: "auto" }}>
            <SmsTemplateCard
              smsTemplate={smsTemplate}
              updateSmsTemplatePrompt={this.updateSmsTemplatePrompt.bind(this)}
              deleteSmsTemplatePrompt={this.deleteSmsTemplatePrompt.bind(this)}
              globalData={this.props.globalData}
            />
          </div>
        </DialogContentText>
        <br />
        <br />
        <Button onClick={this.resetUpdateSmsTemplate.bind(this)} style={{ backgroundColor: "green", color: "white", marginRight: "12px", textTransform: "none" }} variant="contained">
          {labels.ok}
        </Button>
      </DialogContent>
    </Dialog>
  }

  confirmDeleteSmsTemplateDialog(args) {
    let { smsTemplateToDelete, labels } = this.state

    return <Dialog
      open={true}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogContent style={{ textAlign: "center", backgroundColor: "#F9F9F9" }}>
        <DialogContentText id="alert-dialog-slide-description" style={{ color: "red", fontSize: "150%" }}>
          {labels.deleteTemplateWarningMessage}
          <br />
          <br />
          <div style={{ width: "70%", margin: "auto" }}>
            <SmsTemplateCard
              smsTemplate={smsTemplateToDelete}
              updateSmsTemplatePrompt={this.updateSmsTemplatePrompt.bind(this)}
              deleteSmsTemplatePrompt={this.deleteSmsTemplatePrompt.bind(this)}
              globalData={this.props.globalData}
            />
          </div>
        </DialogContentText>
        <br />
        <br />
        <Button onClick={this.deleteSmsTemplate.bind(this, smsTemplateToDelete.guid)} style={{ backgroundColor: "orangered", color: "white", marginRight: "12px", textTransform: "none" }} variant="contained">
          {labels.delete}
        </Button>
        <Button onClick={this.cancelDeleteSmsTemplate.bind(this)} style={{ backgroundColor: "orange", color: "white", textTransform: "none" }} variant="contained">
          {labels.cancel}
        </Button>
      </DialogContent>
    </Dialog>
  }

  successfullyDeletedSmsTemplateMessageDialog() {
    const labels = this.state.labels
    return <Dialog
      open={true}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogContent style={{ textAlign: "center", backgroundColor: "#F9F9F9" }}>
        <DialogContentText id="alert-dialog-slide-description" style={{ color: "green", fontSize: "200%" }}>
          {labels.successfullyDeletedSmsTemplate}
          <br />
        </DialogContentText>
        <br />
        <br />
        <Button onClick={this.resetDeleteSmsTemplate.bind(this)} style={{ backgroundColor: "green", color: "white", marginRight: "12px", textTransform: "none" }} variant="contained">
          {labels.ok}
        </Button>
      </DialogContent>
    </Dialog>
  }


  render() {
    let smses = (this.props.smsTemplates.sms_templates ? this.props.smsTemplates.sms_templates.items : [])
    const labels = this.state.labels;
    let supportedParameters = this.props.smsTemplates.supported_parameters ? this.props.smsTemplates.supported_parameters.parameters : []
    supportedParameters = supportedParameters.sort()
    return (
      <Loader loaded={true} color="#ff834f">
        <MainPageTitle style={{marginLeft:"10px"}} pageTitle={labels.smsTemplates} />
        <SmsTemplateCreateUpdate
          type="create"
          supportedParameters={supportedParameters}
          createSmsTemplate={this.createSmsTemplate.bind(this)}
          globalData={this.props.globalData}
        />
        <br /><br /><br />
        <div> <center>
          {this.props.smsTemplates.loader ?
            <Dialog
              open={true}
            >
              <CircularProgress size={60} thickness={5} />
            </Dialog>
            : ''}
        </center>
        </div>

        <div>
          {
            this.props.smsTemplates.successfully_deleted_sms_template ?
              <center>
                {this.successfullyDeletedSmsTemplateMessageDialog()}
              </center> : ''}
          {
            (this.state.openDeleteSmsTemplateDialog && this.state.smsTemplateToDelete) ?
              <center>
                {this.confirmDeleteSmsTemplateDialog(this.state.smsTemplateToDelete)}
              </center> : ''}
          {
            this.props.smsTemplates.successfully_created_sms_template ?
              <center>
                {this.successfullyCreatedTemplateMessageDialog(this.props.smsTemplates.successfully_created_sms_template)}
              </center> : ''}
          {
            this.props.smsTemplates.successfully_updated_sms_template ?
              <center>
                {this.successfullyUpdatedSmsTemplateMessageDialog(this.props.smsTemplates.successfully_updated_sms_template)}
              </center> : ''}
        </div>
        <div>
          {this.renderPagination()}
        </div>
        <div className="x_panel panel">
          {
            (this.state.openUpdateSmsTemplateDialog && this.state.smsTemplateToUpdate) ?
              <center>
                <SmsTemplateCreateUpdate
                  type="update"
                  supportedParameters={supportedParameters}
                  updateSmsTemplate={this.updateSmsTemplate.bind(this)}
                  smsTemplateToUpdate={this.state.smsTemplateToUpdate}
                  closeDialogUpdateSmsTemplate={this.resetUpdateSmsTemplate.bind(this)}
                  globalData={this.props.globalData}
                />
              </center> : ''}
          {
            this.props.smsTemplates.sms_templates  && (smses.length > 0) ?
              <div className="x_content panel">
                <Grid
                  spacing={24}
                  container
                  direction="row"
                  justify="space-around"
                  alignItems="flex-start"
                >
                  {smses.map(template => (
                    <Grid xs={3} spacing={24}
                      style={{ padding: "10px", marginTop: "20px" }}
                    >
                      {
                        <SmsTemplateCard
                          smsTemplate={template}
                          updateSmsTemplatePrompt={this.updateSmsTemplatePrompt.bind(this)}
                          deleteSmsTemplatePrompt={this.deleteSmsTemplatePrompt.bind(this)}
                          globalData={this.props.globalData}
                        />
                      }
                    </Grid>
                  ))}
                </Grid>
                {this.renderPagination()}
              </div>
              : ''
          }
          {
            this.props.smsTemplates.sms_templates  && (smses.length === 0) ?
              <div style={{textAlign:"center", fontSize:"20px"}}> No SMS templates found for this partner: {getPartner().name}</div>
              : ''
          }
        </div>
      </Loader>
    );
  }

  handleChangeSize(event) {
    event.preventDefault();
    const size = parseInt(event.target.value);
    this.setState({ size });
    const page = this.state.page;
    this.getAllTemplates(size, page);
  }
  sizeSelector() {
    const { size } = this.state;
    const display = {
      display: this.props.smsTemplates.count > size ? '' : 'none',
    };
    return (
      <select value={size} onChange={this.handleChangeSize.bind(this)} style={display}>
        <option>4</option>
        <option>8</option>
        <option>16</option>
        <option>20</option>
        <option>50</option>
        <option>100</option>
      </select>
    );
  }
  handlePageClick(data) {
    const page = data.selected;
    this.setState({ page });
    const size = this.state.size;
    this.getAllTemplates(size, page);
  }

  getPages() {
    return Math.ceil(this.props.smsTemplates.count / this.state.size);
  }

  paginateClaims() {
    const pages = this.getPages();
    const display = {
      display: pages > 1 ? '' : 'none',
    };
    const labels = this.state.labels;
    return (
      <div style={display}>
        <ReactPaginate
          previousLabel={labels.paginationPreviousText}
          nextLabel={labels.paginationNextText}
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick.bind(this)}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
          forcePage={this.state.page}
        />
      </div>
    );
  }

  renderPagination() {
    return <Grid spacing={24} container direction="row" justify="center" alignItems="center">
      <Grid xs={11} style={{ textAlign: "right" }}>{this.paginateClaims()}</Grid>
      <Grid xs={1} style={{ textAlign: "left", paddingLeft: "10px" }}>{this.sizeSelector()}</Grid>
    </Grid>;
  }
}
export default connect(state => ({
  smsTemplates: state.smsTemplates,
  globalData: state.global,
}))(SmsTemplate);