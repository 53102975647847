import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import './ProductsTable.css'
import { SECONDARY_THEME_COLOR_SHADE_1 } from "../../../../lib/constants";

export default function ProductsTable(props) {

  const gotoProduct = (product) => {
    props.hashHistory.push(`admin/products/view/product/${product.guid}`);
  }
  if(props.products && props.products.length > 0){
  return (
    <>
      <div style={{ marginTop: "2%" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{color: 'red'}}>
              <TableRow >
                <TableCell className="table-cell-header">Name</TableCell>
                <TableCell className="table-cell-header">type</TableCell>
                <TableCell className="table-cell-header">Cover Type</TableCell>
                <TableCell className="table-cell-header">Min Age</TableCell>
                <TableCell className="table-cell-header">Max Age</TableCell>
                <TableCell className="table-cell-header">Waiting Period</TableCell>
                <TableCell className="table-cell-header">Threshold</TableCell>
                <TableCell className="table-cell-header" align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.products.map((product) => {
                return (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {product.name}
                    </TableCell>
                    <TableCell align="left"> {product.type}</TableCell>
                    <TableCell align="left"> {product.cover_type}</TableCell>
                    <TableCell align="left"> {product.minimum_age}</TableCell>
                    <TableCell align="left"> {product.maximum_age}</TableCell>
                    <TableCell align="left"> {product.waiting_period_days}</TableCell>
                    <TableCell align="left"> {product.eligibility_threshold}</TableCell>

                    <TableCell align="right">
                      <Button onClick={()=>gotoProduct(product)}variant="contained" style={{ background: SECONDARY_THEME_COLOR_SHADE_1, color: 'white', fontWeight: 'bolder', marginRight: "2%" }}>
                        View Product
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
  }else if(props.products && props.products.length === 0){
  return "No products were found, click on the add button to add new product."
  }
}
 