import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  uploadzone: {
    textAlign: "center",
    "& h4":{
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
    }
  },

  itemx: {
    marginLeft: '2%',
    borderLeft: 'grey solid 1px',
    padding: '1%'

  },

  subTtile: {
    color: "#000",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "24px",
}

}));