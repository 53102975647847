import * as types from '../../../../lib/actionTypes.es6';



export function getGuideRequest(type) {
  return {
    type: types.GET_GUIDE_REQUEST,
    payload: type,
  };
}

export function getGuideSuccess(response) {
  return {
    type: types.GET_GUIDE_SUCCESS,
    payload: response,
  };
}

export function getGuideFailed(error) {
  return {
    type: types.GET_GUIDE_FAILURE,
    payload: error,
  };
}



export function getGuidesRequest(type) {
  return {
    type: types.GET_GUIDES_REQUEST,
    payload: type,
  };
}

export function getGuidesSuccess(response) {
  return {
    type: types.GET_GUIDES_SUCCESS,
    payload: response,
  };
}

export function getGuidesFailed(error) {
  return {
    type: types.GET_GUIDES_FAILURE,
    payload: error,
  };
}

export function resetGetGuides(error) {
  return {
    type: types.RESET_GET_GUIDES,
    payload: error,
  };
}



export function addGuideRequest(payload) {
  return {
    type: types.ADD_GUIDE_REQUEST,
    payload,
  };
}

export function addGuideSuccess(response) {
  return {
    type: types.ADD_GUIDE_SUCCESS,
    payload: response,
  };
}

export function addGuideFailed(error) {
  return {
    type: types.ADD_GUIDE_FAILURE,
    payload: error,
  };
}

export function editGuideRequest(payload) {
  return {
    type: types.EDIT_GUIDE_REQUEST,
    payload,
  };
}

export function editGuideSuccess(response) {
  return {
    type: types.EDIT_GUIDE_SUCCESS,
    payload: response,
  };
}

export function editGuideFailed(error) {
  return {
    type: types.EDIT_GUIDE_FAILURE,
    payload: error,
  };
}

export function setGuideToEdit(response) {
  return {
    type: types.SET_GUIDE_TO_EDIT,
    payload: response,
  };
}


export function resetEditGuideSuccess() {
  return {
    type: types.RESET_EDIT_GUIDE_SUCCESS,
  };
}

export function resetEditGuideError() {
  return {
    type: types.RESET_EDIT_GUIDE_ERROR,
  };
}

export function resetGuideToEdit() {
  return {
    type: types.RESET_GUIDE_TO_EDIT,
  };
}



export const deleteGuideRequest = (payload) =>{
  return{
    type: types.DELETE_GUIDE_REQUEST,
    payload
  }
}

export const deleteGuideSuccess =  (response) =>{
  return{
    type: types.DELETE_GUIDE_SUCCESS,
    payload: response
  }
}

export const deleteGuideFailure = (error) =>{
  return{
    type: types.DELETE_GUIDE_FAILURE,
    payload: error
  }
}

export const resetDeleteGuideSuccess = () =>{
  return{
    type: types.RESET_DELETE_GUIDE_SUCCESS
  }
}

export const resetDeleteGuideError = () =>{
  return{
    type: types.RESET_DELETE_GUIDE_ERROR
  }
}

export function setGuideToDelete(response) {
  return {
    type: types.SET_GUIDE_TO_DELETE,
    payload: response,
  };
}

export function resetGuideToDelete(response) {
  return {
    type: types.RESET_GUIDE_TO_DELETE,
  };
}
