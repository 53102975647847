import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  response: null,
  loader: false,
};

export default function tigoLoyaltyReducer(state = initialState, action) {
  switch (action.type) {
    case types.TIGO_UPLOAD_LOYALTY_REQUEST:
      return { ...state, loader: true, errors: null, response: null };

    case types.TIGO_UPLOAD_LOYALTY_SUCCESS:
      return { ...state, response: action.payload, loader: false };

    case types.TIGO_UPLOAD_LOYALTY_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    case types.USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
}
