import * as types from '../../../../../../lib/actionTypes.es6';
import find from 'lodash/find';

export const initialState = {

  get_payments_errors: null,
  get_payments_loader: false,
  get_payments_success_message: '',
  payments: [],


  get_products_errors: null,
  get_products_loader: false,
  get_products_success_message: '',
  products: [],

  loader: false,
  success_message: '',
  payment_error: null,
  add_payment_loader: false,

};

export default function orangePaymentReducer(state = initialState, action) {
  switch (action.type) {


    case types.GET_ALL_ORANGE_PAYMENTS_REQUEST:
      return { ...state, get_payments_loader: true, get_payments_errors: null };
    case types.GET_ALL_ORANGE_PAYMENTS_SUCCESS:
      return { ...state, get_payments_loader: false, get_payments_errors: null, payments: getPaymentsWithProductDetails(action.payload, state.products, state.policies) };
    case types.GET_ALL_ORANGE_PAYMENTS_FAILURE:
      return { ...state, get_payments_loader: false, get_payments_errors: action.payload };

    case types.ADD_ORANGE_MISSING_PAYMENT_REQUEST:
      return { ...state, loader: true, add_payment_loader: true };
    case types.ADD_ORANGE_MISSING_PAYMENT_SUCCESS:
      return {
        ...state,
        loader: false,
        add_payment_loader: false,
        success_message: 'Payment Added Successfully',
      };
    case types.ADD_ORANGE_MISSING_PAYMENT_FAIL:
      return { ...state, loader: false, add_payment_loader: false, payment_error: action.payload };
    case types.ADD_ORANGE_MISSING_PAYMENT_RESET:
      return { ...state, payment_error: null, success_message: null, add_payment_loader: false };



    case types.GET_ALL_ORANGE_PRODUCTS_REQUEST:
      return { ...state, get_products_loader: true, get_products_errors: null };
    case types.GET_ALL_ORANGE_PRODUCTS_SUCCESS:
      return { ...state, get_products_loader: false, get_products_errors: null, products: action.payload };
    case types.GET_ALL_ORANGE_PRODUCTS_FAILURE:
      return { ...state, get_products_loader: false, get_products_errors: action.payload };


    default:
      return state;
  }
}

function getPaymentsWithProductDetails(payments, products, polices) {
  if (products.length > 0 && polices.length > 0) {
    let npayments = payments.map((payment) => {
      const policy = find(polices, { guid: payment.policy_guid })
      const product = find(products, { guid: policy.product_guid })
      return {
        ...payment,
        product_name: product.name || '',
        product_cover_type: product.cover_type || '',
      }
    })
    return npayments
  }
  return payments

}