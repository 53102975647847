import React from 'react';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import orange from '@material-ui/core/colors/orange';
import { Button } from '@material-ui/core';
import LocalizedStrings from 'react-localization';
import Loader from 'react-loader';
import * as actions from './actions.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { localisedText } from '../../lib/localisation.es6';
import * as globalActions from '../Main/actions.es6';
import MainPageTitle from '../../components/MainPageTitle/index.es6';
import { claimEnumRequest } from '../ClaimSubmission/actions.es6';
import { formatStatus } from '../../lib/utils.es6';
import CustomersClaims from '../../components/CustomersClaims/index.es6';
import { deepOrange } from '@material-ui/core/colors'

const DEFAULT_PAGE = 0;
const DEFAULT_SIZE = 50;

export class ClaimSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
      criteria: null,
      validationErrors: null,
      openClaimSearchDialog: false,
      claimReferenceSearchVal: '',
      selectedStatus: [],
      selectedType: [],
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleChangeSize = this.handleChangeSize.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.submitSearchClaim = this.submitSearchClaim.bind(this);
    this.handleClaimSearchInput = this.handleClaimSearchInput.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }

  componentWillMount() {
    this.updateLanguage();
    this.props.dispatch(globalActions.hideCustomerBar());
  }

  componentDidMount() {
    const searchData = this.props.searchData;
    if (searchData.types.length <= 1 || searchData.statuses.length <= 1) {
      this.props.dispatch(claimEnumRequest());
    }
  }

  componentDidUpdate() {
    // if (this.props.searchData.searchFinishedFlag) {
    //   this.props.dispatch(actions.resetSearchFlag());
    // }
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'ClaimSearch',
      this.state.labels
    );
  }

  resetErrors() {
    this.setState({
      validationErrors: null,
      criteria: null,
    });
  }

  submitSearchClaim(event) {
    event.preventDefault();

    const defaultPage = this.state.page;
    const defaultSize = this.state.size;

    this.submitSearchClaimHandler(defaultSize, defaultPage);
  }

  submitSearchClaimHandler(size, page) {
    this.setState({ openClaimSearchDialog: true });
    const criteria = {};
    const guid = this.state.claimReferenceSearchVal;
    const labels = this.state.labels;
    if (guid) {
      criteria.guid = guid;
    }

    const types = this.state.selectedType;

    if (types.length !== 0) {
      criteria.type = types.join();
    }

    const statuses = this.state.selectedStatus;

    if (statuses.length !== 0) {
      criteria.status = statuses.join();
    }
    if (Object.keys(criteria).length === 0) {
      this.setState({
        validationErrors: labels.pickSearchCriteria,
        openClaimSearchDialog: false,
      });
      return;
    }
    if (guid.length > 0 && guid.length < 3) {
      this.setState({
        validationErrors: labels.searchMoreThanTwoChars,
        openClaimSearchDialog: false,
      });
      return;
    }
    if (this.props.searchData.paginated) {
      criteria.page = page;
      criteria.size = size;
    }
    this.setState({ criteria });
    this.props.dispatch(actions.claimSearchRequest(criteria));
    this.resetErrors();
  }

  resetPagination() {
    this.setState({ size: DEFAULT_SIZE, page: DEFAULT_PAGE });
  }

  handleClaimSearchInput(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
    this.resetErrors();
    this.resetPagination();
  }

  onTypeSelection(type) {
    let selected = this.state.selectedType;
    if (selected.includes(type)) {
      selected = selected.filter(x => x !== type);
      this.setState({ selectedType: selected });
    } else {
      selected.push(type);
      this.setState({ selectedType: selected });
    }
  }

  onStatusSelection(status) {
    let selected = this.state.selectedStatus;
    if (selected.includes(status)) {
      selected = selected.filter(x => x !== status);
      this.setState({ selectedStatus: selected });
    } else {
      selected.push(status);
      this.setState({ selectedStatus: selected });
    }
  }

  handleClose() {
    this.props.dispatch(actions.claimSearchReset());
    this.setState({ openClaimSearchDialog: false });
    this.resetPagination();
  }

  handleChangeSize(event) {
    event.preventDefault();
    const size = parseInt(event.target.value);
    this.setState({ size });
    const page = this.state.page;
    this.submitSearchClaimHandler(size, page);
  }

  handlePageClick(data) {
    const page = data.selected;
    this.setState({ page });
    const size = this.state.size;
    this.submitSearchClaimHandler(size, page);
  }

  getPages() {
    return Math.ceil(this.props.searchData.count / this.state.size);
  }

  paginateClaims() {
    const pages = this.getPages();
    const display = {
      display: pages > 1 ? '' : 'none',
    };
    const labels = this.state.labels;
    return (
      <div style={display}>
        <ReactPaginate
          previousLabel={labels.paginationPreviousText}
          nextLabel={labels.paginationNextText}
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
          forcePage={this.state.page}
        />
      </div>
    );
  }

  sizeSelector() {
    const { size } = this.state;
    const display = {
      display: this.props.searchData.count > size ? '' : 'none',
    };
    return (
      <select value={size} onChange={this.handleChangeSize} style={display}>
        <option>5</option>
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    );
  }

  displaySearchResults() {
    const labels = this.state.labels;
    const claims = this.props.searchData.claimList;
    const count = this.props.searchData.count || '';
    const dialogActions = [
      this.paginateClaims(),
      this.sizeSelector(),
    ];
    if (!claims) {
      if (this.state.criteria) {
        return <center>{labels.nothingFound} </center>;
      }
    } else if (claims.length === 0) {
      return (
        <Dialog open={this.state.openClaimSearchDialog} fullWidth maxWidth="md">
          <DialogTitle id="alert-dialog-title">{labels.results}</DialogTitle>
          <DialogContent>{labels.nothingFound}</DialogContent>
          <DialogActions style={{marginRight:'5%'}}>
            <Button
              onClick={this.handleClose}
              variant="contained"
              style={{ backgroundColor: orange[400], color: "#fff" }}
            >
              {labels.cancel}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return (
      <Dialog open={this.state.openClaimSearchDialog} fullScreen maxWidth="md">
        <AppBar style={{ position: 'absolute', backgroundColor: deepOrange[400] }} >
          <div style={{ textAlign: 'right', fontSize: "150%" }}>
            <span style={{float:'left', margin: '1%'}}>{labels.results +': '+count +' '+ labels.claimsFound}</span>
            <IconButton edge="start" color="inherit"
              style={{ padding: '1%'}}
              onClick={this.handleClose}
              aria-label="close">
              <CloseIcon style={{fontSize:'100%'}}/> 
            </IconButton>
          </div>
        </AppBar>
        <DialogTitle id="alert-dialog-title">{labels.claimSearch}</DialogTitle>
        <DialogContent style={{ padding: "10px" }}>
          <CustomersClaims
            claims={this.props.searchData.claimList}
            columnWidth="col-md-12"
            showMore={false}
          />
        </DialogContent>
        <DialogActions style={{marginRight:'5%'}}>
          {dialogActions.map((item, index) => (
            <div key={index}> {item}</div>
          ))}
        </DialogActions>
      </Dialog>
    );
  }

  clearForm() {
    document.getElementById('searchForm').reset();
    this.resetErrors();
  }

  failureMessage() {
    return this.state.validationErrors
      ? this.state.validationErrors
      : this.props.searchData.errors;
  }

  displayUserFeedback() {
    const regError = {
      display:
        this.props.searchData.errors || this.state.validationErrors
          ? 'block'
          : 'none',
    };
    return (
      <center style={regError}>
        <p className="error">{this.failureMessage()}</p>
      </center>
    );
  }

  render() {
    const labels = this.state.labels;

    const statusData = this.props.searchData.statuses.map((status, index) => ({
      key: index,
      label: status,
      selected: false,
      color: 'grey',
    }));

    const typeData = this.props.searchData.types.map((status, index) => ({
      key: index,
      label: status,
      selected: false,
      color: 'grey',
    }));

    return (
      <Loader loaded={!this.props.searchData.loader} color="#ff834f">
        <div>
          <MainPageTitle pageTitle={labels.claimSearch} />
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="x_panel">
                <div className="x_title">
                  <h2>{labels.subtitleClaimSearch}</h2>
                  <div className="clearfix" />
                </div>
                <div className="x_content">
                  <br />
                  <form
                    id="searchForm"
                    className="form-horizontal form-label-left"
                    noValidate
                    onSubmit={this.submitSearchClaim}
                  >
                    {this.displayUserFeedback()}
                    <div className="form-group">
                      <label
                        htmlFor="reg_phone_number"
                        className="control-label col-md-4 col-sm-4 col-xs-4"
                      >
                        {labels.claimGuid}
                      </label>
                      <div className="col-md-6 col-sm-4 col-xs-4">
                        <input
                          id="guid"
                          type="text"
                          className="form-control"
                          name="claimReferenceSearchVal"
                          value={this.state.claimReferenceSearchVal}
                          onChange={this.handleClaimSearchInput}
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="form-group">
                      <label
                        htmlFor="reg_phone_number"
                        className="control-label col-md-4 col-sm-4 col-xs-4"
                      >
                        {labels.claimType}
                      </label>
                      <div className="col-md-6 col-sm-4 col-xs-4">
                        {typeData.map(data => (
                          <Chip
                            key={data.key}
                            label={formatStatus(data.label)}
                            onClick={this.onTypeSelection.bind(
                              this,
                              data.label
                            )}
                            style={{
                              margin: '2px',
                              backgroundColor: this.state.selectedType.includes(
                                data.label
                              )
                                ? orange['700']
                                : '',
                            }}
                          />
                        ))}
                      </div>
                    </div>
                    <hr />
                    <div className="form-group">
                      <label
                        htmlFor="reg_phone_number"
                        className="control-label col-md-4 col-sm-4 col-xs-4"
                      >
                        {labels.status}
                      </label>
                      <div className="col-md-6 col-sm-4 col-xs-4">
                        {statusData.map(data => (
                          <Chip
                            key={data.key}
                            label={formatStatus(data.label)}
                            onClick={this.onStatusSelection.bind(
                              this,
                              data.label
                            )}
                            style={{
                              margin: '2px',
                              backgroundColor: this.state.selectedStatus.includes(
                                data.label
                              )
                                ? orange['700']
                                : '',
                            }}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="ln_solid" style={{ clear: 'both' }} />
                    <div
                      className="col-sm-offset-1 col-sm-6"
                      style={{ marginLeft: '30%' }}
                    >
                      <center>
                        <button
                          onClick={this.clearForm}
                          type="button"
                          className="btn btn-default"
                        >
                          {labels.cancel}
                        </button>
                        <button type="submit" className="btn btn-success">
                          {labels.search}
                        </button>
                      </center>
                    </div>
                  </form>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
        {this.displaySearchResults()}
      </Loader>
    );
  }
}

export default connect(state => ({
  searchData: state.claimSearch,
  globalData: state.global,
}))(ClaimSearch);
