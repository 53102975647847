import React from 'react';
import classNames from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import SelectField from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import {  DatePicker, DateTimePicker } from '@material-ui/pickers';
import Autocomplete from '../Autocomplete/index.es6';

import { translateArray } from '../../lib/languageUtils.es6';
import { formatDateTime, formatStatus, formatDate } from '../../lib/utils.es6';
import {getUserGuid, hasAuthourity} from '../../lib/access.es6';

import './index.css';
import {Button} from "@material-ui/core";
import {BENEFIT_NAMES} from "../../containers/NewProductList/PoductConstants";

export class ClaimForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      admissionDate: null,
      dischargeDate: null,
      deathDate: null,
      eventDate: null,
      startDate: null,
      endDate: null,
      birthday: null,
      verification: null,
      docReceivedTime: null,
      docChannel: null,
      validBirthCertificate: null,
      highRisk: null,
      blankConditionError: '',
      ignoreMaxDaysAllowedAfterDischargeOrDeath: false,
      comments: null,
      condition: null,
      hospital_name: null,
      accidental: null,
      conditionOtherValue: null,
      property_total_amount: null,
      personal_accident_total_amount: null,
      medical_expense_total_amount: null,
      claimed_amount: null,
    };

    this.toggleIgnoreMaxDaysAllowedAfterDischargeOrDeath = this.toggleIgnoreMaxDaysAllowedAfterDischargeOrDeath.bind(
      this
    );
  }

  componentDidMount() {
    this.initialiseState();
  }

  initialiseState() {
    const claimData = this.props.claimData.currentCustomerClaim;

    const status = claimData
      ? claimData.status
        ? claimData.status
        : undefined
      : undefined;

    this.setState({
      status,
    });
  }

  isReadOnly(statusIn) {
    let status = statusIn;
    if (!status) {
      status = this.state.status;
    }
    // TODO: this list should come from the API. i.e. not hard coded
    return status === 'Not_Valid' || this.dateReadOnly();
  }

  getAccidentalValue(accidental) {
    if (accidental === false) {
      return false;
    }
    if (accidental === true) {
      return true;
    }
    return '';
  }

  dateReadOnly(statusIn) {
    let status = statusIn;
    if (!status) {
      status = this.state.status;
    }
    // TODO: this list should come from the API. i.e. not hard coded
    return [
      'Approved',
      'Approved_Paid',
      'Approved_Pending',
      'Payment_In_Review',
      'Rejected_Pending',
      'Rejected',
      'ExGratia',
      'ExGratia_Paid',
      'ExGratia_Pending',
    ].includes(status);
  }

  toggleHighRisk() {
    const highRisk = this.props.claimData.currentCustomerClaim.high_risk;
    if (this.state.highRisk == null) {
      this.setState({ highRisk: !highRisk });
    } else {
      this.setState({ highRisk: !this.state.highRisk });
    }
  }

  docChannelSelected() {
    this.setState({
      docChannel: document.getElementById('channel').value,
    });
  }

  cmpntProps(readOnlyIn) {
    let readOnly = readOnlyIn;
    if (!readOnly && readOnly !== false) {
      // NOTE: Don't override passed false value
      readOnly = this.isReadOnly();
    }
    const cmpntProps = {};
    if (readOnly === true) {
      cmpntProps.disabled = 'disabled';
    }
    return cmpntProps;
  }

  onChangeClaimCondition(event) {
    this.setState({
      condition: parseInt(event.target.value),
    });
  }

  onChangeClaimConditionOtherValue(event) {
    this.setState({
      conditionOtherValue: event.target.value,
    });
  }

  getConditionLabel(id) {
    const labels = this.props.labels;
    const condition = this.props.claimData.claimConditionList.find(
      x => x.id === id
    );
    let result = labels.select;
    if (condition) {
      result = condition.value;
    }
    return result;
  }

  onChangeAccident() {
    this.setState({
      accidental: document.getElementById('accidental').value,
    });
  }

  detailsDoblabel() {
    const labels = this.props.labels;
    const claimType = this.props.claimData.currentCustomerClaim.type;
    const labelByClaimType = {
      'Hospital': 'patientDateOfBirth',
      'Death': 'deceasedDateOfBirth',
    };
    const labelKey = labelByClaimType[claimType];
    const result = labels[labelKey] || 'NA';
    return result;
  }

  childRelationCompulsory() {
    const relationToMainMember = this.props.claimData.currentCustomerClaim
      .relation_to_main_member;
    if (relationToMainMember === 'Child') {
      return <span>*</span>;
    }
    return null;
  }

  dateHandler(datePicker) {
    if (datePicker === 'birthday') {
      this.refs.birthday.openDialog();
    } else if (datePicker === 'doc_received_time') {
      this.refs.doc_received_time.openDialog();
    } else if (datePicker === 'death_date') {
      this.refs.death_date.openDialog();
    } else if (datePicker === 'discharge_date') {
      this.refs.discharge_date.openDialog();
    } else if (datePicker === 'admission_date') {
      this.refs.admission_date.openDialog();
    }
  }

  datePickerHandler(datePicker, moment) {
    if (datePicker === 'birthday') {
      this.setState({ birthday: formatDate(new Date(moment)) });
    } else if (datePicker === 'death_date') {
      this.setState({ deathDate: formatDate(new Date(moment)) });
    } else if (datePicker === 'discharge_date') {
      this.setState({ dischargeDate: formatDate(new Date(moment)) });
    } else if (datePicker === 'admission_date') {
      this.setState({ admissionDate: formatDate(new Date(moment)) });
    } else if (datePicker === 'doc_received_time') {
      this.setState({ docReceivedTime: formatDateTime(new Date(moment)) });
    } else if (datePicker === 'event_date') {
      this.setState({ eventDate: formatDate(new Date(moment)) });
    } else if (datePicker === 'start_date') {
      this.setState({ startDate: formatDate(new Date(moment)) });
    } else if (datePicker === 'end_date') {
      this.setState({ endDate: formatDate(new Date(moment)) });
    }
  }

  toggleValidBirthCertificate() {
    const validBirthCertificate = this.state.validBirthCertificate;
    this.setState({ validBirthCertificate: !validBirthCertificate });
  }

  // TODO: Consider moving these label in an structure dataset
  detailsHeaderLabel() {
    const labels = this.props.labels;
    const claimTypeHeaders = {
      Hospital: 'hospitalClaimDetails',
      Death: 'funeralClaimDetails',
      Property: 'propertyClaimDetails',
      Income_Loss: 'incomeLossClaimDetails',
      Personal_Accident: 'personalAccidentClaimDetails',
      Inpatient: 'inpatientClaimDetails',
      Maternity: 'maternityClaimDetails',
      Outpatient: 'outpatientClaimDetails',
      Medical_Expense: 'medicalExpenseClaimDetails',
    };
    const claimType = this.props.claimData.currentCustomerClaim.type;
    const headerLabel = claimTypeHeaders[claimType];
    return labels[headerLabel] || `${formatStatus(claimType)} Details`;
  }

  detailsCauselabel() {
    const labels = this.props.labels;
    const claimType = this.props.claimData.currentCustomerClaim.type;
    const labelByClaimType = {
      'Hospital': 'hospitalisationReason',
      'Death': 'deathCause',
    };
    const labelKey = labelByClaimType[claimType];
    const result = labels[labelKey] || 'NA';
    return result;
  }

  handleCommentChange(e) {
    this.setState({ comments: e.target.value });
  }

  commentReadonly(statusIn) {
    let status = statusIn;
    if (!status) {
      status = this.state.status;
    }
    // TODO: this list should come from the API. i.e. not hard coded
    return ['Approved_Paid', 'ExGratia_Paid', 'Rejected'].includes(status);
  }

  currentReason() {
    const claimData = this.props.claimData.currentCustomerClaim;
    let reason = claimData.death_reason;
    if (claimData.type === 'Hospital') {
      reason = claimData.hospitalization_reason;
    }
    return reason;
  }

  conditionVisible() {
    return this.props.claimData.currentCustomerClaim.type === 'Hospital';
  }

  conditionValueVisible() {
    let result = this.conditionVisible();
    result = result && this.state.condition === this.getConditionId('Other');
    return result;
  }

  getConditionId(value) {
    const condition = this.props.claimData.claimConditionList.find(
      x => x.value === value
    );
    if (condition) {
      return condition.id;
    }
  }

  selectedHospitalValue(selectedValue) {
    this.setState({ hospital_name: selectedValue });
  }

  handleVerificationChange(event) {
    this.setState({ verification: event.target.value });
  }

  payloadUpdateClaimCondition(claimData) {
    if (!this.conditionVisible()) {
      return;
    }
    const claimConditionID = this.state.condition;
    if (!claimConditionID) {
      return;
    }
    claimData.claim_condition_id = claimConditionID;
    if (this.conditionValueVisible()) {
      claimData.claim_condition_other_value = this.state.conditionOtherValue;
    } else {
      delete claimData.claim_condition_other_value;
    }
  }

  payloadUpdateAccident(claimData) {
    const accidental = this.state.accidental;
    if (accidental === '') {
      return;
    }
    claimData.accidental = accidental;
  }

  checkForNull(payload, field) {
    if (!payload[field]) {
      delete payload[field];
    }
  }

  preparePayload() {
    const labels = this.props.labels;
    const claimData = this.props.claimData.currentCustomerClaim;

    if (this.state.birthday) {
      claimData.person_affected_dob = formatDate(new Date(this.state.birthday));
    } else {
      delete claimData.person_affected_dob;
    }

    if (this.state.verification) {
      let verification =
        this.state.verification ||
        this.props.claimData.currentCustomerClaim.verify_by.split(',');

      if (verification.length > 0) {
        claimData.verify_by = this.state.verification
          .filter(verification => verification !== '')
          .join();
      }
      else {
        delete claimData.verify_by;
      }
    }

    this.checkForNull(claimData, 'verify_by');

    if (this.state.docChannel) {
      if (this.state.docChannel === labels.defaultSelection) {
        delete claimData.doc_channel;
      } else {
        claimData.doc_channel = [];
        if (this.state.docChannel) {
          claimData.doc_channel.push(this.state.docChannel);
        }
      }
    }

    this.payloadUpdateClaimCondition(claimData);

    if (this.state.accidental) {
      this.payloadUpdateAccident(claimData);
    }

    claimData.comments = this.state.comments;
    this.checkForNull(claimData, 'comments');

    // TODO: Extract date processing in separate method
    if (this.props.claimData.currentCustomerClaim.type === 'Hospital') {
      if (this.state.admissionDate) {
        claimData.hospital_admission_date = formatDate(
          new Date(this.state.admissionDate)
        );
      }

      if (this.state.dischargeDate) {
        claimData.hospital_discharge_date = formatDate(
          new Date(this.state.dischargeDate)
        );
      }

      claimData.hospitalization_reason = document.getElementById(
        'reason'
      ).value;
      claimData.hospital_name = this.state.hospital_name;

      if (this.state.hospital_name) {
        const hospitalGuid = this.props.claimData.claimsHospital.find(
          x => x.name === this.state.hospital_name
        );
        claimData.hospital_guid = hospitalGuid ? hospitalGuid.guid : null;
      }

      this.checkForNull(claimData, 'hospital_name');
      this.checkForNull(claimData, 'hospitalization_reason');
    }

    if (this.props.claimData.currentCustomerClaim.type === 'Income_Loss') {
      if (this.state.startDate) {
        claimData.start_date = formatDate(
          new Date(this.state.startDate)
        );
      }

      if (this.state.endDate) {
        claimData.end_date = formatDate(
          new Date(this.state.endDate)
        );
      }
    }

    if (this.props.claimData.currentCustomerClaim.type === 'Death') {
      if(this.state.deathDate){
        claimData.death_date = formatDate(new Date(this.state.deathDate));
      }
      claimData.death_reason = document.getElementById('reason').value;
      this.checkForNull(claimData, 'death_reason');
    }

    if (this.props.claimData.currentCustomerClaim.type === 'Property') {
      if(this.state.eventDate){
        claimData.event_date = formatDate(new Date(this.state.eventDate));
      }

      if (this.state.property_total_amount) {
        claimData.property_total_amount = this.state.property_total_amount;
      }
    }

    if (this.props.claimData.currentCustomerClaim.type === 'Personal_Accident') {
      if(this.state.eventDate){
        claimData.event_date = formatDate(new Date(this.state.eventDate));
      }

      if (this.state.personal_accident_total_amount) {
        claimData.personal_accident_total_amount = this.state.personal_accident_total_amount;
      }
    }

    if (this.props.claimData.currentCustomerClaim.type === 'Medical_Expense') {
      if(this.state.eventDate){
        claimData.event_date = formatDate(new Date(this.state.eventDate));
      }

      if (this.state.medical_expense_total_amount) {
        claimData.medical_expense_total_amount = this.state.medical_expense_total_amount;
      }
    }

    if (
      BENEFIT_NAMES.FIXED.includes(
        this.props.claimData.currentCustomerClaim.type
      ) &&
      !['Personal_Accident', 'Medical_Expense', 'Property'].includes(
        claimData.type
      )
    ) {
      if (this.state.eventDate) {
        claimData.event_date = formatDate(new Date(this.state.eventDate));
      }

      if (this.state.claimed_amount) {
        claimData.claimed_amount = this.state.claimed_amount;
      }
    }
    // TODO - END: Extract date processing in separate method

    if (this.state.validBirthCertificate != null) {
      claimData.birth_certificate_valid = this.state.validBirthCertificate;
    }
    if (this.state.highRisk) {
      claimData.high_risk = this.state.highRisk;
    }
    claimData.editor_guid = getUserGuid();

    if (this.state.docReceivedTime) {
      claimData.documentation_received_time = formatDateTime(
        new Date(this.state.docReceivedTime)
      );
    }

    const files = claimData ? claimData.received_files : [];
    const filesCount = files ? files.length : 0;

    let newStatus = null;
    if (claimData.status === 'All_Docs_Pending') {
      if (claimData.documentation_received_time) {
        newStatus = 'Decision_Pending';
      } else if (filesCount !== 0) {
        newStatus = 'Docs_Partially_Submitted';
      }
    } else if (claimData.status === 'Docs_Partially_Submitted') {
      if (claimData.documentation_received_time) {
        newStatus = 'Decision_Pending';
      }
    }

    if (newStatus) {
      claimData.status = newStatus;
    }

    delete claimData.policies;

    if(this.state.ignoreMaxDaysAllowedAfterDischargeOrDeath === true){
      claimData.ignoreMaxDays = this.state.ignoreMaxDaysAllowedAfterDischargeOrDeath
    }
    return claimData;
  }

  verifyByDisplay() {
    const verifyBy = classNames({
      'form-group': true,
      error: this.props.validationErrorFields.includes('verify_by'),
    });
    const type = this.props.claimData.currentCustomerClaim.type;
    const verifyByMeans = (
        this.props.claimData.verifyBy
          ? this.props.claimData.verifyBy[type]
          : []
      ) || []; // NOTE: Default to empty if verification means aren't specified in the backend

    const { labels } = this.props;
    const claimData = this.props.claimData.currentCustomerClaim;
    const verification =
      this.state.verification ||
      (claimData ? (claimData.verify_by ? claimData.verify_by : '') : '').split(
        ','
      );
    return (
      <div className={verifyBy}>
        <label
          htmlFor="rejection_reason"
          className="control-label col-md-4 col-sm-4 col-xs-12"
        >
          {' '}
          {labels.verificationReceived}
          <span>*</span>
        </label>
        <div className="col-md-4 col-sm-4 col-xs-14">
          <SelectField
            id="verify_by"
            multiple
            placeholder={labels.selectVerificationMethod}
            disabled={this.isReadOnly()}
            value={verification}
            onChange={this.handleVerificationChange.bind(this)}
            renderValue={selected => selected.join(', ')}
            fullWidth
          >
            {verifyByMeans.map((verifyByValue, index) => (
              <MenuItem
                key={index}
                value={verifyByValue}
                input={<Input id="select-multiple" />}
              >
                <ListItemText
                  style={{ width: '90%' }}
                  primary={formatStatus(verifyByValue)}
                />
                <Checkbox
                  checked={verification.indexOf(verifyByValue) > -1}
                  color="primary"
                />
              </MenuItem>
            ))}
          </SelectField>
        </div>
      </div>
    );
  }

  detailsHospitalSpecificDisplay() {
    const labels = this.props.labels;
    const claimData = this.props.claimData.currentCustomerClaim;
    const hospitalNameProp = claimData
      ? claimData.hospital_name
        ? claimData.hospital_name
        : ''
      : '';
    const hospitalName = classNames({
      'form-group': true,
      error: this.props.validationErrorFields.includes('hospital_name'),
    });

    const options = this.props.claimData.claimsHospital.map(hospital => ({
      value: hospital.name,
      label: hospital.name,
    }));
    if (claimData.type === 'Hospital') {
      // TODO: Remove this hardcoding
      return (
        <div className={hospitalName}>
          <label
            htmlFor="hospital_name"
            className="control-label col-md-4 col-sm-4 col-xs-12"
          >
            {' '}
            {labels.hospital}
            <span>*</span>
          </label>
          <div className="col-md-4 col-sm-4 col-xs-14">
            <Autocomplete
              isDisabled={this.isReadOnly()}
              value={hospitalNameProp || this.state.hospital_name}
              onChange={this.selectedHospitalValue.bind(this)}
              placeholder={labels.selectHospital}
              options={options}
            />
          </div>
        </div>
      );
    }
    return null;
  }

  dateDisplay() {
    const labels = this.props.labels;
    const claimData = this.props.claimData.currentCustomerClaim;
    const deathDate = claimData
      ? claimData.death_date
        ? claimData.death_date
        : ''
      : '';
    const eventDate = claimData
      ? claimData.event_date
        ? claimData.event_date
        : ''
      : '';
    if (claimData.type === 'Hospital') {
      // TODO: Remove this hard-coding
      return (
        <div>
          <div className="form-group">
            <label
              htmlFor="admission_date"
              className="control-label col-md-4 col-sm-4 col-xs-12"
            >
              {labels.hospitalAdmissionDate}
              <span>*</span>
            </label>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <DatePicker
                value={
                  this.state.admissionDate || claimData.hospital_admission_date
                }
                onChange={this.datePickerHandler.bind(this, 'admission_date')}
                disabled={this.dateReadOnly()}
                format="YYYY-MM-DD"
              />
            </div>
          </div>
          <div className="form-group">
            <label
              htmlFor="discharge_date"
              className="control-label col-md-4 col-sm-4 col-xs-12"
            >
              {labels.hospitalDischargeDate}
              <span>*</span>
            </label>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <DatePicker
                value={
                  this.state.dischargeDate || claimData.hospital_discharge_date
                }
                onChange={this.datePickerHandler.bind(this, 'discharge_date')}
                disabled={this.dateReadOnly()}
                format="YYYY-MM-DD"
              />
            </div>
          </div>
        </div>
      );
    }
    if (claimData.type === 'Income_Loss') {
      return (
        <div>
          <div className="form-group">
            <label
              htmlFor="start_date"
              className="control-label col-md-4 col-sm-4 col-xs-12"
            >
              {labels.startDate}
              <span>*</span>
            </label>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <DatePicker
                value={
                  this.state.startDate || claimData.start_date
                }
                onChange={this.datePickerHandler.bind(this, 'start_date')}
                disabled={this.dateReadOnly()}
                format="YYYY-MM-DD"
              />
            </div>
          </div>
          <div className="form-group">
            <label
              htmlFor="end_date"
              className="control-label col-md-4 col-sm-4 col-xs-12"
            >
              {labels.endDate}
              <span>*</span>
            </label>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <DatePicker
                value={
                  this.state.endDate || claimData.end_date
                }
                onChange={this.datePickerHandler.bind(this, 'end_date')}
                disabled={this.dateReadOnly()}
                format="YYYY-MM-DD"
              />
            </div>
          </div>
        </div>
      );
    }
    if (claimData.type === 'Death') {
      return (
        <div className="form-group">
          <label
            htmlFor="death_date"
            className="control-label col-md-4 col-sm-4 col-xs-12"
          >
            {labels.deathDate}
            <span>*</span>
          </label>
          <div className="col-md-4 col-sm-4 col-xs-12">
            <DatePicker
              value={this.state.deathDate || deathDate}
              onChange={this.datePickerHandler.bind(this, 'death_date')}
              disabled={this.dateReadOnly()}
              format="YYYY-MM-DD"
            />
          </div>
        </div>
      );
    }
    if (claimData.type === 'Property') {
      const totalAmount = claimData?claimData.property_total_amount:'';
      return (
        <div>
          <div className="form-group">
            <label
              htmlFor="event_date"
              className="control-label col-md-4 col-sm-4 col-xs-12"
            >
              {labels.eventDate}
              <span>*</span>
            </label>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <DatePicker
                value={this.state.eventDate || eventDate}
                onChange={this.datePickerHandler.bind(this, 'event_date')}
                disabled={this.dateReadOnly()}
                format="YYYY-MM-DD"
              />
            </div>
          </div>
          <div className="form-group">
            <label
              htmlFor="property_total_amount"
              className="control-label col-md-4 col-sm-4 col-xs-12"
            >
              {labels.propertyTotalAmount}
              <span>*</span>
            </label>
            <div className="col-md-3 col-sm-3 col-xs-12">
              <input
                id="property_total_amount"
                name="property_total_amount"
                className="form-control col-md-7 col-xs-12"
                onChange={this.handlePropertyTotalAmountChange.bind(this)}
                type="number"
                min="0"
                value={this.state.property_total_amount || totalAmount}
                required
                title={labels.requiredInput}
              />
            </div>
          </div>
        </div>
      );
    }

    if (claimData.type === 'Personal_Accident') {
      const totalAmount = claimData?claimData.personal_accident_total_amount:'';
      return (
        <div>
          <div className="form-group">
            <label
              htmlFor="event_date"
              className="control-label col-md-4 col-sm-4 col-xs-12"
            >
              {labels.eventDate}
              <span>*</span>
            </label>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <DatePicker
                value={this.state.eventDate || eventDate}
                onChange={this.datePickerHandler.bind(this, 'event_date')}
                disabled={this.dateReadOnly()}
                format="YYYY-MM-DD"
              />
            </div>
          </div>
          <div className="form-group">
            <label
              htmlFor="personal_accident_total_amount"
              className="control-label col-md-4 col-sm-4 col-xs-12"
            >
              {labels.personalAccidentTotalAmount}
              <span>*</span>
            </label>
            <div className="col-md-3 col-sm-3 col-xs-12">
              <input
                id="personal_accident_total_amount"
                name="personal_accident_total_amount"
                className="form-control col-md-7 col-xs-12"
                onChange={this.handlePersonalAccidentTotalAmountChange.bind(this)}
                type="number"
                min="0"
                value={this.state.personal_accident_total_amount || totalAmount}
                required
                title={labels.requiredInput}
              />
            </div>
          </div>
        </div>
      );
    }

    if (claimData.type === 'Medical_Expense') {
      const totalAmount = claimData?claimData.medical_expense_total_amount:'';
      return (
        <div>
          <div className="form-group">
            <label
              htmlFor="event_date"
              className="control-label col-md-4 col-sm-4 col-xs-12"
            >
              {labels.eventDate}
              <span>*</span>
            </label>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <DatePicker
                value={this.state.eventDate || eventDate}
                onChange={this.datePickerHandler.bind(this, 'event_date')}
                disabled={this.dateReadOnly()}
                format="YYYY-MM-DD"
              />
            </div>
          </div>
          <div className="form-group">
            <label
              htmlFor="medical_expense_total_amount"
              className="control-label col-md-4 col-sm-4 col-xs-12"
            >
              {labels.medicalExpenseTotalAmount}
              <span>*</span>
            </label>
            <div className="col-md-3 col-sm-3 col-xs-12">
              <input
                id="medical_expense_total_amount"
                name="medical_expense_total_amount"
                className="form-control col-md-7 col-xs-12"
                onChange={this.handleMedicalExpenseTotalAmountChange.bind(this)}
                type="number"
                min="0"
                value={this.state.medical_expense_total_amount || totalAmount}
                required
                title={labels.requiredInput}
              />
            </div>
          </div>
        </div>
      );
    }

    if (
      BENEFIT_NAMES.FIXED.includes(claimData.type) &&
      !['Personal_Accident', 'Medical_Expense', 'Property'].includes(
        claimData.type
      )
    ) {
      const totalAmount = claimData ? claimData.claimed_amount : '';
      return (
        <div>
          <div className="form-group">
            <label
              htmlFor="event_date"
              className="control-label col-md-4 col-sm-4 col-xs-12"
            >
              {labels.eventDate}
              <span>*</span>
            </label>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <DatePicker
                value={this.state.eventDate || eventDate}
                onChange={this.datePickerHandler.bind(this, 'event_date')}
                disabled={this.dateReadOnly()}
                format="YYYY-MM-DD"
              />
            </div>
          </div>
          <div className="form-group">
            <label
              htmlFor="claimed_amount"
              className="control-label col-md-4 col-sm-4 col-xs-12"
            >
              {labels.fixedIndemnityGenericTotalAmount}
              <span>*</span>
            </label>
            <div className="col-md-3 col-sm-3 col-xs-12">
              <input
                id="claimed_amount"
                name="claimed_amount"
                className="form-control col-md-7 col-xs-12"
                onChange={this.handleFixedIndemnityGenericTotalAmountChange.bind(
                  this
                )}
                type="number"
                min="0"
                value={this.state.claimed_amount || totalAmount}
                required
                title={labels.requiredInput}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="form-group">
        <label
          htmlFor="not_found_date"
          className="control-label col-md-8 col-sm-8 col-xs-12"
        >
          {labels.claimDateUINotFound}
        </label>
      </div>
    );
  }

  toggleIgnoreMaxDaysAllowedAfterDischargeOrDeath(event) {
    this.setState({
      ignoreMaxDaysAllowedAfterDischargeOrDeath: !this.state
        .ignoreMaxDaysAllowedAfterDischargeOrDeath,
    });
  }

  canIgnoreMaxDaysAllowed() {
    const { claimData } = this.props;

    return (
      (claimData.currentCustomerClaim.type === 'Death' ||
        claimData.currentCustomerClaim.type === 'Hospital') &&
      hasAuthourity('IGNORE_MAX_DAYS_ALLOWED_AFTER_DISCHARGE_OR_DEATH')
    );
  }

  labelForIgnoreMaxDaysAllowedCheckbox() {
    const { labels, claimData } = this.props;

    if (claimData.currentCustomerClaim.type === 'Hospital') {
      return labels.ignoreMaxDaysAllowedAfterDischarge;
    }

    if (claimData.currentCustomerClaim.type === 'Death') {
      return labels.ignoreMaxDaysAllowedAfterDeath;
    }
  }

  displayDetailReason() {
    const claimType = this.props.claimData.currentCustomerClaim.type;
    if (!['Death', 'Hospital'].includes(claimType)) {
      return null;
    }

    const reason = classNames({
      'form-group': true,
      error: this.props.validationErrorFields.includes(
        'hospitalization_reason'
      ),
    });
    return (
      <div className={reason}>
        <label
          htmlFor="reason"
          className="control-label col-md-4 col-sm-4 col-xs-12"
        >
          {this.detailsCauselabel()}
          <span>*</span>
        </label>
        <div className="col-md-4 col-sm-4 col-xs-14">
          <input
            id="reason"
            name="reason"
            required
            className="form-control col-md-7 col-xs-12"
            type="text"
            maxLength="75"
            defaultValue={this.currentReason()}
            {...this.cmpntProps()}
          />
        </div>
      </div>
    );
  }

  displayPersonAffectedDob() {
    const claimType = this.props.claimData.currentCustomerClaim.type;
    if (!['Death', 'Hospital'].includes(claimType)) {
      return null;
    }

    const labels = this.props.labels;
    const personAffectedDob = classNames({
      'form-group': true,
      error: this.props.validationErrorFields.includes('person_affected_dob'),
    });

    return (
      <div className={personAffectedDob}>
      <label
        htmlFor="birthday"
        className="control-label col-md-4 col-sm-4 col-xs-12"
      >
        {this.detailsDoblabel()}
        {this.childRelationCompulsory()}
      </label>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <DatePicker
          value={
            this.state.birthday ||
            this.props.claimData.currentCustomerClaim.person_affected_dob || null
          }
          onChange={this.datePickerHandler.bind(this, 'birthday')}
          disabled={this.dateReadOnly()}
          format="YYYY-MM-DD"
          placeholder={labels.notSetYet}
        />
      </div>
    </div>
    )
  }

  handlePropertyTotalAmountChange(event){
    this.setState({ property_total_amount: event.target.value });
  }

  handlePersonalAccidentTotalAmountChange(event){
    this.setState({ personal_accident_total_amount: event.target.value });
  }

  handleMedicalExpenseTotalAmountChange(event) {
    this.setState({ medical_expense_total_amount: event.target.value });
  }

  handleFixedIndemnityGenericTotalAmountChange(event) {
    this.setState({ claimed_amount: event.target.value });
  }

  render() {
    const labels = this.props.labels;
    const claimData = this.props.claimData.currentCustomerClaim;
    const docChannelFromProps = claimData
      ? claimData.doc_channel
        ? claimData.doc_channel[0]
        : labels.defaultSelection
      : labels.defaultSelection;

    const validBirthCertificate = claimData
      ? claimData.birth_certificate_valid
        ? claimData.birth_certificate_valid
        : false
      : false;

    const conditionOtherValue = claimData
      ? claimData.claim_condition_other_value
        ? claimData.claim_condition_other_value
        : ''
      : '';

    const accidentalProp = claimData
      ? this.getAccidentalValue(claimData.accidental)
      : '';
    const validBirthday = classNames({
      'form-group': true,
      error: this.props.validationErrorFields.includes(
        'birth_certificate_valid'
      ),
    });
    const docChannel = classNames({
      'form-group': true,
      error: this.props.validationErrorFields.includes('doc_channel'),
    });
    const docTime = classNames({
      'form-group': true,
      error: this.props.validationErrorFields.includes(
        'documentation_received_time'
      ),
    });

    const condition = classNames({
      'form-group': true,
      error: this.props.validationErrorFields.includes('condition'),
    });
    const accidental = classNames({
      'form-group': true,
      error: this.props.validationErrorFields.includes('accidental'),
    });
    const conditionStyle = {
      display: this.conditionVisible() ? 'block' : 'none',
    };
    const conditionValueStyle = {
      display: this.conditionValueVisible() ? 'block' : 'none',
    };
    return (
      <div className="grouping">
        <h3 className="header">{this.detailsHeaderLabel()}</h3>
        <br />
        <form
          id="funeralClaim"
          className="form-horizontal form-label-left"
          noValidate
        >
          {this.dateDisplay()}
          <div className="form-group">
            <label
              htmlFor="high_risk"
              className="control-label col-md-4 col-sm-4 col-xs-12"
            >
              {' '}
              {labels.highRisk}
            </label>
            <div className="col-md-4 col-sm-4 col-xs-14">
              <Checkbox
                id="high_risk"
                name="high_risk"
                required
                checked={
                  this.state.highRisk ||
                  this.props.claimData.currentCustomerClaim.high_risk
                }
                disabled={this.isReadOnly()}
                onChange={this.toggleHighRisk.bind(this)}
                color="primary"
              />
            </div>
          </div>
          <div className={docChannel}>
            <label
              htmlFor="channel"
              className="control-label col-md-4 col-sm-4 col-xs-4"
            >
              {labels.documentReceivedVia}
              <span>*</span>
            </label>
            <div className="col-md-4 col-sm-4 col-xs-4">
              <select
                id="channel"
                name="channel"
                className="form-control"
                value={this.state.docChannel}
                defaultValue={docChannelFromProps}
                {...this.cmpntProps()}
                onChange={this.docChannelSelected.bind(this)}
              >
                {translateArray(labels, this.props.claimData.documentTypes).map(
                  (type, index) => (
                    <option key={index}>{type}</option>
                  )
                )}
              </select>
            </div>
          </div>
          <div className={condition} style={conditionStyle}>
            <p style={{ color: 'red' }}>{this.state.blankConditionError} </p>
            <label
              htmlFor="condition"
              className="control-label col-md-4 col-sm-4 col-xs-4"
            >
              {labels.claim_condition}
              <span>*</span>
            </label>
            <div className="col-md-4 col-sm-4 col-xs-4">
              <select
                id="condition"
                name="condition"
                className="form-control"
                value={this.state.condition}
                defaultValue={
                  this.props.claimData.currentCustomerClaim.claim_condition_id
                }
                {...this.cmpntProps()}
                onChange={this.onChangeClaimCondition.bind(this)}
              >
                <option
                  key={
                    this.state.condition ||
                    this.props.claimData.currentCustomerClaim.claim_condition_id
                  }
                >
                  {this.getConditionLabel(
                    this.state.condition ||
                    this.props.claimData.currentCustomerClaim
                      .claim_condition_id
                  )}
                </option>
                {this.props.claimData.claimConditionList.map(conditionDto => (
                  <option key={conditionDto.id} value={conditionDto.id}>
                    {conditionDto.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group" style={conditionValueStyle}>
            <label
              htmlFor="condition_value"
              className="control-label col-md-4 col-sm-4 col-xs-12"
            >
              {labels.conditionValue}
              <span>*</span>
            </label>
            <div className="col-md-4 col-sm-4 col-xs-14">
              <input
                id="condition_value"
                name="condition_value"
                required
                className="form-control col-md-7 col-xs-12"
                type="text"
                maxLength="150"
                defaultValue={this.state.conditionValue || conditionOtherValue}
                onChange={this.onChangeClaimConditionOtherValue.bind(this)}
                {...this.cmpntProps()}
              />
            </div>
          </div>
          <div className={accidental}>
            <label
              htmlFor="accidental"
              className="control-label col-md-4 col-sm-4 col-xs-4"
            >
              {'Accidental'}
              <span>*</span>
            </label>
            <div className="col-md-4 col-sm-4 col-xs-4">
              <select
                id="accidental"
                name="accidental"
                className="form-control"
                value={this.state.accidental || accidentalProp}
                {...this.cmpntProps()}
                onChange={this.onChangeAccident.bind(this)}
              >
                <option value="">{labels.defaultSelection}</option>
                <option value>{labels.yes}</option>
                <option value={false}>{labels.no}</option>
              </select>
            </div>
          </div>
          {this.detailsHospitalSpecificDisplay()}
          {this.displayPersonAffectedDob()}
          <div className={validBirthday}>
            <label
              htmlFor="birth_certificate_valid"
              className="control-label col-md-4 col-sm-4 col-xs-12"
            >
              {labels.birthCertificateValid}
              {this.childRelationCompulsory()}
            </label>
            <div className="col-md-4 col-sm-4 col-xs-14">
              <Checkbox
                id="validBirthCertificate"
                name="validBirthCertificate"
                required
                checked={
                  (this.state.validBirthCertificate != null) ? this.state.validBirthCertificate:  validBirthCertificate
                }
                disabled={this.isReadOnly()}
                onChange={this.toggleValidBirthCertificate.bind(this)}
                color="primary"
              />
            </div>
          </div>

          {this.displayDetailReason()}

          <div
            style={{
              display: this.canIgnoreMaxDaysAllowed() ? 'block' : 'none',
            }}
          >
            <div className="form-group">
              <label
                htmlFor="deathDate"
                className="control-label col-md-4 col-sm-4 col-xs-12"
              >
                {this.labelForIgnoreMaxDaysAllowedCheckbox()}
              </label>
              <div className="col-md-3 col-sm-3 col-xs-12">
                <Checkbox
                  id="ignoreMaxDaysAllowedAfterDischargeOrDeath"
                  name="ignoreMaxDaysAllowedAfterDischargeOrDeath"
                  onChange={this.toggleIgnoreMaxDaysAllowedAfterDischargeOrDeath}
                  required
                  checked={this.state.ignoreMaxDaysAllowedAfterDischargeOrDeath}
                  color="primary"
                />
              </div>
            </div>
          </div>
          <br />
          {this.verifyByDisplay()}
          <div className={docTime}>
            <label
              htmlFor="doc_received_time"
              className="control-label col-md-4 col-sm-4 col-xs-12"
            >
              {labels.documentationReceivedTime}
              <span>*</span>
            </label>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <DateTimePicker
                value={
                  this.state.docReceivedTime ||
                  this.props.claimData.currentCustomerClaim
                    .documentation_received_time ||
                    null
                }
                onChange={this.datePickerHandler.bind(
                  this,
                  'doc_received_time'
                )}
                disabled={this.dateReadOnly()}
                format="YYYY-MM-DD HH:MM"
                placeholder={labels.notSetYet}
              />
            </div>
          </div>
          <div className="form-group">
            <label
              htmlFor="comments"
              className="control-label col-md-4 col-sm-4 col-xs-12"
            >
              {labels.claimComment}
            </label>
            <div className="col-md-4 col-sm-4 col-xs-14">
              <textarea
                id="comments"
                name="comments"
                required
                className="form-control col-md-7 col-xs-12"
                type="text"
                disabled={this.isReadOnly()}
                value={
                  this.state.comments ||
                  this.props.claimData.currentCustomerClaim.comments
                }
                onChange={this.handleCommentChange.bind(this)}
              />
            </div>
          </div>
        </form>
        <br />
        <center>
          <Button
            style={{color: 'white'}}
            className={this.isReadOnly() ? "buttonDefault" : "buttonDanger"}
            disabled={this.isReadOnly()}
          onClick={() => this.props.onUpdateHandle(this.preparePayload())}
          >
            {' '}
            {labels.updateClaimDetails}
          </Button>
        </center>
        <br />
      </div>
    );
  }
}

export default ClaimForm;
