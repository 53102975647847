import { take, call, put } from 'redux-saga/effects';
import { submitClaim, getclaimEnum } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { getCustomer } from '../Customer/actions.es6';
import {
  claimTypesSuccess,
  claimStatusSuccess,
} from '../ClaimSearch/actions.es6';
import * as types from '../../lib/actionTypes.es6';
import {
  getDocumentTypesSuccess,
  claimFieldsSuccess,
  claimVerifyBySuccess,
  claimDocReceivedRequiredFieldsSuccess,
  claimDocReceivedStatusesSuccess,
} from '../ClaimsProcessing/actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { summaryClaimsRequest } from '../ClaimsDashboard/actions.es6';
import { openClaimsRequest } from '../ClaimsOpen/actions.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

/**
 * this saga continually watches register request actions
 */
export function* claimSubmissionWatcher() {
  for (;;) {
    // TODO: There should be no need of an infinite loop here. We can listen for a specific action
    const actionRequest = yield take(types.CLAIM_SUBMISSION_REQUEST);
    try {
      const response = yield call(submitClaim, actionRequest.payload);
      yield put(actions.claimSubmissionSuccess(response.data));
      yield put(getCustomer(actionRequest.payload.payload.customer_guid));
      yield put(summaryClaimsRequest());
      yield put(openClaimsRequest());
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.claimSubmissionFail(errorPayload));
    }
  }
}

export function* claimTypesWatcher() {
  while (yield take('CLAIM_TYPES_REQUEST')) {
    try {
      const response = yield call(getclaimEnum);
      yield put(actions.claimTypeEnumSuccess(response.data.type));
      yield put(
        actions.claimRelationToMainMemberSuccess(
          response.data.relationToMainMember
        )
      );
      yield put(getDocumentTypesSuccess(response.data.docChannel));
      yield put(claimFieldsSuccess(response.data.fields));
      yield put(claimTypesSuccess(response.data.type));
      yield put(claimStatusSuccess(response.data.status));
      yield put(claimVerifyBySuccess(response.data.verify_by));
      yield put(
        claimDocReceivedRequiredFieldsSuccess(
          response.data.doc_received_required_fields
        )
      );
      yield put(
        claimDocReceivedStatusesSuccess(response.data.doc_received_statuses)
      );
    } catch (e) {
      console.log('error message', e);
      unAuthorizedNotification(e);
      // yield put(actions.claimSubmissionFail("An error occurred, this could be due to invalid data"));
    }
  }
}
