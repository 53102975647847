import * as types from '../../lib/actionTypes.es6';

export function getSmsTemplatesRequest(partner) {
  return {
    type: types.GET_SMS_TEMPLATES_REQUEST,
    payload: partner,
  };
}

export function getSmsTemplatesSuccess(smsTemplates) {
  return {
    type: types.GET_SMS_TEMPLATES_SUCCESS,
    payload: smsTemplates,
  };
}

export function getSmsTemplatesFail(error) {
  return {
    type: types.GET_SMS_TEMPLATES_FAIL,
    payload: error,
  };
}

export function getSmsTemplatesReset() {
  return {
    type: types.GET_SMS_TEMPLATES_RESET,
  };
}

export function getSupportedParametersRequest(partner) {
  return {
    type: types.GET_SUPPORTED_PARAMETERS_REQUEST,
    payload: partner,
  };
}

export function getSupportedParametersSuccess(supportedParameters) {
  return {
    type: types.GET_SUPPORTED_PARAMETERS_SUCCESS,
    payload: supportedParameters,
  };
}

export function getSupportedParametersFail(error) {
  return {
    type: types.GET_SUPPORTED_PARAMETERS_FAIL,
    payload: error,
  };
}

export function createSmsTemplatesRequest(smsTemplate) {
  return {
    type: types.CREATE_SMS_TEMPLATES_REQUEST,
    payload: smsTemplate,
  };
}

export function createSmsTemplatesSuccess(smsTemplate) {
  return {
    type: types.CREATE_SMS_TEMPLATES_SUCCESS,
    payload: smsTemplate,
  };
}

export function createSmsTemplatesFail(error) {
  return {
    type: types.CREATE_SMS_TEMPLATES_FAIL,
    payload: error,
  };
}


export function updateSmsTemplatesRequest(smsTemplate) {
  return {
    type: types.UPDATE_SMS_TEMPLATES_REQUEST,
    payload: smsTemplate,
  };
}

export function updateSmsTemplatesSuccess(smsTemplate) {
  return {
    type: types.UPDATE_SMS_TEMPLATES_SUCCESS,
    payload: smsTemplate,
  };
}

export function updateSmsTemplatesFail(error) {
  return {
    type: types.UPDATE_SMS_TEMPLATES_FAIL,
    payload: error,
  };
}

export function deleteSmsTemplatesRequest(guid) {
  return {
    type: types.DELETE_SMS_TEMPLATES_REQUEST,
    payload: guid,
  };
}

export function deleteSmsTemplatesSuccess(sms) {
  return {
    type: types.DELETE_SMS_TEMPLATES_SUCCESS,
    payload: sms,
  };
}

export function deleteSmsTemplatesFail(error) {
  return {
    type: types.DELETE_SMS_TEMPLATES_FAIL,
    payload: error,
  };
}

export function updateSmsTemplatesReset() {
  return {
    type: types.UPDATE_SMS_TEMPLATES_RESET,
  };
}

export function createSmsTemplatesReset() {
  return {
    type: types.CREATE_SMS_TEMPLATES_RESET,
  };
}

export function deleteSmsTemplatesReset() {
  return {
    type: types.DELETE_SMS_TEMPLATES_RESET,
  };
}

