import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  claimswrapper: {
    marginTop: "30px",
    "& button": {
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.001em",
      color: "#1E1E1E",
      textTransform: "capitalize",
    },

  },
  tabs: {

    "& .MuiTabs-indicator": {
      backgroundColor: "#032A37",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#fff",
      background: "#032A37",
      borderRadius: "8px 8px 0px 0px"
    }
  },
  claimdatawrapper: {
    width: "100%",
    height: "auto",
    paddingBottom: "25px",
    paddingTop: "25px",
    background: "#FFFFFF",
    borderRadius: "8px",
  }

}));