import React from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import LocalizedStrings from 'react-localization';
import { getUserGuid } from '../../lib/access.es6';
import {
  currentProfileRequest,
  currentUpdateProfileRequest,
} from './actions.es6';
import { localisedText } from '../../lib/localisation.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { getCurrentUserInfo } from '../../lib/utils.es6';
import { changeUserPasswordRequest, getCurrentPartnersRequest, getCurrentRolesRequest } from '../Users/actions.es6';
import AccountSettingsWrapper from './Components/AccountSettingsWrapper/AccountSettingsWrapper';
import { resetPassword } from '../../lib/requests.es6';
export class Profile extends React.Component {
  componentWillMount() {
    this.setState({
      openEdit: false,
      openPassword: false,
      labels: new LocalizedStrings(localisedText),
      showChangePasswordMessage: false,
      loading: false
    });
    this.getProfileInfo();
    this.getUserRoles();
    this.getUserPartners();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'Users',
      this.state.labels
    );
  }

  getProfileInfo() {
    const requestInfo = { guid: getUserGuid() }; // NOTE: It might be better not to have this here
    this.props.dispatch(currentProfileRequest(requestInfo));
  }

  async resetPassword() {
    try {
      this.setState({ loading: true });
      const requestInfo = {
        guid: getUserGuid(),
      };
      await resetPassword(requestInfo);
      this.setState({
        showChangePasswordMessage: true,
        loading: false,
      });
    } catch (error) {
      console.log("Something wrong happened", error)
      this.setState({ showChangePasswordMessage: false, loading: false });
    }

  }

  getUserRoles() {
    const userGuid = getUserGuid();
    this.props.dispatch(getCurrentRolesRequest(userGuid));

  }

  getUserPartners() {
    const userGuid = getUserGuid();
    this.props.dispatch(getCurrentPartnersRequest(userGuid));

  }

  changePassword() {
    const labels = this.state.labels;
    const val = document.getElementById('changePasswordForm').checkValidity();
    if (!val) {
      document.getElementById('changePasswordForm').reportValidity();
      return;
    }

    const payload = {
      old_password: document.getElementById('password').value,
      new_password: document.getElementById('new_password').value,
      guid: getCurrentUserInfo().user_guid,
    };
    const confirmPassword = document.getElementById('confirm_password').value;
    if (payload.new_password !== confirmPassword) {
      document
        .getElementById('confirm_password')
        .setCustomValidity(labels.passwordConfirmNotMatching);
      return;
    }

    this.props.dispatch(changeUserPasswordRequest(payload));
    this.handleClose();
  }

  handleOpenEdit() {
    this.setState({ openEdit: true });
  }

  handleOpenPassword() {
    this.setState({ openPassword: true });
  }

  handleClose() {
    this.setState({ openPassword: false, openEdit: false });
  }

  updateProfile(e) {
    e.preventDefault();
    const dialogForm = new FormData(
      document.getElementById('profileFormDialog')
    );
    const userGuid = getUserGuid();
    const profileData = {
      first_name: dialogForm.get('first_name'),
      last_name: dialogForm.get('last_name'),
      // user_name: dialogForm.get('username'),
      email: dialogForm.get('email'),
      userGuid,
    };
    this.props.dispatch(currentUpdateProfileRequest(profileData));
    this.handleClose();
    return false;
  }

  render() {
    return (
      <div>
        <Loader loaded={!this.props.profileData.loader} color="#ff834f">
          <section style={{
            background: "#FFFFFF",
            borderRadius: "8px",
            height: "auto",
            marginTop: "30px",
            padding: "30px"
          }}>
            <AccountSettingsWrapper
              profileData={this.props.profileData.userInfo}
              changePassword={this.resetPassword.bind(this)}
              currentPartners={this.props.userData.currentPartners}
              currentRoles={this.props.userData.currentroles}
              showChangePasswordMessage={this.state.showChangePasswordMessage}
              loading={this.state.loading}
              labels={this.state.labels}
            />
          </section>
        </Loader>
      </div>
    );
  }
}

export default connect(state => ({
  profileData: state.profile,
  userData: state.userAdmin,
  globalData: state.global,
}))(Profile);
