import React, { useState } from 'react'
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import AlertDialog from '../../../components/AlertDialog/index.es6';

const DeleteUser = (props) => {

  if (!props.user) {
    return ""
  }
  
  const [showWarning, setShowWarning] = useState(false);

  const deleteCurrentUser = () => {
    setShowWarning(true)
    props.deleteUser(props.user);
  }
  const cancel = () => {
    props.resetUserToDelete()
  }
  const handleUserDeletedSuccessfully = () => {
    props.resetDeleteUserSuccessAlert()
    props.resetUserToDelete()
  }
  const handleUserDeleteError = () => {
    props.resetUserToDelete()
  }
  return (
    <>
      <AlertDialog
        custom
        show={props.showDeleteUserProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Deleting User</h2>
      </AlertDialog>

      <AlertDialog
        warning
        show={!showWarning && props.user}
        size="sm"
        title={'Are you sure you want to delete ' + props.user.firstName}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { cancel() }}
        onCancel={() => { deleteCurrentUser() }}
        confirmBtnText={'Cancel'}
        showCancel={true}
        showConfirm={true}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
      </AlertDialog>
      <AlertDialog
        success
        show={props.showDeleteUserSuccessAlert}
        size="sm"
        title={'User deleted'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleUserDeletedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => { handleUserDeletedSuccessfully() }} style={{ background: 'green', }}>Ok</Button>
        </div>
      </AlertDialog>
      <AlertDialog
        show={props.showDeleteUserErrorAlert}
        danger
        title={'Error deleting user'}
        onConfirm={() => handleUserDeleteError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {'Could not delete user'}
      </AlertDialog>
    </>
  )
}

export default DeleteUser