import React from 'react'
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Grid } from '@mui/material';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { useStyle } from "./Style";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Button from '@mui/material/Button';
import SkeletonWrapper from '../../../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import { getPartner } from '../../../../../../../lib/access.es6';
const ITEM_HEIGHT = 48;

const Policys = (props) => {
  if (props.loader) {
    return <SkeletonWrapper loading={props.loader} />
  }
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedPolicyAction, setSelectedPolicyAction] = React.useState(null)
  const [selectedPolicy, setSelectedPolicy] = React.useState(null)


  const open = Boolean(anchorEl);

  const [expanded, setExpanded] = React.useState(false)
  const dataForDisplay = expanded ? props.policies : props.policies.slice(0, 2)


  const handleClick = (event,policy) => {
    setAnchorEl(event.currentTarget);
    setSelectedPolicyAction(policy);
    setSelectedPolicy(policy)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const currencyCode = getPartner().country.currency_code



  if (dataForDisplay.length > 0) {
    return (
      <>
        {
          dataForDisplay.map((policy, index) => (
            <Grid container className={classes.policyWrapper} key={index}>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container className={classes.policyHeader}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Grid container>
                      <Grid item xs={12} md={2} sm={2} className={classes.policyIconWrapper}   >
                        <Box className={classes.policyIcons}>
                          <NoteAddOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={10} sm={10} className={classes.policyHeaderContent}>
                        <h6>{policy.policy_number || "N/A"}</h6>
                        <p> {props.labels.policyNumber}</p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} >

                    <Grid container>
                      <Grid item xs={12} md={6} sm={6}>
                        <div >

                        </div> 
                      </Grid>
                      <Grid item xs={12} md={6} sm={6} className={classes.policyActiveStatusWrapper}>
                        <Box display="flex" justifyContent="flex-end">
                          <ul>
                            <li className={policy.active === true ? classes.activePill : classes.inactivePill}> <span>{policy.active === true ? `${props.labels.active}` : `${props.labels.inActive}` }</span></li>
                            <li className={classes.actionbtnpolicy}>
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? 'long-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={(event) => handleClick(event, policy)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              {
                                 selectedPolicyAction && selectedPolicyAction.guid === policy.guid &&   <Menu
                                 id="long-menu"
                                 MenuListProps={{
                                   'aria-labelledby': 'long-button',
                                 }}
                                 anchorEl={anchorEl}
                                 open={open}
                                 onClose={handleClose}
                                 PaperProps={{
                                   style: {
                                     maxHeight: ITEM_HEIGHT * 4.5,
                                     width: '20ch',
                                   },
                                 }}
                               >
                                 <MenuItem
                                   onClick={() => { props.setPolicyToEdit(selectedPolicy);  setAnchorEl(null) }} 
                                 style={{ 
                                   fontFamily: "Open Sans",
                                   fontStyle: "normal",
                                   fontWeight: 400,
                                   fontSize: "12px",
                                   lineHeight: "15px",
                                   color: "#000000",
                                 }}>
                                 {props.labels.editPolicyDetails}
                                 </MenuItem>
                                 <MenuItem  onClick={() => { setAnchorEl(null); props.setPolicyToDownloadDocumentFor(policy) }}  style={{
                                   fontFamily: "Open Sans",
                                   fontStyle: "normal",
                                   fontWeight: 400,
                                   fontSize: "12px",
                                   lineHeight: "15px",
                                   color: "#000000",
                                 }}>
                                   {props.labels.downloadPolicyDocument}
                                 </MenuItem>
                                  {
                                    !['Cancelled', 'Non_Payment', 'Not_Taken_Up'].includes(policy.status) && <MenuItem   onClick={() => { setAnchorEl(null); props.toggleCancelPolicyDialog(); props.setPolicyToCancel(policy) }} style={{
                                      fontFamily: "Open Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      lineHeight: "15px",
                                      color: "#000000",
                                    }}>
                                      
                                      {props.labels.cancelPolicy}
                                    </MenuItem>
                                  }
                                 
                               </Menu>
                              }
                            
                            </li>
                          </ul>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className={classes.policyHeaderBorder}></div>
                </Grid>

                <Grid container spacing={2} className={classes.policyContentWrapper}>
                  <Grid item xs={12} sm={3} md={3} >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3} md={3}></Grid>
                      <Grid item xs={12} sm={9} md={9}>
                        <p>{props.labels.productName}  </p>
                        <h6> {props.productNameDisplay(policy)  || "N/A"}</h6>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <p> {props.labels.benefits}</p>
                    <h6>{props.getBenefits(policy) || "N/A"}
                    </h6>

                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <p>{props.labels.startDate}</p>
                    <h6>{policy.start_date || "N/A"}</h6>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <p>{props.labels.validUntil}</p>
                    <h6>{policy.end_date || "N/A"}</h6>
                  </Grid>

                </Grid>
                <div className={classes.policyContentBorder}></div>
                <Grid container spacing={2} className={classes.policyFooterWrapper}>
                  <Grid item xs={12} md={6} sm={6}>
                    <Grid container spacing={2} >


                      <Grid item xs={12} md={6} sm={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={3} md={3}></Grid>
                          <Grid item xs={12} sm={9} md={9}>
                            <p>{props.labels.status}</p>
                            <h6>{policy.status || "N/A"}</h6>

                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6} sm={6}>
                        <p> {props.labels.channel}</p>
                        <h6>{policy.channel || "N/A"}</h6>
                      </Grid>

                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <div className={classes.policyBalance}>
                      <p> {props.labels.balance}</p>
                      <h6>{currencyCode} {policy.balance_in_cents || "N/A"}</h6>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))
        }

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Button style={{
              background: "#FBA92D",
              borderRadius: "8px",
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "15px",
              boxShadow: "none",
              padding: "10px",
              marginTop: "20px",
              textTransform: "capitalize",
            }} variant="contained" size="large" endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} onClick={() => setExpanded(!expanded)}>
              {expanded ? `${props.labels.ShowLessPolicies}` : `${props.labels.ShowMorePolicies}`}
            </Button>

          </Grid>
        </Grid>
    
      </>
    )
  } else {
    return (<>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <h1 style={{
            color: "#000000",
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            textAlign: "center",
            marginTop: "100px",
          }}> {props.labels.noPoliciesFound}</h1>

        </Grid>
      </Grid>

    </>)
  }

}

export default Policys