import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  loader: false,
  success_message: '',
  payment_error: null,
  add_payment_loader: false,
  recurringPaymentRecommedationSmsSent: null,
  recurringPaymentRecommedationSmsError: null,
};

export default function customerStatusReducer(state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_LOYALTY_REGISTRATION_REQUEST:
    case types.DELETE_PREMIUM_REQUEST:
    case types.INITIATE_POLICY_MOBILE_MONEY_PAYMENT_REQUEST:
    case types.GET_POLICY_PRODUCT_REQUEST:
    case types.UPDATE_POLICY_REQUEST:
    case types.DELETE_POLICY_REQUEST:
    case types.REFRESH_CUSTOMER_REQUEST:
      return { ...state, loader: true, errors: null };

    case types.INITIATE_POLICY_MOBILE_MONEY_PAYMENT_SUCCESS:
    case types.GET_POLICY_PRODUCT_SUCCESS:
    case types.CHANGE_CURRENT_CUSTOMER:
      return { ...state, loader: false, errors: null };

    case types.CHANGE_LOYALTY_REGISTRATION_FAILURE:
    case types.DELETE_PREMIUM_FAILURE:
    case types.INITIATE_POLICY_MOBILE_MONEY_PAYMENT_FAILURE:
    case types.GET_POLICY_PRODUCT_FAILURE:
    case types.UPDATE_POLICY_FAILURE:
      return { ...state, loader: false, errors: action.payload };
    case types.ADD_MISSING_PAYMENT_REQUEST:
      return { ...state, loader: true, add_payment_loader: true };
    case types.ADD_MISSING_PAYMENT_SUCCESS:
      return {
        ...state,
        loader: false,
        add_payment_loader: false,
        success_message: 'Payment Added Successfully',
      };
    case types.ADD_MISSING_PAYMENT_FAIL:
      return { ...state, loader: false, add_payment_loader: false, payment_error: action.payload };
    case types.ADD_MISSING_PAYMENT_RESET:
      return { ...state, payment_error: null, success_message: null, add_payment_loader: false };

    case types.SEND_RECURRING_PAYMENT_RECOMMENDATION_SMS_REQUEST:
      return { ...state, loader: true};
    case types.SEND_RECURRING_PAYMENT_RECOMMENDATION_SMS_SUCCESS:
      return {
        ...state,
        loader: false,
        recurringPaymentRecommedationSmsSent: action.payload,
      };
    case types.SEND_RECURRING_PAYMENT_RECOMMENDATION_SMS_FAILED:
      return { ...state, recurringPaymentRecommedationSmsError: action.payload };
    case types.SEND_RECURRING_PAYMENT_RECOMMENDATION_SMS_RESET:
      return { ...state, recurringPaymentRecommedationSmsError: null, recurringPaymentRecommedationSmsSent: null};


    default:
      return state;
  }
}
