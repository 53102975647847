import React from 'react';
import MaterialTable from 'material-table';
import Checkbox from '@material-ui/core/Checkbox';


export default class GroupPremiums extends React.Component {

  render() {
    const premiums = this.props.premiums || [];
    return (
      <MaterialTable
        title="Group Premiums"
        columns={[
          { title: 'Product', field: 'product_name' },
          { title: 'Reference', field: 'mno_reference' },
          { title: 'Created At', field: 'created_at'},
          { title: 'Valid', field: 'valid', render: premium => <Checkbox required checked={premium.valid} disabled />},
          { title: 'Active', field: 'active', render: premium => <Checkbox required checked={premium.active} disabled /> },
          { title: 'Start Date', field: 'start_date' },
          { title: 'End Date', field: 'end_date' },
          { title: 'Amount', field: 'amount_in_cents', render: premium =>  premium.amount_in_cents / 100},
          { title: 'Fixed Benefit', field: 'fixed_indemnity_benefit' },
        ]}
        data={premiums}
        options={{
					search: true,
					sorting: true,
					exportButton: true,
					actionsColumnIndex: -1,
					pageSize: 10,
					headerStyle: {
						backgroundColor: '#003643',
						color: '#FFF'
					}
				}}
      
      />
    );
  }
}