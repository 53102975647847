import React from 'react';
import { Paper, Grid, TextField, Button } from '@mui/material';
import { useFormLogic } from './formUtils';

const DeathForm = ({ claim }) => {
  const {
    formData,
    isSubmitting,
    // submitMessage,
    handleInputChange,
    handleSubmit,
  } = useFormLogic(claim);

  return (
    <Paper elevation={0} sx={{ p: 3, mb: 4 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Death Date"
              type="date"
              name="death_date"
              value={formData.death_date || ''}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Death Reason"
              name="death_reason"
              value={formData.death_reason || ''}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default DeathForm;
