import { take, call, put } from 'redux-saga/effects';
import * as types from '../../lib/actionTypes.es6';
import { getAllCashBacks, markCashbackAsUnpaid, markCashbackAsPaid } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

export function* cashBackRequestWatcher() {
  while (yield take(types.GET_ALL_CASHBACKS_REQUEST)) {
    try {
      const response = yield call(getAllCashBacks);
      yield put(actions.getAllCashbacksRequestSuccess(response.data));
    } catch (e) {
      console.log(e);
      let errorPayload = {};
      if (e.response) {
        console.log(e.response.data);
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
        
      }
      GAloggerException(e);
      yield put(actions.getAllCashbacksRequestFailure(errorPayload));
    }
  }
}

export function* markAsPaidRequestWatcher() {
  for (;;) {
    try {
      const { payload } = yield take(types.MARK_CASHBACK_AS_PAID_REQUEST);
      const response = yield call(markCashbackAsPaid, payload); 
      yield put(actions.markCashbackAsPaidRequestSuccess(response.data));
      yield put(actions.getAllCashbacksRequest());
    } catch (e) {
      console.log(e);
      let errorPayload = {};
      if (e.response) {
        console.log(e.response.data);
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
        
      }
      GAloggerException(e);
      yield put(actions.markCashbackAsPaidRequestFailure(errorPayload));
    }
  }
}

export function* markAsUnpaidRequestWatcher() {
  for (;;) {
    try {
      const { payload } = yield take(types.MARK_CASHBACK_AS_UNPAID_REQUEST);
      const response = yield call(markCashbackAsUnpaid,payload); 
      yield put(actions.markCashbackAsUnpaidRequestSuccess(response.data));
      yield put(actions.getAllCashbacksRequest());
    } catch (e) {
      console.log(e);
      let errorPayload = {};
      if (e.response) {
        console.log(e.response.data);
        errorPayload = e.response.data;
        unAuthorizedNotification(e); 
      }
      GAloggerException(e);
      yield put(actions.markCashbackAsUnpaidRequestFailure(errorPayload));
    }
  }
}





