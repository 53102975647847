import React from 'react'
import { hashHistory } from 'react-router';
import { useStyle } from "./Style";
import { Grid } from '@mui/material';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import { getPartnerGuid, getUserEmail, getUserFullname, hasAuthourity } from '../../../lib/access.es6';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import posthog from 'posthog-js';

const HomePageCta = (props) => {
  const classes = useStyle();

  const handleRegisterCustomer = () => {
    props.open()
    posthog.capture('clicked_register_customer_button_home_page', {
      email: `${getUserEmail()}`,
      name: `${getUserFullname()}`,
      partner: `${getPartnerGuid()}`,
      timestamp: new Date().toLocaleString(),
  });
  
  }
  const viewClaims = () => {
    hashHistory.push('admin/claims_dashboard');
  }

  const viewReports = () => {
    hashHistory.push('admin/premium_report');
  }

  const viewAnalytics = () => {
    hashHistory.push('admin/internal_dashboards');
  }
  return (
    <>
      <section className={classes.welcomewrapper}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.welcomeheading}>
              <span> {props.labels.hello} {getUserFullname()} {props.labels.welcomeBack} !</span>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{
          display: hasAuthourity('ACCESSBANK_REGISTER_CUSTOMER') ? 'inline-flex' : 'none'
        }}>
          <Grid item xs={12} md={3} sm={3}>
            <div className={classes.boxoptionwrapper} onClick={handleRegisterCustomer}>
              <ul>
                <li><HowToRegOutlinedIcon /></li>
                <li>{props.labels.registerCustomer} </li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} md={3} sm={3}>
            <div className={classes.boxoptionwrapper} onClick={viewAnalytics}>
              <ul>
                <li><InsightsOutlinedIcon /></li>
                <li>{props.labels.viewAnalytics}</li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} md={3} sm={3}>
            <div className={classes.boxoptionwrapper} onClick={viewClaims}>
              <ul>
                <li><InventoryOutlinedIcon /></li>
                <li>{props.labels.viewClaims}</li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} md={3} sm={3}>
            <div className={classes.boxoptionwrapper} onClick={viewReports}>
              <ul>
                <li><AssignmentIndOutlinedIcon /></li>
                <li>{props.labels.viewReports}</li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </section>
      <section className={classes.citybg}></section>
    </>
  )
}

export default HomePageCta