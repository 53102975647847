import React from 'react'
import Grid from "@material-ui/core/Grid";
import { hashHistory} from 'react-router';
import TableInc from '../../../../../shared-ui/TableInc';
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import { useStyle } from "./Style";

const Claims = (props) => {
  const classes = useStyle();
  const customerClaims = props.customerClaimsDcp || [];

  const tempArr = customerClaims.map(claims => {
    return {
      ...claims,
      status: claims.status || "N/A",
      system_message: claims.system_message || "N/A",
      type: claims.type || "N/A",

      }
      
  })
  return (
    <>
      {
        customerClaims.length > 0 ? (<>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12}>
                <TableInc
                  columns={[
                    { title: 'Claim Number', field: 'claim_number' },
                    { title: 'Created At', field: 'created_at' },
                    {
                      title: 'Status', field: 'status'
                    },
                    {
                      title: 'Message', field: 'system_message'
                    },
                    {
                      title: 'Claim Type', field: 'type'
                    },
                    {
                      title: 'action', field: 'claimaction', render: rowData => {
                        return <div className={classes.actionBtn}>
                          <ButtonInc variant="contained"  onClick={()=> hashHistory.push(`admin/claim-processing-v2/${rowData.guid}`)} >View Claim</ButtonInc>
                        </div>
                      }
                    },
                  ]}
                  title="Claims"
                  data={
                    tempArr
                  }
                  options={{
                    pageSize: customerClaims.length < 5
                    ? customerClaims.length
                    : 5,
                    exportButton: true,
                    showTitle: false,
                  }}
                />
            </Grid>
          </Grid>

        </>) : (<>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} >
              <div className={classes.policieswrapper}>
                <div className={classes.nodatafound}>
                  <p>No claims found  </p>
                </div>
              </div>
            </Grid>
          </Grid>

        </>)
      }

    </>
  )
}

export default Claims