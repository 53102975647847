import React, { useState } from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import { hasAuthourity } from '../../../../../lib/access.es6';
import { ClaimActionEnum } from '../../../lib/enum';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import ModalInc from '../../../../../shared-ui/ModalInc';

const AwaitingConfirmation = (props) => {
  const [toggleLoading, setToggleLoading] = useState('btn1')
  const [openReasonDialog, setOpenReasonDialog] = useState(false)
  const [reason, setReason] = useState('')
  const classes = useStyle();

  const handleConfirmClaim = () => {
    setToggleLoading('btn1')
    props.submitClaimAction({
      claim_action: ClaimActionEnum.confirm,
      claim_guid: props.claim.guid,
    })
  }
  const handleFailConfirmationClaim = () => {
    setToggleLoading('btn2')
    props.submitClaimAction({
      claim_action: ClaimActionEnum.fail_confirm,
      claim_guid: props.claim.guid,
      reason
    })
  }
  return (
    <section className={classes.ClaimActionButtons}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ButtonInc onClick={handleConfirmClaim} hasPermision={hasAuthourity('APPROVE_CLAIM')} loading={props.loading && toggleLoading === 'btn1'} variant="contained" style={{ width: "221px", height: "55px", float: "left", marginRight: "20px" }}>Confirm Claim</ButtonInc>
          <ButtonInc onClick={()=> setOpenReasonDialog(true)} hasPermision={hasAuthourity('REJECT_CLAIM')} loading={props.loading && toggleLoading === 'btn2'} variant="contained" style={{ width: "221px", height: "55px", background: "#8F191C" }}>Fail Confirmation</ButtonInc>
          <ModalInc
            modalTitle="Log note "
            subTitle="Fill the below field to failure reason note"
            open={openReasonDialog}
            onClose={()=> setOpenReasonDialog(false)}
            fullWidth
          >
            <ValidatorForm onSubmit={() => {
              handleFailConfirmationClaim()
              setOpenReasonDialog(false)
            }} >

              <Grid container spacing={2}>


                <Grid item xs={12} sm={12} md={12}>
                  <TextValidator
                    name='reason'
                    id="reason"
                    label="Add failure reason "
                    value={reason}
                    onChange={(event)=> setReason(event.target.value)}
                    multiline
                    rows={5}
                    maxRows={4}
                    fullWidth
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Please enter failure reason']}
                    style={{ marginTop: "30px" }}
                  />
                </Grid>

              </Grid>
              <Grid container spacing={2} >
                <Grid item xs={12} sm={6} md={6}>

                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8} md={8}>
                      <ButtonInc
                        variant="contained"
                        color="primary"
                        size="large"
                        type='submit'
                        style={{ marginTop: "20px", float: "right" }}
                      >
                        Fail Confirmation
                      </ButtonInc>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <ButtonInc
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ marginTop: "20px" }}
                        onClick={()=> setOpenReasonDialog(false)}
                      >
                        Go back
                      </ButtonInc>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </ValidatorForm>

          </ModalInc>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <p style={{ color: 'red' }}>{props.error ? props.error.message : ''}</p>
        </Grid>
      </Grid>
    </section>
  )
}

export default AwaitingConfirmation