import React from 'react'
import Chip from '@mui/material/Chip';
import { Grid } from '@mui/material';
const UserRoles = (props) => {
  return (
    <>
      {
        <Grid container spacing={2} style={{ marginTop: "40px" }}>
          {
            props.currentRoles.map((role, index) =>
              <Chip sx={{ margin: "10px" }} key={index} label={role.name} variant="outlined" />

            )
          }

        </Grid>

      }

    </>
  )
}

export default UserRoles