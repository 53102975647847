import React from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';
import { deepOrange } from '@material-ui/core/colors'
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import * as utils from '../../lib/utils.es6';
import MainPageTitle from '../MainPageTitle/index.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { localisedText } from '../../lib/localisation.es6';

export class DebitOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
      openConfirmCancel: false,
      debitOrder: null,
    };
  }

  componentWillMount() {
    this.updateLanguage();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'DebitOrders',
      this.state.labels
    );
  }

  handleCloseCancelDialog() {
    this.setState({ openConfirmCancel: false });
  }

  handleOpenCancelDialog(debitOrder) {
    this.setState({ openConfirmCancel: true, debitOrder });
  }

  cancelDebitOrder() {
    this.props.deleteDebitOrderHandler(this.state.debitOrder);
    this.setState({ openConfirmCancel: false, debitOrder: null });
  }

  render() {
    const labels = this.state.labels;
    const debitOrders = this.props.debitOrders ? this.props.debitOrders : [];
    const debitOrderData = debitOrders.map(debitOrder => (
      <TableRow key={debitOrder.guid}>
        <TableRowColumn>{debitOrder.product_name}</TableRowColumn>
        <TableRowColumn>{debitOrder.msisdn}</TableRowColumn>
        <TableRowColumn>{debitOrder.guid}</TableRowColumn>
        <TableRowColumn>{debitOrder.created_at}</TableRowColumn>
        <TableRowColumn>{debitOrder.bank_account_number}</TableRowColumn>
        <TableRowColumn>{debitOrder.day}</TableRowColumn>
        <TableRowColumn>{debitOrder.state}</TableRowColumn>
        <TableRowColumn>{debitOrder.start_date}</TableRowColumn>
        <TableRowColumn>{debitOrder.amount_in_cents / 100}</TableRowColumn>
        <TableRowColumn>
          <button
            disabled
            type="button"
            onClick={this.handleOpenCancelDialog.bind(this, debitOrder)}
            className="btn btn-danger"
          >
            {labels.cancel}
          </button>
        </TableRowColumn>
      </TableRow>
    ));
    const confirmDeleteActions = [
      <Button
        style={{ backgroundColor: deepOrange[400], color: '#FFF' }}
        onClick={this.handleCloseCancelDialog.bind(this)}
      >
        {' '}
        {labels.cancel}
      </Button>,
      <Button
        id="deleteBtnId"
        style={{ backgroundColor: deepOrange[400], color: '#FFF' }}
        onClick={this.cancelDebitOrder.bind(this)}
      >
        {labels.removeDebitOrder}{' '}
      </Button>,
    ];
    return (
      <div className={`${this.props.columnWidth} col-sm-6 col-xs-12`}>
        <MainPageTitle pageTitle={labels.debitOrder} />
        <div className="x_panel">
          <div className="x_content">
            {debitOrders.length === 0 ? (
              <center>{labels.nothingFound}</center>
            ) : (
              <form className="form-horizontal form-label-left" noValidate>
                <Table
                  selectable={false}
                  style={{ tableLayout: 'auto' }}
                  fixedHeader={false}
                >
                  <TableHeader
                    adjustForCheckbox={false}
                    displaySelectAll={false}
                  >
                    <TableRow>
                      <TableHeaderColumn>{labels.policy}</TableHeaderColumn>
                      <TableHeaderColumn>{labels.msisdn}</TableHeaderColumn>
                      <TableHeaderColumn>{labels.reference}</TableHeaderColumn>
                      <TableHeaderColumn>{labels.createdAt}</TableHeaderColumn>
                      <TableHeaderColumn>
                        {labels.bankAccount}
                      </TableHeaderColumn>
                      <TableHeaderColumn>{labels.day}</TableHeaderColumn>
                      <TableHeaderColumn>{labels.status}</TableHeaderColumn>
                      <TableHeaderColumn>{labels.startDate}</TableHeaderColumn>
                      <TableHeaderColumn>{labels.amount}</TableHeaderColumn>
                      <TableHeaderColumn>{labels.action}</TableHeaderColumn>
                    </TableRow>
                  </TableHeader>
                  <TableBody
                    displayRowCheckbox={false}
                    className="debitOrders-body"
                  >
                    {this.props.showMore
                      ? debitOrderData.slice(0, 3)
                      : debitOrderData}
                  </TableBody>
                </Table>
              </form>
            )}
            <Link
              to="admin/customer_debit_orders"
              className="btn btn-info btn-sm"
              style={{
                display: utils.displayValue(
                  this.props.showMore && debitOrders.length !== 0
                ),
              }}
            >
              {labels.moreDebitOrder}
            </Link>
          </div>
        </div>
        <Dialog
          open={this.state.openConfirmCancel}
          onClose={this.handleCloseCancelDialog.bind(this)}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">
            {labels.removeDebitOrderConfirmation}
          </DialogTitle>
          <DialogContent>{labels.removeDebitOrderMessage}</DialogContent>
          <DialogActions>
            {confirmDeleteActions.map((item, index) => (
              <div key={index}> {item}</div>
            ))}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect(state => ({ globalData: state.global }))(DebitOrders);
