import React from 'react'
import { useStyle } from "./Style";
import { Grid } from '@mui/material';

const ProfileDetails = (props) => {
  const classes = useStyle();

  //const createdDateFormat = props.ProfileDetails.createdTimestamp
  return (
    <section style={{marginTop: "40px"}}>
      <Grid container spacing={2} className={classes.customerdatawrapper}>
        <Grid item xs={12} md={3} sm={3}>
          <h2>{props.labels.firstName}</h2>
          <p>{props.ProfileDetails.firstName || "N/A" }  </p>
        </Grid>
        <Grid item xs={12} md={3} sm={3}>
          <h2>{props.labels.lastName} </h2>
          <p> {props.ProfileDetails.lastName || "N/A" }</p>
        </Grid>
        <Grid item xs={12} md={3} sm={3}>
          <h2> {props.labels.username} </h2>
          <p> {props.ProfileDetails.username || "N/A" }</p>
        </Grid>
        <Grid item xs={12} md={3} sm={3}>
          <h2> {props.labels.email}  </h2>
          <p> {props.ProfileDetails.email || "N/A" }</p>
        </Grid>
        

      </Grid>
      </section>
  )
}

export default ProfileDetails