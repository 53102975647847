import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  customerInfo: null,
  loader: false,
};

export default function registrationReducer(state = initialState, action) {
  switch (action.type) {
    case 'REGISTER_CUSTOMER_REQUEST':
      return { ...state, loader: true, errors: null, customerInfo: null };

    case 'REGISTER_CUSTOMER_SUCCESS':
      return { ...state, customerInfo: action.payload, loader: false };

    case 'REGISTER_CUSTOMER_FAIL':
      return { ...state, loader: false, errors: action.payload };

    case 'SET_REGISTRATION_ERROR':
      return { ...state, loader: false, errors: action.payload };

    case 'RESET_REGISTRATION':
      return initialState;

    case types.USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
}
