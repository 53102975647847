import React, { useState } from 'react'
import { Link } from 'react-scroll'
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import { useStyle } from "./Style";
import CloseIcon from '../../../../../Assets/close_icon.png'
import { Button } from '@mui/material';

const MobileNavigation = (props) => {
    const classes = useStyle();
    const [openDrawer, setOpenDrawer] = useState(false);
    const toggle = () => {
        setOpenDrawer(!openDrawer);
        props.toggleLoginPopup();
    }
    return (
        <>
            <Drawer
                PaperProps={{ style: { left: "15px", right: "15px", top: "20px" } }}
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                className={classes.mobiledrawer}
                anchor="top"
            >
                <span className={classes.closemobile} onClick={() => setOpenDrawer(!openDrawer)}>
                    <img src={CloseIcon} alt="close icon" />
                </span>
                <List>
                    <ListItem >
                        <ListItemText>
                            <div className={classes.navigationLink}>
                                <ol>
                                    <li>
                                        <Link style={{ cursor: "pointer" }} to="partners" spy={true} smooth={true} offset={50} duration={500} onClick={() => setOpenDrawer(false)}>
                                            Partners
                                        </Link>
                                    </li>
                                    <li>
                                        <Link style={{ cursor: "pointer" }} to="features" spy={true} smooth={true} offset={50} duration={500} onClick={() => setOpenDrawer(false)}>
                                            Features
                                        </Link>
                                    </li>
                                    <li>
                                        <Link style={{ cursor: "pointer" }} to="products" spy={true} smooth={true} offset={50} duration={500} onClick={() => setOpenDrawer(false)}>
                                            Products
                                        </Link>
                                    </li>
                                    <li>
                                        <Link style={{ cursor: "pointer" }} to="testimonial" spy={true} smooth={true} offset={50} duration={500} onClick={() => setOpenDrawer(false)}>
                                            Testimonials
                                        </Link>
                                    </li>
                                    <li>
                                        <Link style={{ cursor: "pointer" }} to="developers" spy={true} smooth={true} offset={50} duration={500} onClick={() => setOpenDrawer(false)}>
                                            Developers
                                        </Link>
                                    </li>
                                </ol>
                            </div>
                        </ListItemText>
                    </ListItem>
                </List>
                <div className={classes.navigationCta}>
                    <Button onClick={toggle}>Login</Button>
                    <Button href='https://inclusivitysolutions.com/contact-us-2/' target='_blank' rel="noopener noreferrer">Schedule Demo</Button>
                </div>
            </Drawer>
            <IconButton
                className={classes.mobileiconcontainer}
                onClick={() => setOpenDrawer(!openDrawer)}
                sx={{
                    "& button": {
                        backgroundColor: "#fff"
                    }
                }}
            >
                <svg width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="34" height="36" fill="transparent" />
                    <path d="M4.25 10.5H29.75M4.25 18H29.75M4.25 25.5H29.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
            </IconButton>

        </>
    )
}

export default MobileNavigation