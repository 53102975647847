import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  loader: false,
  options: null,
  currentPremium: null,
  premiums: [],
};

export default function productPremiumsReducer(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_PRODUCT_PREMIUM_REQUEST:
      return {
        ...state,
        errors: null,
        currentPremium: null,
        loader: true,
      };

    case types.CREATE_PRODUCT_PREMIUM_SUCCESS:
      return {
        ...state,
        currentPremium: action.payload,
        loader: false,
        errors: null,
      };

    case types.CREATE_PRODUCT_PREMIUM_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    case types.PRODUCT_PREMIUM_ENUMS_REQUEST:
      return { ...state, errors: null, loader: true, options: null };

    case types.PRODUCT_PREMIUM_ENUMS_SUCCESS:
      return {
        ...state,
        options: action.payload,
        loader: false,
        errors: null,
      };

    case types.PRODUCT_PREMIUM_ENUMS_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    case types.VIEW_ALL_PRODUCT_PREMIUMS_REQUEST:
      return { ...state, errors: null, loader: true };

    case types.VIEW_ALL_PRODUCT_PREMIUMS_FAILURE:
      return {
        ...state,
        loader: false,
        errors: action.payload,
      };

    case types.VIEW_ALL_PRODUCT_PREMIUMS_SUCCESS:
      return { ...state, loader: false, premiums: action.payload };

    case types.USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
}
