import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../../lib/actionTypes.es6'
import * as actions from './actions.js';

import { getPaymentsDcp } from '../../../../lib/requests.es6';
import { GAloggerException } from '../../../../lib/monitoring.es6';
import { unAuthorizedNotification } from '../../../../lib/access.es6';




export function* getPaymentsDcpRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_PAYMENTS_DCP_REQUEST);
    try {
      const response = yield call(getPaymentsDcp, request.payload);
      yield put(actions.getPaymentsDcpSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getPaymentsDcpFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}