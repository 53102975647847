import { take, call, put } from 'redux-saga/effects';
import { getDailyReportBlob } from '../../lib/requests.es6';
import * as types from '../../lib/actionTypes.es6';
import * as actions from './actions.js';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

export function* dailyReportRequestWatcher() {
  for (;;) {
    const reportAction = yield take(types.DAILY_REPORT_REQUEST);
    try {
      const response = yield call(getDailyReportBlob, reportAction.payload);
      yield put(actions.dailyReportRequestSuccess(response.data));
    } catch (e) {
      yield put(
        actions.dailyReportRequestError(
          'An error occurred, this could be due to invalid data'
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
