
import { makeStyles } from "@material-ui/styles";



export const useStyle = makeStyles((LandingPageTheme) => ({


    faqs: {
        padding: "50px",
        borderTop: "1px solid #000",
        [LandingPageTheme.breakpoints.down("md")]: {
            padding: "15px"
        },
        "& h2": {
            fontFamily: 'Lato',
            fontStyle: "normal",
            fontWeight: "900",
            fontSize: "22px",
            lineHeight: "28px",
            color: "#000",
            [LandingPageTheme.breakpoints.down("md")]: {
                fontSize: "18px",
                lineHeight: "25px",
                letterSpacing: " 0.001em",
            }
        },
        "& p": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: " 0.005em",
            color: " #FFFFFF",
            [LandingPageTheme.breakpoints.down("md")]: {
                fontSize: "12px",
            }
        }
    },
    dividerdiff: {
        width: "28px",
        height: "7px",
        background: "#FBA92D",
        transform: "matrix(1, 0, 0, -1, 0, 0)",
        float: "left",
        marginTop: "-10px",
        display: "inline",
    },
    faqaccordion: {
        marginTop: "40px",
        "& h4": {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "20px",
            letterSpacing: " 0.001em",
            color: "#FFFFFF",
            [LandingPageTheme.breakpoints.down("md")]: {
                fontSize: "14px",
            },
        },
    },
    faqbtn: {
        width: "178px",
        height: "43px",
        border: "1px solid #FBA92D",
        borderRadius: "20px",
        margin: "auto",
        textAlign: "center",
        paddingTop: "10px",
        "& a": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.001em",
            color: "#000000"
        }
    },
  


}));

