import React from 'react';
import './style.css'
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { hashHistory } from 'react-router';
import Loader from 'react-loader';
import Checkbox from '@material-ui/core/Checkbox';
import ReactPaginate from 'react-paginate';
import * as actions from './actions.es6';
import { makeCurrentCustomer } from '../Customer/actions.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import {
  partnerCustomerFieldsContains,
  customerLanguagesVisible,
  languageLabel,
} from '../../lib/access.es6';
import { localisedText } from '../../lib/localisation.es6';
import * as globalActions from '../Main/actions.es6';
import * as userActions from "../Users/actions.es6";
import { reloadPage } from '../../lib/utils.es6';
import DesktopDisplaySearchResults from './Components/DesktopDisplaySearchResults/DesktopDisplaySearchResults';
import MobileDisplaySearchResults from './Components/MobileDisplaySearchResults/MobileDisplaySearchResults';

export class DisplaySearchResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msisdn:
        this.props.searchData.msisdn === null
          ? ''
          : this.props.searchData.msisdn,
      name: '',
      labels: new LocalizedStrings(localisedText),
      page: 0,
      activeSearchCriteria: 'msisdn',
      isMobile: Window.innerWidth < 600,
    };
  }


  componentWillMount() {
    this.updateLanguage();
    this.props.dispatch(globalActions.showCustomerBar());
  }

  componentDidUpdate() {
    if (this.props.searchData.searchFinishedFlag) {
      this.props.dispatch(actions.resetSearchFlag());
    }
    this.updateLanguage();
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'Search',
      this.state.labels
    );
  }

  msisdnFieldHandler(event) {
    this.setState({ msisdn: event.target.value });
  }

  nameFieldHandler(event) {
    this.setState({ name: event.target.value });
  }

  submitSearchCustomerHandler(criteria, page, size) {
    this.setState({ activeSearchCriteria: criteria });
    let customerData = null;

    console.log("customerData", customerData)

    if (this.props.searchData.activeSearchCriteria === 'msisdn') {
      const msisdn = this.props.searchData.msisdn;
      if (!msisdn) {
        return;
      }

      customerData = {
        msisdn,
        activeSearchCriteria: this.props.searchData.activeSearchCriteria
      };

    } else if (this.props.searchData.activeSearchCriteria === 'name') {

      const name = this.props.searchData.name;
      if (!name) {
        return;
      }
      customerData = {
        name,
        activeSearchCriteria: this.props.searchData.activeSearchCriteria
      };

    } else if (this.props.searchData.activeSearchCriteria === 'national_id') {

      const national_id = this.props.searchData.national_id;
      if (!national_id) {
        return;
      }
      customerData = {
        national_id,
        activeSearchCriteria: this.props.searchData.activeSearchCriteria
      };
    } else if (this.props.searchData.activeSearchCriteria === 'external_identifier') {

      const external_identifier = this.props.searchData.external_identifier;
      if (!external_identifier) {
        return;
      }
      customerData = {
        external_identifier,
        activeSearchCriteria: this.props.searchData.activeSearchCriteria
      };
    } else if (this.props.searchData.activeSearchCriteria === 'policy_number') {
      const policy_number = this.props.searchData.policy_number;
      if (!policy_number) {
        return;
      }
      customerData = {
        policy_number,
        activeSearchCriteria: this.props.searchData.activeSearchCriteria
      };
    }

    customerData['page'] = page;
    customerData['size'] = size;
    this.props.dispatch(actions.searchCustomerByMsisdnRequest(customerData));
  }

  selectCustomer(customer) {
    this.props.dispatch(makeCurrentCustomer(customer));

    if (customer.web_agent_guid) {
      this.props.dispatch(userActions.getUserRequest(customer.web_agent_guid));
    }

    // search bar show username
    this.props.dispatch(globalActions.customerBarShowUser());
    this.handleClose();
    hashHistory.push('admin/customer_status/' + customer.guid);
    reloadPage()
  }

  handleClose() {
    this.props.dispatch(actions.resetSearchResults());
  }

  beneficiaryVisible() {
    return (
      partnerCustomerFieldsContains('beneficiary_name') ||
      partnerCustomerFieldsContains('beneficiary_msisdn')
    );
  }

  beneficiaryFullnameHeader() {
    const labels = this.state.labels;
    const beneficiaryStyle = {
      display: this.beneficiaryVisible() ? '' : 'none',
    };
    return <th style={beneficiaryStyle}>{labels.beneficiaryFullname}</th>;
  }

  beneficiaryFullnameRow(customer) {
    const beneficiaryStyle = {
      display: this.beneficiaryVisible() ? '' : 'none',
    };
    return (
      <td className="col-xs-1" style={beneficiaryStyle}>
        {customer.beneficiary_name}
      </td>
    );
  }

  beneficiaryPhoneNumberHeader() {
    const labels = this.state.labels;
    const beneficiaryStyle = {
      display: this.beneficiaryVisible() ? '' : 'none',
    };
    return <th style={beneficiaryStyle}>{labels.beneficiaryPhoneNumber}</th>;
  }

  beneficiaryPhoneNumberRow(customer) {
    const beneficiaryStyle = {
      display: this.beneficiaryVisible() ? '' : 'none',
    };
    return (
      <td className="col-xs-1" style={beneficiaryStyle}>
        {customer.beneficiary_msisdn}
      </td>
    );
  }

  fullNameVisible() {
    return partnerCustomerFieldsContains('full_name');
  }

  fullNameHeader() {
    const labels = this.state.labels;
    const fullNameStyle = {
      display: this.fullNameVisible() ? '' : 'none',
    };
    return <th style={fullNameStyle}>{labels.fullName}</th>;
  }

  fullNameRow(customer) {
    const fullNameStyle = {
      display: this.fullNameVisible() ? '' : 'none',
    };
    return (
      <td className="col-xs-2" style={fullNameStyle}>
        {customer.full_name || "N/A"}
      </td>
    );
  }

  firstNameVisible() {
    return partnerCustomerFieldsContains('first_name');
  }

  firstNameHeader() {
    const labels = this.state.labels;
    const firstNameStyle = {
      display: this.firstNameVisible() ? '' : 'none',
    };
    return <th style={firstNameStyle}>{labels.firstName}</th>;
  }

  firstNameRow(customer) {
    const firstNameStyle = {
      display: this.firstNameVisible() ? '' : 'none',
    };
    return (
      <td className="col-xs-1" style={firstNameStyle}>
        {customer.first_name || "N/A"}
      </td>
    );
  }

  surnameVisible() {
    return partnerCustomerFieldsContains('surname');
  }

  surnameHeader() {
    const labels = this.state.labels;
    const surnameStyle = {
      display: this.surnameVisible() ? '' : 'none',
    };
    return <th style={surnameStyle}>{labels.surname}</th>;
  }

  surnameRow(customer) {
    const surnameStyle = {
      display: this.surnameVisible() ? '' : 'none',
    };
    return (
      <td className="col-xs-1" style={surnameStyle}>
        {customer.surname}
      </td>
    );
  }

  agentVisible() {
    return partnerCustomerFieldsContains('agent');
  }

  agentHeader() {
    const labels = this.state.labels;
    const agentStyle = {
      display: this.agentVisible() ? '' : 'none',
    };
    return <th style={agentStyle}>{labels.agent}</th>;
  }

  agentRow(customer) {
    const agentStyle = {
      display: this.agentVisible() ? '' : 'none',
    };
    return (
      <td className="col-xs-1" style={agentStyle}>
        <Checkbox required checked={customer.agent} disabled />
      </td>
    );
  }

  nationalIdHeader() {
    const labels = this.state.labels;
    const nationalIdStyle = {
      display: this.nationalIdVisible() ? '' : 'none',
    };
    return <th style={nationalIdStyle}>{labels.nationalId}</th>;
  }

  nationalIdRow(customer) {
    const nationalIdStyle = {
      display: this.nationalIdVisible() ? '' : 'none',
    };
    return (
      <td className="col-xs-1" style={nationalIdStyle}>
        {customer.national_id || "N/A"}
      </td>
    );
  }

  getPages() {
    return Math.ceil(this.props.searchData.count / this.props.searchData.size);
  }

  nationalIdVisible() {
    return partnerCustomerFieldsContains('national_id');
  }

  paginateCustomers() {
    const pages = this.getPages();
    const display = {
      display: pages > 1 ? '' : 'none',
    };
    const labels = this.state.labels;
    return (
      <div style={display}>
        <ReactPaginate
          previousLabel={labels.paginationPreviousText}
          nextLabel={labels.paginationNextText}
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
          forcePage={this.state.page}
        />
      </div>
    );
  }

  pageSizeSelector() {
    const display = {
      display: this.props.searchData.count > 10 ? '' : 'none',
    };
    return (
      <select
        value={this.props.searchData.size}
        onChange={this.handleChangePageSize.bind(this)}
        style={display}
      >
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    );
  }


  handleChangePageSize(event) {
    event.preventDefault();
    const size = parseInt(event.target.value);
    this.setState({ page: 0 });
    this.submitSearchCustomerHandler(this.state.activeSearchCriteria, 0, size);
    this.props.dispatch(actions.updatePageSize(size));
  }

  handlePageClick = data => {
    const selectedPage = data.selected;
    this.setState({ page: selectedPage });
    this.submitSearchCustomerHandler(
      this.state.activeSearchCriteria,
      selectedPage,
      this.props.searchData.size
    );
  };

  languageHeader() {
    const labels = this.state.labels;
    const languageStyle = {
      display: this.languageVisible() ? '' : 'none',
    };
    return <th style={languageStyle}>{labels.lang}</th>;
  }

  languageRow(customer) {
    const languageStyle = {
      display: this.languageVisible() ? '' : 'none',
    };
    return (
      <td className="col-xs-1" style={languageStyle}>
        {this.languageLabel(customer.language)}
      </td>
    );
  }

  languageLabel(languageIn) {
    return languageLabel(languageIn);
  }

  languageVisible() {
    return customerLanguagesVisible();
  }


  handleResize = () => {
    this.setState({
      isMobile: window.innerWidth < 600
    });
  };


  render() {

    return (
      <Loader loaded={!this.props.searchData.customerLoader} color="#ff834f">

        <MobileDisplaySearchResults
          customers={this.props.searchData.customerList}
          labels={this.state.labels}
          handleClose={() => this.handleClose.bind(this)}
          count={this.props.searchData.count}
          paginateCustomers={this.paginateCustomers.bind(this)}
          selectCustomer={this.selectCustomer.bind(this)}
        />

        <DesktopDisplaySearchResults
          customers={this.props.searchData.customerList}
          labels={this.state.labels}
          paginateCustomers={this.paginateCustomers.bind(this)}
          pageSizeSelector={this.pageSizeSelector.bind(this)}
          count={this.props.searchData.count}
          handleClose={() => this.handleClose.bind(this)}

          nationalIdHeader={this.nationalIdHeader.bind(this)}
          firstNameHeader={this.firstNameHeader.bind(this)}
          surnameHeader={this.surnameHeader.bind(this)}
          fullNameHeader={this.fullNameHeader.bind(this)}
          agentHeader={this.agentHeader.bind(this)}
          languageHeader={this.languageHeader.bind(this)}
          beneficiaryFullnameHeader={this.beneficiaryFullnameHeader.bind(this)}
          beneficiaryPhoneNumberHeader={this.beneficiaryPhoneNumberHeader.bind(this)}


          nationalIdRow={this.nationalIdRow.bind(this)}
          firstNameRow={this.firstNameRow.bind(this)}
          surnameRow={this.surnameRow.bind(this)}
          fullNameRow={this.fullNameRow.bind(this)}
          agentRow={this.agentRow.bind(this)}
          languageRow={this.languageRow.bind(this)}
          beneficiaryFullnameRow={this.beneficiaryFullnameRow.bind(this)}
          beneficiaryPhoneNumberRow={this.beneficiaryPhoneNumberRow.bind(this)}
          selectCustomer={this.selectCustomer.bind(this)}
        />

      </Loader>
    );
  }
}
// NOTE: 'searchData' is the way the component accesses the state with 'this.props.searchData'
// the state branch is 'search', we use that in the reducers to subscribe reducer
export default connect(state => ({
  searchData: state.search,
  globalData: state.global,
}))(DisplaySearchResults);
