import * as types from '../../lib/actionTypes.es6';
import GAlogger, { monitoringEventCategories } from '../../lib/monitoring.es6';

export function addSinglePaymentRequest(payment) {
  return {
    type: types.ADD_SINGLE_PAYMENT_REQUEST,
    payload: payment,
  };
}

export function addSinglePaymentSuccess(payment) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.addPayment);
  return {
    type: types.ADD_SINGLE_PAYMENT_SUCCESS,
    payload: payment,
  };
}

export function addSinglePaymentFail(response) {
  return {
    type: types.ADD_SINGLE_PAYMENT_FAIL,
    payload: response,
  };
}

export function addMultiplePaymentRequest(payment) {
  return {
    type: types.ADD_MULTIPLE_PAYMENT_REQUEST,
    payload: payment,
  };
}

export function addMultiplePaymentSuccess(payment) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.addPayment);
  return {
    type: types.ADD_MULTIPLE_PAYMENT_SUCCESS,
    payload: payment,
  };
}

export function addMultiplePaymentFail(response) {
  return {
    type: types.ADD_MULTIPLE_PAYMENT_FAIL,
    payload: response,
  };
}

export function addPaymentReset() {
  return {
    type: types.ADD_PAYMENT_RESET,
  };
}
