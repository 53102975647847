import React from 'react'
import { useStyle } from "./Style";

import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close';

const SearchResults = () => {
  const classes = useStyle();

  return (
    <>
      <AppBar position="static" className={classes.searchappbar}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={classes.appbarresultsnumber}>
                <h2>Search Results: 46 Customers Found</h2>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={classes.seearchcloseicons}>
                <CloseIcon />
              </div>
            </Grid>
          </Grid>
        </Container>
      </AppBar>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.searchresultstable}>
              <table>
                <tr>
                  <th>National ID</th>
                  <th>Full Name</th>
                  <th>Phone Number</th>
                  <th>Date Of Birth</th>
                  <th>Agent</th>
                  <th>Action</th>
                </tr>
                <tr>
                  <td>6353637474</td>
                  <td>Paul Fooo</td>
                  <td>00254763717073</td>
                  <td>2005-01-01</td>
                  <td><input type='checkbox' checked className={classes.isagent} /></td>
                  <td><Button variant="contained" color="primary" size="large">Select</Button></td>
                </tr>
                <tr>
                  <td>6353637474</td>
                  <td>Paul Fooo</td>
                  <td>00254763717073</td>
                  <td>2005-01-01</td>
                  <td><input type='checkbox' disabled /></td>
                  <td><Button variant="contained" color="primary" size="large">Select</Button></td>
                </tr>
                <tr>
                  <td>6353637474</td>
                  <td>Paul Fooo</td>
                  <td>00254763717073</td>
                  <td>2005-01-01</td>
                  <td><input type='checkbox' disabled /></td>
                  <td><Button variant="contained" color="primary" size="large">Select</Button></td>
                </tr>
                <tr>
                  <td>6353637474</td>
                  <td>Paul Fooo</td>
                  <td>00254763717073</td>
                  <td>2005-01-01</td>
                  <td><input type='checkbox' disabled /></td>
                  <td><Button variant="contained" color="primary" size="large">Select</Button></td>
                </tr>
                <tr>
                  <td>6353637474</td>
                  <td>Paul Fooo</td>
                  <td>00254763717073</td>
                  <td>2005-01-01</td>
                  <td><input type='checkbox' checked className={classes.isagent} /></td>
                  <td><Button variant="contained" color="primary" size="large">Select</Button></td>
                </tr>
                <tr>
                  <td>6353637474</td>
                  <td>Paul Fooo</td>
                  <td>00254763717073</td>
                  <td>2005-01-01</td>
                  <td><input type='checkbox' checked className={classes.isagent} /></td>
                  <td><Button variant="contained" color="primary" size="large">Select</Button></td>
                </tr>
                <tr>
                  <td>6353637474</td>
                  <td>Paul Fooo</td>
                  <td>00254763717073</td>
                  <td>2005-01-01</td>
                  <td><input type='checkbox' checked className={classes.isagent} /></td>
                  <td><Button variant="contained" color="primary" size="large">Select</Button></td>
                </tr>
                <tr>
                  <td>6353637474</td>
                  <td>Paul Fooo</td>
                  <td>00254763717073</td>
                  <td>2005-01-01</td>
                  <td><input type='checkbox' disabled /></td>
                  <td><Button variant="contained" color="primary" size="large">Select</Button></td>
                </tr>
                <tr>
                  <td>6353637474</td>
                  <td>Paul Fooo</td>
                  <td>00254763717073</td>
                  <td>2005-01-01</td>
                  <td><input type='checkbox' disabled /></td>
                  <td><Button variant="contained" color="primary" size="large">Select</Button></td>
                </tr>
              </table>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={2}>
          <Grid xs={12} sm={12} md={12}>
            <div className={classes.paginationsearch}>
              <form>
              <select id="paginationnumbers" name="paginationnumbers">
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
                <option value="200">200</option>
              </select>
              </form>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default SearchResults