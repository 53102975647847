import * as types from '../../../lib/actionTypes.es6';



// template document actions


export function getReportTemplateRequest(type) {
  return {
    type: types.GET_REPORT_TEMPLATES_REQUEST,
    payload: type,
  };
}

export function getReportTemplatesSuccess(response) {
  return {
    type: types.GET_REPORT_TEMPLATES_SUCCESS,
    payload: response,
  };
}

export function getReportTemplatesFailed(error) {
  return {
    type: types.GET_REPORT_TEMPLATES_FAILURE,
    payload: error,
  };
}

export function resetGetReportTemplates(error) {
  return {
    type: types.RESET_GET_REPORT_TEMPLATES,
    payload: error,
  };
}



export function addReportTemplateRequest(payload) {
  return {
    type: types.ADD_REPORT_TEMPLATE_REQUEST,
    payload,
  };
}

export function addReportTemplateSuccess(response) {
  return {
    type: types.ADD_REPORT_TEMPLATE_SUCCESS,
    payload: response,
  };
}

export function addReportTemplateFailed(error) {
  return {
    type: types.ADD_REPORT_TEMPLATE_FAILURE,
    payload: error,
  };
}

export function editReportTemplateRequest(payload) {
  return {
    type: types.EDIT_REPORT_TEMPLATE_REQUEST,
    payload,
  };
}

export function editReportTemplateSuccess(response) {
  return {
    type: types.EDIT_REPORT_TEMPLATE_SUCCESS,
    payload: response,
  };
}

export function editReportTemplateFailed(error) {
  return {
    type: types.EDIT_REPORT_TEMPLATE_FAILURE,
    payload: error,
  };
}

export function setReportTemplateToEdit(response) {
  return {
    type: types.SET_REPORT_TEMPLATE_TO_EDIT,
    payload: response,
  };
}


export function resetEditReportTemplateSuccess() {
  return {
    type: types.RESET_EDIT_REPORT_TEMPLATE_SUCCESS,
  };
}

export function resetEditReportTemplateError() {
  return {
    type: types.RESET_EDIT_REPORT_TEMPLATE_ERROR,
  };
}

export function resetReportTemplateToEdit() {
  return {
    type: types.RESET_REPORT_TEMPLATE_TO_EDIT,
  };
}



export const deleteReportTemplateRequest = (payload) =>{
  return{
    type: types.DELETE_REPORT_TEMPLATE_REQUEST,
    payload
  }
}

export const deleteReportTemplateSuccess =  (response) =>{
  return{
    type: types.DELETE_REPORT_TEMPLATE_SUCCESS,
    payload: response
  }
}

export const deleteReportTemplateFailure = (error) =>{
  return{
    type: types.DELETE_REPORT_TEMPLATE_FAILURE,
    payload: error
  }
}

export const resetDeleteReportTemplateSuccess = () =>{
  return{
    type: types.RESET_DELETE_REPORT_TEMPLATE_SUCCESS
  }
}

export const resetDeleteReportTemplateError = () =>{
  return{
    type: types.RESET_DELETE_REPORT_TEMPLATE_ERROR
  }
}

export function setReportTemplateToDelete(response) {
  return {
    type: types.SET_REPORT_TEMPLATE_TO_DELETE,
    payload: response,
  };
}

export function resetReportTemplateToDelete(response) {
  return {
    type: types.RESET_REPORT_TEMPLATE_TO_DELETE,
  };
}
