import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  loader: false,
  popupLoader: false,
  productPremium: { premiums: [] },
  mobileMoneyTransaction: null,
};

export default function policyListReducer(state = initialState, action) {
  switch (action.type) {
    case types.DELETE_POLICY_REQUEST:
      return { ...state, loader: true, errors: null };

    case types.DELETE_POLICY_SUCCESS:
      return { ...state, loader: false };

    case types.DELETE_POLICY_FAIL:
      return { ...state, loader: false, errors: action.payload };

    case types.GET_POLICY_PRODUCT_REQUEST:
      return { ...state, popupLoader: true };

    case types.GET_POLICY_PRODUCT_SUCCESS:
      return { ...state, productPremium: action.payload, popupLoader: false };

    case types.INITIATE_POLICY_MOBILE_MONEY_PAYMENT_SUCCESS:
      return { ...state, mobileMoneyTransaction: action.payload };

    case types.INITIATE_POLICY_MOBILE_MONEY_PAYMENT_FAILURE:
      return { ...state, errors: action.payload };

    case types.INITIATE_POLICY_MOBILE_MONEY_PAYMENT_REQUEST:
      return { ...state, errors: null };

    case types.RESET_MOBILE_MONEY_INITIATION:
      return { ...state, mobileMoneyTransaction: null, errors: null };

    case types.MAKE_CURRENT_CUSTOMER:
    case types.USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
}
