import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TableInc from '../../../../../shared-ui/TableInc';
import { updateExtendedConfig } from '../../../../../lib/requests.es6';
import { RELATIONSHIP_POOL } from '../../../PoductConstants';

const Annuities = (props) => {
  let dataContent = [];

  if (props.membership_configs && props.membership_configs.content) {
    dataContent = props.membership_configs.content.annuities;
  }

  const [data, setData] = useState(dataContent || []);

  const relationshipOptions = ['Member Only', 'Member & Family', ...Object.values(RELATIONSHIP_POOL)];
  const currencyCode = props.currencyCode;


  const updateMembershipConfigs = async (newData) => {
    const payload = {
      "product_guid": props.productGuid,
      ...props.membership_configs,
      "content": {
        ...props.membership_configs.content,
        annuities: newData
      }
    }

    await updateExtendedConfig(payload)

    window.location.reload(true);
  };

  return (
    <TableInc
      title="Annuity Benefits"
      columns={[
        { title: 'Month', field: 'months', 
        lookup: {
          3: '3 Months',
          6: '6 Months',
          12: '12 Months',
          18: '18 Months',
          24: '24 Months',
        } },

        { title: 'Benefit Amount', field: 'benefit_amount', type: 'numeric',
        render: rowData =>  currencyCode + " " + (rowData.benefit_amount/100).toLocaleString() },

        { title: 'Premium Amount', field: 'premium_amount', type: 'numeric',
        render: rowData =>  currencyCode + " " + (rowData.premium_amount/100).toLocaleString() },


        {
          title: 'Allowed Relationships',
          field: 'allowed_relationships',
          render: rowData => rowData.allowed_relationships.join(', '),
          editComponent: props => (
            <Select
              multiple
              value={props.value || []}
              onChange={e => props.onChange(e.target.value)}
            >
              {relationshipOptions.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          ),
        },
      ]}
      data={data}
      options={{
        addRowPosition: 'first',
        showTitle: true,
        actionsColumnIndex: -1,
        pageSize: data.length,
      }}
      actionsColumnIndex={-1}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            newData.benefit_amount = newData.benefit_amount * 100;
            newData.premium_amount = newData.premium_amount * 100;
            newData.uuid = uuidv4()
            const newDataArray = [...data, newData];
            setData(newDataArray);
            updateMembershipConfigs(newDataArray);
            resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            newData.benefit_amount = newData.benefit_amount * 100;
            newData.premium_amount = newData.premium_amount * 100;
            dataUpdate[index] = newData;
            setData(dataUpdate);
            updateMembershipConfigs(dataUpdate);
            resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            const dataDelete = [...data];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);
            setData(dataDelete);
            updateMembershipConfigs(dataDelete);
            resolve();
          }),
      }}
    />
  );
};

export default Annuities;
