import React, { useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import AlertDialog from '../../../../components/AlertDialog/index.es6';
import ButtonInc from '../../../../shared-ui/ButtonInc';
import { reloadPage } from '../../../../lib/utils.es6';
const DeleteGuide = (props) => {

  if (!props.guideToDelete) {
    return ""
  }

  const [showWarning, setShowWarning] = useState(false);

  const cancel = () => {
    props.resetGuideToDelete()
  }
  const deleteGuide = () => {
    setShowWarning(true)
    props.deleteGuide(props.guideToDelete);
  }
  const handleGuideDeletedSuccessfully = () => {
    props.resetGuideToDelete()
    reloadPage()
  }
  const handleDeletedError = () => {
    props.resetGuideToDelete()

  }
  return (
    <>
      <AlertDialog
        custom
        show={props.showDeleteGuideProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Deleting guide...</h2>
      </AlertDialog>

      <AlertDialog
        warning
        show={!showWarning && props.guideToDelete}
        size="sm"
        title={'Are you sure you want to delete this guide? '}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { cancel() }}
        onCancel={() => { deleteGuide() }}
        confirmBtnText={'Cancel'}
        showCancel={true}
        showConfirm={true}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
      </AlertDialog>
      <AlertDialog
        success
        show={props.showDeleteGuideSuccessAlert}
        size="sm"
        title={'guide deleted'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleGuideDeletedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
        <div >
          <ButtonInc onClick={() => { handleGuideDeletedSuccessfully() }} style={{ background: 'green', }}>OK</ButtonInc>
        </div>
      </AlertDialog>
      <AlertDialog
        show={props.showDeleteGuideErrorAlert}
        danger
        title={'Error deleting guide'}
        onConfirm={() => handleDeletedError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >

        {props.deleteGuideError
          ? props.deleteGuideError.message
          : ''}

      </AlertDialog>
    </>
  )
}

export default DeleteGuide