import { take, call, put } from 'redux-saga/effects';
import * as types from '../../lib/actionTypes.es6';
import { getPartners, createPartner, getPartner } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

export function* getAllPartnersRequestWatcher() {
  while (yield take(types.VIEW_ALL_PARTNERS_REQUEST)) {
    try {
      const response = yield call(getPartners);
      yield put(actions.getAllPartnersSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getAllPartnersFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* createPartnerRequestWatcher() {
  for (;;) {
    const request = yield take(types.CREATE_PARTNER_REQUEST);
    try {
      const response = yield call(createPartner, request.payload);
      yield put(actions.createPartnerSuccess(response.data));
      yield put(actions.getAllPartnersRequest());
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.createPartnerFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* getPartnerRequestWatcher() {
  for (;;) {
    const request = yield take(types.VIEW_PARTNER_REQUEST);
    try {
      const response = yield call(getPartner, request.payload);
      yield put(actions.getPartnerSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getPartnerFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
