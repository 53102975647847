import * as React from "react";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import AlertDialog from "../AlertDialog/index.es6";


export default function DeleteBankDetails(props) {

  if (!props.bankDetails) {
    return ""
  }

  const [showWarning, setShowWarning ] = React.useState(false);


  const deleteBankDetails = ()=>{
    setShowWarning(true) 
    props.deleteBankDetails(props.bankDetails)
  }

  const cancel = ()=>{
    props.resetBankDetailsToDelete()
  }


  const handleBankDetailsDeletedSuccessfully = () => {
    props.resetDeleteBankDetailsSuccessAlert()
    props.resetBankDetailsToDelete()
  }

  const handleBankDetailsDeleteError = () => {
    props.resetDeleteBankDetailsErrorAlert()
  }

  return (
    <React.Fragment>
      <AlertDialog
        custom
        show={props.showDeleteBankDetailsProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Deleting bankDetails</h2>
      </AlertDialog>


      <AlertDialog
        warning
        show={!showWarning && props.bankDetails}
        size="sm"
        title={'Are you sure you want to delete'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => {cancel() }}
        onCancel={() => {deleteBankDetails()}}
        confirmBtnText={'Cancel'}
        showCancel={true}
        showConfirm={true}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
      </AlertDialog>

      <AlertDialog
        success
        show={props.showDeleteBankDetailsSuccessAlert}
        size="sm"
        title={'BankDetails deleted'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleBankDetailsDeletedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => { handleBankDetailsDeletedSuccessfully() }} style={{ background: 'green', }}>Ok</Button>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showDeleteBankDetailsErrorAlert}
        danger
        title={'Error deleting bankDetails'}
        onConfirm={() => handleBankDetailsDeleteError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.editBankDetailsError ? props.editBankDetailsError.message : ''}
      </AlertDialog>






    </React.Fragment>

  );



}
