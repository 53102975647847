import React from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { hashHistory } from 'react-router';
import LocalizedStrings from "react-localization";
import { localisedText } from "../../lib/localisation.es6";
import * as actions from "./actions.es6";
import { updateCurrentLanguage } from "../../lib/languageUtils.es6";
import ProductsHeader from "../NewProductList/components/ProductsHeader/ProductsHeader";
import SkeletonWrapper from "../NewProductList/components/SkeletonWrapper/SkeletonWrapper";
import CountriesTable from "./CountriesTable/CountriesTable";
import DeleteCountry from "./components/DeleteCountry";
import EditCountry from "./components/EditCountry";
import AddCountry from "./components/AddCountry";

export class ViewCountries extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labels: new LocalizedStrings(localisedText),
      openAddCountryDialog: false,

    };
  }

  componentWillMount() {
    this.props.dispatch(actions.getCountriesRequest());
    this.updateLanguage();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'countries',
      this.state.labels
    );
  }

  closeAddCountryDialog() {
    this.setState({ openAddCountryDialog: false })
  }

  addCountry(benefit) {
    this.props.dispatch(actions.createCountryRequest(benefit))
  }

  refreshProduct(){
    this.props.dispatch(actions.getCountriesRequest())
  }

  editCountry(country) {
    this.props.dispatch(actions.editCountryRequest(country))
  }

  setCountryToEdit(country) {
    this.props.dispatch(actions.setCountryToEdit(country))
  }

  setCountryToDelete(country) {
    this.props.dispatch(actions.setCountryToDelete(country))
  }
  resetCountryToEdit(country) {
    this.props.dispatch(actions.resetCountryToEdit(country))
  }

  resetCountryToDelete(country) {
    this.props.dispatch(actions.resetCountryToDelete(country))
  }

  deleteCountry(country) {
    this.props.dispatch(actions.hardDeleteCountryRequest(country.code))
  }

  render() {
    console.log(window.location.href);
    console.log(this.props.countriesData);
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg" style={{ marginTop: "2%" }}>
          <SkeletonWrapper loading={this.props.countriesData.loader} />
          {
            !this.props.countriesData.loader &&
            <>
              <ProductsHeader
                title={"Countries"}
                showAddProductDialog={this.state.openAddCountryDialog}
                showAddButton={true}
                onAddButtonClick={() => { this.setState({ openAddCountryDialog: true }) }}
                showEditButton={false}
                onEditButtonClick={() => { }}
              />
              <CountriesTable
                hashHistory={hashHistory}
                countries={this.props.countriesData.countries}
                displayEditButton={true}
                displayDeleteButton={true}
                hardDeleteCountry={this.setCountryToDelete.bind(this)}
                setCountryToEdit={this.setCountryToEdit.bind(this)}
              />

              <AddCountry
                openAddCountryDialog={this.state.openAddCountryDialog}
                closeAddCountryDialog={this.closeAddCountryDialog.bind(this)}
                addCountry={this.addCountry.bind(this)}
                showAddCountryProgressAlert={this.props.countriesData.createCountryLoader}
                showAddCountrySuccessAlert={this.props.countriesData.createCountrySuccess}
                showAddCountryErrorAlert={!!this.props.countriesData.createCountryError}
                createCountryError={this.props.countriesData.createCountryError}
                resetAddCountrySuccessAlert={() => { 
                  this.props.dispatch(actions.resetCreateCountrySuccess())
                  this.props.dispatch(actions.getCountriesRequest())
                 }}
                resetAddCountryErrorAlert={() => { this.props.dispatch(actions.resetCreateCountryError()) }}
                createdCountrys={this.props.countriesData.createdCountrys}
                product={this.props.countriesData.currentProduct}
                refreshProduct={this.refreshProduct.bind(this)}
              />
              <EditCountry
                country={this.props.countriesData.countryToEdit}
                editCountry={this.editCountry.bind(this)}
                resetCountryToEdit={this.resetCountryToEdit.bind(this)}

                showEditCountryProgressAlert={this.props.countriesData.editCountryLoader}
                showEditCountrySuccessAlert={this.props.countriesData.editCountrySuccess}
                showEditCountryErrorAlert={!!this.props.countriesData.editCountryError}
                editCountryError={this.props.countriesData.editCountryError}

                resetEditCountrySuccessAlert={() => { 
                  this.props.dispatch(actions.resetEditCountrySuccess())
                  this.props.dispatch(actions.getCountriesRequest())
                 }}
                resetEditCountryErrorAlert={() => { this.props.dispatch(actions.resetEditCountryError()) }}
              />

              <DeleteCountry
                country={this.props.countriesData.countryToDelete}
                deleteCountry={this.deleteCountry.bind(this)}
                resetCountryToDelete={this.resetCountryToDelete.bind(this)}

                showDeleteCountryProgressAlert={this.props.countriesData.hardDeleteCountryLoader}
                showDeleteCountrySuccessAlert={this.props.countriesData.hardDeleteCountrySuccess}
                showDeleteCountryErrorAlert={!!this.props.countriesData.hardDeleteCountryError}
                deleteCountryError={this.props.countriesData.hardDeleteCountryError}

                resetDeleteCountrySuccessAlert={() => { 
                  this.props.dispatch(actions.resetHardDeleteCountrySuccess())
                  this.props.dispatch(actions.getCountriesRequest())
                 }}
                resetDeleteCountryErrorAlert={() => { this.props.dispatch(actions.resetHardDeleteCountryError()) }}
              />

              <br />
              <br />
            </>
          }

        </Container>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  countriesData: state.country,
  globalData: state.global,
}))(ViewCountries);
