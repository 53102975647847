import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({

  selectbenefit: {
    "& span": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontSize: "10px",
      lineHeight: "25px",
      color: "#000000",
      textAlign: "inherit"
    }
  },
  summaryWrapper: {
    background: "#FFFFFF",
    border: "1px solid #A7A7A7",
    borderRadius: "8px",
    textAlign: "center",
    marginTop: "5px",
    padding: "15px 0",
    margin: "0",
    "& h4": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#000000"
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontSize: "12px",
      color: "#000000",
      lineHeight: "16px",
      letterSpacing: "0.005em"

    }
  },
  beneficiaryname: {
    borderRight: "1px solid #A7A7A7"
  },
  benefitbalance: {
    borderRight: "1px solid #A7A7A7"
  },
  completeLouSectionTitle: {
    "& h4": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#000000",
      marginTop: "20px"
    }
  },
  autocomplete: {
    "& input": {
      background: "transparent",
      borderRadius: "8px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#000000",
    },
    "& label": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      color: "#000000",
      transformOrigin: "bottom",
    }
  },
  addhospital: {
    "& ul": {
      display: "flex",
      listStyle: "none",
      padding: 0,
      marginTop: "10px",
      cursor: "pointer",
      "& li": {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: "12px",
        color: "#000000",
        "& svg": {
          fontSize: "16px"
        }
      }
    }
  },
  checkconditionlevel: {
   
        "& span": {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          fontWeight: 300,
          fontSize: "12px",
          color: "#000000",
        },
     
  },
  calculationswrapper: {
    height: "58px",
    background: "#F5F5F5",
    borderRadius: "8px",
    "& p":{
      marginTop: "15px"
    }
  }
}));