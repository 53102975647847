import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
// import ButtonInc from '../../../../../shared-ui/ButtonInc';
import { formatDateLocale } from '../../../../../lib/utils.es6';

const Viewpolicy = (props) => {
  const classes = useStyle();
  if (props.buyPolicyData) {
    return (
      <>
        <Grid container spacing={2} className={classes.viewpolicywrapper}>
          <Grid item xs={12} sm={12} md={12} >
            <table className={classes.policywrap}>
              <thead className={classes.policyhead}>
                <tr>
                  <th colspan="2" >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} sm={6}>
                        <th style={{ padding: "4px 0 0 8px"}}>Policy Information</th>
                      </Grid>
                      <Grid item xs={12} md={6} sm={6}>
                        {/* <ButtonInc style={{
                              float: "right",
                              marginRight: "40px",
                        }}>Download Policy Document</ButtonInc> */}
                      </Grid>
                    </Grid>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Beneficiary Name</td>
                  <td>{props.buyPolicyData.beneficiary_name || "N/A"}</td>
                </tr>
                <tr>
                  <td>Policy Number</td>
                  <td>{props.buyPolicyData.policy_number || "N/A"}</td>
                </tr>
                <tr>
                  <td>Phone Number</td>
                  <td>{props.buyPolicyData.beneficiary_msisdn || "N/A"}</td>
                </tr>
                <tr>
                  <td>Start Date</td>
                  <td>{formatDateLocale(props.buyPolicyData.start_date )|| "N/A"}</td>
                </tr>
                <tr>
                  <td>End Date</td>
                  <td>{formatDateLocale(props.buyPolicyData.end_date) || "N/A"}</td>
                </tr>
                <tr>
                  <td>Due Date</td>
                  <td>{formatDateLocale(props.buyPolicyData.due_date) || "N/A"}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{props.buyPolicyData.status || "Unpaid"}</td>
                </tr>
                <tr>
                  <td>Active</td>
                  <td>{props.buyPolicyData.active === "true" ? "Active" : "Inactive"}</td>
                </tr>
                <tr>
                  <td>Debit</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Created At</td>
                  <td>{formatDateLocale(props.buyPolicyData.updated_at)}</td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
      </>
    )
  } else {
    return (
      <>
        <Grid container spacing={2} className={classes.viewpolicywrapper}>
          <Grid item xs={12} sm={6} md={6} >
            <p>No Policy found</p>
          </Grid>
        </Grid>
      </>
    )

  }

}

export default Viewpolicy