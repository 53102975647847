import * as types from '../../../lib/actionTypes.es6';

export function groupCertificateUploadCallbackRequest(payload) {
  return {
    type: types.GROUP_CERTIFICATE_UPLOAD_CALLBACK,
    payload,
  };
}

export function groupExcelUploadCallbackRequest(payload) {
  return {
    type: types.GROUP_EXCEL_UPLOAD_CALLBACK,
    payload,
  };
}


export function resetGroupPreRegistraion() {
  return {
    type: types.RESET_GROUP_PRE_REGISTRAION,
  };
}

export function resetGroupRegistraion() {
  return {
    type: types.RESET_GROUP_REGISTRAION,
  };
}

export function resetActualQuote() {
  return {
    type: types.RESET_ACTUAL_QUOTE,
  };
}

export function preRegisterGroupRequest(payload) {
  return {
    type: types.PRE_REGISTER_GROUP_REQUEST,
    payload,
  };
}

export function preRegisterGroupSuccess(response) {
  return {
    type: types.PRE_REGISTER_GROUP_SUCCESS,
    payload: response,
  };
}

export function preRegisterGroupFailed(error) {
  return {
    type: types.PRE_REGISTER_GROUP_FAILURE,
    payload: error,
  };
}

export function registerGroupRequest(payload) {
  return {
    type: types.REGISTER_GROUP_REQUEST,
    payload,
  };
}

export function registerGroupSuccess(response) {
  return {
    type: types.REGISTER_GROUP_SUCCESS,
    payload: response,
  };
}

export function registerGroupFailed(error) {
  return {
    type: types.REGISTER_GROUP_FAILURE,
    payload: error,
  };
}

export function getActualGroupQuoteRequest(payload) {  
  return {
    type: types.GET_ACTUAL_GROUP_QUOTE_REQUEST,
    payload,
  };
}

export function getActualGroupQuoteSuccess(response) {
  return {
    type: types.GET_ACTUAL_GROUP_QUOTE_SUCCESS,
    payload: response,
  };
}

export function getActualGroupQuoteFailed(error) {
  return {
    type: types.GET_ACTUAL_GROUP_QUOTE_FAILURE,
    payload: error,
  };
}

export function updateGroupRequest(payload) {  
  return {
    type: types.UPDATE_GROUP_REQUEST,
    payload,
  };
}

export function updateGroupSuccess(response) {
  return {
    type: types.UPDATE_GROUP_SUCCESS,
    payload: response,
  };
}

export function updateGroupFailed(error) {
  return {
    type: types.UPDATE_GROUP_FAILURE,
    payload: error,
  };
}


export function getGroupRequest(payload) {  
  return {
    type: types.GET_GROUP_REQUEST,
    payload,
  };
}

export function getGroupSuccess(response) {
  return {
    type: types.GET_GROUP_SUCCESS,
    payload: response,
  };
}

export function getGroupFailed(error) {
  return {
    type: types.GET_GROUP_FAILURE,
    payload: error,
  };
}