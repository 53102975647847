import React, { Component } from 'react'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import './BenefitsTable.css'
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { Stack } from '@mui/material';
import { PRIMARY_THEME_COLOR_SHADE_1 } from '../../../../lib/constants';

export default class VariableIndemnityTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      benefits: []
    };
  }

  componentWillMount() {
    this.setState({ benefits: this.props.variableBenefits })
  }

  componentDidUpdate(){
    const propsBenefitsLength = this.props.variableBenefits.length
    const stateBenefitsLength = this.state.benefits.length
    if(propsBenefitsLength!== stateBenefitsLength){
      this.setState({ benefits: this.props.variableBenefits })
    }
  }

  toggleCollapse = (index) => {
    const currentBenefits = this.state.benefits
    currentBenefits[index]['rowCollapsed'] = !currentBenefits[index]['rowCollapsed']
    this.setState({ benefits: currentBenefits })
  }

  setBenefitToEdit = (benefit) =>{
    const payload = benefit
    payload['benefitType'] = 'variable';
    this.props.setBenefitToEdit(payload)
  }

  render() {
    if (this.props.variableBenefits && this.props.variableBenefits.length > 0) {
      return (
        <>
          <div style={{ marginTop: "2%", }}>
            <h3 style={{ textAlign: 'left' }}>Variable Indemity benefits</h3>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{ color: 'red' }}>
                  <TableRow >
                    <TableCell className="table-cell-header"></TableCell>
                    <TableCell className="table-cell-header">name</TableCell>
                    <TableCell className="table-cell-header">amount</TableCell>
                    <TableCell className="table-cell-header">max_period</TableCell>
                    <TableCell className="table-cell-header" align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.benefits.map((benefit, index) => {
                    return (
                      <>
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                          <TableCell>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => this.toggleCollapse(index)}
                            >
                              {benefit.rowCollapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                          </TableCell>
                          <TableCell component="th" scope="row"> {benefit.name}</TableCell>
                          <TableCell align="left"> {benefit.amount}</TableCell>
                          <TableCell align="left"> {benefit.maximum_period}</TableCell>
                          <TableCell align="right">
                            <Stack direction="row" spacing={1} justifyContent="flex-end" >
                              <IconButton onClick={this.setBenefitToEdit.bind(this, benefit)} >
                                <EditIcon
                                  style={{ color: PRIMARY_THEME_COLOR_SHADE_1, fontWeight: 'bolder', display: this.props.displayEditButton ? 'inline-block' : 'none' }}
                                />
                              </IconButton>
                              <IconButton onClick={() => { this.props.hardDeleteBenefit({ benefit, benefitType: 'variable_indemnity' }) }} >
                                <DeleteForeverIcon
                                  style={{ color: 'red', fontWeight: 'bolder', marginRight: "2%", display: this.props.displayDeleteButton ? 'inline-block' : 'none' }}
                                />
                              </IconButton>
                            </Stack>
                          </TableCell>

                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={benefit.rowCollapsed} timeout="auto" unmountOnExit>
                              <Box sx={{ margin: 1 }}>
                                <Table size="small" aria-label="purchases">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="table-cell-header-collapsible">min_period</TableCell>
                                      <TableCell className="table-cell-header-collapsible">amount_granularity</TableCell>
                                      <TableCell className="table-cell-header-collapsible">max_period_granularity</TableCell>
                                      <TableCell className="table-cell-header-collapsible">min_period_granularity</TableCell>
                                      <TableCell className="table-cell-header-collapsible">max_period_applicable_granularity</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell align="left"> {benefit.minimum_period}</TableCell>
                                      <TableCell align="left"> {benefit.amount_granularity}</TableCell>
                                      <TableCell align="left"> {benefit.maximum_period_granularity}</TableCell>
                                      <TableCell align="left"> {benefit.minimum_period_granularity}</TableCell>
                                      <TableCell align="left"> {benefit.maximum_period_applicable_granularity}</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      );
    }
    else {
      return ""
    }
  }
}
