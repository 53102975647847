import { take, call, put } from 'redux-saga/effects';
import { getPoliciesReportBlob } from '../../lib/requests.es6';
import * as types from '../../lib/actionTypes.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

export function* reportsPoliciesRequestWatcher() {
  for (;;) {
    const reportAction = yield take(types.POLICIES_CREATED_REPORT_REQUEST);
    try {
      const response = yield call(getPoliciesReportBlob, reportAction.payload);
      yield put(actions.policiesCreatedReportRequestSuccess(response.data));
    } catch (e) {
      yield put(
        actions.policiesCreatedReportRequestError(
          'An error occurred, this could be due to invalid data'
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
