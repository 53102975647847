import React from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { hashHistory } from 'react-router';

import LocalizedStrings from "react-localization";
import { localisedText } from "../../lib/localisation.es6";
import * as actions from "./actions.es6";
import { getPartnerGuid, getPartners, hasAuthourity } from "../../lib/access.es6";
import { updateCurrentLanguage } from "../../lib/languageUtils.es6";
import PartnersTable from "./components/PartnersTable/PartnersTable";
import ProductsHeader from "../NewProductList/components/ProductsHeader/ProductsHeader";
import SkeletonWrapper from "../NewProductList/components/SkeletonWrapper/SkeletonWrapper";
import AddPartner from "./components/AddPartner";
import { setCurrentPartner } from "../Main/actions.es6";
import AlertDialog from "../../components/AlertDialog/index.es6";
import { reloadPage } from "../../lib/utils.es6";

export class Partners extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labels: new LocalizedStrings(localisedText),
      generalPartner: getPartnerGuid(),
      openViewDialog: false,
      openAddPartnerDialog: false,
      openViewPartnersDialog: false,
      noAccessToPartnerAlert: false,
    };
  }

  componentWillMount() {
    this.updateLanguage();

    if (hasAuthourity('VIEW_ALL_PARTNERS')) {
      this.props.dispatch(actions.getAllPartnersRequest());
    }

    if (hasAuthourity('CREATE_PARTNER')) {
      if (
        !this.props.countryData.countries ||
        !this.props.countryData.countries.length
      ) {
        this.props.dispatch(actions.getCountriesRequest());
      }

      if (
        !this.props.languagesData.languages ||
        !this.props.languagesData.languages.length
      ) {
        this.props.dispatch(actions.getLanguagesRequest());
      }
    }
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'Partners',
      this.state.labels
    );
  }


  closeAddPartnerDialog() {
    this.setState({ openAddPartnerDialog: false })
  }

  addPartner(partner) {
    console.log('create parnter: ', partner);
    this.props.dispatch(actions.createPartnerRequest(partner));
  }
  refreshPartners() {
    this.props.dispatch(actions.getAllPartnersRequest());
  }

  hasAccessToPartner(partnerGuid) {
    const partners = getPartners();
    for (let partner of partners) {
      if (partner.guid === partnerGuid) return true;
    }
    return false;
  }

  viewPartner(partner) {
    const hasAccessToPartner = this.hasAccessToPartner(partner.guid)
    if (hasAccessToPartner) {
      this.props.dispatch(setCurrentPartner(partner));
      hashHistory.push(`admin/partners/view/partner`);
      reloadPage();
    } else {
      this.setState({ noAccessToPartnerAlert: true })
      console.log('you have no access to partner alert');
    }
  }

  render() {


    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg" style={{ marginTop: "2%" }}>
          <SkeletonWrapper loading={this.props.partnersData.loader || this.props.countryData.loader} />
          {
            !this.props.partnersData.loader && !this.props.countryData.loader &&
            <>
              <ProductsHeader
                title={"Partners"}
                showAddProductDialog={this.state.openAddPartnerDialog}
                showAddButton={true}
                onAddButtonClick={() => { this.setState({ openAddPartnerDialog: true }) }}
                showEditButton={false}
                onEditButtonClick={() => { }}
              />
              <PartnersTable
                hashHistory={hashHistory}
                partners={this.props.partnersData.partners}
                viewPartner={this.viewPartner.bind(this)}
              />

              <AlertDialog
                warning
                show={this.state.noAccessToPartnerAlert}
                title={'You have no access to this partner'}
                onConfirm={() => this.setState({ noAccessToPartnerAlert: false })}
                confirmBtnText={'ok'}
                showCancel={false}
                confirmBtnCssClass
                style={{ marginTop: '0', top: '30vh' }}
                size="sm"
              >
                <p>instructions to get access to partner here</p>
              </AlertDialog>
              <br />
              <br />
            </>
          }

          <AddPartner
            openAddPartnerDialog={this.state.openAddPartnerDialog}
            closeAddPartnerDialog={this.closeAddPartnerDialog.bind(this)}
            addPartner={this.addPartner.bind(this)}
            showAddPartnerProgressAlert={this.props.partnersData.createPartnerLoader}
            showAddPartnerSuccessAlert={this.props.partnersData.createPartnerSuccess}
            showAddPartnerErrorAlert={!!this.props.partnersData.createPartnerError}
            createPartnerError={this.props.partnersData.createPartnerError}
            resetAddPartnerSuccessAlert={() => { this.props.dispatch(actions.resetCreatePartnerSuccess()) }}
            resetAddPartnerErrorAlert={() => { this.props.dispatch(actions.resetCreatePartnerError()) }}
            createdPartners={this.props.partnersData.createdPartners}
            refreshPartners={this.refreshPartners.bind(this)}
            countries={this.props.countryData.countries}
            languages={this.props.languagesData.languages}
          />
        </Container>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  partnersData: state.partners,
  countryData: state.country,
  languagesData: state.languages,
  globalData: state.global,
}))(Partners);
