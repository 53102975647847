import * as types from "../../../../lib/actionTypes.es6";



export function addBulkPolicyOnboardingRequest(payload) {
  return {
    type: types.ADD_BULK_POLICY_ONBOARDING_REQUEST,
    payload,
  };
}

export function addBulkPolicyOnboardingSuccess(response) {
  return {
    type: types.ADD_BULK_POLICY_ONBOARDING_SUCCESS,
    payload: response,
  };
}

export function addBulkPolicyOnboardingFailed(error) {
  return {
    type: types.ADD_BULK_POLICY_ONBOARDING_FAILURE,
    payload: error,
  };
}



export function saveBulkPolicyOnboadingRequest(payload) {
  return {
    type: types.SAVE_BULK_POLICY_ONBOARDING_REQUEST,
    payload,
  };
}

export function saveBulkPolicyOnboardingSuccess(response) {
  return {
    type: types.SAVE_BULK_POLICY_ONBOARDING_SUCCESS,
    payload: response,
  };
}

export function saveBulkPolicyOnboardingFailed(error) {
  return {
    type: types.SAVE_BULK_POLICY_ONBOARDING_FAILURE,
    payload: error,
  };
}



export function getBulkPolicyOnboardingBatchesRequest(payload){
  return{
    type: types.GET_BULK_POLICY_ONBOARDING_BATCHES_REQUEST,
    payload: payload
  }

}

export function getBulkPolicyOnboardingBatchesSuccess(payload){
  return{
    type: types.GET_BULK_POLICY_ONBOARDING_BATCHES_SUCCESS,
    payload: payload
  }

}


export function getBulkPolicyOnboardingBatchesFailure(error){
  return{
    type: types.GET_BULK_POLICY_ONBOARDING_BATCHES_FAILURE,
  }
}

export function getFailedBatchesRequest(batch_guid){
  return{
    type: types.GET_FAILED_BATCHES_REQUEST,
    payload: batch_guid
  }

}

export function getFailedBatchesSuccess(payload){
  return{
    type: types.GET_FAILED_BATCHES_SUCCESS,
    payload: payload
  }

}


export function getFailedBatchesFailure(error){
  return{
    type: types.GET_BATCHES_FAILURE,
  }
}