import React, { useState } from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FilterClaims from '../Claims/FilterClaims';
import { ClaimStatusEnum } from '../../enum';
import { getPartnerGuid } from '../../../../../lib/access.es6';
import { getQueryParams, updateUrlWithStatus } from '../../helpers';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ClaimsWrapper = (props) => {
  const classes = useStyle();
  const [value, setValue] = useState(getQueryParams(props.queryParamsStatus));


  const getClaimStatus = (tabValue) => {
    if (props.isFinanceUser) {
      return ClaimStatusEnum.awaiting_payment
    }
    switch (tabValue) {
      case 0:
        return ClaimStatusEnum.docs_pending
      case 1:
        return ClaimStatusEnum.awaiting_confirmation
      case 2:
        return ClaimStatusEnum.Rejected_Pending
      case 3:
        return ClaimStatusEnum.verification
      case 4:
        return ClaimStatusEnum.Approved
      default:
    }
  }
  const handleChange = (event, newValue) => {

    const status = getClaimStatus(newValue);
    props.getClaimsByStatus(status)
    setValue(newValue);
    updateUrlWithStatus(newValue, props.type);
    props.getClaimsByStatus(status, () => {
      props.getClaimsFiltered();
    });
  };

  const getClaims = (payload) => {
    payload['partner'] = getPartnerGuid();
    payload['statuses'] = getClaimStatus(value)
    props.getClaimsFiltered(payload)
  }



  return (
    <>
      <section className={classes.claimswrapper}>

        {
          props.isFinanceUser ?
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="claims dashboards" variant="scrollable"
                    scrollButtons="auto" className={classes.tabs}>
                    <Tab label="Awaiting Payments" {...a11yProps(0)} />
                  </Tabs>
                </Box>


                <TabPanel value={value} index={0}>
                  <FilterClaims
                    pagingData={props.pagingData}
                    getClaims={(p) => getClaims(p)}
                    claimsData={props.claimsData}
                    loading={props.loading}
                    hasBulkApprove={props.hasBulkApprove}
                    type={props.type}

                    handleBulkApproval={props.handleBulkApproval}
                    hasAlerts={props.hasAlerts}
                    showBulkApproveProgressAlert={props.showBulkApproveProgressAlert}
                    showBulkApproveSuccessAlert={props.showBulkApproveSuccessAlert}
                    showBulkApproveErrorAlert={props.showBulkApproveErrorAlert}
                    bulkApproveError={props.bulkApproveError}

                    resetBulkApproveSuccessAlert={props.resetBulkApproveSuccessAlert}
                    resetBulkApproveErrorAlert={props.resetBulkApproveErrorAlert}
                    status={"approved"}

                    getClaimByHosipital={props.getClaimByHosipital}
                    getClaimsByStartDate={props.getClaimsByStartDate}
                    getClaimsByEndDate={props.getClaimsByEndDate}
                    getClaimsFiltered={props.getClaimsFiltered}
                    getClaimsByPage={props.getClaimsByPage}
                    getClaimsBySize={props.getClaimsBySize}

                  />
                </TabPanel>


              </Grid>
            </Grid>

            :
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="claims dashboards" variant="scrollable"
                    scrollButtons="auto" className={classes.tabs}>
                    <Tab label="Docs Pending" {...a11yProps(0)} disabled={props.loading} />
                    <Tab label="Awaiting Confirmation" {...a11yProps(1)} disabled={props.loading} />
                    <Tab label="Rejected Pending" {...a11yProps(2)} disabled={props.loading} />
                    <Tab label="Approvals" {...a11yProps(3)} disabled={props.loading} />
                    <Tab label="Awaiting Payments" {...a11yProps(4)} disabled={props.loading} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0} >
                  <FilterClaims
                    pagingData={props.pagingData}
                    getClaims={getClaims} type={props.type} claimsData={props.claimsData} loading={props.loading} getClaimByHosipital={props.getClaimByHosipital}
                    getClaimsFiltered={props.getClaimsFiltered}
                    getClaimsByStartDate={props.getClaimsByStartDate}
                    getClaimsByEndDate={props.getClaimsByEndDate}
                    filteredClaims={props.filteredClaims}
                    getClaimsBySize={props.getClaimsBySize}
                    getClaimsByPage={props.getClaimsByPage}
                   
                  />
                </TabPanel>
                <TabPanel value={value} index={1} >
                  <FilterClaims
                    pagingData={props.pagingData}
                    getClaims={getClaims} type={props.type} claimsData={props.claimsData} loading={props.loading} getClaimByHosipital={props.getClaimByHosipital}
                    getClaimsByStartDate={props.getClaimsByStartDate}
                    getClaimsByEndDate={props.getClaimsByEndDate}
                    getClaimsByPage={props.getClaimsByPage}
                    getClaimsBySize={props.getClaimsBySize}
                    getClaimsFiltered={props.getClaimsFiltered}
                    
                  />
                </TabPanel>

                <TabPanel value={value} index={2} >
                  <FilterClaims
                    pagingData={props.pagingData}
                    getClaims={getClaims} type={props.type} claimsData={props.claimsData} loading={props.loading} getClaimByHosipital={props.getClaimByHosipital}
                    getClaimsByStartDate={props.getClaimsByStartDate}
                    getClaimsByEndDate={props.getClaimsByEndDate}
                    getClaimsByPage={props.getClaimsByPage}
                    getClaimsBySize={props.getClaimsBySize}
                    getClaimsFiltered={props.getClaimsFiltered}
                   
                  />
                </TabPanel>

                <TabPanel value={value} index={3}>
                  <FilterClaims
                    pagingData={props.pagingData}
                    getClaims={getClaims}
                    type={props.type}
                    claimsData={props.claimsData}
                    loading={props.loading}
                    hasBulkApprove={props.hasBulkApprove}

                    handleBulkApproval={props.handleBulkApproval}
                    hasAlerts={props.hasAlerts}
                    showBulkApproveProgressAlert={props.showBulkApproveProgressAlert}
                    showBulkApproveSuccessAlert={props.showBulkApproveSuccessAlert}
                    showBulkApproveErrorAlert={props.showBulkApproveErrorAlert}
                    bulkApproveError={props.bulkApproveError}

                    resetBulkApproveSuccessAlert={props.resetBulkApproveSuccessAlert}
                    resetBulkApproveErrorAlert={props.resetBulkApproveErrorAlert}
                    status={"decision_pending"}
                    getClaimByHosipital={props.getClaimByHosipital}
                    getClaimsByStartDate={props.getClaimsByStartDate}
                    getClaimsByEndDate={props.getClaimsByEndDate}
                    getClaimsByPage={props.getClaimsByPage}
                    getClaimsBySize={props.getClaimsBySize}
                    getClaimsFiltered={props.getClaimsFiltered}
                    
                  />
                  
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <FilterClaims
                    pagingData={props.pagingData}
                    getClaims={getClaims}
                    claimsData={props.claimsData}
                    loading={props.loading}
                    hasBulkApprove={props.hasBulkApprove}
                    type={props.type}

                    handleBulkApproval={props.handleBulkApproval}
                    hasAlerts={props.hasAlerts}
                    showBulkApproveProgressAlert={props.showBulkApproveProgressAlert}
                    showBulkApproveSuccessAlert={props.showBulkApproveSuccessAlert}
                    showBulkApproveErrorAlert={props.showBulkApproveErrorAlert}
                    bulkApproveError={props.bulkApproveError}

                    resetBulkApproveSuccessAlert={props.resetBulkApproveSuccessAlert}
                    resetBulkApproveErrorAlert={props.resetBulkApproveErrorAlert}
                    status={"approved"}
                    getClaimByHosipital={props.getClaimByHosipital}
                    getClaimsByStartDate={props.getClaimsByStartDate}
                    getClaimsByEndDate={props.getClaimsByEndDate}
                    getClaimsByPage={props.getClaimsByPage}
                    getClaimsBySize={props.getClaimsBySize}
                    getClaimsFiltered={props.getClaimsFiltered}
                   
                  />
                </TabPanel>


              </Grid>
            </Grid>

        }

      </section>
    </>
  )
}

export default ClaimsWrapper