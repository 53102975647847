import React from "react";
import { connect } from "react-redux";
import Payments from "./Components/DisplayPayments/Payments";
import AddPayment from "./Components/AddPayment/AddPayment";
import { addOrangeMissingPaymentRequest, addOrangeMissingPaymentReset, getAllOrangePaymentsRequest,  } from "./Redux/actions";
import { getAllOrangePoliciesRequest, listOrangePaidProductsRequest } from "../Policies/Redux/actions";

class OrangePayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openRegisterCustomerDialog: false,
    }
  }

  componentWillMount() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      this.props.dispatch(getAllOrangePaymentsRequest({ msisdn: currentCustomer.msisdn, guid: currentCustomer.guid }))

    }
    if (currentCustomer) {
      this.props.dispatch(getAllOrangePoliciesRequest({ msisdn: currentCustomer.msisdn, guid: currentCustomer.guid }))
     // this.props.dispatch(getAllOrangeProductsRequest()) 
    }

    if (this.props.orangePolicyData.products.length < 1) {
      this.props.dispatch(listOrangePaidProductsRequest());
    }


  }

	addPayment(payment) {
		const payload = {
			paymentData: payment,
			customerGuid: this.props.customerData.currentCustomer.guid,
		};
		this.props.dispatch(addOrangeMissingPaymentRequest(payload));
	}
	resetAddPayment() {
		this.props.dispatch(addOrangeMissingPaymentReset());
	}

  render() {
    return (
      <>
        <Payments
          labels={this.props.labels}
          loader={this.props.orangePaymentData.get_payments_loader}
          Payments={this.props.orangePaymentData.payments}


        />
        <AddPayment
          labels={this.props.labels}
          openAddPaymentDialog={this.props.openAddPaymentDialog}
          toggleAddPaymentDialog={this.props.toggleAddPaymentDialog}
          policies={this.props.orangePolicyData.policies}

          addPayment={this.addPayment.bind(this)}

          paymentAlert={this.props.orangePaymentData.success_message}
          paymentProgressAlert={this.props.orangePaymentData.add_payment_loader}
          paymentError={this.props.orangePaymentData.payment_error}
          resetPayment={this.resetAddPayment.bind(this)}
        />
      </>
    )
  }
}
export default connect((state) => ({
  customerData: state.currentCustomer,
  orangePaymentData: state.orangePaymentData,
  orangePolicyData: state.orangePoliciesData,
}))(OrangePayments);