import * as React from "react";
import Grid from "@mui/material/Grid";
import CashBackIndemnityForm from "./CashBackIndemnityForm";
import FixedIndemnityForm from "./FixedIndemnityForm";
import VariableIndemnityForm from "./VariableIndemnityForm";
import { CircularProgress } from "@mui/material";
import AlertDialog from "../../../../../../components/AlertDialog/index.es6";
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../../../../lib/constants";

export default function EditBenefits(props) {
  if (!props.benefit) {
    return ""
  }


  const handleBenefitEditedSuccessfully = () => {
    props.resetEditBenefitSuccessAlert();
    props.resetBenefitToEdit();
    
  }


  const handleBenefitEditError = () => {
    props.resetEditBenefitErrorAlert()
  }

  return (
    <React.Fragment>
      <Grid container >
        <Grid item x={12}>
          <AlertDialog
            custom
            show={props.showEditBenefitProgressAlert}
            size="sm"
            style={{ marginTop: '0', top: '30vh' }}
            confirmBtnText={'ok'}
            showCancel={false}
            showConfirm={false}
            cancelBtnText={'cancel'}
            showTitle={false}
            confirmBtnCssClass
          >
            <CircularProgress />
            <h2>Updating benefit</h2>
          </AlertDialog>

          <AlertDialog
            success
            show={props.showEditBenefitSuccessAlert}
            size="sm"
            title={'Benefit updated'}
            style={{ marginTop: '0', top: '30vh' }}
            onConfirm={() => { handleBenefitEditedSuccessfully() }}
            confirmBtnText={'ok'}
            showCancel={false}
            showConfirm={true}
            cancelBtnText={'cancel'}
            confirmBtnCssClass
          >
          </AlertDialog>

          <AlertDialog
            show={props.showEditBenefitErrorAlert}
            danger
            title={'Error updating benefit'}
            onConfirm={() => handleBenefitEditError()}
            confirmBtnText={'Try again'}
            confirmBtnCssClass
            showCancel={false}
            style={{ marginTop: '0', top: '30vh' }}
          >
            {props.createBenefitError ? props.createBenefitError.message : ''}
          </AlertDialog>

        </Grid>
      </Grid>

      <Dialog
        open={props.benefit} fullWidth
        maxWidth='lg'
        onClose={props.resetBenefitToEdit}
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.resetBenefitToEdit}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Benefit
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div style={{ marginTop: "2%" }}>
            <Box sx={{ width: "100%" }}>
              {
                (props.benefit.benefitType === 'cashback') &&
                <CashBackIndemnityForm
                  hardDeleteBenefit={props.hardDeleteBenefit}
                  editBenefit={props.editBenefit}
                  benefit={props.benefit}
                  resetBenefitToEdit={props.resetBenefitToEdit}
                />
              }
              {
                (props.benefit.benefitType === 'variable') &&
                <VariableIndemnityForm
                  hardDeleteBenefit={props.hardDeleteBenefit}
                  editBenefit={props.editBenefit}
                  benefit={props.benefit}
                  resetBenefitToEdit={props.resetBenefitToEdit}
                  currencyCode ={props.currencyCode}
                />
              }
              {
                (props.benefit.benefitType === 'fixed') &&
                <FixedIndemnityForm
                  hardDeleteBenefit={props.hardDeleteBenefit}
                  editBenefit={props.editBenefit}
                  benefit={props.benefit}
                  resetBenefitToEdit={props.resetBenefitToEdit}
                  currencyCode ={props.currencyCode}
                />
              }
            </Box>
          </div>

        </DialogContent>
      </Dialog>
      
    </React.Fragment>
  );
}
