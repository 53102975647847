import * as types from '../../lib/actionTypes.es6';

export const initialState = {
 cashbacks: [],
 loader: false
};

export default function cashbackReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ALL_CASHBACKS_REQUEST:
      return {...state, loader: true};
    case types.GET_ALL_CASHBACKS_SUCCESS:
      return {...state, cashbacks: action.payload, loader: false};
    case types.GET_ALL_CASHBACKS_FAILURE:
      return {...state, loader:false };

    case types.MARK_CASHBACK_AS_PAID_REQUEST:
      return {...state, loader: true};
    case types.MARK_CASHBACK_AS_PAID_SUCCESS:
      return {...state, loader: false};
    case types.MARK_CASHBACK_AS_PAID_FAILURE:
      return {...state, loader:false };

    case types.MARK_CASHBACK_AS_UNPAID_REQUEST:
      return {...state, loader: true};
    case types.MARK_CASHBACK_AS_UNPAID_SUCCESS:
      return {...state, loader: false};
    case types.MARK_CASHBACK_AS_UNPAID_FAILURE:
      return {...state,loader: false};

    default:
      return state;
  }
}
