import React from 'react';
import { connect } from 'react-redux';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import MaterialTable, { MTableToolbar } from 'material-table';
import Loader from 'react-loader';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from "@material-ui/core/Grid";
import LinearProgress from 'material-ui/LinearProgress';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import { getS3BucketSignedUrl } from '../../lib/requests.es6';
import { getPartner, getPartnerGuid } from '../../lib/access.es6';
import {
  uploadLoyaltyDataSuccess,
  uploadLoyaltyDataReset,
  mapColumnsToFieldsRequest,
  getPreviousLoyaltiesStatusRequest,
  setLoyaltiesStartDateRequest, setLoyaltiesEndDateRequest
} from './actions.es6';
import LocalizedStrings from "react-localization";
import { localisedText } from "../../lib/localisation.es6";
import { updateCurrentLanguage } from "../../lib/languageUtils.es6";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import AlertDialog from "../../components/AlertDialog/index.es6";
import CircularProgress from "@material-ui/core/CircularProgress";
import { PRIMARY_THEME_COLOR_SHADE_1, PRIMARY_THEME_COLOR_SHADE_2, SECONDARY_THEME_COLOR_SHADE_1, SECONDARY_THEME_COLOR_SHADE_2 } from '../../lib/constants';

const btnStyle = {
  margin: '2%',
  secondary: {
    backgroundColor: SECONDARY_THEME_COLOR_SHADE_1,
    color: 'white',
    margin: '2px',
    height: '50px',
    padding: '0 30px',
  },
  danger: {
    backgroundColor: 'orangered',
    color: 'white',
    margin: '2px',
    height: '50px',
    padding: '0 30px',
  },
};

export class LoyaltyEligibility extends React.Component {
  constructor(props) {
    super(props);

    const partner = getPartner();
    const loyaltyFields = partner.loyalty_fields
      ? String(partner.loyalty_fields)
          .replaceAll('[', '')
          .replaceAll(']', '')
          .replaceAll(' ', '')
          .split(',')
      : [];
    const mappings = {};
    for (var i = 0; i < loyaltyFields.length; i++) {
      mappings[loyaltyFields[i]] = i + 1;
    }

    this.state = {
      labels: new LocalizedStrings(localisedText),
      completed: 0,
      message: null,
      tab: 'loyaltyUploadTab',
      formKey: new Date().getTime(),
      startDate: null,
      endDate: null,
      mappings,
      loyaltyFields,
      showLoyaltyWarning: false
    };

    this.handleTabChange = this.handleTabChange.bind(this);
    this.submitFieldToColumns = this.submitFieldToColumns.bind(this);
    this.dateHandler = this.dateHandler.bind(this);
    this.filterReports = this.filterReports.bind(this);
    this.handleColumnNumberInput = this.handleColumnNumberInput.bind(this);
    this.uploadLoyalties = this.uploadLoyalties.bind(this);
    this.cancelUploadLoyalties = this.cancelUploadLoyalties.bind(this);
  }

  componentWillMount() {
    this.updateLanguage();
    this.props.dispatch(uploadLoyaltyDataReset());
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'Partners',
      this.state.labels
    );
  }

  handleFinishedUpload = (info) => {
    this.props.dispatch(uploadLoyaltyDataSuccess(info));
  }

  filterReports() {
    this.props.dispatch(getPreviousLoyaltiesStatusRequest());
  }

  uploadLoyalties() {
    this.setState({ showLoyaltyWarning: true });
  }

  cancelUploadLoyalties() {
    this.setState({ showLoyaltyWarning: false });
  }

  submitFieldToColumns() {
    const partnerGuid = getPartnerGuid();
    this.setState({ showLoyaltyWarning: false });
    const s3BucketKey = this.props.loyaltyUploadData.uploadLoyaltyData.bucketKey;

    let schema = Object.keys(this.state.mappings).map(key => {
      let mapping = {};
      mapping['field'] = key;
      mapping['column'] = Number(this.state.mappings[key]);

      return mapping;
    });

    let payload = {
      schema,
      "filename": s3BucketKey,
      "partner": partnerGuid
    };

    this.props.dispatch(mapColumnsToFieldsRequest(payload));

    setTimeout(() => {
      this.handleTabChange(null, 'loyaltyProgressTab');
    }, 1000)
  }

  resetFields() {
    this.setState({
      msisdnColumnNumber: '',
      dateOfBirthColumnNumber: '',
      fullNameColumnNumber: '',
      nationalIdColumnNumber: '',
    })
  }

  progress(completed) {
    if (completed > 100) {
      this.setState({ completed: 100 });
    } else {
      this.setState({ completed });
    }
  }

  errorHandler(message) {
    this.setState({ message });
  }

  displayCompleteMessage() {
    if (this.state.completed === 0) {
      return (
        <div>
          <h4>Drag and drop files here, or click to upload.</h4>
        </div>
      );
    }
    if (this.state.completed > 0 && this.state.completed < 100) {
      return (
        <div>
          <h4>Upload in progress, do not interrupt</h4>
        </div>
      );
    }
    if (this.state.completed >= 100 && !this.state.message) {
      return (
        <div>
          <h4 style={{ color: 'green' }}>Upload succesfully completed</h4>
        </div>
      );
    }
    if (this.state.completed >= 100 && this.state.message) {
      return (
        <div>
          <h4>Upload completed, but unfortunately something went wrong :(</h4>
        </div>
      );
    }
    return '';
  }

  getTabStyle(tabValue) {
    const tabStyles = {
      default_tab: {
        color: '#fff',
        backgroundColor: PRIMARY_THEME_COLOR_SHADE_2,
        minWidth: '50%',
      },
      active_tab: {
        color: "#fff",
        backgroundColor: PRIMARY_THEME_COLOR_SHADE_1,
        fontWeight: 600,
        minWidth: '50%',
      }
    }

    const { tab } = this.state;
    if (tab === tabValue) {
      return tabStyles.active_tab
    } else {
      return tabStyles.default_tab
    }
  }

  handleTabChange(event, tab) {
    this.setState({ tab });
    this.resetFields();

    if (tab === 'loyaltyProgressTab') {
      this.filterReports();
    }
  }

  dateHandler(type, moment) {
    const rawDate = new Date(moment);
    if (type === 'start') {
      this.setState({ startDate: rawDate });
      this.props.dispatch(setLoyaltiesStartDateRequest(rawDate));
    }

    if (type === 'end') {
      this.setState({ endDate: rawDate });
      this.props.dispatch(setLoyaltiesEndDateRequest(rawDate));
    }
  }

  renderTab() {
    const { labels } = this.state;

    return (
      <div className="form-group " style={{ margin: "1px", padding: "20px", width: "100%" }}>
        <AppBar position="static" style={{ backgroundColor: PRIMARY_THEME_COLOR_SHADE_2 }}>
          <Tabs
            value={this.state.tab}
            onChange={this.handleTabChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: SECONDARY_THEME_COLOR_SHADE_2
              }
            }}
          >
            <Tab value='loyaltyUploadTab' id="loyaltyUploadTab" label={labels.uploadLoyaltyData} style={this.getTabStyle('loyaltyUploadTab')} />
            <Tab value='loyaltyProgressTab' id="loyaltyProgressTab" label={labels.previousLoyalties} style={this.getTabStyle('loyaltyProgressTab')} />
          </Tabs>
        </AppBar>
        {this.state.tab === 'loyaltyUploadTab' ? this.renderLoyaltyUploadTab() : this.renderLoyaltyProgressTab()}
      </div>
    );
  }

  renderLoyaltyUpload() {
    const { labels } = this.state;

    const style = {
      loader: {
        display: this.state.completed > 0 ? '' : 'none',
        width: '40%',
        marginLeft: '30%',
      },
      s3Uploader: {
        display: this.state.completed > 0 ? 'none' : '',
      },
    };
    const uploadOptions = {
      getSignedUrl: getS3BucketSignedUrl,
      s3Url: 'https://loyalty-eligibility.s3.amazonaws.com',
      uploadRequestHeaders: {},
      onProgress: this.progress.bind(this),
      onError: this.errorHandler.bind(this),
    };
    return <div>
      <center>
        <h3>{labels.uploadFile}</h3>
        <br />
        <p style={{ color: 'red' }}>{this.state.message}</p>
        <DropzoneS3Uploader
          onFinish={this.handleFinishedUpload}
          maxSize={1024 * 1024 * 200}
          upload={uploadOptions}
          s3Url="https://loyalty-eligibility.s3.amazonaws.com"
        >
          <div>
            <br />
            <br />
            {this.displayCompleteMessage()}
          </div>
        </DropzoneS3Uploader>
        <br />
      </center>
      <LinearProgress
        style={style.loader}
        mode="determinate"
        value={this.state.completed}
      />
    </div>
  }

  handleColumnNumberInput(field, event) {
    const { mappings } = this.state;
    mappings[field] = event.target.value;
    this.setState({ mappings })
  }

  renderForm() {
    const { loyaltyFields, labels } = this.state;

    return <div style={{ display: (loyaltyFields ? 'block' : 'none') }}>
      <center>
        <h3>{labels.mapColumnsToFields}</h3>
        <ValidatorForm onSubmit={this.uploadLoyalties} key={this.state.formKey}>
          <Grid container spacing={3}>
            <Grid container item xs={7} spacing={3} style={{ margin: 'auto' }}>
              {loyaltyFields.map(field => {
                const label = labels[field.toLowerCase()] || field;
                const errorLabel = labels.errorLoyaltyField.replace('{field}', label);
                const helperLoyaltyField = labels.helperLoyaltyField.replace('{field}', label);
                return <Grid item xs={12} key={field}>
                  <TextValidator label={label.toUpperCase()}
                    fullWidth
                    variant="outlined"
                    type="number"
                    value={this.state.mappings[field]}
                    onChange={(event) => this.handleColumnNumberInput(field, event)}
                    name={field + "ColumnNumber"}
                    placeholder={field.toUpperCase()}
                    validators={['required']}
                    errorMessages={[errorLabel]}
                    helperText={helperLoyaltyField} />
                </Grid>;
              })}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                style={btnStyle.secondary}
                type="submit">
                {labels.import}
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </center> </div>

  }

  renderLoyaltyUploadTab() {
    const { labels } = this.state;

    return <Grid container item xs={12} spacing={3} style={{ marginTop: '10px' }}>
      <Grid item md={6} xs={12}>
        {this.renderLoyaltyUpload()}
      </Grid>
      <Grid item md={6} xs={12}>
        <AlertDialog
          warning
          show={!!this.state.showLoyaltyWarning}
          title={labels.uploadLoyaltyData}
          confirmBtnText={labels.yes}
          onConfirm={this.submitFieldToColumns}
          cancelBtnText={labels.no}
          onCancel={this.cancelUploadLoyalties}
          showCancel={true}
          confirmBtnCssClass
        >
          {labels.loyaltyWarning}
        </AlertDialog>
        {this.renderForm()}
      </Grid>
    </Grid>
  }

  renderLoyaltyProgressTab() {
    const { labels, endDate, startDate } = this.state;
    const { getPreviousLoyaltiesStatus: loyalties } = this.props.loyaltyUploadData;

    return <div style={{ marginTop: '10px' }}>
      <MaterialTable
        title={<h3>{labels.previousLoyalties}</h3>}
        columns={[
          { title: labels.created, field: 'created_at', defaultSort: 'desc' },
          { title: labels.providedLoyalties, field: 'provided_loyalties' },
          { title: labels.loyaltiesCreated, field: 'loyalties_created' },
          {
            title: labels.status,
            render: rowData => <span>{rowData.status ? labels[rowData.status.toLowerCase()] : rowData.status}</span>
          },
        ]}
        data={loyalties}
        options={{
          showTitle: true,
          paging: true,
          search: false,
          header: true,
          padding: 'dense',
          sorting: true,
          actionsColumnIndex: -1
        }}
        actions={[
          rowData => ({
            icon: () => (
              rowData.report_file
                ? <Link href={rowData.report_file_url} target="_blank"><SaveAltIcon color="primary" /></Link>
                : <CircularProgress disableShrink size={25} />),
            tooltip: 'Download Report',
            hidden: rowData.status === 'Failed',
          })
        ]}
        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar {...props} />
              <Grid container item xs={6} spacing={1} style={{ paddingLeft: '15px', marginTop: '5px', marginBottom: '5px' }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Grid item md={4} xs={12}>
                    <DatePicker
                      value={startDate}
                      onChange={(date) => this.dateHandler('start', date)}
                      format="YYYY-MM-DD"
                      keyboard={true}
                      clearable
                      label={labels.startDate}
                      inputVariant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <DatePicker
                      value={endDate}
                      onChange={(date) => this.dateHandler('end', date)}
                      format="YYYY-MM-DD"
                      keyboard
                      clearable
                      label={labels.endDate}
                      inputVariant="outlined"
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                <Grid item md={4} xs={12} >
                  <Button variant="contained"
                    style={btnStyle.secondary}
                    onClick={this.filterReports}>
                    {startDate || endDate ? labels.search : labels.refresh}</Button>
                </Grid>
              </Grid>
            </div>
          ),
        }}
        detailPanel={rowData => {
          return (
            <Grid container spacing={1} style={{ padding: '10px', marginLeft: '15px' }}>
              <Grid item md={6} xs={12}>
                <Typography><span style={{ textAlign: "right" }}>Created At: </span><span style={{ textAlign: "left" }}><strong>{rowData.created_at}</strong></span></Typography>
                {rowData.provided_loyalties !== undefined
                  ? <Typography><span style={{ textAlign: "left" }}>Provided Loyalties : </span><span style={{ textAlign: "left" }}><strong>{rowData.provided_loyalties}</strong></span></Typography>
                  : ""}
                {rowData.unique_customers_provided !== undefined
                  ? <Typography>Unique Customers Provided : <strong>{rowData.unique_customers_provided}</strong></Typography>
                  : ""}
                {rowData.loyalties_created !== undefined
                  ? <Typography>Loyalties Created : <strong>{rowData.loyalties_created}</strong></Typography>
                  : ""}
                {rowData.error !== undefined
                  ? <Typography><span style={{ textAlign: "left" }}>Error : </span><span style={{ textAlign: "left" }}><strong>{rowData.error}</strong></span></Typography>
                  : ""}
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>guid : <strong>{rowData.guid}</strong></Typography>
                {rowData.duplicated_msisdns !== undefined
                  ? <Typography>Duplicate Msisdn : <strong>{rowData.duplicated_msisdns}</strong></Typography>
                  : ""}
                {rowData.representing_duplicated_msisdns !== undefined
                  ? <Typography>Representing Duplicate Msisdn : <strong>{rowData.representing_duplicated_msisdns}</strong></Typography>
                  : ""}
              </Grid>
            </Grid>
          )
        }}
      />
    </div>
  }

  render() {
    const { mapColumnsToFieldsLoader: mappingLoader, getPreviousLoyaltiesStatusLoader: loyaltiesLoader, getPreviousLoyaltyStatusLoader: loyaltyLoader } = this.props.loyaltyUploadData;


    return (
      <div style={{ width: '90%', margin: "auto" }}>
        <Loader loaded={!mappingLoader && !loyaltiesLoader && !loyaltyLoader} color="#ff834f">
          <div className="x_panel">
            <div className="x_content" >
              <div style={{ margin: "auto", borderColor: "black" }}>
                {this.renderTab()}
              </div>
            </div>

          </div>
        </Loader>
      </div>
    );
  }
}
export default connect(state => ({
  loyaltyUploadData: state.loyaltyUpload,
  globalData: state.global,
}))(LoyaltyEligibility);
