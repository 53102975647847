import React from "react";
import { hashHistory } from "react-router";
import { useStyle } from "./Style";
import { Grid } from "@mui/material";
import { getPartnerGuid, isComplexPartner } from "../../../../lib/access.es6";
import { Customer } from "./Customer";
import { Policy } from "./Policy";
import { Claim } from "./Claim";
import { PARTNERS_WITH_OLD_COMPLEX_UI } from "../../../../routes/PartnersEnum";

const ResultsCards = (props) => {
  const classes = useStyle();

  const hasData =
    props.searchResults && Object.keys(props.searchResults).length > 0;

  const handleViewCustomer = (customer) => {
    hashHistory.push("admin/customer_status/" + customer.guid);
  };

  const handleViewPolicy = (policy) => {
    const isComplex = isComplexPartner();
    if (isComplex) {
      const partnerGuid = getPartnerGuid();
      if(PARTNERS_WITH_OLD_COMPLEX_UI.includes(partnerGuid)){
        hashHistory.push("admin/customer_status/" + policy.customer_guid);
      }
      else{
        hashHistory.push("admin/policy-processing/" + policy.policy_number);
      }
     
    } else {
      hashHistory.push("admin/customer_status/" + policy.customer_guid);
    }
  };

  const handleViewClaim = (claim) => {
    const isComplex = isComplexPartner();
    if (isComplex) {
      hashHistory.push("admin/claim-processing-v2/" + claim.guid);
    } else {
      hashHistory.push(`admin/claims_processing/${claim.guid}`);
    }
  };

  const renderItem = (item) => {
    switch (item._doc_type) {
      case "Customer":
        return (
          <Customer
            customer={item}
            handleViewCustomer={handleViewCustomer}
          />
        );
      case "Policy":
        return (
          <Policy
            policy={item}
            handleViewPolicy={handleViewPolicy}
          />
        );
      case "Claim":
        return (
          <Claim
            claim={item}
            handleViewClaim={handleViewClaim}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Grid container spacing={2}>
      {hasData && props.searchResults.data && props.searchResults.data.length > 0 ? (
        props.searchResults.data.map((item, index) => (
          <React.Fragment key={index}>
            {renderItem(item)}
          </React.Fragment>
        ))
      ) : (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className={classes.noResultsFound}
          >
            <h1>No results found !!</h1>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ResultsCards;
