import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({


  headingclaim:{
    "& h4":{
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#000000"
    }
  },
  claimDetailsWrapper:{
   
    "& table": {
        width: "100%",
        background: "#FFFFFF",
        border: "1px solid #A7A7A7",
        marginBottom: "20px",
        "& tr": {
            borderTop: "1px solid #A7A7A7",
        },
        "& th": {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
            padding: "10px",
            borderRight: "1px solid #A7A7A7",
        },
        "& td": {
            fontFamily: "open sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
            padding: "10px",
            borderRight: "1px solid #A7A7A7",

        }
    }
  },

}));