import React from 'react'
import TableInc from '../../../../../shared-ui/TableInc';
import { addCustomerBeneficiary,deleteCustomerbeneficiary } from '../../../../../lib/requests.es6';
import { formatDateLocale } from '../../../../../lib/utils.es6';



const BeneficiaryDetails = (props) => {

const Gender = {
  Male: "Male",
  Female: "Female"
}

  if (props.beneficiary) {
    const beneficiary = props.beneficiary ? [props.beneficiary] : [];
    return (
      <>
        <TableInc
          columns={[
            {
              title: 'First Name', field: 'first_name'

            },
            {
              title: 'Last Name', field: 'last_name'
            },
            {
              title: 'Date Of Birth', field: 'date_of_birth' , type: "date", render: rowData => 
                <>
                {
                  formatDateLocale(rowData.date_of_birth)
                }
                </>
              
            },
            {
              title: 'Gender', field: 'gender', lookup: Gender,

            },
            {
              title: 'Phone Number	', field: 'msisdn'

            },
            {
              title: 'National ID', field: 'national_id'

            },
            {
              title: 'Address ', field: 'address'
            },
          ]}
          data={
            beneficiary
          }
          options={{
            pageSize: beneficiary.length + 1,
            showTitle: false,
            actionsColumnIndex: -1

          }}

          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {

                const beneficiaryDetails = {
                  "first_name": newData.first_name,
                  "last_name": newData.last_name,
                  "msisdn": newData.msisdn,
                  "date_of_birth": newData.date_of_birth,
                  "national_id": newData.national_id,
                  "address": newData.address,
                  "gender": newData.gender,
                  "email": newData.email,
                  "customer_guid": props.customerGuid,
                }
                console.log("beneficiaryDetails", beneficiaryDetails)
                addCustomerBeneficiary(beneficiaryDetails).then(
                  (response) => {
                    console.log("here is response", response);
                    resolve();
                    window.location.reload();
                  }
                ).catch(
                  (error) => {
                    console.log("did not work", error)
                    reject();
                  }
                )

              }),

            onRowDelete: oldData =>
            new Promise((resolve, reject) => {        
              deleteCustomerbeneficiary(oldData).then(
                (response) => {
                  resolve();
                  window.location.reload();
                }
              ).catch(
                (error) => {
                  reject();
                }
              )
              resolve();
            })
          }}
        />
      </>
    )
  }

}

export default BeneficiaryDetails