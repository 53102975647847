import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  componentHeading: {
    "& h2": {

      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#000000",
    }
  },
  productWrapper: {
    minHeight: "200px",
    marginTop: "30px",
    padding: "0 10px 30px 10px",
    background: "#032A37",
    boxShadow: "0px 2px 8px rgba(99, 99, 99, 0.2)",
    borderRadius: "8px",
    textAlign: "center",
    marginBottom: "50px",
    '&:hover': {
      background: '#FBA92D',
      cursor: 'pointer'
    },
    '&.selected': {
      background: '#FBA92D',
      cursor: 'pointer',
    },
    "& h3": {
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "15px",
      color: "#FFFFFF",
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      lineHeight: "20px",
      textAlign: "center",
      color: "#FFFFFF",
    }
  }
}));