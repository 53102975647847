import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../../../../lib/actionTypes.es6';
import { getAllPayments, getAllProducts, addMissingPayment } from '../../../../../../lib/requests.es6';
import * as actions from './actions.js';
import { unAuthorizedNotification } from '../../../../../../lib/access.es6';
import { GAloggerException } from '../../../../../../lib/monitoring.es6';

export function* getAllOrangePaymentsWatcher() {
  while (true) {
    const requestAction = yield take(types.GET_ALL_ORANGE_PAYMENTS_REQUEST);
    let response = {};
    try {
      const msisdn = requestAction.payload;
      response = yield call(getAllPayments, msisdn);
      yield put(actions.getAllOrangePaymentsSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.getAllOrangePaymentsFail(errorPayload));
    }
  }
}


export function* getAllOrangeProductsWatcher() {
  while (true) {
    yield take(types.GET_ALL_ORANGE_PRODUCTS_REQUEST);
    let response = {};
    try {
      response = yield call(getAllProducts);
      yield put(actions.getAllOrangeProductsSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.getAllOrangeProductsFail(errorPayload));
    }
  }
}


export function* AddOrangeMissingPaymentRequestWatcher() {
  while (true) {
    const requestAction = yield take(types.ADD_ORANGE_MISSING_PAYMENT_REQUEST);
    let response = {};
    try {
      response = yield call(
        addMissingPayment,
        requestAction.payload.paymentData
      );
      yield put(actions.addOrangeMissingPaymentSuccess(response.message));

    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.addOrangeMissingPaymentFail(errorPayload));
    }
  }
}