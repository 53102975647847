import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { TextValidator, ValidatorForm} from 'react-material-ui-form-validator';

import {CircularProgress, FormControl, InputAdornment, InputLabel, Select} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AlertDialog from "../AlertDialog/index.es6";
import {
  getInternationalCallingCode,
  getLocalNumberLength,
  isSouthAfricanPartner,
  isVAlidSouthAfricanNationalId
} from "../../lib/access.es6";
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../lib/constants";
import { formatDate } from "../../lib/utils.es6";
import TextField from "@mui/material/TextField";
import {DatePicker} from "@material-ui/pickers";


let initialState = {
  first_name: '',
  last_name: '',
  msisdn: '',
  date_of_birth: '',
  national_id: '',
  address: '',
  gender: '',
  email: ''
}
export default function EditBeneficiaryDetails(props) {

  if (!props.beneficiaryDetailstoedit) {
    return ""
  }

  initialState = props.beneficiaryDetailstoedit

  const [SAIdCheckError, setSAIdCheckError] = React.useState(null)
  const [dateOfBirth, setDateOfBirth] = React.useState('')
  const [gender, setGender] = React.useState('')
  const [nationalId, setNationalId] = React.useState('')

  
  const [
    {
      full_name,
      first_name,
      last_name,
      msisdn,
      date_of_birth,
      national_id,
      address,
      email,
    }, setState
  ] = React.useState(initialState)


  React.useEffect(()=>{
    if(nationalId===''){
      setNationalId(props.beneficiaryDetailstoedit.national_id)
      setGender(props.beneficiaryDetailstoedit.gender)
      setDateOfBirth(props.beneficiaryDetailstoedit.date_of_birth)
    }

  },[props])

  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };


  const reset = () => {
    setState(initialState);
  }


  const submit = () => {
    const payload = {
      full_name,
      first_name,
      last_name,
      msisdn,
      date_of_birth,
      national_id,
      address,
      gender,
      email,
      customer_guid: props.beneficiaryDetailstoedit.customer_guid,
      guid: props.beneficiaryDetailstoedit.guid
    }
    props.editBeneficiaryDetails(payload)
    console.log(payload)
  }

  const handleEditBeneficiaryDetailsEditedSuccessfully = () => {
    props.resetEditBeneficiaryDetailsSuccessAlert()
    props.resetBeneficiaryDetailsToEdit()
    reset()
  }

  const handleEditBankDetailsCreateError = () => {
    props.resetEditBeneficiaryDetailsErrorAlert()
  }
  const onNationalIdChange = (event) => {
    const value = event.target.value;
    setNationalId(value)
    const result = isVAlidSouthAfricanNationalId(value)
    if (result.isValid) {

      setDateOfBirth(formatDate(new Date(result.dob)))
      setGender(result.gender)
      setSAIdCheckError(result.error)

      return true
    } else {
      setSAIdCheckError(true)
      return false
    }
  }
  return (
    <React.Fragment>
      <AlertDialog
        custom
        show={props.showEditBeneficiaryDetailsProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Updating bankDetails</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showEditBeneficiaryDetailsSuccessAlert}
        size="sm"
        title={'Beneficiary Details updated'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleEditBeneficiaryDetailsEditedSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => { handleEditBeneficiaryDetailsEditedSuccessfully() }} style={{ background: 'green', }}>Ok</Button>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showEditBeneficiaryDetailsErrorAlert}
        danger
        title={'Error updating bankDetails'}
        onConfirm={() => handleEditBankDetailsCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.editBeneficiaryDetailsError ? props.editBeneficiaryDetailsError.message : ''}
      </AlertDialog>

      <Dialog
        open={props.beneficiaryDetailstoedit}
        fullWidth
        maxWidth='lg'
        onClose={props.resetBeneficiaryDetailsToEdit}
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.resetBeneficiaryDetailsToEdit}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Beneficiary Details
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div style={{ marginTop: "2%" }}>
            <Box sx={{ width: "100%" }}>
              <ValidatorForm onSubmit={() => submit()} >
                <Grid container spacing={2}>
                  <Grid item lg={3} md={3} xs={12}>
                    <TextField
                      fullWidth
                      placeholder={props.labels.firstName}
                      label={props.labels.firstName}
                      name="first_name"
                      variant="outlined"
                      onChange={onChange}
                      value={first_name}
                    />
                  </Grid>
                  <Grid item lg={3} md={3} xs={12}>
                    <TextValidator
                      onChange={onChange}
                      value={last_name}
                      label={props.labels.surname}
                      name='last_name'
                      fullWidth
                      variant="outlined"
                      type="text"
                      placeholder='last_name'
                      validators={['required']}
                      errorMessages={['Please enter last name']}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <TextValidator
                      onChange={onChange}
                      value={msisdn}
                      label={props.labels.telephone}
                      name='msisdn'
                      fullWidth
                      variant="outlined"
                      type="number"
                      placeholder={props.labels.telephone}
                      validators={[isSouthAfricanPartner() ? 'required' : '']}
                      errorMessages={['Please enter msisdn']}
                      maxLength={getLocalNumberLength()}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, getLocalNumberLength())
                      }}
                      InputProps={{
                        startAdornment: (<InputAdornment position="start">
                          {getInternationalCallingCode()}
                        </InputAdornment>),
                      }}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <TextField
                      fullWidth
                      placeholder={props.labels.email}
                      label={props.labels.email}
                      name="email"
                      variant="outlined"
                      onChange={onChange}
                      value={email}
                      type="email"
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <TextField
                      fullWidth
                      placeholder={props.labels.address}
                      label={props.labels.address}
                      name="address"
                      variant="outlined"
                      onChange={onChange}
                      value={address}
                      multiline={true}
                      rows={5}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    {isSouthAfricanPartner() ? (
                      <TextValidator
                        onChange={onNationalIdChange}
                        value={nationalId}
                        label={'kfdjfk '+props.labels.nationalId}
                        name="national_id"
                        fullWidth
                        variant="outlined"
                        placeholder={props.labels.nationalId}
                        validators={['required', 'isValidNationalId']}
                        errorMessages={[
                          'Please enter national id',
                          SAIdCheckError || 'Invalid national Id',
                        ]}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        placeholder={props.labels.nationalId}
                        label={props.labels.nationalId}
                        name="national_id"
                        variant="outlined"
                        onChange={onNationalIdChange}
                        value={nationalId}
                      />
                    )}
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <DatePicker
                      fullWidth
                      variant="outlined"
                      clearable
                      label={props.labels.dateOfBirth}
                      inputVariant="outlined"
                      value={dateOfBirth}
                      onChange={e => {
                        setDateOfBirth(e.target.value);
                      }}
                      format="YYYY-MM-DD"
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>{props.labels.gender}</InputLabel>
                      <Select
                        value={gender}
                        label={props.labels.gender}
                        onChange={e => {
                          setGender(e.target.value);
                        }}
                      >
                        <MenuItem key="-1" value="">
                          {props.labels.gender}
                        </MenuItem>
                        {['Male', 'Female'].map((gender, index) => (
                          <MenuItem key={index} value={gender}>
                            {gender}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant="contained" type="submit" style={{ marginRight: '2%' }} > Update Beneficiary </Button>
                    <Button variant="contained" color="secondary" style={{ marginRight: '2%' }} onClick={() => reset()}> Reset</Button>
                  </Grid>

                </Grid>
              </ValidatorForm>
            </Box>
          </div>

        </DialogContent>
      </Dialog>
    </React.Fragment>

  );



}
