import GAlogger, { monitoringEventCategories } from '../../lib/monitoring.es6';

import * as types from '../../lib/actionTypes.es6';

export function summaryClaimsRequest() {
  return {
    type: types.SUMMARY_CLAIM_REQUEST,
  };
}

export function summaryClaimsSuccess(payload) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.getClaimSummary);
  return {
    type: types.SUMMARY_CLAIM_SUCCESS,
    payload,
  };
}

export function summaryClaimsFail(payload) {
  return {
    type: types.SUMMARY_CLAIM_FAILURE,
    payload,
  };
}
