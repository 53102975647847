import * as types from '../../../../../../lib/actionTypes.es6';
import find from 'lodash/find';

export const initialState = {
  get_policies_errors: null,
  get_policies_loader: false,
  get_policies_success_message: '',
  policies: [],

  //purchase policy
  errors: null,
  policyPurchaseInfo: null,
  loader: false,
  products: [],
  policy_purchase_succesful: false,
  policy_purchase_failed: false,

  //cancel policy
  cancelPolicyErrors: null,
  cancelPolicyLoader: false,
  cancelPolicySuccesful: false,
  cancelPolicyfailed: false,
  policyToCancel: null,

  //edit policy
  policyToEdit: null,
  editedPolicy: null,
  editPolicyErrors: null,
  editPolicyLoader: false,
  editPolicySuccesful: false,
  editPolicyfailed: false,

  extendedFamilyProductConfigsOrange: null,
  extendedFamilyProductConfigsErrorsOrange: null,
  extendedFamilyProductConfigsLoaderOrange: false,

  get_total_payable_premium_errors_orange: null,
  get_total_payable_premium_loader_orange: false,
  get_total_payable_premium_success_message_orange: '',
  total_payable_premium_orange: null,

};

export default function orangePoliciesReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ALL_ORANGE_POLICIES_REQUEST:
      return { ...state, get_policies_loader: true, get_policies_errors: null };
    case types.GET_ALL_ORANGE_POLICIES_SUCCESS:
      return { ...state, get_policies_loader: false, get_policies_errors: null, policies: getPoliciesWithProductDetails(action.payload, state.products) };


    case types.GET_ALL_ORANGE_POLICIES_FAILURE:
      return { ...state, get_policies_loader: false, get_policies_errors: action.payload };

    case types.PURCHASE_ORANGE_POLICY_REQUEST:
      return {
        ...state, loader: true, errors: null, policyPurchaseInfo: null, policy_purchase_succesful: false,
        policy_purchase_failed: false
      };

    case types.PURCHASE_ORANGE_POLICY_SUCCESS:
      return {
        ...state, policyPurchaseInfo: action.payload, loader: false, policy_purchase_succesful: true,
        policy_purchase_failed: false,
      };

    case types.PURCHASE_ORANGE_POLICY_FAILURE:
      return {
        ...state, loader: false, errors: action.payload, policy_purchase_succesful: false,
        policy_purchase_failed: true,
      };


    case types.PAID_ORANGE_PRODUCT_LIST_SUCCESS:
      return { ...state, products: parseTags(action.payload) };

    case types.PURCHASE_ORANGE_POLICY_RESET:
      return {
        ...state, policy_purchase_succesful: false,
        policy_purchase_failed: false,
      };

    case types.DELETE_ORANGE_POLICY_REQUEST:
      return { ...state, cancelPolicyLoader: true, policyToCancel: action.payload, cancelPolicyErrors: null, cancelPolicySuccesful: false, cancelPolicyfailed: false };

    case types.DELETE_ORANGE_POLICY_SUCCESS:
      return { ...state, cancelPolicyLoader: false, cancelPolicySuccesful: true, cancelPolicyfailed: false };

    case types.DELETE_ORANGE_POLICY_FAIL:
      return { ...state, cancelPolicyLoader: false, cancelPolicyErrors: action.payload, cancelPolicySuccesful: false, cancelPolicyfailed: true };

    case types.SET_ORANGE_POLICY_TO_CANCEL:
      return { ...state, policyToCancel: action.payload };

    case types.RESET_ORANGE_POLICY_TO_CANCEL:
      return { ...state, policyToCancel: null };

    case types.SET_ORANGE_POLICY_TO_EDIT:
      return { ...state, policyToEdit: action.payload };

    case types.RESET_ORANGE_POLICY_TO_EDIT:
      return { ...state, policyToEdit: null };

    case types.UPDATE_ORANGE_POLICY_REQUEST:
      return {
        ...state, editPolicyLoader: true, editPolicyErrors: null, editPolicySuccesful: false,
        editPolicyfailed: false
      };

    case types.UPDATE_ORANGE_POLICY_SUCCESS:
      return {
        ...state, editedPolicy: action.payload, editPolicyLoader: false, editPolicySuccesful: true,
        editPolicyfailed: false,
      };

    case types.UPDATE_ORANGE_POLICY_FAILURE:
      return {
        ...state, editPolicyLoader: false, editPolicyErrors: action.payload, editPolicySuccesful: false,
        editPolicyfailed: true,
      };

    case types.EDIT_ORANGE_POLICY_RESET:
      return {
        ...state, editPolicySuccesful: false,
        editPolicyfailed: false,
      };

      case types.GET_ORANGE_EXTENDED_FAMILY_PRODUCT_CONFIGS_REQUEST:
        return { ...state, extendedFamilyProductConfigsOrange: null, extendedFamilyProductConfigsErrorsOrange: null, extendedFamilyProductConfigsLoaderOrange: true };
  
      case types.GET_ORANGE_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS:
        return { ...state, extendedFamilyProductConfigsOrange: action.payload, extendedFamilyProductConfigsErrorsOrange: null, extendedFamilyProductConfigsLoaderOrange: false };
  
      case types.GET_ORANGE_EXTENDED_FAMILY_PRODUCT_CONFIGS_FAILURE:
        return { ...state, extendedFamilyProductConfigsOrange: null, extendedFamilyProductConfigsErrorsOrange: action.payload, extendedFamilyProductConfigsLoaderOrange: false };


        case types.GET_TOTAL_PAYABLE_ORANGE_PREMIUM_REQUEST:
          return { ...state, get_total_payable_premium_loader_orange: true, get_total_payable_premium_errors_orange: null };

        case types.GET_TOTAL_PAYABLE_ORANGE_PREMIUM_SUCCESS:
          return { ...state, get_total_payable_premium_loader_orange: false, get_total_payable_premium_errors_orange: null, total_payable_premium_orange: action.payload, policies: addToPolicyFields(state.policies, action.payload) };

        case types.GET_TOTAL_PAYABLE_ORANGE_PREMIUM_FAILURE:
          return { ...state, get_total_payable_premium_loader_orange: false, get_total_payable_premium_errors_orange: action.payload };


    default:
      return state;
  }
}


function getBenefits(product) {
  let benefits = [
    ...product.variable_indemnities.map((benefit) => { return { amount: benefit.amount, type: benefit.name } }),
    ...product.fixed_indemnities.map((benefit) => { return { amount: benefit.amount_benefit, type: benefit.name } }),
  ]
  return benefits
}

function getPoliciesWithProductDetails(policies, products) {
  let npolicies = policies.map((policy) => {
    const product = find(products, { guid: policy.product_guid })
    return {
      ...policy,
      product_name: product.name || '',
      product_cover_type: product.cover_type || '',
      benefits: getBenefits(product)
    }
  })
  return npolicies
}

function parseTags(products) {
  let parsedTagProducts = products.map(p => {
    if (p.tag && p.tag.includes("mainProduct")) {
      return { ...p, tag: JSON.parse(p.tag) }
    }
    return p
  })
  return parsedTagProducts
}

function addToPolicyFields(policies, total_payable_premium){
  if(total_payable_premium){
    return policies.map(policy => {
      return {...policy, total_payable_premium: total_payable_premium.premium_amount_in_cents + total_payable_premium.additional_premium_amount_in_cents }
    })
  }

  return policies

}