import * as types from '../../../lib/actionTypes.es6'

export const initialState = {
  errors: null,
  loader: false,



  createdClaimExpense: null,
  createdClaimExpenseSuccess: false,
  createdClaimExpenseLoader: false,
  createdClaimExpenseError: null,

  deleteExpenseErrors: null,
  deleteExpenseSuccess: null,
  expenseToDelete: null,
  hardDeleteExpenseSuccess: false,
  hardDeleteExpenseLoader: false,

  editExpenseErrors: null,
  editExpenseInfo: null,
  editExpenseSuccess: false,
  ExpenseToEdit: null,

  addClaimDocumentError: null,
  addClaimDocumentLoader: false,
  addedClaimDocument: null,

  deleteClaimDocumentError: null,
  deleteClaimDocumentLoader: false,
  deleteClaimDocument: null,
  hardDeleteDocumentsSuccess: false,
  hardDeleteDocumentLoader: false,
  hardDeleteDocumentSuccess: false,

  documentsToDelete: null,

  ClaimAction: null,
  ClaimActionSuccess: false,
  ClaimActionLoader: false,
  ClaimActionError: null,

  ClaimNotes: null,
  ClaimNotesSuccess: false,
  ClaimNotesLoader: false,
  ClaimNotesError: null,



  ClaimFacilityNotes: null,
  ClaimFacilityNotesSuccess: false,
  ClaimFacilityNotesLoader: false,
  ClaimFacilityNotesError: null,


  get_claimsProcessingDcp_errors: null,
  get_claimsProcessingDcp_loader: false,
  get_claimsProcessingDcp_success_message: '',
  claimsProcessingDcp: null,

  saveClaimDcpDocumentLoader: false,
  saveClaimDcpDocumentError: null,
  addedTemplateDocument: null,

  get_preauthDetails_errors: null,
  get_preauthDetails_loader: false,
  get_preauthDetails_success_message: '',
  preauthDetails: null,
};

export default function claimProcessingV2Reducer(state = initialState, action) {
  switch (action.type) {


    case types.CREATE_EXPENSE_REQUEST:
      return {
        ...state,
        createdClaimExpenseError: null,
        createdClaimExpenseLoader: true,
        createdClaimExpenseSuccess: false,
        createdClaimExpense: null
      };

    case types.CREATE_EXPENSE_SUCCESS:
      return {
        ...state,
        createdClaimExpense: action.payload,
        createdClaimExpenseSuccess: true,
        createdClaimExpenseLoader: false,
        createdClaimExpenseError: null,
      };

    case types.CREATE_EXPENSE_FAILURE:
      return { ...state, createdClaimExpenseLoader: false, createdClaimExpenseError: action.payload };

    case types.RESET_CREATE_EXPENSE_SUCCESS:
      return { ...state, createdClaimExpenseSuccess: false };

    case types.RESET_CREATE_EXPENSE_ERROR:
      return { ...state, createdClaimExpenseError: null };

    case types.DELETE_EXPENSE_REQUEST:
      return { ...state, loader: true, deleteExpenseErrors: null, deleteExpenseSuccess: null, expenseToDelete: action.payload, hardDeleteExpenseLoader: true, hardDeleteUserSuccess: false, hardDeleteUserError: null }

    case types.DELETE_EXPENSE_FAILED:
      return { ...state, loader: false, deleteExpenseErrors: action.payload, deleteExpenseSuccess: null, hardDeleteExpenseLoader: false }

    case types.DELETE_EXPENSE_SUCCESS:
      return { ...state, loader: false, deleteExpenseErrors: null, deleteExpenseSuccess: action.payload, hardDeleteExpenseLoader: false, hardDeleteExpenseSuccess: true }

    case types.RESET_DELETE_EXPENSE_SUCCESS:
      return { ...state, hardDeleteExpenseSuccess: false };

    case types.SET_EXPENSE_TO_DELETE:
      return { ...state, expenseToDelete: action.payload };

    case types.RESET_EXPENSE_TO_DELETE:
      return { ...state, expenseToDelete: null };

    case types.EXPENSE_EDIT_REQUEST:
      return {
        ...state,
        loader: true,
        editExpenseErrors: null,
        editExpenseInfo: null,
        editExpenseSuccess: false,
        ExpenseToEdit: action.payload
      };

    case types.EXPENSE_EDIT_SUCCESS:
      return {
        ...state,
        editExpenseInfo: action.payload,
        loader: false,
        editExpenseSuccess: true,
      };

    case types.EXPENSE_EDIT_FAILED:
      return {
        ...state,
        loader: false,
        editExpenseErrors: action.payload,
      };

    case types.RESET_EXPENSE_EDIT:
      return { ...state, ExpenseToEdit: null };

    case types.RESET_EXPENSE_EDIT_ERROR:
      return { ...state, ExpenseToEdit: null };

    case types.RESET_EXPENSE_EDIT_SUCCESS:
      return { ...state, editExpenseSuccess: false, ExpenseToEdit: null };

    case types.SET_EXPENSE_TO_EDIT:
      return { ...state, ExpenseToEdit: action.payload };


    case types.ADD_CLAIM_DOCUMENTS_REQUEST:
      return {
        ...state,
        addClaimDocumentLoader: true,
        addClaimDocumentError: null,
        addedClaimDocument: null,
      };

    case types.ADD_CLAIM_DOCUMENTS_SUCCESS:
      return {
        ...state,
        addedClaimDocument: action.payload,
        addClaimDocumentLoader: false,
      };

    case types.ADD_CLAIM_DOCUMENTS_FAILURE:
      return {
        ...state,
        addClaimDocumentLoader: false,
        addClaimDocumentError: action.payload,
      };

    case types.DELETE_CLAIM_DOCUMENTS_REQUEST:
      return {
        ...state,
        deleteClaimDocumentLoader: true,
        deleteClaimDocumentError: null,
        deleteClaimDocument: null,
        hardDeleteDocumentLoader: true,

      };

    case types.DELETE_CLAIM_DOCUMENTS_SUCCESS:
      return {
        ...state,
        deleteClaimDocumentLoader: false,
        deleteClaimDocument: true,
        hardDeleteExpenseLoader: false,
        hardDeleteDocumentSuccess: true
      };

    case types.DELETE_CLAIM_DOCUMENTS_FAILURE:
      return {
        ...state,
        deleteClaimDocumentLoader: false,
        deleteClaimDocumentError: action.payload,
        hardDeleteExpenseLoader: false,

      };



      case types.RESET_DELETE_DOCUMENT_SUCCESS: 
        return { ...state, hardDeleteDocumentsSuccess: false, hardDeleteDocumentSuccess: false };
  
      case types.SET_DOCUMENT_TO_DELETE:
        return { ...state, documentsToDelete: action.payload };
  
      case types.RESET_DOCUMENT_TO_DELETE:
        return { ...state, documentsToDelete: null };





    case types.CLAIM_ACTION_REQUEST:
      return {
        ...state,
        ClaimActionError: null,
        ClaimActionLoader: true,
        ClaimActionSuccess: false,
        ClaimAction: null
      };

    case types.CLAIM_ACTION_SUCCESS:
      return {
        ...state,
        ClaimAction: action.payload,
        ClaimActionSuccess: true,
        ClaimActionLoader: false,
        ClaimActionError: null,
      };

    case types.CLAIM_ACTION_FAILURE:
      return { ...state, ClaimActionLoader: false, ClaimActionError: action.payload };

    case types.RESET_CLAIM_ACTION_SUCCESS:
      return { ...state, ClaimActionSuccess: false };

    case types.RESET_CLAIM_ACTION_ERROR:
      return { ...state, ClaimActionError: null };


    case types.CLAIM_NOTES_REQUEST:
      return {
        ...state,
        ClaimNotesError: null,
        ClaimNotesLoader: true,
        ClaimNotesSuccess: false,
        ClaimNotes: null
      };

    case types.CLAIM_NOTES_SUCCESS:
      return {
        ...state,
        ClaimNotes: action.payload,
        ClaimNotesSuccess: true,
        ClaimNotesLoader: false,
        ClaimNotesError: null,
      };

    case types.CLAIM_NOTES_FAILURE:
      return { ...state, ClaimNotesLoader: false, ClaimNotesError: action.payload };

    case types.RESET_CLAIM_NOTES_SUCCESS:
      return { ...state, ClaimNotesSuccess: false };

    case types.RESET_CLAIM_NOTES_ERROR:
      return { ...state, ClaimNotesError: null };



    case types.CLAIM_FACILTY_NOTES_REQUEST:
      return {
        ...state,
        ClaimFacilityNotesError: null,
        ClaimFacilityNotesLoader: true,
        ClaimFacilityNotesSuccess: false,
        ClaimFacilityNotes: null
      };

    case types.CLAIM_FACILITY_NOTES_SUCCESS:
      return {
        ...state,
        ClaimFacilityNotes: action.payload,
        ClaimFacilityNotesSuccess: true,
        ClaimFacilityNotesLoader: false,
        ClaimFacilityNotesError: null,
      };

    case types.CLAIM_FACILITY_NOTES_FAILURE:
      return { ...state, ClaimFacilityNotesLoader: false, ClaimFacilityNotesError: action.payload };

    case types.RESET_FACILITY_CLAIM_NOTES_SUCCESS:
      return { ...state, ClaimFacilityNotesSuccess: false };

    case types.RESET_FACILITY_CLAIM_NOTES_ERROR:
      return { ...state, ClaimFacilityNotesError: null };


    case types.GET_CLAIM_PROCESSING_DCP_REQUEST:
      return { ...state, get_claimsProcessingDcp_loader: true, get_claimsProcessingDcp_errors: null };
    case types.GET_CLAIM_PROCESSING_DCP_SUCCESS:
      return { ...state, get_claimsProcessingDcp_loader: false, get_claimsProcessingDcp_errors: null, claimsProcessingDcp: action.payload };
    case types.GET_CLAIM_PROCESSING_DCP_FAILURE:
      return { ...state, get_claimsProcessingDcp_loader: false, get_claimsProcessingDcp_errors: action.payload };

      case types.SAVE_CLAIM_DCP_DOCUMENT_REQUEST:
        return {
          ...state,
          saveClaimDcpDocumentLoader: true,
          saveClaimDcpDocumentError: null,
          addedTemplateDocument: null,
        };
  
      case types.SAVE_CLAIM_DCP_DOCUMENT_SUCCESS:
        return {
          ...state,
          addedTemplateDocument: action.payload,
          saveClaimDcpDocumentLoader: false,
        };
  
      case types.SAVE_CLAIM_DCP_DOCUMENT_FAILURE:
        return {
          ...state,
          saveClaimDcpDocumentLoader: false,
          saveClaimDcpDocumentError: action.payload,
        };

        case types.GET_PREAUTH_DETAILS_REQUEST:
          return { ...state, get_preauthDetails_loader: true, get_preauthDetails_errors: null };
        case types.GET_PREAUTH_DETAILS_SUCCESS:
          return { ...state, get_preauthDetails_loader: false, get_preauthDetails_errors: null, preauthDetails: action.payload };
        case types.GET_PREAUTH_DETAILS_FAILURE:
          return { ...state, get_preauthDetails_loader: false, get_preauthDetails_errors: action.payload };

    default:
      return state;
  }
}
