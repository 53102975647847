import * as types from "../../../lib/actionTypes.es6"

export const getAllPoliciesRequest = (customerGuid) =>{
  return{
    type: types.GET_ALL_CUSTOMER_POLICIES_REQUEST,
    payload: customerGuid,
  }
}

export const getAllPoliciesSuccess = (payload) =>{
  return{
    type: types.GET_ALL_CUSTOMER_POLICIES_SUCCESS,
    payload: payload,
  }
}

export const getAllPoliciesFailure = (error) =>{
  return{
    type: types.GET_ALL_CUSTOMER_POLICIES_FAILURE,
    payload: error,
  }
}


export function getCustomerQuotesRequest(payload) {
  return {
    type: types.VIEW_CUSTOMER_QUOTES_REQUEST,
    payload,
  };
}

export function getCustomerQuotesSuccess(response) {
  return {
    type: types.VIEW_ALL_CUSTOMER_QUOTES_SUCCESS,
    payload: response,
  };
}

export function getCustomerQuotesFailure(error) {
  return {
    type: types.VIEW_CUSTOMER_QUOTES_FAILURE,
    payload: error,
  };
}



export const getAllCustomerPremiumsRequest = (payload) =>{
  return{
    type: types.GET_ALL_CUSTOMER_PREMIUMS_REQUEST,
    payload,
  }
}

export const getAllPremiumsSuccess = (payload) =>{
  return{
    type: types.GET_ALL_CUSTOMER_PREMIUMS_SUCCESS,
    payload: payload,
  }
}
export const getAllPremiumsFailure = (error) =>{
  return{
    type: types.GET_ALL_CUSTOMER_PREMIUMS_FAILURE,
    payload: error,
  }
}


export const cancelV2PolicyRequest = (payload) =>{
  return{
    type: types.CANCEL_V2_POLICY_REQUEST,
    payload
  }
}

export const cancelV2PolicySuccess =  (response) =>{
  return{
    type: types.CANCEL_V2_POLICY_SUCCESS,
    payload: response
  }
}

export const cancelV2PolicyFailure = (error) =>{
  return{
    type: types.CANCEL_V2_POLICY_FAILURE,
    payload: error
  }
}

export const resetCancelV2PolicySuccess = () =>{
  return{
    type: types.RESET_CANCEL_V2_POLICY_SUCCESS
  }
}

export const resetCancelV2PolicyError = () =>{
  return{
    type: types.RESET_CANCEL_V2_POLICY_ERROR
  }
}

export function setPolicyV2ToCancel(response) {
  return {
    type: types.SET_V2_POLICY_TO_CANCEL,
    payload: response,
  };
}

export function resetPolicyV2ToCancel(response) {
  return {
    type: types.RESET_V2_POLICY_TO_CANCEL,
  };
}


export function policyNotesRequest(payload) {
  return {
    type: types.POLICY_NOTES_REQUEST,
    payload,
  };
}

export function policyNotesSuccess(response) {
  return {
    type: types.POLICY_NOTES_SUCCESS,
    payload: response,
  };
}

export function policyNotesFailure(error) {
  return {
    type: types.POLICY_NOTES_FAILURE,
    payload: error,
  };
}


export function resetPolicyNotesSuccess(error) {
  return {
    type: types.RESET_POLICY_NOTES_SUCCESS,
  };
}

export function resetPolicyNotesError(error) {
  return {
    type: types.RESET_POLICY_NOTES_ERROR,
  };
}


export function policyNotesAddRequest(payload) {
  return {
    type: types.POLICY_NOTES_ADD_REQUEST,
    payload,
  };
}

export function policyNotesAddSuccess(response) {
  return {
    type: types.POLICY_NOTES_ADD_SUCCESS,
    payload: response,
  };
}

export function policyNotesAddFailure(error) {
  return {
    type: types.POLICY_NOTES_ADD_FAILURE,
    payload: error,
  };
}


export function resetPolicyNotesAddSuccess(error) {
  return {
    type: types.RESET_POLICY_NOTES_ADD_SUCCESS,
  };
}

export function resetPolicyNotesAddError(error) {
  return {
    type: types.RESET_POLICY_NOTES_ADD_ERROR,
  };
}



export function setPolicyNoteToAdd(response) {
  return {
    type: types.SET_POLICY_TO_ADD,
    payload: response,
  };
}

export function resetPolicyNoteToAdd(response) {
  return {
    type: types.RESET_POLICY_NOTE_TO_ADD,
  };
}



export function setPolicyNoteToView(response) {
  return {
    type: types.SET_POLICY_NOTE_TO_VIEW,
    payload: response,
  };
}

export function resetPolicyNoteToView(response) {
  return {
    type: types.RESET_POLICY_NOTE_TO_VIEW,
  };
}