import * as React from "react";
import Grid from "@mui/material/Grid";
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../../lib/constants";
import MainPageTitle from "../../../../components/MainPageTitle/index.es6";

export default function ProductsHeader(props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        {/* <h1 style={{ fontWeight: 'bolder', color: PRIMARY_THEME_COLOR_SHADE_1 }}>{props.title ? props.title : 'sample titile'}</h1> */}
        <MainPageTitle pageTitle={props.title ? props.title : 'sample titile'} />

      </Grid>
      {
        props.showAddButton && <Grid item xs={3} style={{ textAlign: 'right' }}>
          <Fab onClick={props.onAddButtonClick} color="secondary" aria-label="add" style={{ color: 'white', background: PRIMARY_THEME_COLOR_SHADE_1, textAlign: 'right' }}>
            <AddIcon />
          </Fab>
        </Grid>
      }

      {
        props.showEditButton && <Grid item xs={3} style={{ textAlign: 'right' }}>
          <Fab onClick={props.onEditButtonClick} color="secondary" aria-label="add" style={{ color: 'white', background: PRIMARY_THEME_COLOR_SHADE_1, textAlign: 'right' }}>
            <EditIcon />
          </Fab>
        </Grid>
      }


    </Grid>
  );
}
