import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../lib/actionTypes.es6';
import {
  getAllGroups, addGroupPayment, deleteGroup
} from '../../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../../lib/access.es6';
import { GAloggerException } from '../../../lib/monitoring.es6';


export function* getAllGroupsRequestWatcher() {

  while (true) {
    try {
      const { payload } = yield take(types.GET_ALL_GROUPS_REQUEST);
      const response = yield call(getAllGroups, payload);
      yield put(actions.getAllGroupsSuccess(response.data));
    } catch (e) {
      yield put(
        actions.getAllGroupsFailed(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* addGroupPaymentRequestWatcher() {

  while (true) {
    try {
      const { payload } = yield take(types.ADD_GROUP_PAYMENT_REQUEST);

      const response = yield call(addGroupPayment, payload);
      yield put(actions.addGroupPaymentSuccess(response.data));
    } catch (e) {
      let errorPayload = { message: 'An error occurred, this could be due to invalid data' };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      } yield put(actions.addGroupPaymentFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* deleteGroupRequestWatcher() {

  while (true) {
    try {
      const { payload } = yield take(types.DELETE_GROUP_REQUEST);
      const response = yield call(deleteGroup, payload.groupId);
      yield put(actions.deleteGroupSuccess(response.data));
      yield put(actions.getAllGroupsRequest(payload));
    } catch (e) {
      yield put(
        actions.deleteGroupFailed(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
