import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';

import Loader from 'react-loader';
import LocalizedStrings from 'react-localization';
import { hashHistory } from 'react-router';

import { getCustomer } from '../Customer/actions.es6';
import { claimEnumRequest } from '../ClaimSubmission/actions.es6';
import ClaimOverview from '../../components/ClaimOverview/index.es6';
import ClaimBenefits from '../../components/ClaimBenefits/index.es6';
import ClaimProcessingButtons from '../../components/ClaimProcessingButtons/index.es6';
import ClaimProcessingUpload from '../../components/ClaimProcessingUpload/index.es6';
import ClaimPayments from '../../components/ClaimPayments/index.es6';
import { buildFailureMessage, reloadPage } from '../../lib/utils.es6';
import {
  storeCurrentClaimGuid,
  partnerCanPayClaim,
  hasAuthourity,
  hasAIClaimAssessor,
} from '../../lib/access.es6';
import * as actions from './actions.es6';
import * as globalActions from '../Main/actions.es6';
import { localisedText } from '../../lib/localisation.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import NoRecord from '../../components/NoRecord/index.es6';
import Claims from '../../components/Claims/index.es6';
import ClaimForm from '../../components/ClaimForm/index.es6';
import '../../App.css';
import { CircularProgress } from '@mui/material';
import { getClaimAssessments, searchClaim } from '../../lib/requests.es6';
import { ViewAssessment } from './components/ViewAssessment';

// TODO: Rename all reference to 'birthday' to 'personAffectedDob'
// TODO: Stop using the string 'Please select'
// TODO: This class is huge
export class ClaimsProcessing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validationErrors: [],
      validationErrorFields: [],
      openRejection: false,
      labels: new LocalizedStrings(localisedText),
      rejectionReason: '',
      rejectionReasonError: '',
      openWarningPopUp: false,
      targetStatus: null,
      WaitingPeriodPayment: false,
      HighRiskHospital: false,
      HighRiskOnApprove: false,
      loading: false,
      openAssessmentDialog: false,
      assessments: [ ],
    };
  }

  fetchClaim = async (claim_guid) => {
    this.setState({ claim: null, loading: true });
    try {
      const response = await searchClaim({ guid: claim_guid });
      this.setState({ claim: response.data[0], loading: false });
      await this.props.dispatch(actions.selectClaim(response.data[0]));
      await this.props.dispatch(getCustomer(response.data[0].customer_guid));


    } catch (error) {
      console.error('Error fetching suggestions:', error);
      this.setState({ claim: null, loading: false });

    }
  };

  fetchClaimAssessments = async (claim_guid) => {
    this.setState({ assessments: [] });
    try {
      const response = await getClaimAssessments(claim_guid);
      this.setState({ assessments: response.data });
      console.log('Assessments:', response.data);
    } catch (error) {
      console.error('Error fetching assesments:', error);
      this.setState({ assessments: [] });
    }
  };

  componentWillMount() {
    let claim_guid = this.props.params.claim_guid;
    this.fetchClaim(claim_guid)
    this.fetchClaimAssessments(claim_guid)

    this.updateLanguage();
    this.props.dispatch(globalActions.hideCustomerBar());

    const currentCustomer = this.props.customerData.currentCustomer;
    const claimCustomer = this.props.claimData.currentCustomerClaim;
    if (
      !currentCustomer ||
      currentCustomer.msisdn !== (claimCustomer || {}).customer_msisdn
    ) {
      if (claimCustomer) {
        this.props.dispatch(getCustomer(claimCustomer.customer_guid));
      }
    }
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  componentDidMount() {
    if (this.props.claimData.documentTypes.length <= 1) {
      this.props.dispatch(claimEnumRequest());
    }
    if (this.props.claimData.claimsHospital.length <= 1) {
      this.props.dispatch(actions.claimHospitalsRequest());
    }
    if (this.props.claimData.claimRejectionReasons.length <= 1) {
      // TODO: Use customer selected language
      const payload = { language: 'en' };
      this.props.dispatch(actions.claimRejectionReasonRequest(payload));
    }

    const claimConditionList = this.props.claimData.claimConditionList
      ? this.props.claimData.claimConditionList
      : [];
    if (claimConditionList.length <= 1) {
      this.props.dispatch(actions.claimConditionsRequest());
    }
    this.props.dispatch(globalActions.hideCustomerBar());
    this.initialiseState();
  }

  // TODO: This looks like logic that should seat in constructor
  initialiseState() {
    const claimData = this.props.claimData.currentCustomerClaim;
    const benefits = claimData ? claimData.benefits : [];
    const guid = claimData
      ? claimData.guid
        ? claimData.guid
        : undefined
      : undefined;
    storeCurrentClaimGuid(guid);
    const selectedRows = [];

    let benefit;
    for (let i = 0; i < benefits.length; i++) {
      benefit = benefits[i];
      if (benefit.approved === true) {
        selectedRows.push(i);
      }
    }
    this.props.dispatch(actions.setSelectedPolicies(selectedRows));
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'ClaimsOpen',
      this.state.labels
    );
  }

  updateClaimUpload(payload) {
    this.props.dispatch(actions.createClaimFileRequest(payload));
  }

  deleteClaimFileUpload(payload) {
    this.props.dispatch(actions.deleteClaimFileRequest(payload));
  }

  // TODO: We should enable buttons instead i.e. have them disabled by default
  commentReadonly(statusIn) {
    let status = statusIn;
    if (!status) {
      status = this.state.status;
    }
    // TODO: this list should come from the API. i.e. not hard coded
    return ['Approved_Paid', 'ExGratia_Paid', 'Rejected'].includes(status);
  }

  updateClaimBenefits(rows) {
    const claimData = this.props.claimData.currentCustomerClaim;
    this.props.dispatch(actions.setSelectedPolicies(rows));

    const selectedBenefits = rows;
    const benefits = claimData.benefits ? claimData.benefits : [];
    for (let i = 0; i < benefits.length; i++) {
      claimData.benefits[i].approved = selectedBenefits.includes(i);
    }
    this.props.dispatch(actions.processClaimRequest(claimData));
  }

  computeSelectedClaimBenefitTotal() {
    const claimData = this.props.claimData.currentCustomerClaim;
    const benefits = claimData ? claimData.benefits : [];

    let totalAmount = 0;
    let benefit;
    for (let i = 0; i < benefits.length; i++) {
      benefit = benefits[i];
      if (
        benefit.approved === true &&
        this.props.claimData.selectedPolicies.includes(i)
      ) {
        totalAmount += this.totalBenefitAmount(benefit);
      }
    }
    return totalAmount;
  }

  saveClaimForm(claimData) {
    this.props.dispatch(actions.processClaimRequest(claimData));
  }

  checkDocReceivedRequiredFields(targetStatus) {
    const docReceivedStatuses = this.props.claimData.docReceivedStatuses;
    let validationErrors = [];
    let validationErrorFields = [];
    if (docReceivedStatuses.includes(targetStatus)) {
      const claimData = this.props.claimData.currentCustomerClaim;
      const type = claimData.type;
      const allDocReceivedRequiredFields = this.props.claimData
        .docReceivedRequiredFields;
      const docReceivedRequiredFields = allDocReceivedRequiredFields
        ? allDocReceivedRequiredFields[type]
        : [];
      if (docReceivedRequiredFields && docReceivedRequiredFields.includes('doc_channel')) {
        const result = this.checkDocChannel(
          validationErrors,
          validationErrorFields
        );
        validationErrors = result.validationErrors;
        validationErrorFields = result.validationErrorFields;
      }
      if (docReceivedRequiredFields && docReceivedRequiredFields.includes('verify_by')) {
        const result = this.checkVerifyBy(
          validationErrors,
          validationErrorFields
        );
        validationErrors = result.validationErrors;
        validationErrorFields = result.validationErrorFields;
      }
      if (docReceivedRequiredFields && docReceivedRequiredFields.includes('documentation_received_time')) {
        const result = this.checkDocumentationReceivedTime(
          validationErrors,
          validationErrorFields
        );
        validationErrors = result.validationErrors;
        validationErrorFields = result.validationErrorFields;
      }
      if (docReceivedRequiredFields && docReceivedRequiredFields.includes('person_affected_dob')) {
        const result = this.checkPersonAffectedDobTime(
          validationErrors,
          validationErrorFields
        );
        validationErrors = result.validationErrors;
        validationErrorFields = result.validationErrorFields;
      }
      if (docReceivedRequiredFields && docReceivedRequiredFields.includes('hospital_name')) {
        const result = this.checkHospitalName(
          validationErrors,
          validationErrorFields
        );
        validationErrors = result.validationErrors;
        validationErrorFields = result.validationErrorFields;
      }
      if (docReceivedRequiredFields && docReceivedRequiredFields.includes('hospitalization_reason')) {
        const result = this.checkHospitalizationReason(
          validationErrors,
          validationErrorFields
        );
        validationErrors = result.validationErrors;
        validationErrorFields = result.validationErrorFields;
      }
      if (docReceivedRequiredFields && docReceivedRequiredFields.includes('birth_certificate_valid')) {
        const result = this.checkValidBirthCertificate(
          validationErrors,
          validationErrorFields
        );
        validationErrors = result.validationErrors;
        validationErrorFields = result.validationErrorFields;
      }
      if (docReceivedRequiredFields && docReceivedRequiredFields.includes('condition')) {
        const result = this.checkCondition(
          validationErrors,
          validationErrorFields
        );
        validationErrors = result.validationErrors;
        validationErrorFields = result.validationErrorFields;
      }
      if (docReceivedRequiredFields && docReceivedRequiredFields.includes('accidental')) {
        const result = this.checkAccident(
          validationErrors,
          validationErrorFields
        );
        validationErrors = result.validationErrors;
        validationErrorFields = result.validationErrorFields;
      }
    }
    const valid = validationErrors.length === 0;
    if (valid === false) {
      this.setState({ validationErrors, validationErrorFields });
    }
    return valid;
  }

  checkValidBirthCertificate(validationErrors, validationErrorFields) {
    const labels = this.state.labels;
    const relationToMainMember = this.props.claimData.currentCustomerClaim
      .relation_to_main_member;
    if (
      relationToMainMember === 'Child' &&
      document.getElementById('validBirthCertificate').checked !== true
    ) {
      validationErrors.push(labels.errorValidBirthCertificate);
      validationErrorFields.push('birth_certificate_valid');
    }
    return { validationErrors, validationErrorFields };
  }

  checkHospitalizationReason(validationErrors, validationErrorFields) {
    const labels = this.state.labels;
    const claimData = this.props.claimData.currentCustomerClaim;
    if (!claimData.hospitalization_reason) {
      validationErrors.push(labels.errorMissingHospitalizationReason);
      validationErrorFields.push('hospitalization_reason');
    }
    return { validationErrors, validationErrorFields };
  }

  checkHospitalName(validationErrors, validationErrorFields) {
    const labels = this.state.labels;
    const claimData = this.props.claimData.currentCustomerClaim;
    if (!claimData.hospital_name) {
      validationErrors.push(labels.errorMissingHospitalName);
      validationErrorFields.push('hospital_name');
    }
    return { validationErrors, validationErrorFields };
  }

  checkDocChannel(validationErrors, validationErrorFields) {
    const labels = this.state.labels;
    const claimData = this.props.claimData.currentCustomerClaim;
    if (!claimData.doc_channel) {
      validationErrors.push(labels.errorMissingDocChannel);
      validationErrorFields.push('doc_channel');
    }
    return { validationErrors, validationErrorFields };
  }

  checkVerifyBy(validationErrors, validationErrorFields) {
    const labels = this.state.labels;
    const claimData = this.props.claimData.currentCustomerClaim;

    if (!claimData.verify_by) {
      validationErrors.push(labels.errorMissingVerification);
      validationErrorFields.push('verify_by');
    }
    return { validationErrors, validationErrorFields };
  }

  checkDocumentationReceivedTime(validationErrors, validationErrorFields) {
    const claimData = this.props.claimData.currentCustomerClaim;
    const labels = this.state.labels;
    if (!claimData.documentation_received_time) {
      validationErrors.push(labels.errorMissingDocReceivedTime);
      validationErrorFields.push('documentation_received_time');
    }
    return { validationErrors, validationErrorFields };
  }

  checkPersonAffectedDobTime(validationErrors, validationErrorFields) {
    const labels = this.state.labels;
    const claimData = this.props.claimData.currentCustomerClaim;
    const relationToMainMember = claimData.relation_to_main_member;
    if (relationToMainMember === 'Child' && !claimData.person_affected_dob) {
      validationErrors.push(labels.errorMissingAffectedPersonDob);
      validationErrorFields.push('person_affected_dob');
    }
    return { validationErrors, validationErrorFields };
  }

  checkCondition(validationErrors, validationErrorFields) {
    const labels = this.state.labels;
    const claimData = this.props.claimData.currentCustomerClaim;
    if (claimData.condition === '') {
      validationErrors.push(labels.errorMissingCondition);
      validationErrorFields.push('condition');
    }
    return { validationErrors, validationErrorFields };
  }

  checkAccident(validationErrors, validationErrorFields) {
    const labels = this.state.labels;
    const claimData = this.props.claimData.currentCustomerClaim;

    if (
      claimData.accidental === null ||
      claimData.accidental === undefined ||
      claimData.accidental === ''
    ) {
      validationErrors.push(labels.errorMissingAccident);
      validationErrorFields.push('accidental');
    }
    return { validationErrors, validationErrorFields };
  }

  resetValidationErrors() {
    if (this.state.validationErrors.length !== 0) {
      this.setState({ validationErrors: [], validationErrorFields: [] });
    }
  }

  showWaitingPeriodWarning(status) {
    return this.isApproval(status) && this.containsWaitingPeriodBenefit();
  }

  isApproval(status) {
    return ['Approved', 'Approved_Pending'].includes(status);
  }

  isAssessorStatus(targetStatus) {
    return [
      'Approved',
      'Approved_Pending',
      'ExGratia',
      'ExGratia_Pending',
    ].includes(targetStatus);
  }

  containsWaitingPeriodBenefit() {
    const claimData = this.props.claimData.currentCustomerClaim;
    const benefits = claimData.benefits;
    const benefitsWaitingPeriod = benefits.filter(
      benefit =>
        benefit.type === 'Waiting_Period_Benefit' && benefit.approved === true
    );
    return benefitsWaitingPeriod.length > 0;
  }

  changeClaimStatus(targetStatus, claimData) {
    // TODO: Rename the local name 'claimData', quite confusing currently
    claimData = claimData || this.props.claimData.currentCustomerClaim;
    const currentStatus = claimData.status;
    if (!claimData) {
      return;
    }

    this.resetValidationErrors();

    const valid = this.checkDocReceivedRequiredFields(targetStatus);
    if (valid === false) {
      return;
    }

    if (
      targetStatus === 'Rejected_Pending' &&
      this.state.openRejection === false
    ) {
      this.handleOpenRejectionDialog();
      return;
    }
    if (
      targetStatus === 'Rejected_Pending' &&
      (!this.state.rejectionReason ||
        this.state.rejectionReason === 'Please Select')
    ) {
      this.setState({
        rejectionReasonError: 'Please select a rejection reason',
      });
      return;
    }
    this.setState({ status: targetStatus });

    this.handleCloseRejectionDialog();

    if (targetStatus !== currentStatus && this.isAssessorStatus(targetStatus)) {
      if (
        this.displayHospitalHighRiskPopUp(claimData.hospital_name) &&
        !this.showWaitingPeriodWarning(targetStatus)
      ) {
        this.setState({ HighRiskOnApprove: true, targetStatus });
        return;
      }
      if (claimData.customer_is_high_risk) {
        this.setState({ openWarningPopUp: true, targetStatus });
        return;
      }
    }

    if (this.showWaitingPeriodWarning(targetStatus)) {
      this.handleWaitingPeriodPaymentOrRiskHospitalDialogOpen();
      this.setState({ targetStatus });
    } else {
      this.props.dispatch(
        actions.processClaimRequest({ ...claimData, status: targetStatus })
      );
    }
  }

  reverseClaim() {
    const claimData = this.props.claimData.currentCustomerClaim;
    if (
      claimData &&
      claimData.status === 'Expired' &&
      hasAuthourity('REVERT_EXPIRED_CLAIM')
    ) {
      this.props.dispatch(actions.expiredClaimReversalRequest(claimData));
    }
  }

  displayHospitalHighRiskPopUp(hospitalName) {
    const hospital = this.props.claimData.claimsHospital.find(
      element => element.name === hospitalName
    );
    if (hospital && hospital.high_risk) {
      this.setState({ openWarningPopUp: true, HighRiskHospital: true });
      return true;
    }
    return false;
  }

  selectRejectionReason() {
    this.setState({
      rejectionReason: document.getElementById('rejection_reasons').value,
    });
  }

  rejectionReasonDisplay() {
    const labels = this.state.labels;
    const options = this.props.claimData.claimRejectionReasons.map(
      reason => reason.reason
    );
    return (
      <div className="form-group">
        <label
          htmlFor="rejection_reason"
          className="control-label col-md-4 col-sm-4 col-xs-12"
        >
          {' '}
          {labels.rejectionReason}
        </label>
        <div className="col-md-8 col-sm-8 col-xs-12">
          <select
            className="form-control co"
            id="rejection_reasons"
            onChange={this.selectRejectionReason.bind(this)}
          >
            {options.map((reason, index) => (
              <option key={index} value={reason}>
                {reason}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }

  handleCommentChange(e) {
    this.setState({ comments: e.target.value });
  }

  displayRejectionReasons() {
    const claim = this.props.claimData.currentCustomerClaim;
    if (claim) {
      if (claim.rejection_reason) {
        return (
          <div>
            <center>
              <h4 style={{ color: 'red' }}>
                {this.state.labels.rejectionReason}: {claim.rejection_reason}
              </h4>
            </center>
          </div>
        );
      }
    }
    return null;
  }

  moneyTransactionDisplay() {
    const labels = this.state.labels;
    const claim = this.props.claimData.currentCustomerClaim;
    const finalApprovedStatus = this.props.claimData.final_approved_status;
    if (!finalApprovedStatus.includes(claim.status) || !partnerCanPayClaim()) {
      return '';
    }
    return (
      <ClaimPayments
        payments={claim.payouts}
        claimStatus={claim.status}
        labels={labels}
        retryFailedPaymentHandler={this.retryFailedPayment.bind(this)}
        updatePaymentOutcomeHandler={this.updatePaymentOutcome.bind(this)}
      />
    );
  }

  totalBenefitAmount(benefit) {
    return benefit.night
      ? benefit.night * benefit.amount_benefit
      : benefit.amount_benefit;
  }

  failureMessage() {
    const defaultErrorMessage = 'Something went wrong';
    const errorMessage = buildFailureMessage(
      defaultErrorMessage,
      this.props.claimData.errors
    );
    return errorMessage;
  }

  successMessage() {
    const successMessage = 'Claim successfully processed';
    return successMessage;
  }

  notificationsDisplay() {
    const resultError = {
      display: this.props.claimData.errors ? 'block' : 'none',
    };
    return (
      <div>
        <center style={resultError}>
          <p className="error">{this.failureMessage()} </p>
        </center>
        <center
          style={{
            display:
              this.state.validationErrors.length !== 0 ? 'block' : 'none',
          }}
        >
          <ul className="error" style={{ width: '50%' }}>
            {this.state.validationErrors.map((error, index) => (
              <li key={index} style={{ textAlign: 'left' }}>
                {error}
              </li>
            ))}
          </ul>
        </center>
      </div>
    );
  }

  handleCloseRejectionDialog() {
    this.setState({ openRejection: false });
  }

  handleOpenRejectionDialog() {
    this.setState({ openRejection: true });
  }

  confirmRejection() {
    this.changeClaimStatus('Rejected_Pending');
  }

  handleWaitingPeriodPaymentOrRiskHospitalDialogOpen() {
    this.setState({ openWarningPopUp: true, WaitingPeriodPayment: true });
  }

  approveWaitingPeriodPaymentOrHighRiskHospital() {
    const claimData = this.props.claimData.currentCustomerClaim;
    if (this.state.WaitingPeriodPayment) {
      const targetStatus = this.state.targetStatus;
      this.setState({ status: targetStatus });
      this.props.dispatch(
        actions.processClaimRequest({ ...claimData, status: targetStatus })
      );
      this.setState({ openWarningPopUp: false, WaitingPeriodPayment: false });
    } else if (this.state.HighRiskHospital && this.state.HighRiskOnApprove) {
      const targetStatus = this.state.targetStatus;
      this.setState({ status: targetStatus });
      this.props.dispatch(
        actions.processClaimRequest({ ...claimData, status: targetStatus })
      );
      this.setState({
        openWarningPopUp: false,
        HighRiskHospital: false,
        HighRiskOnApprove: false,
      });
    } else if (claimData.customer_is_high_risk) {
      const targetStatus = this.state.targetStatus;
      this.setState({ status: targetStatus });
      this.props.dispatch(
        actions.processClaimRequest({ ...claimData, status: targetStatus })
      );
      this.setState({ openWarningPopUp: false });
    } else if (this.state.HighRiskHospital) {
      this.setState({ openWarningPopUp: false, HighRiskHospital: false });
    }
  }

  handleWaitingPeriodPaymentDialogClose() {
    if (this.state.WaitingPeriodPayment) {
      this.setState({
        status: this.props.claimData ? this.props.claimData.status : undefined,
      });
    }
    this.setState({
      openWarningPopUp: false,
      WaitingPeriodPayment: false,
      HighRiskHospital: false,
    });
  }

  getWarningDialogButtonLabel() {
    const labels = this.state.labels;
    const claim = this.props.claimData.currentCustomerClaim;
    const buttonLabels = {};
    if (this.state.WaitingPeriodPayment) {
      buttonLabels.accept = labels.approvedClaimWarningButtonAccept;
      buttonLabels.reject = labels.approvedClaimWarningButtonReject;
      return buttonLabels;
    }
    if (claim.customer_is_high_risk) {
      buttonLabels.accept = labels.highRiskCustomerApproveBtn;
      buttonLabels.reject = labels.highRiskCustomerRejectBtn;
      return buttonLabels;
    }
    if (this.state.HighRiskHospital) {
      buttonLabels.accept = labels.highRiskWarningButtonAccept;
      buttonLabels.reject = labels.highRiskWarningButtonReject;
      return buttonLabels;
    }
    return buttonLabels;
  }

  confirmWaitingPeriodPaymentOrHighRiskHospitalDialog() {
    const labels = this.state.labels;
    const claim = this.props.claimData.currentCustomerClaim;
    const warningBtnLabel = this.getWarningDialogButtonLabel();
    const confirmWaitingPeriodPaymentActions = [
      <button
        className="buttonDanger"
        onClick={this.approveWaitingPeriodPaymentOrHighRiskHospital.bind(this)}
      >
        {warningBtnLabel.accept}
      </button>,

      <button
        className="buttonGreen"
        onClick={this.handleWaitingPeriodPaymentDialogClose.bind(this)}
      >
        {' '}
        {warningBtnLabel.reject}{' '}
      </button>,
    ];
    return (
      <div>
        <Dialog
          open={this.state.openWarningPopUp}
          onClose={this.handleWaitingPeriodPaymentDialogClose.bind(this)}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">Warning</DialogTitle>
          <DialogContent>
            <ul style={{ listStyleType: 'circle' }}>
              {this.state.WaitingPeriodPayment ? (
                <li>
                  <p style={{ color: 'red' }}>{labels.approvedClaimWarning}</p>
                </li>
              ) : (
                <div />
              )}
              {this.state.HighRiskHospital ? (
                <li>
                  <p
                    style={{ color: 'red' }}
                  >{`${this.state.hospital_name} ${labels.approveClaimWithHighRiskHospital}`}</p>
                </li>
              ) : (
                <div />
              )}
              {claim.customer_is_high_risk ? (
                <li>
                  <p style={{ color: 'red' }}>{labels.highRiskCustomer}</p>
                </li>
              ) : (
                <div />
              )}
            </ul>
          </DialogContent>
          <DialogActions>
            {confirmWaitingPeriodPaymentActions.map((item, index) => (
              <div key={index}> {item}</div>
            ))}
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  redirectToCustomerStatusPage(customerGuid) {
    this.props.dispatch(getCustomer(customerGuid));
    hashHistory.push('admin/customer_status/' + customerGuid);
  }

  confirmRejectionDialog() {
    const labels = this.state.labels;

    const confirmActions = [
      <button
        className="buttonDanger"
        onClick={this.confirmRejection.bind(this)}
      >
        {labels.rejectClaimBtn}
      </button>,

      <button
        className="buttonGreen"
        onClick={this.handleCloseRejectionDialog.bind(this)}
      >
        Cancel
      </button>,
    ];
    return (
      <Dialog
        open={this.state.openRejection}
        onClose={this.handleCloseRejectionDialog.bind(this)}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {labels.claimRejectionReason}
        </DialogTitle>
        <DialogContent>
          <form
            id="rejectionDialogForm"
            className="form-horizontal form-label-left"
            noValidate
          >
            <p style={{ color: 'red' }}>{this.state.rejectionReasonError} </p>
            {this.rejectionReasonDisplay()}
            <div className="form-group">
              <label
                htmlFor="commentsDialog"
                className="control-label col-md-4 col-sm-4 col-xs-12"
              >
                {labels.claimComment}
              </label>
              <div className="col-md-8 col-sm-8 col-xs-12">
                <textarea
                  id="commentsDialog"
                  name="commentsDialog"
                  required
                  className="form-control col-md-7 col-xs-12"
                  type="text"
                  disabled={this.commentReadonly()}
                  value={this.state.comments}
                  onChange={this.handleCommentChange.bind(this)}
                />
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          {confirmActions.map((item, index) => (
            <div key={index}> {item}</div>
          ))}
        </DialogActions>
      </Dialog>
    );
  }

  retryFailedPayment(paymentGuid) {
    this.props.dispatch(actions.retryFailedClaimPaymentRequest(paymentGuid));
  }

  updatePaymentOutcome(payload) {
    this.props.dispatch(actions.claimPaymentUpdateOutcomeRequest(payload));
  }

  renderClaims() {
    const labels = this.state.labels;
    const claimData = this.props.claimData;

    const showAssessmentButton = hasAIClaimAssessor()
      && hasAuthourity('VIEW_ASPIN_AI_ASSESSMENTS')
      && this.state.assessments.length > 0;

    if (claimData.loader || this.state.loading) {
      return <div style={{ textAlign: 'center' }}> <CircularProgress /></div>
    }
    else if (claimData.currentCustomerClaim) {
      return (
        <div className="claim-info">
          <ViewAssessment
            open={this.state.openAssessmentDialog}
            assessments={this.state.assessments}
            onClose={() => this.setState({ openAssessmentDialog: false })}
          />
          <ClaimOverview
            claimRefresh={this.refreshClaim.bind(this)}
            redirectToCustomerStatusPageHandler={this.redirectToCustomerStatusPage.bind(
              this
            )}
            data={claimData.currentCustomerClaim}
            fields={claimData.fields}
            labels={labels}
            showAssessmentButton={showAssessmentButton}
            toggleAssesmentDialog={() => this.setState({ openAssessmentDialog: !this.state.openAssessmentDialog })}
          />
          <br />
          {this.notificationsDisplay()}
          <ClaimForm
            globalData={this.props.globalData}
            claimData={this.props.claimData}
            labels={this.state.labels}
            onUpdateHandle={this.saveClaimForm.bind(this)}
            validationErrors={this.state.validationErrors}
            validationErrorFields={this.state.validationErrorFields}
          />
          <br />
          <div className="grouping row">
            <ClaimProcessingUpload
              files={claimData.currentCustomerClaim.received_files.filter(
                file => file.active
              )}
              labels={labels}
              claimGuid={claimData.currentCustomerClaim.guid}
              claimStatus={claimData.currentCustomerClaim.status}
              updateClaimUploadHandler={this.updateClaimUpload.bind(this)}
              deleteClaimFileUpload={this.deleteClaimFileUpload.bind(this)}
              claimFilesLoader={this.props.claimData.claimFilesLoader}
            />
          </div>
          <br />

          <div className="grouping">{this.moneyTransactionDisplay()}</div>
          <br />

          <div className="grouping">{this.displayRejectionReasons()}</div>

          <br />
          <ClaimBenefits
            labels={labels}
            claim={claimData.currentCustomerClaim}
            rowSelection={this.updateClaimBenefits.bind(this)}
            selectedRows={claimData.selectedPolicies}
            totalBenefitAmount={this.totalBenefitAmount}
          />

          <br />
          {this.confirmRejectionDialog()}
          {this.confirmWaitingPeriodPaymentOrHighRiskHospitalDialog()}
          <br />
          <div className="grouping row">
            <Claims
              columnWidth="col-md-12"
              extended
              showMore={false}
              title={labels.claimHistory}
              claims={this.props.customerData.claims}
              claimRefresh={this.refreshClaim.bind(this)}
            />
          </div>
          <br />
          <ClaimProcessingButtons
            labels={labels}
            onClickHandle={this.changeClaimStatus.bind(this)}
            total={this.computeSelectedClaimBenefitTotal()}
            claim={claimData.currentCustomerClaim}
            payable_status={claimData.payable_status}
            reverseClaim={this.reverseClaim.bind(this)}
          />
        </div>
      );
    }
    return (
      <NoRecord
        noTextLabel={labels.noClaimSelected}
        searchLabel={labels.searchClaim}
        redirectTo="admin/claim_search"
      />
    );
  }

  refreshClaim(claimGuid) {
    reloadPage();
  }

  render() {
    return (
      <Loader loaded={!this.props.claimData.loader || this.state.loading} color="#ff834f">
        {this.renderClaims()}
      </Loader>
    );
  }
}

export default connect(state => ({
  claimData: state.processingClaim,
  globalData: state.global,
  customerData: state.currentCustomer,
}))(ClaimsProcessing);
