import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({

    footer: {
        padding: "50px 0 30px 0",
        background: "#032A37",
        "& h3": {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "24px",
            lineHeight: "32px",
            color: "#FFFFFF",
        },
        "& ul": {
            "& li": {
                color: '#fff',
                "& a": {
                    color: '#fff'
                },
            }
        },
        "& ol": {
            padding: "0",
            "& li": {
                listStyle: "none",
                marginBottom: "15px",
                "& a": {
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "24px",
                    textAlign: "center",
                    letterSpacing: " 0.005em",
                    color: " #FFFFFF"
                }
            }
        }
    },
    socialmedia: {
        "& li": {
            listStyle: "none",
            float: "left",
            textAlign: "center",
            width: "40px",
            height: "40px",
            borderRadius: "100%",
            lineHeight: "40px",
            background: "#D9D9D9",
            marginLeft: "10px",
            "& a": {
                "& img": {
                    width: "16px"
                }
            }
        }
    },
    copyrights: {
        borderTop: "1px solid #fff",
        marginTop: "40px",
        "& p": {
            marginTop: "20px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "15px",
            textAlign: "center",
            color: " #FFFFFF"
        }
    }
}));