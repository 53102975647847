import React from 'react';
import { connect } from 'react-redux';
import DebitOrderTransactionsComponent from '../../components/DebitOrderTransactions/index.es6';
import * as globalActions from '../Main/actions.es6';
import * as actions from './actions.es6';

export class DebitOrderTransactions extends React.Component {
  componentWillMount() {
    this.props.dispatch(globalActions.hideCustomerBar());
    this.props.dispatch(actions.getDebitOrderTransactionsRequest());
  }

  updateTransactions(payload) {
    this.props.dispatch(actions.updateDebitOrderTransactionsRequest(payload));
  }

  render() {
    return (
      <div className="container">
        <DebitOrderTransactionsComponent
          updateTransactions={this.updateTransactions.bind(this)}
          debitOrderTransactions={
            this.props.debitOrderTransactionData.debitOrderTransactions || []
          }
        />
      </div>
    );
  }
}

export default connect(state => ({
  globalData: state.global,
  debitOrderTransactionData: state.debitOrderTransactions,
}))(DebitOrderTransactions);
