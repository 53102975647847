import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  customerErrors: null,
  customerList: null,
  customerLoader: false,
  searchFinishedFlag: true,
  count: 0,
  size: 50,
  activeSearchCriteria: null,
  msisdn: null,
  name: null,
  national_id: null,
  external_identifier: null,
};

export default function searchCustomerByMsisdnReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case 'SEARCH_CUSTOMER_BY_MSISDN_REQUEST':
      return {
        ...state,
        customerLoader: true,
        customerErrors: null,
        msisdn: action.payload.msisdn,
        name: action.payload.name,
        national_id: action.payload.national_id,
        external_identifier: action.payload.external_identifier,
        activeSearchCriteria: action.payload.activeSearchCriteria,
      };
    case 'UPDATE_PAGE_SIZE':
      return { ...state, size: action.payload };
    case types.SEARCH_CUSTOMER_BY_MSISDN_SUCCESS:
      return {
        ...state,
        customerLoader: false,
        searchFinishedFlag: true,
        customerList: action.payload.items,
        count: action.payload.count,
      };

    case 'SEARCH_CUSTOMER_BY_MSISDN_FAIL':
      return {
        ...state,
        customerLoader: false,
        customerErrors: action.payload,
      };

    case 'RESET_SEARCH_FLAG':
      return { ...state, searchFinishedFlag: false };

    case 'RESET_SEARCH_RESULTS':
      return { ...initialState, size: state.size };

    case types.USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
}