import React from "react";
import { hashHistory } from 'react-router';
import { connect } from "react-redux";
import HomePageCta from './HomePageCta'
import { registerCustomerRequest, resetRegistration } from "../../../containers/CustomerScreen/components/RegisterCustomer/actions.es6";
import { reloadPage } from "../../../lib/utils.es6";
import LocalizedStrings from 'react-localization';
import { localisedText } from "../../../lib/localisation.es6";
import RegisterCustomer from "../../../containers/PolicyV2CustomerStatus/Components/RegisterCustomer/RegisterCustomer";


class KTDAHomePage extends React.Component {
  constructor(props) {
    const localizedStrings = new LocalizedStrings(localisedText);
    super(props);
    this.state = {
      openRegisterCustomerDialog: false,
      labels: localizedStrings,
    }
  }

  toggleRegisterCustomerDialog() {
    this.setState({ openRegisterCustomerDialog: !this.state.openRegisterCustomerDialog })
  }
  registerCustomer(customerData) {
    this.props.dispatch(registerCustomerRequest(customerData));
  }
  resetRegistrationSuccess() {
    this.props.dispatch(resetRegistration());
		hashHistory.push('admin/customer_status/' + this.props.customerData.currentCustomer.guid);
		this.toggleRegisterCustomerDialog()
		reloadPage();
  }
  resetRegistrationError() {
		this.props.dispatch(resetRegistration())

  }
  render() {

      return (
        <>
        <HomePageCta
        open={this.toggleRegisterCustomerDialog.bind(this)}
        labels={this.state.labels}
        />

          <RegisterCustomer
            openRegisterCustomerDialog={this.state.openRegisterCustomerDialog}
            toggleRegisterCustomerDialog={this.toggleRegisterCustomerDialog.bind(this)}
            registerCustomer={this.registerCustomer.bind(this)}
            registrationData={this.props.registrationData}
            resetRegistrationSuccess={this.resetRegistrationSuccess.bind(this)}
            resetRegistrationError={this.resetRegistrationError.bind(this)}
            labels={this.state.labels}
          />
        </>
      )
   

  }
}

export default connect((state) => ({
  customerData: state.currentCustomer,
  registrationData: state.customerRegistration,

}))(KTDAHomePage);