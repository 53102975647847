import React from 'react';
import { connect } from 'react-redux';
import { hashHistory } from 'react-router';
import LocalizedStrings from 'react-localization';
import MainPageTitle from '../../../components/MainPageTitle/index.es6';
import * as actions from './actions.es6';
import { getGroupProductsRequest } from '../../BritamLandingPage/actions.es6'
import { updateCurrentLanguage } from '../../../lib/languageUtils.es6';
import { localisedText } from '../../../lib/localisation.es6';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Button, CircularProgress, InputAdornment } from '@material-ui/core';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import LinearProgress from 'material-ui/LinearProgress';
import { getS3BucketSignedUrlForGroupUploadResources } from '../../../lib/requests.es6';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from "@material-ui/core/Grid";
import AlertDialog from '../../../components/AlertDialog/index.es6';
import { displayProductFixedBenefits, getGroupMobisiteBaseURL, getGroupPremiumEstimate, organiseProductsByTag } from '../../../lib/utils.es6';
import { Checkbox } from 'material-ui';
import SweetAlert from 'react-bootstrap-sweetalert';
import Backdrop from '@material-ui/core/Backdrop';
// import ContentCopyIcon from '@material-ui/icons/ContentCopy';
import FileCopy from '@material-ui/icons/FileCopy';
import Snackbar from 'material-ui/Snackbar';
import { getPartner, getPartnerGuid } from '../../../lib/access.es6';
import { getGroupProductConfigsRequest } from '../../NewProductList/actions.es6';
const btnStyle = {
  margin: '2%',
  green: {
    backgroundColor: 'orangered',
    color: 'white',
    margin: '2px',
    height: '50px',
    padding: '0 30px',
  },
  danger: {
    backgroundColor: 'green',
    color: 'white',
    margin: '2px',
    height: '50px',
    padding: '0 30px',
  },
};

export class GroupRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      size: '',
      benefit: '',
      email: '',
      telephone: '',
      address: '',
      occupation: '',
      kraPin: '',
      certificateUploadCompleted: 0,
      excelFileUploadCompleted: 0,
      certificateUploadMessage: null,
      excelFileUploadMessage: null,
      s3Url: 'https://group-upload-resources.s3.amazonaws.com',
      certificateFileSignedUrl: '',
      membersFileSignedUrl: '',
      labels: new LocalizedStrings(localisedText),
      selectedProduct: null,
      acceptTerms: false,
      groupScheduleError: null,
      certificateError: null,
      acceptTermsError: null,
      linkCopied: false,
      selectedProductCategory: null,
      minimumGroupSize: 1
    };

    this.handleChange = this.handleChange.bind(this);
    this.downloadCSVTemplate = this.downloadCSVTemplate.bind(this);
    this.onCertificateFileSelect = this.onCertificateFileSelect.bind(this);
    this.onExcelFileSelect = this.onExcelFileSelect.bind(this);
    this.handleChangeBenefit = this.handleChangeBenefit.bind(this);
    this.submitGroupRegistration = this.submitGroupRegistration.bind(this);
  }
  componentWillMount() {
    this.updateLanguage();
    if (!this.props.quoteData.quote) {
    }
    if (this.props.quoteData.quote) {
      let quote = this.props.quoteData.quote
      if (quote) {
        let size = Number(quote.groupSize)
        let selectedProduct = quote.product
        this.setState({
          size,
          selectedProduct
        })
      }
    }
    if (!this.props.quoteData.groupProducts) {
      this.props.dispatch(getGroupProductsRequest(getPartnerGuid()));
    }
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('is9Digits', (value) => {
      return value.length === 9;
    });

    ValidatorForm.addValidationRule('isValidKraPin', (value) => {
      return /^[A-Z]\d{9}[A-Z]$/gi.test(value)
    });

    ValidatorForm.addValidationRule('isValidGroupSize', (value) => {
      let size;
      if(this.props.productsData.groupProductConfigs && this.props.productsData.groupProductConfigs.minimum_group_size){
        size = this.props.productsData.groupProductConfigs.minimum_group_size
        this.setState({minimumGroupSize: size})
      }else{
        const DEFAULT_MINIMUM_GROUP_SIZE = 1;
        size = DEFAULT_MINIMUM_GROUP_SIZE
        this.setState({minimumGroupSize: size})
      }
      return value >= size;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule('is9Digits');
    ValidatorForm.removeValidationRule('isValidKraPin');
    ValidatorForm.removeValidationRule('isValidGroupSize');
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'GroupRegistration',
      this.state.labels
    );
  }

  handleResetForm() {
    this.props.dispatch(actions.resetGroupPreRegistraion())
    this.setState({
      name: '',
      selectedProduct: null,
      email: '',
      telephone: '',
      address: '',
      occupation: '',
      kraPin: '',
      size: '',
    });
  }

  handleProductcategoryChange = (event) => {
    const category = event.target.value;
    this.setState({
      selectedProductCategory: category,
    });
  };


  submitGroupRegistration() {
    if (!this.state.acceptTerms) {
      this.setState({ acceptTermsError: true });
      return;
    }
    this.props.dispatch(actions.resetActualQuote());
    const telephone = "00254" + this.state.telephone
    let payload = {
      name: this.state.name,
      product_guid: this.state.selectedProduct.guid,
      email: this.state.email,
      telephone: telephone,
      address: this.state.address,
      occupation: this.state.occupation,
      kra_pin: this.state.kraPin,
      certificate_url: `${this.state.s3Url}/${this.props.groupRegistrationData.certificateFileUploaded.bucketKey}`,
      group_schedule_s3_key: this.props.groupRegistrationData.excelFileUploaded.bucketKey,
    }
    this.props.dispatch(actions.registerGroupRequest(payload))
  }

  preRegisterGroup() {
    const telephone = "00254" + this.state.telephone

    const payload = {
      name: this.state.name,
      product_guid: this.state.selectedProduct.guid,
      email: this.state.email,
      telephone: telephone,
      occupation: this.state.occupation,
    }

    this.props.dispatch(actions.preRegisterGroupRequest(payload))
    console.log('preRegister payload: ', payload);
  }


  getActualQuote() {
    if (!this.props.groupRegistrationData.excelFileUploaded) {
      this.setState({ groupScheduleError: true });
      return;
    }
    if (!this.props.groupRegistrationData.certificateFileUploaded) {
      this.setState({ certificateError: true });
      return;
    }
    this.setState({ acceptTerms: false });
    let payload = {
      group_schedule_s3_key: this.props.groupRegistrationData.excelFileUploaded.bucketKey,
      product_premium_guid: this.state.selectedProduct.premiums[0].guid,
    }
    this.props.dispatch(actions.getActualGroupQuoteRequest(payload))
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }
  handleChangeBenefit(event) {
    const selectedProduct = event.target.value;
    this.setState({ selectedProduct })
    this.props.dispatch(getGroupProductConfigsRequest(selectedProduct.guid))
  }

  toggleAcceptTerms() {
    const acceptTerms = this.state.acceptTerms;
    if (acceptTerms) {
      this.setState({ acceptTerms: !acceptTerms, acceptTermsError: false });
    } else {
      this.setState({ acceptTerms: !acceptTerms })
    }
  }

  handleFinishedUploadExcelFile(info) {
    this.setState({ membersFileSignedUrl: info.signedUrl, groupScheduleError: false })
    this.props.dispatch(actions.groupExcelUploadCallbackRequest(info));
  }

  handleFinishedUploadCertificate(info) {
    this.setState({ certificateFileSignedUrl: info.signedUrl, certificateError: false });
    this.props.dispatch(actions.groupCertificateUploadCallbackRequest(info));
  }

  onCertificateFileSelect(event) {
    this.setState({
      certificateFileKey: event.target.files[0],
      isSubmitDisabled: false,
    });
  }

  onExcelFileSelect(event) {
    this.setState({
      membersFile: event.target.files[0],
      isSubmitDisabled: false,
    });
  }

  errorHandler(message) {
    this.setState({ message });
  }

  downloadCSVTemplate() {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';

    const url = encodeURI("https://group-upload-resources.s3-eu-west-1.amazonaws.com/Britam_group_funeral_last_expense_form_v2.3.xlsx");
    a.href = url;
    a.download = 'sample.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  progressExcelFile(completed) {
    if (completed > 100) {
      this.setState({ excelFileUploadCompleted: 100 });
    } else {
      this.setState({ excelFileUploadCompleted: completed });
    }
  }

  progressCertificateFile(completed) {
    if (completed > 100) {
      this.setState({ certificateUploadCompleted: 100 });
    } else {
      this.setState({ certificateUploadCompleted: completed });
    }
  }

  displayCompleteMessageForCertificate() {
    const style = {
      margin: '5%',
      textAlign: 'center'
    };
    const labels = this.state.labels;
    if (this.state.certificateUploadCompleted === 0) {
      return (
        <div style={style}>
          <h4>{'Drag and Drop Certificate of Incorporation here'}</h4>
          <h4>{labels.Or}</h4>
          <h4>{labels.clickUpload}</h4>
        </div>
      );
    }

    if (this.state.certificateUploadCompleted > 0 && this.state.certificateUploadCompleted < 100) {
      return (
        <div style={style}>
          <h4>{labels.uploadProgress}</h4>
        </div>
      );
    }
    if (this.state.certificateUploadCompleted >= 100 && !this.state.certificateUploadMessage) {
      return (
        <div style={style}>
          <h4 style={{ color: 'green' }}>{labels.uploadSuccess}</h4>
        </div>
      );
    }
    if (this.state.certificateUploadCompleted >= 100 && this.state.certificateUploadMessage) {
      return (
        <div style={style}>
          <h4>{labels.uploadFailure}</h4>
        </div>
      );
    }
    return '';
  }


  displayCompleteMessageForGroupExcelFile() {
    const style = {
      margin: '5%',
      textAlign: 'center'
    };
    const labels = this.state.labels;
    if (this.state.excelFileUploadCompleted === 0) {
      return (
        <div style={style}>
          <h4>{'Drag and Drop Group Schedule excel file here'}</h4>
          <h4>{labels.Or}</h4>
          <h4>{labels.clickUpload}</h4>
        </div>
      );
    }

    if (this.state.excelFileUploadCompleted > 0 && this.state.excelFileUploadCompleted < 100) {
      return (
        <div style={style}>
          <h4>{labels.uploadProgress}</h4>
        </div>
      );
    }
    if (this.state.excelFileUploadCompleted >= 100 && !this.state.excelFileUploadMessage) {
      return (
        <div style={style}>
          <h4 style={{ color: 'green' }}>{labels.uploadSuccess}</h4>
        </div>
      );
    }
    if (this.state.excelFileUploadCompleted >= 100 && this.state.excelFileUploadMessage) {
      return (
        <div style={style}>
          <h4>{labels.uploadFailure}</h4>
        </div>
      );
    }
    return '';
  }

  renderGroupExcelFileUploader() {
    const uploadOptions = {
      getSignedUrl: getS3BucketSignedUrlForGroupUploadResources,
      uploadRequestHeaders: {},
      onProgress: this.progressExcelFile.bind(this),
      onError: this.errorHandler.bind(this),
    };

    const style = {
      loader: {
        display: this.state.excelFileUploadCompleted > 0 ? '' : 'none',
        width: '52%',
      },
      s3Uploader: {
        display: this.state.excelFileUploadCompleted > 0 ? 'none' : '',
      },
      loaderColor: this.state.message ? 'red' : 'green',
    };

    return (
      <div>
        <div>
          <DropzoneS3Uploader
            onFinish={this.handleFinishedUploadExcelFile.bind(this)}
            maxSize={1024 * 1024 * 5}
            upload={uploadOptions}
            s3Url={this.state.s3Url}
          >
            <div>
              <br />
              <br />
              {this.displayCompleteMessageForGroupExcelFile()}
            </div>
          </DropzoneS3Uploader>

          <br />
          <br />
          <LinearProgress
            color={style.loaderColor}
            style={style.loader}
            mode="determinate"
            value={this.state.excelFileUploadCompleted}
          />
        </div>
      </div>
    );
  }

  renderCertificateUploader() {
    const uploadOptions = {
      getSignedUrl: getS3BucketSignedUrlForGroupUploadResources,
      s3Url: this.state.s3Url,
      uploadRequestHeaders: {},
      onProgress: this.progressCertificateFile.bind(this),
      onError: this.errorHandler.bind(this),
    };

    const style = {
      loader: {
        display: this.state.certificateUploadCompleted > 0 ? '' : 'none',
        width: '52%',
      },
      s3Uploader: {
        display: this.state.certificateUploadCompleted > 0 ? 'none' : '',
      },
      loaderColor: this.state.message ? 'red' : 'green',
    };

    return (
      <div className="form-group">
        <div>
          <DropzoneS3Uploader
            onFinish={this.handleFinishedUploadCertificate.bind(this)}
            maxSize={1024 * 1024 * 5}
            upload={uploadOptions}
            s3Url={this.state.s3Url}
            passChildrenProps={false}
          >
            <div>
              <br />
              <br />
              {this.displayCompleteMessageForCertificate()}
            </div>
          </DropzoneS3Uploader>
          <br />
          <br />
          <LinearProgress
            color={style.loaderColor}
            style={style.loader}
            mode="determinate"
            value={this.state.certificateUploadCompleted}
          />
        </div>
      </div>
    );
  }

  goToGroupMembers() {
    const group = this.props.groupRegistrationData.groupPreRegisteration;
    hashHistory.push(`admin/add-group-members/${group.id}`)
    this.props.dispatch(actions.resetGroupPreRegistraion())
  }
  generateGroupLink() {
    const group = this.props.groupRegistrationData.groupPreRegisteration;
    const registrationLink = group ? group.registration_link : '';
    return `${getGroupMobisiteBaseURL()}${registrationLink}`;
  }

  copyGroupLink() {
    const groupMobisiteURL = this.generateGroupLink();
    navigator.clipboard.writeText(groupMobisiteURL);
    this.setState({ linkCopied: true })
  }

  downloadCSVGroupScheduleErrors() {
    if (!this.isErrorDownloadable()) {
      alert("Unexpected Error occurred. Contact admin")
      return;
    }
    const errors = this.props.groupRegistrationData.actualGroupQuoteError ? this.props.groupRegistrationData.actualGroupQuoteError.csvErrors : [];

    let csvContent = "data:text/csv;charset=utf-8, Error Message\n";

    for (let i = 0; i < errors.length; i++) {
      const row = errors[i];
      csvContent += row + "\n";
    }


    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';

    const url = encodeURI(csvContent);
    a.href = url;
    a.download = 'group_schedule_errors.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  isErrorDownloadable = () => {
    const errors = this.props.groupRegistrationData.actualGroupQuoteError ? this.props.groupRegistrationData.actualGroupQuoteError.csvErrors : [];
    if (!errors) {
      return false;
    }
    return true;
  }

  render() {
    const currencyPartner =  getPartner();
    const currencyCode = currencyPartner ? currencyPartner.country.currency_code : "";
    const labels = this.state.labels;
    const allProducts = this.props.quoteData.groupProducts || [];

    const organisedProducts = organiseProductsByTag(allProducts);
    let products = organisedProducts[this.state.selectedProductCategory] || [];
    products = this.state.size < 100 ? products.slice(0, 2) : products;

    const isErrorDownloadable = this.isErrorDownloadable();

    const quote = this.props.groupRegistrationData.actualGroupQuote || {};
    const { groupRegistrationError, actualGroupQuoteError, actualGroupQuoteLoader, groupPreRegistrationError } = this.props.groupRegistrationData;
    return (
      <div>
        <div className="row" style={{ width: '90%', margin: "auto" }}>
          <MainPageTitle pageTitle={'Group Registration'} />
          <div className="col-md-12 col-sm-12 col-xs-12" >
            <div className="x_panel" >
              <div className="x_title">
                <h2>{'Enter Group Details'}</h2>
                <div className="clearfix" />
              </div>
              <div className="x_content">
                <ValidatorForm onSubmit={this.preRegisterGroup.bind(this)} >
                  <Grid container item xs={12} spacing={3}>

                    <Grid item md={4} xs={12}>
                      <TextValidator label={'Group Size'}
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={this.state.size}
                        onChange={this.handleChange}
                        name="size"
                        placeholder={'group size'}
                        validators={['required', 'isValidGroupSize']}
                        errorMessages={['Please enter a group size', `Group Size should be ${this.state.minimumGroupSize} or more`]}
                      />
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <SelectValidator label={'Select Product Category'}
                        id="product-category"
                        name="product-category"
                        className="form-control"
                        type="text"
                        value={this.state.selectedProductCategory}
                        onChange={this.handleProductcategoryChange}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Please select product category']}
                        style={{ marginBottom: "20px" }}>
                        <MenuItem value="">{'Please select product category'}</MenuItem>
                        {
                          Object.keys(organisedProducts).map((product) => {
                            return <MenuItem value={product}>{product}</MenuItem>
                          })
                        }
                      </SelectValidator>
                    </Grid>
                    {
                      this.state.selectedProductCategory &&
                      <Grid item md={4} xs={12}>
                        <SelectValidator label={'Select Benefit'}
                          id="benefit"
                          name="benefit"
                          className="form-control"
                          type="text"
                          value={this.state.selectedProduct}
                          onChange={this.handleChangeBenefit}
                          variant="outlined"
                          validators={['required']}
                          errorMessages={['Please select benefit']}
                          style={{ marginBottom: "20px" }}>
                          <MenuItem value="">{'Please select benefit'}</MenuItem>
                          {
                            products.map((product) => {
                              return <MenuItem value={product}>{displayProductFixedBenefits(product.fixed_indemnities)}</MenuItem>
                            })
                          }
                        </SelectValidator>
                      </Grid>
                    }


                    <Grid item md={4} xs={12}>
                      <p style={{ color: 'green', fontSize: "150%" }}>Your total group premium estimate: {getGroupPremiumEstimate(this.state.size, this.state.selectedProduct)}</p>
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <TextValidator label={'Group Name'}
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={this.state.name}
                        onChange={this.handleChange}
                        name="name"
                        placeholder={'group name'}
                        validators={['required']}
                        errorMessages={'Please enter a group name'}
                      />
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <TextValidator label={'Group email'}
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={this.state.email}
                        onChange={this.handleChange}
                        name="email"
                        placeholder={'group email'}
                        validators={['required', 'isEmail']}
                        errorMessages={['Please enter a group email', 'email is not valid']}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}></Grid>
                    <Grid item md={4} xs={12}>
                      <TextValidator label={'Group Telephone'}
                        fullWidth
                        variant="outlined"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start" style={{ marginRight: '0px' }}>{'0'}</InputAdornment>,
                        }}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9)
                        }}
                        value={this.state.telephone}
                        onChange={this.handleChange}
                        name="telephone"
                        validators={['required', 'is9Digits']}
                        errorMessages={['Please enter a group telephone', 'Please enter 10 digits']}
                      />
                    </Grid>
                    {/* <Grid item md={4} xs={12}>
                      <TextValidator label={'Group Physical Address'}
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={this.state.address}
                        onChange={this.handleChange}
                        name="address"
                        placeholder={'group address'}
                        validators={['required']}
                        errorMessages={'Please enter a group address'}
                      />
                    </Grid> */}

                    <Grid item md={4} xs={12}>
                      <TextValidator label={'Group Occupation'}
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={this.state.occupation}
                        onChange={this.handleChange}
                        name="occupation"
                        placeholder={'Group Occupation'}
                        validators={['required']}
                        errorMessages={'Please enter a group occupation'}
                      />
                    </Grid>

                    {/* <Grid item md={4} xs={12}>
                      <TextValidator label={'KRA PIN'}
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={this.state.kraPin}
                        onChange={this.handleChange}
                        name="kraPin"
                        placeholder={'KRA PIN'}
                        validators={['required', 'isValidKraPin']}
                        errorMessages={['Please enter a group KRA PIN','Invalid KRA PIN']}
                      />
                    </Grid> */}

                    {/* <Grid item md={6} xs={12} >
                      <div className="form-group">
                        <div style={{ textAlign: 'left' }}>
                          <br />
                          <h3>Please upload Group schedule excel</h3>
                          <Button onClick={this.downloadCSVTemplate} style={{ backgroundColor: "orange", color: "#fff" }}><GetAppIcon />{"Download sample excel file"}</Button>
                          <br />
                          {
                            this.state.groupScheduleError &&
                            <p style={{ color: 'red' }}>Error: Please upload group schedule excel. </p>
                          }
                          <br />
                          {this.renderGroupExcelFileUploader()}
                        </div>
                      </div>
                    </Grid> */}


                    {/* <Grid item md={6} xs={12} >
                      <div className="form-group">
                        <div>
                          <br />
                          <centre>
                            <h3>Please upload certificate of incorporation</h3>
                            <br />
                            {
                              this.state.certificateError &&
                              <p style={{ color: 'red' }}>Error: Please upload certificate of incorporation. </p>
                            }
                            <br />
                            <br />
                            {this.renderCertificateUploader()}

                          </centre>
                        </div>

                      </div>
                    </Grid> */}
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <br />
                    <br />
                    <Button
                      variant="contained"
                      style={btnStyle.danger}
                      type="submit">
                      {'Pre Register Group'}
                    </Button>
                    <Button
                      onClick={() => this.handleResetForm()}
                      variant="contained"
                      style={btnStyle.green}>
                      {labels.reset}
                    </Button>
                  </Grid>
                </ValidatorForm>

                <br />
              </div>
            </div>
            <hr />
          </div>
        </div>
        {/* registerGroupLoader, groupRegistrationError, groupRegisteration, groupRegistrationSuccess } = this.props.groupRegistrationData; */}
        <SweetAlert
          success
          show={!!this.props.groupRegistrationData.groupPreRegistrationSuccess}
          // show={true}
          title={'Group Pre-Registered 😀'}
          onConfirm={() => this.goToGroupMembers()}
          confirmBtnText={'Add members'}
          cancelBtnText={'Register another group'}
          showCancel={true}
          onCancel={() => this.handleResetForm()}
          confirmBtnCssClass
          style={{ display: "block", marginTop: "80px", fontSize: "200%" }}
          confirmBtnStyle={{
            backgroundColor: "#4CAF50", /* Green */
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "26px",
            fontWeight: 800
          }}
          cancelBtnStyle={{
            border: "1px solid grey",
            color: "grey",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "26px",
            fontWeight: 800
          }}
        >  <div style={{ backgroundColor: 'white', color: 'black', margin: '2px', height: '150px', padding: '0 30px' }}>
            <p>Visit Mobi Site at: </p>
            <a href={this.generateGroupLink()} target="_blank" rel="noopener noreferrer"> {this.generateGroupLink()}</a>
            <p style={{ paddingTop: '2%' }} onClick={this.copyGroupLink.bind(this)}><FileCopy label="copy link" /> or copy link</p></div>
          <p style={{ color: 'green', fontSize: '70%', display: this.state.linkCopied ? 'block' : 'none' }}>Link copied</p>
          <br />
        </SweetAlert>

        <SweetAlert
          success
          show={!!this.props.groupRegistrationData.groupRegistrationSuccess}
          title={'Group Registered 😀'}
          onConfirm={() => this.goToGroupMembers()}
          confirmBtnText={'Add members'}
          cancelBtnText={'Register another group'}
          showCancel={true}
          onCancel={() => this.handleResetForm()}
          confirmBtnCssClass
          style={{ display: "block", marginTop: "80px", fontSize: "200%" }}
          confirmBtnStyle={{
            backgroundColor: "#4CAF50", /* Green */
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "26px",
            fontWeight: 800
          }}
          cancelBtnStyle={{
            border: "1px solid grey",
            color: "grey",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "26px",
            fontWeight: 800
          }}
        >
          <h2> <span role="img" aria-label="smile"> 😀</span> Group Registration is Successful</h2>
          <br />
        </SweetAlert>

        <AlertDialog
          success
          show={!!this.props.groupRegistrationData.actualGroupQuoteSuccess}
          title={'Quote Ready'}
          onConfirm={() => this.submitGroupRegistration()}
          confirmBtnText={'Accept and Register Group'}
          showCancel={true}
          cancelBtnText={labels.cancel}
          onCancel={() => this.props.dispatch(actions.resetActualQuote())}
          confirmBtnCssClass
        >
          <div style={{ textAlign: 'center' }}>
            <p>Total Groups: {quote.group_family_count}</p>
            <p>Total  Premium Before Tax: {currencyCode} {(quote.total_premium_amount_in_cents_before_tax / 100).toFixed(2)} per Year</p>
            <p>Total  Premium After Tax: {currencyCode} {(quote.total_premium_amount_in_cents_with_tax / 100).toFixed(2)} per Year</p>
            {
              this.state.acceptTermsError &&
              <p style={{ color: 'red' }}>Error: Please accept our terms and conditions <span role="img" with aria-label="pleading">🥺</span></p>
            }
            <div className="form-group" >
              <div>
                <div className="col-md-4 col-sm-4 col-xs-12" style={{ textAlign: 'right', paddingTop: '1%' }}>
                  <br />
                  <Checkbox
                    id="terms_and_conditions"
                    name="terms_and_conditions"
                    required
                    checked={
                      this.state.acceptTerms
                    }
                    style={{ marginLeft: '130%', transform: 'scale(3)', color: 'green' }}
                    onCheck={this.toggleAcceptTerms.bind(this)}
                    color="primary"
                  />
                  <br />
                </div>
                <div style={{ textAlign: 'left' }}>
                  <br />
                  <label
                    htmlFor="terms_and_conditions"
                    className="control-label col-md-8 col-sm-8 col-xs-12"
                  >
                    {' '}
                    {<p>Accept our <a style={{ textDecoration: 'underline' }} target="_blank" href="https://group-upload-resources.s3-eu-west-1.amazonaws.com/britam_group_Last_Expense_Cover_terms_and_conditions_2020.pdf" rel="noopener noreferrer"> Terms and Conditions</a></p>}
                  </label></div>

                <br />
              </div>
            </div>

          </div>
        </AlertDialog>;


        <AlertDialog
          danger
          show={!!groupPreRegistrationError}
          title={'Could not pre register group'}
          onConfirm={() => this.props.dispatch(actions.resetGroupPreRegistraion())}
          cancelBtnText={labels.tryAgain}
          showCancel={false}
          onCancel={() => this.props.dispatch(actions.resetGroupPreRegistraion())}
          confirmBtnCssClass
        >
          {groupPreRegistrationError ? groupPreRegistrationError.message : ''}
        </AlertDialog>;

        <AlertDialog
          danger
          show={!!groupRegistrationError}
          title={'Schedule Excel file error'}
          confirmBtnText={'Correct the error and try again'}
          onConfirm={() => this.props.dispatch(actions.resetGroupRegistraion())}
          cancelBtnText={labels.tryAgain}
          showCancel={true}
          onCancel={() => this.props.dispatch(actions.resetGroupRegistraion())}
          confirmBtnCssClass
        >
          {groupRegistrationError ? groupRegistrationError.message : ''}
        </AlertDialog>;

        <AlertDialog
          show={!!actualGroupQuoteError}
          danger
          title={'You have errors in your excel file, fix the errors and resubmit.'}
          onConfirm={this.downloadCSVGroupScheduleErrors.bind(this)}
          confirmBtnText={'Download the Errors'}
          cancelBtnText={'Try again'}
          confirmBtnCssClass
          showCancel={true}
          showConfirm={isErrorDownloadable}
          onCancel={() => this.props.dispatch(actions.resetActualQuote())}
        >
          {actualGroupQuoteError ? actualGroupQuoteError.message : ''}
        </AlertDialog>
        <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={actualGroupQuoteLoader}>
          <CircularProgress size={80} color="inherit" />
          <h3 style={{ marginTop: '10%', marginLeft: '-15%' }}>.....Getting your actual quote....</h3>
        </Backdrop>
        <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={this.props.groupRegistrationData.preRegisterGroupLoader}>
          <CircularProgress size={80} color="inherit" />
          <h3 style={{ marginTop: '15%', marginLeft: '-15%' }}>.....Pre Registering your Group.....</h3>
        </Backdrop>

        <Snackbar
          open={this.state.linkCopied}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          autoHideDuration={6000}
          onClose={() => this.setState({ linkCopied: false })}
          message="Group link copied to clipboard"
        />
      </div>

    );
  }
}

export default connect(state => ({
  groupRegistrationData: state.groupRegistration,
  globalData: state.global,
  quoteData: state.britamQuote,
  productsData: state.newProducts,
}))(GroupRegistration);
