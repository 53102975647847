import { registerRequestWatcher } from './containers/RegisterCustomer/saga.es6';
import {
  loginRequestWatcher,
  refreshSessionRequestWatcher,
  forgotPasswordRequestWatcher,
  logoutRequestWatcher,
  getStartedMessageRequestWatcher
} from './containers/Login/saga.es6';
import {
  purchasePolicyRequestWatcher,
  productListWatcher,
} from './containers/BuyPolicy/saga.es6';
import { searchCustomerByMsisdnRequestWatcher } from './containers/Search/saga.es6';
import {
  claimSubmissionWatcher,
  claimTypesWatcher,
} from './containers/ClaimSubmission/saga.es6';
import {
  deletePolicyWatcher,
  getPolicyProductWatcher,
  initiatePolicyMobilePaymentWatcher,
} from './containers/PolicyList/saga.es6';
import {
  getCustomerRequestWatcher,
  updateCustomerRequestWatcher,
  updatePolicyRequestWatcher,
  changeLoyaltyRegistrationWatcher,
  pullCustomerKycWatcher,
} from './containers/Customer/saga.es6';
import {
  profileRequestWatcher,
  profileUpdateRequestWatcher,
} from './containers/Profile/saga.es6';
import { openClaimRequestWatcher } from './containers/ClaimsOpen/saga.es6';
import { claimStatusRequestWatcher } from './containers/ClaimsStatus/saga.es6';
import { payableClaimRequestWatcher } from './containers/ClaimsPayable/saga.es6';
import { closedClaimRequestWatcher } from './containers/ClaimsClosed/saga.es6';
import { summaryClaimRequestWatcher } from './containers/ClaimsDashboard/saga.es6';
import { reportsPaidClaimRequestWatcher } from './containers/ClaimPaidReport/saga.es6';
import {
  claimConditionListWatcher,
  claimFileDeleteRequestWatcher,
  claimHospitalsWatcher,
  claimProcessingWatcher,
  claimRejectionReasonWatcher,
  claimUploadRequestWatcher,
  refreshClaimRequestWatcher,
  retryFailedClaimPaymentRequestWatcher,
  updateClaimPaymentOutcomeRequestWatcher,
  expiredClaimReversalWatcher, claimCreateRequestWatcher,
} from './containers/ClaimsProcessing/saga.es6';
import { claimSearchRequestWatcher } from './containers/ClaimSearch/saga.es6';
import { reportsRequestWatcher } from './containers/ReportsPremium/saga.es6';
import {
  AddMissingPaymentRequestWatcher,
  deletePremiumWatcher,
  sendRecurringPaymentRecommendationSmsRequestWatcher,
} from './containers/CustomerStatus/saga.es6';
import {
  claimCallbackDeleteRequestWatcher,
  claimCallbackRequestWatcher,
  getClosedClaimCallbackRequestWatcher,
} from './containers/ClaimCallback/saga.es6';
import {
  createCountryRequestWatcherSaga,
  getCountriesRequestWatcherSaga,
  deleteCountryRequestWatcherSaga,
  updateCountryRequestWatcherSaga,
} from './containers/Country/saga.es6';
import {
  changeUserPasswordRequestWatcher,
  createUserRequestWatcher,
  deleteUserRequestWatcher,
  getPartnersRequestWatcher,
  getRolesRequestWatcher,
  getCurrentRolesRequestWatcher,
  getCurrentPartnersRequestWatcher,
  getUsersRequestWatcher,
  updateRolesAndPartnersRequestWatcher,
  resetPasswordRequestWatcher, getUserRequestWatcher,
} from './containers/Users/saga.es6';
import { claimFinalisationReportRequestWatcher } from './containers/ReportClaimFinalisation/saga.es6';
import { reportsOpenClaimRequestWatcher } from './containers/ReportOpenClaim/saga.es6';
import { reportsRegistrationRequestWatcher } from './containers/ReportRegistration/saga.es6';
import { tigoUploadLoyaltyRequestWatcher } from './containers/LoyaltyEligibilityTigoRwanda/saga.es6';
import { loyaltyRenewalRequestWatcher } from './containers/LoyaltyRenewal/saga.es6';
import { createDebitOrderWatcher, getAllBanksWatcher } from './containers/DebitOrderManagement/saga.es6';
import {
  debitOrderTransactionsRequestWatcher,
  updateDebitOrderTransactionsRequestWatcher,
} from './containers/DebitOrderTransactions/saga.es6';
import { reportsPoliciesRequestWatcher } from './containers/ReportPolicies/saga.es6';
import { reportsRefundRequestWatcher } from './containers/ReportRefund/saga.es6';
import { reportVasAgentRequestWatcher } from './containers/ReportsAgent/saga.es6';
import { reportAgentWebRequestWatcher } from './containers/ReportsAgentWeb/saga.es6';
import { reportsPayableClaimRequestWatcher } from './containers/ReportsPayableClaims/saga.es6';
import { reportPaymentRequestWatcher } from './containers/ReportsPayment/saga.es6';
import { reportsClaimNoticationRequestWatcher } from './containers/ReportClaimNotification/saga.es6';
import { reportsClaimRegisterRequestWatcher } from './containers/ReportClaimRegister/saga.es6';
import { reportsPremiumRegisterRequestWatcher } from './containers/ReportPremiumRegister/saga.es6';
import { reportSuspendedPoliciesRequestWatcher } from './containers/ReportSuspendedPolicies/saga.es6';
import { reportsPremiumDueRequestWatcher } from './containers/ReportPremiumDue/saga.es6';
import { reportPreAuthorizationRequestWatcher } from './containers/ReportPreAuthorization/saga.es6'
import { reportClaimDeathRequestWatcher } from './containers/DeathClaimReport/saga.es6'
import { reportHospitalClaimRequestWatcher } from './containers/HospitalClaimReport/saga.es6'
import { reportClaimReimbursementRequestWatcher } from './containers/ReimbursementReport/saga.es6'
import { reportClaimFinalizationReimbursementRequestWatcher} from './containers/ReimbursementFinalisationReport/saga.es6'
import { reportClaimFinalizationDeathRequestWatcher } from './containers/DeathFinalizationClaimReport/saga.es6'
import { reportFinalizationHospitalClaimRequestWatcher } from './containers/HospitalFinalizationClaimReport/saga.es6'
import {
  downloadBulkSmsRequestWatcher,
  summariseBulkSmsRequestWatcher,
  uploadBulkSmsRequestWatcher,
  stopBlastRequestWatcher,
  getBatchProgressRequestWatcher,
} from './containers/BulkSms/saga.es6';
import {
  getAllPartnersRequestWatcher,
  createPartnerRequestWatcher,
  getPartnerRequestWatcher,
} from './containers/Partners/saga.es6';

import { getLanguagesWatcher } from './containers/Languages/saga.es6';

import {
  getAllProductsRequestWatcher,
  createProductRequestWatcher,
  getProductRequestWatcher,
  getProductEnumRequestWatcher,
} from './containers/ProductList/saga.es6';

import {
  createPremiumRequestWatcher,
  hardDeletePremiumRequestWatcher,
  createBenefitRequestWatcher,
  hardDeleteBenefitRequestWatcher,
  editProductRequestWatcher,
  editPremiumRequestWatcher,
  editBenefitRequestWatcher,
  getGroupProductConfigsRequestWatcher,
  updateGroupProductConfigsRequestWatcher,
  addGroupProductConfigsRequestWatcher,
  getExtendedFamilyProductConfigsRequestWatcher,
  updateExtendedFamilyProductConfigsRequestWatcher,
  addExtendedFamilyProductConfigsRequestWatcher,
  createPremiumConfigRequestWatcher,
  getPremiumConfigsRequestWatcher,
  createAddonConfigRequestWatcher
} from './containers/NewProductList/saga.es6';

import {
  createProductPremiumRequestWatcher,
  getAllProductPremiumsRequestWatcher,
  getProductPremiumsEnumRequestWatcher,
} from './containers/ProductPremiums/saga.es6';
import { addMultiplePaymentRequestWatcher, addSinglePaymentRequestWatcher } from './containers/ManualPayment/saga.es6';
import { getCurrentGroupClaimsRequestWatcher, getCurrentGroupMembersRequestWatcher, getCurrentGroupMembersForAdminsRequestWatcher, getCurrentGroupMembersForCsvRequestWatcher, getCurrentGroupPaymentsRequestWatcher, getCurrentGroupPoliciesRequestWatcher, getCurrentGroupPremiumsRequestWatcher, getCurrentGroupClaimCallbacksRequestWatcher } from './containers/Group/GroupMembers/saga.es6';
import {deleteMyGroupRequestWatcher, getGroupsRequestWatcher} from './containers/Group/MyGroups/saga.es6';
import { registerGroupRequestWatcher, getActualGroupQuoteRequestWatcher, updateGroupRequestWatcher, getGroupRequestWatcher, preRegisterGroupRequestWatcher } from './containers/Group/GroupRegistration/saga.es6';
import { getSmsTemplatesRequestWatcher, createSmsTemplatesRequestWatcher, updateSmsTemplatesRequestWatcher, deleteSmsTemplatesRequestWatcher, getSupportedParametersRequestWatcher } from './containers/SmsTemplate/saga.es6';
import { getGroupProductsRequestWatcher, createGroupClaimCallBackRequestWatcher } from './containers/BritamLandingPage/saga.es6';
import {
  getAllGroupsRequestWatcher,
  addGroupPaymentRequestWatcher,
  deleteGroupRequestWatcher
} from './containers/Group/AllGroups/saga.es6';
import {addRemittancesRequestWatcher} from "./containers/ManualRemittance/saga.es6";
import {cashBackRequestWatcher, markAsPaidRequestWatcher, markAsUnpaidRequestWatcher} from "./containers/Cashback/saga.es6";
import { uploadLoyaltyDataRequestWatcher, mapColumnsToFieldsRequestWatcher, getPreviousLoyaltyStatusRequestWatcher, getPreviousLoyaltiesStatusRequestWatcher } from './containers/LoyaltyEligibility/saga.es6';
import { addDependantRequestWatcher, addPrincipalRequestWatcher, deleteDependantRequestWatcher, deletePrincipalRequestWatcher, editDependantRequestWatcher, editPrincipalRequestWatcher, getTempMembersRequestWatcher, uploadExcelMembersRequestWatcher } from './containers/Group/AddEditMembers/saga.es6';
import { getPartnerConfigsClaimRequestWatcher, getPartnerConfigsGroupRequestWatcher, getPartnerConfigsRequestWatcher, updatePartnerConfigGroupRequestWatcher, updatePartnerConfigClaimRequestWatcher, updatePartnerConfigRequestWatcher, updatePartnerRequestWatcher } from './containers/PartnerStatus/saga.es6';
import {
  addCustomerDependantRequestWatcher, deleteCustomerDependantRequestWatcher,
  getCustomerDependantRequestWatcher,
  getCustomerDependantsRequestWatcher, updateCustomerDependantRequestWatcher
} from "./containers/DependantsList/saga.es6";
import {
  addCustomerDocumentRequestWatcher, deleteCustomerDocumentRequestWatcher,
  getCustomerDocumentRequestWatcher,
  getCustomerDocumentsRequestWatcher, updateCustomerDocumentRequestWatcher
} from "./containers/CustomerFiles/saga.es6";
import {
  createDebitOrderzRequestWatcher,
  editDebitOrderzRequestWatcher,
  getBanksWatcher,
  getDebitOrderzRequestWatcher,
  hardDeleteDebitOrderzRequestWatcher
} from './containers/CustomerScreen/components/DebitOrder/state/saga.es6';
import { getAllCustomerClaimsWatcher, getAllPaymentsWatcher, getAllPoliciesWatcher, getAllPremiumsWatcher, getAllProductsWatcher, getTotalPayablePremiumWatcher,getAllMandatesWatcher } from './containers/CustomerScreen/saga.es6';

import {getCustomeBeneficiaryRequestWatcher,addCustomerBeneficiaryRequestWatcher,updateCustomerBeneficiaryRequestWatcher,deleteCustomerBeneficiaryRequestWatcher} from './containers/BeneficiaryList/saga.js'

import {getCustomeBeneficiaryBankDetailsRequestWatcher,addCustomerBeneficiaryBankDetailsRequestWatcher,updateCustomerBeneficiaryBankDetailsRequestWatcher,deleteCustomerBeneficiaryBankDetailsRequestWatcher} from './containers/BeneficiaryBankDetailsList/saga.js'

import {getCustomerBankDetailsRequestWatcher,updateCustomerBankDetailsRequestWatcher,addCustomerBankDetailsRequestWatcher,deleteCustomerBankDetailsRequestWatcher} from './containers/CustomerBankDetailsList/saga.js'
import {createQuoteRequestWatcher, BuyPolicyRequestWatcher,changeQuoteRequestWatcher,UploadPolicyDocumentRequestWatcher,createDebitOrdersDcpRequestWatcher, amendQuoteRequestWatcher} from './containers/Policyv2/Redux/saga.js'

import {getCustomerPoliciesRequestWatcher, getCustomerQuotesRequestWatcher,getCustomerPremiumsComplexRequestWatcher,cancelPolicyRequestWatcher,PolicyNotesRequestWatcher,PolicyNotesAddRequestWatcher} from './containers/PolicyV2CustomerStatus/Redux/saga.js'

import {selectPolicyRequestWatcher,initiateClaimDcpRequestWatcher,getCustomerClaimsDcpRequestWatcher} from './containers/ClaimInitiationv2/Redux/saga.js'
import {getClaimsDcpRequestWatcher} from './containers/ClaimDashboardv2/Redux/saga.js'
import {getPreauthDetailsRequestWatcher, createExpenseRequestWatcher,deleteExpenseRequestWatcher,editExpenseRequestWatcher,addClaimDocumentRequestWatcher,deleteClaimDocumentRequestWatcher,claimActionsRequestWatcher,claimNotesRequestWatcher,getClaimProcessingDcpRequestWatcher, saveClaimDcpDocumentRequestWatcher,claimFacilityNotesRequestWatcher} from './containers/ClaimsProcessingV2/Redux/saga'
import {
  addTemplateDocumentRequestWatcher,
  deleteTemplateDocumentRequestWatcher,
  downloadDocumentRequestWatcher,
  editTemplateDocumentRequestWatcher,
  getTemplateDocumentRequestWatcher
} from './containers/DocumentGeneration/saga.es6';
import {createLOURequestWatcher,editLOURequestWatcher, getLOUSRequestWatcher,createHospitalRequestWatcher,deleteHospitalRequestWatcher,getHospitalsRequestWatcher,searchHospitalsRequestWatcher,createDiagnosisRequestWatcher,deleteDiagnosisRequestWatcher,getDiagnosisRequestWatcher,searchDiagnosisRequestWatcher, deleteLouRequestWatcher} from './partner-apps/ktda/PreAuth/Redux/saga.js'
import {dailyReportRequestWatcher} from "./containers/DailyReports/saga.js"
import {bulkApproveRequestWatcher} from "./partner-apps/ktda/ClaimDashboard/Redux/saga.js"
import {addBulkPolicyOnboardingRequestWatcher,saveBulkPolicyOnboardingRequestWatcher,getBulkPoliciesOnboardingBatchesRequestWatcher,getFailedBulkPoliciesBatchesRequestWatcher} from "./partner-apps/ktda/BulkPolicyOnboarding/Redux/saga.js"
import {getSmsActivitiesRequestWatcher, initiatePaymentWatcher} from './partner-apps/Konyana/CustomerStatus/Redux/saga'
import { addBulkPaymentsRequestWatcher, getBulkPaymentsBatchesRequestWatcher, getFailedBulkPaymentsBatchesRequestWatcher, saveBulkPaymentsRequestWatcher } from './partner-apps/ktda/BulkPayments/Redux/saga';

import {getOrangePoliciesWatcher,purchaseOrangePolicyRequestWatcher,OrangeProductListWatcher,deleteOrangePolicyWatcher,updateOrangePolicyRequestWatcher } from './partner-apps/Orange/CustomerStatus/Containers/Policies/Redux/saga.js'
import {getAllOrangePaymentsWatcher,getAllOrangeProductsWatcher,AddOrangeMissingPaymentRequestWatcher} from './partner-apps/Orange/CustomerStatus/Containers/Payments/Redux/saga.js'

import {getAllOrangePremiumsWatcher} from './partner-apps/Orange/CustomerStatus/Containers/Premiums/Redux/saga.js'
import {getAllOrangeCustomerClaimsWatcher} from './partner-apps/Orange/CustomerStatus/Containers/Claims/Redux/saga.js'
import {getOrangeSmsActivitiesRequestWatcher} from './partner-apps/Orange/CustomerStatus/Containers/SMSActivity/Redux/saga.js'
import {getOrangeCustomerDocumentsRequestWatcher,addOrangeCustomerDocumentRequestWatcher,deleteOrangeCustomerDocumentRequestWatcher} from './partner-apps/Orange/CustomerStatus/Containers/Documents/Redux/saga.js'
import { getFeatureFlagsRequestWatcher } from './containers/FeatureFlags/saga.es6.js';
import {registerAccessBankRequestWatcher,updateAccessBankCustomerRequestWatcher} from './partner-apps/AccessBank/CustomerStatus/Containers/RegisterCustomer/redux/saga.js'
import {getPoliciesDcpRequestWatcher} from './partner-apps/AccessBank/PoliciesDashboard/Redux/saga.js'
import {getPolicyProcessingDcpRequestWatcher, policyActionsRequestWatcher,policyRejectActionsRequestWatcher,addPolicyNotesAddRequestWatcher,cancelPolicyActionRequestWatcher,deleteCustomerPolicyDocumentRequestWatcher, getPaymentsByPolicyGuidRequestWatcher} from './partner-apps/AccessBank/PoliciesProcessing/Redux/saga.js'
import {getPaymentsDcpRequestWatcher} from './partner-apps/AccessBank/PaymentsDashboard/Redux/saga.js'
import {getPaymentProcessingDcpRequestWatcher, paymentApproveActionRequestWatcher , paymentRejectActionsRequestWatcher} 
from './partner-apps/AccessBank/PaymentProcessing/Redux/saga.js'
import {getAccessBankCustomerQuotesRequestWatcher} from './partner-apps/AccessBank/CustomerStatus/Containers/Quotes/Redux/saga.js'
import {getAccessBankCustomerPremiumsRequestWatcher} from './partner-apps/AccessBank/CustomerStatus/Containers/Premiums/Redux/saga.js'
import {getCustomerAccessBankClaimsRequestWatcher} from './partner-apps/AccessBank/CustomerStatus/Containers/Claims/redux/saga.js'
import {getAccessBankSmsActivitiesRequestWatcher} from './partner-apps/AccessBank/CustomerStatus/Containers/SmsActivity/Redux/saga.js'
import {getEmailTemplatesRequestWatcher,addEmailTemplateRequestWatcher} from './containers/EmailTemplates/Redux/saga.js'
import {addReportTemplateRequestWatcher, getReportsTemplatesRequestWatcher,editReportTemplateRequestWatcher,deleteReportTemplateRequestWatcher} from './containers/ReportsConfigs/Redux/saga.js'
import {generateReportRequestWatcher} from './containers/Reports/Redux/saga.js'
import { addGuideRequestWatcher, deleteGuideRequestWatcher, editGuideRequestWatcher, getGuideRequestWatcher, getGuidesRequestWatcher } from './partner-apps/AccessBank/Knowledgebase/Redux/saga.js';
import {getElasticSearchResultsRequestWatcher} from './containers/ElasticSearch/Redux/saga.js'
import {NGSGroupCustomerQuotesRequestWatcher} from './partner-apps/NgsUganda/CustomerStatus/Container/Quotes/Redux/saga.js'
import {getNgsCustomerClaimsRequestWatcher} from './partner-apps/NgsUganda/CustomerStatus/Container/Claims/redux/saga.js'
import { getAuditsRequestWatcher } from './containers/Audits/Redux/saga.js';
import {getClaimsDcpFilteredRequestWatcher } from './partner-apps/ktda/ClaimDashboard/Redux/saga.js'
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield [
    registerRequestWatcher(),
    loginRequestWatcher(),
    purchasePolicyRequestWatcher(),
    searchCustomerByMsisdnRequestWatcher(),
    claimSubmissionWatcher(),
    deletePolicyWatcher(),
    getCustomerRequestWatcher(),
    claimTypesWatcher(),
    profileRequestWatcher(),
    profileUpdateRequestWatcher(),
    productListWatcher(),
    updateCustomerRequestWatcher(),
    refreshSessionRequestWatcher(),
    openClaimRequestWatcher(),
    payableClaimRequestWatcher(),
    closedClaimRequestWatcher(),
    summaryClaimRequestWatcher(),
    claimStatusRequestWatcher(),
    claimProcessingWatcher(),
    claimSearchRequestWatcher(),
    reportsRequestWatcher(),
    getUsersRequestWatcher(),
    createUserRequestWatcher(),
    getPartnersRequestWatcher(),
    getRolesRequestWatcher(),
    getCurrentRolesRequestWatcher(),
    getCurrentPartnersRequestWatcher(),
    deleteUserRequestWatcher(),
    changeUserPasswordRequestWatcher(),
    updatePolicyRequestWatcher(),
    changeLoyaltyRegistrationWatcher(),
    pullCustomerKycWatcher(),
    deletePremiumWatcher(),
    claimHospitalsWatcher(),
    claimRejectionReasonWatcher(),
    claimCallbackRequestWatcher(),
    forgotPasswordRequestWatcher(),
    claimFinalisationReportRequestWatcher(),
    reportsOpenClaimRequestWatcher(),
    reportsRegistrationRequestWatcher(),
    tigoUploadLoyaltyRequestWatcher(),
    loyaltyRenewalRequestWatcher(),
    createDebitOrderWatcher(),
    debitOrderTransactionsRequestWatcher(),
    updateDebitOrderTransactionsRequestWatcher(),
    reportsPoliciesRequestWatcher(),
    getPolicyProductWatcher(),
    initiatePolicyMobilePaymentWatcher(),
    claimUploadRequestWatcher(),
    claimFileDeleteRequestWatcher(),
    claimCallbackDeleteRequestWatcher(),
    reportsRefundRequestWatcher(),
    reportVasAgentRequestWatcher(),
    reportAgentWebRequestWatcher(),
    reportsPayableClaimRequestWatcher(),
    refreshClaimRequestWatcher(),
    reportPaymentRequestWatcher(),
    retryFailedClaimPaymentRequestWatcher(),
    uploadBulkSmsRequestWatcher(),
    summariseBulkSmsRequestWatcher(),
    downloadBulkSmsRequestWatcher(),
    reportsPaidClaimRequestWatcher(),
    reportsClaimNoticationRequestWatcher(),
    reportsClaimRegisterRequestWatcher(),
    reportsPremiumRegisterRequestWatcher(),
    reportSuspendedPoliciesRequestWatcher(),
    updateClaimPaymentOutcomeRequestWatcher(),
    AddMissingPaymentRequestWatcher(),
    claimConditionListWatcher(),
    reportsPremiumDueRequestWatcher(),
    expiredClaimReversalWatcher(),
    updateRolesAndPartnersRequestWatcher(),
    resetPasswordRequestWatcher(),
    createCountryRequestWatcherSaga(),
    getCountriesRequestWatcherSaga(),
    deleteCountryRequestWatcherSaga(),
    updateCountryRequestWatcherSaga(),
    getLanguagesWatcher(),
    getAllPartnersRequestWatcher(),
    createPartnerRequestWatcher(),
    getPartnerRequestWatcher(),
    getAllProductsRequestWatcher(),
    createProductRequestWatcher(),
    getProductRequestWatcher(),
    getProductEnumRequestWatcher(),
    createProductPremiumRequestWatcher(),
    getProductPremiumsEnumRequestWatcher(),
    getAllProductPremiumsRequestWatcher(),
    addMultiplePaymentRequestWatcher(),
    addSinglePaymentRequestWatcher(),
    getSmsTemplatesRequestWatcher(),
    createSmsTemplatesRequestWatcher(),
    updateSmsTemplatesRequestWatcher(),
    deleteSmsTemplatesRequestWatcher(),
    getSupportedParametersRequestWatcher(),
    stopBlastRequestWatcher(),
    getBatchProgressRequestWatcher(),
    sendRecurringPaymentRecommendationSmsRequestWatcher(),
    getCurrentGroupMembersRequestWatcher(),
    getCurrentGroupMembersForAdminsRequestWatcher(),
    getCurrentGroupMembersForCsvRequestWatcher(),
    getGroupsRequestWatcher(),
    registerGroupRequestWatcher(),
    getGroupProductsRequestWatcher(),
    getActualGroupQuoteRequestWatcher(),
    getCurrentGroupClaimsRequestWatcher(),
    getCurrentGroupPremiumsRequestWatcher(),
    getCurrentGroupPoliciesRequestWatcher(),
    getCurrentGroupPaymentsRequestWatcher(),
    getAllGroupsRequestWatcher(),
    addGroupPaymentRequestWatcher(),
    getCurrentGroupClaimCallbacksRequestWatcher(),
    createGroupClaimCallBackRequestWatcher(),
    addRemittancesRequestWatcher(),
    cashBackRequestWatcher(),
    markAsPaidRequestWatcher(),
    markAsUnpaidRequestWatcher(),
    uploadLoyaltyDataRequestWatcher(),
    mapColumnsToFieldsRequestWatcher(),
    getPreviousLoyaltyStatusRequestWatcher(),
    getPreviousLoyaltiesStatusRequestWatcher(),
    getTempMembersRequestWatcher(),
    addPrincipalRequestWatcher(),
    addDependantRequestWatcher(),
    editPrincipalRequestWatcher(),
    editDependantRequestWatcher(),
    deletePrincipalRequestWatcher(),
    deleteDependantRequestWatcher(),
    uploadExcelMembersRequestWatcher(),
    updateGroupRequestWatcher(),
    getGroupRequestWatcher(),
    preRegisterGroupRequestWatcher(),
    deleteGroupRequestWatcher(),
    deleteMyGroupRequestWatcher(),
    createPremiumRequestWatcher(),
    hardDeletePremiumRequestWatcher(),
    createBenefitRequestWatcher(),
    hardDeleteBenefitRequestWatcher(),
    editProductRequestWatcher(),
    editPremiumRequestWatcher(),
    editBenefitRequestWatcher(),
    getPartnerConfigsRequestWatcher(),
    getPartnerConfigsClaimRequestWatcher(),
    getPartnerConfigsGroupRequestWatcher(),
    updatePartnerConfigGroupRequestWatcher(),
    updatePartnerConfigRequestWatcher(),
    updatePartnerConfigClaimRequestWatcher(),
    updatePartnerRequestWatcher(),
    logoutRequestWatcher(),
    getGroupProductConfigsRequestWatcher(),
    updateGroupProductConfigsRequestWatcher(),
    addGroupProductConfigsRequestWatcher(),
    getCustomerDependantsRequestWatcher(),
    getCustomerDependantRequestWatcher(),
    addCustomerDependantRequestWatcher(),
    updateCustomerDependantRequestWatcher(),
    deleteCustomerDependantRequestWatcher(),
    getCustomerDocumentsRequestWatcher(),
    getCustomerDocumentRequestWatcher(),
    addCustomerDocumentRequestWatcher(),
    updateCustomerDocumentRequestWatcher(),
    deleteCustomerDocumentRequestWatcher(),
    getAllBanksWatcher(),
    createDebitOrderzRequestWatcher(),
    hardDeleteDebitOrderzRequestWatcher(),
    editDebitOrderzRequestWatcher(),
    getDebitOrderzRequestWatcher(),
    getBanksWatcher(),
    getUserRequestWatcher(),
    getAllPoliciesWatcher(),
    getAllPremiumsWatcher(),
    getAllPaymentsWatcher(),
    getAllProductsWatcher(),
    getAllCustomerClaimsWatcher(),
    getClosedClaimCallbackRequestWatcher(),
    getExtendedFamilyProductConfigsRequestWatcher(),
    updateExtendedFamilyProductConfigsRequestWatcher(),
    addExtendedFamilyProductConfigsRequestWatcher(),
    getPremiumConfigsRequestWatcher(),
    createPremiumConfigRequestWatcher(),
    createAddonConfigRequestWatcher(),
    getTotalPayablePremiumWatcher(),
    getAllMandatesWatcher(),
    getCustomeBeneficiaryRequestWatcher(),
    addCustomerBeneficiaryRequestWatcher(),
    updateCustomerBeneficiaryRequestWatcher(),
    deleteCustomerBeneficiaryRequestWatcher(),
    getCustomeBeneficiaryBankDetailsRequestWatcher(),
    addCustomerBeneficiaryBankDetailsRequestWatcher(),
    updateCustomerBeneficiaryBankDetailsRequestWatcher(),
    deleteCustomerBeneficiaryBankDetailsRequestWatcher(),
    getCustomerBankDetailsRequestWatcher(),
    updateCustomerBankDetailsRequestWatcher(),
    addCustomerBankDetailsRequestWatcher(),
    deleteCustomerBankDetailsRequestWatcher(),
    createQuoteRequestWatcher(),
    changeQuoteRequestWatcher(),
    BuyPolicyRequestWatcher(),
    getCustomerPoliciesRequestWatcher(),
    cancelPolicyRequestWatcher(),
    getCustomerQuotesRequestWatcher(),
    getCustomerPremiumsComplexRequestWatcher(),
    getStartedMessageRequestWatcher(),
    selectPolicyRequestWatcher(),
    downloadDocumentRequestWatcher(),
    getTemplateDocumentRequestWatcher(),
    addTemplateDocumentRequestWatcher(),
    deleteTemplateDocumentRequestWatcher(),
    editTemplateDocumentRequestWatcher(),
    initiateClaimDcpRequestWatcher(),
    getCustomerClaimsDcpRequestWatcher(),
    getClaimsDcpRequestWatcher(),
    getClaimsDcpFilteredRequestWatcher(),
    createExpenseRequestWatcher(),
    deleteExpenseRequestWatcher(),
    editExpenseRequestWatcher(),
    addClaimDocumentRequestWatcher(),
    deleteClaimDocumentRequestWatcher(),
    claimActionsRequestWatcher(),
    claimNotesRequestWatcher(),
    claimFacilityNotesRequestWatcher(),
    getClaimProcessingDcpRequestWatcher(),
    saveClaimDcpDocumentRequestWatcher(),
    PolicyNotesRequestWatcher(),
    PolicyNotesAddRequestWatcher(),
    createLOURequestWatcher(),
    editLOURequestWatcher(),
    getLOUSRequestWatcher(),
    createHospitalRequestWatcher(),
    deleteHospitalRequestWatcher(),
    getHospitalsRequestWatcher(),
    searchHospitalsRequestWatcher(),
    createDiagnosisRequestWatcher(),
    deleteDiagnosisRequestWatcher(),
    getDiagnosisRequestWatcher(),
    searchDiagnosisRequestWatcher(),
    dailyReportRequestWatcher(),
    bulkApproveRequestWatcher(),
    addBulkPolicyOnboardingRequestWatcher(),
    saveBulkPolicyOnboardingRequestWatcher(),
    getBulkPoliciesOnboardingBatchesRequestWatcher(),
    getFailedBulkPoliciesBatchesRequestWatcher(),
    getPreauthDetailsRequestWatcher(),
    deleteLouRequestWatcher(),
    getSmsActivitiesRequestWatcher(),
    initiatePaymentWatcher(),
    getBulkPaymentsBatchesRequestWatcher(),
    addBulkPaymentsRequestWatcher(),
    saveBulkPaymentsRequestWatcher(),
    getFailedBulkPaymentsBatchesRequestWatcher(),
    getOrangePoliciesWatcher(),
    purchaseOrangePolicyRequestWatcher(),
    OrangeProductListWatcher(),
    deleteOrangePolicyWatcher(),
    updateOrangePolicyRequestWatcher(),
    getAllOrangePaymentsWatcher(),
    getAllOrangeProductsWatcher(),
    AddOrangeMissingPaymentRequestWatcher(),
    getAllOrangePremiumsWatcher(),
    getAllOrangeCustomerClaimsWatcher(),
    claimCreateRequestWatcher(),
    getOrangeSmsActivitiesRequestWatcher(),
    getOrangeCustomerDocumentsRequestWatcher(),
    addOrangeCustomerDocumentRequestWatcher(),
    deleteOrangeCustomerDocumentRequestWatcher(),
    getFeatureFlagsRequestWatcher(),
    registerAccessBankRequestWatcher(),
    updateAccessBankCustomerRequestWatcher(),
    UploadPolicyDocumentRequestWatcher(),
    createDebitOrdersDcpRequestWatcher(),
    getPoliciesDcpRequestWatcher(),
    getPolicyProcessingDcpRequestWatcher(),
    policyActionsRequestWatcher(),
    policyRejectActionsRequestWatcher(),
    addPolicyNotesAddRequestWatcher(),
    getPaymentsDcpRequestWatcher(),
    getPaymentProcessingDcpRequestWatcher(),
    paymentApproveActionRequestWatcher(),
    paymentRejectActionsRequestWatcher(),
    cancelPolicyActionRequestWatcher(),
    getAccessBankCustomerQuotesRequestWatcher(),
    getAccessBankCustomerPremiumsRequestWatcher(),
    getCustomerAccessBankClaimsRequestWatcher(),
    getAccessBankSmsActivitiesRequestWatcher(),
    deleteCustomerPolicyDocumentRequestWatcher(),
    getEmailTemplatesRequestWatcher(),
    addEmailTemplateRequestWatcher(),
    reportPreAuthorizationRequestWatcher(),
    reportClaimDeathRequestWatcher(),
    reportHospitalClaimRequestWatcher(),
    reportClaimReimbursementRequestWatcher(),
    reportClaimFinalizationReimbursementRequestWatcher(),
    reportClaimFinalizationDeathRequestWatcher(),
    reportFinalizationHospitalClaimRequestWatcher(),
    addReportTemplateRequestWatcher(),
    getReportsTemplatesRequestWatcher(),
    generateReportRequestWatcher(),
    editReportTemplateRequestWatcher(),
    deleteReportTemplateRequestWatcher(),
    amendQuoteRequestWatcher(),
    getPaymentsByPolicyGuidRequestWatcher(),
    getGuideRequestWatcher(),
    getGuidesRequestWatcher(),
    addGuideRequestWatcher(),
    editGuideRequestWatcher(),
    deleteGuideRequestWatcher(),
    getElasticSearchResultsRequestWatcher(),
    NGSGroupCustomerQuotesRequestWatcher(),
    getNgsCustomerClaimsRequestWatcher(),
    getAuditsRequestWatcher(),
  ];
}

