import React from "react";
import './adonsTable.css'
import { v4 as uuidv4 } from 'uuid';

import TableInc from "../../../../../shared-ui/TableInc";
import { updateExtendedConfig } from "../../../../../lib/requests.es6";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { RELATIONSHIP_POOL } from "../../../PoductConstants";
import { getPartner } from "../../../../../lib/access.es6";

export default function AddOns(props) {

  const currencyCode = getPartner().country.currency_code
  const relationshipOptions = ['Member Only', 'Member & Family', ...Object.values(RELATIONSHIP_POOL)];

  if (props.addOns) {
    return (
      <>
        <div style={{ marginTop: "2%" }}>
          <TableInc
            title="Addons / Riders"
            columns={[
              { title: 'name', field: 'name' },
              {
                title: 'Benefit', field: 'benefit_amount_in_cents', type: 'numeric', render: rowData => <>
                  {
                    currencyCode + " " + (rowData.benefit_amount_in_cents/100).toLocaleString()
                  }
                </>
              },
              {
                title: 'Premium', field: 'premium_amount_in_cents',  type: 'numeric', render: rowData => <>
                  {
                    currencyCode + " " + (rowData.premium_amount_in_cents/100).toLocaleString()
                  }
                </>
              },
              {
                title: 'Allowed Relationships',
                field: 'allowed_relationships',
                render: rowData => rowData.allowed_relationships ? rowData.allowed_relationships.join(', '): '',
                editComponent: props => (
                  <Select
                    multiple
                    value={props.value || []}
                    onChange={e => props.onChange(e.target.value)}
                  >
                    {relationshipOptions.map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                ),
              },
            ]}
            data={
              props.addOns
            }
            options={{
              pageSize: props.addOns.length,
              addRowPosition: 'first',
              showTitle: true,
              actionsColumnIndex: -1

            }}

            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                newData.uuid = uuidv4()

                  const addOnsConfig = {
                    "product_guid": props.productGuid,
                    ...props.extendedConfig,
                    "content": {
                      ...props.extendedConfig.content,
                      add_ons: [...props.extendedConfig.content.add_ons, {
                        ...newData,
                        premium_amount_in_cents: Number(newData.premium_amount_in_cents) * 100,
                        benefit_amount_in_cents: Number(newData.benefit_amount_in_cents) * 100,
                      }]
                    }
                  }
                  resolve();
                  updateExtendedConfig(addOnsConfig).then(
                    (response) => {
                      resolve();
                      props.refreshProduct(props.productGuid);
                      window.location.reload(true);

                    }
                  ).catch(
                    (error) => {
                      console.log("did not work", error)
                      reject();
                    }
                  )

                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  console.log('you are updating  this old_data: ', oldData, " with this new data: ", newData)
                  resolve();
                }),
              onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                const indexToDelete = oldData.tableData.id
                const add_ons = props.extendedConfig.content.add_ons
                add_ons.splice(indexToDelete, 1)
                const extendedConfig = {
                  "product_guid": props.productGuid,
                  ...props.extendedConfig,
                  "content": {
                    ...props.extendedConfig.content,
                    add_ons: add_ons
                  }
                }
                updateExtendedConfig(extendedConfig).then(
                  (response) => {
                    console.log("here is response", response);
                    resolve();
                    props.refreshProduct(props.productGuid);
                    window.location.reload(true);
                  }
                ).catch(
                  (error) => {
                    console.log("did not work", error)
                    reject();
                  }
                )
                resolve();
              }),
            }}

          />
        </div>
      </>
    );
  }
  return "No addons found, click on the add button to add new premums."
}
