import React from "react";
import { Grid, Box } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { useStyle } from "./Style";

export const Customer = ({ customer, handleViewCustomer }) => {
  const classes = useStyle();

  return (
    <Grid item xs={12} md={12} sm={12}>
      <div className={classes.customerCardWrapper}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={2}
            sm={2}
            className={classes.customerIconWrapper}
          >
            <Box className={classes.customerIcons}>
              <PersonIcon fontSize="14px" sx={{ color: "#ffffff" }} />
            </Box>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <span>Full Name</span>
            <p>{customer.full_name || "N/A"}</p>
          </Grid>

          <Grid item xs={12} md={2} sm={2}>
            <span>Phone Number</span>
            <p>{customer.msisdn || "N/A"}</p>
          </Grid>

          <Grid item xs={12} md={2} sm={2}>
            <span>National ID</span>
            <p>{customer.national_id || "N/A"}</p>
          </Grid>

          <Grid item xs={12} md={3} sm={3} className={classes.viewWrapper}>
            <Box display="flex" justifyContent="flex-start">
              <ul style={{ cursor: "pointer" }} onClick={() => handleViewCustomer(customer)}>
                <li >View Customer details </li>
                <li><ChevronRightOutlinedIcon /></li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};
