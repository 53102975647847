import React from 'react';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import LinearProgress from 'material-ui/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '@material-ui/core';
import { getS3BucketSignedUrlForClaims } from '../../lib/requests.es6';

export default class ClaimProcessingUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      completed: 0,
      message: null,
      s3Url: 'https://claims-document-received.s3.amazonaws.com',
    };
  }

  compareUploads(claimUploadA, claimUploadB) {
    if (
      new Date(claimUploadA.created_at).getTime() ===
      new Date(claimUploadB.created_at).getTime()
    ) {
      return 0;
    }
    if (new Date(claimUploadA.created_at) > new Date(claimUploadB.created_at)) {
      return -1;
    }
    return 1;
  }

  // TODO: We need to claim processing to know about the file uploaded to do this in one go
  handleFinishedUpload(info) {
    console.log(info);
    info.status = 'Succeeded';
    info.claim_guid = this.props.claimGuid;
    info.filename = info.fileUrl.split('.s3.amazonaws.com/').at(1);
    info.url = info.signedUrl;
    this.props.updateClaimUploadHandler(info);
  }

  trimUrl(url) {
    return url.substring(0, url.indexOf('?'));
  }

  progress(completed) {
    if (completed > 100) {
      this.setState({ completed: 100 });
    } else {
      this.setState({ completed });
    }
  }

  errorHandler(message) {
    this.setState({ message });

    const tempUpload = JSON.parse(sessionStorage.getItem('tempUpload'));
    if (tempUpload) {
      tempUpload.status = 'Failed';
      this.props.updateClaimUploadHandler(tempUpload);
    }
  }

  deleteClaimFile(guid) {
    this.props.deleteClaimFileUpload({
      guid,
      claim_guid: this.props.claimGuid,
    });
  }

  displayCompleteMessage() {
    const style = {
      margin: '5%',
    };
    const labels = this.props.labels;
    if (this.state.completed === 0) {
      return (
        <div style={style}>
          <h4>{labels.dragAndDrop}</h4>
          <h4>{labels.Or}</h4>
          <h4>{labels.clickUpload}</h4>
        </div>
      );
    }
    if (this.state.completed > 0 && this.state.completed < 100) {
      return (
        <div style={style}>
          <h4>{labels.uploadProgress}</h4>
        </div>
      );
    }
    if (this.state.completed >= 100 && !this.state.message) {
      return (
        <div style={style}>
          <h4 style={{ color: 'green' }}>{labels.uploadSuccess}</h4>
          <h4>{labels.uploadMore}</h4>
        </div>
      );
    }
    if (this.state.completed >= 100 && this.state.message) {
      return (
        <div style={style}>
          <h4>{labels.uploadFailure}</h4>
        </div>
      );
    }
    return '';
  }

  renderFileTable() {
    const labels = this.props.labels;
    if (this.props.files.length > 0) {
      return this.renderNonEmptyList();
    }
    return (
      <div style={{ textAlign: 'center' }}>
        <h4>{labels.titleNoFiles}</h4>
      </div>
    );
  }

  renderUploader() {
    const uploadOptions = {
      getSignedUrl: (file, callback)=> getS3BucketSignedUrlForClaims(file, callback, this.props.claimGuid),
      s3Url: this.state.s3Url,
      uploadRequestHeaders: {},
      onProgress: this.progress.bind(this),
      onError: this.errorHandler.bind(this),
    };

    const style = {
      loader: {
        display: this.state.completed > 0 ? '' : 'none',
        width: '52%',
      },
      s3Uploader: {
        display: this.state.completed > 0 ? 'none' : '',
      },
      loaderColor: this.state.message ? 'red' : 'green',
    };

    if (this.fileUploadReadOnly()) {
      return <div />;
    }

    return (
      <div className="form-group">
        <div>
          <center>
            <DropzoneS3Uploader
              onFinish={this.handleFinishedUpload.bind(this)}
              maxSize={1024 * 1024 * 5}
              upload={uploadOptions}
              s3Url={this.state.s3Url}
              passChildrenProps={false}
              accept="image/png, image/jpeg, image/gif, image/webp, image/jpg, application/pdf"
            >
              <div>
                <br />
                <br />
                {this.displayCompleteMessage()}
              </div>
            </DropzoneS3Uploader>
            <br />
            <br />

            <p style={{ color: 'grey' }}>You can only upload the following file types: <strong>.png .jpeg .jpg .gif .webp .pdf</strong></p>
          </center>

          <br />
          <br />
          <LinearProgress
            color={style.loaderColor}
            style={style.loader}
            mode="determinate"
            value={this.state.completed}
          />
        </div>
      </div>
    );
  }

  fileUploadReadOnly() {
    const status = this.props.claimStatus;

    // TODO: this list should come from the API. i.e. not hard coded
    return [
      'Approved',
      'Approved_Paid',
      'Approved_Pending',
      'Payment_In_Review',
      'Rejected_Pending',
      'Rejected',
      'ExGratia',
      'ExGratia_Paid',
    ].includes(status);
  }

  render() {
    return (
      <center>
        {' '}
        <CircularProgress
          style={{ display: this.props.claimFilesLoader ? '' : 'none' }}
        />
        <div>
          {this.renderFileTable()}
          <br />
          {this.renderUploader()}
        </div>
      </center>
    );
  }

  renderNonEmptyList() {
    const labels = this.props.labels;
    const btnStyle = {
      green: {
        backgroundColor: 'green',
        color: 'white',
        margin: '2px',
      },
      danger: {
        backgroundColor: 'orangered',
        color: 'white',
        margin: '2px',
      },
    };
    return (
      <div className="form-horizontal form-label-left" noValidate>
        <br />
        <h5>{labels.documentReceived}</h5>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>{labels.index}</th>
              <th>{labels.filename}</th>
              <th>{labels.createdAt}</th>
              <th>{labels.uploadStatus}</th>
              <th>{labels.file}</th>
              <th>{labels.delete}</th>
            </tr>
          </thead>
          <tbody className="policies-list">
            {this.props.files.sort(this.compareUploads).map((aFile, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  {decodeURI(
                    this.trimUrl(aFile.url)
                      .split('/')
                      .pop()
                  )}
                </td>
                <td>{aFile.created_at}</td>
                <td>{aFile.status}</td>
                <td>
                  {aFile.status === 'Succeeded' ? (
                    <Button
                      disabled={aFile.status !== 'Succeeded'}
                      variant="contained"
                      style={btnStyle.green}
                    >
                      {
                        <a
                          href={aFile.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: 'white' }}
                        >
                          {labels.view}
                        </a>
                      }
                    </Button>
                  ) : (
                    <Button disabled={
                         aFile.status === 'Pending' || true
                    } variant="contained">
                      {labels.view}
                    </Button>
                  )}
                </td>
                <td>
                  <Button
                    style={btnStyle.danger}
                    onClick={this.deleteClaimFile.bind(this, aFile.guid)}
                    disabled={
                      this.fileUploadReadOnly() || aFile.active === false
                    }
                    variant="contained"
                  >
                    {labels.delete}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
