import React from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { hashHistory } from 'react-router';

import LocalizedStrings from "react-localization";
import { localisedText } from "../../lib/localisation.es6";
import * as actions from "./actions.es6";
import { getPartner, getPartnerGuid, hasAuthourity } from "../../lib/access.es6";
import { updateCurrentLanguage } from "../../lib/languageUtils.es6";
import SkeletonWrapper from "./components/SkeletonWrapper/SkeletonWrapper";
import ProductsTable from "./components/ProductsTable/ProductsTable";
import ProductsHeader from "./components/ProductsHeader/ProductsHeader";
import AddDialog from "./components/AddProduct/AddDialog";

export class NewProductList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labels: new LocalizedStrings(localisedText),
      generalPartner: getPartnerGuid(),
      openViewDialog: false,
      openAddPremiumDialog: false,
      openViewPremiumsDialog: false,
      openCreateProductDialog: false,
    };
  }

  componentWillMount() {
    this.updateLanguage();

    if (hasAuthourity("VIEW_ALL_PRODUCTS")) {
      this.props.dispatch(
        actions.getAllProductsRequest({
          partnerGuid: this.state.generalPartner,
          activeOnly: false,
        })
      );
    }
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      "NewProductList",
      this.state.labels
    );
  }

  createProduct(payload) {
    this.props.dispatch(actions.createProductRequest({payload}));
    this.setState({ generalPartner: payload.partnerGuid });
    this.toggleCreateProductDialog();
  }
  closeAddProductDialog() {
    this.setState({ showAddProductDialog: false })
  }

  addProduct(product) {
    this.props.dispatch(actions.createProductRequest(product));
  }

  addPremium(premium) {
    this.props.dispatch(actions.createPremiumRequest(premium));
  }
  hardDeletePremium(premium) {
    this.props.dispatch(actions.hardDeletePremiumRequest(premium.guid));
  }

  addBenefit(benefit) {
    benefit['product_guid'] = this.props.productsData.createdProduct.guid
    this.props.dispatch(actions.createBenefitRequest(benefit));
  }
  hardDeleteBenefit(benefitPayload) {
    this.props.dispatch(actions.hardDeleteBenefitRequest(benefitPayload));
  }

  updateDialogProductType(type){
    this.props.dispatch(actions.updateDialogProductType(type))
  }

  viewCreatedProduct(){
    const productGuid = this.props.productsData.createdProduct.guid
    this.resetCreateBenefitsAndPremiumsLists()
    hashHistory.push(`admin/products/view/product/${productGuid}`);

  }
  resetCreateBenefitsAndPremiumsLists(){
    this.props.dispatch(actions.resetCreateBenefitsAndPremiumsLists())
  }

  render() {
    const currencyCode = getPartner().country.currency_code

    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg" style={{ marginTop: "2%" }}>
          <SkeletonWrapper loading={this.props.productsData.loader} />
          <AddDialog
            showAddProductDialog={this.state.showAddProductDialog}
            closeAddProductDialog={this.closeAddProductDialog.bind(this)}
            productType = {this.props.productsData.dialogProductType}
            createdProduct = {this.props.productsData.createdProduct}
            viewCreatedProduct={this.viewCreatedProduct.bind(this)}
            resetCreateBenefitsAndPremiumsLists={this.resetCreateBenefitsAndPremiumsLists.bind(this)}
            mainProducts = {this.props.productsData.products}
            addOns={['Tombstone', 'Groceries', 'Cow']}

            currencyCode ={currencyCode}
            // add product
            addProduct={this.addProduct.bind(this)}
            showAddProductProgressAlert={this.props.productsData.createProductLoader}
            showAddProductSuccessAlert={this.props.productsData.createProductSuccess}
            showAddProductErrorAlert={!!this.props.productsData.createProductError}
            addProductError={this.props.productsData.createProductError}
            resetAddProductSuccessAlert={()=>{this.props.dispatch(actions.resetCreateProductSuccess())}}
            resetAddProductErrorAlert={()=>{this.props.dispatch(actions.resetCreateProductError())}}
            updateDialogProductType={this.updateDialogProductType.bind(this)}

            // add premium
            addPremium={this.addPremium.bind(this)}
            showAddPremiumProgressAlert={this.props.productsData.createPremiumLoader}
            showAddPremiumSuccessAlert={this.props.productsData.createPremiumSuccess}
            showAddPremiumErrorAlert={!!this.props.productsData.createPremiumError}
            createPremiumError={this.props.productsData.createPremiumError}
            resetAddPremiumSuccessAlert={()=>{this.props.dispatch(actions.resetCreatePremiumSuccess())}}
            resetAddPremiumErrorAlert={()=>{this.props.dispatch(actions.resetCreatePremiumError())}}
            createdPremiums={this.props.productsData.createdPremiums}
            
            // hard delete
            hardDeletePremium={this.hardDeletePremium.bind(this)}
            showHardDeletePremiumProgressAlert={this.props.productsData.hardDeletePremiumLoader}
            showHardDeletePremiumSuccessAlert={this.props.productsData.hardDeletePremiumSuccess}
            showHardDeletePremiumErrorAlert={!!this.props.productsData.hardDeletePremiumError}
            resetHardDeletePremiumSuccessAlert={()=>{this.props.dispatch(actions.resetHardDeletePremiumSuccess())}}
            resetHardDeletePremiumErrorAlert={()=>{this.props.dispatch(actions.resetHardDeletePremiumError())}}


            // add benefit
            addBenefit={this.addBenefit.bind(this)}
            showAddBenefitProgressAlert={this.props.productsData.createBenefitLoader}
            showAddBenefitSuccessAlert={this.props.productsData.createBenefitSuccess}
            showAddBenefitErrorAlert={!!this.props.productsData.createBenefitError}
            createBenefitError={this.props.productsData.createBenefitError}
            resetAddBenefitSuccessAlert={()=>{this.props.dispatch(actions.resetCreateBenefitSuccess())}}
            resetAddBenefitErrorAlert={()=>{this.props.dispatch(actions.resetCreateBenefitError())}}
            createdBenefits={this.props.productsData.createdBenefits}
            
            // hard benefit
            hardDeleteBenefit={this.hardDeleteBenefit.bind(this)}
            showHardDeleteBenefitProgressAlert={this.props.productsData.hardDeleteBenefitLoader}
            showHardDeleteBenefitSuccessAlert={this.props.productsData.hardDeleteBenefitSuccess}
            showHardDeleteBenefitErrorAlert={!!this.props.productsData.hardDeleteBenefitError}
            resetHardDeleteBenefitSuccessAlert={()=>{this.props.dispatch(actions.resetHardDeleteBenefitSuccess())}}
            resetHardDeleteBenefitErrorAlert={()=>{this.props.dispatch(actions.resetHardDeleteBenefitError())}}
          />
          {
            !this.props.productsData.loader &&
            <>
              <ProductsHeader
                title={"Products"}
                showAddProductDialog={this.state.showAddProductDialog}
                showAddButton={true}
                onAddButtonClick={() => { this.setState({ showAddProductDialog: true }) }}
                showEditButton={false}
                onEditButtonClick={() => { }}
              />
              <ProductsTable

                hashHistory={hashHistory}
                products={this.props.productsData.products}
              />
              <br/>
              <br/>
            </>
          }
        </Container>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({
  productsData: state.newProducts,
  globalData: state.global,
}))(NewProductList);
