import * as React from 'react';
import './OpenClaimCallBacksTable.css'
import TableInc from '../../../shared-ui/TableInc';
import { formatDateLocale } from '../../../lib/utils.es6';


export default function ClosedClaimCallBacksTable(props) {
	const callbacks = props.callbacks || { data: [], page: 1, page_size: 10, total: 0};

	React.useEffect(() => {	
		props.onPageChange(1, 10, 'closed');
	}, []);

	if (props.loading) return <p>loading</p>;

	return (
		<>
			<TableInc
				title="Closed claim call back List"
				columns={[
					{ title: 'No', field: 'count', render: rowData => rowData.tableData.id + 1 },
					{ title: 'MSISDN', field: 'customer_msisdn' },
					{ title: 'Created At', field: `created_at`, render: rowData =>formatDateLocale(rowData.created_at)   },
					{ title: 'Closed At', field: `modifiedAt`, render: rowData => rowData.modifiedAt ? rowData.modifiedAt.substring(0, 10) : 'N/A' },
					{ title: 'Closed By', field: 'closedBy', render: rowData => rowData.reason.closed_by },
					{ title: 'Reason', field: 'reason', render: rowData => rowData.reason.reason },
				]}
				data={(query) =>
					new Promise((resolve, reject) => {
						resolve({
							data: [...callbacks.data],
							page: callbacks.page - 1,
							totalCount: callbacks.total,
						});
					})
				}
				options={{
					showTitle: false,
					pageSizeOptions: [1, 5, 10, 20, 50, 100],
					pageSize: callbacks.page_size,
				}}
				onChangeRowsPerPage={(pageSize) => {
					console.log(pageSize, "pageSize");
					props.onPageChange(callbacks.page, pageSize, 'closed');
				}}
				onChangePage={(page) => {
					console.log(page, "page");
					props.onPageChange(page + 1, callbacks.page_size, 'closed');
				}}
			/>
		</>
	);
}
