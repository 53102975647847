import React from 'react';
import LocalizedStrings from 'react-localization';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import * as actions from '../../../Customer/actions.es6';
import { buildFailureMessage, formatDate, reloadPage } from '../../../../lib/utils.es6';
import { localisedText } from '../../../../lib/localisation.es6';
import {
  getInternationalCallingCode,
  addInternationalCallingCode,
  getLocalNumberLength,
  partnerCustomerFieldsContains,
  hasInternationalCallingCode,
  partnerLanguages,
  partnerPullKyc,
  customerLanguagesVisible,
  languageLabel,
  getPartnerGuid,
  getPartner,
} from '../../../../lib/access.es6';
import { AppBar, Button, Grid, IconButton, TextField, Toolbar, Typography } from '@mui/material';
import { PRIMARY_THEME_COLOR_SHADE_1, SECONDARY_THEME_COLOR_SHADE_1 } from '../../../../lib/constants';
import AlertDialog from '../../../../components/AlertDialog/index.es6';
import { updateCurrentLanguage } from '../../../../lib/languageUtils.es6';

export class EditCustomer extends React.Component {
  constructor(props) {
    super(props);
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const hasWallet = currentCustomer ? currentCustomer.has_wallet : true;
    const localizedStrings = new LocalizedStrings(localisedText);
    this.state = {
      language: currentCustomer.language,
      labels: localizedStrings,
      hasWallet,
      isPartnerPullKyc: partnerPullKyc(),
    };
  }

  componentWillMount() {
    this.updateLanguage();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'RegisterCustomer',
      this.state.labels
    );
  }

  failureMessage() {
    const defaultErrorMessage =
      'An error occurred, this could be due to invalid data';
    const errorMessage = buildFailureMessage(
      defaultErrorMessage,
      this.props.currentCustomerData.errors
    );
    return errorMessage;
  }

  successMessage() {
    return this.state.labels.kyc_success_edit_message;
  }

  dialogDisplayUserErrorFeedback() {
    const styleErrorMessage = {
      display: this.props.currentCustomerData.errors ? 'block' : 'none',
    };
    return (
      <center style={styleErrorMessage}>
        {' '}
        <p style={{ color: 'red' }}>{this.failureMessage()} </p>{' '}
      </center>
    );
  }

  dialogDisplayUserSuccessFeedback() {
    const styleSuccessMessage = {
      display: this.props.currentCustomerData.success_message
        ? 'block'
        : 'none',
    };
    return (
      <center style={styleSuccessMessage}>
        {' '}
        <p style={{ color: 'green' }}>{this.successMessage()} </p>{' '}
      </center>
    );
  }

  // TODO: Move this in a helper method. It should use info on the country
  localNumber(msisdn) {
    const partner = getPartner();
    const internationalCallingCode = partner.country.international_calling_code
    const internationalCallingCodeLength = internationalCallingCode.length
    return msisdn.substring(internationalCallingCodeLength);
  }

  pullCustomerKycRequest() {
    const customerData = {
      guid: this.props.currentCustomerData.currentCustomer.guid,
    };
    this.props.dispatch(actions.pullCustomerKycRequest(customerData));
  }

  submitUpdateCustomerRequest() {
    const labels = this.state.labels;
    const regForm = new FormData(document.getElementById('editDialogForm'));
    // TODO: Use class state instead of reducer to manage error
    // TODO: Move this validation in a helper method
    if (regForm.get('phone').length !== getLocalNumberLength()) {
      const errorObject = {
        message: `Phone number must be ${getLocalNumberLength()} digits`,
      };
      this.props.dispatch(actions.displayError(errorObject));
      return;
    }

    const customerData = {
      guid: this.props.currentCustomerData.currentCustomer.guid,
      date_of_birth: this.state.birthday,
      msisdn: getInternationalCallingCode() + regForm.get('phone'),
    };

    if (this.beneficiaryValid() === false) {
      // TODO: Display error message
      // return;
    }

    this.updateHasWalletInfo(customerData);

    // TODO: Use class state instead of reducer to manage error
    if (this.fullNameValid() === false) {
      const errorObject = {
        message: labels.errorMissingFullName,
      };
      this.props.dispatch(actions.displayError(errorObject));
      return;
    }
    this.updateFullNameInfo(customerData);

    // TODO: Use class state instead of reducer to manage error
    if (this.nationalIdValid() === false) {
      const errorObject = {
        message: labels.errorNationalIdMissing,
      };
      this.props.dispatch(actions.displayError(errorObject));
      return;
    }
    this.updateNationalIdInfo(customerData);

    // TODO: Use class state instead of reducer to manage error
    // TODO: Remove duplication
    if (this.firstNameValid() === false) {
      const errorObject = {
        message: labels.errorMissingFirstName,
      };
      this.props.dispatch(actions.displayError(errorObject));
      return;
    }
    this.updateFirstNameInfo(customerData);

    // TODO: Use class state instead of reducer to manage error
    // TODO: Remove duplication
    if (this.surnameValid() === false) {
      const errorObject = {
        message: labels.errorMissingSurname,
      };
      this.props.dispatch(actions.displayError(errorObject));
      return;
    }
    this.updateSurnameInfo(customerData);

    this.updateLanguageInfo(customerData);
    if (!this.languageValid()) {
      delete customerData.language;
    }

    this.updateBeneficiaryInfo(customerData);
    this.props.dispatch(actions.updateCustomerRequest(customerData));
  }

  updateBeneficiaryInfo(customerData) {
    const regForm = new FormData(document.getElementById('editDialogForm'));
    customerData.beneficiary_name = regForm.get('beneficiary');
    if (regForm.get('beneficiary_msisdn')) {
      customerData.beneficiary_msisdn = this.getInternationalNumber(
        regForm.get('beneficiary_msisdn')
      );
    }

    if (regForm.get('beneficiary_name')) {
      customerData.beneficiary_name = regForm.get('beneficiary_name')
    }
  }

  getInternationalNumber(number) {
    if (hasInternationalCallingCode(number)) {
      return number;
    }
    return getInternationalCallingCode() + number;
  }

  dateHandler(type, moment) {
    const rawDate = formatDate(new Date(moment));
    if (type === 'birthday') {
      this.setState({ birthday: rawDate });
    }
  }

  dateHandler2(moment) {
    const rawDate = formatDate(new Date(moment.target.value));
    this.setState({ birthday: rawDate });
  }

  handleOpen() {
    this.props.dispatch(actions.openEditCustomerModal());
  }

  resetAfterSucesfulyEdit() {
    this.props.dispatch(actions.resetUpdateCustomerl());
    this.handleClose()
    reloadPage()
  }

  resetAfterFailedEdit() {
    this.props.dispatch(actions.resetUpdateCustomerl());
  }

  handleClose() {
    this.props.closeEditCustomerDialog()
  }

  cancelEdit() {
    this.clearDialogForm();
    this.handleClose();
  }

  refreshCustomer() {
    this.props.dispatch(
      actions.getCustomer(this.props.currentCustomerData.currentCustomer.guid)
    );
  }

  clearDialogForm() {
    document.getElementById('editDialogForm').reset();
    this.setState({ birthday: '' });
  }

  loyaltyRegistrationText() {
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const labels = this.state.labels;
    if (currentCustomer.accept_loyalty === true) {
      return labels.loyaltyOpOut;
    }
    return labels.loyaltyOpIn;
  }

  loyaltyRegistrationUpdate() {
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const payload = {
      msisdn: currentCustomer.msisdn,
      optin: !currentCustomer.accept_loyalty,
      partner_guid: getPartnerGuid(),
    };
    this.props.dispatch(actions.changeLoyaltyRegistrationRequest(payload));
  }

  beneficiaryValid() {
    const regForm = new FormData(document.getElementById('editDialogForm'));
    if (this.beneficiaryVisible()) {
      const beneficiaryMsisdn = regForm.get('beneficiary_msisdn');
      if (beneficiaryMsisdn) {
        if (beneficiaryMsisdn.length !== getLocalNumberLength()) {
          return false;
        }
      }
    }
    return true;
  }

  beneficiaryVisible() {
    return (
      partnerCustomerFieldsContains('beneficiary_name') ||
      partnerCustomerFieldsContains('beneficiary_msisdn')
    );
  }

  beneficiaryDisplay() {
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const labels = this.state.labels;
    const beneficiaryStyle = {
      display: this.beneficiaryVisible() ? 'block' : 'none',
    };
    return (
      <div style={beneficiaryStyle}>
        <div className="form-group">
          <label className="control-label col-md-4 col-sm-4 col-xs-12">
            {labels.beneficiaryFullname}
          </label>
          <div className="col-md-6 col-sm-6 col-xs-12 padding5">
            {currentCustomer.beneficiary_name}
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-md-4 col-sm-4 col-xs-12">
            {labels.beneficiaryPhoneNumber}
          </label>
          <div className="col-md-6 col-sm-6 col-xs-12 padding5">
            {currentCustomer.beneficiary_msisdn}
          </div>
        </div>
      </div>
    );
  }

  beneficiaryDialogDisplay() {
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const labels = this.state.labels;
    const beneficiaryStyle = {
      display: this.beneficiaryVisible() ? 'block' : 'none',
    };
    return (
      <div style={beneficiaryStyle}>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <br />
          <TextField
            fullWidth
            name="beneficiary"
            type="text"
            defaultValue={currentCustomer.beneficiary_name}
            label={labels.beneficiaryFullname} variant="outlined"
          />
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <br />
          <TextField
            fullWidth
            type="text"
            name="beneficiary_msisdn"
            defaultValue={currentCustomer.beneficiary_msisdn}
            label={addInternationalCallingCode(labels.beneficiaryPhoneNumber)} variant="outlined"
          />
        </div>
      </div>
    );
  }

  loyaltyRegistrationDisplay() {
    const loyaltyRegistrationStyle = {
      display: this.loyaltyRegistrationVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    return (
      <div className="form-group" style={loyaltyRegistrationStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.acceptsLoyalty} <span className="required">*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12 padding5">
          <Checkbox required checked={currentCustomer.accept_loyalty} />
        </div>
      </div>
    );
  }

  loyaltyRegistrationButtonDisplay() {
    const loyaltyRegistrationStyle = {
      display: this.loyaltyRegistrationVisible() ? 'block' : 'none',
      backgroundColor: SECONDARY_THEME_COLOR_SHADE_1,
      color: 'white'
    };
    return (
      <Button
        onClick={this.loyaltyRegistrationUpdate.bind(this)}
        style={loyaltyRegistrationStyle}
        variant='contained'
        size='small'
      >
        {this.loyaltyRegistrationText()}
      </Button>
    );
  }

  loyaltyRegistrationVisible() {
    return partnerCustomerFieldsContains('accept_loyalty');
  }

  hasWalletDisplay() {
    const hasWalletStyle = {
      display: this.hasWalletVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    return (
      <div className="form-group" style={hasWalletStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.hasWallet} <span className="required">*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12 padding5">
          <Checkbox required checked={currentCustomer.has_wallet} />
        </div>
      </div>
    );
  }

  hasWalletVisible() {
    return partnerCustomerFieldsContains('has_wallet');
  }

  updateHasWalletInfo(customerData) {
    if (this.hasWalletVisible()) {
      customerData.has_wallet = this.state.hasWallet;
    }
  }

  toggleHasWallet() {
    const hasWallet = this.state.hasWallet;
    this.setState({ hasWallet: !hasWallet });
  }

  hasWalletDialogDisplay() {
    const labels = this.state.labels;
    const hasWalletStyle = {
      display: this.hasWalletVisible() ? 'block' : 'none',
    };
    return (
      <div className="col-md-6 col-sm-6 col-xs-12" style={hasWalletStyle}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                required
                checked={this.state.hasWallet}
                onChange={this.toggleHasWallet.bind(this)}
              />
            }
            label={labels.hasWallet} />
        </FormGroup>
      </div>
    );
  }

  agentDisplay() {
    const agentStyle = {
      display: this.agentVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    return (
      <div className="col-md-6 col-sm-6 col-xs-12" style={agentStyle}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                required
                checked={currentCustomer.agent}
              />
            }
            label={labels.agent} />
        </FormGroup>
      </div>
    );
  }

  agentVisible() {
    return partnerCustomerFieldsContains('agent');
  }

  nationalIdValid() {
    const regForm = new FormData(document.getElementById('editDialogForm'));
    if (this.nationalIdVisible()) {
      const nationalId = regForm.get('national_id');
      if (nationalId) {
        return nationalId.length !== 0;
      }
    }
    return true;
  }

  updateNationalIdInfo(customerData) {
    const regForm = new FormData(document.getElementById('editDialogForm'));
    if (this.nationalIdVisible()) {
      const nationalId = regForm.get('national_id');
      if (nationalId) {
        customerData.national_id = nationalId;
      }
    }
  }

  nationalIdDialogDisplay() {
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const labels = this.state.labels;
    const nationalIdStyle = {
      display: this.nationalIdVisible() ? 'block' : 'none',
    };
    return (
      <div className="col-md-6 col-sm-6 col-xs-6" style={nationalIdStyle}>
        <TextField
          fullWidth
          name="national_id"
          id="national_id"
          type="text"
          defaultValue={currentCustomer.national_id}
          value={this.state.pulled_national_id}
          disabled={this.state.isPartnerPullKyc}
          label={labels.nationalId} variant="outlined"
        />
      </div>
    );
  }

  nationalIdDisplay() {
    const nationalIdStyle = {
      display: this.nationalIdVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    return (
      <div className="form-group" style={nationalIdStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.nationalId} <span className="required">*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12 padding5">
          {currentCustomer.national_id}
        </div>
      </div>
    );
  }

  nationalIdVisible() {
    return partnerCustomerFieldsContains('national_id');
  }

  // TODO: Remove duplication
  firstNameValid() {
    const regForm = new FormData(document.getElementById('editDialogForm'));
    if (this.firstNameVisible()) {
      const firstName = regForm.get('first_name');
      if (firstName) {
        return firstName.length !== 0;
      }
    }
    return true;
  }

  updateFirstNameInfo(customerData) {
    const regForm = new FormData(document.getElementById('editDialogForm'));
    if (this.firstNameVisible()) {
      const firstName = regForm.get('first_name');
      if (firstName) {
        customerData.first_name = firstName;
      }
    }
  }

  firstNameDialogDisplay() {
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const labels = this.state.labels;
    const firstNameStyle = {
      display: this.firstNameVisible() ? 'block' : 'none',
    };
    return (
      <div className="col-md-6 col-sm-6 col-xs-6" style={firstNameStyle}>
        <TextField
          fullWidth
          name="first_name"
          id="first_name"
          type="text"
          defaultValue={currentCustomer.first_name}
          value={this.state.pulled_first_name}
          disabled={this.state.isPartnerPullKyc}
          label={labels.firstName} variant="outlined"
        />
      </div>
    );
  }

  firstNameDisplay() {
    const firstNameStyle = {
      display: this.firstNameVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    return (
      <div className="form-group" style={firstNameStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.firstName} <span className="required">*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12 padding5">
          {currentCustomer.first_name}
        </div>
      </div>
    );
  }

  firstNameVisible() {
    return partnerCustomerFieldsContains('first_name');
  }

  // TODO: Remove duplication
  surnameValid() {
    const regForm = new FormData(document.getElementById('editDialogForm'));
    if (this.surnameVisible()) {
      const surname = regForm.get('surname');
      if (surname) {
        return surname.length !== 0;
      }
    }
    return true;
  }

  updateSurnameInfo(customerData) {
    const regForm = new FormData(document.getElementById('editDialogForm'));
    if (this.surnameVisible()) {
      const surname = regForm.get('surname');
      if (surname) {
        customerData.surname = surname;
      }
    }
  }

  surnameDialogDisplay() {
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const labels = this.state.labels;
    const surnameStyle = {
      display: this.surnameVisible() ? 'block' : 'none',
    };
    return (
      <div className="col-md-6 col-sm-6 col-xs-6" style={surnameStyle}>
        <br />
        <TextField
          fullWidth
          name="surname"
          id="surname"
          type="text"
          defaultValue={currentCustomer.surname}
          value={this.state.pulled_lastname}
          disabled={this.state.isPartnerPullKyc}
          label={labels.surname} variant="outlined"
        />
      </div>
    );
  }

  surnameDisplay() {
    const surnameStyle = {
      display: this.surnameVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    return (
      <div className="form-group" style={surnameStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.surname} <span className="required">*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12 padding5">
          {currentCustomer.surname}
        </div>
      </div>
    );
  }

  surnameVisible() {
    return partnerCustomerFieldsContains('surname');
  }

  fullNameValid() {
    const regForm = new FormData(document.getElementById('editDialogForm'));
    if (this.fullNameVisible()) {
      const fullName = regForm.get('full_name');
      if (fullName) {
        return fullName.length !== 0;
      }
    }
    return true;
  }

  updateFullNameInfo(customerData) {
    const regForm = new FormData(document.getElementById('editDialogForm'));
    if (this.fullNameVisible()) {
      const fullName = regForm.get('full_name');
      if (fullName) {
        customerData.full_name = fullName;
      }
    }
  }

  fullNameDialogDisplay() {
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const labels = this.state.labels;
    const fullNameStyle = {
      display: this.fullNameVisible() ? 'block' : 'none',
    };
    return (
      <div className="col-md-6 col-sm-6 col-xs-6" style={fullNameStyle}>
        <TextField
          fullWidth
          name="full_name"
          id="full_name"
          type="text"
          defaultValue={currentCustomer.full_name}
          value={this.state.pulled_full_name}
          disabled={this.state.isPartnerPullKyc}
          label={labels.fullName} variant="outlined"
        />
      </div>
    );
  }

  fullNameDisplay() {
    const fullNameStyle = {
      display: this.fullNameVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    return (
      <div className="form-group" style={fullNameStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.fullName} <span className="required">*</span>
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12 padding5">
          {currentCustomer.full_name}
        </div>
      </div>
    );
  }

  fullNameVisible() {
    return partnerCustomerFieldsContains('full_name');
  }

  languageValid() {
    if (this.languageVisible()) {
      const language = this.state.language;
      if (language) {
        return language.length !== 0;
      }
    }
    return true;
  }

  updateLanguageInfo(customerData) {
    if (this.languageVisible()) {
      const language = this.state.language;
      if (language) {
        customerData.language = language;
      }
    }
  }

  languageLabel(languageIn) {
    return languageLabel(languageIn);
  }

  selectLanguages(e) {
    console.log(e.target.value);
    this.setState({ language: e.target.value });
  }

  languageDialogDisplay() {
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const labels = this.state.labels;
    const languages = partnerLanguages();
    const languageStyle = {
      display: this.languageVisible() ? 'block' : 'none',
    };
    return (
      <div className="col-md-6 col-sm-6 col-xs-12" style={languageStyle}>
        <br />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label"> {labels.lang}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.language}
            defaultValue={currentCustomer.language}
            label={labels.lang}
            onChange={this.selectLanguages.bind(this)}
          >
            <MenuItem key="-1" value="">{labels.selectLanguage}</MenuItem>
            {languages.map((lang, index) => (
              <MenuItem key={index} value={lang.code}>
                {lang.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }

  languageDisplay() {
    const languageStyle = {
      display: this.languageVisible() ? 'block' : 'none',
    };
    const labels = this.state.labels;
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    return (
      <div className="form-group" style={languageStyle}>
        <label className="control-label col-md-4 col-sm-4 col-xs-12">
          {labels.lang}
        </label>
        <div className="col-md-6 col-sm-6 col-xs-12 padding5">
          {this.languageLabel(currentCustomer.language)}
        </div>
      </div>
    );
  }

  languageVisible() {
    return customerLanguagesVisible();
  }

  render() {
    const labels = this.state.labels;
    if (
      !this.props.currentCustomerData ||
      !this.props.currentCustomerData.currentCustomer
    ) {
      return (
        <div className="col-md-12 col-sm-12 col-xs-12">
          {labels.nothingToDisplay + 'kkkk'}
        </div>
      );
    }
    const currentCustomer = this.props.currentCustomerData.currentCustomer;
    const languages = partnerLanguages();


    return (
      <React.Fragment>
        <div>
          <div className="clearfix">
            {this.dialogDisplayUserErrorFeedback()}
          </div>
          <div className="clearfix">
            {this.dialogDisplayUserSuccessFeedback()}
          </div>

        </div>
        <div className="x_content">
        </div>

        <Dialog
          open={true}
          fullWidth maxWidth='lg'
          onClose={this.cancelEdit.bind(this)}
          disableEnforceFocus
          enforceFocus={false}
          >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.cancelEdit.bind(this)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {labels.dialogTitleCustomer}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <center>
              {' '}
              <CircularProgress
                style={{
                  display: this.props.currentCustomerData.edit_loader
                    ? ''
                    : 'none',
                }}
              />{' '}
            </center>
            <form
              id="editDialogForm"
              className="form-horizontal form-label-left"
              noValidate
            >
              <Grid container style={{ padding: '3%' }} spacing={2}>
                <Grid item xs={12}>
                  {this.dialogDisplayUserErrorFeedback()}
                </Grid>
                <Grid item xs={12} md={6} style={{ display: this.nationalIdVisible() ? 'block' : 'none' }}>
                  <TextField
                    fullWidth
                    name="national_id"
                    id="national_id"
                    type="text"
                    defaultValue={currentCustomer.national_id}
                    value={this.state.pulled_national_id}
                    InputProps={{
                      readOnly: this.state.isPartnerPullKyc
                    }}
                    label={labels.nationalId} variant="outlined"
                    style={{backgroundColor: this.state.isPartnerPullKyc ? '#bbb': 'inherit'}}
                  />
                </Grid>

                <Grid item xs={12} md={6} style={{ display: this.firstNameVisible() ? 'block' : 'none' }}>
                  <TextField
                    fullWidth
                    name="first_name"
                    id="first_name"
                    type="text"
                    defaultValue={currentCustomer.first_name}
                    value={this.state.pulled_first_name}
                    InputProps={{
                      readOnly: this.state.isPartnerPullKyc
                    }}
                    style={{backgroundColor: this.state.isPartnerPullKyc ? '#bbb': 'inherit'}}
                    label={labels.firstName} variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} md={6} style={{ display: this.surnameVisible() ? 'block' : 'none' }}>
                  <TextField
                    fullWidth
                    name="surname"
                    id="surname"
                    type="text"
                    defaultValue={currentCustomer.surname}
                    value={this.state.pulled_lastname}
                    InputProps={{
                      readOnly: this.state.isPartnerPullKyc
                    }}
                    style={{backgroundColor: this.state.isPartnerPullKyc ? '#bbb': 'inherit'}}
                    label={labels.surname} variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} md={6} >
                  <TextField
                    fullWidth
                    InputProps={{
                      readOnly: this.state.isPartnerPullKyc
                    }}
                    style={{backgroundColor: this.state.isPartnerPullKyc ? '#bbb': 'inherit'}}
                    type="text"
                    name="phone"
                    required
                    defaultValue={this.localNumber(currentCustomer.msisdn)}
                    label={addInternationalCallingCode(labels.phoneNumber)} variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} md={6} style={{ display: this.fullNameVisible() ? 'block' : 'none' }}>
                  <TextField
                    fullWidth
                    name="full_name"
                    id="full_name"
                    type="text"
                    defaultValue={currentCustomer.full_name}
                    value={this.state.pulled_full_name}
                    InputProps={{
                      readOnly: this.state.isPartnerPullKyc
                    }}
                    style={{backgroundColor: this.state.isPartnerPullKyc ? '#bbb': 'inherit'}}
                    label={labels.fullName} variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} md={6} >
                  {this.state.isPartnerPullKyc ? (
                    <TextField
                      fullWidth
                      name="birthday"
                      id="birthday"
                      defaultValue={currentCustomer.date_of_birth}
                      required
                      InputProps={{
                        readOnly: this.state.isPartnerPullKyc
                      }}
                      type="text"
                      value={this.state.pulled_birthday}
                      label={labels.dateOfBirth} variant="outlined"
                      style={{backgroundColor: this.state.isPartnerPullKyc ? '#bbb': 'inherit'}}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      name="date sample"
                      id="date sample"
                      type="date"
                      format="YYYY-MM-DD"
                      value={this.state.birthday || currentCustomer.date_of_birth}
                      onChange={this.dateHandler2.bind(this)}
                      label={'date'} variant="outlined"
                    />
                  )}
                </Grid>

                <Grid item xs={12} md={6} style={{ display: this.beneficiaryVisible() ? 'block' : 'none' }}>
                  <TextField
                    fullWidth
                    name="beneficiary"
                    type="text"
                    defaultValue={currentCustomer.beneficiary_name}
                    label={labels.beneficiaryFullname} variant="outlined"
                  />
                </Grid>


                <Grid item xs={12} md={6} style={{ display: this.beneficiaryVisible() ? 'block' : 'none' }}>
                  <TextField
                    fullWidth
                    type="text"
                    name="beneficiary_msisdn"
                    defaultValue={currentCustomer.beneficiary_msisdn}
                    label={addInternationalCallingCode(labels.beneficiaryPhoneNumber)} variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} md={6} style={{ display: this.languageVisible() ? 'block' : 'none' }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"> {labels.lang}</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.language}
                      defaultValue={currentCustomer.language}
                      label={labels.lang}
                      onChange={this.selectLanguages.bind(this)}
                    >
                      <MenuItem key="-1" value="">{labels.selectLanguage}</MenuItem>
                      {languages.map((lang, index) => (
                        <MenuItem key={index} value={lang.code}>
                          {lang.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} style={{ display: this.hasWalletVisible() ? 'block' : 'none' }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          required
                          checked={this.state.hasWallet}
                          onChange={this.toggleHasWallet.bind(this)}
                        />
                      }
                      label={labels.hasWallet} />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={6} style={{ display: this.agentVisible() ? 'block' : 'none' }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          required
                          checked={currentCustomer.agent}
                        />
                      }
                      label={labels.agent} />
                  </FormGroup>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions style={{ padding: '1% 2%' }}>
            <Button variant="contained" onClick={this.pullCustomerKycRequest.bind(this)} style={{ marginRight: 'auto', textAlign: 'left', background: SECONDARY_THEME_COLOR_SHADE_1, display: this.state.isPartnerPullKyc ? '' : 'none', }}> {labels.getKyc}</Button>
            <Button variant="contained" onClick={this.submitUpdateCustomerRequest.bind(this)} style={{ background: PRIMARY_THEME_COLOR_SHADE_1, }}> {labels.save}</Button>
            <Button variant="outlined" onClick={this.cancelEdit.bind(this)} style={{ borderColor: PRIMARY_THEME_COLOR_SHADE_1, color: PRIMARY_THEME_COLOR_SHADE_1, fontWeight: 'bold' }}> {labels.cancel}</Button>

          </DialogActions>
        </Dialog>

        <AlertDialog
          custom
          show={this.props.currentCustomerData.loader}
          size="sm"
          style={{ marginTop: '0', top: '30vh' }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={'cancel'}
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2>updating customer...</h2>
        </AlertDialog>

        <AlertDialog
          success
          show={!!this.props.currentCustomerData.customer_edited_succesfully}
          size="sm"
          title={'Customer details updated'}
          style={{ marginTop: '0', top: '30vh' }}
          onConfirm={() => { this.resetAfterSucesfulyEdit() }}
          confirmBtnText={'ok'}
          showCancel={false}
          cancelBtnText={'cancel'}
          confirmBtnCssClass
        >
        </AlertDialog>


        <AlertDialog
          show={!!this.props.currentCustomerData.customer_edited_failed}
          danger
          title={'Error updating customer details'}
          onConfirm={() => { this.resetAfterFailedEdit() }}
          confirmBtnText={'Try again'}
          confirmBtnCssClass
          showCancel={false}
          style={{ marginTop: '0', top: '30vh' }}
        >
          {this.props.currentCustomerData.errors ? this.props.currentCustomerData.errors.message : ''}
        </AlertDialog>

        {/*  */}
      </React.Fragment>
    );
  }
}

export default EditCustomer;
