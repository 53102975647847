import React from 'react'
import { useStyle } from "./Style";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';

const PaginationResults = (props) => {
  const classes = useStyle();
  const handleChange = (event, value) => {
    props.getResultsByPage(value)
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} sm={12}>
          <div className={classes.filterWrapper}>
            <div className={classes.filterHeader}>
              <span>View  search results by page:</span>
            </div>
            <div className={classes.filterOption}>
              <Stack spacing={2}>
                <Pagination count={props.total_pages} page={props.page} onChange={handleChange} />
              </Stack>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default PaginationResults