import * as types from '../../lib/actionTypes.es6';

export function updateGlobalLanguage(language) {
  return {
    type: types.UPDATE_LANGUAGE,
    payload: language,
  };
}

export function setCurrentPartner(partner) {
  return {
    type: 'SET_CURRENT_PARTNER',
    payload: partner,
  };
}

export function showCustomerBar() {
  return {
    type: 'SHOW_CUSTOMER_BAR',
  };
}

export function hideCustomerBar() {
  return {
    type: 'HIDE_CUSTOMER_BAR',
  };
}

export function customerBarShowUser() {
  return {
    type: 'CUSTOMER_BAR_SHOW_USER',
  };
}

export function customerBarShowSearch() {
  return {
    type: 'CUSTOMER_BAR_SHOW_SEARCH',
  };
}
