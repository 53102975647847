import React from 'react'
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useStyle } from "./Style";


import ClaimDetails from './ClaimDetails';
import ClaimSummary from './ClaimSummary';
import ClaimResults from './ClaimResults';

const ClaimInitiationMobileWrapper = (props) => {

  const [newClaim, setNewClaim] = React.useState({})
  const [showError, setShowError] = React.useState(false)
  React.useEffect(() => {
    if (props.products.length > 0) {
      props.getPolicies()
    }

  

  }, [props.products])
  console.log("claimTypes", props.claimTypes)
  const steps = [
    {
      label: 'Enter Claims Details',
      Component: <ClaimDetails setShowError={setShowError} setNewClaim={setNewClaim} newClaim={newClaim} relationToMainMember={props.relationToMainMember} claimTypes={props.claimTypes} setAdditionalState={props.setAdditionalState} />,
    },
    {
      label: 'Summary',
      Component: <ClaimSummary claimInitiationPayload={props.claimInitiationPayload} />,
    },
    {
      label: 'Results',
      Component: <ClaimResults handleInitiateClaim={props.handleInitiateClaim} displayUserFeedback={props.displayUserFeedback} claimInitiationPayload={props.claimInitiationPayload} displayOutcome={props.displayOutcome} displayNotes={props.displayNotes} />,
    },
  ];

  const classes = useStyle();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const hasAllFields = () => {
    console.log('newClaim: ', newClaim);
    
    if (!newClaim.type) {
      return false
    }

    if(!newClaim.relation_to_main_member){
      return false
    }

    if (newClaim.type === 'Hospital') {
      const { hospital_admission_date, hospital_discharge_date } = newClaim;
      return !!(hospital_admission_date && hospital_discharge_date);
    }

    if (newClaim.type === 'Personal_Accident') {
      const { event_date } = newClaim;
      return !!(event_date);
    }

    if (newClaim.type === 'Death') {
      const { death_date } = newClaim;
      return !!(death_date);
    }

    if (newClaim.type === 'Property') {
      const { event_date } = newClaim;
      return !!(event_date);
    }
    

    return true
  }
  const handleNext = () => {
    if (hasAllFields()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setShowError(true)
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>

      <Box sx={{ maxWidth: 400, flexGrow: 1, marginTop: "20px" }}>
        <Paper
          square
          elevation={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: 50,
            pl: 2,
            bgcolor: 'background.default',
            borderRadius: "8px"
          }}
          className={classes.claimHeading}
        >
          <h6>{steps[activeStep].label}</h6>
        </Paper>


        <Paper
          square
          elevation={0}
          sx={{
            // display: 'flex',
            alignItems: 'center',
            // height: 150,
            padding: "20px 10px 15px 20px ",
            bgcolor: 'background.default',
            marginTop: "10px"
          }}
        >
          {showError && <p style={{ color: 'red' }}>Please complete all fields</p>}
          {steps[activeStep].Component}
        </Paper>
        <MobileStepper
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          sx={{
            marginTop: "30px",
            position: 'fixed',
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: 1000,
            transform: 'translateZ(0px)',
            flexGrow: 1
          }}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </Box>
    </>
  )
}

export default ClaimInitiationMobileWrapper