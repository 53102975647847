import * as types from '../../lib/actionTypes.es6';

export function loyaltyRenewalRequest(data) {
  return {
    type: types.LOYALTY_RENEWAL_REQUEST,
    payload: data,
  };
}

export function loyaltyRenewalSuccess(data) {
  return {
    type: types.LOYALTY_RENEWAL_SUCCESS,
    payload: data,
  };
}

export function loyaltyRenewalFailure(response) {
  return {
    type: types.LOYALTY_RENEWAL_FAILURE,
    payload: response,
  };
}
