import React from 'react'
import { Box, Grid } from '@mui/material';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { useStyle } from "./Style";
import SkeletonWrapper from '../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import { getPartner } from '../../../../../lib/access.es6';
import { formatDateLocale } from '../../../../../lib/utils.es6';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import PoliciesDependants from './Components/PoliciesDependants/PoliciesDependants';

const ITEM_HEIGHT = 48;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    width: "100%",
    maxWidth: "1000px"
  }
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#fff',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Quotes = (props) => {
  const classes = useStyle();

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedQuote, setSelectedQuote] = React.useState(null)
  const [selectedQuoteAction, setSelectedQuoteAction] = React.useState(null)

  const openQuoteAction = Boolean(anchorEl);

  const handleOpenMenu = (event, quote) => {
    setAnchorEl(event.currentTarget);
    setSelectedQuoteAction(quote);
  };

  const handleClickOpen = (quote) => {    
    setSelectedQuote(quote);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };


  const currencyCode = getPartner().country.currency_code

  if (!props.quotes) {
    return <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
        <Grid container spacing={2} className={classes.claimdetailswrapper}>
          <Grid item xs={12} sm={12} md={12} className={classes.ClaimDetailscontent}>
            <p style={{ textAlign: 'center' }}>No details found</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }

  if (props.loader) {
    return <SkeletonWrapper loading={props.loader} />
  }

  return (
    <>
      <>
        <Grid container className={classes.policyWrapper} >
          <Grid item xs={12} sm={12} md={12}>
            <Grid container className={classes.policyHeader}>
              <Grid item xs={12} sm={6} md={6}>
                <Grid container>
                  <Grid item xs={12} md={2} sm={2} className={classes.policyIconWrapper}   >
                    <Box className={classes.policyIcons}>
                      <NoteAddOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={10} sm={10} className={classes.policyHeaderContent}>
                    <h6>{props.quotes.quotation_number || "N/A"}</h6>
                    <p> Quotation Number</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6} >

                <Grid container>
                  <Grid item xs={12} md={6} sm={6}>
                    <div >

                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} className={classes.policyActiveStatusWrapper}>
                    <Box display="flex" justifyContent="flex-end">
                      <ul>
                        <li className={props.quotes.active === true ? classes.activePill : classes.inactivePill}> <span>{props.quotes.active === true ? `Active` : `Inactive`}</span></li>
                        <li className={classes.actionbtnpolicy}>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={openQuoteAction ? 'long-menu' : undefined}
                            aria-expanded={openQuoteAction ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={(event) => handleOpenMenu(event, props.quotes)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          {
                            selectedQuoteAction && selectedQuoteAction.guid === props.quotes.guid && <Menu
                              id="long-menu"
                              MenuListProps={{
                                'aria-labelledby': 'long-button',
                              }}
                              anchorEl={anchorEl}
                              open={openQuoteAction}
                              onClose={handleClose}
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                  width: '20ch',
                                },
                              }}
                            >
                              <MenuItem style={{
                                cursor: "pointer", fontFamily: "Open Sans",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "15px",
                                color: "#000000",
                              }}
                                onClick={() => { setAnchorEl(null); handleClickOpen(props.quotes) }}
                              >View Dependants</MenuItem>

                            </Menu>
                          }

                        </li>
                      </ul>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <div className={classes.policyHeaderBorder}></div>
            </Grid>

            <Grid container spacing={2} className={classes.policyContentWrapper}>
              <Grid item xs={12} sm={3} md={3} >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3} md={3}></Grid>
                  <Grid item xs={12} sm={9} md={9}>
                    <p>Start Date </p>
                    <h6> {formatDateLocale(props.quotes.start_date) || "N/A"}</h6>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <p> End Date</p>
                <h6>{formatDateLocale(props.quotes.end_date) || "N/A"}
                </h6>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <p>Lapse Period</p>
                <h6>{props.quotes.lapse_period * props.quotes.premium_cardinality + " " + props.quotes.premium_granularity || "N/A"}</h6>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <p>Created At</p>
                <h6>{
                  formatDateLocale(props.quotes.created_at) || "N/A"
                }</h6>
              </Grid>

            </Grid>
            <div className={classes.policyContentBorder}></div>
            <Grid container spacing={2} className={classes.policyFooterWrapper}>
              <Grid item xs={12} md={6} sm={6}>
                <Grid container spacing={2} >


                  <Grid item xs={12} md={6} sm={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3} md={3}></Grid>
                      <Grid item xs={12} sm={9} md={9}>
                        <p>status</p>
                        <h6>{props.quotes.status || "N/A"}</h6>

                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <p> Branch</p>
                    <h6>{props.quotes.branch || "N/A"}</h6>
                  </Grid>

                </Grid>
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <div className={classes.policyBalance}>
                  <p>Premiums</p>
                  <h6>{`${currencyCode} ${props.quotes.total_premium_amount_in_cents / 100} per ${props.quotes.premium_cardinality} ${props.quotes.premium_granularity}` || "0.00"}   </h6>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/*dependants dialog box */}
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="dependants-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle className={classes.dependantstitle} id="dependants-dialog-title" onClose={handleClose}>
            Dependants
          </BootstrapDialogTitle>
          <BootstrapDialogTitle id="dependants-dialog-title" onClose={handleClose}>
          </BootstrapDialogTitle>
          <DialogContent >
            <PoliciesDependants quote={selectedQuote} />
          </DialogContent>
        </BootstrapDialog>

      </>

    </>
  )
}

export default Quotes