import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProductsHeader from "../../../NewProductList/components/ProductsHeader/ProductsHeader";
import { Chip, CircularProgress } from "@mui/material";

export default function ViewPartnerConfig(props) {
  if (props.isLoading) {
    return <div style={{ textAlign: 'center' }}>
      <CircularProgress />
    </div>
  }

  if (!props.partnerConfigClaim) {
    return "";
  }


  const [expandedPartnerClaimConfigs, setExpandedPartnerClaimConfigs] = React.useState(false)

  const partnerClaimConfiguration = props.partnerConfigClaim

  return (
    <div style={{ marginTop: "3%" }}>
      <>
        {/* Partner Claim Configurations*/}
        <Accordion expanded={expandedPartnerClaimConfigs}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon onClick={()=>{setExpandedPartnerClaimConfigs(!expandedPartnerClaimConfigs)}}/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <ProductsHeader
              title={"Partner Claim Configurations"}
              showEditButton={true}
              onEditButtonClick={props.openUpdatePartnerConfigClaimDialog}
            />
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper} style={{ marginTop: "2%" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table-cell-header">
                      Property
                    </TableCell>
                    <TableCell align="left" className="table-cell-header">
                      Value
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>level 1 email list</strong>
                    </TableCell>
                    <TableCell align="left">{
                      props.formatAndConvertToListString(partnerClaimConfiguration.level_1_email_list).map(field => <Chip label={field} style={{ marginRight: '1%' }} />)
                    }</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>level 2 email list</strong>
                    </TableCell>
                    <TableCell align="left">{
                      props.formatAndConvertToListString(partnerClaimConfiguration.level_2_email_list).map(field => <Chip label={field} style={{ marginRight: '1%' }} />)
                    }</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>finance email list</strong>
                    </TableCell>
                    <TableCell align="left">{
                      props.formatAndConvertToListString(partnerClaimConfiguration.finance_email_list).map(field => <Chip label={field} style={{ marginRight: '1%' }} />)
                    }</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>closed email list</strong>
                    </TableCell>
                    <TableCell align="left">{
                      props.formatAndConvertToListString(partnerClaimConfiguration.closed_email_list).map(field => <Chip label={field} style={{ marginRight: '1%' }} />)
                    }</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>submission email list</strong>
                    </TableCell>
                    <TableCell align="left">{
                      props.formatAndConvertToListString(partnerClaimConfiguration.submission_email_list).map(field => <Chip label={field} style={{ marginRight: '1%' }} />)
                    }</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>reinsurer referral limit loyalty</strong>
                    </TableCell>
                    <TableCell align="left">{partnerClaimConfiguration.reinsurer_referral_limit_loyalty}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>reinsurer referral limit upsell</strong>
                    </TableCell>
                    <TableCell align="left">{partnerClaimConfiguration.reinsurer_referral_limit_upsell}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>reinsurer referral email</strong>
                    </TableCell>
                    <TableCell align="left">{partnerClaimConfiguration.reinsurer_referral_email}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>check reinsurer limit</strong>
                    </TableCell>
                    <TableCell align="left">{partnerClaimConfiguration.check_reinsurer_limit}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>claim payout cool off in minutes</strong>
                    </TableCell>
                    <TableCell align="left">{partnerClaimConfiguration.claim_payout_cool_off_in_minutes}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>claim types</strong>
                    </TableCell>
                    <TableCell align="left">{
                      props.formatAndConvertToListString(partnerClaimConfiguration.claim_types).map(field => <Chip label={field} style={{ marginRight: '1%' }} />)
                    }</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>customer high risk flagging enabled</strong>
                    </TableCell>
                    <TableCell align="left">{partnerClaimConfiguration.customer_high_risk_flagging_enabled ? 'true' : 'false'}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>customer high risk claim paid count</strong>
                    </TableCell>
                    <TableCell align="left">{partnerClaimConfiguration.customer_high_risk_claim_paid_count}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>customer high risk past days count</strong>
                    </TableCell>
                    <TableCell align="left">{partnerClaimConfiguration.customer_high_risk_past_days_count}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>hospital high risk flagging enabled</strong>
                    </TableCell>
                    <TableCell align="left">{partnerClaimConfiguration.hospital_high_risk_flagging_enabled ? 'true' : 'false'}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>hospital high risk claim paid count</strong>
                    </TableCell>
                    <TableCell align="left">{partnerClaimConfiguration.hospital_high_risk_claim_paid_count}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>hospital high risk past days count</strong>
                    </TableCell>
                    <TableCell align="left">{partnerClaimConfiguration.hospital_high_risk_past_days_count}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>customer high risk flagging from hospital days enabled</strong>
                    </TableCell>
                    <TableCell align="left">{partnerClaimConfiguration.customer_high_risk_flagging_from_hospital_days_enabled ? 'true' : 'false'}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>customer high risk previous hospital days count</strong>
                    </TableCell>
                    <TableCell align="left">{partnerClaimConfiguration.customer_high_risk_previous_hospital_days_count}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>customer high risk from hospital days past days count</strong>
                    </TableCell>
                    <TableCell align="left">{partnerClaimConfiguration.customer_high_risk_from_hospital_days_past_days_count}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>maximum allowed days to report claim</strong>
                    </TableCell>
                    <TableCell align="left">{partnerClaimConfiguration.maximum_allowed_days_to_report_claim}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <strong>claim assessment mode</strong>
                    </TableCell>
                    <TableCell align="left">{partnerClaimConfiguration.claim_assessment_mode}</TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
        <br />
        <br />
      </>

    </div>
  );
}
