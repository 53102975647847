import { take, call, put } from 'redux-saga/effects';
import * as types from '../../lib/actionTypes.es6';
import {
  getProducts,
  createProduct,
  getProduct,
  getProductEnums,
} from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';
import { createaddOnsConfigRequest } from '../NewProductList/actions.es6';

export function* getAllProductsRequestWatcher() {
  for (;;) {
    const request = yield take(types.VIEW_ALL_PRODUCTS_REQUEST);
    try {
      const response = yield call(getProducts, request.payload);
      yield put(actions.getAllProductsSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getAllProductsFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* createProductRequestWatcher() {
  for (;;) {
    const request = yield take(types.CREATE_PRODUCT_REQUEST);
    try {
      const response = yield call(createProduct, request.payload);
      yield put(actions.createProductSuccess(response.data));
      yield put(createaddOnsConfigRequest(response.data));

      yield put(
        actions.getAllProductsRequest({
          partnerGuid: request.payload.partnerGuid,
        })
      );
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.createProductFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* getProductRequestWatcher() {
  for (;;) {
    const request = yield take(types.VIEW_PRODUCT_REQUEST);
    try {
      const response = yield call(getProduct, request.payload);
      yield put(actions.getProductSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getProductFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* getProductEnumRequestWatcher() {
  while (yield take(types.PRODUCT_ENUMS_REQUEST)) {
    try {
      const response = yield call(getProductEnums);
      yield put(actions.getProductEnumsSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getProductEnumsFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
