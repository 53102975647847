import React from 'react'
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import Container from "@material-ui/core/Container";
import ProductsMobile from './ProductsMobile';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// core version + navigation, pagination modules:
import { Pagination } from 'swiper';
// import Swiper and modules styles

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'

import Life from '../../../../../Assets/life.png'
import Property from '../../../../../Assets/property.png'
import personalaccident from '../../../../../Assets/personal-accident.png'
import hospitalcash from '../../../../../Assets/hospital-cash.png'
import medicalcover from '../../../../../Assets/medical-cover.png'
import msmes from '../../../../../Assets/msmes.png'
import device from '../../../../../Assets/device-cover.png'
import lossofincome from '../../../../../Assets/lossofincome.png'
import homecontents from '../../../../../Assets/homecontents.png'

//media query and breakpoints and MU theme imports
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
const Products = () => {
    const classes = useStyle();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    return (

        <section className={classes.products} id="products">
            <Container>
                <Grid container>
                    <Grid item xs={12} md={12} sm={12}>
                        <h2>Our Expertise Spans a Range of Cover Types</h2>
                        <div className={classes.divider}></div>
                    </Grid>
                </Grid>
            </Container>
            <Container>
                {
                    isMobile ? (<ProductsMobile />) : (<>
                        <div className={classes.productswrapper}>
                            <Grid container>
                                <Swiper
                                    spaceBetween={30}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    className={classes.mySwiperproducts}
                                >
                                    <SwiperSlide>
                                        <Grid item xs={12} sm={4} md={4} className={classes.individualproduct}>
                                            <img src={personalaccident} alt="personal accident" />
                                            <h4>Personal Accident</h4>
                                            <p>Benefit paid  in the event of injuries, disability or death caused  accidentally</p>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} className={classes.individualproduct}>
                                            <img src={Life} alt="Life" />
                                            <h4>Life</h4>
                                            <p>Simple life products covering expenses such as education, funerals and loss of life</p>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} className={classes.individualproduct}>
                                            <img src={Property} alt="Property" />
                                            <h4>Property Assistance Cover</h4>
                                            <p>Benefit paid in event of business assets destruction by fire, storm/flood or Impact by external forces</p>
                                        </Grid>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Grid item xs={12} sm={4} md={4} className={classes.individualproduct}>
                                            <img src={hospitalcash} alt="Hospital cash" />
                                            <h4>Hospital Cash</h4>
                                            <p>Benefit paid per night of hospitalisation​</p>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} className={classes.individualproduct}>
                                            <img src={medicalcover} alt="Medical Cover" />
                                            <h4>Medical Cover</h4>
                                            <p>Benefit paid for inpatient hospital expenses</p>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} className={classes.individualproduct}>
                                            <img src={msmes} alt="MSME cover" />
                                            <h4>MSME cover</h4>
                                            <p>Benefit paid for various risks faced by MSMEs</p>
                                        </Grid>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Grid item xs={12} sm={4} md={4} className={classes.individualproduct}>
                                            <img src={device} alt="Device Cover" />
                                            <h4>Device cover</h4>
                                            <p>Benefit paid in the event of theft or damage of phone, tablet, laptop, etc</p>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} className={classes.individualproduct}>
                                            <img src={lossofincome} alt="Loss of income cover" />
                                            <h4>Loss of Income Cover</h4>
                                            <p>Benefit paid in the event operations are interrupted by fire, storm/flood or  impact by external forces</p>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} className={classes.individualproduct}>
                                            <img src={homecontents} alt="Home contents cover" />
                                            <h4>Home contents cover</h4>
                                            <p>Benefit paid in the event of loss or damage of home possessions.</p>
                                        </Grid>
                                    </SwiperSlide>
                                </Swiper>

                            </Grid>
                        </div>
                    </>)
                }

            </Container>
        </section>
    )
}

export default Products