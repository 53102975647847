import * as types from '../../../../../../lib/actionTypes.es6';

export const initialState = {


  get_claims_errors: null,
  get_claims_loader: false,
  get_claims_success_message: '',
  claims: [],


  
};

export default function orangeClaimsReducer(state = initialState, action) {
  switch (action.type) {


    case types.GET_ALL_ORANGE_CUSTOMER_CLAIMS_REQUEST:
      return { ...state, get_claims_loader: true, get_claims_errors: null };
    case types.GET_ALL_ORANGE_CUSTOMER_CLAIMS_SUCCESS:
      return { ...state, get_claims_loader: false, get_claims_errors: null, claims: addClaimGuid(action.payload) };
    case types.GET_ALL_ORANGE_CUSTOMER_CLAIMS_FAILURE:
      return { ...state, get_claims_loader: false, get_claims_errors: action.payload };


    default:
      return state;
  }
}


function addClaimGuid(claims){
  return claims.map(claim => {
    return {...claim, guid: claim.reference }
  })
}




