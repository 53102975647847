import {call, put, take} from "redux-saga/effects";
import * as types from "../../lib/actionTypes.es6";
import {
  addCustomerBeneficiary,
  deleteCustomerbeneficiary,
  getCustomerBeneficiary,
  updateCustomerBeneficiary
} from "../../lib/requests.es6";
import * as actions from "./actions.js";
import {unAuthorizedNotification} from "../../lib/access.es6";
import {GAloggerException} from "../../lib/monitoring.es6";

export function* getCustomeBeneficiaryRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.GET_CUSTOMER_BENEFICIARY_REQUEST);
      const response = yield call(getCustomerBeneficiary, payload);
      yield put(actions.getCustomerBeneficiarySuccess(response.data));
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.getCustomerBeneficiaryFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

// export function* getCustomerBeneficiaryRequestWatcher() {
//   while (true) {
//     try {
//       const {payload} = yield take(types.GET_CUSTOMER_BENEFICIARY_REQUEST);

//       const response = yield call(getCustomerDependant, payload);
//       yield put(actions.getCustomerDependantSuccess(response.data));
//     } catch (e) {
//       let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
//       if (e.response) {
//         errorPayload = e.response.data;
//         unAuthorizedNotification(e);
//       }
//       yield put(actions.getCustomerDependantFailed(errorPayload));
//       GAloggerException(e);
//     }
//   }
// }

export function* addCustomerBeneficiaryRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.ADD_CUSTOMER_BENEFICIARY_REQUEST);

      const response = yield call(addCustomerBeneficiary, payload);
      yield put(actions.addCustomerBeneficiarySuccess(response.data));
      yield put(actions.getCustomerBeneficiaryRequest(payload.customer_guid));
      
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.addCustomerBeneficiaryFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* updateCustomerBeneficiaryRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.UPDATE_CUSTOMER_BENEFICIARY_REQUEST);

      const response = yield call(updateCustomerBeneficiary, payload);
      yield put(actions.editCustomerBeneficiarySuccess(response.data));
      yield put(actions.getCustomerBeneficiaryRequest(payload.customer_guid));
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.editCustomerBeneficiaryFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* deleteCustomerBeneficiaryRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.DELETE_CUSTOMER_BENEFICIARY_REQUEST);

      const response = yield call(deleteCustomerbeneficiary, payload);
      yield put(actions.deleteCustomerBeneficiarySuccess(response.data));
      yield put(actions.getCustomerBeneficiaryRequest(payload.customer_guid));
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.deleteCustomerBeneficiaryFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

