import { store } from '../store.es6';
// TODO: This might be an anti pattern. Needed to update gloabl language
import { updateGlobalLanguage } from '../containers/Main/actions.es6';

export function availableLanguages() {
  return [
    {
      value: 'en',
      display: 'English',
      country: 'UK'
    },
    {
      value: 'fr',
      display: 'Francais',
      country: 'French'
    },
  ];
}

export function updateCurrentLanguage(language, pageName, labels) {
  let resetLanguage = false;
  if (!language) {
    resetLanguage = true;
  }
  if (resetLanguage || labels.getLanguage() !== language) {
    labels.setLanguage(language);
    store.dispatch(updateGlobalLanguage(language));
  }
}

// TODO: In principles, there should be no need to pass labels through
export function defaultSelection(labels) {
  return labels.defaultSelection;
}

// TODO: In principles, there should be no need to pass labels through
export function translateArray(labels, array) {
  const defaultEn = 'Please select';
  const newArray = array.slice(0); // Passing '0' is a trick to make it faster
  const index = newArray.indexOf(defaultEn);
  if (index !== -1) {
    newArray[index] = defaultSelection(labels);
  }
  return newArray;
}
