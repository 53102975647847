import React, { useState } from 'react'
import ModalInc from '../../../../shared-ui/ModalInc'
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { CircularProgress, Grid } from '@mui/material';
import { useStyle } from "./Style";
import ButtonInc from '../../../../shared-ui/ButtonInc';

import MenuItem from '@mui/material/MenuItem';
import AlertDialog from '../../../../components/AlertDialog/index.es6';
import { reloadPage } from '../../../../lib/utils.es6';
import { getPartnerGuid, getUserFullname } from '../../../../lib/access.es6';
import ProgressState from './ProgressState';
import DropzoneS3Uploader from "react-dropzone-s3-uploader";
import { getSignedUrlForAnyBucket } from '../../../../lib/requests.es6';
import { AppConfigs } from '../../../../lib/configs';



const AddGuide = (props) => {
  const classes = useStyle();

  const [title, setTitle] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [url, setUrl] = useState('');
  const [hideErrorAlert, setHideErrorAlert] = useState(true);
  const [showUploadPdf, setShowUploadPdf] = useState(false)


 // const [docType, setDocType] = useState('');
 // const [docTypeError, setDocTypeError] = useState(false)
  const [s3Url] = useState('https://puplic-assets.s3.amazonaws.com');
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null)




  const saveTemplate = () => {
    const payload = {
      "title": title,
      "type": type,
      "description": description,
      "url": url,
      "created_by": getUserFullname(),
      "updated_by": getUserFullname(),
      "partner": getPartnerGuid(),
      "status": "UP_TO_DATE"
    }

    console.log('payload', payload);
    props.saveGuide(payload)
  };

  const handleRefresh = () => {
    reloadPage()
  }

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "title") {
      setTitle(value);
   
    }
    if (name === "description") {
      setDescription(value)
    }
    if (name === "type") {
      setType(value)
      if (value === "PDF") {
        setShowUploadPdf(true)
      } else {
        setShowUploadPdf(false)
      }

    }
    if (name === "url") {
      setUrl(value)
    }
  }

 
  const handleFinishedUpload = (info) => {
     setUrl(info.fileUrl.replace('undefined', info.bucketKey) )
  }

  const getS3Ur = (file, callback) =>
    getSignedUrlForAnyBucket(file, callback, AppConfigs.REACT_APP_AWS_KNOWLEDGE_BASE_S3_BUCKET_NAME);

  const uploadOptions = {
    getSignedUrl: getS3Ur,
    s3Url: s3Url,
    uploadRequestHeaders: {},
    onProgress: (progress) => setProgress(progress),
    onError: (errorMessage) => setErrorMessage(errorMessage),

  };




  return (
    <>
      <AlertDialog
        custom
        show={props.showAddGuideProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Adding guide</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showAddGuideSuccessAlert}
        size="sm"
        title={'guide added'}
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
        <div >
          <ButtonInc variant="contained" onClick={handleRefresh} style={{ background: 'green', }}>OK</ButtonInc>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showAddGuideErrorAlert && !hideErrorAlert}
        danger
        title={'Error adding guide'}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        showConfirm={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.AddGuideError ? props.AddGuideError.message : ''}
        <div >
          <ButtonInc variant="contained" onClick={() => { setHideErrorAlert(false) }} style={{ background: 'green', }}>OK</ButtonInc>
        </div>
      </AlertDialog>
      <ModalInc
        modalTitle="Lets make a new guide"
        subTitle="Fill the below fields to create a guide"
        open={props.open}
        onClose={props.close}
        fullWidth
        maxWidth="md"
      >

        <ValidatorForm onSubmit={() => { }} >
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <TextValidator
                  name='title'
                  id="title"
                  label="Title"
                  value={title}
                  onChange={handleChange}
                  fullWidth
                  className={classes.paymentinputs}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please enter the title']}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextValidator
                  name='description'
                  id="description"
                  label="short description"
                  value={description}
                  onChange={handleChange}
                  fullWidth
                  className={classes.paymentinputs}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please enter a short description']}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <SelectValidator
                  label="Type"
                  name='type'
                  value={type}
                  onChange={handleChange}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please select  type']}
                  style={{ marginBottom: "10px", width: "100%" }}

                >
                  {['SCRIBE', 'VIDEO', 'PDF'].map((type) => (
                    <MenuItem sx={{
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: 300,
                      fontSize: "12px",
                      lineHeight: "16px",
                      letterSpacing: "0.005em",
                      color: "#000000",
                    }} value={type}>{type}</MenuItem>
                  ))}
                </SelectValidator>
              </Grid>

              {
                showUploadPdf ? (<>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className={classes.uploadsection}>
                      {/* {docTypeError && <p style={{ color: 'red' }}>Please Choose document type</p>} */}
                      <DropzoneS3Uploader
                        onFinish={handleFinishedUpload}
                        upload={uploadOptions}
                        s3Url={s3Url}
                        passChildrenProps={false}
                        maxSize={1024 * 1024 * 5}
                        className={classes.uploadzone}
                      //  {...(!setDocType && { onDrop: () => setDocTypeError('please add document type before uploading') })}
                        style={{
                          width: "100%",
                          height: "200px",
                          background: "#FFFFFF",
                          border: "1px dashed #B8B8B8",
                          padding: " 55px 0"
                        }}
                      >
                        <div >
                          <ProgressState progress={progress} errorMessage={errorMessage} />

                        </div>
                      </DropzoneS3Uploader>
                    </div>
                  </Grid>

                </>) : (<>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextValidator
                      name='url'
                      id="url"
                      label="Resource Url"
                      value={url}
                      onChange={handleChange}
                      fullWidth
                      className={classes.paymentinputs}
                      variant="outlined"
                      validators={['required']}
                      errorMessages={['Please enter resource URL']}
                    />
                  </Grid>
                </>)
              }







            </Grid>

          </Grid>

          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <ButtonInc
                variant="contained"
                color="primary"
                size="large"
                onClick={props.close}
                style={{
                  width: '221px',
                  height: '55px',
                  background: '#9e9e9e',
                  marginTop: '10%',
                  border: '1px solid #FBA92D',
                }}
              >
                Cancel
              </ButtonInc>
            </Grid>
            <Grid item>
              <ButtonInc
                size="large"
                onClick={saveTemplate}
                variant="contained"
                style={{ width: '221px', height: '55px', marginTop: '10%' }}
              >
                Save Guide
              </ButtonInc>
            </Grid>

          </Grid>
        </ValidatorForm>
      </ModalInc>

    </>
  )
}

export default AddGuide
