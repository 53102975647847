export const agentsData = [
  {
    "id": "cddc8a07-935e-4f0b-9726-ab95814f37d8",
    "createdTimestamp": 1702642888738,
    "username": "agent2tester",
    "enabled": true,
    "totp": false,
    "emailVerified": false,
    "firstName": "agent 2 ",
    "lastName": "test ",
    "email": "agent2@test.com",
    "attributes": {
      "isAgent": [
        "true"
      ],
      "partners": [
        "accessbank"
      ]
    },
    "disableableCredentialTypes": [],
    "requiredActions": [],
    "notBefore": 0,
    "access": {
      "manageGroupMembership": true,
      "view": true,
      "mapRoles": true,
      "impersonate": false,
      "manage": true
    }
  },
  {
    "id": "69db6422-9d02-428f-b047-9f579cba926d",
    "createdTimestamp": 1702889689664,
    "username": "agent4",
    "enabled": true,
    "totp": false,
    "emailVerified": false,
    "firstName": "agent4",
    "lastName": "mat",
    "email": "agent4@gmail.com",
    "attributes": {
      "isAgent": [
        "true"
      ],
      "partners": [
        "accessbank"
      ],
      "branch": [
        "Head Office (CAPITAL BANK LTD)"
      ]
    },
    "disableableCredentialTypes": [],
    "requiredActions": [],
    "notBefore": 0,
    "access": {
      "manageGroupMembership": true,
      "view": true,
      "mapRoles": true,
      "impersonate": false,
      "manage": true
    }
  },
  {
    "id": "cde42036-3da7-4ead-82b6-313651061a39",
    "createdTimestamp": 1702888046171,
    "username": "agentmateli",
    "enabled": true,
    "totp": false,
    "emailVerified": false,
    "firstName": "agent",
    "lastName": "Mateli",
    "email": "agentmateli@gmail.com",
    "attributes": {
      "isAgent": [
        "true"
      ],
      "partners": [
        "accessbank"
      ]
    },
    "disableableCredentialTypes": [],
    "requiredActions": [],
    "notBefore": 0,
    "access": {
      "manageGroupMembership": true,
      "view": true,
      "mapRoles": true,
      "impersonate": false,
      "manage": true
    }
  },
  {
    "id": "2ec7434a-eb7c-4915-9892-a886abdeaa61",
    "createdTimestamp": 1702633894030,
    "username": "agenttest",
    "enabled": true,
    "totp": false,
    "emailVerified": false,
    "firstName": "test ",
    "lastName": "agent",
    "email": "agent@gmail.com",
    "attributes": {
      "isAgent": [
        "true"
      ],
      "partners": [
        "accessbank"
      ]
    },
    "disableableCredentialTypes": [],
    "requiredActions": [],
    "notBefore": 0,
    "access": {
      "manageGroupMembership": true,
      "view": true,
      "mapRoles": true,
      "impersonate": false,
      "manage": true
    }
  }
]