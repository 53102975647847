import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../../lib/actionTypes.es6'
import * as actions from './actions';

import { addBulkPayments, saveBulkPayments, getBulkPaymentsBatches, getFailedBulkPaymentsBatches } from '../../../../lib/requests.es6';
import { GAloggerException } from '../../../../lib/monitoring.es6';
import { unAuthorizedNotification } from '../../../../lib/access.es6';




export function* addBulkPaymentsRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.ADD_BULK_PAYMENTS_REQUEST);

      const response = yield call(addBulkPayments, payload);
      yield put(actions.addBulkPaymentsSuccess(response.data));
    } catch (e) {
      let errorPayload = {
        message: 'An error occurred, this could be due to invalid data',
      };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.addBulkPaymentsFailed(errorPayload));
      GAloggerException(e);
    }
  }
}




export function* saveBulkPaymentsRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.SAVE_BULK_PAYMENTS_REQUEST);

      const response = yield call(saveBulkPayments, payload);
      console.log(response)
      yield put(actions.saveBulkPaymentsSuccess(response.data));
      // yield put(actions.getTemplateDocumentsRequest());
    } catch (e) {
      console.log(e)
      let errorPayload = {
        message: 'An error occurred, this could be due to invalid data',
      };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.saveBulkPaymentsFailed(errorPayload));
      GAloggerException(e);
    }
  }
}



export function* getBulkPaymentsBatchesRequestWatcher() {
  for (; ;) {
    const request = yield take(types.GET_BULK_PAYMENTS_BATCHES_REQUEST);
    try {
      const response = yield call(getBulkPaymentsBatches, request.payload);
      yield put(actions.getBulkPaymentsBatchesSuccess(response.data));

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getBulkPaymentsBatchesFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* getFailedBulkPaymentsBatchesRequestWatcher() {
  for (; ;) {
    const request = yield take(types.GET_FAILED_PAYMENTS_BATCHES_REQUEST);
    try {
      const response = yield call(getFailedBulkPaymentsBatches, request.payload);
      yield put(actions.getFailedBatchesSuccess(response.data));

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getFailedBatchesFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}