import React from 'react';
import { Grid, Typography } from '@mui/material';

const Rejected = ({ claim, labels }) => {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography 
          align="center" 
          variant="body1" 
          style={{ 
            padding: '20px',
            color: '#8F191C',
            fontWeight: 500
          }}
        >
          {/* {labels.claimRejected} */}
          Claim rejected
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Rejected; 