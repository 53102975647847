import * as types from '../../../../../../lib/actionTypes.es6';
import GAlogger, { monitoringEventCategories } from '../../../../../../lib/monitoring.es6';


export function getAllOrangePoliciesRequest(customerGuid) {
  return {
    type: types.GET_ALL_ORANGE_POLICIES_REQUEST,
    payload: customerGuid,
  };
}

export function getAllOrangePoliciesSuccess(payload) {
  return {
    type: types.GET_ALL_ORANGE_POLICIES_SUCCESS,
    payload: payload,
  };
}

export function getAllOrangePoliciesFail(error) {
  return {
    type: types.GET_ALL_ORANGE_POLICIES_FAILURE,
    payload: error,
  };
}

export function purchaseOrangePolicyRequest(purchasePolicyData) {
  return {
    type: types.PURCHASE_ORANGE_POLICY_REQUEST,
    payload: purchasePolicyData,
  };
}

export function purchaseOrangePolicySuccess(policyInfo) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.purchasePolicy);

  return {
    type: types.PURCHASE_ORANGE_POLICY_SUCCESS,
    payload: policyInfo,
  };
}

export function purchaseOrangePolicyFail(response) {
  return {
    type: types.PURCHASE_ORANGE_POLICY_FAILURE,
    payload: response,
  };
}


export function listOrangePaidProductsRequest() {
  return {
    type: types.PAID_ORANGE_PRODUCT_LIST_REQUEST,
  };
}

export function listOrangePaidProductsSuccess(response) {
  return {
    type: types.PAID_ORANGE_PRODUCT_LIST_SUCCESS,
    payload: response,
  };
}

export function listOrangePaidProductsFail(response) {
  return {
    type: types.PAID_ORANGE_PRODUCT_LIST_FAIL,
    payload: response,
  };
}

export function resetOrangePolicyPurchase() {
  return {
    type: types.PURCHASE_ORANGE_POLICY_RESET,
  };
}


export function deleteOrangePolicyRequest(policy) {
  return {
    type: types.DELETE_ORANGE_POLICY_REQUEST,
    payload: policy,
  };
}

export function deleteOrangePolicySuccess() {
  return {
    type: types.DELETE_ORANGE_POLICY_SUCCESS,
  };
}

export function deleteOrangePolicyFail(error) {
  return {
    type: types.DELETE_ORANGE_POLICY_FAIL,
    payload: error,
  };
}

export function setOrangePolicyToCancel(response) {
  return {
    type: types.SET_ORANGE_POLICY_TO_CANCEL,
    payload: response,
  };
}

export function resetOrangePolicyToCancel(response) {
  return {
    type: types.RESET_ORANGE_POLICY_TO_CANCEL,
  };
}

export function setOrangePolicyToEdit(response) {
  return {
    type: types.SET_ORANGE_POLICY_TO_EDIT,
    payload: response,
  };
}

export function resetOrangePolicyToEdit(response) {
  return {
    type: types.RESET_ORANGE_POLICY_TO_EDIT,
  };
}


export function updateOrangePolicyRequest(policyData) {
  return {
    type: types.UPDATE_ORANGE_POLICY_REQUEST,
    payload: policyData,
  };
}

export function updateOrangePolicySuccess(policyInfo) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.purchasePolicy);

  return {
    type: types.UPDATE_ORANGE_POLICY_SUCCESS,
    payload: policyInfo,
  };
}

export function updatePolicyFail(response) {
  return {
    type: types.UPDATE_ORANGE_POLICY_FAILURE,
    payload: response,
  };
}

export function resetOrangePolicyEdit() {
  return {
    type: types.EDIT_ORANGE_POLICY_RESET,
  };
}

export function getOrangeExtendedFamilyProductConfigsRequest(payload) {
  return {
    type: types.GET_ORANGE_EXTENDED_FAMILY_PRODUCT_CONFIGS_REQUEST,
    payload,
  };
}

export function getOrangeExtendedFamilyProductConfigsSuccess(response) {
  return {
    type: types.GET_ORANGE_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS,
    payload: response,
  };
}

export function getOrangeExtendedFamilyProductConfigsFailure(error) {
  return {
    type: types.GET_ORANGE_EXTENDED_FAMILY_PRODUCT_CONFIGS_FAILURE,
    payload: error,
  };
}

export function getTotalPayableOrangePremiumRequest(policy_guid) {
  return {
    type: types.GET_TOTAL_PAYABLE_ORANGE_PREMIUM_REQUEST,
    payload: policy_guid
  };
}

export function getTotalPayableOrangePremiumSuccess(rete) {
  return {
    type: types.GET_TOTAL_PAYABLE_ORANGE_PREMIUM_SUCCESS,
    payload: rete,
  };
}

export function getTotalPayableOrangePremiumFail(error) {
  return {
    type: types.GET_TOTAL_PAYABLE_ORANGE_PREMIUM_FAILURE,
    payload: error,
  };
}