import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  partnerConfig: null,
  partnerConfigErrors: null,
  partnerConfigLoader: false,

  partnerConfigClaim: null,
  partnerConfigClaimErrors: null,
  partnerConfigClaimLoader: false,

  partnerConfigGroup: null,
  partnerConfigGroupErrors: null,
  partnerConfigGroupLoader: false,

  updatePartner: null,
  updatePartnerSuccess: false,
  updatePartnerLoader: false,
  updatePartnerError: null,
  
  updatePartnerConfig: null,
  updatePartnerConfigSuccess: false,
  updatePartnerConfigLoader: false,
  updatePartnerConfigError: null,

  updatePartnerConfigClaim: null,
  updatePartnerConfigClaimSuccess: false,
  updatePartnerConfigClaimLoader: false,
  updatePartnerConfigClaimError: null,

  updatePartnerConfigGroup: null,
  updatePartnerConfigGroupSuccess: false,
  updatePartnerConfigGroupLoader: false,
  updatePartnerConfigGroupError: null,
};

export default function partnerStatusReducer(state = initialState, action) {
  switch (action.type) {

    // partner configs
    case types.GET_PARTNER_CONFIGS_REQUEST:
      return { ...state, partnerConfig: null, partnerConfigErrors: null, partnerConfigLoader: true };

    case types.GET_PARTNER_CONFIGS_SUCCESS:
      return { ...state, partnerConfig: action.payload, partnerConfigErrors: null, partnerConfigLoader: false };

    case types.GET_PARTNER_CONFIGS_FAILURE:
      return { ...state, partnerConfig: null, partnerConfigErrors: action.payload, partnerConfigLoader: false };


    // partner config claim
    case types.GET_PARTNER_CONFIGS_CLAIM_REQUEST:
      return { ...state, partnerConfigClaim: null, partnerConfigClaimErrors: null, partnerConfigClaimLoader: true };

    case types.GET_PARTNER_CONFIGS_CLAIM_SUCCESS:
      return { ...state, partnerConfigClaim: action.payload, partnerConfigClaimErrors: null, partnerConfigClaimLoader: false };

    case types.GET_PARTNER_CONFIGS_CLAIM_FAILURE:
      return { ...state, partnerConfigClaim: null, partnerConfigClaimErrors: action.payload, partnerConfigClaimLoader: false };


    // partner config group
    case types.GET_PARTNER_CONFIGS_GROUP_REQUEST:
      return { ...state, partnerConfigGroup: null, partnerConfigGroupErrors: null, partnerConfigGroupLoader: true };

    case types.GET_PARTNER_CONFIGS_GROUP_SUCCESS:
      return { ...state, partnerConfigGroup: action.payload, partnerConfigGroupErrors: null, partnerConfigGroupLoader: false };

    case types.GET_PARTNER_CONFIGS_GROUP_FAILURE:
      return { ...state, partnerConfigGroup: null, partnerConfigGroupErrors: action.payload, partnerConfigGroupLoader: false };


    // update partner config
    case types.UPDATE_PARTNER_REQUEST:
      return { ...state, updatePartnerError: null, updatePartnerLoader: true, updatePartnerSuccess: false, updatePartner: null };

    case types.UPDATE_PARTNER_SUCCESS:
      return { ...state, updatePartner: action.payload, updatePartnerSuccess: true, updatePartnerLoader: false, updatePartnerError: null };

    case types.UPDATE_PARTNER_FAILURE:
      return { ...state, updatePartnerLoader: false, updatePartnerError: action.payload };

    case types.RESET_UPDATE_PARTNER_SUCCESS:
      return { ...state, updatePartnerSuccess: false };

    case types.RESET_UPDATE_PARTNER_ERROR:
      return { ...state, updatePartnerError: null };
      
      
    // update partner config
    case types.UPDATE_PARTNER_CONFIG_REQUEST:
      return { ...state, updatePartnerConfigError: null, updatePartnerConfigLoader: true, updatePartnerConfigSuccess: false, updatePartnerConfig: null };

    case types.UPDATE_PARTNER_CONFIG_SUCCESS:
      return { ...state, updatePartnerConfig: action.payload, updatePartnerConfigSuccess: true, updatePartnerConfigLoader: false, updatePartnerConfigError: null };

    case types.UPDATE_PARTNER_CONFIG_FAILURE:
      return { ...state, updatePartnerConfigLoader: false, updatePartnerConfigError: action.payload };

    case types.RESET_UPDATE_PARTNER_CONFIG_SUCCESS:
      return { ...state, updatePartnerConfigSuccess: false };

    case types.RESET_UPDATE_PARTNER_CONFIG_ERROR:
      return { ...state, updatePartnerConfigError: null };


    // update partner config claim 
    case types.UPDATE_PARTNER_CONFIG_CLAIM_REQUEST:
      return { ...state, updatePartnerConfigClaimError: null, updatePartnerConfigClaimLoader: true, updatePartnerConfigClaimSuccess: false, updatePartnerConfigClaim: null };

    case types.UPDATE_PARTNER_CONFIG_CLAIM_SUCCESS:
      return { ...state, updatePartnerConfigClaim: action.payload, updatePartnerConfigClaimSuccess: true, updatePartnerConfigClaimLoader: false, updatePartnerConfigClaimError: null };

    case types.UPDATE_PARTNER_CONFIG_CLAIM_FAILURE:
      return { ...state, updatePartnerConfigClaimLoader: false, updatePartnerConfigClaimError: action.payload };

    case types.RESET_UPDATE_PARTNER_CONFIG_CLAIM_SUCCESS:
      return { ...state, updatePartnerConfigClaimSuccess: false };

    case types.RESET_UPDATE_PARTNER_CONFIG_CLAIM_ERROR:
      return { ...state, updatePartnerConfigClaimError: null };

    // update partner config group 
    case types.UPDATE_PARTNER_CONFIG_GROUP_REQUEST:
      return { ...state, updatePartnerConfigGroupError: null, updatePartnerConfigGroupLoader: true, updatePartnerConfigGroupSuccess: false, updatePartnerConfigGroup: null };

    case types.UPDATE_PARTNER_CONFIG_GROUP_SUCCESS:
      return { ...state, updatePartnerConfigGroup: action.payload, updatePartnerConfigGroupSuccess: true, updatePartnerConfigGroupLoader: false, updatePartnerConfigGroupError: null };

    case types.UPDATE_PARTNER_CONFIG_GROUP_FAILURE:
      return { ...state, updatePartnerConfigGroupLoader: false, updatePartnerConfigGroupError: action.payload };

    case types.RESET_UPDATE_PARTNER_CONFIG_GROUP_SUCCESS:
      return { ...state, updatePartnerConfigGroupSuccess: false };

    case types.RESET_UPDATE_PARTNER_CONFIG_GROUP_ERROR:
      return { ...state, updatePartnerConfigGroupError: null };

    default:
      return state;
  }
}