import React from "react";
import { connect } from "react-redux";
import { getAccessBankSmsActivityRequest } from "./Redux/actions";
import SmsActivity from "./Components/SmsActivity/SmsActivity";
class AccessBankSmsActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentWillMount() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      const msisdn = currentCustomer.msisdn
      this.props.dispatch(getAccessBankSmsActivityRequest(msisdn));
    }

  }



  render() {
    return (
      <>
       <SmsActivity 
        loader={this.props.AccessBankSmsActivityData.get_sms_activity_loader}
        smses={this.props.AccessBankSmsActivityData.smsActivities}
        />
      </>
    )
  }
}
export default connect((state) => ({
  customerData: state.currentCustomer,
  AccessBankSmsActivityData: state.AccessBankSmsActivityData,
}))(AccessBankSmsActivity);