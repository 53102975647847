import * as types from '../../lib/actionTypes.es6';
import find from 'lodash/find';

export const initialState = {
  get_policies_errors: null,
  get_policies_loader: false,
  get_policies_success_message: '',
  policies: [],

  get_payments_errors: null,
  get_payments_loader: false,
  get_payments_success_message: '',
  payments: [],


  get_premiums_errors: null,
  get_premiums_loader: false,
  get_premiums_success_message: '',
  premiums: [],


  get_products_errors: null,
  get_products_loader: false,
  get_products_success_message: '',
  products: [],

  get_claims_errors: null,
  get_claims_loader: false,
  get_claims_success_message: '',
  claims: [],

  get_total_payable_premium_errors: null,
  get_total_payable_premium_loader: false,
  get_total_payable_premium_success_message: '',
  total_payable_premium: null,

  get_mandates_errors: null,
  get_mandates_loader: false,
  get_mandates_success_message: '',
  mandates: [],
  
};

export default function customerScreenReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ALL_POLICIES_REQUEST:
      return { ...state, get_policies_loader: true, get_policies_errors: null };
    case types.GET_ALL_POLICIES_SUCCESS:
      return { ...state, get_policies_loader: false, get_policies_errors: null, policies: getPoliciesWithProductDetails(action.payload, state.products) };
    case types.GET_ALL_POLICIES_FAILURE:
      return { ...state, get_policies_loader: false, get_policies_errors: action.payload };


    case types.GET_ALL_PAYMENTS_REQUEST:
      return { ...state, get_payments_loader: true, get_payments_errors: null };
    case types.GET_ALL_PAYMENTS_SUCCESS:
      return { ...state, get_payments_loader: false, get_payments_errors: null, payments: getPaymentsWithProductDetails(action.payload, state.products, state.policies) };
    case types.GET_ALL_PAYMENTS_FAILURE:
      return { ...state, get_payments_loader: false, get_payments_errors: action.payload };


    case types.GET_ALL_PREMIUMS_REQUEST:
      return { ...state, get_premiums_loader: true, get_premiums_errors: null };
    case types.GET_ALL_PREMIUMS_SUCCESS:
      return { ...state, get_premiums_loader: false, get_premiums_errors: null, premiums: getPremiumsWithProductDetails(action.payload, state.products, state.policies) };
    case types.GET_ALL_PREMIUMS_FAILURE:
      return { ...state, get_premiums_loader: false, get_premiums_errors: action.payload };


    case types.GET_ALL_PRODUCTS_REQUEST:
      return { ...state, get_products_loader: true, get_products_errors: null };
    case types.GET_ALL_PRODUCTS_SUCCESS:
      return { ...state, get_products_loader: false, get_products_errors: null, products: action.payload };
    case types.GET_ALL_PRODUCTS_FAILURE:
      return { ...state, get_products_loader: false, get_products_errors: action.payload };


    case types.GET_ALL_CUSTOMER_CLAIMS_REQUEST:
      return { ...state, get_claims_loader: true, get_claims_errors: null };
    case types.GET_ALL_CUSTOMER_CLAIMS_SUCCESS:
      return { ...state, get_claims_loader: false, get_claims_errors: null, claims: addClaimGuid(action.payload) };
    case types.GET_ALL_CUSTOMER_CLAIMS_FAILURE:
      return { ...state, get_claims_loader: false, get_claims_errors: action.payload };


    case types.GET_TOTAL_PAYABLE_PREMIUM_REQUEST:
      return { ...state, get_total_payable_premium_loader: true, get_total_payable_premium_errors: null };
    case types.GET_TOTAL_PAYABLE_PREMIUM_SUCCESS:
      return { ...state, get_total_payable_premium_loader: false, get_total_payable_premium_errors: null, total_payable_premium: action.payload, policies: addToPolicyFields(state.policies, action.payload) };
    case types.GET_TOTAL_PAYABLE_PREMIUM_FAILURE:
      return { ...state, get_total_payable_premium_loader: false, get_total_payable_premium_errors: action.payload };


      case types.GET_ALL_MANDATES_REQUEST:
        return { ...state, get_mandates_loader: true, get_mandates_errors: null };
      case types.GET_ALL_MANDATES_SUCCESS:
        return { ...state, get_mandates_loader: false, get_mandates_errors: null, mandates: action.payload };
      case types.GET_ALL_MANDATES_FAILURE:
        return { ...state, get_mandates_loader: false, get_mandates_errors: action.payload };

    default:
      return state;
  }
}


function getBenefits(product) {
  let benefits = [
    ...product.variable_indemnities.map((benefit) => { return { amount: benefit.amount, type: benefit.name } }),
    ...product.fixed_indemnities.map((benefit) => { return { amount: benefit.amount_benefit, type: benefit.name } }),
  ]
  return benefits
}

function getPoliciesWithProductDetails(policies, products) {
  let npolicies = policies.map((policy) => {
    const product = find(products, { guid: policy.product_guid })
    return {
      ...policy,
      product_name: product.name || '',
      product_cover_type: product.cover_type || '',
      benefits: getBenefits(product)
    }
  })
  return npolicies
}

function getPaymentsWithProductDetails(payments, products, polices) {
  if(products.length > 0 && polices.length > 0){
    let npayments = payments.map((payment) => {
      const policy = find(polices, { guid: payment.policy_guid })
      const product = find(products, { guid: policy.product_guid })
      return {
        ...payment,
        product_name: product.name || '',
        product_cover_type: product.cover_type || '',
      }
    })
    return npayments
  }
  return payments

}


function getPremiumsWithProductDetails(premiums, products, polices) {
  let npremiums = premiums.map((premium) => {
    const policy = find(polices, { guid: premium.policy_guid })
    const product = find(products, { guid: policy.product_guid })
    return {
      ...premium,
      product_name: product.name || '',
      product_cover_type: product.cover_type || '',
      benefits: getBenefits(product)
    }
  })
  return npremiums
}


function addClaimGuid(claims){
  return claims.map(claim => {
    let claim_guid = claim.guid ? claim.guid : claim.reference
    return {...claim, guid: claim_guid }
  })
}



function addToPolicyFields(policies, total_payable_premium){
  if(total_payable_premium){
    return policies.map(policy => {
      return {...policy, total_payable_premium: total_payable_premium.premium_amount_in_cents + total_payable_premium.additional_premium_amount_in_cents }
    })
  }

  return policies

}
