import * as types from '../../../../../lib/actionTypes.es6';

export function getDebitOrderzRequest(payload) {
    return {
      type: types.GET_DEBIT_ORDERZ_REQUEST,
      payload,
    };
  }

  export function getDebitOrderzFailure(error) {
    return {
      type: types.GET_DEBIT_ORDERZ_FAILURE,
      payload: error,
    };
  }

  export function getDebitOrderzSuccess(response) {
    return {
      type: types.GET_DEBIT_ORDERZ_SUCCESS,
      payload: response,
    };
  }

  export function createDebitOrderzRequest(payload) {
  return {
    type: types.CREATE_DEBIT_ORDERZ_REQUEST,
    payload,
  };
}

export function createDebitOrderzFailure(error) {
  return {
    type: types.CREATE_DEBIT_ORDERZ_FAILURE,
    payload: error,
  };
}

export function createDebitOrderzSuccess(response) {
  return {
    type: types.CREATE_DEBIT_ORDERZ_SUCCESS,
    payload: response,
  };
}

export function resetCreateDebitOrderzSuccess(error) {
  return {
    type: types.RESET_CREATE_DEBIT_ORDERZ_SUCCESS,
  };
}

export function resetCreateDebitOrderzError(error) {
  return {
    type: types.RESET_CREATE_DEBIT_ORDERZ_ERROR,
  };
}

export function hardDeleteDebitOrderzRequest(payload) {
  return {
    type: types.HARD_DELETE_DEBIT_ORDERZ_REQUEST,
    payload,
  };
}

export function hardDeleteDebitOrderzFailure(error) {
  return {
    type: types.HARD_DELETE_DEBIT_ORDERZ_FAILURE,
    payload: error,
  };
}

export function hardDeleteDebitOrderzSuccess(response) {
  return {
    type: types.HARD_DELETE_DEBIT_ORDERZ_SUCCESS,
    payload: response,
  };
}

export function resetHardDeleteDebitOrderzSuccess(error) {
  return {
    type: types.RESET_HARD_DELETE_DEBIT_ORDERZ_SUCCESS,
  };
}

export function resetHardDeleteDebitOrderzError(error) {
  return {
    type: types.RESET_HARD_DELETE_DEBIT_ORDERZ_ERROR,
  };
}

export function softDeleteDebitOrderzRequest(payload) {
  return {
    type: types.SOFT_DELETE_DEBIT_ORDERZ_REQUEST,
    payload,
  };
}

export function softDeleteDebitOrderzFailure(error) {
  return {
    type: types.SOFT_DELETE_DEBIT_ORDERZ_FAILURE,
    payload: error,
  };
}

export function softDeleteDebitOrderzSuccess(response) {
  return {
    type: types.SOFT_DELETE_DEBIT_ORDERZ_SUCCESS,
    payload: response,
  };
}


export function editDebitOrderzRequest(payload) {
  return {
    type: types.EDIT_DEBIT_ORDERZ_REQUEST,
    payload,
  };
}

export function editDebitOrderzFailure(error) {
  return {
    type: types.EDIT_DEBIT_ORDERZ_FAILURE,
    payload: error,
  };
}

export function editDebitOrderzSuccess(response) {
  return {
    type: types.EDIT_DEBIT_ORDERZ_SUCCESS,
    payload: response,
  };
}

export function resetEditDebitOrderzSuccess(error) {
  return {
    type: types.RESET_EDIT_DEBIT_ORDERZ_SUCCESS,
  };
}

export function resetEditDebitOrderzError(error) {
  return {
    type: types.RESET_EDIT_DEBIT_ORDERZ_ERROR,
  };
}

export function setDebitOrderzToEdit(response) {
    return {
      type: types.SET_DEBIT_ORDERZ_TO_EDIT,
      payload: response,
    };
  }

  export function setDebitOrderzToDelete(response) {
    return {
      type: types.SET_DEBIT_ORDERZ_TO_DELETE,
      payload: response,
    };
  }


export function resetDebitOrderzToEdit(response) {
    return {
      type: types.RESET_DEBIT_ORDERZ_TO_EDIT,
    };
  }

  export function resetDebitOrderzToDelete(response) {
    return {
      type: types.RESET_DEBIT_ORDERZ_TO_DELETE,
    };
  }

export function getAllBanksRequest() {
  return {
    type: types.GET_ALL_BANKS_REQUEST,
  };
}

export function getAllBanksSuccess(banks) {
  return {
    type: types.GET_ALL_BANKS_SUCCESS,
    payload: banks,
  };
}

export function getAllBanksFailure(response) {
  return {
    type: types.GET_ALL_BANKS_FAILURE,
    payload: response,
  };
}

