import * as types from '../../lib/actionTypes.es6';

export function currentProfileRequest(profileData) {
  return {
    type: types.CURRENT_PROFILE_REQUEST,
    payload: profileData,
  };
}

export function currentProfileSuccess(profileData) {
  return {
    type: types.CURRENT_PROFILE_SUCCESS,
    payload: profileData,
  };
}

export function currentProfileFail(response) {
  return {
    type: types.CURRENT_PROFILE_FAIL,
    payload: response,
  };
}

export function currentUpdateProfileRequest(profileData) {
  return {
    type: types.CURRENT_PROFILE_UPDATE_REQUEST,
    payload: profileData,
  };
}

export function currentProfileUpdateSuccess(claimInfo) {
  return {
    type: types.CURRENT_PROFILE_UPDATE_SUCCESS,
    payload: claimInfo,
  };
}

export function currentProfileUpdateFail(response) {
  return {
    type: types.CURRENT_PROFILE_UPDATE_FAIL,
    payload: response,
  };
}
