import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import './BankDetailsTable.css'
import { PRIMARY_THEME_COLOR_SHADE_1, PRIMARY_THEME_COLOR_SHADE_2 } from "../../lib/constants";
import SkeletonWrapper from "../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper";

export default function DisplayBeneficiaryBankDetails(props) {
  if (props.loading) {
    return <SkeletonWrapper loading={props.loading} />
  } else if (props.customerbankdetail) {
    return (
      <>
        <div style={{ marginTop: "0%" }}>
          {props.title && <div className="page-title">
            <div className="title_left" style={{ color: PRIMARY_THEME_COLOR_SHADE_2 }}>
              <h4>{props.title}</h4>
            </div>
          </div>}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ color: 'red' }}>
                <TableRow >
                  <TableCell className="table-cell-header-dep">Account Name</TableCell>
                  <TableCell className="table-cell-header-dep">Bank Name</TableCell>
                  <TableCell className="table-cell-header-dep">Account Number</TableCell>
                  <TableCell className="table-cell-header-dep">Account Type</TableCell>
                  <TableCell className="table-cell-header-dep">Branch Code</TableCell>
                  <TableCell className="table-cell-header-dep"> created At</TableCell>
                  <TableCell className="table-cell-header-dep">Actions </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[props.customerbankdetail].map((detail) => {
                  return (
                    <TableRow
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{detail.account_name}</TableCell>
                      <TableCell>{detail.bank_name}</TableCell>
                      <TableCell>{detail.account_number}</TableCell>
                      <TableCell>{detail.account_type}</TableCell>
                      <TableCell >{detail.branch_code}</TableCell>
                      {/* <TableCell>{detail.bank_name}</TableCell> */}
                      <TableCell>{detail.created_at}</TableCell>
                      <TableCell align="right">
                        <Stack direction="row" spacing={1} justifyContent="flex-end" >
                          <IconButton onClick={() => { props.setBankDetailsToEdit(detail) }} >
                            <EditIcon
                              style={{ color: PRIMARY_THEME_COLOR_SHADE_1, fontWeight: 'bolder', display: props.displayEditButton ? 'inline-block' : 'none' }}
                            />
                          </IconButton>
                          <IconButton onClick={() => { props.setBankDetailsToDelete(detail) }} >
                            <DeleteForeverIcon
                              style={{ color: 'red', fontWeight: 'bolder', marginRight: "2%", display: props.displayDeleteButton ? 'inline-block' : 'none' }}
                            />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
    );
  }
  return "No bank details found, click on the add button to add new bank details."
}
