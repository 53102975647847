import { take, call, put } from 'redux-saga/effects';
import { getReportOpenClaimBlob } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import * as types from '../../lib/actionTypes.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

export function* reportsOpenClaimRequestWatcher() {
  for (;;) {
    const reportAction = yield take(types.REPORT_OPEN_CLAIM_REQUEST);
    try {
      const response = yield call(getReportOpenClaimBlob, reportAction.payload);
      yield put(actions.reportOpenClaimRequestSuccess(response.data));
    } catch (e) {
      yield put(
        actions.reportOpenClaimRequestError(
          'An error occurred, this could be due to invalid data'
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
