import React from 'react';
import Loader from 'react-loader';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import CustomersClaims from '../../components/CustomersClaims/index.es6';
import { formatStatus } from '../../lib/utils.es6';
import * as globalActions from '../Main/actions.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { localisedText } from '../../lib/localisation.es6';

export class ClaimsStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
    };
  }

  componentWillMount() {
    this.updateLanguage();
    this.props.dispatch(globalActions.hideCustomerBar());
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'ClaimsStatus',
      this.state.labels
    );
  }

  titleDisplay() {
    const labels = this.state.labels;
    let title = labels.noStatusText;
    const status = this.props.claimData.status;
    if (status) {
      title = `${formatStatus(status)} ${labels.claims}`;
    }
    return title;
  }

  render() {
    const claimData = this.props.claimData;
    return (
      <Loader loaded={!claimData.loader} color="#ff834f">
        <CustomersClaims
          claims={claimData.claimsStatusList}
          status={claimData.status}
          title={this.titleDisplay()}
          columnWidth="col-md-12"
          showMore={false}
        />
      </Loader>
    );
  }
}

export default connect(state => ({
  claimData: state.claimsStatusList,
  globalData: state.global,
}))(ClaimsStatus);
