import { take, call, put } from 'redux-saga/effects';
import {
  searchCustomerByMsisdn,
  searchCustomerByName,
  searchCustomerByNationalId,
  searchCustomerByExternalId
} from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

/**
 * this saga continually watches register request actions
 */
export function* searchCustomerByMsisdnRequestWatcher() {
  for (;;) {
    const actionResponse = yield take('SEARCH_CUSTOMER_BY_MSISDN_REQUEST');

    try {
      let response = null;
      if (actionResponse.payload.msisdn) {
        response = yield call(searchCustomerByMsisdn, actionResponse.payload);
      } else if (actionResponse.payload.name) {
        response = yield call(searchCustomerByName, actionResponse.payload);
      } else if (actionResponse.payload.national_id) {
        response = yield call(searchCustomerByNationalId, actionResponse.payload);
      }else if (actionResponse.payload.external_identifier) {
        response = yield call(searchCustomerByExternalId, actionResponse.payload);
      }
      yield put(actions.searchCustomerByMsisdnSuccess(response.data));
    } catch (e) {
      yield put(
        actions.searchCustomerByMsisdnFail(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
