import * as types from '../../lib/actionTypes.es6';

export function reportRequest(reportType) {
  return {
    type: types.REPORT_REQUEST,
    payload: reportType,
  };
}

export function reportRequestSuccess(reportURL) {
  return {
    type: types.REPORT_REQUEST_SUCCESS,
    payload: reportURL,
  };
}

export function reportRequestError(error) {
  return {
    type: types.REPORT_REQUEST_ERROR,
    payload: error,
  };
}

export function reportRequestReset() {
  return {
    type: types.REPORT_REQUEST_RESET,
  };
}
