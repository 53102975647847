import React from 'react';
import MaterialTable from 'material-table';
import * as utils from '../../lib/utils.es6';


export default class GroupPolicies extends React.Component {
  productNameDisplay(policy) {
    return `${policy.product_name} (${policy.product_cover_type})`;
  }
  render() {
    const policies = this.props.policies || [];
    return (
      <>
      {this.props.paginatation}

      <MaterialTable
        title="Group Policies"
        columns={[
          { title: 'Principal', field: 'customer_full_name' },
          { title: 'Msisdn', field: 'customer_msisdn' },
          { title: 'Product Name', field: 'product_name', render: policy =>  this.productNameDisplay(policy)},
          { title: "Status", field: 'status', render: policy => utils.formatStatus(policy.status)},
          { title: 'Active', field: 'active', render: policy => utils.pascalCase(policy.active.toString())},
          { title: 'Start Date', field: 'start_date' },
          { title: 'End Date', field: 'end_date' },
          { title: 'Death Benefit', field: 'product_fixed_benefit' },
   
        ]}
        data={policies}
        options={{
					search: true,
					sorting: true,
					exportButton: true,
					actionsColumnIndex: -1,
					pageSize: 10,
					headerStyle: {
						backgroundColor: '#003643',
						color: '#FFF'
					}
				}}
     
      />

      {this.props.paginatation}
      </>
    );
  }
}