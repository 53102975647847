import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { deepOrange } from '@material-ui/core/colors';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MainPageTitle from '../../components/MainPageTitle/index.es6';
import { connect } from 'react-redux'; 
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getAllCashbacksRequest } from './actions.es6';
import LocalizedStrings from 'react-localization';
import { localisedText } from '../../lib/localisation.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import * as actions from './actions.es6';
import { hasAuthourity } from '../../lib/access.es6';


export class Cashback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText)
    }
    this.statusHandler = this.statusHandler.bind(this);
  }

  componentWillMount() {
    this.updateLanguage();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }
  
  componentDidMount() {
    this.props.dispatch(getAllCashbacksRequest());
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'Cashback',
      this.state.labels
    );
  }

  statusHandler = (id,paid) => {
    if(paid){
      this.props.dispatch(actions.markCashbackAsUnpaidRequest(id));
    } else {
      this.props.dispatch(actions.markCashbackAsPaidRequest(id));
    }
  };

  render() {
    const rows = this.props.cashbackData.cashbacks;
    const { labels } = this.state;
    return (
      <React.Fragment>
        <Grid container justify="center">
          <div style={{ width: '98%' }}>
            <MainPageTitle pageTitle={labels.cashback} />
              <p style={{display: this.props.cashbackData.error ? 'inline-block' : 'none'}}>Error Happened</p>
            <TableContainer component={Paper} >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table-cell-header">{labels.createdAt}</TableCell>
                    <TableCell className="table-cell-header">{labels.fullName}</TableCell>
                    <TableCell className="table-cell-header">{labels.msisdn}</TableCell>
                    <TableCell className="table-cell-header">{labels.amount}</TableCell>
                    <TableCell className="table-cell-header">{labels.paymentStatus}</TableCell>
                    {
                       hasAuthourity('UPDATE_CASHBACK_PAYMENT_STATUS') && 
                      <TableCell className="table-cell-header">{labels.action}</TableCell>
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {new Date(row.created_at).toDateString()}
                      </TableCell>
                      <TableCell>{row.full_name}</TableCell>
                      <TableCell>{row.msisdn}</TableCell>
                      <TableCell>{(row.amount_in_cents / 100).toFixed(2)}</TableCell>
                      <TableCell>{row.paid ? labels.paid : labels.notPaid}</TableCell>
                      {
                         hasAuthourity('UPDATE_CASHBACK_PAYMENT_STATUS') && 
                        <TableCell> 
                        <Button 
                          variant="contained" size='small' color="primary" 
                          style={{ backgroundColor: deepOrange[400], color: '#FFF', margin: '3%' }} 
                          onClick={() => this.statusHandler(row.id, row.paid) }>
                          {row.paid ? labels.markAsUnpaidBtn : labels.markAsPaidBtn}
                        </Button>
                        </TableCell>
                      }
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
        <Backdrop style={{ zIndex: 1000 + 1,color: '#fff'}} open={this.props.cashbackData.loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </React.Fragment>
    )
  }

}

//connect this component to a reducer called cashback that we defined in the reducer tree. We can connect n amount of reducers
export default connect(state => ({ 
  cashbackData: state.cashback,
  globalData: state.global,
}))(Cashback);