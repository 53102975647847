import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import IconMenu from 'material-ui/IconMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import LocalizedStrings from 'react-localization';
import { localisedText } from '../../lib/localisation.es6';
import { hasAuthourity } from '../../lib/access.es6';

export default class ClaimPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      claimStatus: props.claimStatus,
      payments: props.payments,
      labels: new LocalizedStrings(localisedText),
      anchorElAction: null,
      payment: null,
    };
  }

  renderFileTable() {
    const labels = this.props.labels;
    if (this.state.payments.length > 0) {
      return this.renderNonEmptyList();
    }
    return (
      <div style={{ textAlign: 'center' }}>
        <h4>{labels.titleNoPayments}</h4>
      </div>
    );
  }

  comparePayments(paymentA, paymentB) {
    if (
      new Date(paymentA.created_at).getTime() ===
      new Date(paymentB.created_at).getTime()
    ) {
      return 0;
    }
    if (new Date(paymentA.created_at) > new Date(paymentB.created_at)) {
      return -1;
    }
    return 1;
  }

  retryPaymentVisible() {
    return (
      hasAuthourity('CLAIM_RETRY_TRANSACTION_PAYMENT') &&
      this.state.claimStatus === 'Payment_In_Review'
    );
  }

  retryPaymentMenuVisible(aPayment) {
    return this.retryPaymentVisible() && this.enableRetryPayment(aPayment);
  }

  paymentOutcomeUpdateVisible(aPayment) {
    return (
      hasAuthourity('CLAIM_PAYMENT_UPDATE_OUTCOME') && !aPayment.callback_status
    );
  }

  paymentHeaderAction() {
    const labels = this.state.labels;
    const retryPaymentStyle = {
      display: this.retryPaymentVisible() ? '' : 'none',
    };
    return <th style={retryPaymentStyle}>{labels.action}</th>;
  }

  handleActionMenuButtons(payment, event) {
    this.setState({ anchorElAction: event.currentTarget, payment });
  }

  handleCloseActionMenu() {
    this.setState({ anchorElAction: null });
  }

  enablePaymentAction(aPayment) {
    return (
      this.enableRetryPayment(aPayment) ||
      this.paymentOutcomeUpdateVisible(aPayment)
    );
  }

  enableRetryPayment(aPayment) {
    return aPayment.callback_status === 'Failed';
  }

  // TODO: No need to repeat for each row...
  paymentActionRow(aPayment) {
    const labels = this.state.labels;
    const anchorElAction = this.state.anchorElAction;
    const retryPaymentStyle = {
      display: this.retryPaymentVisible() ? '' : 'none',
    };
    return (
      <td>
        <IconMenu
          iconButtonElement={
            <button
              style={retryPaymentStyle}
              disabled={!this.enablePaymentAction(aPayment)}
              onClick={this.handleActionMenuButtons.bind(this, aPayment)}
              type="button"
              className="btn btn-info"
            >
              {labels.action}
            </button>
          }
        />
        <Menu
          anchorEl={anchorElAction}
          open={Boolean(anchorElAction)}
          onClose={this.handleCloseActionMenu.bind(this)}
        >
          {this.paymentRetryActionMenu()}
          {this.paymentUpdateOutcomeFailedMenu()}
          {this.paymentUpdateOutcomeSucceededMenu()}
        </Menu>
      </td>
    );
  }

  paymentRetryActionMenu() {
    const aPayment = this.state.payment;
    const labels = this.state.labels;
    if (!aPayment || !this.retryPaymentMenuVisible(aPayment)) {
      return null;
    }
    return (
      <MenuItem
        disabled={!this.enableRetryPayment(aPayment)}
        onClick={this.retryFailedPayment.bind(this, aPayment.guid)}
      >
        {labels.retryClaimPaymentBtnLabel}
      </MenuItem>
    );
  }

  retryFailedPayment(paymentGuid) {
    this.props.retryFailedPaymentHandler(paymentGuid);
    this.handleCloseActionMenu();
  }

  paymentUpdateOutcomeFailedMenu() {
    const labels = this.state.labels;
    return this.paymentUpdateOutcomeMenu(
      'Failed',
      labels.markClaimPaymentAsFailedBtnLabel
    );
  }

  paymentUpdateOutcomeSucceededMenu() {
    const labels = this.state.labels;
    return this.paymentUpdateOutcomeMenu(
      'Succeeded',
      labels.markClaimPaymentAsSucceededBtnLabel
    );
  }

  paymentUpdateOutcomeMenu(updatedStatus, labelText) {
    const aPayment = this.state.payment;
    if (!aPayment || !this.paymentOutcomeUpdateVisible(aPayment)) {
      return null;
    }
    return (
      <MenuItem
        disabled={!this.paymentOutcomeUpdateVisible(aPayment)}
        onClick={this.updatePaymentOutcome.bind(
          this,
          aPayment.guid,
          updatedStatus
        )}
      >
        {labelText}
      </MenuItem>
    );
  }

  updatePaymentOutcome(paymentGuid, updatedStatus) {
    const payload = {
      callback_status: updatedStatus,
    };
    payload.guid = paymentGuid;
    this.props.updatePaymentOutcomeHandler(payload);
    this.handleCloseActionMenu();
  }

  render() {
    return <div>{this.renderFileTable()}</div>;
  }

  renderNonEmptyList() {
    const labels = this.props.labels;
    const payments = this.props.payments;
    payments.sort(this.comparePayments);

    return (
      <div className="form-horizontal form-label-left" noValidate>
        <center>
          {' '}
          <CircularProgress
            style={{ display: this.props.loader ? '' : 'none' }}
          />{' '}
        </center>
        <br />
        <h4>{labels.paymentAttemptTitle}</h4>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>{labels.index}</th>
              <th>{labels.amount}</th>
              <th>{labels.status}</th>
              <th>{labels.mnoStatus}</th>
              <th>{labels.loyalty}</th>
              <th>{labels.createdAt}</th>
              {this.paymentHeaderAction()}
            </tr>
          </thead>
          <tbody className="policies-list">
            {payments.map((aPayment, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{aPayment.amount_in_cents / 100}</td>
                <td>{aPayment.status}</td>
                <td>{aPayment.callback_status || 'N/A'}</td>
                <td className="col-xs-1">
                  <Checkbox required checked={aPayment.loyalty} disabled />
                </td>
                <td>{aPayment.created_at}</td>
                {this.paymentActionRow(aPayment)}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
