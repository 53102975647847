import React, { forwardRef } from "react";
import { Paper, Typography, Grid } from "@mui/material";
import { deepOrange } from '@material-ui/core/colors';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import BoxInc from "../../../../shared-ui/BoxInc/Index";
import ButtonInc from "../../../../shared-ui/ButtonInc";
import { formatDateLocale } from "../../../../lib/utils.es6";
import { hashHistory } from "react-router";

const Overview = forwardRef(({ claim, labels, showAssessmentButton, toggleAssesmentDialog }, ref) => {
  const renderAssessmentButton = () => {
    if (!showAssessmentButton) {
      return null;
    }

    return (
      <Grid
        item
        xs={12}
        style={{
          textAlign: "end",
          marginRight: '5.35%',
          marginBottom: '-2.5%'
        }}
      >
        <ButtonInc
          onClick={toggleAssesmentDialog}
          style={{
            border: "1px solid #808080",
            borderRadius: "8px",
            background: "#fff",
            padding: "5px 35px",
          }}
        >
          <span style={{ color: "#000000" }}>
            View ASPin AI assesment
          </span>
          <AutoFixHighIcon
            sx={{
              color: deepOrange[400],
              marginLeft: "10px",
            }}
          />
        </ButtonInc>
      </Grid>
    );
  };

  return (
    <div id="overview" component-id="overview">
      {renderAssessmentButton()}
      <BoxInc
        title="Claims Overview"
        expanded
        style={{ marginTop: "40px" }}
        actionButtons={[<ButtonInc
          variant="contained"
          color="primary"
          size="large"
          onClick={
            ()=> hashHistory.push(
              `admin/customer_status/${claim.customer_guid}`
            )
          }
        >
         View Customer Profile    
        </ButtonInc>]}
      >
        <Paper elevation={0} sx={{ p: 3, mb: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Type: <strong>{claim.type}</strong></Typography>
              <Typography variant="subtitle1">
                Relation to Main Member: <strong>{claim.relation_to_main_member}</strong>
              </Typography>
              <Typography variant="subtitle1">
                Created Date: <strong>{formatDateLocale(claim.created_at)}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {claim.type === "Personal_Accident" && (
                <Typography variant="subtitle1">
                  Event Date: <strong>{claim.event_date}</strong>
                </Typography>
              )}

              {claim.type === "Hospital" && (
                <Typography variant="subtitle1">
                  Hospital Admision Date: <strong>{claim.hospital_admission_date}</strong>
                </Typography>
              )}
              <Typography variant="subtitle1">
                Customer Fullname: <strong>{claim.customer_full_name}</strong>
              </Typography>
              <Typography variant="subtitle1">
                Customer Phone: <strong>{claim.customer_msisdn}</strong>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </BoxInc>
    </div>
  );
});

Overview.displayName = 'Overview';

export default Overview;
