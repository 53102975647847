import {call, put, take} from "redux-saga/effects";
import * as types from "../../../../../../lib/actionTypes.es6";
import {
  addCustomerDocument,
  deleteCustomerDocument,
  getCustomerDocuments,
  
} from "../../../../../../lib/requests.es6";
import * as actions from "./actions.js";
import {unAuthorizedNotification} from "../../../../../../lib/access.es6";
import {GAloggerException} from "../../../../../../lib/monitoring.es6";

export function* getOrangeCustomerDocumentsRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.GET_ORANGE_CUSTOMER_DOCUMENTS_REQUEST);
      const response = yield call(getCustomerDocuments, payload);
      yield put(actions.getOrangeCustomerDocumentsSuccess(response.data));
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.getOrangeCustomerDocumentsFailed(errorPayload));
      GAloggerException(e);
    }
  }
}


export function* addOrangeCustomerDocumentRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.ADD_ORANGE_CUSTOMER_DOCUMENT_REQUEST);

      const response = yield call(addCustomerDocument, payload);
      yield put(actions.addOrangeCustomerDocumentSuccess(response.data));
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.addOrangeCustomerDocumentFailed(errorPayload));
      GAloggerException(e);
    }
  }
}


export function* deleteOrangeCustomerDocumentRequestWatcher() {
  while (true) {
    try {
      const {payload} = yield take(types.DELETE_ORANGE_CUSTOMER_DOCUMENT_REQUEST);

      const response = yield call(deleteCustomerDocument, payload);
      yield put(actions.deleteOrangeCustomerDocumentSuccess(response.data));
    } catch (e) {
      let errorPayload = {message: 'An error occurred, this could be due to invalid data'};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.deleteOrangeCustomerDocumentFailed(errorPayload));
      GAloggerException(e);
    }
  }
}
