import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  statuscard: {
    background: "#FFFFFF",
    borderRadius: "8px",
    height: "auto",
    marginBottom: "20px",
    width: "95%"
  },
  statusheader: {
    background: "#173E4B",
    borderRadius: "8px 8px 0px 0px",
    height: "65px",
    width: "103%",
    "& h1": {
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.0015em",
      color: "#FFFFFF",
      paddingLeft: "30px"
    }
  },

  actionbtns: {
    marginTop: "2%",
    textAlign: "right",
    display: 'flex',
    justifyContent: 'end',
    "& button": {
      background: "#fba92c",
      color: "#fff !important",
      borderRadius: "8px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      boxShadow: "none",
      padding: "10px",
      textTransform: "capitalize",
      marginBottom: "30px",
      marginLeft: "30px",
      "&:hover": {
        background: "#fba92c",
        boxShadow: "none",
      }
    }
  },

  expandIconWrapper: {
    marginTop: "-20%",
   // marginLeft: "15px",
    color: "#fff",
    "& svg":{
      marginRight: "40px",
    }
  },
  accordionWrapper:{
    "& .MuiPaper-root-MuiAccordion-root":{
      "&::before":{
        background: "transparent",
      }
    }
  }

}));