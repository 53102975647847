import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getPartner } from '../../../../lib/access.es6';

const Viewquote = (props) => {
  const classes = useStyle();
  const dummYCreatedQuote = props.createdQuote

  const currencyCode = getPartner().country.currency_code

  if (props.createdQuote) {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <section className={classes.viewquotetablewrapper}>
              <table>
                <tr>
                  <th>Name</th>
                  <th>Daily Hospitalization (DHB)</th>
                  <th>Medical Surgical (MSB)</th>
                  <th>Death  (DB)-DHB</th>
                  {/* <th>Waiting Period</th> */}
                  <th>Premiums</th>
                </tr>
                {
                  dummYCreatedQuote.policy_schedule_entries.map((quote, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>{quote.dependant.first_name + " " + quote.dependant.last_name}</td>
                          <td>{currencyCode + " " + (quote.benefits[0].cover_amount_in_cents / 100).toLocaleString()}</td>
                          <td>{currencyCode + " " + (quote.benefits[1].cover_amount_in_cents / 100).toLocaleString() }</td>
                          <td>{currencyCode + " " + (quote.benefits[2].cover_amount_in_cents / 100).toLocaleString() }</td>
                          <td>{currencyCode + " " + (quote.premium_amount_in_cents / 100).toLocaleString()  }</td>
                        </tr>
                      </>
                    )
                  })
                }

              </table>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <div className={classes.showmoreshowless}>
                    <ul>
                      <li>Show more</li>
                      <li><ArrowDropDownIcon /></li>
                    </ul>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Grid container>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className={classes.totalpremium}>
                        <p>Total  premiums:  {currencyCode + " " + dummYCreatedQuote.total_premium_amount_in_cents / 100} | </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <form className={classes.acceptquote}>
                        <input onClick={()=> props.setAcceptQuote(!props.acceptQuote)} type="checkbox" id="acceptquote" name="acceptquote" value="Accept Quote" />
                        <label for="acceptquote"> Accept Quote</label>
                      </form>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </section>
          </Grid>
        </Grid>
      </>
    )
  }else{
    return (
      <>
        <section className={classes.statuscard}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} >
              <div className={classes.policieswrapper}>
                <div className={classes.nodatafound}>
                  <p>No Quotes found</p>
                </div>
              </div>
            </Grid>
          </Grid>
        </section>
      </>
    )
  }

}

export default Viewquote