import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";

const ApprovedPaid = () => {
  const classes = useStyle();
  return (
    <section className={classes.ClaimActionButtons}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <p style={{textAlign: "center"}}>claim has been Approved and Paid</p>
      </Grid>
    </Grid>
  </section>
  )
}

export default ApprovedPaid