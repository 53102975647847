import React from 'react'
import AlertDialog from '../../../../../components/AlertDialog/index.es6'
import { CircularProgress } from '@mui/material'
import ButtonInc from '../../../../../shared-ui/ButtonInc'
import { reloadPage } from '../../../../../lib/utils.es6'

const ApprovePayment = (props) => {


  const handlePaymentApprovedSuccessfully = () => {
    reloadPage()
  }

  const handlePaymentApprovalError = () => {
    reloadPage()

  }
  return (
    <>
      <AlertDialog
        custom
        show={props.showApprovePaymentProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Approving payment...</h2>
      </AlertDialog>


      <AlertDialog
        success
        show={props.showApprovePaymentSuccessAlert}
        size="sm"
        title={`payment approved successsfuly`}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handlePaymentApprovedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'Cancel'}
        confirmBtnCssClass
      >
        <div >
          <ButtonInc variant="contained" onClick={() => { handlePaymentApprovedSuccessfully() }} style={{ background: 'green', }}>Ok</ButtonInc>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showApprovePaymentErrorAlert}
        danger
        title={`Error approving payment`}
        onConfirm={() => handlePaymentApprovalError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.approvePaymentError ? props.approvePaymentError.message : ''}
      </AlertDialog>

    </>
  )
}

export default ApprovePayment