import React from 'react';
import Grid from "@material-ui/core/Grid";
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import { useStyle } from "./index.styles";
// import { ProductTypes } from '../../helpers';


export function SelectProductType({ setProductType, productType }) {
  const classes = useStyle();

 const ProductTypes = {
  Monamodi: 'Monamodi Funeral Plan',
  FamilyClan: 'Family Clan Cover',
  Motshelo: 'Motshelo Group Cover'
};

  const handleSelect = (value) => {
    setProductType(value)
    console.log("product type", value)
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12}><h2 style={{
        fontFamily: 'Open Sans',
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: "20px",
        lineHeight: "16px",
        letterSpacing: "0.005em",
        color: "#000000",
        textAlign: "center"
      }}>Please select product type</h2></Grid>
      <Grid item xs={12} sm={4} md={4}>
        <div className={`${classes.productWrapper} ${productType === ProductTypes.Monamodi ? 'selected' : ''}`}
          onClick={() => handleSelect(ProductTypes.Monamodi)}>
          <PersonAddAltOutlinedIcon
            sx={{
              color: "#fff",
              marginTop: "30px",
            }}
          />
          <div> <h3>{ProductTypes.Monamodi}</h3></div>
          <p>covers six nominated children parents and parents-in-law and twelve extended family members</p>
        </div>
      </Grid>
      <Grid item xs={12} sm={4} md={4} >
        <div className={`${classes.productWrapper} ${productType === ProductTypes.FamilyClan ? 'selected' : ''}`} onClick={() => handleSelect(ProductTypes.FamilyClan)}>
          <Diversity1OutlinedIcon
            sx={{
              color: "#fff",
              marginTop: "30px",
            }}
          />
          <div><h3>{ProductTypes.FamilyClan}</h3></div>
          <p> Provides insurance cover
            to groups ranging from 10 to 30 people per group.</p>
        </div>
      </Grid>
      <Grid item xs={12} sm={4} md={4} >
        <div className={`${classes.productWrapper} ${productType === ProductTypes.Motshelo ? 'selected' : ''}`} onClick={() => handleSelect(ProductTypes.Motshelo)}>
          <Diversity3OutlinedIcon
            sx={{
              color: "#fff",
              marginTop: "30px",
            }}
          />
          <div><h3>{ProductTypes.Motshelo}</h3></div>
          <p> Group members receive equal coverage. Additionally, members can include their immediate family. </p>
        </div>
      </Grid>
    </Grid>
  )
}
