import * as types from '../../lib/actionTypes.es6';
import { take, call, put } from 'redux-saga/effects';
import { getClaimsByStatus } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

export function* claimStatusRequestWatcher() {
  for (;;) {
    const requestAction = yield take(types.CLAIM_STATUS_REQUEST);
    try {
      const response = yield call(getClaimsByStatus, requestAction.payload);
      yield put(actions.claimsStatusSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.claimsStatusFail(errorPayload));
    }
  }
}
