import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  payableClaimList: null,
  loader: false,
};

export default function payableClaimReducer(state = initialState, action) {
  switch (action.type) {
    case 'PAYABLE_CLAIM_REQUEST':
      return { ...initialState, loader: true };

    case 'PAYABLE_CLAIM_SUCCESS':
      return { ...state, payableClaimList: action.payload, loader: false };

    case 'PAYABLE_CLAIM_FAIL':
      return {
        ...state,
        loader: false,
        errors: action.payload,
        payableClaimList: null,
      };

    case types.USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
}
