import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import BoxInc from "../../../shared-ui/BoxInc/Index";
import ButtonInc from "../../../shared-ui/ButtonInc";
import { createDiagnosisRequest, deleteDiagnosisRequest, getDiagnosisRequest, resetCreateDiagnosisError, resetCreateDiagnosisSuccess, resetDiagnosisToDelete, setDiagnosisToDelete } from "../PreAuth/Redux/actions";
import DisplayDiagnosis from "./Components/DisplayDiagnosis/DisplayDiagnosis";
import CreateDiagnosis from "./Components/CreateDiagnosis/CreateDiagnosis";
import DeleteDiagnosis from "./Components/DeleteDiagnosis/DeleteDiagnosis";
class Diagnois extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddDiagnosisDialog: false,
    };
  }
  componentWillMount() {
    this.props.dispatch(getDiagnosisRequest());
  }
  toggleCreateDiagnosisDialog() {
    this.setState({ openAddDiagnosisDialog: !this.state.openAddDiagnosisDialog })
  }
  createDiagnosis(diagnosis) {
    this.props.dispatch(createDiagnosisRequest(diagnosis));
  }
  setDiagnosisToDelete(diagnosis) {
    this.props.dispatch(setDiagnosisToDelete(diagnosis));
    console.log("hospitaltodelete", diagnosis)
  }
  deleteDiagnosis(diagnosis) {
    this.props.dispatch(deleteDiagnosisRequest({ id: diagnosis._id }));

  }

  resetDiagnosisToDelete(diagnosis) {
    this.props.dispatch(resetDiagnosisToDelete(diagnosis))
  }

  render() {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <BoxInc
            expanded
              title=" Diagnosis"
              actionButtons={[<ButtonInc
                variant="contained"
                color="primary"
                size="large"
                onClick={this.toggleCreateDiagnosisDialog.bind(this)}
              >
                Create Diagnosis
              </ButtonInc>]}

            >
              <CreateDiagnosis
                open={this.state.openAddDiagnosisDialog}
                close={this.toggleCreateDiagnosisDialog.bind(this)}
                createDiagnosis={this.createDiagnosis.bind(this)}

                createDiagnosisError={this.props.LouData.createDiagnosisError}
                showCreateDiagnosisProgressAlert={this.props.LouData.createDiagnosisLoader}
                showCreateDiagnosisSuccessAlert={this.props.LouData.createDiagnosisSuccess}
                showCreateDiagnosisErrorAlert={!!this.props.LouData.createDiagnosisError}
                resetCreateDiagnosisSuccessAlert={() => { this.props.dispatch(resetCreateDiagnosisSuccess()) }}
                resetCreateDiagnosisErrorAlert={() => { this.props.dispatch(resetCreateDiagnosisError()) }}
              />
              <DisplayDiagnosis
                diagnosis={this.props.LouData.diagnosis}
                loader={this.props.LouData.getDiagnosisLoader}

                setDiagnosisToDelete={this.setDiagnosisToDelete.bind(this)}

              />
              <DeleteDiagnosis
                deleteDiagnosis={this.deleteDiagnosis.bind(this)}
                DiagnoisToDelete={this.props.LouData.DiagnosisToDelete}

                resetDiagnosisToDelete={this.resetDiagnosisToDelete.bind(this)}
                showDeleteDiagnosisProgressAlert={this.props.LouData.hardDeleteDiagnosisLoader}
                showDeleteDiagnosisSuccessAlert={this.props.LouData.hardDeleteDiagnosisSuccess}
                showDeleteDiagnosisErrorAlert={!!this.props.LouData.deleteDiagnosisError}
                deleteDiagnosisError={this.props.LouData.deleteDiagnosisError}
              />

            </BoxInc>
          </Grid>
        </Grid>
      </>
    )
  }

}
export default connect((state) => ({
  customerData: state.currentCustomer,
  LouData: state.LouData,
  globalData: state.global,
}))(Diagnois);