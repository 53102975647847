import React from 'react';
import { connect } from 'react-redux';
import * as globalActions from '../Main/actions.es6';
import * as actions from "./actions"
import ProductsHeader from '../NewProductList/components/ProductsHeader/ProductsHeader';
import AddBankDetails from '../../components/CustomerBankDetails/AddBankDetails';
import DisplayCustomerBankDetails from '../../components/CustomerBankDetails/DisplayCustomerBankDetails';
import DeleteBankDetails from '../../components/CustomerBankDetails/DeleteBankDetails';
import EditBankDetails from '../../components/CustomerBankDetails/EditBankDetails';

export class CustomerBankDetailsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddBankDetailsDialog: false,
      customer_guid: null
    }
  }
  componentDidMount() {
    const currentCustomer = this.props.customerData.currentCustomer;
      this.props.dispatch(actions.getCustomerBankDetailsRequest(currentCustomer.guid));
    this.props.dispatch(globalActions.showCustomerBar());
  }

  addBankDetails = (payload) => {
    this.props.dispatch(actions.addCustomerBankDetailsRequest(payload));
  }


  toggleEditBeneficiaryBankDetailsDialog() {
    this.setState({ openEditModal: !this.state.openEditModal });
  }
  closeEditBeneficiaryBankDetailsDialog() {
    this.setState({ openEditModal: false });
  }
  handleOpenConfirmDeleteDialog(beneficiary) {
    this.setState({ openConfirmDelete: false, customerGuid: beneficiary.guid });
  }
  setDeleteDialog(beneficiary) {
    this.setState({ openConfirmDelete: true, customer_guid: beneficiary.guid });
    this.handleCloseActionMenu();

  }
  deleteBeneficiaryBankDetails() {
    this.props.onDelete(this.state.customer_guid);
    this.setState({ openConfirmDelete: false });
  }

  toggleAddBankDetailsDialog(){ 
    this.setState({ openAddBankDetailsDialog: !this.state.openAddBankDetailsDialog }) 
  }

  deleteBankDetails(payload){
    this.props.dispatch(actions.deleteCustomerBankDetailsRequest(payload));
  }

  editBankDetails(payload){
    this.props.dispatch(actions.editCustomerBankDetailsRequest(payload));
  }


  render() {
    const bankdetail = this.props.customerbankDetailsData.bankdetail
    return (
      <>
        <div style={{marginTop: "50px"}}>

    
        <ProductsHeader
          title="Customer Bank Details"
          onAddButtonClick={ this.toggleAddBankDetailsDialog.bind(this) }
          showAddBankDetailsDialog={this.state.openAddBankDetailsDialog}
          showAddButton={this.props.customerbankDetailsData.bankdetail===null || this.props.customerbankDetailsData.bankdetail===''}
       
        />

        <DisplayCustomerBankDetails
          customerbankdetail={bankdetail}
          customerGuid={this.props.customerGuid}
          labels={this.state.labels}

          setBankDetailsToDelete={(bankdetails)=> this.props.dispatch(actions.setCustomerBankDetailsToDelete(bankdetails))}
          setBankDetailsToEdit={(bankdetails)=> this.props.dispatch(actions.setCustomerBankDetailsToEdit(bankdetails))}
          
          displayEditButton={true}
          displayDeleteButton={true}
          loading={this.props.customerbankDetailsData.loader}
        />

        <AddBankDetails
          openAddBankDetailsDialog={this.state.openAddBankDetailsDialog}
          closeAddBeneficiaryBankDetailsDialog={this.toggleAddBankDetailsDialog.bind(this)}
          customerGuid={this.props.customerData.currentCustomer.guid }
          addBankDetails={this.addBankDetails.bind(this)}


          showAddBankDetailsProgressAlert={this.props.customerbankDetailsData.createbankdetailLoader}
          showAddBankDetailsSuccessAlert={this.props.customerbankDetailsData.createbankdetailSuccess}
          showAddBankDetailsErrorAlert={!!this.props.customerbankDetailsData.createbankdetailError}
          error={this.props.customerbankDetailsData.createbankdetailError}

          resetAddBankDetailsSuccessAlert={() => { this.props.dispatch(actions.resetAddCustomerCustomerBankDetailsSuccess()) }}
          resetAddBankDetailsErrorAlert={() => { this.props.dispatch(actions.resetAddCustomerCustomerBankDetailsFailed()) }}
        />

        <DeleteBankDetails
          bankDetails={this.props.customerbankDetailsData.bankDetailsToDelete}
          deleteBankDetails={this.deleteBankDetails.bind(this)}
          resetBankDetailsToDelete={()=> this.props.dispatch(actions.resetCustomerBankDetailsToDelete())}

          showDeleteBankDetailsProgressAlert={this.props.customerbankDetailsData.deleteBankDetailLoader}
          showDeleteBankDetailsSuccessAlert={this.props.customerbankDetailsData.deleteBankDetailSuccess}
          showDeleteBankDetailsErrorAlert={!!this.props.customerbankDetailsData.deleteBankDetailError}
          deleteBankDetailsError={this.props.customerbankDetailsData.deleteBankDetailError}

          resetDeleteBankDetailsSuccessAlert={() => { this.props.dispatch(actions.resetDeleteCustomerBankDetailsSuccess()) }}
          resetDeleteBankDetailsErrorAlert={() => { this.props.dispatch(actions.resetDeleteCustomerBankDetailsFailed()) }}
        />

        <EditBankDetails
          bankDetails={this.props.customerbankDetailsData.bankDetailToEdit}
          editBankDetails={this.editBankDetails.bind(this)}
          resetBankDetailsToEdit={() => { this.props.dispatch(actions.resetCustomerBankDetailsToEdit()) }}

          showEditBankDetailsProgressAlert={this.props.customerbankDetailsData.editBankDetailLoader}
          showEditBankDetailsSuccessAlert={this.props.customerbankDetailsData.editBankDetailSuccess}
          showEditBankDetailsErrorAlert={!!this.props.customerbankDetailsData.editBankDetailError}
          editBankDetailsError={this.props.customerbankDetailsData.editBankDetailError}

          resetEditBankDetailsSuccessAlert={() => { this.props.dispatch(actions.resetEditCustomerBankDetailsSuccess()) }}
          resetEditBankDetailsErrorAlert={() => { this.props.dispatch(actions.resetEditCustomerBankDEtailsFailed()) }}

        />
    </div>
      </>
    );
  }
}

export default connect(state => ({
  customerData: state.currentCustomer,
  customerbankDetailsData: state.customerbankdetails,
}))(
  CustomerBankDetailsList
);
