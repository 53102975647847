import React from "react";
import { connect } from "react-redux";
import { getAccessBankCustomerClaimsRequest } from "./redux/actions";
import { getAllPoliciesRequest } from "../../Redux/actions";
import Claims from "./Components/Claims/Claims";
class AccessBankClaims extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentWillMount() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      this.props.dispatch(getAccessBankCustomerClaimsRequest({ guid: currentCustomer.guid }));
      this.props.dispatch(getAllPoliciesRequest({ guid: currentCustomer.guid }))
    }
  }



  render() {

    return (
      <>
       <Claims
       claims={this.props.AccessBankClaimsData.claims}
       loader={this.props.AccessBankClaimsData.get_claims_loader}
       />
      </>
    )


  }
}

export default connect((state) => ({
  customerData: state.currentCustomer,
  customerScreenData: state.customerScreen,
  AccessBankClaimsData: state.AccessBankClaimsData
}))(AccessBankClaims);