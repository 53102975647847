import React from "react";
import LocalizedStrings from 'react-localization';
import { hashHistory } from 'react-router';
import { connect } from "react-redux";
import ClaimHeader from './Components/ClaimHeader/ClaimHeader'
import ClaimDetails from './Components/ClaimDetails/ClaimDetails'
import ClaimDocuments from './Components/ClaimDocuments/ClaimDocuments'
import ClaimActionButtons from './Components/ClaimActionButtons'
import * as actions from "./Redux/actions"
import BoxInc from "../../../shared-ui/BoxInc/Index";
import ButtonInc from '../../../shared-ui/ButtonInc';
import AddClaimNotes from "./Components/ClaimDetails/AddClaimNotes";
import AddClaimDocuments from "./Components/ClaimDocuments/AddClaimDocuments";
import DeleteClaimDocument from "./Components/ClaimDocuments/DeleteClaimDocument";
import AddClaimFacilityNotes from "./Components/ClaimDetails/AddClaimFacilityNotes";
import { hasAuthourity } from "../../../lib/access.es6";
import { ClaimTypeEnum } from "../lib/enum";
import DownloadDocument from "../../../components/DownloadDocument/DownloadDocument";
import { localisedText } from "../../../lib/localisation.es6";
import * as documentActions from '../../../containers/DocumentGeneration/actions.es6';

import ClaimExpenses from './Components/ClaimExpenses/ClaimExpenses'
import AddClaimExpense from "./Components/ClaimExpenses/AddClaimExpense";
import DeleteClaimExpense from "./Components/ClaimExpenses/DeleteClaimExpense";
import find from 'lodash/find';


class ClaimsProcessing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labels: new LocalizedStrings(localisedText),
      openClaimNotesDialog: false,
      openClaimFacilityNotesDialog: false,
      openClaimDocumentsDialog: false,
      openClaimExpenseDialog: false,
      openEditClaimExpenseDialog: false,
      description: "",
      expanded: false,
      missingDocs: false,
      claimToDownload: null,
      policyToDownload: null,
      scrollPos: 0,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }


  componentWillMount() {
    let claim_guid = this.props.params.claim_guid
    this.props.dispatch(actions.getClaimProcessingDcpRequest({
      guid: claim_guid,
    }));

  }

  componentDidMount() {
    const savedScrollPos = sessionStorage.getItem('scrollPos');
    if (savedScrollPos) {
      try {
        window.scrollTo(0, parseInt(savedScrollPos));
      } catch (error) {
        console.error("Error scrolling:", error);
      }
    }

    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    this.setState({ scrollPos: window.pageYOffset });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.scrollPos !== this.state.scrollPos) {
      sessionStorage.setItem('scrollPos', this.state.scrollPos);
    }
  }


  toggleClaimNotesDialog() {
    this.setState({ openClaimNotesDialog: !this.state.openClaimNotesDialog })
  }

  toggleClaimFacilityNotesDialog() {
    this.setState({ openClaimFacilityNotesDialog: !this.state.openClaimFacilityNotesDialog })
  }

  toggleClaimDocumentsDialog() {
    this.setState({ openClaimDocumentsDialog: !this.state.openClaimDocumentsDialog })
  }

  toggleClaimExpenseDialog() {
    this.setState({ openClaimExpenseDialog: !this.state.openClaimExpenseDialog, expanded: true })

  }

  toggleEditClaimExpenseDialog() {
    this.setState({ openEditClaimExpenseDialog: !this.state.openEditClaimExpenseDialog })
  }

  handleClaimExpense(payload) {
    this.props.dispatch(actions.createExpenseRequest(payload))
  }

  setClaimExpenseToDelete(expense) {
    this.props.dispatch(actions.setExpenseToDelete(expense));
  }
  deleteClaimExpense(expense) {
    this.props.dispatch(actions.deleteExpenseRequest({
      claim_guid: this.props.claimProcessingv2Data.claimsProcessingDcp.guid,
      expense_guid: expense.guid
    }));
  }
  resetExpenseToDelete(expense) {
    this.props.dispatch(actions.resetExpenseToDelete(expense))
  }

  setClaimExpenseToEdit(expense) {
    console.log("set claim to edit", expense)
    this.props.dispatch(actions.setExpenseToEdit(expense));
  }

  editClaimExpense(payload) {
    this.props.dispatch(actions.expenseEditRequest(
      payload
    ));
  }
  resetExpenseToEdit(expense) {
    this.props.dispatch(actions.resetExpenseEdit(expense))
  }
  handleClaimNotes(payload) {
    this.props.dispatch(actions.claimNotesRequest(
      payload));
  }

  handleClaimFacilityNotes(payload) {
    this.props.dispatch(actions.claimFacilityNotesRequest(
      payload));
  }

  deleteClaimDocument(document) {
    this.props.dispatch(actions.deleteclaimDocumentsRequest({
      claim_guid: this.props.claimProcessingv2Data.claimsProcessingDcp.guid,
      claim_doc_guid: document.guid
    }));
  }
  setClaimDocumentToDelete(document) {
    this.props.dispatch(actions.setDocumentsToDelete(document));
  }

  resetDocumentToDelete(document) {
    this.props.dispatch(actions.resetDocumentToDelete(document))
  }
  getPreauthDetails(guid) {
    this.props.dispatch(actions.getPreauthDetailsRequest(guid))
  }

  viewPolicyDetail(policyNumber) {
    if (policyNumber) {
      hashHistory.push(`admin/policy-processing/${policyNumber}`)
    } else {
      return
    }
  }

  isDeathClaim(claim) {
    return true

  }

  isReimbusermentClaim(claim) {
    return false

  }

  isHospitalClaim(claim) {
    return false
  }
  getClaimType(claim) {
    if (!claim) return ""
    if (this.isDeathClaim(claim)) return ClaimTypeEnum.death
    if (this.isReimbusermentClaim(claim)) return ClaimTypeEnum.reimbursement
    if (this.isHospitalClaim(claim)) return ClaimTypeEnum.hospital
    return ""
  }
  render() {
    const type = this.getClaimType(this.props.claimProcessingv2Data.claimsProcessingDcp);
    const benefits = this.props.claimProcessingv2Data.claimsProcessingDcp ? this.props.claimProcessingv2Data.claimsProcessingDcp.benefits : []
    const deathBenefit = find(benefits, {type: "FUNERAL BENEFIT"})

    if (!hasAuthourity('VIEW_CLAIM')) {
      return <p>You are unauthorized to view claim.</p>
    }

    if (this.props.claimProcessingv2Data.get_claimsProcessingDcp_loader) {
      return <>
        <p>loading....</p>
      </>
    }
    if (!this.props.claimProcessingv2Data.claimsProcessingDcp) {
      return <>
        <p>nothing found </p>
      </>
    }
    const templateDocuments = this.props.documentGenerationData.templateDocuments;
    return (
      <>
        <ClaimHeader
          claimData={this.props.claimProcessingv2Data.claimsProcessingDcp}

        />
        <BoxInc
          title="Claims Details"
          expanded
          style={{ marginTop: "40px" }}
          hasPermision={hasAuthourity('ACCESSBANK_FUNERAL_CLAIM_RECEIVERS')}
          actionButtons={[<ButtonInc
            variant="contained"
            color="primary"
            size="large"
            onClick={() => this.setState({
              claimToDownload: this.props.claimProcessingv2Data.claimsProcessingDcp
            })}
          >
            Download Claim Document

          </ButtonInc>,
          <ButtonInc
            variant="contained"
            color="primary"
            size="large"
            onClick={() => this.viewPolicyDetail(this.props.claimProcessingv2Data.claimsProcessingDcp.claim_details.policy_number)}
          >
            View Policy

          </ButtonInc>

          ]

          }
        >

          <ClaimDetails
            claim={this.props.claimProcessingv2Data.claimsProcessingDcp}
            toggleClaimNotesDialog={this.toggleClaimNotesDialog.bind(this)}
            openClaimNotesDialog={this.state.openClaimNotesDialog}
            getPreauthDetails={this.getPreauthDetails.bind(this)}
            preauthDeatils={this.props.claimProcessingv2Data.preauthDetails}
            isDeathClaim={this.isDeathClaim.bind(this)}
            isReimbusermentClaim={this.isReimbusermentClaim.bind(this)}
          />
          <AddClaimFacilityNotes
            toggleClaimFacilityNotesDialog={this.toggleClaimFacilityNotesDialog.bind(this)}
            openClaimFacilityNotesDialog={this.state.openClaimFacilityNotesDialog}

            handleClaimFacilityNotes={this.handleClaimFacilityNotes.bind(this)}
            claimguid={this.props.claimProcessingv2Data.claimsProcessingDcp.guid}

            showCreateFacilityNoteProgressAlert={this.props.claimProcessingv2Data.ClaimFacilityNotesLoader}
            showCreateFacilityNotesSuccessAlert={this.props.claimProcessingv2Data.ClaimFacilityNotesSuccess}
            showCreateFacilityNotesErrorAlert={!!this.props.claimProcessingv2Data.ClaimFacilityNotesError}
            CreateFacilityNotesError={this.props.claimProcessingv2Data.ClaimFacilityNotesError}
            resetCreateFacilityNotesSuccessAlert={() => { this.props.dispatch(actions.resetFacilityClaimNotesSuccess()) }}
            resetCreateFacilityNotesErrorAlert={() => { this.props.dispatch(actions.resetCreateExpenseError()) }}
          />
          <AddClaimNotes

            toggleClaimNotesDialog={this.toggleClaimNotesDialog.bind(this)}
            openClaimNotesDialog={this.state.openClaimNotesDialog}

            handleClaimNotes={this.handleClaimNotes.bind(this)}
            claimguid={this.props.claimProcessingv2Data.claimsProcessingDcp.guid}

            showCreateNoteProgressAlert={this.props.claimProcessingv2Data.ClaimNotesLoader}
            showCreateNotesSuccessAlert={this.props.claimProcessingv2Data.ClaimNotesSuccess}
            showCreateNotesErrorAlert={!!this.props.claimProcessingv2Data.ClaimNotesError}
            CreateNotesError={this.props.claimProcessingv2Data.ClaimNotesError}
            resetCreateNotesSuccessAlert={() => { this.props.dispatch(actions.resetClaimActionSuccess()) }}
            resetCreateNotesErrorAlert={() => { this.props.dispatch(actions.resetFacilityClaimNotesError()) }}
          />


        </BoxInc>
        <BoxInc
          title="Claims Documents "
          expanded={true}
          style={{ marginTop: "30px" }}
          actionButtons={[<ButtonInc
            variant="contained"
            color="primary"
            size="large"
            onClick={() => this.setState({
              policyToDownload: {
                guid: this.props.claimProcessingv2Data.claimsProcessingDcp.policy_guid
              }
            })}
          >
            Download Policy Document

          </ButtonInc>]}
        >
          <AddClaimDocuments
            description={this.state.description}
            toggleClaimDocumentsDialog={this.toggleClaimDocumentsDialog.bind(this)}
            openClaimDocumentsDialog={this.state.openClaimDocumentsDialog}
            claim_guid={this.props.claimProcessingv2Data.claimsProcessingDcp.guid}
            saveClaimDocument={(doc) => this.props.dispatch(actions.saveClaimDcpDocumentRequest(doc))}
            error={this.props.claimProcessingv2Data.saveClaimDcpDocumentError}

          />
          <ClaimDocuments
            setDescription={(description) => this.setState({ description })}
            claimDocuments={this.props.claimProcessingv2Data.claimsProcessingDcp.claim_documents}
            setClaimDocumentToDelete={this.setClaimDocumentToDelete.bind(this)}
            toggleMissingDocs={() => this.setState({ missingDocs: true })}
            missingDocs={this.state.missingDocs}
            openUploadDialog={this.toggleClaimDocumentsDialog.bind(this)}
            type={type}
          />
          <DeleteClaimDocument
            DocumentToDelete={this.props.claimProcessingv2Data.documentsToDelete}
            deleteClaimDocument={this.deleteClaimDocument.bind(this)}


            resetDocumentToDelete={this.resetDocumentToDelete.bind(this)}
            showDeleteDocumentProgressAlert={this.props.claimProcessingv2Data.hardDeleteExpenseLoader}
            showDeleteDocumentSuccessAlert={this.props.claimProcessingv2Data.hardDeleteDocumentSuccess}
            showDeleteDocumentErrorAlert={!!this.props.claimProcessingv2Data.deleteClaimDocumentError}
            deleteDocumentError={this.props.claimProcessingv2Data.deleteClaimDocumentError}

            resetDeleteDocumentSuccessAlert={() => {
              this.props.dispatch(actions.resetHardDeleteDocumentSuccess())
            }}
            resetDeleteDocumentErrorAlert={() => { this.props.dispatch(actions.resetHardDeleteDocumentError()) }}

          />
        </BoxInc>


        <BoxInc
          title={type === ClaimTypeEnum.death ? "Claim Benefits " : " Claim Invoices "} 
          expanded={this.state.expanded}
          style={{ marginTop: "30px" }}
          actionButtons={[<ButtonInc
            variant="contained"
            color="primary"
            size="large"
            onClick={this.toggleClaimExpenseDialog.bind(this)}
            hasPermision={hasAuthourity('UPDATE_CLAIM')}
          >
            {
              type === ClaimTypeEnum.death ? "Benefit Amount" : " Add  invoice details"
            }
           
          </ButtonInc>]}
        >
          <ClaimExpenses
            claimExpense={this.props.claimProcessingv2Data.claimsProcessingDcp}
            setClaimExpenseToDelete={this.setClaimExpenseToDelete.bind(this)}
            setClaimExpenseToEdit={this.setClaimExpenseToEdit.bind(this)}

            toggleEditClaimExpenseDialog={this.toggleEditClaimExpenseDialog.bind(this)}
            openEditClaimExpenseDialog={this.state.openEditClaimExpenseDialog}
            type={type}
            hasDeathBenefit={true}
            deathBenefit={deathBenefit}
          />

          <AddClaimExpense
            toggleClaimExpenseDialog={this.toggleClaimExpenseDialog.bind(this)}
            openClaimExpenseDialog={this.state.openClaimExpenseDialog}
            claim={this.props.claimProcessingv2Data.claimsProcessingDcp}
            handleClaimExpense={this.handleClaimExpense.bind(this)}

            showCreateExpenseProgressAlert={this.props.claimProcessingv2Data.createdClaimExpenseLoader}
            showCreateExpenseSuccessAlert={this.props.claimProcessingv2Data.createdClaimExpenseSuccess}
            showCreateExpenseErrorAlert={!!this.props.claimProcessingv2Data.createdClaimExpenseError}
            CreateExpenseError={this.props.claimProcessingv2Data.createdClaimExpenseError}
            resetCreateExpenseSuccessAlert={() => { this.props.dispatch(actions.resetCreateExpenseSuccess()) }}
            resetCreateExpenseErrorAlert={() => { this.props.dispatch(actions.resetCreateExpenseError()) }}
            type={type}
          />
          <DeleteClaimExpense
            expenseToDelete={this.props.claimProcessingv2Data.expenseToDelete}
            deleteClaimExpense={this.deleteClaimExpense.bind(this)}

            resetExpenseToDelete={this.resetExpenseToDelete.bind(this)}
            showDeleteExpenseProgressAlert={this.props.claimProcessingv2Data.hardDeleteExpenseLoader}
            showDeleteExpenseSuccessAlert={this.props.claimProcessingv2Data.hardDeleteExpenseSuccess}
            showDeleteExpenseErrorAlert={!!this.props.claimProcessingv2Data.deleteExpenseErrors}
            deleteExpenseError={this.props.claimProcessingv2Data.deleteExpenseErrors}

            resetDeleteExpenseSuccessAlert={() => {
              this.props.dispatch(actions.resetHardDeleteExpenseSuccess())
            }}
            resetDeleteExpenseErrorAlert={() => { this.props.dispatch(actions.resetHardDeleteExpenseError()) }}
          />

        </BoxInc>



        <ClaimActionButtons
          claim={this.props.claimProcessingv2Data.claimsProcessingDcp}
          submitClaimAction={(claimActionPayload) => this.props.dispatch(actions.claimActionRequest(claimActionPayload))}
          loading={this.props.claimProcessingv2Data.ClaimActionLoader}
          error={this.props.claimProcessingv2Data.ClaimActionError}
          missingDocs={this.state.missingDocs}
        />


        <DownloadDocument
          labels={this.state.labels}
          identifier="Claims"
          entityDocument={
            this.props.documentGenerationData.entityDocument
          }
          entity={this.state.claimToDownload}
          resetEntity={() => this.setState({ claimToDownload: null })}
          templates={templateDocuments}
          getTemplates={() => this.props.dispatch(documentActions.getTemplateDocumentsRequest('Claim'))}
          getTemplatesLoader={this.props.documentGenerationData.getTemplateDocumentloader}
          isEmptyTemplatesResponse={this.props.documentGenerationData.isEmptyTemplates}
          downloadDocument={guid => {
            this.props.dispatch(
              documentActions.downloadDocumentRequest({
                entity_guid: this.state.claimToDownload.guid,
                template_guid: guid,
              })
            );
          }}
          resetGetTemplateDocuments={() => {
            this.props.dispatch(documentActions.resetGetTemplateDocuments());
          }}
          resetDownloadDocument={() => {
            this.props.dispatch(documentActions.resetDocumentEntity());
          }}
          showDownloadDocumentProgressAlert={
            this.props.documentGenerationData.downloadDocumentLoader
          }
          showDownloadDocumentSuccessAlert={
            this.props.documentGenerationData.downloadDocumentSuccess
          }
          showDownloadDocumentErrorAlert={
            !!this.props.documentGenerationData.downloadDocumentError
          }
          downloadDocumentError={
            this.props.documentGenerationData.downloadDocumentError
          }
          resetDownloadDocumentSuccessAlert={() => {
            this.props.dispatch(
              documentActions.resetDownloadDocumentSuccess()
            );
          }}
          resetDownloadDocumentErrorAlert={() => {
            this.props.dispatch(
              documentActions.resetDownloadDocumentError()
            );
          }}

          goToTemplatesPage={() => {
            hashHistory.push('admin/view_doc_templates');
          }}
        />
        <DownloadDocument
          labels={this.state.labels}
          identifier="Policies"
          entityDocument={
            this.props.documentGenerationData.entityDocument
          }
          entity={this.state.policyToDownload}
          resetEntity={() => this.setState({ policyToDownload: null })}
          templates={templateDocuments}
          getTemplates={() => this.props.dispatch(documentActions.getTemplateDocumentsRequest('Policy'))}
          getTemplatesLoader={this.props.documentGenerationData.getTemplateDocumentloader}
          isEmptyTemplatesResponse={this.props.documentGenerationData.isEmptyTemplates}
          downloadDocument={guid => {
            this.props.dispatch(
              documentActions.downloadDocumentRequest({
                entity_guid: this.props.claimProcessingv2Data.claimsProcessingDcp.policy_guid,
                template_guid: guid,
              })
            );
          }}
          resetGetTemplateDocuments={() => {
            this.props.dispatch(documentActions.resetGetTemplateDocuments());
          }}
          resetDownloadDocument={() => {
            this.props.dispatch(documentActions.resetDocumentEntity());
          }}
          showDownloadDocumentProgressAlert={
            this.props.documentGenerationData.downloadDocumentLoader
          }
          showDownloadDocumentSuccessAlert={
            this.props.documentGenerationData.downloadDocumentSuccess
          }
          showDownloadDocumentErrorAlert={
            !!this.props.documentGenerationData.downloadDocumentError
          }
          downloadDocumentError={
            this.props.documentGenerationData.downloadDocumentError
          }
          resetDownloadDocumentSuccessAlert={() => {
            this.props.dispatch(
              documentActions.resetDownloadDocumentSuccess()
            );
          }}
          resetDownloadDocumentErrorAlert={() => {
            this.props.dispatch(
              documentActions.resetDownloadDocumentError()
            );
          }}

          goToTemplatesPage={() => {
            hashHistory.push('admin/view_doc_templates');
          }}
        />
      </>
    )
  }

}
export default connect((state) => ({
  documentGenerationData: state.documentGeneration,
  customerData: state.currentCustomer,
  claimProcessingv2Data: state.claimProcessingv2Data,
  PolicyProcessingData: state.PolicyProcessingData,
  claimDashboardData: state.claimDashboardData,
  globalData: state.global,
}))(ClaimsProcessing);