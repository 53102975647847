import { take, call, put } from 'redux-saga/effects';
import * as types from '../../lib/actionTypes.es6';
import {
  getPartnerConfigs,
  getPartnerConfigsGroup,
  getPartnerConfigsClaim,
  
  updatePartner,
  updatePartnerConfig,
  updatePartnerConfigGroup,
  updatePartnerConfigClaim
} from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';
import { getAllPartnersRequest } from '../Partners/actions.es6';


export function* getPartnerConfigsRequestWatcher() {
  for (; ;) {
    const request = yield take(types.GET_PARTNER_CONFIGS_REQUEST);
    try {
      const response = yield call(getPartnerConfigs, request.payload);
      yield put(actions.getPartnerConfigsSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getPartnerConfigsFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* getPartnerConfigsClaimRequestWatcher() {
  for (; ;) {
    const request = yield take(types.GET_PARTNER_CONFIGS_CLAIM_REQUEST);
    try {
      const response = yield call(getPartnerConfigsClaim, request.payload);
      yield put(actions.getPartnerConfigsClaimSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getPartnerConfigsClaimFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* getPartnerConfigsGroupRequestWatcher() {
  for (; ;) {
    const request = yield take(types.GET_PARTNER_CONFIGS_GROUP_REQUEST);
    try {
      const response = yield call(getPartnerConfigsGroup, request.payload);
      yield put(actions.getPartnerConfigsGroupSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getPartnerConfigsGroupFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* updatePartnerRequestWatcher() {
  for (; ;) {
    const request = yield take(types.UPDATE_PARTNER_REQUEST);
    console.log(request);

    try {
      const response = yield call(updatePartner, request.payload);
      yield put(actions.updatePartnerSuccess(response.data));
      yield put(getAllPartnersRequest());
      // start of test
      // yield fakeDelayToIndicateProgress()
      // yield put(actions.updatePartnerSuccess('done'));
      // yield put(actions.updatePartnerFailure('errorMessage'))
      // end of test
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.updatePartnerFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* updatePartnerConfigRequestWatcher() {
  for (; ;) {
    const request = yield take(types.UPDATE_PARTNER_CONFIG_REQUEST);
    try {
      const response = yield call(updatePartnerConfig, request.payload);
      yield put(actions.updatePartnerConfigSuccess(response.data));
      // start of test
      // yield fakeDelayToIndicateProgress()
      // yield put(actions.updatePartnerConfigSuccess('done'));
      // yield put(actions.updatePartnerConfigFailure('errorMessage'));
      // end of test
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.updatePartnerConfigFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* updatePartnerConfigClaimRequestWatcher() {
  for (; ;) {
    const request = yield take(types.UPDATE_PARTNER_CONFIG_CLAIM_REQUEST);
    try {
      const response = yield call(updatePartnerConfigClaim, request.payload);
      yield put(actions.updatePartnerConfigClaimSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.updatePartnerConfigClaimFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* updatePartnerConfigGroupRequestWatcher() {
  for (; ;) {
    const request = yield take(types.UPDATE_PARTNER_CONFIG_GROUP_REQUEST);
    try {
      const response = yield call(updatePartnerConfigGroup, request.payload);
      yield put(actions.updatePartnerConfigGroupSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.updatePartnerConfigGroupFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}



// const delay = ms => new Promise(res => setTimeout(res, ms));
// const fakeDelayToIndicateProgress = async () => {
//   await delay(2000);
//   console.log("Waited 2s");
// };