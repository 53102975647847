import * as types from '../../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  loader: false,

  membersError: null,
  members: [],
  membersLoadedSuccess: false,

  addedPrincipal: null,
  addedPrincipalError: null,

  editedPrincipal: null,
  editedPrincipalError: null,

  addedDependant: null,
  addedDependantError: null,

  editedDependant: null,
  editedDependantError: null,

  deletedPrincipal: null,
  deletedPrincipalError: null,

  deletedDependant: null,
  deletedDependantError: null,

  uploadExcel: null,
  uploadExcelError: null,
  uploadExcelLoader: false,
};

export default function addEditMembersReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ALL_TEMP_MEMBERS_REQUEST:
      return { ...state, loader: true, membersError: null };

    case types.GET_ALL_TEMP_MEMBERS_SUCCESS:
      return { ...state, loader: false, members: action.payload };

    case types.GET_ALL_TEMP_MEMBERS_FAILURE:
      return { ...state, loader: false, membersError: action.payload };

    case types.UPLOAD_EXCEL_MEMBERS_REQUEST:
      return { ...state, uploadExcelLoader: true, uploadExcelError: null };

    case types.UPLOAD_EXCEL_MEMBERS_SUCCESS:
      return { ...state, uploadExcelLoader: false, uploadExcel: action.payload };

    case types.UPLOAD_EXCEL_MEMBERS_FAILURE:
      return { ...state, uploadExcelLoader: false, uploadExcelError: action.payload };


    case types.ADD_PRINCIPAL_REQUEST:
      return { ...state, loader: true, addedPrincipalError: null };

    case types.ADD_PRINCIPAL_SUCCESS:
      return { ...state, loader: false, addedPrincipal: action.payload };

    case types.ADD_PRINCIPAL_FAILURE:
      return { ...state, loader: false, addedPrincipalError: action.payload };


    case types.EDIT_PRINCIPAL_REQUEST:
      return { ...state, loader: true, editedPrincipalError: null };

    case types.EDIT_PRINCIPAL_SUCCESS:
      return { ...state, loader: false, editedPrincipal: action.payload };

    case types.EDIT_PRINCIPAL_FAILURE:
      return { ...state, loader: false, editedPrincipalError: action.payload };

    // add/edit dependant
    case types.ADD_DEPENDANT_REQUEST:
      return { ...state, loader: true, addedDependantError: null };

    case types.ADD_DEPENDANT_SUCCESS:
      return { ...state, loader: false, addedDependant: action.payload };

    case types.ADD_DEPENDANT_FAILURE:
      return { ...state, loader: false, addedDependantError: action.payload };


    case types.EDIT_DEPENDANT_REQUEST:
      return { ...state, loader: true, editedDependantError: null };

    case types.EDIT_DEPENDANT_SUCCESS:
      return { ...state, loader: false, editedDependant: action.payload };

    case types.EDIT_DEPENDANT_FAILURE:
      return { ...state, loader: false, editedDependantError: action.payload };

    // delete dependant/principal
    case types.DELETE_DEPENDANT_REQUEST:
      return { ...state, loader: true, deletedDependantError: null };

    case types.DELETE_DEPENDANT_SUCCESS:
      return { ...state, loader: false, deletedDependant: action.payload };

    case types.DELETE_DEPENDANT_FAILURE:
      return { ...state, loader: false, deletedDependantError: action.payload };


    case types.DELETE_PRINCIPAL_REQUEST:
      return { ...state, loader: true, deletedPrincipalError: null };

    case types.DELETE_PRINCIPAL_SUCCESS:
      return { ...state, loader: false, deletedPrincipal: action.payload };

    case types.DELETE_PRINCIPAL_FAILURE:
      return { ...state, loader: false, deletedPrincipalError: action.payload };

    case types.RESET_EXCEL_FILE_UPLOAD:
      return { ...state, uploadExcel: null };

    case types.RESET_ADD_EDIT_MEMBERS:
      return { ...state, addedPrincipalError: null, addedDependantError: null, editedDependantError: null, editedPrincipalError: null };
    default:
      return state;
  }
}