import React, { useState } from 'react'
import ModalInc from '../../../shared-ui/ModalInc'
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { useStyle } from "./Style";
import ButtonInc from '../../../shared-ui/ButtonInc';

import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import AlertDialog from '../../../components/AlertDialog/index.es6';
import { reloadPage } from '../../../lib/utils.es6';

const EditReport = (props) => {
  if (!props.report) {
    return ""
  }

  const classes = useStyle();

  const [reportType, setType] = useState(props.report.title);
  const [query, setQuery] = useState(props.report.query);
  const [fields, setFields] = useState(props.report.fields);

  const handleChange = (event) => {
    const { name, value } = event.target
    if (name === "reportType") {
      setType(value)
    }
    if (name === "query") {
      console.log("query", value);
      setQuery(value)
    }
  }


  const addField = () => {
    setFields([...fields, { name: '', type: '', display_name: '', options: '' }]);
  };

  const updateField = (index, key, value) => {
    const newFields = [...fields];
    newFields[index][key] = value;
    setFields(newFields);
  };

  const removeField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  const EditTemplate = () => {

    const payload = {
      "id": props.report.id,
      "title": reportType,
      "query": query,
      "fields": fields.map(field => ({ ...field, options: field.options && field.options.length ? field.options.split(',') : undefined })),
    }
    setFields([])
    console.log("editPayload", payload)
    props.editReportTemplate(payload)

  }

  const handleRefresh = () => {
    reloadPage()
  }

  const fieldTypes = [
    'date', 'number', 'text', 'drop-down', 'boolean', 'USER_AGENTS', 'PRODUCTS', 'HOSPITALS'
  ]
  return (
    <>

      <AlertDialog
        custom
        show={props.showEditReportTemplateProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText="ok"
        showCancel={false}
        showConfirm={false}
        cancelBtnText="cancel"
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Editting report template record... </h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showEditReportTemplateSuccessAlert}
        size="sm"
        title="Report template  edited"
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => {
          handleRefresh();
        }}
        confirmBtnText="ok"
        showCancel={false}
        showConfirm
        cancelBtnText="cancel"
        confirmBtnCssClass
      ></AlertDialog>

      <AlertDialog
        show={!!props.showEditReportTemplateErrorAlert}
        danger
        title="Error editting report template"
        onConfirm={() => handleRefresh()}
        confirmBtnText="ok"
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.error
          ? props.error.message
          : ''}
      </AlertDialog>
      <ModalInc
        modalTitle="Lets Edit A Report Template"
        subTitle="Fill the below fields to edit a report template"
        open={props.open}
        onClose={props.close}
        fullWidth
        maxWidth="md"
      >
        <ValidatorForm onSubmit={() => { }} >

          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <TextValidator
                  name='reportType'
                  id="reportType"
                  label="Report Type"
                  value={reportType}
                  onChange={handleChange}
                  fullWidth
                  className={classes.paymentinputs}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please enter report type']}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextValidator
                  name='query'
                  id="query"
                  label="query "
                  multiline
                  rows={8}
                  maxRows={12}
                  value={query}
                  onChange={handleChange}
                  fullWidth
                  className={classes.paymentinputs}
                  variant="outlined"
                  //validators={['required']}
                  //errorMessages={['Please enter query']}
                  editorProps={{ $blockScrolling: true }}
                  mode="mysql"
                  theme="monokai"
                  fontSize={14}
                  lineHeight={19}
                  showPrintMargin={true}
                  showGutter={true}
                  highlightActiveLine={true}
                  setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 2,
                  }}
                  style={{
                    width: "100%"
                  }}
                />
              </Grid>
              {fields.map((field, index) => (

                <Grid key={index} container item spacing={2} >
                  <Grid item xs={12} sm={4} md={4}>
                    <TextValidator
                      label="Name"
                      value={field.name}
                      onChange={(e) => updateField(index, 'name', e.target.value)}
                      fullWidth
                      //className={classes.paymentinputs}
                      variant="outlined"
                    //style={{ marginBottom: "10px" }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <SelectValidator
                      label="Type"
                      value={field.type}
                      onChange={(e) => updateField(index, 'type', e.target.value)}
                      variant="outlined"
                      validators={['required']}
                      errorMessages={['Please select  type']}
                      style={{ marginBottom: "10px", width: "100%" }}

                    >
                      {fieldTypes.map((type) => (
                        <MenuItem sx={{
                          fontFamily: "Open Sans",
                          fontStyle: "normal",
                          fontWeight: 300,
                          fontSize: "12px",
                          lineHeight: "16px",
                          letterSpacing: "0.005em",
                          color: "#000000",
                        }} value={type}>{type}</MenuItem>
                      ))}
                    </SelectValidator>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextValidator
                      label="Display Name"
                      value={field.display_name}
                      onChange={(e) => updateField(index, 'display_name', e.target.value)}
                      fullWidth
                      className={classes.paymentinputs}
                      variant="outlined"
                      style={{ marginBottom: "10px" }}

                    />
                    {field.type === 'drop-down' && <TextValidator
                      label="Options (comma-separated)"
                      value={field.options}
                      onChange={(e) => updateField(index, 'options', e.target.value)}
                      fullWidth
                      className={classes.paymentinputs}
                      style={{ marginBottom: "10px" }}
                      variant="outlined"
                    />}
                  </Grid>
                  <Grid container xs={12} sm={6}>
                    <Grid item xs={12} sm={2}>
                      <IconButton
                        onClick={() => removeField(index)}
                        aria-label="delete"
                        style={{ marginTop: "-12px", float: "right" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <Typography variant="body2" color="textSecondary" style={{ textAlign: "left" }}>
                        Click the delete icon to remove a field.
                      </Typography>
                    </Grid>

                  </Grid>
                </Grid>
              ))}

              <Grid item xs={12} sm={12} md={12}>
                <ButtonInc onClick={addField}>Add Field</ButtonInc>
              </Grid>



            </Grid>

          </Grid>


          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <ButtonInc
                size="large"
                onClick={EditTemplate}
                variant="contained"
                style={{ width: '221px', height: '55px', marginTop: '10%' }}
              >
                Edit Template
              </ButtonInc>
            </Grid>
            <Grid item>
              <ButtonInc
                variant="contained"
                color="primary"
                size="large"
                onClick={props.close}
                style={{
                  width: '221px',
                  height: '55px',
                  background: '#FBA92D',
                  marginTop: '10%',
                }}
              >
                Cancel
              </ButtonInc>
            </Grid>
          </Grid>
        </ValidatorForm>
      </ModalInc>
    </>
  )
}

export default EditReport