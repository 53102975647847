import * as types from '../../../lib/actionTypes.es6'


export function getEventQuoteRequest(payload) {
  return {
    type: types.GET_EVENT_QUOTE_REQUEST,
    payload,
  };
}

export function getEventQuoteFailure(error) {
  return {
    type: types.GET_EVENT_QUOTE_FAILURE,
    payload: error,
  };
}

export function getEventQuoteSuccess(response) {
  return {
    type: types.GET_EVENT_QUOTE_SUCCESS,
    payload: response,
  };
}

export function resetgetEventQuoteSuccess(error) {
  return {
    type: types.RESET_GET_EVENT_QUOTE_SUCCESS,
  };
}

export function resetgetEventQuoteError(error) {
  return {
    type: types.RESET_GET_EVENT_QUOTE_ERROR,
  };
}

export function initiateClaimRequest(payload){
  return{
    type: types.INITIATE_CLAIM_REQUEST,
    payload,
  }
}
export function initiateClaimSuccess(response){
  return {
    type: types.INITIATE_CLAIM_SUCCESS,
    payload: response,
  }
}
export function initiateClaimFailure(error){
  return{
    type: types.INITIATE_CLAIM_FAILURE,
    payload: error
  }
}
export function resetInitiateClaimSuccess(error){
  return{
    type: types.RESET_INITIATE_CLAIM_SUCCESS
  }
}
export function resetInitiateClaimError(error){
  return{
    type: types.RESET_INITIATE_CLAIM_ERROR
  }
}

export function getCustomerClaimsDcpRequest(customerGuid){
  return{
    type: types.GET_CUSTOMER_CLAIMS_DCP_REQUEST,
    payload: customerGuid
  }
}


export const getCustomerClaimsDcpSuccess = (payload) =>{
  return{
    type: types.GET_CUSTOMER_CLAIMS_DCP_SUCCESS,
    payload: payload,
  }
}

export const getCustomerClaimsDcpFailure = (error) =>{
  return{
    type: types.GET_CUSTOMER_CLAIMS_DCP_FAILURE,
    payload: error,
  }
}



