import React from 'react';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Button, DialogActions, DialogContent } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import 'react-table/react-table.css';
import LocalizedStrings from 'react-localization';
import Loader from 'react-loader';
import AlertDialog from '../../components/AlertDialog/index.es6';
import * as globalActions from '../Main/actions.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { localisedText } from '../../lib/localisation.es6';
import * as utils from '../../lib/utils.es6';
import { addMultiplePaymentRequest, addPaymentReset, addSinglePaymentRequest } from './actions.es6';
import { getInternationalCallingCode, getLocalNumberLength, getPartnerGuid } from '../../lib/access.es6';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import { PRIMARY_THEME_COLOR_SHADE_1, PRIMARY_THEME_COLOR_SHADE_2, SECONDARY_THEME_COLOR_SHADE_1, SECONDARY_THEME_COLOR_SHADE_2 } from '../../lib/constants';

const btnStyle = {
  margin: '2%',
  secondary: {
    backgroundColor: SECONDARY_THEME_COLOR_SHADE_2,
    color: 'white',
    margin: '2px',
    height: '50px',
    padding: '0 30px',
  },
  danger: {
    backgroundColor: SECONDARY_THEME_COLOR_SHADE_1,
    color: 'white',
    margin: '2px',
    height: '50px',
    padding: '0 30px',
  },
};

const minimumPayingAmount = 0;

export class ManualPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
      tab: 'singlePayment',
      msisdn: '',
      mnoReference: '',
      effectedDate: '',
      paymentAmount: '',
      paymentType: '',
      bundlePaymentType: '',
      file: null,
      errorList: null,
      fileInputKey: Date.now(),
      resetAfterSuccess: false,
      submitted: false,
      keyPayment: new Date().getTime(),
    };

    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleChangePaymentType = this.handleChangePaymentType.bind(this);
    this.handleChangeBundlePaymentType = this.handleChangeBundlePaymentType.bind(this);
    this.handleMsisdnChange = this.handleMsisdnChange.bind(this);
    this.handlePaymentAmountChange = this.handlePaymentAmountChange.bind(this);
    this.handleMnoReferenceChange = this.handleMnoReferenceChange.bind(this);
    this.handlePaymentEffectedDateChange = this.handlePaymentEffectedDateChange.bind(this);
    this.handleSubmitSinglePayment = this.handleSubmitSinglePayment.bind(this);
    this.handleResetButton = this.handleResetButton.bind(this);
    this.handleResetMultiplePaymentButton = this.handleResetMultiplePaymentButton.bind(this);
    this.onFileSelect = this.onFileSelect.bind(this);
    this.downloadCSVTemplate = this.downloadCSVTemplate.bind(this);
    this.addMultiplePayments = this.addMultiplePayments.bind(this);
    this.dowloadFile = this.dowloadFile.bind(this);
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('isMsisdnMatch', (value) => {
      return this.validateMsisdnRegex(value.trim());
    });

    ValidatorForm.addValidationRule('isPaymentAmountMatch', (value) => {
      return parseInt(value, 10) >= minimumPayingAmount;
    });
  }

  componentWillMount() {
    this.updateLanguage();
    this.props.dispatch(globalActions.hideCustomerBar());
    this.props.dispatch(addPaymentReset());
  }

  componentDidUpdate() {
    this.updateLanguage();
    if (this.props.manualPayment.success_single_payment) {
      this.resetAfterSuccess()
    }
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule('isMsisdnMatch');
    ValidatorForm.removeValidationRule('isPaymentAmountMatch');
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'ManualPayment',
      this.state.labels
    );
  }
  resetFields() {
    if (!this.fieldsAreResset()) {
      this.setState({
        msisdn: '',
        mnoReference: '',
        effectedDate: '',
        paymentAmount: '',
        paymentType: '',
        bundlePaymentType: '',
        file: null,
        errorList: null,
        fileInputKey: Date.now(),
        openConfirmResetPaymentResultsDiaglog: false,
        submitted: false,
        keyPayment: new Date().getTime(),
      });
    } else {
      this.setState({
        keyPayment: new Date().getTime(),
      });
    }
  }

  fieldsAreResset() {
    const { msisdn, mnoReference, effectedDate, paymentAmount, paymentType, file, errorList, bundlePaymentType, openConfirmResetPaymentResultsDiaglog } = this.state;
    return msisdn === '' &&
      mnoReference === '' &&
      effectedDate === '' &&
      paymentAmount === '' &&
      paymentType === '' &&
      file === null &&
      errorList === null &&
      bundlePaymentType === '' &&
      openConfirmResetPaymentResultsDiaglog === false;
  }

  resetAfterSuccess() {
    if (!this.state.resetAfterSuccess) {
      this.resetFields()
      this.setState({ resetAfterSuccess: true })
    }
  }

  handleResetButton() {
    this.resetFields();
    this.props.dispatch(addPaymentReset());
  }

  handleResetMultiplePaymentButton() {
    if (this.props.manualPayment.success_multiple_payments) {
      this.setState({ openConfirmResetPaymentResultsDiaglog: true })
    } else {
      this.resetFields();
      this.props.dispatch(addPaymentReset());
      this.setState({ submitted: false })
    }
  }

  handleTabChange(event, tab) {
    this.setState({ tab });
    this.resetFields()
  }

  handleMnoReferenceChange(event) {
    this.setState({ mnoReference: event.target.value });
  }

  handlePaymentAmountChange(event) {
    this.setState({ paymentAmount: event.target.value });
  }

  handleMsisdnChange(event) {
    this.setState({ msisdn: event.target.value });
  }

  handlePaymentEffectedDateChange(event) {
    const rawDate = utils.formatDate(new Date(event.target.value));
    this.setState({ effectedDate: rawDate });
  }

  handleChangePaymentType(event) {
    this.setState({ paymentType: event.target.value })
  }

  handleChangeBundlePaymentType(event) {
    this.setState({ bundlePaymentType: event.target.value })
  }

  onFileSelect(event) {
    this.setState({
      file: event.target.files[0],
      isSubmitDisabled: false,
    });
  }

  addMultiplePayments() {
    const { paymentType } = this.state;
    this.setState({ submitted: true });

    if (!this.state.file) {
      return;
    }

    const payload = {
      file: new FormData(),
      partner: getPartnerGuid(),
    };

    payload.file.append('file', this.state.file);
    payload.isBundled = paymentType === 'bundlePayment';

    this.props.dispatch(addMultiplePaymentRequest(payload));
    this.resetFields()
  }


  dowloadFile() {
    if (this.props.manualPayment.payment_report_file) {
      utils.downloadBlobReports(
        this.props.manualPayment.payment_report_file.data,
        `payment-report-${utils.formatDateTime(new Date())}.csv`
      );
    }
  }

  downloadCSVTemplate() {
    const { paymentType } = this.state;

    if(paymentType === 'bundlePayment') {
      return this.downloadBundledCSVTemplate();
    } else if (paymentType === 'normalPayment'){
      return this.downloadNormalCSVTemplate();
    }
  }

  downloadBundledCSVTemplate() {
    const rows = [
      ["MNO Reference", "msisdn", "Amount", "Effected Date", 'Bundle Payment Type'],
      ["abcdefghi1", "00254715877533", "149", "2000/11/19", 'voice'],
      ["abcdefghi2", "00254715877345", "149", "2020/11/29", 'data'],
    ];

    let csvContent = "data:text/csv;charset=utf-8,"
      + rows.map(e => e.join(",")).join("\n");

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';

    const url = encodeURI(csvContent);
    a.href = url;
    a.download = 'sample.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  downloadNormalCSVTemplate() {
    const rows = [
      ["MNO Reference", "msisdn", "Amount", "Effected Date", "Payment Channel"],
      ["abcdefghi1", "00260123456789", "49", "2021/02/19", "MANUAL"],
      ["abcdefghi2", "00260990001234", "49", "2021/02/19", "DEBIT_ORDER"],
    ];

    let csvContent = "data:text/csv;charset=utf-8,"
      + rows.map(e => e.join(",")).join("\n");

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';

    const url = encodeURI(csvContent);
    a.href = url;
    a.download = 'sample.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  confirmResetPaymentResultsDiaglog() {
    const { labels } = this.state;
    return <Dialog open={this.state.openConfirmResetPaymentResultsDiaglog || false} onClose={() => { this.setState({ openConfirmResetPaymentResultsDiaglog: false }) }} aria-labelledby="simple-dialog-title" fullWidth={true} maxWidth="sm" >
      <DialogContent>
        <h5>{labels.confirmResetPaymentResults}</h5>
        <br /><br />
        <Button color="secondary" variant="contained" style={{
          backgroundColor: 'green',
          color: 'white',
          margin: '2px',
          height: '50px',
          padding: '0 30px',
        }}
          onClick={this.dowloadFile}
        >{labels.downloadPaymentUploadResults}</Button>
        <Button color="secondary" variant="contained" style={{
          backgroundColor: 'orangered',
          color: 'white',
          margin: '2px',
          height: '50px',
          padding: '0 30px',
        }}
          onClick={this.handleResetButton}
        >{labels.reset}</Button>

      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  }
  renderPaymentType() {
    const { labels } = this.state;
    return <SelectValidator label={labels.selectPaymentType}
      id="paymentType"
      name="paymentType"
      className="form-control"
      value={this.state.paymentType}
      onChange={this.handleChangePaymentType}
      variant="outlined"
      validators={['required']}
      errorMessages={[labels.pleaseSelectPaymentType]}
      style={{ marginBottom: "20px" }}>
      <MenuItem value="">{labels.defaultSelection}</MenuItem>
      <MenuItem value="bundlePayment">{labels.bundlePayment}</MenuItem>
      <MenuItem value="normalPayment">{labels.normalPayment}</MenuItem>
    </SelectValidator>
  }

  renderBundlePaymentType() {
    const { labels, paymentType } = this.state;

    if (paymentType === 'bundlePayment') {
      return <SelectValidator label={labels.selectBundlePaymentType}
        id="paymentType"
        name="paymentType"
        className="form-control"
        value={this.state.bundlePaymentType}
        onChange={this.handleChangeBundlePaymentType}
        variant="outlined"
        validators={['required']}
        errorMessages={[labels.pleaseSelectBundlePaymentType]}
        style={{ marginBottom: "20px" }}>
        <MenuItem value="">{labels.defaultSelection}</MenuItem>
        <MenuItem value="MOBILE_BUNDLE_VOICE">{utils.formatStatus(labels.voiceBundle)}</MenuItem>
        <MenuItem value="MOBILE_BUNDLE_DATA">{utils.formatStatus(labels.dataBundle)}</MenuItem>
      </SelectValidator>
    }
    return <div />

  }

  apiSinglePaymentErrorMessage() {
    const errorMessage = this.props.manualPayment.error_single_payment ? this.props.manualPayment.error_single_payment.message : '';
    const { labels } = this.state
    return <AlertDialog
      danger
      title="Oops"
      onCancel={() => this.props.dispatch(addPaymentReset())}
      showCancel
      showConfirm={false}
      cancelBtnText={labels.tryAgain}
    >
      {errorMessage}
    </AlertDialog>
  }
  apiMultiplePaymentErrorMessage() {
    const errorMessage = this.props.manualPayment.error_multiple_payments ? this.props.manualPayment.error_multiple_payments.message : '';
    return errorMessage
  }

  succesSinglePaymentsMessage() {
    const { labels } = this.state;
    const message = this.props.manualPayment.success_single_payment ? labels.paymentAddedSuccessfully : '';
    return <AlertDialog
      success
      title={labels.added}
      onConfirm={() => this.handleResetButton()}
    >
      {message}
    </AlertDialog>

  }

  succesMultiplePaymentsMessage() {
    const { labels } = this.state;
    const message = this.props.manualPayment.success_multiple_payments ? labels.fileUploadedSuccessfully : '';
    return <AlertDialog
      success
      title={labels.uploaded}
      onConfirm={() => this.dowloadFile()}
      confirmBtnText={labels.downloadPaymentUploadResults}
      showCancel
      cancelBtnText={labels.reset}
      onCancel={() => this.handleResetButton()}
      confirmBtnCssClass
    >
      {message}
    </AlertDialog>;
  }

  msisdnErrorMessage() {
    const { labels } = this.state;
    return labels.phoneNumberDigitsMustBe + this.getFullPhoneNumberLenght() + ". " + labels.hereIsASampleNumber + this.getSamplePhoneNumber()
  }
  getFullPhoneNumberLenght() {
    return (getInternationalCallingCode() || 1).length + getLocalNumberLength();
  }

  getSamplePhoneNumber() {
    const localNumberLength = getLocalNumberLength();
    let randomNumbers = ""

    for (let i = 0; i < localNumberLength; i++) {
      randomNumbers += Math.floor((Math.random() * 9) + 1);
    }
    return getInternationalCallingCode() + randomNumbers
  }

  validateMsisdnRegex(value) {
    if (!value) {
      return false;
    }

    const internationalCallingCode = getInternationalCallingCode();
    if (!value.startsWith(internationalCallingCode)) {
      return false;
    }

    const localNumberLength = getLocalNumberLength();

    return value.length === (localNumberLength + internationalCallingCode.length);
  }

  handleSubmitSinglePayment() {
    const partnerGuid = getPartnerGuid()
    const { mnoReference, effectedDate } = this.state;
    let { paymentAmount } = this.state;
    this.setState({ validating: true });

    const subChannel = this.state.bundlePaymentType
    paymentAmount = parseInt(paymentAmount, 10) * 100;

    this.setState({ resetAfterSuccess: false })
    this.props.dispatch(addPaymentReset());

    this.addSinglePayment({
      partner_guid: partnerGuid,
      mno_reference: mnoReference,
      msisdn: this.state.msisdn,
      amount_in_cents: paymentAmount,
      effected_at: effectedDate,
      sub_channel: subChannel
    });
  }


  getTabStyle(tabValue) {
    const tabStyles = {
      default_tab: {
        color: '#fff',
        backgroundColor: PRIMARY_THEME_COLOR_SHADE_2,
        minWidth: '50%',
      },
      active_tab: {
        color: "#fff",
        backgroundColor: PRIMARY_THEME_COLOR_SHADE_2,
        fontWeight: 600,
        minWidth: '50%',
      }
    }

    const { tab } = this.state;
    if (tab === tabValue) {
      return tabStyles.active_tab
    } else {
      return tabStyles.default_tab
    }
  }
  addSinglePayment(payload) {
    const { paymentType } = this.state;

    payload.isBundled = paymentType === 'bundlePayment';
    if(!payload.isBundled) {
      payload.sub_channel = 'MANUAL';
    }
    this.props.dispatch(addSinglePaymentRequest(payload));
  }

  renderSinglePayment() {
    const { labels, keyPayment, paymentType } = this.state;

    return <div>
      <br />
      <br />
      <ValidatorForm onSubmit={this.handleSubmitSinglePayment} key={keyPayment}>
        <Grid container spacing={3}>
          <Grid container item xs={12} spacing={3} >
            <Grid item md={6} xs={12}>
              {this.renderPaymentType()}
            </Grid>
            <Grid item md={6} xs={12}>
              {this.renderBundlePaymentType()}
            </Grid>
          </Grid>
          {
            paymentType &&
            <React.Fragment>
              <Grid container item xs={12} spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextValidator label={labels.customer + " " + labels.msisdn}
                    fullWidth
                    variant="outlined"
                    type="number"
                    value={this.state.msisdn}
                    onChange={this.handleMsisdnChange}
                    name="msisdn"
                    aria-describedby="emailHelp"
                    placeholder={labels.customer + " " + labels.msisdn}
                    validators={['required', 'isMsisdnMatch']}
                    errorMessages={[this.msisdnErrorMessage(), this.msisdnErrorMessage()]} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextValidator label={labels.partialPaymentPlaceHolder}
                    fullWidth
                    variant="outlined"
                    type="number"
                    value={this.state.paymentAmount}
                    onChange={this.handlePaymentAmountChange}
                    name="PaymentAmount"
                    aria-describedby="emailHelp"
                    placeholder={labels.partialPaymentPlaceHolder}
                    validators={['required', 'isPaymentAmountMatch']}
                    errorMessages={[labels.missingPaymentAmountRequiredError, `${labels.missingVaryingPaymentMinAmountError} ${minimumPayingAmount}`]} />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextValidator label={labels.mnoReference}
                    fullWidth
                    variant="outlined"
                    type="text"
                    value={this.state.mnoReference}
                    onChange={this.handleMnoReferenceChange}
                    name="MNOReference"
                    aria-describedby="emailHelp"
                    placeholder={labels.mnoReference}
                    validators={['required']}
                    errorMessages={[labels.missingPaymentMnoReferenceError]} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextValidator label={labels.effectedAt}
                    placeholder={labels.effectedAt}
                    fullWidth
                    variant="outlined"
                    type="date"
                    value={this.state.effectedDate}
                    onChange={this.handlePaymentEffectedDateChange}
                    name="EffectedDate"
                    aria-describedby="emailHelp"
                    validators={['required']}
                    errorMessages={[labels.missingPaymentEffectedDateError]}
                    InputLabelProps={{ shrink: true, }} />
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Button
                  variant="contained"
                  style={btnStyle.danger}
                  type="submit">
                  {labels.addMissingPayment}
                </Button>
                <Button
                  onClick={this.handleResetButton}
                  variant="contained"
                  style={btnStyle.secondary}>
                  {labels.reset}
                </Button>
              </Grid>
            </React.Fragment>
          }
        </Grid>
      </ValidatorForm>
    </div>
  }

  renderMultiplePayments() {
    const { labels, keyPayment, paymentType } = this.state;
    return (
      <div>
        <br /> <br />
        <ValidatorForm onSubmit={this.addMultiplePayments}
          key={keyPayment}
          onError={() => this.setState({ submitted: true })}>
          <Grid container spacing={3}>
            <Grid item xs={10} >
              {this.renderPaymentType()}
            </Grid>
            <Grid item xs={10}>
              <div className="form-group">
                <div>
                  <TextValidator
                    fullWidth
                    variant="outlined"
                    label={labels.uploadPaymentsCsv}
                    type="file"
                    accept=".csv,.txt"
                    className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl"
                    key={this.state.fileInputKey}
                    onChange={this.onFileSelect}
                    InputLabelProps={{ shrink: true, }}
                  />
                </div>
                {this.state.submitted && !this.state.file
                  ? <p style={{ color: "red" }} > {labels.pleaseSelectFileToUpload} </p>
                  : ""}
              </div>
            </Grid>
            <Grid item xs={10} >
              {
                paymentType &&
                <Button onClick={this.downloadCSVTemplate} style={{ backgroundColor: "orange", color: "#fff" }}>{labels.downloadCSVTemplate}</Button>
              }
            </Grid>
            <Grid item md={6} xs={12}>
              <Button
                variant="contained"
                style={btnStyle.danger}
                type="submit"
              >
                {labels.addBulkMissingPayments}
              </Button>
              <Button
                onClick={this.handleResetMultiplePaymentButton}
                variant="contained"
                style={btnStyle.secondary}
              >
                {labels.reset}
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>);
  }

  renderAddPayment() {
    const labels = this.state.labels;

    return (

      <div className="form-group " style={{ margin: "1px", padding: "20px", width: "90%" }}>
        <AppBar position="static" style={{ backgroundColor: PRIMARY_THEME_COLOR_SHADE_1 }}>
          <Tabs value={this.state.tab}
            onChange={this.handleTabChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: SECONDARY_THEME_COLOR_SHADE_2
              }
            }}
          >
            <Tab value='singlePayment' id="singlePayment" label={labels.addSinglePayment} style={this.getTabStyle('singlePayment')} />
            <Tab value='bulkPayment' id="multiplePayment" label={labels.addMultiplePayment} style={this.getTabStyle('bulkPayment')} />
          </Tabs>
        </AppBar>
        {this.state.tab === 'singlePayment' ? this.renderSinglePayment() : this.renderMultiplePayments()}
      </div>
    );
  }


  render() {
    return (
      <Loader loaded={true} color="#ff834f">
        <div className="x_panel">
          <div className="x_content" >
            <div> <center>
              {this.props.manualPayment.loader ?
                <center>
                  <CircularProgress />
                </center> : ''}

              <center >
                {this.props.manualPayment.error_single_payment ?
                  <p style={{ color: 'red', fontSize: "150%" }}>{this.apiSinglePaymentErrorMessage()}</p> : ''}
              </center>
              <center >
                {this.props.manualPayment.error_multiple_payments ? this.apiMultiplePaymentErrorMessage() : ''}
              </center>
              <center >
                {this.props.manualPayment.success_single_payment ?
                  <p style={{ color: 'green', fontSize: "150%" }}>{this.succesSinglePaymentsMessage()}</p> : ''}
              </center>
              <center >
                {this.props.manualPayment.success_multiple_payments ? this.succesMultiplePaymentsMessage() : ''}
              </center>
            </center>
            </div>
            <div style={{ margin: "auto", borderColor: "black" }}>
              {this.confirmResetPaymentResultsDiaglog()}
              {this.renderAddPayment()}
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default connect(state => ({
  manualPayment: state.manualPayment,
  globalData: state.global,
}))(ManualPayment);
