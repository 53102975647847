import React from 'react';

// project imports
import Logo from '../ui-component/Logo'

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <>
        <Logo />
    </>
);

export default LogoSection;
