import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { CircularProgress } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AlertDialog from "../../../components/AlertDialog/index.es6";
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../lib/constants";

let initialState = {
  code: '',
  currency_code: '',
  currency_name: '',
  date_format_pattern: '',
  date_format_pattern_month: '',
  date_time_format_pattern: '',
  international_calling_code: '',
  local_number_length: '',
  msisdn_regex: '',
  name: '',
  time_zone: '',
  national_id_regex: '',
}
export default function EditCountry(props) {

  if(!props.country){
   return ""
  }

  let country = props.country
  initialState = country

  const [
    {
      code,
      currency_code,
      currency_name,
      date_format_pattern,
      date_format_pattern_month,
      date_time_format_pattern,
      international_calling_code,
      local_number_length,
      msisdn_regex,
      name,
      partner_guids,
      time_zone,
      national_id_regex,
    }, setState
  ] = React.useState(initialState)



  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };


  const reset = () => {
    setState(initialState);
  }


  const submit = () => {
    const payload = {
      code,
      currency_code,
      currency_name,
      date_format_pattern,
      date_format_pattern_month,
      date_time_format_pattern,
      international_calling_code,
      local_number_length,
      msisdn_regex,
      name,
      partner_guids,
      time_zone,
      national_id_regex,
    }
    props.editCountry(payload)
  }

  const handleCountryEditedSuccessfully = () => {
    props.resetEditCountrySuccessAlert()
    props.resetCountryToEdit()
    reset()
  }

  const handleCountryCreateError = () => {
    props.resetEditCountryErrorAlert()
  }

  const textFields = [
    { name: 'code', placeholder: 'KE', label: 'Country code', disabled: true },
    { name: 'name', placeholder: 'Kenya', label: 'Country name', },
    { name: 'currency_code', placeholder: 'Ksh', label: 'Currency code', },
    { name: 'currency_name', placeholder: 'Kenya Shilling', label: 'Currency name', },
    { name: 'time_zone', placeholder: 'EAT', label: 'Timezone', },
    { name: 'international_calling_code', placeholder: '00254', label: 'international calling code', },
    { name: 'msisdn_regex', placeholder: '^(00|\\+)254\\d{9}$', label: 'msisdn regex', },
    { name: 'national_id_regex', placeholder: '^(\\d{7}|\\d{8})$', label: 'National Id regex', },
    { name: 'date_format_pattern', placeholder: 'dd/MM/yyyy', label: 'Date format pattern', },
    { name: 'date_time_format_pattern', placeholder: 'dd/MM/yyyy HH/mm/ss', label: 'Date time format pattern', },
    { name: 'date_format_pattern_month', placeholder: 'MMM', label: 'Date format pattern month', },
    { name: 'local_number_length', placeholder: '9', label: 'Local number length', },
  ]

    return (
      <React.Fragment>
        <AlertDialog
          custom
          show={props.showEditCountryProgressAlert}
          size="sm"
          style={{ marginTop: '0', top: '30vh' }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={'cancel'}
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2>Updating country</h2>
        </AlertDialog>
  
        <AlertDialog
          success
          show={props.showEditCountrySuccessAlert}
          size="sm"
          title={'Country updated'}
          style={{ marginTop: '0', top: '30vh' }}
          onConfirm={() => { handleCountryEditedSuccessfully() }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={'cancel'}
          confirmBtnCssClass
        >
          <div >
            <Button variant="contained" onClick={() => { handleCountryEditedSuccessfully() }} style={{ background: 'green', }}>Ok</Button>
          </div>
        </AlertDialog>
        <AlertDialog
          show={props.showEditCountryErrorAlert}
          danger
          title={'Error updating country'}
          onConfirm={() => handleCountryCreateError()}
          confirmBtnText={'Try again'}
          confirmBtnCssClass
          showCancel={false}
          style={{ marginTop: '0', top: '30vh' }}
        >
          {props.editCountryError ? props.editCountryError.message : ''}
        </AlertDialog>
        <Dialog
          open={props.country} fullWidth
          maxWidth='lg'
          onClose={props.resetCountryToEdit}
          fullScreen
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={props.resetCountryToEdit}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Edit Country
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <div style={{ marginTop: "2%" }}>
              <Box sx={{ width: "100%" }}>
                <ValidatorForm onSubmit={() => submit()} >
                  <Grid container spacing={2}>
 
                  {
                    textFields.map(field => {
                      return <Grid item lg={3} md={6} xs={12}>
                        <TextValidator
                          onChange={onChange}
                          value={eval(field.name)} //eslint-disable-line
                          label={field.label}
                          name={field.name}
                          fullWidth
                          variant="outlined"
                          type="text"
                          placeholder={field.placeholder}
                          disabled={field.disabled}
                          // validators={['required']}
                          errorMessages={[`Please enter ${field.label}`]}
                        />
                      </Grid>
                    })
                  }
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Button variant="contained" type="submit" style={{ marginRight: '2%' }}> Update </Button>
                      <Button variant="contained" color="secondary" style={{ marginRight: '2%' }} onClick={() => props.resetCountryToEdit()}> Cancel</Button>
                    </Grid>
  
                  </Grid>
                </ValidatorForm>
              </Box>
            </div>
  
          </DialogContent>
        </Dialog>
      </React.Fragment>
  
    );
 

 
}
