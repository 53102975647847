import React from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { DatePicker } from '@material-ui/pickers';
import MainPageTitle from '../MainPageTitle/index.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { localisedText } from '../../lib/localisation.es6';
import { formatDate } from '../../lib/utils.es6';
import { Grid, Select, TextField, FormControl, MenuItem, InputLabel, Button } from '@mui/material';

export class CreateDebitOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
      debitOrder: {},
      granularity: '',
      fieldError: {},
      bankName: '',
    };
  }

  componentWillMount() {
    this.updateLanguage();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'CreateDebitOrders',
      this.state.labels
    );
  }

  handleTextFormFields(field, event, date) {
    if (field === 'start_date') {
      date = formatDate(date);
    }
    const debitOrder = Object.assign({}, this.state.debitOrder, {
      [field]: date || event.target.value,
    });
    this.setState({ debitOrder, fieldError: {} });
  }
  handleChangeGranularity(event) {
    this.setState({ granularity: event.target.value });
  }

  handleChangeBankName(event) {
    this.setState({ bankName: event.target.value });
  }

  datePickerHandler(type, moment) {
    const rawDate = formatDate(new Date(moment));
    if (type === 'start_date') {
      const debitOrder = Object.assign({}, this.state.debitOrder, {
        start_date: rawDate,
      });
      this.setState({ debitOrder, fieldError: {} });
    }
  }

  // TODO: error messages to be localised
  createDebitOrder() {
    if (!this.state.debitOrder.msisdn) {
      this.setState({ fieldError: { msisdn: 'msisdn cannot be empty' } });
      return;
    }

    if (!this.state.debitOrder.branch_code) {
      this.setState({ fieldError: { branch_code: 'branch code cannot be empty' } });
      return;
    }

    if (!this.state.debitOrder.bank_account_number) {
      this.setState({
        fieldError: {
          bank_account_number: 'bank account number cannot be empty',
        },
      });
      return;
    }
    if (!this.state.debitOrder.amount_in_cents) {
      this.setState({
        fieldError: { amount_in_cents: 'amount cannot be empty' },
      });
      return;
    }
    if (!this.state.debitOrder.day) {
      this.setState({ fieldError: { day: 'debit order day cannot be empty' } });
      return;
    }
    if (!this.state.granularity) {
      this.setState({ fieldError: { granularity: 'please select granularity' } });
      return;
    }

    if (!this.state.bankName) {
      this.setState({ fieldError: { bankName: 'please select bankName' } });
      return;
    }

    if (
      parseInt(this.state.debitOrder.day, 10) <= 0 ||
      parseInt(this.state.debitOrder.day, 10) > 28
    ) {
      this.setState({
        fieldError: { day: 'day can only be in this range 1-28' },
      });
      return;
    }
    if (!this.state.debitOrder.start_date) {
      this.setState({
        fieldError: { start_date: 'debit order start date cannot be empty' },
      });
      return;
    }

    this.setState({ fieldError: {} })
    const debitOrder = this.state.debitOrder
    debitOrder.granularity = this.state.granularity
    this.props.createDebitOrderhandler(debitOrder);
  }

  render() {
    const labels = this.state.labels;
    const tomorrowDate = new Date();
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);

    return (
      <div className="col-sm-12">
        <MainPageTitle pageTitle="Create Debit Order" />
        <div className="x_panel">
          <div className="x_content">
            <Grid container style={{ padding: '2%' }} spacing={2}>
              <Grid item xs={12} md={3} sm={6} >

                <TextField
                  variant="outlined"
                  fullWidth
                  hintText={labels.msisdnHint}
                  label={labels.msisdn}
                  type="number"
                  onChange={this.handleTextFormFields.bind(this, 'msisdn')}
                  value={this.state.debitOrder.msisdn}
                  errorText={this.state.fieldError.msisdn}
                />
              </Grid>

              <Grid item xs={12} md={3} sm={6} >
                <TextField
                  variant="outlined"
                  fullWidth
                  label={labels.amountFloatingText}
                  type="number"
                  onChange={this.handleTextFormFields.bind(
                    this,
                    'amount_in_cents'
                  )}
                  errorText={this.state.fieldError.amount_in_cents}
                  value={this.state.debitOrder.amount_in_cents}
                />
              </Grid>

              <Grid item xs={12} md={3} sm={6} >
                <TextField
                  variant="outlined"
                  fullWidth
                  hintText={labels.debitOrderHint}
                  label={labels.debitOrderFloating}
                  type="number"
                  onChange={this.handleTextFormFields.bind(this, 'day')}
                  errorText={this.state.fieldError.day}
                  value={this.state.debitOrder.day}
                />
              </Grid>

              <Grid item xs={12} md={3} sm={6} >
                <FormControl fullWidth>
                  <InputLabel htmlFor="age-simple">granularity</InputLabel>
                  <Select
                    defaultValue=""
                    label="granularity"
                    value={this.state.granularity}
                    onChange={this.handleChangeGranularity.bind(this)}
                  >
                    <MenuItem value={"Day"}>Day</MenuItem>
                    <MenuItem value={"Month"}>Month</MenuItem>
                    <MenuItem value={"Year"}>Year</MenuItem>
                  </Select>
                  {
                    this.state.fieldError.granularity ?
                      <p style={{ color: "red" }}>{this.state.fieldError.granularity}</p> : ''
                  }
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3} sm={6} >
                <TextField
                  variant="outlined"
                  fullWidth
                  hintText={labels.bankAccountHint}
                  label={labels.bankAccountFloating}
                  type="number"
                  onChange={this.handleTextFormFields.bind(
                    this,
                    'bank_account_number'
                  )}
                  errorText={this.state.fieldError.bank_account_number}
                  value={this.state.debitOrder.bank_account_number}
                />
              </Grid>


              <Grid item xs={12} md={3} sm={6} >
                <FormControl fullWidth>
                  <InputLabel htmlFor="age-simple">bank</InputLabel>
                  <Select
                    defaultValue=""
                    label="bank"
                    value={this.state.bankName}
                    onChange={this.handleChangeBankName.bind(this)}
                  >
                    {
                      this.props.banks.map(bank => {
                        return <MenuItem value={bank.name}>{bank.name}</MenuItem>
                      })
                    }
                  </Select>
                  {
                    this.state.fieldError.bankName ?
                      <p style={{ color: "red" }}>{this.state.fieldError.bankName}</p> : ''
                  }
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3} sm={6} >
                <TextField
                  variant="outlined"
                  fullWidth
                  hintText={'Branch code'}
                  label={'branch code '}
                  type="number"
                  onChange={this.handleTextFormFields.bind(
                    this,
                    'branch_code'
                  )}
                  errorText={this.state.fieldError.branch_code}
                  value={this.state.debitOrder.branch_code}
                />
              </Grid>

              <Grid item xs={12} md={3} sm={6} >
                <DatePicker
                  label="Debit order start Date"
                  value={this.state.debitOrder.start_date || null}
                  onChange={this.datePickerHandler.bind(this, 'start_date')}
                  format="YYYY-MM-DD"
                  minDate={tomorrowDate}
                  placeholder='Debit order start date'
                  variant='outlined'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={3} sm={6} >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.createDebitOrder.bind(this)}
                >{labels.createDebitOrder}</Button>
              </Grid>

            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({ globalData: state.global }))(
  CreateDebitOrders
);
