import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
const ClaimsHeader = (props) => {
  const classes = useStyle();
  return (
    <>
      <section className={classes.claimsheader}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.claimstitle}>
              <h1>{props.title}</h1>
            </div>
          </Grid>
       
        </Grid>
      </section>
    </>
  )
}

export default ClaimsHeader