import React from 'react'
import ClaimsCardInc from '../../../../../../../shared-ui/ClaimsCardInc'


const Claims = (props) => {

  return (
    <>

      <ClaimsCardInc
        data={props.claims}
        loader={props.loader}
      />
    </>
  )


}

export default Claims