import React from "react";
import LocalizedStrings from "react-localization";
import {localisedText} from "../../lib/localisation.es6";
import {updateCurrentLanguage} from "../../lib/languageUtils.es6";
import MainPageTitle from "../MainPageTitle/index.es6";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@mui/material";
import {deepOrange} from "@mui/material/colors";
import {getS3BucketSignedUrlForCustomerDocuments} from "../../lib/requests.es6";
import DropzoneS3Uploader from "react-dropzone-s3-uploader";
import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";



export class CustomerDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
      document: null,
      type: Object.entries(this.props.fileTypeEnums)[0][1],
      filename: null,
      openAddModal: false,
      openEditModal: false,
      anchorElAction: null,
      openConfirmDelete: false,
      msisdn: null,
      completed: true,
      message: null,
      s3Url: this.props.s3BucketUrl,
    };

    this.onTypeChange = this.onTypeChange.bind(this);
    this.deleteDocument = this.deleteDocument.bind(this);
    this.toggleOpenConfirmDeleteDialog = this.toggleOpenConfirmDeleteDialog.bind(this);
    this.deleteDocument = this.deleteDocument.bind(this);
  }

  componentWillMount() {
    this.updateLanguage();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'Documents',
      this.state.labels
    );
  }

  onTypeChange(event) {
    this.setState({ type: event.target.value });
  }

  deleteDocument() {
    this.setState({ openConfirmDelete: false });
    this.props.onDelete(this.state.document.guid);
  }

  handleOpenConfirmDeleteDialog(document) {
    this.setState({ openConfirmDelete: true, document: document });
  }

  toggleOpenConfirmDeleteDialog(document) {
    this.setState({ openConfirmDelete: !this.state.openConfirmDelete, document });
  }

  deleteDependantDialog(){
    const { labels, } = this.state;
    const btnStyle = {
      margin: '2%',
      green: {
        backgroundColor: 'orangered',
        color: 'white',
        margin: '2px',
        height: '50px',
        padding: '0 30px',
      },
      danger: {
        backgroundColor: 'green',
        color: 'white',
        margin: '2px',
        height: '50px',
        padding: '0 30px',
      },
    };

    const confirmDeleteActions = [
      <Button style={btnStyle.danger} onClick={this.toggleOpenConfirmDeleteDialog}>
        {labels.no}
      </Button>,
      <Button style={btnStyle.green} onClick={this.deleteDocument}>
        {labels.yes}
      </Button>,
    ];

    return (
      <Dialog
        open={this.state.openConfirmDelete}
        onClose={this.toggleOpenConfirmDeleteDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {labels.confirmDeleteDocument}
        </DialogTitle>
        <DialogContent>{labels.confirmDeleteDocumentText}</DialogContent>
        <DialogActions>
          {confirmDeleteActions.map((item, index) => (
            <div key={index}> {item}</div>
          ))}
        </DialogActions>
      </Dialog>
    )
  }

  handleFinishedUpload(info) {
    const payload ={
      customer_guid:this.props.customerGuid,
      type: this.state.type,
      filename: info.file.name,
      url: info.fileUrl.replace('undefined', info.bucketKey),
    }

    this.props.onSave(payload);
  }

  renderUploader() {
    const style = {
      margin: '5%',
    };
    const labels = this.state.labels;

    const getS3Ur = (file, callback) => getS3BucketSignedUrlForCustomerDocuments(this.props.customerGuid,file, callback)

    const uploadOptions = {
      getSignedUrl: getS3Ur,
      s3Url: this.state.s3Url,
      uploadRequestHeaders: {},
    };

    return (
      <div className="form-group">
        <div>
          <center>
            <DropzoneS3Uploader
              onFinish={this.handleFinishedUpload.bind(this)}
              maxSize={1024 * 1024 * 5}
              upload={uploadOptions}
              s3Url={this.state.s3Url}
              passChildrenProps={false}
            >
              <div style={style}>
                <h4>{labels.dragAndDrop}</h4>
                <h4>{labels.Or}</h4>
                <h4>{labels.clickUpload}</h4>
              </div>
            </DropzoneS3Uploader>
          </center>
        </div>
      </div>
    );
  }

  render() {
    const {labels,} = this.state;
    let documents = this.props.documents || [];

    return (
      <Grid container spacing={2}>
        {this.deleteDependantDialog()}
        <Grid item xs={8}>
          <MainPageTitle pageTitle={labels.documents} />
        </Grid>
        <Grid item xs={12}>
          {documents.length === 0 ? (
            <center>{labels.nothingFound}</center>
          ) : (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow >
                    {/* <TableCell className="table-cell-header">{labels.date}</TableCell> */}
                    <TableCell className="table-cell-header">{labels.documentType}</TableCell>
                    <TableCell className="table-cell-header">{labels.filename}</TableCell>
                    <TableCell className="table-cell-header">{labels.action}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="documents-list">
                  {
                    documents.map((document, index) => {
                      return <TableRow key={index}>
                        {/* <TableCell>{new Date(document.created_at).toDateString()}</TableCell> */}
                        <TableCell>{labels[document.type]}</TableCell>
                        <TableCell><Link href={document.url} target="_blank">{document.filename}</Link></TableCell>
                        <TableCell>
                          <Button
                            onClick={()=>this.handleOpenConfirmDeleteDialog(document)}
                            variant="flat"
                            style={{ backgroundColor: deepOrange[400], color: '#FFF' }}
                            className="btn btn-info"
                          >
                            {labels.delete}
                          </Button>
                        </TableCell>
                      </TableRow>
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        <br/>
        <br/>
        <br/>
        <br/>
        <Grid item container justifyContent="center" alignItems="center">
          <Grid md={4} xs={12} item>
            <FormControl>
              <RadioGroup
                aria-labelledby="documentType"
                name="documentType"
                value={this.state.type}
                onChange={this.onTypeChange}>
                {Object.keys(this.props.fileTypeEnums)
                  .sort((a,b)=> labels[a].localeCompare(labels[b]) )
                  .map(k=>(<FormControlLabel value={this.props.fileTypeEnums[k]} control={<Radio />} label={labels[k]} />))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            {this.renderUploader()}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}
