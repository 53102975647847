import * as types from '../../lib/actionTypes.es6';

export function reportPaymentRequest(reportType) {
  return {
    type: types.REPORT_PAYMENT_REQUEST,
    payload: reportType,
  };
}

export function reportPaymentRequestSuccess(reportURL) {
  return {
    type: types.REPORT_PAYMENT_REQUEST_SUCCESS,
    payload: reportURL,
  };
}

export function reportPaymentRequestError(error) {
  return {
    type: types.REPORT_PAYMENT_REQUEST_ERROR,
    payload: error,
  };
}

export function reportPaymentRequestReset() {
  return {
    type: types.REPORT_PAYMENT_REQUEST_RESET,
  };
}
