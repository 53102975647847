import React from 'react';
import Loader from 'react-loader';
import { connect } from 'react-redux';
import CustomersClaims from '../../components/CustomersClaims/index.es6';
import * as actions from './actions.es6';
import * as globalActions from '../Main/actions.es6';
import Cashback from '../Cashback/index.es6';

export class ClaimsDashboard extends React.Component {
  componentDidMount() {
    this.props.dispatch(actions.summaryClaimsRequest());
    this.props.dispatch(globalActions.hideCustomerBar());
  }

  claimsSummary() {
    const processedClaims = {};
    if (this.props.claimData.claimsList) {
      this.props.claimData.claimsList.forEach(element => {
        if (processedClaims[element.status]) {
          processedClaims[element.status].push(element);
        } else {
          processedClaims[element.status] = [];
          processedClaims[element.status].push(element);
        }
      }, this);
    }
    return Object.keys(processedClaims).map((status, index) => (
      <CustomersClaims
        key={index}
        claims={processedClaims[status]}
        title={status}
        status={status}
        columnWidth="col-md-6"
        showMore
      />
    ));
  }

  displayClaims() {
    if (
      this.props.claimData.claimsList &&
      this.props.claimData.claimsList.length > 0
    ) {
      return this.claimsSummary();
    }
    return this.renderNoClaims();
  }

  renderNoClaims() {
    if (
      !this.props.claimData.claimsList ||
      this.props.claimData.claimsList.length > 0
    ) {
      return (
        <div>
          <center>
            <h3>No claim available</h3>
          </center>
        </div>
      );
    }
    return <div />;
  }

  render() {
    return (
      <div>
        <Loader loaded={!this.props.claimData.loader} color="#ff834f">
          <div>
            <div className="row">
              {this.displayClaims()}
              <div className={`col-md-6 col-sm-6 col-xs-12`}>
                <Cashback />
              </div>
            </div>

          </div>
        </Loader>
      </div>
    );
  }
}

export default connect(state => ({ claimData: state.claimsSummariesList }))(
  ClaimsDashboard
);
