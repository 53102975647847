import React,{useState} from 'react'
import AlertDialog from '../../../../../components/AlertDialog/index.es6'
import { CircularProgress } from '@material-ui/core'
import ButtonInc from '../../../../../shared-ui/ButtonInc'

const DeleteClaimDocument = (props) => {

  if (!props.DocumentToDelete) {
    return ""
  }
  const [showWarning, setShowWarning] = useState(false);

  const deleteDocument = () => {
    setShowWarning(true)
    props.deleteClaimDocument(props.DocumentToDelete);
  }
  const cancel = () => {
    props.resetDocumentToDelete()
  }
  const handleDocumentDeletedSuccessfully = () => {
    props.resetDeleteDocumentSuccessAlert()
    props.resetDocumentToDelete();
    window.location.reload();
  }
  const handleDocumentDeleteError = () => {
    props.resetDocumentToDelete()

  }
  return (
    <>
      <AlertDialog
        custom
        show={props.showDeleteDocumentProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Deleting Document...</h2>
      </AlertDialog>

      <AlertDialog
        warning
        show={!showWarning && props.DocumentToDelete}
        size="sm"
        title={'Are you sure you want to delete this claim document? '}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { cancel() }}
        onCancel={() => { deleteDocument() }}
        confirmBtnText={'Cancel'}
        showCancel={true}
        showConfirm={true}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
      </AlertDialog>
      <AlertDialog
        success
        show={props.showDeleteDocumentSuccessAlert}
        size="sm"
        title={'Document deleted'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleDocumentDeletedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'Delete'}
        confirmBtnCssClass
      >
        <div >
          <ButtonInc onClick={() => { handleDocumentDeletedSuccessfully() }} style={{ background: 'green', }}>OK</ButtonInc>
        </div>
      </AlertDialog>
      <AlertDialog
        show={props.showDeleteDocumentErrorAlert}
        danger
        title={'Error deleting document'}
        onConfirm={() => handleDocumentDeleteError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {'Could not delete claim document'}
      </AlertDialog>
    </>
  )
}

export default DeleteClaimDocument