import * as types from "../../lib/actionTypes.es6";

export const initialState = {
  error: null,
  loader: false,
  bankdetails: [],

  bankdetail: null,

  createdbankdetail: null,
  createbankdetailSuccess: false,
  createbankdetailLoader: false,
  createbankdetailError: null,


  bankDetailToEdit: null,
  bankDetailsToDelete: null,

  editedbankdetail: null,
  editbankdetailSuccess: false,
  editbankdetailLoader: false,
  editbankdetailError: null,


  deletebankdetailSuccess: false,
  deletebankdetailLoader: false,
  deletebankdetailError: null,
  bankdetailToDeleteGuid: null,
};

export default function customerBeneficiarybankdetailsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CUSTOMER_BENEFICIARY_BANK_DETAILS_REQUEST:
      return { ...state, loader: true, error: null, bankdetail: null };
    case types.GET_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS:
      return { ...state, bankdetail: action.payload, loader: false };
    case types.GET_CUSTOMER_BENEFICIARY_BANK_DETAILS_FAILURE:
      return { ...state, loader: false, error: action.payload };

    case types.ADD_CUSTOMER_BENEFICIARY_BANK_DETAILS_REQUEST:
      return { ...state, createbankdetailLoader: true, createbankdetailError: null, createdbankdetail: null };
    case types.ADD_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS:
      return { ...state, bankdetail: action.payload, createbankdetailLoader: false, createbankdetailSuccess: true };
    case types.ADD_CUSTOMER_BENEFICIARY_BANK_DETAILS_FAILURE:
      return { ...state, createbankdetailLoader: false, createbankdetailError: action.payload };

    case types.RESET_CREATE_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS:
      return { ...state, createbankdetailSuccess: false };
    case types.RESET_CREATE_CUSTOMER_BENEFICIARY_BANK_DETAILS_ERROR:
      return { ...state, createbankdetailError: null };


    case types.UPDATE_CUSTOMER_BENEFICIARY_BANK_DETAILS_REQUEST:
      return { ...state, editbankdetailLoader: true, editbankdetailError: null, bankdetail: null };
    case types.UPDATE_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS:
      return { ...state, editedbankdetail: action.payload, editbankdetailLoader: false, editbankdetailSuccess: true };
    case types.UPDATE_CUSTOMER_BENEFICIARY_BANK_DETAILS_FAILURE:
      return { ...state, editbankdetailLoader: false, editbankdetailError: action.payload };
    case types.SET_CUSTOMER_BENEFICIARY_BANK_DETAILS_TO_EDIT:
      return { ...state, bankDetailToEdit: action.payload };
    case types.RESET_CUSTOMER_BENEFICIARY_BANK_DETAILS_TO_EDIT:
      return { ...state, bankDetailToEdit: null };

    case types.RESET_EDIT_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS:
      return { ...state, editbankdetailSuccess: false };

    case types.RESET_EDIT_CUSTOMER_BENEFICIARY_BANK_DETAILS_ERROR:
      return { ...state, editbankdetailError: null,  };


    case types.DELETE_CUSTOMER_BENEFICIARY_BANK_DETAILS_REQUEST:
      return { ...state, loader: true, error: null, bankdetail: null };
    case types.DELETE_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS:
      return { ...state, loader: false };
    case types.DELETE_CUSTOMER_BENEFICIARY_BANK_DETAILS_FAILURE:
      return { ...state, loader: false, error: action.payload };

    case types.SET_CUSTOMER_BENEFICIARY_BANK_DETAILS_TO_DELETE:
      return { ...state, bankDetailsToDelete: action.payload };

    case types.RESET_CUSTOMER_BENEFICIARY_BANK_DETAILS_TO_DELETE:
      return { ...state, bankDetailsToDelete: null };


    case types.RESET_DELETE_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS:
      return { ...state, deletebankdetailSuccess: false };

    case types.RESET_DELETE_CUSTOMER_BENEFICIARY_BANK_DETAILS_ERROR:
      return { ...state, deletebankdetailError: null };

      case types.RESET_EDIT_CUSTOMER_BANK_DETAILS_SUCCESS:
        return { ...state, editBankDetailSuccess: false };
      case types.RESET_EDIT_CUSTOMER_BANK_DETAILS_ERROR:
        return { ...state, editBankDetailError: null };

    default:
      return state;
  }
}
