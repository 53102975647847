import React from "react";
import { connect } from "react-redux";
import { getAllPoliciesRequest } from "../../Redux/actions";
import MakePayment from "./Components/MakePayment/MakePayment";
import { getAllPaymentsRequest } from "../../../../../containers/CustomerScreen/actions.es6";
import Payments from "./Components/Payments/Payments";
class AccessBankPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentWillMount() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      this.props.dispatch(getAllPoliciesRequest({ guid: currentCustomer.guid }))
      this.props.dispatch(getAllPaymentsRequest({ guid: currentCustomer.guid }));
    }
  }



  render() {

    return (
      <>
        <MakePayment
          policies={this.props.AccessBankPolicyData.policies}
          toggleAddPaymentDialog={this.props.toggleAddPaymentDialog}
          openAddPaymentDialog={this.props.openAddPaymentDialog}
        />
        <Payments
          payments={this.props.customerScreenData.payments}
          loader={this.props.customerScreenData.get_payments_loader}
        />
      </>
    )


  }
}

export default connect((state) => ({
  customerData: state.currentCustomer,
  customerScreenData: state.customerScreen,
  documentGenerationData: state.documentGeneration,
  AccessBankPolicyData: state.AccessBankPolicyData
}))(AccessBankPayments);