import React from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import MainPageTitle from '../MainPageTitle/index.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { localisedText } from '../../lib/localisation.es6';

export class Callback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
    };
  }

  componentWillMount() {
    this.updateLanguage();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'Claims',
      this.state.labels
    );
  }

  viewCustomerHandler(customerGuid) {
    this.props.viewCustomer(customerGuid);
  }

  deleteCallBackHandler(guid) {
    this.props.deleteCallback(guid);
  }

  orderByDate(callback1, callback2) {
    const callback1Date = new Date(callback1.created_at);
    const callback2Date = new Date(callback2.created_at);

    if (callback1Date === callback2Date) return 0;

    if (callback1Date > callback2Date) {
      return -1;
    }
    return 1;
  }

  // TODO: Translate this component. Pass the labels along
  render() {
    const labels = this.state.labels;
    const callbackList = this.props.callback ? this.props.callback : [];
    const callbackData = callbackList
      .sort(this.orderByDate)
      .map((callback, index) => (
        <tr key={callback.guid}>
          <td className="col-sm-1">{index + 1}</td>
          <td className="col-sm-2">{callback.created_at}</td>
          <td className="col-sm-1">{callback.count}</td>
          <td className="col-sm-3">{callback.customer_msisdn}</td>
          <td className="col-sm-3">{callback.customer_full_name}</td>
          <td className="col-sm-1">
            <button
              type="button"
              onClick={this.viewCustomerHandler.bind(
                this,
                callback.customer_guid
              )}
              className="btn btn-primary"
            >
              {labels.view}
            </button>
          </td>
          <td className="col-sm-1">
            <button
              type="button"
              onClick={this.deleteCallBackHandler.bind(this, callback.guid)}
              className="btn btn-primary"
            >
              {'Close'}
            </button>
          </td>
        </tr>
      ));
    return (
      <div className={`${this.props.columnWidth} col-sm-6 col-xs-12`}>
        <MainPageTitle pageTitle={labels.claimCallback} />
        <div className="x_panel">
          <div className="x_content">
            {callbackList.length === 0 ? (
              <center>{labels.nothingFound}</center>
            ) : (
              <form className="form-horizontal form-label-left" noValidate>
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>{labels.createdAt}</th>
                      <th>{labels.count}</th>
                      <th>{labels.msisdn}</th>
                      <th>{labels.fullName}</th>
                      <th>{labels.customer}</th>
                      <th>{labels.action}</th>
                    </tr>
                  </thead>
                  <tbody className="callback-body">{callbackData}</tbody>
                </table>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({ globalData: state.global }))(Callback);
