import React from 'react';
import BoxInc from '../../../../shared-ui/BoxInc/Index';

const VideoGuide = ({ guide }) => {

  const embedUrl = guide.url.replace("watch?v=", "embed/");

  return (
    <BoxInc
      title={guide ? guide.title : "No title"}
      expanded
      style={{ marginTop: "40px" }}
    >
      <div style={{ margin: '0 auto', textAlign: 'center', padding: '4%' }}>
        <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
          <iframe
            src={embedUrl}
            width="100%"
            height="100%"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          ></iframe>
        </div>
        <p>description: {guide ? guide.description : "No description"}</p>
        <p>Author: {guide ? guide.created_by : "No author"}</p>
        <p>Updated at: {guide ? guide.updated_at : "No date"}</p>
        <p>Status: {guide ? guide.status : "No status"}</p>
      </div>
    </BoxInc>
  );
};
export default VideoGuide;