import React from 'react'
import { AppBar, Dialog, DialogContent, DialogTitle, IconButton, DialogActions, Button } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { getPartnerGuid } from '../../../../lib/access.es6';
import './style.css'


const DesktopDisplaySearchResults = (props) => {
  const dialogActions = [
    props.paginateCustomers(),
    props.pageSizeSelector(),
  ];

  if (!props.customers) {
    return <div className="no-customers" />;
  }
  if (props.customers.length === 0) {
    return (
      <Dialog
        open={!!props.customers}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">{props.labels.results}</DialogTitle>
        <DialogContent>{props.labels.nothingFound}</DialogContent>
        <DialogActions>
          <Button style={{ color: "white", backgroundColor: "orange" }} onClick={props.handleClose()}>{props.labels.cancel}</Button>
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <>
      <Dialog
        open={!!props.customers}
        aria-labelledby="form-dialog-title"
        fullScreen
        maxWidth="md"
        className='desktop'
      >

        <AppBar style={{ position: 'absolute', backgroundColor: "#183e4b", height: "74px", paddingTop: "10px" }} >
          <div style={{ textAlign: 'right', fontSize: "150%" }}>
            <span className='search-results-found' style={{ float: 'left', margin: '1%' }}>{props.labels.results + ': ' + props.count + ' ' + props.labels.customersFound}</span>
            <IconButton edge="start" color="inherit"
              style={{ padding: '1%' }}
              onClick={props.handleClose()}
              aria-label="close">
              <CloseIcon style={{ fontSize: '100%' }} />
            </IconButton>
          </div>
        </AppBar>

        <DialogTitle id="form-dialog-title">{props.labels.results}</DialogTitle>
        <DialogContent>
          <div className='searchresultstable'>
            <table className="search-table ">
              <thead>
                <tr>
                  {props.nationalIdHeader()}
                  {props.firstNameHeader()}
                  {props.surnameHeader()}
                  {props.fullNameHeader()}
                  {props.agentHeader()}
                  {props.languageHeader()}
                  <th>{getPartnerGuid() === 'bboxxkeguid' || getPartnerGuid() === 'bboxxrwguid' ? "Customer Number" : props.labels.phoneNumber}</th>
                  <th>{props.labels.dateOfBirth}</th>
                  {props.beneficiaryFullnameHeader()}
                  {props.beneficiaryPhoneNumberHeader()}
                  <th>{props.labels.action}</th>
                </tr>
              </thead>
              <tbody className="search-results">
                {props.customers.map(customer => (
                  <tr key={customer.guid}>
                    {props.nationalIdRow(customer)}
                    {props.firstNameRow(customer)}
                    {props.surnameRow(customer)}
                    {props.fullNameRow(customer)}
                    {props.agentRow(customer)}
                    {props.languageRow(customer)}
                    <td className="col-xs-1">{customer.msisdn || "N/A"}</td>
                    <td className="col-xs-1">{customer.date_of_birth || "N/A"}</td>
                    {props.beneficiaryFullnameRow(customer)}
                    {props.beneficiaryPhoneNumberRow(customer)}
                    <td className="col-xs-1">
                      <Button
                        onClick={props.selectCustomer.bind(this, customer)}
                        variant="contained" color="primary" size="large"
                      >
                        {props.labels.select}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </DialogContent>
        <DialogActions style={{ marginRight: '5%' }}>
          {dialogActions.map((item, index) => (
            <div key={index}> {item}</div>
          ))}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DesktopDisplaySearchResults