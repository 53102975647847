import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  AuthData: null,
  loader: false,
  sessionOpen: true,
  refreshSessionExpired: false,
  message: null,
  forgotPassword: false,


  getStartedMessage: null,
  getStartedMessageSuccess: false,
  getStartedMessageLoader: false,
  getStartedMessageError: null,
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_REQUESTED:
      return { ...state, loader: true, errors: null };

    case types.USER_LOGIN_SUCCESS:
      return {
        ...state,
        AuthData: action.payload,
        loader: false,
        sessionOpen: true,
      };

    case types.USER_LOGIN_FAIL:
      return { ...state, loader: false, errors: action.payload };

    case types.USER_LOGOUT:
      return { ...initialState };

    case types.SESSION_EXPIRED_ALERT:
      return { ...state, sessionOpen: false };

    case types.SESSION_REFRESH_REQUEST:
      return { ...state, loader: true };

    case types.SESSION_REFRESH_SUCCESS:
      return {
        ...state,
        AuthData: action.payload,
        loader: false,
        sessionOpen: true,
      };

    case types.SESSION_REFRESH_FAIL:
      return { ...state, loader: false, refreshSessionExpired: true };

    case types.FORGOT_PASSWORD_SUCCESS:
      return { ...state, forgotPassword: true };

    case types.FORGOT_PASSWORD_RESET:
      return { ...state, forgotPassword: false };

    case 'UNAUTHORISED_ACTION':
      return { ...state, message: action.payload };

    case 'RESET_UNAUTHORISED_ACTION':
      return { ...state, message: null };


    case types.GET_STARTED_REQUEST:
      return {
        ...state,
        getStartedMessageError: null,
        getStartedMessageLoader: true,
        getStartedMessageSuccess: false,
        getStartedMessage: null
      };

    case types.GET_STARTED_SUCCESS:
      return {
        ...state,
        getStartedMessage: action.payload,
        getStartedMessageSuccess: true,
        getStartedMessageLoader: false,
        getStartedMessageError: null,
      };

    case types.GET_STARTED_FAILURE:
      return { ...state, getStartedMessageLoader: false, getStartedMessageError: action.payload };

      case types.RESET_GET_STARTED_SUCCESS:
        return { ...state, getStartedMessageSuccess: false };
  
      case types.RESET_GET_STARTED_ERROR:
        return { ...state, getStartedMessageError: null };

    default:
      return state;
  }
}
