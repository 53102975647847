import React from 'react';
import LocalizedStrings from 'react-localization';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as utils from '../../lib/utils.es6';
import MainPageTitle from '../MainPageTitle/index.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { localisedText } from '../../lib/localisation.es6';
import { addMissingPaymentRequest } from '../../containers/CustomerStatus/actions.es6';
import { SECONDARY_THEME_COLOR_SHADE_1 } from '../../lib/constants';
import { Button } from '@mui/material';

export class Payments extends React.Component {
  constructor(props) {
    super(props);

    const paidPolicies = (this.props.customerData.currentCustomer.policies || []).filter(policy => policy.active && policy.product_type === 'Paid')

    this.state = {
      labels: new LocalizedStrings(localisedText),
      openMissingPaymentModal: !!this.props.errors,
      mnoReference: '',
      effectedDate: '', // initialize value to current date, for use in the DatePicker component value field
      paymentAmount: '',
      error_message: 'Something went wrong',
      error: this.props.errors ? this.props.errors : false,
      selectedPaidPolicyGuid: '',
      paidPolicy: paidPolicies.length ? paidPolicies[0] : null,
      paidPolicies: paidPolicies
    };
  }

  componentWillMount() {
    this.updateLanguage();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'Payments',
      this.state.labels
    );
  }

  comparePayments(paymentA, paymentB) {
    if (paymentA.state !== paymentB.state) {
      if (paymentA.state === 'Succeeded') {
        return -1;
      }
      return 1;
    }
    if (
      new Date(paymentA.effected_at).getTime() ===
      new Date(paymentB.effected_at).getTime()
    ) {
      return 0;
    }
    if (new Date(paymentA.effected_at) > new Date(paymentB.effected_at)) {
      return -1;
    }
    return 1;
  }

  addMissingPayment(payment) {
    const payload = {
      paymentData: payment,
      customerGuid: this.props.customerData.currentCustomer.guid,
    };
    this.props.dispatch(addMissingPaymentRequest(payload));
  }

  failureMessage() {
    const defaultErrorMessage = this.state.error_message;
    return this.props.errors ? this.props.errors.message : defaultErrorMessage;
  }

  handleClosePaymentDialog() {
    this.setState({ openMissingPaymentModal: false });
  }

  handleOpenPaymentDialog() {
    this.setState({ openMissingPaymentModal: true });
    this.props.onReset();
  }

  sendRecurringPaymentRecommendationSms() {
    const customerGuid = this.props.currentCustomer.currentCustomer.guid
    this.props.promptRecurringPaymentSmsDialog(customerGuid);
  }

  handleMnoReferenceChange(event) {
    this.setState({ mnoReference: event.target.value });
  }

  handlePaymentAmountChange(event) {
    this.setState({ paymentAmount: event.target.value });
  }

  handlePaymentEffectedDateChange(event) {
    const rawDate = utils.formatDate(new Date(event.target.value));
    this.setState({ effectedDate: rawDate });
  }

  handleSubmit() {
    const { selectedPaidPolicyGuid, mnoReference, effectedDate, labels } = this.state
    let paymentAmount = this.state.paymentAmount;

    const channel = 'WebClient';
    const status = 'Succeeded';
    const valid = Boolean(mnoReference) && Boolean(effectedDate) && selectedPaidPolicyGuid.length > 0;
    if (valid) {
      paymentAmount = parseInt(paymentAmount, 10) * 100;
      this.addMissingPayment({
        amount_in_cents: paymentAmount,
        effected_at: effectedDate,
        mno_reference: mnoReference,
        policy_guid: this.state.selectedPaidPolicyGuid,
        status,
        channel,
      });
    } else if (!parseInt(paymentAmount, 10)) {
      this.setState({
        error: true,
        error_message: labels.missingPaymentAmountRequiredError,
      });
    } else if (parseInt(paymentAmount, 10) < 100) {
      this.setState({
        error: true,
        error_message: labels.missingPaymentMinAmountError,
      });
    } else if (!effectedDate) {
      this.setState({
        error: true,
        error_message: labels.missingPaymentEffectedDateError,
      });
    } else if (!mnoReference) {
      this.setState({
        error: true,
        error_message: labels.missingPaymentMnoReferenceError,
      });
    }else if (!selectedPaidPolicyGuid || selectedPaidPolicyGuid.length < 1 ) {
      this.setState({
        error: true,
        error_message: labels.missingPaymentPolicyError,
      });
    }
  }

  canSendRecurringSMS() {
    const canSendRecurringSMSValue = this.props.canSendRecurringSMS
    if (canSendRecurringSMSValue && canSendRecurringSMSValue()) {
      return true
    }
    return false;
  }

  selectPolicy(e){
    this.setState({
      selectedPaidPolicyGuid: e.target.value
    })
  }

  AddMissingPaymentDialog() {
    const labels = this.state.labels;
    const open = this.state.openMissingPaymentModal;
    const paidAndActivePolicies = this.state.paidPolicies;
    const styleErrorMessage = {
      display: this.state.error ? 'block' : 'none',
    };
    const btnStyle = {
      margin: '2%',
      green: {
        backgroundColor: 'orangered',
        color: 'white',
        margin: '2px',
        height: '50px',
        padding: '0 30px',
      },
      danger: {
        backgroundColor: 'green',
        color: 'white',
        margin: '2px',
        height: '50px',
        padding: '0 30px',
      },
    };

    const confirmActions = [
      <Button
        onClick={this.handleSubmit.bind(this)}
        variant="contained"
        style={btnStyle.green}
      >
        {labels.addMissingPayment}
      </Button>,
      <Button
        onClick={this.handleClosePaymentDialog.bind(this)}
        variant="contained"
        style={btnStyle.danger}
      >
        {labels.cancel}
      </Button>,
    ];
    return (
      <Dialog
        open={open}
        onClose={this.handleClosePaymentDialog.bind(this)}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {labels.addMissingPayment}
        </DialogTitle>
        <DialogContent>
          <center style={styleErrorMessage}>
            {' '}
            <p className="error">{this.failureMessage()} </p>{' '}
          </center>
          <form>
            <div className="form-group col-md-5">
              <label htmlFor="PaymentAmount">
                {labels.partialPaymentPlaceHolder}
              </label>
              <input
                type="number"
                value={this.state.paymentAmount}
                onChange={this.handlePaymentAmountChange.bind(this)}
                className="form-control"
                id="PaymentAmount"
                aria-describedby="emailHelp"
                placeholder={labels.partialPaymentPlaceHolder}
                required
              />
            </div>
            <div className="form-group col-md-5">
              <label htmlFor="MNOReference">{labels.mnoReference}</label>
              <input
                type="text"
                className="form-control"
                value={this.state.mnoReference}
                onChange={this.handleMnoReferenceChange.bind(this)}
                id="MNOReference"
                aria-describedby="emailHelp"
                placeholder={labels.mnoReference}
                required
              />
            </div>
            <div className="form-group col-md-5">
              <label htmlFor="EffectedDate">{labels.effectedAt}</label>
              <input
                type="date"
                className="form-control"
                value={this.state.effectedDate}
                onChange={this.handlePaymentEffectedDateChange.bind(this)}
                id="EffectedDate"
                aria-describedby="emailHelp"
                placeholder={labels.effectedDate}
                required
              />
            </div>
            <div className="form-group col-md-5">
              <label htmlFor="policy">{labels.policy}</label>
              <br/>
              <select
                id="policy"
                required
                className="form-control"
                onChange={this.selectPolicy.bind(this)}
                defaultValue={this.state.selectedPaidPolicyGuid}
              >
                <option key="-1" value={labels.policy}>
                  {labels.policy}
                </option>
                {paidAndActivePolicies.map((policy, index) =>
                    <option key={index} value={policy.guid}>
                      {policy.product_name }
                    </option>
                )}
              </select>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          {confirmActions.map((item, index) => (
            <div key={index}> {item}</div>
          ))}
        </DialogActions>
      </Dialog>
    );
  }

  getAmount(payment){
    if(payment.amount_received_in_cents) return payment.amount_received_in_cents / 100
    if(payment.amount_in_cents) return payment.amount_in_cents / 100
    return 'N/A'
  }

  render() {
    const labels = this.state.labels;
    const payments = this.props.payments ? this.props.payments : [];

    // payments.sort(this.comparePayments);
    const paymentsData = payments.map(payment => (
      <tr key={payment.guid}>
        <TableCell>{payment.product_name}</TableCell>
        <TableCell>{payment.mno_reference}</TableCell>
        <TableCell>{payment.created_at}</TableCell>
        <TableCell>{payment.effected_at}</TableCell>
        <TableCell>{utils.formatStatus(payment.status)}</TableCell>
        <TableCell>{this.getAmount(payment)}</TableCell>
        <TableCell>{payment.status === "Succeeded" ? (<TableCell>{"None"}</TableCell>) : (<TableCell>{payment.payment_failure_reason}</TableCell>)}</TableCell>
      </tr>
    ));
    return (
      <div className={`${this.props.columnWidth} col-sm-6 col-xs-12`}>
        <MainPageTitle pageTitle={labels.titlePayments} />
        {this.AddMissingPaymentDialog()}

        {payments.length === 0 ? (
          <center>{labels.nothingFound}</center>
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow >
                  <TableCell className="table-cell-header">{labels.productName}</TableCell>
                  <TableCell className="table-cell-header">{labels.mnoReference}</TableCell>
                  <TableCell className="table-cell-header">{labels.createdAt}</TableCell>
                  <TableCell className="table-cell-header">{labels.effectedAt}</TableCell>
                  <TableCell className="table-cell-header">{labels.status}</TableCell>
                  <TableCell className="table-cell-header">{labels.amount}</TableCell>
                  <TableCell className="table-cell-header">{labels.paymentFailureReason}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="payments-body">
                {this.props.showMore
                  ? paymentsData.slice(0, 3)
                  : paymentsData}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Button
          href="#admin/payments"
          style={{
            display: utils.displayValue(
              this.props.showMore && payments.length !== 0
            ),
            backgroundColor: SECONDARY_THEME_COLOR_SHADE_1,
            marginTop: '1%'
          }}
          variant="contained"
          size='small'
        >
          {labels.morePayments}
        </Button>
        <Button
          style={{
            display: this.state.paidPolicy ? '' : 'none',
            backgroundColor: SECONDARY_THEME_COLOR_SHADE_1,
            marginTop: '1%',
            marginLeft: '1%'
          }}
          onClick={this.handleOpenPaymentDialog.bind(this)}
          variant="contained"
          size='small'
        >
          {labels.addMissingPayment}
        </Button>
        <Button
          style={{
            display: this.canSendRecurringSMS() ? '' : 'none',
            backgroundColor: SECONDARY_THEME_COLOR_SHADE_1,
            color: '#FFF',
            marginTop: '1%',
            marginLeft: '1%'
          }}
          onClick={this.sendRecurringPaymentRecommendationSms.bind(this)}
          variant="contained"
          size='small'
        >
          {labels.sendRecurringPaymentRecommendationSms}
        </Button>
      </div>
    );
  }
}

export default connect(state => ({
  globalData: state.global,
  customerData: state.currentCustomer,
}))(Payments);
