import React from 'react';
import { Grid, Typography } from '@mui/material';

const ApprovedPaid = ({ claim, labels }) => {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography 
          align="center" 
          variant="body1" 
          style={{ 
            padding: '20px',
            color: '#75A85E',
            fontWeight: 500
          }}
        >
          {/* {labels.claimApprovedAndPaid} */}
          Claim approved and paid
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ApprovedPaid; 