import React, { useState } from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import TableInc from '../../../../../shared-ui/TableInc';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SkeletonWrapper from '../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import { trimTimestampFromDate } from '../../../../../lib/utils.es6';


const ITEM_HEIGHT = 48;

const DisplayHospitals = (props) => {
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedHospitalAction, setSelectedHospitalAction] = useState(null)

  const open = Boolean(anchorEl);


  const handleOpenMenu = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedHospitalAction(rowData)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const hospitals = props.hospitals || [];

  if (props.loader) {
    return <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} style={{ padding: '5%' }} >
        <SkeletonWrapper loading={props.loader} />
      </Grid></Grid>
  }
  return (
    <>

      <TableInc
        columns={[
          {
            title: 'Hospital Name', field: 'name', render: rowData => <>
              {
                rowData.name || "N/A"
              }
            </>
          },
          {
            title: 'Service Offered', field: 'service_offered', render: rowData => <>
              {
                rowData.service_offered || "N/A"
              }
            </>
          },
          {
            title: 'physical Address', field: 'physical_address', render: rowData => <>
              {
                rowData.physical_address || "N/A"
              }
            </>
          },
          {
            title: 'Telephone', field: 'telephone', render: rowData => <>
              {
                rowData.telephone || "N/A"
              }
            </>
          },
          {
            title: 'Region', field: 'region', render: rowData => <>
              {
                rowData.region || "N/A"
              }
            </>
          },

          {
            title: 'Created At', field: 'created_at', render: rowData => <>
              {
                trimTimestampFromDate(rowData.created_at) || "N/A"
              }
            </>
          },
          {
            title: 'Action', field: 'lou_action', render: rowData => {
              return <div >
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? 'long-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={(event) => handleOpenMenu(event, rowData)}

                >
                  <MoreVertIcon />
                </IconButton>
                {
                  selectedHospitalAction && selectedHospitalAction._id
                  === rowData._id
                  && <Menu
                    className={classes.louactionbtns}
                    id="long-menu"
                    MenuListProps={{
                      'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                      },
                    }}
                  >
                    <MenuItem style={{ cursor: "pointer" }} onClick={() => { setAnchorEl(null); props.setHospitalToDelete(rowData);  }}>Delete Hospital</MenuItem>
                  </Menu>
                }

              </div>
            }
          },
        ]}
        data={hospitals}
      options={{
        pageSize: hospitals.length < 5
          ? hospitals.length
          : 15,
        showTitle: false,
        exportButton: true
      }}
      />

    </>
  )
}

export default DisplayHospitals