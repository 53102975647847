import React from 'react'
import PoliciesCardInc from '../../../../../shared-ui/PoliciesCardInc';


const PolicyDetails = (props) => {

  return (
    <>
      <PoliciesCardInc
      data={props.policy}
      
      />

    </>
  )
}

export default PolicyDetails