import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../../lib/actionTypes.es6'
import * as actions from './actions.js';

import {  getPoliciesDcpByBranch } from '../../../../lib/requests.es6';
import { GAloggerException } from '../../../../lib/monitoring.es6';
import { unAuthorizedNotification } from '../../../../lib/access.es6';




export function* getPoliciesDcpRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_POLICIES_DCP_REQUEST);
    try {
      const response = yield call(getPoliciesDcpByBranch, request.payload);
      yield put(actions.getPoliciesDcpSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getPoliciesDcpFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}