import { take, call, put } from 'redux-saga/effects';
import * as types from '../../lib/actionTypes.es6';
import {
  createProductPremium,
  getProductPremiumEnums,
  getProductPremiums,
} from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

export function* createProductPremiumRequestWatcher() {
  for (;;) {
    const request = yield take(types.CREATE_PRODUCT_PREMIUM_REQUEST);
    try {
      const response = yield call(createProductPremium, request.payload);
      yield put(actions.createProductPremiumSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.createProductPremiumFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* getProductPremiumsEnumRequestWatcher() {
  while (yield take(types.PRODUCT_PREMIUM_ENUMS_REQUEST)) {
    try {
      const response = yield call(getProductPremiumEnums);
      yield put(actions.getProductPremiumEnumsSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getProductPremiumEnumsFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* getAllProductPremiumsRequestWatcher() {
  for (;;) {
    const request = yield take(types.VIEW_ALL_PRODUCT_PREMIUMS_REQUEST);
    try {
      const response = yield call(getProductPremiums, request.payload);
      yield put(actions.getAllProductPremiumsSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getAllProductPremiumsFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
