import * as types from '../../lib/actionTypes.es6';

export function reportHospitalClaimRequest(reportType) {
  return {
    type: types.REPORT_HOSPITAL_CLAIM_REQUEST,
    payload: reportType,
  };
}

export function reportHospitalClaimRequestSuccess(reportURL) {
  return {
    type: types.REPORT_HOSPITAL_CLAIM_SUCCESS,
    payload: reportURL,
  };
}

export function reportHospitalClaimRequestError(error) {
  return {
    type: types.REPORT_HOSPITAL_CLAIM_FAILURE,
    payload: error,
  };
}

export function reportHospitalClaimReset() {
  return {
    type: types.REPORT_HOSPITAL_CLAIM_RESET,
  };
}
