import { makeStyles } from "@material-ui/styles";
import banner from '../../../Assets/city-bg.png'

export const useStyle = makeStyles(() => ({

  welcomeheading:{
    "& span":{
      color: "rgb(76, 87, 115);",
    fontSize: "1.125rem",
    fontWeight: "bold",
    fontFamily: "Lato",
    lineHeight: "1.5rem",
    marginLeft: "0.5rem"
    }
  },
  welcomewrapper:{
    margin: "3% 1%;"
  },
  boxoptionwrapper:{
    marginTop: "20px",
    height: "60px",
    alignItems: "center",
    padding: "1rem",
    border: "1px solid rgb(201 201 201)",
    cursor: "pointer",
    borderRadius: "0.5rem",
    backgroundColor: "rgb(255, 255, 255)",
    boxShadow: "rgba(0, 0, 0, 0.08) 0px 7px 20px",
    "& ul":{
      display: "flex",
      padding: "0",
      "& li":{
        display: "inline-block",
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "15px",
        color: "#000000",
        "&:nth-child(2)":{
          marginLeft: "20px",
          marginTop: "5px",
        }
      }
    }
  },
  citybg:{
    backgroundImage: `url(${banner})`,
    paddingBottom: "50px",
    backgroundPosition: 'center bottom',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: "absolute",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    zIndex: "-9999"
  }

}));