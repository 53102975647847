/*
 * This is the main entry point for the Javascript application.
 */
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import * as Sentry from "@sentry/react";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import orange from '@material-ui/core/colors/orange';
import routes from './routes';
import { store } from './store.es6';
import * as serviceWorker from './serviceWorker';
import { getEnviroment, getEnvironmentInformation } from './lib/utils.es6';
import { getPartnerGuid, getUserEmail, getUserFullname, getUserGuid } from './lib/access.es6';
import posthog from 'posthog-js';

import { QueryClient, QueryClientProvider } from 'react-query'
const queryClient = new QueryClient()

const enviroment = getEnviroment();
const isOnProdEnvironment = ['prod'].includes(enviroment);

if (isOnProdEnvironment) {
  posthog.init("phc_cRjiVgmQMXF94mFG5co5wt13ZbL5slKTZ2esJ4ImI94", {
    api_host: "https://app.posthog.com",
  })
}




// react dev tool browser is useful for debugging
if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(store);
}
// #FF8349

const orange700 = orange['700'];
const orange400 = orange['400'];

const muiTheme = getMuiTheme({
  palette: {
    primary1Color: '#ff8349',
    primary2Color: orange700,
    pickerHeaderColor: orange400,
  },
});

function App() {

  useEffect(() => {
    const splashScreen = document.getElementById('splash-screen');
    if (splashScreen) {
      splashScreen.classList.add('hidden');
    }
  }, []);

  if (isOnProdEnvironment) {
    const [identified, setIdentified] = useState(false);

    useEffect(() => {
      if (!identified) {
        posthog.identify(
          getUserGuid(),
          {
            email: `${getUserEmail()}`,
            name: `${getUserFullname()}`,
            partner: `${getPartnerGuid()}`

          }
        );
        setIdentified(true);
      }
    }, [identified]);
  }

  const EnvironmentalInformation = getEnvironmentInformation();
  if (!EnvironmentalInformation) {
    return (
      <center>
        <h1 style={{ color: 'red' }}>
          {' '}
          Environment could not be determined, contact administrator
        </h1>
      </center>
    );
  }

  return (
    <MuiThemeProvider muiTheme={muiTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>{routes}</Provider>
        </QueryClientProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

ReactDOM.render(
  // pass two args, JSX and the app element
  <App />,
  document.getElementById('root') // where to render
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
