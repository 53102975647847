import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(field, fields, theme) {
  return {
    fontWeight:
      fields.indexOf(field) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectChip(props) {
  const theme = useTheme();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    props.setFields(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const isMultiple = (multiple) => {
    if (multiple === false) {
      return false;
    }
    return true;
  }

  return (

    <React.Fragment>
      <InputLabel id="demo-multiple-chip-label" style={{ display: props.displayLabel ? 'block' : 'none'}}>{props.fieldLabel}</InputLabel>
      <FormControl sx={{ m: 1, width: "100%" }} 
          style={props.style}
      >
        <InputLabel id="demo-multiple-chip-label">select one or more {props.fieldLabel}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          label={props.fieldLabel}
          multiple={isMultiple(props.multiple)}
          value={props.fields}
          onChange={handleChange}
          fullWidth
          input={<OutlinedInput id="select-multiple-chip" label={`select one or more ${props.fieldLabel}`} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                typeof value === 'string' ? <Chip key={value} label={value} /> : <Chip key={value.name} label={value.name} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {
            props.constantFields.map((field) => (
              <MenuItem
                key={typeof field === 'string' ? field : field.name}
                value={field}
                style={getStyles(field, props.fields, theme)}
              >
                {
                  typeof field === 'string' ? field : field.name
                }
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );
}
