import { take, call, put } from 'redux-saga/effects';
import { getPayableClaims } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

/**
 * this saga continually watches register request actions
 */
export function* payableClaimRequestWatcher() {
  while (yield take('PAYABLE_CLAIM_REQUEST')) {
    try {
      const response = yield call(getPayableClaims);
      yield put(actions.payableClaimsSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.payableClaimsFail(errorPayload));
    }
  }
}
