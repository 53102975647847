import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  partners: [],
  errors: null,
  loader: false,
  currentPartner: null,

  createdPartner: null,
  createPartnerSuccess: false,
  createPartnerLoader: false,
  createPartnerError: null,
};

export default function partnersReducer(state = initialState, action) {
  switch (action.type) {
    case types.VIEW_ALL_PARTNERS_REQUEST:
      return { ...state, errors: null, loader: true };

    case types.VIEW_ALL_PARTNERS_SUCCESS:
      return {
        ...state,
        partners: action.payload,
        loader: false,
        errors: null,
      };

    case types.VIEW_ALL_PARTNERS_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    case types.CREATE_PARTNER_REQUEST:
      return {
        ...state,
        createPartnerError: null,
        createPartnerLoader: true,
        createPartnerSuccess: false,
        createdPartner: null
      };

    case types.CREATE_PARTNER_SUCCESS:
      return {
        ...state,
        createdPartner: action.payload,
        createPartnerSuccess: true,
        createPartnerLoader: false,
        createPartnerError: null,
      };

    case types.CREATE_PARTNER_FAILURE:
      return { ...state, createPartnerLoader: false, createPartnerError: action.payload };
    
    case types.RESET_CREATE_PARTNER_SUCCESS:
      return { ...state, createPartnerSuccess: false };

    case types.RESET_CREATE_PARTNER_ERROR:
      return { ...state, createPartnerError: null };

    case types.GET_COUNTRIES_REQUEST:
      return {
        ...state,
        errors: null,
        loader: true,
      };

    case types.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
        loader: false,
        errors: null,
      };

    case types.GET_COUNTRIES_FAILED:
      return { ...state, loader: false, errors: action.payload };

    case types.VIEW_PARTNER_REQUEST:
      return { ...state, errors: null, loader: true };

    case types.VIEW_PARTNER_SUCCESS:
      return {
        ...state,
        currentPartner: action.payload,
        loader: false,
        errors: null,
      };

    case types.VIEW_PARTNER_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    case types.GET_LANGUAGES_REQUEST:
      return {
        ...state,
        errors: null,
        loader: true,
      };

    case types.GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: action.payload,
        loader: false,
        errors: null,
      };

    case types.GET_LANGUAGES_FAILED:
      return { ...state, loader: false, errors: action.payload };

    case types.USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
}
