import * as types from '../../lib/actionTypes.es6';

export function policiesCreatedReportRequest(reportType) {
  return {
    type: types.POLICIES_CREATED_REPORT_REQUEST,
    payload: reportType,
  };
}

export function policiesCreatedReportRequestSuccess(reportURL) {
  return {
    type: types.POLICIES_CREATED_REPORT_REQUEST_SUCCESS,
    payload: reportURL,
  };
}

export function policiesCreatedReportRequestError(error) {
  return {
    type: types.POLICIES_CREATED_REPORT_REQUEST_ERROR,
    payload: error,
  };
}

export function policiesCreatedReportRequestReset() {
  return {
    type: types.POLICIES_CREATED_REPORT_REQUEST_RESET,
  };
}
