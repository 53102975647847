import React from 'react';
import { connect } from 'react-redux';
import { hashHistory } from 'react-router';
import ReactPaginate from 'react-paginate';
import Loader from 'react-loader';
import LocalizedStrings from 'react-localization';
import { updateCurrentLanguage } from '../../../lib/languageUtils.es6';
import { localisedText } from '../../../lib/localisation.es6';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Button, DialogContent,
  Dialog, Typography, Card, CardContent, Grid, Backdrop, Snackbar
} from '@material-ui/core';
import MaterialTable from 'material-table';
import * as actions from './actions.es6';
import * as utils from '../../../lib/utils.es6';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import AlertDialog from '../../../components/AlertDialog/index.es6';
import { CircularProgress } from 'material-ui';
import { getCurrentGroupMembersForCSVRequest, resetGetCUrrentGroupMembersForCSV } from '../GroupMembers/actions.es6';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../lib/constants';
import {getPartner, hasAuthourity} from "../../../lib/access.es6";

export class Groups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
      addPaymentDialog: false,
      showPaymentConfirmation: false,
      selectedGroup: {},
      effectedDate: '',
      amountSelected: '',
      paymentReference: '',
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      copy: false,
      showDeleteGroupConfirmation: false,
    };
    this.handlePaymentEffectedDateChange = this.handlePaymentEffectedDateChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentWillMount() {
    this.updateLanguage();
    this.getGroups();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  componentWillUnmount() {
    this.props.dispatch(resetGetCUrrentGroupMembersForCSV())
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'GroupRegistration',
      this.state.labels
    );
  }

  getGroups = () => {
    const { page, size } = this.state;
    this.props.dispatch(actions.getAllGroupsRequest({ page, size }))
  }

  addPayment() {
    this.setState({ showPaymentConfirmation: false })
    const { paymentReference, effectedDate, selectedGroup } = this.state;
    let payload = {
      amount_in_cent: selectedGroup.pre_computed_premium_in_cents,
      status: "Succeeded",
      reference: paymentReference,
      effected_at: effectedDate,
      channel: "WebClient",
      sub_channel: "MANUAL",
      group_id: selectedGroup.id
    }
    this.props.dispatch(actions.addGroupPaymentRequest(payload))

  }

  handlePaymentEffectedDateChange(event) {
    const rawDate = utils.formatDate(new Date(event.target.value));
    this.setState({ effectedDate: rawDate });
  }

  handleSelectAmount(event) {
    this.setState({ amountSelected: event.target.value })
  }

  handlePaymentReferenceChange(event) {
    this.setState({ paymentReference: event.target.value })
  }

  resetFields() {
    this.setState({
      addPaymentDialog: false,
      selectedGroup: {},
      effectedDate: '',
      amountSelected: '',
      paymentReference: '',
    })
  }

  reset() {
    this.resetFields()
    this.props.dispatch(actions.resetAddGroupPayment())
  }

  handleMenuOpen(rowData, event) {
    this.setState({ selectedGroup: rowData, anchorEl: event.currentTarget });
  };

  handleMenuClose() {
    this.setState({ anchorEl: null });
  };

  goToMembers() {
    hashHistory.push(`admin/group-status/${this.state.selectedGroup.id}`)
  }

  goToAddMembers() {
    hashHistory.push(`admin/add-group-members/${this.state.selectedGroup.id}`)
  }

  getGroupMembers() {
    let groupId = this.state.selectedGroup.id
    this.props.dispatch(getCurrentGroupMembersForCSVRequest(groupId));
  }

  prepareCSVData(){
    let members = this.props.currentGroupData.members.items ? this.props.currentGroupData.members.items : [] ;
    let membersCSV = [
      ['name', 'DOB', 'National Id', 'Msisdn', 'Relationship', 'Principal Id']
    ];

    for (let i = 0; i < members.length; i++) {
      const member = members[i];
      const fullName = member.full_name;
      const dob = member.date_of_birth;
      const nationalId = member.national_id;
      const msisdn = member.msisdn;
      const relationship = 'Principal'
      const row = [fullName, dob, nationalId, msisdn, relationship, nationalId];
      membersCSV.push(row);

      let dependants = member.dependantDto
      if (dependants.length > 0) {
        for (let d = 0; d < dependants.length; d++) {
          const dependant = dependants[d];

          const dependantFullName = dependant.firstName + " " + dependant.middleName + " " + dependant.lastName;
          const dependantDob = dependant.dob;
          const dependantNationalId = dependant.national_id;
          const dependantMsisdn = dependant.msisdn;
          const dependantRelationship = dependant.relationship
          const dependantRow = [dependantFullName, dependantDob, dependantNationalId, dependantMsisdn, dependantRelationship, nationalId]
          membersCSV.push(dependantRow)
        }
      }
    }
    return membersCSV
  }
  downloadMembersCSV() {
    const groupName = this.state.selectedGroup.name
    const rows = this.prepareCSVData()

    let csvContent = "data:text/csv;charset=utf-8,"
      + rows.map(e => e.join(",")).join("\n");

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';

    const url = encodeURI(csvContent);
    a.href = url;
    a.download = groupName + '_members.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  resetDownloadMembers() {
    this.setState({ anchorEl: null, selectedGroup: {} })
    this.props.dispatch(resetGetCUrrentGroupMembersForCSV())
  }

  errorHandler(message) {
    this.setState({ message });
  }

  openCertificate(certificateUrl) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';

    const url = encodeURI(certificateUrl);
    a.href = url;
    a.target = "_blank"
    a.download = 'sample.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  generateGroupLink() {
    const registrationLink = this.state.selectedGroup.registration_link;
    return `${utils.getGroupMobisiteBaseURL()}${registrationLink}`;
  }

  copyGroupLink() {
    const groupMobisiteURL = this.generateGroupLink();
    navigator.clipboard.writeText(groupMobisiteURL);
    this.setState({ copy: true })
  }

  closeDialog = () => this.setState({ addPaymentDialog: false, selectedGroup: {} })

  openDialog = () => this.setState({ addPaymentDialog: true })

  getPremiums = (group) => {

    if (!group || Object.keys(group).length === 0) {
      return "";
    }
    const productPremiums = group.product.premiums || [];
    const amountDisplay = []
    for (let i = 0; i < productPremiums.length; i++) {
      const productPremium = productPremiums[i];
      amountDisplay.push(((productPremium.amount_in_cents * group.group_size) / 100).toFixed(2))
    }
    return amountDisplay;
  }

  displayAmountDue = (group) => {

    if (!group || Object.keys(group).length === 0) {
      return "";
    }
    const productPremiums = group.product.premiums || [];
    const amountDisplay = []
    const currencyPartner =  getPartner();
    const currencyCode = currencyPartner ? currencyPartner.country.currency_code : "";
    for (let i = 0; i < productPremiums.length; i++) {
      const productPremium = productPremiums[i];
      amountDisplay.push(`${currencyCode} ${((productPremium.amount_in_cents * group.group_size) / 100).toFixed(2)} per ${productPremium.granularity}`)
    }
    return amountDisplay.join(" OR ");
  }


  handleChangePageSize(event) {
    event.preventDefault();
    const size = parseInt(event.target.value);
    console.log('size: ', size);
    this.setState({ size }, function () {
      this.getGroups()
    });
  }

  handlePageClick(data) {
    const page = data.selected;
    console.log('page: ', page);
    this.setState({ page }, function () {
      this.getGroups()
    });
  }

  handleDelete(){
    let groupId = this.state.selectedGroup.id;
    const { page, size } = this.state;
    this.props.dispatch(actions.deleteGroupRequest({groupId, page, size}));
    this.setState({ showDeleteGroupConfirmation: false });
  }

  getPages() {
    return Math.ceil(this.props.groupsData.count / this.state.size);
  }

  pageSizeSelector() {
    const { size } = this.state;
    const display = {
      display: this.props.groupsData.count > size ? '' : 'none',
    };
    return (
      <select
        value={size}
        onChange={this.handleChangePageSize.bind(this)}
        style={display}
      >
        <option>5</option>
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    );
  }

  paginateGroups() {
    const pages = this.getPages();
    const display = {
      display: pages > 1 ? '' : 'none',
    };
    const labels = this.state.labels;
    return (
      <div style={display}>
        <ReactPaginate
          previousLabel={labels.paginationPreviousText}
          nextLabel={labels.paginationNextText}
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick.bind(this)}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
          forcePage={this.state.page}
        />
      </div>
    );
  }

  renderPagination() {
    return <Grid container style={{ alignItems: "right", backgroundColor: 'white' }}>
      <Grid item xs={11} style={{ textAlign: "right", backgroundColor: 'white' }}> {this.paginateGroups()}</Grid>
      <Grid item xs={1} style={{ textAlign: "left", backgroundColor: 'white', paddingLeft: '1%', margin: "auto" }}> {this.pageSizeSelector()} </Grid>
    </Grid>
  }

  render() {
    const groups = this.props.groupsData.groups || [];
    const groupPaymentLoader = this.props.groupsData.groupPaymentLoader
    const groupPaymentError = this.props.groupsData.groupPaymentError
    const groupPaymentSuccess = this.props.groupsData.groupPaymentSuccess
    const loaderMembers = this.props.currentGroupData.loaderMembers
    const membersLoadedSuccess = this.props.currentGroupData.membersLoadedSuccess
    const membersError = this.props.currentGroupData.membersError
    const isLoading = this.props.groupsData.loader;
    const currencyPartner =  getPartner();
    const currencyCode = currencyPartner ? currencyPartner.country.currency_code : "";
    // const isLoading = true;

    const labels = this.state.labels;
    const btnStyle = {
      margin: '2%',
      green: {
        backgroundColor: 'orangered',
        color: 'white',
        margin: '2px',
        height: '50px',
        padding: '0 30px',
      },
      danger: {
        backgroundColor: 'green',
        color: 'white',
        margin: '2px',
        height: '50px',
        padding: '0 30px',
      },
    };

    return (
      <div>
        <Loader loaded={!this.props.groupRegistrationData.loader} color="#ff834f">
          <div className="row" style={{ width: '98%', margin: "auto" }}>
            {this.props.groupRegistrationData.groupRegistrationError ? this.groupRegisterationError() : ''}
            <div className="col-md-12 col-sm-12 col-xs-12" >
              <div className="">
                {this.renderPagination()}
                <MaterialTable
                  title="All Groups"
                  columns={[
                    { title: 'ID', field: 'id' },
                    { title: 'Registered', field: 'created_at', render: rowData => { return new Date(rowData.created_at).toLocaleDateString("en-US") } },
                    { title: 'Name', field: 'name' },
                    { title: 'Product', field: 'product', render: rowData => rowData.product.name },
                    { title: 'Email', field: 'email', sorting: false, },
                    { title: 'Telephone', field: 'telephone', sorting: false, },
                    { title: 'Occupation', field: 'occupation' },
                    { title: 'KRA Pin', field: 'kra_pin', sorting: false },
                    {
                      title: 'action', field: 'kra_pin', render: rowData => {
                        return <div>
                          {/* <Button
                                 variant="contained"
                                 color="primary"
                                 size="small"
                                 style={{ backgroundColor: 'green', color: '#fff'}}
                                 onClick={ this.goToMembers.bind(this, rowData.id)}>
                                 View
                               </Button> */}

                          <MoreVertIcon
                            onClick={this.handleMenuOpen.bind(this, rowData)}
                            style={{ float: 'left' }}
                          />
                           {  ( this.state.selectedGroup.id === rowData.id) && <Menu
                            id="simple-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleMenuClose.bind(this)}
                            name={rowData}>
                            <MenuItem onClick={this.goToMembers.bind(this)}>View Members</MenuItem>
                            <MenuItem onClick={this.goToAddMembers.bind(this)} disabled={this.state.selectedGroup.registration_status === 'Closed'}>Edit Group</MenuItem>
                            <MenuItem onClick={() => this.openDialog()}>add payment</MenuItem>
                            <MenuItem disabled={!this.state.selectedGroup.registration_link || this.state.selectedGroup.registration_status === 'Closed'} onClick={this.copyGroupLink.bind(this)}>Copy Group Link </MenuItem>
                            <MenuItem onClick={this.getGroupMembers.bind(this)}>Download members</MenuItem>
                            <MenuItem disabled={!hasAuthourity('DELETE_GROUP') || this.state.selectedGroup.registration_status === 'Closed'} onClick={() => this.setState({ showDeleteGroupConfirmation: true })}>Delete Group</MenuItem>
                          </Menu>}
                        </div>
                      }
                    },
                  ]}
                  data={groups}
                  actions={[
                    {
                      icon: 'add',
                      tooltip: 'Register New Group',
                      isFreeAction: true,
                      onClick: (event) => hashHistory.push(`admin/group-registeration`)
                    }
                  ]}
                  options={{
                    search: true,
                    sorting: true,
                    exportButton: true,
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    headerStyle: {
                      backgroundColor: '#003643',
                      color: '#FFF'
                    }
                  }}
                  // options={{
                  //   showTitle: true,
                  //   emptyRowsWhenPaging: false,
                  //   paging: false,
                  // }}
                />
                {this.renderPagination()}
                <br />
              </div>
            </div>
            <hr />
          </div>
        </Loader>
        <Dialog maxWidth="md" fullWidth onClose={this.closeDialog} aria-labelledby="customized-dialog-title"
          // open={false}
          open={this.state.addPaymentDialog}
        >
          <DialogContent dividers>
            <Grid justify="space-between" container style={{ width: '80%', margin: 'auto' }}>
              <Grid item sm={12}>
                <h1 style={{ marginBottom: '7%' }}>   Add a payment for group: <span style={{ color: '#06c' }}>{this.state.selectedGroup.name} </span> </h1>
                <ValidatorForm onSubmit={() => { this.setState({ showPaymentConfirmation: true, addPaymentDialog: false }) }} >
                  <Grid container spacing={3}>
                    <Grid container item xs={12} spacing={3} style={{ marginBottom: '1%' }}>
                      <Grid item md={12} xs={12}>
                        <Typography> Premium amount {currencyCode}:{this.state.selectedGroup.pre_computed_premium_in_cents / 100} </Typography>
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <TextValidator label={'Payment Reference'}
                          fullWidth
                          variant="outlined"
                          type="text"
                          value={this.state.paymentReference}
                          onChange={this.handlePaymentReferenceChange.bind(this)}
                          name="paymentReference"
                          aria-describedby="emailHelp"
                          placeholder={'Payment Reference'}
                          validators={['required']}
                          errorMessages={['Please enter payment reference']} />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextValidator label={labels.effectedAt}
                          placeholder={labels.effectedAt}
                          fullWidth
                          variant="outlined"
                          type="date"
                          value={this.state.effectedDate}
                          onChange={this.handlePaymentEffectedDateChange}
                          name="EffectedDate"
                          aria-describedby="emailHelp"
                          validators={['required']}
                          errorMessages={[labels.missingPaymentEffectedDateError]}
                          InputLabelProps={{ shrink: true, }} />
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={12} style={{ marginBottom: '1%' }}>
                      <Button
                        variant="contained"
                        style={btnStyle.danger}
                        type="submit">
                        {'Add Payment'}
                      </Button>
                      <Button
                        onClick={this.resetFields.bind(this)}
                        variant="contained"
                        style={btnStyle.green}>
                        {labels.cancel}
                      </Button>
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <AlertDialog
          show={this.state.showPaymentConfirmation}
          warning
          title={'Comfirm Payment Details'}
          onConfirm={this.addPayment.bind(this)}
          confirmBtnText={'Add Payment'}
          confirmBtnCssClass
          showCancel
          onCancel={() => this.setState({ showPaymentConfirmation: false })}
        >
          <Card style={{ margin: 'auto', width: '80%', textAlign: 'centre' }}>
            <CardContent>
              <Typography> Premium amount {currencyCode}:{this.state.selectedGroup.pre_computed_premium_in_cents / 100} </Typography>
              <Typography color="textSecondary">
                Reference: <b>{this.state.paymentReference}</b>
              </Typography>
              <Typography color="textSecondary">
                Effected Date: <b>{this.state.effectedDate}</b>
              </Typography>
              <Typography color="textSecondary">
                Account Number: <b>{`${this.state.selectedGroup.name} - ${this.state.selectedGroup.id}`}</b>
              </Typography>
              <Typography color="textSecondary">
                Group Id: <b>{`${this.state.selectedGroup.id}`}</b>
              </Typography>
            </CardContent>
          </Card>
        </AlertDialog>

        <AlertDialog
          success
          show={!!groupPaymentSuccess}
          title={'Successfully Added Payment'}
          onConfirm={this.reset.bind(this)}
          cancelBtnText={labels.tryAgain}
          showConfirm={true}
          showCancel={false}
          onCancel={() => this.props.dispatch(actions.resetAddGroupPayment())}
          confirmBtnCssClass
        >
          {groupPaymentSuccess ? groupPaymentSuccess.message : ''}
        </AlertDialog>

        <AlertDialog
          danger
          show={!!groupPaymentError}
          title={'Could not add payment'}
          onConfirm={() => this.props.dispatch(actions.resetAddGroupPayment())}
          cancelBtnText={labels.tryAgain}
          showConfirm={false}
          showCancel={true}
          onCancel={() => this.props.dispatch(actions.resetAddGroupPayment())}
          confirmBtnCssClass
        >
          {groupPaymentError ? groupPaymentError.message : ''}
        </AlertDialog>

        <AlertDialog
          success
          show={membersLoadedSuccess}
          title={'Members data ready for download'}
          cancelBtnText={'Close'}
          confirmBtnText={'Download Members'}
          showConfirm={true}
          showCancel={true}
          onConfirm={() => this.downloadMembersCSV()}
          onCancel={() => this.resetDownloadMembers()}
          confirmBtnCssClass
        >
        </AlertDialog>

        <AlertDialog
          danger
          show={!!membersError}
          title={'Could not download members'}
          onConfirm={() => this.resetDownloadMembers()}
          cancelBtnText={labels.tryAgain}
          showConfirm={false}
          showCancel={true}
          onCancel={() => this.resetDownloadMembers()}
          confirmBtnCssClass
        >
        </AlertDialog>

        <AlertDialog
          show={this.state.showDeleteGroupConfirmation}
          warning
          title={'Confirm Delete Group'}
          onConfirm={this.handleDelete}
          confirmBtnText={'Delete Group'}
          confirmBtnCssClass
          showCancel
          onCancel={() => this.setState({ showDeleteGroupConfirmation: false })}
        />

        <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={groupPaymentLoader}>
          <CircularProgress size={80} color="#fff" />
          <h3 style={{ marginTop: '15%', marginLeft: '-15%' }}>..... Adding Group Payment.....</h3>
        </Backdrop>

        <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={loaderMembers}>
          <CircularProgress size={80} color="#fff" />
          <h3 style={{ marginTop: '15%', marginLeft: '-15%' }}>..... Getting Group Members.....</h3>
        </Backdrop>
        <Backdrop style={{ zIndex: 3000, color: 'white' }} open={isLoading}>
          <CircularProgress color="#fff" />
        </Backdrop>

        <Snackbar
          open={this.state.copy}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => this.setState({ copy: false })}
          message="Group link copied to clipboard"
        />

      </div>
    );
  }
}

export default connect(state => ({
  groupRegistrationData: state.groupRegistration,
  globalData: state.global,
  groupsData: state.listAllGroups,
  currentGroupData: state.currentGroup,
}))(Groups);
