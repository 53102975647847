import * as types from "../../lib/actionTypes.es6";


//get custommer beneficiary
export function getCustomerBeneficiaryRequest(payload) {
  return {
    type: types.GET_CUSTOMER_BENEFICIARY_REQUEST,
    payload,
  };
}

export function getCustomerBeneficiarySuccess(response) {
  return {
    type: types.GET_CUSTOMER_BENEFICIARY_SUCCESS,
    payload: response,
  };
}

export function getCustomerBeneficiaryFailed(error) {
  return {
    type: types.GET_CUSTOMER_BENEFICIARY_FAILURE,
    payload: error,
  };
}


//add customer beneficiary

export function addCustomerBeneficiaryRequest(payload) {
  return {
    type: types.ADD_CUSTOMER_BENEFICIARY_REQUEST,
    payload,
  };
}


export function addCustomerBeneficiarySuccess(response) {
  return {
    type: types.ADD_CUSTOMER_BENEFICIARY_SUCCESS,
    payload: response,
  };
}

export function addCustomerBeneficiaryFailed(error) {
  return {
    type: types.ADD_CUSTOMER_BENEFICIARY_FAILURE,
    payload: error,
  };
}

export function resetAddCustomerBeneficiaryDetailsSuccess() {
  return {
    type: types.RESET_ADD_CUSTOMER_BENEFICIARY_DETAILS_SUCCESS,
  };
}
export function resetAddCustomerBeneficiaryDetailsFailed() {
  return {
    type: types.RESET_ADD_CUSTOMER_BENEFICIARY_DETAILS_ERROR,
  };
}

export function editCustomerBeneficiaryRequest(payload) {
  return {
    type: types.UPDATE_CUSTOMER_BENEFICIARY_REQUEST,
    payload,
  };
}

export function editCustomerBeneficiarySuccess(response) {
  return {
    type: types.UPDATE_CUSTOMER_BENEFICIARY_SUCCESS,
    payload: response,
  };
}

export function editCustomerBeneficiaryFailed(error) {
  return {
    type: types.UPDATE_CUSTOMER_BENEFICIARY_FAILURE,
    payload: error,
  };

}


//delete customer beneficiary

export function deleteCustomerBeneficiaryRequest(payload) {
  return {
    type: types.DELETE_CUSTOMER_BENEFICIARY_REQUEST,
    payload,
  };
}

export function deleteCustomerBeneficiarySuccess(response) {
  return {
    type: types.DELETE_CUSTOMER_BENEFICIARY_SUCCESS,
    payload: response,
  };
}

export function deleteCustomerBeneficiaryFailed(error) {
  return {
    type: types.DELETE_CUSTOMER_BENEFICIARY_FAILURE,
    payload: error,
  };
}


export function resetDeleteCustomerBeneficiarySuccess() {
  return {
    type: types.RESET_DELETE_CUSTOMER_BENEFICIARY_SUCCESS,
  };
}

export function resetDeleteCustomerBeneficiaryFailed() {
  return {
    type: types.RESET_DELETE_CUSTOMER_BENEFICIARY_ERROR,
  };
}

// set customer beneficiary details to delete
export function setCustomerBeneficiaryDetailsToDelete(customerBeneficiaryDetails) {
  return {
    type: types.SET_CUSTOMER_BENEFICIARY_DETAILS_TO_DELETE,
    payload: customerBeneficiaryDetails,
  };
}
export function resetCustomerBeneficiaryDetailsToDelete() {
  return {
    type: types.SET_CUSTOMER_BENEFICIARY_DETAILS_TO_DELETE,
  };
}


// set customer beneficiary details to edit new
export function setCustomerBeneficiaryDetailsToEdit(customerBeneficiaryDetails) {
  return {
    type: types.SET_CUSTOMER_BENEFICIARY_DETAILS_TO_EDIT,
    payload: customerBeneficiaryDetails,
  };
}
export function resetCustomerBeneficiaryDetailsToEdit() {
  return {
    type: types.SET_CUSTOMER_BENEFICIARY_DETAILS_TO_EDIT,
  };
}

//edit customer

export function resetEditCustomerBeneficiarySuccess() {
  return {
    type: types.RESET_EDIT_CUSTOMER_BENEFICIARY_SUCCESS,
  };
}

export function resetEditCustomerBeneficiaryFailed() {
  return {
    type: types.RESET_EDIT_CUSTOMER_BENEFICIARY_ERROR,
  };
}



export function setCustomerBeneficiaryToDeleteFailed(dependant) {
  return {
    type: types.SET_CUSTOMER_BENEFICIARY_TO_DELETE,
    payload: dependant,
  };
}


export function resetCustomerBeneficiaryToEdit() {
  return {
    type: types.RESET_CUSTOMER_BENEFICIARY_TO_EDIT,
  };
}

export function resetCustomerBeneficiaryToDelete() {
  return {
    type: types.RESET_CUSTOMER_BENEFICIARY_TO_DELETE,
  };
}