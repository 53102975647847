import * as types from '../../lib/actionTypes.es6';


// PARTNER CONFIG
export function getPartnerConfigsRequest(payload) {
  return {
    type: types.GET_PARTNER_CONFIGS_REQUEST,
    payload,
  };
}

export function getPartnerConfigsFailure(error) {
  return {
    type: types.GET_PARTNER_CONFIGS_FAILURE,
    payload: error,
  };
}

export function getPartnerConfigsSuccess(response) {
  return {
    type: types.GET_PARTNER_CONFIGS_SUCCESS,
    payload: response,
  };
}


// PARTNER CONFIG CLAIM
export function getPartnerConfigsClaimRequest(payload) {
  return {
    type: types.GET_PARTNER_CONFIGS_CLAIM_REQUEST,
    payload,
  };
}

export function getPartnerConfigsClaimFailure(error) {
  return {
    type: types.GET_PARTNER_CONFIGS_CLAIM_FAILURE,
    payload: error,
  };
}

export function getPartnerConfigsClaimSuccess(response) {
  return {
    type: types.GET_PARTNER_CONFIGS_CLAIM_SUCCESS,
    payload: response,
  };
}

// PARTNER CONFIG GROUP
export function getPartnerConfigsGroupRequest(payload) {
  return {
    type: types.GET_PARTNER_CONFIGS_GROUP_REQUEST,
    payload,
  };
}

export function getPartnerConfigsGroupFailure(error) {
  return {
    type: types.GET_PARTNER_CONFIGS_GROUP_FAILURE,
    payload: error,
  };
}

export function getPartnerConfigsGroupSuccess(response) {
  return {
    type: types.GET_PARTNER_CONFIGS_GROUP_SUCCESS,
    payload: response,
  };
}



// update PARTNER
export function updatePartnerRequest(payload) {
  return {
    type: types.UPDATE_PARTNER_REQUEST,
    payload,
  };
}

export function updatePartnerFailure(error) {
  return {
    type: types.UPDATE_PARTNER_FAILURE,
    payload: error,
  };
}

export function updatePartnerSuccess(response) {
  return {
    type: types.UPDATE_PARTNER_SUCCESS,
    payload: response,
  };
}

export function resetUpdatePartnerSuccess(error) {
  return {
    type: types.RESET_UPDATE_PARTNER_SUCCESS,
  };
}

export function resetUpdatePartnerError(error) {
  return {
    type: types.RESET_UPDATE_PARTNER_ERROR,
  };
}

// update PARTNER CONFIG
export function updatePartnerConfigRequest(payload) {
  return {
    type: types.UPDATE_PARTNER_CONFIG_REQUEST,
    payload,
  };
}

export function updatePartnerConfigFailure(error) {
  return {
    type: types.UPDATE_PARTNER_CONFIG_FAILURE,
    payload: error,
  };
}

export function updatePartnerConfigSuccess(response) {
  return {
    type: types.UPDATE_PARTNER_CONFIG_SUCCESS,
    payload: response,
  };
}

export function resetUpdatePartnerConfigSuccess(error) {
  return {
    type: types.RESET_UPDATE_PARTNER_CONFIG_SUCCESS,
  };
}

export function resetUpdatePartnerConfigError(error) {
  return {
    type: types.RESET_UPDATE_PARTNER_CONFIG_ERROR,
  };
}


// update PARTNER CONFIG CLAIM
export function updatePartnerConfigClaimRequest(payload) {
  return {
    type: types.UPDATE_PARTNER_CONFIG_CLAIM_REQUEST,
    payload,
  };
}

export function updatePartnerConfigClaimFailure(error) {
  return {
    type: types.UPDATE_PARTNER_CONFIG_CLAIM_FAILURE,
    payload: error,
  };
}

export function updatePartnerConfigClaimSuccess(response) {
  return {
    type: types.UPDATE_PARTNER_CONFIG_CLAIM_SUCCESS,
    payload: response,
  };
}

export function resetUpdatePartnerConfigClaiimSuccess(error) {
  return {
    type: types.RESET_UPDATE_PARTNER_CONFIG_CLAIM_SUCCESS,
  };
}

export function resetUpdatePartnerConfigClaiimError(error) {
  return {
    type: types.RESET_UPDATE_PARTNER_CONFIG_CLAIM_ERROR,
  };
}

// update PARTNER CONFIG CLAIM
export function updatePartnerConfigGroupRequest(payload) {
  return {
    type: types.UPDATE_PARTNER_CONFIG_GROUP_REQUEST,
    payload,
  };
}

export function updatePartnerConfigGroupFailure(error) {
  return {
    type: types.UPDATE_PARTNER_CONFIG_GROUP_FAILURE,
    payload: error,
  };
}

export function updatePartnerConfigGroupSuccess(response) {
  return {
    type: types.UPDATE_PARTNER_CONFIG_GROUP_SUCCESS,
    payload: response,
  };
}

export function resetUpdatePartnerConfigGroupSuccess() {
  return {
    type: types.RESET_UPDATE_PARTNER_CONFIG_GROUP_SUCCESS,
  };
}

export function resetUpdatePartnerConfigGroupError(error) {
  return {
    type: types.RESET_UPDATE_PARTNER_CONFIG_GROUP_ERROR,
  };
}