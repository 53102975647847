import React from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from "react-redux";
import Quotes from './Components/Quotes/Quotes';
import { localisedText } from '../../../../../lib/localisation.es6';
import {  getNGSGroupCustomerQuotesRequest } from './Redux/actions';

class NGSGroupQuotes extends React.Component {

	constructor(props) {
		const localizedStrings = new LocalizedStrings(localisedText);
		super(props);
		this.state = {
			labels: localizedStrings,

		};
	}

	componentWillMount() {
		
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      this.props.dispatch(getNGSGroupCustomerQuotesRequest({ guid: currentCustomer.guid }))
    }


	}

	componentDidUpdate() {
		this.updateLanguage();
	}

	updateLanguage() {
		const language = this.props.globalData.language;
		let resetLanguage = false;
		if (!language) {
			resetLanguage = true;
		}
		const labels = this.state.labels;
		if (resetLanguage || labels.getLanguage() !== language) {
			labels.setLanguage(language);
			this.setState({});
		}
	}





	render() {


		if (this.props.NGSGroupQuoteData.quotes) {
			return (
				<>
				<Quotes
        quotes={this.props.NGSGroupQuoteData.quotes}
        loader={this.props.NGSGroupQuoteData.get_quotes_loader}
        />

				</>
			)
		} else {
			return ""
		}

	}
}
export default connect((state) => ({
	customerData: state.currentCustomer,
	NGSGroupQuoteData: state.NGSGroupQuoteData,
	globalData: state.global,
}))(NGSGroupQuotes);