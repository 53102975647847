import { take, call, put } from 'redux-saga/effects';
import {
  getPendingDebitOrderTransactions,
  updateDebitOrderTransactions,
  getFailedDebitOrderTransactions,
} from '../../lib/requests.es6';
import * as actions from './actions.es6';
import * as types from '../../lib/actionTypes.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';

export function* debitOrderTransactionsRequestWatcher() {
  while (yield take(types.GET_DAILY_DEBIT_ORDER_REQUEST)) {
    try {
      const pendingTransactions = yield call(getPendingDebitOrderTransactions);
      const failedTransactions = yield call(getFailedDebitOrderTransactions);

      let allTransactions = [];
      allTransactions = allTransactions.concat(
        pendingTransactions.data,
        failedTransactions.data
      );
      yield put(actions.getDebitOrderTransactionsSuccess(allTransactions));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.getDebitOrderTransactionsFailure(errorPayload));
    }
  }
}

export function* updateDebitOrderTransactionsRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.UPDATE_DAILY_DEBIT_ORDER_REQUEST);
      const response = yield call(updateDebitOrderTransactions, payload);
      yield put(actions.updateDebitOrderTransactionsSuccess(response.data));
      yield put(actions.getDebitOrderTransactionsRequest());
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.updateDebitOrderTransactionsFailure(errorPayload));
    }
  }
}
