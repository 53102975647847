import * as types from '../../../../lib/actionTypes.es6'

export function getClaimsDcpRequest(payload){
  return{
    type: types.GET_CLAIMS_DCP_REQUEST,
    payload: payload
  }
}


export const getClaimsDcpSuccess = (payload) =>{
  return{
    type: types.GET_CLAIMS_DCP_SUCCESS,
    payload: payload,
  }
}

export const getClaimsDcpFailure = (error) =>{
  return{
    type: types.GET_CLAIMS_DCP_FAILURE,
    payload: error,
  }
}

export function getClaimsDcpFilteredRequest(payload){
  return{
    type: types.GET_CLAIMS_DCP_FILTERED_REQUEST,
    payload: payload
  }
}


export const getClaimsDcpFilteredSuccess = (payload) =>{
  return{
    type: types.GET_CLAIMS_DCP_FILTERED_SUCCESS,
    payload: payload,
  }
}

export const getClaimsDcpFilteredFailure = (error) =>{
  return{
    type: types.GET_CLAIMS_DCP_FILTERED_FAILURE,
    payload: error,
  }
}


export function bulkApproveRequest(payload){
  return{
    type: types.BULK_APPROVE_REQUEST,
    payload: payload
  }

}

export function bulkApproveSuccess(payload){
  return{
    type: types.BULK_APPROVE_SUCCESS,
    payload: payload
  }

}


export function bulkApproveFailure(error){
  return{
    type: types.BULK_APPROVE_FAILURE,
    payload: error
  }
}


export function resetbulkApprovalSuccess() {
  return {
    type: types.RESET_BULK_APPROVE_SUCCESS,
  };
}
export function resetbulkApprovalFailure() {
  return {
    type: types.RESET_BULK_APPROVE_FAILURE,
  };
}