export const GRANULARITY = Object.freeze([
  'Day', 'Week', 'Month', 'Year'
])

export const PRODUCT_TYPES_FREE = Object.freeze([
  'Free', 'Free_Agent'
])

export const PRODUCT_TYPES_PAID = Object.freeze([
  'Paid', 'Paid_Bundled'
])

export const PRODUCT_TYPES = Object.freeze([...PRODUCT_TYPES_FREE, ...PRODUCT_TYPES_PAID])

export const COVER_TYPES = Object.freeze([
  'Individual', 'Family'
])

export const PAYMENT_METHODS = Object.freeze([
  'Mobile_Money', 'Airtime'
])

export const BENEFIT_NAMES = Object.freeze({
  CASHBACK: ['Cashback'],
  FIXED: [
    'Death',
    'Property',
    'Personal_Accident',
    'Medical_Expense',
    'Funeral',
    'Inpatient',
    'Maternity',
    'Outpatient',
  ],
  VARIABLE: ['Hospital', 'Income_Loss'],
});

export const RELATIONSHIP_POOL = {
  Principal : 'Principal',
  Spouse : 'Spouse',
  Child : 'Child',
  Parent : 'Parent',
  Parent_In_Law : 'Parent_In_Law',
  Beneficiary_Adult : 'Beneficiary_Adult',
  Husband : 'Husband',
  Wife : 'Wife',
  Daughter : 'Daughter',
  Son : 'Son',
  Mother : 'Mother',
  Father : 'Father',
  Mother_In_Law : 'Mother_In_Law',
  Father_In_Law : 'Father_In_Law',
  Uncle : 'Uncle',
  Aunt : 'Aunt',
  Sister : 'Sister',
  Brother : 'Brother',
  Cousin : 'Cousin',
  Nephew : 'Nephew',
  Extended : 'Extended',
  Brother_In_Law : 'Brother_In_Law',
  Niece : 'Niece',
  Grandmother : 'Grandmother',
  Grandfather : 'Grandfather',
  Grandson : 'Grandson',
  Granddaughter : 'Granddaughter',
  Grandchild : 'Grandchild',
  Sister_In_Law : 'Sister_In_Law',
  Dependant : 'Dependant',
  Employee : 'Employee',
  Other : 'Other'
}

export const SOUTH_AFRICA_BANKS_NAME = {
  ABSA : "ABSA",
  AfricanBank : "African Bank",
  Bidvest: "Bidvest",
  Capitec: "Capitec",
  FNB  : "FNB",
  Investec: "Investec",
  MeegBank: "Meeg Bank",
  Nedbank: "Nedbank",
  Postbank: "Postbank",
  StandardBankofSA: "Standard Bank of SA"
}

export const DEBIT_ORDER_SOUTH_AFRICA_BANKS_NAME = {
  ABSA : { name: "ABSA", branch_code: '632005' },
  Bidvest: { name: "Bidvest", branch_code: '462005' },
  Capitec: { name: "Capitec", branch_code: '470010' },
  FNB  : { name: "FNB", branch_code: '25655' },
  Investec: { name: "Investec", branch_code: '580105' },
  Nedbank: { name: "Nedbank", branch_code: '198765' },
  Postbank: { name: "Postbank", branch_code: '460005 ' },
  'Meeg Bank': { name: "Meeg Bank", branch_code: '634721' },
  'African Bank' : { name: "African Bank", branch_code: '430000' },
  'Standard Bank of SA': { name: "Standard Bank of SA",	 branch_code: '051001' },

}
export const SOUTH_AFRICA_BANKS_CODES = {
  632005: "632005",
  430000: "430000",
  462005: "462005",
  470010: "470010",
  250655: "250655",
  580105: "580105",
  471001: "471001",
  198765: "198765",
  460005: "460005",
  51001: "051001",
}

export const granularity = {
  Day : "Day",
  Week : "Week",
  Month: "Month",
  Year: "Year",
}
