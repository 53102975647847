import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import {
  assignUserRoleGroup,
  getCurrentRoles,
  getRoles,
  revokeUserRoleGroup,
} from "../../../lib/requests.es6";

const TTITLE = {
  ALL_ROLES: "Available roles",
  CURRENT_ROLES: "Current user roles",
};

export default function ManageRoles(props) {
  const [selected, setSelected] = React.useState(null);
  const [currentRoles, setCurrentRoles] = React.useState([]);
  const [allRoles, setAllRoles] = React.useState([]);
  const [assignRoleLoader, setAssignRoleLoader] = React.useState(false);
  const [revokeRoleLoader, setRevokeRoleLoader] = React.useState(false);
  const [currentRolesLoader, setCurrentRolesLoader] = React.useState(false);
  const [allRolesLoader, setAllRolesLoader] = React.useState(false);
  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    severity: "",
  });

  React.useEffect(() => {
    fetchCurrentRoles(props.user);
    fetchAllRoles();
  }, []);

  const assignRole = async (user, roleId) => {
    setAssignRoleLoader(true);
    try {
      const response = await assignUserRoleGroup({
        userId: user.id,
        roleId: roleId,
      });
      console.log("assigned role", response.data);
      fetchCurrentRoles(user);
      setAlert({ open: true, message: "Role assigned", severity: "success" });
    } catch (error) {
      console.error("Error assigning user role:", error);
      setAlert({
        open: true,
        message: "Error: user already has role",
        severity: "error",
      });
    }
    setAssignRoleLoader(false);
  };

  const revokeRole = async (user, roleId) => {
    setRevokeRoleLoader(true);
    try {
      const response = await revokeUserRoleGroup({
        userId: user.id,
        roleId: roleId,
      });
      console.log("revoked role", response.data);

      fetchCurrentRoles(user);
      setAlert({ open: true, message: "Role revoked", severity: "success" });
    } catch (error) {
      console.error("Error revoking user role:", error);
      setAlert({
        open: true,
        message: "Error: user does not have role",
        severity: "error",
      });
    }
    setRevokeRoleLoader(false);
  };

  const fetchCurrentRoles = async (user) => {
    setCurrentRolesLoader(true);
    try {
      const response = await getCurrentRoles({ id: user.id });
      console.log("fetched current roles", response.data);

      setCurrentRoles(response.data);
    } catch (error) {
      console.error("Error fetching user roles:", error);
    }
    setCurrentRolesLoader(false);
  };

  const fetchAllRoles = async () => {
    setAllRolesLoader(true);
    try {
      const response = await getRoles();
      console.log("fetched all roles", response.data);
      setAllRoles(response.data);
    } catch (error) {
      console.error("Error fetching user roles:", error);
    }
    setAllRolesLoader(false);
  };

  const handleToggle = (value, title) => () => {
    setSelected(value === selected ? null : { ...value, title: title });
  };

  const handleRevokeRole = () => {
    if (selected !== null) {
      revokeRole(props.user, selected.id);
      fetchCurrentRoles(props.user);
      setSelected(null);
    }
  };

  const handleAssignRole = () => {
    if (selected !== null) {
      assignRole(props.user, selected.id);
      fetchCurrentRoles(props.user);
      setSelected(null);
    }
  };

  const customList = (items, title) => {
    if (allRolesLoader && title === TTITLE.ALL_ROLES)
      return (
        <>
          {" "}
          {title} <br /> Loading..
        </>
      );
    if (currentRolesLoader && title === TTITLE.CURRENT_ROLES)
      return (
        <>
          {" "}
          {title} <br /> Loading..
        </>
      );
    if (!items || items.length < 1) return title;
    console.log("items", items, title);

    return (
      <>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
      <h2 style={{ textAlign: "left", marginRight: "5px" }}>{title}</h2>
      <Button
        sx={{ my: 0.5 }}
        variant="outlined"
        size="small"
        onClick={handleAssignRole}
        disabled={
          selected === null || selected.title === TTITLE.CURRENT_ROLES
        }
        style={{ display: title === TTITLE.CURRENT_ROLES ? "none" : "" }}
        aria-label="revoke selected role"
      >
        Assign
      </Button>

      <Button
        sx={{ my: 0.5 }}
        variant="outlined"
        size="small"
        onClick={handleRevokeRole}
        disabled={selected === null || selected.title === TTITLE.ALL_ROLES}
        aria-label="assign selected role"
        style={{ display: title === TTITLE.ALL_ROLES ? "none" : "" }}
      >
        Revoke
      </Button>
    </div>
      <Paper sx={{ width: "100%", height: '70vh', overflow: "auto" }}>


        {title === TTITLE.CURRENT_ROLES &&
          assignRoleLoader &&
          "Assigning role..."}
        {title === TTITLE.CURRENT_ROLES &&
          revokeRoleLoader &&
          "Revoking role..."}

        <List dense component="div" role="list">
          {items.map((value) => {
            const labelId = `transfer-list-item-${value.name}-label`;

            return (
              <ListItemButton
                key={value}
                role="listitem"
                onClick={handleToggle(value, title)}
                disabled={
                  title === TTITLE.ALL_ROLES && currentRoles.includes(value.id)
                }
              >
                <ListItemIcon>
                  <Radio
                    checked={
                      selected &&
                      selected.name === value.name &&
                      selected.title === title
                    }
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value.name}`} />
              </ListItemButton>
            );
          })}
        </List>
      </Paper>
      </>
    );
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>{customList(currentRoles, TTITLE.CURRENT_ROLES)}</Grid>
        <Grid item>{customList(allRoles, TTITLE.ALL_ROLES)}</Grid>
        <Snackbar
          open={alert.open}
          autoHideDuration={2000}
          onClose={handleCloseAlert}
          message={alert.message}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          severity={alert.severity}
        />
      </Grid>
    </>
  );
}
