import * as types from '../../lib/actionTypes.es6';

export function getAllPartnersRequest() {
  return {
    type: types.VIEW_ALL_PARTNERS_REQUEST,
  };
}

export function getAllPartnersFailure(error) {
  return {
    type: types.VIEW_ALL_PARTNERS_FAILURE,
    payload: error,
  };
}

export function getAllPartnersSuccess(response) {
  return {
    type: types.VIEW_ALL_PARTNERS_SUCCESS,
    payload: response,
  };
}

export function createPartnerRequest(payload) {
  return {
    type: types.CREATE_PARTNER_REQUEST,
    payload,
  };
}

export function createPartnerFailure(error) {
  return {
    type: types.CREATE_PARTNER_FAILURE,
    payload: error,
  };
}

export function createPartnerSuccess(response) {
  return {
    type: types.CREATE_PARTNER_SUCCESS,
    payload: response,
  };
}

export function resetCreatePartnerSuccess(error) {
  return {
    type: types.RESET_CREATE_PARTNER_SUCCESS,
  };
}

export function resetCreatePartnerError(error) {
  return {
    type: types.RESET_CREATE_PARTNER_ERROR,
  };
}

export function getCountriesRequest() {
  return {
    type: types.GET_COUNTRIES_REQUEST,
  };
}

export function getCountriesSuccess(response) {
  return {
    type: types.GET_COUNTRIES_SUCCESS,
    payload: response,
  };
}

export function getCountriesFailure(response) {
  return {
    type: types.GET_COUNTRIES_FAILED,
    payload: response,
  };
}

export function getPartnerRequest(payload) {
  return {
    type: types.VIEW_PARTNER_REQUEST,
    payload,
  };
}

export function getPartnerFailure(error) {
  return {
    type: types.VIEW_PARTNER_FAILURE,
    payload: error,
  };
}

export function getPartnerSuccess(response) {
  return {
    type: types.VIEW_PARTNER_SUCCESS,
    payload: response,
  };
}

export function getLanguagesRequest() {
  return {
    type: types.GET_LANGUAGES_REQUEST,
  };
}

export function getLanguagesSuccess(response) {
  return {
    type: types.GET_LANGUAGES_SUCCESS,
    payload: response,
  };
}

export function getLanguagesFailure(response) {
  return {
    type: types.GET_LANGUAGES_FAILED,
    payload: response,
  };
}
