import React from "react";
import { Grid, Box } from "@mui/material";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { useStyle } from "./Style";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

export const Claim = ({ claim, handleViewClaim }) => {
  const classes = useStyle();

  return (
    <Grid item xs={12} md={12} sm={12}>
      <div className={classes.claimCardWrapper}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2} sm={2} className={classes.claimIconWrapper}>
            <Box className={classes.claimIcons}>
              <AssignmentTurnedInIcon
                fontSize="14px"
                sx={{ color: "#ffffff" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <span>Claim Number</span>
            <p>{claim.claim_number || "N/A"}</p>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <span>Status</span>
            <p>{claim.status || "N/A"}</p>
          </Grid>

          <Grid item xs={12} md={2} sm={2}>
            <span>Phone Number</span>
            <p>{claim.msisdn || "N/A"}</p>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <span>Full Name</span>
            <p>{claim.full_name || "N/A"}</p>
          </Grid>

          <Grid item xs={12} md={2} sm={2} className={classes.viewWrapper}>
            <Box display="flex" justifyContent="flex-start">
              <ul style={{ cursor: "pointer" }} onClick={() => handleViewClaim(claim)}>
                <li >View Claim </li>
                <li><ChevronRightOutlinedIcon /></li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};
