export const ClaimStatusEnum =  {
  Pending : 'Pending',
  All_Docs_Pending : 'All_Docs_Pending',
  Docs_Partially_Submitted : 'Docs_Partially_Submitted',
  Decision_Pending : 'Decision_Pending',
  Approved : 'Approved',
  Approved_Paid : 'Approved_Paid',
  Rejected : 'Rejected',
}

export const ClaimActionEnum = {
  complete_docs:  'complete_docs',
  approve:  'approve',
  approve_paid:  'approve_paid',
  reject:  'reject',
}


export const ClaimTypeEnum = {
  hospital:  'hospital',
  death:  'death',
  reimbursement:  'reimbursement',
}

export const policyActionEnum = {
  Approved: 'Approved',
  Rejected : 'Rejected',
}

export const policiesStatusEnum = {
  Un_Confirmed: "Un_Confirmed",
  Rejected: "Rejected"
}
export const paymentStatusEnum = {
  Un_Confirmed: "Unconfirmed",
  Rejected: "Rejected"
}