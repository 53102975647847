import React from "react";
import { connect } from "react-redux";
import HomePageCta from './HomePageCta'
import LocalizedStrings from 'react-localization';
import { localisedText } from "../../../lib/localisation.es6";


class NGSHomePage extends React.Component {
  constructor(props) {
    const localizedStrings = new LocalizedStrings(localisedText);
    super(props);
    this.state = {
      openRegisterCustomerDialog: false,
      labels: localizedStrings,
    }
  }

  componentWillMount() {

  }




  render() {


    
    return (
      <>
        <HomePageCta
          labels={this.state.labels}
        />
   
      </>
    );


  }
}

export default connect((state) => ({
  customerData: state.currentCustomer,
  AccessBankRegistrationData: state.AccessBankRegistrationData,
  registrationData: state.customerRegistration,
  userData: state.userAdmin,
}))(NGSHomePage);