import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import OpenWithIcon from '@mui/icons-material/OpenWith';
import { getPartnerGuid } from "../../../../../../lib/access.es6";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 5;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: '10px',
  margin: `0 ${grid}px 0 0`,
  width: '150px',
  borderLeft: 'grey solid 1px',

  background: isDragging ? "lightgreen" : "white",

  ...draggableStyle
});

const getListStyle = (isDraggingOver, itemsLength) => ({
  background: isDraggingOver ? "white" : "white",
  display: "flex",
  padding: grid,
  marginLeft: '1%',
});



export default class DragableColumns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      identifier: (getPartnerGuid() === 'kymbritam' || getPartnerGuid() === 'accessbank') ? 'policy_guid' : 'msisdn',
      identifierSampleData: {
        'msisdn': <> <br /> 00250788312596  <br /> 00250788312596</>,
        'external_identifier': <> <br /> I88312596  <br /> I88312596</>,
        'policy_guid': <> <br /> Oppls3l2k3l3  <br /> 2ppls3l2k3l3</>,
      },
      items: []
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  initialItems = () => {

   const accessbank_payments_columns  = (identifierSampleData, identifier) =>  [
      {
        field_name: identifier,
        id: `item-1`,
        content: <> <select
          style={{ border: 'none', fontWeight: 'bolder', width: '100%', marginLeft: '-3%' }}
          value={identifier}
          onChange={
            this.updateIdentifier.bind(this)
          }
        >
          <option value="msisdn">msisdn</option>
          <option value="external_identifier">external_identifier</option>
          <option value="policy_guid">policy_guid</option>
        </select>
    
          {identifierSampleData[identifier]} </>
      },
      {
        field_name: 'amount',
        id: `item-2`,
        content: <> <span style={{ fontWeight: 'bolder' }}>amount</span> <br /> 1234  <br /> 1234</>
      },
      {
        field_name: 'reference',
        id: `item-3`,
        content: <> <span style={{ fontWeight: 'bolder' }}> reference</span> <br /> ref1  <br /> ref2</>
      },
      {
        field_name: 'effected_at',
        id: `item-4`,
        content: <> <span style={{ fontWeight: 'bolder' }}>effected_at</span> <br /> 2025-01-01  <br /> 2025-01-02</>
      },
      {
        field_name: 'status',
        id: `item-5`,
        content: <> <span style={{ fontWeight: 'bolder' }}>  status</span>  <br /> Succeeded  <br /> Failed </>
      },
      {
        field_name: 'sub_channel',
        id: `item-6`,
        content: <> <span style={{ fontWeight: 'bolder' }}> sub_channel</span>  <br /> ussd  <br /> webclient </>
      }
    ];
    
   const ktda_payments_columns = (identifierSampleData, identifier) =>  [
      {
        field_name: 'reference',
        id: `item-3`,
        content: <> <span style={{ fontWeight: 'bolder' }}> reference</span> <br /> ref1  <br /> ref2</>
      },
      {
        field_name: identifier,
        id: `item-1`,
        content: <> <select
          style={{ border: 'none', fontWeight: 'bolder', width: '100%', marginLeft: '-3%' }}
          value={identifier}
          onChange={
            this.updateIdentifier.bind(this)
          }
        >
          <option value="msisdn">msisdn</option>
          <option value="external_identifier">external_identifier</option>
          <option value="policy_guid">policy_guid</option>
        </select>
    
          {identifierSampleData[identifier]} </>
      },
      {
        field_name: 'amount',
        id: `item-2`,
        content: <> <span style={{ fontWeight: 'bolder' }}>amount</span> <br /> 1234  <br /> 1234</>
      },
      {
        field_name: 'effected_at',
        id: `item-4`,
        content: <> <span style={{ fontWeight: 'bolder' }}>effected_at</span> <br /> 2025-01-01  <br /> 2025-01-02</>
      },
      {
        field_name: 'sub_channel',
        id: `item-5`,
        content: <> <span style={{ fontWeight: 'bolder' }}> sub_channel</span>  <br /> ussd  <br /> webclient </>
      }
    ]
    

    if(getPartnerGuid() === 'accessbank') {
      return accessbank_payments_columns(this.state.identifierSampleData, this.state.identifier);
    }
    return ktda_payments_columns(this.state.identifierSampleData, this.state.identifier);
  }
  


  componentDidMount() {
    if (this.state.items.length === 0) {
      this.setState({
        items: this.initialItems()
      })
      this.updateColumnMappings(this.initialItems());
    }
  }

  updateIdentifier(e) {
    const newIdentifier = e.target.value;
    this.setState({ identifier: newIdentifier }, () => {
      const newItems = this.state.items.map(item => {
        const field_name = item.field_name
        if (['msisdn', 'external_identifier', 'policy_guid'].includes(field_name)) {
          item.content = <>
            <select
              style={{ border: 'none', fontWeight: 'bolder', width: '100%', marginLeft: '-3%' }}
              value={newIdentifier}
              onChange={this.updateIdentifier.bind(this)}
            >
              <option value="msisdn">msisdn</option>
              <option value="external_identifier">external_identifier</option>
              <option value="policy_guid">policy_guid</option>
            </select>
            {this.state.identifierSampleData[newIdentifier]}
          </>;
        }
        return item;
      });
      this.setState({ items: newItems });
      this.updateColumnMappings(newItems);

    });
  }


  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items
    });

    this.updateColumnMappings(items);

  }

  updateColumnMappings(items) {
    this.props.setColumnMappings(
      items.map((item, index) => {
        return {
          "field_name": ['msisdn', 'external_identifier', 'policy_guid'].includes(item.field_name) ? this.state.identifier : item.field_name,
          "column_index": index
        };
      })
    );
  }

  render() {
    return (
      <div style={{ overflow: "scroll", height: 200 }}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(
                  snapshot.isDraggingOver,
                  this.state.items.length
                )}
                {...provided.droppableProps}
              >
                {this.state.items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div
                          style={{
                            marginLeft: '2%',
                            paddingLeft: '3px'
                          }}
                        >
                          <span style={{ marginBottom: '2%', fontSize: '75%' }}>Move  <OpenWithIcon style={{ fontSize: '90%' }} /> </span>
                          <br />
                          {item.content}
                          <br />
                          <span style={{ marginTop: '90px', fontSize: '75%' }}>column {index + 1} </span>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}
