import * as types from "../../lib/actionTypes.es6";


export function getCustomerDocumentRequest(payload) {
  return {
    type: types.GET_CUSTOMER_DOCUMENT_REQUEST,
    payload,
  };
}

export function getCustomerDocumentSuccess(response) {
  return {
    type: types.GET_CUSTOMER_DOCUMENT_SUCCESS,
    payload: response,
  };
}

export function getCustomerDocumentFailed(error) {
  return {
    type: types.GET_CUSTOMER_DOCUMENT_FAILURE,
    payload: error,
  };
}

export function addCustomerDocumentRequest(payload) {
  return {
    type: types.ADD_CUSTOMER_DOCUMENT_REQUEST,
    payload,
  };
}

export function getCustomerDocumentsRequest(payload) {
  return {
    type: types.GET_CUSTOMER_DOCUMENTS_REQUEST,
    payload,
  };
}

export function getCustomerDocumentsSuccess(response) {
  return {
    type: types.GET_CUSTOMER_DOCUMENTS_SUCCESS,
    payload: response,
  };
}

export function getCustomerDocumentsFailed(error) {
  return {
    type: types.GET_CUSTOMER_DOCUMENTS_FAILURE,
    payload: error,
  };
}

export function addCustomerDocumentSuccess(response) {
  return {
    type: types.ADD_CUSTOMER_DOCUMENT_SUCCESS,
    payload: response,
  };
}

export function addCustomerDocumentFailed(error) {
  return {
    type: types.ADD_CUSTOMER_DOCUMENT_FAILURE,
    payload: error,
  };
}

export function updateCustomerDocumentRequest(payload) {
  return {
    type: types.UPDATE_CUSTOMER_DOCUMENT_REQUEST,
    payload,
  };
}

export function updateCustomerDocumentSuccess(response) {
  return {
    type: types.UPDATE_CUSTOMER_DOCUMENT_SUCCESS,
    payload: response,
  };
}

export function updateCustomerDocumentFailed(error) {
  return {
    type: types.UPDATE_CUSTOMER_DOCUMENT_FAILURE,
    payload: error,
  };
}

export function deleteCustomerDocumentRequest(payload) {
  return {
    type: types.DELETE_CUSTOMER_DOCUMENT_REQUEST,
    payload,
  };
}

export function deleteCustomerDocumentSuccess(response) {
  return {
    type: types.DELETE_CUSTOMER_DOCUMENT_SUCCESS,
    payload: response,
  };
}

export function deleteCustomerDocumentFailed(error) {
  return {
    type: types.DELETE_CUSTOMER_DOCUMENT_FAILURE,
    payload: error,
  };
}

export function setCustomerDocumentsToDelete(response) {
  return {
    type: types.SET_CUSTOMER_DOCUMENT_TO_DELETE,
    payload: response,
  };
}

export function resetHardDeleteCustomerDocumentSuccess() {
  return {
    type: types.RESET_DELETE_CUSTOMER_DOCUMENT_SUCCESS,
  };
}

export function resetDocumentToDelete() {
  return {
    type: types.RESET_CUSTOMER_DOCUMENT_TO_DELETE,
  };
}
export function resetHardDeleteCustomerDocumentError() {
  return {
    type: types.RESET_CUSTOMER_DELETE_DOCUMENT_ERROR,
  };
}