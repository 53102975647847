import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../../../../lib/actionTypes.es6'
import * as actions from './actions.js';

import {getSmsActivities} from '../../../../../../lib/requests.es6';
import { GAloggerException } from '../../../../../../lib/monitoring.es6';
import { unAuthorizedNotification } from '../../../../../../lib/access.es6';



export function* getAccessBankSmsActivitiesRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_ACCESSBANK_SMS_ACTIVITY_REQUEST);
    try {
      const response = yield call(getSmsActivities, request.payload);
      yield put(actions.getAccessBankSmsActivitySuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getAccessBankSmsActivityFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
