import React, { useState } from 'react'
import { hashHistory } from 'react-router';
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import TableInc from '../../../../../shared-ui/TableInc';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SkeletonWrapper from '../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import { formatDateLocale } from '../../../../../lib/utils.es6';
import MenuItemInc from '../../../../../shared-ui/MenuItemInc';
import { hasAuthourity } from '../../../../../lib/access.es6';


const ITEM_HEIGHT = 48;

const DisplayLOUS = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLouAction, setSelectedLouAction] = useState(null)

  const open = Boolean(anchorEl);

  const classes = useStyle();

  const handleOpenMenu = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedLouAction(rowData)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (props.loader) {
    return <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} style={{ padding: '5%' }} >
        <SkeletonWrapper loading={props.loader} />
      </Grid></Grid>
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} >
          <TableInc

            columns={[
              {
                title: 'Action', field: 'lou_action', render: rowData => {
                  return <div >
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? 'long-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={(event) => handleOpenMenu(event, rowData)}

                    >
                      <MoreVertIcon />
                    </IconButton>
                    {
                      selectedLouAction && selectedLouAction.guid === rowData.guid && <Menu
                        className={classes.louactionbtns}
                        id="long-menu"
                        MenuListProps={{
                          'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                          },
                        }}
                      >




                        <MenuItemInc style={{ cursor: "pointer" }} hasPermision={hasAuthourity('LOU_VIEW')} onClick={() => { setAnchorEl(null); props.setLouToView(rowData) }}>View Details</MenuItemInc>
                        <MenuItemInc style={{ cursor: "pointer" }} hasPermision={hasAuthourity('LOU_DELETE')} onClick={() => { setAnchorEl(null); props.setLouToDelete(rowData); }}>Delete</MenuItemInc>
                        {(rowData.status === 'Succeeded') &&
                          <>
                            <MenuItemInc style={{ cursor: "pointer" }} hasPermision={hasAuthourity('LOU_EDIT')} onClick={() => { setAnchorEl(null); props.setLouToEdit(rowData); }}>Edit</MenuItemInc>
                            <MenuItemInc style={{ cursor: "pointer" }} hasPermision={hasAuthourity('LOU_DOWNLOAD')} onClick={() => { setAnchorEl(null); props.setPolicyToDownloadDocumentFor(rowData) }} >Download LOU Document</MenuItemInc>
                            {(rowData.claim_guid) && <MenuItemInc hasPermision={hasAuthourity('VIEW_CLAIM')} style={{ cursor: "pointer" }} onClick={() => { setAnchorEl(null); hashHistory.push(`admin/claim-processing-v2/${rowData.claim_guid}`) }} >
                              View Claim
                            </MenuItemInc>}
                          </>
                        }

                      </Menu>
                    }

                  </div>
                }
              },
              {
                title: 'LOU  Number', field: 'preauth_number', render: rowData => <>
                  {
                    rowData.preauth_number || "N/A"
                  }
                </>
              },
              {
                title: 'Grower Number', field: 'policy_number', render: rowData => <>
                  {
                    rowData.policy_number || "N/A"
                  }
                </>
              },
              { title: 'Hospital', field: 'hospital', render: rowData => rowData.hospital_details.hospital },
              { title: 'Admission Date', field: 'hospital_admission_date', render: rowData => formatDateLocale(rowData.hospital_details.hospital_admission_date) },
              { title: 'Discharge Date', field: 'hospital_admission_date', render: rowData => formatDateLocale(rowData.hospital_details.hospital_discharch_date) },
              {
                title: 'Created At', field: 'created_at', render: rowData => <>
                  {
                    //(rowData.created_at).substring(0, 16).replace("T", " ") || "N/A"
                    formatDateLocale(rowData.created_at) || "N/A"
                  }
                </>
              },
              {
                title: 'Status', field: 'status', render: rowData => <>
                  {
                    rowData.status || "N/A"
                  }
                </>
              },
              { title: 'Case Manager', field: 'web_agent_name' },
              
            ]}
            data={props.lous}
            options={{
              pageSize: props.lous.length < 6
                ? props.lous.length
                : 15,
              showTitle: false,
              exportButton: true
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default DisplayLOUS