import React, { useState, useEffect } from "react";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import Grid from "@material-ui/core/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import { useStyle } from "./Style";
import { Button, CircularProgress } from "@mui/material";
import AlertDialog from "../../../../../../components/AlertDialog/index.es6";
import {
  getLocalNumberLength,
  getPartner,
  getPartnerGuid,
  isVAlidSouthAfricanNationalId,
} from "../../../../../../lib/access.es6";
import { BankData } from "../../../../lib/AccessBankBranches";
import { formatDate } from "../../../../../../lib/utils.es6";

const RegisterCustomerAspinPlus = (props) => {
  if (!props.agentUsersData) {
    return;
  }

  const classes = useStyle();

  const [firstname, setFirstname] = useState("");
  const [middlename, setmiddlename] = useState("");
  const [surname, setsurname] = useState("");
  const [dateofbirth, setdateofbirth] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [nationalid, setnationalid] = useState("");
  const [passport, setPassport] = useState("");
  const [isCitizenCheck, setIsCitizenCheck] = useState(false);
  const [gender, setGender] = useState("");
  const [postalAddress, setPostalAddress] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [physicalAddress, setPhysicalAddress] = useState("");
  const [openBranches, setOpenBranches] = useState(false);
  const [inputBranchValue, setInputBranchValue] = useState("");

  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selectedAgentError, setSelectedAgentError] = useState(false);

  const [nationalIdError, setNationalIdError] = useState(null);

  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedBranchError, setSelectedBranchError] = useState(false);

  //validations
  useEffect(() => {
    ValidatorForm.addValidationRule("isValidNationalId", (value) => {
      const result = isVAlidSouthAfricanNationalId(value);
      if (result.isValid) {
        setdateofbirth(formatDate(new Date(result.dob)));
        setGender(result.gender);
        setNationalIdError(result.error);
        return true;
      } else {
        setNationalIdError(result.error);
        setGender("");
        setdateofbirth('');
        return false;
      }
    });

    ValidatorForm.addValidationRule("isValidPhoneNumber", (value) => {
      return value.length === getLocalNumberLength();
    });

    ValidatorForm.addValidationRule("isValidPrincipalAge", (value) => {
      if (value) {
        const selectedDateObj = new Date(value);
        const currentDate = new Date();

        const ageDifference =
          currentDate.getFullYear() - selectedDateObj.getFullYear();

        return ageDifference >= 18 && ageDifference <= 75;
      }
      return true;
    });

    return function cleanup() {
      ValidatorForm.removeValidationRule("isValidPhoneNumber");
      ValidatorForm.removeValidationRule("isValidNationalId");
      ValidatorForm.removeValidationRule("isValidPrincipalAge");
    };
  }, []);

  //country calling code

  const internationalCallingCode = getPartner().country
    .international_calling_code;

  const handleIsCitizenChange = (event) => {
    setIsCitizenCheck(event.target.checked);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "firstname") {
      setFirstname(value);
    }
    if (name === "middlename") {
      setmiddlename(value);
    }
    if (name === "surname") {
      setsurname(value);
    }
    if (name === "dateofbirth") {
      setdateofbirth(value);
    }
    if (name === "phonenumber") {
      setphonenumber(value);
    }
    if (name === "nationalid") {
      setnationalid(value);
    }
    if (name === "gender") {
      setGender(value);
    }
    if (name === "postalAddress") {
      setPostalAddress(value);
    }
    if (name === "emailAddress") {
      setEmailAddress(value);
    }
    if (name === "physicalAddress") {
      setPhysicalAddress(value);
    }
    if (name === "passport") {
      setPassport(value);
    }
  };

  const handleBranchInputChange = (event, newValue) => {
    setSelectedBranch(newValue);
    setSelectedBranchError(!newValue);
    setInputBranchValue(newValue);
  };

  const reset = () => {
    setFirstname("");
    setmiddlename("");
    setsurname("");
    setdateofbirth("");
    setphonenumber("");
    setnationalid("");
    setGender("");
    setPostalAddress("");
    setEmailAddress("");
    setPhysicalAddress("");
  };
  const handleRegisterCustomer = () => {
    if (getPartnerGuid() === "bxck") {
      const payload = {
        msisdn: phonenumber,
        date_of_birth: dateofbirth,
      };
      props.registerCustomer(payload);
      reset();
    } else {
      if (!selectedBranch) {
        selectedBranchError(true);
        return;
      }
      if (!selectedAgent) {
        setSelectedAgentError(true);
        return;
      }
      const payload = {
        msisdn: phonenumber,
        first_name: firstname,
        middlename: middlename,
        surname: surname,
        national_id: nationalid || "N/A",
        date_of_birth: dateofbirth,
        postal_address: postalAddress,
        passport: passport || "N/A",
        gender: gender,
        email: emailAddress,
        address: physicalAddress,
        branch: inputBranchValue,
        agent_guid: selectedAgent.id,
        external_identifier: nationalid,
      };
      props.registerCustomer(payload);
      reset();
    }
  };

  return (
    <>
      <section>
        <Dialog
          open={props.openRegisterCustomerDialog}
          onClose={props.toggleRegisterCustomerDialog}
          fullWidth
          maxWidth="md"
        >
          <ValidatorForm onSubmit={() => handleRegisterCustomer()}>
            <DialogTitle className={classes.registermodaltitle}>
              <div>
                <h3>Register Customer Aspin PLus </h3>
                <div></div>
              </div>
            </DialogTitle>
            <DialogContent className={classes.registerinputwrapper}>
              <DialogContentText>
                Fill the below details to register a customer
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sm={12}>
                  <div className={classes.checkconditionCitizenship}>
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        checked={isCitizenCheck}
                        onChange={handleIsCitizenChange}
                        control={<Checkbox size="small" />}
                        label="Is Non Citizen?"
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </div>
                </Grid>
              </Grid>
              {getPartnerGuid() === "bxck" ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextValidator
                      name="phonenumber"
                      id="Phone"
                      label="Phone"
                      type="number"
                      value={phonenumber}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            sx={{
                              paddingTop: "32px",
                            }}
                            position="start"
                          >
                            {internationalCallingCode
                              ? internationalCallingCode
                              : ""}
                          </InputAdornment>
                        ),
                        style: {
                          background: "#F7F7F7",
                        },
                      }}
                      variant="outlined"
                      validators={["isValidPhoneNumber"]}
                      errorMessages={[
                        `Please enter a valid ${getLocalNumberLength()} digit phone number`,
                      ]}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <TextValidator
                      name="dateofbirth"
                      id="Date-of-birth"
                      label="Date of birth"
                      value={dateofbirth}
                      onChange={handleChange}
                      fullWidth
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      validators={["required"]}
                      errorMessages={["Please enter date of birth"]}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextValidator
                      name="firstname"
                      id="First-Name"
                      label="First Name"
                      value={firstname}
                      onChange={handleChange}
                      fullWidth
                      className={classes.registerinputs}
                      variant="outlined"
                      validators={["required"]}
                      errorMessages={["Please enter first name"]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextValidator
                      name="middlename"
                      id="Middle-Name"
                      label="Middle Name"
                      value={middlename}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextValidator
                      name="surname"
                      id="Surname"
                      label="Surname"
                      value={surname}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      validators={["required"]}
                      errorMessages={["Please enter surname name"]}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    {isCitizenCheck ? (
                      <>
                        <TextValidator
                          name="passport"
                          id="passport"
                          label="Passport No"
                          value={passport}
                          onChange={handleChange}
                          fullWidth
                          variant="outlined"
                          validators={["required"]}
                          errorMessages={[
                            "Please enter a valid passport number",
                          ]}
                        />
                      </>
                    ) : (
                      <>
                        <TextValidator
                          name="nationalid"
                          id="National-iD"
                          label="National ID"
                          value={nationalid}
                          onChange={handleChange}
                          fullWidth
                          variant="outlined"
                          validators={["required", "isValidNationalId"]}
                          errorMessages={[
                            "Please enter a valid national id",
                            nationalIdError
                              ? nationalIdError
                              : "Invalid national Id",
                          ]}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <SelectValidator
                      name="gender"
                      labelId="select-gender"
                      id="select-gender"
                      value={gender}
                      label="Gender"
                      onChange={handleChange}
                      variant="outlined"
                      validators={["required"]}
                      errorMessages={["Please select gender"]}
                      style={{ width: "100%" }}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                    </SelectValidator>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <TextValidator
                      name="dateofbirth"
                      id="Date-of-birth"
                      label="Date of birth"
                      value={dateofbirth}
                      onChange={handleChange}
                      fullWidth
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      validators={["required", "isValidPrincipalAge"]}
                      errorMessages={[
                        "Please enter date of birth",
                        "principal must be at least 18 years and not more than 75 years",
                      ]}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <TextValidator
                      name="phonenumber"
                      id="Phone"
                      label="Phone"
                      type="number"
                      value={phonenumber}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            sx={{
                              paddingTop: "32px",
                            }}
                            position="start"
                          >
                            {internationalCallingCode
                              ? internationalCallingCode
                              : ""}
                          </InputAdornment>
                        ),
                        style: {
                          background: "#F7F7F7",
                        },
                      }}
                      variant="outlined"
                      validators={["isValidPhoneNumber"]}
                      errorMessages={[
                        `Please enter a valid ${getLocalNumberLength()} digit phone number`,
                      ]}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <TextValidator
                      name="postalAddress"
                      id="postalAddress"
                      label="postal Address"
                      value={postalAddress}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      validators={["required"]}
                      errorMessages={["Please enter a valid postal address"]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextValidator
                      name="emailAddress"
                      id="emailAddress"
                      label="Email Address"
                      type="email"
                      value={emailAddress}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextValidator
                      name="physicalAddress"
                      id="physicalAddress"
                      label="Physical Address"
                      value={physicalAddress}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      validators={["required"]}
                      errorMessages={["Please enter a valid physical address"]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Autocomplete
                      open={openBranches}
                      onOpen={() => {
                        setOpenBranches(true);
                      }}
                      onClose={() => {
                        setOpenBranches(false);
                      }}
                      ListboxProps={{
                        sx: {
                          "& li": {
                            fontFamily: "Open Sans",
                            fontStyle: "normal",
                            fontSize: "12px",
                            lineHeight: "25px",
                            color: "#000000",
                          },
                        },
                      }}
                      id="branch"
                      name="branch"
                      options={BankData}
                      //getOptionLabel={(option) => `${option.name} (${BankData.find(bank => bank.branch_details.includes(option)).bank_name})`}
                      inputValue={inputBranchValue}
                      onInputChange={handleBranchInputChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Branch"
                          variant="outlined"
                          error={selectedBranchError}
                          helperText={
                            selectedBranchError ? "Please select a branch" : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Autocomplete
                      options={[
                        {
                          id: "all_agents",
                          firstName: "all",
                          lastName: "agents",
                        },
                      ]}
                      getOptionLabel={(user) =>
                        `${user.firstName} ${user.lastName}`
                      }
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, value) => {
                        setSelectedAgent(value);
                        setSelectedAgentError(!value);
                      }}
                      value={selectedAgent}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Agent"
                          variant="outlined"
                          error={selectedAgentError}
                          helperText={
                            selectedAgentError ? "Please select an agent" : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions className={classes.registerbtns}>
              <Button variant="contained" type="submit">
                Register Customer
              </Button>
              <Button
                variant="outlined"
                onClick={props.toggleRegisterCustomerDialog}
              >
                Cancel
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>

        <AlertDialog
          custom
          show={props.registrationData.loader}
          size="sm"
          style={{ marginTop: "0", top: "30vh" }}
          confirmBtnText={"ok"}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={"cancel"}
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2>registering customer</h2>
        </AlertDialog>

        <AlertDialog
          success
          show={!!props.registrationData.customerInfo}
          size="sm"
          title={"Customer registeration request submitted"}
          style={{ marginTop: "0", top: "30vh" }}
          onConfirm={() => {
            props.resetRegistrationSuccess();
          }}
          confirmBtnText={"ok"}
          showCancel={false}
          cancelBtnText={"cancel"}
          confirmBtnCssClass
        ></AlertDialog>

        <AlertDialog
          show={!!props.showRegisterCustomerErrorAlert}
          danger
          title={"Error registering customer"}
          onConfirm={() => {
            props.resetRegistrationError();
          }}
          confirmBtnText={"Try again"}
          confirmBtnCssClass
          showCancel={false}
          style={{ marginTop: "0", top: "30vh" }}
        >
          {props.error ? props.error : ""}
        </AlertDialog>
      </section>
    </>
  );
};

export default RegisterCustomerAspinPlus;
