import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import TableInc from '../../../../../shared-ui/TableInc';
import DeleteIcon from '@mui/icons-material/Delete';
import { getPartner, hasAuthourity } from '../../../../../lib/access.es6';
import Tooltip from '@mui/material/Tooltip';
import { ClaimTypeEnum } from '../../../lib/enum';
import { formatDateLocale } from '../../../../../lib/utils.es6';

const DeathExpenses = (props) => {
  const classes = useStyle();
  const currencyCode = getPartner().country.currency_code;
  const tempArr = props.claimExpense.benefit_usages.map(benefit => { 
    let maxBenefits = 0
    if(props.deathBenefit && props.deathBenefit.max_benefit_amount_in_cents){
      maxBenefits = props.deathBenefit.max_benefit_amount_in_cents / 100
    }
    return {
      ...benefit,
      max_benefits_amount_in_cents: maxBenefits,

      invoice_amount_in_cents:   props.type === ClaimTypeEnum.death ?  `${currencyCode} ${(benefit.benefit_amount_in_cents / 100).toLocaleString()  }`: `${benefit.invoice_number}` ? `${benefit.invoice_number}` : "N/A",

      benefit_amount_in_cents: benefit.benefit_amount_in_cents ? `${currencyCode}  ${(benefit.benefit_amount_in_cents / 100).toLocaleString()}` : "N/A",
      benefit_type: benefit.benefit_type ? benefit.benefit_type : "N/A",
      invoice_number: props.type === ClaimTypeEnum.death ? benefit.external_unique_identifier : benefit.invoice_number ? benefit.invoice_number : "N/A",
      created_at: benefit.created_at ? formatDateLocale(benefit.created_at) : "N/A"
    }

  })
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}  >
          <Grid container spacing={2} className={classes.claimdocswrapper}>
            <Grid item xs={12} sm={12} md={12}>
              <TableInc
                columns={[
                  {
                    title:  'Benefit Amount', field: 'max_benefits_amount_in_cents'
                  },
                  {
                    title: 'Amount Payable', field: 'benefit_amount_in_cents'
                  },
                  {
                    title: 'Expense Type', field: 'benefit_type'
                  },
                  {
                    title: `${props.type === ClaimTypeEnum.death ? 'Death Certificate Number' : 'Invoice Number'}`, field: 'invoice_number'
                  },
                  { title: 'Created At', field: 'created_at' },

                  {
                    title: 'Action', field: 'action', render: rowData => {
                      return <div className={classes.actionbtn}>
                        <Tooltip title="Delete Claim Expense">
                          <span style={{ cursor: "pointer", display: hasAuthourity('UPDATE_CLAIM') ? 'inline' : 'none' }} onClick={() => props.setClaimExpenseToDelete(rowData)} > <DeleteIcon sx={{ color: "#8F191C" }} /></span>
                        </Tooltip>
                      </div>
                    }
                  },
                ]}
                data={tempArr}
                options={{
                  pageSize: tempArr.length < 5
                    ? tempArr.length
                    : 5,
                    showTitle: false,

                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>



          <div className={classes.totalExpense}>
            <p><strong>Total Claim Expense</strong>: {currencyCode + " " + (props.claimExpense.benefit_usages.reduce((psum, expense) => psum + expense.benefit_amount_in_cents, 0) / 100).toLocaleString()}  </p>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default DeathExpenses