import React, { useState } from 'react'
import ModalInc from '../../../../shared-ui/ModalInc'
import ButtonInc from '../../../../shared-ui/ButtonInc'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import AlertDialog from '../../../../components/AlertDialog/index.es6';
import { CircularProgress } from '@material-ui/core';
import { getUserFullname, getUserGuid } from '../../../../lib/access.es6';

const AddPolicyNotes = (props) => {
  const [notes, setNotes] = useState('');


  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === "notes") {
      setNotes(value)
    }

  }
  const reset = () => {
    setNotes('');
  }
  const web_agent_guid = getUserGuid();
  const web_agent_name = getUserFullname();

  const handlePolicyNotes = () => {
    const payload = {
      "note": notes,
      "policy_guid": props.policy.guid,
      "web_user": web_agent_name,
      "web_user_guid": web_agent_guid
    }
    console.log("notes payload", payload);
    props.handlePolicyNotesAdd(payload)
    reset()
  }
  const handleNoteCreatedSuccessfully = () => {
    props.resetAddPolicyNoteSuccessAlert();
    props.resetPolicyNoteToAdd();
    props.getAllPolicies();
  
  }
  const handleNoteCreateError = () => {
    props.resetAddPolicyNoteErrorAlert();
  }
  return (
    <>
      <AlertDialog
        custom
        show={props.showAddPolicyNoteProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2> Creating policy Note...</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showAddPolicyNoteSuccessAlert}
        size="sm"
        title={'Policy note created'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleNoteCreatedSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={true}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >

      </AlertDialog>

      <AlertDialog
        show={props.showAddPolicyNoteErrorAlert}
        danger
        title={'Error creating policy note'}
        onConfirm={() => handleNoteCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >

        {props.AddPolicyNoteError ? props.AddPolicyNoteError.message : ''}
      </AlertDialog>
      <ModalInc
        modalTitle="Add Policy Notes"
        subTitle="Fill the below field to add policy notes"
        open={props.open}
        onClose={props.close}
        fullWidth
      >
        <ValidatorForm onSubmit={() => handlePolicyNotes()} >

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <TextValidator
                name='notes'
                id="notes"
                label="Policy Notes"
                value={notes}
                onChange={handleChange}
                multiline
                rows={5}
                maxRows={4}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter policy Notes']}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} md={6}>

            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    type='submit'
                    style={{ marginTop: "20px", float: "right" }}
                  >
                    Add Notes
                  </ButtonInc>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginTop: "20px" }}
                    onClick={props.close}
                  >
                    Cancel
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </ValidatorForm>

      </ModalInc>

    </>
  )
}

export default AddPolicyNotes