import { Button, CircularProgress, InputAdornment } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Grid from "@material-ui/core/Grid";
import LinearProgress from 'material-ui/LinearProgress';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import LocalizedStrings from 'react-localization';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { hashHistory } from 'react-router';
import * as actions from '../../containers/Group/GroupRegistration/actions.es6';
import { getPartner, getPartnerGuid } from '../../lib/access.es6';
import { localisedText } from '../../lib/localisation.es6';
import { getS3BucketSignedUrlForGroupUploadResources } from '../../lib/requests.es6';
import AlertDialog from '../AlertDialog/index.es6';
import { getGroupMobisiteBaseURL } from '../../lib/utils.es6'


const btnStyle = {
  margin: '2%',
  green: {
    backgroundColor: 'orangered',
    color: 'white',
    margin: '2px',
    height: '50px',
    padding: '0 30px',
  },
  danger: {
    backgroundColor: 'green',
    color: 'white',
    margin: '2px',
    height: '50px',
    padding: '0 30px',
  },
  greyedOut: {
    backgroundColor: 'grey',
    color: 'white',
    margin: '2px',
    height: '50px',
    padding: '0 30px',
  }
};

export class UpdateGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      name: '',
      size: '',
      benefit: '',
      email: '',
      telephone: '',
      address: '',
      occupation: '',
      kraPin: '',
      certificateUploadCompleted: 0,
      excelFileUploadCompleted: 0,
      certificateUploadMessage: null,
      excelFileUploadMessage: null,
      s3Url: 'https://group-upload-resources.s3.amazonaws.com',
      certificateFileSignedUrl: '',
      membersFileSignedUrl: '',
      labels: new LocalizedStrings(localisedText),
      selectedProduct: null,
      acceptTerms: false,
      groupScheduleError: null,
      certificateError: null,
      acceptTermsError: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onCertificateFileSelect = this.onCertificateFileSelect.bind(this);
    this.handleChangeBenefit = this.handleChangeBenefit.bind(this);
    this.submitGroupRegistration = this.submitGroupRegistration.bind(this);
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('is9Digits', (value) => {
      return value.length === 9;
    });

    ValidatorForm.addValidationRule('isValidKraPin', (value) => {
      return /^[A-Z]\d{9}[A-Z]$/gi.test(value)
    });

    ValidatorForm.addValidationRule('isValidGroupSize', (value) => {
      return value >= 10;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule('is9Digits');
    ValidatorForm.removeValidationRule('isValidKraPin');
    ValidatorForm.removeValidationRule('isValidGroupSize');
  }

  handleResetForm() {
    this.props.dispatch(actions.resetGroupRegistraion())
    this.setState({
      name: '',
      selectedProduct: null,
      email: '',
      telephone: '',
      address: '',
      occupation: '',
      kraPin: '',
      size: '',
    });
  }


  updateGroupDetails(){
    console.log('groupRegistrationData: ', this.props.groupRegistrationData);
    const group = this.props.groupRegistrationData.getGroup
    if (!this.props.groupRegistrationData.certificateFileUploaded) {
      this.setState({ certificateError: true });
      return;
    }
    const payload = {
      kra_pin: this.state.kraPin || group.kra_pin,
      address: this.state.address || group.adress,
      certificate_url: `${this.state.s3Url}/${this.props.groupRegistrationData.certificateFileUploaded.bucketKey}`,
      partner: getPartnerGuid(),
      groupId: this.props.groupRegistrationData.getGroup.id,
    }
    this.props.dispatch(actions.updateGroupRequest(payload))
  }

  submitGroupRegistration() {
    if (!this.state.acceptTerms) {
      this.setState({ acceptTermsError: true });
      return;
    }
    this.props.dispatch(actions.resetActualQuote());
    const telephone = "00254" + this.state.telephone
    let payload = {
      name: this.state.name,
      product_guid: this.state.selectedProduct.guid,
      email: this.state.email,
      telephone: telephone,
      address: this.state.address,
      occupation: this.state.occupation,
      kra_pin: this.state.kraPin,
      certificate_url: `${this.state.s3Url}/${this.props.groupRegistrationData.certificateFileUploaded.bucketKey}`,
      group_schedule_s3_key: this.props.groupRegistrationData.excelFileUploaded.bucketKey,
    }
    this.props.dispatch(actions.registerGroupRequest(payload))
  }

  getActualQuote() {
    if (!this.props.groupRegistrationData.excelFileUploaded) {
      this.setState({ groupScheduleError: true });
      return;
    }
    if (!this.props.groupRegistrationData.certificateFileUploaded) {
      this.setState({ certificateError: true });
      return;
    }
    this.setState({ acceptTerms: false });
    let payload = {
      group_schedule_s3_key: this.props.groupRegistrationData.excelFileUploaded.bucketKey,
      product_premium_guid: this.state.selectedProduct.premiums[0].guid,
    }
    this.props.dispatch(actions.getActualGroupQuoteRequest(payload))
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }
  handleChangeBenefit(event) {
    this.setState({ selectedProduct: event.target.value })
  }
  toggleAcceptTerms() {
    const acceptTerms = this.state.acceptTerms;
    if (acceptTerms) {
      this.setState({ acceptTerms: !acceptTerms, acceptTermsError: false });
    } else {
      this.setState({ acceptTerms: !acceptTerms })
    }
  }

  handleFinishedUploadCertificate(info) {
    this.setState({ certificateFileSignedUrl: info.signedUrl, certificateError: false });
    this.props.dispatch(actions.groupCertificateUploadCallbackRequest(info));
  }

  onCertificateFileSelect(event) {
    this.setState({
      certificateFileKey: event.target.files[0],
      isSubmitDisabled: false,
    });
  }

  errorHandler(message) {
    this.setState({ message });
  }


  progressCertificateFile(completed) {
    if (completed > 100) {
      this.setState({ certificateUploadCompleted: 100 });
    } else if(completed === 0){
      this.setState({ certificateUploadCompleted: 1 });

    }else {
      this.setState({ certificateUploadCompleted: completed });
    }
  }

  displayCompleteMessageForCertificate() {
    const style = {
      margin: '5%',
      textAlign: 'center'
    };
    const labels = this.state.labels;
    if (this.state.certificateUploadCompleted === 0) {
      return (
        <div style={style}>
          <h4>{'Drag and Drop Certificate of Incorporation here'}</h4>
          <h4>{labels.Or}</h4>
          <h4>{labels.clickUpload}</h4>
        </div>
      );
    }

    if (this.state.certificateUploadCompleted > 0 && this.state.certificateUploadCompleted < 100) {
      return (
        <div style={style}>
          <h4>{labels.uploadProgress}</h4>
        </div>
      );
    }
    if (this.state.certificateUploadCompleted >= 100 && !this.state.certificateUploadMessage) {
      return (
        <div style={style}>
          <h4 style={{ color: 'green' }}>{labels.uploadSuccess}</h4>
        </div>
      );
    }
    if (this.state.certificateUploadCompleted >= 100 && this.state.certificateUploadMessage) {
      return (
        <div style={style}>
          <h4>{labels.uploadFailure}</h4>
        </div>
      );
    }
    return '';
  }


  renderCertificateUploader() {
    const uploadOptions = {
      getSignedUrl: getS3BucketSignedUrlForGroupUploadResources,
      s3Url: this.state.s3Url,
      uploadRequestHeaders: {},
      onProgress: this.progressCertificateFile.bind(this),
      onError: this.errorHandler.bind(this),
    };

    const style = {
      loader: {
        display: this.state.certificateUploadCompleted > 0 ? '' : 'none',
        width: '52%',
      },
      s3Uploader: {
        display: this.state.certificateUploadCompleted > 0 ? 'none' : '',
      },
      loaderColor: this.state.message ? 'red' : 'green',
    };

    return (
      <div className="form-group">
        <div>
          <DropzoneS3Uploader
            onFinish={this.handleFinishedUploadCertificate.bind(this)}
            maxSize={1024 * 1024 * 5}
            upload={uploadOptions}
            s3Url={this.state.s3Url}
            passChildrenProps={false}
          >
            <div>
              <br />
              <br />
              {this.displayCompleteMessageForCertificate()}
            </div>
          </DropzoneS3Uploader>
          <br />
          <br />
          <LinearProgress
            color={style.loaderColor}
            style={style.loader}
            mode="determinate"
            value={this.state.certificateUploadCompleted}
          />
        </div>
      </div>
    );
  }

  goToGroupMembers() {
    const group = this.props.groupRegistrationData.groupRegisteration;
    hashHistory.push(`admin/group-status/${group.id}`)
    this.props.dispatch(actions.resetGroupRegistraion())
  }

  downloadCSVGroupScheduleErrors() {
    const errors = this.props.groupRegistrationData.actualGroupQuoteError ? this.props.groupRegistrationData.actualGroupQuoteError.csvErrors : [];

    let csvContent = "data:text/csv;charset=utf-8, Error Message\n";

    for (let i = 0; i < errors.length; i++) {
      const row = errors[i];
      csvContent += row + "\n";
    }


    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';

    const url = encodeURI(csvContent);
    a.href = url;
    a.download = 'group_schedule_errors.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  generateGroupLink() {
    const group = this.props.groupRegistrationData.getGroup;
    const registrationLink = group ? group.registration_link : '';
    return `${getGroupMobisiteBaseURL()}${registrationLink}`;
  }
  render() {
    const currencyPartner =  getPartner();
    const currencyCode = currencyPartner ? currencyPartner.country.currency_code : "";
    const labels = this.state.labels;

    const group = this.props.groupRegistrationData.getGroup

    const { groupRegistrationError, actualGroupQuoteError, registerGroupLoader, updateGroupLoader } = this.props.groupRegistrationData;
    const registrationStatus = group ? group.registration_status : ''
    const registrationStatusClosed = registrationStatus === 'Closed'
    if (group) {
      return (
        <div>
          <div className="row" style={{ width: '100%', margin: "auto" }}>
            <div className="col-md-12 col-sm-12 col-xs-12" >
              <div className="x_panel" >
            <p style={{ color: 'red', textAlign: 'center', display: registrationStatusClosed ? 'block' : 'none'  }}>This group's regisgtration for new members is closed. Please contact Britam for more info.</p>

                <div className="x_title">
                  <h2>{'Group Details'} <span style={{float: 'right'}}>
                  <p style={{display: !registrationStatusClosed ? 'block' : 'none' }}>Visit Mobi Site at: </p>
            <a style={{display: !registrationStatusClosed ? 'block' : 'none' }} href={this.generateGroupLink()} target="_blank" rel="noopener noreferrer"> {this.generateGroupLink()}</a>
                    </span></h2>
                  <div className="clearfix" />
                </div>
                <div className="x_content">
                  <ValidatorForm onSubmit={this.updateGroupDetails.bind(this)} >
                    <Grid container item xs={12} spacing={3}>

                      <Grid item md={4} xs={12}>
                        <TextValidator label={'Product'}
                          fullWidth
                          variant="outlined"
                          type="text"
                          value={currencyCode +': ' + group.product.fixed_indemnity_amount_benefit}
                          disabled
                          onChange={this.handleChange}
                          name="email"
                          placeholder={'group email'}
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextValidator label={'Group Occupation'}
                          fullWidth
                          variant="outlined"
                          type="text"
                          value={group.occupation}
                          disabled
                          onChange={this.handleChange}
                          name="occupation"
                          placeholder={'Group Occupation'}
                          validators={['required']}
                          errorMessages={'Please enter a group occupation'}
                        />
                      </Grid>


                      <Grid item md={4} xs={12}>
                        <TextValidator label={'Group Name'}
                          fullWidth
                          variant="outlined"
                          type="text"
                          value={group.name}
                          disabled
                          onChange={this.handleChange}
                          name="name"
                          placeholder={'group name'}
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextValidator label={'Group email'}
                          fullWidth
                          variant="outlined"
                          type="text"
                          value={group.email}
                          disabled
                          onChange={this.handleChange}
                          name="email"
                          placeholder={'group email'}
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextValidator label={'Group Telephone'}
                          fullWidth
                          variant="outlined"
                          type="number"
                          InputProps={{
                            startAdornment: <InputAdornment position="start" style={{ marginRight: '0px' }}>{'0'}</InputAdornment>,
                          }}
                          onInput={(e) => {
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9)
                          }}
                          value={group.telephone}
                          disabled
                          onChange={this.handleChange}
                          name="telephone"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}><h2 style={{ textDecoration: 'underline' }}>Update details</h2></Grid>
                      <Grid item md={4} xs={12} >
                        <TextValidator label={'Group Physical Address'}
                          disabled={registrationStatusClosed}
                          fullWidth
                          variant="outlined"
                          type="text"
                          value={this.state.address || group.address}
                          onChange={this.handleChange}
                          name="address"
                          placeholder={'group address'}
                          validators={['required']}
                          errorMessages={'Please enter a group address'}
                        />
                      </Grid>



                      <Grid item md={4} xs={12}>
                        <TextValidator label={'KRA PIN'}
                          disabled={registrationStatusClosed}
                          fullWidth
                          variant="outlined"
                          type="text"
                          value={this.state.kraPin || group.kra_pin}
                          onChange={this.handleChange}
                          name="kraPin"
                          placeholder={'KRA PIN'}
                          validators={['required', 'isValidKraPin']}
                          errorMessages={['Please enter a group KRA PIN', 'Invalid KRA PIN']}
                        />
                      </Grid>

                      <Grid item md={6} xs={12} >
                        <div className="form-group"  disabled={registrationStatusClosed}>
                          <div>
                            <br />
                            <centre>
                              <h3>Please upload certificate of incorporation</h3>
                              <br />
                              {
                                this.state.certificateError &&
                                <p style={{ color: 'red' }}>Error: Please upload certificate of incorporation. </p>
                              }
                              <br />
                              <br />
                              {this.renderCertificateUploader()}

                            </centre>
                          </div>

                        </div>
                      </Grid>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Button
                        variant="contained"
                        disabled={registrationStatusClosed}
                        style={registrationStatusClosed ? btnStyle.greyedOut : btnStyle.danger}
                        type="submit">
                        {'Update Group'}
                      </Button>
                      <Button
                        onClick={() => this.handleResetForm()}
                        variant="contained"
                        style={registrationStatusClosed ? btnStyle.greyedOut : btnStyle.green}
                        >
                        {labels.reset}
                      </Button>
                    </Grid>
                  </ValidatorForm>

                  <br />
                </div>
              </div>
              <hr />
            </div>
          </div>
          <SweetAlert
            success
            show={!!this.props.groupRegistrationData.groupRegistrationSuccess}
            title={'Group Registered'}
            onConfirm={() => this.goToGroupMembers()}
            confirmBtnText={'Add members'}
            cancelBtnText={'Register another group'}
            showCancel={true}
            onCancel={() => this.handleResetForm()}
            confirmBtnCssClass
            style={{ display: "block", marginTop: "80px", fontSize: "200%" }}
            confirmBtnStyle={{
              backgroundColor: "#4CAF50", /* Green */
              border: "none",
              color: "white",
              padding: "15px 32px",
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              fontSize: "26px",
              fontWeight: 800
            }}
            cancelBtnStyle={{
              border: "1px solid grey",
              color: "grey",
              padding: "15px 32px",
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              fontSize: "26px",
              fontWeight: 800
            }}
          >
            <h2> <span role="img" aria-label="smile"> 😀</span> Group Registration is Successful</h2>
            <br />
          </SweetAlert>

          <AlertDialog
            success
            show={!!this.props.groupRegistrationData.updateGroupSuccess}
            title={'Group Updated'}
            onConfirm={() => this.props.dispatch(actions.resetActualQuote())}
            confirmBtnText={'ok'}
            showCancel={false}
            cancelBtnText={labels.cancel}
            onCancel={() => this.props.dispatch(actions.resetActualQuote())}
            confirmBtnCssClass
          >
          </AlertDialog>

          <AlertDialog
            danger
            show={!!groupRegistrationError}
            title={'Schedule Excel file error'}
            confirmBtnText={'Correct the error and try again'}
            onConfirm={() => this.props.dispatch(actions.resetGroupRegistraion())}
            cancelBtnText={labels.tryAgain}
            showCancel={true}
            onCancel={() => this.props.dispatch(actions.resetGroupRegistraion())}
            confirmBtnCssClass
          >
            {groupRegistrationError ? groupRegistrationError.message : ''}
          </AlertDialog>

          <AlertDialog
            show={!!actualGroupQuoteError}
            danger
            title={'You have errors in your excel file download the log file to view the errors'}
            onConfirm={this.downloadCSVGroupScheduleErrors.bind(this)}
            confirmBtnText={'Download the Errors'}
            cancelBtnText={'try again'}
            confirmBtnCssClass
            showCancel={true}
            onCancel={() => this.props.dispatch(actions.resetActualQuote())}
          >
            {actualGroupQuoteError ? actualGroupQuoteError.message : ''}
          </AlertDialog>
          <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={updateGroupLoader}>
            <CircularProgress size={80} color="inherit" />
            <h3 style={{ marginTop: '10%', marginLeft: '-15%' }}>.....updating group....</h3>
          </Backdrop>
          <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={registerGroupLoader}>
            <CircularProgress size={80} color="inherit" />
            <h3 style={{ marginTop: '15%', marginLeft: '-15%' }}>.....Registering your Group.....</h3>
          </Backdrop>
        </div>

      );
    }else if(this.props.groupRegistrationData.getGroupLoader){
      return <div style={{textAlign: 'center'}}><CircularProgress /></div>
    }else{
      return <p style={{textAlign: 'center'}}>no group found</p>
    }
  }
}

export default UpdateGroup;
