import * as types from '../../../../../../lib/actionTypes.es6'


export function getAccessBankCustomerQuotesRequest(payload) {
  return {
    type: types.GET_ACCESSBANK_CUSTOMER_QUOTES_REQUEST,
    payload,
  };
}

export function getAccessBankCustomerQuotesSuccess(response) {
  return {
    type: types.GET_ACCESSBANK_CUSTOMER_QUOTES_SUCCESS,
    payload: response,
  };
}

export function getAccessBankCustomerQuotesFailure(error) {
  return {
    type: types.GET_ACCESSBANK_CUSTOMER_QUOTES_FAILURE,
    payload: error,
  };
}