import * as types from '../../../lib/actionTypes.es6';
import { getPaginatedMembers, getPaginatedGroupPolicies } from '../../../lib/utils.es6';

export const initialState = {
  errors: null,
  membersError: null,
  loaderMembers: false,
  loaderPremiums: false,
  loaderClaims: false,
  loaderPolicies: false,
  loaderPayments: false,
  loaderClaimCallbacks: false,
  members: [],
  membersLoadedSuccess: false,
  policies: [],
  payments: [],
  premiums: [],
  claims: [],
  claimCallbacks: [],
  count: 0,
  size: 5,
  policiesCount: 0,
};

export default function currentGroupReducer(state = initialState, action) {
  switch (action.type) {

    case types.GET_CURRENT_GROUP_MEMBERS_REQUEST:
      return { ...state, loaderMembers: true, errors: null, members: [] };

    case types.GET_CURRENT_GROUP_MEMBERS_SUCCESS:
      return { ...state, loaderMembers: false, ...getPaginatedMembers(action.payload)};

    case types.GET_CURRENT_GROUP_MEMBERS_FAILURE:
      return { ...state, loaderMembers: false, errors: action.payload };

    case types.GET_CURRENT_GROUP_MEMBERS_FOR_ADMINS_REQUEST:
      return { ...state, loaderMembers: true, membersError: null, members: [] };

    case types.GET_CURRENT_GROUP_MEMBERS_FOR_ADMINS_SUCCESS:
      return { ...state, loaderMembers: false, ...getPaginatedMembers(action.payload) };

    case types.GET_CURRENT_GROUP_MEMBERS_FOR_ADMINS_FAILURE:
      return { ...state, loaderMembers: false, membersError: action.payload };
    
    case types.GET_CURRENT_GROUP_MEMBERS_FOR_CSV_REQUEST:
      return { ...state, loaderMembers: true, membersError: null, members: [] };

    case types.GET_CURRENT_GROUP_MEMBERS_FOR_CSV_SUCCESS:
      return { ...state, loaderMembers: false, members: action.payload, membersLoadedSuccess: true };

    case types.GET_CURRENT_GROUP_MEMBERS_FOR_CSV_FAILURE:
      return { ...state, loaderMembers: false, membersError: action.payload, membersLoadedSuccess: false };

    case types.RESET_GET_CURRENT_GROUP_MEMBERS_FOR_CSV:
      return { ...state, loaderMembers: false, membersError: null, members: [], membersLoadedSuccess: false };
    case types.GET_CURRENT_GROUP_POLICIES_REQUEST:
      return { ...state, loaderPolicies: true, errors: null, policies: [] };

    case types.GET_CURRENT_GROUP_POLICIES_SUCCESS:
      return { ...state, loaderPolicies: false, ...getPaginatedGroupPolicies(action.payload) };

    case types.GET_CURRENT_GROUP_POLICIES_FAILURE:
      return { ...state, loaderPolicies: false, errors: action.payload };



    case types.GET_CURRENT_GROUP_PAYMENTS_REQUEST:
      return { ...state, loaderPayments: true, errors: null, payments: [] };

    case types.GET_CURRENT_GROUP_PAYMENTS_SUCCESS:
      return { ...state, loaderPayments: false, payments: action.payload };

    case types.GET_CURRENT_GROUP_PAYMENTS_FAILURE:
      return { ...state, loaderPayments: false, errors: action.payload };



    case types.GET_CURRENT_GROUP_PREMIUMS_REQUEST:
      return { ...state, loaderPremiums: true, errors: null, premiums: [] };

    case types.GET_CURRENT_GROUP_PREMIUMS_SUCCESS:
      return { ...state, loaderPremiums: false, premiums: action.payload };

    case types.GET_CURRENT_GROUP_PREMIUMS_FAILURE:
      return { ...state, loaderPremiums: false, errors: action.payload };


    case types.GET_CURRENT_GROUP_CLAIMS_REQUEST:
      return { ...state, loaderClaims: true, errors: null, claims: [] };

    case types.GET_CURRENT_GROUP_CLAIMS_SUCCESS:
      return { ...state, loaderClaims: false, claims: action.payload };

    case types.GET_CURRENT_GROUP_CLAIMS_FAILURE:
      return { ...state, loaderClaims: false, errors: action.payload };
    
    case types.GET_CURRENT_GROUP_CLAIM_CALLBACKS_REQUEST:
      return { ...state, loaderClaimCallbacks: true, errors: null, claimCallbacks: [] };

    case types.GET_CURRENT_GROUP_CLAIM_CALLBACKS_SUCCESS:
      return { ...state, loaderClaimCallbacks: false, claimCallbacks: action.payload };

    case types.GET_CURRENT_GROUP_CLAIM_CALLBACKS_FAILURE:
      return { ...state, loaderClaimCallbacks: false, errors: action.payload };


    default:
      return state;
  }
}