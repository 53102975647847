import React from 'react'
import DeathClaimDetails from '../DeathClaimDetails/DeathClaimDetails'
import HospitalClaimDetails from '../HospitalClaimDetails/HospitalClaimDetails'
import FuneralClaimDetails from '../FuneralClaimDetails/FuneralClaimDetails'
import DeviceDamageClaimDetails from '../DeviceDamageClaimDetails/DeviceDamageClaimDetails'
import DisabilityClaimDetails from '../DisabilityClaimDetails/DisabilityClaimDetails'
import DeviceTheftClaimDetails from '../DeviceTheftClaimDetails/DeviceTheftClaimDetails'
import LastExpenseClaimDetails from '../LastExpenseClaimDetails/LastExpenseClaimDetails'
import PropertyClaimDetails from '../PropertyClaimDetails/PropertyClaimDetails'
export default function AdditionalDetails(props) {

  if(props.hasDeathBenefit){
    return <DeathClaimDetails {...props} />
  }
  if(props.hasHospitalBenefit){
    return <HospitalClaimDetails {...props} />
  }
  if(props.hasFuneralBenefit){
    return <FuneralClaimDetails {...props} />
  }
  if(props.hasDeviceDamageBenefit){
    return <DeviceDamageClaimDetails {...props} />
  }
  if(props.hasParmanentDisabilityBenefit){
    return <DisabilityClaimDetails {...props} />
  }
  if(props.hasDeviceTheftBenefit){
    return <DeviceTheftClaimDetails {...props} />
  }

  if(props.hasLastExpenseBenfit){
    return <LastExpenseClaimDetails {...props} />
  }

  if(props.hasPropertyBenefit){
    return <PropertyClaimDetails {...props} />
  }

  
}
