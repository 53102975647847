export const BankData = [

  {
    "bank_name" : "ACCESS BANK",
    "digit_account": 13,
    "branch_details": [
      {
        "name": "Retail Head Office",
        "code": "550067",
        "swift_code": "FMBZBWGA000"
      },
      {
        "name": "Squaremart",
        "code": "550167",
        "swift_code": "FMBZBWGA001"
      },
      {
        "name": "Fair ground Branch",
        "code": "550267",
        "swift_code": "FMBZBWGA002"
      },
      {
        "name": "Francistown",
        "code": "550367",
        "swift_code": "FMBZBWGA003"
      },
      {
        "name": "Game City",
        "code": "550467",
        "swift_code": "FMBZBWGA004"
      },
      {
        "name": "Airport Junction",
        "code": "550567",
        "swift_code": "FMBZBWGA005"
      },
      {
        "name": "Maun Branch",
        "code": "550667",
        "swift_code": "FMBZBWGA006"
      },
      {
        "name": "Selibe Phikwe",
        "code": "550767",
        "swift_code": "FMBZBWGA007"
      },
      {
        "name": "Palapye",
        "code": "550867",
        "swift_code": "FMBZBWGA008"
      },
      {
        "name": "Private Branch",
        "code": "550967",
        "swift_code": "FMBZBWGA009"
      },
      {
        "name": "Kanye Service centre",
        "code": "551067",
        "swift_code": "FMBZBWGA055"
      },
      {
        "name": "Molepolole Sales and Service Centre",
        "code": "551167",
        "swift_code": "FMBZBWGA056"
      },
      {
        "name": "Letlhakane Sales and Service Centre",
        "code": "551267",
        "swift_code": "FMBZBWGA057"
      },
      {
        "name": "Ghanzi Service Centre",
        "code": "551367",
        "swift_code": "FMBZBWGA058"
      },
      {
        "name": "Virtual",
        "code": "551467",
        "swift_code": "N/A"
      },
      {
        "name": "Mogoditshane",
        "code": "551567",
        "swift_code": "N/A"
      },
      {
        "name": "Mochudi",
        "code": "551667",
        "swift_code": "N/A"
      },
      {
        "name": "Lobatse",
        "code": "551767",
        "swift_code": "N/A"
      },
      {
        "name": "Mahalapye",
        "code": "551867",
        "swift_code": "N/A"
      },
      {
        "name": "Serowe",
        "code": "551967",
        "swift_code": "N/A"
      },
      {
        "name": "Shakawe",
        "code": "552367",
        "swift_code": "N/A"
      },
      {
        "name": "Kasane",
        "code": "552667",
        "swift_code": "N/A"
      },
    ]    
  },
  {
    "bank_name" : "BANK OF BARODA (BOTSWANA) LTD",
    "digit_account": 13,
    "branch_details": [
      {
        "name": "Main Mall",
        "code": "110167",
        "swift_code": "BARBBWGX001"
      },
      {
        "name": "Francistown",
        "code": "110267",
        "swift_code": "BARBBWGX002"
      },
      {
        "name": "Gaborone West",
        "code": "110367",
        "swift_code": "BARBBWGX003"
      },
    ]    
  },

  {
    "bank_name": "ABSA BANK OF BOTSWANA LTD",
    "digit_account": 7,
    "branch_details": [
      {
        "name": "Gaborone Mall",
        "code": "290167",
        "swift_code": "BARCBWGX001"
      },
      {
        "name": "Barclays House",
        "code": "290267",
        "swift_code": "BARCBWGX002"
      },
      {
        "name": "Gaborone Industrial",
        "code": "290367",
        "swift_code": "BARCBWGX003"
      },
      {
        "name": "Broadhurst",
        "code": "290467",
        "swift_code": "BARCBWGX004"
      },
      {
        "name": "Merafhe/Gemvas",
        "code": "290567",
        "swift_code": "BARCBWGX005"
      },
      {
        "name": "BOFEX",
        "code": "290667",
        "swift_code": "BARCBWGX006"
      },
      {
        "name": "Carbo Centre",
        "code": "290767",
        "swift_code": "BARCBWGX007"
      },
      {
        "name": "Lobatse",
        "code": "290867",
        "swift_code": "BARCBWGX008"
      },
      {
        "name": "Orapa",
        "code": "290967",
        "swift_code": "BARCBWGX009"
      },
      {
        "name": "Jwaneng",
        "code": "291067",
        "swift_code": "BARCBWGX010"
      },
      {
        "name": "Ghanzi",
        "code": "291167",
        "swift_code": "BARCBWGX011"
      },
      {
        "name": "Kanye",
        "code": "291267",
        "swift_code": "BARCBWGX012"
      },
      {
        "name": "Mahalapye",
        "code": "291367",
        "swift_code": "BARCBWGX013"
      },
      {
        "name": "Serowe",
        "code": "291467",
        "swift_code": "BARCBWGX014"
      },
      {
        "name": "Palapye",
        "code": "291567",
        "swift_code": "BARCBWGX015"
      },
      {
        "name": "Selibe Phikwe",
        "code": "291667",
        "swift_code": "BARCBWGX016"
      },
      {
        "name": "Francistown",
        "code": "291767",
        "swift_code": "BARCBWGX017"
      },
      {
        "name": "Tutume",
        "code": "291867",
        "swift_code": "BARCBWGX018"
      },
      {
        "name": "Maun",
        "code": "291967",
        "swift_code": "BARCBWGX019"
      },
      {
        "name": "Kasane",
        "code": "292067",
        "swift_code": "BARCBWGX020"
      },
      {
        "name": "Tsabong",
        "code": "292167",
        "swift_code": "BARCBWGX021"
      },
      {
        "name": "Masunga",
        "code": "292267",
        "swift_code": "BARCBWGX022"
      },
      {
        "name": "Bobonong",
        "code": "292367",
        "swift_code": "BARCBWGX023"
      },
      {
        "name": "Mochudi",
        "code": "292467",
        "swift_code": "BARCBWGX024"
      },
      {
        "name": "Molepolole",
        "code": "292567",
        "swift_code": "BARCBWGX025"
      },
      {
        "name": "Tlokweng",
        "code": "292667",
        "swift_code": "BARCBWGX026"
      },
      {
        "name": "Ramotswa",
        "code": "292767",
        "swift_code": "BARCBWGX027"
      },
      {
        "name": "Moshupa",
        "code": "292867",
        "swift_code": "BARCBWGX028"
      },
      {
        "name": "Mogoditshane",
        "code": "292967",
        "swift_code": "BARCBWGX029"
      },
      {
        "name": "Sowa/Nata",
        "code": "293067",
        "swift_code": "BARCBWGX030"
      },
      {
        "name": "Gumare",
        "code": "293267",
        "swift_code": "BARCBWGX032"
      },
      {
        "name": "Shakawe",
        "code": "293367",
        "swift_code": "BARCBWGX033"
      },
      {
        "name": "Airport Junction",
        "code": "293467",
        "swift_code": "BARCBWGX034"
      },
      {
        "name": "Game City",
        "code": "293567",
        "swift_code": "BARCBWGX035"
      },
      {
        "name": "Phakalane",
        "code": "293667",
        "swift_code": "BARCBWGX036"
      },
      {
        "name": "Galo Prestige",
        "code": "293767",
        "swift_code": "BARCBWGX037"
      },
      {
        "name": "Gaborone Station",
        "code": "293867",
        "swift_code": "BARCBWGX038"
      },
      {
        "name": "Letlhakane",
        "code": "293967",
        "swift_code": "BARCBWGX039"
      },
      {
        "name": "Galagwe",
        "code": "294067",
        "swift_code": "BARCBWGX040"
      },
      {
        "name": "Tati Nickel(Not yet operated)",
        "code": "294167",
        "swift_code": "BARCBWGX041"
      },
      {
        "name": "Botshabelo",
        "code": "294367",
        "swift_code": "BARCBWGX043"
      },
      {
        "name": "Personal Prestige",
        "code": "294467",
        "swift_code": "BARCBWGX044"
      },
      {
        "name": "Selibe Phikwe Prestige",
        "code": "294567",
        "swift_code": "BARCBWGX045"
      },
      {
        "name": "Blue Jacket",
        "code": "294667",
        "swift_code": "BARCBWGX046"
      },
      {
        "name": "Gaborone Savings Branch",
        "code": "296467",
        "swift_code": "BARCBWGX064"
      },
      {
        "name": "Head Office",
        "code": "297867",
        "swift_code": "BARCBWGX078"
      }
    ]
  },
  {
    "bank_name": "BANK GABORONE LTD",
    "digit_account": 10,
    "branch_details": [
      {
        "name": "Head Office",
        "code": "200167",
        "swift_code": "BGLIBWGX001"
      },
      {
        "name": "Treasury",
        "code": "200267",
        "swift_code": "BGLIBWGX002"
      },
      {
        "name": "Home Loans",
        "code": "200367",
        "swift_code": "BGLIBWGX003"
      },
      {
        "name": "Gaborone",
        "code": "200467",
        "swift_code": "BGLIBWGX004"
      },
      {
        "name": "Central Processing Centre",
        "code": "200567",
        "swift_code": "BGLIBWGX005"
      },
      {
        "name": "Mall & Molepolole Branches",
        "code": "200667",
        "swift_code": "BGLIBWGX006"
      },
      {
        "name": "Legal",
        "code": "200767",
        "swift_code": "BGLIBWGX007"
      },
      {
        "name": "Micro Finance",
        "code": "201067",
        "swift_code": "BGLIBWGX010"
      },
      {
        "name": "Corporate",
        "code": "201167",
        "swift_code": "BGLIBWGX011"
      },
      {
        "name": "Vehicle and Asset Finance",
        "code": "201267",
        "swift_code": "BGLIBWGX012"
      },
      {
        "name": "Game City",
        "code": "201367",
        "swift_code": "BGLIBWGX013"
      },
      {
        "name": "Francistown",
        "code": "201467",
        "swift_code": "BGLIBWGX014"
      },
      {
        "name": "Cards Division",
        "code": "201567",
        "swift_code": "BGLIBWGX015"
      },
      {
        "name": "ATM Mechant",
        "code": "201667",
        "swift_code": "BGLIBWGX016"
      },
      {
        "name": "Ghanzi Branch",
        "code": "201767",
        "swift_code": "BGLIBWGX017"
      },
      {
        "name": "Airport Junction",
        "code": "201867",
        "swift_code": "BGLIBWGX018"
      },
      {
        "name": "Kang",
        "code": "201967",
        "swift_code": "BGLIBWGX019"
      },
      {
        "name": "Maun",
        "code": "202067",
        "swift_code": "BGLIBWGX020"
      },
      {
        "name": "Palapye Brach",
        "code": "202167",
        "swift_code": "BGLIBWGX021"
      }
    ]
  },
  {
    "bank_name": "CAPITAL BANK LTD",
    "digit_account": 13,
    "branch_details": [
      {
        "name": "Head Office",
        "code": "800167",
        "swift_code": "CAPLBWGA001"
      },
      {
        "name": "Gaborone Main Branch",
        "code": "800267",
        "swift_code": "CAPLBWGA002"
      },
      {
        "name": "Francistown",
        "code": "800367",
        "swift_code": "CAPLBWGA003"
      },
      {
        "name": "Broadhurst",
        "code": "800467",
        "swift_code": "CAPLBWGA004"
      },
      {
        "name": "Mogoditshane",
        "code": "800567",
        "swift_code": "CAPLBWGA005"
      }
    ]
  },
  {
    "bank_name": "FIRST NATIONAL BANK OF BOTSWANA LTD",
    "digit_account": 11,
    "branch_details": [
      {
        "name": "Property Division",
        "code": "281067",
        "swift_code": "FIRNBWGX010"
      },
      {
        "name": "Broadhurst",
        "code": "281267",
        "swift_code": "FIRNBWGX012"
      },
      {
        "name": "IBD Botswana (Division)",
        "code": "281367",
        "swift_code": "FIRNBWGX013"
      },
      {
        "name": "Gaborone Main",
        "code": "281467",
        "swift_code": "FIRNBWGX014"
      },
      {
        "name": "Wes Bank",
        "code": "281567",
        "swift_code": "FIRNBWGX015"
      },
      {
        "name": "Gaborone Industrial",
        "code": "281667",
        "swift_code": "FIRNBWGX016"
      },
      {
        "name": "Lobatse",
        "code": "281767",
        "swift_code": "FIRNBWGX017"
      },
      {
        "name": "Francistown",
        "code": "281867",
        "swift_code": "FIRNBWGX018"
      },
      {
        "name": "Kanye",
        "code": "281967",
        "swift_code": "FIRNBWGX019"
      },
      {
        "name": "Head Office",
        "code": "282067",
        "swift_code": "FIRNBWGX020"
      },
      {
        "name": "Treasury",
        "code": "282167",
        "swift_code": "FIRNBWGX021"
      },
      {
        "name": "Corporate",
        "code": "282267",
        "swift_code": "FIRNBWGX022"
      },
      {
        "name": "Maun",
        "code": "282367",
        "swift_code": "FIRNBWGX023"
      },
      {
        "name": "Mahalapye",
        "code": "282467",
        "swift_code": "FIRNBWGX024"
      },
      {
        "name": "Technology Service Division",
        "code": "282567",
        "swift_code": "FIRNBWGX025"
      },
      {
        "name": "First Card",
        "code": "282767",
        "swift_code": "FIRNBWGX027"
      },
      {
        "name": "Gaborone Mall",
        "code": "282867",
        "swift_code": "FIRNBWGX028"
      },
      {
        "name": "Jwaneng",
        "code": "283067",
        "swift_code": "FIRNBWGX030"
      },
      {
        "name": "Palapye",
        "code": "283167",
        "swift_code": "FIRNBWGX031"
      },
      {
        "name": "West Bank Corporate",
        "code": "283267",
        "swift_code": "FIRNBWGX032"
      },
      {
        "name": "WesBank Motor",
        "code": "283367",
        "swift_code": "FIRNBWGX033"
      },
      {
        "name": "WesBank Francistown",
        "code": "283467",
        "swift_code": "FIRNBWGX034"
      },
      {
        "name": "Electronic Debit Card Center",
        "code": "283667",
        "swift_code": "FIRNBWGX036"
      },
      {
        "name": "Electronic Banking",
        "code": "283767",
        "swift_code": "FIRNBWGX037"
      },
      {
        "name": "Premium Credit",
        "code": "283867",
        "swift_code": "FIRNBWGX038"
      },
      {
        "name": "Code Line Clearing",
        "code": "284367",
        "swift_code": "FIRNBWGX043"
      },
      {
        "name": "Kgale View",
        "code": "284567",
        "swift_code": "FIRNBWGX045"
      },
      {
        "name": "First Funding Motor",
        "code": "284767",
        "swift_code": "FIRNBWGX047"
      },
      {
        "name": "Islamic Finance",
        "code": "284867",
        "swift_code": "FIRNBWGX048"
      },
      {
        "name": "Selebi Phikwe",
        "code": "285067",
        "swift_code": "FIRNBWGX050"
      },
      {
        "name": "Kasane",
        "code": "285167",
        "swift_code": "FIRNBWGX051"
      },
      {
        "name": "Riverwalk",
        "code": "285267",
        "swift_code": "FIRNBWGX052"
      },
      {
        "name": "Serowe",
        "code": "285367",
        "swift_code": "FIRNBWGX053"
      },
      {
        "name": "Private Clients",
        "code": "285467",
        "swift_code": "FIRNBWGX054"
      },
      {
        "name": "Letlhakane",
        "code": "285567",
        "swift_code": "FIRNBWGX055"
      },
      {
        "name": "Molepolole",
        "code": "285667",
        "swift_code": "FIRNBWGX056"
      },
      {
        "name": "Ghanzi",
        "code": "286067",
        "swift_code": "FIRNBWGX060"
      },
      {
        "name": "RMB",
        "code": "287867",
        "swift_code": "FIRNBWGX078"
      },
      {
        "name": "Scheme Center",
        "code": "288067",
        "swift_code": "FIRNBWGX080"
      },
      {
        "name": "Rail Park Mall",
        "code": "288467",
        "swift_code": "FIRNBWGX084"
      },
      {
        "name": "Airport Junction",
        "code": "288267",
        "swift_code": "FIRNBWGX082"
      },
      {
        "name": "Main",
        "code": "288367",
        "swift_code": "FIRNBWGX083"
      },
      {
        "name": "Mochudi",
        "code": "288767",
        "swift_code": "FIRNBWGX087"
      },
      {
        "name": "Mogoditshane",
        "code": "288967",
        "swift_code": "FIRNBWGX089"
      },
      {
        "name": "Commercial Property Finance",
        "code": "289467",
        "swift_code": "FIRNBWGX094"
      }
    ]
  },
  {
    "bank_name": "STANBIC BANK BOTSWANA LTD",
    "digit_account": 13,
    "branch_details": [
      {
        "name": "Kgale",
        "code": "60167",
        "swift_code": "SBICBWGX001"
      },
      {
        "name": "Gaborone West",
        "code": "60267",
        "swift_code": "SBICBWGX002"
      },
      {
        "name": "Airport Junction (Broadhurst)",
        "code": "60367",
        "swift_code": "SBICBWGX003"
      },
      {
        "name": "Head Office",
        "code": "60467",
        "swift_code": "SBICBWGX004"
      },
      {
        "name": "VAF",
        "code": "60567",
        "swift_code": "SBICBWGX005"
      },
      {
        "name": "Gaborone Industrial",
        "code": "61967",
        "swift_code": "SBICBWGX019"
      },
      {
        "name": "Mobile Banking",
        "code": "62267",
        "swift_code": "SBICBWGX022"
      },
      {
        "name": "Francistown",
        "code": "64067",
        "swift_code": "SBICBWGX040"
      },
      {
        "name": "Maun",
        "code": "64767",
        "swift_code": "SBICBWGX047"
      },
      {
        "name": "Fair grounds",
        "code": "64967",
        "swift_code": "SBICBWGX049"
      },
      {
        "name": "Palapye",
        "code": "65067",
        "swift_code": "SBICBWGX050"
      },
      {
        "name": "CBD Square",
        "code": "65167",
        "swift_code": "SBICBWGX051"
      },
      {
        "name": "Mogoditshane",
        "code": "65367",
        "swift_code": "SBICBWGX053"
      },
      {
        "name": "Molepolole",
        "code": "65467",
        "swift_code": "SBICBWGX054"
      },
      {
        "name": "Credit card",
        "code": "65567",
        "swift_code": "SBICBWGX055"
      },
      {
        "name": "CPS Credit Card",
        "code": "65667",
        "swift_code": "SBICBWGX056"
      },
      {
        "name": "Selebi Phikwe",
        "code": "65767",
        "swift_code": "SBICBWGX057"
      },
      {
        "name": "Work Place Banking",
        "code": "65867",
        "swift_code": "SBICBWGX058"
      },
      {
        "name": "Acacia",
        "code": "65967",
        "swift_code": "SBICBWGX059"
      }
    ]
  },
  {
    "bank_name": "BANK SBI LTD",
    "digit_account": 11,
    "branch_details": [
      {
        "name": "CBD Gaborone",
        "code": "500167",
        "swift_code": "SBINBWGX001"
      }
    ]
  },
  {
    "bank_name": "STANDARD CHARTERED BANK BOTSWANA LTD",
    "digit_account": 13,
    "branch_details": [
      {
        "name": "Head Office",
        "code": "660167",
        "swift_code": "SCHBBWGX001"
      },
      {
        "name": "Lobatse",
        "code": "660867",
        "swift_code": "SCHBBWGX008"
      },
      {
        "name": "Jwaneng",
        "code": "660967",
        "swift_code": "SCHBBWGX009"
      },
      {
        "name": "Mahalapye",
        "code": "661367",
        "swift_code": "SCHBBWGX013"
      },
      {
        "name": "Serowe",
        "code": "661467",
        "swift_code": "SCHBBWGX014"
      },
      {
        "name": "Palapye",
        "code": "661567",
        "swift_code": "SCHBBWGX015"
      },
      {
        "name": "Selibe Phikwe",
        "code": "661667",
        "swift_code": "SCHBBWGX016"
      },
      {
        "name": "Francistown",
        "code": "661767",
        "swift_code": "SCHBBWGX017"
      },
      {
        "name": "Orapa",
        "code": "661867",
        "swift_code": "SCHBBWGX018"
      },
      {
        "name": "Maun",
        "code": "661967",
        "swift_code": "SCHBBWGX019"
      },
      {
        "name": "Gaborone Mall",
        "code": "662167",
        "swift_code": "SCHBBWGX021"
      },
      {
        "name": "Gaborone Standard House",
        "code": "662267",
        "swift_code": "SCHBBWGX022"
      },
      {
        "name": "Gaborone Industrial",
        "code": "662367",
        "swift_code": "SCHBBWGX023"
      },
      {
        "name": "Broadhurst",
        "code": "662467",
        "swift_code": "SCHBBWGX024"
      },
      {
        "name": "Airport Junction",
        "code": "662567",
        "swift_code": "SCHBBWGX025"
      },
      {
        "name": "Francistown Nzamo",
        "code": "662667",
        "swift_code": "SCHBBWGX026"
      },
      {
        "name": "Hemamo Centre",
        "code": "662767",
        "swift_code": "SCHBBWGX027"
      },
      {
        "name": "Game City/Gaborone West",
        "code": "662867",
        "swift_code": "SCHBBWGX028"
      },
      {
        "name": "Sir Seretse Khama International",
        "code": "663167",
        "swift_code": "SCHBBWGX031"
      },
      {
        "name": "Mowana",
        "code": "663267",
        "swift_code": "SCHBBWGX032"
      }
    ]
  }

]

export const SouthAfricaBanks = [

  {
    "bank_name" : "BANK A",
    "digit_account": 13,
    "branch_details": [
      {
        "name": "Retail Head Office",
        "code": "550067",
        "swift_code": "FMBZBWGA000"
      },
      {
        "name": "Squaremart",
        "code": "550167",
        "swift_code": "FMBZBWGA001"
      },
      {
        "name": "Fair ground Branch",
        "code": "550267",
        "swift_code": "FMBZBWGA002"
      },
      {
        "name": "Francistown",
        "code": "550367",
        "swift_code": "FMBZBWGA003"
      },
      {
        "name": "Game City",
        "code": "550467",
        "swift_code": "FMBZBWGA004"
      },
      {
        "name": "Airport Junction",
        "code": "550567",
        "swift_code": "FMBZBWGA005"
      },
      {
        "name": "Maun Branch",
        "code": "550667",
        "swift_code": "FMBZBWGA006"
      },
      {
        "name": "Selibe Phikwe",
        "code": "550767",
        "swift_code": "FMBZBWGA007"
      },
      {
        "name": "Palapye",
        "code": "550867",
        "swift_code": "FMBZBWGA008"
      },
      {
        "name": "Private Branch",
        "code": "550967",
        "swift_code": "FMBZBWGA009"
      },
      {
        "name": "Kanye Service centre",
        "code": "551067",
        "swift_code": "FMBZBWGA055"
      },
      {
        "name": "Molepolole Sales and Service Centre",
        "code": "551167",
        "swift_code": "FMBZBWGA056"
      },
      {
        "name": "Letlhakane Sales and Service Centre",
        "code": "551267",
        "swift_code": "FMBZBWGA057"
      },
      {
        "name": "Ghanzi Service Centre",
        "code": "551367",
        "swift_code": "FMBZBWGA058"
      },
      {
        "name": "Virtual",
        "code": "551467",
        "swift_code": "N/A"
      },
      {
        "name": "Mogoditshane",
        "code": "551567",
        "swift_code": "N/A"
      },
      {
        "name": "Mochudi",
        "code": "551667",
        "swift_code": "N/A"
      },
      {
        "name": "Lobatse",
        "code": "551767",
        "swift_code": "N/A"
      },
      {
        "name": "Mahalapye",
        "code": "551867",
        "swift_code": "N/A"
      },
      {
        "name": "Serowe",
        "code": "551967",
        "swift_code": "N/A"
      },
      {
        "name": "Shakawe",
        "code": "552367",
        "swift_code": "N/A"
      },
      {
        "name": "Kasane",
        "code": "552667",
        "swift_code": "N/A"
      },
    ]    
  },
  
  {
    "bank_name" : "FNB",
    "digit_account": 13,
    "branch_details": [
      {
        "name": "Jhb Main",
        "code": "110167",
        "swift_code": "BARBBWGX001"
      },
      {
        "name": "Cape Town",
        "code": "110267",
        "swift_code": "BARBBWGX002"
      },
      {
        "name": "Bellville",
        "code": "110367",
        "swift_code": "BARBBWGX003"
      },
    ]    
  },

  {
    "bank_name": "ABSA",
    "digit_account": 7,
    "branch_details": [
      {
        "name": "Jhb",
        "code": "290167",
        "swift_code": "BARCBWGX001"
      },
      {
        "name": "Barclays House",
        "code": "290267",
        "swift_code": "BARCBWGX002"
      },
      {
        "name": "Cape Town",
        "code": "290367",
        "swift_code": "BARCBWGX003"
      },
    ]
  },
  {
    "bank_name": "Nedbank",
    "digit_account": 9,
    "branch_details": [
      {
        "name": "Jhb",
        "code": "333234",
        "swift_code": "BARCBWGX001"
      },
      {
        "name": "Barclays House",
        "code": "5765432",
        "swift_code": "BARCBWGX002"
      },
      {
        "name": "Cape Town",
        "code": "345678",
        "swift_code": "BARCBWGX003"
      },
    ]
  },
  {
    "bank_name": "Standard Bank",
    "digit_account": 8,
    "branch_details": [
      {
        "name": "Jhb",
        "code": "12323",
        "swift_code": "BARCBWGX001"
      },
      {
        "name": "Barclays House",
        "code": "5676543",
        "swift_code": "BARCBWGX002"
      },
      {
        "name": "Cape Town",
        "code": "09876543",
        "swift_code": "BARCBWGX003"
      },
    ]
  },
]