import * as types from '../../lib/actionTypes.es6';
import { take, call, put } from 'redux-saga/effects';
import {addRemittances} from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';


export function* addRemittancesRequestWatcher() {
  while (true) {
    const requestAction = yield take(types.ADD_REMITTANCES_REQUEST);
    let response = {};
    
    try {
      response = yield call(
        addRemittances,
        requestAction.payload
      );
      yield put(actions.addRemittancesSuccess(response));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.addRemittancesFail(errorPayload));
    }
  }
}
