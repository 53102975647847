import React from "react";
import { hashHistory } from 'react-router';
import LocalizedStrings from 'react-localization';
import { connect } from "react-redux";
import PolicyHeader from '../PolicyHeader/PolicyHeader'
import RegisterCustomer from '../RegisterCustomer/RegisterCustomer'
import { registerCustomerRequest,resetRegistration } from "../../../../../containers/CustomerScreen/components/RegisterCustomer/actions.es6";
import { reloadPage } from "../../../../../lib/utils.es6";
import { localisedText } from "../../../../../lib/localisation.es6";
class RegisterCustomerOrange extends React.Component {
  constructor(props) {
    const localizedStrings = new LocalizedStrings(localisedText);
    super(props);
    this.state = {
      openRegisterCustomerDialog: false,
      labels: localizedStrings,
    }
  }

  componentWillMount() {
    this.updateLanguage();
  }
  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    const language = this.props.globalData.language;
    let resetLanguage = false;
    if (!language) {
      resetLanguage = true;
    }
    const labels = this.state.labels;
    if (resetLanguage || labels.getLanguage() !== language) {
      labels.setLanguage(language);
      this.setState({});
    }
  }


  toggleRegisterCustomerDialog() {
    this.setState({ openRegisterCustomerDialog: !this.state.openRegisterCustomerDialog })
  }
  registerCustomer(customerData) {
    this.props.dispatch(registerCustomerRequest(customerData));
  }
  resetRegistrationSuccess() {
    this.props.dispatch(resetRegistration());
		hashHistory.push('admin/customer_status/' + this.props.customerData.currentCustomer.guid);
		this.toggleRegisterCustomerDialog()
		reloadPage();
  }
  resetRegistrationError() {
		this.props.dispatch(resetRegistration())

  }
  render() {
    if (this.props.customerData.currentCustomer) {

      return (
        <>
          < PolicyHeader 
          open={this.toggleRegisterCustomerDialog.bind(this)}
          labels={this.state.labels}
          />
          <RegisterCustomer
            openRegisterCustomerDialog={this.state.openRegisterCustomerDialog}
            toggleRegisterCustomerDialog={this.toggleRegisterCustomerDialog.bind(this)}
            registerCustomer={this.registerCustomer.bind(this)}
            registrationData={this.props.registrationData}
            resetRegistrationSuccess={this.resetRegistrationSuccess.bind(this)}
            resetRegistrationError={this.resetRegistrationError.bind(this)}
            labels={this.state.labels}
          />

        </>
      )
    } else {
      return ""
    }

  }
}

export default connect((state) => ({
  customerData: state.currentCustomer,
  registrationData: state.customerRegistration,
  globalData: state.global,

}))(RegisterCustomerOrange);