import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  loader: false,
  success_remittances: '',
  error_remittances: null,
  remittances_report_file: null,
};

export default function manualRemittancesReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_REMITTANCES_REQUEST:
      return { ...state, loader: true };
    case types.ADD_REMITTANCES_SUCCESS:
      return {
        ...state,
        loader: false,
        success_remittances: 'Remittances Added Successfully',
        remittances_report_file: action.payload,
      };
    case types.ADD_REMITTANCES_FAIL:
      return { ...state, loader: false, error_remittances: action.payload };

    case types.ADD_REMITTANCES_RESET:
      return { initialState };

    default:
      return state;
  }
}
