import * as types from '../../../lib/actionTypes.es6'

export const initialState = {
  errors: null,
  loader: false,


  eventQuote: null,
  eventQuoteSuccess: false,
  eventQuoteLoader: false,
  eventQuoteError: null,

  initiateClaim: null,
  initiateClaimSuccess: false,
  initiateClaimLoader: false,
  initiateClaimError: null,


  get_customer_claims_dcp_errors: null,
  get_customer_claims_dcp_loader: false,
  get_customer_claims_dcp_success_message: '',
  customer_claims_dcp: [],


  get_claims_dcp_errors: null,
  get_claims_dcp_loader: false,
  get_claims_dcp_success_message: '',
  claims_dcp: [],

};

export default function claimInitiationReducer(state = initialState, action) {
  switch (action.type) {


    case types.GET_EVENT_QUOTE_REQUEST:
      return {
        ...state,
        eventQuoteError: null,
        eventQuoteLoader: true,
        eventQuoteSuccess: false,
        eventQuote: null
      };

    case types.GET_EVENT_QUOTE_SUCCESS:
      return {
        ...state,
        eventQuote: action.payload,
        eventQuoteSuccess: true,
        eventQuoteLoader: false,
        eventQuoteError: null,
      };

    case types.GET_EVENT_QUOTE_FAILURE:
      return { ...state, eventQuoteLoader: false, eventQuoteError: action.payload };

    case types.RESET_GET_EVENT_QUOTE_SUCCESS:
      return { ...state, eventQuoteSuccess: false };

    case types.RESET_GET_EVENT_QUOTE_ERROR:
      return { ...state, eventQuoteError: null };

    case types.INITIATE_CLAIM_REQUEST:
      return {
        ...state,
        initiateClaimError: null,
        initiateClaimLoader: true,
        initiateClaimSuccess: false,
        initiateClaim: null
      };

    case types.INITIATE_CLAIM_SUCCESS:
      return {
        ...state,
        initiateClaim: action.payload,
        initiateClaimSuccess: true,
        initiateClaimLoader: false,
        initiateClaimError: null,
      };

    case types.INITIATE_CLAIM_FAILURE:
      return { ...state, initiateClaimLoader: false, initiateClaimError: action.payload };

    case types.RESET_INITIATE_CLAIM_SUCCESS:
      return { ...state, initiateClaimSuccess: false };

    case types.RESET_INITIATE_CLAIM_ERROR:
      return { ...state, initiateClaimError: null };

    case types.GET_CUSTOMER_CLAIMS_DCP_REQUEST:
      return { ...state, get_customer_claims_dcp_loader: true, get_customer_claims_dcp_errors: null };
    case types.GET_CUSTOMER_CLAIMS_DCP_SUCCESS:
      return { ...state, get_customer_claims_dcp_loader: false, get_customer_claims_dcp_errors: null, customer_claims_dcp: action.payload };
    case types.GET_CUSTOMER_CLAIMS_DCP_FAILURE:
      return { ...state, get_customer_claims_dcp_loader: false, get_customer_claims_dcp_errors: action.payload };

      case types.GET_CLAIMS_DCP_REQUEST:
        return { ...state, get_claims_dcp_loader: true, get_claims_dcp_errors: null };
      case types.GET_CLAIMS_DCP_SUCCESS:
        return { ...state, get_claims_dcp_loader: false, get_claims_dcp_errors: null, claims_dcp: action.payload };
      case types.GET_CLAIMS_DCP_FAILURE:
        return { ...state, get_claims_dcp_loader: false, get_claims_dcp_errors: action.payload };

    default:
      return state;
  }
}
