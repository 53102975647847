import * as types from '../../../lib/actionTypes.es6';



export function generateReportRequest(type) {
  return {
    type: types.GENERATE_REPORT_REQUEST,
    payload: type,
  };
}

export function generateReportSuccess(response) {
  return {
    type: types.GENERATE_REPORT_SUCCESS,
    payload: response,
  };
}

export function generateReportFailed(error) {
  return {
    type: types.GENERATE_REPORT_FAILURE,
    payload: error,
  };
}

export function resetGenerateReport(error) {
  return {
    type: types.RESET_GENERATE_REPORT,
    payload: error,
  };
}

