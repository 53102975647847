import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../lib/constants";
import AlertDialog from "../AlertDialog/index.es6";
import { CircularProgress } from "@mui/material";


const initialState = {
  account_name: "",
  account_number: "",
  account_type: "",
  branch_code: "",
  bank_name: "",
}
export default function AddBankDetails(props) {
  const [
    {
      account_name,
      bank_name,
      account_number,
      account_type,
      branch_code,
    }, setState
  ] = React.useState(initialState)
  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const reset = () => {

  }

  const submit = () => {
    const payload = {
      account_name,
      bank_name,
      account_number,
      account_type,
      branch_code,
      customer_guid: props.customerGuid,
      beneficiary_guid: props.beneficiaryGuid,
    }
    props.addBankDetails(payload)
  }

  const handleCustomerBankDetailsCreatedSuccessfully = () => {
    props.resetAddBankDetailsSuccessAlert()
    props.closeAddBeneficiaryBankDetailsDialog()
  }

  const handleCustomerBankDetailsCreateError = () => {
    props.resetAddBankDetailsErrorAlert()
  }


  return (
    <React.Fragment>
      <Dialog
        open={props.openAddBankDetailsDialog}
        fullWidth
        maxWidth='lg'
        onClose={props.closeAddBeneficiaryBankDetailsDialog}
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.closeAddBeneficiaryBankDetailsDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Customer Bank Details
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <ValidatorForm onSubmit={() => submit()} >
            <AlertDialog
              custom
              show={!!props.showAddBankDetailsProgressAlert}
              size="sm"
              style={{ marginTop: '0', top: '30vh' }}
              confirmBtnText={'ok'}
              showCancel={false}
              showConfirm={false}
              cancelBtnText={'cancel'}
              showTitle={false}
              confirmBtnCssClass
            >
              <CircularProgress />
              <h2>Adding beneficiaary bank details </h2>
            </AlertDialog>

            <AlertDialog
              success
              show={!!props.showAddBankDetailsSuccessAlert}
              size="sm"
              title={'customer bank details added'}
              style={{ marginTop: '0', top: '30vh' }}
              onConfirm={() => { handleCustomerBankDetailsCreatedSuccessfully() }}
              confirmBtnText={'ok'}
              showCancel={false}
              showConfirm={true}
              cancelBtnText={'cancel'}
              confirmBtnCssClass
            >
            </AlertDialog>

            <AlertDialog
              show={!!props.showAddBankDetailsErrorAlert}
              danger
              title={'Error adding customer bank details.'}
              onConfirm={() => handleCustomerBankDetailsCreateError()}
              confirmBtnText={'ok'}
              confirmBtnCssClass
              showCancel={false}
              style={{ marginTop: '0', top: '30vh' }}
            >
              {props.error ? props.error.message : ''}
            </AlertDialog>

            <Grid container spacing={2} >

              <Grid item lg={3} md={3} xs={12}>
                <TextValidator
                  onChange={onChange}
                  value={account_name}
                  label='Account Name'
                  name='account_name'
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder='Account Name'
                  validators={['required']}
                  errorMessages={['Please enter account name']}
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12}>
                <TextValidator
                  onChange={onChange}
                  value={bank_name}
                  label='Bank Name'
                  name='bank_name'
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder='Bank Name'
                  validators={['required']}
                  errorMessages={['Please enter bank name']}
                />
              </Grid>


              <Grid item lg={3} md={3} xs={12}>
                <TextValidator
                  onChange={onChange}
                  value={account_number}
                  label='Account Number'
                  name='account_number'
                  fullWidth
                  variant="outlined"
                  type="number"
                  placeholder='Account Number'
                  validators={['required']}
                  errorMessages={['Please enter account number']}
                />
              </Grid>


              <Grid item lg={3} md={6} xs={12}>
                <TextValidator
                  onChange={onChange}
                  value={account_type}
                  label='Account Type'
                  name='account_type'
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder='Account Type'
                  validators={['required']}
                  errorMessages={['Please enter account type']}
                />
              </Grid>


              <Grid item lg={3} md={6} xs={12}>
                <TextValidator
                  onChange={onChange}
                  value={branch_code}
                  label='Branch Code'
                  name='branch_code'
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder='Account Type'
                  validators={['required']}
                  errorMessages={['Please enter account type']}
                />
              </Grid>

              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button variant="contained" type="submit" style={{ marginRight: '2%' }} > Save </Button>
                <Button variant="contained" color="secondary" style={{ marginRight: '2%' }} onClick={() => reset()}> Reset</Button>
              </Grid>

            </Grid>
          </ValidatorForm>
        </DialogContent>
      </Dialog>

    </React.Fragment>
  )
}