import { defaultMenu } from "./PartnerMenus/defaultMenu";
import { konyanaMenu } from "./PartnerMenus/konyana";
import { ktdaMenu } from "./PartnerMenus/ktda";
import { PARTNERS_GUID_ENUM } from "./PartnersEnum";
import { AccessBankMenu } from "./PartnerMenus/AccessBank";
import { BboxxKeBankMenu } from "./PartnerMenus/BboxxKe";
import { PremierCreditZambiaMenu } from './PartnerMenus/PremierCreditZambia'
import { AirtelZambiaMenu } from './PartnerMenus/AirtelZambia'
import { AspinPlusMenu } from "./PartnerMenus/AspinPlus";
import { airtelMalaiMenu } from "./PartnerMenus/AirtelMalawi";
import { NGSGroupMenu } from "./PartnerMenus/NGSGroup";
import { RealPeople } from './PartnerMenus/RealPeople'
export const MenuSwitch = (labels, partnerGuid) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.KTDA:
      return ktdaMenu(labels);

    case PARTNERS_GUID_ENUM.ATLEHANG:
    case PARTNERS_GUID_ENUM.MANANKIFIN:
    case PARTNERS_GUID_ENUM.KONYANA:
    case PARTNERS_GUID_ENUM.FAITH_FUNERALS:
      return konyanaMenu(labels);

    case PARTNERS_GUID_ENUM.ACCESS_BANK:
    case PARTNERS_GUID_ENUM.INSOLCOMPLEX:
      return AccessBankMenu(labels);
      
    case PARTNERS_GUID_ENUM.AIRTEL_MALAWI:
      return airtelMalaiMenu(labels);


    case PARTNERS_GUID_ENUM.ASPIN_PLUS:
      return AspinPlusMenu(labels);
    case PARTNERS_GUID_ENUM.BBOX_KENYA:
    case PARTNERS_GUID_ENUM.BBOX_RWANDA:
      return BboxxKeBankMenu(labels);

    case PARTNERS_GUID_ENUM.PREMIER_CREDIT_ZAMBIA:
      return PremierCreditZambiaMenu(labels);

    case PARTNERS_GUID_ENUM.AIRTEL_ZAMBIA:
      return AirtelZambiaMenu(labels);

      case PARTNERS_GUID_ENUM.NGS_UGANDA:
      return NGSGroupMenu(labels)

      case PARTNERS_GUID_ENUM.REAL_PEOPLE:
      return RealPeople(labels)


    default:
      return defaultMenu(labels);
  }
}
