import React from 'react'
import { hashHistory} from 'react-router';
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import TableInc from '../../../../shared-ui/TableInc';
import ButtonInc from '../../../../shared-ui/ButtonInc';
import SkeletonWrapper from '../../../NewProductList/components/SkeletonWrapper/SkeletonWrapper';


const Claims = (props) => {
  const classes = useStyle();
  const customerClaims = props.claimsData || [];

  if(props.loading){
    return <SkeletonWrapper loading={props.loading} />
  }

  return (
    <>
      <section className={classes.claimTable}>
        <Grid container spacing={2} >
          <Grid item xs={12} md={12} sm={12}>
          <TableInc
                columns={[
                  { title: 'Claim Number', field: 'claim_number' },
                  { title: 'Created At', field: 'created_at', render: rowData => (rowData.created_at)},
                  { title: 'Status', field: 'status' },
                  { title: 'System Message', field: 'system_message',render: rowData => <>
                  {
                    rowData.system_message || "N/A"
                  }
                </> },
                  { title: 'Claim Type', field: 'type',render: rowData => <>
                  {
                    rowData.type || "N/A"
                  }
                </> },
                  { title: 'Initiator Full Name', field: 'initiator_fullname' },
                  { title: 'Initiator Phone Number', field: 'initiator_msisdn' },
                  {
                    title: 'action', field: 'claimaction', render: rowData => {
                      return <div className={classes.claimactionbt}>
                            <ButtonInc 
                              onClick={()=> hashHistory.push(`admin/claim-processing-v2/${rowData.guid}`)}
                            
                            variant="contained">View Claim</ButtonInc>
                      </div>
                    }
                  },
                ]}
                data={
                  customerClaims
                }
                options={{
                  pageSize: customerClaims.length < 5 
                  ? customerClaims.length 
                  : 5,
                  showTitle: false,
                }}
            />
    
          </Grid>
        </Grid>
      </section>

    </>
  )
}

export default Claims