export const extended_members_options = [
  {
    "min_age": 0,
    "max_age": 15,
    "options": [
      {
        "benefit_amount": 3000,
        "premium_amount": 5.3
      },
      {
        "benefit_amount": 4000,
        "premium_amount": 6.75
      },
      {
        "benefit_amount": 5000,
        "premium_amount": 8.15
      },
      {
        "benefit_amount": 7500,
        "premium_amount": 12.4
      },
      {
        "benefit_amount": 10000,
        "premium_amount": 17.67
      },
      {
        "benefit_amount": 15000,
        "premium_amount": 26.5
      },
      {
        "benefit_amount": 20000,
        "premium_amount": 35.33
      },
      {
        "benefit_amount": 30000,
        "premium_amount": 53.0
      }
    ]
  },
  {
    "min_age": 16,
    "max_age": 25,
    "options": [
      {
        "benefit_amount": 3000,
        "premium_amount": 8.2
      },
      {
        "benefit_amount": 4000,
        "premium_amount": 10.6
      },
      {
        "benefit_amount": 5000,
        "premium_amount": 12.85
      },
      {
        "benefit_amount": 7500,
        "premium_amount": 19.6
      },
      {
        "benefit_amount": 10000,
        "premium_amount": 27.33
      },
      {
        "benefit_amount": 15000,
        "premium_amount": 41.0
      },
      {
        "benefit_amount": 20000,
        "premium_amount": 54.67
      },
      {
        "benefit_amount": 30000,
        "premium_amount": 82.0
      }
    ]
  },
  {
    "min_age": 26,
    "max_age": 35,
    "options": [
      {
        "benefit_amount": 3000,
        "premium_amount": 11.7
      },
      {
        "benefit_amount": 4000,
        "premium_amount": 15.1
      },
      {
        "benefit_amount": 5000,
        "premium_amount": 18.3
      },
      {
        "benefit_amount": 7500,
        "premium_amount": 27.75
      },
      {
        "benefit_amount": 10000,
        "premium_amount": 39.0
      },
      {
        "benefit_amount": 15000,
        "premium_amount": 58.5
      },
      {
        "benefit_amount": 20000,
        "premium_amount": 78.0
      },
      {
        "benefit_amount": 30000,
        "premium_amount": 117.0
      }
    ]
  },
  {
    "min_age": 36,
    "max_age": 45,
    "options": [
      {
        "benefit_amount": 3000,
        "premium_amount": 13.4
      },
      {
        "benefit_amount": 4000,
        "premium_amount": 17.3
      },
      {
        "benefit_amount": 5000,
        "premium_amount": 20.95
      },
      {
        "benefit_amount": 7500,
        "premium_amount": 31.65
      },
      {
        "benefit_amount": 10000,
        "premium_amount": 44.67
      },
      {
        "benefit_amount": 15000,
        "premium_amount": 67
      },
      {
        "benefit_amount": 20000,
        "premium_amount": 89.33
      },
      {
        "benefit_amount": 30000,
        "premium_amount": 134
      }
    ]
  },
  {
    "min_age": 46,
    "max_age": 55,
    "options": [
      {
        "benefit_amount": 3000,
        "premium_amount": 20.90
      },
      {
        "benefit_amount": 4000,
        "premium_amount": 27.00
      },
      {
        "benefit_amount": 5000,
        "premium_amount": 32.70
      },
      {
        "benefit_amount": 7500,
        "premium_amount": 49.60
      },
      {
        "benefit_amount": 10000,
        "premium_amount": 69.67
      },
      {
        "benefit_amount": 15000,
        "premium_amount": 104.50
      },
      {
        "benefit_amount": 20000,
        "premium_amount": 139.33
      },
      {
        "benefit_amount": 30000,
        "premium_amount": 209.00
      }
    ]
  },

  {
    "min_age": 56,
    "max_age": 65,
    "options": [
      {
        "benefit_amount": 3000,
        "premium_amount": 31.35
      },
      {
        "benefit_amount": 4000,
        "premium_amount": 40.45
      },
      {
        "benefit_amount": 5000,
        "premium_amount": 49.00
      },
      {
        "benefit_amount": 7500,
        "premium_amount": 74.35
      },
      {
        "benefit_amount": 10000,
        "premium_amount": 104.50
      },
      {
        "benefit_amount": 15000,
        "premium_amount": 156.75
      },
      {
        "benefit_amount": 20000,
        "premium_amount": 209.00
      },
      {
        "benefit_amount": 30000,
        "premium_amount": 313.50
      }
    ]
  },
  {
    "min_age": 66,
    "max_age": 75,
    "options": [
      {
        "benefit_amount": 3000,
        "premium_amount": 44.40
      },
      {
        "benefit_amount": 4000,
        "premium_amount": 57.35
      },
      {
        "benefit_amount": 5000,
        "premium_amount": 69.45
      },
      {
        "benefit_amount": 7500,
        "premium_amount": 105.35
      },
      {
        "benefit_amount": 10000,
        "premium_amount": 148.00
      },
      {
        "benefit_amount": 15000,
        "premium_amount": 222.00
      },
      {
        "benefit_amount": 20000,
        "premium_amount": 296.00
      },
      {
        "benefit_amount": 30000,
        "premium_amount": 444.00
      }
    ]
  },
  {
    "min_age": 76,
    "max_age": 85,
    "options": [
      {
        "benefit_amount": 3000,
        "premium_amount": 74.50
      },
      {
        "benefit_amount": 4000,
        "premium_amount": 96.20
      },
      {
        "benefit_amount": 5000,
        "premium_amount": 116.50
      },
      {
        "benefit_amount": 7500,
        "premium_amount": 176.75
      },
      {
        "benefit_amount": 10000,
        "premium_amount": 248.33
      },
      {
        "benefit_amount": 15000,
        "premium_amount": 372.50
      },
      {
        "benefit_amount": 20000,
        "premium_amount": 496.67
      },
      {
        "benefit_amount": 30000,
        "premium_amount": 745.00
      }
    ]
  }
  
  
  
  
];
