import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../lib/actionTypes.es6';
import {
  generateReport,
} from '../../../lib/requests.es6';
import * as actions from './actions';
import { unAuthorizedNotification } from '../../../lib/access.es6';
import { GAloggerException } from '../../../lib/monitoring.es6';



export function* generateReportRequestWatcher() {
  while (true) {
    try {
      const  reportAction  = yield take(types.GENERATE_REPORT_REQUEST);
     
      const response = yield call( generateReport, reportAction.payload);
      yield put(actions.generateReportSuccess(response.data));
    } catch (e) {
      let errorPayload = {
        message: 'An error occurred, this could be due to invalid data',
      };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.generateReportFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

