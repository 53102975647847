import React from "react";
import { connect } from "react-redux";
import DisplayClaims from "./Components/DisplayClaims/DisplayClaims";
import { getAllOrangeCustomerClaimsRequest } from "./Redux/actions";

class OrangeClaims extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentWillMount() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      this.props.dispatch(getAllOrangeCustomerClaimsRequest(currentCustomer.msisdn))
    }

  }

  render() {
    return (
      <>
        <DisplayClaims
          labels={this.props.labels}
          loader={this.props.orangeClaimsData.get_claims_loader}
          claims={this.props.orangeClaimsData.claims}
        />

      </>
    )
  }
}

export default connect((state) => ({
  customerData: state.currentCustomer,
  orangeClaimsData: state.orangeClaimsData
}))(OrangeClaims);