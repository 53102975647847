import React, { forwardRef } from "react";
import { Paper } from "@mui/material";
import BoxInc from "../../../shared-ui/BoxInc/Index";
import Audits from "../../Audits";

const Audit = forwardRef(({ claim }, ref) => {
  return (
    <BoxInc
      title="Audit Trail"
      expanded
      style={{ marginTop: "40px" }}
      actionButtons={[]}
    >
      <div id="audit" component-id="audit" >
        <Paper elevation={0} sx={{ p: 3, mb: 4 }}>
           <Audits entity_guid={claim.guid} size={5} />
        </Paper>
      </div>
    </BoxInc>
  );
});

export default Audit;
