import React from "react";
import { hashHistory } from 'react-router';
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import BoxInc from "../../../shared-ui/BoxInc/Index";
import ButtonInc from "../../../shared-ui/ButtonInc";
import Batches from "./Components/OnboardingData/Batches";
import Upload from "./Components/Upload/Upload";
import * as actions from "./Redux/actions"
import { hasAuthourity } from "../../../lib/access.es6";


class BulkPolicyOnboarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openUploadDialog: false,
    };
  }
  componentWillMount() {
    this.getBatches()
  }

  handlePageChange = (page, pageSize) => {
    this.getBatches(page, pageSize)
  };

  getBatches(page, page_size){
    this.props.dispatch(actions.getBulkPolicyOnboardingBatchesRequest({
      page: page || 1,
      page_size: page_size || 10
    }));
  }

  toggleUploadDialog() {
    this.setState({ openUploadDialog: !this.state.openUploadDialog })
  }

  viewFailedEnteries(guid) {
    hashHistory.push(`admin/bulk-policy-onboarding/failed-enteries/${guid}`)
  }

  render() {

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <BoxInc
              expanded
              title="Bulk Policy Onboarding"
              hasPermision={hasAuthourity('BULK_ONBOARD_CUSTOMERS')}
              actionButtons={[
              <ButtonInc
                variant="contained"
                color="primary"
                size="large"
                hasPermision={hasAuthourity('BULK_ONBOARD_CUSTOMERS')}
                onClick={(event) => {
                  event.stopPropagation()
                  this.getBatches()
                }}
              >
                refresh
              </ButtonInc>, 
              <ButtonInc
                variant="contained"
                color="primary"
                size="large"
                hasPermision={hasAuthourity('BULK_ONBOARD_CUSTOMERS')}
                onClick={(event) => {
                  event.stopPropagation()
                  this.toggleUploadDialog()
                }}
              >
                Upload Policy Schedule
              </ButtonInc>

              ]}

            >
              <Batches
                batches={this.props.bulkPolicyOnboardingData.BulkPoliciesBatches}
                viewFailedEnteries={this.viewFailedEnteries.bind(this)}
                loading={this.props.bulkPolicyOnboardingData.getBulkPoliciesLoader}
                onPageChange={this.handlePageChange}
              />
              <Upload
                close={this.toggleUploadDialog.bind(this)}
                open={this.state.openUploadDialog}
                saveBulkPolicyOnboarding={(doc) => this.props.dispatch(actions.saveBulkPolicyOnboadingRequest(doc))}
                errror = {this.props.bulkPolicyOnboardingData.saveBulkPolicyOnboardingError}

              />
            </BoxInc>
          </Grid>
        </Grid>
      </>
    )
  }

}
export default connect((state) => ({
  customerData: state.currentCustomer,
  bulkPolicyOnboardingData: state.bulkPolicyOnboarding,
  globalData: state.global,
}))(BulkPolicyOnboarding);