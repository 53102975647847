import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// core version + navigation, pagination modules:
import { Pagination } from 'swiper';
// import Swiper and modules styles

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import { TestimonialsData } from './TestimonialsData';

const TestimonialMobile = () => {
  const classes = useStyle();
  return (
    <>
      <Swiper
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className={classes.mySwipertestimonial}
      >
        {
          TestimonialsData.map((testimonial, index) =>
            <SwiperSlide key={index}>
              <Grid item xs={12} sm={12} md={12} className={classes.testimonialouter}>
                <div className={classes.testimonialwrapper}>
                  <div className={classes.testimonialavatar}>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 15C16.6576 15 18.2473 14.3415 19.4194 13.1694C20.5915 11.9973 21.25 10.4076 21.25 8.75C21.25 7.0924 20.5915 5.50269 19.4194 4.33058C18.2473 3.15848 16.6576 2.5 15 2.5C13.3424 2.5 11.7527 3.15848 10.5806 4.33058C9.40849 5.50269 8.75001 7.0924 8.75001 8.75C8.75001 10.4076 9.40849 11.9973 10.5806 13.1694C11.7527 14.3415 13.3424 15 15 15ZM15 18.125C8.73751 18.125 3.63751 22.325 3.63751 27.5C3.63751 27.85 3.91251 28.125 4.26251 28.125H25.7375C26.0875 28.125 26.3625 27.85 26.3625 27.5C26.3625 22.325 21.2625 18.125 15 18.125Z" fill="white" />
                    </svg>

                  </div>
                  <h4 className={classes.clienttestimonialtitle}>{testimonial.title}</h4>
                  <p>{testimonial.body}</p>
                </div>

              </Grid>
            </SwiperSlide>
          )
        }

      </Swiper>
    </>
  )
}

export default TestimonialMobile