import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import BoxInc from "../../../shared-ui/BoxInc/Index";
import FailedEnteriesTable from "./Components/FailedEnteries/FailedEnteriesTable";
import { getFailedBatchesRequest } from "../BulkPolicyOnboarding/Redux/actions";


class FailedEnteries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openUploadDialog: false,
    };
  }
  componentWillMount() {
    let guid = this.props.params.guid

    this.props.dispatch(getFailedBatchesRequest({
      batch_guid : guid
    }));

  }

  handlePageChange = (page, pageSize) => {
    this.getBatches(page, pageSize)
  };

  getBatches(page, page_size){
    let guid = this.props.params.guid
    this.props.dispatch(getFailedBatchesRequest({
      batch_guid : guid,
      page: page || 1,
      page_size: page_size || 10
    }));

  }
  
  toggleUploadDialog() {
    this.setState({ openUploadDialog: !this.state.openUploadDialog })
  }
  render() {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <BoxInc
              expanded
              title="Failed Enteries Batch Number: " 
              actionButtons={[]}

            >
              <FailedEnteriesTable
                failedEnteries={this.props.bulkPolicyOnboardingData.failedPoliciesBatches}
                loading={this.props.bulkPolicyOnboardingData.failedPoliciesBatchesLoader}
                onPageChange={this.handlePageChange}
              />
            </BoxInc>
          </Grid>
        </Grid>
      </>
    )
  }

}
export default connect((state) => ({
  bulkPolicyOnboardingData: state.bulkPolicyOnboarding,
  globalData: state.global,
}))(FailedEnteries);