import * as types from '../../lib/actionTypes.es6';
import GAlogger, { monitoringEventCategories } from '../../lib/monitoring.es6';

export function addRemittancesRequest(data) {
  return {
    type: types.ADD_REMITTANCES_REQUEST,
    payload: data,
  };
}

export function addRemittancesSuccess(data) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.addRemittance);
  return {
    type: types.ADD_REMITTANCES_SUCCESS,
    payload: data,
  };
}

export function addRemittancesFail(response) {
  return {
    type: types.ADD_REMITTANCES_FAIL,
    payload: response,
  };
}

export function addRemittanceReset() {
  return {
    type: types.ADD_REMITTANCES_RESET,
  };
}
