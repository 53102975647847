import React, { useState } from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import { CircularProgress } from '@mui/material';

const SelectPolicy = (props) => {
  if (!props.policies) {
    return
  }
  const classes = useStyle();
  const [policies, setPolicies] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [policy, setPolicy] = useState('');
  const [customerguid, setCustomerguid] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === "policies") {
      setPolicies(value)
      setPolicy(value)
      setCustomerguid(value.customer_guid)
    }
    if (name === "eventDate") {
      setEventDate(value)
    }
  }


  const handlePolicies = () => {
    const payload = {
      "customer_guid": customerguid,
      "policy_guid": policy.guid,
      "event_date": eventDate,
    }
    console.log("select policy ", payload)
    props.handleSelectPolicies(payload);
    const tempClaimInitiation = props.claimInitiationPayload;
    tempClaimInitiation["event_date"] = eventDate;
    tempClaimInitiation["policy_number"] = policy.policy_number;
    props.setClaimInitiationPayload(tempClaimInitiation);
    // handleReset();
  }
  const filteredPolicies = props.policies.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).filter(policy => policy.active === true)

  return (
    <>
      <ValidatorForm onSubmit={() => handlePolicies()}>
        <Grid container spacing={2} className={classes.selectpolicyWrapper}>
          <Grid item xs={12} md={6} sm={6}>
            <SelectValidator
              labelId="select-policy"
              id="select-policy"
              value={policies}
              name='policies'
              type="text"
              label="Select Policy"
              onChange={handleChange}
              variant="outlined"
              validators={['required']}
              errorMessages={['Please select a policy']}
              style={{ width: "100%" }}
              sx={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.005em",
                color: "#000000",
              }}
            >

              {
                filteredPolicies.map((policy, index) => {
                  return (
                    <MenuItem
                      key={index}
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }} value={policy}>{policy.policy_number}</MenuItem>
                  )

                })
              }


            </SelectValidator>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <TextValidator
              id="event-date"
              label="Select Event Date"
              name='eventDate'
              value={eventDate}
              onChange={handleChange}
              style={{ width: "100%" }}
              type='date'
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              validators={['required',`maxNumber:${new Date().toISOString().slice(0, 10)}`]}
              errorMessages={['Please enter an event date','Select a past date  ']}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sm={12} className={classes.claimBtn}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type='submit'
            >
              {props.eventQuoteLoader ? <CircularProgress /> :'Verify event date' }
            </Button>
            {props.eventQuoteSuccess && <p style={{color: 'green'}} >Event found</p>}
            {props.eventQuoteError && <p style={{color: 'red'}} >Event NOT found</p>}
          </Grid>
        </Grid>
      </ValidatorForm>
    </>
  )
}

export default SelectPolicy