import * as types from '../../lib/actionTypes.es6';

export function reportVasAgentRequest(reportType) {
  return {
    type: types.REPORT_VAS_AGENT_REQUEST,
    payload: reportType,
  };
}

export function reportVasAgentSuccess(reportURL) {
  return {
    type: types.REPORT_VAS_AGENT_SUCCESS,
    payload: reportURL,
  };
}

export function reportVasAgentError(error) {
  return {
    type: types.REPORT_VAS_AGENT_FAILURE,
    payload: error,
  };
}

export function reportVasAgentReset() {
  return {
    type: types.REPORT_VAS_AGENT_RESET,
  };
}
