import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
// import './BankDetailsTable.css'
import '../Beneficiary/BeneficiaryTable.css'
import { PRIMARY_THEME_COLOR_SHADE_1, PRIMARY_THEME_COLOR_SHADE_2 } from "../../lib/constants";
import SkeletonWrapper from "../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper";

export default function DisplayCustomerBeneficiaryDetails(props) {
  if (props.loading) {
    return <SkeletonWrapper loading={props.loading} />
  } else if (props.beneficiary) {
    return (
      <>
        <div style={{ marginTop: "0%" }}>
          {props.title && <div className="page-title">
            <div className="title_left" style={{ color: PRIMARY_THEME_COLOR_SHADE_2 }}>
              <h4>{props.title}</h4>
            </div>
          </div>}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ color: 'red' }}>
                <TableRow >
                  <TableCell className="table-cell-header-dep">Created at	</TableCell>
                  <TableCell className="table-cell-header-dep">First Name	</TableCell>
                  <TableCell className="table-cell-header-dep">Last Name	</TableCell>
                  <TableCell className="table-cell-header-dep">date Of Birth</TableCell>
                  <TableCell className="table-cell-header-dep">Gender </TableCell>
                  <TableCell className="table-cell-header-dep"> Phone Number </TableCell>
                  <TableCell className="table-cell-header-dep">National ID </TableCell>
                  <TableCell className="table-cell-header-dep">Actions  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[props.beneficiary].map((detail) => {
                  return (
                    <TableRow
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{detail.created_at ? detail.created_at.substring(0,10) : detail.created_at}</TableCell>
                      <TableCell>{detail.first_name}</TableCell>
                      <TableCell>{detail.last_name}</TableCell>
                      <TableCell>{detail.date_of_birth}</TableCell>
                      <TableCell >{detail.gender}</TableCell>
                      <TableCell >{detail.msisdn}</TableCell>
                      <TableCell >{detail.national_id}</TableCell>
                      {/* <TableCell>{detail.bank_name}</TableCell> */}
                      <TableCell >
                        <Stack direction="row" spacing={1} justifyContent="flex-end" >
                          <IconButton onClick={() => { props.setBeneficiaryDetailsToEdit(detail) }} >
                            <EditIcon
                              style={{ color: PRIMARY_THEME_COLOR_SHADE_1, fontWeight: 'bolder', display: props.displayEditButton ? 'inline-block' : 'none' }}
                            />
                          </IconButton>
                          <IconButton onClick={() => { props.setBeneficiaryDetailsToDelete(detail) }} >
                            <DeleteForeverIcon
                              style={{ color: 'red', fontWeight: 'bolder', marginRight: "2%", display: props.displayDeleteButton ? 'inline-block' : 'none' }}
                            />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
    );
  }
  return "No bank details found, click on the add button to add new bank details."
}
