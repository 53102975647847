import React from "react";
import { connect } from "react-redux";
import HomePageCta from './HomePageCta'
import { hashHistory } from 'react-router';
import LocalizedStrings from 'react-localization';
import { localisedText } from "../../../lib/localisation.es6";
import RegisterCustomer from "../CustomerStatus/Containers/RegisterCustomer/Components/RegisterCustomer";
import { registerAcessBankCustomerRequest, resetAccessBankRegistration } from "../CustomerStatus/Containers/RegisterCustomer/redux/actions";
import { reloadPage } from "../../../lib/utils.es6";
import { getUsersRequest } from "../../../containers/Users/actions.es6";
import { getPartnerGuid } from "../../../lib/access.es6";
import RegisterCustomerAspinPlus from "../CustomerStatus/Containers/RegisterCustomer/Components/RegisterCustomerAspinPlus";

class AccessBankHomePage extends React.Component {
  constructor(props) {
    const localizedStrings = new LocalizedStrings(localisedText);
    super(props);
    this.state = {
      openRegisterCustomerDialog: false,
      labels: localizedStrings,
    }
  }

  componentWillMount() {
    this.props.dispatch(getUsersRequest());

  }


  toggleRegisterCustomerDialog() {
    this.setState({ openRegisterCustomerDialog: !this.state.openRegisterCustomerDialog })
  }
  registerCustomer(customerData) {
    this.props.dispatch(registerAcessBankCustomerRequest(customerData));
  }
  resetRegistrationSuccess() {
    this.props.dispatch(resetAccessBankRegistration());
		hashHistory.push('admin/customer_status/' + this.props.customerData.currentCustomer.guid);
		this.toggleRegisterCustomerDialog()
		reloadPage();
  }
  resetRegistrationError() {
		this.props.dispatch(resetAccessBankRegistration())

  }

  render() {

    let agentUsersData = this.props.userData.users.filter(user => user.attributes.isAgent && user.attributes.isAgent[0] === 'true') || [];
    const partnerGuid = getPartnerGuid();
    
    return (
      <>
        <HomePageCta
          open={this.toggleRegisterCustomerDialog.bind(this)}
          labels={this.state.labels}
        />
        {partnerGuid === "accessbank" ? (
          <RegisterCustomer
            openRegisterCustomerDialog={this.state.openRegisterCustomerDialog}
            toggleRegisterCustomerDialog={this.toggleRegisterCustomerDialog.bind(
              this
            )}
            registerCustomer={this.registerCustomer.bind(this)}
            registrationData={this.props.AccessBankRegistrationData}
            resetRegistrationSuccess={this.resetRegistrationSuccess.bind(this)}
            resetRegistrationError={this.resetRegistrationError.bind(this)}
            labels={this.state.labels}
            agentUsersData={agentUsersData}
            error={this.props.AccessBankRegistrationData.errors}
            showRegisterCustomerErrorAlert={
              !!this.props.AccessBankRegistrationData.errors
            }
          />
        ) : (
          <RegisterCustomerAspinPlus
            openRegisterCustomerDialog={this.state.openRegisterCustomerDialog}
            toggleRegisterCustomerDialog={this.toggleRegisterCustomerDialog.bind(
              this
            )}
            registerCustomer={this.registerCustomer.bind(this)}
            registrationData={this.props.AccessBankRegistrationData}
            resetRegistrationSuccess={this.resetRegistrationSuccess.bind(this)}
            resetRegistrationError={this.resetRegistrationError.bind(this)}
            labels={this.state.labels}
            agentUsersData={agentUsersData}
            error={this.props.AccessBankRegistrationData.errors}
            showRegisterCustomerErrorAlert={
              !!this.props.AccessBankRegistrationData.errors
            }
          />
        )}
      </>
    );


  }
}

export default connect((state) => ({
  customerData: state.currentCustomer,
  AccessBankRegistrationData: state.AccessBankRegistrationData,
  registrationData: state.customerRegistration,
  userData: state.userAdmin,
}))(AccessBankHomePage);