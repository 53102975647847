import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../lib/actionTypes.es6';
import {
  addEmailTemplate,
  getEmailTemplates,
} from '../../../lib/requests.es6';
import * as actions from './actions';
import { unAuthorizedNotification } from '../../../lib/access.es6';
import { GAloggerException } from '../../../lib/monitoring.es6';


// template docs

export function* getEmailTemplatesRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.GET_EMAIL_TEMPLATES_REQUEST);
      const response = yield call(getEmailTemplates, payload);
      yield put(actions.getEmailTemplatesSuccess(response.data));
    } catch (e) {
      let errorPayload = {
        message: 'An error occurred, this could be due to invalid data',
      };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.getEmailTemplatesFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

export function* addEmailTemplateRequestWatcher() {
  while (true) {
    try {
      const { payload } = yield take(types.ADD_EMAIL_TEMPLATE_REQUEST);

      const response = yield call(addEmailTemplate, payload);
      yield put(actions.addEmailTemplateSuccess(response.data));
    } catch (e) {
      let errorPayload = {
        message: 'An error occurred, this could be due to invalid data',
      };
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      yield put(actions.addEmailTemplateFailed(errorPayload));
      GAloggerException(e);
    }
  }
}

// export function* editTemplateDocumentRequestWatcher() {
//   while (true) {
//     try {
//       const { payload } = yield take(types.EDIT_TEMPLATE_DOCUMENT_REQUEST);

//       const response = yield call(editTemplateDocument, payload);
//       yield put(actions.editTemplateDocumentSuccess(response.data));
//       yield put(actions.getTemplateDocumentsRequest());
//     } catch (e) {
//       let errorPayload = {
//         message: 'An error occurred, this could be due to invalid data',
//       };
//       if (e.response) {
//         errorPayload = e.response.data;
//         unAuthorizedNotification(e);
//       }
//       yield put(actions.editTemplateDocumentFailed(errorPayload));
//       GAloggerException(e);
//     }
//   }
// }

// export function* deleteTemplateDocumentRequestWatcher() {
//   while (true) {
//     try {
//       const { payload } = yield take(types.DELETE_TEMPLATE_DOCUMENT_REQUEST);

//       const response = yield call(deleteTemplateDocument, payload);
//       yield put(actions.deleteTemplateDocumentSuccess(response.data));
//       yield put(actions.getTemplateDocumentsRequest());
//     } catch (e) {
//       let errorPayload = {
//         message: 'An error occurred, this could be due to invalid data',
//       };
//       if (e.response) {
//         errorPayload = e.response.data;
//         unAuthorizedNotification(e);
//       }
//       yield put(actions.deleteTemplateDocumentFailed(errorPayload));
//       GAloggerException(e);
//     }
//   }
// }
