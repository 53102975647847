
import React from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";

import LocalizedStrings from "react-localization";
import { localisedText } from "../../../../lib/localisation.es6";
import { getPartner, getPartnerGuid } from "../../../../lib/access.es6";
import { updateCurrentLanguage } from "../../../../lib/languageUtils.es6";
// import Products from '../../components/Products/index.es6';
import { getProductRequest } from "../../actions.es6";
import ViewProduct from "./ViewProduct";
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper";
import EditProduct from "./Edit/EditProduct";
import * as actions from "../../actions.es6";
import EditPremium from "./Edit/EditPremium";
import DeletePremium from "./Delete/DeletePremium";
import EditBenefits from "./Edit/Benefit/EditBenefits";
import DeleteBenefit from "./Delete/DeleteBenefit";
import AddBenefits from "./Add/AddBenefits";
import AddPremium from "./Add/AddPremium";


export class ViewProductStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labels: new LocalizedStrings(localisedText),
      generalPartner: getPartnerGuid(),
      productGuid: '',
      openEditProductDialog: false,
      openAddBenefitDialog: false,
      openAddPremiumDialog: false,
    };
  }

  componentWillMount() {
    this.updateLanguage();

    const productGuid = this.props.params.productGuid
    this.setState({ productGuid })
    this.props.dispatch(getProductRequest(productGuid))
    this.props.dispatch(actions.getGroupProductConfigsRequest(productGuid))
    this.props.dispatch(actions.getExtendedFamilyProductConfigsRequest(productGuid))

    // resets 
    this.props.dispatch(actions.resetBenefitToDelete())
    this.props.dispatch(actions.resetPremiumToDelete())
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      "NewProductList",
      this.state.labels
    );
  }

  refreshProduct(productGuid) {
    console.log('refrshing product: ', productGuid );
    this.props.dispatch(getProductRequest(productGuid))
  }

  editProduct(product) {
    this.props.dispatch(actions.editProductRequest(product))
  }

  editPremium(premium) {
    this.props.dispatch(actions.editPremiumRequest(premium))
  }

  setPremiumToEdit(premium) {
    this.props.dispatch(actions.setPremiumToEdit(premium))
  }

  setPremiumToDelete(premium) {
    this.props.dispatch(actions.setPremiumToDelete(premium))
  }

  editBenefit(benefit) {
    this.props.dispatch(actions.editBenefitRequest(benefit))
  }

  setBenefitToEdit(benefit) {
    this.props.dispatch(actions.setBenefitToEdit(benefit))
  }

  setBenefitToDelete(benefit) {
    this.props.dispatch(actions.setBenefitToDelete(benefit))
  }

  resetPremiumToEdit(premium) {
    this.props.dispatch(actions.resetPremiumToEdit(premium))
  }

  resetPremiumToDelete(premium) {
    this.props.dispatch(actions.resetPremiumToDelete(premium))
  }

  resetBenefitToEdit(benefit) {
    this.props.dispatch(actions.resetBenefitToEdit(benefit))
  }

  resetBenefitToDelete(benefit) {
    this.props.dispatch(actions.resetBenefitToDelete(benefit))
  }

  deletePremium(premium) {
    this.props.dispatch(actions.hardDeletePremiumRequest(premium))
  }

  deleteBenefit(benefit) {
    this.props.dispatch(actions.hardDeleteBenefitRequest(benefit))
  }

  openAddBenefitDialog() {
    this.setState({ openAddBenefitDialog: true })
  }

  closeAddBenefitDialog() {
    this.setState({ openAddBenefitDialog: false })
  }

  addBenefit(benefit) {
    benefit['product_guid'] = this.state.productGuid
    this.props.dispatch(actions.createBenefitRequest(benefit))
  }


  openAddPremiumDialog() {
    this.setState({ openAddPremiumDialog: true })
  }

  closeAddPremiumDialog() {
    this.setState({ openAddPremiumDialog: false })
  }

  addPremium(premium) {
    this.props.dispatch(actions.createPremiumRequest(premium))
  }

  addPremiumConfig(premiumConfig) {
    this.props.dispatch(actions.createPremiumConfigRequest(premiumConfig))
  }


  // refreshProduct() {
  //   this.props.dispatch(getProductRequest(this.state.productGuid))
  // }

  updateGroupProductConfigs(groupProductConfig) {
    this.props.dispatch(actions.updateGroupProductConfigsRequest(groupProductConfig))
  }

  resetUpdateGroupProductConfigsSuccessAlert() {
    this.props.dispatch(actions.resetUpdateGroupProductConfigsSuccess())
    this.props.dispatch(actions.getGroupProductConfigsRequest(this.state.productGuid))
  }

  addGroupProductConfigs(groupProductConfig) {
    groupProductConfig.product_guid = this.state.productGuid
    this.props.dispatch(actions.addGroupProductConfigsRequest(groupProductConfig))
  }

  resetAddGroupProductConfigsSuccessAlert() {
    this.props.dispatch(actions.resetAddGroupProductConfigsSuccess())
    this.props.dispatch(actions.getGroupProductConfigsRequest(this.state.productGuid))
  }


  addExtendedFamilyProductConfigs(extendedFamilyProductConfig) {
    extendedFamilyProductConfig.product_guid = this.state.productGuid
    this.props.dispatch(actions.addExtendedFamilyProductConfigsRequest(extendedFamilyProductConfig))
  }

  resetAddExtendedFamilyProductConfigsSuccessAlert() {
    this.props.dispatch(actions.resetAddExtendedFamilyProductConfigsSuccess())
    this.props.dispatch(actions.getExtendedFamilyProductConfigsRequest(this.state.productGuid))
  }

  updateExtendedFamilyProductConfigs(groupProductConfig) {
    this.props.dispatch(actions.updateExtendedFamilyProductConfigsRequest(groupProductConfig))
  }

  resetUpdateExtendedFamilyProductConfigsSuccessAlert() {
    this.props.dispatch(actions.resetUpdateExtendedFamilyProductConfigsSuccess())
    this.props.dispatch(actions.getExtendedFamilyProductConfigsRequest(this.state.productGuid))
  }

  addaddonConfig(premiumConfig) {
    this.props.dispatch(actions.createaddOnsConfigRequest(premiumConfig))
  }

  render() {

    const currencyCode = getPartner().country.currency_code

    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg" style={{ marginTop: "2%" }}>
          <SkeletonWrapper loading={this.props.productsData.loader} />
          <ViewProduct
            refreshProduct={this.refreshProduct.bind(this)}
            product={this.props.productsData.currentProduct}
            // isLoadings={this.props.productsData.loader}
            productNotFoundError={this.props.productsData.errors}
            showAddProductDialog={this.state.showAddProductDialog}
            openAddProductDialog={() => { this.setState({ showAddProductDialog: true }) }}
            openEditProductDialog={() => { this.setState({ openEditProductDialog: true }) }}

            setBenefitToDelete={this.setBenefitToDelete.bind(this)}
            setBenefitToEdit={this.setBenefitToEdit.bind(this)}
            setPremiumToDelete={this.setPremiumToDelete.bind(this)}
            setPremiumToEdit={this.setPremiumToEdit.bind(this)}

            resetBenefitToDelete={this.resetBenefitToDelete.bind(this)}
            resetBenefitToEdit={this.resetBenefitToEdit.bind(this)}
            resetPremiumToDelete={this.resetPremiumToDelete.bind(this)}

            openAddBenefitDialog={this.openAddBenefitDialog.bind(this)}
            openAddPremiumDialog={this.openAddPremiumDialog.bind(this)}

            // group product config update props
            showUpdateGroupProductConfigsProgressAlert={this.props.productsData.updateGroupProductConfigsLoader}
            showUpdateGroupProductConfigsSuccessAlert={this.props.productsData.updateGroupProductConfigsSuccess}
            showUpdateGroupProductConfigsErrorAlert={!!this.props.productsData.updateGroupProductConfigsError}
            updateGroupProductConfigsError={this.props.productsData.updateGroupProductConfigsError}
            updateGroupProductConfigs={this.updateGroupProductConfigs.bind(this)}

            resetUpdateGroupProductConfigsSuccessAlert={this.resetUpdateGroupProductConfigsSuccessAlert.bind(this)}
            resetUpdateGroupProductConfigsErrorAlert={() => { this.props.dispatch(actions.resetUpdateGroupProductConfigsError()) }}

            groupProductConfig={this.props.productsData.groupProductConfigs}
            partnerConfigGroupLoader={this.props.productsData.partnerConfigGroupLoader}


            // group product config add props
            showAddGroupProductConfigsProgressAlert={this.props.productsData.addGroupProductConfigsLoader}
            showAddGroupProductConfigsSuccessAlert={this.props.productsData.addGroupProductConfigsSuccess}
            showAddGroupProductConfigsErrorAlert={!!this.props.productsData.addGroupProductConfigsError}
            addGroupProductConfigsError={this.props.productsData.addGroupProductConfigsError}
            addGroupProductConfigs={this.addGroupProductConfigs.bind(this)}

            resetAddGroupProductConfigsSuccessAlert={this.resetAddGroupProductConfigsSuccessAlert.bind(this)}
            resetAddGroupProductConfigsErrorAlert={() => { this.props.dispatch(actions.resetAddGroupProductConfigsError()) }}


            // extended group config   
            // add 
            showAddExtendedFamilyProductConfigsProgressAlert={this.props.productsData.addExtendedFamilyProductConfigsLoader}
            showAddExtendedFamilyProductConfigsSuccessAlert={this.props.productsData.addExtendedFamilyProductConfigsSuccess}
            showAddExtendedFamilyProductConfigsErrorAlert={!!this.props.productsData.addExtendedFamilyProductConfigsError}
            addExtendedFamilyProductConfigsError={this.props.productsData.addExtendedFamilyProductConfigsError}
            addExtendedFamilyProductConfigs={this.addExtendedFamilyProductConfigs.bind(this)}

            resetAddExtendedFamilyProductConfigsSuccessAlert={this.resetAddExtendedFamilyProductConfigsSuccessAlert.bind(this)}
            resetAddExtendedFamilyProductConfigsErrorAlert={() => { this.props.dispatch(actions.resetAddExtendedFamilyProductConfigsError()) }}

            extendedFamilyProductConfigs={
              this.props.productsData.currentProduct ? this.props.productsData.currentProduct.membership_configs : null
            }

            premiumConfigs={this.props.productsData.premiumConfigs}

            showUpdateExtendedFamilyProductConfigsProgressAlert={this.props.productsData.updateExtendedFamilyProductConfigsLoader}
            showUpdateExtendedFamilyProductConfigsSuccessAlert={this.props.productsData.updateExtendedFamilyProductConfigsSuccess}
            showUpdateExtendedFamilyProductConfigsErrorAlert={!!this.props.productsData.updateExtendedFamilyProductConfigsError}
            updateExtendedFamilyProductConfigsError={this.props.productsData.updateExtendedFamilyProductConfigsError}
            updateExtendedFamilyProductConfigs={this.updateExtendedFamilyProductConfigs.bind(this)}

            resetUpdateExtendedFamilyProductConfigsSuccessAlert={this.resetUpdateExtendedFamilyProductConfigsSuccessAlert.bind(this)}
            resetUpdateExtendedFamilyProductConfigsErrorAlert={() => { this.props.dispatch(actions.resetUpdateExtendedFamilyProductConfigsError()) }}



            // openAddPremiumDialog={this.state.openAddPremiumDialog}
            // closeAddPremiumDialog={this.closeAddPremiumDialog.bind(this)}
            addPremiumConfig={this.addPremiumConfig.bind(this)}
            showAddPremiumConfigProgressAlert={this.props.productsData.createPremiumConfigLoader}
            showAddPremiumConfigSuccessAlert={this.props.productsData.createPremiumConfigSuccess}
            showAddPremiumConfigErrorAlert={!!this.props.productsData.createPremiumConfigError}
            createPremiumConfigError={this.props.productsData.createPremiumConfigError}
            resetAddPremiumConfigSuccessAlert={() => { this.props.dispatch(actions.resetCreatePremiumConfigSuccess()) }}
            resetAddPremiumConfigErrorAlert={() => { this.props.dispatch(actions.resetCreatePremiumConfigError()) }}
            createdPremiumConfigs={this.props.productsData.createdPremiumConfigs}
            currencyCode={currencyCode}

            addaddonConfig={this.addaddonConfig.bind(this)}
          />

          <EditProduct
            showEditProductDialog={this.state.openEditProductDialog}
            closeEditProductDialog={() => { this.setState({ openEditProductDialog: false }) }}
            product={this.props.productsData.currentProduct}
            showEditProductProgressAlert={this.props.productsData.editProductLoader}
            showEditProductSuccessAlert={this.props.productsData.editProductSuccess}
            showEditProductErrorAlert={!!this.props.productsData.editProductError}
            editProductError={this.props.productsData.editProductError}
            editProduct={this.editProduct.bind(this)}

            resetEditProductSuccessAlert={() => { this.props.dispatch(actions.resetEditProductSuccess()) }}
            resetEditProductErrorAlert={() => { this.props.dispatch(actions.resetEditProductError()) }}
          />

          <EditPremium
            premium={this.props.productsData.premiumToEdit}
            editPremium={this.editPremium.bind(this)}
            resetPremiumToEdit={this.resetPremiumToEdit.bind(this)}

            showEditPremiumProgressAlert={this.props.productsData.editPremiumLoader}
            showEditPremiumSuccessAlert={this.props.productsData.editPremiumSuccess}
            showEditPremiumErrorAlert={!!this.props.productsData.editPremiumError}
            editPremiumError={this.props.productsData.editPremiumError}

            resetEditPremiumSuccessAlert={() => { this.props.dispatch(actions.resetEditPremiumSuccess()) }}
            resetEditPremiumErrorAlert={() => { this.props.dispatch(actions.resetEditPremiumError()) }}

            currencyCode={currencyCode}
          />



          <EditBenefits
            benefit={this.props.productsData.benefitToEdit}
            editBenefit={this.editBenefit.bind(this)}
            resetBenefitToEdit={this.resetBenefitToEdit.bind(this)}

            showEditBenefitProgressAlert={this.props.productsData.editBenefitLoader}
            showEditBenefitSuccessAlert={this.props.productsData.editBenefitSuccess}
            showEditBenefitErrorAlert={!!this.props.productsData.editBenefitError}
            editBenefitError={this.props.productsData.editBenefitError}

            resetEditBenefitSuccessAlert={() => { this.props.dispatch(actions.resetEditBenefitSuccess()) }}
            resetEditBenefitErrorAlert={() => { this.props.dispatch(actions.resetEditBenefitError()) }}
            currencyCode={currencyCode}
          />

          <DeletePremium
            premium={this.props.productsData.premiumToDelete}
            deletePremium={this.deletePremium.bind(this)}
            resetPremiumToDelete={this.resetPremiumToDelete.bind(this)}

            showDeletePremiumProgressAlert={this.props.productsData.hardDeletePremiumLoader}
            showDeletePremiumSuccessAlert={this.props.productsData.hardDeletePremiumSuccess}
            showDeletePremiumErrorAlert={!!this.props.productsData.hardDeletePremiumError}
            deletePremiumError={this.props.productsData.hardDeletePremiumError}

            resetDeletePremiumSuccessAlert={() => { this.props.dispatch(actions.resetHardDeletePremiumSuccess()) }}
            resetDeletePremiumErrorAlert={() => { this.props.dispatch(actions.resetHardDeletePremiumError()) }}
          />

          <DeleteBenefit
            benefit={this.props.productsData.benefitToDelete}
            deleteBenefit={this.deleteBenefit.bind(this)}
            resetBenefitToDelete={this.resetBenefitToDelete.bind(this)}

            showDeleteBenefitProgressAlert={this.props.productsData.hardDeleteBenefitLoader}
            showDeleteBenefitSuccessAlert={this.props.productsData.hardDeleteBenefitSuccess}
            showDeleteBenefitErrorAlert={!!this.props.productsData.hardDeleteBenefitError}
            deleteBenefitError={this.props.productsData.hardDeleteBenefitError}

            resetDeleteBenefitSuccessAlert={() => { this.props.dispatch(actions.resetHardDeleteBenefitSuccess()) }}
            resetDeleteBenefitErrorAlert={() => { this.props.dispatch(actions.resetHardDeleteBenefitError()) }}
          />


          <AddBenefits
            openAddBenefitDialog={this.state.openAddBenefitDialog}
            closeAddBenefitDialog={this.closeAddBenefitDialog.bind(this)}
            addBenefit={this.addBenefit.bind(this)}
            showAddBenefitProgressAlert={this.props.productsData.createBenefitLoader}
            showAddBenefitSuccessAlert={this.props.productsData.createBenefitSuccess}
            showAddBenefitErrorAlert={!!this.props.productsData.createBenefitError}
            createBenefitError={this.props.productsData.createBenefitError}
            resetAddBenefitSuccessAlert={() => { this.props.dispatch(actions.resetCreateBenefitSuccess()) }}
            resetAddBenefitErrorAlert={() => { this.props.dispatch(actions.resetCreateBenefitError()) }}
            createdBenefits={this.props.productsData.createdBenefits}
            refreshProduct={this.refreshProduct.bind(this)}
            currencyCode={currencyCode}
          />

          <AddPremium
            openAddPremiumDialog={this.state.openAddPremiumDialog}
            closeAddPremiumDialog={this.closeAddPremiumDialog.bind(this)}
            addPremium={this.addPremium.bind(this)}
            showAddPremiumProgressAlert={this.props.productsData.createPremiumLoader}
            showAddPremiumSuccessAlert={this.props.productsData.createPremiumSuccess}
            showAddPremiumErrorAlert={!!this.props.productsData.createPremiumError}
            createPremiumError={this.props.productsData.createPremiumError}
            resetAddPremiumSuccessAlert={() => { this.props.dispatch(actions.resetCreatePremiumSuccess()) }}
            resetAddPremiumErrorAlert={() => { this.props.dispatch(actions.resetCreatePremiumError()) }}
            createdPremiums={this.props.productsData.createdPremiums}
            product={this.props.productsData.currentProduct}
            refreshProduct={this.refreshProduct.bind(this)}
            currencyCode={currencyCode}
          />



        </Container>
      </React.Fragment >
    );
  }
}

export default connect((state) => ({
  productsData: state.newProducts,
  globalData: state.global,
}))(ViewProductStatus);
