import * as types from '../../../../../../lib/actionTypes.es6'


export function getNGSGroupCustomerQuotesRequest(payload) {
  return {
    type: types.GET_NGSGROUP_CUSTOMER_QUOTES_REQUEST,
    payload,
  };
}

export function getNGSGroupCustomerQuotesSuccess(response) {
  return {
    type: types.GET_NGSGROUP_CUSTOMER_QUOTES_SUCCESS,
    payload: response,
  };
}

export function getNGSGroupCustomerQuotesFailure(error) {
  return {
    type: types.GET_NGSGROUP_CUSTOMER_QUOTES_FAILURE,
    payload: error,
  };
}