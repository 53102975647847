import * as types from '../../lib/actionTypes.es6';

export function reportClaimReimbursementRequest(reportType) {
  return {
    type: types.REPORT_CLAIM_REIMBURSEMENT_REQUEST,
    payload: reportType,
  };
}

export function reportClaimReimbursementRequestSuccess(reportURL) {
  return {
    type: types.REPORT_CLAIM_REIMBURSEMENT_SUCCESS,
    payload: reportURL,
  };
}

export function reportClaimReimbursementRequestError(error) {
  return {
    type: types.REPORT_CLAIM_REIMBURSEMENT_FAILURE,
    payload: error,
  };
}

export function reportClaimReimbursementReset() {
  return {
    type: types.REPORT_CLAIM_REIMBURSEMENT_RESET,
  };
}
