import React from "react";
import { connect } from "react-redux";
import moment from 'moment';
import find from 'lodash/find';
import ClaimsHeader from "./Components/ClaimsHeader/ClaimsHeader";
import Grid from "@material-ui/core/Grid";
import Principal from "../../../containers/Policyv2/Components/Principal/Principal";
import ClaimInitiationWrapper from "./Components/ClaimInitiationWrapper/ClaimInitiationWrapper";
import { getCustomer } from "../../../containers/Customer/actions.es6";
//import { getAllPoliciesRequest } from "../../../containers/CustomerScreen/actions.es6";
import *  as actions from "./Redux/actions"
import SelectPolicy from "./Components/SelectPolicy/SelectPolicy";
import SelectMember from "./Components/SelectMember/SelectMember";
import ClaimLoggingDetails from "./Components/ClaimLoggingDetails/ClaimLoggingDetails";
import ClaimDetails from "./Components/ClaimDetails/ClaimDetails";
import { getUserFullname, getUserGuid } from "../../../lib/access.es6";
import AdditionalDetails from "./Components/AdditionalDetails/AdditionalDetails";
import { getAllPoliciesRequest } from "../../../containers/PolicyV2CustomerStatus/Redux/actions";

class ClaimInitiation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      claimInitiationPayload: {
        "event_date": null,
        "policy_guid": null,
        "quotation_guid": null,
        "customer_guid": null,
        "dependant_guid": null,
        "partner": null,
        "benefits": [],
        "initiator_fullname": null,
        "initiator_msisdn": null,
        "web_agent_guid": null,
        "web_agent_name": null,
        "additional_details": null,
      },

      "claimant_phone_number": '',
      "claimant_full_name": '',
      "claimant_id_number": '',
      "claimant_relationship_to_patient": '',
      admission_date: '',
      discharge_date: '',
      hospital_name: '',
      diagnosis_name: '',
      age: '',
      hospital_in_panel: false
    };
  }

  setAdditionalState(name, value){
    this.setState({[name]: value})
  }

  componentWillMount() {
    // const currentCustomer = this.props.customerData.currentCustomer;
    // if (currentCustomer) {
    //   this.props.dispatch(getCustomer(currentCustomer.guid))
    // }


    // if (currentCustomer) {
    //   this.props.dispatch(
    //     getAllPoliciesRequest({
    //       guid: currentCustomer.guid
    //     })
    //   );

    // }

    // if (currentCustomer) {
    //   this.props.dispatch(actions.getCustomerClaimsDcpRequest({
    //     guid: currentCustomer.guid
    //   })
    //   );

    // }


    let customer_guid = this.props.params.customer_guid
    if (customer_guid) {
      this.refreshCustomerDetails(customer_guid)
    }
    if(customer_guid){
      this.props.dispatch(getAllPoliciesRequest({ guid: customer_guid }))
    }


    if (customer_guid) {
      this.props.dispatch(actions.getCustomerClaimsDcpRequest({
        guid: customer_guid
      })
      );
    }


  }

  refreshCustomerDetails(customer_guid) {
    this.props.dispatch(getCustomer(customer_guid))

	}
   getAge(){
    const member = this.state.claimInitiationPayload.member ? this.state.claimInitiationPayload.member.dependant : null

    if (member) {

      const dob = member.date_of_birth;
      const currentDate = moment();
      const birthDate = moment(dob);
      const currentAge = currentDate.diff(birthDate, 'years');
      return currentAge;
    }
    return ""
  };

  handleSelectPolicies(payload) {
    this.props.dispatch(actions.getEventQuoteRequest(payload));
  }

  handleInitiateClaim() {
    let payload = this.state.claimInitiationPayload;
    payload['policy_guid'] = this.props.claimInitiationData.eventQuote.policy_guid;
    payload['quotation_guid'] = this.props.claimInitiationData.eventQuote.guid;
    payload['customer_guid'] = this.props.claimInitiationData.eventQuote.customer_guid;
    payload['partner'] = this.props.claimInitiationData.eventQuote.partner;
    payload['web_agent_guid'] = getUserGuid();
    payload['web_agent_name'] = getUserFullname();

    payload = {
      ...payload,
      initiator_fullname: this.state.claimant_full_name,
      initiator_msisdn: this.state.claimant_phone_number,
      additional_details: {
        ...payload.additional_details,
        claimant_phone_number: this.state.claimant_phone_number,
        claimant_full_name: this.state.claimant_full_name,
        claimant_id_number: this.state.claimant_id_number,
        claimant_relationship_to_patient: this.state.claimant_relationship_to_patient,
        admission_date: this.state.admission_date,
        discharge_date: this.state.discharge_date,
        hospital_name: this.state.hospital_name,
        diagnosis_name: this.state.diagnosis_name,
        hospital_in_panel: this.state.hospital_in_panel,
        age: this.getAge(),
      }
    }

    console.log('payload: ', payload);
    this.props.dispatch(actions.initiateClaimRequest(payload));
  }

  render() {
    if (this.props.customerData.loader) {
      return "Loading...";
    }
    if (!this.props.customerData.currentCustomer) {
      return "not found...";
    }

    const hasDeathBenefit = !!find(this.state.claimInitiationPayload.benefits, {type: "DEATH BENEFIT"})

    return (
      <>
        <ClaimsHeader
          title="Initiate Claim"
        />
        <Grid container spacing={2}>
          <Grid xs={12} md={3} sm={3}>
            <Principal
              customerData={this.props.customerData.currentCustomer}
              loader={false}
            />
          </Grid>
          <ClaimInitiationWrapper
            policies={this.props.customerStatusV2Data.policies}
            handleSelectPolicies={this.handleSelectPolicies.bind(this)}
            selectedMember={this.props.claimInitiationData.selectedPolicy}
            eventQuote={this.props.claimInitiationData.eventQuote}
            customerClaimsDcp={this.props.claimInitiationData.customer_claims_dcp}
            handleInitiateClaim={this.handleInitiateClaim.bind(this)}
            hasDeathBenefit={hasDeathBenefit}
            createdClaim={this.props.claimInitiationData.initiateClaim}
            components={
              {
                SelectPolicy: <SelectPolicy
                  policies={this.props.customerStatusV2Data.policies}
                  customerData={this.props.customerData.currentCustomer}
                  handleSelectPolicies={this.handleSelectPolicies.bind(this)}

                  eventQuoteSuccess={this.props.claimInitiationData.eventQuoteSuccess}
                  eventQuoteLoader={this.props.claimInitiationData.eventQuoteLoader}
                  eventQuoteError={this.props.claimInitiationData.eventQuoteError}

                  claimInitiationPayload={this.state.claimInitiationPayload}
                  setClaimInitiationPayload={(newClaimInitiationPayload) => this.setState({ claimInitiationPayload: newClaimInitiationPayload })}
                />,
                SelectMember:
                  <SelectMember
                    eventQuote={this.props.claimInitiationData.eventQuote}
                    claimInitiationPayload={this.state.claimInitiationPayload}
                    setClaimInitiationPayload={(newClaimInitiationPayload) => this.setState({ claimInitiationPayload: newClaimInitiationPayload })}
                  />,

                AdditionalDetails: <AdditionalDetails
                  hasDeathBenefit={hasDeathBenefit}

                  handleInitiateClaim={this.handleInitiateClaim.bind(this)}
                  eventQuote={this.props.claimInitiationData.eventQuote}

                  claimInitiationPayload={this.state.claimInitiationPayload}
                  setClaimInitiationPayload={(newClaimInitiationPayload) => this.setState({ claimInitiationPayload: newClaimInitiationPayload })}
                  member={this.state.claimInitiationPayload.member ? this.state.claimInitiationPayload.member.dependant : null}
                  eventDate={this.state.claimInitiationPayload.event_date}
                  setAdditionalState={this.setAdditionalState.bind(this)}
                />,


                ClaimLoggingDetails: <ClaimLoggingDetails
                  handleInitiateClaim={this.handleInitiateClaim.bind(this)}
                  eventQuote={this.props.claimInitiationData.eventQuote}

                  claimInitiationPayload={this.state.claimInitiationPayload}
                  setClaimInitiationPayload={(newClaimInitiationPayload) => this.setState({ claimInitiationPayload: newClaimInitiationPayload })}
                  hasDeathBenefit={hasDeathBenefit}
                  setAdditionalState={this.setAdditionalState.bind(this)}
                />,
                ClaimDetails: <ClaimDetails
                  claimDetailsData={this.props.claimInitiationData.initiateClaim}
                  initiateClaimLoader={this.props.claimInitiationData.initiateClaimLoader}
                />
              }
            }
          />


        </Grid>
      </>
    )
  }

}

export default connect((state) => ({
  customerData: state.currentCustomer,
  claimInitiationData: state.claimInitiationData,
  customerStatusV2Data: state.customerStatusV2Data,
  globalData: state.global,
}))(ClaimInitiation);