import * as types from '../../lib/actionTypes.es6';

export function claimFinalisationReportRequest(reportPayload) {
  return {
    type: types.REPORT_CLAIM_FINALISATION_REQUEST,
    payload: reportPayload,
  };
}

export function claimFinalisationReportSuccess(reportBlob) {
  return {
    type: types.REPORT_CLAIM_FINALISATION_SUCCESS,
    payload: reportBlob,
  };
}

export function claimFinalisationReportError(error) {
  return {
    type: types.REPORT_CLAIM_FINALISATION_ERROR,
    payload: error,
  };
}

export function claimFinalisationReportReset() {
  return {
    type: types.REPORT_CLAIM_FINALISATION_RESET,
  };
}
