import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles((LandingPageTheme) => ({

    partners: {
        background: "#FBA92D",
        borderRadius: "50px 50px 0px 0px",
        padding: "50px",
        [LandingPageTheme.breakpoints.down("md")]:{
            padding: "30px"
        },
        "& h2": {
            fontFamily: 'Lato',
            fontStyle: "normal",
            fontWeight: "900",
            fontSize: "22px",
            lineHeight: "28px",
            color: "#fff",
            [LandingPageTheme.breakpoints.down("md")]: {
                fontSize: "18px",
                lineHeight: "25px",
                letterSpacing: " 0.001em",
            }
        },
    },
    partnerlogos: {
        marginTop: "40px",
        marginBottom: "40px",
        width: "20%",
        float: "left",
        "& img":{
            width: "70%"
        },
        [LandingPageTheme.breakpoints.down("md")]:{
            width: "50%",
        },
    },
    divider: {
        width: "28px",
        height: "7px",
        background: "#fff",
        transform: "matrix(1, 0, 0, -1, 0, 0)",
        float: "left",
        marginTop: "-10px",
        display: "inline",
    },
    mySwiper: {
        "& span": {
            background: "#fff",
            marginTop: "50px !important",
            width: "12px",
            height: "12px"
        }
    },

}));

