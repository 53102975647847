import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import { hasAuthourity } from '../../../../../lib/access.es6';

const PaymentHeader = (props) => {
  const classes = useStyle();

  const handleapprovePayment = () => {
    props.approvePayment()
  }

  const PAYMENT_STATUSES = {
    Un_Confirmed: 'Unconfirmed',
    Rejected: 'Rejected',
  }

  const status = props.payment ? props.payment.processing_status : null;


  return (
    <section className={classes.claimsheader}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10} md={10}>
          <div className={classes.claimstitle}>
            <ul>
              <li>Payment Processing </li>
            </ul>
          </div>
        </Grid>
        {
          (status === PAYMENT_STATUSES.Un_Confirmed) && <Grid item xs={12} sm={2} md={2}  style={{display : hasAuthourity('APPROVE_PAYMENT') ? "block" : "none"}}>
            <Grid container spacing={2} className={classes.claimactionbtn}>
              <Grid item xs={12} sm={6} md={6}>
                <ButtonInc style={{ float: "right" }} onClick={handleapprovePayment} >Approve</ButtonInc>

              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <ButtonInc style={{ float: "left", background: "#8F191C" }} onClick={() => props.toggleOpenRejectPayment()}>Reject</ButtonInc>
              </Grid>
            </Grid>
          </Grid>

        }


      </Grid>
    </section>
  )
}

export default PaymentHeader