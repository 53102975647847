import React, { useState, useEffect} from 'react';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import ManIcon from '@mui/icons-material/Man';
import Grid from "@material-ui/core/Grid";
import AlertDialog from '../../../../../../components/AlertDialog/index.es6';
import { getCustomerPolicies } from '../../../../../../lib/requests.es6';
import { hasSameProduct } from '../../../../lib/helpers';
import { CircularProgress } from '@mui/material';
import ButtonInc from '../../../../../../shared-ui/ButtonInc';
import { hashHistory } from 'react-router';

const CoverTypes = {
  MEMBER_ONLY: 'Member Only',
  MEMBER_AND_FAMILY: 'Member & Family'
};

export default function SelectCoverType({setCoverType, coverType, customerGuid}) {
  const [showGettingActivePolicies, setShowGettingActivePolicies] = useState(false)
  const [showActivePolicyFoundErrorAlert, setShowActivePolicyFoundErrorAlert] = useState(false)
  const [showContent, setShowContent] = useState(false)



  const fetchActivePolicies = async () => {
    console.log('fetching active policies');
    
    setShowGettingActivePolicies(true)
    const response = await getCustomerPolicies({guid: customerGuid})
    const activePolicies = response.data.filter(policy => policy.active )
    if(activePolicies.length > 0 ){
      const newPolicy = { product_name: 'Monamodi' }
      for(let currentPolicy of activePolicies){
        if(hasSameProduct(currentPolicy, newPolicy)){
          setShowGettingActivePolicies(false)
          console.log('found duplicate active policy: ', currentPolicy.product_name, newPolicy.product_name);
          
          return setShowActivePolicyFoundErrorAlert(true)
      }
    }
    }
    
    setShowGettingActivePolicies(false)
    setShowContent(true)

    return response
  }

  useEffect(() => {
    fetchActivePolicies()

  }, [])
  
  const handleSelect = (value) => {
    setCoverType(value)
  }

  return (
    <Grid container spacing={2}>
      <AlertDialog
        show={showActivePolicyFoundErrorAlert}
        danger
        title={'Can not have two active Policies'}
        onConfirm={() => {}}
        confirmBtnText={'view active policy'}
        confirmBtnCssClass
        showCancel={false}
        showConfirm={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        <p>There is an activie policy for this same product,</p>
        <p>before approving this policy and making it active</p>
        <p>please cancel the previous active policy</p>
        <ButtonInc onClick={() => hashHistory.push('admin/customer_status/' + customerGuid)} >View active policy</ButtonInc>
      </AlertDialog>

      <AlertDialog
        custom
        show={showGettingActivePolicies}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress/>
        <h2>Checking for active policies of the same product type</h2>
      </AlertDialog>
      <Grid xs={12} style={{ textAlign: 'center' }}><h2>What is your Monamodi cover type</h2></Grid>
      {
        showContent &&  <>
        <Grid item xs={12} sm={3} style={{ textAlign: 'right', }}></Grid>
          <Grid item xs={6} sm={3} style={{ textAlign: 'right', }}>
            <div style={{ textAlign: 'center' }}>
              <ManIcon
                onClick={() => handleSelect(CoverTypes.MEMBER_ONLY)}
                sx={{
                  color: coverType === CoverTypes.MEMBER_ONLY ? 'orange' : '#bdbdbd',
                  fontSize: 200,
                  border: coverType === CoverTypes.MEMBER_ONLY ? 'solid 1px #032a37' : 'none',
                  borderRadius: '10%',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    color: 'orange',
                    cursor: 'pointer'
                  }
                }}
              />
              <div>{CoverTypes.MEMBER_ONLY}</div>
            </div>
          </Grid>
          <Grid item xs={6} sm={6}  style={{ textAlign: 'left' }}>
            <div style={{ textAlign: 'left' }}>
              <FamilyRestroomIcon
                onClick={() => handleSelect(CoverTypes.MEMBER_AND_FAMILY)}
                sx={{
                  color: coverType === CoverTypes.MEMBER_AND_FAMILY ? 'orange' : '#bdbdbd',
                  fontSize: 200,
                  border: coverType === CoverTypes.MEMBER_AND_FAMILY ? 'solid 1px #032a37' : 'none',
                  borderRadius: '10%',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    color: 'orange',
                    cursor: 'pointer'
                  }
                }}
              />
              <div style={{marginLeft: '5%'}}>{CoverTypes.MEMBER_AND_FAMILY}</div>
            </div>
          </Grid>
        </>
      }
    </Grid>
  )
}
