import * as types from "../../lib/actionTypes.es6";

export const initialState = {
  error: null,
  loader: false,
  beneficiarys: [],


  beneficiary: null,

  createdbeneficiary: null,
  createbeneficiarySuccess: false,
  createbeneficiaryLoader: false,
  createbeneficiaryError: null,


  beneficiaryToEdit: null,
  beneficiaryToDelete: null,

  editedbeneficiary: null,
  editbeneficiarySuccess: false,
  editbeneficiaryLoader: false,
  editbeneficiaryError: null,


  deletebeneficiarySuccess: false,
  deletebeneficiaryLoader: false,
  deletebeneficiaryError: null,
  beneficiaryToDeleteGuid: null,
};

export default function customerbeneficiarysReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CUSTOMER_BENEFICIARY_REQUEST:
      return { ...state, loader: true, error: null, beneficiary: null };
    case types.GET_CUSTOMER_BENEFICIARY_SUCCESS:
      return { ...state, beneficiary: action.payload, loader: false };
    case types.GET_CUSTOMER_BENEFICIARY_FAILURE:
      return { ...state, loader: false, error: action.payload };

    // case types.GET_CUSTOMER_BENEFICIARY_REQUEST:
    //   return { ...state, loader: true, error: null, beneficiarys: null };
    // case types.GET_CUSTOMER_BENEFICIARY_SUCCESS:
    //   return { ...state, beneficiarys: action.payload, loader: false };
    // case types.GET_CUSTOMER_BENEFICIARY_FAILURE:
    //   return { ...state, loader: false, error: action.payload };

    case types.ADD_CUSTOMER_BENEFICIARY_REQUEST:
      return { ...state, createbeneficiaryLoader: true, createbeneficiaryError: null, createdbeneficiary: null };
    case types.ADD_CUSTOMER_BENEFICIARY_SUCCESS:
      return { ...state,  createdbeneficiary:action.payload, beneficiary: action.payload, createbeneficiaryLoader: false, createbeneficiarySuccess: true };
    case types.ADD_CUSTOMER_BENEFICIARY_FAILURE:
      return { ...state, createbeneficiaryLoader: false, createbeneficiaryError: action.payload, createdbeneficiary: null };

    case types.RESET_ADD_CUSTOMER_BENEFICIARY_DETAILS_SUCCESS:
      return { ...state, createbeneficiarySuccess: false };
    case types.RESET_ADD_CUSTOMER_BENEFICIARY_DETAILS_ERROR:
      return { ...state, createbeneficiaryError: null };



    case types.UPDATE_CUSTOMER_BENEFICIARY_REQUEST:
      return { ...state, editbeneficiaryLoader: true, editbeneficiaryError: null, beneficiary: null };
    case types.UPDATE_CUSTOMER_BENEFICIARY_SUCCESS:
      return { ...state, editedbeneficiary: action.payload, editbeneficiaryLoader: false, editbeneficiarySuccess: true };
    case types.UPDATE_CUSTOMER_BENEFICIARY_FAILURE:
      return { ...state, editbeneficiaryLoader: false, editbeneficiaryError: action.payload };
    case types.SET_CUSTOMER_BENEFICIARY_TO_EDIT:
      return { ...state, beneficiaryToEdit: action.payload };
    case types.RESET_CUSTOMER_BENEFICIARY_TO_EDIT:
      return { ...state, beneficiaryToEdit: null };

    case types.RESET_EDIT_CUSTOMER_BENEFICIARY_SUCCESS:
      return { ...state, editbeneficiarySuccess: false };

    case types.RESET_EDIT_CUSTOMER_BENEFICIARY_ERROR:
      return { ...state, editbeneficiaryError: null };


    case types.DELETE_CUSTOMER_BENEFICIARY_REQUEST:
      return { ...state, loader: true, error: null, beneficiary: null };
    case types.DELETE_CUSTOMER_BENEFICIARY_SUCCESS:
      return { ...state, loader: false, beneficiary: null, };
    case types.DELETE_CUSTOMER_BENEFICIARY_FAILURE:
      return { ...state, loader: false, error: action.payload };

    case types.SET_CUSTOMER_BENEFICIARY_DETAILS_TO_DELETE: 
      return { ...state, beneficiaryToDelete: action.payload };

    case types.RESET_CUSTOMER_BENEFICIARY_TO_DELETE:
      return { ...state, beneficiaryToDelete: null };

      case types.SET_CUSTOMER_BENEFICIARY_DETAILS_TO_EDIT: 
      return { ...state, beneficiaryToEdit: action.payload };

    case types.RESET_CUSTOMER_BENEFICIARY_DETAILS_TO_EDIT:
      return { ...state, beneficiaryToEdit: null };

    case types.RESET_DELETE_CUSTOMER_BENEFICIARY_SUCCESS:
      return { ...state, deletebeneficiarySuccess: false };

    case types.RESET_DELETE_CUSTOMER_BENEFICIARY_ERROR:
      return { ...state, deletebeneficiaryError: null };


    default:
      return state;
  }
}
