import React from 'react';
import MaterialTable from 'material-table';
import { Button } from '@material-ui/core';
import * as utils from '../../lib/utils.es6';


export default class GroupClaims extends React.Component {

  render() {
    const claims = this.props.claims || [];
    return (
      <MaterialTable
        title="Group Claims"
        columns={[
          { title: 'Received At', field: 'received_time' },
          { title: "Principal", field: 'customer_full_name' },
          { title: "Msisdn", field: 'customer_msisdn' },
          { title: 'Affected', field: 'person_affected'},
          { title: 'Status', field: 'status', render: claim => utils.formatStatus(claim.status) },
          { title: 'Claim Type', field: 'type' },
          { title: 'Relationship', field: 'relation_to_main_member' },
          { title: 'Death Date', field: 'death_date' },
          {
            title: 'Action', field: 'kra_pin', render: claim => {
              return <Button
                variant="contained"
                color="secondary"
                onClick={() => this.props.viewClaim(claim)}
              >
                View
              </Button>
            }
          },
        ]}
        data={claims}
      
        options={{
					search: true,
					sorting: true,
					exportButton: true,
					actionsColumnIndex: -1,
					pageSize: 10,
					headerStyle: {
						backgroundColor: '#003643',
						color: '#FFF'
					}
				}}
      />
    );
  }
}