import * as types from '../../lib/actionTypes.es6';

export function downloadDocumentRequest(payload) {
  return {
    type: types.DOWNLOAD_DOCUMENT_REQUEST,
    payload,
  };
}

export function downloadDocumentFailure(error) {
  return {
    type: types.DOWNLOAD_DOCUMENT_FAILURE,
    payload: error,
  };
}

export function downloadDocumentSuccess(response) {
  return {
    type: types.DOWNLOAD_DOCUMENT_SUCCESS,
    payload: response,
  };
}

export function resetDownloadDocumentSuccess(error) {
  return {
    type: types.RESET_DOWNLOAD_DOCUMENT_SUCCESS,
  };
}

export function resetDownloadDocumentError(error) {
  return {
    type: types.RESET_DOWNLOAD_DOCUMENT_ERROR,
  };
}

export function setDocumentEntity(response) {
  return {
    type: types.SET_ENTITY_TO_DOWNLOAD_FOR,
    payload: response,
  };
}

export function resetDocumentEntity(response) {
  return {
    type: types.RESET_ENTITY_TO_DOWNLOAD_FOR,
  };
}


// template document actions


export function getTemplateDocumentsRequest(type) {
  return {
    type: types.GET_TEMPLATE_DOCUMENTS_REQUEST,
    payload: type,
  };
}

export function getTemplateDocumentsSuccess(response) {
  return {
    type: types.GET_TEMPLATE_DOCUMENTS_SUCCESS,
    payload: response,
  };
}

export function getTemplateDocumentsFailed(error) {
  return {
    type: types.GET_TEMPLATE_DOCUMENTS_FAILURE,
    payload: error,
  };
}

export function resetGetTemplateDocuments(error) {
  return {
    type: types.RESET_GET_TEMPLATE_DOCUMENTS,
    payload: error,
  };
}



export function addTemplateDocumentRequest(payload) {
  return {
    type: types.ADD_TEMPLATE_DOCUMENT_REQUEST,
    payload,
  };
}

export function addTemplateDocumentSuccess(response) {
  return {
    type: types.ADD_TEMPLATE_DOCUMENT_SUCCESS,
    payload: response,
  };
}

export function addTemplateDocumentFailed(error) {
  return {
    type: types.ADD_TEMPLATE_DOCUMENT_FAILURE,
    payload: error,
  };
}

export function editTemplateDocumentRequest(payload) {
  return {
    type: types.EDIT_TEMPLATE_DOCUMENT_REQUEST,
    payload,
  };
}

export function editTemplateDocumentSuccess(response) {
  return {
    type: types.EDIT_TEMPLATE_DOCUMENT_SUCCESS,
    payload: response,
  };
}

export function editTemplateDocumentFailed(error) {
  return {
    type: types.EDIT_TEMPLATE_DOCUMENT_FAILURE,
    payload: error,
  };
}


export function deleteTemplateDocumentRequest(payload) {
  return {
    type: types.DELETE_TEMPLATE_DOCUMENT_REQUEST,
    payload,
  };
}

export function deleteTemplateDocumentSuccess(response) {
  return {
    type: types.DELETE_TEMPLATE_DOCUMENT_SUCCESS,
    payload: response,
  };
}

export function deleteTemplateDocumentFailed(error) {
  return {
    type: types.DELETE_TEMPLATE_DOCUMENT_FAILURE,
    payload: error,
  };
}
