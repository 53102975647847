import React, { useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Link from '@material-ui/core/Link';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { PRIMARY_THEME_COLOR_SHADE_1 } from '../../../lib/constants';
import { formatDate } from '../../../lib/utils.es6';
import { EditPartnerDocument } from './EditPartnerDocument';

export default function ViewPartnerDocuments(props) {
  const { documents, labels, onDelete, onEdit, s3Url } = props;

  const [showEditDialog, setShowEditDialog] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null);

  const onUpdate = payload => {
    setShowEditDialog(false);
    setCurrentDocument(null);
    onEdit(payload);
  };

  return (
    <>
      {!documents || documents.length === 0 ? (
        <center>{labels.nothingFound}</center>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="table-cell-header">
                  {labels.date}
                </TableCell>
                <TableCell className="table-cell-header">
                  {labels.documentType}
                </TableCell>
                <TableCell className="table-cell-header">
                  {labels.name}
                </TableCell>
                <TableCell className="table-cell-header" align="center">
                  {labels.action}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="documents-list">
              {documents.map((document, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {formatDate(new Date(document.created_at))}
                  </TableCell>
                  <TableCell>{document.type}</TableCell>
                  <TableCell>
                    <Link
                      href={document.content_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {document.name}
                    </Link>
                  </TableCell>
                  <TableCell align="right">
                    <Stack direction="row" spacing={1} justifyContent="center">
                      <IconButton
                        onClick={() => {
                          setCurrentDocument(document);
                          setShowEditDialog(true);
                        }}
                      >
                        <EditIcon
                          style={{
                            color: PRIMARY_THEME_COLOR_SHADE_1,
                            fontWeight: 'bolder',
                            display: 'inline-block',
                          }}
                        />
                      </IconButton>
                      <IconButton onClick={() => onDelete(document.guid)}>
                        <DeleteForeverIcon
                          style={{
                            color: 'red',
                            fontWeight: 'bolder',
                            marginRight: '2%',
                            display: 'inline-block',
                          }}
                        />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {labels && (
        <EditPartnerDocument
          showEditDialog={showEditDialog}
          setShowEditDialog={setShowEditDialog}
          labels={labels}
          onEdit={onUpdate}
          s3Url={s3Url}
          document={currentDocument}
        />
      )}
    </>
  );
}
