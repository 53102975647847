import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  products: [],
  errors: null,
  loader: false,
  currentProduct: null,
  options: null,
};

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    case types.VIEW_ALL_PRODUCTS_REQUEST:
      return { ...state, errors: null, loader: true, products: [] };

    case types.VIEW_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
        loader: false,
        errors: null,
      };

    case types.VIEW_ALL_PRODUCTS_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    case types.CREATE_PRODUCT_REQUEST:
      return {
        ...state,
        errors: null,
        currentProduct: null,
        loader: true,
      };

    case types.CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        currentProduct: action.payload,
        loader: false,
        errors: null,
      };

    case types.CREATE_PRODUCT_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    case types.VIEW_PRODUCT_REQUEST:
      return { ...state, errors: null, loader: true };

    case types.VIEW_PRODUCT_SUCCESS:
      return {
        ...state,
        currentProduct: action.payload,
        loader: false,
        errors: null,
      };

    case types.VIEW_PRODUCT_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    case types.PRODUCT_ENUMS_REQUEST:
      return { ...state, errors: null, loader: true, options: null };

    case types.PRODUCT_ENUMS_SUCCESS:
      return {
        ...state,
        options: action.payload,
        loader: false,
        errors: null,
      };

    case types.PRODUCT_ENUMS_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    case types.USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
}
