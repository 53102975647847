import * as types from '../../../../../../lib/actionTypes.es6';

export function getAllOrangePremiumsRequest(customerGuid) {
  return {
    type: types.GET_ALL_ORANGE_PREMIUMS_REQUEST,
    payload: customerGuid,
  };
}

export function getAllOrangePremiumsSuccess(response) {
  return {
    type: types.GET_ALL_ORANGE_PREMIUMS_SUCCESS,
    payload: response,
  };
}

export function getAllOrangePremiumsFail(response) {
  return {
    type: types.GET_ALL_ORANGE_PREMIUMS_FAILURE,
    payload: response,
  };
}