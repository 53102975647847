import React from 'react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useStyle } from "./Style";

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Box,
    ClickAwayListener,
    Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ButtonBase,
    Paper,
    Popper,
    Typography,
    useMediaQuery
} from '@mui/material';

// project imports
import NavItem from '../NavItem';
import Transitions from '../../ui-component/extended/Transitions';

import LAYOUT_CONST from '../../constant';
import useConfig from '../../../../hooks/useConfig';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { activeID } from '../../../../containers/Drawer/redux/actions';

// mini-menu - wrapper
const PopperStyledMini = styled(Popper)(({ theme }) => ({
    overflow: 'visible',
    zIndex: 1202,
    minWidth: 180,
    '&:before': {
        content: '""',
        backgroundColor: theme.palette.background.paper,
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 120,
        borderLeft: `1px solid ${theme.palette.grey.A800}`,
        borderBottom: `1px solid ${theme.palette.grey.A800}`
    }
}));



// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level, parentId, drawerOpen, location, dispatch, selectedItem , selectedParentID}) => {
    const classes = useStyle();

    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const { layout, borderRadius } = useConfig();
    // const { drawerOpen } = useSelector((state) => state.menu);

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(selectedParentID);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        setSelected(selectedParentID)
    }, [selectedParentID])
    

    const handleClickMini = (event) => {
        dispatch(activeID(menu.id));
        
        setAnchorEl(null);
        if (drawerOpen) {
            setOpen(!open);
            setSelected(!selected ? menu.id : null);
        } else {
            const anchorEvent = event ? (event.currentTarget ? event.currentTarget : null) : null
            setAnchorEl(anchorEvent);
        }
    };

    // const handleHover = (event) => {
    //     const anchorEvent = event ? (event.currentTarget ? event.currentTarget : null) : null
    //     setAnchorEl(anchorEvent);
    // };

    const handleClosePopper = () => {
        setOpen(false);
        setSelected(null);
        setAnchorEl(null);
    };

    const openMini = Boolean(anchorEl);
    const { pathname } = location;

    const checkOpenForParent = (child, id) => {
        child.forEach((item) => {
            if (item.url === pathname) {
                setOpen(true);
                setSelected(id);
            }
        });
    };

    // menu collapse for sub-levels
    useEffect(() => {
        setOpen(false);
        setSelected(null);
        if (openMini) setAnchorEl(null);
        if (menu.children) {
            menu.children.forEach((item) => {
                if (item.children && item.children.length) {
                    checkOpenForParent(item.children, menu.id);
                }
                if (pathname && pathname.includes('product-details')) {
                    if (item.url && item.url.includes('product-details')) {
                        setSelected(menu.id);
                        setOpen(true);
                    }
                }
                if (item.url === pathname) {
                    setSelected(menu.id);
                    setOpen(true);
                }
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, menu.children]);

    // menu collapse & item
    const menus = menu.children && menu.children.map((item) => {
        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={item.id} menu={item} level={level + 1} parentId={menu.id} drawerOpen={drawerOpen} dispatch={dispatch} location={location} />;
            case 'item':
                return <NavItem key={item.id} item={item} level={level + 1} selectedItem={selectedItem} parentId={menu.id} drawerOpen={drawerOpen} dispatch={dispatch} location={location} />;

            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error4
                    </Typography>
                );
        }
    });

    const isSelected = selected === menu.id;

    const Icon = menu.icon;
    const menuIcon = menu.icon ? (
        <Icon
            strokeWidth={1.5}
            size={drawerOpen ? '20px' : '24px'}
            // style={{ color: isSelected ? 'green' : '#032a37' }}
            sx={{
                ...(drawerOpen &&
                    level === 1 &&
                    theme.palette.mode !== 'dark' && {

                    '&.Mui-selected': {
                        background: '#032a37',
                        color: 'orange',

                    }
                }),

            }}
        />
    ) : (
        <FiberManualRecordIcon
            sx={{
                color: isSelected ? 'red' : 'purple',
                width: isSelected ? 8 : 6,
                height: isSelected ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    const collapseIcon = drawerOpen ? (
        <ExpandLessIcon stroke={1.5} size="16px" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
    ) : (
        <NavigateNextIcon stroke={1.5} size="16px" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
    );

    // const textColor = '#032a37';
    // const iconSelectedColor = '#fff';

    const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary';
    const iconSelectedColor = theme.palette.mode === 'dark' && drawerOpen ? 'text.primary' : 'secondary.main';

    // const popperId = openMini ? `collapse-pop-${menu.id}` : undefined;

    return (
        <>
            <section className={classes.menuItemsWrapper}>
                {layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
                    <>
                        <ListItemButton
                            sx={{
                                zIndex: 1201,
                                borderRadius: `${borderRadius}px`,
                                mb: 0.5,
                                pl: drawerOpen ? `${level * 24}px` : 1.25,
                                ...(drawerOpen &&
                                    level === 1 &&
                                    theme.palette.mode !== 'dark' && {
                                    '&:hover': {
                                        background: "#032a37",
                                        color: "#fff"
                                    },
                                    '&.Mui-selected': {
                                        background: "#032a37",
                                        color: "#fff",
                                        '&:hover': {
                                            background: "#032a37",
                                            color: "#fff"
                                        }
                                    }
                                }),

                        

                                   ...((selectedParentID === menu.id) && {
                                    "& .MuiSvgIcon-root":{
                                      fill: "white"
                                    },
                                    "& .MuiTypography-root ":{
                                      color: "white"
                                    },
                                    "& .MuiButtonBase-root .MuiTypography-root ":{
                                      color: "white"
                                    },
                                   }),


                                ...((!drawerOpen || level !== 1) && {
                                    py: level === 1 ? 0 : 1,
                                    '&:hover': {
                                        bgcolor: '#032a37'
                                    },
                                    '&.Mui-selected': {
                                        '&:hover': {
                                            bgcolor: '#032a37'
                                        },
                                        bgcolor: '#032a37'
                                    }
                                })
                            }}
                            // selected={true}
                            selected={selectedParentID === menu.id}
                            {...(!drawerOpen && { onMouseEnter: handleClickMini, onMouseLeave: handleClosePopper })}
                            onClick={handleClickMini}
                        >
                            {menuIcon && (
                                <ButtonBase sx={{ borderRadius: `${borderRadius}px` }} disableRipple={drawerOpen}>
                                    <ListItemIcon
                                        sx={{
                                            minWidth: level === 1 ? 36 : 18,
                                            color: isSelected ? iconSelectedColor : textColor,
                                            ...(!drawerOpen &&
                                                level === 1 && {
                                                borderRadius: `${borderRadius}px`,
                                                width: 46,
                                                height: 46,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                '&:hover': {
                                                    background: "#032a37",
                                                    color: "#fff"
                                                },
                                                ...(isSelected && {
                                                    background: "#032a37",
                                                    color: "#fff",
                                                    '&:hover': {
                                                        background: "#032a37",
                                                        color: "#fff"
                                                    }
                                                })
                                            }),

                                        }}
                                    >
                                        {menuIcon}
                                    </ListItemIcon>
                                </ButtonBase>

                            )}
                            {(drawerOpen || (!drawerOpen && level !== 1)) && (
                                <ListItemText
                                    primary={
                                        <Typography variant={selected === menu.id ? 'body1' : 'body1'} color="#032a37" sx={{ my: 'auto' }}>
                                            {menu.title}
                                        </Typography>
                                    }
                                    secondary={
                                        menu.caption && (
                                            <Typography
                                                variant="caption"
                                                sx={{ ...theme.typography.subMenuCaption }}
                                                display="block"
                                                gutterBottom
                                            >
                                                {menu.caption}
                                            </Typography>
                                        )
                                    }
                                />

                                // <ListItemText
                                //     primary={
                                //         <Typography variant='body1' color="inherit">
                                //             {menu.title}
                                //         </Typography>
                                //     }
                                //     secondary={
                                //         menu.caption && (
                                //             <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                //                 {menu.caption}
                                //             </Typography>
                                //         )
                                //     }
                                // />
                            )}

                            {openMini || open ? (
                                collapseIcon
                            ) : (
                                drawerOpen && <ExpandMoreIcon stroke={1.5} size="16px" style={{ marginTop: 'auto', marginBottom: 'auto' }} />

                            )}

                            {!drawerOpen && (
                                <PopperStyledMini
                                    open={openMini}
                                    anchorEl={anchorEl}
                                    placement="right-start"
                                    style={{
                                        zIndex: 2001
                                    }}
                                    modifiers={[
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [-12, 0]
                                            }
                                        }
                                    ]}
                                >
                                    {({ TransitionProps }) => (
                                        <Transitions in={openMini} {...TransitionProps}>
                                            <Paper
                                                sx={{
                                                    overflow: 'hidden',
                                                    mt: 1.5,
                                                    boxShadow: theme.shadows[8],
                                                    backgroundImage: 'none'
                                                }}
                                            >
                                                <ClickAwayListener onClickAway={handleClosePopper}>
                                                    <Box>{menus}</Box>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Transitions>
                                    )}
                                </PopperStyledMini>
                            )}
                        </ListItemButton>
                        {drawerOpen && (
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                {open && (
                                    <List
                                        component="div"
                                        disablePadding
                                        sx={{
                                            position: 'relative',
                                            color: 'red',
                                            ':hover': {
                                                color: 'orange',
                                                "& .MuiTypography-root ": {
                                                    color: "red"
                                                },
                                            },
                                            "& .MuiTypography-root ": {
                                                color: "#032a37 !important"
                                            },
                                            '&:after': {
                                                content: "''",
                                                position: 'absolute',
                                                left: '32px',
                                                top: 0,
                                                height: '100%',
                                                width: '1px',
                                                opacity: theme.palette.mode === 'dark' ? 0.2 : 1,
                                                background:
                                                    theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary.light
                                            }
                                        }}
                                    >
                                        {menus}
                                    </List>
                                )}
                            </Collapse>
                        )}
                    </>
                ) : null}
            </section>
        </>
    );
};

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number,
    parentId: PropTypes.string
};

export default NavCollapse;
