import React from 'react'
import { useStyle } from "./Style";

const ResultsBar = (props) => {
  const classes = useStyle();

  return (
    <div className={classes.ResultsBarWrapper}>
      <h2>{props.resultsShowing} results showing for {props.query}</h2>
      </div>
  )
}

export default ResultsBar