import React from 'react';
import { connect } from 'react-redux';
import { hashHistory } from 'react-router';
import Loader from 'react-loader';
import LocalizedStrings from 'react-localization';
import { updateCurrentLanguage } from '../../../lib/languageUtils.es6';
import { localisedText } from '../../../lib/localisation.es6';
import * as actions from './actions.es6';
import { getCustomer } from '../../Customer/actions.es6';
import GroupCustomers from '../../../components/GroupResourceComponents/groupCustomers.es6';


export class Members extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
    };
  }

  componentWillMount() {
    this.updateLanguage();
    let groupId = this.props.params.groupId
    this.props.dispatch(actions.getCurrentGroupMembersRequest(groupId))
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'GroupRegistration',
      this.state.labels
    );
  }

  redirectToCustomerStatusPage(customerGuid) {
    this.props.dispatch(getCustomer(customerGuid));
    hashHistory.push('admin/home'); 
  }

  render() {

    const members = this.props.membersData.members || [];
    return (
      <div>
        <Loader loaded={!this.props.groupRegistrationData.loader} color="#ff834f">
          <div className="row" style={{ width: '98%', margin: "auto" }}>
            {this.props.groupRegistrationData.groupRegistrationError ? this.groupRegisterationError() : ''}
            <div className="col-md-12 col-sm-12 col-xs-12" >
              <div className="" >
                <div className="">
                  <GroupCustomers redirectToCustomerStatusPage={this.redirectToCustomerStatusPage.bind(this)} members={members} />
                  <br />
                </div>
              </div>
              <hr />
            </div>
          </div>
        </Loader>
      </div>
    );
  }
}

export default connect(state => ({
  groupRegistrationData: state.groupRegistration,
  globalData: state.global,
  membersData: state.currentGroup
}))(Members);
