import { take, call, put } from 'redux-saga/effects';
import {
  getCustomerInfo,
  updateCustomer,
  updatePolicy,
  changeLoyaltyRegistration,
  pullCustomerKyc,
} from '../../lib/requests.es6';
import * as actions from './actions.es6';
import * as types from '../../lib/actionTypes.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

export function* getCustomerRequestWatcher() {
  for (;;) {
    const getCustomerActionResponse = yield take(
      types.REFRESH_CUSTOMER_REQUEST
    );
    try {
      // make a get customer request
      const response = yield call(
        getCustomerInfo,
        getCustomerActionResponse.payload
      );
      yield put(actions.changeCurrentCustomer(response.data));
    } catch (e) {
      yield put(actions.getCustomerFailed(e.response.data));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* updateCustomerRequestWatcher() {
  for (;;) {
    const requestAction = yield take(types.UPDATE_CUSTOMER_REQUEST);
    let response = {};
    try {
      response = yield call(updateCustomer, requestAction.payload);
      yield put(actions.updateCustomerSuccess(response.data));
      yield put(actions.getCustomer(requestAction.payload.guid))
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.updateCustomerFail(errorPayload));
    }
  }
}

export function* updatePolicyRequestWatcher() {
  while (true) {
    const requestAction = yield take(types.UPDATE_POLICY_REQUEST);
    let response = {};
    try {
      response = yield call(updatePolicy, requestAction.payload);
      yield put(actions.getCustomer(response.data.customer_guid));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.updatePolicyFail(errorPayload));
    }
  }
}

export function* changeLoyaltyRegistrationWatcher() {
  while (true) {
    const requestAction = yield take(types.CHANGE_LOYALTY_REGISTRATION_REQUEST);
    let response = {};
    try {
      response = yield call(changeLoyaltyRegistration, requestAction.payload);
      yield put(actions.getCustomer(response.data.guid));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.changeLoyaltyRegistrationFail(errorPayload));
    }
  }
}

export function* pullCustomerKycWatcher() {
  while (true) {
    const requestAction = yield take(types.PULL_CUSTOMER_KYC_REQUEST);
    let response = {};
    try {
      response = yield call(pullCustomerKyc, requestAction.payload);
      yield put(actions.pullCustomerKycRequestSuccess(response.data));
    } catch (e) {
      let errorPayload = {};
      if (e.response) {
        errorPayload = e.response.data;
        unAuthorizedNotification(e);
      }
      GAloggerException(e);
      yield put(actions.pullCustomerKycRequestFailure(errorPayload));
    }
  }
}
