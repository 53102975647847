import * as types from '../../../../../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  customerInfo: null,
  loader: false,

  edit_loader: false,
  editModalState: false,
  customer_edited_succesfully: false,
  customer_edited_failed: false,
};

export default function AccessBankRegistrationReducer(state = initialState, action) {
  switch (action.type) {
    case types.REGISTER_ACCESS_BANK_CUSTOMER_REQUEST:
      return { ...state, loader: true, errors: null, customerInfo: null };

    case types.REGISTER_ACCESS_BANK_CUSTOMER_SUCCESS:
      return { ...state, customerInfo: action.payload, loader: false };

    case types.REGISTER_ACCESS_BANK_CUSTOMER_FAIL:
      return { ...state, loader: false, errors: action.payload };

    case types.SET_ACCESS_BANK_REGISTRATION_ERROR:
      return { ...state, loader: false, errors: action.payload };

    case types.RESET_ACCESS_BANK_REGISTRATION:
      return initialState;

    case types.USER_LOGOUT:
      return initialState;


    case types.UPDATE_ACCESS_BANK_CUSTOMER_REQUEST:
      return { ...state, edit_loader: true, errors: null, customer_edited_succesfully: false, customer_edited_failed: false };


    case types.UPDATE_ACCESS_BANK_CUSTOMER_SUCCESS:
      return {
        ...state,
        edit_loader: false,
        editModalState: false,
        customer_edited_succesfully: true,
        customer_edited_failed: false
      };

    case types.UPDATE_ACCESS_BANK_CUSTOMER_FAIL:
      return {
        ...state,
        loader: false,
        edit_loader: false,
        errors: action.payload,
        customer_edited_succesfully: false,
        customer_edited_failed: true
      };

    case types.UPDATE_CUSTOMER_ACCESS_BANK_RESET:
      return {
        ...state,
        loader: false,
        edit_loader: false,
        editModalState: false,
        customer_edited_succesfully: false,
        customer_edited_failed: false
      };

    case types.EDIT_ACCESS_BANK_CUSTOMER_DISPLAY_ERROR:
      return { ...state, errors: action.payload };

    default:
      return state;
  }
}
