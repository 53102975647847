import React, { useState, useEffect } from 'react'
import { useStyle } from "./Style";
import { Box, Grid, Button } from '@mui/material';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { formatDateLocale } from '../../../../../../../lib/utils.es6';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SkeletonWrapper from '../../../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import { getPartner } from '../../../../../../../lib/access.es6';


const Premiums = (props) => {
  const classes = useStyle();

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    props.getPremiums()
  }, [])

  const filteredPremiums = props.premiums.filter(premium => premium.policy_guid === props.policyGuid)

  console.log("filteredPremiums", filteredPremiums)

  const premiumsDisplay = expanded ? filteredPremiums : filteredPremiums.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).slice(0, 2)

  const currencyCode = getPartner().country.currency_code

  if (props.premiumsloader) {
    return <SkeletonWrapper loading={props.premiumsloader} />
  }

  if (props.premiums.length === 0) {
    return <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
        <Grid container spacing={2} className={classes.claimdetailswrapper}>
          <Grid item xs={12} sm={12} md={12} className={classes.ClaimDetailscontent}>
            <p style={{ textAlign: 'center', marginTop: "30px" }}>No details found</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }



  return (
    <>
      {
        premiumsDisplay.map((premium, index) => (
          <>
            <Grid container className={classes.policyWrapper} key={index} >
              <Grid item xs={12} sm={12} md={12}>
                <Grid container className={classes.policyHeader}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Grid container>
                      <Grid item xs={12} md={2} sm={2} className={classes.policyIconWrapper}   >
                        <Box className={classes.policyIcons}>
                          <NoteAddOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={10} sm={10} className={classes.policyHeaderContent}>
                        <h6>{premium.product_name || "N/A"}</h6>
                        <p> Product Name</p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} >

                    <Grid container>
                      <Grid item xs={12} md={6} sm={6}>
                        <div >

                        </div>
                      </Grid>
                      <Grid item xs={12} md={6} sm={6} className={classes.policyActiveStatusWrapper}>
                        <Box display="grid" justifyContent="flex-end">
                          <ul>
                            <li className={premium.active === true ? classes.activePill : classes.inactivePill}> <span>{premium.active === true ? `Active` : `Inactive`}</span></li>
                          </ul>

                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className={classes.policyHeaderBorder}></div>
                </Grid>

                <Grid container spacing={2} className={classes.policyContentWrapper}>
                  <Grid item xs={12} sm={3} md={3} >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3} md={3}></Grid>
                      <Grid item xs={12} sm={9} md={9}>
                        <p>Payment Reference </p>
                        <h6> {premium.reference || "N/A"}</h6>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <p> Created At </p>
                    <h6>{formatDateLocale(premium.created_at) || "N/A"}
                    </h6>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <p>Effected At </p>
                    <h6>{formatDateLocale(premium.effected_at) || "N/A"}</h6>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <p>Updated At </p>
                    <h6>
                      {formatDateLocale(premium.updated_at) || "N/A"}
                    </h6>
                  </Grid>

                </Grid>
                <div className={classes.policyContentBorder}></div>
                <Grid container spacing={2} className={classes.policyFooterWrapper}>
                  <Grid item xs={12} md={6} sm={6}>
                    <Grid container spacing={2} >


                      <Grid item xs={12} md={6} sm={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={3} md={3}></Grid>
                          <Grid item xs={12} sm={9} md={9}>
                            <p>Payment Method</p>
                            <h6>{premium.payment_method || "N/A"}</h6>

                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6} sm={6}>
                        <p> Policy Number</p>
                        <h6>{premium.policy_number || "N/A"}</h6>
                      </Grid>

                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <div className={classes.policyBalance}>
                      <p>Amount</p>
                      <h6>{`${currencyCode} ${premium.amount_in_cents / 100}` || "0.00"}   </h6>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                {
                  premiumsDisplay.length > 1 && (
                    <Button style={{
                      background: "#FBA92D",
                      borderRadius: "8px",
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "15px",
                      boxShadow: "none",
                      padding: "10px",
                      marginTop: "20px",
                      textTransform: "capitalize",
                    }} variant="contained" size="large" endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} onClick={() => setExpanded(!expanded)}>
                      {expanded ? `Show Less` : `Show More`}
                    </Button>
                  )
                }

              </Grid>
            </Grid>
          </>
        ))
      }
    </>
  )

}

export default Premiums