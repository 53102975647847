import React from 'react';
import { deepOrange } from '@material-ui/core/colors'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import * as utils from '../../lib/utils.es6';
import MainPageTitle from '../MainPageTitle/index.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { localisedText } from '../../lib/localisation.es6';
import { refreshClaimRequest } from '../../containers/ClaimsProcessing/actions.es6';
import { SECONDARY_THEME_COLOR_SHADE_1 } from '../../lib/constants';
import { Button } from '@mui/material';
import { hashHistory} from 'react-router';

export class Claims extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
    };
  }

  componentWillMount() {
    this.updateLanguage();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'Claims',
      this.state.labels
    );
  }

  tableHeaders() {
    const { labels } = this.state;
    if (this.props.extended) {
      return (
        <TableRow id="extended-header">
          <TableCell className="table-cell-header">{labels.receivedAt}</TableCell>
          <TableCell className="table-cell-header">#</TableCell>
          <TableCell className="table-cell-header">{labels.status}</TableCell>
          <TableCell className="table-cell-header">{labels.claimType}</TableCell>
          <TableCell className="table-cell-header">{labels.relationToMainMember}</TableCell>
          <TableCell className="table-cell-header">{labels.personAffected}</TableCell>
          <TableCell className="table-cell-header">{labels.startDate}</TableCell>
          <TableCell className="table-cell-header">{labels.endDate}</TableCell>
          <TableCell className="table-cell-header">{labels.eventDate}</TableCell>
        </TableRow>
      );
    }
    return (
      <TableRow id="non-extended-header">
        <TableCell className="table-cell-header">{labels.receivedAt}</TableCell>
        <TableCell className="table-cell-header">#</TableCell>
        <TableCell className="table-cell-header">{labels.status}</TableCell>
      </TableRow>
    );
  }

  viewClaim(claim) {
    if(this.props.claimRefresh){
      this.props.claimRefresh(claim.guid);
    }

    this.props.dispatch(refreshClaimRequest(claim.guid))

    hashHistory.push(`admin/claims_processing/${claim.guid}`);
  }

  getTypeLabel(type, labels) {
    switch (type) {
      case 'Hospital':
        return labels.nightlyBenefit;
      case 'Death':
        return labels.fixedBenefit;
      case 'Funeral':
        return labels.fixedBenefit;
      case 'Cashback':
        return labels.cashback;
      case 'Property':
        return labels.propertyAssistanceBenefit;
      case 'Income_Loss':
        return labels.incomeLossBenefit;
      case 'Personal_Accident':
        return labels.personalAccident;
      case 'Medical_Expense':
        return labels.medicalExpense;
      case 'Inpatient':
        return labels.inpatient;
      case 'Maternity':
        return labels.maternity;
      case 'Outpatient':
        return labels.outpatient;
      default:
        return type;
    }
  }

  tablesRows(claim) {
    if (this.props.extended) {
      const labels = this.state.labels;

      return (
        <TableRow id="extended-data" key={claim.guid || claim.reference}>
          <TableCell>{claim.received_time || claim.submitted_at}</TableCell>
          <TableCell>
            <Button
              style={{ color: deepOrange[400] }}
              onClick={this.viewClaim.bind(this, claim)}
            >
              {' '}
              {claim.guid || claim.reference}
            </Button>
          </TableCell>
          <TableCell>{claim.status ? utils.formatStatus(claim.status) : 'N/A'}</TableCell>
          <TableCell>{this.getTypeLabel(claim.type, labels)}</TableCell>
          <TableCell>{claim.relation_to_main_member}</TableCell>
          <TableCell>{claim.person_affected || 'N/A'}</TableCell>
          <TableCell>{claim.hospital_admission_date || claim.start_date || 'N/A'}</TableCell>
          <TableCell>{claim.hospital_discharge_date || claim.end_date || 'N/A'}</TableCell>
          <TableCell>{claim.death_date || claim.event_date || 'N/A'}</TableCell>
        </TableRow>
      );
    }
    return (
      <TableRow id="non-extended-data" key={claim.guid}>
        <TableCell>{claim.received_time}</TableCell>
        <TableCell>
          <Button
            style={{ color: deepOrange[400] }}
            onClick={this.viewClaim.bind(this, claim)}
          >
            {' '}
            {claim.guid}
          </Button>
        </TableCell>
        <TableCell>{utils.formatStatus(claim.status)}</TableCell>
      </TableRow>
    );
  }

  compareClaims(claimA, claimB) {
    if (
      new Date(claimA.received_time).getTime() ===
      new Date(claimB.received_time).getTime()
    ) {
      return 0;
    }
    if (new Date(claimA.received_time) > new Date(claimB.received_time)) {
      return -1;
    }
    return 1;
  }

  render() {
    const claims = this.props.claims ? this.props.claims : [];
    claims.sort(this.compareClaims);
    const claimsData = claims.map(claim => this.tablesRows(claim));
    const labels = this.state.labels;
    return (
      <div className={`${this.props.columnWidth} col-sm-6 col-xs-12`}>
        <MainPageTitle pageTitle={this.props.title || labels.titleClaims} />

            {claims.length === 0 ? (
              <center>{labels.nothingFound}</center>
            ) : (
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    {this.tableHeaders()}
                  </TableHead>
                  <TableBody className="claims-body">
                    {this.props.showMore ? claimsData.slice(0, 3) : claimsData}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <Button
              id='more_claims'
              href="#admin/claims"
              style={{
                display: utils.displayValue(
                  this.props.showMore && claims.length !== 0
                ),
                backgroundColor: SECONDARY_THEME_COLOR_SHADE_1,
                marginTop: '1%'
              }}
              variant="contained"
              size='small'
            >
              {labels.moreClaims}
            </Button>
      </div>
    );
  }
}

export default connect(state => ({ globalData: state.global }))(Claims);
