import * as types from '../../lib/actionTypes.es6';

export function reportPremiumRegisterRequest(reportType) {
  return {
    type: types.REPORT_PREMIUM_REGISTER_REQUEST,
    payload: reportType,
  };
}

export function reportPremiumRegisterSuccess(reportURL) {
  return {
    type: types.REPORT_PREMIUM_REGISTER_SUCCESS,
    payload: reportURL,
  };
}

export function reportPremiumRegisterFailure(error) {
  return {
    type: types.REPORT_PREMIUM_REGISTER_FAILURE,
    payload: error,
  };
}

export function reportPremiumRegisterReset() {
  return {
    type: types.REPORT_PREMIUM_REGISTER_RESET,
  };
}
