import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
 
    detailstitle: {
        "& p": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "0.005em",
            color: "#000000"
        }
    },
    benefitstitle:{
        marginTop: "20px",
        "& p": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "0.005em",
            color: "#000000"
        }
    },
    quoteinputwrapper: {
        "& p": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
            textAlign: "center",
            marginBottom: "30px"
        },
        "& input": {
            background: "#F7F7F7",
            borderRadius: "8px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.005em",
            color: "#000000",
        },
        "& label": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "12px",
            color: "#000000",
            transformOrigin: "bottom",
        }
    },
    benefitswrapper:{
        "& label": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "12px",
            color: "#000000",
            transformOrigin: "left",

        },
    },
    quotesbtns:{
        "& button:nth-child(1)":{
            background: "#032A37",
            color: "#fff !important",
            borderRadius: "8px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            boxShadow: "none",
            padding: "10px",
            textTransform: "capitalize",
            marginBottom: "30px",
            marginRight: '-2%',
            "&:hover": {
                background: "#032A37",
                boxShadow: "none",
            }
          },
          "& button:nth-child(2)":{
            background: "#fba92c",
            color: "#fff !important",
            borderRadius: "8px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            boxShadow: "none",
            padding: "10px",
            textTransform: "capitalize",
            marginBottom: "30px",
            marginLeft: "30px",
            "&:hover": {
                background: "#fba92c",
                boxShadow: "none",
            }
          }
    },
    createquote:{
      
        "& button":{
            background: "#fba92c",
            color: "#fff !important",
            borderRadius: "8px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            boxShadow: "none",
            padding: "10px",
            textTransform: "capitalize",
            marginBottom: "30px",
            marginLeft: "5px",
            marginTop: "-30px",
            "&:hover": {
                background: "#fba92c",
                boxShadow: "none",
            }
        } 
    }
}));