import React from 'react';
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";


export function PolicyNote(props) {
  const classes = useStyle();
  return <>
    <div className={classes.policynotetitle}>
      <h3><strong>Policy Number: </strong>{props.policy_number}</h3>
    </div>
    <div className={classes.noteswrapper} key={props.key}>
      <p>{props.data.note}</p>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <span><strong>Created by</strong></span>
          <span>{props.data.web_user}</span>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <span><strong>Created at</strong></span>
          <span>{props.data.created_at}</span>
        </Grid>
      </Grid>
      <div className={classes.border}></div>
    </div>
  </>;
}
