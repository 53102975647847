import * as types from '../../../lib/actionTypes.es6';

export function activeItem(payload) {
  return {
    type: types.SET_SELECTED_DRAWER_ITEM,
    payload: payload,
  };
}

export function activeID(payload) {
  return {
    type: types.SET_SELECTED_DRAWER_ID,
    payload: payload,
  };
}


export function openDrawer(payload) {
  return {
    type: types.SET_DRAWER_OPEN,
    payload: payload,
  };
}