import React from 'react';

import { useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from '../MenuList';
import LogoSection from '../LogoSection';
import MiniDrawerStyled from './MiniDrawerStyled';

import LAYOUT_CONST from '../constant';
import useConfig from '../../../hooks/useConfig';
import { drawerWidth } from '../../../store/constant';





// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = (props) => {
    //const classes = useStyles();

    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const {  drawerType } = useConfig();

    const logo = useMemo(
        () => (
            <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                <LogoSection />
            </Box>
        ),
        []
    );

    const drawer = useMemo(
        () => (
            <PerfectScrollbar
                component="div"
                style={{
                    paddingLeft: props.drawerOpen ? '16px' : 0,
                    paddingRight: props.drawerOpen ? '16px' : 0,
                    marginTop: props.drawerOpen ? 0 : '20px'
                }}
            >
                        
                <MenuList 
                    lastItem={null} 
                    drawerOpen={props.drawerOpen} 
                    selectedParentID={props.selectedParentID}  
                    dispatch={props.dispatch} 
                    selectedItem={props.selectedItem}
                    location={props.location}
                    labels={props.labels}
                />
            
            </PerfectScrollbar>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [matchUpMd, props.drawerOpen, drawerType, props.location, props.selectedItem, props.selectedParentID ]
    );

    return (
        <Box className='hhhjkena' component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            {matchDownMd || (drawerType === LAYOUT_CONST.MINI_DRAWER && props.drawerOpen) ? (
                <Drawer
                    variant={matchUpMd ? 'persistent' : 'temporary'}
                    anchor="left"
                    open={props.drawerOpen}
                    onClose={() => props.openDrawer}
                    sx={{
                        zIndex: 0,
                        position: 'revert !important', 
                        '& .MuiDrawer-paper': {
                            mt: matchDownMd ? 0 : 11,
                            zIndex: 1099,
                            width: drawerWidth,
                            background: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            borderRight: 'none'   
                        }
                    }}
                    ModalProps={{ keepMounted: true }}
                    color="inherit"
                >
                    {matchDownMd && logo}
                    {drawer}
                </Drawer>
            ) : (
                <MiniDrawerStyled variant="permanent" open={props.drawerOpen}>
                    {logo}
                    {drawer}
                </MiniDrawerStyled>
            )}
        </Box>
    );
};

export default Sidebar;
