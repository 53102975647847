import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { BENEFIT_NAMES, GRANULARITY } from "../../PoductConstants";
import { InputAdornment } from "@mui/material";


const initialState = {
  name: '',
  amount: '',
  amount_granularity: '',
  maximum_period: '',
  minimum_period: '',
  maximum_period_granularity: '',
  minimum_period_granularity: '',
  maximum_period_applicable_granularity: '',
}
export default function VariableIndemnityForm(props) {
  const [
    { name,
      amount,
      amount_granularity,
      maximum_period,
      maximum_period_granularity,
      maximum_period_applicable_granularity,
      minimum_period,
      minimum_period_granularity,
    }, setState
  ] = React.useState(initialState)

  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const submit = () => {
    const payload = {
      name,
      amount: Number(amount),
      amount_granularity,
      maximum_period: Number(maximum_period),
      maximum_period_granularity,
      maximum_period_applicable_granularity: Number(maximum_period_applicable_granularity),
      minimum_period: Number(minimum_period),
      minimum_period_granularity,
    }
    payload['benefitType'] = 'variable'
    props.addBenefit(payload)
  }

  const reset = () => {
    setState(initialState);
  }


  return (
    <React.Fragment>
      <ValidatorForm onSubmit={() => submit()} >
        <Grid container spacing={2}>
          <Grid item lg={3} md={6} xs={12}><SelectValidator
            value={name}
            label='name'
            id="name"
            name='name'
            onChange={onChange}
            className="form-control"
            type="text"
            variant="outlined"
            validators={['required']}
            errorMessages={['Please select name']}
          >
            <MenuItem value="">{'Please select name'}</MenuItem>
            {
              BENEFIT_NAMES.VARIABLE.map((type) => {
                return <MenuItem value={type}>{type}</MenuItem>
              })
            }
          </SelectValidator></Grid>

          <Grid item lg={3} md={6} xs={12}><TextValidator
            onChange={onChange}
            label='amount'
            name='amount'
            value={amount}
            fullWidth
            variant="outlined"
            type="number"
            placeholder='amount'
            validators={['required']}
            errorMessages={['Please enter amount']}
            InputProps={{
              startAdornment: (<InputAdornment position="start">
              {props.currencyCode}
              </InputAdornment>),

              'aria-label': 'Ksh',
            }}
          /></Grid>


          <Grid item lg={3} md={6} xs={12}><SelectValidator
            label='amount granularity'
            name='amount_granularity'
            value={amount_granularity}
            onChange={onChange}
            className="form-control"
            type="text"
            variant="outlined"
            validators={['required']}
            errorMessages={['Please enter amount granularity']}
          >
            <MenuItem value="">{'Please enter amount granularity'}</MenuItem>
            {
              GRANULARITY.map((type) => {
                return <MenuItem value={type}>{type}</MenuItem>
              })
            }
          </SelectValidator></Grid>


          <Grid item lg={3} md={6} xs={12}><TextValidator
            onChange={onChange}
            label='maximum period'
            name='maximum_period'
            value={maximum_period}
            fullWidth
            variant="outlined"
            type="number"
            placeholder='maximum period'
            validators={['required']}
            errorMessages={['Please enter maximum period']}
          /></Grid>


          <Grid item lg={3} md={6} xs={12}><TextValidator
            onChange={onChange}
            label='minimum period'
            name='minimum_period'
            value={minimum_period}
            fullWidth
            variant="outlined"
            type="number"
            placeholder='minimum period'
            validators={['required']}
            errorMessages={['Please enter minimum period']}
          /></Grid>

          <Grid item lg={3} md={6} xs={12}><SelectValidator
            label='maximum period applicable granularity'
            name='maximum_period_applicable_granularity'
            value={maximum_period_applicable_granularity}
            onChange={onChange}
            className="form-control"
            type="text"
            variant="outlined"
            validators={['required']}
            errorMessages={['Please enter maximum period applicable granularity']}
          >
            <MenuItem value="">{'Please enter maximum period applicable granularity'}</MenuItem>
            {
              GRANULARITY.map((type) => {
                return <MenuItem value={type}>{type}</MenuItem>
              })
            }
          </SelectValidator></Grid>


          <Grid item lg={3} md={6} xs={12}><SelectValidator
            label='maximum period granularity'
            name='maximum_period_granularity'
            value={maximum_period_granularity}
            onChange={onChange}
            className="form-control"
            type="text"
            variant="outlined"
            validators={['required']}
            errorMessages={['Please enter maximum period granularity']}
          >
            <MenuItem value="">{'Please enter maximum period granularity'}</MenuItem>
            {
              GRANULARITY.map((type) => {
                return <MenuItem value={type}>{type}</MenuItem>
              })
            }
          </SelectValidator></Grid>

          <Grid item lg={3} md={6} xs={12}><SelectValidator
            label='minimum period granularity'
            name='minimum_period_granularity'
            value={minimum_period_granularity}
            onChange={onChange}
            className="form-control"
            type="text"
            variant="outlined"
            validators={['required']}
            errorMessages={['Please enter minimum period granularity']}
          >
            <MenuItem value="">{'Please enter minimum period granularity'}</MenuItem>
            {
              GRANULARITY.map((type) => {
                return <MenuItem value={type}>{type}</MenuItem>
              })
            }
          </SelectValidator></Grid>

          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button variant="contained" type="submit" style={{ marginRight: '2%' }}> Save </Button>
            <Button variant="contained" color="secondary" style={{ marginRight: '2%' }} onClick={() => reset()}> Reset</Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </React.Fragment>
  );
}
