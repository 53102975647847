import GAlogger, { monitoringEventCategories } from '../../lib/monitoring.es6';

export function payableClaimsRequest() {
  return {
    type: 'PAYABLE_CLAIM_REQUEST',
  };
}

export function payableClaimsSuccess(payload) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.fetchPayableClaims);
  return {
    type: 'PAYABLE_CLAIM_SUCCESS',
    payload,
  };
}

export function payableClaimsFail(payload) {
  return {
    type: 'PAYABLE_CLAIM_FAIL',
    payload,
  };
}
