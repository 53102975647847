import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import './CountriesTable.css'
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../lib/constants";

export default function CountriesTable(props) {
  if (props.countries && props.countries.length > 0) {
    return (
      <>
        <div style={{ marginTop: "2%" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ color: 'red' }}>
                <TableRow >
                  <TableCell className="table-cell-header">Name</TableCell>
                  <TableCell className="table-cell-header">Code</TableCell>
                  <TableCell className="table-cell-header">Currency name</TableCell>
                  <TableCell className="table-cell-header">Currency code</TableCell>
                  <TableCell className="table-cell-header">Date format</TableCell>
                  <TableCell className="table-cell-header">Timezone</TableCell>
                  <TableCell className="table-cell-header" align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.countries.map((country) => {
                  return (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left"> {country.name}</TableCell>
                      <TableCell align="left"> {country.code}</TableCell>
                      <TableCell align="left"> {country.currency_name}</TableCell>
                      <TableCell align="left"> {country.currency_code}</TableCell>
                      <TableCell align="left"> {country.date_format_pattern}</TableCell>
                      <TableCell align="left"> {country.time_zone}</TableCell>
                      <TableCell align="right">
                        <Stack direction="row" spacing={1} justifyContent="flex-end" >
                          <IconButton onClick={() => { props.setCountryToEdit(country) }} >
                            <EditIcon
                              style={{ color: PRIMARY_THEME_COLOR_SHADE_1, fontWeight: 'bolder', display: props.displayEditButton ? 'inline-block' : 'none' }}
                            />
                          </IconButton>
                          <IconButton onClick={() => { props.hardDeleteCountry(country) }} >
                            <DeleteForeverIcon
                              style={{ color: 'red', fontWeight: 'bolder', marginRight: "2%", display: props.displayDeleteButton ? 'inline-block' : 'none' }}
                            />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
    );
  }
  return "No countries found, click on the add button to add new country."
}
