import React from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import * as actions from './actions.es6';
import { localisedText } from '../../lib/localisation.es6';
import { downloadBlobReports, formatDate, displayFieldValidationErrors } from '../../lib/utils.es6';
import * as globalActions from '../Main/actions.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { availableFormat } from '../../lib/reportUtils.es6';

export class PremiumReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      start_date: null,
      end_date: null,
      labels: new LocalizedStrings(localisedText),
      errorList: []
    };
  }

  componentWillMount() {
    this.props.dispatch(globalActions.hideCustomerBar());
    this.updateLanguage();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'Reports',
      this.state.labels
    );
  }

  getReport() {
    const type = document.getElementById('type').value;
    const { start_date, end_date, labels } = this.state

    const errorList = []

    if (!start_date) {
      errorList.push(labels.pleaseSelectStartDate)
    }

    if (!end_date) {
      errorList.push(labels.pleaseSelectEndDate)
    }

    this.setState({ errorList })

    if (errorList.length !== 0) {
      return;
    }
    const payload = {
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      type,
    };
    this.props.dispatch(actions.reportRequest(payload));
  }

  selectPartner(partner) {
    this.props.dispatch(globalActions.setCurrentPartner(partner));
  }

  getMinimumDate() {
    return moment().subtract(3, 'months');
  }

  dateHandler(type, moment) {
    if (type === 'start_date') {
      this.setState({ start_date: formatDate(new Date(moment)) });
    } else if (type === 'end_date') {
      this.setState({ end_date: formatDate(new Date(moment)) });
    }
    this.resetReport();
  }

  resetReport() {
    this.props.dispatch(actions.reportRequestReset());
  }

  render() {
    const labels = this.state.labels;
    const fileName = `premium-report-${this.state.start_date}-${
      this.state.end_date
      }.${
      document.getElementById('type')
        ? document.getElementById('type').value
        : ''
      }`;
    return (
      <div>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_title">
                <h2>{labels.premiumReport}</h2>
                <p>{labels.premiumReportDurationText}</p>
                <div className="clearfix" />
              </div>
              <div className="x_content">
                <br />
                <div className="row form-group">
                  <label
                    className="control-label col-md-4 col-sm-4 col-xs-12"
                    htmlFor="start_date"
                  >
                    {labels.startDate}
                    <span>*</span>
                  </label>
                  <div className="col-md-2 col-sm-2 col-xs-12">
                    <DatePicker
                      minDate={this.getMinimumDate()}
                      value={this.state.start_date}
                      onChange={this.dateHandler.bind(this, 'start_date')}
                      format="YYYY-MM-DD"
                      placeholder="start date"
                    />
                    {displayFieldValidationErrors(this.state.errorList, labels.pleaseSelectStartDate)}
                  </div>
                </div>

                <div className="row form-group">
                  <label
                    className="control-label col-md-4 col-sm-4 col-xs-12"
                    htmlFor="end_date"
                  >
                    {labels.endDate}
                    <span>*</span>
                  </label>
                  <div className="col-md-2 col-sm-2 col-xs-12">
                    <DatePicker
                      minDate={this.getMinimumDate()}
                      value={this.state.end_date}
                      onChange={this.dateHandler.bind(this, 'end_date')}
                      format="YYYY-MM-DD"
                      placeholder="end date"
                    />
                    {displayFieldValidationErrors(this.state.errorList, labels.pleaseSelectEndDate, "")}
                  </div>
                </div>

                <div className="row form-group">
                  <label
                    className="control-label col-md-4 col-sm-4 col-xs-12"
                    htmlFor="type"
                  >
                    {labels.reportType}
                    <span>*</span>
                  </label>
                  <div className="col-md-2 col-sm-2 col-xs-12">
                    <select
                      id="type"
                      className="form-control"
                      onChange={this.resetReport.bind(this)}
                    >
                      {availableFormat().map((format, index) => (
                        <option key={index} value={format}>
                          {format.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-sm-offset-4 col-sm-5">
                  <button
                    className="btn btn-primary"
                    onClick={this.getReport.bind(this)}
                  >
                    {labels.generateReport}
                  </button>
                </div>
                <br />
                {this.props.reportsData.loader ? (
                  <div className="col-sm-offset-4 col-sm-1">
                    <center>
                      <CircularProgress />
                    </center>
                  </div>
                ) : (
                    <div />
                  )}
                {this.props.reportsData.reportBlob ? (
                  <div className="report-url">
                    <br />
                    <br />
                    <div className="col-sm-offset-4 col-sm-5">
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          downloadBlobReports(
                            this.props.reportsData.reportBlob,
                            fileName
                          )
                        }
                      >
                        {labels.downloadReport}
                      </button>
                    </div>
                  </div>
                ) : (
                    <div />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  reportsData: state.reports,
  globalData: state.global,
}))(PremiumReports);
