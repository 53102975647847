import React from "react";
import { connect } from "react-redux";
import Policies from "./Components/Policies/Policies";
import { getAllPoliciesRequest } from "../../Redux/actions";


class AccessBankPolicies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentWillMount() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      this.props.dispatch(getAllPoliciesRequest({ guid: currentCustomer.guid }))
    }
  }



  render() {

    return (
      <>

        <Policies
          loader={this.props.AccessBankPolicyData.get_policies_loader}
          policies={this.props.AccessBankPolicyData.policies}
        />
      </>
    )


  }
}

export default connect((state) => ({
  customerData: state.currentCustomer,
  documentGenerationData: state.documentGeneration,
  AccessBankPolicyData: state.AccessBankPolicyData
}))(AccessBankPolicies);