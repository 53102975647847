import * as React from "react";
import Grid from "@mui/material/Grid";
import { Button, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AlertDialog from "../../../../../components/AlertDialog/index.es6";
import CashBackIndemnityForm from "../../AddProduct/CashBackIndemnityForm";
import VariableIndemnityForm from "../../AddProduct/VariableIndemnityForm";
import FixedIndemnityForm from "../../AddProduct/FixedIndemnityForm";
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../../../lib/constants";

export default function AddBenefits(props) {
  const [type, setType] = React.useState('cashback')


  const handleBenefitCreatedSuccessfully = () => {
    props.resetAddBenefitSuccessAlert()
    props.closeAddBenefitDialog()
    props.refreshProduct();
  }

  const handleBenefitCreateError = () => {
    props.resetAddBenefitErrorAlert()
  }

  return (
    <React.Fragment>

      <AlertDialog
        custom
        show={props.showAddBenefitProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Adding benefit</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showAddBenefitSuccessAlert}
        size="sm"
        title={'Benefit added'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleBenefitCreatedSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => { handleBenefitCreatedSuccessfully() }} style={{ background: 'green', }}>Ok</Button>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showAddBenefitErrorAlert}
        danger
        title={'Error adding benefit'}
        onConfirm={() => handleBenefitCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.createBenefitError ? props.createBenefitError.message : ''}
      </AlertDialog>



      <Dialog
        open={props.openAddBenefitDialog} fullWidth
        maxWidth='lg'
        onClose={props.closeAddBenefitDialog}
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.closeAddBenefitDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Benefit
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div style={{ marginTop: "2%" }}>
            <Box sx={{ width: "100%" }}>
              <Grid container >
                <Grid item md={4} xs={12} style={{ textAlign: 'center' }}>
                  <label style={{ margin: '3% 0%' }}>Select benefit type:</label>
                </Grid>
                <Grid item md={8} xs={12} style={{ textAlign: 'left' }}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(e) => { setType(e.target.value) }}
                      value={type}
                    >
                      <FormControlLabel value="cashback" name="cashback" control={<Radio />} label="Cashback Indemnity" />
                      <FormControlLabel value="fixed" name="fixex" control={<Radio />} label="Fixed Indemnity" />
                      <FormControlLabel value="variable" name="variable" control={<Radio />} label="Variable Indemnity" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <hr />
              </Grid>

              {
                (type === 'cashback') &&
                <CashBackIndemnityForm
                  hardDeleteBenefit={props.hardDeleteBenefit}
                  addBenefit={props.addBenefit}
                />
              }
              {
                (type === 'variable') &&
                <VariableIndemnityForm
                  hardDeleteBenefit={props.hardDeleteBenefit}
                  addBenefit={props.addBenefit}
                  currencyCode ={props.currencyCode}
                />
              }
              {
                (type === 'fixed') &&
                <FixedIndemnityForm
                  hardDeleteBenefit={props.hardDeleteBenefit}
                  addBenefit={props.addBenefit}
                  currencyCode ={props.currencyCode}
                />
              }

            </Box>
          </div>

        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
