import React, { useState, useEffect } from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import MenuItem from '@mui/material/MenuItem';
import { InputAdornment } from '@mui/material';
import { getLocalNumberLength,getPartner } from '../../../../../lib/access.es6';

const ClaimLoggingDetails = (props) => {
  const classes = useStyle();

  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [relationship, setRelationship] = useState(null)
  const [member, setMember] = useState('');

  const members = props.eventQuote.policy_schedule_entries ? props.eventQuote.policy_schedule_entries : []

  useEffect(() => {

    ValidatorForm.addValidationRule('isValidPhoneNumber', value => {
      return value.length === getLocalNumberLength();
    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidPhoneNumber');
    };
  }, [])

  const internationalCallingCode = getPartner().country.international_calling_code

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.headingclaim}>
            <h4>Claimant details?</h4>
          </div>
        </Grid>
      </Grid>
      <ValidatorForm onSubmit={() => { }}>
        <Grid container spacing={2} className={classes.selectpolicyWrapper}>

          <Grid item xs={12}>
            <SelectValidator
              labelId="select-member"
              id="select-member"
              value={member}
              name='selectmember'
              type="text"
              label="Select from policy member"
              onChange={(e) => {

                const fullPhoneNumber = e.target.value.dependant.msisdn
                const n = e.target.value.dependant.first_name + " " + e.target.value.dependant.middle_name + " " + e.target.value.dependant.last_name
                const i = e.target.value.dependant.national_id;
                const r = e.target.value.dependant.relationship === 'Principal' ? 'Self' : e.target.value.dependant.relationship
                
                setMember(e.target.value)
                setName(e.target.value.dependant.first_name + " " + e.target.value.dependant.middle_name + " " + e.target.value.dependant.last_name)
                setIdNumber(e.target.value.dependant.national_id)
          
                props.setAdditionalState('claimant_full_name', n)

                
                const nineDigit = fullPhoneNumber.substring(5, fullPhoneNumber.length)

                props.setAdditionalState('claimant_phone_number', nineDigit)
                setPhoneNumber(nineDigit)

                props.setAdditionalState('claimant_id_number', i)
                props.setAdditionalState('claimant_relationship_to_patient', r)
                setRelationship(r)
              }}

              variant="outlined"
              validators={['required']}
              errorMessages={['Please select a member']}
              style={{ width: "100%" }}
              sx={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.005em",
                color: "#000000",
              }}
            >

              {
                members.map((entry, index) => (
                  <MenuItem
                    key={index}
                    sx={{
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: 300,
                      fontSize: "12px",
                      lineHeight: "16px",
                      letterSpacing: "0.005em",
                      color: "#000000",
                    }}
                    value={entry}>{entry.dependant.first_name + " " + entry.dependant.last_name}</MenuItem>
                ))
              }

            </SelectValidator>
            <hr />

          </Grid>


          <Grid item xs={12} md={6} sm={6}>
            <TextValidator
              id="name"
              style={{ width: "100%" }}
              label="Full Names"
              name='name'
              value={name}
              onChange={(e) => {
                setName(e.target.value)
                props.setAdditionalState('claimant_full_name', e.target.value)
              }}
              variant="outlined"
              validators={['required']}
              errorMessages={['Please enter you full names']}
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <TextValidator
              id="phone"
              style={{ width: "100%" }}
              label="Phone"
              name='phone'
              value={phoneNumber}
              onChange={(e) => { 
                setPhoneNumber(e.target.value)
                props.setAdditionalState('claimant_phone_number', "00254"+e.target.value)
              }}
              InputProps={{
                startAdornment: <InputAdornment
                  sx={{
                    fontSize: "small",
                    color: "#000",
                    background: "#F7F7F7"
                  }}
                  position="start"
                > <span style={{
                  fontSize: "small",
                  color: "#000"
                }}>{internationalCallingCode ? internationalCallingCode : ''}</span></InputAdornment>,

              }}
              variant="outlined"
              validators={["isValidPhoneNumber"]}
              errorMessages={[`Please enter a valid ${getLocalNumberLength()} digit phone number`]}
            />
          </Grid>

          <Grid item xs={12} md={6} sm={6}>
            <TextValidator
              id="id"
              style={{ width: "100%" }}
              label="Id number"
              name='id'
              value={idNumber}
              onChange={(e) => { 
                setIdNumber(e.target.value)
                props.setAdditionalState('claimant_id_number', e.target.value)

              }}
              variant="outlined"
              validators={['required']}
              errorMessages={['Please enter you id number']}
            />
          </Grid>

          <Grid item xs={12} md={6} sm={6}>

            <SelectValidator
              labelId="select-relationship"
              id="select-relationship"
              value={relationship}
              label="Relationship"
              name='relationships'
              onChange={(event) => {
                setRelationship(event.target.value)
                props.setAdditionalState('claimant_relationship_to_patient', event.target.value)
              }}
              style={{ width: "100%" }}
              sx={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.005em",
                color: "#000000",
              }}
              variant="outlined"
              InputLabelProps={{
                shrink: !!relationship,
              }}
            >
              {
                ['Self', 'Spouse', 'Son', 'Daughter'].map((relationship, index) => {
                  return <MenuItem
                    key={index}
                    sx={{
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: 300,
                      fontSize: "12px",
                      lineHeight: "16px",
                      letterSpacing: "0.005em",
                      color: "#000000",
                    }}
                    //disabled={principalAdded}
                    value={relationship}>
                    {(relationship)}</MenuItem>
                })
              }
            </SelectValidator>
          </Grid>

        </Grid>
      </ValidatorForm>
    </>
  )
}

export default ClaimLoggingDetails