import React from 'react'
import DependantsCardInc from '../../../../../shared-ui/DependantsCardInc'
const Dependants = (props) => {


  return (
    <>
      < DependantsCardInc
        data={props.policy}
      />


    </>

  )

}

export default Dependants