import * as types from '../../../lib/actionTypes.es6'

export const initialState = {

  get_elastic_search_errors: null,
  get_elastic_search_loader: false,
  get_elastic_search_success_message: '',
  elastic_search_results: [],


};

export default function elasticSearchReducer(state = initialState, action) {
  switch (action.type) {

    case types.GET_ELASTIC_SEARCH_REQUEST:
      return { ...state, get_elastic_search_loader: true, get_elastic_search_errors: null,  elastic_search_results: [] };
    case types.GET_ELASTIC_SEARCH_SUCCESS:
      return { ...state, get_elastic_search_loader: false, get_elastic_search_errors: null, elastic_search_results: action.payload };
    case types.GET_ELASTIC_SEARCH_FAILURE:
      return { ...state, get_elastic_search_loader: false, get_elastic_search_errors: action.payload };

    default:
      return state;
  }
}
