import { take, call, put } from 'redux-saga/effects';
import * as types from '../../lib/actionTypes.es6';
import {
  getCountries,
  createCountry,
  updateCountry,
  deleteCountry,
} from '../../lib/requests.es6';
import {
  createCountryFail,
  createCountrySuccess,
  getCountriesFail,
  getCountriesSuccess,
  updateCountrySuccess,
  updateCountryFail,
  deleteCountryFail,
  deleteCountrySuccess,
} from './actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { GAloggerException } from '../../lib/monitoring.es6';

export function* getCountriesRequestWatcherSaga() {
  while (yield take(types.GET_COUNTRIES_REQUEST)) {
    // TODO: There should be no need of an infinite loop here. We can listen for a specific action
    try {
      const response = yield call(getCountries);
      yield put(getCountriesSuccess(response.data));
    } catch (e) {
      yield put(
        getCountriesFail(
          'An error occurred, this could be due to invalid data '
        )
      );
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* createCountryRequestWatcherSaga() {
  for (;;) {
    try {
      const action = yield take(types.CREATE_COUNTRY_REQUEST);
      yield call(createCountry, action.payload);
      yield put(createCountrySuccess(action.payload));
    } catch (e) {
      yield put(createCountryFail(e.response.data));
      unAuthorizedNotification(e);
    }
  }
}

export function* updateCountryRequestWatcherSaga() {
  for (;;) {
    try {
      const action = yield take(types.UPDATE_COUNTRY_REQUEST);
      yield call(updateCountry, action.payload);
      yield put(updateCountrySuccess(action.payload));
    } catch (e) {
      yield put(updateCountryFail(e.response.data));
      unAuthorizedNotification(e);
    }
  }
}

export function* deleteCountryRequestWatcherSaga() {
  for (;;) {
    try {
      const action = yield take(types.DELETE_COUNTRY_REQUEST);
      yield call(deleteCountry, action.payload);
      yield put(deleteCountrySuccess(action.payload));
    } catch (e) {
      yield put(deleteCountryFail(e.response.data));
      unAuthorizedNotification(e);
    }
  }
}
