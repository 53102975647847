import React, { useState } from 'react'
import { useStyle } from "./Style";
import Button from '@mui/material/Button';
import Grid from "@material-ui/core/Grid";


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';

import DropzoneS3Uploader from "react-dropzone-s3-uploader";
import { getS3BucketSignedUrlForProofOfPaymentsDocuments, updatePayment } from '../../../../../../../lib/requests.es6';
import ProgressState from './ProgressState';



const MakePayment = (props) => {
  const [paymentAmount, setPaymentAmount] = useState('');
  const [paymentrefrence, setPaymentRefrence] = useState('');
  const [effectedat, setEffectedAt] = useState('');
  const [policy, setPolicy] = useState('');
  const [policyguid, setPolicyguid] = useState('');
  const [policyNumber, setPolicyNUmber] = useState('')

  const [error, setError] = useState('')

  const [s3Url] = useState('https://payment-documents.s3.amazonaws.com');
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null)
  const [done, setDone] = useState(false)

  const handleFinishedUpload = async (info) => {

    const payload = {
      policy_guid: policy.guid,
      description: 'Proof Of Payment Document',
      filename: 'Proof Of Payment Document',
      url: info.fileUrl.replace('undefined', info.bucketKey),
    }

    const updatePayload = {
      "proof_of_payment_url": payload.url,
      "paymentGuid": localStorage.getItem('payment_guid')
    }
    setError('')
    await updatePayment(updatePayload)
    setDone(true)

  }

  const getS3Ur = (file, callback) => getS3BucketSignedUrlForProofOfPaymentsDocuments({
    "policy_guid": policyguid,
    "amount_in_cents": Number(paymentAmount) * 100,
    "mno_reference": paymentrefrence,
    "status": "Succeeded",
    "channel": "WebClient",
    "effected_at": effectedat,
    "additional_details": {
      "policy_number": policyNumber
    }

  }, file, callback)


  const uploadOptions = {
    getSignedUrl: getS3Ur,
    s3Url: s3Url,
    uploadRequestHeaders: {},
    acceptedFiles: "application/pdf",
    onProgress: (progress) => setProgress(progress),
    onError: (errorMessage) => setErrorMessage(errorMessage),

  };

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === "paymentamount") {
      setPaymentAmount(value);
    }
    if (name === "paymentrefrence") {
      setPaymentRefrence(value);
    }
    if (name === "effectedat") {
      setEffectedAt(value);
    }
    if (name === "policy") {
      console.log("policy", value)
      setPolicy(value);
      setPolicyguid(value.guid)
      setPolicyNUmber(value.policy_number
        )
    }

  }

  const reset = () => {
    setPaymentAmount('');
    setPaymentRefrence('');
    setEffectedAt('');
    setPolicy('');
    window.location.reload();
  }





  const classes = useStyle();
  const filteredPolicies = props.policies.filter(policy => policy.active === true)

  const hasAllfieldsFilled = paymentAmount && paymentrefrence && policy && effectedat
  return (
    <>

      <section className={classes.makepaymentdialog}>
        <Dialog open={props.openAddPaymentDialog} onClose={props.toggleAddPaymentDialog} fullWidth>
          <ValidatorForm onSubmit={() => { }} >
            <DialogTitle className={classes.paymentmodaltitle}>
              <div >
                <h3>Add  payment</h3>
              </div>
            </DialogTitle>
            <DialogContent className={classes.paymentinputwrapper}>
              <DialogContentText>
                Fill the below details to add  a payment
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextValidator
                    name='paymentamount'
                    id="payment-amount"
                    label="Payment Amount"
                    value={paymentAmount}
                    onChange={handleChange}
                    fullWidth
                    className={classes.paymentinputs}
                    variant="outlined"
                    validators={['minNumber:1']}
                    errorMessages={['Please enter a valid payment amount']}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextValidator
                    name='paymentrefrence'
                    id="payment-reference"
                    label="Payment Reference"
                    value={paymentrefrence}
                    onChange={handleChange}
                    fullWidth
                    className={classes.paymentinputs}
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Please enter payment reference']}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextValidator
                    name='effectedat'
                    id="effected-date"
                    label="Effected At"
                    value={effectedat}
                    onChange={handleChange}
                    fullWidth
                    type='date'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Please enter effected date']}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>

                  <SelectValidator
                    name='policy'
                    labelId="select-policy"
                    id="select-policy"
                    value={policy}
                    label="Policy"
                    onChange={handleChange}
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Please select a policy']}
                    style={{ width: "100%" }}
                  >
                    {
                      filteredPolicies.map((policy, index) => {
                        return <MenuItem
                          sx={{
                            fontFamily: "Open Sans",
                            fontStyle: "normal",
                            fontWeight: 300,
                            fontSize: "12px",
                            lineHeight: "16px",
                            letterSpacing: "0.005em",
                            color: "#000000",
                          }}
                          key={index}
                          value={policy}>
                          {policy.policy_number}
                        </MenuItem>
                      })
                    }

                  </SelectValidator>

                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <div className={classes.uploadsection}>
                    {error && <p style={{ color: 'red' }}>{error}</p>}

                    <DropzoneS3Uploader
                      onFinish={handleFinishedUpload}
                      upload={uploadOptions}
                      s3Url={s3Url}
                      passChildrenProps={false}
                      maxSize={1024 * 1024 * 5}
                      className={classes.uploadzone}
                      {...(!hasAllfieldsFilled && { onDrop: () => setError('please fill out all other details before  uploading ') })}
                      style={{
                        width: "100%",
                        height: "200px",
                        background: "#FFFFFF",
                        border: "1px dashed #B8B8B8",
                        padding: " 55px 0"
                      }}
                    >
                      <div >
                        <ProgressState progress={progress} errorMessage={errorMessage} />

                      </div>
                    </DropzoneS3Uploader>
                  </div>
                </Grid>
              </Grid>

            </DialogContent>
            <DialogActions className={classes.paymentbtns}>
              <Button variant="contained" disabled={!done} onClick={reset}>I'm Done</Button>
              <Button variant="outlined" onClick={props.toggleAddPaymentDialog}>Cancel</Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </section>
    </>
  )
}

export default MakePayment