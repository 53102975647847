import React, { useEffect, useState } from 'react'
import { hashHistory } from 'react-router';
import { useStyle } from "./Style";
import ModalInc from '../../../../../shared-ui/ModalInc'
import ButtonInc from '../../../../../shared-ui/ButtonInc'
import moment from 'moment';
import find from 'lodash/find';

import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import MenuItem from '@mui/material/MenuItem';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { getPartnerGuid, getUserFullname, getUserGuid } from '../../../../../lib/access.es6';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress, FormHelperText } from '@mui/material';
import debounce from 'debounce-promise'
import { searchHospitals, searchDiagnosis, getBenefitUsageBalances } from '../../../../../lib/requests.es6';




const CreateLOU = (props) => {


  const classes = useStyle();

  const [openHospitals, setOpenHospitals] = useState(false);
  const [loadingHospitals, setHospitalLoading] = useState(false);
  const [inputHospitalValue, setInputHospitalValue] = useState('');

  const [openDiagnosis, setOpenDiagnosis] = useState(false);
  const [loadingDiagnosis, setDiagnosisLoading] = useState(false);
  const [inputDiagnosisValue, setInputDiagnosisValue] = useState('');

  const [policy, setPolicy] = useState('');
  const [member, setMember] = useState('');
  const [currentBenefit, setCurrentBenefit] = useState({});
  const [benefitType, setBenefitType] = useState('');
  const [hospital, setHospital] = useState(null);
  const [hospitals, setHospitals] = useState([]);
  const [hospitalError, setHospitalError] = useState(false);
  const [diagnosisError, setDiagnosisError] = useState(false);

  const [admissionDate, setAdmissionDate] = useState('');
  const [dischargeDate, setDischargeDate] = useState('');
  const [diagnosisSearchResult, setDiagnosisSearchResult] = useState([]);
  const [diagnosis, setDiagnosis] = useState(null);
  const [condition, setCondition] = useState('')
  const [conditionError, setConditionError] = useState(false);
  const [numberOfdays, setNumberOfDays] = useState('');
  const [surgicalAmount, setSurgicalAmount] = useState();
  const [rateperday, setRatePerday] = useState('');

  const [benefitUsageBalances, setBenefitUsageBalances] = useState(null)
  const [errorFetchingBenefitUsage, setErrorFetchingBenefitUsage] = useState(false)
  
  const [loadingBenefitBalances, setLoadingBenefitBalances] = useState(false)

  const debounceHospitalSearch = debounce(searchHospitals, 3000)

  const fetchHospitals = async (query) => {
    setHospitalLoading(true);
    try {
      const response = await debounceHospitalSearch({ query });
      setHospitals(response.data);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
    setHospitalLoading(false);
  };

  const handleHospitalInputChange = (event, newValue) => {
    setInputHospitalValue(newValue);
    fetchHospitals(newValue);
  };
  const handleCreateHospital = () => {
    hashHistory.push('admin/create-hospital');
  }

  const handleCreateDiagnosis = () =>{
    hashHistory.push('admin/create-Diagnosis');
  }

  const debounceDiagnosisSearch = debounce(searchDiagnosis, 3000)

  const fetchBenefitUsageBalance = async (selectedMember) => {
    setLoadingBenefitBalances(true);
    try {
      const response = await getBenefitUsageBalances({ dependant_guid: selectedMember.dependant.guid });
      if(isEmptyBenefitUsage(response.data)){
        setErrorFetchingBenefitUsage(true)
      }else{
        setBenefitUsageBalances(response.data);
        setCurrentBenefit({})
        setBenefitType('')
        setErrorFetchingBenefitUsage(false)
      }

    } catch (error) {
      console.error('Error fetching benefit balances:', error);
    }
    setLoadingBenefitBalances(false);
  };

  const isEmptyBenefitUsage = (response) => !response.hasOwnProperty("DAILY HOSPITAL BENEFIT") || !response.hasOwnProperty("ADDITIONAL SURGERY BENEFIT")


  const fetchDiagnosis = async (query) => {
    setDiagnosisLoading(true);
    try {
      const response = await debounceDiagnosisSearch({ query });
      setDiagnosisSearchResult(response.data);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
    setDiagnosisLoading(false);
  };

  const handleDiagnosisInputChange = (event, newValue) => {
    setInputDiagnosisValue(newValue);
    fetchDiagnosis(newValue);
  };

  useEffect(() => {

    if (benefitType) {

      const memberHospitalBenefit = find(member.benefits, {"type": "DAILY HOSPITAL BENEFIT"})
      console.log('memberHospitalBenefit', memberHospitalBenefit);
      const hospital = {
        ...benefitUsageBalances["DAILY HOSPITAL BENEFIT"],
        yearly_total: benefitUsageBalances["DAILY HOSPITAL BENEFIT"].remaining_amount_in_cents/100,
        remaining_amount_in_cents: (memberHospitalBenefit.cover_amount_in_cents/memberHospitalBenefit.cover_usage_count)/100,
        type: "DAILY HOSPITAL BENEFIT",
      }
      const surgery = {
        ...benefitUsageBalances["ADDITIONAL SURGERY BENEFIT"],
        remaining_amount_in_cents: benefitUsageBalances["ADDITIONAL SURGERY BENEFIT"].remaining_amount_in_cents / 100,
        type: "ADDITIONAL SURGERY BENEFIT",
      }

      if (benefitType === 'HOSPITAL + SURGERY') {
        setCurrentBenefit({ hospital, surgery })
      } else if (benefitType === 'HOSPITAL') {
        setCurrentBenefit({ hospital })
      } else if (benefitType === 'SURGERY') {
        setCurrentBenefit({ surgery })
      }
    }

  }, [benefitType])

  const findLouWithSameAdmissionDate = (lous, date) => {
    for(let lou of lous){
      if(lou.hospital_details.hospital_admission_date === date) return true
    }
    return false
  }
  
  useEffect(() => {
    ValidatorForm.addValidationRule('hasAdmissionDateFirst', () => {
      return admissionDate !== ''
    });

    ValidatorForm.addValidationRule('hasLouWithSameAdmissionDate', (admissionDate) => {
      const louWithSameAdmissionDate = findLouWithSameAdmissionDate(props.lous, admissionDate);
      return !louWithSameAdmissionDate
    });


    ValidatorForm.addValidationRule('numberOfDaysDoesNotExceedBalance', (value) => {
      return exceedsHospitalDays()
    });

    ValidatorForm.addValidationRule('dischargeDateIsAfterAdmissionDate', (value) => {
      if (!admissionDate || !dischargeDate) return true;
      return moment(dischargeDate).isAfter(moment(admissionDate));
    });

    updateNumberOfDays();

    return function cleanup() {
      ValidatorForm.removeValidationRule('hasAdmissionDateFirst');
      ValidatorForm.removeValidationRule('numberOfDaysDoesNotExceedBalance');
      ValidatorForm.removeValidationRule('dischargeDateIsAfterAdmissionDate');
      ValidatorForm.removeValidationRule('hasLouWithSameAdmissionDate');
    };
  }, [admissionDate, dischargeDate])
  

  useEffect(() => {
    const daily_hospital_rate = currentBenefit ? (currentBenefit.hospital ? currentBenefit.hospital.remaining_amount_in_cents : 0) : 0;
    ValidatorForm.addValidationRule('dailyRateNotMoreThanDailyHospitalRate', () => {
      return rateperday <= daily_hospital_rate;
    });
    return function cleanup() {
      ValidatorForm.removeValidationRule('dailyRateNotMoreThanDailyHospitalRate');
    };

  }, [rateperday])

  useEffect(() => {
    const surgeryAmnt = currentBenefit ? (currentBenefit.surgery ? currentBenefit.surgery.remaining_amount_in_cents : 0) : 0;
    ValidatorForm.addValidationRule('surgeryAmountNotMoreThanTotalSurgeryAmount', () => {
      return Number(surgicalAmount) <= surgeryAmnt
    });
    return function cleanup() {
      ValidatorForm.removeValidationRule('surgeryAmountNotMoreThanTotalSurgeryAmount');
    };
  }, [surgicalAmount])


  useEffect(() => {
    const surgeryAmnt = currentBenefit ? (currentBenefit.surgery ? currentBenefit.surgery.remaining_amount_in_cents : 0) : 0;
    const defaultDailyRate = currentBenefit ? (currentBenefit.hospital ? currentBenefit.hospital.remaining_amount_in_cents : 0) : 0;

    setSurgicalAmount(surgeryAmnt)
    setRatePerday(defaultDailyRate)

  }, [currentBenefit])

  const exceedsHospitalDays = () => {
    const balance_days = currentBenefit ? (currentBenefit.hospital ? currentBenefit.hospital.remaining_usage_count : 0) : 0;

    if (!admissionDate || !dischargeDate) return true;
    const days = daysInHospital(dischargeDate, admissionDate);
    return days <= balance_days;
  }
  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === "benefitType") {
      setBenefitType(value)
    }

    if (name === "policy") {
      setPolicy(value)
    }
    if (name === "member") {
      const memberHospitalBenefit = find(value.benefits, {"type": "DAILY HOSPITAL BENEFIT"})
      const memberSurgeryBenefit = find(value.benefits, {"type": "ADDITIONAL SURGERY BENEFIT"})
      const memberDeathBenefit = find(value.benefits, {"type": "DEATH BENEFIT"})

      if(memberHospitalBenefit &&  memberSurgeryBenefit && memberDeathBenefit){
        setMember(value)
        fetchBenefitUsageBalance(value);
      }

    }
    if (name === "admissionDate") {
      setAdmissionDate(value)
    }
    if (name === "dischargeDate") {
      setDischargeDate(value)
    }

    if (name === "condition") {
      setCondition(value)
      setConditionError(false);
    }

    if (name === "surgicalAmount") {
      setSurgicalAmount(value)
    }
    if (name === "rateperday") {
      setRatePerday(value)
    }

  }

  const updateNumberOfDays = () => {
    const daysInHospitald = daysInHospital(dischargeDate, admissionDate);
    const balance_days = currentBenefit ? (currentBenefit.hospital ? currentBenefit.hospital.remaining_usage_count : 0) : 0;

    if (daysInHospitald > balance_days) {
      setNumberOfDays(balance_days);
    } else {
      setNumberOfDays(daysInHospitald);
    }
  }
  const reset = () => {
    setPolicy('');
    setMember('');
    setHospital('');
    setAdmissionDate('');
    setDischargeDate('');
    setDiagnosis('');
    setCondition('');
    setNumberOfDays('');
    setSurgicalAmount('');
    setRatePerday('');
  }

  const handleLOUCreatedSuccessfully = () => {
    props.resetCreateLOUSuccessAlert();
    props.close();
    reset();
  }

  const handleLOUCreateError = () => {
    props.resetCreateLOUFailureAlert()
  }

  const handleCreateLou = () => {
    if (!diagnosis) {
      setDiagnosisError(true);
      return;
    }

    if (!condition) {
      setConditionError(true);
      return;
    }

    if (!hospital) {
      setHospitalError(true);
      return;
    }



    const benefits = []
    if (currentBenefit.hospital) benefits.push({
      "benefit_type": currentBenefit.hospital.type,
      "authorized_amount_in_cents": getHospitalAmount() * 100,
      "authorized_usage_count": numberOfdays

    })
    if (currentBenefit.surgery) benefits.push({
      "benefit_type": currentBenefit.surgery.type,
      "authorized_amount_in_cents": Number(surgicalAmount) * 100
    })

    const payload = {
      "partner": getPartnerGuid(),
      "type": 'Hospital',
      "policy_guid": policy.guid,
      "customer_guid": policy.customer_guid,
      "dependant_guid": member.dependant.guid,
      "event_date": admissionDate,
      "web_agent_guid": getUserGuid(),
      "web_agent_name": getUserFullname(),
      "hospital_details": {
        "hospital_admission_date": admissionDate,
        "hospital_discharch_date": dischargeDate,
        "days_in_hospital": numberOfdays,
        "hospital": inputHospitalValue || 'N/A',
        "hospital_code": hospital.code || 'N/A',
        "hospital_address": hospital.physical_address || 'N/A',
        "hospital_region": hospital.region || 'N/A',
        "hospital_telephone": hospital.telephone || 'N/A',
        "diagnosis_name": diagnosis.name || 'N/A',
        "diagnosis_code": diagnosis.code || 'N/A',
        "diagnosis_type": condition || 'N/A',
      },
      "benefits": [
        ...benefits
      ]
    }
    console.log("payload", payload)
    props.createLou(payload)
  }


  const benefitsTypes = [
    'HOSPITAL',
    'SURGERY', 
    'HOSPITAL + SURGERY'
  ]

  const members = policy ? policy.quotation.policy_schedule_entries : []
  const getHospitalAmount = () => {
    const daysInHospitald = daysInHospital(dischargeDate, admissionDate);
    if (rateperday && daysInHospitald) return rateperday * daysInHospitald
    return 0
  }


  return (
    <>
      <ModalInc
        modalTitle="Create Letter of Undertaking "
        subTitle="Fill the below fields to create a letter of undertaking"
        open={props.open}
        onClose={props.close}
        fullWidth
        maxWidth="md"
      >
        <ValidatorForm onSubmit={() => handleCreateLou()} >

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
              <SelectValidator
                name='policy'
                labelId="select-grower-number"
                id="select-grower-number"
                value={policy}
                label="Select Grower Number"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select a grower number']}
                style={{ width: "100%" }}
              >
                {
                  props.policies.map(policy => {
                    return <MenuItem
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }}
                      value={policy}
                    >
                      {policy.policy_number}
                    </MenuItem>
                  })
                }


              </SelectValidator>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <SelectValidator
                name='member'
                labelId="select-member"
                id="select-member"
                value={member}
                label="Select Member"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select a member']}
                style={{ width: "100%" }}
              >
                {
                  members.map(member => {
                    return <MenuItem
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }}
                      value={member}
                    >
                      {member.dependant.first_name}

                    </MenuItem>
                  })
                }

              </SelectValidator>
            </Grid>
            <Grid item xs={12} sm={4} md={4} className={classes.selectbenefit}>
              <SelectValidator
                name='benefitType'
                labelId="select-benefit"
                id="select-benefit"
                value={benefitType}
                label={loadingBenefitBalances ? '...Fetching benefits' : "Select Benefit"}
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select benefits']}
                style={{ width: "100%" }}
              >
                {
                  ((errorFetchingBenefitUsage ||  loadingBenefitBalances) ? []: (member ? benefitsTypes: [])).map(benefitType => {
                    return <MenuItem
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }}
                      value={benefitType}
                    >
                      {benefitType}

                    </MenuItem>
                  })
                }

              </SelectValidator>
              {errorFetchingBenefitUsage && <p style={{color: 'red'}}>Could not find benefits for this member</p>}
            </Grid>
            <Grid container spacing={2} className={classes.summaryWrapper}>
              <Grid item xs={12} sm={4} md={4} className={classes.beneficiaryname}>
                <h4>Beneficiary Name</h4>
                {member
                  ? <p><strong> {member.dependant.first_name}</strong></p>
                  : <p>N/A</p>}
              </Grid>
              <Grid item xs={12} sm={4} md={4} className={classes.benefitbalance}>
                <h4>Hospital Benefit Balance</h4>
                {currentBenefit.hospital
                  ? 
                  <>
                  <p><strong>DHB {currentBenefit.hospital.remaining_usage_count} days left -  KSH {currentBenefit.hospital.remaining_amount_in_cents}</strong></p>
                  <p>yearly total: {currentBenefit.hospital.yearly_total}</p>
                  </>
                  : <p>N/A</p>}
              </Grid>
              <Grid item xs={12} sm={4} md={4} className={classes.surgicalbalance}>
                <h4>Surgical Balance</h4>
                {currentBenefit.surgery
                  ? <p><strong>KSH {currentBenefit.surgery.remaining_amount_in_cents}</strong></p>
                  : <p>N/A</p>}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <div className={classes.completeLouSectionTitle}>
                  <h4>Complete New LOU</h4>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4} className={classes.autocomplete}>
                <Autocomplete
                  open={openHospitals}
                  onOpen={() => {
                    setOpenHospitals(true);
                  }}
                  onClose={() => {
                    setOpenHospitals(false);
                  }}
                  onChange={(event, selectedHospital)=>{
                    setHospital(selectedHospital)
                    setHospitalError(false)

                  }}
                  ListboxProps={{
                    sx: {
                      "& li": {
                        fontFamily: 'Open Sans',
                        fontStyle: "normal",
                        fontSize: "12px",
                        lineHeight: "25px",
                        color: "#000000",
                      },
                    }
                  }}
                  id="hospital"
                  name='hospital'
                  options={hospitals.sort((a, b) => (b.region ? -b.region.localeCompare(a.region) : b))}
                  groupBy={(option) => option.region}
                  getOptionLabel={(option) => option.name.toLowerCase()}
                  loading={loadingHospitals}
                  inputValue={inputHospitalValue}
                  onInputChange={handleHospitalInputChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Hospital"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loadingHospitals ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}

                />
                {hospitalError && <p style={{color: 'red'}}>Select a hospital</p>}

                <div className={classes.addhospital} onClick={handleCreateHospital}>
                  <ul>
                    <li><AddIcon /></li>
                    <li>Add Hospital</li>
                  </ul>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  id="admissionDate"
                  label="Admission Date"
                  name='admissionDate'
                  value={admissionDate}
                  onChange={handleChange}
                  fullWidth
                  type='date'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  
                  validators={
                    ['hasLouWithSameAdmissionDate']
                  }
                  errorMessages={[
                    `There is an lou with same admission date`,
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  id="dischargeDate"
                  label="Discharge Date "
                  name='dischargeDate'
                  value={dischargeDate}
                  onChange={handleChange}
                  fullWidth
                  type='date'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  validators={
                    ['hasAdmissionDateFirst',
                      'dischargeDateIsAfterAdmissionDate']
                  }
                  errorMessages={[
                    `Please select admision date first`,
                    `Discharge date can not be before admision date`,
                  ]}
                />
                {!exceedsHospitalDays() && <p>Total days in hospital exceeds the daily hospital days balance</p>}

              </Grid>
              <Grid item xs={12} sm={4} md={4} className={classes.autocomplete}>

                <Autocomplete
                  open={openDiagnosis}
                  onOpen={() => {
                    setOpenDiagnosis(true);
                  }}
                  onClose={() => {
                    setOpenDiagnosis(false);
                  }}
                  ListboxProps={{
                    sx: {
                      "& li": {
                        fontFamily: 'Open Sans',
                        fontStyle: "normal",
                        fontSize: "12px",
                        lineHeight: "25px",
                        color: "#000000",
                      },
                    }
                  }}
                  id="diagnosis"
                  name='diagnosis'
                  options={diagnosisSearchResult.sort((a, b) => -b.code.localeCompare(a.code))}
                  groupBy={(option) => option.code}
                  getOptionLabel={(option) => option.name.toLowerCase()}
                  loading={loadingDiagnosis}
                  inputValue={inputDiagnosisValue}
                  onInputChange={handleDiagnosisInputChange}
                  onChange={(event, selectedDiagnosis)=>{
                    setDiagnosis(selectedDiagnosis)
                    setDiagnosisError(false)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Diagnosis (ICD-10)"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loadingDiagnosis ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}

                />
                {diagnosisError && <p style={{color: 'red'}}>Select a diagnosis</p>}

                <div className={classes.checkcondition}>
                  <FormControl error={conditionError} component="fieldset">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="condition"
                      value={condition}
                      onChange={handleChange}
                    >
                      <FormControlLabel value="Acute" control={<Radio />} label="Acute" />
                      <FormControlLabel value="Chronic" control={<Radio />} label="Chronic" />
                    </RadioGroup>
                    {conditionError && <FormHelperText>Select a condition</FormHelperText>}
                  </FormControl>
                </div>
                <div className={classes.addhospital} onClick={handleCreateDiagnosis}>
                  <ul>
                    <li><AddIcon /></li>
                    <li>Add Diagnosis</li>
                  </ul>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  id="days"
                  fullWidth
                  label="Number of Days"
                  name='numberOfdays'
                  value={numberOfdays}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  id="SurgeryAmount"
                  fullWidth
                  label="Surgery amount"
                  name='surgicalAmount'
                  type='number'
                  value={surgicalAmount}
                  onChange={handleChange}
                  variant="outlined"
                  validators={['required', 'surgeryAmountNotMoreThanTotalSurgeryAmount']}
                  errorMessages={[
                    `Please enter surgery amount`,
                    `surgery amount  can not be more than the total surgery amount`,
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextValidator
                  id="rateperday"
                  fullWidth
                  label="Rate Per Day"
                  type='number'
                  name='rateperday'
                  value={rateperday}
                  onChange={handleChange}
                  variant="outlined"
                  validators={['required', 'dailyRateNotMoreThanDailyHospitalRate']}
                  errorMessages={[
                    `Please enter rate per day`,
                    `rate can not be more than the daily hospital rate`,
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={8} className={classes.calculationswrapper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={4}>
                    <p><strong>Hospital: </strong>KSH {getHospitalAmount()}</p>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <p><strong>Surgery: </strong>KSH {surgicalAmount}</p>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <p><strong>Total Amount: </strong>KSH {Number(getHospitalAmount()) + Number(surgicalAmount)}</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} md={6}>

            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    type='submit'
                    style={{ marginTop: "20px", float: "right" }}
                  >
                    Create Document
                  </ButtonInc>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginTop: "20px" }}
                    onClick={props.close}
                  >
                    Cancel
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </ValidatorForm>

        <AlertDialog
          custom
          show={!!props.showCreateLOUProgressAlert}
          size="sm"
          style={{ marginTop: '0', top: '30vh' }}
          confirmBtnText="ok"
          showCancel={false}
          showConfirm={false}
          cancelBtnText="cancel"
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2>Creating LOU record </h2>
        </AlertDialog>

        <AlertDialog
          success
          show={!!props.showCreateLOUSuccessAlert}
          size="sm"
          title="LOU record created"
          style={{ marginTop: '0', top: '30vh' }}
          onConfirm={() => {
            handleLOUCreatedSuccessfully();
          }}
          confirmBtnText="ok"
          showCancel={false}
          showConfirm
          cancelBtnText="cancel"
          confirmBtnCssClass
        ></AlertDialog>

        <AlertDialog
          show={!!props.showCreateLOUErrorAlert}
          danger
          title="Error creating lou record"
          onConfirm={() => handleLOUCreateError()}
          confirmBtnText="ok"
          confirmBtnCssClass
          showCancel={false}
          style={{ marginTop: '0', top: '30vh' }}
        >
          {props.error
            ? props.error.message
            : ''}
        </AlertDialog>

      </ModalInc>
    </>
  )
}

export default CreateLOU

function daysInHospital(dischargeDate, admissionDate) {
  if (dischargeDate && admissionDate) {
    return moment(dischargeDate).diff(moment(admissionDate), 'days');
  }
  return 0
}
