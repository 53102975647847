import * as types from '../../lib/actionTypes.es6';

export function getAllProductsRequest(payload) {
  return {
    type: types.VIEW_ALL_PRODUCTS_REQUEST,
    payload,
  };
}

export function getAllProductsFailure(error) {
  return {
    type: types.VIEW_ALL_PRODUCTS_FAILURE,
    payload: error,
  };
}

export function getAllProductsSuccess(response) {
  return {
    type: types.VIEW_ALL_PRODUCTS_SUCCESS,
    payload: response,
  };
}

export function createProductRequest(payload) {
  return {
    type: types.CREATE_PRODUCT_REQUEST,
    payload,
  };
}

export function createProductFailure(error) {
  return {
    type: types.CREATE_PRODUCT_FAILURE,
    payload: error,
  };
}

export function createProductSuccess(response) {
  return {
    type: types.CREATE_PRODUCT_SUCCESS,
    payload: response,
  };
}

export function resetCreateProductSuccess(error) {
  return {
    type: types.RESET_CREATE_PRODUCT_SUCCESS,
  };
}

export function resetCreateProductError(error) {
  return {
    type: types.RESET_CREATE_PRODUCT_ERROR,
  };
}
export function getProductRequest(payload) {
  return {
    type: types.VIEW_PRODUCT_REQUEST,
    payload,
  };
}


export function getProductFailure(error) {
  return {
    type: types.VIEW_PRODUCT_FAILURE,
    payload: error,
  };
}

export function getProductSuccess(response) {
  return {
    type: types.VIEW_PRODUCT_SUCCESS,
    payload: response,
  };
}

export function getAllPartnersRequest() {
  return {
    type: types.VIEW_ALL_PARTNERS_REQUEST,
  };
}

export function getProductEnumsRequest(payload) {
  return {
    type: types.PRODUCT_ENUMS_REQUEST,
    payload,
  };
}

export function getProductEnumsFailure(error) {
  return {
    type: types.PRODUCT_ENUMS_FAILURE,
    payload: error,
  };
}

export function getProductEnumsSuccess(response) {
  return {
    type: types.PRODUCT_ENUMS_SUCCESS,
    payload: response,
  };
}

export function getProductPremiumEnumsRequest(payload) {
  return {
    type: types.PRODUCT_PREMIUM_ENUMS_REQUEST,
    payload,
  };
}


export function getProductPremiumsRequest(payload) {
  return {
    type: types.VIEW_ALL_PRODUCT_PREMIUMS_REQUEST,
    payload,
  };
}

export function createProductPremiumRequest(payload) {
  return {
    type: types.CREATE_PRODUCT_PREMIUM_REQUEST,
    payload,
  };
}


// PREMIUMS
export function createPremiumRequest(payload) {
  return {
    type: types.CREATE_PREMIUM_REQUEST,
    payload,
  };
}

export function createPremiumFailure(error) {
  return {
    type: types.CREATE_PREMIUM_FAILURE,
    payload: error,
  };
}

export function createPremiumSuccess(response) {
  return {
    type: types.CREATE_PREMIUM_SUCCESS,
    payload: response,
  };
}

export function resetCreatePremiumSuccess(error) {
  return {
    type: types.RESET_CREATE_PREMIUM_SUCCESS,
  };
}

export function resetCreatePremiumError(error) {
  return {
    type: types.RESET_CREATE_PREMIUM_ERROR,
  };
}

export function hardDeletePremiumRequest(payload) {
  return {
    type: types.HARD_DELETE_PREMIUM_REQUEST,
    payload,
  };
}

export function hardDeletePremiumFailure(error) {
  return {
    type: types.HARD_DELETE_PREMIUM_FAILURE,
    payload: error,
  };
}

export function hardDeletePremiumSuccess(response) {
  return {
    type: types.HARD_DELETE_PREMIUM_SUCCESS,
    payload: response,
  };
}

export function resetHardDeletePremiumSuccess(error) {
  return {
    type: types.RESET_HARD_DELETE_PREMIUM_SUCCESS,
  };
}

export function resetHardDeletePremiumError(error) {
  return {
    type: types.RESET_HARD_DELETE_PREMIUM_ERROR,
  };
}

export function softDeletePremiumRequest(payload) {
  return {
    type: types.SOFT_DELETE_PREMIUM_REQUEST,
    payload,
  };
}

export function softDeletePremiumFailure(error) {
  return {
    type: types.SOFT_DELETE_PREMIUM_FAILURE,
    payload: error,
  };
}

export function softDeletePremiumSuccess(response) {
  return {
    type: types.SOFT_DELETE_PREMIUM_SUCCESS,
    payload: response,
  };
}


export function editPremiumRequest(payload) {
  return {
    type: types.EDIT_PREMIUM_REQUEST,
    payload,
  };
}

export function editPremiumFailure(error) {
  return {
    type: types.EDIT_PREMIUM_FAILURE,
    payload: error,
  };
}

export function editPremiumSuccess(response) {
  return {
    type: types.EDIT_PREMIUM_SUCCESS,
    payload: response,
  };
}

export function resetEditPremiumSuccess(error) {
  return {
    type: types.RESET_EDIT_PREMIUM_SUCCESS,
  };
}

export function resetEditPremiumError(error) {
  return {
    type: types.RESET_EDIT_PREMIUM_ERROR,
  };
}


// BENEFITS

export function createBenefitRequest(payload) {
  return {
    type: types.CREATE_BENEFIT_REQUEST,
    payload,
  };
}

export function createBenefitFailure(error) {
  return {
    type: types.CREATE_BENEFIT_FAILURE,
    payload: error,
  };
}

export function createBenefitSuccess(response) {
  return {
    type: types.CREATE_BENEFIT_SUCCESS,
    payload: response,
  };
}

export function resetCreateBenefitSuccess(error) {
  return {
    type: types.RESET_CREATE_BENEFIT_SUCCESS,
  };
}

export function resetCreateBenefitError(error) {
  return {
    type: types.RESET_CREATE_BENEFIT_ERROR,
  };
}
export function resetCreateBenefitsAndPremiumsLists(error) {
  return {
    type: 'RESET_CREATED_BENEFITS_AND_PREMIUMS_LISTS',
  };
}

export function hardDeleteBenefitRequest(payload) {
  return {
    type: types.HARD_DELETE_BENEFIT_REQUEST,
    payload,
  };
}

export function hardDeleteBenefitFailure(error) {
  return {
    type: types.HARD_DELETE_BENEFIT_FAILURE,
    payload: error,
  };
}

export function hardDeleteBenefitSuccess(response) {
  return {
    type: types.HARD_DELETE_BENEFIT_SUCCESS,
    payload: response,
  };
}

export function resetHardDeleteBenefitSuccess(error) {
  return {
    type: types.RESET_HARD_DELETE_BENEFIT_SUCCESS,
  };
}

export function resetHardDeleteBenefitError(error) {
  return {
    type: types.RESET_HARD_DELETE_BENEFIT_ERROR,
  };
}

export function softDeleteBenefitRequest(payload) {
  return {
    type: types.SOFT_DELETE_BENEFIT_REQUEST,
    payload,
  };
}

export function softDeleteBenefitFailure(error) {
  return {
    type: types.SOFT_DELETE_BENEFIT_FAILURE,
    payload: error,
  };
}

export function softDeleteBenefitSuccess(response) {
  return {
    type: types.SOFT_DELETE_BENEFIT_SUCCESS,
    payload: response,
  };
}


export function editBenefitRequest(payload) {
  return {
    type: types.EDIT_BENEFIT_REQUEST,
    payload,
  };
}

export function editBenefitFailure(error) {
  return {
    type: types.EDIT_BENEFIT_FAILURE,
    payload: error,
  };
}

export function editBenefitSuccess(response) {
  return {
    type: types.EDIT_BENEFIT_SUCCESS,
    payload: response,
  };
}

export function resetEditBenefitSuccess(error) {
  return {
    type: types.RESET_EDIT_BENEFIT_SUCCESS,
  };
}

export function resetEditBenefitError(error) {
  return {
    type: types.RESET_EDIT_BENEFIT_ERROR,
  };
}

export function updateDialogProductType(response) {
  return {
    type: 'UPDATE_PRODUCT_TYPE_IN_DIALOG',
    payload: response,
  };
}


export function editProductRequest(payload) {
  return {
    type: types.EDIT_PRODUCT_REQUEST,
    payload,
  };
}

export function editProductFailure(error) {
  return {
    type: types.EDIT_PRODUCT_FAILURE,
    payload: error,
  };
}

export function editProductSuccess(response) {
  return {
    type: types.EDIT_PRODUCT_SUCCESS,
    payload: response,
  };
}

export function resetEditProductSuccess(error) {
  return {
    type: types.RESET_EDIT_PRODUCT_SUCCESS,
  };
}

export function resetEditProductError(error) {
  return {
    type: types.RESET_EDIT_PRODUCT_ERROR,
  };
}


export function setPremiumToEdit(response) {
  return {
    type: types.SET_PREMIUM_TO_EDIT,
    payload: response,
  };
}

export function setPremiumToDelete(response) {
  return {
    type: types.SET_PREMIUM_TO_DELETE,
    payload: response,
  };
}

export function setBenefitToEdit(response) {
  return {
    type: types.SET_BENEFIT_TO_EDIT,
    payload: response,
  };
}

export function setBenefitToDelete(response) {
  return {
    type: types.SET_BENEFIT_TO_DELETE,
    payload: response,
  };
}


export function resetPremiumToEdit(response) {
  return {
    type: types.RESET_PREMIUM_TO_EDIT,
  };
}

export function resetPremiumToDelete(response) {
  return {
    type: types.RESET_PREMIUM_TO_DELETE,
  };
}

export function resetBenefitToEdit(response) {
  return {
    type: types.RESET_BENEFIT_TO_EDIT,
  };
}

export function resetBenefitToDelete(response) {
  return {
    type: types.RESET_BENEFIT_TO_DELETE,
  };
}


// GET GROUP_PRODUCT_CONFIGS 
export function getGroupProductConfigsRequest(payload) {
  return {
    type: types.GET_GROUP_PRODUCT_CONFIGS_REQUEST,
    payload,
  };
}

export function getGroupProductConfigsFailure(error) {
  return {
    type: types.GET_GROUP_PRODUCT_CONFIGS_FAILURE,
    payload: error,
  };
}

export function getGroupProductConfigsSuccess(response) {
  return {
    type: types.GET_GROUP_PRODUCT_CONFIGS_SUCCESS,
    payload: response,
  };
}


// update GROUP_PRODUCT_CONFIGS 
export function updateGroupProductConfigsRequest(payload) {
  return {
    type: types.UPDATE_GROUP_PRODUCT_CONFIGS_REQUEST,
    payload,
  };
}

export function updateGroupProductConfigsFailure(error) {
  return {
    type: types.UPDATE_GROUP_PRODUCT_CONFIGS_FAILURE,
    payload: error,
  };
}

export function updateGroupProductConfigsSuccess(response) {
  return {
    type: types.UPDATE_GROUP_PRODUCT_CONFIGS_SUCCESS,
    payload: response,
  };
}

export function resetUpdateGroupProductConfigsSuccess() {
  return {
    type: types.RESET_UPDATE_GROUP_PRODUCT_CONFIGS_SUCCESS,
  };
}

export function resetUpdateGroupProductConfigsError(error) {
  return {
    type: types.RESET_UPDATE_GROUP_PRODUCT_CONFIGS_ERROR,
  };
}


// add GROUP_PRODUCT_CONFIGS 
export function addGroupProductConfigsRequest(payload) {
  return {
    type: types.ADD_GROUP_PRODUCT_CONFIGS_REQUEST,
    payload,
  };
}

export function addGroupProductConfigsFailure(error) {
  return {
    type: types.ADD_GROUP_PRODUCT_CONFIGS_FAILURE,
    payload: error,
  };
}

export function addGroupProductConfigsSuccess(response) {
  return {
    type: types.ADD_GROUP_PRODUCT_CONFIGS_SUCCESS,
    payload: response,
  };
}

export function resetAddGroupProductConfigsSuccess() {
  return {
    type: types.RESET_ADD_GROUP_PRODUCT_CONFIGS_SUCCESS,
  };
}

export function resetAddGroupProductConfigsError(error) {
  return {
    type: types.RESET_ADD_GROUP_PRODUCT_CONFIGS_ERROR,
  };
}


export function getExtendedFamilyProductConfigsRequest(payload) {
  return {
    type: types.GET_EXTENDED_FAMILY_PRODUCT_CONFIGS_REQUEST,
    payload,
  };
}

export function getExtendedFamilyProductConfigsFailure(error) {
  return {
    type: types.GET_EXTENDED_FAMILY_PRODUCT_CONFIGS_FAILURE,
    payload: error,
  };
}

export function getExtendedFamilyProductConfigsSuccess(response) {
  return {
    type: types.GET_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS,
    payload: response,
  };
}


// update EXTENDED_FAMILY_PRODUCT_CONFIGS 
export function updateExtendedFamilyProductConfigsRequest(payload) {
  return {
    type: types.UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_REQUEST,
    payload,
  };
}

export function updateExtendedFamilyProductConfigsFailure(error) {
  return {
    type: types.UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_FAILURE,
    payload: error,
  };
}

export function updateExtendedFamilyProductConfigsSuccess(response) {
  return {
    type: types.UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS,
    payload: response,
  };
}

export function resetUpdateExtendedFamilyProductConfigsSuccess() {
  return {
    type: types.RESET_UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS,
  };
}

export function resetUpdateExtendedFamilyProductConfigsError(error) {
  return {
    type: types.RESET_UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_ERROR,
  };
}


// add EXTENDED_FAMILY_PRODUCT_CONFIGS 
export function addExtendedFamilyProductConfigsRequest(payload) {
  return {
    type: types.ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_REQUEST,
    payload,
  };
}

export function addExtendedFamilyProductConfigsFailure(error) {
  return {
    type: types.ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_FAILURE,
    payload: error,
  };
}

export function addExtendedFamilyProductConfigsSuccess(response) {
  return {
    type: types.ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS,
    payload: response,
  };
}

export function resetAddExtendedFamilyProductConfigsSuccess() {
  return {
    type: types.RESET_ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS,
  };
}

export function resetAddExtendedFamilyProductConfigsError(error) {
  return {
    type: types.RESET_ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_ERROR,
  };
}





// premium configs


// PREMIUMS
export function createPremiumConfigRequest(payload) {
  return {
    type: types.CREATE_PREMIUM_CONFIG_REQUEST,
    payload,
  };
}

export function createPremiumConfigFailure(error) {
  return {
    type: types.CREATE_PREMIUM_CONFIG_FAILURE,
    payload: error,
  };
}

export function createPremiumConfigSuccess(response) {
  return {
    type: types.CREATE_PREMIUM_CONFIG_SUCCESS,
    payload: response,
  };
}

export function resetCreatePremiumConfigSuccess(error) {
  return {
    type: types.RESET_CREATE_PREMIUM_CONFIG_SUCCESS,
  };
}

export function resetCreatePremiumConfigError(error) {
  return {
    type: types.RESET_CREATE_PREMIUM_CONFIG_ERROR,
  };
}

//addons config

export function createaddOnsConfigRequest(payload) {
  return {
    type: types.CREATE_ADDON_CONFIG_REQUEST,
    payload,
  };
}

export function createAddOnConfigFailure(error) {
  return {
    type: types.CREATE_ADDON_CONFIG_FAILURE,
    payload: error,
  };
}

export function createAddOnConfigSuccess(response) {
  return {
    type: types.CREATE_ADDON_CONFIG_SUCCESS,
    payload: response,
  };
}

export function resetCreateAddOnConfigSuccess(error) {
  return {
    type: types.RESET_CREATE_ADDON_CONFIG_SUCCESS,
  };
}

export function resetCreateAddOnConfigError(error) {
  return {
    type: types.RESET_CREATE_ADDON_CONFIG_ERROR,
  };
}

//end of addons configs
export function hardDeletePremiumConfigRequest(payload) {
  return {
    type: types.HARD_DELETE_PREMIUM_CONFIG_REQUEST,
    payload,
  };
}

export function hardDeletePremiumConfigFailure(error) {
  return {
    type: types.HARD_DELETE_PREMIUM_CONFIG_FAILURE,
    payload: error,
  };
}

export function hardDeletePremiumConfigSuccess(response) {
  return {
    type: types.HARD_DELETE_PREMIUM_CONFIG_SUCCESS,
    payload: response,
  };
}

export function resetHardDeletePremiumConfigSuccess(error) {
  return {
    type: types.RESET_HARD_DELETE_PREMIUM_CONFIG_SUCCESS,
  };
}

export function resetHardDeletePremiumConfigError(error) {
  return {
    type: types.RESET_HARD_DELETE_PREMIUM_CONFIG_ERROR,
  };
}

export function editPremiumConfigRequest(payload) {
  return {
    type: types.EDIT_PREMIUM_CONFIG_REQUEST,
    payload,
  };
}

export function editPremiumConfigFailure(error) {
  return {
    type: types.EDIT_PREMIUM_CONFIG_FAILURE,
    payload: error,
  };
}

export function editPremiumConfigSuccess(response) {
  return {
    type: types.EDIT_PREMIUM_CONFIG_SUCCESS,
    payload: response,
  };
}

export function resetEditPremiumConfigSuccess(error) {
  return {
    type: types.RESET_EDIT_PREMIUM_CONFIG_SUCCESS,
  };
}

export function resetEditPremiumConfigError(error) {
  return {
    type: types.RESET_EDIT_PREMIUM_CONFIG_ERROR,
  };
}

export function setPremiumConfigToEdit(response) {
  return {
    type: types.SET_PREMIUM_CONFIG_TO_EDIT,
    payload: response,
  };
}

export function setPremiumConfigToDelete(response) {
  return {
    type: types.SET_PREMIUM_CONFIG_TO_DELETE,
    payload: response,
  };
}

export function resetPremiumConfigToEdit(response) {
  return {
    type: types.RESET_PREMIUM_CONFIG_TO_EDIT,
  };
}

export function resetPremiumConfigToDelete(response) {
  return {
    type: types.RESET_PREMIUM_CONFIG_TO_DELETE,
  };
}



// GET GROUP_PRODUCT_CONFIGS 
export function getPremiumConfigsRequest(payload) {
  return {
    type: types.GET_PREMIUM_CONFIGS_REQUEST,
    payload,
  };
}

export function getPremiumConfigsFailure(error) {
  return {
    type: types.GET_PREMIUM_CONFIGS_FAILURE,
    payload: error,
  };
}

export function getPremiumConfigsSuccess(response) {
  return {
    type: types.GET_PREMIUM_CONFIGS_SUCCESS,
    payload: response,
  };
}