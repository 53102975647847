import * as types from '../../lib/actionTypes.es6';

export function reportFinalizationHospitalClaimRequest(reportType) {
  return {
    type: types.REPORT_FINALIZATION_HOSPITAL_CLAIM_REQUEST,
    payload: reportType,
  };
}

export function reportFinalizationHospitalClaimRequestSuccess(reportURL) {
  return {
    type: types.REPORT_FINALIZATION_HOSPITAL_CLAIM_SUCCESS,
    payload: reportURL,
  };
}

export function reportFinalizationHospitalClaimRequestError(error) {
  return {
    type: types.REPORT_FINALIZATION_HOSPITAL_CLAIM_FAILURE,
    payload: error,
  };
}

export function reportFinalizationHospitalClaimReset() {
  return {
    type: types.REPORT_FINALIZATION_HOSPITAL_CLAIM_RESET,
  };
}
