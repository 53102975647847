import React from 'react';
import { PRIMARY_THEME_COLOR_SHADE_2 } from '../../lib/constants';

export default class MainPageTitle extends React.Component {
  render() {
    return (
      <div className="page-title">
        <div className="title_left" style={{ color: PRIMARY_THEME_COLOR_SHADE_2 }}>
          <h3>{this.props.pageTitle}</h3>
        </div>
      </div>
    );
  }
}
