import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import CreateDebitOrders from '../../components/CreateDebitOrder/index.es6';
import * as globalActions from '../Main/actions.es6';
import * as actions from './actions.es6';

export class DebitOrderManagement extends React.Component {
  componentWillMount() {
    this.props.dispatch(globalActions.hideCustomerBar());
    this.props.dispatch(actions.getAllBanksRequest());
  }

  CreateDebitOrders(debitOrder) {
    this.props.dispatch(actions.createDebitOrderRequest(debitOrder));
  }

  render() {
    return (
      <div className="container">
        <center>
          <p style={{ color: 'red' }}>
            {this.props.createDebitOrder.errors
              ? this.props.createDebitOrder.errors.message
              : ''}
          </p>
        </center>
        <center>
          <p style={{ color: 'green' }}>
            {this.props.createDebitOrder.debitOrder
              ? 'debit order successfully created'
              : ''}
          </p>
        </center>
        <center
          style={{
            marginTop: '20%',
            display: this.props.createDebitOrder.loader ? '' : 'none',
          }}
        >
          <CircularProgress size={70} thickness={5} />
        </center>
        <div
          style={{ display: this.props.createDebitOrder.loader ? 'none' : '' }}
        >
          <CreateDebitOrders
            createDebitOrderhandler={this.CreateDebitOrders.bind(this)}
            banks={this.props.createDebitOrder.banks}
          />
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  globalData: state.global,
  createDebitOrder: state.createDebitOrder,
}))(DebitOrderManagement);
