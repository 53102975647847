
import React from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";

import LocalizedStrings from "react-localization";
import { localisedText } from "../../lib/localisation.es6";
import { getPartner } from "../../lib/access.es6";
import { updateCurrentLanguage } from "../../lib/languageUtils.es6";
import * as actions from "./actions.es6";
import SkeletonWrapper from "../../components/SkeletonWrapper/SkeletonWrapper";

import UpdatePartner from "./components/Edit/UpdatePartner";
import UpdatePartnerConfig from "./components/Edit/UpdatePartnerConfig";
import UpdatePartnerConfigClaim from "./components/Edit/UpdatePartnerConfigClaim";

import ViewPartner from "./components/view/ViewPartner";
import ViewPartnerConfig from "./components/view/ViewPartnerConfig";
import ViewPartnerConfigClaim from "./components/view/ViewPartnerConfigClaim";

export class PartnerStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labels: new LocalizedStrings(localisedText),
      openUpdatePartnerDialog: false,
      openUpdatePartnerConfigDialog: false,
      openUpdatePartnerConfigClaimDialog: false,
      openUpdatePartnerConfigGroupDialog: false,
    };
  }

  componentWillMount() {
    this.updateLanguage();
    this.props.dispatch(actions.getPartnerConfigsRequest())
    this.props.dispatch(actions.getPartnerConfigsClaimRequest())
    this.props.dispatch(actions.getPartnerConfigsGroupRequest())
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      "PartnerStatus",
      this.state.labels
    );
  }

  updatePartner(partner) {
    this.props.dispatch(actions.updatePartnerRequest(partner));
  }

  updatePartnerConfig(partnerConfig) {
    this.props.dispatch(actions.updatePartnerConfigRequest(partnerConfig));
  }

  updatePartnerConfigClaim(partnerConfigClaim) {
    this.props.dispatch(actions.updatePartnerConfigClaimRequest(partnerConfigClaim));
  }

  updatePartnerConfigGroup(partnerConfigGroup) {
    this.props.dispatch(actions.updatePartnerConfigGroupRequest(partnerConfigGroup));
  }


  resetUpdatePartnerSuccessAlert = () => {
    this.props.dispatch(actions.resetUpdatePartnerSuccess())
  }

  resetUpdatePartnerConfigSuccessAlert = () => {
    this.props.dispatch(actions.resetUpdatePartnerConfigSuccess())
    this.props.dispatch(actions.getPartnerConfigsRequest())
  }

  resetUpdatePartnerConfigClaimSuccessAlert = () => {
    this.props.dispatch(actions.resetUpdatePartnerConfigClaiimSuccess())
    this.props.dispatch(actions.getPartnerConfigsClaimRequest())
  }

  resetUpdatePartnerConfigGroupSuccessAlert = () => {
    this.props.dispatch(actions.resetUpdatePartnerConfigGroupSuccess())
    this.props.dispatch(actions.getPartnerConfigsGroupRequest())
  }

  sanitizeList(listString) {
    if (!listString) {
      return 
    }
    if(Array.isArray(listString)) {
      return listString.filter(x => x !== "");
    }
    let formatedList = listString.replaceAll("[", "").replaceAll("]", "").replaceAll(" ", "").split(",")
    return formatedList.filter(i => i.length > 0)
  }

  convertStringToList(listString) {
    if(!listString) {
      return [];
    }
    return listString.split(',').map(x => x.trim());
  }

  removeSquareBrackets(listString) {
    if (!listString) {
      return ""
    }
    if(Array.isArray(listString)) {
      return listString.join(",");
    }
    return listString.replaceAll("[", "").replaceAll("]", "")
  }

  render() {
    const partner = getPartner();

    const partnerConfig = this.props.partnerStatusData.partnerConfig
    const partnerConfigClaim = this.props.partnerStatusData.partnerConfigClaim

    console.log();
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg" style={{ marginTop: "2%" }}>
          <SkeletonWrapper loading={this.props.partnerStatusData.updatePartnerConfigLoader} />
          <ViewPartner
            partner={partner}
            partnerConfig={partnerConfig}
            isLoading={this.props.partnerStatusData.updatePartnerLoader}
            openUpdatePartnerDialog={() => { this.setState({ openUpdatePartnerDialog: true }) }}
          />

          <ViewPartnerConfig
            partner={partner}
            partnerConfig={partnerConfig}
            isLoading={this.props.partnerStatusData.partnerConfigLoader}
            openUpdatePartnerConfigDialog={() => { this.setState({ openUpdatePartnerConfigDialog: true }) }}
            formatAndConvertToListString={this.sanitizeList.bind(this)}
          />

          <ViewPartnerConfigClaim
            partnerConfigClaim={partnerConfigClaim}
            isLoading={this.props.partnerStatusData.partnerConfigClaimLoader}
            openUpdatePartnerConfigClaimDialog={() => { this.setState({ openUpdatePartnerConfigClaimDialog: true }) }}
            formatAndConvertToListString={this.sanitizeList.bind(this)}
          />

          <UpdatePartner
            showUpdatePartnerDialog={this.state.openUpdatePartnerDialog}
            closeUpdatePartnerDialog={() => { this.setState({ openUpdatePartnerDialog: false }) }}
            partner={partner}
            showUpdatePartnerProgressAlert={this.props.partnerStatusData.updatePartnerLoader}
            showUpdatePartnerSuccessAlert={this.props.partnerStatusData.updatePartnerSuccess}
            showUpdatePartnerErrorAlert={!!this.props.partnerStatusData.updatePartnerError}
            updatePartnerError={this.props.partnerStatusData.updatePartnerError}
            updatePartner={this.updatePartner.bind(this)}

            resetUpdatePartnerSuccessAlert={this.resetUpdatePartnerSuccessAlert.bind(this)}
            resetUpdatePartnerErrorAlert={() => { this.props.dispatch(actions.resetUpdatePartnerError()) }}

            formatAndConvertToListString={this.sanitizeList.bind(this)}
            removeSquareBrackets={this.removeSquareBrackets.bind(this)}
            convertListToString={this.sanitizeList.bind(this)}
          />

          <UpdatePartnerConfig
            showUpdatePartnerConfigDialog={this.state.openUpdatePartnerConfigDialog}
            closeUpdatePartnerConfigDialog={() => { this.setState({ openUpdatePartnerConfigDialog: false }) }}
            partnerConfig={partnerConfig}
            showUpdatePartnerConfigProgressAlert={this.props.partnerStatusData.updatePartnerConfigLoader}
            showUpdatePartnerConfigSuccessAlert={this.props.partnerStatusData.updatePartnerConfigSuccess}
            showUpdatePartnerConfigErrorAlert={!!this.props.partnerStatusData.updatePartnerConfigError}
            updatePartnerConfigError={this.props.partnerStatusData.updatePartnerConfigError}
            updatePartnerConfig={this.updatePartnerConfig.bind(this)}

            resetUpdatePartnerConfigSuccessAlert={this.resetUpdatePartnerConfigSuccessAlert.bind(this)}
            resetUpdatePartnerConfigErrorAlert={() => { this.props.dispatch(actions.resetUpdatePartnerConfigError()) }}

            formatAndConvertToListString={this.sanitizeList.bind(this)}
            removeSquareBrackets={this.removeSquareBrackets.bind(this)}
            convertListToString={this.sanitizeList.bind(this)}
            convertStringToList={this.convertStringToList.bind(this)}
          />

          <UpdatePartnerConfigClaim
            showUpdatePartnerConfigClaimDialog={this.state.openUpdatePartnerConfigClaimDialog}
            closeUpdatePartnerConfigClaimDialog={() => { this.setState({ openUpdatePartnerConfigClaimDialog: false }) }}
            partnerConfigClaim={partnerConfigClaim}
            showUpdatePartnerConfigClaimProgressAlert={this.props.partnerStatusData.updatePartnerConfigClaimLoader}
            showUpdatePartnerConfigClaimSuccessAlert={this.props.partnerStatusData.updatePartnerConfigClaimSuccess}
            showUpdatePartnerConfigClaimErrorAlert={!!this.props.partnerStatusData.updatePartnerConfigClaimError}
            updatePartnerConfigClaimError={this.props.partnerStatusData.updatePartnerConfigClaimError}
            updatePartnerConfigClaim={this.updatePartnerConfigClaim.bind(this)}

            resetUpdatePartnerConfigClaimSuccessAlert={this.resetUpdatePartnerConfigClaimSuccessAlert.bind(this)}
            resetUpdatePartnerConfigClaimErrorAlert={() => { this.props.dispatch(actions.resetUpdatePartnerConfigClaiimError()) }}

            formatAndConvertToListString={this.sanitizeList.bind(this)}
            removeSquareBrackets={this.removeSquareBrackets.bind(this)}
            convertListToString={this.sanitizeList.bind(this)}
            convertStringToList={this.convertStringToList.bind(this)}

          />

        </Container>
      </React.Fragment >
    );
  }
}

export default connect((state) => ({
  partnerStatusData: state.partnerStatus,
  globalData: state.global,
}))(PartnerStatus);
