export const ClaimStatusEnum =  {

  docs_pending: ['All_Docs_Pending','Docs_Partially_Submitted'],
  verification: ['Decision_Pending'],
  Approved: ['Approved']
}

export const ClaimActionEnum = {
  complete_docs:  'complete_docs',
  approve:  'approve',
  approve_paid:  'approve_paid',
  reject:  'reject',
}
