import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import './LanguagesTable.css'

export default function LanguagesTable(props) {

  if(props.languages && props.languages.length > 0){
  return (
    <>
      <div style={{ marginTop: "2%" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{color: 'red'}}>
              <TableRow >
                <TableCell className="table-cell-header">Name</TableCell>
                <TableCell className="table-cell-header">Code</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.languages.map((language) => {
                return (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {language.name}
                    </TableCell>
                    <TableCell align="left"> {language.code}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
  }else if(props.languages && props.languages.length === 0){
  return "No languages were found, click on the add button to add new language."
  }
}
 