import React from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import Dialog from 'material-ui/Dialog';
import { Button } from '@material-ui/core';
import * as actions from './actions.es6';
import { hideCustomerBar } from '../Main/actions.es6';
import { localisedText } from '../../lib/localisation.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';

export class LoyaltyRenewal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
      openConfirmation: false,
    };
  }

  componentWillMount() {
    this.props.dispatch(hideCustomerBar());
    this.updateLanguage();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'Reports',
      this.state.labels
    );
  }

  submitRequest() {
    this.props.dispatch(actions.loyaltyRenewalRequest());
    this.handleClose();
  }

  openConfirmation() {
    this.setState({ openConfirmation: true });
  }

  handleClose() {
    this.setState({ openConfirmation: false });
  }

  render() {
    const labels = this.state.labels;

    const dialogActions = [
      <Button
        color="primary"
        variant="outlined"
        size="large"
        onClick={this.handleClose.bind(this)}
        style={{margin:"5px"}}
      >
        {labels.cancel}
      </Button>,
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={this.submitRequest.bind(this)}
          style={{margin:"5px", paddingLeft: '5%', paddingRight:'5%'}}
      >
        {labels.loyaltyRenewalSubmit}
      </Button>
    ];

    return (
      <div>
        <center>
          <button
            className="btn btn-info"
            onClick={this.openConfirmation.bind(this)}
          >
            {labels.loyaltyRenewalButton}
          </button>
        </center>
        <div>
          <Dialog
            title={labels.loyaltyRenewal}
            actions={dialogActions}
            modal={false}
            open={this.state.openConfirmation}
            onRequestClose={this.handleClose}
            contentStyle={{ width: '35%' }}
          >
            {labels.loyaltyRenewalConfirm}
          </Dialog>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  data: state.loyaltyRenewal,
  globalData: state.global,
}))(LoyaltyRenewal);
