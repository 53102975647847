import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  uploadLoyaltyData: null,

  mapColumnsToFields: null,
  mapColumnsToFieldsLoader: false,
  mapColumnsToFieldsErrors: null,

  getPreviousLoyaltyStatus: null,
  getPreviousLoyaltyStatusLoader: false,
  getPreviousLoyaltyStatusErrors: null,

  getPreviousLoyaltiesStatus: null,
  getPreviousLoyaltiesStatusLoader: false,
  getPreviousLoyaltiesStatusErrors: null,

  startDate: null,
  endDate: null,
};

export default function loyaltyUploadReducer(state = initialState, action) {
  switch (action.type) {
    case types.UPLOAD_LOYALTY_DATA_SUCCESS:
      return { ...state, uploadLoyaltyData: action.payload };
    case types.MAP_COLUMN_NUMBER_TO_FIELDS_REQUEST:
      return { ...state, mapColumnsToFieldsLoader: true };
    case types.MAP_COLUMN_NUMBER_TO_FIELDS_SUCCESS:
      return {
        ...state,
        mapColumnsToFieldsLoader: false,
        mapColumnsToFields: action.payload,
      };
    case types.MAP_COLUMN_NUMBER_TO_FIELDS_FAIL:
      return { ...state, mapColumnsToFieldsLoader: false, mapColumnsToFieldsErrors: action.payload };



    case types.GET_PREVIOUS_LOYALTY_STATUS_REQUEST:
      return { ...state, getPreviousLoyaltyStatusLoader: true };
    case types.GET_PREVIOUS_LOYALTY_STATUS_SUCCESS:
      return {
        ...state,
        getPreviousLoyaltyStatusLoader: false,
        getPreviousLoyaltyStatus: action.payload,
      };
    case types.GET_PREVIOUS_LOYALTY_STATUS_FAIL:
      return { ...state, getPreviousLoyaltyStatusLoader: false, getPreviousLoyaltyStatusErrors: action.payload };



    case types.GET_PREVIOUS_LOYALTIES_STATUS_REQUEST:
      return { ...state, getPreviousLoyaltiesStatusLoader: true };
    case types.GET_PREVIOUS_LOYALTIES_STATUS_SUCCESS:

      let loyalties = [];
      if(!state.endDate || !state.startDate){
        loyalties = action.payload;
      }else{
        loyalties = action.payload.filter(l=> {
          if(!l.created_at){
            return false;
          }

          const date = new Date(l.created_at);
          return state.endDate >= date && date >= state.startDate;
        });
      }

      return {
        ...state,
        getPreviousLoyaltiesStatusLoader: false,
        getPreviousLoyaltiesStatus: loyalties,
      };
    case types.GET_PREVIOUS_LOYALTIES_STATUS_FAIL:
      return { ...state, getPreviousLoyaltiesStatusLoader: false, getPreviousLoyaltiesStatusErrors: action.payload };
    case types.SET_LOYALTIES_START_DATE_REQUEST:
      return { ...state, startDate: action.payload };
    case types.SET_LOYALTIES_END_DATE_REQUEST:
      return { ...state, endDate: action.payload };


    case types.UPLOAD_LOYALTY_DATA_RESET:
      return { initialState };

    default:
      return state;
  }
}
