import React from 'react';
import ScribeGuide from './ScribeGuide';
import VideoGuide from './VideoGuide';

export default function ViewGuide(props) {
  const guideGuid = props.params.id
  const guides = JSON.parse(localStorage.getItem('guides')) || [];
  const guide = guides.find(g => g.guid === guideGuid);

  console.log('props: ', guide);

  const type = guide ? guide.type : '';
  console.log('type: ', type);

  if (type === 'SCRIBE') {
    return <ScribeGuide guide={guide} />;
  } else if (type === 'VIDEO') {
    return <VideoGuide guide={guide} />;
  } else {
  console.log('Unknown type: ', type);
    return <p>Unknown guide type</p>;
  }
}