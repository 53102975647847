import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({

    viewquotetablewrapper: {
        width: "100%",
        "& table": {
            width: "100%",
            background: "#FFFFFF",
            border: "1px solid #A7A7A7",
            marginBottom: "20px",
            "& tr": {
                borderTop: "1px solid #A7A7A7",
            },
            "& th": {
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "12px",
                lineHeight: "15px",
                color: "#000000",
                padding: "10px",
                borderRight: "1px solid #A7A7A7",
            },
            "& td": {
                fontFamily: "open sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "15px",
                color: "#000000",
                padding: "10px",
                borderRight: "1px solid #A7A7A7",

            }
        }
    },
    headerborder: {
        borderBottom: "1px solid #A7A7A7",
    },
    showmoreshowless: {
        "& ul": {
            display: "flex",
            padding: "0",
            cursor: "pointer",
            "& li": {
                listStyle: "none",
                fontFamily: "open sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "15px",
                color: "#000000",
                "&:nth-child(2)": {
                    marginTop: "-3px"
                }
            }
        }
    },
    totalpremium: {
        textAlign: "right",
        "& p": {
            fontFamily: "open sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
        }
    },
    acceptquote:{
        display: "flex",
        marginLeft: "6px",
        "& input":{
            marginRight: "5px",
            marginTop: "-5px",
        },
        "& label":{
            fontFamily: "open sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
        }
    },
    nodatafound: {
        textAlign: "center",
        margin: "40px 0 40px 0",
        "& p":{
          fontFamily: "open sans",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "15px",
          color: "#000000",
        },
        "& button": {
          background: "#FBA92D",
          borderRadius: "8px",
          fontFamily: "Open Sans",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "15px",
          boxShadow: "none",
          padding: "10px",
          textTransform: "capitalize",
          marginTop: "15px",
          "&:hover":{
              background: "#173E4B",
              boxShadow: "none",
          }
      }
      },

}));