import React from 'react';
import { connect } from 'react-redux';
import { hashHistory } from 'react-router';
import Loader from 'react-loader';
import LocalizedStrings from 'react-localization';
import { updateCurrentLanguage } from '../../../lib/languageUtils.es6';
import { localisedText } from '../../../lib/localisation.es6';
import {
  Button, DialogActions, DialogContent, DialogTitle,
  Dialog, Typography, Card, CardContent, Grid, Backdrop, CircularProgress
} from '@material-ui/core';
import ReactPaginate from 'react-paginate';
import MaterialTable from 'material-table';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as actions from './actions.es6';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../lib/constants';
import { getGroupMobisiteBaseURL } from '../../../lib/utils.es6';
import { getPartner, hasAuthourity } from "../../../lib/access.es6";
import AlertDialog from "../../../components/AlertDialog/index.es6";

export class MyGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: new LocalizedStrings(localisedText),
      paymentDialog: false,
      selectedGroup: {},
      anchorEl: null,
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      showDeleteGroupConfirmation: false,
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentWillMount() {
    this.updateLanguage();
    this.getGroups();
  }

  componentDidUpdate() {
    this.updateLanguage();
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'GroupRegistration',
      this.state.labels
    );
  }

  getGroups = () => {
    const { page, size } = this.state
    this.props.dispatch(actions.getGroupsRequest({ page, size }))
  }
  handleMenuOpen(rowData, event) {
    this.setState({ selectedGroup: rowData, anchorEl: event.currentTarget });
  };

  handleMenuClose() {
    this.setState({ anchorEl: null });
  };

  goToMembers(groupId) {
    hashHistory.push(`admin/group-status/${groupId}`)
  }

  goToAddMembers(groupId) {
    hashHistory.push(`admin/add-group-members/${groupId}`)
  }


  errorHandler(message) {
    this.setState({ message });
  }

  openCertificate() {
    const certificateUrl = this.state.selectedGroup.certificate_url
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';

    const url = encodeURI(certificateUrl);
    a.href = url;
    a.target = "_blank"
    a.download = 'sample.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  generateGroupLink() {
    const registrationLink = this.state.selectedGroup.registration_link;
    return `${getGroupMobisiteBaseURL()}${registrationLink}`;
  }

  copyGroupLink() {
    const groupMobisiteURL = this.generateGroupLink();
    navigator.clipboard.writeText(groupMobisiteURL);
  }

  closeDialog = () => this.setState({ paymentDialog: false, selectedGroup: {} })

  openDialog() {
    this.setState({ paymentDialog: true, });
  }

  displayAmountDue = (group) => {

    if (!group || Object.keys(group).length === 0) {
      return "";
    }
    const productPremiums = group.product.premiums || [];
    const amountDisplay = []
    const currencyPartner = getPartner();
    const currencyCode = currencyPartner ? currencyPartner.country.currency_code : "";
    for (let i = 0; i < productPremiums.length; i++) {
      const productPremium = productPremiums[i];
      amountDisplay.push(`${currencyCode} ${((productPremium.amount_in_cents * group.group_size) / 100).toFixed(2)} per ${productPremium.granularity}`)
    }
    return amountDisplay.join(" OR ");
  }

  handleChangePageSize(event) {
    event.preventDefault();
    const size = parseInt(event.target.value);
    console.log('size: ', size);
    this.setState({ size }, function () {
      this.getGroups()
    });
  }

  handlePageClick(data) {
    const page = data.selected;
    console.log('page: ', page);
    this.setState({ page }, function () {
      this.getGroups()
    });
  }

  handleDelete() {
    let groupId = this.state.selectedGroup.id;
    const { page, size } = this.state;
    this.props.dispatch(actions.deleteMyGroupRequest({ groupId, page, size }));
    this.setState({ showDeleteGroupConfirmation: false });
  }

  getPages() {
    return Math.ceil(this.props.groupsData.count / this.state.size);
  }


  pageSizeSelector() {
    const { size } = this.state;
    const display = {
      display: this.props.groupsData.count > size ? '' : 'none',
    };
    return (
      <select
        value={size}
        onChange={this.handleChangePageSize.bind(this)}
        style={display}
      >
        <option>5</option>
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </select>
    );
  }


  paginateGroups() {
    const pages = this.getPages();
    const display = {
      display: pages > 1 ? '' : 'none',
    };
    const labels = this.state.labels;
    return (
      <div style={display}>
        <ReactPaginate
          previousLabel={labels.paginationPreviousText}
          nextLabel={labels.paginationNextText}
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick.bind(this)}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
          forcePage={this.state.page}
        />
      </div>
    );
  }

  renderPagination() {
    return <Grid container style={{ alignItems: "right", backgroundColor: 'white' }}>
      <Grid item xs={11} style={{ textAlign: "right", backgroundColor: 'white' }}> {this.paginateGroups()}</Grid>
      <Grid item xs={1} style={{ textAlign: "left", backgroundColor: 'white', paddingLeft: '1%', margin: "auto" }}> {this.pageSizeSelector()} </Grid>
    </Grid>
  }
  getStatus() {
    const num = Math.floor(Math.random() * 10);
    if (num % 2 === 0) {
      return 'Compelete'
    } else {
      return 'In Progress'
    }
  }

  render() {
    const groups = this.props.groupsData.groups || [];
    const isLoading = this.props.groupsData.loader;


    return (
      <div>
        <Loader loaded={!this.props.groupRegistrationData.loader} color="#ff834f">
          <div className="row" style={{ width: '98%', margin: "auto" }}>
            {this.props.groupRegistrationData.groupRegistrationError ? this.groupRegisterationError() : ''}
            <Grid item xs={12}>
              <MaterialTable
                title="My Registered Groups"
                columns={[
                  { title: 'ID', field: 'id' },
                  { title: 'Registered', field: 'created_at', render: rowData => { return new Date(rowData.created_at).toLocaleDateString("en-US") } },
                  { title: 'Name', field: 'name' },
                  { title: 'Product', field: 'product', render: rowData => rowData.product.name },
                  { title: 'Email', field: 'email', sorting: false, },
                  { title: 'Telephone', field: 'telephone', sorting: false, },
                  { title: 'Occupation', field: 'occupation' },
                  { title: 'KRA Pin', field: 'kra_pin', sorting: false },
                  {
                    title: 'action', field: 'kra_pin', render: rowData => {
                      return <div>

                        <MoreVertIcon
                          onClick={this.handleMenuOpen.bind(this, rowData)}
                          style={{ float: 'left' }}
                        />
                        {(this.state.selectedGroup.id === rowData.id) && <Menu
                          id="simple-menu"
                          anchorEl={this.state.anchorEl}
                          keepMounted
                          open={Boolean(this.state.anchorEl)}
                          onClose={this.handleMenuClose.bind(this)}
                          name={rowData}>
                          <MenuItem onClick={this.goToMembers.bind(this, rowData.id)}>View Members</MenuItem>
                          <MenuItem onClick={this.goToAddMembers.bind(this, rowData.id)} disabled={this.state.selectedGroup.registration_status === 'Closed'}>Edit Group</MenuItem>
                          <MenuItem onClick={this.openDialog.bind(this)}>View Payment Details</MenuItem>
                          <MenuItem disabled={!this.state.selectedGroup.registration_link || this.state.selectedGroup.registration_status === 'Closed'} onClick={this.copyGroupLink.bind(this)} >Copy Group Link </MenuItem>
                          <MenuItem onClick={this.openCertificate.bind(this)}>Download Certificate</MenuItem>
                          <MenuItem disabled={!hasAuthourity('DELETE_GROUP') || this.state.selectedGroup.registration_status === 'Closed'} onClick={() => this.setState({ showDeleteGroupConfirmation: true })}>Delete Group</MenuItem>
                        </Menu>}
                      </div>
                    }
                  },
                ]}
                data={groups || []}
                actions={[
                  {
                    icon: 'add',
                    tooltip: 'Register New Group',
                    isFreeAction: true,
                    onClick: (event) => hashHistory.push(`admin/group-registeration`)
                  }
                ]}

                options={{
                  search: true,
                  sorting: true,
                  exportButton: true,
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  headerStyle: {
                    backgroundColor: '#003643',
                    color: '#FFF'
                  },
                  // tableLayout: "fixed",
                  // columnResizable: true
                }}
              />
            </Grid>
            <br />
            <hr />
          </div>
        </Loader>
        <Dialog maxWidth="md" fullWidth onClose={this.closeDialog} aria-labelledby="customized-dialog-title" open={this.state.paymentDialog}>
          <DialogTitle id="customized-dialog-title" onClose={this.closeDialog}>
            Make a payment using any of the below methods
          </DialogTitle>
          <DialogContent dividers>
            <Grid justify="space-between" container>
              <Grid item sm={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h6">
                      Banking Details
                    </Typography>
                    <Typography color="textSecondary">
                      Amount: <b>{this.displayAmountDue(this.state.selectedGroup)}</b>
                    </Typography>
                    <Typography color="textSecondary">
                      Bank Name: <b>NCBA Bank Kenya PLC</b>
                    </Typography>
                    <Typography color="textSecondary">
                      Account Name: <b>Britam General Insuarance (Kenya) LTD</b>
                    </Typography>
                    <Typography color="textSecondary">
                      Account Number: <b>6631200088</b>
                    </Typography>
                    <Typography color="textSecondary">
                      Swift Code: <b>CBAFKENX</b>
                    </Typography>
                    <Typography color="textSecondary">
                      Branch Name: <b>Wabera Street</b>
                    </Typography>
                    <Typography color="textSecondary">
                      Clearing Code: <b>07002</b>
                    </Typography>
                    <Typography color="textSecondary">
                      Reference: <b>{`${this.state.selectedGroup.name} - ${this.state.selectedGroup.id}`}</b>
                    </Typography>
                    <br />
                    <p><b>Payment will take at least 3 working day to reflect.</b></p>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item sm={5}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h6">
                      Mpesa Paybill
                    </Typography>
                    <Typography color="textSecondary">
                      Amount: <b>{this.displayAmountDue(this.state.selectedGroup)}</b>
                    </Typography>
                    <Typography color="textSecondary">
                      Mpesa Paybill Number: <b>584065</b>
                    </Typography>
                    <Typography color="textSecondary">
                      Account Number: <b>{`${this.state.selectedGroup.name} - ${this.state.selectedGroup.id}`}</b>
                    </Typography>
                  </CardContent>
                  <br />
                  <p><b>Payment will take at least 1 working day to reflect.</b></p>
                </Card>
              </Grid>
            </Grid>
            <br />
            <center><label><b>NB: Please send proof of payment to micro-underwriting@britam.com</b></label></center>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={this.closeDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <AlertDialog
          show={this.state.showDeleteGroupConfirmation}
          warning
          title={'Confirm Delete Group'}
          onConfirm={this.handleDelete}
          confirmBtnText={'Delete Group'}
          confirmBtnCssClass
          showCancel
          onCancel={() => this.setState({ showDeleteGroupConfirmation: false })}
        />
        <Backdrop style={{ zIndex: 3000, color: 'white' }} open={isLoading}>
          <CircularProgress color="#fff" />
        </Backdrop>
      </div >
    );
  }
}

export default connect(state => ({
  groupRegistrationData: state.groupRegistration,
  globalData: state.global,
  groupsData: state.listGroups
}))(MyGroups);
