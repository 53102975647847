import { take, call, put } from 'redux-saga/effects';
import { registerCustomer } from '../../lib/requests.es6';
import * as actions from './actions.es6';
import { makeCurrentCustomer } from '../Customer/actions.es6';
import { unAuthorizedNotification } from '../../lib/access.es6';
import { buildFailureMessage } from '../../lib/utils.es6';
import { GAloggerException } from '../../lib/monitoring.es6';
import { getCustomerDependantsRequest } from '../DependantsList/actions.es6';
import { getCustomerBeneficiaryRequest } from '../BeneficiaryList/actions';
import { getAllCustomerClaimsRequest } from '../CustomerScreen/actions.es6';


/**
 * this saga continually watches register request actions
 */
export function* registerRequestWatcher() {
  for (;;) {
    // pause and wait for the REGISTER_CUSTOMER_REQUEST action to be dispatched
    const regActionResponse = yield take('REGISTER_CUSTOMER_REQUEST');
    try {
      const response = yield call(registerCustomer, regActionResponse.payload);
      yield put(actions.registerCustomerSuccess(response.data));
      yield put(
        makeCurrentCustomer(
          response.data.customer,
          'Customer successfully registered'
        )
      );
      yield put(getCustomerBeneficiaryRequest(response.data.customer.guid))
      yield put(getCustomerDependantsRequest(response.data.customer.guid));
      yield put(getAllCustomerClaimsRequest(response.data.msisdn))
    } catch (e) {
      const errorMessage = buildFailureMessage(
        'An error occurred, this could be due to invalid data',
        e.response.data
      );
      yield put(actions.registerCustomerFail(errorMessage));
      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
