import React, { useState, useEffect } from 'react'
import ModalInc from '../../../../shared-ui/ModalInc'
import Grid from "@material-ui/core/Grid";
import MenuItem from '@mui/material/MenuItem';
import { useStyle } from "./Style";
import ButtonInc from '../../../../shared-ui/ButtonInc';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import benefitOptions from '../../../Policyv2/Components/BenefitsOptionsData/Benefits';
import { find } from 'lodash';
import { getUserFullname, getUserGuid, numberInCommaFormat } from '../../../../lib/access.es6';
import { generateChangeQoutePayload, generateNewQoutePayload } from './helpers';
import AlertDialog from '../../../../components/AlertDialog/index.es6';
import { CircularProgress } from '@mui/material';
import moment from 'moment';


const PolicyUpdates = (props) => {

  if (!props.policy && !props.open) return null;

  const classes = useStyle();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [effected_At, setEffectedAt] = useState('')
  const [reasonforupgrade, setReasonForUpgrade] = useState('')

  useEffect(() => {
    if (props.createdQuote) {

      const createdQuote = props.createdQuote
      props.resetAddQuoteSuccessAlert();

      const changeQuotePayload = generateChangeQoutePayload(createdQuote, props.policy, effected_At)
      props.changeQuote(changeQuotePayload)
    }
  }, [props.createdQuote]);

  useEffect(() => {
    if (props.changedQuote) {
      props.resetChangeQuoteSuccessAlert();

      const web_agent_guid = getUserGuid();
      const web_agent_name = getUserFullname();

      const prefixPolicyNote = "Note from policy upgrade/downgrade::  "

      const policyNotePayload = {
        "note": prefixPolicyNote + reasonforupgrade,
        "policy_guid": props.policy.guid,
        "web_user": web_agent_name,
        "web_user_guid": web_agent_guid
      }

      props.addPolicyNote(policyNotePayload)
    }
  }, [props.changedQuote]);


  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === "selectedProduct") {
      setSelectedProduct(value)
    }
    if (name === "effected_At") {
      setEffectedAt(value)
    }
    if(name === "reasonforupgrade"){
      setReasonForUpgrade(value)
    }
  }



  const handleUpdatePolicy = () => {
    const payload = {
      "selectedProduct": selectedProduct,
      "effected_At": effected_At
    }
    console.log("update policy", payload)
    const newQoute = selectedProduct ? generateNewQoutePayload(currentPolicy, selectedProduct) : null

    props.createQuote(newQoute)
  }

  const handleNoteCreatedSuccessfully = () => {
    props.resetAddPolicyNoteSuccessAlert();
    props.togglePolicyUpgradeDialog()
  }

  const handleNoteCreateError = () => {
    props.resetAddPolicyNoteErrorAlert();
  }

  const handleQuoteCreateError = () => {
    props.resetAddQuoteErrorAlert();
  }

  const handleChangeQuoteError = () => {
    props.resetChangeQuoteErrorAlert()
  }


  const currentPolicy = props.policy

  const product = find(benefitOptions, { option: currentPolicy.quotation.product_code })
  console.log('product: ', product);
  console.log('currentPolicy: ', currentPolicy);


  const currentProductDisplay = {
    title: 'Current Plan',
    product,
    policy: currentPolicy,
    totalPremiums: currentPolicy.quotation.total_premium_amount_in_cents / 100
  }




  const products = benefitOptions.filter(product => product.option !== currentPolicy.quotation.product_code)
  const currentDate = moment().format('YYYY-MM-DD');

  const newQoute = selectedProduct ? generateNewQoutePayload(currentPolicy, selectedProduct) : null

  const selectedProductDisplay = {
    title: 'Selected Plan',
    product: selectedProduct,
    totalPremiums: newQoute ? newQoute.total_premmius / 100 : null
  }
  return (
    <>
      <ModalInc
        modalTitle="Upgrade or Downgrade  Policy "
        subTitle={
          <>
            Fill the below fields to upgrade or downgrade policy with grower number:{" "}
            <strong>{currentPolicy.policy_number}</strong>
          </>
        }
        open={props.open}
        onClose={props.close}
        fullWidth
        maxWidth="lg"
      >
        <ValidatorForm onSubmit={() => handleUpdatePolicy()}>
          <Grid container spacing={2} className={classes.updatePolicyWrapper}>
            <Grid xs={12} sm={6} md={6}>
              <SelectValidator
                labelId="select-selectedProduct"
                id="select-selectedProduct"
                value={selectedProduct}
                label="Select new plan"
                name='selectedProduct'
                onChange={handleChange}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select new plan']}
              >
                {products.map((benefit, index) => {
                  return (
                    <MenuItem
                      key={index}
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }}
                      value={benefit}>
                      {benefit.option}
                    </MenuItem>
                  );
                })}
              </SelectValidator>
            </Grid>
            <Grid xs={12} sm={6} md={6}>
              <TextValidator
                id="effected_At"
                label="When do you want this to take effect?"
                name='effected_At'
                value={effected_At}
                onChange={handleChange}
                fullWidth
                style={{ width: "100%", marginLeft: "20px" }}
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: currentDate,
                }}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please tell us when you want this to take effect']}
              />
            </Grid>
            {Product(currentProductDisplay)}
            {Product(selectedProductDisplay)}

            <Grid xs={12} sm={12} md={12}>
              <TextValidator
                name='reasonforupgrade'
                id="reasonforupgrade"
                label="Reason for upgrade or downgrade"
                value={reasonforupgrade}
                onChange={handleChange}
                fullWidth
                className={classes.paymentinputs}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter reason for upgrade or donwgrade']}
                rows={4}
                maxRows={6}
                multiline
                style={{
                  marginTop: "20px"
                }}
              />
            </Grid>
          </Grid>
          <Grid

            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >

            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <ButtonInc
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                style={{ marginTop: "20px", marginRight: '2%' }}
              >
                Upgrade / Downgrade Policy
              </ButtonInc>
            </Grid>
            <Grid>
              <ButtonInc
                variant="contained"
                color="primary"
                size="large"
                style={{ marginTop: "20px" }}
                onClick={props.close}
              >
                Cancel
              </ButtonInc>
            </Grid>
          </Grid>

        </ValidatorForm>

        <AlertDialog
          custom
          show={props.showAddQuoteProgressAlert}
          size="sm"
          style={{ marginTop: '0', top: '30vh' }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={'cancel'}
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2> Creating New Quote...</h2>
        </AlertDialog>

        <AlertDialog
          custom
          show={props.changeQuoteProgressAlert}
          size="sm"
          style={{ marginTop: '0', top: '30vh' }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={'cancel'}
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2> Qoute created success fully </h2>
          <h2> Now Attaching the new quote to the policy</h2>
        </AlertDialog>

        <AlertDialog
          custom
          show={props.showAddPolicyNoteProgressAlert}
          size="sm"
          style={{ marginTop: '0', top: '30vh' }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={'cancel'}
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2> Change to policy was successful </h2>
          <h2> Now Adding notes to the policy</h2>
        </AlertDialog>



        <AlertDialog
          success
          show={props.showAddPolicyNoteSuccessAlert}
          size="sm"
          title={'Policy wass successfully upgraded'}
          style={{ marginTop: '0', top: '30vh' }}
          onConfirm={() => { handleNoteCreatedSuccessfully() }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={true}
          cancelBtnText={'cancel'}
          confirmBtnCssClass
        >
        </AlertDialog>


        <AlertDialog
          show={props.showAddQuoteErrorAlert}
          danger
          title={'Error creating Quote'}
          onConfirm={() => handleQuoteCreateError()}
          confirmBtnText={'Try again'}
          confirmBtnCssClass
          showCancel={false}
          style={{ marginTop: '0', top: '30vh' }}
        >
          {
            "error"
          }
          {props.createQuoteError ? props.createQuoteError.message : ''}
        </AlertDialog>


        <AlertDialog
          show={props.changeQuoteErrorAlert}
          danger
          title={'Error Changing Quote'}
          onConfirm={() => handleChangeQuoteError()}
          confirmBtnText={'Try again'}
          confirmBtnCssClass
          showCancel={false}
          style={{ marginTop: '0', top: '30vh' }}
        >
          {props.changeQuoteError ? props.changeQuoteError.message : ''}
        </AlertDialog>


        <AlertDialog
          show={props.showAddPolicyNoteErrorAlert}
          danger
          title={'Error creating policy note'}
          onConfirm={() => handleNoteCreateError()}
          confirmBtnText={'Try again'}
          confirmBtnCssClass
          showCancel={false}
          style={{ marginTop: '0', top: '30vh' }}
        >

          {props.AddPolicyNoteError ? props.AddPolicyNoteError.message : ''}
        </AlertDialog>


      </ModalInc>

    </>
  )

  function Product(currentProductDisplay) {
    if (!currentProductDisplay.product) return null

    const hospitalBenefit = find(currentProductDisplay.product.benefits, { type: "DAILY HOSPITAL BENEFIT" })
    const surgeryBenefit = find(currentProductDisplay.product.benefits, { type: "ADDITIONAL SURGERY BENEFIT" })
    const deathBenefit = find(currentProductDisplay.product.benefits, { type: "DEATH BENEFIT" })

    return <Grid xs={12} md={6} sm={6}>
      <h6 style={{ textAlign: "left", padding: '3px' }}><strong>{currentProductDisplay.title}: {currentProductDisplay.product.product_code}</strong></h6>
      <div className={classes.tablewrapper}>
        <table>
          <tr style={{ display: 'flex' }}>
            <td style={{ flex: '1' }}>Adult Premium: <strong>Ksh {currentProductDisplay.product.adult_premim}</strong></td>
            <td style={{ flex: '1' }}>Child Premium: <strong>Ksh {currentProductDisplay.product.child_premium}</strong></td>
          </tr>
          <tr>
            <td>
              <ol>
                <li>DAILY HOSPITAL BENEFIT -  <strong> Ksh {numberInCommaFormat(hospitalBenefit.cover_amount_in_cents / 100)}</strong></li>
                <li>ADDITIONAL SURGERY BENEFIT -  <strong> Ksh {numberInCommaFormat(surgeryBenefit.cover_amount_in_cents / 100)}</strong></li>
                <li>DEATH BENEFIT -  <strong> Ksh {numberInCommaFormat(deathBenefit.cover_amount_in_cents / 100)}</strong></li>

              </ol>
            </td>
          </tr>
          <tr>
            <td>
              Total Premim: Ksh {currentProductDisplay.totalPremiums}
            </td>
          </tr>
        </table>
      </div>
    </Grid>;
  }
}

export default PolicyUpdates