import * as types from '../../lib/actionTypes.es6';

export function reportOpenClaimRequest(reportType) {
  return {
    type: types.REPORT_OPEN_CLAIM_REQUEST,
    payload: reportType,
  };
}

export function reportOpenClaimRequestSuccess(reportURL) {
  return {
    type: types.REPORT_OPEN_CLAIM_SUCCESS,
    payload: reportURL,
  };
}

export function reportOpenClaimRequestError(error) {
  return {
    type: types.REPORT_OPEN_CLAIM_FAILURE,
    payload: error,
  };
}

export function reportOpenClaimReset() {
  return {
    type: types.REPORT_OPEN_CLAIM_RESET,
  };
}
