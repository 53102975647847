import * as types from '../../lib/actionTypes.es6';

export function reportAgentWebRequest(reportType) {
  return {
    type: types.REPORT_AGENT_WEB_REQUEST,
    payload: reportType,
  };
}

export function reportAgentWebSuccess(reportURL) {
  return {
    type: types.REPORT_AGENT_WEB_SUCCESS,
    payload: reportURL,
  };
}

export function reportAgentWebError(error) {
  return {
    type: types.REPORT_AGENT_WEB_FAILURE,
    payload: error,
  };
}

export function reportAgentWebReset() {
  return {
    type: types.REPORT_AGENT_WEB_RESET,
  };
}
