import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  countries: [],
  loader: false,
  error: null,

  countryInfo: {},
  createErrors: null,

  updateCountryErrors: null,
  updateCountryInfo: {},

  deleteCountryErrors: null,
  deleteCountryInfo: {},

  createdCountry: null,
  createCountrySuccess: false,
  createCountryLoader: false,
  createCountryError: null,
  createdCountrys: [],

  hardDeleteCountrySuccess: false,
  hardDeleteCountryLoader: false,
  hardDeleteCountryError: null,

  editedCountry: null,
  editCountrySuccess: false,
  editCountryLoader: false,
  editCountryError: null,

  countryToEdit: null,


};

export default function countryReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_COUNTRIES_REQUEST:
      return { ...state, error: null, loader: true };

    case types.GET_COUNTRIES_SUCCESS:
      return { ...state, countries: action.payload, loader: false };

    case types.GET_COUNTRIES_FAILED:
      return { ...state, loader: false, error: action.payload };

    // case types.UPDATE_COUNTRY_REQUEST:
    //   return {
    //     ...state,
    //     loader: true,
    //     updateCountryErrors: null,
    //   };

    // case types.UPDATE_COUNTRY_SUCCESS:
    //   return {
    //     ...state,
    //     updateCountryInfo: action.payload,
    //     loader: false,
    //   };

    // case types.UPDATE_COUNTRY_FAILED:
    //   return {
    //     ...state,
    //     loader: false,
    //     updateCountryErrors: action.payload,
    //   };

    case types.CREATE_COUNTRY_REQUEST:
      return {
        ...state,
        createCountryError: null,
        createCountryLoader: true,
        createCountrySuccess: false,
        createdCountry: null
      };

    case types.CREATE_COUNTRY_SUCCESS:
      return {
        ...state,
        createdCountry: action.payload,
        createCountrySuccess: true,
        createCountryLoader: false,
        createCountryError: null,
      };

    case types.CREATE_COUNTRY_FAILED:
      return { ...state, createCountryLoader: false, createCountryError: action.payload };

    case types.RESET_CREATE_COUNTRY_SUCCESS:
      return { ...state, createCountrySuccess: false };

    case types.RESET_CREATE_COUNTRY_ERROR:
      return { ...state, createCountryError: null };


    case types.DELETE_COUNTRY_REQUEST:
      return {
        ...state,
        hardDeleteCountryError: null,
        hardDeleteCountryLoader: true,
        hardDeleteCountrySuccess: false,
      };

    case types.DELETE_COUNTRY_SUCCESS:
      return {
        ...state,
        hardDeleteCountrySuccess: true,
        hardDeleteCountryLoader: false,
        hardDeleteCountryError: null,
      };

    case types.DELETE_COUNTRY_FAILED:
      return { ...state, hardDeleteCountryLoader: false, hardDeleteCountryError: action.payload };

    case types.RESET_DELETE_COUNTRY_SUCCESS:
      return { ...state, hardDeleteCountrySuccess: false };

    case types.RESET_DELETE_COUNTRY_ERROR:
      return { ...state, hardDeleteCountryError: null };


    case types.UPDATE_COUNTRY_REQUEST:
      return {
        ...state,
        editCountryError: null,
        editCountryLoader: true,
        editCountrySuccess: false,
        editedCountry: null
      };

    case types.UPDATE_COUNTRY_SUCCESS:
      return {
        ...state,
        editedCountry: action.payload,
        editCountrySuccess: true,
        editCountryLoader: false,
        editCountryError: null,
      };

    case types.UPDATE_COUNTRY_FAILED:
      return { ...state, editCountryLoader: false, editCountryError: action.payload };


    case types.RESET_UPDATE_COUNTRY_SUCCESS:
      return { ...state, editCountrySuccess: false };

    case types.RESET_UPDATE_COUNTRY_ERROR:
      return { ...state, editCountryError: null };


    case types.SET_COUNTRY_TO_UPDATE:
      return { ...state, countryToEdit: action.payload };

    case types.SET_COUNTRY_TO_DELETE:
      return { ...state, countryToDelete: action.payload };

    case types.RESET_COUNTRY_TO_UPDATE:
      return { ...state, countryToEdit: null };

    case types.RESET_COUNTRY_TO_DELETE:
      return { ...state, countryToDelete: null };


    case types.USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
}
