import * as React from "react";
import Grid from "@mui/material/Grid";
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@material-ui/core";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddProduct from "./AddProduct";
import AddPremium from "./AddPremium";
import AddBenefits from "./AddBenefits";
import { PRODUCT_TYPES_FREE } from "../../PoductConstants";
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../../lib/constants";


export default function AddDialog(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});


  let steps = ["Create Product", "Add Premiums", "Add Benefits"];
  if (PRODUCT_TYPES_FREE.includes(props.productType)) {
    steps = ["Create Product", "Add Benefits"];
  }


  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    if(!props.createdProduct) return
    
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    if(!props.createdProduct) return
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
    props.resetCreateBenefitsAndPremiumsLists();
  };

  const handleClose = () => {
    return props.closeAddProductDialog()
  };

  const getAddCompnent = () => {

    let addProductStepIndex;
    let addpremiumStepIndex;
    let addBenefitStepIndex;

    if (steps.length === 3) {
      addProductStepIndex = 0
      addpremiumStepIndex = 1
      addBenefitStepIndex = 2
    } else {
      addProductStepIndex = 0
      addpremiumStepIndex = -1 // just don't show add premium on free products
      addBenefitStepIndex = 1
    }

    if (activeStep === addProductStepIndex) return {
      component:
        <AddProduct
          addProduct={props.addProduct}
          showAddProductProgressAlert={props.showAddProductProgressAlert}
          showAddProductSuccessAlert={props.showAddProductSuccessAlert}
          showAddProductErrorAlert={props.showAddProductErrorAlert}
          addProductError={props.addProductError}
          goToNextStep={handleComplete}
          resetAddProductSuccessAlert={props.resetAddProductSuccessAlert}
          resetAddProductErrorAlert={props.resetAddProductErrorAlert}
          updateDialogProductType={props.updateDialogProductType}
          mainProducts={props.mainProducts}
          addOns={props.addOns}
        />,
      title: 'Create Product ' 
    }
    if (activeStep === addpremiumStepIndex) return {
      component:
        <AddPremium
          // add premium props
          addPremium={props.addPremium}
          showAddPremiumProgressAlert={props.showAddPremiumProgressAlert}
          showAddPremiumSuccessAlert={props.showAddPremiumSuccessAlert}
          showAddPremiumErrorAlert={props.showAddPremiumErrorAlert}
          goToNextStep={handleComplete}
          resetAddPremiumSuccessAlert={props.resetAddPremiumSuccessAlert}
          resetAddPremiumErrorAlert={props.resetAddPremiumErrorAlert}
          createdPremiums={props.createdPremiums}
          createPremiumError={props.createPremiumError}
          createdProduct={props.createdProduct}
          currencyCode ={props.currencyCode}

          // hard delete props
          hardDeletePremium={props.hardDeletePremium}
          showHardDeletePremiumProgressAlert={props.showHardDeletePremiumProgressAlert}
          showHardDeletePremiumSuccessAlert={props.showHardDeletePremiumSuccessAlert}
          showHardDeletePremiumErrorAlert={props.showHardDeletePremiumErrorAlert}
          resetHardDeletePremiumSuccessAlert={props.resetHardDeletePremiumSuccessAlert}
          resetHardDeletePremiumErrorAlert={props.resetHardDeletePremiumErrorAlert}

        />,
      title: 'Add Premiums'
    }
    if (activeStep === addBenefitStepIndex) return {
      component:
        <AddBenefits
          // add benefit props
          addBenefit={props.addBenefit}
          showAddBenefitProgressAlert={props.showAddBenefitProgressAlert}
          showAddBenefitSuccessAlert={props.showAddBenefitSuccessAlert}
          showAddBenefitErrorAlert={props.showAddBenefitErrorAlert}
          goToNextStep={handleComplete}
          resetAddBenefitSuccessAlert={props.resetAddBenefitSuccessAlert}
          resetAddBenefitErrorAlert={props.resetAddBenefitErrorAlert}
          createdBenefits={props.createdBenefits}
          createBenefitError={props.createBenefitError}
          createdProduct={props.createdProduct}
          currencyCode ={props.currencyCode}

          // hard delete props
          hardDeleteBenefit={props.hardDeleteBenefit}
          showHardDeleteBenefitProgressAlert={props.showHardDeleteBenefitProgressAlert}
          showHardDeleteBenefitSuccessAlert={props.showHardDeleteBenefitSuccessAlert}
          showHardDeleteBenefitErrorAlert={props.showHardDeleteBenefitErrorAlert}
          resetHardDeleteBenefitSuccessAlert={props.resetHardDeleteBenefitSuccessAlert}
          resetHardDeleteBenefitErrorAlert={props.resetHardDeleteBenefitErrorAlert}
        />,
      title: 'Add Benefits'
    }
  }

  const renderAddComponent = () => {
    let addComponent = getAddCompnent();

    return <div style={{ margin: '2% 5%', textAlign: 'center' }}>
      <h3>{addComponent.title}</h3>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <>
          {addComponent.component}
        </>
      </Box>
    </div>
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Dialog open={props.showAddProductDialog} fullWidth fullScreen maxWidth='lg' onClose={handleClose}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Adding Product, Premiums and Benefits
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <div style={{ marginTop: "2%" }}>
              <Box sx={{ width: "100%" }}>
                <Stepper nonLinear activeStep={activeStep}>
                  {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                      <StepButton color="inherit" onClick={handleStep(index)}>
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
                <div>
                  {allStepsCompleted() ? (
                    <React.Fragment>
                        <div  style={{textAlign: 'center', margin: '10%'}}>
                      <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                      </Typography>
                      <br/>
                      <br/>
              <Button variant="outlined" onClick={handleReset} style={{ marginRight: '3%', borderColor: 'green', color: 'green', fontWeight: 'bold' }}>Add another product</Button>
              <Button variant="contained" onClick={() => { props.viewCreatedProduct() }} style={{ background: 'green', }}>View Product</Button>
            </div>

                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Typography sx={{ mt: 2, mb: 1 }}>

                        {renderAddComponent()}
                      </Typography>
                      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <Button
                          color="inherit"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Back
                        </Button>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleComplete}>
                          {completedSteps() === totalSteps() - 1
                            ? "Finish"
                            : "Next"}
                        </Button>
                      </Box>
                    </React.Fragment>
                  )}
                </div>
              </Box>
            </div>

          </DialogContent>
        </Dialog>
      </Grid>
    </Grid>
  );
}
