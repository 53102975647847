import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";

import SkeletonWrapper from '../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import { getPartner } from '../../../../../lib/access.es6';
import TableInc from '../../../../../shared-ui/TableInc';
import { trimTimestampFromDate } from '../../../../../lib/utils.es6';


const Premiums = (props) => {
  const classes = useStyle();


  const customerPremiums = props.premiums || [];

  const currencyCode = getPartner().country.currency_code

  const tempArr = customerPremiums.map(premiums => {
    return {
      ...premiums,
      product_name:  premiums.product_name || "N/A",
      created_at:   trimTimestampFromDate(premiums.created_at) || "N/A",
      amount_in_cents: currencyCode + " " + premiums.amount_in_cents / 100 || "N/A" 
      }
      
  })

  if (props.loading) {
    return <SkeletonWrapper loading={props.loading} />
  }


  return (
    <>
      {
        customerPremiums.length > 0 ? (<>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} >
              <TableInc

                columns={[
                  {
                    title: 'Product Name', field: 'product_name'
                  },
                  {
                    title: 'Payment reference', field: 'reference', render: rowData => <>
                      {
                        rowData.reference || "N/A"
                      }
                    </>
                  },
                  {
                    title: 'Created at', field: 'created_at', render: rowData => <>
                      {
                        rowData.created_at || "N/A"
                      }
                    </>
                  },
                  {
                    title: 'Effected at', field: 'effected_at', render: rowData => <>
                      {
                        rowData.effected_at || "N/A"
                      }
                    </>
                  },

                  {
                    title: 'Amount', field: 'amount_in_cents'
                  },


                ]}
                title="Premiums"
                data={
                  tempArr
                }
                options={{
                  pageSize: customerPremiums.length < 5
                  ? customerPremiums.length
                  : 5,
                  exportButton: true,
                  showTitle: false,
                }}

              />
            </Grid>
          </Grid>
        </>) : (<>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} >
              <div className={classes.policieswrapper}>
                <div className={classes.nodatafound}>
                  <p>No premiums found please add payments </p>
                </div>
              </div>
            </Grid>
          </Grid>

        </>)
      }



    </>
  )


}

export default Premiums