import React, { useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Container, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import {  Box } from '@material-ui/core'; 
import Header from './Header';
import Sidebar from './Sidebar';
import LAYOUT_CONST from './constant';
import useConfig from '../../hooks/useConfig';
import { drawerWidth } from '../../store/constant';
import { openDrawer } from '../../containers/Drawer/redux/actions';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, layout }) => {
    return {
        width: '100%',
        minHeight: 'calc(100vh - 88px)',
        flexGrow: 1,
        padding: '20px',
        marginTop: '88px',
        marginRight: '20px',
        borderRadius: `8px`,
        ...(!open && {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.shorter
            }),
            [theme.breakpoints.up('md')]: {
                marginLeft: layout === LAYOUT_CONST.VERTICAL_LAYOUT ? -(drawerWidth - 72) : '20px',
                width: `calc(100% - ${drawerWidth}px)`,
                marginTop: layout === 'horizontal' ? 135 : 88
            },
            [theme.breakpoints.down('md')]: {
                marginLeft: '20px',
                width: `calc(100% - ${drawerWidth}px)`,
                padding: '16px',
                marginTop: 88
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '10px',
                width: `calc(100% - ${drawerWidth}px)`,
                padding: '16px',
                marginRight: '10px',
                marginTop: 88
            }
        }),
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.shorter
            }),
            marginLeft: layout === 'horizontal' ? '20px' : 0,
            marginTop: layout === 'horizontal' ? 135 : 88,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            width: `calc(100% - ${drawerWidth}px)`,
            [theme.breakpoints.up('md')]: {
                marginTop: layout === 'horizontal' ? 135 : 88
            },
            [theme.breakpoints.down('md')]: {
                marginLeft: '20px',
                marginTop: 88
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '10px',
                marginTop: 88
            }
        })
    };
});



const DrawerNew = (props) => {

    const theme = useTheme();

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const { container, drawerType, layout } = useConfig();

    useEffect(() => {
        if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
            props.dispatch(openDrawer(true));

        } else {
            props.dispatch(openDrawer(false));
        }
    }, [drawerType]);

    useEffect(() => {
        if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
            props.dispatch(openDrawer(true));
        }
    }, []);

    useEffect(() => {
        if (matchDownMd) {
            props.dispatch(openDrawer(true));
        }
    }, [matchDownMd]);

    const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;

    const header =
    //  useMemo(
    //     () => (
            <Toolbar sx={{ p: condition ? '10px' : '16px' }}>
                <Header
                    selectPartner={props.selectPartner}
                    openDrawer={() => props.dispatch(openDrawer(!props.drawerData.drawerOpen))}

                    // global search props
                    searchForCustomer={props.searchForCustomer} 
                    customerList={props.customerList} 
                    resetSearch={props.resetSearch} 
                    selectCustomer={props.selectCustomer} 
                    searchData={props.searchData} 
                    dispatch={props.dispatch} 
                    logOutUserHandler={props.logOutUserHandler} 

                    // language props
                    languages={props.availableLanguages}
                    onSelectLanguage={props.onSelectLanguage}
                    selectedLanguage={props.selectedLanguage} 
                />
            </Toolbar>
    //     ),
    //     [layout, matchDownMd, props.drawerData.selectedItem, props.selectedLanguage]
    // );


    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={0}
                    sx={{
                        transition: props.drawerData.drawerOpen ? theme.transitions.create('width') : 'none'
                    }}
                >
                    {header}
                </AppBar>


                {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) &&
                    <Sidebar
                        openDrawer={() => props.dispatch(openDrawer(!props.drawerData.drawerOpen))}
                        drawerOpen={props.drawerData.drawerOpen}
                        selectedParentID={props.drawerData.selectedParentID}
                        dispatch={props.dispatch}
                        selectedItem={props.drawerData.selectedItem}
                        location={props.location}
                        labels={props.labels}
                    />}

                <Main theme={theme} open={props.drawerData.drawerOpen} layout={layout} className='mainspace'>
                    <Container maxWidth={container ? 'lg' : false} {...(!container && { style: { paddingLeft: 0, paddingRight: 0 } })}>
                        {props.children}
                    </Container>

                </Main>
            </Box>
        </>
    );

}

export default DrawerNew
