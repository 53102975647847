import React from 'react'
import { useStyle } from "./Style";
import { AppBar, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './style.css'

const MobileDisplaySearchResults = (props) => {
  const classes = useStyle();
  if (!props.customers) {
    return <div className="no-customers" />;
  }
  if (props.customers.length === 0) {
    return (
      <>
        <h2>Nothing found</h2>
      </>
    );
  }

  return (
    <>
      <Dialog
        open={!!props.customers}
        aria-labelledby="form-dialog-title"
        fullScreen
        maxWidth="md"
        className='mobile'
      >
        <AppBar style={{ position: 'absolute', backgroundColor: "#183e4b", height: "74px", paddingTop: "10px" }} >
          <div style={{ textAlign: 'right', fontSize: "150%" }}>
            <span className='search-results-found' style={{ float: 'left', margin: '1%' }}>{props.labels.results + ': ' + props.count + ' ' + props.labels.customersFound}</span>
            <IconButton edge="start" color="inherit"
              style={{ padding: '1%' }}
              onClick={props.handleClose()}
              aria-label="close">
              <CloseIcon style={{ fontSize: '100%' }} />
            </IconButton>
          </div>
        </AppBar>

        <DialogTitle id="form-dialog-title">{props.labels.results}</DialogTitle>
        <DialogContent sx={{
          background: "#eef2f6",
        }}>

          <Grid container spacing={2}>
            <Grid item xs={12} >
              {
                props.customers.map(customer => (
                  <div className={classes.searchWrapper} key={customer.guid}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <h2> {customer.full_name}</h2>
                      </Grid>
                      <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button onClick={() => props.selectCustomer(customer)}>Select</button>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <h3>National ID</h3>
                        <p>{customer.national_id || "N/A"}</p>
                      </Grid>
                      <Grid item xs={4}>
                        <h3>Phone No.</h3>
                        <p>{customer.msisdn || "N/A"}</p>
                      </Grid>
                      <Grid item xs={4}>
                        <h3>Surname</h3>
                        <p>{customer.surname || "N/A"}</p>
                      </Grid>
                    </Grid>
                  </div>

                ))
              }

            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {
                  props.paginateCustomers()
                }
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

      </Dialog>
    </>
  )
}

export default MobileDisplaySearchResults