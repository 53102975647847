import React from 'react';
import { Typography, Paper, Box } from '@mui/material';

const NoClaimComponent = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Paper elevation={3} sx={{ p: 4, maxWidth: 400 }}>
        <Typography variant="h5" gutterBottom>
          No Claim Found
        </Typography>
        <Typography variant="body1">
          There is no claim data available for the provided claim ID.
        </Typography>
      </Paper>
    </Box>
  );
};

export default NoClaimComponent;
