import React from "react";
import { connect } from "react-redux";
import ClaimsHeader from "./Components/ClaimsHeader/ClaimsHeader";
import ClaimsWrapper from "./Components/ClaimsWrapper/ClaimsWrapper";
import *  as actions from "./Redux/actions.js"
import { ClaimStatusEnum } from "./enum";

class ClaimDashboardv2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentWillMount() {
    this.props.dispatch(actions.getClaimsDcpRequest(ClaimStatusEnum.Pending))
  }

  getClaims(status) {
    this.props.dispatch(actions.getClaimsDcpRequest(status))
  }


  render() {
    return (
      <>
        <ClaimsHeader
          title="Claims Dashboard"
        />
        <ClaimsWrapper
          claimsData={this.props.claimDashboardData.claims_dcp}
          getClaims={this.getClaims.bind(this)}
          loading={this.props.claimDashboardData.get_claims_dcp_loader}
        />
      </>
    )
  }

}
export default connect((state) => ({
  customerData: state.currentCustomer,
  claimDashboardData: state.claimDashboardData,
  globalData: state.global,
}))(ClaimDashboardv2);