import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  errors: null,
  currentCustomerClaim: null,
  claimFilesLoader: false,
  loader: false,
  documentTypes: ['Please select'],
  fields: null,
  verifyBy: null,
  docReceivedRequiredFields: null,
  docReceivedStatuses: null,
  claimsHospital: ['Please Select'],
  claimRejectionReasons: [{ reason: 'Please Select' }],
  selectedPolicies: [],
  final_approved_status: [
    'ExGratia_Paid',
    'Approved_Paid',
    'Payment_In_Review',
  ],
  payable_status: ['Approved', 'ExGratia'],
  claimConditionList: [],
};

export default function processingClaimReducer(state = initialState, action) {
  switch (action.type) {
    case 'SELECT_CUSTOMER_CLAIM':
      return {
        ...state,
        currentCustomerClaim: action.payload,
      };
    case 'GET_DOCUMENT_TYPES_SUCCESS':
      return {
        ...state,
        documentTypes: initialState.documentTypes.concat(action.payload),
      };
    case 'PROCESS_CLAIM_REQUEST':
      return { ...state, loader: true, errors: null };
    case 'PROCESS_CLAIM_SUCCESS':
      return { ...state, currentCustomerClaim: action.payload, loader: false };
    case 'PROCESS_CLAIM_FAIL':
      return { ...state, errors: action.payload, loader: false };
    case 'CLAIM_FIELDS_SUCCESS':
      return { ...state, fields: action.payload };
    case 'CLAIM_VERIFY_BY_SUCCESS':
      return { ...state, verifyBy: action.payload };
    case 'CLAIM_DOC_RECEIVED_REQUIRED_FIELDS_SUCCESS':
      return { ...state, docReceivedRequiredFields: action.payload };
    case 'CLAIM_DOC_RECEIVED_STATUSES_SUCCESS':
      return { ...state, docReceivedStatuses: action.payload };
    case 'CLAIM_HOSPITAL_SUCCESS':
      return { ...state, claimsHospital: action.payload };
    case types.SET_SELECTED_POLICIES:
      return { ...state, selectedPolicies: action.payload };
    case types.CLAIM_REJECTION_REASON_SUCCESS:
      return {
        ...state,
        claimRejectionReasons: state.claimRejectionReasons.concat(
          action.payload
        ),
      };
    case types.REFRESH_CLAIM_REQUEST:
      return { ...state, loader: true, errors: null };
    case types.RETRY_FAILED_CLAIM_PAYMENT_REQUEST:
    case types.CLAIM_PAYMENT_UPDATE_OUTCOME_REQUEST:
      return { ...state, loader: true, errors: null };
    case types.RETRY_FAILED_CLAIM_PAYMENT_REQUEST_SUCCESS:
    case types.CLAIM_PAYMENT_UPDATE_OUTCOME_REQUEST_SUCCESS:
      return { ...state, loader: false };
    case types.RETRY_FAILED_CLAIM_PAYMENT_REQUEST_FAILURE:
    case types.CLAIM_PAYMENT_UPDATE_OUTCOME_REQUEST_FAILURE:
      return { ...state, loader: false, errors: action.payload };
    case types.REFRESH_CLAIM_SUCCESS:
      return { ...state, loader: false, currentCustomerClaim: action.payload };
    case types.REFRESH_CLAIM_FAILURE:
      return { ...state, loader: false, errors: action.payload };
    case types.USER_LOGOUT:
      return initialState;
    case types.CLAIM_FILE_DELETE_REQUEST:
      return { ...state, claimFilesLoader: true };
    case types.CLAIM_FILE_DELETE_REQUEST_FAILURE:
      return { ...state, claimFilesLoader: false, errors: action.payload };
    case types.CLAIM_FILE_DELETE_REQUEST_SUCCESS:
      return { ...state, claimFilesLoader: false };
    case types.CLAIM_CONDITION_REQUEST:
      return { ...state, loader: true, errors: null };
    case types.CLAIM_CONDITION_SUCCESS:
      return {
        ...state,
        claimConditionList: state.claimConditionList.concat(action.payload),
        loader: false,
      };
    default:
      return state;
  }
}
