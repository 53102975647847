import * as types from '../../lib/actionTypes.es6';

export const initialState = {
  loader: false,
  success_single_payment: '',
  error_single_payment: null,

  success_multiple_payments: '',
  error_multiple_payments: null,
  payment_report_file: null,
};

export default function manualPaymentReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_SINGLE_PAYMENT_REQUEST:
      return { ...state, loader: true };
    case types.ADD_SINGLE_PAYMENT_SUCCESS:
      return {
        ...state,
        loader: false,
        success_single_payment: 'Payment Added Successfully',
        error_single_payment: null
      };
    case types.ADD_SINGLE_PAYMENT_FAIL:
      return { ...state, loader: false, error_single_payment: action.payload };

    case types.ADD_MULTIPLE_PAYMENT_REQUEST:
      return { ...state, loader: true };
    case types.ADD_MULTIPLE_PAYMENT_SUCCESS:
      return {
        ...state,
        loader: false,
        payment_report_file: action.payload,
        success_multiple_payments: 'file uploaded successfully, download results',
      };
    case types.ADD_MULTIPLE_PAYMENT_FAIL:
      return { ...state, loader: false, error_multiple_payments: action.payload };


    case types.ADD_PAYMENT_RESET:
      return { initialState };

    default:
      return state;
  }
}
