import * as types from "../../lib/actionTypes.es6";


export function getCustomerDependantRequest(payload) {
  return {
    type: types.GET_CUSTOMER_DEPENDANT_REQUEST,
    payload,
  };
}

export function getCustomerDependantSuccess(response) {
  return {
    type: types.GET_CUSTOMER_DEPENDANT_SUCCESS,
    payload: response,
  };
}

export function getCustomerDependantFailed(error) {
  return {
    type: types.GET_CUSTOMER_DEPENDANT_FAILURE,
    payload: error,
  };
}

export function resetGetCustomerDependant(){
  return {
    type: types.RESET_GET_CUSTOMER_DEPENDANT
  }
}

export function addCustomerDependantRequest(payload) {
  return {
    type: types.ADD_CUSTOMER_DEPENDANT_REQUEST,
    payload,
  };
}

export function getCustomerDependantsRequest(payload) {
  return {
    type: types.GET_CUSTOMER_DEPENDANTS_REQUEST,
    payload,
  };
}

export function getCustomerDependantsSuccess(response) {
  return {
    type: types.GET_CUSTOMER_DEPENDANTS_SUCCESS,
    payload: response,
  };
}

export function getCustomerDependantsFailed(error) {
  return {
    type: types.GET_CUSTOMER_DEPENDANTS_FAILURE,
    payload: error,
  };
}

export function addCustomerDependantSuccess(response) {
  return {
    type: types.ADD_CUSTOMER_DEPENDANT_SUCCESS,
    payload: response,
  };
}

export function addCustomerDependantFailed(error) {
  return {
    type: types.ADD_CUSTOMER_DEPENDANT_FAILURE,
    payload: error,
  };
}


export function resetAddCustomerDependantSuccess() {
  return {
    type: types.RESET_CREATE_CUSTOMER_DEPENDANTS_SUCCESS,
  };
}

export function resetAddCustomerDependantFailed() {
  return {
    type: types.RESET_CREATE_CUSTOMER_DEPENDANTS_ERROR,
  };
}

export function updateCustomerDependantRequest(payload) {
  return {
    type: types.UPDATE_CUSTOMER_DEPENDANT_REQUEST,
    payload,
  };
}

export function updateCustomerDependantSuccess(response) {
  return {
    type: types.UPDATE_CUSTOMER_DEPENDANT_SUCCESS,
    payload: response,
  };
}

export function updateCustomerDependantFailed(error) {
  return {
    type: types.UPDATE_CUSTOMER_DEPENDANT_FAILURE,
    payload: error,
  };
}

export function deleteCustomerDependantRequest(payload) {
  return {
    type: types.DELETE_CUSTOMER_DEPENDANT_REQUEST,
    payload,
  };
}

export function deleteCustomerDependantSuccess(response) {
  return {
    type: types.DELETE_CUSTOMER_DEPENDANT_SUCCESS,
    payload: response,
  };
}

export function deleteCustomerDependantFailed(error) {
  return {
    type: types.DELETE_CUSTOMER_DEPENDANT_FAILURE,
    payload: error,
  };
}



export function resetDeleteCustomerDependantSuccess() {
  return {
    type: types.RESET_DELETE_CUSTOMER_DEPENDANTS_SUCCESS,
  };
}

export function resetDeleteCustomerDependantFailed() {
  return {
    type: types.RESET_DELETE_CUSTOMER_DEPENDANTS_ERROR,
  };
}

export function resetEditCustomerDependantSuccess() {
  return {
    type: types.RESET_EDIT_CUSTOMER_DEPENDANTS_SUCCESS,
  };
}

export function resetEditCustomerDependantFailed() {
  return {
    type: types.RESET_EDIT_CUSTOMER_DEPENDANTS_ERROR,
  };
}

export function setCustomerDependantToEditSuccess(dependant) {
  return {
    type: types.SET_CUSTOMER_DEPENDANTS_TO_EDIT,
    payload: dependant,
  };
}

export function setCustomerDependantToDeleteFailed(dependant) {
  return {
    type: types.SET_CUSTOMER_DEPENDANTS_TO_DELETE,
    payload: dependant,
  };
}


export function resetCustomerDependantToEdit() {
  return {
    type: types.RESET_CUSTOMER_DEPENDANTS_TO_EDIT,
  };
}

export function resetCustomerDependantToDelete() {
  return {
    type: types.RESET_CUSTOMER_DEPENDANTS_TO_DELETE,
  };
}