import * as types from '../../lib/actionTypes.es6';

export function reportClaimNotificationRequest(reportType) {
  return {
    type: types.REPORT_CLAIM_NOTIFICATION_REQUEST,
    payload: reportType,
  };
}

export function reportClaimNotificationSuccess(reportURL) {
  return {
    type: types.REPORT_CLAIM_NOTIFICATION_SUCCESS,
    payload: reportURL,
  };
}

export function reportClaimNotificationFailure(error) {
  return {
    type: types.REPORT_CLAIM_NOTIFICATION_FAILURE,
    payload: error,
  };
}

export function reportClaimNotificationReset() {
  return {
    type: types.REPORT_CLAIM_NOTIFICATION_RESET,
  };
}
